import { memo } from 'react';
import { BlobProvider } from '@react-pdf/renderer';
import PpdPdfPreview from '@components/PpdPdfPreview';
import { ParsedInvoiceXML } from 'types/entities';
import PreviewDocument from '@components/PreviewDocument';

function InvoicePdf({ cfdi }: any) {
  // @ts-ignore PpdPdfPreview is a JS file
  return <PpdPdfPreview cfdi={cfdi} />;
}
const MemoizedInvoicePdf = memo(InvoicePdf);

function InvoicePreview({
  invoiceObject,
}: {
  invoiceObject: string | ParsedInvoiceXML;
}) {
  return invoiceObject ? (
    <BlobProvider document={<MemoizedInvoicePdf cfdi={invoiceObject} />}>
      {({ url, loading }) => (
        <PreviewDocument
          defaultFile={url || ''}
          loading={loading}
          maxWidth={800}
        />
      )}
    </BlobProvider>
  ) : null;
}
export default memo(InvoicePreview);
