import api from '@api';
import { type AxiosRequestConfig } from 'axios';
import {
  TaxableEntityFlowUpdatePayload,
  type TaxableEntityFlow,
} from 'types/flows';

export const getTaxableEntityFlows = async (
  config?: AxiosRequestConfig<TaxableEntityFlow[]>,
) => {
  const { data } = await api.get<TaxableEntityFlow[]>(
    `taxable_entity_flows`,
    config,
  );
  return data;
};

export const getTaxableEntityFlow = async (
  id: number,
  config?: AxiosRequestConfig<TaxableEntityFlow>,
) => {
  const { data } = await api.get<TaxableEntityFlow>(
    `taxable_entity_flows/${id}`,
    config,
  );
  return data;
};

export const createTaxableEntityFlow = async (
  payload: TaxableEntityFlow,
  config?: AxiosRequestConfig<TaxableEntityFlow>,
) => {
  const { data } = await api.post<TaxableEntityFlow>(
    `taxable_entity_flows`,
    payload,
    config,
  );
  return data;
};

export const putTaxableEntityFlow = async (
  { taxableEntityFlowId, payload }: TaxableEntityFlowUpdatePayload,
  config?: AxiosRequestConfig<TaxableEntityFlow>,
) => {
  const { data } = await api.put<TaxableEntityFlow>(
    `taxable_entity_flows/${taxableEntityFlowId}`,
    payload,
    config,
  );
  return data;
};
