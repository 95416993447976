import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Badge, Tooltip } from 'reactstrap';
import { useToggle } from 'rooks';

export default function TooltipedBadge({ children, tooltip }) {
  const badgeRef = useRef();
  const [showTooltip, toggleTooltip] = useToggle(false);

  return (
    <>
      <Badge
        innerRef={badgeRef}
        className="mr-1"
        id="badge-iva-1"
        color="secondary"
      >
        {children}
      </Badge>

      {tooltip && !!badgeRef.current && (
        <Tooltip
          placement="top"
          trigger="hover"
          isOpen={showTooltip}
          target={badgeRef.current}
          toggle={toggleTooltip}
        >
          {tooltip}
        </Tooltip>
      )}
    </>
  );
}

TooltipedBadge.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.node,
};

TooltipedBadge.defaultProps = {
  tooltip: null,
};
