import React, { useRef } from 'react';
import { Flex } from '@konta/ui';

export default function OnboardingField({
  children,
  addHelpFeature,
  removeHelpFeature,
  fieldName,
  ...props
}) {
  const ref = useRef();

  return (
    <Flex
      {...props}
      ref={ref}
      onMouseEnter={() =>
        addHelpFeature(ref?.current?.querySelector('label'), fieldName)
      }
      onMouseLeave={removeHelpFeature}
    >
      {children}
    </Flex>
  );
}
