import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { workflowUpdateSuccess, workflowUpdateFailure } from '../actions';

export default function* ({ payload: { workflow_id, body } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `/platform_workflows/${workflow_id}`,
      body,
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      response,
      title: 'Declaración actualizada',
      message: 'Se han guardado los datos de forma correcta',
      status: response.status,
      success: workflowUpdateSuccess,
      failure: workflowUpdateFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: workflowUpdateFailure,
      showBodyErrors: true,
    });
  }
}
