import React, { Component } from 'react';

import {
  Table,
  Colxx,
  IconButton,
  CustomModal,
  IconConfirmModal,
} from '@components';
import { Row, Card, CardBody } from 'reactstrap';

import { connect } from 'react-redux';
import { editProducts, fetchProducts, deleteProduct } from '@redux/actions';
import { toCurrency as $ } from '@util/Utils';
import ProductForm from '@components/ProductForm/ProductForm';

// TODO remove this component
class ProductsCard extends Component {
  columns = () => {
    return [
      {
        Header: (
          <span className="list-item-heading text-capitalize">Producto</span>
        ),
        accessor: 'description',
        width: 350,
        Cell: (props) => {
          return <p className="list-item-heading">{props.value}</p>;
        },
      },
      {
        Header: 'Precio unitario',
        accessor: 'price',
        Cell: (props) => (
          <p className="list-item-heading text-capitalize">
            {props.row.original ? $(props.value) : ''}
          </p>
        ),
      },
      {
        Header: 'IVA',
        accessor: 'iva',
        Cell: (props) => (
          <p className="list-item-heading">{`${props.value * 100}%`}</p>
        ),
      },
      {
        Header: 'IVA Retenido',
        accessor: 'retained_iva',
        Cell: (props) => (
          <p className="list-item-heading">{`${props.value * 100}%`}</p>
        ),
      },
      /* {
        Header: "ISR",
        accessor: "isr",
        Cell: (props) => (
          <p className="list-item-heading">{`${props.row.original.isr * 100}%`}</p>
        ),
      },
      {
        Header: "IEPS",
        accessor: "ieps",
        Cell: (props) => (
          <p className="list-item-heading">{`${props.row.original.ieps * 100}%`}</p>
        ),
      }, */
      {
        Header: 'Acción',
        accessor: 'email',
        Cell: (props) => {
          return (
            <>
              <IconButton
                id={`edit-icon-${props.row.original.id}`}
                tooltipHelper="Editar producto"
                hover="primary"
                iconName="simple-icon-pencil"
                handleClick={() => this.editProduct(props.row.original)}
                className=""
              />
              <IconConfirmModal
                cancelButton="Cancelar"
                confirmButton="Confirmar"
                iconId={`delete-icon-${props.row.original.id}`}
                iconTooltipHelper="Borrar producto"
                iconHover="danger"
                iconName="simple-icon-trash"
                handleSubmit={() => this.deleteProduct(props.row.original.id)}
                iconClassName="ml-2"
              >
                <i className="large-icon iconsmind-Danger" color="danger" />
                <p>¡Estas a punto de borrar un producto de tu catálogo!</p>
              </IconConfirmModal>
            </>
          );
        },
      },
    ];
  };

  state = {
    productData: [],
    selectedProduct: {},
    isProductsOpen: false,
    selectedIVARETPercent: {},
    selectedIVAPercent: {},
    selectedISRPercent: {},
    selectedIEPSPercent: {},
    selectedUnitID: null,
  };

  handleValidProducts = (event, values, errors) => {
    const {
      selectedISRPercent,
      selectedIVARETPercent,
      selectedIVAPercent,
      selectedIEPSPercent,
      selectedUnitID,
    } = this.state;
    const product = {
      ...values,
      client_supplier_id: null,
      iva: selectedIVAPercent ? selectedIVAPercent.value : null,
      retained_iva: selectedIVARETPercent ? selectedIVARETPercent.value : null,
      ieps: selectedIEPSPercent ? selectedIEPSPercent.value : null,
      isr: selectedISRPercent ? selectedISRPercent.value : null,
    };
    if (selectedUnitID) {
      product.unit_id = selectedUnitID;
    }

    this.props.editProducts(
      this.props.taxable_entity,
      product,
      this.state.selectedProduct.id,
    );
    this.setState({ selectedUnitID: null });
    this.toggleProducts();
  };

  handleProducts = (event, values, errors) => {
    console.log(errors);
  };

  toggleProducts = (_) => {
    this.setState({ isProductsOpen: !this.state.isProductsOpen });
  };

  updateProductTax = (
    selectedIVARETPercent,
    selectedIVAPercent,
    selectedISRPercent,
    selectedIEPSPercent,
  ) => {
    this.setState({
      selectedIVARETPercent,
      selectedIVAPercent,
      selectedISRPercent,
      selectedIEPSPercent,
    });
  };

  updateNewProductUnit = (unit_id) => {
    this.setState({ selectedUnitID: unit_id });
  };

  editProduct = (product) => {
    this.setState({ selectedProduct: product });
    this.toggleProducts();
  };

  deleteProduct = (product_id) => {
    this.props.deleteProduct(this.props.taxable_entity, product_id);
  };

  render() {
    const { loading, products } = this.props;
    const { isProductsOpen, selectedProduct } = this.state;
    return (
      <>
        <Card className="mb-4" style={{ minHeight: '70vh' }}>
          <Row>
            <Colxx sm="12">
              <CardBody>
                <Table
                  loading={loading}
                  noDataText="No existen datos para este periodo."
                  defaultPageSize={10}
                  data={products}
                  columns={this.columns()}
                />
              </CardBody>
            </Colxx>
          </Row>
        </Card>
        <CustomModal
          title="Editar Producto o Servicio"
          toggle={this.toggleProducts}
          isOpen={isProductsOpen}
          handleSubmit={this.handleProducts}
          handleValidSubmit={this.handleValidProducts}
          model={selectedProduct}
        >
          <ProductForm
            product={selectedProduct}
            updateProductTax={this.updateProductTax}
            updateNewProductUnit={this.updateNewProductUnit}
          />
        </CustomModal>
      </>
    );
  }
}

const mapStateToProps = ({
  taxableEntity: { taxable_entity },
  products: { products, loading: pLoading },
  authUser: { user },
}) => {
  return {
    taxable_entity,
    user,
    products,
    pLoading,
  };
};
export default connect(mapStateToProps, {
  editProducts,
  deleteProduct,
  fetchProducts,
})(ProductsCard);
