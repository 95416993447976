/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Loader,
  PaymentMethods,
  SimpleStateButton,
  Colxx,
  ButtonConfirmModal,
} from '@components';
import { Row, Alert } from 'reactstrap';
import BankCard from './BankCard';

export function PaymentCard(props) {
  const {
    floading,
    handlePayment,
    handleTabChange,
    tabChangeButton,
    payButton,
    fpIsExpired = false,
    filter = false,
  } = props;

  return (
    <Loader loading={props.loading}>
      {props.ui.payment_methods_toogle ? (
        <div>
          <PaymentMethods name="payments" filter={filter} />{' '}
          <div>
            <div>
              {props.children}
              {payButton && (
                <Row>
                  {fpIsExpired && (
                    <Colxx xs={12}>
                      <Alert color="danger" className="mb-3 rounded-alert">
                        Declaración de impuestos vencida. Porfavor contacte a su
                        contador.
                      </Alert>
                    </Colxx>
                  )}
                  <Colxx
                    xs={12}
                    className="mt-4 d-flex align-items-center justify-content-center mb-5 mt-3"
                  >
                    {payButton && (
                      <ButtonConfirmModal
                        buttonColor="primary"
                        handleSubmit={handlePayment}
                        cancelButton="Cancelar"
                        confirmButton="Confirmar"
                        buttonLabel="Pagar ahora"
                        loading={floading}
                        isButtonDisabled={floading || fpIsExpired}
                      >
                        <i
                          className="large-icon iconsmind-Danger"
                          color="danger"
                        />
                        <p>¡Estas a punto de pagar tus impuestos! </p>
                        <br />
                        <p>El pago se verá reflejado en 24h en el SAT.</p>
                      </ButtonConfirmModal>
                    )}
                    {tabChangeButton && (
                      <SimpleStateButton
                        className="ml-3"
                        size="md"
                        color="primary"
                        loading={floading}
                        disabled={floading}
                        onClick={() => handleTabChange('2')}
                      >
                        Pagar con línea de captura
                      </SimpleStateButton>
                    )}
                  </Colxx>
                </Row>
              )}
            </div>
            <div>
              {props.displayFiscalPeriodInvoice ? props.children[1] : null}
            </div>
          </div>
        </div>
      ) : (
        <BankCard />
      )}
    </Loader>
  );
}

PaymentCard.propTypes = {
  children: PropTypes.any,
  handleSubmit: PropTypes.any,
  loading: PropTypes.any,
  ui: PropTypes.any,
};

// Note: Even if payments is a radio-button like component, it doesn't has a form inside, to achieve scalability we manage everything with redux
