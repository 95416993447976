import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const BalancesTable = lazy(() => import('./BalancesTable'));
const DeclarationsTable = lazy(() => import('./DeclarationsTable'));
const TransactionsTable = lazy(() => import('./TransactionsTable'));

const BalancesRoutes = [
  {
    path: 'declarations-balance',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <Navigate replace to="declarations" />,
      },
      {
        path: 'declarations',
        element: <DeclarationsTable />,
      },
      {
        path: 'declaration/:declarationId/balances',
        element: <BalancesTable />,
      },
      {
        path: 'declaration/:declarationId/transactions',
        element: <TransactionsTable />,
      },
    ],
  },
  {
    path: 'declarations-balance/balances',
    element: <BalancesTable />,
  },
];

export default BalancesRoutes;
