import React from 'react';
import { CardTitle } from 'reactstrap';
import { usePaymentMethods } from '@hooks';
import PropTypes from 'prop-types';
import { Loader, PaymentMethods, AddCardButton } from '@components';
import BankCard from '@components/Payment/BankCard';

function PaymentCards({ cardTitle = '', filter = false }) {
  const { ui, loading } = usePaymentMethods();

  return (
    <Loader loading={loading}>
      {cardTitle && <CardTitle tag="h4">{cardTitle}</CardTitle>}
      {ui.payment_methods_toogle ? (
        <div>
          <PaymentMethods name="payments" filter={filter} />
          <AddCardButton />
        </div>
      ) : (
        <BankCard />
      )}
    </Loader>
  );
}

PaymentCards.propTypes = {
  cardTitle: PropTypes.any,
};

export default PaymentCards;
