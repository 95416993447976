import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import axios from 'axios';
import { postFeedbackSuccess, postFeedbackFailure } from '../actions';

export default function* ({ payload: { feedback } }) {
  try {
    const response = yield call(
      axios.post,
      'https://hooks.zapier.com/hooks/catch/6665294/okyu5b1/silent/',
      JSON.stringify(feedback)
    );

    yield call(StatusHandler, {
      response,
      title: 'Retroalimentación enviada',
      message: 'La retroalimentación se ha enviado con éxito.',
      status: response.status,
      success: postFeedbackSuccess,
      failure: postFeedbackFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: postFeedbackFailure,
      showBodyErrors: true,
    });
  }
}
