import { ComponentProps, useMemo } from 'react';
import { useWindowSize } from 'rooks';
import { HelpCircleLineIcon } from '@konta/icons';
import {
  BarSeries,
  Flex,
  HoverCard,
  Text,
  Select,
  Box,
  useMediaQuery,
} from '@konta/ui';
import toCurrency from '@util/toCurrency';
import Icon from 'shared/components/Icon';
import type {
  AccumulatedIncomeAndExpenseEntriesFilters,
  ReactSelectItemProps,
} from 'types/entities';
import Loader from 'shared/components/Loader/Loader';

interface GraphSeriesProps {
  dataSet: ComponentProps<typeof BarSeries>[`dataSet`];
  id: string;
  margin?: { top: number; right: number; bottom: number; left: number };
  total?: number;
  textTitleHover?: string;
  title?: string;
  filterProps?: {
    options: ReactSelectItemProps<AccumulatedIncomeAndExpenseEntriesFilters>[];
    value: boolean;
    onChangeFilter: (filter: unknown) => void;
  };
  tooltip?: ComponentProps<typeof BarSeries>[`tooltip`];
  onChangeFilter?: (filter: string) => void;
  series?: 'area' | 'line' | 'none';
  yNumberTicks?: number;
  height?: number;
  paddingInner?: number;
  nTicks?: number;
  onlyGraph?: boolean;
  loader?: boolean;
}

export default function GraphSeries({
  dataSet,
  id,
  margin = { top: 5, right: 0, bottom: 43, left: 55 },
  total = 0,
  textTitleHover,
  title,
  filterProps,
  tooltip,
  series = 'area',
  yNumberTicks = 6,
  height = 270,
  paddingInner = 0.4,
  nTicks = 6,
  onlyGraph,
  loader,
}: GraphSeriesProps) {
  const { outerWidth } = useWindowSize();
  const isDesktop = useMediaQuery('(min-width: 1512px)');

  const width = useMemo(() => {
    if (isDesktop || !outerWidth) {
      return undefined;
    }

    if (outerWidth <= 991) {
      return outerWidth - 50;
    }

    return undefined;
  }, [isDesktop, outerWidth]);

  if (loader) {
    return (
      <Flex
        css={{
          flex: 1,
          overflow: 'auto',
          gap: 10,
          flexDirection: 'column',
        }}
      >
        <Loader />
      </Flex>
    );
  }

  return (
    <Flex
      css={{
        flex: 1,
        overflow: 'auto',
        gap: 10,
        flexDirection: 'column',
      }}
    >
      {!onlyGraph && (
        <Flex column>
          <Flex itemsCenter justify="between">
            <Flex gap={6} itemsCenter>
              <Text color="gray700" medium s lineHeight="s">
                {title}
              </Text>
              {!!textTitleHover && (
                <HoverCard
                  side="top"
                  openDelay={0}
                  triggerElement={
                    <Icon tabIndex={-1} role="button">
                      <HelpCircleLineIcon />
                    </Icon>
                  }
                >
                  {textTitleHover}
                </HoverCard>
              )}
            </Flex>
            {!!filterProps && (
              <Flex column itemsCenter>
                <Box css={{ height: '$4' }} />
                <Select
                  onChange={(newValue) => {
                    if (filterProps?.onChangeFilter) {
                      filterProps.onChangeFilter(newValue);
                    }
                  }}
                  size="xs"
                  options={filterProps.options}
                  value={filterProps.options.find(
                    (opt) => opt.value === filterProps.value,
                  )}
                />
                <Box css={{ height: '$4' }} />
              </Flex>
            )}
          </Flex>
          <Text color="gray900" medium l lineHeight="l">
            {toCurrency(+total)} MXN
          </Text>
        </Flex>
      )}
      <BarSeries
        tooltip={tooltip}
        series={series}
        yNumberTicks={yNumberTicks}
        margin={margin}
        showTooltip
        dataSet={dataSet}
        height={height}
        id={id}
        width={width}
        paddingInner={paddingInner}
        nTicks={nTicks}
        snapTooltipToDatumX
        snapTooltipToDatumY
        showVerticalCross
      />
    </Flex>
  );
}
