import toCurrency from '@util/toCurrency';
import { CalculateDeclarationsSummary } from './calculateDeclarationsSummary';

export default function generateRegimeBalanceRows(
  declarationSummary: CalculateDeclarationsSummary,
) {
  return [
    {
      key: 'ivaInFavorOfPreviousPeriods',
      label: `IVA a favor de meses anteriores`,
      amount: `${toCurrency(
        +declarationSummary.taxesBalance.ivaInFavorOfPreviousPeriods,
      )} MXN`,
    },
    {
      key: 'currentPeriodIvaResult',
      label: `Resultado de IVA del periodo actual`,
      amount: `${toCurrency(
        +declarationSummary.taxesBalance.currentPeriodIvaResult,
      )} MXN`,
    },
    {
      key: 'totalToPayIva',
      label: `Total a pagar de IVA`,
      amount: `${toCurrency(
        +declarationSummary.taxesBalance.totalToPayIva,
      )} MXN`,
    },
    {
      key: 'isrInFavorOfPreviousPeriods',
      label: `ISR a favor acumulado`,
      amount: `${toCurrency(
        +declarationSummary.taxesBalance.isrInFavorOfPreviousPeriods,
      )} MXN`,
    },
    {
      key: 'currentPeriodIsrResult',
      label: `Resultado de ISR de este período`,
      amount: `${toCurrency(
        +declarationSummary.taxesBalance.currentPeriodIsrResult,
      )} MXN`,
    },
    {
      key: 'totalToPayIsr',
      label: `Total a pagar de ISR`,
      amount: `${toCurrency(
        +declarationSummary.taxesBalance.totalToPayIsr,
      )} MXN`,
    },
    {
      key: 'totalLateFees',
      label: `Recargos`,
      amount: `${toCurrency(
        +declarationSummary.taxesBalance.totalLateFees,
      )} MXN`,
    },
    {
      key: 'totalTaxesToPay',
      label: `Total a pagar de impuesto`,
      amount: `${toCurrency(
        +declarationSummary.taxesBalance.totalTaxesToPay +
          +declarationSummary.taxesBalance.totalLateFees,
      )} MXN`,
      isBold: true,
    },
  ];
}
