import { Flex } from '@konta/ui';
import toCurrency from '@util/toCurrency';
import { cellAmountCss, footerCellAmountCss } from './styled';

interface CurrencyCellProps {
  value: number;
  isFooter?: boolean;
}
export function CurrencyCell({ value, isFooter }: CurrencyCellProps) {
  return (
    <Flex justify="end" css={isFooter ? footerCellAmountCss : cellAmountCss}>
      {toCurrency(value)}
    </Flex>
  );
}
