import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { getMonthAndYearDate } from '@util/Utils';
import toCurrency from '@util/toCurrency';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import WorkflowLayout from '@components/WorkflowLayout';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import PrevPhaseButton from '@components/PrevPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import FormikTextField from '@components/FormikTextField';
import FormikOptionsSelect from '@components/FormikOptionsSelect';
import { CardOption } from '@konta/ui';
import { Collapse, Row } from 'reactstrap';
import useNextPhase from '@hooks/useNextPhase';
import { Colxx, NotificationManager } from '@components';
import usePutDeclaration from 'shared/hooks/usePutDeclaration';
import style from './NotPlatformIncome.module.scss';

const validationSchema = yup.object().shape({
  // hasIncome: yup.boolean().required(),
  hasIncome: yup.array(yup.boolean().required()).required(),
  totalOfExtras: yup
    .number()
    .min(0, 'No puede ser negativo')
    .when('hasIncome[0]', {
      is: true,
      then: yup.number().required('Agrega el monto de ingresos que obtuviste'),
    }),
  extraZeroBases: yup.number().min(0, 'No puede ser negativo'),
});

export default function NotPlatformIncome() {
  const nextPhase = useNextPhase();
  const { workflow } = useSelectedWorkflow();
  const { declaration_income, iva_rate, id } =
    workflow?.active_declaration || {};
  const putDeclaration = usePutDeclaration(id);

  const defaultWithExtra =
    declaration_income.total_of_extras > 0 ||
    declaration_income.extra_zero_bases > 0;
  const [hasExtraIncomes, setHasExtraIncomes] = useState([defaultWithExtra]);

  const onSubmit = async (values) => {
    const { totalOfExtras, extraZeroBases } = values;
    let payload = {
      declaration: {
        declaration_income_attributes: {
          total_of_extras: 0,
          extra_zero_bases: 0,
        },
      },
    };
    if (hasExtraIncomes[0]) {
      payload = {
        declaration: {
          declaration_income_attributes: {
            total_of_extras: totalOfExtras || 0,
            extra_zero_bases: extraZeroBases || 0,
          },
        },
      };
    }
    try {
      await putDeclaration.mutateAsync(payload);
      nextPhase({
        workflow: {
          next_phase: 'primary_phase',
        },
      });
    } catch (error) {
      NotificationManager.error(
        'No se pudo actualizar la declaración',
        'Error al actualizar',
      );
    }
  };
  const initialValues = {
    hasIncome: [false],
    totalOfExtras:
      declaration_income?.total_of_extras > 0
        ? declaration_income?.total_of_extras
        : '',
    extraZeroBases:
      declaration_income?.extra_zero_bases > 0
        ? declaration_income?.extra_zero_bases
        : '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (iva_rate) {
      formik.setFieldValue('iva', {
        label: `IVA ${iva_rate * 100}%`,
        value: Number(iva_rate),
        key: 3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iva_rate]);

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Captura tus ingresos fuera de las constancias"
          description="Se deben declarar todos los ingresos por ventas obtenidas en el mes y que hayan ingresado a su cuenta de banco personal."
        />
      }
      actions={
        <>
          <PrevPhaseButton loading={putDeclaration.isLoading} />
          <PrimaryPhaseButton
            loading={putDeclaration.isLoading}
            onClick={formik.submitForm}
          />
        </>
      }
    >
      <div>
        <h1>
          {'Según tu constancia de retención del mes de '}
          {getMonthAndYearDate(new Date(workflow.start_date))}
          {!!declaration_income && (
            <>
              {', se obtuvieron ingresos por ventas de '}
              {toCurrency(+declaration_income.total_of_constancies)}.
            </>
          )}
        </h1>
        <h1>
          ¿Obtuviste más ingresos por ventas fuera de las plataformas digitales?
          (No considerar ingresos por sueldos y salarios / empleo formal)
        </h1>
        <p className="m-0 text-muted">
          Ejemplo: Le vendí uno de mis productos a un conocido y me pagó 116
          pesos (IVA Incluido) en efectivo.
        </p>
      </div>
      <FormikProvider value={formik}>
        <div className={style.options}>
          <FormikOptionsSelect
            name="hasIncome"
            noEmpty
            setHasExtraIncomes={setHasExtraIncomes}
            hasExtraIncomes={hasExtraIncomes}
          >
            <CardOption
              value={false}
              header={
                <span data-testid="has-no-income-option">
                  No obtuve otros ingresos
                </span>
              }
            />
            <CardOption
              value
              header={
                <span data-testid="has-income-option">
                  Si obtuve más ingresos por ventas
                </span>
              }
            />
          </FormikOptionsSelect>
        </div>

        <Collapse isOpen={hasExtraIncomes[0]}>
          <Row>
            <Colxx xxs={12} md={6}>
              <FormikTextField
                name="totalOfExtras"
                data-testid="totalOfExtras"
                type="number"
                label="Monto de ingresos con IVA"
                placeholder="Ej. 200"
              />
            </Colxx>

            <Colxx xxs={12} md={6}>
              <FormikTextField
                name="extraZeroBases"
                data-testid="extraZeroBases"
                type="number"
                label="Monto de ingresos extra con el 0% de IVA"
                placeholder="Ej. 200"
              />
            </Colxx>
          </Row>
        </Collapse>
      </FormikProvider>
    </WorkflowLayout>
  );
}
