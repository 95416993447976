import useUploadEntryFile from 'shared/hooks/useUploadEntryFile';

interface UseUploadConstancyModalProps {
  toggleOpenUploadIncomes: () => void;
}
export default function useUploadConstancyModal({
  toggleOpenUploadIncomes,
}: UseUploadConstancyModalProps) {
  const {
    isLoading,
    toggleShowConfirmation,
    showConfirmation,
    xmlFiles,
    setXmlFiles,
    handleOnSubmit,
    activeDeclarations,
    workflow,
    currentRegimes,
    selectRegimeView,
    setSelectRegimeView,
    regimeOptionsWithDeclarationId,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  } = useUploadEntryFile({
    toggleOpenUploadModal: toggleOpenUploadIncomes,
    source_type: 'Constancy',
  });

  return {
    isLoading,
    toggleShowConfirmation,
    showConfirmation,
    xmlFiles,
    setXmlFiles,
    handleOnSubmit,
    activeDeclarations,
    workflow,
    currentRegimes,
    selectRegimeView,
    setSelectRegimeView,
    regimeOptionsWithDeclarationId,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  };
}
