import { NotificationManager } from '@components/Notifications';
import { put } from 'redux-saga/effects';
import C from '@constants/httpStatus';

export default function* ({
  title = 'Exíto...',
  message = 'La solicitud de procesó correctamente...',
  response = { status: 200 },
  status = null,
  TIMEOUT = 6000,
  PRIORITY = null,
  CLASSNAME = null,
  success = null,
  failure = null,
}) {
  if (response.data.debug_message) {
    // if Error with format decapricated ("error con formato")
    status = response.data.status;
  }
  // TODO: More specific Success Handler
  if (status >= C.OK && status < C.REDIRECTION) {
    if (success) {
      yield put(success(response.data));
    }
    (message || title) &&
      NotificationManager.success(message, title, TIMEOUT, PRIORITY, CLASSNAME);
  } else {
    switch (status) {
      case C.NETWORK:
        title = `Error de red ${status}`;
        message = 'Verifique su conexión a internet e intentelo de nuevo.';
        break;
      case C.UNPROCESSABLE_ENTITY:
        title = `Error en el servidor ${status}`;
        message = 'Por favor verifique los datos y vuelva a intentar.';
        break;
      case C.NOT_FOUND:
        message = 'No se encontró el recurso en el sistema';
        break;
      default:
        title = `Error en el sistema ${status}`;
        message = 'Por favor contacte a su contador';
        break;
    }
    if (response.data.debug_message) {
      // if Error with format decapricated ("error con formato")
      message =
        response.data.message || response.data.errors || response.data.error; // if Error with format decapricated ("error con formato")
    }
    (message || title) &&
      NotificationManager.error(message, title, TIMEOUT, PRIORITY, CLASSNAME);
    if (failure) {
      yield put(failure());
    }
  }
}
