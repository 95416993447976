import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Collapse, Nav, NavItem } from 'reactstrap';
// import UserFirstSteps from '@components/UserFirstSteps';
import IntlMessages from '@util/IntlMessages';
import useFeature from '@hooks/useFeature';
import { isPathOnCurrentPageId } from '@util';
import { ARREARS_V1, REPORTS_PAGE } from '@constants/featureFlags';
import {
  CalendarLineIcon,
  Column01Up1Icon,
  FolderLineIcon,
  File02LineIcon,
  PlayIcon,
  LineChartUp02LineIcon,
  CalendarCheck02LineIcon,
  Speedometer02LineIcon,
  PresentationChart02LineIcon,
} from '@konta/icons';

const featureFlagConfig = {
  source: 'SidebarNavMenu',
};

export default function SidebarNavMenu({
  toogleMobileMenu,
  isMobile,
  isRejected,
  pending_workflows,
}) {
  const location = useLocation();
  const [collapsedMenus, setCollapsedMenus] = useState([]);
  const [reportsPageEnabled] = useFeature(REPORTS_PAGE, featureFlagConfig);
  const [arrearsV1Enabled] = useFeature(ARREARS_V1, featureFlagConfig);

  useEffect(() => {
    sidebarMenuModules.forEach((sidebarMenuModule, index) => {
      if (location.pathname.includes(sidebarMenuModule.id)) {
        toggleMenuCollapse(`${sidebarMenuModule.id}_${index}`);
      }
    });
  }, []);

  const declarationSubMenus = [
    {
      id: 'declaraciones',
      label: 'workflows.tabs.summary',
      to: '/app/declaraciones',
    },
    {
      id: 'anuales',
      label: 'workflows.tabs.annuals',
      to: '/app/declaraciones/anuales',
    },
  ];

  let sidebarMenuModules = isRejected
    ? [
        {
          id: 'pendientes',
          icon: File02LineIcon,
          label: 'menu.pendientes',
          to: '/app/pendientes',
          counter: null,
        },
        {
          id: 'invoicing',
          icon: LineChartUp02LineIcon,
          label: 'menu.invoicing',
          to: '/app/invoicing',
          counter: null,
          subMenus: [
            {
              id: 'invoicing',
              label: 'invoicing.tabs.invoicing',
              to: '/app/invoicing',
            },
            {
              id: 'contacts',
              label: 'invoicing.tabs.contacts',
              to: '/app/invoicing/contacts',
            },
            {
              id: 'products',
              label: 'invoicing.tabs.products',
              to: '/app/invoicing/products',
            },
          ],
        },
        ...(arrearsV1Enabled
          ? [
              {
                id: 'declaraciones',
                icon: CalendarLineIcon,
                label: 'menu.declaraciones',
                to: '/app/declaraciones',
                counter:
                  pending_workflows.length > 0
                    ? pending_workflows.length
                    : null,
                subMenus: declarationSubMenus,
              },
            ]
          : []),
        {
          id: 'reports',
          icon: Column01Up1Icon,
          label: 'menu.reports',
          to: '/app/reports',
          counter: null,
        },

        {
          id: 'documents',
          icon: FolderLineIcon,
          label: 'menu.documents',
          to: '/app/documents',
          counter: null,
        },
        {
          id: 'annuals',
          icon: CalendarLineIcon,
          label: 'menu.declaraciones.anuales',
          to: '/app/declaraciones/anuales',
        },
        {
          id: 'advisory-channels',
          icon: CalendarCheck02LineIcon,
          label: 'menu.advisory-channels',
          to: '/app/advisory-channels',
        },
      ]
    : [
        {
          id: 'pendientes',
          icon: File02LineIcon,
          label: 'menu.pendientes',
          to: '/app/pendientes',
          counter: null,
        },
        {
          id: 'invoicing',
          icon: LineChartUp02LineIcon,
          label: 'menu.invoicing',
          to: '/app/invoicing',
          counter: null,
          subMenus: [
            {
              id: 'invoicing',
              label: 'invoicing.tabs.invoicing',
              to: '/app/invoicing',
            },
            {
              id: 'contacts',
              label: 'invoicing.tabs.contacts',
              to: '/app/invoicing/contacts',
            },
            {
              id: 'products',
              label: 'invoicing.tabs.products',
              to: '/app/invoicing/products',
            },
          ],
        },
        {
          id: 'declaraciones',
          icon: CalendarLineIcon,
          label: 'menu.declaraciones',
          to: '/app/declaraciones',
          counter:
            pending_workflows.length > 0 ? pending_workflows.length : null,
          subMenus: declarationSubMenus,
        },
        {
          id: 'advisory-channels',
          icon: CalendarCheck02LineIcon,
          label: 'menu.advisory-channels',
          to: '/app/advisory-channels',
        },

        {
          id: 'documents',
          icon: FolderLineIcon,
          label: 'menu.documents',
          to: '/app/documents',
          counter: null,
        },
      ];

  sidebarMenuModules.unshift({
    id: 'information-dashboard',
    icon: Speedometer02LineIcon,
    label: 'menu.information-dashboard',
    to: '/app/information-dashboard',
  });
  if (reportsPageEnabled && !isRejected) {
    sidebarMenuModules.push({
      id: 'new-reports',
      icon: PresentationChart02LineIcon,
      label: 'menu.new-reports',
      to: '/app/new-reports',
    });
  }

  const toggleMenuCollapse = (menuKey) => {
    if (collapsedMenus.indexOf(menuKey) > -1) {
      setCollapsedMenus(collapsedMenus.filter((x) => x !== menuKey));
    } else {
      setCollapsedMenus([...collapsedMenus, menuKey]);
    }
    return false;
  };

  if (isMobile) {
    return (
      <div className="sidebar-nav-menu-with-collapse">
        {sidebarMenuModules &&
          sidebarMenuModules.map((sidebarMenuModule, index) => {
            const SidebarMenuModuleIcon = sidebarMenuModule.icon;
            return (
              <div key={`${sidebarMenuModule.id}_${index}`}>
                {sidebarMenuModule.subMenus &&
                sidebarMenuModule.subMenus.length > 0 ? (
                  <>
                    <span
                      className={`sidebar-nav-link-container sidebar-text rotate-arrow-icon ${
                        collapsedMenus.indexOf(
                          `${sidebarMenuModule.id}_${index}`,
                        ) === -1
                          ? 'collapsed'
                          : ''
                      }`}
                      onClick={(e) =>
                        toggleMenuCollapse(`${sidebarMenuModule.id}_${index}`)
                      }
                    >
                      <PlayIcon className="sidebar-nav-link-collapse-icon" />
                      <div className="sidebar-nav-link">
                        <IntlMessages id={sidebarMenuModule.label} />
                      </div>
                    </span>
                    <Collapse
                      isOpen={
                        !(
                          collapsedMenus.indexOf(
                            `${sidebarMenuModule.id}_${index}`,
                          ) === -1
                        )
                      }
                    >
                      <Nav>
                        {sidebarMenuModule.subMenus &&
                          sidebarMenuModule.subMenus.map(
                            (subMenu, subIndex) => {
                              return (
                                <NavItem
                                  key={`${subMenu.id}_${index}_${subIndex}`}
                                  className={`${
                                    isPathOnCurrentPageId(subMenu.id, location)
                                      ? 'sidebar-nav-link-active'
                                      : ''
                                  }`}
                                >
                                  <NavLink
                                    to={subMenu.to}
                                    onClick={toogleMobileMenu}
                                    data-flag="tasks"
                                  >
                                    <IntlMessages id={subMenu.label} />
                                  </NavLink>
                                </NavItem>
                              );
                            },
                          )}
                      </Nav>
                    </Collapse>
                  </>
                ) : (
                  <NavLink to={sidebarMenuModule.to} onClick={toogleMobileMenu}>
                    <span
                      className={`sidebar-nav-link-container sidebar-text ${
                        location.pathname.includes(sidebarMenuModule.id)
                          ? 'sidebar-nav-link-active'
                          : ''
                      }`}
                    >
                      <SidebarMenuModuleIcon className="sidebar-nav-link-icon" />
                      <div className="sidebar-nav-link">
                        <IntlMessages id={sidebarMenuModule.label} />
                      </div>
                    </span>
                  </NavLink>
                )}
              </div>
            );
          })}
        {/* <UserFirstSteps /> */}
      </div>
    );
  }

  return (
    <>
      {sidebarMenuModules &&
        sidebarMenuModules.map((sidebarMenuModule, index) => {
          const SidebarMenuModuleIcon = sidebarMenuModule.icon;
          return (
            <NavLink
              to={sidebarMenuModule.to}
              onClick={toogleMobileMenu}
              key={`${sidebarMenuModule.id}_${index}`}
            >
              <span
                className={`sidebar-nav-link-container sidebar-text ${
                  location.pathname.includes(sidebarMenuModule.id)
                    ? 'sidebar-nav-link-active'
                    : ''
                }`}
              >
                <SidebarMenuModuleIcon className="sidebar-nav-link-icon" />
                <div className="sidebar-nav-link">
                  <IntlMessages id={sidebarMenuModule.label} />

                  {sidebarMenuModule.counter && (
                    <div className="sidebar-nav-link-counter">
                      {sidebarMenuModule.counter}
                    </div>
                  )}
                </div>
              </span>
            </NavLink>
          );
        })}
      {/* <UserFirstSteps /> */}
    </>
  );
}
