import {
  LOGIN_USER_SUCCESS,
  REGISTER_USER_SUCCESS,
  SUBSCRIBE_SUCCESS,
  ORDERS_FETCH_SUCCESS,
  ORDER_PAY_SUCCESS,
  USER_FETCH_SUCCESS,
  USER_UPDATE_SUCCESS,
  TAXABLE_ENTITY_UPDATE,
  TAXABLE_ENTITY_UPDATE_SUCCESS,
  TAXABLE_ENTITY_UPDATE_FAILURE,
  TAXABLE_ENTITY_FIEL_UPLOAD,
  TAXABLE_ENTITY_FIEL_UPLOAD_SUCCESS,
  TAXABLE_ENTITY_FIEL_UPLOAD_FAILURE,
  TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD,
  TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_SUCCESS,
  TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_FAILURE,
  TAXABLE_ENTITY_CSD_UPLOAD,
  TAXABLE_ENTITY_CSD_UPLOAD_SUCCESS,
  TAXABLE_ENTITY_CSD_UPLOAD_FAILURE,
  TAXABLE_ENTITY_CREDENTIALS_FETCH,
  TAXABLE_ENTITY_CREDENTIALS_FETCH_SUCCESS,
  TAXABLE_ENTITY_CREDENTIALS_FETCH_FAILURE,
  TAXABLE_ENTITY_FETCH,
  TAXABLE_ENTITY_FETCH_FAILURE,
  TAXABLE_ENTITY_FETCH_SUCCESS,
} from '@constants/actionTypes';

const INIT_STATE = {
  taxable_entity: null,
  loading: false,
  error: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TAXABLE_ENTITY_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        taxable_entity: action.payload,
      };
    case LOGIN_USER_SUCCESS:
    case REGISTER_USER_SUCCESS:
    case USER_FETCH_SUCCESS:
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        taxable_entity: action.payload.taxable_entity,
      };
    case TAXABLE_ENTITY_UPDATE_SUCCESS:
    case TAXABLE_ENTITY_FIEL_UPLOAD_SUCCESS:
    case TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_SUCCESS:
    case TAXABLE_ENTITY_CSD_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        taxable_entity: action.payload,
        error: false,
      };
    case TAXABLE_ENTITY_CREDENTIALS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        taxable_entity: { ...state.taxable_entity, ...action.payload },
      };
    case SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        taxable_entity: {
          ...state.taxable_entity,
          ...action.payload[0].taxable_entity,
        },
      };
    case ORDERS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        taxable_entity: {
          ...state.taxable_entity,
          ...action.payload[0]?.taxable_entity,
        },
      };
    case ORDER_PAY_SUCCESS:
      return {
        ...state,
        loading: false,
        taxable_entity: {
          ...state.taxable_entity,
          ...action.payload?.taxable_entity,
        },
      };
    case TAXABLE_ENTITY_FETCH:
    case TAXABLE_ENTITY_UPDATE:
    case TAXABLE_ENTITY_FIEL_UPLOAD:
    case TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD:
    case TAXABLE_ENTITY_CSD_UPLOAD:
    case TAXABLE_ENTITY_CREDENTIALS_FETCH:
      return { ...state, loading: true, error: false };
    case TAXABLE_ENTITY_FETCH_FAILURE:
    case TAXABLE_ENTITY_FIEL_UPLOAD_FAILURE:
    case TAXABLE_ENTITY_UPDATE_FAILURE:
    case TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_FAILURE:
    case TAXABLE_ENTITY_CSD_UPLOAD_FAILURE:
    case TAXABLE_ENTITY_CREDENTIALS_FETCH_FAILURE:
      return { ...state, loading: false, error: true };
    default:
      return { ...state };
  }
};
