import React, { useEffect, useState } from 'react';
import { Colxx } from '@components/CustomBootstrap';
import { Link } from 'react-router-dom';
import { Row, Badge, Button } from 'reactstrap';
import { Flex, HoverCard, Text } from '@konta/ui';
import { toCurrency as $, getDateObjectWithFormat } from '@util/Utils';
import { useDispatch } from 'react-redux';
import { addOrdersToCart } from '@redux/actions';

// eslint-disable-next-line import/prefer-default-export
export function OrdersRow({ orders }) {
  const dispatch = useDispatch();
  const today = new Date();
  const limitDate = new Date(orders.created_at);
  const [isTaxArrear, setIsTaxArrear] = useState(false);
  limitDate.setDate(limitDate.getDate() + 6);

  useEffect(() => {
    if (orders.tax_arrears && orders.tax_arrears.length > 0) {
      if (orders.tax_arrears[0].product_type === 'Declaration')
        setIsTaxArrear(true);
    }
  }, [orders.tax_arrears]);
  let orderType = '';
  if (orders.tax_arrears && orders.tax_arrears.length > 0) {
    const { product_type } = orders.tax_arrears[0];
    orderType =
      {
        Declaration: 'Declaración anual',
        TaxArrear: 'Servicio de declaración',
      }[product_type] || 'Servicio de declaración';
  } else {
    orderType = {
      Subscription: 'Suscripción',
      TaxArrear: 'Servicio de declaración',
    }[orders.source_type];
  }

  return (
    <Row className="my-2 py-3 px-5 align-items-center row-outline mx-auto">
      <Colxx xxs="2" lg="2" className="my-auto">
        <span className="align-middle d-inline-block order-price text-muted">
          {$(orders.total - orders.total_paid)}
        </span>
      </Colxx>
      <Colxx className="d-none d-lg-flex" lg="2">
        <Badge
          pill
          color={
            { Subscription: 'outline-primary', TaxArrear: 'outline-warning' }[
              orders.source_type
            ]
          }
          className="cursor-default"
        >
          {orderType}
        </Badge>
      </Colxx>
      <Colxx className="d-none d-lg-flex" lg="3">
        <span
          className={`align-middle d-inline-block text-muted ${
            today > limitDate && 'exceeded-date'
          }`}
        >
          {getDateObjectWithFormat(new Date(limitDate))}
        </span>
      </Colxx>
      <Colxx className="d-none d-lg-flex" lg="2">
        {isTaxArrear &&
          (orders?.tax_arrears[0]?.arrear_months.length > 1 ? (
            <HoverCard
              openDelay={0}
              triggerElement={
                <Flex tabIndex={-1} role="button">
                  <Text
                    css={{
                      width: '100px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Meses: {orders.tax_arrears[0]?.arrear_months}
                  </Text>
                </Flex>
              }
            >
              <Flex column>
                {orders.tax_arrears[0].arrear_months.map((month, index) => (
                  <Text
                    key={index}
                    css={{
                      lineBreak: 'anywhere',
                    }}
                  >
                    {month}
                  </Text>
                ))}
              </Flex>
            </HoverCard>
          ) : (
            <span className="align-middle d-inline-block ">
              Año:{' '}
              {orders.tax_arrears && orders.tax_arrears[0].arrear_months[0]}
            </span>
          ))}
      </Colxx>

      <Colxx xxs="2" lg="2" className="text-right">
        <Link
          to={{
            pathname: '/app/checkout',
          }}
          state={{ order_id: orders.id }}
        >
          <Button
            color="link"
            onClick={() => dispatch(addOrdersToCart([orders]))}
          >
            Pagar
          </Button>
        </Link>
      </Colxx>
    </Row>
  );
}
