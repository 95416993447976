import { StateCreator } from 'zustand';
import { ICustomerSlice, IStoreState } from 'backoffice/store/types';

const initialState = {
  previousTaxableEntities: [],
  customerHeaderCollapsed: false,
  customersTableFilters: {
    name: '',
    taxable_entity_id: '',
  },
  fromComponent: 'customers' as const,
};

const createCustomerSlice: StateCreator<IStoreState, [], [], ICustomerSlice> = (
  set,
) => ({
  ...initialState,
  setPreviousTaxableEntities: (taxableEntities) =>
    set({ previousTaxableEntities: taxableEntities }),
  toggleCustomerHeaderCollapsed: () =>
    set((state) => ({
      customerHeaderCollapsed: !state.customerHeaderCollapsed,
    })),
  setCustomersTableFilters: (filters: Record<string, string>) =>
    set((state) => ({
      customersTableFilters: {
        ...state.customersTableFilters,
        ...filters,
      },
    })),
  setFromComponent: (fromComponent) => set({ fromComponent }),
});

export default createCustomerSlice;
