import { Button, Flex, Icon, Overlay } from '@konta/ui';
import CloseIcon from 'mdi-react/CloseIcon';
import { useCallback, useEffect, useRef } from 'react';
import {
  IconTitleCss,
  MobileHandle,
  SidebarContainer,
  SidebarHeaderContainer,
  Title,
} from './styled';
import { useEffectOnceWhen } from 'rooks';

interface SidebarProps {
  children?: React.ReactNode;
  headerIcon?: React.ReactNode;
  headerTitle?: React.ReactNode;
  isOpen: boolean;
  toggleIsOpen: () => void;
  withOverlay?: boolean;
  isMobile?: boolean;
}

export default function Sidebar({
  children,
  headerIcon,
  headerTitle,
  withOverlay,
  isOpen,
  toggleIsOpen,
  isMobile = false,
}: SidebarProps) {
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        toggleIsOpen();
      }
    };

    if (isOpen && isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (isOpen && isMobile) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [isOpen, isMobile, toggleIsOpen]);

  return (
    <>
      {withOverlay && isOpen && <Overlay onClick={toggleIsOpen} />}

      <SidebarContainer
        ref={sidebarRef}
        isMobile={isMobile}
        className={isOpen ? 'open' : 'closed'}
      >
        <Flex justify="between">
          <SidebarHeaderContainer>
            {isMobile && (
              <Flex
                css={{ cursor: 'pointer', width: '100%' }}
                onClick={toggleIsOpen}
              >
                <MobileHandle />
              </Flex>
            )}
            <Flex
              align="center"
              justify="center"
              gap={10}
              css={{ alignSelf: 'flex-start' }}
            >
              {headerIcon && (
                <Icon notFill css={IconTitleCss}>
                  {headerIcon}
                </Icon>
              )}
              {headerTitle && <Title>{headerTitle}</Title>}
            </Flex>
          </SidebarHeaderContainer>
          {!isMobile && (
            <Button icon variant="text" onClick={toggleIsOpen}>
              <CloseIcon />
            </Button>
          )}
        </Flex>
        <Flex>{children}</Flex>
      </SidebarContainer>
    </>
  );
}
