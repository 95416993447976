import React from 'react';
import IntlMessages from '@util/IntlMessages';
import { paymentMethodsToogle } from '@redux/actions';
import { useDispatch } from 'react-redux';
import { Colxx } from '@components/CustomBootstrap';
import { Button } from '@konta/ui';
import { PlusCrFrOutlineIcon } from '@konta/icons';

export function AddCardButton() {
  const dispatch = useDispatch();
  const changeHandler = () => dispatch(paymentMethodsToogle());
  return (
    <Colxx className="d-flex justify-content-center align-items-center p-3">
      <Button
        color="primary"
        variant="outlined"
        onClick={changeHandler}
        rightIcon={<PlusCrFrOutlineIcon />}
      >
        <IntlMessages id="task.payment-methods-addcard" />
      </Button>
    </Colxx>
  );
}
