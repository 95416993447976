import React from 'react';
import { Button } from 'reactstrap';
import IntlMessages from '@util/IntlMessages';

export function ReturnButton({ handleReturn, size = 'xs', className }) {
  if (handleReturn) {
    return (
      <Button
        outline
        className={`d-flex align-items-center float-right ${className}`}
        size={size}
        color="primary"
        onClick={handleReturn}
        data-testid="return-button"
      >
        <i className="iconsmind-Back mr-1 font-weight-bold" />
        <IntlMessages id="button.return" />
      </Button>
    );
  }
  return null;
}
