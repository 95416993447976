import { StateCreator } from 'zustand';
import type { ITaxesModuleSlice, IStoreState } from 'backoffice/store/types';
import dayjs from 'dayjs';

const initialState = {
  periodFilterType: 'monthly' as const,
  periodFilterValue: dayjs()
    .startOf('month')
    .subtract(1, 'month')
    .format('YYYY-MM-DD'),
  selectedWorkflows: [],
  selectedWorkflow: null,
  selectedEntries: [],
};

const createTaxesModuleSlice: StateCreator<
  IStoreState,
  [],
  [],
  ITaxesModuleSlice
> = (set) => ({
  ...initialState,
  setPeriodFilterType: (periodFilterType) => set({ periodFilterType }),
  setPeriodFilterValue: (periodFilterValue) => set({ periodFilterValue }),
  setSelectedWorkflows: (selectedWorkflows) => set({ selectedWorkflows }),
  setSelectedWorkflow: (selectedWorkflow) => set({ selectedWorkflow }),
  setSelectedEntries: (selectedEntries) => set({ selectedEntries }),
});

export default createTaxesModuleSlice;
