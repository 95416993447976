import { useMemo, useState } from 'react';
import useClientSuppliers from 'shared/hooks/useClientSuppliers';
import useSharedTaxableEntity from 'shared/hooks/useSharedTaxableEntity';
import ClientSupplierList from '../ClientSupplierList';

export default function SuppliersPage() {
  const { taxableEntityId } = useSharedTaxableEntity();
  const { clientSuppliers, clientSuppliersLoading } = useClientSuppliers(
    taxableEntityId,
    undefined,
    {
      staleTime: 60 * 1000,
    },
  );

  const suppliers = useMemo(
    () =>
      clientSuppliers.filter(
        (clientSupplier) => clientSupplier.relationship_type === 'provider',
      ),
    [clientSuppliers],
  );

  const [modalInvoiceDetails, setModalInvoiceDetails] = useState<{
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  } | null>(null);

  const closeModalInvoiceDetails = () => setModalInvoiceDetails(null);

  return (
    <ClientSupplierList
      clientSuppliersLoading={clientSuppliersLoading}
      clientSuppliers={suppliers}
      modalInvoiceDetails={modalInvoiceDetails}
      closeModalInvoiceDetails={closeModalInvoiceDetails}
      onSelectItem={(data) => {
        setModalInvoiceDetails({
          rfc: data.rfc,
          legalName: data.legalName,
          isEmitted: false,
        });
      }}
    />
  );
}
