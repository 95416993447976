import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Button, CircularLoading, Flex, Text } from '@konta/ui';
import useBackupCodesTwoFactor from '@hooks/auth/useBackupCodesTwoFactor';

export default function BackupCodesModal({ toggle, isOpen, size }) {
  const { backupCodes, loadingBackupCodes, isError } =
    useBackupCodesTwoFactor();
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={size}>
      <ModalBody>
        <Flex direction="column">
          <Text as="h2" bold>
            Códigos de respaldo
          </Text>
          <Text as="p">
            Los códigos de recuperación se utilizan para acceder a su cuenta en
            caso de que no pueda recibir los códigos de autenticación de dos
            factores.
          </Text>
          <Alert color="warning" className="rounded-alert">
            Descargue, imprima o copie sus códigos de recuperación antes de
            continuar.
          </Alert>
          <hr />
          {loadingBackupCodes && <CircularLoading xl />}
          {backupCodes &&
            JSON.parse(backupCodes)?.map((code, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text key={`${code}-${i}`}>{code}</Text>
            ))}
          {isError && (
            <Alert>
              Ocurrió un error al generar los código de respaldo, por favor
              contacta a soporte para generarlos nuevamente.
            </Alert>
          )}
          <hr />
          <Text as="p">
            Trate sus códigos de recuperación con el mismo nivel de atención que
            prestaría a su contraseña.
          </Text>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button color="error" onClick={toggle}>
          Ya he guardado los código de recuperación en lugar seguro
        </Button>
      </ModalFooter>
    </Modal>
  );
}

BackupCodesModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  size: PropTypes.string,
};

BackupCodesModal.defaultProps = {
  toggle: null,
  size: 's',
  isOpen: false,
};
