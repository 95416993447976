import { useSelector } from 'react-redux';

export default function usePpdComplements(isWorkflow, declarationEntryPayment) {
  const cfdiComplements = useSelector((state) => {
    return state.cfdis.cfdiComplements;
  });
  if (isWorkflow) {
    return { complements: declarationEntryPayment };
  }
  return { complements: cfdiComplements };
}
