import api from '@api';
import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import {
  defaultPaymentMethodSuccess,
  defaultPaymentMethodFailure,
} from '../actions';

export default function* defaultCard({ payload: { taxable_entity, card } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `/taxable_entities/${taxable_entity.id}/payment_methods/${card.id}/default_card`,
      {},
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: defaultPaymentMethodSuccess,
      failure: defaultPaymentMethodFailure,
      title: 'Éxito',
      message: 'La tarjeta se guardó como default correctamente',
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: defaultPaymentMethodFailure,
    });
  }
}
