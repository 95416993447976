import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '@components/CustomBootstrap';

export function OrderHead(props) {
  return (
    <Row className="py-3 px-5 mx-auto bordered-row">
      <Colxx className="d-lg-flex" xxs="12" lg="2">
        Monto a pagar
      </Colxx>
      <Colxx className="d-none d-lg-flex" lg="2">
        Tipo de cargo
      </Colxx>
      <Colxx className="d-none d-lg-flex" lg="3">
        Fecha límite
      </Colxx>
      <Colxx className="d-none d-lg-flex" lg="2" />
      <Colxx xxs="6" lg="2" className="text-right" />
    </Row>
  );
}
