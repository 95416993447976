import { DialogClose, styled } from '@konta/ui';

export const BlogDialogHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$20 $40',
  borderBottom: '1px solid $gray200',
});

export const BlogDialogHeaderContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  span: {
    fontSize: '$16',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#2B2B34',
  },
});

export const BlogDialogHeaderClose = styled(DialogClose, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  p: '0',
  color: '$gray500',
  svg: {
    color: '$gray500',
    width: '16px',
    height: '16px',
  },
});

export const BlogPostTitle = styled('span', {
  color: '$gray800',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '32px',
});
export const BlogPostSummary = styled('span', {
  color: '$gray500',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
});

export const BlogPostAuthorContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-end',
  alignSelf: 'stretch',
  gap: '8px',
  p: '$16 0',
  borderTop: '1px solid $gray200',
  borderBottom: '1px solid $gray200',
  my: '$24',
  img: {
    display: 'flex',
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '200px',
  },
  'div[data-slot="title"]': {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  'span[data-slot="author"]': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
    color: '$gray900',
  },
  'span[data-slot="description"]': {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '400',
    color: '$gray500',
  },
});

export const BlogContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '$24',
  fontFamily: 'Inter',
  lineHeight: 1.6,
  color: '$gray800',
  maxWidth: '1080px',
  'h1, h2, h3, h4, h5, h6': {
    fontFamily: 'Inter',
    marginTop: '$24',
    marginBottom: '$16',
    fontWeight: 600,
    lineHeight: 1.25,
  },

  h1: {
    fontFamily: 'Inter',
    fontSize: '24px',
    borderBottom: '1px solid $gray200',
    paddingBottom: '0.3em',
  },

  h2: {
    fontFamily: 'Inter',
    fontSize: '16px',
    borderBottom: '1px solid $gray200',
    paddingBottom: '0.3em',
  },

  'p, article': {
    fontFamily: 'Inter',
    marginBottom: '$16',
    color: '$gray600',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'justify',
  },

  a: {
    color: '$primary600',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  code: {
    backgroundColor: 'rgba(27,31,35,0.05)',
    borderRadius: '$2',
    fontSize: '85%',
    margin: 0,
    padding: '0.2em 0.4em',
  },

  pre: {
    backgroundColor: '$gray100',
    borderRadius: '$2',
    fontSize: '85%',
    lineHeight: 1.45,
    overflow: 'auto',
    padding: '$16',
  },

  blockquote: {
    borderLeft: '0.25em solid $gray300',
    color: '$gray600',
    padding: '0 $16',
  },

  'ul, ol': {
    paddingLeft: '$32',
  },

  img: {
    maxWidth: '100%',
    boxSizing: 'content-box',
  },
});
