import { useNavigate } from 'react-router-dom';
import {
  Card,
  Text,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from '@konta/ui';
import { Overlay } from './styled';

export default function ExpiredCsdModal() {
  const navigate = useNavigate();
  return (
    <Overlay>
      <Card>
        <>
          <CardHeader header={<Text xl>Tu CSD ha expirado</Text>} />
          <CardContent>
            <Text>
              Antes de continuar con la declaración, es necesario actualizar tu
              CSD desde la configuración para poder timbrar tus facturas.
            </Text>
            <br />
          </CardContent>
          <CardActions gap={10} justify="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate('/app/settings/credentials');
              }}
            >
              Ir a la configuración
            </Button>
          </CardActions>
        </>
      </Card>
    </Overlay>
  );
}
