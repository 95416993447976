import React from 'react';

export default function Stepper({ activeStep, steps, setActiveTab }) {
  const renderedSteps = steps.map(({ label, completed }, index) => {
    return (
      <div
        className={`mdl-stepper-step ${completed === true && 'step-done'} ${
          activeStep === index && 'active-step'
        } `}
        key={index}
      >
        <div
          className="mdl-stepper-circle"
          onClick={() => {
            setActiveTab(String(index));
          }}
        >
          <span>-</span>
        </div>
        <p className="mdl-stepper-title text-muted">{label}</p>
        <div className="mdl-stepper-bar-left" />
        <div className="mdl-stepper-bar-right" />
      </div>
    );
  });
  return (
    <div className="mdl-card mdl-shadow--2dp">
      <div className="mdl-card__supporting-text">
        <div className="mdl-stepper-horizontal-alternative">
          {renderedSteps}
        </div>
      </div>
    </div>
  );
}
