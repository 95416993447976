import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { CoinDollarOutlineIcon } from '@konta/icons';
import { ReactComponent as OxxoIcon } from '@assets/img/payment-method/oxxo.svg';
import { ReactComponent as AmericanExpressIcon } from '@assets/img/payment-method/american_express.svg';
import { ReactComponent as CarnetIcon } from '@assets/img/payment-method/carnet.svg';
import { ReactComponent as MasterCardIcon } from '@assets/img/payment-method/mastercard.svg';
import { ReactComponent as PaypalIcon } from '@assets/img/payment-method/paypal.svg';
import { ReactComponent as SpeiIcon } from '@assets/img/payment-method/spei.svg';
import { ReactComponent as VisaIcon } from '@assets/img/payment-method/visa.svg';
import { Chip } from '@konta/ui';

const paymentIcons = {
  oxxo: OxxoIcon,
  american_express: AmericanExpressIcon,
  carnet: CarnetIcon,
  mastercard: MasterCardIcon,
  paypal: PaypalIcon,
  spei: SpeiIcon,
  visa: VisaIcon,
};

function PaymentMethodItem({
  item = {},
  name = '',
  checked = false,
  index = '',
  onChange,
}) {
  const iconName = item.brand?.toLowerCase?.();
  const PaymentIcon = paymentIcons[iconName] || CoinDollarOutlineIcon;

  return (
    <Label
      className={`payment-option__container mb-2 ${checked ? 'active' : ''}`}
    >
      <div className="payment-option__type">
        <PaymentIcon className="payment-option__image" />
      </div>
      <div className="payment-option__number">
        {item.brand !== 'spei' && item.brand !== 'oxxo'
          ? `•••• •••• •••• ${item.card_number}`
          : item.reference_subscription}
      </div>
      <div className="payment-option__name">
        {item.brand !== 'spei' && item.brand !== 'oxxo' ? item.holder_name : ''}
      </div>
      <div className="payment-option__default">
        {item.default_card && <Chip size="s" label="Por defecto" />}
      </div>
      <div className="d-none d-lg-flex pr-2 custom-radio custom-control">
        <input
          className="custom-control-input"
          type="radio"
          id={index}
          name={name}
          value={item}
          checked={checked}
          onChange={onChange}
        />
        <label className="custom-control-label" htmlFor={index} />
      </div>
    </Label>
  );
}

PaymentMethodItem.propTypes = {
  checked: PropTypes.bool,
  index: PropTypes.string,
  item: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.any,
};

export default PaymentMethodItem;
