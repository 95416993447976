import {
  FINANCIAL_TRANSACTIONS_FETCH,
  FINANCIAL_TRANSACTIONS_FETCH_SUCCESS,
  FINANCIAL_TRANSACTIONS_FETCH_FAILURE,
  UI_SET_DATE,
  UI_CHANGE_DATE,
} from '@constants/actionTypes';

export const fetchFinancialTransactions = (fiscal_period) => ({
  type: FINANCIAL_TRANSACTIONS_FETCH,
  payload: { fiscal_period },
});
export const fetchFinancialTransactionsSuccess = (financial_transactions) => ({
  type: FINANCIAL_TRANSACTIONS_FETCH_SUCCESS,
  payload: financial_transactions,
});
export const fetchFinancialTransactionsFailure = () => ({
  type: FINANCIAL_TRANSACTIONS_FETCH_FAILURE,
});
export const uiSetDate = (date) => ({
  type: UI_SET_DATE,
  payload: date,
});
export const uiChangeDate = (date) => ({
  type: UI_CHANGE_DATE,
  payload: { date },
});
