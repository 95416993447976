import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { Button } from '@konta/ui';
import getErrorMessage from '@util/getErrorMessage';
import postDiscardSuggestedReferrals from '@api/postDiscardSuggestedReferrals';
import { NotificationManager } from '@components';

const onError = (error) => {
  const message = getErrorMessage(error);
  NotificationManager.error(message, 'Error', 10000);
};

export default function DiscardSuggestedReferralButton({
  invitationId,
  onDiscard,
}) {
  const discardSuggestedReferral = useMutation(postDiscardSuggestedReferrals, {
    onSuccess: onDiscard,
    onError,
  });

  return (
    <Button
      variant="text"
      color="gray"
      size="s"
      loading={discardSuggestedReferral.isLoading}
      onClick={() => discardSuggestedReferral.mutate(invitationId)}
    >
      Descartar
    </Button>
  );
}

DiscardSuggestedReferralButton.propTypes = {
  invitationId: PropTypes.number.isRequired,
  onDiscard: PropTypes.func,
};

DiscardSuggestedReferralButton.defaultProps = {
  onDiscard: null,
};
