import { useMemo, useState } from 'react';
import getTableRowsFromDeclarationIncome from '@util/getTableRowsFromDeclarationIncomeTotals';
import useWorkflow from '../../hooks/useWorkflow';
import usePreviewOfPublicInvoice from '../../hooks/usePreviewOfPublicInvoice';

export default function useTotalsButtons() {
  const [previewData, setPreviewData] = useState<object>();
  const { activeDeclarations } = useWorkflow();
  const { getDataForPreviewByDeclaration } = usePreviewOfPublicInvoice();
  const declarationsIncomeTableTotals = useMemo(
    () =>
      activeDeclarations.map((declaration) =>
        getTableRowsFromDeclarationIncome(
          declaration,
          getDataForPreviewByDeclaration,
          setPreviewData,
        ),
      ),
    [activeDeclarations, getDataForPreviewByDeclaration],
  );

  const closePreview = () => setPreviewData(undefined);

  return {
    declarationsIncomeTableTotals,
    previewData,
    closePreview,
  };
}
