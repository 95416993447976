import { Command } from 'cmdk';
import LetterAvatar from 'react-avatar';
import { Flex, Text } from '@konta/ui';
import TextOverflow from 'shared/components/TextOverflow';
import type { ClientSupplier } from 'types/entities';

interface ItemProps {
  shortcut?: string;
  onSelect?: (value: string) => void;
  clientSupplier: ClientSupplier;
}

export default function ClientSupplierItem({
  shortcut,
  onSelect,
  clientSupplier,
}: ItemProps) {
  const { id, legal_name, rfc } = clientSupplier;
  const legalName = legal_name || '-';
  const rfcValue = rfc || '-';
  const value = `${id}-${legalName}-${rfcValue}`;
  return (
    <Command.Item onSelect={onSelect} value={value}>
      <Flex gap={12} itemsCenter>
        <span className="classify-expenses-logo-container">
          <LetterAvatar maxInitials={2} name={legalName} size="28" round />
        </span>
        <Flex column>
          <TextOverflow maxLen={395} s lineHeight="s" semibold>
            {legalName}
          </TextOverflow>
          <Text>{rfcValue}</Text>
        </Flex>
      </Flex>
      {shortcut && (
        <div cmdk-shortcuts="">
          {shortcut.split(' ').map((key) => {
            return <kbd key={key}>{key}</kbd>;
          })}
        </div>
      )}
    </Command.Item>
  );
}
