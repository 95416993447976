import { styled } from '@konta/ui';
import { ReactNode } from 'react';
import GradientBackground from 'backoffice/components/GradientBackground';

const StyledResponsiveCenterContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  py: '80px',
  width: '100%',
  px: '$30',
  '@sm': { width: '70%', px: '$48' },
  '@md': { width: '55%', px: '$48' },
  '@lg': { width: '35%', px: '$48' },
  background: 'white',
  borderRadius: '10px',
});

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

interface ResponsiveCenterContainerProps {
  children: ReactNode;
  withBackground?: boolean;
}
export default function ResponsiveCenterContainer({
  children,
  withBackground = true,
}: ResponsiveCenterContainerProps) {
  return (
    <GradientBackground withBackground={withBackground}>
      <StyledResponsiveCenterContainer>
        <Content>{children}</Content>
      </StyledResponsiveCenterContainer>
    </GradientBackground>
  );
}
