export default function getModalInvoiceData(
  rfc: string | null,
  legalName: string | null,
  isEmitted: boolean,
) {
  return {
    hasCfdiModal: !!rfc && !!legalName && rfc !== 'N/A' && legalName !== 'N/A',
    modalInvoiceDetails: {
      rfc: rfc || '',
      legalName: legalName || '',
      isEmitted,
    },
  };
}
