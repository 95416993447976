import { put, select } from 'redux-saga/effects';
import { paymentMethodsToogle } from '@redux/actions';
import { NotificationManager } from '@components/Notifications';

export default function* displayCardView() {
  try {
    const { payment_methods } = yield select((state) => state.paymentMethods);
    if (payment_methods.length) {
      yield put(paymentMethodsToogle());
    } else {
      const NO_CARDS_MESSAGE = 'Por favor, añada una tarjeta para continuar.';
      NotificationManager.primary(NO_CARDS_MESSAGE, 'Upss..', 6000, null, null);
    }
  } catch (error) {
    // catch throw
    console.error(error);
  }
}
