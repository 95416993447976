import { Id, Row } from '@silevis/reactgrid';
import { isSelectedMonth, sumGroupValues } from 'shared/util/grids';
import {
  nonEditable,
  borderLeft,
  borderRight,
  numberCell,
  showZero,
  textCell,
  noBorders,
  leftBorder,
  rightBorder,
  grayBorderTop,
  monthHeaderCell,
  borderLeftRightTop,
  noRightBorder,
  noLeftBorder,
} from 'backoffice/util/cells';
import { getEmptyRow } from 'backoffice/util/rows';
import {
  MONTHS,
  ROW_HEIGHT,
  HEADING_ROW_HEIGHT,
  EMPTY_ROW_ID,
  HEADER_ROW_ID,
} from 'shared/constants/grids';
import type { SimplifiedDataGrid } from 'types/grids';
import { AnnualDeclaration, AnnualDeclarationTotal } from 'types/entities';
import { sumBy, sum, defaultTo } from 'lodash';

function getHeaderRow(
  selectedMonth: string | null,
  lastItemName: string,
  months: string[],
  height: number,
  rowId: Id,
  title = '',
): Row {
  const cellMonths = months.map((month) => {
    const cell = monthHeaderCell(month, 'justify-content-center');
    if (isSelectedMonth(month, selectedMonth)) {
      return borderLeftRightTop(cell);
    }
    return cell;
  });
  return {
    rowId,
    height,
    cells: [
      noRightBorder(nonEditable(textCell(title, 'font-bold'))),
      noLeftBorder(
        monthHeaderCell(lastItemName, 'font-bold justify-content-end'),
      ),
      ...cellMonths,
    ],
  };
}

interface GetRowProps {
  title: string;
  values: number[];
  selectedMonth: string | null;
  isTotal?: boolean;
  cursorPointer?: boolean;
  withClickableCells?: boolean;
  withHighDash?: boolean;
  annualTotal?: number;
}

function getRow({
  title,
  values,
  selectedMonth,
  isTotal = false,
  cursorPointer = false,
  withClickableCells = false,
  withHighDash = false,
  annualTotal,
}: GetRowProps): Row {
  const cursorClass = cursorPointer ? 'cursor-pointer corner-indicator' : '';
  const cellMonths =
    values.length === 0
      ? MONTHS.map(() =>
          noBorders(nonEditable(textCell('-', 'justify-content-end'))),
        )
      : values.map((value, idx) => {
          const isCurrentMonth = isSelectedMonth(MONTHS[idx], selectedMonth);

          if (isCurrentMonth) {
            return borderRight(
              borderLeft(nonEditable(numberCell(value, cursorClass))),
            );
          }

          if (isTotal) {
            return grayBorderTop(
              noBorders(
                nonEditable(
                  showZero(numberCell(value, `font-bold ${cursorClass} `)),
                ),
              ),
            );
          }

          return noBorders(nonEditable(numberCell(value, cursorClass)));
        });

  const total = annualTotal ? +annualTotal : sumGroupValues(values);
  const tileClass = isTotal ? 'font-bold gray-border-top' : '';
  const lastClass = isTotal ? 'gray-border-top font-bold' : '';
  return {
    rowId: title,
    height: ROW_HEIGHT,
    cells: [
      leftBorder(noBorders(nonEditable(textCell(title, tileClass)))),
      rightBorder(
        noBorders(nonEditable(showZero(numberCell(total, lastClass)))),
      ),
      ...cellMonths,
    ],
  };
}

interface UseSimplifiedRowsProps {
  dataSet?: SimplifiedDataGrid;
  selectedMonth: string | null;
  title?: string;
  withClickableCells?: boolean;
  period?: string | number;
  selectedAnnualDeclaration: AnnualDeclaration | null;
}

type TransactionType =
  | 'tax_losses'
  | 'personal_deduction'
  | 'provisional_payment'
  | 'tax_retained'
  | 'income'
  | 'compensation';

const calculateTotal = (
  annualTotals: AnnualDeclarationTotal[],
  transactionType: TransactionType,
  sourceType?: string,
) =>
  sumBy(annualTotals, (total) => {
    const matchesTransactionType = total.transaction_type === transactionType;
    const matchesSourceType = sourceType
      ? total.source_type === sourceType
      : true;
    return matchesTransactionType && matchesSourceType ? +total.amount : 0;
  });

export default function useSimplifiedRows({
  dataSet,
  selectedMonth,
  title,
  withClickableCells,
  period,
  selectedAnnualDeclaration,
}: UseSimplifiedRowsProps): Row[] {
  const totalIncomes = dataSet?.totalIncomes || [];
  const totalExpenses = dataSet?.totalExpenses || [];
  const profitOrLoss = dataSet?.profitOrLoss || [];
  const annualTotals =
    selectedAnnualDeclaration?.annual_declaration_totals || [];

  const taxLosses = calculateTotal(annualTotals, 'tax_losses');
  const personalDeduction = calculateTotal(annualTotals, 'personal_deduction');
  const provisionalPayment = calculateTotal(
    annualTotals,
    'provisional_payment',
  );
  const taxRetained = calculateTotal(annualTotals, 'tax_retained');
  const salary = calculateTotal(annualTotals, 'income', 'salary');
  const inFavorCompensation = calculateTotal(annualTotals, 'compensation');

  const isrInFavorOrAgainst = +defaultTo(
    selectedAnnualDeclaration?.profit_or_loss,
    0,
  );
  const isrTariff = +defaultTo(selectedAnnualDeclaration?.isr_tariff, 0);

  const totalProfitOrLoss = sum(profitOrLoss);

  const exerciseBusinessUtility = Math.max(
    Math.max(totalProfitOrLoss, 0) + salary - personalDeduction,
    0,
  );

  const baseGravable = exerciseBusinessUtility;

  return [
    getHeaderRow(
      selectedMonth,
      `Anual ${period || ''}`,
      MONTHS,
      HEADING_ROW_HEIGHT,
      HEADER_ROW_ID,
      title,
    ),
    getRow({
      title: 'Ingresos por actividad',
      values: totalIncomes,
      selectedMonth,
      isTotal: false,
      cursorPointer: true,
      withClickableCells,
      withHighDash: true,
    }),

    getRow({
      title: 'Gastos por actividad',
      values: totalExpenses,
      selectedMonth,
      isTotal: false,
      cursorPointer: true,
      withClickableCells,
      withHighDash: true,
    }),
    getRow({
      title: 'Pérdidas fiscales de años anteriores',
      values: [],
      selectedMonth,
      isTotal: false,
      cursorPointer: false,
      withHighDash: true,
      annualTotal: taxLosses,
    }),
    getRow({
      title: 'Utilidad o pérdida de la actividad',
      values: profitOrLoss,
      selectedMonth,
      isTotal: true,
      cursorPointer: false,
      withHighDash: true,
    }),
    getRow({
      title: 'Ingresos por SyS',
      values: [],
      selectedMonth,
      isTotal: false,
      cursorPointer: false,
      withHighDash: true,
      annualTotal: salary,
    }),
    getRow({
      title: 'Deducciones personales',
      values: [],
      selectedMonth,
      isTotal: false,
      cursorPointer: false,
      withHighDash: true,
      annualTotal: personalDeduction,
    }),
    getRow({
      title: 'Utilidad del ejercicio',
      values: [],
      selectedMonth,
      isTotal: true,
      cursorPointer: false,
      withHighDash: true,
      annualTotal: exerciseBusinessUtility,
    }),
    getEmptyRow(
      selectedMonth,
      MONTHS,
      HEADING_ROW_HEIGHT,
      `${EMPTY_ROW_ID}-${1}`,
      true,
    ),
    getRow({
      title: 'Base gravable',
      values: [],
      selectedMonth,
      isTotal: true,
      cursorPointer: false,
      withHighDash: true,
      annualTotal: baseGravable,
    }),
    getEmptyRow(
      selectedMonth,
      MONTHS,
      HEADING_ROW_HEIGHT,
      `${EMPTY_ROW_ID}-${2}`,
      true,
    ),
    getRow({
      title: 'Impuesto ISR a cargo',
      values: [],
      selectedMonth,
      isTotal: true,
      cursorPointer: false,
      withHighDash: true,
      annualTotal: isrTariff,
    }),
    getRow({
      title: 'Pagos provisionales',
      values: [],
      selectedMonth,
      isTotal: false,
      cursorPointer: false,
      withHighDash: true,
      annualTotal: provisionalPayment,
    }),
    getRow({
      title: 'ISR Retenido',
      values: [],
      selectedMonth,
      isTotal: false,
      cursorPointer: false,
      withHighDash: true,
      annualTotal: taxRetained,
    }),
    getRow({
      title: 'Compensación de saldo a favor',
      values: [],
      selectedMonth,
      isTotal: false,
      cursorPointer: false,
      withHighDash: true,
      annualTotal: inFavorCompensation,
    }),
    getEmptyRow(
      selectedMonth,
      MONTHS,
      HEADING_ROW_HEIGHT,
      `${EMPTY_ROW_ID}-${4}`,
      true,
    ),
    getRow({
      title: `Total ISR a ${
        isrInFavorOrAgainst > 0 ? 'cargo' : 'favor'
      } del año ${period || ''}`,
      values: [],
      selectedMonth,
      isTotal: true,
      cursorPointer: false,
      withHighDash: true,
      annualTotal: Math.abs(isrInFavorOrAgainst),
    }),
  ];
}
