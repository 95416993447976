import {
  ChangeEvent,
  ChangeEventHandler,
  ComponentProps,
  FocusEvent,
  FocusEventHandler,
} from 'react';
import { useField, useFormikContext } from 'formik';
import { Flex, Input, Text } from '@konta/ui';

interface FormikKontaTextInputProps
  extends Omit<
    ComponentProps<typeof Input>,
    'label' | 'name' | 'placeholder' | 'onChange' | 'onBlur'
  > {
  label: string;
  name: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}
export default function FormikTextInput({
  name,
  label,
  disabled,
  onChange,
  onBlur,
  ...restProps
}: FormikKontaTextInputProps) {
  const formik = useFormikContext();
  const [field, meta] = useField({
    name,
  });

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  const touched = meta.touched || formik.submitCount > 0;
  const error = meta.error && touched ? meta.error : null;

  return (
    <Flex direction="column" gap={6} css={{ flex: 1 }}>
      <Input
        {...field}
        {...restProps}
        name={name}
        label={label}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        inputCss={{ alignItems: 'center' }}
      />
      {error && (
        <Text xs lineHeight="xs" color="error500">
          {error}
        </Text>
      )}
    </Flex>
  );
}
