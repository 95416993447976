import api from '@api';

export default async function putProduct({ taxableId, product }) {
  const token = localStorage.getItem('user_id');
  const { data } = await api.put(
    `taxable_entities/${taxableId}/products/${product.id}`,
    { product },
    { headers: { Authorization: token } },
  );

  return data;
}
