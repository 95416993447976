import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'rooks';
import useTaxableEntityPreferences from '@hooks/useTaxableEntityPreferences';
import {
  TaxableEntity,
  TaxableEntityPayload,
  TaxableEntityPreference,
  TaxableEntityPreferencePayload,
} from 'types/entities';
import { ImageListType } from 'react-images-uploading';
import { useTaxableEntity } from '@hooks';
import { NotificationManager } from '@components';
import getErrorMessage from '@util/getErrorMessage';
import { dataURItoBlob } from '@util/Utils';
import { updateTaxableEntity } from '@redux/taxable_entity/actions';
import useCreateInvoiceDraftNewMutation from '@hooks/useCreateInvoiceDraftNewMutation';
import useUpdateTaxableEntityPreferencesMutation from '@hooks/useUpdateTaxableEntityPreferencesMutation';
import isHexColor from '@util/isHexColor';
import { Cfdi, Receipt } from 'types/cfdi';
import { validationSchema, templatesOpts } from './form';

const usePreviewPayload = (
  taxableEntity: TaxableEntity | null,
  defaultFolio: string,
): Cfdi =>
  useMemo(
    () => ({
      body: {
        receipt: {
          version: '4.0',
          'xsi:schemaLocation':
            'http://www.sat.gob.mx/cfd/4 http://www.sat.gob.mx/sitio_internet/cfd/4/cfdv40.xsd',
          date: '2023-01-24T08:59:10',
          export_code: '01',
          serie: '',
          folio_number: defaultFolio,
          payment_way: '03',
          subtotal: '1783.70',
          discount: '10.00',
          total: '2047.49',
          currency: 'MXN',
          type_of_receipt: 'I',
          payment_method: 'PUE',
          expedition_place: '000000',
          issuer_attributes: {
            rfc: taxableEntity?.rfc || 'XAXX010101000',
            name: taxableEntity?.legal_name || 'PUBLICO EN GENERAL',
            fiscal_regime: 616,
          },
          receiver_attributes: {
            rfc: 'XAXX010101000',
            name: 'PUBLICO EN GENERAL',
            zip_code: '97117',
            fiscal_regime: 616,
            cfdi_usage: 'S01',
            tax_residency: null,
          },
          concepts_attributes: [
            {
              taxable_code: '02',
              prod_or_svc_key: '10121801',
              identification_number: '3213',
              quantity: '1',
              unit_measure_key: 'H87',
              unit_measure: 'Pieza',
              description: 'Croquetas para perros',
              unit_value: '1588.880000',
              amount: '1588.880000',
              transferred_attributes: [
                {
                  base: '1588.880000',
                  tax: '002',
                  factor_type: 'Tasa',
                  rate_or_fee: '0.160000',
                  import: '254.22',
                },
              ],
              property_account_attributes: {
                number: '12312321321',
              },
            },
            {
              taxable_code: '02',
              prod_or_svc_key: '53131628',
              identification_number: '',
              quantity: '1',
              unit_measure_key: 'H87',
              unit_measure: 'Pieza',
              description: 'Estéreo',
              unit_value: '94.820000',
              amount: '94.820000',
              discount: '10.000000',
              transferred_attributes: [
                {
                  base: '84.820000',
                  tax: '002',
                  factor_type: 'Tasa',
                  rate_or_fee: '0.160000',
                  import: '13.57',
                },
              ],
            },
            {
              taxable_code: '02',
              prod_or_svc_key: '50151605',
              identification_number: '',
              quantity: '1',
              unit_measure_key: 'H87',
              unit_measure: 'Pieza',
              description: 'Arrendamiento de inmueble',
              unit_value: '100.000000',
              amount: '100.000000',
              detained_attributes: [
                {
                  base: '100.000000',
                  tax: '001',
                  factor_type: 'Tasa',
                  rate_or_fee: '0.100000',
                  import: '10.00',
                },
              ],
              transferred_attributes: [
                {
                  base: '100.000000',
                  tax: '002',
                  factor_type: 'Tasa',
                  rate_or_fee: '0.160000',
                  import: '16.00',
                },
              ],
            },
          ],
          taxes_attributes: {
            total_taxes_transferred: '283.79',
            total_taxes_detained: '10.00',
            transferred_attributes: [
              {
                base: '1773.70',
                tax: '002',
                factor_type: 'Tasa',
                rate_or_fee: '0.160000',
                import: '283.79',
              },
            ],
            detained_attributes: [
              {
                tax: '001',
                import: '10.00',
              },
            ],
          },
          global_info_attributes: {
            periodicity: '04',
            months: '01',
            year: '2023',
          },
        },
      },
    }),
    [defaultFolio, taxableEntity?.legal_name, taxableEntity?.rfc],
  );

const useInitialValues = (
  taxableEntityPreferences: TaxableEntityPreference | null,
  taxableEntity: TaxableEntity | null,
) =>
  useMemo(() => {
    const invoiceTemplate = taxableEntityPreferences?.invoice_template;
    const invoiceColor = taxableEntityPreferences?.invoice_color;
    const incrementInternalFolio =
      taxableEntityPreferences?.increment_internal_folio;
    const internalFolioPrefix = taxableEntityPreferences?.internal_folio_prefix;
    const internalFolioNumberLength =
      taxableEntityPreferences?.internal_folio_number_length;
    const uniqueInternalFolio = taxableEntityPreferences?.unique_internal_folio;
    const nextInternalFolio = taxableEntityPreferences?.next_internal_folio;
    const logo: ImageListType = [];
    if (taxableEntity?.logo_url) {
      logo.push({
        dataURL: taxableEntity.logo_url,
      });
    }
    return {
      hasLogoInsteadOfIcon: false,
      template:
        templatesOpts.find((template) => template.value === invoiceTemplate) ||
        templatesOpts[1],
      brandColor: invoiceColor?.substring?.(1) || '1b4f96',
      logo,
      showLogoInInvoice: !!taxableEntity?.show_logo_in_invoice,
      incrementInternalFolio,
      internalFolioPrefix,
      internalFolioNumberLength,
      uniqueInternalFolio,
      nextInternalFolio,
    };
  }, [
    taxableEntity?.logo_url,
    taxableEntity?.show_logo_in_invoice,
    taxableEntityPreferences?.increment_internal_folio,
    taxableEntityPreferences?.internal_folio_number_length,
    taxableEntityPreferences?.internal_folio_prefix,
    taxableEntityPreferences?.invoice_color,
    taxableEntityPreferences?.invoice_template,
    taxableEntityPreferences?.next_internal_folio,
    taxableEntityPreferences?.unique_internal_folio,
  ]);

type UseTaxableEntity = {
  taxable_entity: TaxableEntity | null;
  loading: boolean;
};
export default function useSettingsBrandForm() {
  const dispatch = useDispatch();
  const { taxable_entity: taxableEntity, loading: loadingTaxableEntity } =
    useTaxableEntity() as UseTaxableEntity;
  const taxableEntityId = taxableEntity?.id || 0;
  const {
    taxableEntityPreferences,
    refetch: refetchTxEntityQuery,
    loadingTaxableEntityPreferences,
  } = useTaxableEntityPreferences(taxableEntityId);
  const createInvoiceDraftNewMutation = useCreateInvoiceDraftNewMutation();
  const updateTaxableEntityPreference =
    useUpdateTaxableEntityPreferencesMutation(taxableEntityId);

  const initialValues = useInitialValues(
    taxableEntityPreferences,
    taxableEntity,
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values, { resetForm }) => {
      resetForm();
    },
  });

  const defaultFolio = useMemo(() => {
    let folio = '';
    if (formik.values.internalFolioPrefix) {
      folio += formik.values.internalFolioPrefix;
    }
    if (formik.values.nextInternalFolio) {
      folio += `${formik.values.nextInternalFolio}`.padStart(
        formik.values.internalFolioNumberLength || 0,
        '0',
      );
    }
    return folio;
  }, [
    formik.values.internalFolioNumberLength,
    formik.values.internalFolioPrefix,
    formik.values.nextInternalFolio,
  ]);

  const cfdiPreviewPayload = usePreviewPayload(taxableEntity, defaultFolio);

  const uploadTaxableEntityPreference = async (
    payload: TaxableEntityPreferencePayload,
  ) => {
    try {
      await updateTaxableEntityPreference.mutateAsync(payload);
      await createInvoiceDraftNewMutation.mutateAsync(cfdiPreviewPayload);
      await refetchTxEntityQuery();
      NotificationManager.success(
        'Se han actualizado las preferencias correctamente.',
        'Imagen de marca',
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e) as string;
      NotificationManager.error(errorMessage, 'Imagen de marca');
    }
  };

  const updateTaxableEntityFn = (payload: FormData | TaxableEntityPayload) => {
    dispatch(
      updateTaxableEntity(payload, taxableEntityId, undefined, () => {
        void createInvoiceDraftNewMutation.mutateAsync(cfdiPreviewPayload);
      }),
    );
  };

  const uploadTaxableEntityLogo = (logoUrl: string) => {
    try {
      const logoBlob = dataURItoBlob(logoUrl);
      const formData = new FormData();
      formData.append('taxable_entity[logo]', logoBlob);
      updateTaxableEntityFn(formData);
    } catch (e) {
      const errorMessage = getErrorMessage(e) as string;
      NotificationManager.error(errorMessage, 'Imagen de marca');
    }
  };

  const uploadTaxableEntity = (payload: TaxableEntityPayload) => {
    try {
      updateTaxableEntityFn(payload);
    } catch (e) {
      const errorMessage = getErrorMessage(e) as string;
      NotificationManager.error(errorMessage, 'Imagen de marca');
    }
  };

  const loadingPreview =
    loadingTaxableEntity ||
    updateTaxableEntityPreference.isLoading ||
    createInvoiceDraftNewMutation.isLoading;

  useEffect(() => {
    void createInvoiceDraftNewMutation.mutateAsync(cfdiPreviewPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedRefetch = useDebounce(
    async (payload: TaxableEntityPreferencePayload) => {
      if (!payload) return;

      await uploadTaxableEntityPreference(payload);
    },
    500,
  );

  useEffect(() => {
    const { template, brandColor } = formik.values;
    const isValidColor = isHexColor(brandColor);
    const isInvoiceTemplateDirty =
      template?.value !== initialValues.template?.value;
    const isBrandColorDirty =
      isValidColor && brandColor !== initialValues.brandColor;
    if (
      (isInvoiceTemplateDirty || isBrandColorDirty) &&
      (template?.value || isValidColor) &&
      formik.dirty
    ) {
      const payload: TaxableEntityPreferencePayload = {
        invoice_template: template?.value,
        ...(isValidColor && { invoice_color: `#${brandColor}` }),
      };

      void debouncedRefetch(payload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return {
    formik,
    loadingTaxableEntity,
    loadingTaxableEntityPreferences,
    cfdiPreviewPayload,
    loadingPreview,
    uploadTaxableEntity,
    uploadTaxableEntityLogo,
    uploadTaxableEntityPreference,
    invoiceDraft: createInvoiceDraftNewMutation.data,
    loadingUpdateTaxableEntityPreferences:
      updateTaxableEntityPreference.isLoading,
    loadingCreateTaxableEntity: createInvoiceDraftNewMutation.isLoading,
    defaultFolio,
  };
}
