import { ComponentProps, ReactNode } from 'react';
import { Button } from '@konta/ui';
import Modal from '../Modal';

interface ButtonModalFormProps {
  children: ReactNode;
  isLoading: boolean;
  cancelTextBtn?: string;
  submitTextBtn?: string;
  modalSize?: 'sm' | 'lg' | 'xl';
  isOpen: boolean;
  toggleIsOpen: () => void;
  resetForm?: () => void;
  submitForm?: () => void;
  centered?: boolean;
  disableSubmit?: boolean;
  modalTitle?: string;
  modalSubtitle?: string;
  onCancelButton?: () => void;
  withBanner?: boolean;
  submitBtnProps?: Omit<ComponentProps<typeof Button>, 'children'>;
}
export default function ModalForm({
  modalTitle,
  modalSubtitle,
  cancelTextBtn = 'Cancelar',
  submitTextBtn = 'Guardar',
  children,
  isLoading,
  modalSize = 'lg',
  isOpen,
  toggleIsOpen,
  resetForm,
  submitForm,
  centered = true,
  disableSubmit = false,
  onCancelButton,
  withBanner = true,
  submitBtnProps,
}: ButtonModalFormProps) {
  const closeModal = () => {
    toggleIsOpen();
    if (resetForm) {
      resetForm();
    }
  };
  return (
    <Modal
      modalTitle={modalTitle}
      modalSubtitle={modalSubtitle}
      isOpen={isOpen}
      toggleIsOpen={closeModal}
      modalSize={modalSize}
      centered={centered}
      withBanner={withBanner}
      actions={
        <>
          <Button
            loading={isLoading}
            onClick={onCancelButton || closeModal}
            variant="outlined"
          >
            {cancelTextBtn}
          </Button>
          <Button
            disabled={disableSubmit}
            loading={isLoading}
            color="primary600"
            onClick={submitForm}
            {...submitBtnProps}
          >
            {submitTextBtn}
          </Button>
        </>
      }
      isLoading={isLoading}
    >
      {children}
    </Modal>
  );
}
