import { createContext, useContext } from 'react';
import menuModules, { MenuModules } from '../constants/menuModules';
import routes from '../configs/routesConfig';

interface AppContextProps {
  menu: MenuModules;
  routes: typeof routes;
}

export const appContext = createContext<AppContextProps>({
  menu: menuModules,
  routes,
});

export const AppProvider = appContext.Provider;

export function useAppContext() {
  return useContext(appContext);
}
