import React, { useEffect, memo } from 'react';
import { BlobProvider } from '@react-pdf/renderer';
import PpdPdfPreview from '@components/PpdPdfPreview';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchCFDIfromXML } from '@redux/actions';
import PreviewDocument from '@components/PreviewDocument';

function InvoicePdf({ cfdi, taxableEntity }) {
  return <PpdPdfPreview cfdi={cfdi} taxable_entity={taxableEntity} />;
}
const MemoizedInvoicePdf = memo(InvoicePdf);

export default function PpdInvoicePreview({ invoiceObject }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCFDIfromXML(invoiceObject.xml_url));
  }, [dispatch, invoiceObject.xml_url]);

  const { cfdi } = useSelector((state) => {
    return state.cfdis;
  }, shallowEqual);

  return (
    !!cfdi && (
      <BlobProvider document={<MemoizedInvoicePdf cfdi={cfdi} />}>
        {({ url, loading }) => (
          <PreviewDocument defaultFile={url} loading={loading} maxWidth={800} />
        )}
      </BlobProvider>
    )
  );
}
