import { NonDeductibleGasInvoicesFlowStep } from 'types/flows';
import { ReactComponent as Img } from './img.svg';
import { ImageContainer, Title, Subtitle } from './styled';

interface NonDeductibleGasInvoicesStepContentProps {
  nonDeductibleGasInvoicesFlowStep: NonDeductibleGasInvoicesFlowStep;
}

export default function NonDeductibleGasInvoicesStepContent({
  nonDeductibleGasInvoicesFlowStep,
}: NonDeductibleGasInvoicesStepContentProps) {
  const { state } = nonDeductibleGasInvoicesFlowStep;
  const { count } = state;

  return (
    <>
      <Title>
        <b>
          El mes pasado tuviste ({count}) factura{count === 1 ? '' : 's'} de
          gasolina
        </b>{' '}
        que no que no pudiste deducir.
      </Title>
      <ImageContainer>
        <Img />
      </ImageContainer>
      <Subtitle>
        <span data-type="s">Para deducir los gastos de gasolina</span>
        <span data-type="s">en tu próxima declaración,</span>
        <span data-type="s">
          toma en consideración lo siguiente <small>👉🏼</small>
        </span>
      </Subtitle>
    </>
  );
}
