import { ClipboardCopy, CSS } from '@konta/ui';
import { Copy01LineIcon } from '@konta/icons';
import { ButtonProps } from '@konta/ui/dist/components/Button/Button';

interface MinimalClipboardCopyProps {
  value: string;
  size?: ButtonProps['size'];
  css?: CSS;
}
export default function MinimalClipboardCopy({
  value,
  css,
  size = '2xs',
}: MinimalClipboardCopyProps) {
  return (
    <ClipboardCopy
      size={size}
      variant="text"
      clipboard={value}
      css={{ whiteSpace: 'nowrap', ...css }}
      noFill
      icon
    >
      <Copy01LineIcon width={14} height={14} />
    </ClipboardCopy>
  );
}
