import React from 'react';
import PropTypes from 'prop-types';
import formatNumber from '@util/formatNumber';
import TooltipedBadge from '@components/TooltipedBadge';

function formatTax(tax) {
  return formatNumber(tax || 0, { currency: true });
}

export default function TaxBadge({ amount, label, rate }) {
  const fixedRate = rate === null ? 'exento' : `${rate * 100}%`;

  return (
    <TooltipedBadge tooltip={`${label} - ${fixedRate}`}>
      {label} - {formatTax(amount)}
    </TooltipedBadge>
  );
}

TaxBadge.propTypes = {
  amount: PropTypes.number,
  label: PropTypes.string.isRequired,
  rate: PropTypes.number,
};

TaxBadge.defaultProps = {
  amount: 0,
  rate: null,
};
