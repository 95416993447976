import { ComponentProps, ReactNode } from 'react';
import { useToggle } from 'usehooks-ts';
import { Button, Chip, Flex, Text } from '@konta/ui';
import { EyeLineIcon, EyeOffLineIcon } from '@konta/icons';
import isReactElement from '@util/isReactElement';
import {
  Root,
  defaultLabelCss,
  defaultValueCss,
  CSS,
  defaultWrapValueCss,
} from './styled';
import MinimalClipboardCopy from '../MinimalClipboardCopy';

export interface InformationTextProps {
  label: string;
  value: string | string[] | JSX.Element;
  labelCss?: CSS;
  valueCss?: CSS;
  wrapValueCss?: CSS;
  css?: CSS;
  icon?: ReactNode;
  withCopyValue?: boolean;
  isSecret?: boolean;
  valueAsChip?: boolean;
  colorChip?: ComponentProps<typeof Chip>['color'];
  sizeChip?: ComponentProps<typeof Chip>['size'];
  itemsCenter?: boolean;
}
interface SecretValueProps {
  showSecret: boolean;
  value: string;
  toggleShowSecret: () => void;
}
function SecretValue({
  showSecret,
  value,
  toggleShowSecret,
}: SecretValueProps) {
  return (
    <>
      {!showSecret ? '*'.repeat(value.length) : value}
      {showSecret ? (
        <Button variant="text" icon size="2xs" noFill>
          <EyeOffLineIcon onClick={toggleShowSecret} />
        </Button>
      ) : (
        <Button variant="text" icon size="2xs" noFill>
          <EyeLineIcon onClick={toggleShowSecret} />
        </Button>
      )}
    </>
  );
}

function Value({
  valueCss,
  value,
  isSecret,
  icon,
  withCopyValue,
  valueAsChip,
  colorChip,
  sizeChip,
}: Pick<
  InformationTextProps,
  | 'valueCss'
  | 'isSecret'
  | 'icon'
  | 'withCopyValue'
  | 'valueAsChip'
  | 'colorChip'
  | 'sizeChip'
> & { value: string | string[] }) {
  const [showSecret, toggleShowSecret] = useToggle();

  if (Array.isArray(value)) {
    return null;
  }

  return (
    <Text css={valueCss} key={value}>
      <Flex gap={8} itemsCenter>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isSecret ? (
          <SecretValue
            value={value}
            showSecret={showSecret}
            toggleShowSecret={toggleShowSecret}
          />
        ) : valueAsChip ? (
          <Chip label={value} color={colorChip} size={sizeChip} />
        ) : (
          value
        )}
        {withCopyValue && <MinimalClipboardCopy value={value} />}
        {!!icon && icon}
      </Flex>
    </Text>
  );
}

export default function InformationText({
  label,
  value,
  icon,
  withCopyValue,
  isSecret,
  labelCss,
  valueCss = defaultValueCss,
  wrapValueCss = defaultWrapValueCss,
  css = {},
  valueAsChip,
  colorChip,
  itemsCenter,
  sizeChip = 'm',
}: InformationTextProps) {
  return (
    <Root itemsCenter={itemsCenter} css={css}>
      <Text css={{ ...defaultLabelCss, ...labelCss }}>{label}: </Text>
      <Flex column css={wrapValueCss}>
        {Array.isArray(value) &&
          value.map((val) => (
            <Value
              sizeChip={sizeChip}
              valueAsChip={valueAsChip}
              key={val}
              value={val}
              valueCss={valueCss}
              withCopyValue={withCopyValue}
              icon={icon}
              isSecret={isSecret}
              colorChip={colorChip}
            />
          ))}
        {typeof value === 'string' && (
          <Value
            sizeChip={sizeChip}
            valueAsChip={valueAsChip}
            value={value}
            valueCss={valueCss}
            withCopyValue={withCopyValue}
            icon={icon}
            isSecret={isSecret}
            colorChip={colorChip}
          />
        )}
        {isReactElement(value) && value}
      </Flex>
    </Root>
  );
}
