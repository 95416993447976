import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { Workflow } from 'types/entities';
import { WORKFLOWS } from 'backoffice/constants/reactQueries';
import { getWorkflows } from 'backoffice/api/workflows';

export default function useWorkflows(
  axiosConfig?: AxiosRequestConfig<Workflow[]>,
  queryOptions?: UseQueryOptions<Workflow[]>,
) {
  const {
    data = [],
    isLoading,
    isFetching,
    ...rest
  } = useQuery<Workflow[]>(
    [WORKFLOWS, axiosConfig],
    async () => {
      const workflows = await getWorkflows(axiosConfig);
      return workflows;
    },
    queryOptions,
  );

  return {
    workflowsLoading: isLoading || isFetching,
    workflows: data,
    ...rest,
  };
}
