import { useCallback, useEffect, useState } from 'react';
import toCurrency from '@util/toCurrency';
import dayjs from 'dayjs';
import {
  Calendar02OutlineIcon,
  CoinsStacked01LineIcon,
  CreditCard02LineIcon,
} from '@konta/icons';
import { useOrders } from '@hooks';
import { PendingTask } from '@components/TaskCard/TaskCard';
import { TaxOrder } from 'types/entities';
import usePendingTasksStore from 'store/pendingTasksStore';

export default function useHandleDebsOrders() {
  const { addPendingTask } = usePendingTasksStore();
  const { debts, loading } = useOrders();
  const taxOrders = debts as TaxOrder[];
  const loadingOrders = loading as boolean;

  const [showDebsOrderModalTask, setShowDebsOrderModalTask] =
    useState<PendingTask | null>(null);

  const toggleShowDebsOrderModalTask = useCallback(() => {
    setShowDebsOrderModalTask(null);
  }, [setShowDebsOrderModalTask]);

  useEffect(() => {
    if (taxOrders.length > 0 && !loadingOrders) {
      const { subscriptions, tax_arrears } = taxOrders[0];
      const totalTaxesArrear = taxOrders.reduce((acc, currentOrder) => {
        if (currentOrder.source_type === 'subscription') {
          return acc;
        }
        return acc + +currentOrder.total;
      }, 0);
      if (subscriptions.length > 0) {
        subscriptions.forEach((subscription) => {
          addPendingTask({
            id: `debt-subscription-${subscription.id}`,
            type: 'taxArrear',
            status: 'requiredAction',
            title: 'Tienes deudas pendientes',
            description: 'Tu suscripción tiene un pago pendiente por saldar',
            extraDescription: subscription,
            details: [
              {
                label: 'Paga tus adeudos para poder continuar en la aplicación',
                icon: <CreditCard02LineIcon />,
                iconCss: {
                  background: '$cyan100',
                  path: {
                    fill: 'none',
                    stroke: '$cyan600',
                  },
                },
              },
              {
                label: `Total a pagar: ${toCurrency(+subscription.total)}`,
                icon: <CoinsStacked01LineIcon />,
                iconCss: {
                  path: {
                    fill: 'none',
                    stroke: '$gray500',
                  },
                },
              },
            ],
            handleAction: () => {
              setShowDebsOrderModalTask({
                id: `debt-subscription-${subscription.id}`,
                type: 'taxArrear',
                status: 'requiredAction',
                title: 'Tienes deudas pendientes',
                description:
                  'Tu suscripción tiene un pago pendiente por saldar',
              });
            },
          });
        });
      } else if (tax_arrears) {
        tax_arrears.forEach((tax_arrear) => {
          addPendingTask({
            id: `debt-arrear-${tax_arrear.id}`,
            type: 'taxArrear',
            status: 'requiredAction',
            title: 'Pago de declaraciones atrasadas',
            description: 'Proceso mensual de impuestos al final del mes',
            extraDescription: tax_arrear,
            details: [
              {
                label: 'Paga tus declaraciones para poder comenzar a trabajar',
                icon: <CreditCard02LineIcon />,
                iconCss: {
                  background: '$cyan100',
                  path: {
                    fill: 'none',
                    stroke: '$cyan600',
                  },
                },
              },
              {
                label: `Total a pagar: ${toCurrency(+totalTaxesArrear)}`,
                icon: <CoinsStacked01LineIcon />,
                iconCss: {
                  path: {
                    fill: 'none',
                    stroke: '$gray500',
                  },
                },
              },
              {
                label: `Fecha límite: ${dayjs(tax_arrear.valid_to)
                  .add(6, 'days')
                  .format('DD [de] MMMM')}`,
                icon: <Calendar02OutlineIcon />,
                iconCss: {
                  path: {
                    fill: '$gray500',
                  },
                },
              },
            ],
            handleAction: () => {
              setShowDebsOrderModalTask({
                id: `debt-arrear-${tax_arrear.id}`,
                type: 'taxArrear',
                status: 'requiredAction',
                title: 'Pago de declaraciones atrasadas',
                description: 'Proceso mensual de impuestos al final del mes',
              });
            },
          });
        });
      }
    }
  }, [taxOrders, addPendingTask, setShowDebsOrderModalTask, loadingOrders]);

  return {
    showDebsOrderModalTask,
    loadingOrders,
    toggleShowDebsOrderModalTask,
  };
}
