import React, { useState } from 'react';
// eslint-disable-next-line import/no-cycle
import {
  Colxx,
  CaptureLine,
  FiscalPeriodInvoice,
  ButtonConfirmModal,
  NotificationManager,
} from '@components';
import { Row, Badge, Collapse, Card } from 'reactstrap';
import { getDateObjectWithFormat, sumAll } from '@util/Utils';
import toCurrency from '@util/toCurrency';
import { useFiscalPeriods, usePaymentMethods } from '@hooks';

// eslint-disable-next-line import/prefer-default-export
export function FiscalPeriodRow({ fp }) {
  const { total_income, total_expenses, id, taxable_entity_id, ...rest } = fp;
  const { handleFpPay } = useFiscalPeriods('fiscal_period');
  const [activeIndex, setActiveIndex] = useState(false);
  const {
    ui: { selected_card },
  } = usePaymentMethods();

  const expiration_date = new Date(fp.validation_date);
  const isExpired = new Date(expiration_date) < new Date();

  const handlePaymentClick = () => {
    if (isExpired)
      NotificationManager.error(
        'El periodo de pago de este periodo fiscal ha expirado, porfavor contacte a su contador.',
        'Upss..',
        4000,
        null,
        null,
        null,
      );
    else if (selected_card && selected_card.source_type === 'card')
      handleFpPay(fp);
    else
      NotificationManager.error(
        'Verifique que tenga un método de pago de tipo tarjeta seleccionado.',
        'Upss..',
        4000,
        null,
        null,
        null,
      );
  };

  return (
    <Row className="my-2 py-3 px-5 align-items-center row-outline mx-auto">
      <Colxx xxs="6" lg="3" className="my-auto">
        <span className="align-middle d-inline-block order-price text-muted">
          {toCurrency(+sumAll(...Object.values(rest)))}
        </span>
      </Colxx>
      <Colxx className="d-none d-lg-flex" lg="2">
        <Badge pill color="outline-danger" className="cursor-default">
          Periodo Fiscal
        </Badge>
      </Colxx>
      <Colxx className="d-none d-lg-flex" lg="3">
        <span className="align-middle d-inline-block text-muted">
          {getDateObjectWithFormat(expiration_date)}
        </span>
      </Colxx>
      <Colxx xxs="4" lg="2">
        <Badge
          color="secondary"
          pill
          onClick={(_) => setActiveIndex(!activeIndex)}
        >
          <span className="d-none d-lg-inline">Más información</span>
          <span className="d-lg-none">Ver</span>
          <span
            className={`simple-icon-arrow-${activeIndex ? 'up' : 'down'} ml-2`}
          />
        </Badge>
      </Colxx>

      <Colxx xxs="2" lg="2" className="text-right">
        <ButtonConfirmModal
          buttonColor="link"
          handleSubmit={handlePaymentClick}
          cancelButton="Cancelar"
          confirmButton="Confirmar"
          buttonLabel="Pagar"
        >
          <i className="large-icon iconsmind-Danger" color="danger" />
          <p>¡Estas a punto de pagar un periodo fiscal!</p>
        </ButtonConfirmModal>
      </Colxx>
      <Collapse isOpen={activeIndex} className="w-100">
        <Row className="flex-column-reverse flex-lg-row">
          <Colxx xxs="12" lg="4">
            <Card className="my-2 inner-card" body>
              <CaptureLine fiscal_period={fp} />
            </Card>
          </Colxx>
          <Colxx xxs="12" lg="8">
            <FiscalPeriodInvoice fiscal_period={fp} />
          </Colxx>
        </Row>
      </Collapse>
    </Row>
  );
}
