import _ from 'lodash';
import dayjs from 'dayjs';
import { WorkflowHeatmapData } from 'types/entities';

export default function transformWorkflowDataForHeatmap(
  data: WorkflowHeatmapData | null,
) {
  const months: string[] = [];
  const years = new Set<string>();
  let arrearsCount = 0;

  if (!data) {
    return {
      data: [],
      yAccessor: months,
      xAccessor: Array.from(years),
      arrearsCount,
    };
  }

  const transformedData = Object.keys(data[Object.keys(data)[0]])
    .sort()
    .map((monthKey) => {
      const accessor = Number(monthKey)
        ? _.startCase(
            dayjs()
              .month(Number(monthKey) - 1)
              .format('MMM'),
          )
        : 'Anual';
      const bins = Object.keys(data).map((year) => {
        years.add(year);
        const count = data[year][monthKey] || 0;
        if (count === 0) {
          arrearsCount += 1;
        }
        return {
          bin: year,
          count,
          accessor,
        };
      });
      months.push(accessor);
      return {
        bin: Number.isNaN(+monthKey) ? 12 : +monthKey,
        bins,
        accessor,
      };
    });

  return {
    data: transformedData,
    yAccessor: Array.from(years),
    xAccessor: months,
    arrearsCount,
  };
}
