import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, Flex } from '@konta/ui';
import { ProcessType, User } from 'types/entities';
import ProcessStepLayout from '@components/ProcessStepLayout';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepDescription from '@components/ProcessStepDescription';
import useUser from '@hooks/useUser';

export default function RecommendationsAnnual() {
  const process = ProcessType.AnnualDeclarationProcess;
  const { user } = useUser() as {
    user: User;
  };

  const period = dayjs().subtract(1, 'year').year();
  const navigate = useNavigate();

  const userName = user?.nickname || user?.first_name;
  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Declaraciones Anuales"
      titleChip="En curso"
      content={
        <Flex direction="column" gap={12}>
          <ProcessStepTitle>
            Seguimiento de tu declaración anual
          </ProcessStepTitle>
          <ProcessStepDescription>¡Hola {userName}!</ProcessStepDescription>
          <ProcessStepDescription>
            Quería informarte que estamos presentando tu declaración anual ante
            el SAT en estos momentos. Gracias por tu paciencia mientras
            completamos el proceso.
          </ProcessStepDescription>
          <ProcessStepDescription>
            Cuando finalicemos, te notificaremos por correo electrónico y
            WhatsApp para que sepas cómo va todo. Si tienes alguna pregunta o
            necesitas asistencia, no dudes en contactarnos.
          </ProcessStepDescription>
          <ProcessStepDescription>
            Apreciamos tu confianza en nosotros y estamos aquí para ayudarte en
            lo que necesites.
          </ProcessStepDescription>
          <Flex>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(`/app/declaraciones/anuales/?period=${period}`);
              }}
            >
              Ir al resumen
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
}
