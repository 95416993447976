import React from 'react';
import { Flex, CircularLoading } from '@konta/ui';

export default function Loader() {
  return (
    <Flex
      align="center"
      justify="center"
      css={{ height: '100%', width: '100%' }}
    >
      <CircularLoading l />
    </Flex>
  );
}
