import {
  type TaxableEntityFlowStep,
  BalanceAvailableForDeductionsFlowStep,
  DeductionsLimitReachedFlowStep,
  NonDeductibleGasInvoicesFlowStep,
  PendingDeclarationsFlowStep,
  ScoreFlowStep,
  Step,
  StepDataType,
  TaxToPayFlowStep,
  WithoutTaxEffectsFlowStep,
} from 'types/flows';
import type { CSS } from '@konta/ui';
import { DateStr } from 'types/entities';
import TextStepContent from './InsightsContent/TextStepContent';
import ImageStepContent from './InsightsContent/ImageStepContent';
import BlogStepContent from './InsightsContent/BlogStepContent';
import PendingDeclarationsStepContent from './InsightsContent/PendingDeclarationsStepContent';
import WithoutTaxEffectsStepContent from './InsightsContent/WithoutTaxEffectsStepContent';
import NonDeductibleGasInvoicesStepContent from './InsightsContent/NonDeductibleGasInvoicesStepContent';
import ScoreStepContent from './InsightsContent/ScoreStepContent';
import DeductionsLimitReachedStepContent from './InsightsContent/DeductionsLimitReachedStepContent';
import TaxToPayStepContent from './InsightsContent/TaxToPayStepContent';
import BalanceAvailableForDeductionsStepContent from './InsightsContent/BalanceAvailableForDeductionsStepContent';

// eslint-disable-next-line import/prefer-default-export
export const getStepContent = (
  taxableEntityFlowStep: TaxableEntityFlowStep,
  {
    setIsActiveDialog,
    setIsOpen,
    setPreviewContainerCss,
  }: {
    setIsActiveDialog?: (isActiveDialog: boolean) => void;
    setIsOpen?: (isOpen: boolean) => void;
    setPreviewContainerCss?: (css: CSS) => void;
  },
) => {
  switch (taxableEntityFlowStep.flow_step.type) {
    case StepDataType.TEXT:
      return <TextStepContent step={taxableEntityFlowStep.flow_step} />;
    case StepDataType.IMG:
      return (
        <ImageStepContent
          step={taxableEntityFlowStep.flow_step}
          setPreviewContainerCss={setPreviewContainerCss}
        />
      );
    case StepDataType.BLOG:
      return (
        <BlogStepContent
          step={taxableEntityFlowStep.flow_step}
          setIsActiveDialog={setIsActiveDialog}
          setIsOpen={setIsOpen}
        />
      );
    case StepDataType.NON_DEDUCTIBLE_GAS_INVOICES:
      return (
        <NonDeductibleGasInvoicesStepContent
          nonDeductibleGasInvoicesFlowStep={
            taxableEntityFlowStep as NonDeductibleGasInvoicesFlowStep
          }
        />
      );
    case StepDataType.SCORE:
      return (
        <ScoreStepContent
          scoreFlowStep={taxableEntityFlowStep as ScoreFlowStep}
        />
      );
    case StepDataType.PENDING_DECLARATIONS:
      return (
        <PendingDeclarationsStepContent
          pendingDeclarationsFlowStep={
            taxableEntityFlowStep as PendingDeclarationsFlowStep
          }
        />
      );
    case StepDataType.DEDUCTIONS_LIMIT_REACHED:
      return (
        <DeductionsLimitReachedStepContent
          deductionsLimitReachedFlowStep={
            taxableEntityFlowStep as DeductionsLimitReachedFlowStep
          }
        />
      );
    case StepDataType.BALANCE_AVAILABLE_FOR_DEDUCTIONS:
      return (
        <BalanceAvailableForDeductionsStepContent
          balanceAvailableForDeductionsFlowStep={
            taxableEntityFlowStep as BalanceAvailableForDeductionsFlowStep
          }
        />
      );
    case StepDataType.WITHOUT_TAX_EFFECTS:
      return (
        <WithoutTaxEffectsStepContent
          withoutTaxEffectsFlowStep={
            taxableEntityFlowStep as WithoutTaxEffectsFlowStep
          }
        />
      );
    case StepDataType.TAX_TO_PAY:
      return (
        <TaxToPayStepContent
          taxToPayFlowStep={taxableEntityFlowStep as TaxToPayFlowStep}
        />
      );
    default:
      return null;
  }
};

export const convertToTaxableEntityFlowStep = (
  flowId: number,
  flowStep: Step,
  state: unknown[] | Record<string, unknown> = {},
): TaxableEntityFlowStep => ({
  flow_step: flowStep,
  state,
  flow_step_id: flowId,
  taxable_entity_flow_id: 0,
  id: 0,
  seen: false,
  completed: false,
  created_at: new Date().toISOString() as DateStr,
  updated_at: new Date().toISOString() as DateStr,
});

export const getDefaultStateByStepType = (stepType: StepDataType) => {
  switch (stepType) {
    case StepDataType.WITHOUT_TAX_EFFECTS:
      return { count: 0 };
    case StepDataType.NON_DEDUCTIBLE_GAS_INVOICES:
      return { count: 0 };
    case StepDataType.SCORE:
      return {
        score: 0,
        last_update: new Date().toISOString() as DateStr,
        status: 'No disponible',
      };
    case StepDataType.PENDING_DECLARATIONS:
      return [
        'Declaración anual: 2023',
        'Declaración semestral: Junio, 2024',
        'Declaración trimestral: Julio, 2024',
        'Declaración bimestral: Agosto, 2024',
        'Declaración mensual: Septiembre, 2024',
        'Declaración mensual: Octubre, 2024',
      ];
    case StepDataType.BALANCE_AVAILABLE_FOR_DEDUCTIONS:
      return { available_balance: 100 };
    case StepDataType.TAX_TO_PAY:
      return { to_pay: 100 };
    default:
      return {};
  }
};

export const getStepDataTypeTranslation = (stepDataType: StepDataType) => {
  const StepDataTypeTranslation: Record<StepDataType, string> = {
    [StepDataType.WITHOUT_TAX_EFFECTS]: 'Sin efectos fiscales',
    [StepDataType.IMG]: 'Imagen',
    [StepDataType.TEXT]: 'Texto',
    [StepDataType.BLOG]: 'Blog',
    [StepDataType.TAX_TO_PAY]: 'Impuesto',
    [StepDataType.PENDING_DECLARATIONS]: 'Declaraciones pendientes',
    [StepDataType.SCORE]: 'Konta score',
    [StepDataType.NON_DEDUCTIBLE_GAS_INVOICES]: 'Facturas de gasolina',
    [StepDataType.DEDUCTIONS_LIMIT_REACHED]: 'Limite de deducciones alcanzado',
    [StepDataType.BALANCE_AVAILABLE_FOR_DEDUCTIONS]: 'Saldo disponible',
  };
  return StepDataTypeTranslation[stepDataType] || stepDataType || 'Sin tipo';
};
