import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Button, Text } from '@konta/ui';
import { User1Icon } from '@konta/icons';
import * as chat from '@util/chat';
import PrevPhaseButton from '@components/PrevPhaseButton';
import WorkflowLayout from '@components/WorkflowLayout';
import WorkflowHeader from '@components/WorkflowHeader';
import IncomeSummaryTable from '@components/IncomeSummaryTable';

function IncomeReviewRequired({ wloading }) {
  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Revisión de ingresos"
          description="Cuando los ingresos facturados en el mes son mayores a los ingresos que tenemos en banco tenemos que revisar la declaración con el contador."
        />
      }
      actions={<PrevPhaseButton />}
    >
      <Text x2l>
        Tus ingresos facturados son mayores a los ingresos declarados, por lo
        que debes validar y clasificar tus ingresos junto con tu contador para
        que todo esté en orden.
      </Text>
      <Text xl>
        Cuando sucede este caso, no hay ningún problema con tu declaración,
        simplemente tenemos que revisar con un contador el caso. Normalmente
        esto sucede por una factura al público general que quedó mal
        categorizada en el proceso.
      </Text>
      <div>
        <Button
          leftIcon={<User1Icon />}
          onClick={chat.open}
          variant="outlined"
          loading={wloading}
        >
          Hablar con un Agente
        </Button>
      </div>
      <Row>
        <Col md="12" lg="7">
          <IncomeSummaryTable />
        </Col>
      </Row>
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ workflows }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { workflow, wloading };
};

export default connect(mapStateToProps)(IncomeReviewRequired);
