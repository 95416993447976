import { FeaturedSearchIcon } from '@konta/icons';
import { Flex, Icon, Text } from '@konta/ui';

export default function EmptyState() {
  return (
    <Flex
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        alignSelf: 'stretch',
      }}
    >
      <Icon
        css={{
          width: '48px',
          height: '48px',
          svg: {
            width: 'auto',
            height: 'auto',
            path: {
              fill: 'none',
            },
          },
        }}
      >
        <FeaturedSearchIcon />
      </Icon>
      <Flex column gap={8} align="center" justify="center">
        <Text bold l>
          No encontramos la declaración
        </Text>
        <Text color="gray500">La declaración no ha sido creada aún</Text>
      </Flex>
    </Flex>
  );
}
