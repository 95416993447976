import React, { Fragment } from 'react';
import { Divider, Flex, Text, Spacer, Button, Alert } from '@konta/ui';
import { PlusCrFrOutlineIcon } from '@konta/icons';
import InvoiceTotalTable from './InvoiceTotalTable';
import InvoiceConcept from './InvoiceConcept';

/**
 * @param {{ invoice: import('@hooks/useInvoiceForm').UseInvoice }} param0
 */
export default function InvoiceConcepts({ invoice }) {
  const { concepts } = invoice.formik.values;
  const conceptsError = invoice.formik.errors.concepts;

  return (
    <Flex direction="column" gap="10">
      <div>
        <Text xl bold color="black">
          Productos o Servicios
        </Text>
        <Divider css={{ my: '4px' }} />
      </div>

      {typeof conceptsError === 'string' && (
        <Alert color="error">{conceptsError}</Alert>
      )}

      {concepts.map((concept, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <InvoiceConcept concept={concept} invoice={invoice} index={index} />
            <Divider />
          </Fragment>
        );
      })}
      <Flex
        css={{
          display: 'flex',
          alignItems: 'flex-start',
          mt: '$16',
          '@<md': { flexWrap: 'wrap' },
        }}
      >
        <Button
          size="s"
          color="primary"
          variant="text"
          leftIcon={<PlusCrFrOutlineIcon />}
          onClick={() => invoice.addConcept()}
          css={{
            '@<md': { mb: '20px' },
          }}
        >
          Agregar producto o servicio
        </Button>
        <Spacer />
        <Flex direction="column" justify="start" align="start">
          <InvoiceTotalTable invoice={invoice} />
        </Flex>
      </Flex>
    </Flex>
  );
}
