import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Collapse, Row } from 'reactstrap';
import { useTable, usePagination, useSortBy } from 'react-table';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import {
  Chip,
  Button,
  Tabs,
  Tab,
  ConfiguredReactTable,
  Flex,
  Text,
  ClipboardCopy,
  Tooltip,
} from '@konta/ui';
import { useToggle } from 'rooks';
import { Eye2Icon, QuestionMarkCrFr2OutlineIcon } from '@konta/icons';
import getInvoiceStatus from '@util/getInvoiceStatus';
import toCurrency from '@util/toCurrency';
import { getDateObjectWithFormat } from '@util/Utils';
import usePpdComplements from '@hooks/useComplements';
import { CustomModal, Colxx } from '@components';
import { getComplements } from '@redux/actions';
import ComplementForm from './ComplementForm';

export default function ComplementTableForm({
  cfdi,
  toggle,
  isWorkflow,
  toggleModal,
  isPfaeAndPlatform,
  taxableEntityPreferences,
}) {
  const dispatch = useDispatch();
  const {
    total,
    currency,
    exchange_rate,
    banxico_exchange_rate,
    declaration_entry_payments,
  } = cfdi || {};

  const { complements, complementsLoading } = usePpdComplements(
    isWorkflow,
    declaration_entry_payments,
  );
  const memoComplements = useMemo(() => complements, [complements]);
  const [showTable, setShowTable] = useToggle(false);
  const [toggleTab, setToggleTab] = useState('manual_total');

  const handleToggleTab = (value) => {
    setToggleTab(value);
  };

  useEffect(() => {
    dispatch(getComplements(cfdi.id));
  }, [dispatch, cfdi.id, toggle]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Parcialidad',
        accessor: 'partiality_number',
        width: 80,
        cellClass: 'cell-small-text',
        Cell: ({ value, row }) => {
          const { source } = row.original;
          return (
            <Text>{value || source.payment_detail.partiality_number}</Text>
          );
        },
      },
      {
        Header: 'Total pagado',
        accessor: 'amount_paid',
        width: 80,
        cellClass: 'cell-small-text',
        Cell: ({ value, row }) => {
          const { source } = row.original;
          return (
            <Text>
              {toCurrency(source ? +source.payment_detail.amount_paid : +value)}
            </Text>
          );
        },
      },
      {
        Header: 'Folio Fiscal',
        accessor: 'fiscal_folio',
        cellClass: 'cell-small-text',
        width: 80,
        Cell: ({ value, row }) => {
          const { source } = row.original;
          const fiscalFolio = isWorkflow
            ? source?.payment_detail.cfdi.folio_fiscal
            : row.original.cfdi?.folio_fiscal;
          const xmlUrl = isWorkflow
            ? source?.payment_detail.cfdi.xml_url
            : row.original.cfdi?.xml_url;
          return (
            <Flex
              css={{
                whiteSpace: 'nowrap',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Tooltip
                triggerElement={
                  <Flex>
                    <Text as="a" href={xmlUrl}>
                      {fiscalFolio}
                    </Text>
                  </Flex>
                }
              >
                Haz click para descargar el XML
              </Tooltip>
              <ClipboardCopy
                clipboard={fiscalFolio}
                variant="text"
                color="black"
                rightIcon={<ContentCopyIcon size="12px" />}
                css={{
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                feedback="Se copió el folio fiscal"
              />
            </Flex>
          );
        },
      },
      {
        Header: 'Estado',
        accessor: 'invoice_status',
        width: 80,
        cellClass: 'cell-small-text',
        Cell: ({ value, row }) => {
          const { source } = row.original;
          const complementStatus = isWorkflow
            ? source?.payment_detail.cfdi.status
            : row.original.cfdi?.status;
          return (
            <Chip
              color={
                {
                  stamped: 'success200',
                  acceptance_pending: 'gray200',
                  not_accepted: 'error200',
                  cancelled: 'error200',
                  pending: 'warning200',
                  null: 'success200',
                  cancellation_in_progress: 'warning200',
                }[complementStatus]
              }
              variant="contained"
              size="s"
              label={getInvoiceStatus(complementStatus)}
            />
          );
        },
      },
      {
        Header: 'Fecha de pago',
        accessor: 'payment_date',
        width: 140,
        cellClass: 'cell-small-text',

        Cell: ({ value, row }) => {
          const { source } = row.original;

          return (
            <Text>
              {' '}
              {getDateObjectWithFormat(
                new Date(source ? source.payment_detail.payment_date : value),
              )}{' '}
            </Text>
          );
        },
      },
      ...(isPfaeAndPlatform
        ? [
            {
              Header: 'Considerado en este mes',
              accessor: 'is_entry_payment',
              width: 140,
              cellClass: 'cell-small-text',

              Cell: ({ row }) => {
                const isEntry = declaration_entry_payments.find((payment) => {
                  return +payment.source_id === +row.original.id;
                });

                return <Text>{isEntry ? 'Si' : 'No'}</Text>;
              },
            },
          ]
        : []),
    ],
    [isWorkflow, declaration_entry_payments],
  );

  const totalInvoice =
    currency !== 'MXN'
      ? total * (exchange_rate || banxico_exchange_rate)
      : total;

  const sumComplementsPaid = useMemo(
    () =>
      complements
        .filter((complement) => {
          const filteredComplement = isWorkflow
            ? complement.source.payment_detail.cfdi?.status !== 'cancelled'
            : complement.cfdi?.status !== 'cancelled';
          return filteredComplement;
        })
        .reduce((acc, complement) => {
          return (
            acc + +complement.amount_paid ||
            acc + +complement.source.payment_detail.amount_paid
          );
        }, 0),
    [complements, isWorkflow],
  );

  const badgeStatus = useMemo(() => {
    const filteredComplements = complements.filter((complement) => {
      const filteredComplement = isWorkflow
        ? complement.source.payment_detail.cfdi?.status !== 'cancelled'
        : complement.cfdi?.status !== 'cancelled';
      return filteredComplement;
    });
    if (filteredComplements.length > 0 && sumComplementsPaid < cfdi.total) {
      return {
        color: 'warning',
        label: 'Incompleto',
      };
    }
    if (filteredComplements.length > 0 && sumComplementsPaid === cfdi.total) {
      return {
        color: 'success',
        label: 'Pagado',
      };
    }
    if (filteredComplements.length === 0) {
      return {
        color: 'gray200',
        label: 'No pagado',
      };
    }
    return {
      color: 'gray200',
      label: 'No pagado',
    };
  }, [cfdi, complements, sumComplementsPaid]);

  const table = useTable(
    {
      columns,
      data: memoComplements,
    },
    useSortBy,
    usePagination,
  );

  return (
    <CustomModal
      title="Agregar complemento de pago"
      toggle={toggleModal}
      isOpen={toggle}
      size="xl"
      handleValidSubmit={toggleModal}
      submitText="Cerrar"
      centered
    >
      <Flex direction="column">
        <p>
          En esta sección puedes ver los complementos de pago ya generados
          anteriormente a esta factura, de igual forma, puedes generar un nuevo
          complemento de pago llenando los datos que se muestran a continuación.{' '}
          {'  '}
        </p>
        {isWorkflow && (
          <p>
            Si ya realizaste tu complemento de pago en otra plataforma o
            directamente del SAT puedes subir el archivo XML de este complemento
            dando click en la opción “Subir XML de complemento de pago”
          </p>
        )}
        <h4 className="mb-2">
          El monto total de la factura original es de{' '}
          <strong>{toCurrency(totalInvoice)} MXN.</strong>{' '}
          <Chip color={badgeStatus.color} size="s" label={badgeStatus.label} />
        </h4>
        <Flex>
          <Text color="gray500">
            El monto total pagado hasta ahora es de{' '}
            <strong>{toCurrency(sumComplementsPaid)} MXN.</strong>{' '}
          </Text>
          <Tooltip
            side="right"
            triggerElement={
              <Flex align="center">
                <QuestionMarkCrFr2OutlineIcon className="button-icon-alert-primary cursor-pointer" />
              </Flex>
            }
          >
            No se contemplan los complementos cancelados
          </Tooltip>
        </Flex>

        <Button
          color="gray"
          css={{ mb: '$16', mt: '$16', width: '250px' }}
          leftIcon={<Eye2Icon />}
          type="button"
          variant={showTable ? 'contained' : 'outlined'}
          onClick={setShowTable}
        >
          Ver lista de complementos
        </Button>
      </Flex>
      <Collapse isOpen={showTable}>
        <Row>
          <Colxx sm={12} className="table-overflow-hidden">
            <ConfiguredReactTable
              {...table}
              loading={complementsLoading}
              noDataText="No hay facturas emitidas"
            />
          </Colxx>
        </Row>
      </Collapse>
      <Flex>
        <Tabs onChange={handleToggleTab} value={toggleTab} variant="switch">
          <Tab type="button" id="manual_total">
            Ingresar total de pago
          </Tab>
          {isWorkflow && (
            <Tab type="button" id="file_total">
              Subir XML de complemento de pago
            </Tab>
          )}
        </Tabs>
      </Flex>

      <Row className="mt-1">
        <ComplementForm
          cfdi={cfdi}
          complements={complements}
          complementsLoading={complementsLoading}
          sumComplementPaid={sumComplementsPaid}
          totalInvoice={totalInvoice}
          uploadComplementType={toggleTab}
          isWorkflow={isWorkflow}
          toggleModal={toggleModal}
          isPfaeAndPlatform={isPfaeAndPlatform}
          taxableEntityPreferences={taxableEntityPreferences}
        />
      </Row>
    </CustomModal>
  );
}
