import React from 'react';
import { useToggle } from 'rooks';
import { Button } from 'reactstrap';
import ProductModalForm from '@components/ProductModalForm';

export default function NewProductButton() {
  const [visible, toggleVisible] = useToggle(false);

  const handleSubmit = () => {
    toggleVisible();
  };

  return (
    <>
      <Button size="sm" color="primary" onClick={toggleVisible}>
        Nuevo Producto
      </Button>
      <ProductModalForm
        isOpen={visible}
        onClose={toggleVisible}
        onSubmit={handleSubmit}
      />
    </>
  );
}
