import { useState } from 'react';
import { Flex, Icon, Tab, Tabs, Text, Chip } from '@konta/ui';
import usePendingTasksStore from 'store/pendingTasksStore';
import Loader from '@components/Loader';
import { TaskStatus } from './types';
import { TABS_CONFIG, AVAILABLE_PROCESSES } from './constants';
import usePendingTask from './hooks/usePendingTask';
import {
  PreOnboardingHomeCardModal,
  DebtsOrdersModal,
  ProcessModal,
  Header,
  TasksList,
} from './components';
import { styles } from './styled';

export default function PendingTasks() {
  const [activeTab, setActiveTab] = useState<TaskStatus>('requiredAction');
  const { selectedTask } = usePendingTasksStore();

  const {
    showPreOnboardingModalTask,
    toggleShowPreOnboardingModalTask,
    filteredPendingTasks,
    showDebsOrderModalTask,
    toggleShowDebsOrderModalTask,
    tasksLoading,
  } = usePendingTask();

  if (tasksLoading) {
    return (
      <Flex direction="column" css={styles.loaderContainer}>
        <Loader />
      </Flex>
    );
  }

  const renderTabs = () => (
    <Tabs
      value={activeTab}
      onChange={setActiveTab}
      variant="switch"
      indicator="bottom"
    >
      {TABS_CONFIG.map(({ id, icon, label }) => (
        <Tab key={id} id={id} css={styles.tab}>
          <Icon css={styles.tabIcon} size={24}>
            {icon}
          </Icon>
          <Text s>{label}</Text>
          <Chip
            label={filteredPendingTasks[id].length.toString()}
            color="gray100"
          />
        </Tab>
      ))}
    </Tabs>
  );

  const shouldShowProcessModal = selectedTask?.type
    ? AVAILABLE_PROCESSES.includes(selectedTask.type)
    : false;
  const renderModals = () => (
    <>
      <PreOnboardingHomeCardModal
        openModal={Boolean(showPreOnboardingModalTask)}
        toggleOpenModal={toggleShowPreOnboardingModalTask}
      />

      <DebtsOrdersModal
        openModal={Boolean(showDebsOrderModalTask)}
        toggleOpenModal={toggleShowDebsOrderModalTask}
      />

      {shouldShowProcessModal && <ProcessModal />}
    </>
  );

  return (
    <>
      <Flex gap={24} direction="column" className="app-container">
        <Header />
        <Flex css={styles.tabsContainer}>{renderTabs()}</Flex>
        <TasksList tab={activeTab} />
      </Flex>

      {renderModals()}
    </>
  );
}
