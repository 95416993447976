import {
  DOCUMENTS_FETCH,
  DOCUMENTS_FETCH_SUCCESS,
  DOCUMENTS_FETCH_FAILURE,
  DOCUMENTS_POST,
  DOCUMENTS_POST_SUCCESS,
  DOCUMENTS_POST_FAILURE,
} from '@constants/actionTypes';

export const fetchDocs = (_) => ({
  type: DOCUMENTS_FETCH,
});
export const fetchDocsSuccess = (documents) => ({
  type: DOCUMENTS_FETCH_SUCCESS,
  payload: documents,
});
export const fetchDocsFailure = () => ({
  type: DOCUMENTS_FETCH_FAILURE,
});

export const postDocs = (document, category) => ({
  type: DOCUMENTS_POST,
  payload: { document, category },
});
export const postDocsSuccess = (documents) => ({
  type: DOCUMENTS_POST_SUCCESS,
  payload: documents,
});
export const postDocsFailure = () => ({
  type: DOCUMENTS_POST_FAILURE,
});
