import type { Filters } from '@konta/ui';
import type { DeclarationEntry } from 'types/entities';

export default function filteredDeclarationsEntries(
  declarationEntries: DeclarationEntry[],
  filters: Filters,
) {
  return declarationEntries.filter((item) =>
    filters.every((filter) => {
      if (!filter.value) {
        return true;
      }
      if (filter.id === 'fiscal_regime_id') {
        return filter.value === item.fiscal_regime_id.toString();
      }
      if (filter.id === 'declaration_entry_type') {
        const { source, declaration_entry_type } = item;
        if (!source) {
          return true;
        }
        const { type } = source;
        if (filter.value === 'foreignInvoices') {
          return type === 'foreign_invoice';
        }
        if (filter.value === 'foreignProducts') {
          return type === 'foreign_product';
        }
        if (filter.value === 'cfdi') {
          return (
            type === 'cfdi' && declaration_entry_type === 'national_expenses'
          );
        }
        if (filter.value === 'fixedAsset') {
          return type === 'fixed_asset';
        }
        if (filter.value === 'creditNote') {
          return (
            type === 'cfdi' &&
            declaration_entry_type === 'national_egress_received'
          );
        }
      }

      return true;
    }),
  );
}
