import { styled } from '@konta/ui';

export const StepCard = styled('div', {
  display: 'flex',
  padding: '12px 0px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
  borderRadius: '$m',
  background: 'white',
});
export const StepCardContent = styled('div', {
  display: 'flex',
  padding: '24px 20px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '12px',
  flex: '1 0 0',
  borderRadius: '$m',
  border: '1px solid $gray200',
  background: '$gray50',
  width: '100%',
});

export const StepConnector = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  alignSelf: 'stretch',
});

export const StepConectorFeatureIcon = styled('div', {
  display: 'flex',
  width: '48px',
  height: '48px',
  padding: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  border: '1px solid $gray200',
  background: 'white',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
});

export const Title = styled('span', {
  color: '$gray900',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '32px',
});

export const BreadcrumbsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  pb: '22px',
  background: '$white',
  width: '100%',
  borderBottom: '1px solid $gray200',
});
