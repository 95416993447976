import { useCallback } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { putTaxableEntityFlow } from 'shared/api/taxableEntityFlows';
import type { AxiosRequestConfig } from 'axios';
import type {
  TaxableEntityFlow,
  TaxableEntityFlowUpdatePayload,
} from 'types/flows';

export default function usePutTaxableEntityFlow(
  axiosConfig?: AxiosRequestConfig<TaxableEntityFlow>,
  queryOptions?: UseMutationOptions<
    TaxableEntityFlow,
    unknown,
    TaxableEntityFlowUpdatePayload
  >,
) {
  const putTaxableEntityFlowRequest = useCallback(
    (payload: TaxableEntityFlowUpdatePayload) =>
      putTaxableEntityFlow(payload, axiosConfig),
    [axiosConfig],
  );
  return useMutation(putTaxableEntityFlowRequest, queryOptions);
}
