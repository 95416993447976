import { styled } from '@konta/ui';

export const Root = styled('div', {
  width: '100%',
  height: '100%',
  padding: '24px 32px 12px 32px',
  overflowY: 'scroll',
  gap: '16px',
  display: 'flex',
  flexDirection: 'column',
});

export const MessageWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '6px',
  flex: '1 0 0',
  maxWidth: '80%',
  width: 'fit-content',
  variants: {
    isMe: {
      true: {
        alignItems: 'flex-end',
        marginLeft: 'auto',
      },
    },
  },
});

export const NameAndTime = styled('div', {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  alignSelf: 'stretch',
  justifyContent: 'space-between',
});

export const Name = styled('span', {
  color: '$gray700',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
});

export const Time = styled('span', {
  color: '$gray500',
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: 400,
});

export const Message = styled('div', {
  display: 'flex',
  padding: '10px 14px',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  borderRadius: '8px 0 8px 8px',
  background: '$primary600',
  variants: {
    isMe: {
      false: {
        borderRadius: '0 8px 8px 8px',
        background: '$gray100',
      },
    },
  },
});

export const MessageText = styled('span', {
  color: '$white',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  variants: {
    isMe: {
      false: {
        color: '$gray900',
      },
    },
  },
});

export const LastMessage = styled('div', {});
