import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { FileWithPath } from 'react-dropzone';
import { useToggle } from 'rooks';
import { NotificationManager } from '@components';
import useWorkflow from '@components/Workflow/hooks/useWorkflow';
import getErrorMessage from '@util/getErrorMessage';
import usePostDeclarationEntries from 'shared/hooks/usePostDeclarationEntries';
import { Workflow } from 'types/entities';
import { DECLARATION_ENTRIES_BY_WORKFLOW } from 'shared/constants/reactQueries';

export interface ExtendedFileWithPath extends FileWithPath {
  isValidFile?: boolean;
  newDeclaration?: {
    label: string;
    value: number;
    declarationId: number;
  };
}

const notificationTitle = 'XML cargado exitosamente';
interface UseUploadConstancyModalProps {
  toggleOpenUploadModal: () => void;
  source_type: string;
}

const createPayload = (
  file: ExtendedFileWithPath,
  source_type: string,
  workflow?: Workflow,
): FormData => {
  const { newDeclaration } = file;
  const { taxable_entity_id, start_date } = workflow || {};
  const fileType = source_type === 'Constancy' ? 'file' : 'xml';
  const payload = new FormData();
  payload.append(`declaration_entry[source_attributes][${fileType}]`, file);
  payload.append('declaration_entry[accounting_status]', 'is_deductible');
  payload.append('declaration_entry[source_type]', source_type);
  payload.append(
    'declaration_entry[declaration_id]',
    newDeclaration?.declarationId?.toString() || '',
  );
  payload.append(
    'declaration_entry[taxable_entity_id]',
    taxable_entity_id?.toString() || '',
  );
  payload.append(
    'declaration_entry[accounting_date]',
    start_date?.toString() || '',
  );

  return payload;
};

export default function useUploadConstancyModal({
  toggleOpenUploadModal,
  source_type,
}: UseUploadConstancyModalProps) {
  const [showConfirmation, setShowConfirmation] = useToggle(false);
  const [selectRegimeView, setSelectRegimeView] = useToggle(false);
  const [xmlFiles, setXmlFiles] = useState<ExtendedFileWithPath[]>([]);
  const postDeclarationEntries = usePostDeclarationEntries();
  const {
    workflow,
    currentRegimes,
    isLoadingWorkflow,
    activeDeclarations,
    regimeOptionsWithDeclarationId,
    updateCurrentWorkflow,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  } = useWorkflow();

  const queryClient = useQueryClient();

  const toggleShowConfirmation = () => {
    setXmlFiles([]);
    setShowConfirmation();
  };

  const handleOnSubmit = () => {
    const hasFilesWithoutDeclaration = xmlFiles.every(
      (file) => file.newDeclaration,
    );
    const hasValidFiles = xmlFiles.every((file) => file.isValidFile);

    if (!hasValidFiles) {
      NotificationManager.error(
        `Archivo de CFDI inválido`,
        'Error al subir archivos',
      );
      return;
    }
    if (!hasFilesWithoutDeclaration) {
      NotificationManager.error(
        `Por favor selecciona el régimen para cada archivo`,
        'Error al subir archivos',
      );
      return;
    }

    xmlFiles.forEach(async (file) => {
      try {
        const payload = createPayload(file, source_type, workflow as Workflow);
        await postDeclarationEntries.mutateAsync(payload);
        NotificationManager.success(
          `Se ha agregado el archivo exitosamente`,
          notificationTitle,
        );
        updateCurrentWorkflow();
        await queryClient.invalidateQueries([DECLARATION_ENTRIES_BY_WORKFLOW]);
        toggleShowConfirmation();
        toggleOpenUploadModal();
        setSelectRegimeView();
      } catch (error) {
        const errorMessage = getErrorMessage(error) as string;
        NotificationManager.error(errorMessage, 'Error al subir archivos');
      }
    });
  };

  const isLoading = postDeclarationEntries.isLoading || isLoadingWorkflow;

  return {
    isLoading,
    toggleShowConfirmation,
    showConfirmation,
    xmlFiles,
    setXmlFiles,
    handleOnSubmit,
    activeDeclarations,
    workflow,
    currentRegimes,
    selectRegimeView,
    setSelectRegimeView,
    regimeOptionsWithDeclarationId,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  };
}
