import React, { Component } from 'react';
import { ReportsTransactionsHistory } from '@containers/Reports';
import {
  TabList,
  FiscalPeriodInvoice,
  DatePickerPopover,
  Colxx,
} from '@components';
import { Row, Nav, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import { uiChangeDate } from '@redux/actions';
import PropTypes from 'prop-types';

// TODO remove fetch financial transactions from uiChangeDate and resolve for inovicing

class HistoryView extends Component {
  state = {
    activeTab: '1',
  };

  componentDidMount() {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    this.props.uiChangeDate(date);
  }

  handleChange = (date) => {
    this.props.uiChangeDate(date);
  };

  toggleTab = (activeTab) => {
    if (this.state.activeTab !== activeTab) this.setState({ activeTab });
  };

  render() {
    const { activeTab } = this.state;
    const {
      fiscal_period,
      selected_period,
      ui: { date },
    } = this.props;
    return (
      <div className="app-container">
        <Row>
          <Colxx xxs="12">
            <Nav tabs className="separator-tabs position-relative ml-0 mb-5">
              <TabList
                tabs={['reports.tabs.reports', 'reports.tabs.summary']}
                activeTab={activeTab}
                toggleTab={this.toggleTab}
              />
              <DatePickerPopover
                target="btn-datepicker"
                date={date}
                handleChange={this.handleChange}
              />
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row className="mb-4">
                  <Colxx xxs="12">
                    <ReportsTransactionsHistory
                      legal_type="business_activity"
                      fiscal_period={fiscal_period}
                    />
                  </Colxx>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row className="mb-4">
                  <Colxx xxs="lg-6">
                    <FiscalPeriodInvoice fiscal_period={selected_period} />
                  </Colxx>
                </Row>
              </TabPane>
            </TabContent>
          </Colxx>
        </Row>
      </div>
    );
  }
}

HistoryView.propTypes = {
  fiscal_period: PropTypes.any,
  loading: PropTypes.any,
  selected_period: PropTypes.any,
  taxable_entity: PropTypes.any,
  ui: PropTypes.any,
  uiChangeDate: PropTypes.any,
};

const mapStateToProps = ({
  financialTransactions,
  taxableEntity: { taxable_entity },
  fiscalPeriods: {
    fiscal_period,
    ui: { selected_period },
  },
}) => {
  return {
    taxable_entity,
    fiscal_period,
    selected_period,
    ...financialTransactions,
  };
};

export default connect(mapStateToProps, {
  uiChangeDate,
})(HistoryView);
