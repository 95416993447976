import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useToggle } from 'usehooks-ts';
import { Modal } from '@konta/ui';
import { ProcessType } from 'types/entities';
import useProcessNavigation from '@hooks/useProcessNavigation';
import WelcomeAnnual from './WelcomeAnnual';
import ChargesAnnual from './ChargesAnnual';
import PreferencesAnnual from './PreferencesAnnual';
import DeclineAnnual from './DeclineAnnual/DeclineAnnual';
import RecommendationsAnnual from './RecommendationsAnnual/RecommendationsAnnual';
import UploadAnnual from './UploadAnnual/UploadAnnual';
import InformativeAnnual from './InformativeAnnual';
import DocumentsAnnual from './DocumentsAnnual/DocumentsAnnual';
import Portal from '../Portal';
import RoutesResumeAnnual from './RoutesResumeAnnual';

export default function Annual() {
  const { processId } = useParams();
  if (!processId) {
    throw new Error('No processId provided');
  }
  useProcessNavigation(ProcessType.AnnualDeclarationProcess, +processId);
  const [open] = useToggle(true);
  return (
    <Portal target="#root">
      <Modal
        open={open}
        size="full-screen"
        id="annual-modal"
        css={{
          '&:first-child > div': {
            width: '100%',
          },
        }}
      >
        <Routes>
          <Route path="/welcome" element={<WelcomeAnnual />} />
          <Route path="/charges" element={<ChargesAnnual />} />
          <Route path="/documents" element={<DocumentsAnnual />} />
          <Route path="/informative" element={<InformativeAnnual />} />
          <Route path="/resume" element={<RoutesResumeAnnual isProcess />} />
          <Route path="/preferences" element={<PreferencesAnnual />} />
          <Route path="/rejection" element={<DeclineAnnual />} />
          <Route path="/recommendations" element={<RecommendationsAnnual />} />
          <Route path="/upload" element={<UploadAnnual />} />
          <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
      </Modal>
    </Portal>
  );
}
