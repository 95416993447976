import camelcaseKeys from 'camelcase-keys';
import type { DeclarationExpense } from 'types/entities';

export default function getTotalsFromDeclarationExpense(
  declarationExpense: DeclarationExpense,
) {
  const {
    totalIvaBases,
    totalZeroBases,
    totalExemptBases,
    totalRetainedIva,
    totalRetainedIsr,
    totalIva,
    accreditableIsrAverage,
    accreditableIvaAverage,
    nonAccreditableIva,
    sumIvaBasesOfFixedAssets,
    sumIvaBasesOfActiveCfdis,
    totalIvaBasesOfForeignProducts,
    sumIvaBasesOfEgress,
    sumIvaBasesOfCancelledCfdis,
    totalIvaOfForeignInvoices,
    foreignProductsNonTaxableAmountTotal,
    total,
    totalBasesOfForeignInvoices,
    sumIvaOfEgress,
    sumExemptBasesOfEgress,
    sumZeroBasesOfEgress,
  } = camelcaseKeys(declarationExpense);

  const tax16BasesTotal = +totalIvaBases;
  const tax16TaxTotal = +totalIva;
  const tax16RetainedTotal = +totalRetainedIva + +totalRetainedIsr;
  const tax16GrandTotal = tax16BasesTotal + tax16TaxTotal - tax16RetainedTotal;

  const tax0BasesTotal = +totalZeroBases - +sumZeroBasesOfEgress;
  const tax0TaxTotal = 0;
  const tax0RetainedTotal = 0;
  const tax0GrandTotal = tax0BasesTotal + tax0TaxTotal - tax0RetainedTotal;

  const taxExemptBasesTotal = +totalExemptBases - +sumExemptBasesOfEgress;
  const taxExemptTaxTotal = 0;
  const taxExemptRetainedTotal = 0;
  const taxExemptGrandTotal =
    taxExemptBasesTotal + taxExemptTaxTotal - taxExemptRetainedTotal;

  const grandBasesTotal =
    +tax16BasesTotal + +tax0BasesTotal + +taxExemptBasesTotal;
  const grandTotalBases =
    +tax16BasesTotal + +tax0BasesTotal + +taxExemptBasesTotal;
  const grandTaxesTotal = +totalIva + +tax0TaxTotal + +taxExemptTaxTotal;
  const grandRetainedTotal =
    +tax16RetainedTotal + +tax0RetainedTotal + +taxExemptRetainedTotal;
  const grandTotal = +grandBasesTotal + +grandTaxesTotal + -+grandRetainedTotal;

  const nationExpensesWithIva =
    +sumIvaBasesOfActiveCfdis +
    +sumIvaBasesOfEgress -
    +sumIvaBasesOfCancelledCfdis;

  const foreignExpensesWithIva =
    +totalIvaBasesOfForeignProducts + +totalIvaOfForeignInvoices;

  const foreignExpensesWithIvaAndIsr =
    +foreignProductsNonTaxableAmountTotal + foreignExpensesWithIva;

  // Gastos en el extranjero con Pedimento
  const foreignProductTotal =
    +totalIvaBasesOfForeignProducts + +foreignProductsNonTaxableAmountTotal;
  // Gastos en el extranjero con Invoice
  const foreignInvoicesTotal = +totalBasesOfForeignInvoices;

  // GOOD
  // total de iva en gastos
  const creditableTotal = +accreditableIvaAverage + +nonAccreditableIva;
  const creditablePercentage = +accreditableIvaAverage
    ? +accreditableIvaAverage / +creditableTotal
    : 0;

  return {
    tax16BasesTotal,
    tax16TaxTotal,
    tax16RetainedTotal,
    tax16GrandTotal,
    tax0BasesTotal,
    tax0TaxTotal,
    tax0RetainedTotal,
    tax0GrandTotal,
    taxExemptBasesTotal,
    taxExemptTaxTotal,
    taxExemptRetainedTotal,
    taxExemptGrandTotal,
    grandBasesTotal,
    grandTaxesTotal,
    grandRetainedTotal,
    grandTotal,
    grandTotalBases,
    accreditableIsrAverage: +accreditableIsrAverage,
    sumIvaBasesOfFixedAssets: +sumIvaBasesOfFixedAssets,
    nationExpensesWithIva,
    foreignExpensesWithIva: foreignInvoicesTotal,
    foreignExpensesWithIvaAndIsr,
    total: +total,
    totalIvaBases: +totalIvaBases,
    totalExemptBases: +totalExemptBases,
    totalZeroBases: +totalZeroBases,
    creditableTotal,
    creditablePercentage,
    accreditableIvaAverage: +accreditableIvaAverage,
    sumIvaOfEgress: +sumIvaOfEgress,
    sumIvaBasesOfActiveCfdis: +sumIvaBasesOfActiveCfdis - +sumIvaBasesOfEgress,
    nonAccreditableIva: +nonAccreditableIva,
  };
}
