import { useState } from 'react';
import useCfdiPdfById from 'backoffice/hooks/useCfdiPdfById';
import api from '@api';
import getErrorMessage from '@util/getErrorMessage';
import { NotificationManager } from '@components';

interface UseCfdiPreviewPdfProps {
  cfdiId?: string | number;
  accessToken?: string;
}

export default function useCfdiPreviewPdf({
  cfdiId,
  accessToken,
}: UseCfdiPreviewPdfProps) {
  const [downloadXmlLoading, setDownloadXmlLoading] = useState(false);
  const id = cfdiId || 0;
  const { cfdiPdf, cfdiPdfLoading } = useCfdiPdfById(
    id,
    {
      ...(accessToken && {
        headers: {
          Authorization: accessToken,
        },
      }),
    },
    {
      enabled: !!cfdiId,
    },
  );

  const handleOnClickDownloadXml = async () => {
    try {
      setDownloadXmlLoading(true);
      const response = await api.post<{ url: string }>(
        `/download`,
        {
          resource: 'cfdi',
          id: cfdiId,
          attribute: 'xml',
        },
        {
          headers: {
            ...(accessToken && {
              Authorization: accessToken,
            }),
          },
        },
      );
      if (!response?.data?.url) {
        throw new Error('No se encontró el archivo');
      }
      const a = document.createElement('a');
      a.href = response.data.url;
      a.click();
      a.remove();
    } catch (e) {
      const errorMessage = getErrorMessage(
        e,
        'Error al descargar el XML',
      ) as string;
      NotificationManager.error(errorMessage, 'Descargar XML');
    } finally {
      setDownloadXmlLoading(false);
    }
  };

  const handleOnClickDownloadPdf = async () => {
    if (!cfdiPdf || !id) {
      NotificationManager.error('Error al descargar el PDF', 'Descargar XML');
      return;
    }
    const a = document.createElement('a');
    a.href = cfdiPdf;
    a.download = `${id}.pdf`;
    a.click();
    a.remove();
  };

  return {
    cfdiPdf,
    cfdiPdfLoading,
    downloadXmlLoading,
    handleOnClickDownloadXml,
    handleOnClickDownloadPdf,
  };
}
