import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  postClientsSuppliersSuccess,
  postClientsSuppliersFailure,
} from '../actions';

export default function* ({ payload: { taxable_entity, client_supplier } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.post,
      `taxable_entities/${taxable_entity.id}/client_suppliers`,
      {
        client_supplier,
      },
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      response,
      title: 'Cliente añadido',
      message: 'Se ha agregado exitosamente',
      status: response.status,
      success: postClientsSuppliersSuccess,
      failure: postClientsSuppliersFailure,
    });
  } catch (error) {
    const isRfcTaken =
      error.response.data.errors?.rfc.includes('ya ha sido tomado');
    // catch throw
    yield call(ErrorHandler, {
      title: 'Error al agregar al cliente',
      message: 'Este cliente ya ha sido agregado',
      showBodyErrors: !!isRfcTaken,
      error,
      action: postClientsSuppliersFailure,
    });
  }
}
