import React, { useContext, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@redux/store';
import _ from 'lodash';
import { TrackerContext } from './TrackerProvider';

export default function ReduxProvider({ children }) {
  const { initTracker, pluginsReturnedValues } = useContext(TrackerContext);
  const [store, setStore] = useState(null);

  useEffect(() => {
    initTracker();
  }, [initTracker]);

  useEffect(() => {
    if (_.isEmpty(pluginsReturnedValues)) return;
    const middlewares = [];
    if (pluginsReturnedValues?.redux) {
      middlewares.push(pluginsReturnedValues?.redux);
    }
    setStore(configureStore(undefined, middlewares));
  }, [pluginsReturnedValues]);

  return store && <Provider store={store}>{children}</Provider>;
}
