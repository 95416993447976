import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, ImageVideoModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

function PaperWork() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />

      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p className="font-weight-bold">Sincronización de Facturas</p>
          <p>
            El sistema automáticamente carga sus facturas de ingresos y gastos
            para que pueda comenzar a realizar el cálculo de sus impuestos.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
      <Row>
        <Colxx md="12">
          <p className="font-weight-bold">Constancia de retención</p>
          <p>
            La Constancia de Sueldos, Salarios, Conceptos Asimilados, Crédito al
            Salario y Subsidio para el Empleo, es un documento fiscal que
            contiene un registro de las percepciones que realizan los
            contribuyentes.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
    </>
  );
}
export default PaperWork;
