import api from '@api';
import { Process } from 'types/entities';

export interface PutProcessesOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  id: string | number;
}

export default async function putProcesses({
  payload,
  id,
}: PutProcessesOptions) {
  const { data } = await api.put<Process>(
    `onboarding_processes/${id}`,
    payload,
  );

  return data;
}
