import ImageUploading from 'react-images-uploading';
import { ImageUploadOuLc2OutlineIcon, TrashCanOutlineIcon } from '@konta/icons';
import { Button, Flex, Text } from '@konta/ui';
import Loader from '@components/Loader';
import FormikCheckbox from '@components/FormikCheckbox';
import useSettingsBrandForm from './useSettingsBrandForm';

type LogoSettingsBrandProps = Pick<
  ReturnType<typeof useSettingsBrandForm>,
  | 'formik'
  | 'loadingTaxableEntity'
  | 'uploadTaxableEntity'
  | 'uploadTaxableEntityLogo'
>;
export default function LogoSettingsBrand({
  formik,
  loadingTaxableEntity,
  uploadTaxableEntity,
  uploadTaxableEntityLogo,
}: LogoSettingsBrandProps) {
  return (
    <>
      <Flex
        css={{
          gap: '32px',
          justifyContent: 'space-between',
          maxWidth: '100%',
          '@md': { maxWidth: '500px' },
        }}
      >
        <Flex align="center" gap={6}>
          <Text>Logo</Text>
        </Flex>
        <Flex>
          {loadingTaxableEntity ? (
            <Loader />
          ) : (
            <ImageUploading
              acceptType={['jpg', 'png', 'jpeg']}
              multiple={false}
              value={formik.values.logo}
              onChange={async (imageList) => {
                if (imageList?.length === 0) {
                  uploadTaxableEntity({
                    taxable_entity: { logo: null },
                  });
                } else if (imageList[0].dataURL) {
                  uploadTaxableEntityLogo(imageList[0].dataURL);
                }
                await formik.setFieldValue('logo', imageList);
              }}
              maxNumber={1}
            >
              {({
                imageList,
                onImageUpload,
                onImageUpdate,
                onImageRemove,
                dragProps,
              }) => (
                <div className="upload__image-wrapper">
                  {imageList.length === 0 && (
                    <Button
                      onClick={onImageUpload}
                      variant="outlined"
                      size="xs"
                      {...dragProps}
                    >
                      Haga clic o suelte aquí
                    </Button>
                  )}
                  {imageList.map((image, index) => (
                    <Flex
                      direction="column"
                      gap={6}
                      key={image.file?.name || index}
                      align="center"
                      justify="center"
                    >
                      <img
                        src={image.dataURL}
                        alt="logo"
                        width="100"
                        style={{ borderRadius: '5px' }}
                      />
                      <Flex gap={6}>
                        <Button
                          size="xs"
                          variant="outlined"
                          leftIcon={<ImageUploadOuLc2OutlineIcon />}
                          onClick={() => onImageUpdate(index)}
                        >
                          Actualizar
                        </Button>
                        <Button
                          size="xs"
                          variant="outlined"
                          leftIcon={<TrashCanOutlineIcon />}
                          onClick={() => onImageRemove(index)}
                        >
                          Eliminar
                        </Button>
                      </Flex>
                    </Flex>
                  ))}
                </div>
              )}
            </ImageUploading>
          )}
        </Flex>
      </Flex>
      <Flex
        css={{
          gap: '32px',
          justifyContent: 'space-between',
          maxWidth: '100%',
          '@md': { maxWidth: '500px' },
        }}
      >
        <Flex>
          {loadingTaxableEntity ? (
            <Loader />
          ) : (
            <FormikCheckbox
              id="showLogoInInvoice"
              name="showLogoInInvoice"
              label="Habilitar logo en la factura"
              onChange={(value) => {
                const showLogoInInvoice = value as boolean;
                uploadTaxableEntity({
                  taxable_entity: {
                    show_logo_in_invoice: showLogoInInvoice,
                  },
                });
              }}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
}
