import { CLIENT_RFC_VALIDATION } from '@constants/featureFlags';
import verifyRfc from '@util/verifyRfc';
import { useCallback } from 'react';
import useFeature from './useFeature';

/**
 * @param {{ source: string }} param0
 * @returns {typeof verifyRfc}
 */
export default function useVerifyRfc({ source }) {
  const [rfcValidationEnabled] = useFeature(CLIENT_RFC_VALIDATION, {
    source,
  });

  return useCallback(
    (config) => {
      if (rfcValidationEnabled) {
        return verifyRfc(config);
      }
      return Promise.resolve({ fixedName: config.legalName });
    },
    [rfcValidationEnabled],
  );
}
