import { AxiosRequestConfig } from 'axios';
import api from '@api';
import { Cfdi } from '../types/cfdi';

export default async function getInvoiceDraft(
  draftId: number | string,
  config?: AxiosRequestConfig<Cfdi>,
) {
  const { data } = await api.get<Cfdi>(`/invoice_drafts/${draftId}`, config);
  return data;
}
