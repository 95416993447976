import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  declarationEntriesFetchSuccess,
  declarationEntriesFetchFailure,
} from '../actions';

export default function* ({ payload: { workflow_id } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.get,
      `declaration_entries/by_workflow?workflow_id=${workflow_id}`,
      { headers: { Authorization: `${access_token}` } },
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: declarationEntriesFetchSuccess,
      failure: declarationEntriesFetchFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: declarationEntriesFetchFailure,
    });
  }
}
