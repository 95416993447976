import { ChangeEvent } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import * as validation from '@util/validation';
import isGenericRfc from '@util/isGenericRfc';
import { rfcFormatter, trimFormatter } from '@util/formatters';
import getErrorMessage from '@util/getErrorMessage';
import { NotificationManager } from '@components';
import { PublicClientSupplierCreatePayload } from 'types/entities';
import useCreatePublicClientSupplier from '@hooks/useCreatePublicClientSupplier';
import getGreetingText from '@util/getGreetingText';
import usePublicTaxableEntity from '@hooks/usePublicTaxableEntity';
import fiscalRegimensOptions from './fiscalRegimensOptions';

const validationSchema = yup.object({
  legalName: yup.string().required('Campo requerido'),
  rfc: yup
    .string()
    .required('Campo requerido')
    .test('format', 'Por favor ingrese un RFC con formato valido', (rfc) =>
      validation.rfc(rfc || ''),
    )
    .test(
      'generic',
      'RFC Genérico no permitido',
      (rfc) => !isGenericRfc(rfc || ''),
    ),
  email: yup
    .string()
    .email('El correo electrónico debe ser válido')
    .required('Campo requerido'),
  fiscalRegimes: yup
    .number()
    .required('Campo requerido')
    .min(1, 'Campo requerido'),
  cfdiUsage: yup.string(),
  paymentType: yup.string(),
  postcode: yup
    .string()
    .typeError('Tipo inválido, consulte con soporte')
    .test('required', 'Campo requerido', (value) => !!value)
    .test('format', 'Formato invalido (deben ser 5 números)', (value) =>
      /^\d{5}$/.test(value || ''),
    ),
});

const defaultInitialValues = {
  legalName: '',
  rfc: '',
  email: '',
  fiscalRegimes: 0,
  postcode: '',
  cfdiUsage: '',
  paymentType: '',
};

export type ClientFormValues = yup.InferType<typeof validationSchema>;

function useInitialValues(): ClientFormValues {
  return defaultInitialValues;
}

function valuesToPayload(
  values: ClientFormValues,
  taxableEntityUuid: string,
): PublicClientSupplierCreatePayload {
  return {
    taxableEntityUuid,
    payload: {
      client_supplier: {
        relationship_type: 'client',
        legal_name: values.legalName.trim(),
        rfc: values.rfc,
        email: values.email,
        fiscal_regime_ids: [values.fiscalRegimes],
        source: 'customer',
        payment_form_key: values.paymentType,
        cfdi_use_key: values.cfdiUsage,
        address_attributes: {
          postcode: values.postcode,
        },
      },
    },
  };
}

export default function useNewClient() {
  const greetingText = getGreetingText();
  const initialValues = useInitialValues();
  const createPublicClientSupplier = useCreatePublicClientSupplier();
  const params = useParams();
  const { taxableEntityUuid } = params;
  const { publicTaxableEntityLoading, publicTaxableEntity } =
    usePublicTaxableEntity(taxableEntityUuid || '');
  const handleSubmit = async (
    loginFormValues: ClientFormValues,
    resetForm: () => void,
  ) => {
    try {
      if (!taxableEntityUuid) {
        throw new Error('No se pudo obtener el uuid de la entidad fiscal');
      }
      const payload = valuesToPayload(loginFormValues, taxableEntityUuid);
      await createPublicClientSupplier.mutateAsync(payload);
      NotificationManager.success(
        'Se han compartido los datos con éxito',
        'Datos de facturación',
      );
      resetForm();
    } catch (e) {
      console.error(e);
      const errorMessage = getErrorMessage(e) as string;
      NotificationManager.error(errorMessage, 'Datos de facturación');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      void handleSubmit(values, resetForm);
    },
  });

  const handleRfcChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rfc = rfcFormatter(e.target.value);
    void formik.setFieldValue('rfc', rfc);
  };
  const handleInputBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const value = trimFormatter(e.target.value);
    void formik.setFieldValue(e.target.name, value);
  };

  const legalName = publicTaxableEntity?.legal_name || '';
  const logoUrl = publicTaxableEntity?.logo_url || '';

  return {
    formik,
    fiscalRegimensOptions,
    handleRfcChange,
    handleInputBlur,
    isLoading:
      createPublicClientSupplier.isLoading || publicTaxableEntityLoading,
    greetingText,
    publicTaxableEntity,
    legalName,
    logoUrl,
  };
}
