import { Divider, Flex, Text } from '@konta/ui';
import { FormikProvider } from 'formik';
import useSettingsBrandForm from './useSettingsBrandForm';
import LogoSettingsBrand from './LogoSettingsBrand';
import ColorSettingsBrands from './ColorSettingsBrand';
import TemplateSettingsBrand from './TemplateSettingsBrand';
import InvoicePreview from './InvoicePreview';
import IncrementalFolioSettings from './IncrementalFolioSettings';

export default function SettingsBrand() {
  const {
    formik,
    loadingTaxableEntity,
    uploadTaxableEntity,
    uploadTaxableEntityLogo,
    loadingPreview,
    invoiceDraft,
    loadingUpdateTaxableEntityPreferences,
    uploadTaxableEntityPreference,
    defaultFolio,
  } = useSettingsBrandForm();

  return (
    <FormikProvider value={formik}>
      <Flex css={{ gap: '32px', flexDirection: 'column' }}>
        <Flex direction="column" gap={12}>
          <Text semibold size="x3l" lineHeight="2xl">
            Imagen de marca
          </Text>
          <Text color="gray700" size="l" lineHeight="l">
            Personaliza cómo le aparecerá tu marca a tus clientes.
          </Text>
        </Flex>
        <Flex
          css={{
            gap: 30,
            flexDirection: 'column',
            '@md': { flexDirection: 'row' },
          }}
        >
          <Flex css={{ flex: 1.3, flexDirection: 'column', gap: 16 }}>
            <LogoSettingsBrand
              formik={formik}
              loadingTaxableEntity={loadingTaxableEntity}
              uploadTaxableEntity={uploadTaxableEntity}
              uploadTaxableEntityLogo={uploadTaxableEntityLogo}
            />
            <Divider />
            <ColorSettingsBrands
              formik={formik}
              loadingUpdateTaxableEntityPreferences={
                loadingUpdateTaxableEntityPreferences
              }
            />
            <Divider />
            <TemplateSettingsBrand
              loadingUpdateTaxableEntityPreferences={
                loadingUpdateTaxableEntityPreferences
              }
            />
            <Divider />
            <IncrementalFolioSettings
              defaultFolio={defaultFolio}
              uploadTaxableEntityPreference={uploadTaxableEntityPreference}
              formik={formik}
              loadingUpdateTaxableEntityPreferences={
                loadingUpdateTaxableEntityPreferences
              }
            />
            <Divider />
          </Flex>
          <InvoicePreview
            invoiceDraft={invoiceDraft}
            loadingPreview={loadingPreview}
          />
        </Flex>
      </Flex>
    </FormikProvider>
  );
}
