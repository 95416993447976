import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button, Flex, Input, Text } from "@konta/ui";
import { NotificationManager } from '@components';
import useDeleteTwoFactorAuth from '@hooks/auth/useDeleteTwoFactorAuth';
import { updateUserOtpRequiredForLogin } from '@redux/auth/actions';
import { useDispatch } from 'react-redux';

export default function DisabledTwoFactorModal({ toggle, isOpen, size }) {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');

  const handleError = () => {
    NotificationManager.error(
      'Código erróneo, intenta con otro.',
      'Autenticación de dos pasos',
      5000,
      null,
      null,
    );
  };
  const handleSuccess = () => {
    NotificationManager.success(
      'Código correcto.',
      'Autenticación de dos pasos',
      5000,
      null,
      null,
    );
    dispatch(updateUserOtpRequiredForLogin(false));
    toggle();
  };

  const deleteTwoFactorAuth = useDeleteTwoFactorAuth({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const isValidCode = code && code.length === 6;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={size}>
      <ModalBody>
        <Flex direction="column">
          <Text as="h2">¿Deseas desactivar la autenticación de dos pasos?</Text>
          <Text css={{ my: '$4' }} bold>
            Introduce el código de seis dígitos de la aplicación
          </Text>
          <Input
            data-openreplay-obscured
            type="number"
            css={{ width: '100%' }}
            helperText="Al introducir el código de tu aplicación podrás desactivar la autenticación de dos pasos"
            onChange={(e) => setCode(e.target.value)}
            onKeyPress={(e) => {
              if (e.charCode === 13 && isValidCode) {
                deleteTwoFactorAuth.mutate(code);
              }
            }}
          />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button variant="outlined" onClick={toggle}>
          Cancelar
        </Button>
        <Button
          color="error"
          disabled={!isValidCode}
          onClick={() => deleteTwoFactorAuth.mutate(code)}
        >
          Desactivar autenticación
        </Button>
      </ModalFooter>
    </Modal>
  );
}

DisabledTwoFactorModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  size: PropTypes.string,
};

DisabledTwoFactorModal.defaultProps = {
  toggle: null,
  size: 's',
  isOpen: false,
};
