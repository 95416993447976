import { Text } from '@konta/ui';
import Main from '@components/DesignSystem/Main';
import Section from 'shared/components/Section';
import {
  Container,
  Content,
  Divider,
  Header,
  TextAndSupportingText,
} from './styled';
import BestSellingClients from './BestSellingClients';
import BestSellingClientsDoughnut from './BestSellingClientsDoughnut/BestSellingClientsDoughnut';

export default function NewReports() {
  return (
    <Main>
      <Container>
        <Header>
          <Content>
            <TextAndSupportingText grow={1}>
              <Text bold xl lineHeight="xl">
                Tus reportes 📊
              </Text>
              <Divider />
            </TextAndSupportingText>
          </Content>
        </Header>
        <Section alignStart>
          <BestSellingClients />
          <BestSellingClientsDoughnut />
        </Section>
      </Container>
    </Main>
  );
}
