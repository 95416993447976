import {
  AnnualStep,
  AnualProcessStep,
  AnualProcessStepType,
  AnualProcessStepTypes,
  ChargesAnnualProcessStep,
  InformativeDataAnnualStep,
  PreferencesAnnualProcessStep,
  ProcessType,
  RecommendationsAnnualProcessStep,
  RejectionReasonsAnnualProcessStep,
  ResumeAnnualProcessStep,
  UploadDataAnnualProcessStep,
  WelcomeAnnualProcessStep,
  ForeignDocumentsAnnualProcessStep,
} from 'types/entities';

export default function getAnualSteps(steps: AnualProcessStep[]): AnnualStep {
  const getStep = (type: AnualProcessStepType) =>
    steps.find((step) => step.type === type);

  return {
    type: ProcessType.AnnualDeclarationProcess,
    welcome: getStep(AnualProcessStepTypes.Welcome) as WelcomeAnnualProcessStep,
    charges: getStep(AnualProcessStepTypes.Charges) as ChargesAnnualProcessStep,
    documents: getStep(
      AnualProcessStepTypes.Documents,
    ) as ForeignDocumentsAnnualProcessStep,
    resume: getStep(AnualProcessStepTypes.Resume) as ResumeAnnualProcessStep,
    preferences: getStep(
      AnualProcessStepTypes.Preferences,
    ) as PreferencesAnnualProcessStep,
    reject: getStep(
      AnualProcessStepTypes.Rejection,
    ) as RejectionReasonsAnnualProcessStep,
    recommendations: getStep(
      AnualProcessStepTypes.Recommendations,
    ) as RecommendationsAnnualProcessStep,
    upload: getStep(
      AnualProcessStepTypes.Upload,
    ) as UploadDataAnnualProcessStep,
    informative: getStep(
      AnualProcessStepTypes.Informative,
    ) as InformativeDataAnnualStep,
  };
}
