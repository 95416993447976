import { useEffect, useMemo, useState } from 'react';
import useXmlParser from 'shared/hooks/useXmlParser';
import { ReturnTypeUseWorkflow } from '@components/Workflow/hooks/useWorkflow';
import { ParsedInvoiceXML } from 'types/entities';
import { FileWithPath } from 'react-dropzone';
import useTaxableEntity from '@hooks/useTaxableEntity';

export interface ExtendedFileWithPath extends FileWithPath {
  newDeclaration?: {
    label: string;
    value: number;
    declarationId: number;
  };
}

export interface FileCardProps {
  file: ExtendedFileWithPath;
  xmlFiles: ExtendedFileWithPath[];
  setXmlFiles: (files: ExtendedFileWithPath[]) => void;
  regimeOptionsWithDeclarationId: ReturnTypeUseWorkflow['regimeOptionsWithDeclarationId'];
  isConstancy?: boolean;
  defaultRegimeOption: ReturnTypeUseWorkflow['defaultRegimeOptionBySatkey'];
  taxableEntityPreferences?: ReturnTypeUseWorkflow['taxableEntityPreferences'];
  view?: 'constancy' | 'incomes' | 'expenses';
}

export default function useFileCard({
  file,
  isConstancy,
  xmlFiles,
  setXmlFiles,
  defaultRegimeOption,
  taxableEntityPreferences,
  view,
  regimeOptionsWithDeclarationId,
}: FileCardProps) {
  const { fetchInvoice, isFetching, constancyXml, fileType } = useXmlParser({
    file,
  });

  const { taxable_entity } = useTaxableEntity();
  const [showInvoice, setShowInvoice] = useState<ParsedInvoiceXML>();
  const [selectedRegime, setSelectedRegime] = useState(defaultRegimeOption);
  const [currentFile, setCurrentFile] = useState(file);
  const toggleShowInvoice = () => setShowInvoice(undefined);

  const isEmittedByUser = taxable_entity?.rfc === fetchInvoice?.Emisor.Rfc;

  useEffect(() => {
    const platformRegime = regimeOptionsWithDeclarationId.find(
      (regime) => regime.value === '4',
    );

    const defaultInvoiceRegime = regimeOptionsWithDeclarationId.find(
      (regime) => regime.satKey === +(fetchInvoice?.Emisor?.RegimenFiscal || 0),
    );

    if (fetchInvoice && view === 'incomes') {
      setSelectedRegime(defaultInvoiceRegime);
    }
    if (constancyXml && view === 'constancy') {
      setSelectedRegime(platformRegime);
    }
  }, [
    constancyXml,
    defaultRegimeOption,
    fetchInvoice,
    regimeOptionsWithDeclarationId,
    view,
  ]);

  const invoiceType = {
    retenciones: 'Constancia',
    cfdi: 'Factura',
  }[fileType as 'retenciones' | 'cfdi'];

  const isValidFile = useMemo(
    () =>
      (isConstancy && fileType === 'retenciones') ||
      (!isConstancy && fileType === 'cfdi'),
    [isConstancy, fileType],
  );

  const isValidFileByView = useMemo(() => {
    if (view === 'incomes' && isEmittedByUser) {
      return true;
    }
    if (view === 'expenses' && !isEmittedByUser) {
      return true;
    }
    if (isConstancy) {
      return true;
    }
    return false;
  }, [isEmittedByUser, isConstancy, view]);

  useEffect(() => {
    const updatedFiles = xmlFiles.map((updatedFile) => {
      if (updatedFile.path === currentFile.path) {
        return Object.assign(updatedFile, {
          newDeclaration: selectedRegime,
          isValidFile: isValidFile && isValidFileByView,
        });
      }
      return updatedFile;
    });
    setXmlFiles(updatedFiles);
  }, [currentFile, selectedRegime, isValidFile, isValidFileByView]);

  const isDisabledFiscalRegimeDropdown = useMemo(
    () =>
      !!taxableEntityPreferences?.preferred_fiscal_regime ||
      (isConstancy && defaultRegimeOption?.value === '4'),
    [
      defaultRegimeOption?.value,
      isConstancy,
      taxableEntityPreferences?.preferred_fiscal_regime,
    ],
  );

  return {
    fetchInvoice,
    isFetching,
    constancyXml,
    fileType,
    showInvoice,
    setShowInvoice,
    toggleShowInvoice,
    selectedRegime,
    setSelectedRegime,
    currentFile,
    setCurrentFile,
    isValidFile: isValidFile && isValidFileByView,
    invoiceType,
    isDisabledFiscalRegimeDropdown,
    isEmittedByUser,
  };
}
