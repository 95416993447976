import { call, select } from 'redux-saga/effects';
import api from '@api';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import {
  fetchCurrentFiscalPeriodSuccess as success,
  fetchCurrentFiscalPeriodFailure as failure,
} from '../actions';

export default function* () {
  try {
    const { taxable_entity } = yield select((state) => state.taxableEntity);

    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.get,
      `fiscal_periods/${taxable_entity.current_fiscal_period}`,
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      status: response.status,
      response,
      success,
      failure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: failure,
    });
  }
}
