import dayjs from 'dayjs';
import { getDateObjectWithInvoiceFormat } from './Utils';
import toFixedString from './toFixedString';
import getUtcDateByServerDate from './getUtcDateByServerDate';

export function paymentAttributesTotals(
  { subtotal, iva, iva_8_percent, iva_0_percent, iva_16_percent, iva_exempt },
  amount,
) {
  const base = iva_0_percent
    ? iva_16_percent || iva_8_percent || iva_exempt
    : subtotal;
  let totals = {
    total_payment_amount: toFixedString(+amount),
  };

  if (iva_8_percent) {
    const customIva8 = Math.max(+iva_8_percent * 0.08, iva);
    totals = {
      total_transferred_base_iva_8: toFixedString(+base),
      total_transferred_tax_iva_8: toFixedString(+customIva8),
      ...totals,
    };
  }

  if (iva_0_percent) {
    totals = {
      total_transferred_base_iva_0: toFixedString(+iva_0_percent),
      total_transferred_tax_iva_0: '0.00',
      ...totals,
    };
  }
  if (iva_16_percent) {
    const customIva16 = Math.max(+iva_16_percent * 0.16, iva);
    totals = {
      total_transferred_base_iva_16: toFixedString(+base),
      total_transferred_tax_iva_16: toFixedString(+customIva16),
      ...totals,
    };
  }
  if (iva_exempt) {
    totals = {
      total_transferred_base_iva_exempt: toFixedString(+base),
      ...totals,
    };
  }

  return totals;
}

export function taxesTransferred({
  subtotal,
  iva,
  iva_16_percent,
  iva_8_percent,
  iva_0_percent,
  iva_exempt,
}) {
  const taxes = [];
  const base = iva_0_percent
    ? iva_16_percent || iva_8_percent || iva_exempt
    : subtotal;
  const customIva = Math.max(
    +iva_16_percent * 0.16 + +iva_8_percent * 0.08,
    iva,
  );
  if (iva_16_percent || iva_8_percent) {
    taxes.push({
      base: toFixedString(base),
      tax: '002',
      factor_type: 'Tasa',
      import: toFixedString(customIva),
      rate_or_fee: iva_16_percent > 0 ? '0.160000' : '0.080000',
    });
  }
  if (iva_exempt) {
    taxes.push({
      base: toFixedString(base),
      tax: '002',
      factor_type: 'Exento',
    });
  }
  if (iva_0_percent) {
    taxes.push({
      base: toFixedString(iva_0_percent),
      tax: '002',
      factor_type: 'Tasa',
      import: '0.00',
      rate_or_fee: '0.000000',
    });
  }

  return taxes;
}

export default function generateComplementInvoice(
  ppdObject,
  complementValues,
  activeFiscalRegime,
  generalPublicRfc,
  complementGlobalAttributesEnabled,
  serverDate,
) {
  const {
    currency = 'MXN',
    amount,
    payment_date,
    payment_form,
    exchange_rate,
    previous_debt,
    new_debt,
    payment_number,
    receiver_fiscal_regime,
    receiver_zip_code,
    expedition_place,
  } = complementValues;

  const {
    issuer_rfc,
    issuer_legal_name,
    receiver_rfc,
    receiver_legal_name,
    folio_fiscal,
    currency: ppdCurrency,
  } = ppdObject;

  let receipt = {
    date: getUtcDateByServerDate(null, serverDate), // fecha actual
    export_code: '01',
    version: '4.0',
    subtotal: '0',
    currency: 'XXX',
    total: '0',
    type_of_receipt: 'P',
    expedition_place,
    issuer_attributes: {
      rfc: issuer_rfc,
      name: issuer_legal_name,
      fiscal_regime: activeFiscalRegime.sat_key,
    },
    receiver_attributes: {
      rfc: receiver_rfc,
      name: receiver_legal_name,
      zip_code: receiver_zip_code,
      cfdi_usage: 'CP01',
      fiscal_regime: receiver_fiscal_regime.value,
    },
    concepts_attributes: [
      {
        taxable_code: '01',
        prod_or_svc_key: '84111506',
        quantity: '1',
        unit_measure_key: 'ACT',
        description: 'Pago',
        unit_value: '0',
        amount: '0',
      },
    ],
    payment_attributes: {
      payment_date: getDateObjectWithInvoiceFormat(
        dayjs(payment_date).toDate(),
      ),
      payment_form: payment_form.value,
      currency: currency.value,
      exchange_rate,
      amount: toFixedString(+amount),
      totals: paymentAttributesTotals(ppdObject, amount),
      related_docs: [
        {
          uuid: folio_fiscal,
          serie: 'ASD',
          folio: '23',
          currency: ppdCurrency,
          payment_number,
          exchange_rate,
          previous_debt: toFixedString(+previous_debt),
          paid_amount: toFixedString(+amount),
          new_debt: toFixedString(+new_debt),
          taxable_code: '02',
          taxes: {
            transfers: taxesTransferred(ppdObject),
          },
        },
      ],
    },
  };

  if (generalPublicRfc && complementGlobalAttributesEnabled) {
    receipt = {
      ...receipt,
      global_info_attributes: {
        periodicity: complementValues.periodicity.value,
        months: complementValues.periodicityMonth.value,
        year: complementValues.periodicityYear,
      },
    };
  }

  return {
    receipt,
  };
}
