import React, { useMemo } from 'react';
import { Chip, Box, Text, Flex, HoverCard } from '@konta/ui';
import toCurrency from '@util/toCurrency';

export default function ConceptTaxes({ iva, retainedIva, ieps, isr }) {
  const taxes = [
    iva?.rate === null
      ? { ...iva, label: 'IVA exento' }
      : { ...iva, label: 'IVA trasladado' },
    retainedIva?.rate > 0 && { ...retainedIva, label: 'IVA retenido' },
    ieps?.rate > 0 && { ...ieps, label: 'IEPS' },
    isr?.rate > 0 && { ...isr, label: 'ISR retenido' },
  ].filter(Boolean);

  const hasOneTax = taxes.length === 1;

  const chipLabel = useMemo(() => {
    const hasOnlyIvaExempt = hasOneTax && taxes[0].rate === null;

    if (hasOnlyIvaExempt) {
      return taxes[0].label;
    }
    if (hasOneTax) {
      return `${taxes[0].rate * 100}% de ${taxes[0].label}`;
    }

    return `+${taxes.length} impuestos`;
  }, [hasOneTax, taxes]);

  if (taxes.length === 0) {
    return (
      <span tabIndex={-1} role="button">
        <Chip
          variant="contained"
          label="Exento de impuestos"
          color="gray100"
          size="s"
        />
      </span>
    );
  }

  return (
    <HoverCard
      openDelay={0}
      triggerElement={
        <span tabIndex={-1} role="button">
          <Chip
            variant="contained"
            label={chipLabel}
            color={hasOneTax ? 'gray100' : 'primary100'}
            size="s"
          />
        </span>
      }
    >
      <Box css={{ zIndex: '1000' }}>
        {taxes.map((tax, index) => {
          if (!tax?.amount) {
            return null;
          }
          // eslint-disable-next-line react/no-array-index-key
          return (
            <Flex key={index} gap={8} justify="between">
              {tax?.rate === null ? (
                <Text>{tax.label}</Text>
              ) : (
                <>
                  <Text>
                    {tax.rate * 100}% de {tax.label}
                  </Text>
                  <Text>{toCurrency(tax.amount)}</Text>
                </>
              )}
            </Flex>
          );
        })}
      </Box>
    </HoverCard>
  );
}
