import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { editProductsSuccess, editProductsFailure } from '../actions';

export default function* ({
  payload: { taxable_entity, product, product_id },
}) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `taxable_entities/${taxable_entity.id}/products/${product_id}`,
      { product },
      { headers: { Authorization: `${access_token}` } }
    );

    yield call(StatusHandler, {
      response,
      title: 'Producto editado',
      message: 'Producto editado con éxito',
      status: response.status,
      success: editProductsSuccess,
      failure: editProductsFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: editProductsFailure,
    });
  }
}
