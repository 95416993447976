import { Fragment } from 'react';
import { Checkbox } from '@konta/ui';
import type { CheckedState } from '@components/FormikCheckbox';
import type { TaxableEntityPreference } from 'types/entities';
import { RowItem, TableContainer, TableRow } from './styled';
import NotificationGroup from './NotificationGroup';
import useNotificationCenter from '../useNotificationCenter';
import notificationsPreferences from './notifications';

type UseNotificationCenter = ReturnType<typeof useNotificationCenter>;
interface NotificationPreferencesProps {
  preferences: TaxableEntityPreference | null;
  bulkHandleChange: UseNotificationCenter['bulkHandleChange'];
  handleChecked: UseNotificationCenter['handleChange'];
  getNotificationsUtils: UseNotificationCenter['getNotificationsUtils'];
}

export default function NotificationPreferences({
  preferences,
  handleChecked,
  bulkHandleChange,
  getNotificationsUtils,
}: NotificationPreferencesProps) {
  return (
    <TableContainer>
      <TableRow>
        <RowItem size="l">Preferencias de notificaciones</RowItem>
        <RowItem size="l">Email</RowItem>
        <RowItem size="l">Whatsapp</RowItem>
      </TableRow>
      {notificationsPreferences.map(({ title, notifications, isGrouped }) => {
        return (
          <Fragment key={title}>
            {isGrouped ? (
              <NotificationGroup
                isSubtitle
                getNotificationsUtils={getNotificationsUtils}
                title={title}
                notifications={notifications}
                bulkHandleChange={bulkHandleChange}
              />
            ) : (
              <TableRow type="subtitle">
                <RowItem size="m">{title}</RowItem>
                <RowItem />
                <RowItem />
              </TableRow>
            )}
            {notifications.map(
              ({
                name,
                underline,
                notifications: innerNotifications,
                whatsappKey,
                emailKey,
              }) => {
                const isInnerGrouped =
                  innerNotifications && innerNotifications.length > 0;
                if (isInnerGrouped) {
                  return (
                    <NotificationGroup
                      key={name}
                      getNotificationsUtils={getNotificationsUtils}
                      title={name}
                      notifications={innerNotifications}
                      bulkHandleChange={bulkHandleChange}
                    />
                  );
                }
                return (
                  <TableRow
                    key={name}
                    {...(underline && { type: 'underline' })}
                  >
                    <RowItem>{name}</RowItem>
                    <RowItem>
                      {emailKey && (
                        <Checkbox
                          checked={!preferences?.[emailKey] as CheckedState}
                          onCheckedChange={(checked: CheckedState) =>
                            handleChecked(!checked, emailKey)
                          }
                        />
                      )}
                    </RowItem>
                    <RowItem>
                      {whatsappKey && (
                        <Checkbox
                          checked={!preferences?.[whatsappKey] as CheckedState}
                          onCheckedChange={(checked: CheckedState) =>
                            handleChecked(!checked, whatsappKey)
                          }
                        />
                      )}
                    </RowItem>
                  </TableRow>
                );
              },
            )}
          </Fragment>
        );
      })}
    </TableContainer>
  );
}
