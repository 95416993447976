import { Text } from '@konta/ui';
import openZendeskLinkWithSSO from '@util/openZendeskLinkWithSSO';
import { MouseEvent, ReactNode } from 'react';
import {
  articlesUrl,
  HelpCenterArticlesId,
  helpCenterUrl,
} from '@constants/helpCenter';
import { Emoji } from '../Emoji';

interface HelpCenterLinkProps {
  children?: ReactNode;
  url?: string;
  isTargetBlank?: boolean;
  withDefaultChildren?: boolean;
  className?: string;
  articleId?: HelpCenterArticlesId;
}
function RenderChildren({ children }: { children: ReactNode }) {
  if (typeof children === 'string') {
    return <span className="clickable-text-color font-italic">{children}</span>;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
export default function HelpCenterLink({
  children = 'Más información',
  url = helpCenterUrl,
  isTargetBlank = true,
  withDefaultChildren,
  className,
  articleId,
}: HelpCenterLinkProps) {
  const redirectUrl = articleId ? articlesUrl[articleId] : url;
  return (
    <Text
      color="primary500"
      // @ts-ignore ignore this error because we are using the Link component from react-router-dom
      as="a"
      className={`${className || ''}`}
      href={redirectUrl}
      target="_blank"
      onClick={(e: MouseEvent<HTMLElement>) =>
        openZendeskLinkWithSSO(redirectUrl, isTargetBlank, e)
      }
    >
      {withDefaultChildren ? (
        <>
          <Emoji symbol="📖" label="rocket" className="mr-2" />
          <span className="clickable-text-color">Centro de ayuda</span>
        </>
      ) : (
        <RenderChildren>{children}</RenderChildren>
      )}
    </Text>
  );
}
