import { useCallback } from 'react';
import { postPublicClientSuppliers } from '@api/clientSuppliers';
import { useMutation } from 'react-query';
import type { UseMutationOptions } from 'react-query';
import type { AxiosRequestConfig } from 'axios';
import type {
  ClientSupplier,
  PublicClientSupplierCreatePayload,
} from 'types/entities';

export default function useCreatePublicClientSupplier(
  axiosConfig?: AxiosRequestConfig<ClientSupplier>,
  queryOptions?: UseMutationOptions<
    ClientSupplier,
    unknown,
    PublicClientSupplierCreatePayload
  >,
) {
  const createPublicClientSupplierRequest = useCallback(
    (updateRequest: PublicClientSupplierCreatePayload) =>
      postPublicClientSuppliers(updateRequest, axiosConfig),
    [axiosConfig],
  );
  return useMutation(createPublicClientSupplierRequest, queryOptions);
}
