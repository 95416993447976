import { Form, FormikProvider } from 'formik';
import LetterAvatar from 'react-avatar';
import { Flex, Text, Button } from '@konta/ui';
import FormikTextInput from 'shared/components/FormikTextInput';
import ResponsiveCenterContainer from 'shared/components/ResponsiveCenterContainer';
import FormFieldWrapper from '@components/FormFieldWrapper';
import FormikSelect from 'shared/components/FormikSelect';
import { PAYMENT_TYPE_OPTIONS, USE_CFDI_OPTIONS } from '@constants/invoicing';
import useNewClient from './useNewClient';

export default function NewClient() {
  const {
    formik,
    fiscalRegimensOptions,
    isLoading,
    handleRfcChange,
    handleInputBlur,
    greetingText,
    logoUrl,
    legalName,
  } = useNewClient();

  return (
    <ResponsiveCenterContainer>
      <Flex css={{ mb: '$20', gap: 10 }} itemsCenter>
        <span className="new-client-form-header-profile-image-container">
          {logoUrl ? (
            <img
              src={logoUrl}
              className="sidebar-header-profile-image"
              alt="konta-logo"
            />
          ) : (
            <LetterAvatar maxInitials={2} name={legalName} size="26" round />
          )}
        </span>
        <Text bold l>
          {legalName}
        </Text>
      </Flex>
      <Flex direction="column" css={{ mb: '34px' }}>
        <Text color="gray800" x2l bold>
          {greetingText},
        </Text>
        <Text color="gray500" m css={{ mt: '$8' }}>
          Te envío este formulario para que me proporciones tus datos de
          facturación para emitirte una factura.
        </Text>
      </Flex>
      <FormikProvider value={formik}>
        <Form>
          <Flex direction="column" css={{ gap: 10, '@md': { gap: 18 } }}>
            <FormFieldWrapper>
              <FormikTextInput
                name="rfc"
                label="RFC"
                onChange={handleRfcChange}
                disabled={isLoading}
              />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormikTextInput
                label="Razón Social"
                name="legalName"
                onBlur={handleInputBlur}
                helperText="Nombre sin régimen societario (Sociedad Anónima, SA de CV, Sociedad Civil, SC, CV, etc.)"
                disabled={isLoading}
              />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormikTextInput
                disabled={isLoading}
                name="email"
                label="Correo"
              />
              <FormikTextInput
                name="postcode"
                label="Código Postal"
                onBlur={handleInputBlur}
                disabled={isLoading}
              />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormikSelect
                name="fiscalRegimes"
                label="Régimen fiscal"
                isDisabled={isLoading}
                options={fiscalRegimensOptions}
              />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormikSelect
                name="cfdiUsage"
                label="Uso CFDI (opcional)"
                options={USE_CFDI_OPTIONS}
              />
              <FormikSelect
                name="paymentType"
                label="Forma de pago (opcional)"
                options={PAYMENT_TYPE_OPTIONS}
              />
            </FormFieldWrapper>
          </Flex>
          <Flex direction="column" align="stretch" css={{ pt: '28px' }}>
            <Button
              color="primary600"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
              fontMedium
              size="m"
            >
              Compartir datos
            </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </ResponsiveCenterContainer>
  );
}
