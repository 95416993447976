import {
  ORDERS_FETCH,
  ORDERS_FETCH_SUCCESS,
  ORDERS_FETCH_FAILURE,
  ORDER_PAY,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAILURE,
  CLEAR_CART,
} from '@constants/actionTypes';

const INIT_STATE = {
  orders: [],
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ORDERS_FETCH:
    case ORDER_PAY:
      return { ...state, loading: true };
    case ORDERS_FETCH_SUCCESS:
      return { ...state, loading: false, orders: action.payload };
    case ORDER_PAY_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: state.orders.filter((order) => order.id !== action.payload.id),
      };
    case ORDERS_FETCH_FAILURE:
    case CLEAR_CART:
      return { ...state, loading: false, orders: [] };
    case ORDER_PAY_FAILURE:
      return { ...state, loading: false };
    default:
      return { ...state };
  }
};
