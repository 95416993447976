export const nextPeriodIva = ({ iva_in_favor, iva_total }) => {
  return Math.max(+iva_in_favor - +iva_total, 0);
};

export default function getDeclarationTotalToPay(
  {
    iva_total = '0',
    iva_in_favor = '0',
    isr_total = '0',
    isr_in_favor = '0',
    total_payroll_withholdings_to_pay = '0',
    total_late_fees = '0',
    undue_payment_iva_in_favor = '0',
  },
  hasPayrollRetentions = false,
) {
  const totalTaxToPay = (taxTotal, taxInFavor) => {
    if (+taxTotal <= 0) {
      return 0;
    }
    return +taxTotal - +taxInFavor <= 0 ? 0 : +taxTotal - +taxInFavor;
  };

  const resultObject = {
    nextPeriodIva: nextPeriodIva({ iva_total, iva_in_favor }),
    totalIvaToPay: totalTaxToPay(iva_total, iva_in_favor),
    totalIsrToPay: totalTaxToPay(isr_total, isr_in_favor),
    totalToPay:
      +totalTaxToPay(iva_total, iva_in_favor) +
      +totalTaxToPay(isr_total, isr_in_favor) +
      +total_late_fees -
      +undue_payment_iva_in_favor,
  };

  if (hasPayrollRetentions) {
    resultObject.totalToPay += +total_payroll_withholdings_to_pay;
  }

  return resultObject;
}
