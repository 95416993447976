import {
  PULSE_FETCH_UNCATEGORIZED_INVOICES_FAILURE,
  PULSE_FETCH_UNCATEGORIZED_INVOICES,
  PULSE_FETCH_UNCATEGORIZED_INVOICES_SUCCESS,
  PULSE_CATEGORIZE_INVOICE,
  PULSE_CATEGORIZE_INVOICE_SUCCESS,
  PULSE_CATEGORIZE_INVOICE_FAILURE,
  PULSE_UPDATE_DESCRIPTION_BEGIN,
  PULSE_UPDATE_DESCRIPTION_FAILURE,
  PULSE_ADD_STATEMENT_END,
  PULSE_ADD_STATEMENT_BEGIN,
  PULSE_ADD_STATEMENT_SUCCESS,
  PULSE_ADD_STATEMENT_FAILURE,
} from '@constants/actionTypes';

export const fetchUncategorizedInvoices = () => ({
  type: PULSE_FETCH_UNCATEGORIZED_INVOICES,
});
export const fetchUncategorizedInvoicesFailure = () => ({
  type: PULSE_FETCH_UNCATEGORIZED_INVOICES_FAILURE,
});
export const fetchUncategorizedInvoicesSuccess = (invoices) => ({
  type: PULSE_FETCH_UNCATEGORIZED_INVOICES_SUCCESS,
  payload: invoices,
});

export const categorizeInvoice = (category, id) => ({
  type: PULSE_CATEGORIZE_INVOICE,
  payload: { category, id },
});
export const categorizeInvoiceSuccess = (invoices) => ({
  type: PULSE_CATEGORIZE_INVOICE_SUCCESS,
  payload: invoices,
});
export const categorizeInvoiceFailure = () => ({
  type: PULSE_CATEGORIZE_INVOICE_FAILURE,
});

// export const categorizeInvoice= (category, id, invoice) => {
//     return dispatch => {
//         dispatch(categorizeInvoiceBegin())
//         return fetch(`http://localhost:8080/invoices/${id}`, {
//             method: 'PUT',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ ...invoice, category })
//         })
//             .then(handleErrors)
//             .then(res => res.json())
//             .then(json => {
//                 dispatch(categorizeInvoiceSuccess())
//             })
//             .catch(e => {
//                 dispatch(categorizeInvoiceFailure())
//                 dispatch(categorizeInvoiceEnd())
//
//             })
//
//     }
// }

export const updateDescription = (invoice, newDesc) => {
  return (dispatch) => {
    dispatch(updateDescriptionBegin());
    return fetch(`http://localhost:8080/invoices/${invoice.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...invoice, long_description: newDesc }),
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchInvoices());
      })
      .catch((e) => {
        dispatch(updateDescriptionFailure());
        dispatch(updateDescriptionEnd());
      });
  };
};

const fetchInvoices = () => {
  return {
    type: PULSE_UPDATE_DESCRIPTION_BEGIN,
  };
};

const updateDescriptionBegin = () => {
  return {
    type: PULSE_UPDATE_DESCRIPTION_BEGIN,
  };
};

const updateDescriptionFailure = () => {
  return {
    type: PULSE_UPDATE_DESCRIPTION_FAILURE,
  };
};

const updateDescriptionEnd = () => {
  return {
    type: PULSE_ADD_STATEMENT_END,
  };
};

export const addStatement = (statements) => {
  return (dispatch) => {
    dispatch(addStatementBegin());
    return fetch(`http://localhost:8080/statements`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...statements }),
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(addStatementSuccess());
        dispatch(addStatementEnd());
      })
      .catch((e) => {
        dispatch(addStatementFailure());
        dispatch(addStatementEnd());
      });
  };
};

const addStatementBegin = () => {
  return {
    type: PULSE_ADD_STATEMENT_BEGIN,
  };
};

const addStatementFailure = () => {
  return {
    type: PULSE_ADD_STATEMENT_FAILURE,
  };
};

const addStatementEnd = () => {
  return {
    type: PULSE_ADD_STATEMENT_END,
  };
};

const addStatementSuccess = () => {
  return {
    type: PULSE_ADD_STATEMENT_SUCCESS,
  };
};

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
