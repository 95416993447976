import { FileDownloadInLcOutlineIcon } from '@konta/icons';
import { Text, Flex, Button } from '@konta/ui';
import { WORKFLOW_STATUS_DATA } from '@constants/workflow';
import type { Workflow } from 'types/entities';
import { WORKFLOW_MODAL } from '@constants/featureFlags';
import useFeature from '@hooks/useFeature';
import useWorkflowStatus from './useWorkflowStatus';
import { StepCard, StepCardContent } from '../styled';

interface WorkflowStatusProps {
  workflow: Workflow;
  setShowOldWorkflow?: () => void;
  selectedMonth: string;
  availableWorkflow: Workflow | null;
  hasPendingPreviousMonthWorkflow: boolean;
}

export default function WorkflowStatus({
  workflow,
  setShowOldWorkflow,
  selectedMonth,
  availableWorkflow,
  hasPendingPreviousMonthWorkflow,
}: WorkflowStatusProps) {
  const {
    workflowStatus,
    isEnabledBtn,
    openWorkflow,
    declarationsDocuments,
    isNewWorkflow,
  } = useWorkflowStatus(workflow, selectedMonth, availableWorkflow);
  const [workflowModal] = useFeature(WORKFLOW_MODAL);

  const handleOpenWorkflow = () => {
    if (!workflowModal && !isNewWorkflow) {
      setShowOldWorkflow?.();
      return;
    }
    openWorkflow();
  };

  if (workflowStatus in WORKFLOW_STATUS_DATA) {
    const { title, description, btnText } =
      WORKFLOW_STATUS_DATA[workflowStatus];

    return (
      <Flex gap={12}>
        <StepCard>
          <StepCardContent>
            <Flex gap={4} column>
              {(workflowStatus === 'ready' && !isEnabledBtn) ||
              hasPendingPreviousMonthWorkflow ? (
                <>
                  <Text bold>Declaración bloqueada</Text>
                  <Text size="s" color="gray600">
                    La presente declaración se encuentra actualmente bloqueada
                    debido a la existencia de una declaración en curso en tu
                    cuenta. Te solicitamos amablemente que concluyas la
                    declaración en curso antes de continuar con esta. Si
                    requieres asistencia adicional, no dudes en contactarnos.
                  </Text>
                </>
              ) : (
                <>
                  <Text bold>{title}</Text>
                  <Text size="s" color="gray600">
                    {description}
                  </Text>
                </>
              )}
            </Flex>

            {workflowStatus === 'done' && declarationsDocuments.length > 0 ? (
              declarationsDocuments.map((document) => (
                <a
                  download
                  target="_blank"
                  href={document.documentUrl}
                  rel="noreferrer"
                >
                  <Button
                    size="xs"
                    variant="outlined"
                    leftIcon={<FileDownloadInLcOutlineIcon />}
                    onClick={() => {}}
                  >
                    Descargar declaración de {document.regimeName}
                  </Button>
                </a>
              ))
            ) : (
              <Flex>
                <Button
                  color="primary600"
                  disabled={!isEnabledBtn || hasPendingPreviousMonthWorkflow}
                  onClick={handleOpenWorkflow}
                >
                  {btnText || 'Continuar con la declaración'}
                </Button>
              </Flex>
            )}
          </StepCardContent>
        </StepCard>
      </Flex>
    );
  }
  return (
    <Flex gap={12}>
      <StepCard>
        <StepCardContent>
          <Flex gap={4} column>
            <Text bold>1. Pagado</Text>
            <Text size="s" color="gray600">
              Tu pago ha sido procesado
            </Text>
          </Flex>
          <Flex>
            <Button color="primary600" disabled={!isEnabledBtn}>
              La declaración se encuentra completada
            </Button>
          </Flex>
        </StepCardContent>
      </StepCard>
    </Flex>
  );
}
