import { call } from 'redux-saga/effects';
import { ErrorLayout, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  deleteClientsSuppliersSuccess,
  deleteClientsSuppliersFailure,
} from '../actions';

export default function* ({ payload: { taxable_entity, client_supplier_id } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.delete,
      `taxable_entities/${taxable_entity.id}/client_suppliers/${client_supplier_id}`,
      { headers: { Authorization: `${access_token}` } }
    );
    response.data = client_supplier_id;

    yield call(ErrorLayout, {
      response,
      status: response.status,
      success: deleteClientsSuppliersSuccess,
      failure: deleteClientsSuppliersFailure,
      title: 'Éxito',
      message: 'El cliente o provedor se ha borrado exitosamente',
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      message: 'Algo salió mal en el borrado de productos',
      action: deleteClientsSuppliersFailure,
    });
  }
}
