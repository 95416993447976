import {
  ADD_TO_CART,
  CLEAR_CART,
  ORDER_PAY_SUCCESS,
  CHARGE_PAYMENT_METHOD_SUCCESS,
} from '@constants/actionTypes';

const INIT_STATE = {
  orders_to_pay: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return { ...state, orders_to_pay: action.payload };
    case ORDER_PAY_SUCCESS:
    case CHARGE_PAYMENT_METHOD_SUCCESS:
    case CLEAR_CART:
      return { ...state, orders_to_pay: [] };
    default:
      return { ...state };
  }
};
