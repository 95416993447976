import { FocusEvent } from 'react';
import { Box, Button, Flex, Input, Text } from '@konta/ui';
import Loader from '@components/Loader';
import FormikTextInput from '@components/FormikTextInput';
import FormikCheckbox from '@components/FormikCheckbox';
import useSettingsBrandForm from './useSettingsBrandForm';

type IncrementalFolioSettingsForm = Pick<
  ReturnType<typeof useSettingsBrandForm>,
  | 'formik'
  | 'loadingUpdateTaxableEntityPreferences'
  | 'uploadTaxableEntityPreference'
  | 'defaultFolio'
>;
export default function IncrementalFolioSettings({
  formik,
  loadingUpdateTaxableEntityPreferences,
  uploadTaxableEntityPreference,
  defaultFolio,
}: IncrementalFolioSettingsForm) {
  const isEnabledIncrementFolio = formik.values.incrementInternalFolio;

  return (
    <Flex column gap={12}>
      <Flex
        css={{
          gap: '32px',
          justifyContent: 'space-between',
          maxWidth: '100%',
          '@md': { maxWidth: '500px' },
        }}
      >
        <Flex align="center" gap={6}>
          <Text>Folio incremental para facturas</Text>
        </Flex>
        {loadingUpdateTaxableEntityPreferences ? (
          <Loader />
        ) : (
          <Flex>
            {isEnabledIncrementFolio ? (
              <Flex gap={6}>
                <Box
                  css={{
                    width: '89px',
                    textAlign: 'center',
                    border: '1px solid $gray300',
                    borderRadius: '6px',
                  }}
                >
                  <Input
                    size="xs"
                    value={defaultFolio}
                    maxLength={6}
                    readOnly
                    css={{
                      pointerEvents: 'none',
                      paddingLeft: '$4',
                      py: '$2',
                      '&:first-child :first-child': {
                        border: 'none',
                        py: 0,
                        height: '$20',
                        px: '$2',
                        gap: 0,
                      },
                    }}
                  />
                </Box>
              </Flex>
            ) : (
              <Button
                size="xs"
                loading={loadingUpdateTaxableEntityPreferences}
                variant="outlined"
                onClick={() =>
                  uploadTaxableEntityPreference({
                    increment_internal_folio: true,
                  })
                }
              >
                Habilitar
              </Button>
            )}
          </Flex>
        )}
      </Flex>
      {isEnabledIncrementFolio && (
        <>
          <Flex column gap={10} css={{ maxWidth: '500px' }}>
            <FormikTextInput
              name="internalFolioPrefix"
              label="Prefijo"
              maxLength={6}
              // @ts-ignore TODO: refactor this component to ts
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                void uploadTaxableEntityPreference({
                  internal_folio_prefix: e.target.value,
                });
              }}
            />
            <FormikTextInput
              type="number"
              min={1}
              name="internalFolioNumberLength"
              label="Número mínimo de dígitos"
              // @ts-ignore TODO: refactor this component to ts
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                void uploadTaxableEntityPreference({
                  internal_folio_number_length: +e.target.value,
                });
              }}
            />
            <FormikCheckbox
              id="uniqueInternalFolio"
              name="uniqueInternalFolio"
              label="Folio único"
              onChange={(state) => {
                void uploadTaxableEntityPreference({
                  unique_internal_folio: state as boolean,
                });
              }}
            />
            <FormikTextInput
              type="number"
              min={1}
              name="nextInternalFolio"
              label="Folio de la siguiente factura"
              // @ts-ignore TODO: refactor this component to ts
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                void uploadTaxableEntityPreference({
                  next_internal_folio: +e.target.value,
                });
              }}
            />
          </Flex>
          <Flex justify="end" css={{ maxWidth: '500px' }}>
            <Button
              loading={loadingUpdateTaxableEntityPreferences}
              size="xs"
              variant="outlined"
              onClick={() =>
                uploadTaxableEntityPreference({
                  increment_internal_folio: false,
                })
              }
            >
              Deshabilitar
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
}
