import {
  BANK_TRANSACTIONS_DECLARATIONS_FETCH,
  BANK_TRANSACTIONS_DECLARATIONS_FETCH_SUCCESS,
  BANK_TRANSACTIONS_DECLARATIONS_FETCH_FAILURE,
  BANK_TRANSACTIONS_DECLARATIONS_UPDATE,
  BANK_TRANSACTIONS_DECLARATIONS_UPDATE_SUCCESS,
  BANK_TRANSACTIONS_DECLARATIONS_UPDATE_FAILURE,
  BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE,
  BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_SUCCESS,
  BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_FAILURE,
} from '@constants/actionTypes';

const INITI_STATE = {
  bank_transactions_declarations: [],
  loading: false,
  error: null,
};

export default (state = INITI_STATE, action) => {
  switch (action.type) {
    case BANK_TRANSACTIONS_DECLARATIONS_FETCH:
      return {
        ...state,
        loading: true,
      };
    case BANK_TRANSACTIONS_DECLARATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        bank_transactions_declarations: action.payload,
      };
    case BANK_TRANSACTIONS_DECLARATIONS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case BANK_TRANSACTIONS_DECLARATIONS_UPDATE:
      return {
        ...state,
        loading: true,
      };
    case BANK_TRANSACTIONS_DECLARATIONS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        bank_transactions_declarations:
          state.bank_transactions_declarations.map(
            (bank_transactions_declaration) =>
              bank_transactions_declaration.id === action.payload.id
                ? action.payload
                : bank_transactions_declaration
          ),
      };
    case BANK_TRANSACTIONS_DECLARATIONS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE:
      return {
        ...state,
        loading: true,
      };
    case BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        bank_transactions_declarations:
          state.bank_transactions_declarations.map(
            (bank_transactions_declaration) => {
              const objIndex = action.payload.findIndex(
                (obj) => obj.id === bank_transactions_declaration.id
              );
              return objIndex === -1
                ? bank_transactions_declaration
                : action.payload[objIndex];
            }
          ),
      };
    }
    case BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};
