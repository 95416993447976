import React, { Component } from 'react';
import { Row, Card, CardTitle, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { Colxx } from '@components/CustomBootstrap';

class Error404 extends Component {
  render() {
    return (
      <main className="main-center-container px-3 px-md-1">
        <div className="fixed-background" />
        <Row className="h-100">
          <Colxx xxs="12" md="5" className="mx-auto my-auto">
            <Card className="auth-card">
              <div className="form-side">
                <div className="d-flex justify-content-center align-items-center mb-5">
                  <NavLink to="/" className="white">
                    <span className="logo-single" />
                  </NavLink>
                </div>
                <div className="text-center">
                  <CardTitle tag="h4" className="mb-4">
                    Vaya, parece que ha ocurrido un error!
                  </CardTitle>
                  <p className="mb-0 text-muted  mb-0">Código de error</p>
                  <p className="display-1 font-weight-bold mb-5">404</p>
                  <Button
                    href="/app"
                    color="primary"
                    className="btn-shadow"
                    size="lg"
                  >
                    REGRESAR A INICIO
                  </Button>
                </div>
              </div>
            </Card>
          </Colxx>
        </Row>
      </main>
    );
  }
}
export default Error404;
