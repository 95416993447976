/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  fetchCurrentFiscalPeriod,
  payFiscalPeriod,
  fetchFiscalPeriod,
} from '@redux/actions';

export const useFiscalPeriods = (resource) => {
  const dispatch = useDispatch();
  const fiscalPeriods = useSelector(
    (state) => state.fiscalPeriods,
    shallowEqual,
  );
  const {
    taxable_entity: { current_fiscal_period },
  } = useSelector((state) => state.taxableEntity, shallowEqual);
  const { current } = fiscalPeriods;
  useEffect((_) => {
    current || (current_fiscal_period && dispatch(fetchCurrentFiscalPeriod()));
    resource === 'fiscal_period' && dispatch(fetchFiscalPeriod());
  }, []);
  const payHandler = useCallback(
    (_) => {
      current && dispatch(payFiscalPeriod(current));
    },
    [current],
  );
  const handleFpPay = (fp) => {
    dispatch(payFiscalPeriod(fp));
  };
  return {
    ...fiscalPeriods,
    payHandler,
    handleFpPay,
  };
};
