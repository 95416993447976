import { FC, memo } from 'react';
import Portal from '@components/Portal';
import {
  LoadingIndicator,
  BlockUIContainer,
  BlockUIOverlay,
  BlockUIMessageContainer,
  BlockUIMessage,
  Indicator,
  Circle,
} from './styled';

interface BlockUIProps {
  blocking?: boolean;
  title?: string;
}

// eslint-disable-next-line react/function-component-definition
const BlockUI: FC<BlockUIProps> = ({
  blocking = false,
  title = 'Cargando',
}) => {
  if (!blocking) {
    return null;
  }
  return (
    <Portal target="#root">
      <BlockUIContainer>
        <BlockUIOverlay />
        <BlockUIMessageContainer>
          <BlockUIMessage>
            {!!title && <h4>{title}</h4>}
            <LoadingIndicator>
              <Indicator viewBox="0 0 100 100">
                <Circle cx="50" cy="50" r="45" />
              </Indicator>
            </LoadingIndicator>
          </BlockUIMessage>
        </BlockUIMessageContainer>
      </BlockUIContainer>
    </Portal>
  );
};

export default memo(BlockUI);
