import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Chip,
  Divider,
  Flex,
  ModalHeader,
  Text,
  useMediaQuery,
  config,
  CSS,
} from '@konta/ui';
import CloseIcon from 'mdi-react/CloseIcon';
import { ProcessType } from 'types/entities';
import useProcess from '@hooks/useProcess';
import Loader from '@components/Loader';
import usePendingTasksStore from 'store/pendingTasksStore';
import ProcessStepStepper from '../ProcessStepStepper';

interface ProcessLayoutProps {
  title: string;
  titleChip?: string;
  content?: React.ReactNode;
  leftSidebarContent?: React.ReactNode;
  rightSidebarContent?: React.ReactNode;
  currentProcessType: ProcessType;
  withDefaultLeftSidebar?: boolean;
  fullContent?: boolean;
  contentCss?: CSS;
}

function LeftSidebar({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      css={{
        flex: 1,
        padding: '16px 0 0 24px',
        '@md': {
          padding: '32px 0 0 48px',
        },
      }}
    >
      {children}
    </Flex>
  );
}

export default function ProcessStepLayout({
  title,
  titleChip,
  content,
  leftSidebarContent,
  rightSidebarContent,
  currentProcessType,
  withDefaultLeftSidebar = true,
  fullContent,
  contentCss,
}: ProcessLayoutProps) {
  const navigate = useNavigate();
  const { cleanSelectedTask } = usePendingTasksStore();
  const isTabletOrMobile = useMediaQuery(config.media['<md']);
  const { loadingCurrentProcesses } = useProcess(currentProcessType);
  const showLeftSide = !isTabletOrMobile && !!leftSidebarContent;
  const showRightSide = !isTabletOrMobile && !!rightSidebarContent;
  const showStepper =
    !isTabletOrMobile && withDefaultLeftSidebar && !leftSidebarContent;
  const hasLeftSide = showLeftSide || showStepper;
  const hasRightSide = showRightSide;
  const handleClose = () => {
    cleanSelectedTask();
    navigate('/app/pendientes');
  };

  const flex = useMemo(() => {
    if (hasLeftSide && hasRightSide) {
      return {
        content: 4,
        right: 2,
      };
    }
    if (hasLeftSide && !hasRightSide) {
      return {
        content: 6,
        right: 0,
      };
    }
    if (!hasLeftSide && hasRightSide) {
      return {
        content: 3,
        right: 1,
      };
    }
    return {
      content: 1,
    };
  }, [hasLeftSide, hasRightSide]);

  if (loadingCurrentProcesses) {
    return <Loader />;
  }

  return (
    <>
      <ModalHeader
        divided
        css={{
          marginBottom: 0,
          position: 'sticky',
          top: 0,
          zIndex: 999,
          backgroundColor: 'white',
        }}
      >
        <Button icon variant="text" onClick={handleClose}>
          <CloseIcon />
        </Button>
        <Divider vertical />
        <Text regular m lineHeight="m" data-cy="onboarding-header-title">
          {title}
        </Text>
        {!!titleChip && <Chip color="primary100" size="xs" label={titleChip} />}
      </ModalHeader>
      <Flex
        css={{
          flex: 1,
          gap: '$16',
          flexDirection: 'column',
          '@md': {
            flexDirection: 'row',
          },
        }}
      >
        {showLeftSide && <LeftSidebar>{leftSidebarContent}</LeftSidebar>}
        {showStepper && (
          <LeftSidebar>
            <ProcessStepStepper processType={currentProcessType} />
          </LeftSidebar>
        )}

        {!!content && (
          <Flex
            direction="column"
            data-cy="processStepLayoutContent"
            css={{
              ...(!fullContent &&
                !hasRightSide &&
                hasLeftSide && {
                  '& > *': {
                    maxWidth: '100%',
                  },
                  '@lg': {
                    '& > *': {
                      maxWidth: '80%',
                    },
                  },
                }),
              overflow: 'hidden',
              height: 'fit-content',
              flex: flex.content,
              gap: '32px',
              padding: '16px 24px 16px',
              '@md': {
                padding: hasLeftSide ? '32px 48px 16px 48px' : '32px 48px 16px',
              },
              ...contentCss,
            }}
          >
            {content}
          </Flex>
        )}
        {showRightSide && (
          <Flex
            direction="column"
            css={{
              height: '100%',
              flex: flex.right,
              backgroundColor: '#F6F9FB',
              padding: '32px 16px 16px',
              gap: '48px',
              '@md': {
                padding: '136px 32px 16px', // top, left-right, bottom
              },
            }}
          >
            {rightSidebarContent}
          </Flex>
        )}
      </Flex>
    </>
  );
}
