import FullModal from '@components/FullModal';
import { OnboardingHomeCard } from '@containers';

interface PreOnboardingHomeCardProps {
  openModal: boolean;
  toggleOpenModal: () => void;
}

export default function PreOnboardingHomeCardModal({
  openModal,
  toggleOpenModal,
}: PreOnboardingHomeCardProps) {
  return (
    <FullModal
      open={openModal}
      toggleClose={toggleOpenModal}
      id="preOnboardingHomeCard"
      title="Comprar suscripción"
    >
      <OnboardingHomeCard />
    </FullModal>
  );
}
