import React from 'react';
import { useToggle } from 'rooks';
import { Flex, Button, Text, Chip, Divider } from '@konta/ui';
import useSubscriptions from '@hooks/useSubscriptions';
// eslint-disable-next-line import/no-cycle
import { Loader } from '@components';
import toCurrency from '@util/toCurrency';
import DeleteUserSubscriptionModal from '../DeleteUserSubscriptionModal';

function PlanDescriptionText({ bold, children, css = {} }) {
  return (
    <Text
      color={bold ? 'gray900' : 'gray600'}
      css={css}
      s
      lineHeight="s"
      medium
    >
      {children}
    </Text>
  );
}

function PlanDivider() {
  return (
    <Flex
      css={{ padding: '10px 0 0', hr: { marginBottom: '0' } }}
      direction="column"
    >
      <Divider />
    </Flex>
  );
}

export default function UserSubscription({ serviceSubscriptionType }) {
  const { handleCancel, subscriptions, loading, ...subscriptionsProps } =
    useSubscriptions();

  const [openDeleteModal, toggleOpenDeleteModal] = useToggle();
  const [planCollapse, togglePlanCollapse] = useToggle();

  const {
    totalOfNextPaymentWithDiscount,
    nextPlanName,
    totalOfNextPaymentWithoutIva,
    nextPriceWithoutIva,
    nextDiscount,
    totalIvaOfNextPayment,
    dateValidToWithFormat,
    dateSubscribedWithFormat,
    billingPeriod,
    durationMonth,
    discountText,
    totalOfExtraRegimeWithoutIva,
    totalOfExtraTaxesWithoutIva,
    totalIvaOfExtraRegime,
    totalIvaOfExtraTaxes,
    extraRegimeQuantity,
    nextRegimePriceWithoutIva,
    nextTaxesPriceWithoutIva,
    isActive,
    totalOfExtraTaxesPayment,
    totalOfExtraRegimesPayment,
    subtotalPayment,
  } = subscriptionsProps[serviceSubscriptionType];

  const { dateSubscribedWithFormat: nextDateSubscribedWithFormat } =
    subscriptionsProps.nextServiceSubscription;

  if (subscriptions.length < 1 || loading) {
    return <Loader loading={loading} />;
  }

  if (subscriptions[0]?.status === 'incomplete') {
    return null;
  }

  return (
    <>
      {openDeleteModal && (
        <DeleteUserSubscriptionModal
          toggle={toggleOpenDeleteModal}
          isOpen={openDeleteModal}
          subscriptionId={subscriptions[0].id}
          handleDeleteUserSubscriptionSubmit={handleCancel}
        />
      )}
      <Flex>
        {/* Content */}
        <Flex
          gap={8}
          direction="column"
          css={{ minWidth: 'auto', '@sm': { minWidth: '563px' } }}
        >
          {/* Heading and badge */}
          <Flex gap={8} align="center">
            <Text color="gray700" l lineHeight="l" medium>
              {nextPlanName}
            </Text>
            <Chip color="primary100" label={billingPeriod[0]} />
          </Flex>
          {/* Plan text */}
          <Flex direction="column">
            <Text color="gray500" l lineHeight="l">
              {toCurrency(totalOfNextPaymentWithDiscount || 0)} MXN por{' '}
              {billingPeriod[1]}
            </Text>
            <Text color="gray500" m lineHeight="m">
              Tu próximo pago será el {nextDateSubscribedWithFormat}
            </Text>
          </Flex>
          {/* Collapse trigger */}
          <Flex css={{ padding: '8px 0 0' }}>
            <Button color="primary" variant="text" onClick={togglePlanCollapse}>
              Ver detalle del plan
            </Button>
          </Flex>
          {/* Plan collapse */}
          {planCollapse && (
            <Flex
              css={{
                padding: '16px',
                border: '1px solid #EAECF0',
                borderRadius: '8px',
              }}
              direction="column"
            >
              <Text s lineHeight="s" color="gray600">
                {dateSubscribedWithFormat} - {dateValidToWithFormat}
              </Text>
              {/* Product */}
              <Flex css={{ padding: '8px 0 0' }} justify="between">
                {/* Plan a qty */}
                <Flex direction="column">
                  <PlanDescriptionText bold>{nextPlanName}</PlanDescriptionText>
                  <Text xs lineHeight="xs" color="gray500">
                    Cantidad {durationMonth}
                  </Text>
                </Flex>
                {/* Price and unit */}
                <Flex direction="column" align="end">
                  <PlanDescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
                    {toCurrency(+totalOfNextPaymentWithoutIva)} MXN
                  </PlanDescriptionText>
                  <Text
                    xs
                    lineHeight="xs"
                    color="gray500"
                    css={{ textAlign: 'end' }}
                  >
                    {toCurrency(+nextPriceWithoutIva)} MXN por unidad
                  </Text>
                </Flex>
              </Flex>
              {totalOfExtraRegimesPayment > 0 && (
                <Flex css={{ padding: '8px 0 0' }} justify="between">
                  {/* Plan a qty */}
                  <Flex direction="column">
                    <PlanDescriptionText bold>
                      Régimen extra
                    </PlanDescriptionText>
                    <Text xs lineHeight="xs" color="gray500">
                      Cantidad {extraRegimeQuantity} x {durationMonth} meses
                    </Text>
                  </Flex>
                  {/* Price and unit */}
                  <Flex direction="column" align="end">
                    <PlanDescriptionText
                      bold
                      css={{ flex: 1, textAlign: 'end' }}
                    >
                      {toCurrency(+totalOfExtraRegimeWithoutIva)} MXN
                    </PlanDescriptionText>
                    <Text
                      xs
                      lineHeight="xs"
                      color="gray500"
                      css={{ textAlign: 'end' }}
                    >
                      {toCurrency(+nextRegimePriceWithoutIva)} MXN por unidad
                    </Text>
                  </Flex>
                </Flex>
              )}
              {totalOfExtraTaxesPayment > 0 && (
                <Flex css={{ padding: '8px 0 0' }} justify="between">
                  {/* Plan a qty */}
                  <Flex direction="column">
                    <PlanDescriptionText bold>
                      Obligaciones extra
                    </PlanDescriptionText>
                    <Text xs lineHeight="xs" color="gray500">
                      Cantidad {durationMonth}
                    </Text>
                  </Flex>
                  {/* Price and unit */}
                  <Flex direction="column" align="end">
                    <PlanDescriptionText
                      bold
                      css={{ flex: 1, textAlign: 'end' }}
                    >
                      {toCurrency(+totalOfExtraTaxesWithoutIva)} MXN
                    </PlanDescriptionText>
                    <Text
                      xs
                      lineHeight="xs"
                      color="gray500"
                      css={{ textAlign: 'end' }}
                    >
                      {toCurrency(+nextTaxesPriceWithoutIva)} MXN por unidad
                    </Text>
                  </Flex>
                </Flex>
              )}
              {/* Subtotal */}
              <Flex css={{ padding: '24px 0 0' }} justify="between">
                <PlanDescriptionText bold>Subtotal</PlanDescriptionText>
                <PlanDescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
                  {toCurrency(+subtotalPayment)} MXN MXN
                </PlanDescriptionText>
              </Flex>
              {/* Divider */}
              <PlanDivider />
              {/* Total before taxes */}
              <Flex css={{ padding: '14px 0 0' }} justify="between">
                <PlanDescriptionText bold css={{ flex: 1 }}>
                  Total antes de impuestos
                </PlanDescriptionText>
                <PlanDescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
                  {toCurrency(subtotalPayment)} MXN
                </PlanDescriptionText>
              </Flex>
              {/* Total taxes */}
              <Flex css={{ padding: '14px 0 0' }} justify="between">
                <PlanDescriptionText>
                  Impuestos {nextPlanName} (16%)
                </PlanDescriptionText>
                <PlanDescriptionText css={{ flex: 1, textAlign: 'end' }}>
                  {toCurrency(totalIvaOfNextPayment)} MXN
                </PlanDescriptionText>
              </Flex>
              {totalOfExtraRegimesPayment > 0 && (
                <Flex css={{ padding: '14px 0 0' }} justify="between">
                  <PlanDescriptionText>
                    Impuestos Régimen extra (16%)
                  </PlanDescriptionText>
                  <PlanDescriptionText css={{ flex: 1, textAlign: 'end' }}>
                    {toCurrency(totalIvaOfExtraRegime)} MXN
                  </PlanDescriptionText>
                </Flex>
              )}
              {totalOfExtraTaxesPayment > 0 && (
                <Flex css={{ padding: '14px 0 0' }} justify="between">
                  <PlanDescriptionText>
                    Impuestos Obligaciones extra (16%)
                  </PlanDescriptionText>
                  <PlanDescriptionText css={{ flex: 1, textAlign: 'end' }}>
                    {toCurrency(totalIvaOfExtraTaxes)} MXN
                  </PlanDescriptionText>
                </Flex>
              )}
              {/* Discount */}
              {nextDiscount > 0 && (
                <Flex css={{ padding: '14px 0 0' }} justify="between">
                  <PlanDescriptionText css={{ flex: 1 }}>
                    Descuento {discountText}
                  </PlanDescriptionText>
                  <PlanDescriptionText css={{ flex: 1, textAlign: 'end' }}>
                    -{toCurrency(nextDiscount)} MXN
                  </PlanDescriptionText>
                </Flex>
              )}
              {/* Divider */}
              <PlanDivider />
              {/* Total */}
              <Flex css={{ padding: '14px 0 0' }} justify="between">
                <PlanDescriptionText bold>Total</PlanDescriptionText>
                <PlanDescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
                  {toCurrency(totalOfNextPaymentWithDiscount)} MXN
                </PlanDescriptionText>
              </Flex>
            </Flex>
          )}
          {/* Actions */}
          <Flex align="center" css={{ padding: '8px 0 0' }} gap={12}>
            <Button
              disabled={!isActive}
              onClick={toggleOpenDeleteModal}
              color="gray"
              variant="outlined"
            >
              Cancelar suscripción
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
