import { ChevronLeftLineIcon, ChevronRightLineIcon } from '@konta/icons';
import { Button, Flex } from '@konta/ui';

const commonButtonProps = {
  noFill: true,
  size: 'xs' as const,
  icon: true,
  variant: 'outlined' as const,
};

interface NavigationProps {
  handleExternalPrev: () => void;
  handleExternalNext: () => void;
  buttonState: {
    isPrevDisabled: boolean;
    isNextDisabled: boolean;
  };
  isVisible: boolean;
}

export default function Navigation({
  handleExternalPrev,
  handleExternalNext,
  buttonState,
  isVisible,
}: NavigationProps) {
  if (!isVisible) return null;
  return (
    <Flex gap="8" align="center">
      <Button
        {...commonButtonProps}
        onClick={handleExternalPrev}
        disabled={buttonState.isPrevDisabled}
      >
        <ChevronLeftLineIcon />
      </Button>
      <Button
        {...commonButtonProps}
        onClick={handleExternalNext}
        disabled={buttonState.isNextDisabled}
      >
        <ChevronRightLineIcon />
      </Button>
    </Flex>
  );
}
