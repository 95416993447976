import React, { useEffect } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useFlexLayout,
} from 'react-table';

import { TablePagination } from '@components';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <div className="custom-checkbox custom-control">
          <input
            type="checkbox"
            className="custom-control-input"
            ref={resolvedRef}
            {...rest}
          />
          <label className="custom-control-label" />
        </div>
        {/* <input type="checkbox"ref={resolvedRef} {...rest}></input> */}
      </>
    );
  }
);

function UploadTransactionTable({
  loading,
  columns,
  data,
  setSelectedRows,
  toogleAllRowsSelection,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination,
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          width: 60,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    setSelectedRows(selectedFlatRows);
  }, [selectedFlatRows]);

  useEffect(() => {
    toggleAllRowsSelected(false);
  }, [toogleAllRowsSelection]);

  const noDataText = 'No hay movimientos de banco';

  return (
    <>
      <table {...getTableProps()} className="r-table table compact-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sorted-desc'
                        : 'sorted-asc'
                      : ''
                  }
                >
                  {column.render('Header')}
                  <span />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.length > 0 && !loading ? (
            page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={row.isSelected ? 'selected' : ''}
                >
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      key={`td_${cellIndex}`}
                      {...cell.getCellProps({
                        className: cell.column.cellClass,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <>
              {[...Array(pageSize)].map((row, rowIndex) => {
                return (
                  <tr role="row" key={`row-${rowIndex}`}>
                    {[...Array(columns.length)].map((row, cellIndex) => {
                      return (
                        <td role="cell" key={`cell-${cellIndex}`}>
                          <div className="list-item-heading"> </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
      {page.length === 0 && <div className="rt-noData">{noDataText}</div>}
      <div className={`rt-loading ${loading ? 'rt-active' : ''}`}>
        <div className="rt-loading-inner">Cargando...</div>
      </div>

      <TablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[10, 20, 40, 150]}
        showPageSizeOptions
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />
    </>
  );
}

export default UploadTransactionTable;
