import React, { useState, useEffect, useMemo } from 'react';
import { Row, Button, Label } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { declarationEntriesFetch, uploadXmlCfdi } from '@redux/actions';
import { toCurrency as $ } from '@util/Utils';
// eslint-disable-next-line import/no-cycle
import { IncomesTable } from '@containers';
import { Colxx, CustomModal } from '@components';
import { Separator } from '@components/CustomBootstrap';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import { InvoiceIncomeUpload } from './addForeignIncome/InvoiceUpload';

function IncomeClassification({
  platform_entries: { platform_entries },
  workflow,
}) {
  const dispatch = useDispatch();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [filesState, setFilesState] = useState({
    files: [],
    validFiles: false,
  });
  useEffect(() => {
    const workflow_id = workflow.id;
    dispatch(declarationEntriesFetch({ workflow_id }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow.id]);

  const toggleAddNewInvoice = () => {
    setIsAddModalOpen(!isAddModalOpen);
  };

  const toggleAddInvoiceModal = () => {
    setIsAddModalOpen(!isAddModalOpen);
    setFilesState({
      files: [],
      validFiles: false,
    });
  };
  const eventAddHandlers = {
    addedfile: (file) => {
      setFilesState({
        ...filesState,
        files: [...filesState.files, file],
        validFiles: true,
      });
    },
    removedfile: (file) => {
      const { files } = filesState;
      const newFileArray = files.filter(
        (item) => item.name === file.name && item.size === file.size,
      );
      setFilesState({
        ...filesState,
        files: newFileArray,
        validFiles: !!files.length,
      });
    },
  };

  const handleAddInvoiceValidSubmit = (event, values, errors) => {
    const body = new FormData();
    body.append('platform_workflow_id', workflow.id);
    for (const file of filesState.files) {
      body.append('xml', file);
    }
    dispatch(uploadXmlCfdi({ body, nextPhase: false }));
    toggleAddNewInvoice();
  };

  const total_incomes = platform_entries
    ? platform_entries.reduce((accumulator, currentValue) => {
        if (
          currentValue.entry_type === 'foreign_expenses' ||
          currentValue.entry_type === 'national_expenses' ||
          currentValue.entry_type === 'foreign_products' ||
          currentValue.entry_type === 'foreign_invoices'
        ) {
          return accumulator + 0;
        }
        if (currentValue.accounting_status === 'fixed_asset') {
          return accumulator + parseFloat(currentValue.depreciation_amount);
        }
        if (currentValue.accounting_status === 'is_deductible') {
          const amount = currentValue.cfdi
            ? currentValue.cfdi.total
            : currentValue.amount;
          return accumulator + parseFloat(amount);
        }
        return accumulator + 0;
      }, 0)
    : 0;

  const data = useMemo(
    () =>
      platform_entries
        ? platform_entries.filter(
            (item) => item.entry_type === 'national_income',
          )
        : [],
    [platform_entries],
  );

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Clasificar ingresos"
          description="En esta sección puede clasificar sus ingresos."
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton />
        </>
      }
    >
      <div className="w-100">
        <Row>
          <Colxx xxs={{ size: 12 }}>
            <div className="w-80 w-sm-100">
              <h1>
                En este apartado podrá ver una lista de las facturas de
                ingresos, clasificarlos y agregar nuevos.
              </h1>
            </div>
          </Colxx>
        </Row>
        <Row className="mb-2">
          <Colxx xxs={{ size: 12, offset: 10 }}>
            <Button color="primary" onClick={toggleAddNewInvoice}>
              Agregar Factura
            </Button>
          </Colxx>
        </Row>
        <Row>
          <Colxx md="12">
            <IncomesTable data={data} />
          </Colxx>
        </Row>
        <Row className="justify-content-end">
          <Colxx sm={12}>
            <Separator className="my-3" />
          </Colxx>
          <Colxx sm={2} className="text-right">
            <Label className="av-label" for="global_total">
              <b>Total de ingresos</b>
            </Label>
            <h6>{$(total_incomes)}</h6>
          </Colxx>
        </Row>
      </div>
      <CustomModal
        title="Agregar nueva factura de ingreso"
        toggle={toggleAddInvoiceModal}
        isOpen={isAddModalOpen}
        handleValidSubmit={handleAddInvoiceValidSubmit}
        is_disabled={filesState.files.length === 0}
      >
        <Row className="mx-2">
          <Colxx xxs={12} className="mb-2">
            <h2>
              Si tiene el XML de una factura de ingresos, adjuntela en el
              apartado siguiente.
            </h2>
          </Colxx>
        </Row>

        <InvoiceIncomeUpload events={eventAddHandlers} />
      </CustomModal>
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ platform_entries, workflows }) => {
  const { current_phase_id } = workflows.selected_workflow.workflow;
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { platform_entries, current_phase_id, workflow, wloading };
};
export default connect(mapStateToProps)(IncomeClassification);
