import { ReactNode } from 'react';
import { Text } from '@konta/ui';
import toCurrency from '@util/toCurrency';
import {
  MetricTotalContainer,
  MetricTotalContent,
  MetricTotalContentTitle,
} from './styled';

interface MetricTotalProps {
  title?: string;
  value?: number;
  isTotal?: boolean;
  detailButton?: ReactNode;
}

export default function Metric({
  title,
  value,
  isTotal,
  detailButton,
}: MetricTotalProps) {
  return (
    <MetricTotalContainer
      css={{
        borderBottom: isTotal ? 'none' : '1px solid $gray200',
      }}
    >
      <MetricTotalContent>
        <MetricTotalContentTitle>
          <Text
            size="m"
            color="gray700"
            {...{
              semibold: isTotal,
              medium: !isTotal,
            }}
          >
            {title}
          </Text>
        </MetricTotalContentTitle>

        <Text size="l" color={isTotal ? 'primary700' : 'gray500'} medium>
          {toCurrency(value || 0)} MXN
        </Text>
      </MetricTotalContent>
      {detailButton && detailButton}
    </MetricTotalContainer>
  );
}
