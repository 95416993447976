import { getCurrencyHeader } from 'shared/util/table/headers';
import { getCurrencyCell } from './cells';
import { getCurrencyFooter } from './footers';
import { getValue } from './values';
import type {
  Column,
  CurrencyType,
  GetCurrencyColumnOptions,
  SourceType,
} from './types';

const COLUMN_NUMBER_SIZE = 100;

const labels: Record<CurrencyType, string> = {
  total: 'Total',
  subtotal: 'Subtotal',
  baseIva16: 'Base IVA 16',
  baseIva8: 'Base IVA 8',
  baseIva0: 'Base IVA 0',
  baseIvaExempt: 'Base IVA Exento',
  discount: 'Descuento',
  iva: 'IVA',
  retainedIva: 'IVA Retenido',
  retainedIsr: 'ISR Retenido',
  totalRetained: 'Total de Retenciones',
};

export const getCurrencyColumn = ({
  source,
  type,
}: GetCurrencyColumnOptions): Column => {
  const label = labels[type] || 'Columna';
  return {
    filterHandler: {
      type: 'number',
      initialFilter: 0,
    },
    enableGrouping: false,
    header: getCurrencyHeader(label),
    size: COLUMN_NUMBER_SIZE,
    label,
    id: type,
    accessorFn: (entry) => getValue(entry, type),
    cell: getCurrencyCell(type),
    footer: getCurrencyFooter(source, type),
  };
};

export const getCurrencyColumns = (source: SourceType): Column[] => {
  const currencyTypes: CurrencyType[] = [
    'total',
    'subtotal',
    'baseIva16',
    'baseIva8',
    'baseIva0',
    'baseIvaExempt',
    'discount',
    'iva',
    'retainedIva',
    'retainedIsr',
    'totalRetained',
  ];
  return currencyTypes.map((type: CurrencyType) =>
    getCurrencyColumn({ source, type }),
  );
};
