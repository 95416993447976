import { useCallback } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { putDeclaration } from 'shared/api/declarations';
import type { Declaration, DeclarationAttributes } from 'types/entities';
import type { AxiosRequestConfig } from 'axios';

export default function usePutDeclaration(
  declarationId: number | string,
  axiosConfig?: AxiosRequestConfig<Declaration>,
  queryOptions?: UseMutationOptions<
    Declaration,
    unknown,
    DeclarationAttributes
  >,
) {
  const putDeclarationRequest = useCallback(
    (payload: DeclarationAttributes) =>
      putDeclaration(payload, declarationId, axiosConfig),
    [axiosConfig, declarationId],
  );
  return useMutation(putDeclarationRequest, queryOptions);
}
