import { useMemo, useState } from 'react';
import { Filters } from '@konta/ui';
import useWorkflow from '@components/Workflow/hooks/useWorkflow';
import useDeclarationEntriesByWorkflow from 'shared/hooks/useDeclarationEntriesByWorkflow';
import useListPagination from '@hooks/useListPagination';
import type { DeclarationEntry } from 'types/entities';
import filteredDeclarationsEntries from './Filters/filteredDeclarationsEntries';
import { filtersList } from './Filters/filtersList';

interface SelectedEntry extends DeclarationEntry {
  newAccountingStatus?: DeclarationEntry['accounting_status'];
  newDeclarationId?: number;
  modalHeader: string;
  title: string;
  subtitle: string | React.ReactNode;
}
export type UseIncomeListReturnType = ReturnType<typeof useExpensesList>;
export default function useExpensesList() {
  const {
    workflowId,
    regimeOptions,
    currentRegimes,
    isLoadingWorkflow,
    activeDeclarations,
    updateCurrentWorkflow,
    updateWorkflowGraphs,
    taxableEntityPreferences,
  } = useWorkflow();
  const [activeFilters, setActiveFilters] = useState<Filters>([]);
  const [selectedEntry, setSelectedEntry] = useState<SelectedEntry | null>(
    null,
  );

  const preferredFiscalRegime =
    taxableEntityPreferences?.preferred_fiscal_regime;

  const { expensesEntries, declarationEntriesLoading, refetch } =
    useDeclarationEntriesByWorkflow({
      params: {
        workflow_id: workflowId,
      },
    });
  const filteredData = useMemo(
    () => filteredDeclarationsEntries(expensesEntries ?? [], activeFilters),
    [activeFilters, expensesEntries],
  );

  const { paginatedData, paginationProps } = useListPagination(filteredData);

  const filters: Filters = useMemo(
    () =>
      filtersList.map((filter) => {
        if (filter.id === 'fiscal_regime_id') {
          return {
            ...filter,
            options: regimeOptions,
          };
        }
        return filter;
      }),
    [regimeOptions],
  );

  const toggleEntryModal = () => {
    setSelectedEntry(null);
  };

  return {
    activeFilters,
    setActiveFilters,
    isLoading: declarationEntriesLoading || isLoadingWorkflow,
    expensesEntries: paginatedData,
    filters,
    paginationProps,
    regimeOptions,
    selectedEntry,
    setSelectedEntry,
    toggleEntryModal,
    refetch,
    currentRegimes,
    activeDeclarations,
    updateCurrentWorkflow,
    updateWorkflowGraphs,
    preferredFiscalRegime,
  };
}
