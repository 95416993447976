import React, { useEffect, useMemo, useState } from 'react';
import {
  Row,
  Button,
  Collapse,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  Label,
} from 'reactstrap';
import { Colxx, CustomModal } from '@components';
// eslint-disable-next-line import/no-cycle
import { UploadTransactionTable } from '@containers';
import { NotificationManager } from '@components/Notifications';

import { AvGroup, AvField } from 'availity-reactstrap-validation';

import { connect, useDispatch } from 'react-redux';
import {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
  bankTransactionsDeclarationsFetch,
  bankTransactionsDeclarationsBulkUpdate,
} from '@redux/actions';
import toCurrency from '@util/toCurrency';
import { Edit1Icon } from '@konta/icons';
import Switch from 'rc-switch';
import { Flex } from '@konta/ui';
import WorkflowLayout from '@components/WorkflowLayout';
import WorkflowHeader from '@components/WorkflowHeader';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';

const IVA_OPTIONS = [
  { label: 'IVA 16%', value: 0.16, key: 1 },
  { label: 'IVA 8%', value: 0.08, key: 2 },
  { label: 'IVA 0%', value: 0, key: 3 },
];

function ClassifyTransaction({ workflow, bank_transactions, bloading }) {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [toogleClassifyModal, setToogleClassifyModal] = useState(false);
  const [toogleAllRowsSelection, setToogleAllRowsSelection] = useState(true);
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [ivaBasesCollapse, setIvaBasesCollapse] = useState(false);
  const [selectedIva, setSelectedIva] = useState();

  const inflowBankTransactions = bank_transactions.filter(
    ({ transaction_type, accounting_status }) =>
      transaction_type === 'inflow' && accounting_status === 'is_taxable',
  );

  const totalAmountSelected = selectedRows.reduce(
    (sumOfAmounts, currentValue) =>
      currentValue.original.bank_transaction.amount + sumOfAmounts,
    0,
  );

  useEffect(() => {
    const workflow_id = workflow.id;
    dispatch(bankTransactionsDeclarationsFetch({ workflow_id }));
  }, [dispatch, workflow.id]);

  const handleClassifyActionOnDropDown = (row) => {
    row.toggleRowSelected(true);
    setToogleClassifyModal(!toogleClassifyModal);
  };

  const data = React.useMemo(() => inflowBankTransactions, [bank_transactions]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Importe',
        accessor: 'bank_transaction.amount',
        width: 90,
        maxWidth: 90,
        Cell: ({ value }) => (
          <p className="list-item-heading"> {toCurrency(+value)} </p>
        ),
      },
      {
        Header: 'IVA',
        accessor: 'iva_rate',
        width: 90,
        maxWidth: 90,
        Cell: ({ value, row }) => {
          const [tooltipOpen, setTooltipOpen] = useState(false);
          const toggle = () => setTooltipOpen(!tooltipOpen);
          const ivaPercentage = value ? `${parseFloat(value) * 100}%` : '-';
          const [badgeColor, badgeText] = useMemo(() => {
            if (Number(row.original.total_exempt)) {
              return ['warning', 'Exento'];
            }
            return (
              {
                '16%': ['secondary', '16%'],
                '8%': ['info', '8%'],
                '0%': ['purple', '0%'],
              }[ivaPercentage] || ['light', '']
            );
          }, [row.original.total_exempt, ivaPercentage]);

          return (
            <>
              <Tooltip
                placement="right-start"
                isOpen={tooltipOpen}
                autohide={false}
                target={`transaction-${row.original.id}`}
                toggle={toggle}
                data-testid="tooltiptrest"
              >
                {(row.original.iva_rate === '0.16' ||
                  row.original.iva_rate === '0.08') && (
                  <p>
                    {`IVA ${ivaPercentage}`}:{' '}
                    {Number(row.original.total_with_iva)}
                  </p>
                )}
                {Number(row.original.total_exempt) > 0 && (
                  <p>IVA exento: {Number(row.original.total_exempt)}</p>
                )}
                {Number(row.original.total_zero_iva) > 0 && (
                  <p>IVA 0%: {Number(row.original.total_zero_iva)}</p>
                )}
              </Tooltip>
              <Badge
                id={`transaction-${row.original.id}`}
                color={badgeColor}
                onClick={() => row.toggleRowSelected(row.original.isSelected)}
                className="cursor-pointer badge-clear"
              >
                {badgeText}
              </Badge>
            </>
          );
        },
      },
      {
        Header: 'Descripción',
        accessor: 'bank_transaction.description',
        width: 400,
        cellClass: 'cell-small-text',
        Cell: ({ value }) => (
          <span className="list-item-heading"> {value} </span>
        ),
      },
      {
        Header: 'Referencia',
        accessor: 'bank_transaction.reference',
        width: 130,
        Cell: ({ value }) => <p className="list-item-heading"> {value} </p>,
      },
      {
        Header: 'Fecha',
        accessor: 'bank_transaction.date_issued',
        width: 90,
        maxWidth: 90,
        Cell: ({ value }) => <p className="list-item-heading"> {value} </p>,
      },
      {
        Header: '',
        accessor: 'id',
        width: 60,
        maxWidth: 60,
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-center">
              <UncontrolledDropdown className="button-transparent-container">
                <DropdownToggle caret className="button-transparent">
                  <i className="glyph-icon simple-icon-options button-transparent-icon" />
                  {/* <MoreSqFrIcon className="button-transparent-icon"/> */}
                </DropdownToggle>
                <DropdownMenu right className="">
                  <DropdownItem
                    className="sidebar-header-dropdown-item"
                    onClick={() => handleClassifyActionOnDropDown(row)}
                  >
                    <Edit1Icon className="sidebar-header-dropdown-item-icon" />
                    <div className="sidebar-header-dropdown-item-text">
                      Clasificar
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          );
        },
      },
    ],
    [],
  );

  const handleClassifyModalSubmit = (event, values) => {
    const { total_with_iva, total_exempt, total_zero_iva, iva_rate } = values;

    const totalWithIva = Number(total_with_iva);
    const totalExempt = Number(total_exempt);
    const totalZeroIva = Number(total_zero_iva);
    const ivaRate = iva_rate === 'exempt' ? iva_rate : Number(iva_rate);

    const bankTransactionsDeclarations = selectedRows.map((selectedRow) => {
      const total = Number(selectedRow.original.bank_transaction.amount);
      if (toggleSwitch) {
        return {
          id: selectedRow.original.id,
          declaration_id: selectedRow.original.declaration_id,
          total_with_iva: totalWithIva,
          total_exempt: totalExempt,
          total_zero_iva: totalZeroIva,
          iva_rate: ivaRate,
        };
      }
      return {
        id: selectedRow.original.id,
        declaration_id: selectedRow.original.declaration_id,
        iva_rate: ivaRate === 'exempt' ? 0 : ivaRate,
        total_with_iva: [0.16, 0.08].includes(ivaRate) ? total : 0,
        total_exempt: ivaRate === 'exempt' ? total : 0,
        total_zero_iva: ivaRate === 0 ? total : 0,
      };
    });

    const body = {
      bank_transactions_declarations: bankTransactionsDeclarations,
    };

    if (
      toggleSwitch &&
      totalWithIva + totalExempt + totalZeroIva !== totalAmountSelected
    ) {
      NotificationManager.error(
        'La suma de los montos no coincide con el total a clasificar',
        'Suma de montos incorrecta',
        4000,
        null,
        null,
        null,
      );
      return;
    }

    dispatch(bankTransactionsDeclarationsBulkUpdate({ body }));
    setToogleClassifyModal(!toogleClassifyModal);
  };

  const handleSelectedIva = (event) => {
    setSelectedIva({
      [event.target.name]: Number(event.target.value),
      label: event.target.value * 100,
    });
    if (Number(event.target.value) === 0) {
      setIvaBasesCollapse(false);
    }
  };

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Clasifica el IVA de tus ingresos en bancos"
          description="Es importante indicarnos los ingresos que fueron a IVA de 8% o 0% para considerarlos en la declaración."
          videoId="zBI4erYSB0g"
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton />
        </>
      }
    >
      <Flex className="h-100" direction="column">
        <Row className="my-3">
          <Colxx md={6} className="d-flex mb-2">
            {selectedRows.length > 0 ? (
              <div className="d-flex align-items-center">
                <span>{selectedRows.length} seleccionados</span>
                <div className="selected-text-divider pl-3 ml-3" />
                <span
                  className="clickable-text-color"
                  onClick={() =>
                    setToogleAllRowsSelection(!toogleAllRowsSelection)
                  }
                >
                  Anular selección
                </span>
                <Button
                  size="sm"
                  className="new-btn ml-3"
                  color="primary"
                  onClick={() => {
                    setSelectedIva();
                    setIvaBasesCollapse(false);
                    setToogleClassifyModal(true);
                    setToggleSwitch(false);
                  }}
                >
                  <Edit1Icon className="button-icon" />
                  Clasificar
                </Button>
                <CustomModal
                  title={`Clasificar ${selectedRows.length} movimiento${
                    selectedRows.length > 1 ? 's' : ''
                  }`}
                  toggle={() => {
                    setSelectedIva();
                    setIvaBasesCollapse(false);
                    setToogleClassifyModal(!toogleClassifyModal);
                    // eslint-disable-next-line no-unused-expressions
                    setToggleSwitch(false);
                  }}
                  isOpen={toogleClassifyModal}
                  size="lg"
                  handleSubmit={(_) => _}
                  handleValidSubmit={handleClassifyModalSubmit}
                  submitText="Clasificar"
                  centered
                  // model={selectedProduct}
                >
                  <p>
                    ¡Clasificar el IVA de los movimiento es facil! Solo tienes
                    que selecionar el porcentaje de IVA para que de esa manera
                    nosotros podamos saber como considerar tus ingresos en el
                    cálculo.{' '}
                    <span>
                      <a
                        className="clickable-text-color font-italic"
                        href="https://www.sat.gob.mx/consulta/23972/conoce-las-deducciones-personales"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Más información
                      </a>
                    </span>
                  </p>
                  <strong>
                    {'El monto total a clasificar es de '}
                    {toCurrency(+totalAmountSelected)} MXN
                  </strong>
                  {selectedRows.length <= 1 && (
                    <div className="d-flex my-3">
                      <Label className="mr-2">Dividir montos del IVA</Label>
                      <Switch
                        checked={toggleSwitch}
                        onChange={setToggleSwitch}
                        className="custom-switch custom-switch-primary custom-switch-small"
                      />
                    </div>
                  )}
                  {!toggleSwitch && (
                    <Row className="mt-1">
                      <Colxx xxs={6} className="">
                        <AvGroup>
                          <AvField
                            type="select"
                            name="iva_rate"
                            label="Porcentaje de IVA que tiene el movimiento"
                            onChange={handleSelectedIva}
                            required
                            errorMessage="Campo inválido, seleccione una opción válida"
                          >
                            <option className="d-none" />
                            {[
                              ...IVA_OPTIONS,
                              { label: 'IVA exento', value: 'exempt', key: 4 },
                            ].map((item) => (
                              <option value={item.value} key={item.key}>
                                {item.label}
                              </option>
                            ))}
                          </AvField>
                        </AvGroup>
                      </Colxx>
                    </Row>
                  )}
                  {selectedRows.length <= 1 && (
                    <Row>
                      <Colxx xxs={{ size: 12, offset: 0 }} className="mb-2">
                        <Collapse isOpen={toggleSwitch}>
                          {toggleSwitch ? (
                            <AvGroup>
                              <Row>
                                <Colxx xxs={5}>
                                  <AvField
                                    type="number"
                                    name="total_with_iva"
                                    label={`Monto total  de ingresos con IVA  `}
                                    required={toggleSwitch}
                                    errorMessage="Escriba el monto del IVA"
                                  />
                                </Colxx>
                                <Colxx xxs={5}>
                                  <AvField
                                    type="select"
                                    name="iva_rate"
                                    label="Porcentaje de IVA que tiene el movimiento"
                                    onChange={handleSelectedIva}
                                    required={toggleSwitch}
                                    errorMessage="Campo inválido, seleccione una opción válida"
                                  >
                                    <option className="d-none" />
                                    <option value={0.16}>IVA 16%</option>
                                    <option value={0.08}>IVA 8%</option>
                                  </AvField>
                                </Colxx>
                              </Row>
                              <Row>
                                <Colxx xxs={5}>
                                  <AvField
                                    type="number"
                                    name="total_zero_iva"
                                    label="Monto al IVA 0%"
                                    required={toggleSwitch}
                                    defaultValue="0"
                                    errorMessage="Escriba el monto al IVA 0%"
                                  />
                                </Colxx>
                                <Colxx xxs={5}>
                                  <AvField
                                    type="number"
                                    name="total_exempt"
                                    label="Monto al IVA exento"
                                    required={toggleSwitch}
                                    defaultValue="0"
                                    errorMessage="Escriba el monto al IVA exento"
                                  />
                                </Colxx>
                              </Row>
                            </AvGroup>
                          ) : (
                            <AvGroup>
                              <Row>
                                <Colxx xxs={4} className="mb-2">
                                  <AvField
                                    type="number"
                                    name="total_with_iva"
                                    label={`Monto al ${selectedIva?.label}%`}
                                    required={ivaBasesCollapse}
                                    errorMessage="Escriba el monto del IVA"
                                  />
                                </Colxx>
                                <Colxx xxs={4} className="mb-2">
                                  <AvField
                                    type="number"
                                    name="total_exempt"
                                    label="Monto sin IVA"
                                    required={ivaBasesCollapse}
                                    defaultValue="0"
                                    errorMessage="Escriba el monto al IVA exento"
                                  />
                                </Colxx>
                              </Row>
                            </AvGroup>
                          )}
                        </Collapse>
                      </Colxx>
                    </Row>
                  )}
                </CustomModal>
              </div>
            ) : (
              <div className="mb-5" />
            )}
          </Colxx>
        </Row>
        <Row>
          <Colxx className="table-overflow">
            <UploadTransactionTable
              columns={columns}
              data={data}
              setSelectedRows={setSelectedRows}
              toogleAllRowsSelection={toogleAllRowsSelection}
              loading={bloading}
            />
          </Colxx>
        </Row>
        <Row>
          <Colxx md={6} className="d-flex my-2">
            {selectedRows.length > 0 && (
              <div className="d-flex align-items-center">
                <span>{selectedRows.length} seleccionados</span>
                <div className="selected-text-divider pl-3 ml-3" />
                <span
                  className="clickable-text-color"
                  onClick={() =>
                    setToogleAllRowsSelection(!toogleAllRowsSelection)
                  }
                >
                  Anular selección
                </span>
                <Button
                  size="sm"
                  className="new-btn ml-3"
                  color="primary"
                  onClick={() => setToogleClassifyModal(true)}
                >
                  <Edit1Icon className="button-icon" />
                  Clasificar
                </Button>
              </div>
            )}
          </Colxx>
        </Row>
      </Flex>
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ workflows, bank_transactions_declarations }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  const {
    bank_transactions_declarations: bank_transactions,
    loading: bloading,
  } = bank_transactions_declarations;
  return { workflow, wloading, bank_transactions, bloading };
};

export default connect(mapStateToProps, {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
  bankTransactionsDeclarationsFetch,
  bankTransactionsDeclarationsBulkUpdate,
})(ClassifyTransaction);
