import { TAXABLE_CODE_OPTIONS } from '@constants/invoicing';

export default function createInvoiceConcept(initialValues = {}) {
  return {
    product: null,
    unit: null,
    quantity: '',
    price: '',
    amount: 0,
    formattedAmount: '',
    subtotal: 0,
    formattedSubtotal: '',
    discount: 0,
    taxableCode: TAXABLE_CODE_OPTIONS[1],
    ieps: null, // ConceptTax
    isr: null, // ConceptTax
    iva: null, // ConceptTax
    retainedIva: null, // ConceptTax

    ...initialValues,
  };
}
