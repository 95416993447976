import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  PAY_FISCAL_PERIOD,
  FETCH_CURRENT_FISCAL_PERIOD,
  UPLOAD_FISCAL_PERIOD_STATEMENT,
  FETCH_FISCAL_PERIOD,
  UPDATE_STATUS_FISCAL_PERIOD,
  UPLOAD_FISCAL_PERIOD_RECEIPT,
  POST_FEEDBACK,
} from '@constants/actionTypes';
import fetchSaga from './fetch';
import currentSaga from './current';
import payFiscalPeriodSaga from './pay';
import uploaFiscalPeriodStatementSaga from './statment_upload';
import updateStatus from './put';
import uploaFiscalPeriodReceiptSaga from './capture_line_upload';
import postFeedbackSaga from './feedback';

export function* watchFetchFiscalPeriod() {
  yield takeLatest(FETCH_FISCAL_PERIOD, fetchSaga);
}

export function* watchCurrent() {
  yield takeLatest(FETCH_CURRENT_FISCAL_PERIOD, currentSaga);
}

export function* watchPayFiscalPeriod() {
  yield takeLatest(PAY_FISCAL_PERIOD, payFiscalPeriodSaga);
}

export function* watchUplaoadFiscalPeriodStatement() {
  yield takeLatest(
    UPLOAD_FISCAL_PERIOD_STATEMENT,
    uploaFiscalPeriodStatementSaga
  );
}
export function* watchUpdateStatus() {
  yield takeLatest(UPDATE_STATUS_FISCAL_PERIOD, updateStatus);
}

export function* watchUplaoadFiscalPeriodReciept() {
  yield takeLatest(UPLOAD_FISCAL_PERIOD_RECEIPT, uploaFiscalPeriodReceiptSaga);
}

export function* watchPostFeedback() {
  yield takeLatest(POST_FEEDBACK, postFeedbackSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchPayFiscalPeriod),
    fork(watchUplaoadFiscalPeriodStatement),
    fork(watchFetchFiscalPeriod),
    fork(watchCurrent),
    fork(watchUpdateStatus),
    fork(watchUplaoadFiscalPeriodReciept),
    fork(watchPostFeedback),
  ]);
}
