import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

export default function useWatchError(callback) {
  const errors = useSelector((state) => state.errors.errors, shallowEqual);

  useEffect(() => {
    if (errors instanceof Array) {
      errors.forEach((error) => {
        callback({
          errorMessage: error,
          traceError: errors,
        });
      });
    }
    if (errors?.stamp instanceof Array) {
      errors.stamp.forEach((error) => {
        callback({
          errorMessage: error,
          traceError: errors,
        });
      });
    }
  }, [errors, callback]);
}
