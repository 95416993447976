import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ACCUMULATED_SALES } from '@constants/reactQueries';
import { getAccumulatedSales } from '@api/getAccumulatedSales';
import type { AccumulatedSales } from 'types/entities';

export default function useAccumulatedSales(
  axiosConfig?: AxiosRequestConfig<AccumulatedSales>,
  queryOptions?: UseQueryOptions<AccumulatedSales>,
) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<AccumulatedSales>(
    [ACCUMULATED_SALES, axiosConfig],
    async () => {
      const accumulatedSales = await getAccumulatedSales(axiosConfig);
      return accumulatedSales;
    },
    queryOptions,
  );

  return {
    accumulatedSalesLoading: isLoading || isFetching,
    accumulatedSales: data,
    ...rest,
  };
}
