import ConfirmModal from '@components/Modal/ConfirmModal';
import { Flex, Text } from '@konta/ui';
import type { Workflow } from 'types/entities';
import PreviousPeriodsTable from '../PreviousPeriodsTable';
import usePreviousPeriodsModalTable from './usePreviousPeriodsModalTable';

interface PreviousPeriodsModalTableProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  regimeOptionsWithDeclarationId: {
    label: string;
    value: string;
    declarationId: number;
  }[];
  workflow: Workflow | null;
  source: 'incomes' | 'expenses';
  updateCurrentWorkflow: () => void;
}
export default function PreviousPeriodsModalTable({
  isOpen,
  toggleIsOpen,
  regimeOptionsWithDeclarationId,
  workflow,
  source,
  updateCurrentWorkflow,
}: PreviousPeriodsModalTableProps) {
  const {
    isAddEntryModalOpen,
    toggleIsAddEntryModalOpen,
    setSelectedCfdis,
    addInvoiceBtnText,
    isAddInvoiceBtnEnabled,
    onCloseModal,
  } = usePreviousPeriodsModalTable({
    source,
    updateCurrentWorkflow,
    toggleIsOpen,
  });
  return (
    <ConfirmModal
      open={isOpen}
      title="Agregar factura de meses anteriores"
      onClose={onCloseModal}
      onCancel={onCloseModal}
      onAccept={toggleIsAddEntryModalOpen}
      acceptText={addInvoiceBtnText}
      isAcceptBtnEnabled={isAddInvoiceBtnEnabled}
      withBlur
      centered
      size="xl"
    >
      <Flex gap={16} column>
        <Text m>
          Elige las facturas que deseas incluir en una declaración de meses
          anteriores
        </Text>
        <PreviousPeriodsTable
          setSelectedCfdis={setSelectedCfdis}
          source={source}
          regimeOptionsWithDeclarationId={regimeOptionsWithDeclarationId}
          workflow={workflow}
          toggleIsAddEntryModalOpen={toggleIsAddEntryModalOpen}
          isAddEntryModalOpen={isAddEntryModalOpen}
        />
      </Flex>
    </ConfirmModal>
  );
}
