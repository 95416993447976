import React from 'react';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { Box, Chip, ChipGroup, Spacer, StepListItem, Text } from '@konta/ui';
import { Process, ProcessType } from 'types/entities';

export interface ProcessStepItemProps {
  process: Process;
  onClick?: () => void;
  onContinue?: () => void;
}

// add more processes here
const interactiveProcesses: ProcessType[] = [
  ProcessType.OnboardingOnboardingProcess,
  ProcessType.AnnualDeclarationProcess,
];

export default function ProcessStepItem({
  process,
  onClick,
  onContinue,
}: ProcessStepItemProps) {
  const currentStep = process.steps.find((step) => !step.completed);
  const hasCompletedSteps = process.steps.some((step) => step.completed);
  const isInteractiveProcess = interactiveProcesses.includes(process.type);
  const available = isInteractiveProcess && !process.completed;

  return (
    <StepListItem
      id={process.id}
      onClick={onClick}
      onMouseEnter={onClick}
      tabIndex={0}
      role="button"
    >
      <Text s medium color="gray900">
        {process.name}
      </Text>
      {available && (
        <ChipGroup
          data-testid={process.type}
          color="primary50"
          onClick={onContinue}
        >
          <Chip
            size="s"
            color="white"
            label={hasCompletedSteps ? 'Continuar' : 'Iniciar'}
            css={{ color: '$primary700' }}
          />
          <Text medium size="xs" color="primary700" css={{ ml: '$4' }}>
            {currentStep?.name}
          </Text>
          <Box
            css={{
              lineHeight: '0',
              color: '$primary700',
              svg: { width: '$16', height: '$16' },
            }}
          >
            <ArrowRightIcon />
          </Box>
        </ChipGroup>
      )}
      <Spacer />
      {available && (
        <Text s color="gray500" css={{ px: '$24' }}>
          5 min
        </Text>
      )}
      <Box
        css={{
          height: '$20',
          pl: '$16',
          color: '$gray600',
          svg: { width: '$20', height: '$20' },
        }}
      >
        <ChevronRightIcon />
      </Box>
    </StepListItem>
  );
}

ProcessStepItem.defaultProps = {
  onClick: null,
  onContinue: null,
};
