/* eslint-disable func-names */
import generateJWTToken from './JWT';

const is_production = process.env.REACT_APP_ENV === 'PRODUCTION';

// identify segment user
export const identifySegment = (uid, featureFlags = {}) => {
  if (!is_production || typeof window === 'undefined') return;

  const watermark = window.localStorage.getItem('watermark');
  if (!watermark) {
    const features = Object.keys(featureFlags).map((featureKey) => ({
      [`experiment_${featureKey}`]: featureFlags[featureKey],
    }));
    const identifyProps = Object.assign({}, ...features);
    analytics.identify(uid, identifyProps);
    window.zE('messenger', 'loginUser', function (callback) {
      callback(generateJWTToken({ externalId: uid }));
    });
  }
};

// page segment
export const pageSegment = (uid, customVars = {}) => {
  if (!is_production || typeof window === 'undefined') return;

  const watermark = window.localStorage.getItem('watermark');
  if (!watermark) {
    analytics.page();
  }
};
