import React from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { CustomSelectInput } from '@components';
import Select from 'react-select';

// TODO: refactor buttons and its props
export function CustomSelect(props) {
  const {
    feedback = 'Selecciona una opción',
    btnLabel = 'Nuevo',
    name = 'select',
    placeholder = 'Buscar y seleccionar',
    isLoading = false,
    isDirty = false,
    withButton = false,
    onChange,
    options,
    toggle,
    value,
    defaultValue,
    required = false,
    isDisabled,
    isMulti = false,
    withSecondaryButton = false,
    secondaryBtnLabel = 'Editar',
    secondaryToggle,
    hasError,
  } = props;

  const customStyles = {
    control: (base) => ({
      ...base,
      background: isDisabled ? '#E9ECEF' : '',
      borderColor: hasError ? '#dc3545 !important' : '',
    }),
  };

  return (
    <span className={value || !isDirty ? 'select--valid' : 'select--invalid'}>
      <InputGroup>
        <Select
          styles={customStyles}
          simpleValue
          placeholder={
            <>
              <i className="iconsmind-Magnifi-Glass2 pr-2" /> {placeholder}{' '}
            </>
          }
          components={{ Input: CustomSelectInput }}
          className={`react-select w-${withButton ? 80 : 100} ${
            isDisabled ? 'disabled-rs' : ''
          }`}
          classNamePrefix="react-select"
          id={name}
          name={name}
          value={value}
          options={options}
          required={required}
          onChange={onChange}
          isLoading={isLoading}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          isMulti={isMulti}
          hasError={hasError}
        />
        {withButton && (
          <InputGroupAddon addonType="append" className="w-20">
            {withSecondaryButton && (
              <Button
                className="w-100 p-0"
                color="primary"
                outline
                onClick={secondaryToggle}
                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                <span className="d-none d-lg-inline-flex">
                  {secondaryBtnLabel}
                </span>
                <span className="d-inline-flex d-lg-none">
                  <i className="iconsmind-Pencil" />
                </span>
              </Button>
            )}

            <Button
              className="w-100 p-0"
              color="primary"
              outline
              onClick={toggle}
              style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              <span className="d-none d-lg-inline-flex">{btnLabel}</span>
              <span className="d-inline-flex d-lg-none">+</span>
            </Button>
          </InputGroupAddon>
        )}
      </InputGroup>
      <p className="feedback">{feedback}</p>
    </span>
  );
}

export function CustomModal({
  handleSubmit,
  handleValidSubmit,
  title,
  children,
  toggle,
  isOpen,
  submitText = 'Guardar',
  model = {},
  size = 'lg',
  is_disabled = false,
  centered = false,
}) {
  return (
    <Modal centered={centered} isOpen={isOpen} toggle={toggle} size={size}>
      <AvForm
        onSubmit={handleSubmit}
        onValidSubmit={handleValidSubmit}
        model={model}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody className="custom-modal-body">{children}</ModalBody>
        <ModalFooter>
          <Button disabled={is_disabled} color="primary" type="submit">
            {submitText}
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}
