import React from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import { CoinDollarOutlineIcon } from '@konta/icons';
import { Colxx } from '@components/CustomBootstrap';
import { ReactComponent as OxxoIcon } from '@assets/img/payment-method/oxxo.svg';
import { ReactComponent as AmericanExpressIcon } from '@assets/img/payment-method/american_express.svg';
import { ReactComponent as CarnetIcon } from '@assets/img/payment-method/carnet.svg';
import { ReactComponent as MasterCardIcon } from '@assets/img/payment-method/mastercard.svg';
import { ReactComponent as PaypalIcon } from '@assets/img/payment-method/paypal.svg';
import { ReactComponent as SpeiIcon } from '@assets/img/payment-method/spei.svg';
import { ReactComponent as VisaIcon } from '@assets/img/payment-method/visa.svg';

const paymentIcons = {
  oxxo: OxxoIcon,
  american_express: AmericanExpressIcon,
  carnet: CarnetIcon,
  mastercard: MasterCardIcon,
  paypal: PaypalIcon,
  spei: SpeiIcon,
  visa: VisaIcon,
};

function ReferenceCard({ item = {} }) {
  if (!item?.brand) return null;

  const PaymentIcon =
    paymentIcons[item?.brand?.toLowerCase?.()] || CoinDollarOutlineIcon;

  return (
    <Card className="m-auto">
      <CardBody>
        <Row>
          <Colxx xxs="12" className="justify-content-center d-flex mb-4">
            <PaymentIcon className="payment-option__image" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="justify-content-center d-flex mb-4">
            <h3 className="text-secondary">
              {item.brand === 'spei'
                ? 'Pago por trasferencia bancaria con SPEI'
                : 'Pago por Oxxo Pay'}
            </h3>
          </Colxx>

          <Colxx xxs="12" className="justify-content-center d-flex mb-2">
            <h3 className="text-muted">
              {item.brand === 'spei'
                ? 'CLABE'
                : 'Número de referencia Oxxo Pay'}
            </h3>
          </Colxx>
          <Colxx xxs="12" className="justify-content-center d-flex mb-4">
            <h2 className="text-primary">{item.reference_subscription}</h2>
          </Colxx>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ReferenceCard;
