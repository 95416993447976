import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const UncategorizedTaxableEntities = lazy(
  () => import('./UncategorizedTaxableEntities'),
);
const Customer = lazy(() => import('../customer/Customer'));

const DeclarationsConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'declarations/uncategorized-taxable-entities',
      element: <UncategorizedTaxableEntities />,
    },
    {
      path: 'declarations/uncategorized-taxable-entities/:taxableEntityId/*',
      element: <Customer from="declarations" />,
      children: [
        {
          path: '',
          element: <Navigate replace to="uncategorized-entries" />,
        },
      ],
    },
  ],
};

export default DeclarationsConfig;
