import {
  BELVO_TOKEN_GET,
  BELVO_TOKEN_GET_SUCCESS,
  BELVO_TOKEN_GET_FAILURE,
  BELVO_ORPHAN_LINKS_FETCH,
  BELVO_ORPHAN_LINKS_FETCH_SUCCESS,
  BELVO_ORPHAN_LINKS_FETCH_FAILURE,
  BELVO_BANK_ACCOUNTS_FETCH,
  BELVO_BANK_ACCOUNTS_FETCH_SUCCESS,
  BELVO_BANK_ACCOUNTS_FETCH_FAILURE,

  // BELVO LINKS
  BELVO_LINKS_FETCH,
  BELVO_LINKS_FETCH_SUCCESS,
  BELVO_LINKS_FETCH_FAILURE,
  BELVO_LINKS_CONNECTION_CREATE,
  BELVO_LINKS_CONNECTION_CREATE_SUCCESS,
  BELVO_LINKS_CONNECTION_CREATE_FAILURE,
  BELVO_LINKS_CONNECTION_DELETE,
  BELVO_LINKS_CONNECTION_DELETE_SUCCESS,
  BELVO_LINKS_CONNECTION_DELETE_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  belvo_tokens: {},
  belvo_orphan_links: {
    status: {},
    loading: false,
    error: null,
  },
  belvo_bank_accounts: {
    accounts: [],
    loading: false,
    error: null,
  },
  belvo_links: null,
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BELVO_TOKEN_GET:
      return {
        ...state,
        loading: true,
      };
    case BELVO_TOKEN_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        belvo_tokens: action.payload,
      };
    case BELVO_TOKEN_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case BELVO_ORPHAN_LINKS_FETCH:
      return {
        ...state,
        belvo_orphan_links: {
          ...state.belvo_orphan_links,
          loading: true,
          error: null,
        },
      };
    case BELVO_ORPHAN_LINKS_FETCH_SUCCESS:
      return {
        ...state,
        belvo_orphan_links: {
          ...state.belvo_orphan_links,
          status: action.payload,
          loading: false,
          error: null,
        },
      };
    case BELVO_ORPHAN_LINKS_FETCH_FAILURE:
      return {
        ...state,
        belvo_orphan_links: {
          ...state.belvo_orphan_links,
          loading: false,
          error: true,
        },
      };
    case BELVO_BANK_ACCOUNTS_FETCH:
      return {
        ...state,
        belvo_bank_accounts: {
          ...state.belvo_bank_accounts,
          loading: true,
          error: null,
        },
      };

    case BELVO_BANK_ACCOUNTS_FETCH_SUCCESS:
      return {
        ...state,
        belvo_bank_accounts: {
          ...state.belvo_bank_accounts,
          accounts: action.payload,
          loading: false,
          error: null,
        },
      };
    case BELVO_BANK_ACCOUNTS_FETCH_FAILURE:
      return {
        ...state,
        belvo_bank_accounts: {
          ...state.belvo_bank_accounts,
          loading: false,
          error: true,
        },
      };
    case BELVO_LINKS_FETCH:
      return {
        ...state,
        loading: true,
      };
    case BELVO_LINKS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        belvo_links: action.payload.filter(
          (link) => link.aasm_state !== 'deactivated',
        ),
      };
    case BELVO_LINKS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case BELVO_LINKS_CONNECTION_CREATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case BELVO_LINKS_CONNECTION_CREATE_SUCCESS:
      return {
        ...state,
        belvo_tokens: {
          ...state.belvo_tokens,
          belvo_links: action.payload,
        },
        belvo_links: [...state.belvo_links, action.payload.belvo_link],
        loading: false,
        error: null,
      };
    case BELVO_LINKS_CONNECTION_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case BELVO_LINKS_CONNECTION_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case BELVO_LINKS_CONNECTION_DELETE_SUCCESS:
      return {
        ...state,
        belvo_links: state.belvo_links.filter(
          ({ link }) => link !== action.payload.link,
        ),
        loading: false,
        error: null,
      };
    case BELVO_LINKS_CONNECTION_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return { ...state };
  }
};
