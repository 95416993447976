import React from 'react';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import {
  ClipboardCopy,
  Flex,
  Text,
  CircularLoading,
  Button,
  styled,
} from '@konta/ui';
import { useQuery } from 'react-query';
import { useTaxableEntity } from '@hooks';
import getReferrers from '@api/getReferrers';
import * as chat from '@util/chat';
import ShareSocialNetworkLinks from '@components/ShareSocialNetworkLinks/ShareSocialNetworkLinks';
import PropTypes from 'prop-types';
import RightBand from './img/right-band.svg';
import ReferByWhatsappInput from './ReferByWhatsappInput';
import ReferByEmailInput from './ReferByEmailInput';

const StyledRoot = styled(Flex, {
  variants: {
    withBackground: {
      true: {
        '@xl': {
          position: 'relative',
          '&::after': {
            background: `url(${RightBand}) no-repeat`,
            content: '',
            height: '180px',
            position: 'absolute',
            width: '380px',
            right: '-1rem',
            top: '2rem',
          },
        },
      },
    },
  },
});

export default function ReferralsHeader({ withBackground }) {
  const { data = [], isLoading } = useQuery('get- referrers', async () => {
    const referrers = await getReferrers();

    return referrers.map((referrer) => {
      const displayLink = process.env.REACT_APP_REFERRAL_LINK.replace(
        '[code]',
        encodeURIComponent(referrer.code),
      );

      return {
        ...referrer,
        displayLink,
        link: `https://${displayLink}`,
      };
    });
  });

  const { taxable_entity: taxableEntity } = useTaxableEntity();
  const rewardType = taxableEntity.reward_type;

  return (
    <StyledRoot
      withBackground={withBackground}
      direction="column"
      gap={8}
      data-testid="referrals-header"
    >
      <Flex gap={12} direction="column">
        <Text x3l bold lineHeight="2xl">
          {rewardType === 'product'
            ? '¡Gana meses gratis recomendando Konta.com!'
            : '¡Obtén 500 pesos por cada recomendado a Konta.com!'}
        </Text>
        <Text l lineHeight="l" color="gray700">
          Compartiendo tu link de referido puedes regalarle a tus amigos un{' '}
          <Text bold>50% de descuento</Text> en la <br /> primera mensualidad en
          cualquiera de nuestros planes.
        </Text>
      </Flex>
      <Flex direction="column" gap="12" css={{ width: 'fit-content' }}>
        {isLoading && (
          <Flex justify="center">
            <CircularLoading xl css={{ margin: '$12' }} />
          </Flex>
        )}
        {!isLoading &&
          data.length > 0 &&
          data.map(({ id, link, displayLink }) => (
            <Flex
              key={id}
              css={{
                flexDirection: 'column',
                '@xs': { flexDirection: 'row', alignItems: 'center' },
              }}
              gap={16}
            >
              <Text color="primary700" l>
                {displayLink}
              </Text>
              <Flex css={{ gap: '$8' }}>
                <ClipboardCopy
                  size="xs"
                  variant="outlined"
                  leftIcon={
                    <ContentCopyIcon
                      style={{ width: '14px', height: '14px' }}
                    />
                  }
                  clipboard={link}
                  css={{ whiteSpace: 'nowrap' }}
                >
                  Copiar link
                </ClipboardCopy>
              </Flex>
            </Flex>
          ))}
        {!isLoading && data.length === 0 && (
          <div>
            <Button color="primary" onClick={chat.open}>
              Solicitar links para referir
            </Button>
          </div>
        )}
        <Flex gap="8" direction="column" css={{ maxWidth: '530px' }}>
          <ReferByEmailInput />
          <ReferByWhatsappInput />
        </Flex>
        <Flex gap="12" css={{ mt: '32px' }}>
          <ShareSocialNetworkLinks shareUrl={data[0]?.link} />
        </Flex>
      </Flex>
    </StyledRoot>
  );
}

ReferralsHeader.propTypes = {
  withBackground: PropTypes.bool,
};

ReferralsHeader.defaultProps = {
  withBackground: false,
};
