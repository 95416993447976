import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import type { InvoicePayload } from 'types/entities';
import {
  INVOICE_TYPE_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
  PERIODICITY_OPTIONS,
  EXPORT_CODE_OPTIONS,
  CURRENCY_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  DEFAULT_PAYMENT_METHOD_OPTION,
  DEFAULT_EXPORT_CODE_OPTION,
  DEFAULT_CURRENCY_OPTION,
  DEFAULT_INVOICE_TYPE_OPTION,
  DEFAULT_PERIODICITY_OPTION,
} from '@constants/invoicing';
import createInvoiceConcept from '@util/createInvoiceConcept';
import getInitialMonthByPeriodicity from '@util/getInitialMonthByPeriodicity';
import type { IRootState } from 'types/redux';
import useInvoiceDraftQuery from './useInvoiceDraftsQuery';
import useReceiverAttributesParser from './useReceiverAttributesParser';
import useCfdiRelatedAttributesParser from './useCfdiRelatedAttributesParser';
import useDraftSelectsParser from './useDraftSelectsParser';
import useIssuerAttributesParser from './useIssuerAttributesParser';

export default function useInvoiceDraftPayload(
  draftId: number,
  templateInitialValues: any,
) {
  const { invoiceDraft, invoiceDraftLoading } = useInvoiceDraftQuery(draftId);
  const taxableEntity = useSelector(
    (state: IRootState) => state.taxableEntity.taxable_entity,
  );
  const receipt = invoiceDraft?.body?.receipt;
  const invoiceType = useDraftSelectsParser(
    receipt?.type_of_receipt,
    INVOICE_TYPE_OPTIONS,
    DEFAULT_INVOICE_TYPE_OPTION,
  );
  const paymentMethod = useDraftSelectsParser(
    receipt?.payment_method,
    PAYMENT_METHOD_OPTIONS,
    DEFAULT_PAYMENT_METHOD_OPTION,
  );
  const exportCode = useDraftSelectsParser(
    receipt?.export_code,
    EXPORT_CODE_OPTIONS,
    DEFAULT_EXPORT_CODE_OPTION,
  );

  const currency = useDraftSelectsParser(
    receipt?.currency,
    CURRENCY_OPTIONS,
    DEFAULT_CURRENCY_OPTION,
  );
  const paymentType = useDraftSelectsParser(
    receipt?.payment_way,
    PAYMENT_TYPE_OPTIONS,
  );
  const periodicity = useDraftSelectsParser(
    receipt?.global_info_attributes?.periodicity,
    PERIODICITY_OPTIONS,
    DEFAULT_PERIODICITY_OPTION,
  );
  const { client, cfdiUsage, taxResidency } = useReceiverAttributesParser(
    receipt?.receiver_attributes,
  );
  const { legalName, fiscalRegime } = useIssuerAttributesParser(
    receipt?.issuer_attributes,
  );
  const { creditNoteInvoice } = useCfdiRelatedAttributesParser(
    client?.value,
    receipt?.cfdi_related_attributes,
  );

  return useMemo(() => {
    const periodicityYear =
      receipt?.global_info_attributes?.year || `${dayjs().year()}`;
    const taxIdentityRegistrationNumber =
      receipt?.receiver_attributes?.tax_identity_registration_number || '';
    const createdDate = receipt?.date || '';
    const orderNumber = receipt?.serie || '';
    const invoiceNumber = receipt?.folio_number || '';
    const exchangeRate = receipt?.exchange_rate || '';
    const postcode =
      receipt?.expedition_place || taxableEntity?.postcode || null;
    const total = +(receipt?.total || 0);
    const subtotal = +(receipt?.subtotal || 0);
    const discount = +(receipt?.discount || 0);
    const iva = null;
    const retainedIva = null;
    const isr = null;
    const ieps = null;

    const payload: InvoicePayload = {
      legalName,
      client,
      invoiceType,
      paymentMethod,
      cfdiUsage,
      creditNoteInvoice,
      paymentType,
      periodicity,
      periodicityYear,
      // TODO create parser function to get initial month by periodicity
      periodicityMonth: getInitialMonthByPeriodicity('04'),
      taxIdentityRegistrationNumber,
      exportCode,
      currency,
      createdDate,
      orderNumber,
      invoiceNumber,
      taxResidency,
      exchangeRate,
      postcode,
      total,
      subtotal,
      discount,
      fiscalRegime,
      // TODO create parser function to get concepts
      concepts: [createInvoiceConcept()],
      // TODO create parser function to get taxes (iva, retainedIva, isr, ieps)
      iva,
      retainedIva,
      isr,
      ieps,
    };

    let initialValues;
    if (templateInitialValues) {
      initialValues = templateInitialValues?.formik_values;
    } else if (
      invoiceDraft?.formik_values &&
      Object.values(invoiceDraft?.formik_values).length > 0
    ) {
      initialValues = invoiceDraft.formik_values;
    } else {
      initialValues = payload;
    }

    return {
      initialValues,
      invoiceDraft,
      invoiceDraftLoading,
    };
  }, [
    cfdiUsage,
    client,
    creditNoteInvoice,
    currency,
    exportCode,
    fiscalRegime,
    invoiceDraft,
    invoiceDraftLoading,
    invoiceType,
    legalName,
    paymentMethod,
    paymentType,
    periodicity,
    receipt?.date,
    receipt?.discount,
    receipt?.exchange_rate,
    receipt?.expedition_place,
    receipt?.folio_number,
    receipt?.global_info_attributes?.year,
    receipt?.serie,
    receipt?.subtotal,
    receipt?.receiver_attributes?.tax_identity_registration_number,
    receipt?.total,
    taxResidency,
    taxableEntity?.postcode,
    templateInitialValues,
  ]);
}
