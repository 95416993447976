import { InsightsDialog as KontaInsightsDialog } from 'shared/components/InsightsDialog';
import { useInsightsDialog } from '../InsightsDialogContext';

export default function InsightsDialog() {
  const {
    isOpen,
    setIsOpen,
    currentFlowIndex,
    currentSlideIndex,
    handleFlowChange,
    handleSlideChange,
    isActiveDialog,
    previewContainerCss,
    onFlowComplete,
    onStepVisit,
    onFlowVisit,
    sortedTaxableEntityFlows,
    isLoading,
  } = useInsightsDialog();

  return (
    <KontaInsightsDialog
      isLoading={isLoading}
      contentCss={previewContainerCss}
      isActiveDialog={isActiveDialog}
      open={isOpen}
      onOpenChange={setIsOpen}
      headerProps={{
        avatars: [],
        text: '',
      }}
      taxableEntityFlows={sortedTaxableEntityFlows}
      currentFlowIndex={currentFlowIndex}
      currentSlideIndex={currentSlideIndex}
      onFlowChange={handleFlowChange}
      onSlideChange={handleSlideChange}
      onFlowComplete={onFlowComplete}
      onStepVisit={onStepVisit}
      onFlowVisit={onFlowVisit}
    />
  );
}
