import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { showWorkflowFetch } from '@redux/workflows/actions';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import getCurrentRouteByWorkflowPhase from '@util/getCurrentRouteByWorkflowPhase';

export default function useCurrentWorkflowFromParam() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workflowId } = useParams();
  const { workflow, workflowLoading, error } = useSelectedWorkflow();

  useEffect(() => {
    if (workflow?.current_phase_key && workflow) {
      const workflowPhase = workflow.current_phase_key;
      const route = getCurrentRouteByWorkflowPhase(workflowPhase, workflow);
      if (route) {
        navigate(route, { replace: true });
      }
    }
  }, [navigate, workflow, workflow?.current_phase_key, workflowId]);

  useEffect(() => {
    if (
      workflowId &&
      !workflowLoading &&
      workflow?.id !== +workflowId &&
      !error
    ) {
      dispatch(showWorkflowFetch(workflowId));
    }
  }, [dispatch, workflow?.id, workflowId, workflowLoading, error]);
}
