import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, Row, TabContent, TabPane } from 'reactstrap';
import {
  uploadFiscalPeriodStatement,
  updateStatusFiscalPeriod,
} from '@redux/actions';
import { SimpleStateButton, TabList, CardHeader } from '@components';
import { getMonthName } from '@util/Utils';
import { StatementUploadBank } from './statement-upload-bank';
import { StatementUploadCash } from './statement-upload-cash';
import { StatementUploadNothing } from './statement-upload-nothing';

class StatementUploadCard extends Component {
  state = {
    activeTab: '1',
    files: [],
    validBillings: false,
    validFiles: false,
    billingStatements: [
      {
        amount: 0,
        description: '',
      },
    ],
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  uploadStatement = () => {
    const { billingStatements, files } = this.state;
    const formData = new FormData();

    if (
      billingStatements.length > 0 &&
      billingStatements[0].amount > 0 &&
      billingStatements[0].description !== '' &&
      files.length > 0
    ) {
      // Send files
      files.forEach((file) => {
        formData.append('statement_documents[]', file);
      });
      formData.append('billingStatements', JSON.stringify(billingStatements));
      this.props.uploadFiscalPeriodStatement(
        formData,
        this.props.currentPeriod,
      );
    } else if (
      billingStatements.length > 0 &&
      billingStatements[0].amount > 0 &&
      billingStatements[0].description !== ''
    ) {
      formData.append('billingStatements', JSON.stringify(billingStatements));
      this.props.uploadFiscalPeriodStatement(
        formData,
        this.props.currentPeriod,
      );
    } else if (files.length > 0) {
      files.forEach((file) => {
        formData.append('statement_documents[]', file);
      });
      this.props.uploadFiscalPeriodStatement(
        formData,
        this.props.currentPeriod,
      );
    } else {
      const body = [
        {
          amount: 0,
          description: 'Sin ingresos',
        },
      ];
      formData.append('billingStatements', JSON.stringify(body));
      this.props.uploadFiscalPeriodStatement(
        formData,
        this.props.currentPeriod,
      );
    }
  };

  // Bank Methods
  handleStatementAdded = (file) => {
    const { files } = this.state;
    files.push(file);
    this.setState({ files });
    this.setState({ validFiles: true });
  };

  handleStatementRemove = (file) => {
    const { files } = this.state;
    files.forEach((fileInFiles, i) => {
      if (fileInFiles.name === file.name && fileInFiles.size === file.size) {
        files.splice(i, 1);
      }
    });
    this.setState({ files });
    this.setState({ validFiles: !!files.length });
  };

  // Cash Methods
  addConcept = () => {
    const { billingStatements } = this.state;
    billingStatements.push({ amount: 0, description: '' });
    this.setState({ billingStatements });
  };

  handleBillingChange = (idx, e) => {
    const { billingStatements } = this.state;
    const nameKey = e.target.name.split('-')[0];
    billingStatements[idx][nameKey] = e.target.value;
    this.setState({ billingStatements });
    this.setState({
      validBillings:
        billingStatements[idx].amount &&
        billingStatements[idx].description.length,
    });
  };

  deleteStatement = (idx) => {
    const { billingStatements } = this.state;
    billingStatements.splice(idx, 1);
    this.setState({ billingStatements });
  };

  handleMaxFilesExceeded = (file) => {
    const { files } = this.state;
    files.pop();
    files.push(file);
    this.setState({ files });
  };

  render() {
    const { activeTab, billingStatements, validBillings, validFiles } =
      this.state;
    const isValid = validBillings || validFiles;
    const isPayment = activeTab === '2' || activeTab === '1';

    const date = new Date(this.props.currentPeriod.start_date);
    const mm = getMonthName(date);

    const eventHandlers = {
      addedfile: this.handleStatementAdded,
      maxfilesexceeded: this.handleMaxFilesExceeded,
      removedfile: this.handleStatementRemove,
    };
    return (
      <>
        <CardHeader title={`Sube tus ingresos de ${mm}`} />
        <Nav className="nav-pills mb-4">
          <TabList
            tabs={[
              'task.statement-file-upload',
              'task.statement-manual-upload',
              'task.statement-nothing',
            ]}
            activeTab={activeTab}
            toggleTab={this.toggleTab}
          />
        </Nav>
        <TabContent activeTab={activeTab} className="mb-4">
          <TabPane tabId="1">
            <StatementUploadBank events={eventHandlers} />
          </TabPane>
          <TabPane tabId="2">
            <StatementUploadCash
              billingStatements={billingStatements}
              addConcept={this.addConcept}
              deleteStatement={this.deleteStatement}
              handleBillingChange={this.handleBillingChange}
            />
          </TabPane>
          <TabPane tabId="3">
            <StatementUploadNothing />
          </TabPane>
          <Row className="justify-content-center">
            <SimpleStateButton
              loading={this.props.loading}
              disabled={isPayment && !isValid}
              onClick={this.uploadStatement}
            >
              {isPayment ? 'Subir ingresos' : 'Sin ingresos'}
            </SimpleStateButton>
          </Row>
        </TabContent>
      </>
    );
  }
}

const mapStateToProps = ({
  fiscalPeriods: { current: fiscal_period, loading },
}) => ({
  fiscal_period,
  loading,
});

export default connect(mapStateToProps, {
  uploadFiscalPeriodStatement,
  updateStatusFiscalPeriod,
})(StatementUploadCard);
