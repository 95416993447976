import React from 'react';
import { createRoot } from 'react-dom/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import BackOfficeRoutes from 'backoffice/routes/BackOfficeRoutes';
import BackOfficeProvider from 'backoffice/providers/BackOfficeProvider';
import getEnvironmentsFromLocalStorage from 'backoffice/util/getEnvironmentsFromLocalStorage';
import { ENVIRONMENTS } from 'backoffice/constants/enviroments';
import Providers from './Providers';
import initializers from './initializers';
import App from './App';

initializers();

const isBackOffice = process.env.REACT_APP_ENV === 'BACKOFFICE';

(async () => {
  let launchDarklyClientSideID = process.env.REACT_APP_LAUNCHDARKLY;
  if (isBackOffice) {
    const { launchDarklyClientSideId } = getEnvironmentsFromLocalStorage(
      ENVIRONMENTS.production,
    );
    launchDarklyClientSideID = launchDarklyClientSideId;
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyClientSideID,
  });
  const container = document.getElementById('root');
  const root = createRoot(container);

  if (isBackOffice) {
    root.render(
      <LDProvider>
        <BackOfficeProvider>
          <BackOfficeRoutes />
        </BackOfficeProvider>
      </LDProvider>,
    );
  } else {
    root.render(
      <LDProvider>
        <Providers>
          <App />
        </Providers>
      </LDProvider>,
    );
  }
})();
