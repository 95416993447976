import { styled } from '@konta/ui';

export const CloseIconWrapper = styled('div', {
  position: 'absolute',
  top: '20px',
  right: '20px',
  cursor: 'pointer',
  zIndex: 1,
  width: '24px',
  height: '24px',
});

export const ActionsWrapper = styled('div', {
  marginTop: '32px',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch',
  button: {
    flex: 1,
  },
  variants: {
    end: {
      true: {
        justifyContent: 'flex-end',
        button: {
          flex: 'unset',
        },
      },
    },
  },
});

export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});
export const TitleSection = styled('div', {
  display: 'flex',
  padding: '0px 8px 16px 8px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
});
export const Title = styled('span', {
  color: '$gray900',
  width: '100%',
  textAlign: 'center',
  fontFamily: '$inter',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '28px',
});
export const Subtitle = styled('span', {
  color: '$gray500',
  fontFamily: '$inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  textAlign: 'center',
  width: '100%',
});
