import React from 'react';
import {
  Text,
  Flex,
  Card,
  CardContent,
  CardActions,
  Button,
  styled,
  Box,
  Alert,
} from '@konta/ui';
import Divider from '@components/Divider';

const GroupContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  '> div': { width: '100%' },
  '@sm': { flexDirection: 'row', '> div': { width: '48%' } },
  '@md': { '> div': { width: '31.9%' } },
  '@xl': { '> div': { width: '23.7%' } },
  gap: '$20',
});

export default function AdvisoryChannelsGroup({ advisoryChannelGroup }) {
  const title = advisoryChannelGroup?.title;
  const channels = advisoryChannelGroup?.channels;

  const handleClick = (btn) => {
    if (btn?.link) {
      const a = document.createElement('a');
      a.target = btn?.target ? btn.target : '_blank';
      a.href = btn.link;
      a.click();
      a.remove();
    } else if (btn.action) {
      btn.action();
    }
  };

  return (
    <Flex direction="column" gap={20}>
      <Text bold xl lineHeight="xl">
        {!!title && title}
      </Text>
      <Alert color="primary300">
        Hola, te informamos que a partir de ahora hemos ampliado nuestro horario
        de atención, es de{' '}
        <Text bold>lunes a viernes de 9:00am a 7:00pm hora centro.</Text>
        ¡Gracias por formar parte de la familia Konta.com!
      </Alert>
      <GroupContainer>
        {channels?.map?.((channel) => {
          const {
            title: channelTitle,
            description,
            button,
            icon: Icon,
          } = channel;
          return (
            <Card key={channelTitle}>
              <CardContent>
                <Flex direction="column" gap={10} align="center">
                  <Text l lineHeight="l" css={{ textAlign: 'center' }}>
                    {channelTitle}
                  </Text>
                  <Box
                    css={{
                      textAlign: 'center',
                      svg: { width: '30%', height: 'auto' },
                    }}
                  >
                    <Icon />
                  </Box>
                  <Text css={{ textAlign: 'center' }}>{description}</Text>
                </Flex>
              </CardContent>
              <CardActions gap={10} justify="center">
                <Button onClick={() => handleClick(button)} color="primary">
                  {button.text}
                </Button>
              </CardActions>
            </Card>
          );
        })}
      </GroupContainer>
      <Divider />
    </Flex>
  );
}
