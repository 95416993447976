import { useState } from 'react';
import { Button, Flex, config, useMediaQuery } from '@konta/ui';
import LetterAvatar from 'react-avatar';
import { EyeLineIcon } from '@konta/icons';
import {
  calculateAmountAndConversion,
  getCurrency,
  getDateIssued,
  getIssuerLegalName,
  getIssuerRfc,
  getReceiverLegalName,
  getReceiverRfc,
  getSourceTypeLabel,
} from '@util/declarationEntries';
import { UseIncomeListReturnType } from '@components/Workflow/EditIncomes/ClassifyIncomes/IncomesList/useIncomesList';
import TextOverflow from 'shared/components/TextOverflow';
import useXmlParser from 'shared/hooks/useXmlParser';
import ListInformationText from 'shared/components/ListInformationText';
import {
  Declaration,
  DeclarationEntry,
  ParsedInvoiceXML,
} from 'types/entities';
import { ConfirmModal } from '@components/Modal';
import InvoicePreview from '@components/InvoicePreview/InvoicePreview';
import { InvoiceActions, InvoiceDetails, TableRow } from './styled';
import FiscalRegimeDropdown from './FiscalRegimeDropdown';
import AccountingStatusDropdown from './AccountingStatusDropdown';

export interface TableRowItemProps {
  declarationEntry: DeclarationEntry;
  setSelectedEntry: UseIncomeListReturnType['setSelectedEntry'];
  activeDeclarations: Declaration[];
  url?: string | null;
  entryType?: 'income' | 'expense';
  oldWorkflow?: boolean;
  preferredFiscalRegime?: string | number | null;
}

export default function TableRowItem({
  declarationEntry,
  setSelectedEntry,
  activeDeclarations,
  url,
  entryType,
  oldWorkflow,
  preferredFiscalRegime,
}: TableRowItemProps) {
  const isTabletOrMobile = useMediaQuery(config.media['<xs']);

  const [showInvoicePreview, setShowInvoicePreview] =
    useState<ParsedInvoiceXML>();
  const toggleShowInvoice = () => setShowInvoicePreview(undefined);

  const { fetchInvoice, isFetching } = useXmlParser({
    file: url || '',
  });

  return (
    <>
      <TableRow>
        <InvoiceDetails>
          <span className="classify-expenses-logo-container">
            <LetterAvatar
              maxInitials={2}
              name={
                (entryType === 'income'
                  ? getReceiverLegalName(declarationEntry)
                  : getIssuerLegalName(declarationEntry)) || '-'
              }
              size="28"
              round
            />
          </span>
          <Flex justify="between" css={{ flex: 1 }}>
            <Flex column>
              <TextOverflow maxLen={180} s lineHeight="s" color="gray900">
                {(entryType === 'income'
                  ? getReceiverLegalName(declarationEntry)
                  : getIssuerLegalName(declarationEntry)) || '-'}
              </TextOverflow>
              <Flex column>
                <ListInformationText
                  label="RFC"
                  value={
                    (entryType === 'income'
                      ? getReceiverRfc(declarationEntry)
                      : getIssuerRfc(declarationEntry)) || 'Sin información'
                  }
                />
                <ListInformationText
                  label="Tipo"
                  value={getSourceTypeLabel(declarationEntry) || '-'}
                />
              </Flex>
            </Flex>
            <Flex column itemsEnd>
              <ListInformationText
                isCurrency
                currency={getCurrency(declarationEntry)}
                value={calculateAmountAndConversion(declarationEntry).amount}
              />
              <ListInformationText
                isDate
                format={isTabletOrMobile ? 'DD/MM/YYYY' : 'DD [de] MMM YYYY'}
                value={getDateIssued(declarationEntry) || ''}
              />
              {url && (
                <Button
                  size="xs"
                  variant="outlined"
                  rightIcon={!isTabletOrMobile && <EyeLineIcon />}
                  icon={isTabletOrMobile}
                  noFill
                  onClick={() => {
                    if (declarationEntry.source_type === 'Cfdi') {
                      setShowInvoicePreview(fetchInvoice);
                      return;
                    }
                    window.open(url, '_blank');
                  }}
                >
                  {isTabletOrMobile ? <EyeLineIcon /> : 'Ver factura'}
                </Button>
              )}
            </Flex>
          </Flex>
        </InvoiceDetails>
        <InvoiceActions
          css={{
            justifyContent: oldWorkflow ? 'flex-end' : 'space-between',
          }}
        >
          {!oldWorkflow && (
            <FiscalRegimeDropdown
              declarationEntry={declarationEntry}
              setSelectedEntry={setSelectedEntry}
              activeDeclarations={activeDeclarations}
              preferredFiscalRegime={preferredFiscalRegime}
            />
          )}
          <AccountingStatusDropdown
            declarationEntry={declarationEntry}
            setSelectedEntry={setSelectedEntry}
            entryType={entryType}
          />
        </InvoiceActions>
      </TableRow>
      {!!showInvoicePreview && (
        <ConfirmModal
          open={!!showInvoicePreview}
          size="lg"
          title="Vista previa"
          onClose={toggleShowInvoice}
          loading={isFetching}
          onAccept={toggleShowInvoice}
          acceptText="Cerrar"
        >
          <Flex column gap={16}>
            <InvoicePreview invoiceObject={showInvoicePreview} />
          </Flex>
        </ConfirmModal>
      )}
    </>
  );
}
