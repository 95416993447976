import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { postCFDI } from '@redux/actions';
import { useNavigate } from 'react-router-dom';

export const useCfdis = (_) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, cfdis } = useSelector((state) => state.cfdis, shallowEqual);

  const handlePostCfdi = (cfdi) => {
    dispatch(postCFDI(cfdi, navigate));
  };

  return { loading, handlePostCfdi, cfdis };
};
