import React from 'react';
import { Row, CardTitle, CardSubtitle } from 'reactstrap';
import { Colxx } from '@components';
import WorkflowLayout from '@components/WorkflowLayout';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';

export default function WorkflowIntro() {
  return (
    <WorkflowLayout actions={<PrimaryPhaseButton />}>
      <Row className="text-center">
        <Colxx>
          <CardTitle
            tag="h1"
            className="mb-1 font-weight-bold text-primary display-5"
          >
            ¡Bienvenido!
          </CardTitle>
          <CardSubtitle tag="h4">
            A continuación le haremos algunas preguntas sobre sus{' '}
            <span className="font-weight-bold">ingresos</span> y{' '}
            <span className="font-weight-bold">gastos</span> con el fin de
            calcular correctamente sus{' '}
            <span className="font-weight-bold">impuestos</span> y realizar la
            declaración mensual.
          </CardSubtitle>
        </Colxx>
      </Row>
      <Row>
        <Colxx className="mt-4 mx-auto d-flex justify-content-center">
          <iframe
            width="760"
            height="415"
            src="https://www.youtube.com/embed/1vGxDg7_0rM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Colxx>
      </Row>
      <Row className="text-center mt-4 mb-2">
        <Colxx>
          <h4>
            Recuerde que si en cualquier momento tiene dudas, nuestro equipo de
            atención a clientes y de contadores expertos está disponible para
            apoyarle. Estamos disponibles de lunes a viernes de 9 AM a 6 PM via
            nuestro chat interno.
          </h4>
        </Colxx>
      </Row>
    </WorkflowLayout>
  );
}
