import { Flex, StepIcon, VerticalStep, VerticalStepper } from '@konta/ui';
import { ProcessStep, ProcessStepType, ProcessType } from 'types/entities';
import useProcess from '@hooks/useProcess';

interface StepsGroupProps {
  labelTitle?: string;
  stepNumber: number;
  steps: ProcessStep[];
  value: number;
  onSelect?: (step: ProcessStep) => void;
  getIndexByStepType: (type: ProcessStepType) => number;
}

function StepsGroup({
  getIndexByStepType,
  labelTitle = '...',
  stepNumber,
  steps,
  value,
  onSelect,
}: StepsGroupProps) {
  return (
    // TODO: add default values to iconSize and binary in @konta/ui
    <VerticalStepper value={value} iconSize="medium" binary={false}>
      <VerticalStep
        id={getIndexByStepType(steps?.[0]?.type || 0)}
        icon={<StepIcon>{stepNumber}</StepIcon>}
        title={labelTitle}
      />
      {steps?.map((step) => (
        <VerticalStep
          clickable={false}
          key={step.id}
          id={getIndexByStepType(step?.type)}
          icon={<StepIcon size="xsmall" />}
          lineSize="xsmall"
          indentation={1}
          title={step?.name}
          onClick={() => onSelect && onSelect(step)}
        />
      ))}
    </VerticalStepper>
  );
}

export default function ProcessStepStepper({
  processType,
}: {
  processType: ProcessType;
}) {
  const { getIndexByStepType, groupSteps, activeIndex } =
    useProcess(processType);

  return (
    <Flex direction="column" gap="4">
      {groupSteps.map(([title, steps], index) => (
        <StepsGroup
          getIndexByStepType={getIndexByStepType}
          key={title}
          value={activeIndex}
          labelTitle={title}
          stepNumber={index + 1}
          steps={steps}
        />
      ))}
    </Flex>
  );
}
