import { useEffect, useMemo } from 'react';
import { Flag02LineIcon, Rocket02LineIcon } from '@konta/icons';
import usePendingTasksStore from 'store/pendingTasksStore';
import useCurrentProcessesQuery from '@hooks/useCurrentProcessesQuery';
import { useTaxableEntity } from '@hooks';
import { Process, TaxableEntity } from 'types/entities';
import { TaskStatus } from '../types';

const pendingProcessSteps = [
  'MeantimeOnboardingProcessStep',
  'UploadDataAnnualProcessStep',
  'RecommendationsAnnualProcessStep',
];

const getTaskStatusFromProcessStep = (process: Process): TaskStatus => {
  if (!process.current_step) {
    throw new Error('Process current step is required');
  }
  if (process.completed) {
    return 'completed';
  }
  if (pendingProcessSteps.includes(process.current_step)) {
    return 'pending';
  }
  return 'requiredAction';
};

const getLabelDetailsFromProcess = (process: Process) => {
  if (!process.current_step) {
    throw new Error('Process current step is required');
  }
  if (process.current_step === 'MeantimeOnboardingProcessStep') {
    return 'En espera de la llamada';
  }
  if (process.current_step === 'FeedbackOnboardingProcessStep') {
    return 'Ayudanos a mejorar ¿Cómo te fue en la llamada?';
  }
  if (process.current_step === 'ReferralsOnboardingProcessStep') {
    return '¡Gana meses gratis recomendando Konta.com!';
  }
  if (process.current_step === 'UpdatingAccountOnboardingProcessStep') {
    return 'No requieres realizar ninguna acción';
  }
  if (process.current_step === 'ChargesAnnualProcessStep') {
    return 'Paga tu declaración anual';
  }
  if (process.current_step === 'InformativeDataAnnualProcessStep') {
    return 'Indica si has tenido ingresos adicionales durante el año';
  }
  if (process.current_step === 'UploadDataAnnualProcessStep') {
    return 'Estamos trabajando en tu declaración anual';
  }
  if (process.current_step === 'PreferencesAnnualProcessStep') {
    return 'Revisa tus preferencias de saldos';
  }
  if (process.current_step === 'RecommendationsAnnualProcessStep') {
    return 'Estamos presentando tu declaración anual ante el SAT ';
  }

  const isAnnualProcess = process.type === 'AnnualDeclarationProcess';
  return isAnnualProcess
    ? 'Empieza tu declaración anual'
    : 'Continúa con el proceso de apertura de cuenta';
};

export default function useHandleProcess() {
  const { addPendingTask } = usePendingTasksStore();
  const { taxable_entity: taxableEntity } = useTaxableEntity() as {
    taxable_entity: TaxableEntity;
  };

  const { currentProcesses, loadingCurrentProcesses } =
    useCurrentProcessesQuery();

  const hasFirstInformation = useMemo(
    () => taxableEntity.status !== 'demo',
    [taxableEntity],
  );

  useEffect(() => {
    if (!loadingCurrentProcesses) {
      const sortedProcesses = currentProcesses
        .filter(
          (process) =>
            !process.completed && process.type !== 'FinishOnboardingProcess',
        )
        .sort((a, b) => a.id - b.id);

      const onboardingFinishProcess = currentProcesses.find(
        (process) => process.type === 'FinishOnboardingProcess',
      );
      const onboardingProcess = currentProcesses.find(
        (process) => process.type === 'OnboardingOnboardingProcess',
      );

      if (hasFirstInformation) {
        sortedProcesses.forEach((process) => {
          const isAnnualProcess = process.type === 'AnnualDeclarationProcess';
          const type =
            process.type === 'OnboardingOnboardingProcess'
              ? 'onboarding'
              : 'annualDeclaration';
          addPendingTask({
            id: `${type}-${process?.id || 0}`,
            type,
            status: getTaskStatusFromProcessStep(process),
            title: process?.name,
            description: isAnnualProcess
              ? 'Proceso anual de impuestos al finalizar el año'
              : process?.description,
            extraDescription: process,
            details: [
              {
                label: getLabelDetailsFromProcess(process),
                icon: isAnnualProcess ? (
                  <Rocket02LineIcon />
                ) : (
                  <Flag02LineIcon />
                ),
                iconCss: {
                  background: isAnnualProcess ? '$rose100' : '$orange100',
                  path: {
                    fill: 'none',
                    stroke: isAnnualProcess ? '$rose500' : '$orange500',
                  },
                },
              },
            ],
          });
        });
        if (
          onboardingFinishProcess &&
          onboardingProcess?.completed &&
          !onboardingFinishProcess?.completed
        ) {
          addPendingTask({
            id: `finishOnboarding-${onboardingFinishProcess?.id || 0}`,
            type: 'onboarding',
            status: 'pending',
            title: onboardingFinishProcess?.name || '',
            description: onboardingFinishProcess?.description || '',
            extraDescription: onboardingProcess,
            details: [
              {
                label: getLabelDetailsFromProcess(onboardingFinishProcess),
                icon: <Flag02LineIcon />,
                iconCss: {
                  background: '$orange100',
                  path: {
                    fill: 'none',
                    stroke: '$orange500',
                  },
                },
              },
            ],
          });
        }
      }
    }
  }, [
    addPendingTask,
    currentProcesses,
    hasFirstInformation,
    loadingCurrentProcesses,
  ]);

  return {
    loadingCurrentProcesses,
  };
}
