import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  LOGOUT_USER,
  USER_FETCH,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  ONBOARDING_USER,
  ONBOARDING_USER_FAILURE,
  ONBOARDING_USER_SUCCESS,
  USER_UPDATE_OTP_REQUIRED,
  USER_UPDATE_OTP_TOKEN,
} from '@constants/actionTypes';

const INIT_STATE = {
  access_token: localStorage.getItem('user_id'),
  user: null,
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
    case CHANGE_PASSWORD:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      // notify.success('Login Success');
      return {
        ...state,
        loading: false,
        access_token: action.payload.access_token,
        user: { ...action.payload, taxable_entity: null },
        error: null,
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        access_token: null,
        user: null,
        error: !state.error,
      };
    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      // notify.success('Register User Success');
      return {
        ...state,
        loading: false,
        access_token: action.payload.access_token,
        user: { ...action.payload, taxable_entity: null },
        error: null,
      };
    case REGISTER_USER_FAILURE:
      return {
        ...state,
        loading: false,
        access_token: null,
        user: null,
        error: !state.error,
      };
    case LOGOUT_USER:
      return { ...state, access_token: null, user: null };
    case USER_FETCH:
      return { ...state, loading: true };
    case USER_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        access_token: action.payload.access_token,
        user: { ...action.payload, taxable_entity: null },
      };
    }
    case USER_FETCH_FAILURE:
      return { ...state, loading: false, access_token: null, user: null };
    case USER_UPDATE:
      return { ...state, loading: true };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        access_token: action.payload.access_token,
        user: { ...action.payload, taxable_entity: null },
      };
    case USER_UPDATE_OTP_REQUIRED:
      return {
        ...state,
        user: { ...state.user, otp_required_for_login: action.payload },
      };
    case USER_UPDATE_OTP_TOKEN:
      const { data } = action.payload;
      return {
        ...state,
        user: { ...state.user, otp_token: data.otp_token, email: data.email },
      };
    case USER_UPDATE_FAILURE:
    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_FAILURE:
      return { ...state, loading: false };
    case ONBOARDING_USER:
      return {
        ...state,
        loading: true,
      };
    case ONBOARDING_USER_FAILURE: {
      return { ...state, loading: false };
    }
    case ONBOARDING_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        access_token: action.payload.access_token,
        user: { ...action.payload, taxable_entity: null },
      };

    default:
      return { ...state };
  }
};
