import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Button } from '@konta/ui';
import { useToggle } from 'rooks';
import FormikFormModal from '@components/FormikFormModal';
import FormikTextInput from '@components/FormikTextInput';
import FormikTextarea from '@components/FormikTextarea';
import FormikCheckbox from '@components/FormikCheckbox';
import FormikKontaSelect from '@components/FormikKontaSelect';
import useFeature from '@hooks/useFeature';
import { UNSUBSCRIBE_REQUEST } from '@constants/featureFlags';
import {
  APPROPRIATE_PRICE_OPTIONS,
  BAD_EXPERIENCES_OPTIONS,
  CANCELLATION_REASONS,
  CHANGE_ACCOUNTANT_OPTIONS,
  DISLIKE_OPTIONS,
  CANCEL_SUBSCRIPTION_INITIAL_VALUES,
  cancelSubscriptionSchema,
} from '@constants/subscription';
import { ConfirmModal } from '@components/Modal';

const valuesToPayload = (values) => {
  return {
    cancellation_reason: values.cancellationReason,
    why_did_you_decide_to_close_or_suspend_your_business:
      values.otherSuspendMyBusiness,
    why_are_sales_down: values.otherSalesAreDown,
    what_do_you_think_is_the_right_price_for_the_service:
      values.priceIsTooHighOption?.label || '',
    other_what_do_you_think_is_the_right_price_for_the_service_:
      values.otherPriceIsTooHigh,
    why_did_you_have_a_bad_experience_with_the_service:
      values.badExperienceOptions.map((opt) => opt.label),
    other_why_did_you_have_a_bad_experience_with_the_service:
      values.otherBadExperience,
    why_did_you_decide_to_change_your_accountant:
      values.anotherAccountantOptions.map((opt) => opt.label),
    other_why_did_you_decide_to_change_your_accountant:
      values.otherAnotherAccountant,
    what_did_you_not_like_about_the_app: values.unlikePlatformOptions.map(
      (opt) => opt.label,
    ),
    other_what_did_you_not_like_about_the_app: values.otherUnlikePlatform,
    otherReason: values.otherReason,
    how_likely_it_is_that_you_will_recommend_Konta_to_a_friend_or_family:
      +values.likelyToRecommend,
  };
};

function QuestionWrap({ children }) {
  return (
    <Flex css={{ pl: 0, '@md': { pl: '24px' } }}>
      <Flex direction="column" gap={12} css={{ flex: 1 }}>
        {children}
      </Flex>
    </Flex>
  );
}

export default function DeleteUserSubscriptionModal({
  isOpen,
  toggle,
  subscriptionId,
  handleDeleteUserSubscriptionSubmit,
  withCancelBtn,
}) {
  const [unsubscribeRequest] = useFeature(UNSUBSCRIBE_REQUEST);
  const [openConfirmUnsubscribe, toggleConfirmUnsubscribe] = useToggle(false);

  const handleChange = (formik, value) => {
    const values = [...(formik.values.cancellationReason || [])];
    if (!values.includes(value)) {
      values.push(value);
    } else {
      values.splice(values.indexOf(value), 1);
    }
    formik.setFieldValue('cancellationReason', values);
  };

  return (
    <>
      <FormikFormModal
        size="md"
        title="¿Seguro que quieres suspender tu suscripción?"
        isOpen={isOpen}
        toggle={toggle}
        withCancelBtn={withCancelBtn}
        initialValues={CANCEL_SUBSCRIPTION_INITIAL_VALUES}
        validationSchema={cancelSubscriptionSchema}
        submitText="Suspender suscripción"
        extraButton={
          unsubscribeRequest ? (
            <Flex
              css={{
                width: '100%',
                justifyContent: 'end',
              }}
            >
              <Button
                color="gray"
                variant="text"
                onClick={toggleConfirmUnsubscribe}
              >
                Omitir motivos y suspender
              </Button>
            </Flex>
          ) : null
        }
        onSubmit={({ values }) => {
          const payload = valuesToPayload(values);

          handleDeleteUserSubscriptionSubmit(subscriptionId, {
            cancellationReasons: payload,
            otherReason: payload.otherReason,
          });
          toggle();
        }}
      >
        {(formik) => (
          <Flex
            direction="column"
            gap={10}
            css={{ label: { marginBottom: 0 } }}
          >
            <Text size="m">Seleccione los motivos de cancelación *</Text>
            <Flex direction="column" gap={8}>
              <Flex direction="column" css={{ flex: 1 }} gap={10}>
                <Flex direction="column" gap={10}>
                  <FormikCheckbox
                    name="suspendMyBusiness"
                    label={CANCELLATION_REASONS[0].label}
                    onChange={(value) => {
                      handleChange(formik, CANCELLATION_REASONS[0].value);
                      if (!value) {
                        formik.setFieldValue('otherSuspendMyBusiness', '');
                      }
                    }}
                  />
                  {formik.values.suspendMyBusiness && (
                    <QuestionWrap>
                      <FormikTextarea
                        name="otherSuspendMyBusiness"
                        label="¿Por qué decidiste cerrar o suspender tu negocio? *"
                      />
                    </QuestionWrap>
                  )}
                </Flex>
                <Flex direction="column" gap={10}>
                  <FormikCheckbox
                    name="salesAreDown"
                    label={CANCELLATION_REASONS[1].label}
                    onChange={(value) => {
                      handleChange(formik, CANCELLATION_REASONS[1].value);
                      if (!value) {
                        formik.setFieldValue('otherSalesAreDown', '');
                      }
                    }}
                  />
                  {formik.values.salesAreDown && (
                    <QuestionWrap>
                      <FormikTextarea
                        name="otherSalesAreDown"
                        label="¿Puedes darnos más detalles por favor? *"
                      />
                    </QuestionWrap>
                  )}
                </Flex>
                <Flex direction="column" gap={10}>
                  <FormikCheckbox
                    name="priceIsTooHigh"
                    label={CANCELLATION_REASONS[2].label}
                    onChange={(value) => {
                      handleChange(formik, CANCELLATION_REASONS[2].value);
                      if (!value) {
                        formik.setFieldValue('priceIsTooHighOption', null);
                        formik.setFieldValue('otherPriceIsTooHigh', '');
                      }
                    }}
                  />
                  {formik.values.priceIsTooHigh && (
                    <QuestionWrap>
                      <FormikKontaSelect
                        id="priceIsTooHighOption"
                        name="priceIsTooHighOption"
                        options={APPROPRIATE_PRICE_OPTIONS}
                        label="¿Cuál crees que es el precio adecuado para el servicio? *"
                        onChange={(option) => {
                          if (option?.label !== 'Otro') {
                            formik.setFieldValue('otherPriceIsTooHigh', '');
                          }
                        }}
                        placeholder="Selecciona todos los que apliquen"
                      />
                      {formik.values.priceIsTooHighOption?.label === 'Otro' && (
                        <FormikTextInput
                          type="number"
                          name="otherPriceIsTooHigh"
                          placeholder="Escriba la otra opción"
                          label="Otra opción *"
                        />
                      )}
                    </QuestionWrap>
                  )}
                </Flex>
                <Flex direction="column" gap={10}>
                  <FormikCheckbox
                    name="badExperience"
                    label={CANCELLATION_REASONS[3].label}
                    onChange={(value) => {
                      handleChange(formik, CANCELLATION_REASONS[3].value);
                      if (!value) {
                        formik.setFieldValue('badExperienceOptions', []);
                        formik.setFieldValue('otherBadExperience', '');
                      }
                    }}
                  />
                  {formik.values.badExperience && (
                    <QuestionWrap>
                      <FormikKontaSelect
                        id="badExperienceOptions"
                        name="badExperienceOptions"
                        options={BAD_EXPERIENCES_OPTIONS}
                        isMulti
                        className="onboarding-multi-select"
                        label="¿Por qué? *"
                        onChange={(val) => {
                          if (!val.some((opt) => opt.label === 'Otro')) {
                            formik.setFieldValue('otherBadExperience', '');
                          }
                        }}
                        placeholder="Selecciona todos los que apliquen"
                      />
                      {formik.values.badExperienceOptions?.some(
                        (opt) => opt?.label === 'Otro',
                      ) && (
                        <FormikTextInput
                          name="otherBadExperience"
                          placeholder="Escriba la otra opción"
                          label="Otra opción *"
                        />
                      )}
                    </QuestionWrap>
                  )}
                </Flex>
                <Flex direction="column" gap={10}>
                  <FormikCheckbox
                    name="anotherAccountant"
                    label={CANCELLATION_REASONS[4].label}
                    onChange={(value) => {
                      handleChange(formik, CANCELLATION_REASONS[4].value);
                      if (!value) {
                        formik.setFieldValue('anotherAccountantOptions', []);
                        formik.setFieldValue('otherAnotherAccountant', '');
                      }
                    }}
                  />
                  {formik.values.anotherAccountant && (
                    <QuestionWrap>
                      <FormikKontaSelect
                        id="anotherAccountantOptions"
                        name="anotherAccountantOptions"
                        options={CHANGE_ACCOUNTANT_OPTIONS}
                        isMulti
                        className="onboarding-multi-select"
                        label="¿Por qué decidiste cambiar de contador? *"
                        onChange={(val) => {
                          if (!val.some((opt) => opt.label === 'Otro')) {
                            formik.setFieldValue('otherAnotherAccountant', '');
                          }
                        }}
                        placeholder="Selecciona todos los que apliquen"
                      />
                      {formik.values.anotherAccountantOptions?.some(
                        (opt) => opt?.label === 'Otro',
                      ) && (
                        <FormikTextInput
                          name="otherAnotherAccountant"
                          placeholder="Escriba la otra opción"
                          label="Otra opción *"
                        />
                      )}
                    </QuestionWrap>
                  )}
                </Flex>
                <Flex direction="column" gap={10}>
                  <FormikCheckbox
                    name="unlikePlatform"
                    label={CANCELLATION_REASONS[5].label}
                    onChange={(value) => {
                      handleChange(formik, CANCELLATION_REASONS[5].value);
                      if (!value) {
                        formik.setFieldValue('unlikePlatformOptions', []);
                        formik.setFieldValue('otherUnlikePlatform', '');
                      }
                    }}
                  />
                  {formik.values.unlikePlatform && (
                    <QuestionWrap>
                      <FormikKontaSelect
                        id="unlikePlatformOptions"
                        name="unlikePlatformOptions"
                        options={DISLIKE_OPTIONS}
                        isMulti
                        className="onboarding-multi-select single-select"
                        label="¿Qué fue lo que no te gustó? *"
                        onChange={(val) => {
                          if (!val.some((opt) => opt.label === 'Otro')) {
                            formik.setFieldValue('otherUnlikePlatform', '');
                          }
                        }}
                        placeholder="Selecciona todos los que apliquen"
                      />
                      {formik.values.unlikePlatformOptions?.some(
                        (opt) => opt?.label === 'Otro',
                      ) && (
                        <FormikTextInput
                          name="otherUnlikePlatform"
                          placeholder="Escriba la otra opción"
                          label="Otra opción *"
                        />
                      )}
                    </QuestionWrap>
                  )}
                </Flex>
              </Flex>
              {!!formik.errors.cancellationReason && (
                <Text xs lineHeight="xs" color="error500">
                  {formik.errors.cancellationReason}
                </Text>
              )}
            </Flex>
            <FormikTextarea label="Comentarios *" name="otherReason" />
            <FormikTextInput
              label="¿Qué tan probable es que recomiendes Konta a un amigo o familiar? *"
              name="likelyToRecommend"
              helperText="Donde 0 es nada probable y 10 muy probable"
              placeholder="Introduce un número del 0 al 10"
              pattern="\d*"
              maxLength="2"
            />
          </Flex>
        )}
      </FormikFormModal>
      <ConfirmModal
        title="Suspender suscripción "
        open={openConfirmUnsubscribe}
        onAccept={() => {
          handleDeleteUserSubscriptionSubmit(subscriptionId, {
            cancellationReasons: {
              cancellation_reason: [],
            },
            otherReason: 'Se omitieron los motivos de cancelación',
          });
          toggle();
        }}
        onCancel={toggleConfirmUnsubscribe}
        onClose={toggleConfirmUnsubscribe}
      >
        <Text l>
          ¿Estás seguro que quieres suspender tu suscripción sin proporcionar
          los motivos de cancelación?
        </Text>
      </ConfirmModal>
    </>
  );
}

DeleteUserSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  subscriptionId: PropTypes.number.isRequired,
  handleDeleteUserSubscriptionSubmit: PropTypes.func.isRequired,
  withCancelBtn: PropTypes.bool,
};

DeleteUserSubscriptionModal.defaultProps = {
  withCancelBtn: true,
};
