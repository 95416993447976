import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';

import { Row, Card, CardTitle, CardSubtitle, CardBody } from 'reactstrap';
import { AvForm, AvGroup } from 'availity-reactstrap-validation';
import {
  notificationPreferencesFetch,
  notificationPreferencesUpdate,
} from '@redux/actions';
import { Colxx } from '@components';

function NotificationItem(props) {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);

  const handleClick = (event) => {
    const notification_config = {
      id: props.id,
      authorized: event,
    };
    dispatch(notificationPreferencesUpdate(notification_config));
  };

  const toggleSwitch = () => {
    setActive(!active);
  };
  return (
    <AvForm data-test="notification-form" className="has-float-label">
      <Row className="mt-2">
        <Colxx xxs={9}>
          <AvGroup>
            <h5 data-test="form-text">{props.name}</h5>
          </AvGroup>
        </Colxx>
        <Colxx xxs={3}>
          <Switch
            data-test="form-switch"
            id={props.id}
            checked={props.authorized}
            onChange={toggleSwitch}
            onClick={handleClick}
            className="custom-switch custom-switch-primary custom-switch-small"
          />
        </Colxx>
      </Row>
    </AvForm>
  );
}

function NotificationPreferences(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(notificationPreferencesFetch());
  }, []);
  return (
    <Card data-test="notification-card">
      <CardBody>
        <CardTitle data-test="card-title" tag="h3">
          Preferencias de notificación
        </CardTitle>
        <CardSubtitle data-test="card-subtitle">
          En esta sección puedes activar o desactivar las opciones de envío de
          notificaciones
        </CardSubtitle>
        {props.notification_preferences.map((preference) => (
          <NotificationItem
            key={preference.id}
            id={preference.id}
            authorized={preference.authorized}
            name={preference.name}
            method={preference.method}
          />
        ))}
      </CardBody>
    </Card>
  );
}

const mapStateToProps = ({ notification_preferences }) => {
  return notification_preferences;
};

export default connect(mapStateToProps, {
  notificationPreferencesFetch,
  notificationPreferencesUpdate,
})(NotificationPreferences);
