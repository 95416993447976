import useApiPagination from 'shared/hooks/useApiPagination';
import type { UseApiPaginationOptions } from 'shared/hooks/useApiPagination';
import { IS_BACKOFFICE } from '@constants/enviroments';
import type { Cfdi } from 'types/entities';

type CfdisPaginationOptions = UseApiPaginationOptions<Cfdi> & {
  apiVersion?: 1 | 2;
};

export default function useCfdisPagination({
  apiVersion = 1,
  ...options
}: Partial<Omit<CfdisPaginationOptions, 'endpoint'>>) {
  let host = process.env.REACT_APP_API_HOST || '';
  if (IS_BACKOFFICE) {
    const environment = JSON.parse(
      localStorage.getItem('environments' || '') || '{}',
    ) as { host: string };
    host = environment?.host;
  }
  if (!host) {
    throw new Error('Host is not defined');
  }
  const endpoint = apiVersion === 1 ? `cfdis` : `${host}/api/v2/cfdis`;

  const { loading, items, ...restProps } = useApiPagination<Cfdi>({
    ...options,
    endpoint,
  });

  return {
    ...restProps,
    cfdisLoading: loading,
    cfdis: items,
  };
}
