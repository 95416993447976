import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { PROCESSES } from '@constants/reactQueries';
import { Process, ProcessType } from 'types/entities';
import getProcessWrappers from '@api/getCurrentProcessWrapper';

export default function useCurrentProcessesQuery(
  queryOptions?: UseQueryOptions<Process[]>,
) {
  const {
    data = [],
    isLoading,
    ...rest
  } = useQuery<Process[]>(
    PROCESSES,
    async () => {
      const processWrappers = await getProcessWrappers();

      const processes = processWrappers.flatMap((wrapper) =>
        wrapper.processes.map((process) => ({
          ...process,
          steps: Object.values(process.grouped_steps ?? {}).flat(),
        })),
      );

      return processes;
    },
    {
      ...queryOptions,
    },
  );

  const queryClient = useQueryClient();
  const completedProcesses =
    data.length > 0 && data.every((process) => process.completed);
  const hasProcesses = data.length > 0;

  const updateCacheProcess = (newOnboardingOnboardingProcess: Process) => {
    queryClient.setQueryData(
      [PROCESSES],
      (oldProcesses: Process[] | undefined) => {
        if (!oldProcesses) return []; // only for ts validation

        return oldProcesses.map((process) =>
          process.id === newOnboardingOnboardingProcess.id
            ? newOnboardingOnboardingProcess
            : process,
        );
      },
    );
  };

  const getProcess = (type: ProcessType | null, processId?: number) =>
    data.find((process) => {
      if (processId) {
        return processId === process.id;
      }

      return process.type === type;
    });

  return {
    loadingCurrentProcesses: isLoading,
    currentProcesses: data,
    completedProcesses,
    hasProcesses,
    updateCacheProcess,
    getProcess,
    ...rest,
  };
}
