import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  declarationEntriesUpdateFailure,
  declarationEntriesUpdateSuccess,
} from '../actions';

export default function* ({
  payload: {
    callback,
    declaration_entries_id,
    body,
    successMessage = 'Gasto actualizado con éxito',
    successTitle = 'Gasto actualizado',
  },
}) {
  try {
    const iva = body.get('[iva]');
    const response = yield call(
      api.put,
      `${
        iva === '0'
          ? `foreign_invoices/${declaration_entries_id}`
          : `declaration_entries/${declaration_entries_id}`
      }`,
      body,
    );

    if (callback) {
      callback();
    }

    yield call(StatusHandler, {
      response,
      title: successTitle,
      message: successMessage,
      status: response.status,
      success: declarationEntriesUpdateSuccess,
      failure: declarationEntriesUpdateFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      message: 'Algo salió mal al editar el gasto',
      action: declarationEntriesUpdateFailure,
    });
  }
}
