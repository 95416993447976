import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { useToggle } from 'rooks';
import getTotalsFromDeclarationIncome from 'shared/util/getTotalsFromDeclarationIncome';
import getTotalsFromDeclarationExpense from 'shared/util/getTotalsFromDeclarationExpense';
import useDeclarationEntriesPagination from 'shared/hooks/useDeclarationEntriesPagination';
import {
  declarationEntryExpenseTypes,
  declarationEntryIncomeTypes,
} from '@constants/declarationEntries';
import {
  calculateAmountAndConversion,
  getIssuerLegalName,
  getIssuerRfc,
  getReceiverLegalName,
  getReceiverRfc,
  getSourceTypeLabel,
} from '@util/declarationEntries';
import type { GroupedEntries, Workflow } from 'types/entities';

export interface UseEntriesCollapseTable {
  workflow: Workflow;
  type: 'incomes' | 'expenses';
  currentDate?: Dayjs;
}

export default function useEntriesCollapseTable({
  workflow,
  type,
  currentDate,
}: UseEntriesCollapseTable) {
  const isIncomes = type === 'incomes';
  const title = useMemo(
    () => dayjs(workflow.start_date).format('MMMM YYYY').toUpperCase(),
    [workflow.start_date],
  );
  const isSameMonth = useMemo(
    () => dayjs(currentDate).isSame(dayjs(workflow.start_date), 'month'),
    [currentDate, workflow.start_date],
  );
  const [showEntriesTable, toggleShowEntriesTable] = useToggle(isSameMonth);
  const totals = useMemo(
    () =>
      workflow.declarations
        .filter((declaration) => declaration.is_active)
        .reduce(
          (acc, declaration) => {
            const { grandTotal } = isIncomes
              ? getTotalsFromDeclarationIncome(declaration.declaration_income)
              : getTotalsFromDeclarationExpense(
                  declaration.declaration_expense,
                );
            return {
              declarationsGrandTotal: acc.declarationsGrandTotal + grandTotal,
              transactionsCount:
                acc.transactionsCount +
                (isIncomes
                  ? declaration.count_income_entries
                  : declaration.count_expense_entries),
            };
          },
          {
            declarationsGrandTotal: 0,
            transactionsCount: 0,
          },
        ),
    [isIncomes, workflow.declarations],
  );
  const { declarationEntries, declarationEntriesLoading } =
    useDeclarationEntriesPagination({
      itemsPerPage: 2000,
      params: {
        'q[declaration_workflow_id_eq]': workflow.id,
        'q[declaration_is_active_true]': 1,
        'q[declaration_entry_type_in]': isIncomes
          ? declarationEntryIncomeTypes
          : declarationEntryExpenseTypes,
      },
      queryOptions: {
        enabled: showEntriesTable,
      },
    });

  const groupedDeclarationEntries = useMemo<GroupedEntries[]>(
    () =>
      declarationEntries.reduce<GroupedEntries[]>((acc, entry) => {
        const rfc = isIncomes ? getReceiverRfc(entry) : getIssuerRfc(entry);
        const legalName = isIncomes
          ? getReceiverLegalName(entry)
          : getIssuerLegalName(entry);
        const rfcOrSourceType = rfc || getSourceTypeLabel(entry) || 'N/A';
        const total = calculateAmountAndConversion(entry).amount;
        const issuerLegalName = [
          // 'Constancy',
          'ForeignProduct',
          'ForeignInvoice',
          'ExtraIncome',
        ].includes(entry.source_type)
          ? 'N/A'
          : legalName || 'N/A';
        const entryGroup = acc.find((group) => group.rfc === rfcOrSourceType);
        if (entryGroup) {
          entryGroup.total = +entryGroup.total + total;
        } else {
          acc.push({
            issuer_legal_name: issuerLegalName,
            total,
            rfc: rfcOrSourceType,
          });
        }
        return acc;
      }, []),
    [declarationEntries, isIncomes],
  );

  return {
    title,
    showEntriesTable,
    toggleShowEntriesTable,
    totals,
    declarationEntries,
    declarationEntriesLoading,
    groupedDeclarationEntries,
  };
}
