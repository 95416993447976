import React, { useEffect } from 'react';
import { Colxx } from '@components';

import { Row, TabContent, TabPane } from 'reactstrap';
import { FielUploadCard, CiecUploadCard, FielDownloadCard } from '@containers';
import { useTaxableEntity } from '@hooks';
import { Loader } from '@components/Utils/Loading';
import { fetchTaxableEntityCredentials } from '@redux/actions';
import { useDispatch } from 'react-redux';
import { PersonalInfoCard } from '.';

export default function FielandCiecTab({ activeTab, setActiveTab }) {
  const dispatch = useDispatch();
  const { taxable_entity } = useTaxableEntity();
  const { has_fiel, key_url } = taxable_entity;
  useEffect(() => {
    if (key_url === undefined && has_fiel)
      dispatch(fetchTaxableEntityCredentials(taxable_entity));
  }, [activeTab]);
  return (
    <TabContent activeTab={activeTab}>
      <TabPane tabId="0">
        {activeTab === '0' && (
          <Row>
            <Colxx sm="12">
              {has_fiel ? (
                <Loader loading={key_url === undefined}>
                  <FielDownloadCard
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </Loader>
              ) : (
                <FielUploadCard
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              )}
            </Colxx>
          </Row>
        )}
      </TabPane>
      <TabPane tabId="1">
        {activeTab === '1' && (
          <Row>
            <Colxx sm="12">
              <CiecUploadCard
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </Colxx>
          </Row>
        )}
      </TabPane>
      <TabPane tabId="2">
        {activeTab === '2' && (
          <Row>
            <Colxx sm="12">
              <PersonalInfoCard
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </Colxx>
          </Row>
        )}
      </TabPane>
    </TabContent>
  );
}
