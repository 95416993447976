import { css, CSS, styled } from '@konta/ui';

export const flexColumBaseCss: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
};
export const flexRowBaseCss: CSS = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'stretch',
};
export const insideAutoLayoutCss = css({
  flex: '0',
  alignSelf: 'stretch',
  variants: {
    grow: {
      0: {
        flexGrow: 0,
      },
      1: {
        flexGrow: 1,
      },
      2: {
        flexGrow: 2,
      },
      3: {
        flexGrow: 3,
      },
      4: {
        flexGrow: 4,
      },
      5: {
        flexGrow: 5,
      },
    },
    order: {
      0: {
        order: 0,
      },
      1: {
        order: 1,
      },
      2: {
        order: 2,
      },
      3: {
        order: 3,
      },
      4: {
        order: 4,
      },
      5: {
        order: 5,
      },
    },
  },
});
export const Container = styled('div', insideAutoLayoutCss, {
  ...flexColumBaseCss,
  padding: '$20 $16 0px',
  mb: '$40',
  gap: '$20',
});
export const Content = styled('div', insideAutoLayoutCss, {
  ...flexRowBaseCss,
  padding: '0',
  gap: '$16',
});
export const TextAndSupportingText = styled('div', insideAutoLayoutCss, {
  ...flexColumBaseCss,
  padding: '0',
  gap: '$4',
});
export const Divider = styled('div', {
  background: '$gray200',
  height: '1px',
  width: '100%',
});
export const Header = styled('div', insideAutoLayoutCss, {
  ...flexColumBaseCss,
  padding: '0',
  gap: '$20',
});
