/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { Button, Flex, ModalContent, Text } from '@konta/ui';
import { useDispatch, useSelector } from 'react-redux';
import { cancelCFDI } from '@redux/actions';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import FormikSelect from '@components/FormikSelect';
import FormikTextInput from '@components/FormikTextInput';
import { CANCELLATION_REASONS } from '@data/newInvoiceData';
import toCurrency from '@util/toCurrency';
import { useQueryClient } from 'react-query';
import { CFDIS } from '@constants/reactQueries';
import { Cfdi } from 'types/entities';

const validationSchema = yup.object().shape({
  selectedReason: yup.object().required('Campo requerido'),
  replacementUuid: yup
    .string()
    .nullable()
    .when('selectedReason.value', {
      is: (value: string) => value === '01',
      then: yup
        .string()
        .nullable()
        .required('Campo requerido')
        .test({
          message: 'No es un Folio Fiscal válido',
          test: (value) =>
            /^\s*[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\s*$/.test(
              value || '',
            ),
        }),
    }),
});

const initialValues = {
  replacementUuid: null,
  selectedReason: '',
};

interface ConfirmStampModalProps {
  isOpen: boolean;
  toggle: () => void;
  status: string;
  loading: boolean;
  id: number;
  togglePreview: () => void;
  invoice: Cfdi;
}

export default function ConfirmCancelInvoiceModal({
  isOpen,
  toggle,
  status,
  loading,
  id,
  togglePreview,
  invoice,
}: ConfirmStampModalProps) {
  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const { loading: cancelLoading, cfdi } = useSelector(({ cfdis }) => cfdis);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleCancelSubmit = ({ selectedReason, replacementUuid }: any) => {
    const body = {
      reason: selectedReason?.value,
      replacement_uuid: replacementUuid?.trim(),
    };
    dispatch(
      cancelCFDI(id, body, async () => {
        toggle();
        togglePreview();
        await queryClient.invalidateQueries([CFDIS]);
      }),
    );
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: handleCancelSubmit,
  });

  const isCancelBtnEnabled = loading || status === 'cancelled';

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" width="auto">
      {status === 'Pendiente de aceptación' ? (
        <FormikProvider value={formik}>
          <ModalHeader toggle={toggle}>Validar cancelación</ModalHeader>
          <ModalContent
            css={{ display: 'flex', gap: 20, flexDirection: 'column' }}
          >
            <Flex direction="column" gap={8}>
              <Text l lineHeight="m">
                Esta factura de{' '}
                <Text bold>
                  {toCurrency(+invoice.total)} {invoice.currency}{' '}
                </Text>{' '}
                del cliente <Text bold>{invoice.receiver_legal_name}</Text> está
                pendiente de aceptación.
              </Text>
            </Flex>
            <Flex direction="column" gap={10}>
              <Flex direction="column" gap={18} css={{ pt: '$10' }}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <FormikSelect
                  newDesign
                  label="Motivo de cancelación"
                  name="selectedReason"
                  onChange={({ value }: { value: string }) => {
                    if (value !== '01') {
                      formik.setFieldValue('replacementUuid', null);
                    }
                  }}
                  options={CANCELLATION_REASONS}
                />
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {formik.values.selectedReason.value === '01' && (
                  <FormikTextInput
                    name="replacementUuid"
                    // leftIcon={<MailIcon />}
                    placeholder="Escribe el folio fiscal de la factura (UUID)"
                    label="Folio que sustituye al CFDI"
                    css={{
                      label: { marginBottom: 0 },
                      path: {
                        fill: 'inherit',
                      },
                    }}
                  />
                )}
              </Flex>
            </Flex>
          </ModalContent>
          <ModalFooter>
            <Flex gap={10}>
              <Button
                variant="outlined"
                size="s"
                onClick={toggle}
                loading={cancelLoading}
              >
                Continuar visualizando
              </Button>
              <Button
                color="primary"
                disabled={isCancelBtnEnabled}
                size="s"
                onClick={formik.submitForm}
                loading={cancelLoading}
              >
                Validar cancelación
              </Button>
            </Flex>
          </ModalFooter>
        </FormikProvider>
      ) : (
        <FormikProvider value={formik}>
          <ModalHeader toggle={toggle}>Cancelación de CFDI</ModalHeader>
          <ModalContent
            css={{ display: 'flex', gap: 20, flexDirection: 'column' }}
          >
            <Flex direction="column" gap={8}>
              <Text l lineHeight="m">
                ¿Quieres cancelar esta factura de{' '}
                <Text bold>
                  {toCurrency(+invoice?.total)} {invoice?.currency}{' '}
                </Text>{' '}
                del cliente <Text bold>{invoice?.receiver_legal_name}</Text>?
              </Text>
            </Flex>
            <Flex direction="column" gap={10}>
              <Flex direction="column" gap={18} css={{ pt: '$10' }}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <FormikSelect
                  newDesign
                  label="Motivo de cancelación"
                  name="selectedReason"
                  onChange={({ value }: { value: string }) => {
                    if (value !== '01') {
                      formik.setFieldValue('replacementUuid', null);
                    }
                  }}
                  options={CANCELLATION_REASONS}
                />
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {formik.values.selectedReason.value === '01' && (
                  <FormikTextInput
                    name="replacementUuid"
                    // leftIcon={<MailIcon />}
                    placeholder="Escribe el folio fiscal de la factura (UUID)"
                    label="Folio que sustituye al CFDI"
                    css={{
                      label: { marginBottom: 0 },
                      path: {
                        fill: 'inherit',
                      },
                    }}
                  />
                )}
              </Flex>
            </Flex>
          </ModalContent>
          <ModalFooter>
            <Flex gap={10}>
              <Button
                variant="outlined"
                size="s"
                onClick={toggle}
                loading={cancelLoading}
              >
                Continuar visualizando
              </Button>
              <Button
                color="primary"
                disabled={isCancelBtnEnabled}
                size="s"
                onClick={() => formik.submitForm()}
                loading={cancelLoading}
              >
                Cancelar factura
              </Button>
            </Flex>
          </ModalFooter>
        </FormikProvider>
      )}
    </Modal>
  );
}
