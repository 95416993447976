import api from '@api';

const getServerDate = async function () {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = await api.get('/timezone/now', {
      headers: {
        Authorization: access_token,
        'Content-Type': 'application/json',
      },
    });
    return response.data.date_time;
  } catch (error) {
    return false;
  }
};

export default getServerDate;
