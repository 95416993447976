import { useState } from 'react';
import {
  AccumulatedIncomeAndExpenseFilters,
  ReactSelectItemProps,
} from 'types/entities';
import Loader from '@components/Loader';
import LineSeries from 'shared/components/LineSeries';
import useAccumulatedIncomesAndExpenses from 'shared/hooks/useAccumulatedIncomesAndExpenses';

const filterOpts: ReactSelectItemProps<AccumulatedIncomeAndExpenseFilters>[] = [
  { value: 'currentMonth', label: 'Mes actual' },
  { value: 'currentYear', label: 'Año actual' },
];
export default function ExpensesLineSeries() {
  const [filter, setFilter] = useState<
    ReactSelectItemProps<AccumulatedIncomeAndExpenseFilters>
  >(filterOpts[0]);
  const { expensesDataSet, isLoading, accumulatedExpenseTotal, isDesktop } =
    useAccumulatedIncomesAndExpenses({
      filter: filter.value,
    });
  if (isLoading) {
    return <Loader />;
  }
  return (
    <LineSeries
      id="expensesBarSeries"
      isDesktop={isDesktop}
      dataSet={expensesDataSet}
      total={accumulatedExpenseTotal}
      title="Gasto facturado acumulado"
      textTitleHover="Este gráfico representa la evolución temporal de los gastos acumulados."
      filterProps={{
        onChangeFilter: (newFilter) => {
          setFilter(
            newFilter as ReactSelectItemProps<AccumulatedIncomeAndExpenseFilters>,
          );
        },
        options: filterOpts,
        value: filter.value,
      }}
    />
  );
}
