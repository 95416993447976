import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  CFDI_XML_FETCH,
  CFDI_CANCEL,
  CFDI_POST,
  CFDIS_FETCH,
  UI_CHANGE_DATE_CFDIS,
  CFDIS_FETCH_BY_CLIENT,
  SEND_EMAIL,
  CFDIS_FETCH_BY_PPDS,
  GET_COMPLEMENTS,
} from '@constants/actionTypes';
import fetchSaga from './fetch';
import cancelSaga from './cancel';
import postSaga from './post';
import getSaga from './get';
import dateSaga from './date';
import byClientSaga from './by_client';
import sendPdfSaga from './pdf_send';
import byPpdsSaga from './by_ppds';
import complementsGetSaga from './getComplements';

function* watchDate() {
  yield takeLatest(UI_CHANGE_DATE_CFDIS, dateSaga);
}
export function* watchPost() {
  yield takeLatest(CFDI_POST, postSaga);
}
export function* watchFetch() {
  yield takeLatest(CFDI_XML_FETCH, fetchSaga);
}

export function* watchCancel() {
  yield takeLatest(CFDI_CANCEL, cancelSaga);
}
export function* watchGet() {
  yield takeLatest(CFDIS_FETCH, getSaga);
}
export function* watchFetchByClient() {
  yield takeLatest(CFDIS_FETCH_BY_CLIENT, byClientSaga);
}

export function* watchSendEmail() {
  yield takeLatest(SEND_EMAIL, sendPdfSaga);
}

export function* watchByPpds() {
  yield takeLatest(CFDIS_FETCH_BY_PPDS, byPpdsSaga);
}
export function* watchComplements() {
  yield takeLatest(GET_COMPLEMENTS, complementsGetSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetch),
    fork(watchCancel),
    fork(watchPost),
    fork(watchGet),
    fork(watchDate),
    fork(watchFetchByClient),
    fork(watchSendEmail),
    fork(watchByPpds),
    fork(watchComplements),
  ]);
}
