import {
  Button,
  Chip,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  HoverCard,
} from '@konta/ui';
import { ButtonProps } from '@konta/ui/dist/components/Button/Button';
import { ChevronDownCrFr1OutlineIcon, ChevronDownLineIcon } from '@konta/icons';
import { declarationAnnualEntryExpensesTypes } from '@constants/declarationEntries';
import { getAccountingEntryTypeLabel } from '@util/declarationEntries';
import type { DeclarationEntry } from 'types/entities';
import { UseIncomeListReturnType } from '@components/Workflow/EditIncomes/ClassifyIncomes/IncomesList/useIncomesList';

interface AccountingStatusDropdownProps {
  declarationEntry: DeclarationEntry;
  setSelectedEntry: UseIncomeListReturnType['setSelectedEntry'];
  entryType?: 'income' | 'expense';
}
export default function AccountingStatusDropdown({
  declarationEntry,
  setSelectedEntry,
  entryType,
}: AccountingStatusDropdownProps) {
  const { color: accountingStatusColor, label: accountingStatusLabel } =
    getAccountingEntryTypeLabel(declarationEntry);

  const applyForAnnual = declarationAnnualEntryExpensesTypes.includes(
    declarationEntry.declaration_entry_type,
  );

  return (
    <Flex column css={{ minWidth: '110px' }} gap={6} align="end">
      <DropdownMenu>
        <DropdownMenuTrigger data-disabled asChild>
          <Button
            color={accountingStatusColor as ButtonProps['color']}
            size="xs"
            variant="outlined"
            rightIcon={<ChevronDownCrFr1OutlineIcon />}
          >
            {accountingStatusLabel}
          </Button>
        </DropdownMenuTrigger>
        {entryType === 'expense' ? (
          <DropdownMenuContent sideOffset={5}>
            <DropdownMenuItem
              onSelect={() => {
                setSelectedEntry({
                  ...declarationEntry,
                  newAccountingStatus: 'is_deductible',
                  modalHeader: 'Deducir factura',
                  title: '¿Quieres hacer deducible esta factura?',
                  subtitle:
                    'Este gasto pasará a ser considerado para el cálculo del beneficio neto del mes.',
                });
              }}
            >
              <Flex align="center">Hacer deducible</Flex>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setSelectedEntry({
                  ...declarationEntry,
                  newAccountingStatus: 'not_deductible',
                  modalHeader: 'No deducir factura',
                  title: '¿Quieres hacer no deducible esta factura?',
                  subtitle:
                    'Esta opción hará que tu gasto no se tome en cuenta en su cálculo del beneficio neto.',
                });
              }}
            >
              <Flex align="center">Hacer no deducible</Flex>
            </DropdownMenuItem>
            {/* <DropdownMenuItem
              onSelect={() => {
                setSelectedEntry({
                  ...declarationEntry,
                  newAccountingStatus: 'paid',
                  modalHeader: 'Gasto del mes anterior',
                  title: '¿Quieres considerar este gasto en el mes anterior?',
                  subtitle:
                    'Su declaración del mes anterior se verá afectada por el nuevo gasto agregado y tendrá que ser modificada.',
                });
              }}
            >
              <Flex align="center">Ya contemplada en el mes anterior</Flex>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setSelectedEntry({
                  ...declarationEntry,
                  newAccountingStatus: 'not_paid',
                  modalHeader: 'Gasto para el siguiente mes',
                  title:
                    '¿Quieres considerar este gasto para el siguiente mes?',
                  subtitle:
                    'Su declaración del mes siguiente tendrá reflejado este gasto fuera del mes en el cálculo de los impuestos.',
                });
              }}
            >
              <Flex align="center">Mover al siguiente mes</Flex>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setSelectedEntry({
                  ...declarationEntry,
                  newAccountingStatus: 'fixed_asset',
                  modalHeader: 'Activo fijo',
                  title: '¿Quieres considerar este gasto como activo fijo?',
                  subtitle:
                    'Este gasto se declarará de manera paulatina dentro de un periodo indicado por la tasa que escribió.',
                });
              }}
            >
              <Flex align="center">Es un activo fijo</Flex>
            </DropdownMenuItem> */}
            {applyForAnnual && (
              <DropdownMenuItem
                onSelect={() => {
                  setSelectedEntry({
                    ...declarationEntry,
                    newAccountingStatus: 'annual',
                    modalHeader: 'Gasto anual',
                    title: '¿Quieres considerar gasto a la declaración anual?',
                    subtitle:
                      'Este gasto será contemplado en tu declaración anual.',
                  });
                }}
              >
                <Flex align="center">Mover a gasto anual</Flex>
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        ) : (
          <DropdownMenuContent sideOffset={5}>
            <DropdownMenuItem
              size="xs"
              onSelect={() => {
                setSelectedEntry({
                  ...declarationEntry,
                  newAccountingStatus: 'is_deductible',
                  modalHeader: 'Deducir factura',
                  title: '¿Quieres hacer deducible esta factura?',
                  subtitle:
                    'Este ingreso pasará a ser considerado para el cálculo del beneficio neto del mes.',
                });
              }}
            >
              <Flex align="center">Si entra</Flex>
            </DropdownMenuItem>
            <DropdownMenuItem
              size="xs"
              onSelect={() => {
                setSelectedEntry({
                  ...declarationEntry,
                  newAccountingStatus: 'not_deductible',
                  modalHeader: 'No deducir factura',
                  title: '¿Quieres hacer no deducible esta factura?',
                  subtitle:
                    'Esta opción hará que tu ingreso no se tome en cuenta en su cálculo del beneficio neto.',
                });
              }}
            >
              <Flex align="center">No entra</Flex>
            </DropdownMenuItem>
            <DropdownMenuItem
              size="xs"
              onSelect={() => {
                setSelectedEntry({
                  ...declarationEntry,
                  newAccountingStatus: 'not_paid',
                  modalHeader: 'Considerar el siguiente mes',
                  title:
                    '¿Quieres considerar este ingreso para el siguiente mes?',
                  subtitle: '',
                });
              }}
            >
              <Flex align="center">Considerar el siguiente mes</Flex>
            </DropdownMenuItem>
          </DropdownMenuContent>
        )}
      </DropdownMenu>
      {declarationEntry.comments && (
        <HoverCard
          openDelay={0}
          align="end"
          side="bottom"
          triggerElement={
            <span tabIndex={-1} role="button">
              <Chip
                size="xs"
                label="Comentarios del contador"
                iconAction={<ChevronDownLineIcon />}
              />
            </span>
          }
        >
          {declarationEntry.comments}
        </HoverCard>
      )}
    </Flex>
  );
}
