export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const BINOMIAL_MONTHS = [
  'Ene/Feb',
  'Mar/Abr',
  'May/Jun',
  'Jul/Ago',
  'Sep/Oct',
  'Nov/Div',
];
