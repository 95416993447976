import { StateCreator } from 'zustand';
import { IFlowsSlice, IStoreState } from 'backoffice/store/types';

const initialState = {
  openCanvas: false,
  selectedFlow: null,
  selectedNode: null,
};

const createFlowsSlice: StateCreator<IStoreState, [], [], IFlowsSlice> = (
  set,
) => ({
  ...initialState,
  setOpenCanvas: (openCanvas) => set({ openCanvas }),
  closeCanvas: () => set({ openCanvas: false }),
  setSelectedFlow: (flow) => set({ selectedFlow: flow }),
  resetSelectedFlow: () => set({ selectedFlow: null }),
  openCanvasAndSetFlow: (flow) => {
    set({ openCanvas: true, selectedFlow: flow });
  },
  setSelectedNode: (node) => set({ selectedNode: node }),
});

export default createFlowsSlice;
