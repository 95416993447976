import { HoverCard } from '@konta/ui';
import { HelpCircleLineIcon } from '@konta/icons';
import Icon from 'shared/components/Icon';
import toCurrency from '@util/toCurrency';
import { HeaderWrapper, Root, Title, Total, TotalWrapper } from './styled';

interface MetricProps {
  title: string;
  total: number;
  tooltipText?: string;
  isText?: boolean;
}
export default function Metric({
  title,
  total,
  tooltipText,
  isText,
}: MetricProps) {
  return (
    <Root>
      <HeaderWrapper>
        <Title>{title}</Title>
        {!!tooltipText && (
          <HoverCard
            side="top"
            openDelay={0}
            triggerElement={
              <Icon tabIndex={-1} role="button">
                <HelpCircleLineIcon />
              </Icon>
            }
          >
            {tooltipText}
          </HoverCard>
        )}
      </HeaderWrapper>
      <TotalWrapper>
        <Total>{isText ? total : `${toCurrency(total)} MXN`}</Total>
      </TotalWrapper>
    </Root>
  );
}
