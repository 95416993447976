import api from '@api';
import { TaxableEntityPreference } from 'types/entities';

interface PatchTaxableEntityPreferencesProps {
  payload: TaxableEntityPreference;
  taxableEntityId: number;
}
export default async function patchTaxableEntityPreferences({
  payload,
  taxableEntityId,
}: PatchTaxableEntityPreferencesProps) {
  const { data } = await api.patch<TaxableEntityPreference>(
    `taxable_entities/${taxableEntityId}/preferences`,
    payload,
  );
  return data;
}
