import { DataTable, Text } from '@konta/ui';
import toCurrency from '@util/toCurrency';
import type { GroupedEntries } from 'types/entities';
import useEntriesGroupedTable from './useEntriesGroupedTable';
import { totalCss } from '../styled';

export interface EntriesGroupedTableProps {
  groupedEntries: GroupedEntries[];
  declarationEntriesLoading: boolean;
  type: 'incomes' | 'expenses';
  workflowId: number;
  setModalInvoiceDetails: (value: {
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  }) => void;
  total: number;
}

export default function EntriesGroupedTable({
  groupedEntries,
  declarationEntriesLoading,
  type,
  workflowId,
  setModalInvoiceDetails,
  total,
}: EntriesGroupedTableProps) {
  const { groupedDeclarationEntriesColumns, openCfdisModal } =
    useEntriesGroupedTable({
      type,
      setModalInvoiceDetails,
    });

  return (
    <DataTable
      data={groupedEntries}
      // @ts-ignore TODO: fix typing with react-table
      columns={groupedDeclarationEntriesColumns}
      withHover
      loading={declarationEntriesLoading}
      dataTableId={`modal-grouped-${type}-${workflowId}-entries-table`}
      // onFiltersChange={setFilters}
      onRowClick={openCfdisModal}
      withoutExportControl
      withoutHideControl
      extraFooterRightText={
        <>
          <Text css={totalCss}>Total:</Text>
          <Text css={totalCss} color="primary700">
            {toCurrency(total)}
          </Text>
        </>
      }
    />
  );
}
