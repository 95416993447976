const PLATFORM_SAT_KEY = 625;
const RESICO_SAT_KEY = 626;
const PFAE_SAY_KEY = 612;

export const STEPS_RESICO_PFAE = {
  incomes: [
    'intro_resico',
    'select_income_upload_way',
    'belvo_connection',
    'upload_income_total',
    'upload_transaction',
    'has_exempt_iva',
    'classify_transaction',
    'classify_incomes_resico',
    'has_extra_income',
    'income_review_required',
    'create_missing_public_invoice_resico',
    'not_csd_upload_invoice_resico',
    'text_expenses_resico',
    'invoices_income_verification',
  ],
  expenses: [
    'qu_foreign_expences_resico',
    'add_foreign_expence_resico',
    'expences_classification_resico',
  ],
  calculateTaxes: [
    'compute_result_resico',
    'pending_declaration',
    'capture_line_payment',
    'taxes_payment',
    'workflow_end',
  ],
};

export const STEPS_PLATFORM = {
  incomes: [
    'intro',
    'paperwork',
    'constancy_summary',
    'classify_incomes_phase',
    'qu_not_platform_income',
    'create_forign_and_public_invoice',
    'mising_public_invoice',
    'total_constancy_exceeded',
    'not_csd_upload_invoice',
    'text_expences',
    'invoices_income_verification',
  ],
  expenses: [
    'qu_foreign_expences',
    'add_foreign_expence',
    'expences_classification',
  ],
  calculateTaxes: [
    'compute_result',
    'pending_declaration',
    'capture_line_payment',
    'taxes_payment',
    'workflow_end',
  ],
};

export const STEPS = {
  [PLATFORM_SAT_KEY]: STEPS_PLATFORM,
  [RESICO_SAT_KEY]: STEPS_RESICO_PFAE,
  [PFAE_SAY_KEY]: STEPS_RESICO_PFAE,
  default: [],
};
