import { DeductionsLimitReachedFlowStep } from 'types/flows';
import toCurrency from '@util/toCurrency';
import { ReactComponent as Img } from './img.svg';
import {
  AccumulatedAmountContainer,
  ImageContainer,
  Container,
  Title,
  AvailableBalanceContainer,
} from './styled';

interface DeductionsLimitReachedStepContentProps {
  deductionsLimitReachedFlowStep: DeductionsLimitReachedFlowStep;
}

export default function DeductionsLimitReachedStepContent({
  deductionsLimitReachedFlowStep,
}: DeductionsLimitReachedStepContentProps) {
  const { state } = deductionsLimitReachedFlowStep;
  const { limit, accumulated_deductions } = state;

  return (
    <>
      <Title>Has alcanzado el límite de tus deducciones personales</Title>
      <ImageContainer>
        <Img />
      </ImageContainer>
      <Container>
        <AccumulatedAmountContainer>
          <span data-type="s">Saldo Acumulado al día de hoy</span>
          <span data-type="l">
            {toCurrency(+(accumulated_deductions || 0))}
          </span>
        </AccumulatedAmountContainer>
        <AvailableBalanceContainer>
          <span data-type="s">
            Límite disponible: <b>{toCurrency(+(limit || 0))}</b>
          </span>
          <span data-type="xs">Límite disponible anual</span>
        </AvailableBalanceContainer>
      </Container>
    </>
  );
}
