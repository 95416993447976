export const slideStyles = {
  width: '180px',
  height: '180px',
  marginRight: '24px',
  borderRadius: '8px',
  position: 'relative',
};

export const seenCss = {
  border: '1px solid $gray300',
};

export const unseenCss = {
  background: 'white',
  border: '3px solid transparent',
  borderRadius: '8px',
  backgroundImage:
    'linear-gradient(white, white), linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
};
