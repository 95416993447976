export const fileCardCss = {
  padding: '$16',
  alignItems: 'flex-start',
  gap: '$4',
  alignSelf: 'stretch',
};
export const fileCardContentCss = {
  alignItems: 'flex-start',
  flexDirection: 'row',
  padding: '0',
  gap: '$16',
  position: 'relative',
};
export const fileCardIconCss = {
  width: '30px',
  height: '30px',
  flexShrink: 0,
  border: 'solid var(--space) $primary50',
  backgroundColor: '$primary100',
  svg: {
    width: '16px',
    height: '16px',
    path: {
      fill: '$primary600',
    },
  },
};
export const dropzoneWrapperCss = { width: '100%', color: '$gray900' };

export const flex1Css = {
  flex: 1,
};
export const py16Css = {
  py: '$16',
};
export const py32Css = {
  py: '$32',
};
export const gap0Css = {
  gap: '0',
};
export const mt16Css = {
  mt: '$16',
};
export const borderNoneCss = {
  border: 'none',
};
