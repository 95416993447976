import { ComponentProps, useMemo } from 'react';
import { useWindowSize } from 'rooks';
import { HelpCircleLineIcon } from '@konta/icons';
import {
  BarSeries,
  Flex,
  HoverCard,
  Text,
  Select,
  Box,
  useMediaQuery,
} from '@konta/ui';
import toCurrency from '@util/toCurrency';
import Icon from 'shared/components/Icon';
import type { ReactSelectItemProps } from 'types/entities';

interface LineSeriesProps {
  dataSet: ComponentProps<typeof BarSeries>[`dataSet`];
  isDesktop: boolean;
  id: string;
  margin?: { top: number; right: number; bottom: number; left: number };
  total?: number;
  textTitleHover?: string;
  title?: string;
  filterProps?: {
    options: ReactSelectItemProps<unknown>[];
    value: string;
    onChangeFilter: (filter: unknown) => void;
  };
  tooltip?: ComponentProps<typeof BarSeries>[`tooltip`];
  onChangeFilter?: (filter: string) => void;
  onlyGraph?: boolean;
}

export default function StackGraph({
  dataSet,
  isDesktop,
  id,
  margin = { top: 5, right: 10, bottom: 50, left: 60 },
  total,
  textTitleHover,
  title,
  filterProps,
  tooltip,
  onlyGraph,
}: LineSeriesProps) {
  const { outerWidth } = useWindowSize();
  const isMobile = useMediaQuery('(max-width: 426px)');
  const width = useMemo(() => {
    if (isDesktop || !outerWidth) {
      return undefined;
    }

    if (outerWidth <= 991) {
      return outerWidth - 50;
    }

    return undefined;
  }, [isDesktop, outerWidth]);

  return (
    <Flex
      css={{
        width: '100%',
        overflow: 'auto',
        gap: 10,
        flexDirection: 'column',
      }}
    >
      {!onlyGraph && (
        <Flex column>
          <Flex itemsCenter justify="between">
            <Flex gap={6} itemsCenter>
              <Text color="gray700" medium s lineHeight="s">
                {title}
              </Text>
              {!!textTitleHover && (
                <HoverCard
                  side="top"
                  openDelay={0}
                  triggerElement={
                    <Icon tabIndex={-1} role="button">
                      <HelpCircleLineIcon />
                    </Icon>
                  }
                >
                  {textTitleHover}
                </HoverCard>
              )}
            </Flex>
            {!!filterProps && (
              <Flex column itemsCenter>
                <Box css={{ height: '$4' }} />
                <Select
                  onChange={(newValue) => {
                    if (filterProps?.onChangeFilter) {
                      filterProps.onChangeFilter(newValue);
                    }
                  }}
                  size="xs"
                  options={filterProps.options}
                  value={filterProps.options.find(
                    (opt) => opt.value === filterProps.value,
                  )}
                />
                <Box css={{ height: '$4' }} />
              </Flex>
            )}
          </Flex>
          {total !== undefined && (
            <Text color="gray900" medium l lineHeight="l">
              {toCurrency(total)} MXN
            </Text>
          )}
        </Flex>
      )}
      <BarSeries
        showGridRows
        tooltip={tooltip}
        type="stackedBar"
        yNumberTicks={6}
        margin={margin}
        showTooltip
        dataSet={dataSet}
        height={270}
        id={id}
        width={width}
        paddingInner={0.4}
        nTicks={isMobile ? 4 : 6}
        snapTooltipToDatumX
        snapTooltipToDatumY
        showVerticalCross
        yAxisProps={{
          hideTicks: true,
          hideAxisLine: true,
        }}
        xAxisProps={{
          hideTicks: true,
          hideAxisLine: true,
        }}
        theme={{
          colors: [
            '#A29BFE',
            '#83C7FF',
            '#FFB8A1',
            '#FF5A89',
            '#F4C222',
            '#9AD8D8',
            '#F97850',
            '#33CCCC',
            '#64B9A1',
            '#919DAF',
            '#DEEDCF',
            '#F68484',
          ],
          gridColorDark: '#EDF0F2',
          gridColor: '#98A2B3',
        }}
      />
    </Flex>
  );
}
