import {
  ArrowNarrowLeftLineIcon,
  ArrowNarrowRightLineIcon,
} from '@konta/icons';
import { Button } from '@konta/ui';
import { ActionsContainer, navigationButtonCss } from './styled';

interface DialogActionsProps {
  onPrevious: () => void;
  onNext: () => void;
  hasPrevious: boolean;
  hasNext: boolean;
  previousButtonText?: string;
  nextButtonText?: string;
  isLoading?: boolean;
}

export function DialogActions({
  onPrevious,
  onNext,
  hasPrevious,
  hasNext,
  isLoading,
  previousButtonText = 'Atrás',
  nextButtonText = 'Siguiente',
}: DialogActionsProps) {
  return (
    <ActionsContainer role="navigation" aria-label="Navegación de diálogo">
      {hasPrevious && (
        <Button
          size="s"
          css={navigationButtonCss}
          leftIcon={<ArrowNarrowLeftLineIcon aria-hidden="true" />}
          variant="text"
          loading={isLoading}
          onClick={onPrevious}
          disabled={!hasPrevious}
          aria-label={`${previousButtonText}: Página anterior`}
        >
          {previousButtonText}
        </Button>
      )}
      {hasNext && (
        <Button
          size="s"
          css={navigationButtonCss}
          rightIcon={<ArrowNarrowRightLineIcon aria-hidden="true" />}
          variant="text"
          onClick={onNext}
          loading={isLoading}
          disabled={!hasNext}
          aria-label={`${nextButtonText}: Página siguiente`}
        >
          {nextButtonText}
        </Button>
      )}
    </ActionsContainer>
  );
}

export default DialogActions;
