import dayjs from 'dayjs';
import useSearchParamsState from '@hooks/useSearchParamsState';
import useWorkflow from '../hooks/useWorkflow';

export default function useEditIncomes() {
  const { workflow } = useWorkflow();
  const [action, setAction] = useSearchParamsState('action', 'new');
  const openUploadIncomes = action === 'new';
  const toggleOpenUploadIncomes = () =>
    setAction(openUploadIncomes ? 'edit' : 'new');
  const startDate = dayjs(workflow?.start_date).format('MMMM');
  return {
    startDate,
    openUploadIncomes,
    toggleOpenUploadIncomes,
  };
}
