import { AxiosRequestConfig } from 'axios';
import {
  ClientSupplier,
  PublicClientSupplierCreatePayload,
} from 'types/entities';
import api from './index';

// eslint-disable-next-line import/prefer-default-export
export const postPublicClientSuppliers = async (
  { taxableEntityUuid, payload }: PublicClientSupplierCreatePayload,
  config?: AxiosRequestConfig<ClientSupplier>,
) => {
  const { data } = await api.post<ClientSupplier>(
    `taxable_entities/${taxableEntityUuid}/client_suppliers/public_create`,
    payload,
    config,
  );
  return data;
};
