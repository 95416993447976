import React, { ChangeEvent, useMemo } from 'react';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import {
  Button,
  config,
  Flex,
  ModalContent,
  Text,
  useMediaQuery,
} from '@konta/ui';
import { FormikProvider, useFormik } from 'formik';
import FormikTextInput from '@components/FormikTextInput';
import FormikCheckbox, { CheckedState } from '@components/FormikCheckbox';
import * as yup from 'yup';
import { UseInvoice } from '@hooks/useInvoiceForm';
import { InvoiceFormToPayload } from '@util/invoiceFormToPayload';
import { ReactComponent as MailIcon } from '@assets/img/icons/mail-icon.svg';
import WhatsappIcon from '@components/WhatsappIcon';
import { ReactSelectItemProps } from 'types/reactSelect';
import { ClientSupplier } from 'types/entities';
import toCurrency from '@util/toCurrency';

const validationSchema = yup.object().shape({
  hasEmailEnabled: yup.boolean().required('Campo requerido'),
  email: yup.string().when('hasEmailEnabled', {
    is: true,
    then: yup
      .string()
      .email('El correo electrónico debe ser válido')
      .required('Campo requerido')
      .test({
        message: 'No se permiten caracteres especiales',
        test: (value) => {
          if (!value) return true;
          return !/á|é|í|ó|ú|Á|É|Í|Ó|Ú|\|/.test(value);
        },
      }),
  }),
  hasPhoneEnabled: yup.boolean().required('Campo requerido'),
  phone: yup.string().when('hasPhoneEnabled', {
    is: true,
    then: yup.string().required('Campo requerido'),
  }),
});

interface ConfirmStampModalProps {
  isOpen: boolean;
  toggle: () => void;
  payload: InvoiceFormToPayload;
  invoice: UseInvoice;
}
export default function ConfirmStampModal({
  isOpen,
  toggle,
  payload,
  invoice,
}: ConfirmStampModalProps) {
  const isTabletOrMobile = useMediaQuery(config.media['<md']);
  const initialValues = useMemo(() => {
    const email = (
      invoice.formik.values
        .client as unknown as ReactSelectItemProps<ClientSupplier>
    )?.value?.email;

    return {
      hasEmailEnabled: true,
      email,
      hasPhoneEnabled: true,
      phone: '',
    };
  }, [invoice.formik.values.client]);

  const formik = useFormik({
    onSubmit: async ({ email, phone }) => {
      // replace characters that are not allo
      const IntlPhoneNumber = phone && `52${phone}`.slice(-12);

      await invoice.stamp({
        payload,
        email,
        phone: IntlPhoneNumber,
      });
    },
    validationSchema,
    initialValues,
    enableReinitialize: true,
  });

  const enableSendInvoice =
    formik.values.hasPhoneEnabled || formik.values.hasEmailEnabled;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" width="auto">
      <FormikProvider value={formik}>
        <ModalHeader toggle={toggle}>Emitir y enviar factura</ModalHeader>
        <ModalContent
          css={{ display: 'flex', gap: 20, flexDirection: 'column' }}
        >
          <Flex direction="column" gap={8}>
            <Text color="black" medium l lineHeight="l">
              ¿Quieres emitir y enviar la factura de{' '}
              {toCurrency(+payload.total)} MXN a{' '}
              {payload.receiver_attributes.name}?
            </Text>
            {/* <Text color="gray500" m lineHeight="m">
              Si generaste un enlace para que el cliente proporcione su
              información fiscal, la factura se emitirá una vez que haya
              completado esta información a través del enlace.
            </Text> */}
          </Flex>
          <Flex direction="column" gap={10}>
            <Flex direction="column" gap={18} css={{ pt: '$10' }}>
              <FormikCheckbox
                name="hasEmailEnabled"
                label="Enviar la factura por correo electrónico al emitirla"
                onChange={(hasEmailEnabled: CheckedState) => {
                  void formik.setFieldValue(
                    'email',
                    hasEmailEnabled ? initialValues.email : '',
                  );
                }}
              />
              {formik.values.hasEmailEnabled && (
                <Flex css={{ pl: '24px' }}>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <FormikTextInput
                    name="email"
                    leftIcon={<MailIcon />}
                    placeholder="juan@gmail.com"
                    label="Correo electrónico"
                    css={{
                      label: { marginBottom: 0 },
                      path: {
                        fill: 'inherit',
                      },
                    }}
                  />
                </Flex>
              )}
            </Flex>
            {/* Remove false when backend support to send of invoice for whatsapp */}
            <Flex direction="column" gap={18} css={{ pt: '$10' }}>
              <FormikCheckbox
                name="hasPhoneEnabled"
                label="Enviar la factura por whatsapp al emitirla"
                onChange={(hasWhatsEnabled: CheckedState) => {
                  if (!hasWhatsEnabled) {
                    void formik.setFieldValue('phone', '');
                  }
                }}
              />
              {formik.values.hasPhoneEnabled && (
                <Flex css={{ pl: '24px' }}>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <FormikTextInput
                    name="phone"
                    type="number"
                    label="Whatsapp"
                    onWheel={(e: ChangeEvent<HTMLInputElement>) =>
                      e.target.blur()
                    }
                    css={{ label: { marginBottom: 0 } }}
                    leftIcon={<WhatsappIcon />}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </ModalContent>
        <ModalFooter>
          <Flex gap={10}>
            <Button
              variant="outlined"
              size={isTabletOrMobile ? 's' : 'm'}
              onClick={toggle}
            >
              Continuar modificando
            </Button>
            <Button
              color="primary"
              size={isTabletOrMobile ? 's' : 'm'}
              onClick={() => formik.submitForm()}
              loading={invoice.isSubmitting}
            >
              {enableSendInvoice ? 'Emitir y enviar factura' : 'Emitir factura'}
            </Button>
          </Flex>
        </ModalFooter>
      </FormikProvider>
    </Modal>
  );
}
