import React, { useState } from 'react';
import { Modal, ModalHeader, ModalFooter, Button } from 'reactstrap';

export function ButtonConfirmModal({
  iconName,
  buttonColor,
  buttonClassName,
  isButtonDisabled,
  buttonLabel,
  header,
  handleSubmit,
  cancelButton,
  confirmButton,
  children,
  loading,
  bodyClassName = 'm-auto text-center',
  isConfirmButtonDisable,
  outline = false,
  modalSize = 'md',
  lineIcon: LineIcon = null,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const toggle = () => setModalOpen(!modalOpen);

  const handleModalSubmit = () => {
    toggle();
    handleSubmit();
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button
        onClick={toggle}
        color={buttonColor}
        className={`${buttonClassName} btn-multiple-state ${
          loading && 'show-spinner'
        }`}
        disabled={isButtonDisabled}
        outline={outline}
      >
        {iconName && <i className={iconName} />}
        {LineIcon && <LineIcon className="button-icon" />}
        <span className="spinner d-inline-block">
          <span className="bounce1" />
          <span className="bounce2" />
          <span className="bounce3" />
        </span>
        <span className="label">{buttonLabel}</span>
      </Button>
      <Modal size={modalSize} isOpen={modalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} className="text-align-center">
          {header}
        </ModalHeader>
        <div className={bodyClassName}>{children}</div>
        <ModalFooter className="d-flex justify-content-center">
          {cancelButton && (
            <Button outline size="sm" onClick={toggle}>
              {cancelButton}
            </Button>
          )}
          <Button
            color="primary"
            size="sm"
            onClick={() => handleModalSubmit()}
            disabled={isConfirmButtonDisable}
          >
            {confirmButton}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ButtonConfirmModal;
