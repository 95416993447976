import React from 'react';

import {
  OrdersRow,
  OrderHead,
  Loader,
  Colxx,
  FiscalPeriodRow,
  AddCardButton,
  PaymentTabs,
} from '@components';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addOrdersToCart } from '@redux/actions';
import { Button, Row } from 'reactstrap';

export function DebtsTable({ type, data, loading }) {
  return (
    <Loader loading={loading}>
      <section className="p-3">
        {type !== 'FiscalPeriod' ? (
          <OrderTable orders={data} />
        ) : (
          <TableFiscalTablePeriod fps={data} />
        )}
      </section>
    </Loader>
  );
}

export function OrderTable({ orders }) {
  const dispatch = useDispatch();

  return (
    <>
      <OrderHead />
      {orders.map((order, idx) => {
        return <OrdersRow key={idx} orders={order} />;
      })}
      <Row className="mt-4 font-weight-bold  w-90 mx-auto row">
        <Colxx xxs="12" className="text-right">
          <Link
            to={{
              pathname: '/app/checkout',
            }}
            state={{ order_id: null }}
          >
            <Button
              color="primary"
              onClick={() => dispatch(addOrdersToCart(orders))}
            >
              Pagar todas las deudas
            </Button>
          </Link>
        </Colxx>
      </Row>
    </>
  );
}

export function TableFiscalTablePeriod({ fps, current }) {
  return (
    <>
      <PaymentTabs className="mb-4" filter>
        <AddCardButton />
      </PaymentTabs>
      <OrderHead />
      {fps.map((fp, idx) => {
        return <FiscalPeriodRow key={idx} idx={idx} fp={fp} />;
      })}
    </>
  );
}
