import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Flex, OptionsSelect, CardOption, Button } from '@konta/ui';
import { ArrowRightLineIcon, InfoCircleLineIcon } from '@konta/icons';
import WorkflowsLayout from '@components/WorkflowsLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import AccumulatedEntriesLineSeriesByRegimes from 'shared/components/AccumulatedEntriesLineSeriesByRegimes';
import useNextPhase from '@hooks/useNextPhase';
import convertNumberToWords from '@util/convertNumberToWords';
import * as chat from '@util/chat';
import useIncomesByRegime from './useIncomesByRegime';
import { Header, Title, Root, Subtitle, SubtitleHighlight } from '../styled';
import EditIncomes from '../EditIncomes';

import { Alert, AlertText, AlertContent, AlertDescription } from './styled';

export default function IncomesByRegime() {
  const {
    isLoading,
    showEditIncomes,
    toggleShowEditIncomes,
    currentRegimes,
    workflow,
    totalDeclarationIncomesFormatted,
    isConstancyExeeded,
  } = useIncomesByRegime();
  const nextPhase = useNextPhase();
  const [modifyIncomes, setModifyIncomes] = useState([1]);

  useEffect(() => {
    if (isConstancyExeeded) {
      setModifyIncomes([2]);
    }
  }, [isConstancyExeeded]);

  const month = dayjs(workflow?.start_date).format('MMMM [de] YYYY');

  const handleShowEditIncomes = () => {
    if (modifyIncomes[0] === 2) {
      toggleShowEditIncomes();
      return;
    }
    nextPhase({
      workflow: {
        next_phase: 'primary_phase',
      },
    });
  };

  return (
    <WorkflowsLayout
      isLoading={isLoading}
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton
            rightIcon={<ArrowRightLineIcon />}
            onClick={handleShowEditIncomes}
          />
        </>
      }
      title="Declaración mensual"
    >
      <Root>
        <Header>
          <Title>
            {currentRegimes.length > 1
              ? `¿Estos son los totales de ingresos en tus ${convertNumberToWords(
                  currentRegimes.length,
                )} regímenes?`
              : '¿Este es el total de ingresos en uno de tus regímenes?'}
          </Title>
          <Flex>
            <Subtitle>
              ¡Excelente! 🚀 Tus ingresos totales del mes de {month} fueron
            </Subtitle>
            &nbsp;
            <SubtitleHighlight>
              {totalDeclarationIncomesFormatted} pesos + IVA.
            </SubtitleHighlight>
          </Flex>
        </Header>

        <AccumulatedEntriesLineSeriesByRegimes
          workflow={workflow}
          fiscalRegimes={currentRegimes}
          type="incomes"
        />
      </Root>
      <Flex
        css={{
          width: '50%',
          flexDirection: 'column',
          gap: '$12',
        }}
      >
        <OptionsSelect value={modifyIncomes} onChange={setModifyIncomes}>
          <CardOption
            disabled={isConstancyExeeded}
            value={1}
            header={
              <span data-testid="has-no-income-option">
                Si, mis ingresos son correctos.
              </span>
            }
          />
          <CardOption
            value={2}
            header={
              <span data-testid="has-income-option">
                No, quiero modificar o revisar mis ingresos.
              </span>
            }
          />
        </OptionsSelect>
      </Flex>
      {isConstancyExeeded && (
        <Alert mt2>
          <InfoCircleLineIcon />
          <AlertContent>
            <AlertText>
              Los ingresos exceden a tu Constancia de Retención
            </AlertText>
            <AlertDescription>
              Tus ingresos son mayores a los de tu Constancia de Retención, por
              lo que debes validar y clasificar tus ingresos junto con tu
              contador para que todo esté en orden.
            </AlertDescription>
            <Button variant="outlined" onClick={chat.open}>
              Hablar con un agente
            </Button>
          </AlertContent>
        </Alert>
      )}
      <EditIncomes
        showEditIncomes={showEditIncomes}
        toggleShowEditIncomes={toggleShowEditIncomes}
      />
    </WorkflowsLayout>
  );
}
