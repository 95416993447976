import api from '@api';
import {
  annualDeduction as mockedDemoDataAnnualDeduction,
  taxableEntityFlow as mockedDemoDataTaxableEntityFlows,
  taxDelays as mockedDemoDataTaxDelaysHeatMap,
  kontaScore as mockedDemoDataKontaScore,
  monthlyAccumulatedIncomesAndExpenses as mockedDemoDataMonthlyAccumulatedIncomesAndExpenses,
} from '@constants/mockedDemoData';
import { AxiosResponse } from 'axios';

const MOCKED_ENDPOINTS: Record<string, unknown> = {
  annual_deduction: mockedDemoDataAnnualDeduction,
  taxable_entity_flows: mockedDemoDataTaxableEntityFlows,
  'tax_delays/heat_map': mockedDemoDataTaxDelaysHeatMap,
  konta_score: mockedDemoDataKontaScore,
  monthly_accumulated_incomes_and_expenses:
    mockedDemoDataMonthlyAccumulatedIncomesAndExpenses,
};

let enabled = false;
api.interceptors.request.use((config) => {
  if (enabled) {
    const endpoint = Object.keys(MOCKED_ENDPOINTS).find((key) =>
      config.url?.includes(key),
    );

    if (endpoint && config.method?.toLowerCase() === 'get') {
      const mockResponse: AxiosResponse = {
        data: MOCKED_ENDPOINTS[endpoint],
        status: 200,
        statusText: 'OK',
        headers: config.headers as Record<string, string>,
        config,
        request: {
          responseURL: config.url,
          status: 200,
          statusText: 'OK',
          headers: config.headers as Record<string, string>,
        },
      };

      return {
        ...config,
        adapter: () => Promise.resolve(mockResponse),
      };
    }
  }

  return config;
});

const setupMockInterceptor = (mockInterceptorEnabled: boolean) => {
  enabled = mockInterceptorEnabled;
};

export default setupMockInterceptor;
