import React, { useState } from 'react';
import ProcessStepHelpFeature from '@components/ProcessStepHelpFeature';
import getHelpFeatureCss from './util/getHelpFeatureCss';

const INITIAL_FOCUSED_ELEMENT = {
  element: null,
  helpFeature: null,
};

export default function useOnboardingHelpFeature({ helpFeatures = {} }) {
  const [focusedElement, setFocusedElement] = useState(INITIAL_FOCUSED_ELEMENT);
  const [helpFeature, setHelpFeature] = useState(true);

  const removeHelpFeature = (e) => {
    if (e.type === 'blur') {
      setFocusedElement(INITIAL_FOCUSED_ELEMENT);
      setHelpFeature(true);
      return;
    }
    if (focusedElement.element !== document.activeElement) {
      setHelpFeature(true);
      return;
    }
    if (focusedElement.helpFeature) {
      setHelpFeature(focusedElement.helpFeature);
    }
  };
  const addHelpFeature = (e, key) => {
    const feature = (
      <ProcessStepHelpFeature
        css={getHelpFeatureCss(e)}
        title={helpFeatures[key]?.title || 'Título'}
        description={helpFeatures[key]?.description || 'Descripción'}
      />
    );
    if (e.type === 'focus') {
      setFocusedElement({ element: e.target, helpFeature: feature });
    }
    setHelpFeature(feature);
  };

  return {
    currentHelpFeature: helpFeature,
    addHelpFeature,
    removeHelpFeature,
  };
}
