import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { contactMethodPostSuccess, contactMethodPostFailure } from '../actions';

export default function* ({ payload }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const contact_method = payload;
    // Request
    const response = yield call(api.post, `contact_methods`, contact_method, {
      headers: { Authorization: `${access_token}` },
    });
    yield call(StatusHandler, {
      response,
      title:
        response.data.contact_type === 'email'
          ? 'Email añadido'
          : response.data.contact_type === 'phone_number'
          ? 'Teléfono añadido'
          : '',
      message: 'Se ha agregado exitosamente',
      status: response.status,
      success: contactMethodPostSuccess,
      failure: contactMethodPostFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: contactMethodPostFailure,
    });
  }
}
