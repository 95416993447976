import { styled } from '@konta/ui';

export const modalHeaderCss = {
  fontWeight: '500',
  fontSize: '18px',
  color: '$gray900',
  mr: '0!important',
  justifyContent: 'space-between',
  mb: '$16',
};

export const Title = styled('span', {
  fontFamily: 'Inter',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '38px',
});

export const TopBar = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$8',
  flexDirection: 'column',
  '@md': {
    flexDirection: 'row',
  },
});

export const MetricWrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '34px',
});
