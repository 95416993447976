/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Card, CardBody, Alert } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import DropzoneComponent from 'react-dropzone-component';
import { Colxx, SimpleStateButton, Locked } from '@components';
import {
  uploadTaxableEntityFiel,
  uploadTaxableEntityPayrollCredentials,
} from '@redux/actions';
import { ReactComponent as FielUpload } from '@assets/img/fiel_upload_v2.svg';
import { NotificationManager } from '@components/Notifications';
import 'dropzone/dist/min/dropzone.min.css';

const ReactDOMServer = require('react-dom/server');

const dropzoneConfig = {
  autoProcessQueue: false,
  thumbnailHeight: 160,
  maxFilesize: 2,
  maxFiles: 1,
  dictInvalidFileType: 'No puedes subir archivos de este tipo.',
  dictMaxFilesExceeded: 'Ya no puedes subir más archivos.',
};

const dropzoneComponentConfig = {
  postUrl: 'no-url',
};

const cerDzConfig = {
  ...dropzoneConfig,
  acceptedFiles: '.cer',
  dictDefaultMessage: 'Arrastra tu cer aquí o dé click en esta área',
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div
      style={{ maxWidth: '90%' }}
      className="dz-preview dz-file-preview mb-3"
    >
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            <img alt="" data-dz-thumbnail className="img-thumbnail border-0" />
            <i className="iconsmind-Diploma-2 preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            <span data-dz-name />
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#" className="remove" data-dz-remove>
        <i className="glyph-icon simple-icon-trash" />
      </a>
    </div>
  ),
};

const keyDzConfig = {
  ...dropzoneConfig,
  acceptedFiles: '.key',
  dictDefaultMessage: 'Arrastra tu key aquí o dé click en esta área',
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div
      style={{ maxWidth: '90%' }}
      className="dz-preview dz-file-preview mb-3"
    >
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />{' '}
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            <img data-dz-thumbnail className="img-thumbnail border-0" alt="" />
            <i className="iconsmind-Key preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            <span data-dz-name />
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#" className="remove" data-dz-remove>
        <i className="glyph-icon simple-icon-trash" />
      </a>
    </div>
  ),
};

class FielUploadCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cerFile: {},
      keyFile: {},
      fiel_password: '',
      disableFielButton: true,
      dropzoneCer: null,
      dropzoneKey: null,
      status: 'pro',
      disabled: false,
    };
  }

  uploadFiel = (_, __) => {
    const { cerFile, keyFile, fiel_password } = this.state;
    const { activeTab, setActiveTab } = this.props;

    if (cerFile.accepted && keyFile.accepted) {
      if (
        Object.keys(cerFile).length > 0 &&
        Object.keys(keyFile).length > 0 &&
        fiel_password.length > 0
      ) {
        const formData = new FormData();
        formData.append('cer', cerFile);
        formData.append('key', keyFile);
        formData.append('fiel_password', fiel_password);
        this.props.payroll
          ? this.props.uploadTaxableEntityPayrollCredentials(formData)
          : this.props.uploadTaxableEntityFiel(formData);
        activeTab && setActiveTab(String(+activeTab + 1));
      }
    } else {
      NotificationManager.error(
        'Sube archivos con formatos .cer y .key',
        'Archivos inválidos',
        6000,
        null,
        null
      );
    }
  };

  handleCerUploadFile = (cerFile) => {
    this.setState({ cerFile }, () => {
      this.buttonDisableToggle();
    });
  };

  handleCerMaxFilesExceeded = (file) => {
    this.state.dropzoneCer.removeAllFiles(file);
    this.state.dropzoneCer.addFile(file);
  };

  handleKeyUploadFile = (keyFile) => {
    this.setState({ keyFile }, () => {
      this.buttonDisableToggle();
    });
  };

  handleKeyMaxFilesExceeded = (file) => {
    this.state.dropzoneKey.removeAllFiles(file);
    this.state.dropzoneKey.addFile(file);
  };

  updateFielPassword = (e) => {
    this.setState({ fiel_password: e.target.value }, () => {
      this.buttonDisableToggle();
    });
  };

  buttonDisableToggle = () => {
    const { cerFile, keyFile, fiel_password } = this.state;
    const isEnabled =
      Object.keys(cerFile).length > 0 &&
      Object.keys(keyFile).length > 0 &&
      fiel_password.length > 0;
    this.setState({ disableFielButton: !isEnabled });
  };

  componentDidMount() {
    const { taxable_entity } = this.props;
    if (taxable_entity.has_fiel) this.setState({ disabled: true });
  }

  render() {
    const { loading, payroll, activeTab, setActiveTab } = this.props;

    const cerEventHandlers = {
      init: (dropzone) => {
        this.setState({ dropzoneCer: dropzone });
      },
      removedfile: (file) => {
        this.setState({ cerFile: {} });
        this.buttonDisableToggle();
      },
      addedfile: this.handleCerUploadFile,
      maxfilesexceeded: this.handleCerMaxFilesExceeded,
    };

    const keyEventHandlers = {
      init: (dropzone) => {
        this.setState({ dropzoneKey: dropzone });
      },
      removedfile: (file) => {
        this.setState({ keyFile: {} });
        this.buttonDisableToggle();
      },
      addedfile: this.handleKeyUploadFile,
      maxfilesexceeded: this.handleKeyMaxFilesExceeded,
    };

    const fielBody = (
      <Locked
        btn="CONTRATAR"
        url="/app/settings/plan"
        blocking={payroll}
        description="Tu cuenta aún no tiene contratado el servicio de nómina."
      >
        <Row className="d-flex justify-content-between2">
          <Colxx
            md={12}
            lg={4}
            className="mb-4 d-flex align-items-center justify-content-center"
          >
            <FielUpload className="w-60 w-lg-75 w-md-40 w-sm-50" />
          </Colxx>
          <Colxx md={12} lg={8}>
            <h2 className="mb-5">
              {payroll
                ? 'Sube las credenciales de NÓMINA'
                : 'Sube los archivos de tu FIEL (e.firma) para conectar Konta con el SAT'}
            </h2>
            {payroll && (
              <Alert color="secondary" className="mb-4 rounded-alert">
                Recuerda que las credenciales de nómina son las que sacaste en
                el IMSS.
              </Alert>
            )}
            <Row className="d-flex justify-content-between2">
              <Colxx md={6} xxs={12} className="mb-4">
                <>
                  <h5>Subir .CER</h5>
                  <p className="text-muted ">
                    Da clic en el recuadro de abajo para subir tu archivo .CER
                  </p>
                  <DropzoneComponent
                    eventHandlers={cerEventHandlers}
                    config={dropzoneComponentConfig}
                    djsConfig={cerDzConfig}
                  />
                </>
              </Colxx>
              <Colxx md={6} xxs={12} className="mb-4">
                <>
                  <h5>Subir .KEY</h5>
                  <p className="text-muted ">
                    Da clic en el recuadro de abajo para subir tu archivo .KEY
                  </p>
                  <DropzoneComponent
                    eventHandlers={keyEventHandlers}
                    config={dropzoneComponentConfig}
                    djsConfig={keyDzConfig}
                  />
                </>
              </Colxx>
            </Row>
            <Row className="d-flex justify-content-start ">
              <Colxx md={12} xxs={12}>
                <h5>Contraseña</h5>
                <p className="text-muted ">Ingresa la contraseña de tu FIEL</p>
                <AvForm onValidSubmit={this.uploadFiel}>
                  <AvGroup>
                    <AvInput
                      name="fiel_password"
                      type="password"
                      onChange={this.updateFielPassword}
                    />
                  </AvGroup>
                </AvForm>
              </Colxx>
            </Row>
            <Row className="mb-4">
              {activeTab ? (
                <>
                  <Colxx md={6} className="text-right">
                    <SimpleStateButton
                      outline
                      onClick={() => {
                        setActiveTab(String(+activeTab + 1));
                      }}
                    >
                      Omitir
                    </SimpleStateButton>
                  </Colxx>
                  <Colxx md={6}>
                    <SimpleStateButton
                      onClick={this.uploadFiel}
                      loading={loading}
                      disabled={this.state.disableFielButton}
                    >
                      Subir FIEL
                    </SimpleStateButton>
                  </Colxx>
                </>
              ) : (
                <Colxx
                  className="d-flex align-content-center justify-content-center"
                  md={12}
                >
                  <SimpleStateButton
                    onClick={this.uploadFiel}
                    loading={loading}
                    disabled={this.state.disableFielButton}
                  >
                    Subir FIEL
                  </SimpleStateButton>
                </Colxx>
              )}
            </Row>
          </Colxx>
        </Row>
      </Locked>
    );
    return (
      <>
        {activeTab ? (
          fielBody
        ) : (
          <Card>
            <CardBody>{fielBody}</CardBody>
          </Card>
        )}
      </>
    );
  }
}

FielUploadCard.propTypes = {
  loading: PropTypes.bool,
  payroll: PropTypes.bool,
  taxable_entity: PropTypes.object,
  uploadTaxableEntityFiel: PropTypes.func,
  uploadTaxableEntityPayrollCredentials: PropTypes.func,
};

const mapStateToProps = ({ taxableEntity }) => ({ ...taxableEntity });

export default connect(mapStateToProps, {
  uploadTaxableEntityFiel,
  uploadTaxableEntityPayrollCredentials,
})(FielUploadCard);
