import React, { useMemo } from 'react';
import {
  Button,
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  Text,
} from '@konta/ui';
import { Eye2Icon } from '@konta/icons';
import { Collapse } from 'reactstrap';
import { useToggle } from 'rooks';
import useUpdateDeclaration from 'shared/hooks/useUpdateDeclaration';
import getErrorMessage from '@util/getErrorMessage';
import useUser from '@hooks/useUser';
import { NotificationManager } from '@components';
import WorkflowLayout from '@components/WorkflowLayout';
import WorkflowHeader from '@components/WorkflowHeader';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import useActiveDeclarationIncome from '@hooks/useActiveDeclarationIncome';
import toCurrency from '@util/toCurrency';
import SecondaryPhaseButton from '@components/SecondaryPhaseButton';
import useNextPhase from '@hooks/useNextPhase';
import { useSelector } from 'react-redux';

export default function InvoicesIncomeVerification() {
  const [showDetails, toggleShowDetails] = useToggle(false);
  const {
    selected_workflow: { workflow },
  } = useSelector(({ workflows }) => workflows);
  const { user } = useUser();
  const updateDeclarationMutation = useUpdateDeclaration({
    headers: {
      Authorization: user?.access_token || '',
    },
  });
  const nextPhase = useNextPhase();

  const {
    totalBasesOfCfdis,
    totalIvaOfCfdis,
    totalRetainedOfCfdis,
    totalIvaBasesOfCfdis,
    totalIvaExemptBasesOfCfdis,
    totalZeroBasesOfCfdis,
  } = useActiveDeclarationIncome();

  const rows = useMemo(
    () => [
      {
        description: 'Total de ingresos',
        base: totalBasesOfCfdis,
        tax: totalIvaOfCfdis,
        retentions: totalRetainedOfCfdis,
        total: totalBasesOfCfdis + totalIvaOfCfdis - totalRetainedOfCfdis,
        bold: true,
      },
      {
        description: 'Ingresos con IVA al 16% o al 8%',
        base: totalIvaBasesOfCfdis,
        tax: totalIvaOfCfdis,
        retentions: totalRetainedOfCfdis,
        total: totalIvaBasesOfCfdis + totalIvaOfCfdis - totalRetainedOfCfdis,
      },
      {
        description: 'Ingresos con IVA Exento',
        base: totalIvaExemptBasesOfCfdis,
        tax: 0,
        retentions: 0,
        total: totalIvaExemptBasesOfCfdis,
      },
      {
        description: 'Ingresos con IVA al 0%',
        base: totalZeroBasesOfCfdis,
        tax: 0,
        retentions: 0,
        total: totalZeroBasesOfCfdis,
      },
    ],
    [
      totalBasesOfCfdis,
      totalIvaBasesOfCfdis,
      totalIvaExemptBasesOfCfdis,
      totalIvaOfCfdis,
      totalRetainedOfCfdis,
      totalZeroBasesOfCfdis,
    ],
  );

  const activeDeclaration = workflow?.active_declaration;

  const handleOnSubmit = async () => {
    try {
      const declarationPayload = {
        declarationId: activeDeclaration?.id,
        payload: {
          declaration: {
            declaration_income_attributes: {
              source_of_income: 'invoice',
            },
          },
        },
      };
      await updateDeclarationMutation.mutateAsync(declarationPayload);
      nextPhase({
        workflow: {
          next_phase: 'primary_phase',
        },
      });
    } catch (error) {
      console.error(error);
      const errorMessage = getErrorMessage(error);
      NotificationManager.error(errorMessage, 'Error');
    }
  };

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader title="¿Este es el total de tus ingresos en el mes?" />
      }
      actions={
        <>
          <PrevPhaseButton />
          <SecondaryPhaseButton variant="contained">
            Tengo otros ingresos
          </SecondaryPhaseButton>
          <PrimaryPhaseButton
            onClick={handleOnSubmit}
            loading={updateDeclarationMutation.isLoading}
          >
            Continuar
          </PrimaryPhaseButton>
        </>
      }
    >
      <Text x2l>
        ¡Hemos descargado todas tus facturas de ventas directamente del SAT! 🙌🏼
        Encontramos ventas por {toCurrency(totalBasesOfCfdis)} antes de
        impuestos. Si este monto representa{' '}
        <strong>el 100% de tus ventas</strong> da click en
        &quot;Continuar&quot;, de lo contrario háznoslo saber dando click en
        &quot;Tengo otros otros ingresos&quot;. 🤓
      </Text>
      <div>
        <Button
          leftIcon={<Eye2Icon />}
          onClick={toggleShowDetails}
          variant="outlined"
        >
          {showDetails ? 'Ocultar ' : 'Ver '}
          desglose de ingresos facturados
        </Button>
      </div>
      <Collapse isOpen={showDetails}>
        <Table fullWidth>
          <TableHead>
            <Row transparent>
              <Cell />
              <Cell>BASE</Cell>
              <Cell>IMPUESTO</Cell>
              <Cell>RETENCIONES</Cell>
              <Cell>TOTAL</Cell>
            </Row>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row
                key={row.description}
                active={row.bold}
                css={{ fontWeight: row.bold ? '$bold' : '$medium' }}
              >
                <Cell>{row.description}</Cell>
                <Cell>{toCurrency(row.base)}</Cell>
                <Cell>{toCurrency(row.tax)}</Cell>
                <Cell>{toCurrency(row.retentions)}</Cell>
                <Cell>{toCurrency(row.total)}</Cell>
              </Row>
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </WorkflowLayout>
  );
}
