import React, { Fragment, useMemo, useState } from 'react';
import PencilIcon from 'mdi-react/PencilIcon';
import { Divider, Flex, Text, Button } from '@konta/ui';
import clientToOption from '@util/clientToOption';
import FormikClientSelect from '@components/FormikClientSelect';
import ClientModalForm from '@components/ClientModalForm';
import styles from './NewInvoiceModal.module.scss';

/**
 * @param {{ invoice: import('@hooks/useInvoiceForm').UseInvoice }} param0
 */
export default function InvoiceClient({ invoice }) {
  const client = invoice.formik.values?.client?.value ?? null;
  const [showModal, setShowModal] = useState(null);
  const details = useMemo(() => {
    if (!client) return null;

    return [
      { key: 'Razón social', value: client.legal_name },
      { key: 'RFC', value: client.rfc },
      client.email && { key: 'Email', value: client.email },
      client.address?.postcode && {
        key: 'Código postal',
        value: client.address.postcode,
      },
    ].filter(Boolean);
  }, [client]);

  return (
    <Flex
      direction="column"
      gap="4"
      css={{ marginTop: '0px', marginBottom: '28px' }}
    >
      <div>
        <Text xl bold color="black">
          Tu cliente 😎
        </Text>
        <Divider css={{ my: '4px' }} />
      </div>

      {client ? (
        <>
          <Text sm color="black" medium>
            Datos del receptor
          </Text>
          <div className={styles.detailsTable}>
            {details.map(({ key, value }) => (
              <Fragment key={key}>
                <Text color="gray500">{key}</Text>
                <Text color="gray500">{value}</Text>
              </Fragment>
            ))}
          </div>
          <Flex gap="6">
            <Button
              variant="minimal"
              color="primary"
              onClick={() => setShowModal(true)}
              leftIcon={<PencilIcon />}
            >
              Ver o actualizar datos
            </Button>
            <Button variant="outlined" onClick={() => invoice.setClient(null)}>
              Cambiar de cliente
            </Button>
          </Flex>
        </>
      ) : (
        <Flex>
          <FormikClientSelect
            newDesign
            name="client"
            css={{ width: '450px' }}
            onChange={invoice.setClient}
          />
        </Flex>
      )}

      <ClientModalForm
        isOpen={showModal && !!client}
        client={client}
        toggle={() => setShowModal(false)}
        onSubmit={(upsertedClient) => {
          invoice.setClient(clientToOption(upsertedClient));
          setShowModal(false);
        }}
      />
    </Flex>
  );
}
