import { Flex, Text } from '@konta/ui';
import type { HeaderContext } from '@tanstack/react-table';
import type { DeclarationEntry } from 'types/entities';
import { headerCss } from './styled';

interface CurrencyHeaderProps {
  name: string;
}

export function CurrencyHeader({ name }: CurrencyHeaderProps) {
  return (
    <Flex justify="end">
      <Text css={headerCss}>{name}</Text>
    </Flex>
  );
}

export const getCurrencyHeader = (name: string) =>
  function Component(_: HeaderContext<DeclarationEntry, unknown>) {
    return <CurrencyHeader name={name} />;
  };
