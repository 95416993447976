import React from 'react';
import { Button } from '@konta/ui';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import PropTypes from 'prop-types';
import { ReactComponent as TwitterIcon } from './img/twitter-icon.svg';
import { ReactComponent as FacebookIcon } from './img/facebook-icon.svg';
import { ReactComponent as LinkedinIcon } from './img/linkedin-icon.svg';

function SocialBtn({ icon: Icon, ...props }) {
  return (
    <Button
      icon
      variant="outlined"
      css={{
        span: { display: 'flex' },
        borderRadius: '8px',
      }}
      {...props}
    >
      <Icon />
    </Button>
  );
}

export default function ShareSocialNetworkLinks({ shareUrl }) {
  return (
    <>
      <TwitterShareButton url={shareUrl} title="Twitter">
        <SocialBtn icon={TwitterIcon} />
      </TwitterShareButton>
      <FacebookShareButton url={shareUrl} title="Facebook">
        <SocialBtn icon={FacebookIcon} />
      </FacebookShareButton>
      <LinkedinShareButton url={shareUrl} title="Linkedin">
        <SocialBtn icon={LinkedinIcon} />
      </LinkedinShareButton>
    </>
  );
}

ShareSocialNetworkLinks.propTypes = {
  shareUrl: PropTypes.string.isRequired,
};
