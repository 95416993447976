import { styled } from '@konta/ui';

const Main = styled('div', {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0',
  background: '$white',
  height: '100%',
  variants: {
    blockUi: {
      true: {
        pointerEvents: 'none',
        userSelect: 'none',
        opacity: 0.5,
        filter: 'blur(3px)',
      },
    },
    isDemo: {
      true: {
        '& *': {
          pointerEvents: 'none',
          userSelect: 'none',
        },
        pointerEvents: 'auto',
        opacity: 1,
        filter: 'none',

        '&[data-block-ui="true"]': {
          opacity: 0.9,
          filter: 'blur(3px)',
        },
      },
    },
  },
});

export default Main;
