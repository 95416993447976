import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import HomeContainer from './home-container';

export default function Home() {
  return (
    <div className="app-container">
      <Routes>
        <Route path="/" element={<HomeContainer />} />
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
    </div>
  );
}
