import React, { Component } from 'react';
import IntlMessages from '@util/IntlMessages';
import { Row, Card, CardTitle } from 'reactstrap';
import { NavLink, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { AvGroup, AvField, AvForm } from 'availity-reactstrap-validation';

import { NotificationManager } from '@components/Notifications';

import { Colxx } from '@components/CustomBootstrap';
import { SimpleStateButton } from '@components/Buttons';

import { connect } from 'react-redux';

import { changePassword } from '@redux/actions';

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      password_confirmation: '',
      token: null,
      redirect: false,
    };
  }

  componentDidMount() {
    const token = this.getParameterByName('reset_password_token');
    if (token) this.setState({ token });
    else this.setState({ redirect: true });
  }

  getParameterByName = (name) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(this.props.location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  handleLoginPasswordInputChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLoginConfirmPasswordInputChange(e) {
    this.setState({
      password_confirmation: e.target.value,
    });
  }

  onUserRegister = () => {
    const { password, password_confirmation, token } = this.state;
    if (
      this.state.password !== '' &&
      this.state.password_confirmation !== '' &&
      this.state.password_confirmation === this.state.password
    ) {
      const user = {
        user: {
          reset_password_token: token,
          password,
          password_confirmation,
        },
      };

      this.props.changePassword(user, this.props.navigate);
    } else {
      NotificationManager.error(
        'Verifique que los datos sean correctos e intente de nuevo.',
        'Upss..',
        6000,
        null,
        null
      );
    }
  };

  handleSubmit = (event, values, errors) => {
    if (values.length > 0) {
      NotificationManager.error(
        'Verifique que los datos sean correctos e intente de nuevo.',
        'Upss..',
        6000,
        null,
        null
      );
    }
  };

  render() {
    const { loading } = this.props;
    if (this.state.redirect)
      return (
        <Navigate
          to={{
            pathname: '/login',
            state: { from: this.props.location },
          }}
        />
      );
    return (
      <main className="main-center-container px-3 px-md-1">
        <div className="fixed-background" />
        <Row className="h-100">
          <Colxx xxs="12" md="5" className="mx-auto my-auto">
            <Card className="auth-card">
              <div className="form-side register-form">
                <div className="d-flex justify-content-center align-items-center mb-5">
                  <NavLink to="/" className="white">
                    <span className="logo-single" />
                  </NavLink>
                </div>
                <CardTitle tag="h4" className="mb-4">
                  <IntlMessages id="user.change-password" />
                </CardTitle>
                <AvForm
                  className="login-left"
                  onSubmit={this.handleSubmit}
                  onValidSubmit={this.onUserRegister}
                >
                  <AvGroup>
                    <AvField
                      label="Nueva contraseña"
                      type="password"
                      name="password"
                      onChange={(e) => this.handleLoginPasswordInputChange(e)}
                      value={this.state.password}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Introduce tu contraseña',
                        },
                        minLength: {
                          value: 6,
                          errorMessage:
                            'La contraseña debe tener una longitud minima de 6 caracteres',
                        },
                        pattern: {
                          value: '^(?=.*[0-9])(?=.*[a-zA-Z])(.*)$',
                          errorMessage:
                            'La contraseña debe contener numero y letras.',
                        },
                      }}
                    />
                  </AvGroup>
                  <AvGroup>
                    <AvField
                      label="Confirmar contraseña"
                      type="password"
                      name="password_confirmation"
                      onChange={(e) =>
                        this.handleLoginConfirmPasswordInputChange(e)
                      }
                      value={this.state.password_confirmation}
                      validate={{
                        required: {
                          value: true,
                          errorMessage:
                            'Ingresa la confirmación de la contraseña',
                        },
                      }}
                    />
                  </AvGroup>
                  <div className="d-flex flex-wrap flex-column align-items-center flex-md-row justify-content-md-between align-items-md-center">
                    <div className="login-link">
                      <NavLink to="/login">
                        <IntlMessages id="user.login-title" />
                      </NavLink>
                      <br />
                      {/* <NavLink to={`/forgot-password`}>
                          <IntlMessages id="user.forgot-password-question" />
                        </NavLink> */}
                    </div>
                    <SimpleStateButton
                      className="btn-shadow"
                      color="primary"
                      size="lg"
                      loading={loading}
                    >
                      <IntlMessages id="user.change-password" />
                    </SimpleStateButton>
                  </div>
                </AvForm>
              </div>
            </Card>
          </Colxx>
        </Row>
      </main>
    );
  }
}

function NewPasswordHooks(props) {
  const navigate = useNavigate();
  const location = useLocation();

  return <NewPassword {...props} navigate={navigate} location={location} />;
}

export default connect(null, {
  changePassword,
})(NewPasswordHooks);
