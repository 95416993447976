export const DOCUMENT_CATEGORIES = [
  {
    label: 'Documentos fiscales',
    value: 'legal_documents',
    key: 2,
  },
  {
    label: 'Constancía de retención',
    value: 'retention_certificate',
    key: 4,
  },
  {
    label: 'Pedimentos de importación',
    value: 'import_pediments',
    key: 5,
  },
  { label: 'Facturas del extranjero', value: 'abroad_billings', key: 6 },
  { label: 'Otros', value: 'others', key: 7 },
];
