import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { fetchUser } from '@redux/actions';
import withAuth from '@hocs/withAuth';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import PreOnboarding from './PreOnboarding';

function Onboarding({ user, access_token }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const featureClient = useLDClient();

  useEffect(() => {
    if (!user && access_token) {
      dispatch(fetchUser(navigate, featureClient));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <div className="loading" />;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="start" />} />
      <Route path="start" element={<PreOnboarding />} />
      <Route path="*" element={<Navigate to="/error" />} />
    </Routes>
  );
}
const mapStateToProps = ({ authUser: { user, access_token } }) => ({
  user,
  access_token,
});

export default compose(
  withAuth,
  connect(mapStateToProps, { fetchUser }),
)(Onboarding);
