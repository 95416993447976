import {
  PULSE_FETCH_UNCATEGORIZED_INVOICES,
  PULSE_FETCH_UNCATEGORIZED_INVOICES_SUCCESS,
  PULSE_FETCH_UNCATEGORIZED_INVOICES_FAILURE,
  PULSE_CATEGORIZE_INVOICE,
  PULSE_CATEGORIZE_INVOICE_SUCCESS,
  PULSE_CATEGORIZE_INVOICE_FAILURE,
  PULSE_ADD_STATEMENT_END,
  PULSE_ADD_STATEMENT_BEGIN,
  PULSE_ADD_STATEMENT_SUCCESS,
  PULSE_ADD_STATEMENT_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  fetchLoading: false,
  fetchError: false,
  fetchSuccess: false,
  uncategorizedInvoices: [],

  categorizeLoading: false,
  categorizeSuccess: false,
  categorizeError: false,
  updateDescLoading: false,
  updateDescFailure: false,
  updateDescSuccess: false,
  addStatementLoading: false,
  addStatementFailure: false,
  addStatementSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PULSE_FETCH_UNCATEGORIZED_INVOICES:
      return {
        ...state,
        fetchLoading: true,
        fetchError: false,
        fetchSuccess: false,
      };
    case PULSE_FETCH_UNCATEGORIZED_INVOICES_SUCCESS:
      return {
        ...state,
        uncategorizedInvoices: action.payload,
        fetchLoading: false,
        fetchSuccess: true,
        fetchError: false,
      };
    case PULSE_FETCH_UNCATEGORIZED_INVOICES_FAILURE:
      return {
        ...state,
        uncategorizedInvoices: [],
        fetchLoading: false,
        fetchSuccess: false,
        fetchError: true,
      };

    case PULSE_CATEGORIZE_INVOICE:
      return { ...state, fetchLoading: true };
    case PULSE_CATEGORIZE_INVOICE_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        fetchSuccess: true,
        fetchError: false,
        uncategorizedInvoices: action.payload,
      };
    case PULSE_CATEGORIZE_INVOICE_FAILURE:
      return {
        ...state,
        fetchLoading: false,
        fetchSuccess: false,
        fetchError: true,
      };

    case PULSE_ADD_STATEMENT_BEGIN:
      return { ...state, addStatementLoading: true };
    case PULSE_ADD_STATEMENT_FAILURE:
      return {
        ...state,
        addStatementLoading: false,
        addStatementFailure: true,
      };
    case PULSE_ADD_STATEMENT_SUCCESS:
      return {
        ...state,
        addStatementLoading: false,
        addStatementSuccess: true,
      };
    case PULSE_ADD_STATEMENT_END:
      return {
        ...state,
        addStatementLoading: false,
        addStatementFailure: false,
        addStatementSuccess: false,
      };
    default:
      return { ...state };
  }
};
