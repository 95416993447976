import { Dayjs } from 'dayjs';
import { CreditCard02LineIcon, User01LineIcon } from '@konta/icons';
import { Flex, Text } from '@konta/ui';
import Collapse from 'shared/components/CollapseSection/Collapse';
import toCurrency from '@util/toCurrency';
import type { Workflow } from 'types/entities';
import useEntriesCollapseTable from './useEntriesCollapseTable';
import EntriesGroupedTable from '../EntriesGrupedTable';
import EntriesTable from '../EntriesTable';

export interface EntriesCollapseTableProps {
  workflow: Workflow;
  type: 'incomes' | 'expenses';
  isGrouped: boolean;
  currentDate?: Dayjs;
  setModalInvoiceDetails: (value: {
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  }) => void;
}

export default function EntriesCollapseTable({
  workflow,
  type,
  isGrouped,
  currentDate,
  setModalInvoiceDetails,
}: EntriesCollapseTableProps) {
  const {
    title,
    showEntriesTable,
    toggleShowEntriesTable,
    declarationEntries,
    declarationEntriesLoading,
    totals,
    groupedDeclarationEntries,
  } = useEntriesCollapseTable({
    workflow,
    type,
    currentDate,
  });
  return (
    <Collapse
      btnLeftIcon={
        isGrouped ? (
          <User01LineIcon color="#667085" />
        ) : (
          <CreditCard02LineIcon color="#667085" />
        )
      }
      btnText={<Text color="gray500">{title}</Text>}
      controls={[showEntriesTable, toggleShowEntriesTable]}
      rightText={
        <>
          {isGrouped && groupedDeclarationEntries.length ? (
            <Text medium color="gray500">
              {groupedDeclarationEntries.length} Clientes
            </Text>
          ) : (
            <Text medium color="gray500">
              {totals.transactionsCount} Transacciones
            </Text>
          )}

          <Text medium color="gray500">
            Total:{' '}
            <Text color="primary700">
              {toCurrency(totals.declarationsGrandTotal)} MXN
            </Text>
          </Text>
        </>
      }
    >
      <Flex column css={{ width: '100%' }}>
        {isGrouped ? (
          <EntriesGroupedTable
            groupedEntries={groupedDeclarationEntries}
            declarationEntriesLoading={declarationEntriesLoading}
            type={type}
            workflowId={workflow.id}
            setModalInvoiceDetails={setModalInvoiceDetails}
            total={totals.declarationsGrandTotal}
          />
        ) : (
          <EntriesTable
            setModalInvoiceDetails={setModalInvoiceDetails}
            declarationEntries={declarationEntries}
            declarationEntriesLoading={declarationEntriesLoading}
            type={type}
            workflowId={workflow.id}
            total={totals.declarationsGrandTotal}
          />
        )}
      </Flex>
    </Collapse>
  );
}
