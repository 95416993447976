import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Row, Label } from 'reactstrap';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { Colxx } from '@components/CustomBootstrap';
import { NotificationManager } from '@components';
import { updateTaxableEntity, uploadTaxableEntityFiel } from '@redux/actions';
import { ReactComponent as SercuritySvg } from '@assets/img/sercurity.svg';
import { rfcPattern } from '@constants/regex';

// import { statementUpload } from '@redux/actions'

function SimpleCiecUpload({ taxable_entity, loading }) {
  const dispatch = useDispatch();

  const uploadCiec = (event, values, errors) => {
    const regex = new RegExp(
      '^([A-ZÑ]|&)?[A-ZÑ]{3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$',
    );
    if (!regex.test(values.rfc)) {
      NotificationManager.error(
        'El RFC es incorrecto, favor de verificar formato',
        'Upss..',
        4000,
        null,
        null,
        null,
      );
      return;
    }
    if (values.ciec.length > 0) {
      const body = {
        ciec: values.ciec,
        rfc: values.rfc,
        has_ciec: true,
      };
      dispatch(updateTaxableEntity(body, taxable_entity.id));
    }
  };

  return (
    <Row className="p-5">
      <Colxx xxs={12} md={8} className="mb-4">
        <AvForm onValidSubmit={uploadCiec} model={taxable_entity}>
          <Row className="mb-2">
            <Colxx xxs={12}>
              <p>
                Ingresa tu RFC con tu contraseña CIEC del SAT. Es importante que
                cuentes con esta credencial para poder comenzar con el cálculo
                de tus impuestos.
              </p>
            </Colxx>
            <Colxx xxs={12} md={8}>
              <AvGroup>
                <Label for="rfc">RFC</Label>
                <AvField
                  name="rfc"
                  type="search"
                  disabled={!!taxable_entity.rfc}
                  autoComplete="off"
                  validate={{
                    required: {
                      value: true,
                      errorMessage:
                        'Porfavor ingrese un RFC con formato válido. Ejemplo: XAX010101000',
                    },
                    pattern: {
                      value: rfcPattern,
                      errorMessage:
                        'Porfavor ingrese un RFC con formato válido. Ejemplo: XAX010101000',
                    },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <AvField
                  name="ciec"
                  label="Contraseña"
                  autoComplete="off"
                  type="password"
                  validate={{
                    minLength: {
                      value: '8',
                      errorMessage: 'Mínimo 8 caracteres',
                    },
                    required: {
                      value: true,
                      errorMessage: 'Campo requerido',
                    },
                  }}
                />
              </AvGroup>
            </Colxx>
          </Row>
          <Row>
            <Colxx xxs={12} md={4}>
              <Button color="primary" size="sm" disabled={loading}>
                {taxable_entity.has_ciec
                  ? 'Actualizar y continuar'
                  : 'Guardar y continuar'}
              </Button>
            </Colxx>
          </Row>
        </AvForm>
      </Colxx>
      <Colxx
        xxs={12}
        md={4}
        className="d-none d-md-flex align-items-center justify-content-center"
      >
        <SercuritySvg className="mr-md-5 w-100" />
      </Colxx>
    </Row>
  );
}

const mapStateToProps = ({ taxableEntity }) => {
  const { taxable_entity, error, loading } = taxableEntity;
  return { taxable_entity, error, loading };
};
export default connect(mapStateToProps, {
  updateTaxableEntity,
  uploadTaxableEntityFiel,
})(SimpleCiecUpload);
