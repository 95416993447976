import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { CustomerProvider } from 'backoffice/modules/customer/Customer/Customer';
import type { IRootState } from 'types/redux';
import type { TaxableEntity } from 'types/entities';

const getTaxableEntityId = (taxableEntity: TaxableEntity | null) =>
  taxableEntity?.id || 0;
const getUserAccessToken = (taxableEntity: TaxableEntity | null) =>
  taxableEntity?.user?.access_token || '';

export default function useSharedTaxableEntity() {
  const taxableEntityFromContext = useContext(CustomerProvider);
  try {
    const taxableEntity = useSelector(
      (state: IRootState) => state.taxableEntity.taxable_entity,
    );
    return {
      taxableEntityId: getTaxableEntityId(taxableEntity),
      userAccessToken: getUserAccessToken(taxableEntity),
      taxableEntity,
    };
  } catch (e) {
    return {
      taxableEntityId: getTaxableEntityId(taxableEntityFromContext),
      userAccessToken: getUserAccessToken(taxableEntityFromContext),
      taxableEntity: taxableEntityFromContext,
    };
  }
}
