import React from 'react';
import { useToggle } from 'rooks';
import { FileAddInLc1OutlineIcon } from '@konta/icons';
import { Button } from '@konta/ui';
import ForeignProductModalForm from './ForeignProductModalForm';
import ForeignInvoiceModalForm from './ForeignInvoiceModalForm';

export default function NewForeignDeclarationEntryButton({
  type,
  isAnnualProcess,
}) {
  const [visible, toggleVisible] = useToggle(false);
  const isForeign = type === 'foreign';
  return (
    <>
      <Button
        color={isForeign ? 'gray' : 'primary'}
        onClick={toggleVisible}
        leftIcon={<FileAddInLc1OutlineIcon />}
      >
        {`Crear ${
          isForeign ? 'factura en el extranjero' : 'pedimento de importación'
        }`}
      </Button>
      {type === 'foreign' ? (
        <ForeignInvoiceModalForm
          isOpen={visible}
          onClose={toggleVisible}
          onSubmit={toggleVisible}
          isAnnualProcess={isAnnualProcess}
        />
      ) : (
        <ForeignProductModalForm
          isOpen={visible}
          onClose={toggleVisible}
          onSubmit={toggleVisible}
          isAnnualProcess={isAnnualProcess}
        />
      )}
    </>
  );
}
