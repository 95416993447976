import { ComponentProps, ReactNode, Ref } from 'react';
import { Button, CSS, Flex } from '@konta/ui';
import { useToggle } from 'rooks';
import { ChevronRightLineIcon, ChevronDownLineIcon } from '@konta/icons';
import { Container, Controls, ExtraControls } from './styled';

type ButtonProps = ComponentProps<typeof Button>;
export interface CollapseSectionProps {
  children: ReactNode;
  btnText: ButtonProps['children'];
  btnLeftIcon?: ButtonProps['leftIcon'];
  collapsed?: boolean;
  controls?: ReturnType<typeof useToggle>;
  ref?: Ref<HTMLDivElement>;
  rightText?: ReactNode;
  rightTextCss?: CSS;
  extraControls?: ReactNode;
  badge?: ReactNode;
  cssContainer?: CSS;
  cssControls?: CSS;
  cssExtraControls?: CSS;
  hideExtraControls?: boolean;
  disabledExtraControls?: boolean;
  cssChildren?: CSS;
}

export default function Collapse({
  children,
  btnText,
  btnLeftIcon,
  collapsed = false,
  ref,
  controls,
  rightText,
  extraControls,
  badge,
  cssContainer,
  cssControls,
  cssExtraControls,
  hideExtraControls,
  disabledExtraControls,
  cssChildren = {},
  rightTextCss = {},
}: CollapseSectionProps) {
  const toggleControls = useToggle(collapsed);
  const [isCollapsed, toggleIsCollapsed] = controls || toggleControls;

  return (
    <Container ref={ref} css={cssContainer}>
      <Controls css={cssControls}>
        <Flex itemsStart gap={10} direction="column">
          <Button
            size="m"
            variant="text"
            noFill
            leftIcon={btnLeftIcon}
            rightIcon={
              isCollapsed ? (
                <ChevronDownLineIcon color="#667085" />
              ) : (
                <ChevronRightLineIcon color="#667085" />
              )
            }
            onClick={toggleIsCollapsed}
          >
            {btnText}
          </Button>
          {!!badge && badge}
        </Flex>
        <Flex css={rightTextCss} gap={6}>
          {!!rightText && rightText}
        </Flex>
      </Controls>
      {!!extraControls && (
        <ExtraControls
          css={{
            ...cssExtraControls,
            ...(disabledExtraControls && {
              opacity: 0.8,
              cursor: 'not-allowed',
              '& > *': {
                pointerEvents: disabledExtraControls ? 'none' : 'auto',
              },
            }),
            display: hideExtraControls && !isCollapsed ? 'none' : 'flex',
          }}
        >
          {extraControls}
        </ExtraControls>
      )}
      {!!isCollapsed && (
        <Flex column css={{ ...cssChildren, width: '100%', flex: 1 }}>
          {children}
        </Flex>
      )}
    </Container>
  );
}
