import { Flex } from '@konta/ui';
import Loader from '@components/Loader';
import FormikKontaSelect from '@components/FormikKontaSelect';
import { templatesOpts } from './form';
import useSettingsBrandForm from './useSettingsBrandForm';

type TemplateSettingsBrandProps = Pick<
  ReturnType<typeof useSettingsBrandForm>,
  'loadingUpdateTaxableEntityPreferences'
>;
export default function TemplateSettingsBrand({
  loadingUpdateTaxableEntityPreferences,
}: TemplateSettingsBrandProps) {
  return (
    <Flex direction="column">
      {loadingUpdateTaxableEntityPreferences ? (
        <Loader />
      ) : (
        <FormikKontaSelect
          name="template"
          label="Plantilla"
          css={{ width: '500px' }}
          options={templatesOpts}
          placeholder={undefined}
        />
      )}
    </Flex>
  );
}
