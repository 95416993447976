import * as subscriptionConstants from 'shared/constants/subscriptions';
import * as subscriptionUtils from 'shared/util/subscriptions';

const PLAN_PRICES = subscriptionUtils.getUpdatedPlanPrices(
  subscriptionConstants.BASE_PRICES,
  subscriptionConstants.DISCOUNTS,
);

const INFORMATION_TEXTS = subscriptionUtils.getInformationTexts(
  PLAN_PRICES,
  subscriptionConstants.BADGE_TEXT,
);

const subscriptionService = {
  constants: {
    ...subscriptionConstants,
    PLAN_PRICES,
    INFORMATION_TEXTS,
  },
  utils: subscriptionUtils,
};

export default subscriptionService;
