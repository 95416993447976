import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  PRODUCTS_FETCH,
  PRODUCTS_POST,
  PRODUCTS_EDIT,
  PRODUCTS_DELETE,
} from '@constants/actionTypes';
import fetchSaga from './fetch';
import postSaga from './post';
import editSaga from './edit';
import deleteSaga from './delete';

export function* watchFetch() {
  yield takeLatest(PRODUCTS_FETCH, fetchSaga);
}

export function* watchPost() {
  yield takeLatest(PRODUCTS_POST, postSaga);
}

export function* watchEdit() {
  yield takeLatest(PRODUCTS_EDIT, editSaga);
}

export function* watchDelete() {
  yield takeLatest(PRODUCTS_DELETE, deleteSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchPost),
    fork(watchFetch),
    fork(watchEdit),
    fork(watchDelete),
  ]);
}
