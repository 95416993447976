import { useState, useEffect, useCallback } from 'react';
import belvoWidgetConfig from '@util/belvoWidgetConfig';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import useScript from 'shared/hooks/useScript';
import { belvoLinksConnectionCreate } from '@redux/actions';
import { useToggle } from 'rooks';

const src = 'https://cdn.belvo.io/belvo-widget-1-stable.js';

// Hook
const useBelvoScript = () => {
  const dispatch = useDispatch();
  const belvoScriptStatus = useScript(src);
  const [newBank, setNewBank] = useState(false);
  const [open, setOpen] = useToggle(false);
  const {
    belvo_tokens: { widget_token },
    loading,
  } = useSelector(({ belvo }) => belvo);
  const {
    taxable_entity: { id: taxable_entity_id },
  } = useSelector((state) => state.taxableEntity, shallowEqual);

  const onSuccess = useCallback(
    (link, currentToken) => {
      const body = {
        link,
        belvo_token_id: currentToken,
      };
      dispatch(belvoLinksConnectionCreate(body));
    },
    [dispatch],
  );

  useEffect(
    () => {
      // Create script
      if (belvoScriptStatus === 'ready' && open && widget_token && !loading) {
        belvoWidgetConfig(
          widget_token.access,
          open,
          setOpen,
          newBank,
          setNewBank,
          taxable_entity_id,
          onSuccess,
        );
      }
    },
    [
      open,
      widget_token,
      setOpen,
      taxable_entity_id,
      newBank,
      belvoScriptStatus,
      loading,
      onSuccess,
    ], // Only re-run effect if script src changes
  );
  if (['error', 'idle'].includes(belvoScriptStatus)) {
    throw new Error(`Error loading Belvo script: ${belvoScriptStatus}`);
  }
  return {
    open,
    setOpen,
  };
};

export default useBelvoScript;
