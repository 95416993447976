import { TaxableEntityFlowStep } from 'types/flows';
import { utils } from 'shared/components/XyFlow';
import Loader from 'shared/components/Loader';
import { useInsightsDialog } from '@routes/app/InformationDashboard/Insights/InsightsDialogContext';
import { BodyContainer } from './styled';

interface DialogBodyProps {
  taxableEntityFlowStep: TaxableEntityFlowStep;
  isLoading?: boolean;
}

function DialogBody({
  taxableEntityFlowStep,
  isLoading = false,
}: DialogBodyProps): React.ReactElement {
  const { setIsOpen, setIsActiveDialog, setPreviewContainerCss } =
    useInsightsDialog();
  return (
    <BodyContainer stepType={taxableEntityFlowStep.flow_step.type}>
      {isLoading ? (
        <Loader />
      ) : (
        utils.getStepContent(taxableEntityFlowStep, {
          setIsActiveDialog,
          setIsOpen,
          setPreviewContainerCss,
        })
      )}
    </BodyContainer>
  );
}

export default DialogBody;
