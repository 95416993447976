import React from 'react';
import { Text, Flex, Button, Icon, CircularLoading, Box } from '@konta/ui';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { NotificationManager } from '@components';
import useSuggestedReferralsPagination from './useSuggestedReferralsPagination.ts';
import useInvitedReferralsPagination from './useInvitedReferralsPagination.ts';
import InviteSuggestedReferralButton from './InviteSuggestedRefferalButton';
import DiscardSuggestedReferralButton from './DiscardSuggestedReferralButton';

export default function SuggestedReferrals() {
  const { loading, items, hasNext, next, hasPrev, prev, refetch } =
    useSuggestedReferralsPagination();

  const invitedReferralsPagination = useInvitedReferralsPagination();

  const onInvite = async () => {
    await Promise.all([refetch(), invitedReferralsPagination.refetch()]);
    NotificationManager.success(
      'Invitación enviada exitosamente',
      'Referido',
      4000,
    );
  };

  const onDiscard = async () => {
    await refetch();
    NotificationManager.success('Sugerencia descartada', 'Referido', 4000);
  };

  return (
    <Flex column css={{ flex: '1' }}>
      <Flex column css={{ marginBottom: '$24' }}>
        <Text color="gray700" bold l>
          Recomendados
        </Text>
        <Text color="gray500" s>
          ¿A quién quieres recomendar?
        </Text>
      </Flex>
      {loading ? (
        <Flex justify="center">
          <CircularLoading xl />
        </Flex>
      ) : (
        <Flex css={{ overflowX: 'auto' }}>
          <Box css={{ flex: '1' }}>
            {items.map(({ id, email, phone_number }) => {
              return (
                <Flex
                  key={id}
                  justify="between"
                  gap={12}
                  css={{ marginBottom: '$16' }}
                >
                  <Text color="gray700" m>
                    {email || phone_number}
                  </Text>
                  <Flex gap={12}>
                    <DiscardSuggestedReferralButton
                      invitationId={id}
                      onDiscard={onDiscard}
                    />
                    <InviteSuggestedReferralButton
                      invitationId={id}
                      onInvite={onInvite}
                    />
                  </Flex>
                </Flex>
              );
            })}
          </Box>
        </Flex>
      )}
      <Flex justify="between">
        <Button icon variant="outlined" disabled={!hasPrev} onClick={prev}>
          <Icon css={{ display: 'flex' }}>
            <ChevronLeftIcon />
          </Icon>
        </Button>
        <Button icon variant="outlined" disabled={!hasNext} onClick={next}>
          <Icon css={{ display: 'flex' }}>
            <ChevronRightIcon />
          </Icon>
        </Button>
      </Flex>
    </Flex>
  );
}
