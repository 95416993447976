import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  fetchFinancialTransactionsSuccess,
  fetchFinancialTransactionsFailure,
} from '../actions';

export default function* ({ payload: { fiscal_period } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.get,
      `fiscal_periods/${fiscal_period.id}/financial_transactions`,
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      status: response.status,
      response,
      success: fetchFinancialTransactionsSuccess,
      failure: fetchFinancialTransactionsFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: fetchFinancialTransactionsFailure,
    });
  }
}
