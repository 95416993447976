import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { Box } from '@konta/ui';
import classNames from 'classnames';

export default function SidenavItem({ to, onClick, children, icon, counter }) {
  const location = useLocation();

  if (!location) return null;

  return (
    <NavLink to={to} onClick={onClick}>
      <span
        className={classNames('sidebar-nav-link-container', 'sidebar-text', {
          'sidebar-nav-link-active': location.pathname.endsWith(to),
        })}
      >
        {!!icon && icon}
        <div className="sidebar-nav-link">
          {children}

          {typeof counter === 'number' && (
            <div className="sidebar-nav-link-counter">{counter}</div>
          )}
        </div>
      </span>
    </NavLink>
  );
}

SidenavItem.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  icon: PropTypes.node,
  counter: PropTypes.number,
};

SidenavItem.defaultProps = {
  to: '#',
  onClick: null,
  children: null,
  icon: null,
  counter: null,
};
