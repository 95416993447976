import useWatchError from '@hooks/useWatchError';
import { useState, useEffect } from 'react';

const usePostCodeErrors = (callback) => {
  const [error, setError] = useState();

  useWatchError(setError);

  useEffect(() => {
    if (!error) {
      return;
    }
    const postCodeErrors = [
      "301 - XML mal formado. XML Validation: Required attribute 'LugarExpedicion' is missing. Location: /*:Comprobante[1] Detalle: 301",
      'CFDI40149',
      'DomicilioFiscalReceptor Detalle: 301',
      'CFDI40149',
      'Detalle: 902',
    ];
    if (
      postCodeErrors.some((errorItem) => {
        return error?.errorMessage?.includes?.(errorItem);
      })
    ) {
      callback(error);
    }
  }, [error, callback]);
};

export default usePostCodeErrors;
