import { useMemo } from 'react';
import { Column } from '@silevis/reactgrid';
import { generateMonthNames } from 'shared/util/grids';

const COL_WIDTH = 110;

export default function useSimplifiedColumns(): Column[] {
  const months = generateMonthNames().map((month) => ({
    columnId: month,
    width: COL_WIDTH,
  }));

  return useMemo(
    () => [
      {
        columnId: 'concepts',
        width: 200,
      },
      ...months,
      {
        columnId: 'accumulated',
        width: 120,
      },
    ],
    [months],
  );
}
