import {
  ProcessType,
  FinishOnboardingProcessStepType,
  FinishOnboardingProcessStepTypes,
  UpdatingOnboardingProcessStep,
  FinishOnboardingStep,
  FinishOnboardingProcessStep,
} from 'types/entities';

export default function getFinishOnboardingSteps(
  steps: FinishOnboardingProcessStep[],
): FinishOnboardingStep {
  const getStep = (type: FinishOnboardingProcessStepType) =>
    steps.find((step) => step.type === type);

  return {
    type: ProcessType.FinishOnboardingProcess,
    updating: getStep(
      FinishOnboardingProcessStepTypes.Updating,
    ) as UpdatingOnboardingProcessStep,
  };
}
