import { useMemo } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  declarationEntryExpenseTypes,
  declarationEntryIncomeTypes,
} from '@constants/declarationEntries';
import type { DeclarationEntry } from 'types/entities';
import { DECLARATION_ENTRIES_BY_WORKFLOW } from 'shared/constants/reactQueries';
import { getDeclarationEntriesByWorkflow } from 'shared/api/declarationEntries';

export default function useDeclarationEntriesByWorkflow(
  axiosConfig?: AxiosRequestConfig<DeclarationEntry[]>,
  queryOptions?: UseQueryOptions<DeclarationEntry[]>,
) {
  const {
    data = [],
    isLoading,
    isFetching,
    ...rest
  } = useQuery<DeclarationEntry[]>(
    [DECLARATION_ENTRIES_BY_WORKFLOW, axiosConfig],
    async () => {
      const declarationEntriesByWorkflow =
        await getDeclarationEntriesByWorkflow(axiosConfig);
      return declarationEntriesByWorkflow;
    },
    queryOptions,
  );

  const categorizedEntries = useMemo(() => {
    if (!data) {
      return { expensesEntries: [], incomesEntries: [] };
    }

    const expensesEntries = data.filter((entry) =>
      declarationEntryExpenseTypes.includes(entry.declaration_entry_type),
    );

    const incomesEntries = data.filter((entry) =>
      declarationEntryIncomeTypes.includes(entry.declaration_entry_type),
    );

    return { expensesEntries, incomesEntries };
  }, [data]);

  const { expensesEntries, incomesEntries } = categorizedEntries;

  return {
    declarationEntriesLoading: isLoading || isFetching,
    declarationEntries: data,
    expensesEntries,
    incomesEntries,
    ...rest,
  };
}
