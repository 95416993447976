import { ComponentProps } from 'react';
import { Command } from 'cmdk';

interface ItemProps extends ComponentProps<typeof Command.Item> {
  shortcut?: string;
}
export default function Item({
  children,
  shortcut,
  onSelect,
  value,
  ...props
}: ItemProps) {
  const keys = shortcut?.split?.(' ') || [];

  return (
    <Command.Item value={value} onSelect={onSelect} {...props}>
      {children}
      {shortcut && (
        <div cmdk-shortcuts="">
          {keys.map((key) => (
            <kbd key={key}>{key}</kbd>
          ))}
        </div>
      )}
    </Command.Item>
  );
}
