import { useState, ReactNode, ComponentProps } from 'react';
import {
  Card,
  CardContent,
  Flex,
  Text,
  CardRow,
  Button,
  Select,
  Input,
} from '@konta/ui';
import { useToggle } from 'usehooks-ts';
import {
  CheckLineIcon,
  XCloseLineIcon,
  Edit05LineIcon,
  Edit03LineIcon,
} from '@konta/icons';
import Loader from 'shared/components/Loader';
import TooltipIcon from 'backoffice/components/TooltipIcon';
import { ConfirmModal } from '@components/Modal';
import toCurrency from '@util/toCurrency';

type CardRowProps = ComponentProps<typeof CardRow>;
interface MetricsTotalCardProps {
  title: string;
  content?: string;
  rows: {
    key: string;
    label: string;
    amount: string | number;
    isCurrency?: boolean;
    isBold?: boolean;
    withBackground?: boolean;
    onClick?: () => void;
    button?: ReactNode;
    tooltip?: string | ReactNode;
    tooltipTitle?: string;
    buttonText?: string;
    isEditable?: boolean;
    isEditableWithSelect?: boolean;
    selectOptions?: {
      label: string;
      value: string;
    }[];
    onSave?: (value: string | number | SelectValue) => void;
    isLoading?: boolean;
    confirmModalDescription?: string;
  }[];
  actions?: ReactNode;
  hoverElement?: ReactNode | boolean;
  striped?: CardRowProps['striped'];
  size?: CardRowProps['size'];
  tooltip?: string;
  tooltipTitle?: string;
  confirmModalDescription?: string;
}
export type SelectValue = { value: string; label: string; key?: number };

export default function MetricsTotalCard({
  title,
  content,
  rows,
  actions,
  striped,
  hoverElement,
  size = 'small',
  confirmModalDescription,
}: MetricsTotalCardProps) {
  const [itemWithHover, setItemWithHover] = useState<number | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editValue, setEditValue] = useState<string | number | SelectValue>('');
  const [openConfirmModal, toggleOpenConfirmModal] = useToggle();

  const handleEdit = (index: number, value: string | number) => {
    setEditingIndex(index);
    setEditValue(value);
  };

  const handleSave = (index: number) => {
    rows[index].onSave?.(editValue);
    setEditingIndex(null);
    toggleOpenConfirmModal();
  };

  const handleCancel = () => {
    setEditingIndex(null);
  };

  const handleMouseEnter = (index: number) => {
    setItemWithHover(index);
  };
  const handleMouseLeave = () => {
    setItemWithHover(null);
  };

  return (
    <>
      <ConfirmModal
        loading={false}
        title="Actualizar valores"
        open={openConfirmModal}
        onAccept={() => editingIndex !== null && handleSave(editingIndex)}
        onCancel={toggleOpenConfirmModal}
        onClose={toggleOpenConfirmModal}
      >
        {confirmModalDescription}
      </ConfirmModal>
      <Card
        outlined
        css={{
          flex: '1',
        }}
      >
        <CardContent>
          <Flex
            gap="10"
            css={{ flexDirection: 'column', '@sm': { flexDirection: 'row' } }}
          >
            <Flex direction="column" css={{ flex: '1' }}>
              <Text l bold>
                {title}
              </Text>
              {!!content && (
                <Text s color="gray500">
                  {content}
                </Text>
              )}
            </Flex>
            {!!actions && (
              <Flex
                css={{
                  justifyContent: 'end',
                  gap: '$8',
                  '@sm': {
                    alignItems: 'end',
                    justifyContent: 'center',
                    minWidth: '10rem',
                    flexDirection: 'column',
                  },
                }}
              >
                {actions}
              </Flex>
            )}
          </Flex>
        </CardContent>
        {rows.map(
          (
            {
              key,
              label,
              amount,
              isBold,
              withBackground,
              onClick,
              button,
              tooltip,
              tooltipTitle,
              isEditable,
              isEditableWithSelect,
              selectOptions,
              isCurrency,
              isLoading,
            },
            index,
          ) => (
            <CardRow
              {...(!!striped && { striped })}
              withBackground={withBackground}
              size={size}
              key={`${key}-${index}`}
              css={{ gap: 32 }}
              {...(!!hoverElement && {
                onMouseEnter: () => handleMouseEnter(index),
                onMouseLeave: handleMouseLeave,
              })}
            >
              <Flex
                justify={button ? 'start' : 'between'}
                css={{ flex: '1 0 0' }}
                {...(!!button && { gap: '8' })}
              >
                <Text
                  bold={isBold}
                  {...(!isBold && { color: 'gray700' })}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '$8',
                  }}
                >
                  {label}
                  {tooltip && (
                    <TooltipIcon title={tooltipTitle}>{tooltip}</TooltipIcon>
                  )}
                </Text>

                {/* {!!hoverElement && itemWithHover === index && hoverElement} */}
                {!!button && button}
                {!!onClick && itemWithHover === index && (
                  <Button
                    size="xs"
                    variant="text"
                    noFill
                    onClick={onClick}
                    leftIcon={<Edit05LineIcon />}
                  >
                    Filtrar
                  </Button>
                )}
              </Flex>
              {isLoading && <Loader />}

              {!isLoading && editingIndex === index ? (
                <Flex gap={8} align="center">
                  <Button
                    onClick={handleCancel}
                    variant="text"
                    icon
                    size="2xs"
                    css={{
                      svg: {
                        stroke: '$gray200',
                      },
                    }}
                  >
                    <XCloseLineIcon />
                  </Button>
                  <Button
                    onClick={toggleOpenConfirmModal}
                    variant="text"
                    icon
                    noFill
                    size="2xs"
                    css={{
                      svg: {
                        path: {
                          stroke: '$gray700',
                        },
                      },
                    }}
                  >
                    <CheckLineIcon />
                  </Button>

                  {isEditableWithSelect ? (
                    <Select
                      onChange={(newValue: unknown) => {
                        setEditValue(
                          newValue as {
                            value: string;
                            label: string;
                            key: number;
                          },
                        );
                      }}
                      options={selectOptions || []}
                      size="xs"
                      css={{ width: '190px' }}
                      placeholder="Seleccione una opción"
                      value={editValue}
                    />
                  ) : (
                    <Input
                      type="text"
                      value={editValue as string}
                      onChange={(e: any) => setEditValue(e.target.value)}
                      autoFocus
                      size="xs"
                    />
                  )}
                </Flex>
              ) : (
                <Flex gap={8} align="center">
                  {isEditable && (
                    <Button
                      onClick={() => handleEdit(index, amount)}
                      variant="text"
                      icon
                      size="2xs"
                      noFill
                      css={{
                        svg: {
                          path: {
                            stroke: '$gray400',
                          },
                        },
                      }}
                    >
                      <Edit03LineIcon />
                    </Button>
                  )}
                  <Text bold={isBold} {...(!isBold && { color: 'gray700' })}>
                    {isCurrency ? `${toCurrency(amount)} MXN` : amount}
                  </Text>
                </Flex>
              )}
            </CardRow>
          ),
        )}
      </Card>
    </>
  );
}
