import React from 'react';
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Colxx } from '@components/CustomBootstrap';
import LineShadowChart from '@components/LineShadowChart';
import {
  lineChartConfig,
  chartThemes,
  barChartConfig,
} from '@constants/chartConfig';
import { MONTHS, BINOMIAL_MONTHS } from '@constants/time';
import PropTypes from 'prop-types';
import { toFixed } from '@util/Utils';

let labels = MONTHS;

const configChartData = (
  fiscal_period,
  legal_type,
  targets,
  titles,
  customDatasets = []
) => {
  const datasets = [];
  for (const [i, target] of targets.entries()) {
    const data = Array.from(
      { length: legal_type === 'fiscal_incorporation' ? 6 : 12 },
      (_) => 0
    );
    for (const fs of fiscal_period) {
      let dateIndex = new Date(fs.start_date).getUTCMonth();
      if (legal_type === 'fiscal_incorporation') {
        dateIndex = Math.floor(dateIndex / 2);
        labels = BINOMIAL_MONTHS;
      }
      if (target === 'total_expenses') {
        data[dateIndex] = toFixed(parseFloat(-fs[target]), 2);
      } else {
        data[dateIndex] = toFixed(parseFloat(fs[target]), 2);
      }
    }
    datasets.push({
      data,
      label: titles[i],
      ...chartThemes(true)[i],
    });
  }
  return {
    type: 'bar',
    data: {
      labels,
      datasets: [...customDatasets, ...datasets],
    },
    legend: {
      display: true,
    },
    options: {
      ...barChartConfig.options,
      stacked: true,
      scales: {
        y: {
          ...lineChartConfig.themeY,
          stacked: true,
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
            stacked: true,
          },
        },
      },
    },
  };
};

function ReportsTransactionsHistory({ fiscal_period, legal_type }) {
  const data = Array.from(
    { length: legal_type === 'fiscal_incorporation' ? 6 : 12 },
    (_) => 0
  );
  for (const fs of fiscal_period) {
    let dateIndex = new Date(fs.start_date).getUTCMonth();
    if (legal_type === 'fiscal_incorporation') {
      dateIndex = Math.floor(dateIndex / 2);
    }
    data[dateIndex] = parseFloat(
      toFixed(fs.total_income - fs.total_expenses, 2)
    );
  }
  const incomes = configChartData(
    fiscal_period,
    legal_type,
    ['total_income', 'total_expenses'],
    ['Ingreso', 'Egreso'],
    [
      {
        type: 'line',
        data,
        label: 'Neto',
        ...chartThemes(false)[2],
      },
    ]
  );

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Historial</CardTitle>
        <Row>
          <Colxx xxs="12" className="mb-5">
            <div className="chart-container">
              <LineShadowChart {...incomes} />
            </div>
          </Colxx>
        </Row>
      </CardBody>
    </Card>
  );
}

ReportsTransactionsHistory.propTypes = {
  fiscal_period: PropTypes.any,
  legal_type: PropTypes.any,
};

export default ReportsTransactionsHistory;
