import { TextStep } from 'types/flows';
import { IconContainer, Title, Subtitle } from './styled';

interface TextStepContentProps {
  step: TextStep;
}

export default function TextStepContent({ step }: TextStepContentProps) {
  const { params } = step;
  const { title, emoji, body } = params;
  const renderIcon = () => {
    if (typeof emoji === 'string') {
      if (emoji.startsWith('http') || emoji.startsWith('data:')) {
        return <img src={emoji} alt="Icon" />;
      }
      return emoji;
    }
    return emoji;
  };
  return (
    <>
      <IconContainer>{renderIcon()}</IconContainer>
      <Title>{title}</Title>
      <Subtitle>{body}</Subtitle>
    </>
  );
}
