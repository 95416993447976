import {
  WORKFLOWS_FETCH,
  WORKFLOWS_FETCH_SUCCESS,
  WORKFLOWS_FETCH_FAILURE,
  SHOW_WORKFLOW_FETCH,
  SHOW_WORKFLOW_FETCH_SUCCESS,
  SHOW_WORKFLOW_FETCH_FAILURE,
  WORKFLOW_UPDATE,
  WORKFLOW_UPDATE_SUCCESS,
  WORKFLOW_UPDATE_FAILURE,
  SET_WORKFLOW_PHASE_TRANSITION,
  WORKFLOW_PREVIOUS_PHASE,
  WORKFLOW_PREVIOUS_PHASE_SUCCESS,
  WORKFLOW_PREVIOUS_PHASE_FAILURE,
  WORKFLOW_NEXT_PHASE,
  WORKFLOW_NEXT_PHASE_SUCCESS,
  WORKFLOW_NEXT_PHASE_FAILURE,
} from '@constants/actionTypes';

export const workflowsFetch = () => ({
  type: WORKFLOWS_FETCH,
});

export const workflowsFetchSuccess = (payload) => ({
  type: WORKFLOWS_FETCH_SUCCESS,
  payload,
});

export const workflowsFetchFailure = (payload) => ({
  type: WORKFLOWS_FETCH_FAILURE,
  payload,
});

export const showWorkflowFetch = (payload) => ({
  type: SHOW_WORKFLOW_FETCH,
  payload,
});

export const showWorkflowFetchSuccess = (payload) => ({
  type: SHOW_WORKFLOW_FETCH_SUCCESS,
  payload,
});

export const showWorkflowFetchFailure = (payload) => ({
  type: SHOW_WORKFLOW_FETCH_FAILURE,
  payload,
});

export const workflowUpdate = (payload) => ({
  type: WORKFLOW_UPDATE,
  payload,
});

export const workflowUpdateSuccess = (payload) => ({
  type: WORKFLOW_UPDATE_SUCCESS,
  payload,
});

export const workflowUpdateFailure = (payload) => ({
  type: WORKFLOW_UPDATE_FAILURE,
  payload,
});

export const workflowSetTransition = (payload) => ({
  type: SET_WORKFLOW_PHASE_TRANSITION,
  payload,
});

export const workflowPreviousPhase = (payload) => ({
  type: WORKFLOW_PREVIOUS_PHASE,
  payload,
});

export const workflowPreviousPhaseSuccess = (payload) => ({
  type: WORKFLOW_PREVIOUS_PHASE_SUCCESS,
  payload,
});

export const workflowPreviousPhaseFailure = (payload) => ({
  type: WORKFLOW_PREVIOUS_PHASE_FAILURE,
  payload,
});

export const workflowNextPhase = (payload) => ({
  type: WORKFLOW_NEXT_PHASE,
  payload,
});

export const workflowNextPhaseSuccess = (payload) => ({
  type: WORKFLOW_NEXT_PHASE_SUCCESS,
  payload,
});

export const workflowNextPhaseFailure = (payload) => ({
  type: WORKFLOW_NEXT_PHASE_FAILURE,
  payload,
});
