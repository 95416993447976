import React, { useState } from 'react';
import { Modal, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { IconButton } from '@components';

export function IconConfirmModal({
  iconName,
  iconSize,
  iconColor,
  iconHover,
  iconClassName,
  iconTooltipHelper,
  iconId,
  iconTooltipPlacement,
  header,
  handleSubmit,
  cancelButton,
  confirmButton,
  children,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const toggle = () => setModalOpen(!modalOpen);

  const handleModalSubmit = (value) => {
    toggle();
    handleSubmit();
  };
  return (
    <>
      <IconButton
        iconName={iconName}
        handleClick={toggle}
        size={iconSize}
        color={iconColor}
        hover={iconHover}
        className={iconClassName}
        tooltipHelper={iconTooltipHelper}
        id={iconId}
        tooltipPlacement={iconTooltipPlacement}
      />
      <Modal size="md" isOpen={modalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} className="text-align-center">
          {header}
        </ModalHeader>
        <div className="m-auto text-center">{children}</div>
        <ModalFooter className="d-flex justify-content-center">
          <Button outline size="sm" onClick={toggle}>
            {cancelButton}
          </Button>
          <Button
            color="primary"
            size="sm"
            onClick={(_) => handleModalSubmit()}
          >
            {confirmButton}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default IconConfirmModal;
