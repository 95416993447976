import { ReactGrid } from '@silevis/reactgrid';
import { Workflow } from 'types/entities';
import { Flex } from '@konta/ui';
import useSimplifiedColumns from 'shared/hooks/grids/useSimplifiedColumns';
import useRows from './useRows';
import useSingleSimplifiedCalculation from './useSingleSimplifiedCalculation';
import { Title } from '../styled';

interface SingleSimplifiedCalculationProps {
  workflows: Workflow[];
  selectedWorkflow: Workflow | null;
  onHandleEntriesView: (
    monthIdx: string,
    type: 'income' | 'expense',
    selectedWorkflow: Workflow,
  ) => void;
}

export default function SingleSimplifiedCalculation({
  workflows,
  selectedWorkflow,
  onHandleEntriesView,
}: SingleSimplifiedCalculationProps) {
  const { dataSet, onSelectionChanged } = useSingleSimplifiedCalculation({
    selectedWorkflow,
    workflows,
    onHandleEntriesView,
  });
  const columns = useSimplifiedColumns();
  const rows = useRows({
    dataSet,
    selectedWorkflow,
  });

  return (
    <Flex column gap={16} css={{ mt: '$24', mb: '100px', overflow: 'auto' }}>
      <Title>Cálculo simplificado</Title>
      <div
        style={{ height: '100%', overflow: 'auto' }}
        className="taxes-react-grid"
      >
        <ReactGrid
          enableFillHandle={false}
          rows={rows}
          columns={columns}
          stickyTopRows={1}
          stickyLeftColumns={1}
          stickyRightColumns={1}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
    </Flex>
  );
}
