import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  editClientsSuppliersSuccess,
  editClientsSuppliersFailure,
} from '../actions';

export default function* ({
  payload: { taxable_entity, client_supplier, client_id },
}) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `taxable_entities/${taxable_entity.id}/client_suppliers/${client_id}`,
      { client_supplier },
      { headers: { Authorization: `${access_token}` } }
    );

    yield call(StatusHandler, {
      response,
      title: 'Cliente editado',
      message: 'Cliente editado con éxito',
      status: response.status,
      success: editClientsSuppliersSuccess,
      failure: editClientsSuppliersFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: editClientsSuppliersFailure,
    });
  }
}
