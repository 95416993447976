import type { ComponentProps, ReactNode } from 'react';
import {
  EyeLineIcon,
  FileSearch02LineIcon,
  Warning1OutlineIcon,
} from '@konta/icons';
import { Button, Flex, ProgressBar } from '@konta/ui';
import ButtonIconWithTooltip from 'backoffice/components/ButtonIconWithTooltip';
import toCurrency from '@util/toCurrency';
import Loader from 'shared/components/Loader';
import {
  AmountText,
  Content,
  HeadingContainer,
  HeadingText,
  Icon,
  FooterText,
  Root,
  CurrencyText,
  Alert,
  AlertText,
  ValueContainer,
  ContainerRoot,
  FooterTextContainer,
} from './styled';

export interface ProgressBarSection {
  value: number;
  color: string;
  label: string;
}

interface ActionableMetricCardPropsProps {
  title: string;
  amount: number;
  icon?: ReactNode;
  onClick?: () => void;
  isLoading?: boolean;
  footerText?: string;
  customIcon?: ReactNode;
  tooltipText?: ReactNode;
  tooltipAction?: () => void;
  disableTooltip?: boolean;
  withHeadingButton?: boolean;
  btnProps?: Omit<ComponentProps<typeof Button>, 'children'>;
  headingButtonText?: string;
  isCurrency?: boolean;
  withToolTipIcon?: boolean;
  headingIcon?: ReactNode;
  withProgressBar?: boolean;
  progressSections?: ProgressBarSection[];
  progressLimit?: number;
  currencyText?: string;
  status?: 'success' | 'error' | 'warning' | null;
  isDisabled?: boolean;
  actionValue?: () => void;
  alertText?: string;
  actionHelpText?: ReactNode;
  withColorsProgressContainer?: boolean;
  rightValueButtonProps?: ComponentProps<typeof Button>;
  withRightValueButton?: boolean;
  withLeftValueButton?: boolean;
  leftValueButtonProps?: ComponentProps<typeof Button>;
  isMobile?: boolean;
  intercomTarget?: string;
}

export default function ActionableMetricCardProps({
  title,
  amount,
  icon,
  onClick,
  isLoading,
  footerText,
  tooltipText,
  tooltipAction,
  disableTooltip = false,
  withHeadingButton = false,
  btnProps,
  headingButtonText,
  isCurrency = false,
  withToolTipIcon = false,
  headingIcon,
  withProgressBar,
  progressSections = [],
  progressLimit,
  currencyText = 'MXN',
  status,
  isDisabled,
  actionValue,
  alertText,
  actionHelpText,
  withColorsProgressContainer,
  rightValueButtonProps,
  withRightValueButton = false,
  withLeftValueButton = false,
  leftValueButtonProps,
  isMobile = false,
  intercomTarget,
}: ActionableMetricCardPropsProps) {
  return (
    <Root
      isLoading={isLoading}
      status={status ?? undefined}
      isDisabled={isDisabled}
      pointer={!!onClick}
      {...(!isLoading && { onClick })}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <ContainerRoot>
          <HeadingContainer>
            <Flex justify="center" align="center" gap={6}>
              {headingIcon && <Icon>{headingIcon}</Icon>}
              <HeadingText>{title}</HeadingText>
            </Flex>
            {withHeadingButton && (
              <Button {...btnProps}>{headingButtonText}</Button>
            )}
          </HeadingContainer>
          <Content>
            <Flex
              justify="between"
              css={{
                width: '100%',
                flexWrap: 'wrap',
                gap: isMobile ? '16px' : '0',
              }}
            >
              <ValueContainer wrap="wrap">
                {icon && <Icon>{icon}</Icon>}
                <AmountText>
                  {isCurrency ? toCurrency(+amount) : amount}
                  {isCurrency && <CurrencyText>{currencyText}</CurrencyText>}
                  {withLeftValueButton && (
                    <Button
                      onClick={leftValueButtonProps?.onClick}
                      variant="outlined"
                      noFill
                      size="xs"
                      data-intercom-target={intercomTarget}
                      {...leftValueButtonProps}
                    >
                      {leftValueButtonProps?.children}
                    </Button>
                  )}
                </AmountText>
                {withToolTipIcon && (
                  <ButtonIconWithTooltip
                    icon={<EyeLineIcon />}
                    tooltipText={tooltipText}
                    action={tooltipAction || (() => {})}
                    disabled={disableTooltip}
                    styledButtonContainerCss={{
                      padding: '0',
                      borderRight: 'none',
                    }}
                  />
                )}
                {alertText && (
                  <Alert>
                    <Icon
                      css={{
                        svg: {
                          path: { fill: '$error500' },
                        },
                      }}
                    >
                      <Warning1OutlineIcon />
                    </Icon>
                    <AlertText>{alertText}</AlertText>
                  </Alert>
                )}
              </ValueContainer>
              <Flex justify="center" align="center" gap={12}>
                {actionHelpText && actionHelpText}
                {withRightValueButton && (
                  <Button
                    onClick={actionValue}
                    variant="outlined"
                    noFill
                    size="xs"
                    {...rightValueButtonProps}
                  >
                    {rightValueButtonProps?.children}
                  </Button>
                )}
              </Flex>
            </Flex>
            {withProgressBar && (
              <Flex
                css={{
                  width: '100%',
                }}
              >
                <ProgressBar
                  limit={progressLimit}
                  sections={progressSections}
                  withColorsContainer={withColorsProgressContainer}
                />
              </Flex>
            )}
          </Content>
        </ContainerRoot>
      )}
      {footerText && (
        <FooterTextContainer>
          <FooterText>{footerText}</FooterText>
        </FooterTextContainer>
      )}
    </Root>
  );
}
