import React, { useState } from 'react';
import { Alert, Button, Flex } from '@konta/ui';
import FormikUserRegimeSelect from '@components/FormikUserRegimeSelect';
import { UseInvoice } from '@hooks/useInvoiceForm';
import FormikTextInput from '@components/FormikTextInput';
import useVerifyRfc from '@hooks/useVerifyRfc';
import preventTypeText from '@util/preventTypeText';
import { InvoicePayload } from 'types/entities';

interface IssuerInfoFormProps {
  onSubmit: (values: {
    legalName: InvoicePayload['legalName'];
    postcode: InvoicePayload['postcode'];
    fiscalRegime: InvoicePayload['fiscalRegime'];
  }) => void;
  invoice: UseInvoice;
}

const isValidForm = async (invoice: UseInvoice) => {
  const { errors, touched, setTouched } = invoice.formik;
  touched.postcode = true;
  touched.legalName = true;
  touched.fiscalRegime = true;
  await setTouched(touched);

  const { postcode, legalName, fiscalRegime } = errors;
  return !postcode && !legalName && !fiscalRegime;
};

export default function IssuerInfoForm({
  onSubmit,
  invoice,
}: IssuerInfoFormProps) {
  const verifyRfc = useVerifyRfc({ source: 'IssuerInfoForm' });
  const [otherError, setOtherError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const validForm = await isValidForm(invoice);
    if (!validForm) return;
    setLoading(true);
    const { fixedName, errors } = await verifyRfc({
      rfc: invoice.taxableEntity.rfc,
      zipCode: invoice.formik.values.postcode,
      legalName: invoice.formik.values.legalName,
    });
    setLoading(false);

    if (errors) {
      setOtherError(errors.rfc || errors.other || '');
      invoice.formik.setErrors({
        legalName: errors.name,
        postcode: errors.cp,
      });
    } else {
      const payload = {
        fiscalRegime: invoice.formik.values.fiscalRegime,
        postcode: invoice.formik.values.postcode,
        legalName: invoice.formik.values.legalName,
      };
      // eslint-disable-next-line no-param-reassign
      invoice.formik.values.legalName = fixedName || payload.legalName;
      onSubmit(payload);
    }
  };

  return (
    <Flex direction="column" gap="8">
      <FormikTextInput
        name="legalName"
        label="Razón social"
        placeholder="Escribe tu razón social"
      />

      <FormikUserRegimeSelect
        newDesign
        label="Régimen fiscal"
        name="fiscalRegime"
        placeholder="Buscar o añadir un régimen fiscal"
      />

      <FormikTextInput
        name="postcode"
        label="Código postal"
        placeholder="12345"
        css={{ width: '120px' }}
        onKeyPress={preventTypeText}
      />

      <Flex gap="6" justify="end">
        <Button color="primary" loading={loading} onClick={handleSubmit}>
          Verificar y guardar
        </Button>
      </Flex>

      {!!otherError && <Alert color="error">{otherError}</Alert>}
    </Flex>
  );
}
