import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from '@konta/ui';
import PlayBoxOutlineIcon from 'mdi-react/PlayBoxOutlineIcon';
import PlayCircleIcon from 'mdi-react/PlayCircleIcon';
import { useToggle } from 'rooks';
import { Modal } from 'reactstrap';
import axios from 'axios';
import styles from './VideoButton.module.scss';

export default function VideoButton({
  videoId,
  withSpacing,
  withLink,
  onTitleFetched,
  startTime,
  withButton,
}) {
  const [visible, toggleVisible] = useToggle(false);
  const [title, setTitle] = useState('');
  const videoUrl = useMemo(
    () =>
      `https://www.youtube.com/embed/${encodeURI(videoId)}?start=${startTime}`,
    [videoId],
  );

  useEffect(() => {
    if (!videoId) return;
    axios
      .get(
        `https://noembed.com/embed?url=https://www.youtube.com/watch?v=${videoId}`,
      )
      .then(({ data }) => {
        setTitle(data.title.replace('Búho Contable: ', ''));
      });
  }, [videoId]);

  useEffect(() => {
    if (onTitleFetched) {
      onTitleFetched(title);
    }
  }, [onTitleFetched, title]);

  if (!videoId) return null;

  return (
    <>
      <div
        className={withSpacing ? styles.root : styles['root-without-spacing']}
      >
        {withButton && (
          <button
            type="button"
            className={styles.thumbnail}
            onClick={toggleVisible}
          >
            {!!title && (
              <>
                <PlayCircleIcon />
                <Text size="2xs" bold>
                  Video:
                </Text>
                <Text size="xs" truncate>
                  {title}
                </Text>
              </>
            )}
          </button>
        )}
        {!!withLink && (
          <Button
            variant="text"
            color="primary"
            css={{ padding: '0', marginTop: '5px' }}
            onClick={toggleVisible}
            leftIcon={<PlayBoxOutlineIcon className={styles.icon} />}
          >
            Ver video
          </Button>
        )}
      </div>
      <Modal
        isOpen={visible}
        size="lg"
        className={styles.modal}
        toggle={toggleVisible}
      >
        <iframe
          title="Video"
          className={styles.video}
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Modal>
    </>
  );
}

VideoButton.propTypes = {
  videoId: PropTypes.string,
  withSpacing: PropTypes.bool,
  withButton: PropTypes.bool,
  withLink: PropTypes.bool,
  onTitleFetched: PropTypes.func,
  startTime: PropTypes.number,
};

VideoButton.defaultProps = {
  videoId: null,
  withSpacing: true,
  withLink: true,
  withButton: true,
  onTitleFetched: null,
  startTime: 0,
};
