export const financial_transactions_translator = {
  bank: 'banco',
  cash: 'efectivo',
  card: 'tarjeta',
  charges: 'emisor',
  payment: 'receptor',
  income: 'ingresos',
  expenses: 'egresos',
  loans: 'préstamos',
  contributions: 'contribuciones',
  non_deductible: 'No deducibles',
};

export const TYPE_DATA_TRANS = {
  I: 'Ingreso',
  P: 'Complemento de pago',
  N: 'Nómina',
  T: 'Traslado',
  E: 'Egreso',
};

export const METHOD_DATA_TRANS = {
  PPD: 'Pago en parcialidades o diferido',
  PUE: 'Pago en una sola exhibición',
};

export const USE_CFDI_TRANS = {
  G01: 'Adquisición de mercancias',
  G02: 'Devoluciones, descuentos o bonificaciones',
  G03: 'Gastos en general',
  I01: 'Construcciones',
  I02: 'Mobilario y equipo de oficina por inversiones',
  I03: 'Equipo de transporte',
  I04: 'Equipo de computo y accesorios',
  I05: 'Dados, troqueles, moldes, matrices y herramental',
  I06: 'Comunicaciones telefónicas',
  I07: 'Comunicaciones satelitales',
  I08: 'Otra maquinaria y equipo',
  D01: 'Honorarios médicos, dentales y gastos hospitalarios.',
  D02: 'Gastos médicos por incapacidad o discapacidad',
  D03: 'Gastos funerales.',
  D04: 'Donativos.',
  D05: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
  D06: 'Aportaciones voluntarias al SAR.',
  D07: 'Primas por seguros de gastos médicos.',
  D08: 'Gastos de transportación escolar obligatoria.',
  D09: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
  D10: 'Pagos por servicios educativos (colegiaturas)',
  P01: 'Por definir',
};

export const REGIMEN_FISCAL_TRANS = {
  '01': 'ACAPULCO, ACAPULCO DE JUAREZ, GUERRERO.',
  '02': 'AGUA PRIETA, AGUA PRIETA, SONORA.',
  '05': 'SUBTENIENTE LOPEZ, SUBTENIENTE LOPEZ, QUINTANA ROO.',
  '06': 'CIUDAD DEL CARMEN, CIUDAD DEL CARMEN, CAMPECHE.',
  '07': 'CIUDAD JUAREZ, CIUDAD JUAREZ, CHIHUAHUA.',
  '08': 'COATZACOALCOS, COATZACOALCOS, VERACRUZ.',
  11: 'ENSENADA, ENSENADA, BAJA CALIFORNIA.',
  12: 'GUAYMAS, GUAYMAS, SONORA.',
  14: 'LA PAZ, LA PAZ, BAJA CALIFORNIA SUR.',
  16: 'MANZANILLO, MANZANILLO, COLIMA.',
  17: 'MATAMOROS, MATAMOROS, TAMAULIPAS.',
  18: 'MAZATLAN, MAZATLAN, SINALOA.',
  19: 'MEXICALI, MEXICALI, BAJA CALIFORNIA.',
  20: 'MEXICO, DISTRITO FEDERAL.',
  22: 'NACO, NACO, SONORA.',
  23: 'NOGALES, NOGALES, SONORA.',
  24: 'NUEVO LAREDO, NUEVO LAREDO, TAMAULIPAS.',
  25: 'OJINAGA, OJINAGA, CHIHUAHUA.',
  26: 'PUERTO PALOMAS, PUERTO PALOMAS, CHIHUAHUA.',
  27: 'PIEDRAS NEGRAS, PIEDRAS NEGRAS, COAHUILA.',
  28: 'PROGRESO, PROGRESO, YUCATAN.',
  30: 'CIUDAD REYNOSA, CIUDAD REYNOSA, TAMAULIPAS.',
  31: 'SALINA CRUZ, SALINA CRUZ, OAXACA.',
  33: 'SAN LUIS RIO COLORADO, SAN LUIS RIO COLORADO, SONORA.',
  34: 'CIUDAD MIGUEL ALEMAN, CIUDAD MIGUEL ALEMAN, TAMAULIPAS.',
  37: 'CIUDAD HIDALGO, CIUDAD HIDALGO, CHIAPAS.',
  38: 'TAMPICO, TAMPICO, TAMAULIPAS.',
  39: 'TECATE, TECATE, BAJA CALIFORNIA.',
  40: 'TIJUANA, TIJUANA, BAJA CALIFORNIA.',
  42: 'TUXPAN, TUXPAN DE RODRIGUEZ CANO, VERACRUZ.',
  43: 'VERACRUZ, VERACRUZ, VERACRUZ.',
  44: 'CIUDAD ACUÑA, CIUDAD ACUÑA, COAHUILA.',
  46: 'TORREON, TORREON, COAHUILA.',
  47: 'AEROPUERTO INTERNACIONAL DE LA CIUDAD DE MEXICO.',
  48: 'GUADALAJARA, TLACOMULCO DE ZUÑIGA, JALISCO.',
  50: 'SONOYTA, SONOYTA, SONORA.',
  51: 'LAZARO CARDENAS, LAZARO CARDENAS, MICHOACAN.',
  52: 'MONTERREY, GENERAL MARIANO ESCOBEDO, NUEVO LEON.',
  53: 'CANCUN, CANCUN, QUINTANA ROO.',
  64: 'QUERÉTARO, EL MARQUÉS Y COLON, QUERÉTARO.',
  65: 'TOLUCA, TOLUCA, ESTADO DE MEXICO.',
  67: 'CHIHUAHUA, CHIHUAHUA, CHIHUAHUA.',
  73: 'AGUASCALIENTES, AGUASCALIENTES, AGUASCALIENTES.',
  75: 'PUEBLA, HEROICA PUEBLA DE ZARAGOZA, PUEBLA.',
  80: 'COLOMBIA, COLOMBIA, NUEVO LEON.',
  81: 'ALTAMIRA, ALTAMIRA, TAMAULIPAS.',
  82: 'CIUDAD CAMARGO, CIUDAD CAMARGO, TAMAULIPAS.',
  83: 'DOS BOCAS, PARAISO, TABASCO.',
  84: 'GUANAJUATO, SILAO, GUANAJUATO.',
};

export const CFDI_STATUS_TRANS = {
  cancelled: 'Cancelada',
  acceptance_pending: 'Aceptación pendiente',
  not_accepted: 'Cancelación no aceptada',
  pending: 'Pendiente',
  stamped: 'Timbrada',
  cancellation_in_progress: 'Cancelación en proceso',
};

export const PAYMENT_TYPE_TRANS = {
  '01': 'Efectivo',
  '02': 'Cheque nominativo',
  '03': 'Transferencia electrónica de fondos',
  '04': 'Tarjeta de crédito',
  '05': 'Monedero electrónico',
  '06': 'Dinero electrónico',
  '08': 'Vales de despensa',
  12: 'Dación en pago',
  13: 'Pago por subrogación',
  14: 'Pago por consignación',
  15: 'Condonación',
  17: 'Compensación',
  23: 'Novación',
  24: 'Confusión',
  25: 'Remisión de deuda',
  26: 'Prescripción o caducidad',
  27: 'A satisfacción del acreedor',
  28: 'Tarjeta de débito',
  29: 'Tarjeta de servicios',
  30: 'Aplicación de anticipos',
  31: 'Intermediario pagos',
  99: 'Por definir',
};
