import { useQueryClient } from 'react-query';
import { useToggle } from 'rooks';
import useWorkflow from '@components/Workflow/hooks/useWorkflow';
import { DECLARATION_ENTRIES_BY_WORKFLOW } from 'shared/constants/reactQueries';

interface UseUploadForeignProductProps {
  toggleOpenUploadExpense: () => void;
}
export default function useUploadForeignProduct({
  toggleOpenUploadExpense,
}: UseUploadForeignProductProps) {
  const queryClient = useQueryClient();
  const [showConfirmation, toggleShowConfirmation] = useToggle();
  const {
    regimeOptionsWithDeclarationId,
    updateCurrentWorkflow,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  } = useWorkflow();

  const handleSubmit = async () => {
    updateCurrentWorkflow();
    await queryClient.invalidateQueries([DECLARATION_ENTRIES_BY_WORKFLOW]);
    toggleShowConfirmation();
    toggleOpenUploadExpense();
  };

  return {
    regimeOptionsWithDeclarationId,
    showConfirmation,
    toggleShowConfirmation,
    handleSubmit,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  };
}
