import api from '@api';
import { AxiosRequestConfig } from 'axios';
import { AnnualDeduction, AnnualDeductionPayload } from 'types/entities';

export async function getAnnualDeductions() {
  const { data } = await api.get<AnnualDeduction>('/annual_deduction');
  return data;
}

export async function patchAnnualDeductions(
  payload: AnnualDeductionPayload,
  config?: AxiosRequestConfig<AnnualDeduction>,
) {
  const { data } = await api.patch<AnnualDeduction>(
    `annual_deduction`,
    payload,
    config,
  );
  return data;
}
