import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Flex } from '@konta/ui';
import Cell from 'shared/components/Cell';
import { Cfdi } from 'types/entities';
import PDFModal from '@components/Utils/PDF/pdf-modal';
import Table from 'backoffice/@konta/utils/table';

export default function useCfdisTableColumns(type: 'incomes' | 'expenses') {
  return useMemo<ColumnDef<Cfdi>[]>(
    () => [
      {
        header: 'Razón social',
        label: 'Razón social',
        id: 'legalName',
        accessorKey: 'legalName',
        cell: ({ row }) => {
          if (type === 'incomes') {
            return <Cell capitalize>{row.original?.receiver_legal_name}</Cell>;
          }
          return <Cell capitalize>{row.original?.issuer_legal_name}</Cell>;
        },
      },
      {
        header: 'Uso CFDI',
        size: 300,
        label: 'Uso CFDI',
        accessorKey: 'cfdiUse',
        id: 'cfdiUse',
        accessorFn: (cfdi) => cfdi.cfdi_use?.name || '-',
        cell: ({ row }) => (
          <Cell isCollapsedText>{row.original.cfdi_use?.name || '-'}</Cell>
        ),
        // TODO FIX handle reset filters from ui kit
        /* filterHandler: {
          type: 'select',
          initialFilter: '',
          options: USE_CFDI_OPTIONS,
        }, */
      },
      {
        header: 'Rfc',
        label: 'Rfc',
        id: 'rfc',
        accessorKey: 'rfc',
        cell: ({ row }) => {
          if (type === 'incomes') {
            return <Cell capitalize>{row.original?.receiver_rfc}</Cell>;
          }
          return <Cell capitalize>{row.original?.issuer_rfc}</Cell>;
        },
      },
      {
        header: 'Fecha',
        label: 'Fecha',
        id: 'dateIssued',
        accessorKey: 'dateIssued',
        cell: ({ row }) => (
          <Cell capitalize>
            {Table.humanizeDate(row.original?.date_issued)}
          </Cell>
        ),
        // TODO FIX handle reset filters from ui kit
        /* filterFn: 'rangeDate',
        filterHandler: {
          type: 'dateRange',
          initialFilter: '',
        }, */
      },
      {
        header: 'Total',
        label: 'Total',
        id: 'total',
        accessorKey: 'total',
        cell: ({ row }) => {
          const isCreditNote =
            row.original?.is_emited && row.original?.invoice_type === 'expenses';
          return (
            <Cell currency>
              {isCreditNote
                ? (row.original?.total || 0) * -1
                : row.original?.total}
            </Cell>
          );
        },
      },
      {
        header: 'Subtotal',
        label: 'Subtotal',
        id: 'subtotal',
        accessorKey: 'subtotal',
        cell: ({ row }) => {
          const isCreditNote =
            row.original?.is_emited && row.original?.invoice_type === 'expenses';
          return (
            <Cell currency>
              {isCreditNote
                ? (row.original?.subtotal || 0) * -1
                : row.original?.subtotal}
            </Cell>
          );
        },
      },
      {
        header: 'Acciones',
        label: 'Acciones',
        id: 'taxableEntityId',
        accessorKey: 'taxableEntityId',
        cell: ({ row: { original } }) => (
          <Flex gap={8}>
            <PDFModal
              url={original.xml_url}
              title="Vista previa de la factura"
              id={original.id}
              uuid={original.folio_fiscal}
              status={original.status}
              invoice={original}
              togglePdf={undefined}
              onToggle={undefined}
            />
          </Flex>
        ),
      },
    ],
    [type],
  );
}
