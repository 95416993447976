import React from 'react';
import {
  ProfileFormCard,
  SecurityFormCard,
  AddressFormCard,
  LogoutCard,
  ContactMethods,
} from '@containers/';
import { Colxx } from '@components/CustomBootstrap';
import { Row } from 'reactstrap';

export default function (_props) {
  return (
    <>
      <Row>
        <Colxx xxs={12} className="mb-4">
          <ProfileFormCard />
        </Colxx>
      </Row>
      <Row>
        <Colxx lg={6} className="mb-4">
          <Row>
            <Colxx lg={12} className="mb-4">
              <ContactMethods />
            </Colxx>
            <Colxx lg={12} className="mb-4">
              <SecurityFormCard />
            </Colxx>
            <Colxx lg={12} className="mb-4">
              <LogoutCard />
            </Colxx>
          </Row>
        </Colxx>
        <Colxx lg={6} className="mb-4">
          <Row>
            <Colxx lg={12} className="mb-4">
              <AddressFormCard />
            </Colxx>
          </Row>
        </Colxx>
      </Row>
    </>
  );
}
