import { Flex } from '@konta/ui';
import SectionHeader from 'shared/components/SectionHeader';
import Section from 'shared/components/Section';
import Heatmap from 'shared/components/Heatmap';
import transformWorkflowDataForHeatmap from 'shared/util/transformWorkflowDataForHeatmap';
import { ComponentProps } from 'react';

export const danger = '#F04438';
const legend = {
  colors: [danger],
  labels: ['Declaración atrasada o no pagada'],
};

interface TaxDelaysHeatmapProps {
  title?: string;
  subtitle?: string;
  heatmapData: ReturnType<typeof transformWorkflowDataForHeatmap>;
  onClickRect?: ComponentProps<typeof Heatmap>['onClickRect'];
  tooltipText?: string;
}
export default function TaxDelaysHeatmap({
  title = 'Estatus de declaraciones',
  subtitle,
  heatmapData,
  onClickRect,
  tooltipText,
}: TaxDelaysHeatmapProps) {
  return (
    <Flex
      column
      gap={8}
      css={{
        flex: 1,
        width: '100%',
      }}
    >
      <SectionHeader
        title={title}
        subtitle={subtitle}
        textTooltip={tooltipText}
      />
      <Section>
        <Heatmap
          binData={heatmapData.data}
          getFillColor={(d) => {
            if (d.count === 0) {
              return danger;
            }
            return 'transparent';
          }}
          xScaleDomain={heatmapData.xAccessor}
          yScaleDomain={heatmapData.yAccessor}
          legendOrdinal={legend}
          onClickRect={onClickRect}
        />
      </Section>
    </Flex>
  );
}
