import { styled } from '@konta/ui';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: '8px',
  border: '1px solid $gray200',
  background: '$white',
  boxShadow:
    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
  '&:hover': {
    cursor: 'pointer',
    background: '$gray100',
  },
});

export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$16',
  alignSelf: 'stretch',
  padding: '$16',
});

export const Heading = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$12',
  alignSelf: 'stretch',
});

export const HeadingText = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: '1 0 0',
});

export const Details = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$24',
  alignSelf: 'stretch',
});
