import { styled } from '@konta/ui';

export const Title = styled('div', {
  color: '$primary700',
  textAlign: 'center',
  fontFamily: 'DM Sans',
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
});

export const Subtitle = styled('div', {
  fontFamily: 'DM Sans',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '500',
  lineHeight: '24px',
  color: '$primary700',
  fontSize: '20px',
});

export const ScoreGraphContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '100%',
  margin: '0 auto',
  mt: '8px',
});

export const ScoreContainer = styled('div', {
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  mb: '-10px',
});

export const UpdateText = styled('span', {
  color: '$gray800',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '18px',
  textAlign: 'center',
  mb: '20px',
  mt: '10px',
});
