import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Box, Button, CircularLoading, Flex, Input, Text } from '@konta/ui';
import useQrCodeTwoFactor from '@hooks/auth/useQrCodeTwoFactor';
import useCreateTwoFactorAuth from '@hooks/auth/useCreateTwoFactorAuth';
import { NotificationManager } from '@components';
import { updateUserOtpRequiredForLogin } from '@redux/auth/actions';

export default function ScanQrModal({
  toggle,
  isOpen,
  size,
  toggleBackupCodeModal,
}) {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const { data, loadingQrCode, isError } = useQrCodeTwoFactor();

  const handleError = () => {
    NotificationManager.error(
      'Código erróneo, intenta nuevamente.',
      'Autenticación de dos pasos',
      5000,
      null,
      null,
    );
  };
  const handleSuccess = () => {
    NotificationManager.success(
      'Código correcto.',
      'Autenticación de dos pasos',
      5000,
      null,
      null,
    );
    dispatch(updateUserOtpRequiredForLogin(true));
    toggleBackupCodeModal();
    toggle();
  };

  const createTwoFactorAuth = useCreateTwoFactorAuth({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const isValidCode = code && code.length === 6;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={size}>
      <ModalBody>
        <Flex direction="column" css={{ gap: '12px' }}>
          <Text as="h2">Escanea el siguiente código con tu app</Text>
          <Text>
            Descarga la aplicación gratuita Google Authenticator, añade una
            nueva cuenta y escanea este código de barras para configurar tu
            cuenta.
          </Text>
        </Flex>
        <Flex align="center" direction="column" css={{ mt: '24px' }}>
          {loadingQrCode && <CircularLoading xl />}
          {isError && (
            <Alert color="danger">
              Ocurrió un error al generar la imagen del código QR para escanear,
              intenta más tarde.
            </Alert>
          )}
          {data?.two_factor?.qrcode && (
            <img
              className="img-fluid text-center"
              alt="qr-code"
              src={`data:image/svg+xml;base64,${data.two_factor.qrcode}`}
            />
          )}
        </Flex>
        <Flex direction="column" css={{ mt: '32px' }} align="center">
          <Text css={{ mb: '$4' }} bold>
            Introduce el código de seis dígitos de la aplicación
          </Text>
          <Input
            data-openreplay-obscured
            css={{ width: '100%' }}
            type="number"
            helperText="Tras escanear
              la imagen del código de barras, la app mostrará un código que
              puedes introducir aquí"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            onKeyPress={(e) => {
              if (e.charCode === 13 && isValidCode) {
                createTwoFactorAuth.mutate(code);
              }
            }}
          />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button variant="outlined" onClick={toggle}>
          Cancelar
        </Button>
        <Button
          color="primary"
          disabled={!isValidCode}
          onClick={() => createTwoFactorAuth.mutate(code)}
        >
          Verificar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ScanQrModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  size: PropTypes.string,
  toggleBackupCodeModal: PropTypes.func.isRequired,
};

ScanQrModal.defaultProps = {
  toggle: null,
  size: 's',
  isOpen: false,
};
