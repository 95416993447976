import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import useWorkflows from 'shared/hooks/useWorkflows';
import useAccumulatedIncomesAndExpensesEntries from 'shared/hooks/useAccumulatedIncomesAndExpensesEntries';
import getTotalsFromDeclarationIncome from 'shared/util/getTotalsFromDeclarationIncome';
import getTotalsFromDeclarationExpense from 'shared/util/getTotalsFromDeclarationExpense';

type Metrics = {
  total: Record<string, string | boolean>;
  subtotal: Record<string, string | boolean>;
  transactions: Record<string, string | boolean>;
};

const metricsDict: Metrics = {
  total: {
    title: 'Total (Con Impuestos)',
    tooltipText: 'Total (Con Impuestos)',
    isCurrency: true,
  },
  subtotal: {
    title: 'Subtotal (Sin impuestos)',
    tooltipText: 'Subtotal (Sin impuestos)',
    isCurrency: true,
  },
  transactions: {
    title: 'Número de transacciones',
    tooltipText: 'Número de transacciones',
    isCurrency: false,
  },
};
type UseDeclarationEntriesTotalsModalProps = {
  date?: string;
  type: 'incomes' | 'expenses';
};
export default function useDeclarationEntriesTotalsModal({
  date,
  type,
}: UseDeclarationEntriesTotalsModalProps) {
  const [modalInvoiceDetails, setModalInvoiceDetails] = useState<{
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  } | null>(null);
  const [grouped, setGrouped] = useState<'byTransaction' | 'byClient'>(
    'byTransaction',
  );
  const [filters, setFilters] = useState<Record<string, string>>({
    type: 'day',
    period: 'currentYear',
  });

  const currentDate = useMemo(() => {
    if (date) {
      return dayjs(date);
    }
    return dayjs();
  }, [date]);
  const isIncomes = type === 'incomes';

  const title = useMemo(() => {
    const titleType = isIncomes ? 'Ingresos cobrados' : 'Gastos';
    if (filters.period === 'currentMonth') {
      return `${titleType} de ${currentDate.format('MMMM [de] YYYY')}`;
    }
    return `${titleType}  de ${currentDate.format('YYYY')}`;
  }, [currentDate, filters.period, isIncomes]);

  const closeModalInvoiceDetails = () => setModalInvoiceDetails(null);

  const workflowsFilters = useMemo(() => {
    if (filters.period === 'currentMonth') {
      return {
        'q[start_date_gteq]': currentDate.startOf('month').format('YYYY-MM-DD'),
        'q[end_date_lteq]': currentDate.endOf('month').format('YYYY-MM-DD'),
      };
    }
    // if (filters.period === 'currentYear')
    return {
      'q[start_date_gteq]': currentDate.startOf('year').format('YYYY-MM-DD'),
      'q[end_date_lteq]': currentDate.endOf('year').format('YYYY-MM-DD'),
    };
  }, [currentDate, filters.period]);

  const { workflows, workflowsLoading } = useWorkflows({
    params: workflowsFilters,
  });

  const seriesGraph = useMemo<'area' | 'line' | 'none'>(() => {
    if (filters.type === 'accumulated') {
      return 'line';
    }
    return 'none';
  }, [filters.type]);

  const {
    incomesDataSet,
    expensesDataSet,
    isLoading: graphsLoading,
  } = useAccumulatedIncomesAndExpensesEntries({
    filters: {
      ...(filters.period === 'currentYear' && {
        year: currentDate.year(),
      }),
      ...(filters.period === 'currentMonth' && {
        month: currentDate.month() + 1,
        year: currentDate.year(),
      }),
      accumulate_periods: filters.type === 'accumulated',
      from: 'DeclarationEntriesTotalsModal',
    },
    queryOptions: {
      enabled: currentDate.isValid(),
      staleTime: 1000 * 15,
    },
  });

  const metrics = useMemo(
    () =>
      Object.entries(
        workflows
          .flatMap((workflow) =>
            workflow.declarations.filter(
              (declaration) => declaration.is_active,
            ),
          )
          .reduce(
            (acc, declaration) => {
              const { grandTotal, grandTotalBases } = isIncomes
                ? getTotalsFromDeclarationIncome(declaration.declaration_income)
                : getTotalsFromDeclarationExpense(
                    declaration.declaration_expense,
                  );
              acc.total += grandTotal;
              acc.subtotal += grandTotalBases;
              acc.transactions += isIncomes
                ? declaration.count_income_entries
                : declaration.count_expense_entries;

              return acc;
            },
            {
              total: 0,
              subtotal: 0,
              transactions: 0,
            },
          ),
      ).map(([key, value]) => {
        const metricsDictKey = key as keyof Metrics;
        return {
          total: value,
          title: metricsDict[metricsDictKey].title as string,
          tooltipText: metricsDict[metricsDictKey].tooltipText as string,
          isCurrency: metricsDict[metricsDictKey].isCurrency as boolean,
        };
      }),
    [isIncomes, workflows],
  );

  return {
    filterProps: {
      filters,
      setFilters,
      graphsLoading,
    },
    graphProps: {
      dataSet: isIncomes ? incomesDataSet : expensesDataSet,
      onlyGraph: true,
      series: seriesGraph,
      id: 'declarationEntriesTotalsModal',
    },
    graphsLoading,
    workflows,
    workflowsLoading,
    metrics,
    title,
    grouped,
    setGrouped,
    currentDate,
    filters,
    closeModalInvoiceDetails,
    modalInvoiceDetails,
    setModalInvoiceDetails,
  };
}
