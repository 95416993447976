import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, ImageVideoModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

function TextExpences() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />

      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p className="font-weight-bold">Actividades con tasa de 0% de IVA</p>
          <p>
            Existen varias actividades por las cuales el SAT no contempla el
            IVA.
            <span>
              <a
                className="clickable-text-color font-italic"
                href="https://www.sat.gob.mx/articulo/06071/articulo-2-a"
                target="_blank"
                rel="noopener noreferrer"
              >
                Revise el Artículo 2-A del CFF{' '}
              </a>
            </span>
            que contiene todos los casos al respecto.
          </p>
        </Colxx>
        <Colxx md="12">
          <HelpCenterLink articleId="taxable_activities_at_rate_0_and_8">
            Link al artículo
          </HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
      <Row>
        <Colxx md="12">
          <p className="font-weight-bold">Retenciones de impuestos</p>
          <p>
            Las retenciones son impuestos aplicados por la Secretaría de
            Hacienda, señalados dentro de una factura electrónica junto con el
            monto total de una venta. La cantidad retenida varía dependiendo de
            factores como lo son la ubicación física del contribyente, el tipo
            de actividad, o el régimen fiscal.
          </p>
        </Colxx>
        <Colxx md="12">
          <HelpCenterLink articleId="what_are_the_tax_withholding_taxes">
            Link al artículo
          </HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
    </>
  );
}
export default TextExpences;
