import { styled } from '@konta/ui';

export const FeatureIcon = styled('div', {
  display: 'flex',
  width: '40px',
  height: '40px',
  padding: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  border: '1px solid $gray200',
  background: '#FFF',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
});
