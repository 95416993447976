import {
  BANK_TRANSACTIONS_DECLARATIONS_FETCH,
  BANK_TRANSACTIONS_DECLARATIONS_FETCH_SUCCESS,
  BANK_TRANSACTIONS_DECLARATIONS_FETCH_FAILURE,
  BANK_TRANSACTIONS_DECLARATIONS_UPDATE,
  BANK_TRANSACTIONS_DECLARATIONS_UPDATE_SUCCESS,
  BANK_TRANSACTIONS_DECLARATIONS_UPDATE_FAILURE,
  BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE,
  BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_SUCCESS,
  BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_FAILURE,
} from '@constants/actionTypes';

export const bankTransactionsDeclarationsFetch = (payload) => ({
  type: BANK_TRANSACTIONS_DECLARATIONS_FETCH,
  payload,
});

export const bankTransactionsDeclarationsFetchSuccess = (payload) => ({
  type: BANK_TRANSACTIONS_DECLARATIONS_FETCH_SUCCESS,
  payload,
});

export const bankTransactionsDeclarationsFetchFailure = (payload) => ({
  type: BANK_TRANSACTIONS_DECLARATIONS_FETCH_FAILURE,
  payload,
});

export const bankTransactionsDeclarationsUpdate = (payload) => ({
  type: BANK_TRANSACTIONS_DECLARATIONS_UPDATE,
  payload,
});

export const bankTransactionsDeclarationsUpdateSuccess = (payload) => ({
  type: BANK_TRANSACTIONS_DECLARATIONS_UPDATE_SUCCESS,
  payload,
});

export const bankTransactionsDeclarationsUpdateFailure = (payload) => ({
  type: BANK_TRANSACTIONS_DECLARATIONS_UPDATE_FAILURE,
  payload,
});

export const bankTransactionsDeclarationsBulkUpdate = (payload) => ({
  type: BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE,
  payload,
});

export const bankTransactionsDeclarationsBulkUpdateSuccess = (payload) => ({
  type: BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_SUCCESS,
  payload,
});

export const bankTransactionsDeclarationsBulkUpdateFailure = (payload) => ({
  type: BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_FAILURE,
  payload,
});
