import { Navigate, Route, Routes } from 'react-router-dom';
import { useToggle } from 'usehooks-ts';
import { Modal } from '@konta/ui';
import { ProcessType } from 'types/entities';
import useProcessNavigation from '@hooks/useProcessNavigation';
import OnboardingWelcome from '@components/Onboarding/OnboardingWelcome';
import OnboardingBookCall from './OnboardingBookCall';
import OnboardingExpenses from './OnboardingExpenses';
import OnboardingIncomes from './OnboardingIncomes';
import OnboardingFeedback from './OnboardingFeedback';
import OnboardingCallWaiting from './OnboardingCallWaiting';
import OnboardingReferrals from './OnboardingReferrals';
import OnboardingCIEC from './OnboardingCIEC';
import Portal from '../Portal';

export default function Onboarding() {
  useProcessNavigation(ProcessType.OnboardingOnboardingProcess);
  const [open] = useToggle(true);

  return (
    <Portal target="#root">
      <Modal open={open} size="full-screen" id="onboarding-modal">
        <Routes>
          <Route path="/welcome" element={<OnboardingWelcome />} />
          <Route path="/ciec" element={<OnboardingCIEC />} />
          <Route path="/book" element={<OnboardingBookCall />} />
          <Route path="/expenses" element={<OnboardingExpenses />} />
          <Route path="/incomes" element={<OnboardingIncomes />} />
          <Route path="/feedback" element={<OnboardingFeedback />} />
          <Route path="/call-waiting" element={<OnboardingCallWaiting />} />
          <Route path="/referrals" element={<OnboardingReferrals />} />
          <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
      </Modal>
    </Portal>
  );
}
