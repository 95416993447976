import { RefObject } from 'react';
import {
  LastMessage,
  Message,
  MessageText,
  MessageWrapper,
  Name,
  NameAndTime,
  Root,
  Time,
} from './styled';

// Define the type for the props
type ChatBodyProps = {
  messages: Array<{
    id: string;
    name: string;
    text: string;
  }>;
  lastMessageRef: RefObject<HTMLDivElement>;
};

export default function ChatBody({ messages, lastMessageRef }: ChatBodyProps) {
  const isMeByName = (name: string) => name === localStorage.getItem('user_id');

  return (
    <Root>
      {messages.map((message) => {
        const isMe = isMeByName(message.name);
        return (
          <MessageWrapper isMe={isMe} key={message.id}>
            <NameAndTime>
              <Name>{isMe ? 'Tú' : message.name}</Name>
              <Time>Thursday 11:41am</Time>
            </NameAndTime>
            <Message isMe={isMe}>
              <MessageText isMe={isMe}>{message.text}</MessageText>
            </Message>
          </MessageWrapper>
        );
      })}
      <LastMessage ref={lastMessageRef} />
    </Root>
  );
}
