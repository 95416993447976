import * as yup from 'yup';
import type { ReactSelectItemProps } from 'types/reactSelect';

export const CANCELLATION_REASONS = [
  {
    value: 'Voy a cerrar o suspender mi negocio',
    label: 'Voy a cerrar o suspender mi negocio',
  },
  {
    value: 'Las ventas han disminuido',
    label: 'Las ventas han disminuido',
  },
  {
    value: 'El precio de la suscripción es muy elevado para mí',
    label: 'El precio de la suscripción es muy elevado para mí',
  },
  {
    value: 'Tuve una mala experiencia con el servicio',
    label: 'Tuve una mala experiencia con el servicio',
  },
  {
    value: 'Ya conseguí otro contador',
    label: 'Ya conseguí otro contador',
  },
  {
    value: 'No me gustó la plataforma en línea',
    label: 'No me gustó la plataforma en línea',
  },
];

export const CANCEL_SUBSCRIPTION_INITIAL_VALUES = {
  cancellationReason: [],
  suspendMyBusiness: false,
  otherSuspendMyBusiness: '',
  salesAreDown: false,
  otherSalesAreDown: '',
  priceIsTooHigh: false,
  badExperienceOptions: [],
  otherBadExperience: '',
  badExperience: false,
  priceIsTooHighOption: null,
  otherPriceIsTooHigh: '',
  anotherAccountant: false,
  anotherAccountantOptions: [],
  otherAnotherAccountant: '',
  unlikePlatform: false,
  unlikePlatformOptions: [],
  otherUnlikePlatform: '',
  otherReason: '',
  likelyToRecommend: '',
};

export const BILLING_PERIOD_QUANTITY = {
  monthly: 1,
  biannual: 6,
  yearly: 12,
};

export const DURATION_TO_BILLING_PERIOD = {
  1: ['Mensual', 'mes'],
  6: ['Semestral', 'semestre'],
  12: ['Anual', 'año'],
};

export const PLAN_NAMES = {
  standard_plan: 'Plan pro',
  starter_plan: 'Plan básico',
  started_plan: 'Plan básico',
  assisted_plan: 'Plan asistido',
  personalized_plan: 'Plan personalizado',
};

export const EXTRA_SERVICE_NAMES = {
  extra_regime: 'Régimen extra',
  extra_obligations: 'Obligaciones extra',
};

export const BAD_EXPERIENCES_OPTIONS = [
  {
    value: 1,
    label: 'Servicio al cliente',
  },
  {
    value: 2,
    label: 'Proceso de bienvenida',
  },
  {
    value: 3,
    label: 'Regularizaciones fiscales',
  },
  {
    value: 4,
    label: 'La asesoría inicial',
  },
  {
    value: 5,
    label: 'El trato del equipo contable',
  },
  {
    value: 6,
    label: 'Otro',
  },
];
export const CHANGE_ACCOUNTANT_OPTIONS = [
  {
    value: 1,
    label: 'El precio de Konta es muy elevado',
  },
  {
    value: 2,
    label: 'Tuve una mala experiencia con el servicio de Konta',
  },
  {
    value: 3,
    label: 'Busco un servicio más personalizado',
  },
  {
    value: 4,
    label: 'Otro',
  },
];
export const DISLIKE_OPTIONS = [
  {
    value: 1,
    label: 'La plataforma en línea es confusa',
  },
  {
    value: 2,
    label: 'La plataforma en línea no tiene las funcionalidades que necesito',
  },
  {
    value: 3,
    label: 'El portal de facturación no cumple con mis necesidades',
  },
  {
    value: 4,
    label: 'El servicio al cliente es insuficiente',
  },
  {
    value: 5,
    label: 'Otro',
  },
];
export const APPROPRIATE_PRICE_OPTIONS = [
  {
    value: 1,
    label: '$100 ',
  },
  {
    value: 2,
    label: '$200',
  },
  {
    value: 3,
    label: '$300',
  },
  {
    value: 4,
    label: '$400',
  },
  {
    value: 5,
    label: '$500',
  },
  {
    value: 6,
    label: '$600',
  },
  {
    value: 7,
    label: 'Otro',
  },
];

export const cancelSubscriptionSchema = yup.object().shape({
  suspendMyBusiness: yup.boolean(),
  otherSuspendMyBusiness: yup.string().when('salesAreDown', {
    is: true,
    then: yup.string().required('Campo requerido'),
  }),
  salesAreDown: yup.boolean(),
  otherSalesAreDown: yup.string().when('salesAreDown', {
    is: true,
    then: yup.string().required('Campo requerido'),
  }),
  priceIsTooHigh: yup.boolean(),
  priceIsTooHighOption: yup
    .object()
    .nullable()
    .when('priceIsTooHigh', {
      is: true,
      then: yup.object().nullable().required('Campo requerido'),
    }),
  otherPriceIsTooHigh: yup.string().when('priceIsTooHighOption', {
    is: (option: ReactSelectItemProps<string>) => option?.label === 'Otro',
    then: yup.string().required('Campo requerido'),
  }),
  anotherAccountant: yup.boolean(),
  anotherAccountantOptions: yup.array().when('anotherAccountant', {
    is: true,
    then: yup.array().min(1, 'Selecciona al menos una opción.'),
  }),
  otherAnotherAccountant: yup.string().when('anotherAccountantOptions', {
    is: (values: ReactSelectItemProps<string>[]) =>
      values.some?.((opt) => opt?.label === 'Otro'),
    then: yup.string().required('Campo requerido'),
  }),
  unlikePlatform: yup.boolean(),
  unlikePlatformOptions: yup.array().when('unlikePlatform', {
    is: true,
    then: yup.array().min(1, 'Selecciona al menos una opción.'),
  }),
  otherUnlikePlatform: yup.string().when('unlikePlatformOptions', {
    is: (values: ReactSelectItemProps<string>[]) =>
      values.some?.((opt) => opt?.label === 'Otro'),
    then: yup.string().required('Campo requerido'),
  }),
  badExperience: yup.boolean(),
  badExperienceOptions: yup.array().when('badExperience', {
    is: true,
    then: yup.array().min(1, 'Selecciona al menos una opción.'),
  }),
  otherBadExperience: yup.string().when('badExperienceOptions', {
    is: (values: ReactSelectItemProps<string>[]) =>
      values.some?.((opt) => opt?.label === 'Otro'),
    then: yup.string().required('Campo requerido'),
  }),
  cancellationReason: yup
    .array()
    .min(1, 'Selecciona al menos un motivo de cancelación.'),
  otherReason: yup.string().required('Comentario requerido'),
  likelyToRecommend: yup
    .number()
    .typeError('Se requiere un número')
    .required('Recomendación requerida')
    .min(0, 'La recomendación debe ser mayor o igual que 0')
    .max(10, 'La recomendación debe ser menor o igual que 10'),
});
