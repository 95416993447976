import camelcaseKeys from 'camelcase-keys';
import type { DeclarationIncome } from 'types/entities';
import {
  getTotalWithIvaOfManualInput,
  getTotalZeroBasesOfManualInput,
  getTotalExemptBasesOfManualInput,
  getTotalWithIva16OfManualInput,
  getTotalWithIva8OfManualInput,
  getTotalBases,
  getTotalIvaBases,
  getTotalIva,
  getTotal,
  getTotalExemptBases,
  getTotalZeroBases,
  getTotalZeroBasesUnbilled,
  getTotalExemptBasesUnbilled,
  getTotalBasesWithIva,
  getTotalBasesOfCfdis,
  getTotalTaxableBasesOfCfdis,
  getTotalIvaBasesOfCfdis,
  getTotalIvaExemptBasesOfCfdis,
  getTotalZeroBasesOfCfdis,
  getTotalIvaOfCfdis,
  getTotalRetainedOfCfdis,
  getTotalBasesWithIvaOfCfdis,
  getTotalUnbilled,
  getTotalIvaBasesUnbilled,
  getTotalIvaUnbilled,
  getTotalBasesWithIvaUnbilled,
  getTotalRetainedIvaOfCfdis,
  getTotalRetainedIsrOfCfdis,
  getTotalIvaBase8Unbilled,
  getTotalIvaBase16Unbilled,
  getTotalIva8Bases,
  getTotalIva16Bases,
  getTotalOfPublicInvoice,
  getSubTotalOfPublicInvoice,
  getTotalTaxes,
  getTotalRetentions,
  getTotalIVA8,
  getTotalIVA16,
  getGrandTotalBases,
  getGrandTotal,
  getSumIvaBasesOfConstancies,
  getSumExemptBasesOfConstancies,
  getExtraIva,
  getExtraZeroBases,
  getTotalRetainedIva,
  getSumRetainedIsrOfConstancies,
  getTaxesWithheldOfPayroll,
  getEmploymentSubsidyOfPayroll,
  getSumIvaBase16OfManualInput,
  getSumIvaBase8OfManualInput,
  getSumIvaBasesOfEgress,
  getAccruedIsrRetention,
  getTotalRetainedIsr,
  getSumRetainedIvaOfConstancies,
  getExtraIvaBases16,
  getExtraIvaBases8,
  getExtraExemptBases,
} from './getDeclarationTotalCalculations';

export default function getTotalsFromDeclarationIncome(
  declarationIncome: DeclarationIncome,
) {
  return {
    totalWithIvaOfManualInput: getTotalWithIvaOfManualInput(declarationIncome),
    totalZeroBasesOfManualInput:
      getTotalZeroBasesOfManualInput(declarationIncome),
    totalExemptBasesOfManualInput:
      getTotalExemptBasesOfManualInput(declarationIncome),
    totalWithIva16OfManualInput:
      getTotalWithIva16OfManualInput(declarationIncome),
    totalWithIva8OfManualInput:
      getTotalWithIva8OfManualInput(declarationIncome),
    totalBases: getTotalBases(declarationIncome),
    totalIvaBases: getTotalIvaBases(declarationIncome),
    totalIva: getTotalIva(declarationIncome),
    total: getTotal(declarationIncome),
    totalExemptBases: getTotalExemptBases(declarationIncome),
    totalZeroBases: getTotalZeroBases(declarationIncome),
    totalZeroBasesUnbilled: getTotalZeroBasesUnbilled(declarationIncome),
    totalExemptBasesUnbilled: getTotalExemptBasesUnbilled(declarationIncome),
    totalBasesWithIva: getTotalBasesWithIva(declarationIncome),
    totalBasesOfCfdis: getTotalBasesOfCfdis(declarationIncome),
    totalTaxableBasesOfCfdis: getTotalTaxableBasesOfCfdis(declarationIncome),
    totalIvaBasesOfCfdis: getTotalIvaBasesOfCfdis(declarationIncome),
    totalIvaExemptBasesOfCfdis:
      getTotalIvaExemptBasesOfCfdis(declarationIncome),
    totalZeroBasesOfCfdis: getTotalZeroBasesOfCfdis(declarationIncome),
    totalIvaOfCfdis: getTotalIvaOfCfdis(declarationIncome),
    totalRetainedOfCfdis: getTotalRetainedOfCfdis(declarationIncome),
    totalBasesWithIvaOfCfdis: getTotalBasesWithIvaOfCfdis(declarationIncome),
    totalUnbilled: getTotalUnbilled(declarationIncome),
    totalIvaBasesUnbilled: getTotalIvaBasesUnbilled(declarationIncome),
    totalIvaUnbilled: getTotalIvaUnbilled(declarationIncome),
    totalBasesWithIvaUnbilled: getTotalBasesWithIvaUnbilled(declarationIncome),
    totalRetainedIvaOfCfdis: getTotalRetainedIvaOfCfdis(declarationIncome),
    totalRetainedIsrOfCfdis: getTotalRetainedIsrOfCfdis(declarationIncome),
    totalIvaBase8Unbilled: getTotalIvaBase8Unbilled(declarationIncome),
    totalIvaBase16Unbilled: getTotalIvaBase16Unbilled(declarationIncome),
    totalIva8Bases: getTotalIva8Bases(declarationIncome),
    totalIva16Bases: getTotalIva16Bases(declarationIncome),
    totalOfPublicInvoice: getTotalOfPublicInvoice(declarationIncome),
    subTotalOfPublicInvoice: getSubTotalOfPublicInvoice(declarationIncome),
    totalTaxes: getTotalTaxes(declarationIncome),
    totalRetentions: getTotalRetentions(declarationIncome),
    totalIVA8: getTotalIVA8(declarationIncome),
    totalIVA16: getTotalIVA16(declarationIncome),
    grandTotalBases: getGrandTotalBases(declarationIncome),
    grandTotal: getGrandTotal(declarationIncome),
    sumIvaBasesOfConstancies: getSumIvaBasesOfConstancies(declarationIncome),
    sumExemptBasesOfConstancies:
      getSumExemptBasesOfConstancies(declarationIncome),
    extraIva: getExtraIva(declarationIncome),
    extraZeroBases: getExtraZeroBases(declarationIncome),
    totalRetainedIva: getTotalRetainedIva(declarationIncome),
    sumRetainedIsrOfConstancies:
      getSumRetainedIsrOfConstancies(declarationIncome),
    taxesWithheldOfPayroll: getTaxesWithheldOfPayroll(declarationIncome),
    employmentSubsidyOfPayroll:
      getEmploymentSubsidyOfPayroll(declarationIncome),
    sumIvaBase16OfManualInput: getSumIvaBase16OfManualInput(declarationIncome),
    sumIvaBase8OfManualInput: getSumIvaBase8OfManualInput(declarationIncome),
    sumIvaBasesOfEgress: getSumIvaBasesOfEgress(declarationIncome),
    accruedIsrRetention: getAccruedIsrRetention(declarationIncome),
    totalRetainedIsr: getTotalRetainedIsr(declarationIncome),
    sumRetainedIvaOfConstancies:
      getSumRetainedIvaOfConstancies(declarationIncome),
    extraIvaBases16: getExtraIvaBases16(declarationIncome),
    extraIvaBases8: getExtraIvaBases8(declarationIncome),
    extraExemptBases: getExtraExemptBases(declarationIncome),
  };
}
