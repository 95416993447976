import _ from 'lodash';
import {
  AccountingStatus,
  DeclarationEntryType,
  EntrySourceType,
  GeneralDeclarationEntryType,
} from 'types/entities';

export type EntrySourceTypeFilter = EntrySourceType | 'CreditNote';

export const entryTypeNames: Record<DeclarationEntryType, string> = {
  foreign_income: 'Ingreso del extranjero',
  national_income: 'Ingreso nacional',
  foreign_expenses: 'Gastos en el extranjero',
  national_expenses: 'Gastos nacionales',
  foreign_products: 'Productos extranjeros',
  foreign_invoices: 'Facturas extranjeras',
  national_egress_received: 'Egresos nacionales recibidos',
  pastmonths_cancelled_received: 'Cancelaciones de meses anteriores recibidas',
  pastmonths_egress_cancelled_received:
    'Egresos cancelados de meses anteriores recibidos',
  national_egress_issued: 'Egresos nacionales emitidos',
  national_expenses_payment: 'Pago de gastos nacionales',
  national_income_payment: 'Pago de ingresos nacionales',
  pastmonths_cancelled_issued: 'Cancelaciones de meses anteriores emitidas',
  pastmonths_egress_cancelled_issued:
    'Egresos cancelados de meses anteriores emitidos',
};

export const sourceTypeTranslation: Record<EntrySourceTypeFilter, string> = {
  Cfdi: 'Factura',
  CancelledCfdi: 'Factura cancelada',
  ForeignProduct: 'Pedimento de importación',
  ForeignInvoice: 'Factura Extranjera',
  FixedAsset: 'Activo Fijo',
  Constancy: 'Constancia',
  PaymentDetail: 'Complemento de pago',
  ExtraIncome: 'Ingreso Extra',
  BankTransaction: 'Movimiento Bancario',
  CreditNote: 'Nota de Crédito',
};

export const SOURCE_TYPE_FILTER_CLIENT_OPTS = Object.values(
  sourceTypeTranslation,
).map((label) => ({
  label,
  value: label,
}));
export const SOURCE_TYPE_FILTER_CLIENT_KEYS_OPTS = Object.entries(
  sourceTypeTranslation,
).map(([value, label]) => ({
  value,
  label,
}));

export const accountingStatusTranslation: Record<AccountingStatus, string> = {
  is_deductible: 'Si entra',
  not_deductible: 'No entra',
  not_paid: 'No está pagada',
  paid: 'Ya contemplada en meses pasados',
  fixed_asset: 'Activo fijo',
  annual: 'Considerado en la anual',
  cancelled: 'Cancelada',
  pending_classification: 'Pendiente de clasificación',
  in_review: 'En revisión',
};

export const ACCOUNTING_STATUS_FILTER_CLIENT_OPTS = Object.values(
  accountingStatusTranslation,
).map((label) => ({
  label,
  value: label,
}));
export const ACCOUNTING_STATUS_FILTER_CLIENT_KEYS_OPTS = Object.entries(
  accountingStatusTranslation,
).map(([value, label]) => ({
  label,
  value,
}));

export const generalDeclarationEntryTypeTranslation: Record<
  GeneralDeclarationEntryType,
  string
> = {
  income: 'Ingresos',
  expenses: 'Gastos',
};

export const GENERAL_DECLARATION_ENTRY_TYPE_FILTER_CLIENT_OPTS = Object.values(
  generalDeclarationEntryTypeTranslation,
).map((label) => ({
  label,
  value: label,
}));

export const DECLARATION_ENTRY_TYPE_OPTS = (
  Object.keys(entryTypeNames) as DeclarationEntryType[]
).map((key) => ({
  label: entryTypeNames[key],
  value: entryTypeNames[key],
}));

export const expenseCategories = [
  'devoluciones_nacionales',
  'devoluciones_extranjeras',
  'adquisiciones_nacionales',
  'adquisiciones_extranjeras',
  'perdidas_creditos',
  'aportaciones_sar',
  'cuotas_imss',
  'sueldos_nacionales',
  'sueldos_discapacidad',
  'sueldos_mayores',
  'maniobras',
  'viaticos',
  'intereses',
  'honorarios',
  'regalias',
  'uso_bienes',
  'seguros',
  'fletes',
  'combustibles',
  'contribuciones',
  'impuesto_local',
  'deduccion_automoviles',
  'deduccion_electricos',
  'monto_47',
  'monto_53',
  'consumo_restaurantes',
  'gasolina_transporte',
  'deduccion_mano_obra',
  'deduccion_libros',
  'deduccion_donativos',
  'gastos',
  'productos_semiterminados',
  'materias_primas',
  'transferencia_tecnologia',
  'gastos_viaje_automoviles',
  'gastos_viaje_hospedaje',
  'perdidas_caso_fortuito',
  'perdidas_enajenacion',
];
export const EXPENSES_CATEGORY_OPTS = expenseCategories.map((category) => ({
  // change _ to blank space
  label: _.capitalize(category.replace(/_/g, ' ')),
  value: _.capitalize(category.replace(/_/g, ' ')),
}));

export const USE_CFDI_OPTIONS = [
  {
    label: 'Adquisición de mercancias',
    value: 'Adquisición de mercancias',
  },
  {
    label: 'Devoluciones, descuentos o bonificaciones',
    value: 'Devoluciones, descuentos o bonificaciones',
  },
  {
    label: 'Gastos en general',
    value: 'Gastos en general',
  },
  {
    label: 'Construcciones',
    value: 'Construcciones',
  },
  {
    label: 'Mobilario y equipo de oficina por inversiones',
    value: 'Mobilario y equipo de oficina por inversiones',
  },
  {
    label: 'Equipo de transporte',
    value: 'Equipo de transporte',
  },
  {
    label: 'Equipo de computo y accesorios',
    value: 'Equipo de computo y accesorios',
  },
  {
    label: 'Dados, troqueles, moldes, matrices y herramental',
    value: 'Dados, troqueles, moldes, matrices y herramental',
  },
  {
    label: 'Comunicaciones telefonicas',
    value: 'Comunicaciones telefonicas',
  },
  {
    label: 'Comunicaciones satelitales',
    value: 'Comunicaciones satelitales',
  },
  {
    label: 'Otra maquinaria y equipo',
    value: 'Otra maquinaria y equipo',
  },
  {
    label: 'Honorarios medicos, dentales y gastos hospitalarios.',
    value: 'Honorarios medicos, dentales y gastos hospitalarios.',
  },
  {
    label: 'Gastos medicos por incapacidad o discapacidad',
    value: 'Gastos medicos por incapacidad o discapacidad',
  },
  {
    label: 'Gastos funerales.',
    value: 'Gastos funerales.',
  },
  {
    label: 'Donativos.',
    value: 'Donativos.',
  },
  {
    label:
      'Intereses reales efectivamente pagados por creditos hipotecarios (casa habitacion).',
    value:
      'Intereses reales efectivamente pagados por creditos hipotecarios (casa habitacion).',
  },
  {
    label: 'Aportaciones voluntarias al SAR.',
    value: 'Aportaciones voluntarias al SAR.',
  },
  {
    label: 'Primas por seguros de gastos medicos.',
    value: 'Primas por seguros de gastos medicos.',
  },
  {
    label: 'Gastos de transportacion escolar obligatoria.',
    value: 'Gastos de transportacion escolar obligatoria.',
  },
  {
    label:
      'Depositos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
    value:
      'Depositos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
  },
  {
    label: 'Pagos por servicios educativos (colegiaturas)',
    value: 'Pagos por servicios educativos (colegiaturas)',
  },
  {
    label: 'Sin efectos fiscales',
    value: 'Sin efectos fiscales',
  },
];
export const authorOptions = [
  {
    label: 'Contador',
    value: 'Contador',
  },
  {
    label: 'Usuario',
    value: 'Usuario',
  },
  {
    label: 'Sistema',
    value: 'Sistema',
  },
  {
    label: 'AI',
    value: 'AI',
  },
];
export const changeLogAuthorColors = {
  Contador: 'yellow' as const,
  Usuario: 'green' as const,
  Sistema: 'blue' as const,
  AI: 'primary' as const,
};
