import { useEffect, useMemo, useState } from 'react';
import Loader from 'shared/components/Loader';
import { ImgStep } from 'types/flows';
import { Alert, Flex, type CSS } from '@konta/ui';
import { ImageContainer, FadeInImage } from './styled';

interface ImageStepContentProps {
  step: ImgStep;
  setPreviewContainerCss?: (css: CSS) => void;
}

export default function ImageStepContent({
  step,
  setPreviewContainerCss,
}: ImageStepContentProps) {
  const { params } = step;
  const { url } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const parsedUrl = useMemo(() => {
    if (url.startsWith('http')) {
      return url;
    }
    return `${process.env.PUBLIC_URL || ''}/${url}`;
  }, [url]);

  const handleImageLoad = () => {
    setIsLoading(false);
    setPreviewContainerCss?.({
      backgroundImage: `url(${parsedUrl})`,
      backgroundPosition: 'center',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      '@md': {
        backgroundSize: 'cover',
      },
      '&::before': {
        content: 'none',
      },
    });
  };

  const handleImageError = () => {
    setError(true);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setError(false);
    setPreviewContainerCss?.({});
    return () => {
      setPreviewContainerCss?.({});
    };
  }, [url, setPreviewContainerCss]);

  if (error) {
    return (
      <Flex column>
        <Alert color="error">Error al cargar la imagen</Alert>
      </Flex>
    );
  }

  return (
    <ImageContainer>
      {isLoading && <Loader />}
      <FadeInImage
        alt="Step content"
        onLoad={handleImageLoad}
        src={parsedUrl}
        onError={handleImageError}
      />
    </ImageContainer>
  );
}
