import React from 'react';
import { Row } from 'reactstrap';
import { SimpleStateButton, Colxx } from '@components';

export function SubmitButton({
  loading,
  text = 'Enviar',
  className = '',
  handler,
}) {
  return (
    <Row data-testid="state-button-head">
      <Colxx xs={12} className={className} data-testid="state-button-row">
        <SimpleStateButton
          size="md"
          color="primary"
          loading={loading}
          disabled={loading}
          onClick={handler}
          data-testid="state-button"
        >
          {text}
        </SimpleStateButton>
      </Colxx>
    </Row>
  );
}
