import api from '@api';
import { FISCAL_REGIMES } from '@constants/reactQueries';
import { useQuery } from 'react-query';

export default function useFiscalRegimesQuery() {
  const {
    data = [],
    isLoading,
    ...rest
  } = useQuery(FISCAL_REGIMES, async () => {
    const response = await api.get('/fiscal_regimes', {
      headers: {
        Authorization: localStorage.getItem('user_id'),
      },
    });
    return response.data;
  });

  return { loadingRegimens: isLoading, regimes: data, ...rest };
}
