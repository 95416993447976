import { useMemo, useState } from 'react';
import { Filters } from '@konta/ui';
import dayjs from 'dayjs';
import useCfdisByClients from 'shared/hooks/useCfdisByClients';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

interface UsePreviousPeriodsProps {
  source: 'incomes' | 'expenses';
  currentDateFilter: 'currentMonth' | 'currentYear' | string;
  cfdi: {
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  };
  openModal: boolean;
}
export default function useCfdisTotalsModal({
  source,
  currentDateFilter,
  cfdi,
  openModal,
}: UsePreviousPeriodsProps) {
  const [filters, setFilters] = useState<Filters>([]);

  const dates = useMemo(() => {
    const currentDate = dayjs();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month();

    if (currentDateFilter === 'currentMonth') {
      const startDate = dayjs(new Date(currentYear, currentMonth, 1)).format(
        'YYYY-MM-DD',
      );
      const endDate = currentDate.endOf('month').format('YYYY-MM-DD');
      return { startDate, endDate };
    }
    if (currentDateFilter === 'currentYear') {
      const startDate = dayjs(new Date(currentYear, 0, 1)).format('YYYY-MM-DD');
      const endDate = currentDate.format('YYYY-MM-DD');
      return { startDate, endDate };
    }
    return { startDate: '', endDate: '' };
  }, [currentDateFilter]);

  const clientParam =
    source === 'incomes' ? 'receiver_rfc_eq' : 'issuer_rfc_eq';

  const { cfdis, cfdisLoading } = useCfdisByClients({
    endDate: dates.endDate,
    startDate: dates.startDate,

    axiosConfig: {
      params: {
        [`q[is_emited_${source === 'incomes' ? 'true' : 'false'}]`]: 1,
        [`q[${clientParam}]`]: cfdi.rfc,
      },
    },
    queryOptions: {
      enabled: openModal,
    },
  });

  const periodWithInvoices = useMemo(() => {
    const currentDate = dayjs();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month();
    let periods = [];

    if (currentDateFilter === 'currentMonth') {
      periods = [currentDate.format('YYYY-MM-DD')];
    } else if (currentDateFilter === 'currentYear') {
      for (let month = currentMonth; month >= 0; month--) {
        periods.push(dayjs(new Date(currentYear, month)).format('YYYY-MM-DD'));
      }
    }

    return periods.map((period) => {
      const startDate = dayjs(period).startOf('month').format('YYYY-MM-DD');
      const endDate = dayjs(period).endOf('month').format('YYYY-MM-DD');

      const invoices = cfdis?.filter((cfdiItem) => {
        const dateIssued = dayjs(cfdiItem.date_issued);
        return (
          dateIssued.isSameOrAfter(startDate) &&
          dateIssued.isSameOrBefore(endDate)
        );
      });

      return {
        period,
        invoices,
      };
    });
  }, [currentDateFilter, cfdis]);

  return {
    filters,
    setFilters,
    cfdisLoading,
    cfdis,
    periodWithInvoices,
    dates,
  };
}
