import { useQuery } from 'react-query';
import { getClientSuppliers } from 'shared/api/clientSuppliers';
import { CLIENT_SUPPLIERS } from 'shared/constants/reactQueries';
import type { AxiosRequestConfig } from 'axios';
import type { ClientSupplier } from 'types/entities';
import type { UseQueryOptions } from 'react-query';

export default function useClientSuppliers(
  taxableEntityId: number | string,
  config?: AxiosRequestConfig<ClientSupplier[]>,
  queryOptions?: UseQueryOptions<ClientSupplier[]>,
) {
  const {
    data = [],
    isLoading,
    isFetching,
    ...rest
  } = useQuery<ClientSupplier[]>(
    [CLIENT_SUPPLIERS, taxableEntityId, config],
    async () => {
      const clientSuppliers = await getClientSuppliers(taxableEntityId, config);
      return clientSuppliers;
    },
    queryOptions,
  );

  return {
    clientSuppliersLoading: isLoading || isFetching,
    clientSuppliers: data,
    ...rest,
  };
}
