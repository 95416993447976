import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '@components/CustomBootstrap';
import QRCode from 'react-qr-code';
import { useSelector, shallowEqual } from 'react-redux';
import { Loader } from '@components/Utils/Loading';

export default function (_props) {
  const { current: fp, loading } = useSelector(
    (state) => state.fiscalPeriods,
    shallowEqual
  );
  if (!fp) return null;
  return (
    <Loader loading={loading}>
      <Row>
        <Colxx xxs="12" className="mb-4">
          <Row className="mt-4">
            <Colxx
              md={4}
              xxs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <QRCode size={160} value={fp.capture_line || 'NA'} />
            </Colxx>
            <Colxx md={8} xxs={12}>
              <h2>Pago con CODI</h2>
              <p>
                Ingresa a tu banco en la applicación movil. Escoja la opción de
                pagar con CodDi y escaneé el codigo de barras que verá en
                pantalla. Una vez pagado espere un par de días y su pago se verá
                reflejado en Konta como pagado.
              </p>
            </Colxx>
          </Row>
        </Colxx>
      </Row>
    </Loader>
  );
}
