import React, { FocusEvent } from 'react';
import { Checkbox, Flex, Text } from '@konta/ui';
import { useField, useFormikContext } from 'formik';

export type CheckedState = boolean | 'indeterminate';
interface FormikCheckboxProps {
  disabled?: boolean;
  onBlur?: (e: FocusEvent<HTMLButtonElement>) => void;
  onChange?: (checked: CheckedState) => void;
  label?: string;
  name: string;
  size?: 'xs' | 's' | 'm' | 'l';
  supportText?: string;
  helperText?: string;
  id?: string;
}
export default function FormikCheckbox({
  name,
  label,
  onChange,
  onBlur,
  id,
  helperText,
  size = 's',
  disabled = false,
  ...restProps
}: FormikCheckboxProps) {
  const formik = useFormikContext();
  const [field, meta, helpers] = useField({
    name,
  });

  const touched = meta.touched || formik.submitCount > 0;
  const error = meta.error && touched ? meta.error : null;

  const handleChange = (checked: CheckedState) => {
    helpers.setValue(checked);
    if (onChange) {
      onChange(checked);
    }
  };
  const handleBlur = (e: FocusEvent<HTMLButtonElement, Element>) => {
    helpers.setTouched(true);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Flex direction="column" gap={6} css={{ flex: 1 }}>
      <Checkbox
        {...field}
        {...restProps}
        id={id || name}
        disabled={disabled}
        size={size}
        name={name}
        label={label}
        onBlur={handleBlur}
        checked={field.value as CheckedState}
        onCheckedChange={handleChange}
      />
      {helperText && (
        <Text
          xs
          lineHeight="xs"
          color="gray400"
          css={{ fontWeight: 200, fontStyle: 'normal', fontFamily: '$sans' }}
        >
          {helperText}
        </Text>
      )}
      {error && (
        <Text xs lineHeight="xs" color="error500">
          {error}
        </Text>
      )}
    </Flex>
  );
}
