import { FileShield02LineIcon } from '@konta/icons';
import ContainerButton from 'shared/components/ContainerButton';
import usePreviousPeriods from './usePreviousPeriods';
import PreviousPeriodsModalTable from './PreviousPeriodsModalTable';

interface PreviousPeriodsProps {
  source: 'incomes' | 'expenses';
}
export default function PreviousPeriods({ source }: PreviousPeriodsProps) {
  const {
    openModalTable,
    toggleOpenModalTable,
    regimeOptionsWithDeclarationId,
    workflow,
    updateCurrentWorkflow,
  } = usePreviousPeriods();

  return (
    <>
      <ContainerButton
        icon={<FileShield02LineIcon />}
        title="Seleccionar facturas de periodos anteriores"
        onClick={toggleOpenModalTable}
      />
      {openModalTable && (
        <PreviousPeriodsModalTable
          updateCurrentWorkflow={updateCurrentWorkflow}
          source={source}
          workflow={workflow}
          toggleIsOpen={toggleOpenModalTable}
          isOpen={openModalTable}
          regimeOptionsWithDeclarationId={regimeOptionsWithDeclarationId}
        />
      )}
    </>
  );
}
