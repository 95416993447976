import {
  WORKFLOWS_FETCH,
  WORKFLOWS_FETCH_SUCCESS,
  WORKFLOWS_FETCH_FAILURE,
  SHOW_WORKFLOW_FETCH,
  SHOW_WORKFLOW_FETCH_SUCCESS,
  SHOW_WORKFLOW_FETCH_FAILURE,
  WORKFLOW_UPDATE,
  WORKFLOW_UPDATE_SUCCESS,
  WORKFLOW_UPDATE_FAILURE,
  SET_WORKFLOW_PHASE_TRANSITION,
  WORKFLOW_PREVIOUS_PHASE,
  WORKFLOW_PREVIOUS_PHASE_SUCCESS,
  WORKFLOW_PREVIOUS_PHASE_FAILURE,
  WORKFLOW_NEXT_PHASE,
  WORKFLOW_NEXT_PHASE_SUCCESS,
  WORKFLOW_NEXT_PHASE_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  workflows: [],
  selected_workflow: {
    workflow: {},
    loading: false,
    error: false,
  },
  ui: {
    phase_transition: 'phase-front',
  },
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WORKFLOWS_FETCH:
      return {
        ...state,
        loading: true,
      };
    case WORKFLOWS_FETCH_SUCCESS:
      return {
        ...state,
        workflows: action.payload,
        loading: false,
      };
    case WORKFLOWS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SHOW_WORKFLOW_FETCH:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: true,
        },
      };
    case SHOW_WORKFLOW_FETCH_SUCCESS:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: false,
          error: false,
          workflow: {
            ...state.selected_workflow.workflow,
            ...action.payload,
            active_declaration:
              action.payload.declarations.find(
                (declaration) => declaration.is_active,
              ) || action.payload.declarations[0],
            active_declarations: action.payload.declarations.filter(
              (declaration) => declaration.is_active,
            ),
          },
        },
      };
    case SHOW_WORKFLOW_FETCH_FAILURE:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: false,
          error: true,
          workflow: {},
        },
      };
    case WORKFLOW_UPDATE:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: true,
        },
        loading: true,
      };
    case WORKFLOW_UPDATE_SUCCESS:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: false,
          workflow: {
            ...state.selected_workflow.workflow,
            ...action.payload,
            active_declaration:
              action.payload.declarations.find(
                (declaration) => declaration.is_active,
              ) || action.payload.declarations[0],
            active_declarations: action.payload.declarations.filter(
              (declaration) => declaration.is_active,
            ),
          },
        },
        loading: false,
      };
    case WORKFLOW_UPDATE_FAILURE:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: false,
        },
        loading: false,
        error: true,
      };
    case SET_WORKFLOW_PHASE_TRANSITION:
      return {
        ...state,
        ui: {
          phase_transition: action.payload,
        },
      };
    case WORKFLOW_PREVIOUS_PHASE:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: true,
        },
      };
    case WORKFLOW_PREVIOUS_PHASE_SUCCESS:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: false,
          workflow: {
            ...state.selected_workflow.workflow,
            ...action.payload,
            active_declaration:
              action.payload.declarations.find(
                (declaration) => declaration.is_active,
              ) || action.payload.declarations[0],
            active_declarations: action.payload.declarations.filter(
              (declaration) => declaration.is_active,
            ),
          },
        },
      };
    case WORKFLOW_PREVIOUS_PHASE_FAILURE:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: false,
          error: true,
        },
      };
    case WORKFLOW_NEXT_PHASE:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: true,
        },
      };
    case WORKFLOW_NEXT_PHASE_SUCCESS:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: false,
          workflow: action.payload,
        },
      };
    case WORKFLOW_NEXT_PHASE_FAILURE:
      return {
        ...state,
        selected_workflow: {
          ...state.selected_workflow,
          loading: false,
          error: true,
        },
      };
    default:
      return { ...state };
  }
};
