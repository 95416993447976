import React, { useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Flex } from '@konta/ui';
import FormikTextarea from '@components/FormikTextarea';
import FormikKontaSelect from '@components/FormikKontaSelect';
import useProcess from '@hooks/useProcess';
import { ProcessType } from 'types/entities.ts';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepDescription from '@components/ProcessStepDescription';
import NextStepButton from '@components/NextStepButton';
import ProcessStepLayout from '@components/ProcessStepLayout';
import OnboardingField from '../OnboardingField';
import useOnboardingHelpFeature from '../useOnboardingHelpFeature';

const HELP_FEATURES = {
  hasForeignProducts: {
    title: '¿Cómo saber si compro productos en el extranjero?',
    description:
      'Esto significaría que adquieres productos de otras empresas o fabricantes en otras partes del mundo y luego los importas a México para venderlos a tus clientes aquí.',
  },
  signedOnImporterList: {
    title: '¿Qué es el padrón de importadores?',
    description:
      'El padrón de importadores es una lista de empresas o personas que hacen importaciones a un país. Se usa para controlar y registrar las importaciones y es mantenida por el gobierno. Los importadores tienen que registrarse en el padrón para hacer importaciones legales y pagar impuestos y aranceles.',
  },
};
const process = ProcessType.OnboardingOnboardingProcess;

const validationSchema = yup.object().shape({
  commonExpenses: yup.string().required('Campo requerido'),
  hasForeignProducts: yup.object().nullable().required('Campo requerido'),
  signedOnImporterList: yup.object().nullable().required('Campo requerido'),
});

const options = [
  { value: true, label: 'Si' },
  { value: false, label: 'No' },
];

function useInitialValues() {
  const { activeStep } = useProcess(process);

  const initialValues = useMemo(() => {
    const hasForeignProducts = options.find(
      (opt) => opt.value === activeStep?.answers.has_foreign_products,
    );
    const signedOnImporterList = options.find(
      (opt) => opt.value === activeStep?.answers.signed_on_importer_list,
    );

    const notValue = options.find((opt) => !opt.value);

    return {
      hasForeignProducts: hasForeignProducts || notValue,
      signedOnImporterList: signedOnImporterList || notValue,
      commonExpenses: activeStep?.answers.common_expenses,
    };
  }, [
    activeStep?.answers.common_expenses,
    activeStep?.answers.has_foreign_products,
    activeStep?.answers.signed_on_importer_list,
  ]);

  return initialValues;
}

export default function OnboardingExpenses() {
  const [payload, setPayload] = useState(null);
  const { currentHelpFeature, addHelpFeature, removeHelpFeature } =
    useOnboardingHelpFeature({ helpFeatures: HELP_FEATURES });
  const initialValues = useInitialValues();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      setPayload({
        common_expenses: values.commonExpenses,
        has_foreign_products: values.hasForeignProducts.value,
        signed_on_importer_list: values.signedOnImporterList.value,
      });
    },
  });

  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Apertura de cuenta"
      titleChip="En curso"
      content={
        <FormikProvider value={formik}>
          <Flex direction="column" gap={12}>
            <ProcessStepTitle>Platícanos de tus gastos</ProcessStepTitle>
            <ProcessStepDescription>
              En este formulario platícanos sobre los gastos que tiene tu
              empresa o servicio profesional. Un gasto es cuando se produce una
              salida de dinero para adquirir un bien, servicio o transferir
              dinero a otra persona en espera de algo a cambio.
            </ProcessStepDescription>
            <Flex direction="column" css={{ maxWidth: '400px' }} gap={24}>
              <FormikTextarea
                data-cy="commonExpensesInput"
                rows={5}
                name="commonExpenses"
                placeholder="Ej. Mi gasto más común es la renta de un local... Gastamos mucho en gasolina para coche... Gasto en materiales de construcción, etc."
                label="¿Qué gastos son más comunes en tu negocio?"
              />
              <OnboardingField
                fieldName="hasForeignProducts"
                addHelpFeature={addHelpFeature}
                removeHelpFeature={removeHelpFeature}
              >
                <FormikKontaSelect
                  id="hasForeignProducts"
                  placeholder="No"
                  name="hasForeignProducts"
                  options={options}
                  label="¿Compras productos en el extranjero y los vendes en México?"
                  onBlur={removeHelpFeature}
                  onFocus={(e) => addHelpFeature(e, 'hasForeignProducts')}
                />
              </OnboardingField>

              <OnboardingField
                fieldName="signedOnImporterList"
                addHelpFeature={addHelpFeature}
                removeHelpFeature={removeHelpFeature}
              >
                <FormikKontaSelect
                  id="signedOnImporterList"
                  name="signedOnImporterList"
                  label="¿Estás dado de alta en el padrón de importadores?"
                  options={options}
                  placeholder="No"
                  onBlur={removeHelpFeature}
                  onFocus={(e) => addHelpFeature(e, 'signedOnImporterList')}
                />
              </OnboardingField>
            </Flex>

            <Flex css={{ pt: '$24' }}>
              <NextStepButton
                currentProcessType={process}
                text="Continuar"
                onClick={formik.submitForm}
                payload={payload}
                data-cy="onboardingExpensesNextButton"
                data-testid="onboarding-expenses-next"
              />
            </Flex>
          </Flex>
        </FormikProvider>
      }
      rightSidebarContent={currentHelpFeature}
    />
  );
}
