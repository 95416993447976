import { useMemo } from 'react';

export function calculateUploadTransactions({ rows, selectedRows }) {
  const amountSelected = selectedRows.reduce(
    (sumOfAmounts, currentValue) =>
      currentValue.original.bank_transaction.amount + sumOfAmounts,
    0,
  );

  const income = rows.reduce(
    (sumOfAmounts, currentValue) =>
      currentValue.bank_transaction.amount + sumOfAmounts,
    0,
  );

  const taxableIncome = rows
    .filter(({ accounting_status }) => accounting_status === 'is_taxable')
    .reduce(
      (sumOfAmounts, currentValue) =>
        currentValue.bank_transaction.amount + sumOfAmounts,
      0,
    );

  return { amountSelected, income, taxableIncome };
}

export default function useUploadTransactions({ rows, selectedRows }) {
  const totals = useMemo(() => {
    return calculateUploadTransactions({ rows, selectedRows });
  }, [rows, selectedRows]);

  return totals;
}
