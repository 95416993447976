import { AccumulatedIncomeAndExpense } from 'types/entities';

export default function getDataSetByIncomeOrExpense(
  label: string,
  dataType: keyof AccumulatedIncomeAndExpense,
  accumulatedData: AccumulatedIncomeAndExpense | null,
  xAxisLabel: string,
) {
  const data = accumulatedData?.[dataType];

  const totalsByDate = Object.entries(data || {}).reduce<
    Record<string, { [key: string]: number }>
  >((acc, [satKet, incomes]) => {
    Object.entries(incomes).forEach(([date, total]) => {
      acc[date] = {
        ...acc[date],
        total: (acc[date]?.total || 0) + +total,
        [satKet]: +total,
      };
    });
    return acc;
  }, {});

  const collection = Object.entries(totalsByDate).map(([date, totals]) => ({
    name: date,
    value: +totals.total,
    extraData: totals,
  }));

  return {
    label,
    currency: true,
    data: collection,
    xAxisLabel,
  };
}
