import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Col, Row } from 'reactstrap';
import Cards from 'react-credit-cards';
import BankCardImage from '@assets/img/payment-method/cards1.png';
import * as yup from 'yup';
import { Box, Button, Flex, Text } from '@konta/ui';
import dayjs from 'dayjs';
import { usePaymentMethods } from '@hooks';
// eslint-disable-next-line import/extensions
import useScript from 'shared/hooks/useScript';
import { CONEKTA_SCRIPT } from '@constants/thirdPartyScripts';
import FormikTextField from '../FormikTextField';
import 'react-credit-cards/lib/styles.scss';
import Loader from '../Loader';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Introduzca su nombre')
    .matches(
      /^[A-Za-z ]+$/,
      'El nombre solo puede tener letras sin caracteres especiales',
    )
    .min(5, 'El nombre debe de tener al menos 5 letras')
    .max(100, 'El nombre no debe tener más de 100 caracteres'),
  number: yup
    .string()
    .required('Introduzca su el número de la tarjeta')
    .matches(/\d/, 'Introduzca solo números en este campo')
    .min(15, 'El número debe de tener al menos 15 dígitos')
    .max(16, 'El número no debe de tener más de 16 dígitos'),
  expiryMonth: yup
    .string()
    .required('Introduzca el mes de expiración')
    .matches(/\d/, 'Introduzca solo números en este campo')
    .test('len', 'El mes debe de tener 2 dígitos', (v) => v?.length === 2)
    .test('month', 'No es un número de mes válido', (v) => +v >= 1 && +v <= 12)
    .test(
      'isValidMonth',
      'Introduce un número de mes válido',
      (expiryMonth, { parent }) => {
        const currentYear = `${dayjs().year()}`.slice(2);
        const { expiryYear } = parent;
        if (currentYear === expiryYear) {
          return +expiryMonth >= dayjs().month() + 1;
        }
        return true;
      },
    ),
  expiryYear: yup
    .string()
    .required('Introduzca el año de expiración')
    .matches(/\d/, 'Introduzca solo números en este campo')
    .test('len', 'El año debe de tener 2 dígitos', (v) => v?.length === 2)
    .test('isValidYear', 'Introduce un año válido', (expiryYear) => {
      const currentYear = `${dayjs().year()}`.slice(2);
      return currentYear <= expiryYear;
    }),
  cvc: yup
    .string()
    .required('Introduzca el CVC')
    .matches(/\d/, 'Introduzca solo números en este campo')
    .min(3, 'El CVC debe de tener mínimo 3 dígitos')
    .max(4, 'El CVC debe de tener máximo 4 dígitos'),
});

const initialValues = {
  name: '',
  number: '',
  expiryMonth: '',
  expiryYear: '',
  cvc: '',
};

function formatOwnerName(input) {
  return (input || '').toUpperCase();
}

export default function BankCard() {
  const conektaScriptStatus = useScript(CONEKTA_SCRIPT);
  const [elementFocused, setElementFocused] = useState('');
  const {
    payment_methods: paymentMethods,
    loading: isPaymentMethodsLoading,
    handleAdd: addPaymentMethod,
    handleToggle: togglePaymentMethod,
  } = usePaymentMethods();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      addPaymentMethod(values);
    },
  });
  const { name, number, expiryMonth, expiryYear, cvc } = formik.values;

  const preventTypeNumber = (e) =>
    !/^[A-Za-z ]+$/.test(e.key) && e.preventDefault();
  const preventTypeText = (e) => !/\d/.test(e.key) && e.preventDefault();

  const handleFocus = (e) => {
    const elementName = e.target.name;
    setElementFocused(
      ['expiryMonth', 'expiryYear'].includes(elementName)
        ? 'expiry'
        : elementName,
    );
  };

  if (conektaScriptStatus === 'loading') {
    return <Loader />;
  }

  const hasScriptError = ['error', 'idle'].includes(conektaScriptStatus);
  if (hasScriptError) {
    return (
      <Flex direction="column" gap={10}>
        <Text color="error">
          Ocurrió un error al cargar la información de las tarjetas.
        </Text>
        <Box>
          <Button color="error" onClick={() => window.location.reload()}>
            Recargar
          </Button>
        </Box>
      </Flex>
    );
  }

  return (
    <>
      <Row>
        <Col xs={12} className="mb-5 mt-3">
          <Cards
            number={number}
            name={name}
            expiry={expiryMonth + expiryYear}
            cvc={cvc}
            focused={elementFocused}
            placeholders={{ name: 'NOMBRE COMPLETO' }}
            locale={{ valid: 'Fecha exp' }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={4} className="border-right">
          <h4>Tarjetas de crédito</h4>
          <img
            alt="tarjetas de crédito"
            className="payment-card-images"
            src={BankCardImage}
          />
        </Col>
        <Col md={4}>
          <h4>Tarjetas de débito</h4>
          <img
            alt="tarjetas de débito"
            className="payment-card-images"
            src={BankCardImage}
          />
        </Col>
      </Row>
      <FormikProvider value={formik}>
        <Row className="mt-4">
          <Col md={6}>
            <FormikTextField
              label="Nombre del titular de la tarjeta"
              data-cy="cardNameField"
              name="name"
              type="text"
              maxLength={100}
              onKeyPress={preventTypeNumber}
              formatOnBlur={formatOwnerName}
              onFocus={handleFocus}
            />
          </Col>
          <Col md={6}>
            <FormikTextField
              label="Número de la tarjeta"
              data-cy="cardNumberField"
              name="number"
              type="text"
              onKeyPress={preventTypeText}
              onFocus={handleFocus}
              maxLength={16}
            />
          </Col>
          <Col md={3} xs={6}>
            <FormikTextField
              label="Mes de exp"
              data-cy="cardExpMonthField"
              name="expiryMonth"
              type="text"
              onKeyPress={preventTypeText}
              onFocus={handleFocus}
              maxLength={2}
              data-openreplay-obscured
            />
          </Col>
          <Col md={3} xs={6}>
            <FormikTextField
              label="Año de exp"
              data-cy="cardExpYearField"
              name="expiryYear"
              type="text"
              onKeyPress={preventTypeText}
              onFocus={handleFocus}
              maxLength={2}
              data-openreplay-obscured
            />
          </Col>
          <Col md={3}>
            <FormikTextField
              label="CVC"
              data-cy="cardCvcField"
              name="cvc"
              type="text"
              onKeyPress={preventTypeText}
              onFocus={handleFocus}
              maxLength={4}
              data-openreplay-obscured
            />
          </Col>
        </Row>
      </FormikProvider>
      <Flex
        justify="center"
        direction="column"
        css={{
          '@md': {
            flexDirection: 'row',
            justifyContent: 'end',
          },
        }}
        gap={8}
      >
        {paymentMethods.length > 0 && (
          <Button
            onClick={togglePaymentMethod}
            size="s"
            variant="outlined"
            color="primary"
          >
            Regresar
          </Button>
        )}
        <Button
          loading={isPaymentMethodsLoading}
          data-cy="addCardBtn"
          onClick={() => formik.submitForm()}
          variant="contained"
          color="primary"
          size="s"
        >
          Añadir tarjeta
        </Button>
      </Flex>
    </>
  );
}
