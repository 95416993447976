import { Flex } from '@konta/ui';
import type { AnnualDeclaration, Workflow } from 'types/entities';
import useResumeByRegime from './useResumeByRegime';
import Grid from './Grid';
import { Root } from './styled';
import { SubTitle, Title, TitleContainer } from '../styled';

interface ResumeByRegimeProps {
  workflows: Workflow[];
  satKeys: number[];
  onHandleEntriesView: (monthIdx: string, type: 'income' | 'expense') => void;
  period: string | number;
  selectedAnnualDeclaration: AnnualDeclaration | null;
  preferredFiscalRegime?: number | null;
}
export default function ResumeByRegime({
  workflows,
  satKeys,
  onHandleEntriesView,
  period,
  selectedAnnualDeclaration,
  preferredFiscalRegime,
}: ResumeByRegimeProps) {
  const { title, filteredSatKeys } = useResumeByRegime({
    satKeys,
    preferredFiscalRegime,
  });
  return (
    <Root>
      <TitleContainer>
        <Title>Declaración anual {title}</Title>
        <SubTitle>Esto es el detalle de tus impuestos durante el año</SubTitle>
      </TitleContainer>
      <Flex column css={{ flex: 1, gap: '40px' }}>
        <Grid
          onHandleEntriesView={onHandleEntriesView}
          satKeys={filteredSatKeys}
          workflows={workflows}
          period={period}
          selectedAnnualDeclaration={selectedAnnualDeclaration}
          title={title}
        />
      </Flex>
    </Root>
  );
}
