import {
  CLIENTS_SUPPLIERS_FETCH,
  CLIENTS_SUPPLIERS_FETCH_SUCCESS,
  CLIENTS_SUPPLIERS_FETCH_FAILURE,
  CLIENTS_SUPPLIERS_POST,
  CLIENTS_SUPPLIERS_POST_SUCCESS,
  CLIENTS_SUPPLIERS_POST_FAILURE,
  CLIENTS_SUPPLIERS_EDIT,
  CLIENTS_SUPPLIERS_EDIT_SUCCESS,
  CLIENTS_SUPPLIERS_EDIT_FAILURE,
  CLIENTS_SUPPLIERS_DELETE,
  CLIENTS_SUPPLIERS_DELETE_SUCCESS,
  CLIENTS_SUPPLIERS_DELETE_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  clients_suppliers: [],
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLIENTS_SUPPLIERS_FETCH:
    case CLIENTS_SUPPLIERS_POST:
    case CLIENTS_SUPPLIERS_EDIT:
    case CLIENTS_SUPPLIERS_DELETE:
      return { ...state, loading: true };
    case CLIENTS_SUPPLIERS_FETCH_SUCCESS:
    case CLIENTS_SUPPLIERS_POST_SUCCESS:
      return { ...state, loading: false, clients_suppliers: action.payload };
    case CLIENTS_SUPPLIERS_EDIT_SUCCESS:
      const clients = state.clients_suppliers;
      const foundIndex = clients.findIndex((x) => x.id === action.payload.id);
      clients[foundIndex] = action.payload;
      return { ...state, loading: false, clients_suppliers: clients };
    case CLIENTS_SUPPLIERS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        clients_suppliers: state.clients_suppliers.filter(
          (clients_supplier) => clients_supplier.id !== action.payload
        ),
      };
    case CLIENTS_SUPPLIERS_FETCH_FAILURE:
    case CLIENTS_SUPPLIERS_POST_FAILURE:
    case CLIENTS_SUPPLIERS_EDIT_FAILURE:
    case CLIENTS_SUPPLIERS_DELETE_FAILURE:
      return { ...state, loading: false };

    default:
      return { ...state };
  }
};
