import { ComponentProps, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@konta/ui';
import { Backward02OutlineIcon } from '@konta/icons';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import { workflowPreviousPhase, workflowSetTransition } from '@redux/actions';
import styles from './PrevPhaseButton.module.scss';

interface PrevPhaseButtonButtonProps
  extends Omit<ComponentProps<typeof Button>, 'children'> {
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}
export default function PrevPhaseButton({
  children,
  loading,
  onClick,
  ...props
}: PrevPhaseButtonButtonProps) {
  const dispatch = useDispatch();
  const { workflow, loading: loadingWorkflow } = useSelectedWorkflow();

  const handleClick = () => {
    dispatch(workflowSetTransition('phase-back'));
    dispatch(workflowPreviousPhase({ workflow_id: workflow?.id }));
  };

  return (
    <Button
      variant="outlined"
      data-testid="previous-phase"
      color="primary"
      loading={loadingWorkflow || loading}
      onClick={onClick || handleClick}
      className={styles.button}
      leftIcon={<Backward02OutlineIcon />}
      {...props}
    >
      {children || 'Regresar'}
    </Button>
  );
}
