import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Label, Card, CardBody } from 'reactstrap';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { Colxx } from '@components/CustomBootstrap';
import { NotificationManager } from '@components';
import { updateTaxableEntity, uploadTaxableEntityFiel } from '@redux/actions';
import { ReturnButton } from '@components/Buttons';
import { ReactComponent as FileUploadSvg } from '@assets/img/fiel_upload_v2.svg';
import { rfcPattern } from '@constants/regex';

// import { statementUpload } from '@redux/actions'

class CiecUploadCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rfc: '',
      ciec: '',
      disableCiecButton: true,
      disabled: false,
      error: false,
    };
  }

  handleCiecClick = (e) => {
    if (this.props.taxable_entity.has_ciec && this.state.ciec === '---------') {
      this.setState({ ciec: '' });
    }
  };

  updateCiecPassword = (e) => {
    this.setState({ ciec: e.target.value }, () => {
      this.buttonDisableToggle();
    });
  };

  updateRFC = (e) => {
    this.setState({ rfc: e.target.value }, () => {
      this.buttonDisableToggle();
    });
  };

  uploadCiec = (_, __) => {
    const { ciec, rfc } = this.state;
    const { activeTab, setActiveTab } = this.props;
    const regex = new RegExp(
      '^([A-ZÑ]|&)?[A-ZÑ]{3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$',
    );
    if (!regex.test(rfc)) {
      NotificationManager.error(
        'El RFC es incorrecto, favor de verificar formato',
        'Upss..',
        4000,
        null,
        null,
        null,
      );
      return;
    }
    if (ciec.length > 0) {
      const { taxable_entity } = this.props;
      const body = {
        ciec,
        rfc,
        has_ciec: true,
      };
      this.props.updateTaxableEntity(body, taxable_entity.id);
      activeTab && setActiveTab(String(+activeTab + 1));
    }
  };

  buttonDisableToggle = () => {
    const { rfc, ciec } = this.state;
    const isEnabled = rfc.length > 0 && ciec.length > 0;
    this.setState({ disableCiecButton: !isEnabled });
  };

  componentDidMount() {
    const { taxable_entity } = this.props;
    const { has_ciec, rfc } = taxable_entity;
    if (rfc) this.setState({ disabled: true, rfc });
    if (has_ciec) this.setState({ ciec: '---------' });
  }

  componentDidUpdate(prevProps, prevState) {
    const { error, taxable_entity, activeTab, setActiveTab } = this.props;
    if (taxable_entity.rfc !== prevProps.taxable_entity.rfc) {
      this.setState({
        rfc: taxable_entity.rfc,
      });
    } else if (error && error !== prevProps.error) {
      activeTab && setActiveTab(String(+activeTab - 1));
    }
  }

  render() {
    const { activeTab, setActiveTab, taxable_entity } = this.props;
    const ciecBody = (
      <Row>
        <Colxx
          xxs={12}
          md={4}
          className="p-4 d-flex align-items-center justify-content-center"
        >
          <FileUploadSvg className="w-60 w-lg-75 w-md-40 w-sm-50" />
        </Colxx>
        <Colxx xxs={12} md={8}>
          <Row className="d-flex justify-content-center mb-4">
            <h2>
              Ingresa tu clave de identificación electrónica confidencial para
              conectar Konta con el SAT
            </h2>
          </Row>
          <AvForm onValidSubmit={this.uploadCiec} autoComplete="off">
            <Row className="d-flex justify-content-center mb-2">
              <Colxx xxs={12} md={8}>
                <AvGroup>
                  <Label for="rfc">RFC</Label>
                  <AvField
                    name="rfc"
                    type="search"
                    autoComplete="off"
                    disabled={!!taxable_entity.rfc}
                    value={taxable_entity.rfc ? taxable_entity.rfc : null}
                    onChange={this.updateRFC}
                    validate={{
                      required: {
                        value: true,
                        errorMessage:
                          'Porfavor ingrese un RFC con formato válido. Ejemplo: XAX010101000',
                      },
                      pattern: {
                        value: rfcPattern,
                        errorMessage:
                          'Porfavor ingrese un RFC con formato válido. Ejemplo: XAX010101000',
                      },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <AvField
                    name="ciec"
                    label="Contraseña"
                    autoComplete="off"
                    type="password"
                    onClick={this.handleCiecClick}
                    onChange={this.updateCiecPassword}
                    validate={{
                      minLength: {
                        value: '8',
                        errorMessage: 'Mínimo 8 caracteres',
                      },
                      required: {
                        value: true,
                        errorMessage: 'Campo requerido',
                      },
                    }}
                    required
                  />
                </AvGroup>
              </Colxx>
            </Row>
            <Row className="mt-1">
              {activeTab ? (
                <>
                  <Colxx
                    className="d-flex align-items-center justify-content-center mb-2"
                    xxs={12}
                    md={4}
                  >
                    <ReturnButton
                      handleReturn={(e) => {
                        e.preventDefault();
                        setActiveTab(String(+activeTab - 1));
                      }}
                      size="sm"
                      className="ml-2"
                    />
                  </Colxx>

                  <Colxx
                    xxs={12}
                    md={4}
                    className="d-flex align-content-center justify-content-center mb-2"
                  >
                    <Button
                      color="primary"
                      size="sm"
                      outline
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab(String(+activeTab + 1));
                      }}
                    >
                      Omitir
                    </Button>
                  </Colxx>
                  <Colxx
                    xxs={12}
                    md={4}
                    className="d-flex align-items-center justify-content-center mb-2"
                  >
                    <Button color="primary" size="sm">
                      {taxable_entity.has_ciec ? 'Actualizar' : 'Guardar'}
                    </Button>
                  </Colxx>
                </>
              ) : (
                <Colxx
                  className="d-flex align-content-center justify-content-center"
                  xxs={12}
                >
                  <Button
                    color="primary"
                    size="sm"
                    disabled={this.state.disableCiecButton}
                  >
                    Guardar
                  </Button>
                </Colxx>
              )}
            </Row>
          </AvForm>
        </Colxx>
      </Row>
    );
    return (
      <>
        {activeTab ? (
          ciecBody
        ) : (
          <Card>
            {' '}
            <CardBody>{ciecBody}</CardBody>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  pulse,
  taxableEntity: { taxable_entity, error, loading },
}) => {
  return { pulse, taxable_entity, error, loading };
};

export default connect(mapStateToProps, {
  updateTaxableEntity,
  uploadTaxableEntityFiel,
})(CiecUploadCard);
