import { lazy } from 'react';

const Retool = lazy(() => import('./RetoolWrapper'));

const RetoolConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'retool',
      element: (
        <Retool landingPageUuid="fc11939a-2dcd-11ef-b235-5b4f8f4ef8c7" />
      ),
    },
  ],
};

export default RetoolConfig;
