import {
  BELVO_TOKEN_GET,
  BELVO_TOKEN_GET_SUCCESS,
  BELVO_TOKEN_GET_FAILURE,
  BELVO_ORPHAN_LINKS_FETCH,
  BELVO_ORPHAN_LINKS_FETCH_SUCCESS,
  BELVO_ORPHAN_LINKS_FETCH_FAILURE,
  BELVO_BANK_ACCOUNTS_FETCH,
  BELVO_BANK_ACCOUNTS_FETCH_SUCCESS,
  BELVO_BANK_ACCOUNTS_FETCH_FAILURE,
  BELVO_LINKS_FETCH,
  BELVO_LINKS_FETCH_SUCCESS,
  BELVO_LINKS_FETCH_FAILURE,
  BELVO_LINKS_CONNECTION_CREATE,
  BELVO_LINKS_CONNECTION_CREATE_SUCCESS,
  BELVO_LINKS_CONNECTION_CREATE_FAILURE,
  BELVO_LINKS_CONNECTION_DELETE,
  BELVO_LINKS_CONNECTION_DELETE_SUCCESS,
  BELVO_LINKS_CONNECTION_DELETE_FAILURE,
} from '@constants/actionTypes';

export const belvoTokenGet = (payload) => {
  return {
    type: BELVO_TOKEN_GET,
    payload,
  };
};

export const belvoTokenGetSuccess = (payload) => {
  return {
    type: BELVO_TOKEN_GET_SUCCESS,
    payload,
  };
};

export const belvoTokenGetFailure = (payload) => {
  return {
    type: BELVO_TOKEN_GET_FAILURE,
    payload,
  };
};

export const belvoOrphanLinksFetch = (_) => {
  return {
    type: BELVO_ORPHAN_LINKS_FETCH,
  };
};

export const belvoOrphanLinksFetchSuccess = (payload) => {
  return {
    type: BELVO_ORPHAN_LINKS_FETCH_SUCCESS,
    payload,
  };
};

export const belvoOrphanLinksFetchFailure = (payload) => {
  return {
    type: BELVO_ORPHAN_LINKS_FETCH_FAILURE,
    payload,
  };
};

export const belvoBankAccountsFetch = (_) => {
  return {
    type: BELVO_BANK_ACCOUNTS_FETCH,
  };
};

export const belvoBankAccountsFetchSuccess = (payload) => {
  return {
    type: BELVO_BANK_ACCOUNTS_FETCH_SUCCESS,
    payload,
  };
};

export const belvoBankAccountsFetchFailure = (payload) => {
  return {
    type: BELVO_BANK_ACCOUNTS_FETCH_FAILURE,
    payload,
  };
};

export const belvoLinksFetch = () => {
  return {
    type: BELVO_LINKS_FETCH,
  };
};

export const belvoLinksFetchSuccess = (payload) => {
  return {
    type: BELVO_LINKS_FETCH_SUCCESS,
    payload,
  };
};

export const belvoLinksFetchFailure = (payload) => {
  return {
    type: BELVO_LINKS_FETCH_FAILURE,
    payload,
  };
};

export const belvoLinksConnectionCreate = (payload) => {
  return {
    type: BELVO_LINKS_CONNECTION_CREATE,
    payload,
  };
};

export const belvoLinksConnectionCreateSuccess = (payload) => {
  return {
    type: BELVO_LINKS_CONNECTION_CREATE_SUCCESS,
    payload,
  };
};

export const belvoLinksConnectionCreateFailure = (payload) => {
  return {
    type: BELVO_LINKS_CONNECTION_CREATE_FAILURE,
    payload,
  };
};

export const belvoLinksConnectionDelete = (payload) => {
  return {
    type: BELVO_LINKS_CONNECTION_DELETE,
    payload,
  };
};

export const belvoLinksConnectionDeleteSuccess = (payload) => {
  return {
    type: BELVO_LINKS_CONNECTION_DELETE_SUCCESS,
    payload,
  };
};

export const belvoLinksConnectionDeleteFailure = (payload) => {
  return {
    type: BELVO_LINKS_CONNECTION_DELETE_FAILURE,
    payload,
  };
};
