import { styled } from '@konta/ui';

export const RowItemLabel = styled('span', {
  fontSize: '$2xs',
  color: 'rgb(65, 83, 114)',
  lineHeight: '$16',
  letterSpacing: '0.01em',
  overflow: 'hidden',
  flex: 1,
  textWrap: 'nowrap',
});

export const StyledChartRowsContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  border: '1px solid rgb(209, 216, 224)',
  padding: '$4',
});

export const RowItem = styled('div', {
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
});
export const RowItemColor = styled('div', {
  margin: '$4',
  height: '$8',
  width: '$8',
  borderRadius: '50%',
});

export const RowItemNumber = styled('div', {
  padding: '$2 $6',
  backgroundColor: 'rgb(246, 248, 250)',
  borderRadius: '$s',
  flexshrink: 0,
  span: {
    color: 'rgb(65, 83, 114)',
    fontSize: '$2xs',
    lineHeight: '$2xs',
    letterspacing: '0.01em',
  },
});
