import putClient from '@api/putClient';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

/**
 * @param { import('react-query').UseMutationOptions } options
 */
export default function useUpdateClient(options) {
  const taxableId = useSelector(
    (state) => state.taxableEntity?.taxable_entity?.id,
  );

  const postClientRequest = useCallback(
    (client) => {
      return putClient({ taxableId, client });
    },
    [taxableId],
  );

  return useMutation(postClientRequest, options);
}
