import {
  ADDRESS_FETCH,
  ADDRESS_FETCH_SUCCESS,
  ADDRESS_FETCH_FAILURE,
  ADDRESS_UPDATE,
  ADDRESS_UPDATE_SUCCESS,
  ADDRESS_UPDATE_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  address: null,
  loading: false,
  error: null,
  missingPostCode: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADDRESS_FETCH:
      return { ...state, loading: true };
    case ADDRESS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        address: action.payload,
        missingPostCode: !!action.payload.postcode,
      };
    }
    case ADDRESS_FETCH_FAILURE:
      return { ...state, loading: false, address: null };
    case ADDRESS_UPDATE:
      return { ...state, loading: true };
    case ADDRESS_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        address: action.payload,
      };
    }
    case ADDRESS_UPDATE_FAILURE:
      return { ...state, loading: false, address: null };
    default:
      return { ...state };
  }
};
