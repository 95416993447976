import { FileWithPath } from 'react-dropzone';
import useUploadEntryFile from 'shared/hooks/useUploadEntryFile';

export interface ExtendedFileWithPath extends FileWithPath {
  newDeclaration?: {
    label: string;
    value: number;
    declarationId: number;
  };
}

interface UseUploadXmlModalProps {
  toggleOpenUploadIncomes: () => void;
}
export default function useUploadXmlModal({
  toggleOpenUploadIncomes,
}: UseUploadXmlModalProps) {
  const {
    isLoading,
    toggleShowConfirmation,
    showConfirmation,
    xmlFiles,
    setXmlFiles,
    handleOnSubmit,
    activeDeclarations,
    workflow,
    currentRegimes,
    selectRegimeView,
    setSelectRegimeView,
    regimeOptionsWithDeclarationId,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  } = useUploadEntryFile({
    toggleOpenUploadModal: toggleOpenUploadIncomes,
    source_type: 'Cfdi',
  });

  return {
    isLoading,
    toggleShowConfirmation,
    showConfirmation,
    xmlFiles,
    setXmlFiles,
    handleOnSubmit,
    activeDeclarations,
    workflow,
    currentRegimes,
    selectRegimeView,
    setSelectRegimeView,
    regimeOptionsWithDeclarationId,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  };
}
