import { Button, Flex, Select, Text } from '@konta/ui';
import { EyeLineIcon } from '@konta/icons';
import { FileWithPath } from 'react-dropzone';
import LetterAvatar from 'react-avatar';
import InvoicePreview from '@components/InvoicePreview/InvoicePreview';
import TextOverflow from 'shared/components/TextOverflow';
import ListInformationText from 'shared/components/ListInformationText';
import { ConfirmModal } from '@components/Modal';
import { ReturnTypeUseWorkflow } from '@components/Workflow/hooks/useWorkflow';
import useFileCard from './useFileCard';
import { Container, FileActions, FileDetails } from './styled';

export interface ExtendedFileWithPath extends FileWithPath {
  newDeclaration?: {
    label: string;
    value: number;
    declarationId: number;
  };
}

export interface FileCardProps {
  file: ExtendedFileWithPath;
  xmlFiles: ExtendedFileWithPath[];
  setXmlFiles: (files: ExtendedFileWithPath[]) => void;
  regimeOptionsWithDeclarationId: ReturnTypeUseWorkflow['regimeOptionsWithDeclarationId'];
  isConstancy?: boolean;
  defaultRegimeOption: ReturnTypeUseWorkflow['defaultRegimeOptionBySatkey'];
  taxableEntityPreferences?: ReturnTypeUseWorkflow['taxableEntityPreferences'];
  view?: 'constancy' | 'incomes' | 'expenses';
}

export default function FileCard({
  file,
  xmlFiles,
  setXmlFiles,
  regimeOptionsWithDeclarationId,
  isConstancy,
  defaultRegimeOption,
  taxableEntityPreferences,
  view = 'incomes',
}: FileCardProps) {
  const {
    constancyXml,
    fetchInvoice,
    fileType,
    invoiceType,
    isFetching,
    isValidFile,
    selectedRegime,
    setCurrentFile,
    setSelectedRegime,
    toggleShowInvoice,
    showInvoice,
    setShowInvoice,
    isDisabledFiscalRegimeDropdown,
    isEmittedByUser,
  } = useFileCard({
    file,
    regimeOptionsWithDeclarationId,
    isConstancy,
    xmlFiles,
    setXmlFiles,
    defaultRegimeOption,
    taxableEntityPreferences,
    view,
  });

  let fileSourceType = '';
  if (fileType === 'retenciones' || isEmittedByUser) {
    fileSourceType = 'ingresos';
  } else {
    fileSourceType = 'gastos';
  }

  return (
    <>
      <Container>
        <FileDetails>
          <span className="classify-expenses-logo-container">
            <LetterAvatar
              maxInitials={2}
              name={
                fetchInvoice?.Emisor.Nombre ||
                constancyXml?.constancyEmisor ||
                '-'
              }
              size="28"
              round
            />
          </span>
          <Flex justify="between" css={{ flex: 1 }}>
            <Flex column>
              <TextOverflow maxLen={395} s lineHeight="s" color="gray900">
                {fetchInvoice?.Emisor.Nombre ||
                  constancyXml?.constancyEmisor ||
                  '-'}
              </TextOverflow>
              <Flex column>
                <ListInformationText
                  label="RFC"
                  value={
                    fetchInvoice?.Emisor.Rfc ||
                    constancyXml?.constancyRfcEmisor ||
                    '-'
                  }
                />
                <ListInformationText label="Tipo" value={invoiceType} />
              </Flex>
            </Flex>
            <Flex column itemsEnd>
              <ListInformationText
                isCurrency
                value={
                  fetchInvoice?.Total ||
                  constancyXml?.constancyMontoTotOperacion ||
                  0
                }
              />
              <ListInformationText
                isDate
                format="DD [de] MMM YYYY"
                value={
                  fetchInvoice?.Complemento.FechaTimbrado.toString() ||
                  constancyXml?.constancyFechaTimbrado ||
                  '-'
                }
              />
              <Button
                size="xs"
                variant="outlined"
                rightIcon={<EyeLineIcon />}
                noFill
                onClick={() => {
                  if (fileType === 'retenciones') {
                    const url = URL.createObjectURL(file);
                    window.open(url, '_blank');
                  } else {
                    setShowInvoice(fetchInvoice);
                  }
                }}
              >
                Ver factura
              </Button>
            </Flex>
          </Flex>
        </FileDetails>
        <FileActions>
          <Select
            size="xs"
            placeholder="Régimen fiscal"
            options={regimeOptionsWithDeclarationId}
            isDisabled={isDisabledFiscalRegimeDropdown}
            value={selectedRegime}
            onChange={(newValue) => {
              setSelectedRegime(newValue as typeof selectedRegime);
              setCurrentFile(file);
            }}
          />
        </FileActions>
      </Container>
      {!isValidFile && (
        <Text size="s" color="error500">
          El archivo de tipo {invoiceType} de {fileSourceType} no se puede subir{' '}
        </Text>
      )}
      {!!showInvoice && (
        <ConfirmModal
          open={!!showInvoice}
          size="lg"
          title="Vista previa"
          onClose={toggleShowInvoice}
          loading={isFetching}
          onAccept={toggleShowInvoice}
          acceptText="Cerrar"
        >
          <Flex column gap={16}>
            <InvoicePreview invoiceObject={showInvoice} />
          </Flex>
        </ConfirmModal>
      )}
    </>
  );
}
