import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import NextPhaseConfirmation from '@components/NextPhaseConfirmation';
import useFeature from '@hooks/useFeature';
import { WORKFLOWS_WITH_NO_FIEL } from '@constants/featureFlags';

interface NotCsdUploadInvoiceNextPhaseProps {
  isNextBtnDisabled: boolean;
  onNextBtnClick: () => void;
}
export default function NotCsdUploadInvoiceNextPhase({
  onNextBtnClick,
  isNextBtnDisabled,
}: NotCsdUploadInvoiceNextPhaseProps) {
  const [isWorkflowWithNoFielEnabled] = useFeature(WORKFLOWS_WITH_NO_FIEL);

  if (isWorkflowWithNoFielEnabled && isNextBtnDisabled) {
    return (
      <NextPhaseConfirmation
        // @ts-ignore TODO: Convert PrimaryPhaseButton to tsx
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,react/no-unstable-nested-components
        trigger={({ onClick }) => <PrimaryPhaseButton onClick={onClick} />}
        payload={{
          workflow: {
            next_phase: 'primary_phase',
          },
        }}
        title="Confirmación de avance sin factura para el público en general"
        acceptText="Confirmar"
      >
        <div className="m-auto d-flex flex-column justify-content-center text-center">
          <i className="large-icon iconsmind-Danger" color="danger" />
          <p>
            Estás a punto de avanzar sin subir la factura para el público en
            general. ¿Deseas continuar con esta acción?
          </p>
        </div>
      </NextPhaseConfirmation>
    );
  }
  return (
    // @ts-ignore TODO: Convert PrimaryPhaseButton to tsx
    <PrimaryPhaseButton onClick={onNextBtnClick} disabled={isNextBtnDisabled} />
  );
}
