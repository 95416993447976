import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import UploadCsd from './UploadCsd';

// TODO: this component must be more like ProductForm
export default function CredentialsModalForm({ isOpen, toggle }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="l" height="m">
      <ModalHeader
        css={{ fontWeight: '500', fontSize: '18px', color: '$gray900' }}
      >
        Sube tus certificados de sellos digitales CSD para emitir tu factura
      </ModalHeader>
      <ModalBody>
        <UploadCsd onSubmit={toggle} />
      </ModalBody>
    </Modal>
  );
}

CredentialsModalForm.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

CredentialsModalForm.defaultProps = {
  isOpen: false,
  toggle: null,
};
