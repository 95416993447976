import { ReactNode } from 'react';
import { config, Text, useMediaQuery } from '@konta/ui';

export default function ProcessStepTitle({
  children,
}: {
  children: ReactNode;
}) {
  const isTabletOrMobile = useMediaQuery(config.media['<md']);
  return (
    <Text
      size={isTabletOrMobile ? 'l' : 'xl'}
      lineHeight={isTabletOrMobile ? 'l' : '2xl'}
      bold
      data-cy="welcome-annual-title"
    >
      {children}
    </Text>
  );
}
