import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, TaskInactiveJumbotron } from '@components';

export function TaskInactive(_) {
  return (
    <Row className="mb-4">
      <Colxx xxs="12" className="mb-6">
        <TaskInactiveJumbotron />
      </Colxx>
    </Row>
  );
}
