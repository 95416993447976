import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import pulseSagas from './pulse/saga';
import chatSagas from './chat/saga';
import taxableSagas from './taxable_entity/saga';
import paymentSaga from './payment_methods/saga';
import fiscalPeriodsSaga from './fiscal_periods/saga';
import addressSaga from './address/saga';
import subscriptionSaga from './subscriptions/saga';
import financial_transactionsSaga from './financial_transactions/saga';
import productsSaga from './products/saga';
import clients_suppliersSaga from './clients_suppliers/saga';
import documentsSaga from './documents/saga';
import cfdiSaga from './cfdi/saga';
import ordersSaga from './orders/saga';
import contactMethodSaga from './contact_methods/saga';
import notification_preferences from './notification_preferences/saga';
import fiscal_regimensSaga from './fiscal_regimens/saga';
import workflows from './workflows/saga';
import declarationEntriesSaga from './declaration_entries/saga';
import belvoSaga from './belvo/saga';
import bankTransactionsDeclarationsSaga from './bank_transactions_declarations/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    chatSagas(),
    pulseSagas(),
    taxableSagas(),
    paymentSaga(),
    fiscalPeriodsSaga(),
    addressSaga(),
    subscriptionSaga(),
    financial_transactionsSaga(),
    productsSaga(),
    clients_suppliersSaga(),
    documentsSaga(),
    cfdiSaga(),
    ordersSaga(),
    contactMethodSaga(),
    notification_preferences(),
    fiscal_regimensSaga(),
    workflows(),
    declarationEntriesSaga(),
    belvoSaga(),
    bankTransactionsDeclarationsSaga(),
  ]);
}
