import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@konta/ui';
import { workflowUpdate, workflowSetTransition } from '@redux/actions';
import { getMonthAndYearDate } from '@util/Utils';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import SecondaryPhaseButton from '@components/SecondaryPhaseButton';
import HelpCenterLink from '@components/HelpCenterLink';

function QuForeignExpences({ workflow }) {
  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Dinos si tuviste gastos en el extranjero"
          description="Las empresas en el extranjero no emiten facturas mexicanas (CFDIs). Sin embargo, eso no significa que no se puedan deducir esos gastos. Para poder deducir los productos en el extranjero, se necesita un pedimento de importación. Para poder deducir servicios en el extranjero, se necesita descargar la Invoice (factura extranjera) con su RFC."
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton>Si compré productos</PrimaryPhaseButton>
          <SecondaryPhaseButton variant="contained">
            No (continuar)
          </SecondaryPhaseButton>
        </>
      }
    >
      <Text x2l>
        En el mes de {getMonthAndYearDate(new Date(workflow.start_date))},
        ¿tuviste gastos en el extranjero?
      </Text>
      <div className="w-60 w-sm-100">
        <p className="text-muted mb-1">
          {'Ejemplo: Si, compré productos en China y tengo mi '}
          <span className="font-weight-bold">
            &quot;pedimento de importación&quot;
          </span>
          .
        </p>
        <p className="text-muted">
          {'Ejemplo: Si, gasté 1,000 pesos en Facebook y tengo la '}
          <span className="font-weight-bold">&quot;Invoice&quot;</span>.
        </p>
      </div>
      <div className="d-flex">
        <p className="text-muted">Consulta nuestros artículos para obtener</p>
        {/* //TODO ARTICLES URL IS MISSING */}
        <HelpCenterLink> Link al artículo</HelpCenterLink>
      </div>
    </WorkflowLayout>
  );
}
const mapStateToProps = ({ workflows }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { workflow, wloading };
};
export default connect(mapStateToProps, {
  workflowUpdate,
  workflowSetTransition,
})(QuForeignExpences);
