import api from '@api';

export default async function postInvitation({ email, phone }) {
  const { data } = await api.post(`/invitations`, {
    email: email || undefined,
    phone_number: phone || undefined,
  });

  return data;
}
