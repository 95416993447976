import { useRef, useEffect, useCallback } from 'react';
import { useKey, useToggle } from 'rooks';
import { HoverCard, Text, Button, Flex } from '@konta/ui';
import { EyeLineIcon } from '@konta/icons';
import toCurrency from '@util/toCurrency';
import MetricsTotalCard from '@components/MetricsTotalCard';

interface TotalsButtonProps {
  regime: string;
  total: number;
  isPreviewOpen?: boolean;
  totals: Array<any>;
  source?: 'incomes' | 'expenses';
}
export default function TotalsButton({
  regime,
  total,
  totals,
  isPreviewOpen,
  source = 'incomes',
}: TotalsButtonProps) {
  const [isOpen, toggleIsOpen] = useToggle();
  const refs = {
    spanRef: useRef<HTMLSpanElement>(null),
    cardContainerRef: useRef<HTMLDivElement>(null),
  };

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (refs.cardContainerRef.current?.contains(target)) return;
      if (refs.spanRef.current?.contains(target)) return;
      toggleIsOpen();
    },
    [refs.cardContainerRef, refs.spanRef, toggleIsOpen],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, handleOutsideClick]);

  useEffect(() => {
    if (isPreviewOpen) {
      toggleIsOpen();
    }
  }, [isPreviewOpen, toggleIsOpen]);

  useKey(['Escape'], () => {
    if (isOpen) {
      toggleIsOpen();
    }
  });

  return (
    <Flex column itemsEnd>
      <Text lineHeight="l" l>
        {toCurrency(total)} con IVA
      </Text>
      <HoverCard
        css={{
          '&.HoverCardContent': {
            width: '100%',
            overflow: 'auto',
            maxWidth: '800px',
            height: 'auto',
          },
        }}
        hideWhenDetached
        open={isOpen}
        triggerElement={
          <span ref={refs.spanRef}>
            <Button
              noFill
              variant="outlined"
              size="2xs"
              rightIcon={<EyeLineIcon />}
              onClick={toggleIsOpen}
            >
              {source === 'incomes' ? 'Ingresos' : 'Gastos'} de {regime}
            </Button>
          </span>
        }
      >
        <div ref={refs.cardContainerRef}>
          <MetricsTotalCard title={`Cálculo de ${regime}`} rows={totals} />
        </div>
      </HoverCard>
    </Flex>
  );
}
