import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import type { IRootState } from 'types/redux';

export default function useTaxableEntity() {
  const taxableEntity = useSelector(
    (state: IRootState) => state.taxableEntity,
    shallowEqual,
  );

  return useMemo(
    () => ({
      ...taxableEntity,
      taxableEntityLoading: taxableEntity.loading,
    }),
    [taxableEntity],
  );
}
