import { call, put } from 'redux-saga/effects';
import { ErrorHandler } from '@util/Saga';
import * as Sentry from '@sentry/react';
import { setUserDataToOpenReplay } from '@util/OpenReplay';
import gtmEvent from '@util/gtmEvent';
import { identifySegment } from '@util/Segment';
import { NotificationManager } from '@components';
import {
  loginUserSuccess,
  loginUserFailure,
  updateUserOtpToken,
} from '../actions';
import {
  load as loadIntercom,
  boot as bootIntercom,
  formatInfo,
} from '@util/Intercom';

export default function* ({
  payload: { navigate, user, featureClient, response },
}) {
  let withOtpCode = false;
  try {
    const { email, password, code } = user;
    const payload = {
      email,
    };

    if (code && user?.otp_token) {
      payload.otp_code = code;
      payload.otp_token = user.otp_token;
      payload.email = response.email;
      withOtpCode = true;
    } else {
      payload.password = password;
    }

    if (response?.data.otp_token) {
      yield put(updateUserOtpToken(response));
      return;
    }

    const { access_token, phone_number, id, first_name, taxable_entity } =
      response.data;
    localStorage.setItem('user_id', access_token);
    yield put(loginUserSuccess(response.data));

    navigate('/');
    // Google tag manager event submit
    gtmEvent('AppEvent', 'loginSuccess');
    // Sentry data set scope
    Sentry.configureScope((scope) => {
      scope.setUser({
        id,
        email,
        username: first_name,
        phone_number,
      });
    });
    user = response.data;
    setUserDataToOpenReplay({
      user,
      taxable_entity,
    });
    const featureFlags = yield featureClient.identify({
      kind: 'user',
      key: id,
      email,
      firstName: first_name,
      hasSubscription: taxable_entity?.status !== 'demo',
      fiscalRegimes: taxable_entity?.fiscal_regimes.map(
        (fiscal_regime) => fiscal_regime.sat_key,
      ),
    });
    identifySegment(user.id, featureFlags);
    yield loadIntercom();
    yield bootIntercom(formatInfo({ user }));
  } catch (error) {
    if (withOtpCode) {
      NotificationManager.error(
        'El código de verificación es incorrecto',
        'Verificación en dos pasos',
        4000,
        null,
        null,
        null,
      );
      return;
    }
    yield call(ErrorHandler, {
      error,
      action: loginUserFailure,
    });
  }
}
