import React from 'react';
import PropTypes from 'prop-types';
import { OptionsSelect } from '@konta/ui';

export default function FormikOptionsSelect({
  children,
  name,
  hasExtraIncomes,
  setHasExtraIncomes,
  ...rest
}) {
  // TODO: add errors message

  return (
    <OptionsSelect
      value={hasExtraIncomes}
      onChange={setHasExtraIncomes}
      {...rest}
    >
      {children}
    </OptionsSelect>
  );
}

FormikOptionsSelect.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};
