import { CSS, styled } from '@konta/ui';

const flexRowBaseCss: CSS = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'stretch',
};

const Section = styled('div', {
  ...flexRowBaseCss,
  gap: '$16',
  flexDirection: 'column',
  '@md': {
    flexDirection: 'row',
  },
  variants: {
    alignStart: {
      true: {
        alignItems: 'flex-start',
      },
    },
  },
});

export default Section;
