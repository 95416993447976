// TODO we'll remove this phase when backend deleted it
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
  workflowNextPhase,
  addressFetch,
} from '@redux/actions';
import { PreviewPDFModal } from '@components/Utils';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import SecondaryPhaseButton from '@components/SecondaryPhaseButton';
import toCurrency from '@util/toCurrency';
import usePreviewOfPublicInvoice from '@components/Workflow/hooks/usePreviewOfPublicInvoice';
import { useToggle } from 'rooks';

function CreateMisingPublicInvoice({ workflow, wloading }) {
  const { getDataForPreviewByDeclaration, previewLoading } =
    usePreviewOfPublicInvoice();
  const publicInvoicePreview = getDataForPreviewByDeclaration(
    workflow.active_declaration,
  );
  const isLoading = wloading || previewLoading;
  const [invoicePreview, toggleInvoicePreview] = useToggle();
  const total = +(publicInvoicePreview?.receipt?.total || 0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addressFetch());
  }, []);

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Cuadrar ingresos con facturación."
          description="En esta sección puede activar o desactivar las opciones de envío de notificaciones."
        />
      }
      actions={
        <>
          <PrevPhaseButton isLoading={isLoading} />
          <SecondaryPhaseButton
            isLoading={isLoading}
            onClick={toggleInvoicePreview}
          >
            Ver vista previa de la Factura
          </SecondaryPhaseButton>
          <PrimaryPhaseButton isLoading={isLoading}>
            Continuar y facturar
          </PrimaryPhaseButton>
        </>
      }
    >
      <div className="d-flex align-items-center">
        <div>
          <div className="w-80 w-sm-100">
            <h1>
              Notamos que sus ingresos por plataformas son mayores a lo que
              facturó, la diferencia con IVA incluido sería de{' '}
              {toCurrency(total)} MXN. Es necesario realizar la factura a
              Público en general por la diferencia para estar en orden con el
              SAT.
            </h1>
          </div>
        </div>
      </div>
      {invoicePreview && (
        <PreviewPDFModal
          showPreview={invoicePreview}
          toggle={toggleInvoicePreview}
          cfdi={publicInvoicePreview}
          isWorkflowPublicInvoice
          title="Vista Previa"
        />
      )}
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ workflows, taxableEntity, addresses }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  const { taxable_entity } = taxableEntity;
  const { address } = addresses;
  return { workflow, wloading, taxable_entity, address };
};

export default connect(mapStateToProps, {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
  workflowNextPhase,
  addressFetch,
})(CreateMisingPublicInvoice);
