import { Route, Routes } from 'react-router-dom';
import Opt1 from './Opt1';

export default function Designs() {
  return (
    <Routes>
      <Route path="/test" element={<Opt1 />} />
    </Routes>
  );
}
