import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const UTMsKey = 'UTMs';
const utmObject = {
  UTMs: [
    { value: '', key: 'utm_id' },
    { value: '', key: 'utm_source' },
    { value: '', key: 'utm_medium' },
    { value: '', key: 'utm_campaign' },
    { value: '', key: 'utm_term' },
    { value: '', key: 'utm_content' },
  ],
};

function setUTMObjectFromURL(utmParams) {
  utmObject.UTMs = utmObject.UTMs.map((utm) => {
    if (utmParams.has(utm.key)) {
      return { ...utm, value: utmParams.get(utm.key) };
    }
    return { ...utm, value: '' };
  });
}

function saveUTMs(utmParams) {
  if (
    utmParams.has('utm_id') ||
    utmParams.has('utm_source') ||
    utmParams.has('utm_medium') ||
    utmParams.has('utm_campaign') ||
    utmParams.has('utm_term') ||
    utmParams.has('utm_content')
  ) {
    setUTMObjectFromURL(utmParams);
    if (UTMsKey in localStorage) {
      const utmObjectLocalStorage = JSON.parse(localStorage.getItem(UTMsKey));
      if (JSON.stringify(utmObjectLocalStorage) !== JSON.stringify(utmObject)) {
        localStorage.setItem(UTMsKey, JSON.stringify(utmObject));
      }
    } else {
      localStorage.setItem(UTMsKey, JSON.stringify(utmObject));
    }
  }
}

export default function useUTMs() {
  const { search } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    saveUTMs(searchParams);
  }, [search]);
}
