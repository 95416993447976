import { all, fork, takeLatest } from 'redux-saga/effects';
import { ORDERS_FETCH, ORDER_PAY } from '@constants/actionTypes';
import fetchOrdersSaga from './fetch';
import payOrderSaga from './pay';

export function* watchFetch() {
  yield takeLatest(ORDERS_FETCH, fetchOrdersSaga);
}

export function* watchPay() {
  yield takeLatest(ORDER_PAY, payOrderSaga);
}

export default function* rootSaga() {
  yield all([fork(watchFetch), fork(watchPay)]);
}
