import api from '@api';
import { call, select } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';

import {
  fetchPaymentMethodsSuccess,
  fetchPaymentMethodsFailure,
} from '../actions';

export default function* () {
  try {
    const { taxable_entity } = yield select((state) => state.taxableEntity);
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.get,
      `/taxable_entities/${taxable_entity.id}/payment_methods`,
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: fetchPaymentMethodsSuccess,
      failure: fetchPaymentMethodsFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: fetchPaymentMethodsFailure,
    });
  }
}
