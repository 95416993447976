import useApiPagination from 'shared/hooks/useApiPagination';
import useUrlParamsPagination from '@hooks/useUrlParamsPagination';

export default function useInvitedReferralsPagination() {
  const paramsManager = useUrlParamsPagination({ prefix: 'sent_' });

  return useApiPagination({
    endpoint: 'invitations',
    itemsPerPage: 5,
    params: { 'q[status_not_in]': ['suggestion', 'discarded'] },
    paramsManager,
  });
}
