import { Flex, Button } from '@konta/ui';
import { ArrowRightLineIcon } from '@konta/icons';
import { ConfirmModal } from '@components/Modal';
import FullModal from '@components/FullModal';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import IncomeUploadWay from './IncomeUploadWay';
import { Root, Header, Title, Feature } from '../styled';
import useEditIncomes from './useEditIncomes';
import ClassifyIncomes from './ClassifyIncomes';
import TotalsButtons from './TotalsButtons';

interface EditIncomesProps {
  showEditIncomes: boolean;
  toggleShowEditIncomes: () => void;
}

export default function EditIncomes({
  showEditIncomes,
  toggleShowEditIncomes,
}: EditIncomesProps) {
  const { startDate, openUploadIncomes, toggleOpenUploadIncomes } =
    useEditIncomes();

  return (
    <FullModal
      open={showEditIncomes}
      toggleClose={toggleShowEditIncomes}
      id="editDeclarationIncomes"
      title="Declaración mensual"
    >
      <WorkflowLayout
        actions={
          <>
            <PrevPhaseButton onClick={toggleShowEditIncomes} />
            <TotalsButtons />
            <PrimaryPhaseButton
              onClick={toggleShowEditIncomes}
              rightIcon={<ArrowRightLineIcon />}
            >
              Guardar y regresar
            </PrimaryPhaseButton>
          </>
        }
      >
        <Root>
          <Header>
            <Title>Modificar ingresos</Title>
            <Feature>
              Esta es la lista de todos tus ingresos considerados en el mes de{' '}
              {startDate}
            </Feature>
          </Header>
          <Flex>
            <Button color="primary600" onClick={toggleOpenUploadIncomes}>
              Agregar nuevos ingresos
            </Button>
          </Flex>
          <ClassifyIncomes />
        </Root>
      </WorkflowLayout>
      {openUploadIncomes && (
        <ConfirmModal
          open={openUploadIncomes}
          title="Agregar ingresos"
          onClose={toggleOpenUploadIncomes}
          loading={false}
          size="xl"
          withBlur
          centered
          withFooter={false}
        >
          <IncomeUploadWay toggleOpenUploadIncomes={toggleOpenUploadIncomes} />
        </ConfirmModal>
      )}
    </FullModal>
  );
}
