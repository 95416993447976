import React from 'react';
import PropTypes from 'prop-types';
import useFiscalRegimeOptions from '@hooks/useFiscalRegimeOptions';
import FormikSelect from './FormikSelect';

export default function FormikRegimeSelect({
  availableRegimes,
  excludedRegimes,
  labelHelpPopover,
  ...props
}) {
  const { regimeOptions } = useFiscalRegimeOptions({
    availableRegimes,
    excludedRegimes,
  });

  return (
    <FormikSelect
      labelHelpPopover={labelHelpPopover}
      options={regimeOptions}
      {...props}
    />
  );
}

FormikRegimeSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  availableRegimes: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  excludedRegimes: PropTypes.arrayOf(PropTypes.any),
};

FormikRegimeSelect.defaultProps = {
  availableRegimes: null,
  excludedRegimes: null,
};
