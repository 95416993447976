import { ComponentProps } from 'react';
import { Chip } from '@konta/ui';
import type { CfdiInvoiceType, CfdiStatus } from 'types/entities';

const cfdiStatusDataBase = {
  pending: 0,
  stamped: 1,
  acceptance_pending: 2,
  not_accepted: 3,
  cancelled: 4,
  cancellation_in_progress: 5,
};

export const cfdiStatusName: Record<CfdiStatus, string> = {
  stamped: 'Timbrada',
  cancelled: 'Cancelada',
  not_accepted: 'Rechazada',
  acceptance_pending: 'Pendiente de aceptación',
  cancellation_in_progress: 'Cancelación en proceso',
  pending: 'Pendiente',
};

export const cfdiStatusDbOpts = Object.entries(cfdiStatusDataBase).map(
  ([key, value]) => ({
    label: cfdiStatusName[key as CfdiStatus],
    value: `${value}`,
  }),
);

export const cfdiStatus: Record<
  CfdiStatus,
  [color: string, contrastColor: string]
> = {
  stamped: ['#ECFDF3', '#027A48'],
  cancelled: ['#FEF3F2', '#B42318'],
  not_accepted: ['#FEF3F2', '#B42318'],
  acceptance_pending: ['#F8F9FC', '#363F72'],
  cancellation_in_progress: ['#F8F9FC', '#363F72'],
  pending: ['#FEF6EE', '#B93815'],
};

const cfdiInvoiceTypeDataBase = {
  income: 0,
  expenses: 1,
  payment: 2,
  payroll: 3,
  transfer: 4,
};

export const cfdiInvoiceType: Record<CfdiInvoiceType, string> = {
  payment: 'Complemento de pago',
  expenses: 'Egreso/Nota de crédito',
  income: 'Ingreso',
  payroll: 'Nómina',
  transfer: 'Transferencia',
};

export const cfdiInvoiceTypeDbOpts = Object.entries(
  cfdiInvoiceTypeDataBase,
).map(([key, value]) => ({
  label: cfdiInvoiceType[key as CfdiInvoiceType],
  value: `${value}`,
}));

export const invoiceTypeChipColor: Record<
  string,
  ComponentProps<typeof Chip>['color']
> = {
  income: 'primary200',
  payroll: 'graymodern',
  expenses: 'warning200',
  payment: 'gray200',
  credit_note: 'success200',
};
