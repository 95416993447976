import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Text, Flex, Button, Divider } from '@konta/ui';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import { Process, ProcessType } from 'types/entities';
import useProcess from '@hooks/useProcess';
import { NotificationManager } from '@components/Notifications';
import { PROCESSES_HOME_ROUTE } from '@constants/process';

interface UnfinishedAnnualProps {
  process: Process;
}

export default function UnfinishedAnnual({ process }: UnfinishedAnnualProps) {
  const navigate = useNavigate();
  const annualPeriod = dayjs().subtract(1, 'year').year();

  const { activeStepRoute } = useProcess(
    ProcessType.AnnualDeclarationProcess,
    undefined,
    process.id,
  );

  const handleClick = () => {
    if (process?.completed) {
      navigate(PROCESSES_HOME_ROUTE);
      return;
    }
    if (!activeStepRoute || !process?.id) {
      NotificationManager.error(
        'Ocurrió un error al abrir el proceso de Anuales, por favor contacta a soporte.',
        'Anuales',
      );
      return;
    }

    navigate(activeStepRoute);
  };

  if (process?.completed) {
    return null;
  }

  return (
    <>
      <Flex direction="column" gap={12}>
        <Text x3l bold>
          Declaración Anual
        </Text>
        <Text l color="gray700">
          Responde unas preguntas sobre tu actividad del {annualPeriod} y
          presenta tu declaración anual para estar al corriente con tus
          obligaciones fiscales y evitar posibles sanciones o multas.
        </Text>
        <div>
          <Button
            rightIcon={<ArrowRightIcon />}
            onClick={handleClick}
            color="primary"
            css={{ mt: '$12' }}
            id="AnnualDeclarationProcessBtn"
            data-cy="AnnualDeclarationProcessBtn"
          >
            Continuar con las anuales
          </Button>
        </div>
      </Flex>
      <Divider />
    </>
  );
}
