import React from 'react';
import { Colxx } from '@components/CustomBootstrap';
import { useTaxableEntity, usePaymentMethods } from '@hooks';
import { PaymentTabs } from '@components/Payment';
import { Row, Card, CardBody } from 'reactstrap';
import { AddCardButton, SubmitButton } from '@components/Buttons';
import { ButtonConfirmModal } from '@components/Modal';
import { NotificationManager } from '@components/Notifications';

export default function (props) {
  const {
    ui: { selected_card },
    loading,
    handleDefaultCard,
    handleDeleteCard,
  } = usePaymentMethods();
  const { taxable_entity } = useTaxableEntity();

  const setDefault = (_) => {
    selected_card
      ? handleDefaultCard(taxable_entity, selected_card)
      : selectCardNotification();
  };
  const deleteCard = (_) => {
    selected_card
      ? handleDeleteCard(taxable_entity.id, selected_card.id)
      : selectCardNotification();
  };

  const selectCardNotification = (_) => {
    NotificationManager.primary(
      'Selecciona una tarjeta',
      'Upss...',
      3000,
      null,
      null
    );
  };

  return (
    <Row className="mb-4">
      <Colxx xxs="12">
        <Card>
          <CardBody className="no-pills">
            <PaymentTabs
              cardTitle="Mis tarjetas"
              tabs={['task.payment-methods-card']}
            >
              <AddCardButton />
              {selected_card?.source_type !== 'oxxo_recurrent' &&
                selected_card?.source_type !== 'spei_recurrent' && (
                  <ButtonConfirmModal
                    buttonClassName="mb-2"
                    isButtonDisabled={!selected_card}
                    buttonColor="danger"
                    handleSubmit={deleteCard}
                    cancelButton="Cancelar"
                    confirmButton="Confirmar"
                    buttonLabel="Eliminar tarjeta"
                    isButtonDisabled={
                      selected_card?.source_type === 'oxxo_recurrent' ||
                      selected_card?.source_type === 'spei_recurrent'
                    }
                  >
                    <i className="large-icon iconsmind-Danger" color="danger" />
                    <p>¡Estas a punto de borrar una tarjeta!</p>
                  </ButtonConfirmModal>
                )}
              <SubmitButton
                loading={loading}
                text="Marcar como tarjeta por defecto"
                className="d-flex justify-content-center p-4"
                handler={setDefault}
              />
            </PaymentTabs>
          </CardBody>
        </Card>
      </Colxx>
    </Row>
  );
}
