import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, SimpleStateButton } from '@components';
import { Text } from '@konta/ui';
import { PreviewPDFModal } from '@components/Utils';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import toCurrency from '@util/toCurrency';
import { useToggle } from 'rooks';
import PublicInvoiceTable from '@components/PublicInvoiceTable/PublicInvoiceTable';
import usePreviewOfPublicInvoice from '@components/Workflow/hooks/usePreviewOfPublicInvoice';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';

export default function CreateForignAndPublicInvoice() {
  const { workflow, loading: loadingWorkflow } = useSelectedWorkflow();
  const { getDataForPreviewByDeclaration, previewLoading } =
    usePreviewOfPublicInvoice();
  const publicInvoicePreview = getDataForPreviewByDeclaration(
    workflow.active_declaration,
  );
  const isLoading = loadingWorkflow || previewLoading;
  const [invoicePreview, toggleInvoicePreview] = useToggle();
  const total = +(publicInvoicePreview?.receipt?.total || 0);

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Genera tu factura al público en general"
          description="Hemos detectado que el monto de tus facturas de ingreso es menor a tus ingresos totales reportados. Debes realizar una factura al público en general para que tu declaración este correcta."
          videoId="K5b54p7VQmA"
        />
      }
      actions={
        <>
          <PrevPhaseButton loading={isLoading} />
          <PrimaryPhaseButton loading={isLoading}>
            Facturar Ahora
          </PrimaryPhaseButton>
        </>
      }
    >
      <Text x2l>
        ¡Importante! Notamos que aún no factura la totalidad de sus
        <b> ingresos de la(s) constancia(s)</b> + sus <b>ingresos por fuera</b>.
        Es necesario hacer la factura al público en general por la diferencia.
      </Text>
      <Text as="h3">El total que se facturará será de {toCurrency(total)}</Text>
      <Row>
        <Colxx xxs="12" md="6">
          <PublicInvoiceTable />
        </Colxx>
      </Row>
      <div className="d-flex align-items-center w-100">
        <div className="w-100">
          <Row>
            <Colxx xxs="12" md="3">
              <SimpleStateButton
                loading={isLoading}
                outline
                color="primary"
                size="sm"
                className="mb-2 w-100"
                onClick={toggleInvoicePreview}
              >
                Ver vista previa de la Factura
              </SimpleStateButton>
            </Colxx>
          </Row>
        </div>
      </div>
      {invoicePreview && (
        <PreviewPDFModal
          showPreview={invoicePreview}
          toggle={toggleInvoicePreview}
          cfdi={publicInvoicePreview}
          isWorkflowPublicInvoice
          title="Vista Previa"
        />
      )}
    </WorkflowLayout>
  );
}
