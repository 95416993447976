import { ComponentProps, ReactNode, useState } from 'react';
import { Button, HoverCard } from '@konta/ui';

export interface ActionButtonProps
  extends Omit<ComponentProps<typeof Button>, 'children' | 'onClick'> {
  children?: ReactNode;
  tooltipProps?: ComponentProps<typeof HoverCard>;
  tooltip?: string;
  onClick?: () => Promise<void> | void; // Explicitly expect a promise or void
}

export default function ActionButton({
  children,
  tooltipProps,
  tooltip,
  onClick = async () => {},
  ...props
}: ActionButtonProps) {
  const [loading, setLoading] = useState(false);

  const handleOnClick = async () => {
    setLoading(true);
    try {
      await onClick();
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!tooltip) {
    return (
      <Button
        loading={loading}
        onClick={handleOnClick}
        noFill
        variant="outlined"
        icon
        {...props}
      >
        {children}
      </Button>
    );
  }

  return (
    <HoverCard
      {...tooltipProps}
      css={{
        '&.HoverCardContent': {
          padding: '6px',
          width: 'auto',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        },
      }}
      openDelay={500}
      triggerElement={
        <Button
          loading={loading}
          onClick={handleOnClick}
          noFill
          variant="outlined"
          icon
          {...props}
        >
          {children}
        </Button>
      }
    >
      {tooltip}
    </HoverCard>
  );
}
