import React, { memo } from 'react';
import { useToggle } from 'rooks';
import { Modal, ModalHeader } from 'reactstrap';
import { Button, Flex, ModalContent, Text } from '@konta/ui';
import { EyeLineIcon, EyeOutlineIcon } from '@konta/icons';
import CfdiPreviewPdf from '../CfdiPreviewPdf';
import ActionButton from '../../../shared/components/ActionButton';

interface CfdiPreviewPdfModalProps {
  title: string;
  cfdiId?: string | number;
  onlyIcon?: boolean;
  accessToken?: string;
}

function CfdiPreviewPdfModal({
  title,
  cfdiId,
  accessToken,
  onlyIcon = false,
}: CfdiPreviewPdfModalProps) {
  const [open, toggleOpen] = useToggle();

  return (
    <Flex>
      {onlyIcon ? (
        <ActionButton
          size="xs"
          tooltip="Ver PDF de la factura"
          onClick={toggleOpen}
        >
          <EyeLineIcon />
        </ActionButton>
      ) : (
        <Button
          size="2xs"
          color="gray"
          onClick={toggleOpen}
          variant="outlined"
          leftIcon={<EyeOutlineIcon />}
        >
          <Text>Visualizar</Text>
        </Button>
      )}
      {open && (
        <Modal isOpen={open} toggle={toggleOpen} size="lg" width="auto">
          <ModalHeader toggle={toggleOpen}> {title} </ModalHeader>
          <ModalContent css={{ minHeight: '700px', display: 'flex' }}>
            <CfdiPreviewPdf accessToken={accessToken} cfdiId={cfdiId} />
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
}

export default memo(CfdiPreviewPdfModal);
