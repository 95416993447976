import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '@redux/actions';
import { Colxx } from '@components';
import { Card, CardBody, Row } from 'reactstrap';
import NewProductButton from '@components/NewProductButton';
import ClientProducts from '@components/ClientProducts';

export default function InvoiceProducts() {
  const taxableEntity = useSelector(
    (state) => state.taxableEntity.taxable_entity,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts(taxableEntity));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row className="mb-4">
        <Colxx xxs={12}>
          <NewProductButton />
        </Colxx>
      </Row>
      <Row className="mb-4">
        <Colxx xxs={12}>
          <Card>
            <CardBody>
              <ClientProducts />
            </CardBody>
          </Card>
        </Colxx>
      </Row>
    </>
  );
}
