import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  notificationPreferencesUpdateFailure,
  notificationPreferencesUpdateSuccess,
} from '../actions';

export default function* ({ payload }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `notification_configs/${payload.id}`,
      payload,
      { headers: { Authorization: `${access_token}` } }
    );

    yield call(StatusHandler, {
      response,
      title: 'Preferencia actualizada',
      message: 'Se editó con éxito',
      status: response.status,
      success: notificationPreferencesUpdateSuccess,
      failure: notificationPreferencesUpdateFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      message: 'Algo salió mal borrar el email o teléfono',
      action: notificationPreferencesUpdateFailure,
    });
  }
}
