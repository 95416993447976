import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination, useSortBy } from 'react-table';
import toCurrency from '@util/toCurrency';
import {
  ConfiguredReactTable,
  Button,
  Text,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Flex,
  Chip,
} from '@konta/ui';
import {
  TrashCanOutlineIcon,
  EyeOutlineIcon,
  Edit2OutlineIcon,
  Download01Icon,
} from '@konta/icons';
import { DECLARATION_ENTRY_TYPE_DICT } from '@constants/declarations';
import { useToggle } from 'rooks';
import { useDispatch } from 'react-redux';
import { declarationEntriesDelete } from '@redux/declaration_entries/actions';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import { ConfirmModal } from '../Modal';
import ForeignProductModalForm from '../ForeignProductModalForm';
import ForeignInvoiceModalForm from '../ForeignInvoiceModalForm';

export default function ForeignDeclarationEntriesTable({
  loading,
  foreignDeclarationEntries,
}) {
  const dispatch = useDispatch();
  const { workflow } = useSelectedWorkflow();
  const [deleteItem, setDeleteItem] = useState();
  const [openDeleteModal, toggleOpenDeleteModal] = useToggle();
  const [selectedForeignProduct, setSelectedForeignProduct] = useState(null);
  const [selectedForeignInvoice, setSelectedForeignInvoice] = useState(null);
  const [readOnly, setReadonly] = useState(false);

  const setSelectedItem = (item) => {
    if (item?.source_type === 'ForeignInvoice') {
      setSelectedForeignInvoice(item);
    } else {
      setSelectedForeignProduct(item);
    }
  };

  const cleanSelectedItems = () => {
    setSelectedForeignInvoice(null);
    setSelectedForeignProduct(null);
  };

  const foreignDeclarationEntriesColumns = useMemo(() => {
    return [
      {
        Header: <Text m>Descripción</Text>,
        accessor: 'description',
        Cell: ({ value }) => value,
      },
      {
        Header: <Text m>Total</Text>,
        accessor: 'total',
        Cell: ({ row: { original } }) => {
          const foreignProductAmount =
            original.source?.foreign_product?.taxable_amount;
          const foreignInvoiceAmount = original.source?.foreign_invoice?.amount;

          return toCurrency(
            +(foreignInvoiceAmount || foreignProductAmount || 0),
          );
        },
      },
      {
        Header: <Text m>Tipo</Text>,
        accessor: 'declaration_entry_type',
        Cell: ({ value }) => (
          <Chip
            color={
              {
                foreign_invoices: 'gray200',
                foreign_products: 'primary200',
                default: 'warning200',
              }[value || 'default']
            }
            label={
              DECLARATION_ENTRY_TYPE_DICT.find((entry) => entry.type === value)
                ?.text || 'Sin tipo'
            }
          />
        ),
      },
      {
        Header: <Text m>Acciones</Text>,
        accessor: 'actions',
        Cell: ({ row: { original } }) => {
          const foreignProductDocument =
            original.source?.foreign_product?.documents;
          const foreignInvoiceDocument = original?.file;
          const documentUrl = foreignProductDocument || foreignInvoiceDocument;

          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="2xs" color="gray" variant="minimal">
                  <div
                    style={{
                      fontSize: '25px',
                      paddingBottom: '50%',
                    }}
                  >
                    &#x2026;
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  onClick={() => {
                    setReadonly(true);
                    setSelectedItem(original);
                  }}
                >
                  <Flex align="center">
                    <EyeOutlineIcon />
                    <Text css={{ mr: '$16', ml: '$12' }}>Ver detalle</Text>
                  </Flex>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    setReadonly(false);
                    setSelectedItem(original);
                  }}
                >
                  <Flex align="center">
                    <Edit2OutlineIcon />
                    <Text css={{ mr: '$16', ml: '$12' }}>Editar</Text>
                  </Flex>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    setDeleteItem(original.id);
                    toggleOpenDeleteModal();
                  }}
                >
                  <Flex align="center">
                    <TrashCanOutlineIcon />
                    <Text css={{ mr: '$16', ml: '$12' }}>Eliminar</Text>
                  </Flex>
                </DropdownMenuItem>
                {!!documentUrl && (
                  <DropdownMenuItem
                    onClick={() => {
                      window.open(documentUrl);
                    }}
                  >
                    <Flex align="center">
                      <Download01Icon />
                      <Text css={{ mr: '$16', ml: '$12' }}>
                        Descargar documento
                      </Text>
                    </Flex>
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ];
  }, [toggleOpenDeleteModal]);

  const foreignDeclarationEntriesTable = useTable(
    {
      columns: foreignDeclarationEntriesColumns,
      data: foreignDeclarationEntries,
    },
    useSortBy,
    usePagination,
  );
  const deleteProduct = useCallback(
    (productId) => {
      dispatch(
        declarationEntriesDelete({
          workflow_id: workflow.id,
          declaration_entries_id: productId,
        }),
      );
      toggleOpenDeleteModal();
    },
    [dispatch, toggleOpenDeleteModal, workflow.id],
  );

  return (
    <>
      <ConfirmModal
        title="Eliminar"
        open={openDeleteModal}
        onAccept={() => deleteProduct(deleteItem)}
        onCancel={toggleOpenDeleteModal}
        onClose={toggleOpenDeleteModal}
      >
        ¡Estas a punto de eliminar un gasto en el extranjero!
      </ConfirmModal>
      <ForeignProductModalForm
        isOpen={!!selectedForeignProduct}
        foreignProduct={selectedForeignProduct}
        onClose={cleanSelectedItems}
        onSubmit={cleanSelectedItems}
        readOnly={readOnly}
        setReadOnly={setReadonly}
      />
      <ForeignInvoiceModalForm
        isOpen={!!selectedForeignInvoice}
        foreignInvoice={selectedForeignInvoice}
        onClose={cleanSelectedItems}
        onSubmit={cleanSelectedItems}
        readOnly={readOnly}
        setReadOnly={setReadonly}
        taxableEntityPreferences={null}
        defaultRegimeOption={null}
      />
      <ConfiguredReactTable
        {...foreignDeclarationEntriesTable}
        loading={loading}
        noDataText="No hay facturas emitidas"
      />
    </>
  );
}

ForeignDeclarationEntriesTable.propTypes = {
  foreignDeclarationEntries: PropTypes.arrayOf(PropTypes.instanceOf(Object))
    .isRequired,
  loading: PropTypes.bool,
};

ForeignDeclarationEntriesTable.defaultProps = {
  loading: false,
};
