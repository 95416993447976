import { useMemo } from 'react';
import { Dialog, DialogContent, Flex, config, useMediaQuery } from '@konta/ui';
import { XCloseLineIcon } from '@konta/icons';
import ReactMarkdown from 'react-markdown';
import {
  BlogDialogHeader,
  BlogDialogHeaderContent,
  BlogDialogHeaderClose,
  BlogContent,
  BlogPostTitle,
  BlogPostSummary,
  BlogPostAuthorContainer,
} from './styled';
import { MarkdownData } from '../types';

interface PostDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  blogContent: MarkdownData;
}
export default function PostDialog({
  isOpen,
  setIsOpen,
  blogContent,
}: PostDialogProps) {
  const isTabletOrMobile = useMediaQuery(config.media['<sm']);

  const dialogContentProps = useMemo(() => {
    if (isTabletOrMobile) {
      return {
        position: 'bottom',
        slideForClose: true,
      } as { position: 'bottom'; slideForClose: true };
    }
    return {
      position: 'center' as const,
    };
  }, [isTabletOrMobile]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent
        size="full"
        css={{ padding: '0px', display: 'flex', flexDirection: 'column' }}
        {...dialogContentProps}
      >
        {!isTabletOrMobile && (
          <BlogDialogHeader>
            <BlogDialogHeaderContent>
              <BlogDialogHeaderClose>
                <XCloseLineIcon />
              </BlogDialogHeaderClose>
              <span>{blogContent.metadata?.title || 'Blog Post'}</span>
            </BlogDialogHeaderContent>
          </BlogDialogHeader>
        )}
        <Flex column align="center" overflow>
          <BlogContent>
            <BlogPostTitle>{blogContent.metadata?.title}</BlogPostTitle>
            <BlogPostSummary>{blogContent.metadata?.summary}</BlogPostSummary>
            <BlogPostAuthorContainer>
              <img src={blogContent.metadata?.img} alt="Avatar" />
              <div data-slot="title">
                <span data-slot="author">{blogContent.metadata?.author}</span>
                <span data-slot="description">{blogContent.metadata?.bio}</span>
              </div>
            </BlogPostAuthorContainer>
            <ReactMarkdown>{blogContent.content}</ReactMarkdown>
          </BlogContent>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
