import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useFormik, FormikProvider, Form } from 'formik';
import { Button, Card, Flex, styled, Text } from '@konta/ui';
import { Logout2Icon } from '@konta/icons';
import { useTaxableEntity } from '@hooks';
import useUser from '@hooks/useUser';
import { updateTaxableEntity } from '@redux/taxable_entity/actions';
import { logoutUser, onboardingUser } from '@redux/auth/actions';
import { fiscalRegimensFetch } from '@redux/fiscal_regimens/actions';
import FormikPhoneInput from '@components/FormikPhoneInput';
import { phoneRegExp } from '@util/formatPhoneNumber';

const validationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, 'El número de teléfono no es válido')
    .test({
      message: 'El número de teléfono no es válido',
      test: (value) => {
        if (value && value.startsWith('+52')) {
          const num = value.slice(3);
          return num.length === 10;
        }
        return true;
      },
    })
    .required('Campo requerido'),
});
const initialValues = {
  phoneNumber: '',
};

const GRADIENT_BACKGROUND =
  'linear-gradient(to left bottom, #717cdb, #686dc9, #605eb7, #5750a5, #4e4294, #4e4294, #4e4294, #4e4294, #5750a5, #605eb7, #686dc9, #717cdb);, #6d72d8, #717cdb);';

const FormWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mt: '$24',
  mb: '$24',
  gap: '$16',
  label: { marginBottom: 0 },
});

export default function PreOnboarding() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const featureClient = useLDClient();
  const { user, loading: loadingUser } = useUser();
  const { taxable_entity: taxableEntity } = useTaxableEntity();

  useEffect(() => {
    dispatch(fiscalRegimensFetch());
  }, [dispatch]);

  const handleSubmit = ({ phoneNumber }) => {
    const rc = localStorage.getItem('rc');
    const onboardingPayload = {
      user: {
        phone_number: phoneNumber,
      },
    };
    const taxablePayload = {
      taxable_entity: {
        referal: rc && rc,
        onboarding_detail_attributes: {
          is_rejected_user: false,
        },
      },
    };

    dispatch(
      onboardingUser(onboardingPayload, { silent: true }, featureClient),
    );

    dispatch(
      updateTaxableEntity(taxablePayload, taxableEntity.id, {
        silent: true,
      }),
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const handleLogout = () => {
    dispatch(logoutUser(navigate));
  };

  const typeFormUrl = `https://konta.typeform.com/to/vWW4DyYO#correo=${user.email}&name=${user.first_name}&hubspot_utk=xxxxx&hubspot_page_name=xxxxx&hubspot_page_url=xxxxx`;

  if (user && user.phone_number) {
    return (
      <Flex
        css={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: GRADIENT_BACKGROUND,
          px: '$10',
        }}
      >
        <Card css={{ '@lg': { width: '50%' } }}>
          <iframe
            className="w-100"
            style={{
              minHeight: '600px',
              border: 'none',
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px',
            }}
            src={typeFormUrl}
            title="onbaording"
          />
        </Card>
      </Flex>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Flex
        css={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: GRADIENT_BACKGROUND,
          px: '$10',
        }}
      >
        <Card className="auth-card mw-100">
          <Form className="form-side onboarding pb-4 px-4 flex-column">
            <Text as="h2">¡Hola, {user.first_name}!</Text>
            <p className="m-0">
              Llena la siguiente información para que nos podamos comunicar
              contigo y te podamos dar la mejor atención posible.
            </p>
            <FormWrapper>
              <FormikPhoneInput
                name="phoneNumber"
                label="Teléfono *"
                id="phoneNumber"
              />
            </FormWrapper>
            <Flex justify="between" css={{ my: '$16' }}>
              <Button
                loading={loadingUser}
                onClick={handleLogout}
                leftIcon={<Logout2Icon />}
                variant="text"
              >
                Cerrar sesión
              </Button>
              <Button
                loading={loadingUser}
                type="submit"
                color="primary"
                data-testid="submit"
              >
                Siguiente
              </Button>
            </Flex>
          </Form>
          <Flex css={{ p: '$40 $10', flex: 1 }}>
            <div className="position-relative image-side d-none d-lg-inline-block" />
          </Flex>
        </Card>
      </Flex>
    </FormikProvider>
  );
}
