import toCurrency from '@util/toCurrency';
import { useMemo } from 'react';
import getTotalsFromDeclarationIncome from 'shared/util/getTotalsFromDeclarationIncome';
import getRegimeNameFromFiscalRegime, {
  shortNames,
} from 'shared/util/getRegimeNameFromFiscalRegime';
import toFixed from '@util/toFixed';
import useWorkflow from '../hooks/useWorkflow';
import useSearchParamsState from '../../../@hooks/useSearchParamsState';

export default function useIncomesByRegime() {
  const {
    isLoadingWorkflow,
    currentPreferredRegimes,
    workflowId,
    workflow,
    totalDeclarationIncomes,
    updateWorkflowGraphs,
    activeDeclarations,
    preferredFiscalRegime,
  } = useWorkflow();

  const getDeclarationTotals = useMemo(
    () =>
      activeDeclarations.map((declaration) => {
        const {
          totalRetainedOfCfdis,
          totalTaxes,
          totalIvaBases,
          totalExemptBases,
          totalZeroBases,
          totalIvaBasesOfCfdis,
          totalIvaOfCfdis,
          totalZeroBasesOfCfdis,
          totalIvaExemptBasesOfCfdis,
          totalIvaBase16Unbilled,
          totalZeroBasesUnbilled,
          totalExemptBasesUnbilled,
        } = getTotalsFromDeclarationIncome(declaration.declaration_income);
        const grandTotalBases =
          totalIvaBases + totalExemptBases + totalZeroBases;
        const grantTotal = grandTotalBases + totalTaxes;
        const totalBilledIncome =
          totalIvaBasesOfCfdis +
          totalIvaOfCfdis +
          totalZeroBasesOfCfdis +
          totalIvaExemptBasesOfCfdis -
          totalRetainedOfCfdis;
        const regimeName =
          shortNames[declaration.fiscal_regime.sat_key] ||
          getRegimeNameFromFiscalRegime(declaration.fiscal_regime);
        return {
          regimeName,
          total: grantTotal,
          totalBilledIncome,
          unBilled: {
            totalBases: toFixed(totalIvaBase16Unbilled, 1),
            totalExemptBases: toFixed(totalExemptBasesUnbilled, 1),
            totalZeroBases: toFixed(totalZeroBasesUnbilled, 1),
          },
        };
      }),
    [activeDeclarations],
  );

  const platformTotals = useMemo(
    () =>
      getDeclarationTotals.find((row) => row.regimeName === 'Plataformas') || {
        regimeName: 'Plataformas',
        total: 0,
        totalBilledIncome: 0,
        unBilled: {
          totalBases: 0,
          totalExemptBases: 0,
          totalZeroBases: 0,
        },
      },
    [getDeclarationTotals],
  );

  const isConstancyExeeded = useMemo(() => {
    const hasNegativesUnbilled =
      platformTotals.unBilled.totalBases < 0 ||
      platformTotals.unBilled.totalExemptBases < 0 ||
      platformTotals.unBilled.totalZeroBases < 0;

    return hasNegativesUnbilled;
  }, [platformTotals]);

  const [action, setAction] = useSearchParamsState('action', '');
  const showEditIncomes = ['edit', 'new'].includes(action);
  const toggleShowEditIncomes = () => setAction(showEditIncomes ? '' : 'edit');

  const total = useMemo(() => {
    if (preferredFiscalRegime) {
      return +(
        activeDeclarations.find(
          (activeDeclaration) =>
            activeDeclaration.fiscal_regime.id === preferredFiscalRegime.id,
        )?.declaration_income?.total || 0
      );
    }
    return totalDeclarationIncomes;
  }, [activeDeclarations, preferredFiscalRegime, totalDeclarationIncomes]);

  return {
    isLoading: isLoadingWorkflow,
    showEditIncomes,
    toggleShowEditIncomes,
    currentRegimes: currentPreferredRegimes,
    workflowId,
    workflow,
    totalDeclarationIncomesFormatted: toCurrency(total),
    updateWorkflowGraphs,
    isConstancyExeeded,
  };
}
