import { Flex } from '@konta/ui';
import { ArrowRightLineIcon, InfoCircleLineIcon } from '@konta/icons';
import WorkflowsLayout from '@components/WorkflowsLayout';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import useUser from '@hooks/useUser';
import getRegimeNameFromFiscalRegime from 'shared/util/getRegimeNameFromFiscalRegime';
import useWorkflow from '../hooks/useWorkflow';
import {
  Feature,
  Header,
  Root,
  Title,
  Bold,
  Alert,
  AlertText,
  AlertContent,
  AlertDescription,
} from '../styled';

const steps = [
  'Verificar que estés considerando la totalidad de tus ingresos de ambos regímenes.',
  'Revisaremos tus gastos y los clasificaremos de acuerdo a si son deducibles o no.',
  'Deberás autorizar los gastos que se estarán deduciendo.',
  'Te entregaremos una previa de tu declaración de impuestos.',
  'Deberás autorizar tu declaración para que podamos presentar la ante el SAT.',
];

export default function Intro() {
  const { user } = useUser();
  const { currentRegimes } = useWorkflow();

  return (
    <WorkflowsLayout
      actions={
        <PrimaryPhaseButton rightIcon={<ArrowRightLineIcon />}>
          ¡Empezar!
        </PrimaryPhaseButton>
      }
      title="Declaración mensual"
    >
      <Root>
        <Header>
          <Title>¡Hola {user.first_name}!</Title>
          <Feature>
            A continuación te haremos algunas preguntas sobre tus ingresos y
            gastos con el fin de calcular correctamente tus impuestos y realizar
            la declaración mensual.
          </Feature>
          <Feature>
            En cualquier paso puedes consultar nuestro chat para darte asesoría
            de cómo continuar.
          </Feature>
        </Header>
        <Flex column gap={14}>
          {steps.map((step, index) => {
            if (index === 0) {
              return (
                <Feature flex>
                  <Bold>Paso 1.</Bold>
                  &nbsp;
                  <Flex column>
                    {step}
                    {currentRegimes.map((regime) => (
                      <Flex gap={8}>
                        <span>&#8226;</span>
                        {getRegimeNameFromFiscalRegime(regime)}
                      </Flex>
                    ))}
                  </Flex>
                </Feature>
              );
            }
            return (
              <Feature>
                <Bold>Paso {index + 1}.</Bold> {step}
              </Feature>
            );
          })}
        </Flex>
        <Alert mt2>
          <InfoCircleLineIcon />
          <AlertContent>
            <AlertText>
              No presentamos nada ante el SAT hasta que autorices tu declaración
              y Konta revise una vez más la información proporcionada.
            </AlertText>
            <AlertDescription>
              Este formulario nos permite entender la situación fiscal de tu
              negocio, con el fin de poder determinar los impuestos a pagar.
            </AlertDescription>
          </AlertContent>
        </Alert>
      </Root>
    </WorkflowsLayout>
  );
}
