import { useSelector } from 'react-redux';
import type { IRootState } from 'types/redux';
import { useMemo } from 'react';

export default function useSelectedWorkflow() {
  const selectedWorkflow = useSelector((state: IRootState) => ({
    ...state.workflows.selected_workflow,
    workflowLoading: state.workflows.selected_workflow.loading,
  }));

  return useMemo(() => selectedWorkflow, [selectedWorkflow]);
}
