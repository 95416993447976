import { Fragment } from 'react';
import { ChevronRightLineIcon } from '@konta/icons';
import { Root, Item, Text, Icon } from './styled';

interface BreadcrumbsProps {
  breadcrumbs: {
    label: string;
    onClick: () => void;
    isActive?: boolean;
  }[];
}
export default function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  return (
    <Root>
      {breadcrumbs.map((breadcrumb, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return (
          <Fragment key={breadcrumb.label}>
            <Item key={breadcrumb.label} onClick={breadcrumb.onClick}>
              <Text active={breadcrumb.isActive}>{breadcrumb.label}</Text>
            </Item>
            {!isLast && (
              <Icon>
                <ChevronRightLineIcon />
              </Icon>
            )}
          </Fragment>
        );
      })}
    </Root>
  );
}
