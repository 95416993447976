import {
  PRODUCTS_FETCH,
  PRODUCTS_FETCH_SUCCESS,
  PRODUCTS_FETCH_FAILURE,
  PRODUCTS_POST,
  PRODUCTS_POST_SUCCESS,
  PRODUCTS_POST_FAILURE,
  PRODUCTS_EDIT,
  PRODUCTS_EDIT_SUCCESS,
  PRODUCTS_EDIT_FAILURE,
  PRODUCTS_DELETE,
  PRODUCTS_DELETE_SUCCESS,
  PRODUCTS_DELETE_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  products: [],
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PRODUCTS_FETCH:
    case PRODUCTS_POST:
      return { ...state, loading: true };
    case PRODUCTS_EDIT:
    case PRODUCTS_DELETE:
      return { ...state, loading: true };
    case PRODUCTS_FETCH_SUCCESS:
    case PRODUCTS_POST_SUCCESS:
      return { ...state, loading: false, products: action.payload };
    case PRODUCTS_EDIT_SUCCESS:
      const { products } = state;
      const foundIndex = products.findIndex((x) => x.id === action.payload.id);
      products[foundIndex] = action.payload;
      return { ...state, loading: false, products: [...products] };
    case PRODUCTS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        products: state.products.filter(
          (product) => product.id !== action.payload
        ),
      };
    case PRODUCTS_FETCH_FAILURE:
    case PRODUCTS_POST_FAILURE:
      return { ...state, loading: false };
    case PRODUCTS_EDIT_FAILURE:
    case PRODUCTS_DELETE_FAILURE:
      return { ...state, loading: false };
    default:
      return { ...state };
  }
};
