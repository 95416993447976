import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardTitle, CardBody, Button, Collapse } from 'reactstrap';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { NotificationManager } from '@components/Notifications';

import { Colxx } from '@components/CustomBootstrap';
import { updateUser } from '@redux/actions';

class SecurityFormCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  handleSubmit = (event, errors, values) => {
    if (
      errors.length === 0 &&
      values.password === values.password_confirmation
    ) {
      // submit
      const user = {
        user: {
          ...values,
        },
      };
      this.props.updateUser(user);
      this.toggle();
    } else {
      NotificationManager.error(
        'Verifique que los datos sean correctos e intente de nuevo.',
        'Upss..',
        6000,
        null,
        null,
        null
      );
    }
  };

  render() {
    const defaultValues = {
      locationType: 'work',
      locationQualities: ['beautiful', 'awesome', 'wonderful'],
      firstname: 'Sarah',
      lastname: 'Kortney',
      location: {
        street: '10752 Deerwood Park Blvd',
        suite: '110',
        city: 'Jacksonville',
        state: 'Florida',
        zip: '32256',
      },
    };
    return (
      <Card>
        <CardBody>
          <CardTitle tag="h4">Seguridad</CardTitle>
          <div className="clearfix">
            <div className="float-left mt-1">
              <h5>Editar contraseña de usuario</h5>
            </div>
            <div className="float-right">
              <Button
                color="primary"
                size="xs"
                onClick={this.toggle}
                className="mb-1"
              >
                Editar
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.collapse}>
            <AvForm
              onSubmit={this.handleSubmit}
              model={defaultValues}
              className="has-float-label"
            >
              <Row className="mt-4">
                <Colxx lg={6}>
                  <AvGroup>
                    <AvField
                      label="Nueva contraseña"
                      type="password"
                      name="password"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Introduce tu contraseña',
                        },
                        minLength: {
                          value: 6,
                          errorMessage:
                            'La contraseña debe tener una longitud minima de 6 caracteres',
                        },
                        pattern: {
                          value: '^(?=.*[0-9])(?=.*[a-zA-Z])(.*)$',
                          errorMessage:
                            'La contraseña debe contener numero y letras.',
                        },
                      }}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx lg={6}>
                  <AvGroup>
                    <AvField
                      label="Confirmar contraseña"
                      type="password"
                      name="password_confirmation"
                      validate={{
                        required: {
                          value: true,
                          errorMessage:
                            'Ingresa la confirmación de la contraseña',
                        },
                      }}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx
                  xxs="12"
                  className="d-flex align-content-center justify-content-center"
                >
                  <Button color="primary" size="sm">
                    Guardar
                  </Button>
                </Colxx>
              </Row>
            </AvForm>
          </Collapse>
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { updateUser })(SecurityFormCard);
