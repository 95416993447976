import React from 'react';
import { connect } from 'react-redux';
import { getMonthAndYearDate } from '@util/Utils';
import toCurrency from '@util/toCurrency';
import {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
} from '@redux/actions';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';

function ConstancySummary({ workflow }) {
  const { declaration_income } = workflow?.active_declaration || {};

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Resumen de ingresos de la constancia de retención"
          description="En esta sección podrá ver un resumen de los ingresos en la constancia de retención."
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton />
        </>
      }
    >
      <div className="d-flex align-items-center">
        <div>
          <div className="w-80 w-sm-100">
            <h1>
              {'Según su(s) constancia(s) de retención del mes de '}
              {getMonthAndYearDate(new Date(workflow.start_date))}
              {', se obtuvieron ingresos por ventas de '}
              {toCurrency(+declaration_income.total_of_constancies)}
              {' (+ IVA).'}
            </h1>
          </div>
        </div>
      </div>
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ workflows }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { workflow, wloading };
};
export default connect(mapStateToProps, {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
})(ConstancySummary);
