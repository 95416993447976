import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Text,
  CardContent,
  VerticalStepper,
  StepIndicator,
  VerticalStep,
  Tooltip,
  Flex,
} from '@konta/ui';
import { Process, ProcessType } from 'types/entities';
import useProcess from '@hooks/useProcess';

export interface ProcessStatusCardProps {
  selectedProcess: Process;
}

const completedLabelsByProcessType: Record<ProcessType, ReactNode> = {
  OnboardingOnboardingProcess: (
    <>
      <b>¡Listo!</b>
      <p data-testid="onboarding-done">
        Haz completado el primer paso en el proceso de apertura de tu cuenta.
        Ahora, es importante que continúes con los siguientes procesos para
        asegurarte de que tu cuenta esté lista para usar.
      </p>
    </>
  ),
  FinishOnboardingProcess: (
    <>
      <b>¡Listo!</b>
      <p>
        Haz finalizado tu proceso de apertura de tu cuenta. Ahora, es importante
        que continúes con los siguientes procesos para asegurarte de que tu
        cuenta esté lista para usar.
      </p>
    </>
  ),
  AnnualDeclarationProcess: null,
};

export default function ProcessStatusCard({
  selectedProcess,
}: ProcessStatusCardProps) {
  const { type, completed, name, description } = selectedProcess;
  const { availableSteps, activeStepRoute, getIndexByStepType } = useProcess(
    selectedProcess.type,
  );
  const navigate = useNavigate();

  const hasCompletedLabel = !!completedLabelsByProcessType[type];
  const showStepper = !completed || !hasCompletedLabel;

  const lastStep = useMemo(() => {
    const last = availableSteps[availableSteps.length - 1];
    return availableSteps.find((step) => !step.completed) || last;
  }, [availableSteps]);

  const lastStepIndex = getIndexByStepType(lastStep?.type);

  return (
    <Card outlined css={{ width: '400px', '@lg': { width: '500px' } }}>
      <CardHeader
        css={{
          borderBottom: '1px solid $colors$gray300',
          padding: '16px 20px',
        }}
        header={<Text l>{name}</Text>}
        subheader={
          <Text color="gray500" s>
            {description}
          </Text>
        }
      />
      <CardContent
        css={{
          pt: '16px',
        }}
      >
        {showStepper ? (
          <VerticalStepper
            value={lastStepIndex}
            iconSize="medium"
            binary={completed}
          >
            {availableSteps.map((step, currentStepIndex) => {
              const enabled = currentStepIndex <= lastStepIndex;
              const hasClick = step.type === lastStep.type;
              const handleClick = () => {
                if (activeStepRoute) {
                  navigate(activeStepRoute);
                }
              };

              return (
                <VerticalStep
                  id={currentStepIndex}
                  key={step.id}
                  title={
                    <Tooltip
                      triggerElement={
                        <Flex align="center">
                          <Text color={enabled ? 'primary700' : 'gray400'}>
                            {step.name}
                          </Text>
                        </Flex>
                      }
                    >
                      {step.description}
                    </Tooltip>
                  }
                  icon={<StepIndicator />}
                  onClick={hasClick && !completed ? handleClick : undefined}
                />
              );
            })}
          </VerticalStepper>
        ) : (
          completedLabelsByProcessType[type]
        )}
      </CardContent>
    </Card>
  );
}
