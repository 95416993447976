import {
  CheckCircleLineIcon,
  Hourglass03LineIcon,
  Inbox01LineIcon,
} from '@konta/icons';

export const AVAILABLE_PROCESSES = ['onboarding', 'annualDeclaration'];

export const TABS_CONFIG = [
  {
    id: 'requiredAction',
    icon: <Inbox01LineIcon />,
    label: 'Acción requerida',
  },
  {
    id: 'pending',
    icon: <Hourglass03LineIcon />,
    label: 'En espera de konta',
  },
  {
    id: 'completed',
    icon: <CheckCircleLineIcon />,
    label: 'Completadas',
  },
] as const;

export const TASK_MESSAGES = {
  emptyState: {
    requiredAction: {
      title: 'No hay tareas que requieran tu atención',
      subtitle: 'Aquí encontrarás las tareas que requieren tu atención.',
    },
    pending: {
      title: 'No hay tareas que estén en espera de Konta',
      subtitle:
        'Aquí encontrarás las tareas que están en espera de Konta para ser completadas.',
    },
    completed: {
      title: 'No hay tareas completadas',
      subtitle: 'Aquí encontrarás las tareas que ya completaste.',
    },
  },
} as const;
