import { ReactNode, forwardRef, ElementRef } from 'react';
import { CSS, LabelWrapper } from '@konta/ui';
import type { ReactDatePickerProps } from 'react-datepicker';
import type { PopoverProps } from '@konta/ui/dist/components/LabelWrapper/LabelWrapper';
import DatePickerBase, { StyledDate } from './DatePickerBase/DatePickerBase';

export interface DatePickerProps
  extends Omit<ReactDatePickerProps, 'children' | 'size' | 'ref' | 'css'>,
    PopoverProps {
  helperText?: string;
  maxTextLength?: number;
  helperTextColor?: 'gray' | 'primary' | 'success' | 'warning' | 'error';
  label?: string;
  color?: 'gray' | 'primary' | 'success' | 'error' | 'warning';
  size?: 'xs' | 's' | 'm' | 'l';
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  disabled?: boolean;
  css?: CSS;
  inputCss?: CSS;
  placeholderText?: string;
}

const DatePicker = forwardRef<ElementRef<typeof StyledDate>, DatePickerProps>(
  (
    {
      helperText,
      leftIcon,
      rightIcon,
      helperTextColor,
      maxTextLength,
      label,
      size = 's',
      color = 'gray',
      placeholderText,
      disabled,
      css,
      labelPopoverSide,
      labelHelpPopover,
      labelPopoverDelay,
      inputCss,
      ...props
    },
    forwardedRef,
  ) => (
    <LabelWrapper
      helperTextColor={helperTextColor}
      maxTextLength={maxTextLength}
      label={label}
      size={size}
      color={color}
      helperText={helperText}
      css={css}
      labelPopoverDelay={labelPopoverDelay}
      labelHelpPopover={labelHelpPopover}
      labelPopoverSide={labelPopoverSide}
    >
      <DatePickerBase
        {...props}
        size={size}
        color={color}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        placeholderText={placeholderText}
        disabled={disabled}
        ref={forwardedRef}
        inputCss={inputCss}
      />
    </LabelWrapper>
  ),
);

DatePicker.defaultProps = {
  maxTextLength: 0,
  helperText: '',
  color: 'gray',
  helperTextColor: 'gray',
  label: '',
  size: 's',
  leftIcon: null,
  rightIcon: null,
  disabled: false,
};

DatePicker.displayName = 'DatePicker';

export default DatePicker;
