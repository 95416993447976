import api from '@api';
import { call, select } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import { addressFetchSucess, addressFetchFailure } from '../actions';

export function* addressFetchSaga(_) {
  try {
    const { taxable_entity } = yield select((state) => state.taxableEntity);
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.get,
      `/taxable_entities/${taxable_entity.id}/addresses/${taxable_entity.address}`,
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: addressFetchSucess,
      failure: addressFetchFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: addressFetchFailure,
    });
  }
}
