import React, { useMemo } from 'react';
import {
  Button,
  Flex,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Text,
  DataTable,
} from '@konta/ui';
import useApiPagination from 'shared/hooks/useApiPagination';
import ArrowRightThick from 'mdi-react/ArrowRightThickIcon';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export default function InvoiceDraft() {
  const navigate = useNavigate();

  const { loading, items, pagination } = useApiPagination({
    params: { 'q[status_eq]': 'draft' },
    endpoint: 'invoice_drafts',
  });

  const columns = useMemo(
    () => [
      {
        header: 'Receptor',
        label: 'Receptor',
        accessorKey: 'body.receipt.receiver_attributes.name',
      },
      {
        header: 'Rfc receptor',
        accessorKey: 'body.receipt.receiver_attributes.rfc',
        label: 'Rfc receptor',
      },
      { header: 'Estatus', accessorKey: 'status', label: 'Estatus' },
      { header: 'Total', accessorKey: 'price', label: 'Total' },
      {
        header: 'Fecha de creación',
        label: 'Fecha de creación',
        accessorKey: 'created_at',
        cell: ({ renderValue: date }) => dayjs(date()).format('DD-MM-YYYY'),
      },
      {
        header: 'Acciones',
        label: 'Acciones',
        accessorKey: 'uuid',
        cell: ({ renderValue: uuid }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="2xs" color="gray" variant="minimal">
                  <div
                    style={{
                      fontSize: '25px',
                      paddingBottom: '50%',
                    }}
                  >
                    &#x2026;
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  onClick={() => navigate(`/app/invoicing/${uuid()}`)}
                >
                  <Flex align="center">
                    <Text css={{ mr: '$16', ml: '$12' }}>Continuar</Text>
                    <ArrowRightThick />
                  </Flex>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    [],
  );

  return (
    <Flex direction="column" gap={10}>
      <DataTable
        columns={columns}
        data={items}
        loading={loading}
        dataTableId="draftTable"
        manualPagination
        manualPaginationProps={pagination}
      />
    </Flex>
  );
}
