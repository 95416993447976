import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Row } from 'reactstrap';
import { Colxx } from '@components/CustomBootstrap';
import NewInvoiceModal from '@components/NewInvoiceModal';
import InvoicingIncome from './invoicing-income';
import InvoicingContacts from './invoicing-contacts';
import InvoicingProducts from './invoicing-products';

export default function InvoicingTabs() {
  const navigate = useNavigate();
  const goToInvoicing = () => {
    navigate('/app/invoicing');
  };

  return (
    <div className="app-container">
      <Row>
        <Colxx xxs="12">
          <Routes>
            <Route path="/" element={<InvoicingIncome />} />
            <Route
              path="/:uuid"
              element={<NewInvoiceModal visible onClose={goToInvoicing} />}
            />
            <Route
              path="/:template/:uuid"
              element={<NewInvoiceModal visible onClose={goToInvoicing} />}
            />
            <Route path="contacts" element={<InvoicingContacts />} />
            <Route path="products" element={<InvoicingProducts />} />
          </Routes>
        </Colxx>
      </Row>
    </div>
  );
}
