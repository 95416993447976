import React from 'react';
import { Card, Row, Alert } from 'reactstrap';
import { Colxx as Col, SimpleStateButton } from '@components';
import IntlMessages from '@util/IntlMessages';
import { useOrders } from '@hooks';

export function InvoiceCheckoutTotal({
  orders,
  handlePay,
  showPayButton,
  loadingPaymentMethods,
}) {
  const { loading } = useOrders();

  if (orders) {
    let total = 0;
    let totalPayed = 0;
    return (
      <Card body className="mb-4 mt-4 mt-lg-0 ">
        <Row
          className="font-weight-bold text-primary mt-2"
          style={{ fontSize: '1rem' }}
        >
          <Col xxs={{ size: 'auto', offset: 0 }}>
            <IntlMessages id="settings.plan.summary" />
          </Col>
        </Row>
        {orders.map((order, idx) => {
          total += parseFloat(order.total);
          totalPayed += parseFloat(order.balance);
          return (
            <Row key={`order-${idx}`}>
              <Col xxs={{ size: 7, offset: 1 }}>
                {order.source_type === 'Subscription'
                  ? 'Suscripción (Mensual) x 1'
                  : 'Cobro de actualizaciones'}
              </Col>
              <Col xxs={{ size: 3 }} align="right">
                ${(order.total / 1.16).toFixed(2)}
              </Col>
            </Row>
          );
        })}
        {totalPayed > 0 && (
          <Row className="text-danger">
            <Col xxs={{ size: 7, offset: 1 }}>Total pagado</Col>
            <Col xxs={{ size: 3 }} align="right">
              -${(totalPayed / 1.16).toFixed(2)}
            </Col>
          </Row>
        )}
        <hr />
        <Row className="mb-1">
          <Col sm={{ size: 7, offset: 1 }} style={{ fontSize: '1rem' }}>
            <IntlMessages id="settings.plan.subtotal" />
          </Col>
          <Col sm={{ size: 3 }} align="right">
            ${((total - totalPayed) / 1.16).toFixed(2)}
          </Col>
        </Row>
        <Row className="mb-1">
          <Col sm={{ size: 7, offset: 1 }} style={{ fontSize: '1rem' }}>
            <IntlMessages id="settings.plan.taxes" />
          </Col>
          <Col sm={{ size: 3 }} align="right">
            ${(((total - totalPayed) * 0.16) / 1.16).toFixed(2)}
          </Col>
        </Row>
        <Row className="mb-4">
          <Col
            sm={{ size: 7, offset: 1 }}
            style={{ fontSize: '1rem' }}
            className="font-weight-bold"
          >
            <IntlMessages id="settings.plan.total" />
          </Col>
          <Col sm={{ size: 3 }} align="right">
            ${(total - totalPayed).toFixed(2)}
          </Col>
        </Row>
        <Row>
          <Col xxs="12">
            {showPayButton ? (
              <SimpleStateButton
                color="primary"
                loading={loading || loadingPaymentMethods}
                disabled={loading || loadingPaymentMethods}
                className="btn-right btn-block"
                onClick={() => handlePay(total - totalPayed)}
              >
                Pagar
              </SimpleStateButton>
            ) : (
              <Alert color="info" className="rounded-alert">
                ¡Realice el pago del monto total y se vera reflejado en las
                siguientes 48 horas!
              </Alert>
            )}
          </Col>
        </Row>
      </Card>
    );
  }
  return null;
}
