import {
  DECLARATION_ENTRIES_FETCH,
  DECLARATION_ENTRIES_FETCH_SUCCESS,
  DECLARATION_ENTRIES_FETCH_FAILURE,
  DECLARATION_ENTRIES_CREATE,
  DECLARATION_ENTRIES_CREATE_SUCCESS,
  DECLARATION_ENTRIES_CREATE_FAILURE,
  DECLARATION_ENTRIES_UPDATE,
  DECLARATION_ENTRIES_UPDATE_SUCCESS,
  DECLARATION_ENTRIES_UPDATE_FAILURE,
  DECLARATION_ENTRIES_DELETE,
  DECLARATION_ENTRIES_DELETE_SUCCESS,
  DECLARATION_ENTRIES_DELETE_FAILURE,
  UPLOAD_CFDI_XML,
  UPLOAD_CFDI_XML_SUCCESS,
  UPLOAD_CFDI_XML_FAILURE,
  DECLARATION_ENTRIES_BULK_EDIT,
  DECLARATION_ENTRIES_BULK_EDIT_SUCCESS,
  DECLARATION_ENTRIES_BULK_EDIT_FAILURE,
} from '@constants/actionTypes';

export const declarationEntriesFetch = (payload) => ({
  type: DECLARATION_ENTRIES_FETCH,
  payload,
});

export const declarationEntriesFetchSuccess = (payload) => ({
  type: DECLARATION_ENTRIES_FETCH_SUCCESS,
  payload,
});

export const declarationEntriesFetchFailure = (payload) => ({
  type: DECLARATION_ENTRIES_FETCH_FAILURE,
  payload,
});

export const declarationEntriesCreate = (payload) => ({
  type: DECLARATION_ENTRIES_CREATE,
  payload,
});

export const declarationEntriesCreateSuccess = (payload) => ({
  type: DECLARATION_ENTRIES_CREATE_SUCCESS,
  payload,
});

export const declarationEntriesCreateFailure = (payload) => ({
  type: DECLARATION_ENTRIES_CREATE_FAILURE,
  payload,
});

export const declarationEntriesUpdate = (payload) => ({
  type: DECLARATION_ENTRIES_UPDATE,
  payload,
});

export const declarationEntriesUpdateSuccess = (payload) => ({
  type: DECLARATION_ENTRIES_UPDATE_SUCCESS,
  payload,
});

export const declarationEntriesUpdateFailure = (payload) => ({
  type: DECLARATION_ENTRIES_UPDATE_FAILURE,
  payload,
});

export const declarationEntriesDelete = (payload) => ({
  type: DECLARATION_ENTRIES_DELETE,
  payload,
});

export const declarationEntriesDeleteSuccess = (payload) => ({
  type: DECLARATION_ENTRIES_DELETE_SUCCESS,
  payload,
});

export const declarationEntriesDeleteFailure = (payload) => ({
  type: DECLARATION_ENTRIES_DELETE_FAILURE,
  payload,
});

export const declarationEntriesBulkEdit = (payload) => ({
  type: DECLARATION_ENTRIES_BULK_EDIT,
  payload,
});

export const declarationEntriesBulkEditSuccess = (payload) => ({
  type: DECLARATION_ENTRIES_BULK_EDIT_SUCCESS,
  payload,
});

export const declarationEntriesBulkEditFailure = (payload) => ({
  type: DECLARATION_ENTRIES_BULK_EDIT_FAILURE,
  payload,
});

/* Upload cfdi */

export const uploadXmlCfdi = (payload) => {
  return {
    type: UPLOAD_CFDI_XML,
    payload,
  };
};

export const uploadXmlCfdiSuccess = (payload) => {
  return {
    type: UPLOAD_CFDI_XML_SUCCESS,
    payload,
  };
};

export const uploadXmlCfdiFailure = (payload) => {
  return {
    type: UPLOAD_CFDI_XML_FAILURE,
    payload,
  };
};
