import { ReactNode } from 'react';
import { Text } from '@konta/ui';

export default function ProcessStepDescription({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Text
      lineHeight="l"
      regular
      color="gray700"
      css={{ fontSize: '$xs', '@md': { fontSize: '$s' } }}
    >
      {children}
    </Text>
  );
}
