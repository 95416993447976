export const FISCAL_REGIMES = 'FISCAL_REGIMES';
export const USER_ADDRESS = 'USER_ADDRESS';
export const ACTIVE_BANKS = 'ACTIVE_BANKS';
export const FOREIGN_TAXES_PAYERS = 'FOREIGN_TAXES_PAYERS';
export const PPD_COMPLEMENTS = 'PPD_COMPLEMENTS';
export const TWO_FACTOR_QR_CODE = 'TWO_FACTOR_QR_CODE';
export const TWO_FACTOR_BACKUP_CODES = 'TWO_FACTOR_BACKUP_CODES';
export const ANNUAL_DECLARATIONS = 'ANNUAL_DECLARATIONS';
export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';
export const PROCESSES = 'PROCESSES';
export const TAXABLE_ENTITY_COUPONS = 'TAXABLE_ENTITY_COUPONS';
export const INVOICE_DRAFT = 'INVOICE_DRAFT';
export const INVOICE_DOWNLOAD_LINK = 'INVOICE_DOWNLOAD_LINK';
export const INVOICE_PDF_PREVIEW = 'INVOICE_PDF_PREVIEW';
export const INVOICE_JSON_PREVIEW = 'INVOICE_JSON_PREVIEW';
export const USER_TAX_STATUS = 'USER_TAX_STATUS';
export const USER_COMPLIANCE_OPINION = 'USER_COMPLIANCE_OPINION';
export const ANNUAL_ORDER = 'ANNUAL_ORDER';
export const DECLARATIONS = 'DECLARATIONS';
export const FOREIGN_INVOICES = 'FOREIGN_INVOICES';
export const FOREIGN_PRODUCTS = 'FOREIGN_PRODUCTS';
export const CFDIS = 'CFDIS';
export const ADDRESSES = 'ADDRESSES';
export const ANNUAL_ACCUMULATED_INCOMES_AND_EXPENSES =
  'ANNUAL_ACCUMULATED_INCOMES_AND_EXPENSES';
export const MONTHLY_ACCUMULATED_INCOMES_AND_EXPENSES =
  'MONTHLY_ACCUMULATED_INCOMES_AND_EXPENSES';
export const ACCUMULATED_SALES = 'ACCUMULATED_SALES';
export const ZENDESK_REDIRECT_LINK = 'ZENDESK_REDIRECT_LINK';
export const PUBLIC_TAXABLE_ENTITY = 'PUBLIC_TAXABLE_ENTITY';
export const ACCUMULATED_OF_ENTRIES = 'ACCUMULATED_OF_ENTRIES';
export const DECLARATION_ENTRIES_ANALYTICS = 'DECLARATION_ENTRIES_ANALYTICS';
export const TAXABLE_ENTITY_PREFERENCES = 'TAXABLE_ENTITY_PREFERENCES';
export const ANNUAL_DEDUCTION_LIMITS = 'ANNUAL_DEDUCTION_LIMITS';
