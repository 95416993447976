import { Button, styled } from '@konta/ui';
import { ArrowNarrowLeftLineIcon } from '@konta/icons';
import { ComponentProps } from 'react';

const ContainerBackButton = styled('div', {
  display: 'flex',
  width: '100%',
  padding: '24px 16px',
  pl: '0',
  alignItems: 'center',
  borderTop: '1px solid $gray200',
  background: '$white',
  position: 'fixed',
  bottom: 0,
  zIndex: 1000,
});

export interface BackButtonProps
  extends Omit<ComponentProps<typeof Button>, 'children'> {
  children?: string;
}
export default function BackButton(buttonProps: BackButtonProps) {
  return (
    <ContainerBackButton>
      <Button
        data-cy="resumeStepBackBtn"
        leftIcon={<ArrowNarrowLeftLineIcon />}
        variant="outlined"
        {...buttonProps}
      >
        Regresar
      </Button>
    </ContainerBackButton>
  );
}
