import api from '@api';
import { ForeignInvoice, ForeignProduct } from 'types/entities';

export default async function deleteForeignDocument({
  id,
  documentType,
}: {
  id: number;
  documentType: 'foreign_invoices' | 'foreign_products';
}) {
  const { data } = await api.delete<ForeignInvoice | ForeignProduct>(
    `${documentType}/${id}`,
  );

  return data;
}
