import { put, call } from 'redux-saga/effects';
import { NotificationManager } from '@components/Notifications';
import C from '@constants/httpStatus';
import ErrorHandler from './ErrorHandler';

export default function* ({
  title = null,
  message = null,
  status = null,
  response = null,
  TIMEOUT = 6000,
  PRIORITY = null,
  CLASSNAME = null,
  success = null,
  failure = null,
  fallback = false,
  options = { silent: false },
}) {
  if (fallback && response.data.status) {
    status = response.data.status;
  } else {
    status = status || response.status;
  }
  // TODO: More specific Success Handler
  if (status >= C.OK && status < C.REDIRECTION) {
    yield put(success(response.data));
    (message || title) &&
      !options.silent &&
      NotificationManager.success(message, title, TIMEOUT, PRIORITY, CLASSNAME);
  } else {
    yield call(ErrorHandler, {
      status,
      title,
      response,
      TIMEOUT,
      PRIORITY,
      CLASSNAME,
      action: failure,
    });
  }
}
