import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { getStatusDay } from '@util/status';
import { Loader } from '@components/Utils/Loading';

export default function ({ fiscal_period: fp = null, loading }) {
  const data = getStatusDay(fp);
  return (
    <Card
      className="progress-banner check-button border-top-2 justify-content-center"
      outline
      color={data.color}
    >
      <Loader loading={loading}>
        <CardBody>
          <div className="d-flex align-items-center">
            <i className={`iconsmind-${data.icon} mt-2`} />
            <div className="ml-4">
              <p className={`lead text-${data.color}`}>{data.days}</p>
              <p>{data.message}</p>
            </div>
          </div>
        </CardBody>
      </Loader>
    </Card>
  );
}
