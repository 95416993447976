import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import DropzoneComponent from 'react-dropzone-component';
import { NotificationManager } from '@components/Notifications';
import { uploadTaxableEntityCsd } from '@redux/actions';
import { Colxx, SimpleStateButton } from '@components';
import { useDispatch } from 'react-redux';
import 'dropzone/dist/min/dropzone.min.css';

const ReactDOMServer = require('react-dom/server');

const dropzoneConfig = {
  autoProcessQueue: false,
  thumbnailHeight: 160,
  maxFilesize: 2,
  maxFiles: 1,
  dictInvalidFileType: 'No puedes subir archivos de este tipo.',
  dictMaxFilesExceeded: 'Ya no puedes subir más archivos.',
};

// Dropzone config
const dropzoneComponentConfig = {
  postUrl: 'no-url',
};

const cerDzConfig = {
  ...dropzoneConfig,
  acceptedFiles: '.cer',
  dictDefaultMessage: 'Arrastra tu cer aquí o dé click en esta área',
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div
      style={{ maxWidth: '90%' }}
      className="dz-preview dz-file-preview mb-3"
    >
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            <img alt="" data-dz-thumbnail className="img-thumbnail border-0" />
            <i className="iconsmind-Diploma-2 preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            <span data-dz-name />
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#" className="remove" data-dz-remove>
        <i className="glyph-icon simple-icon-trash" />
      </a>
    </div>
  ),
};

const keyDzConfig = {
  ...dropzoneConfig,
  acceptedFiles: '.key',
  dictDefaultMessage: 'Arrastra tu key aquí o dé click en esta área',
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div
      style={{ maxWidth: '90%' }}
      className="dz-preview dz-file-preview mb-3"
    >
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />{' '}
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            <img data-dz-thumbnail className="img-thumbnail border-0" alt="" />
            <i className="iconsmind-Key preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            <span data-dz-name />
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#" className="remove" data-dz-remove>
        <i className="glyph-icon simple-icon-trash" />
      </a>
    </div>
  ),
};

function UploadCsd({ events, buttonSubmit = 'Subir CSD' }) {
  const dispatch = useDispatch();
  const [csdDocuments, setCsdDocuments] = useState({});
  const [keyDocuments, setKeyDocuments] = useState([]);
  const [cerDocuments, setCerDocuments] = useState([]);

  const updateFielPassword = (e) => {
    setCsdDocuments({
      ...csdDocuments,
      fiel_password: e.target.value,
    });
  };

  const uploadCsd = (_, __) => {
    const { fiel_password } = csdDocuments;
    if (cerDocuments.length > 0 && keyDocuments.length > 0) {
      if (fiel_password) {
        const formData = new FormData();
        for (const file of cerDocuments) {
          if (file.accepted) {
            formData.append('csd_cer', file);
          }
        }
        for (const file of keyDocuments) {
          if (file.accepted) {
            formData.append('csd_key', file);
          }
        }
        formData.append('csd_password', fiel_password);
        dispatch(uploadTaxableEntityCsd(formData));
      } else {
        NotificationManager.error(
          'Verifique el formato de la contraseña',
          'Contraseña inválida',
          6000,
          null,
          null
        );
      }
    } else {
      NotificationManager.error(
        'Sube archivos con formatos .cer y .key',
        'Archivos inválidos',
        6000,
        null,
        null
      );
    }
  };

  // key upload
  const handleKeyUploadFile = (keyFile) => {
    setKeyDocuments((keyDocuments) => [...keyDocuments, keyFile]);
  };

  const keyEventHandlers = {
    addedfile: handleKeyUploadFile,
  };

  // cer upload
  const handleCerUploadFile = (cerFile) => {
    setCerDocuments((cerDocuments) => [...cerDocuments, cerFile]);
  };
  const cerEventHandlers = {
    removedfile: (file) => {
      const cerDocumentsArrray = cerDocuments.filter(
        (item) => item.name === file.name && item.size === file.size
      );
      setCerDocuments(cerDocumentsArrray);
    },
    addedfile: handleCerUploadFile,
  };

  return (
    <Row className="mb-4">
      <Colxx sm="12">
        <Row className="d-flex justify-content-between2">
          <Colxx md={12} xxs={12} className="mb-2">
            <>
              <h5>Subir .CER</h5>
              <DropzoneComponent
                eventHandlers={cerEventHandlers}
                config={dropzoneComponentConfig}
                djsConfig={cerDzConfig}
              />
            </>
          </Colxx>
          <Colxx md={12} xxs={12} className="mb-2">
            <>
              <h5>Subir .KEY</h5>
              <DropzoneComponent
                eventHandlers={keyEventHandlers}
                config={dropzoneComponentConfig}
                djsConfig={keyDzConfig}
              />
            </>
          </Colxx>
        </Row>
        <Row className="d-flex justify-content-start ">
          <Colxx md={12} xxs={12}>
            <h5>Contraseña</h5>
            <p className="text-muted ">Ingresa la contraseña de tu CSD</p>
            <AvForm onValidSubmit={uploadCsd}>
              <AvGroup className="text-center">
                <AvInput
                  name="fiel_password"
                  type="password"
                  onChange={updateFielPassword}
                  className="mb-2"
                />
                <SimpleStateButton
                  onClick={uploadCsd}
                  // disabled={csdDocuments.disableFielButton}
                >
                  {buttonSubmit}
                </SimpleStateButton>
              </AvGroup>
            </AvForm>
          </Colxx>
        </Row>
      </Colxx>
    </Row>
  );
}

export default UploadCsd;
