import { lazy } from 'react';

const Flows = lazy(() => import('./Flows'));
const Canvas = lazy(() => import('./Canvas'));

const FlowsConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'flows',
      element: <Flows />,
    },
    {
      path: 'flows/:flowId',
      element: <Canvas />,
    },
  ],
};

export default FlowsConfig;
