const belvoWidgetConfig = (
  widgetToken,
  open,
  setOpen,
  newBank,
  setNewBank,
  taxable_entity_id,
  onSuccess,
) => {
  const successCallbackFunction = (link, institution) => {
    setOpen();
    if (newBank) {
      setNewBank({
        link,
        institution,
      });
    }
    onSuccess(link, widgetToken);
  };
  const onExitCallbackFunction = (data) => {
    setOpen();
    if (newBank) {
      setNewBank({
        data,
      });
    }
  };
  const onEventCallbackFunction = () => {};
  const config = {
    country_codes: ['MX'],
    locale: 'es',
    external_id: taxable_entity_id.toString(),
    callback: (link, institution) => successCallbackFunction(link, institution),
    onExit: (data) => onExitCallbackFunction(data),
    onEvent: (data) => onEventCallbackFunction(data),
    institution_types: ['retail', 'business'],
  };
  try {
    window.belvoSDK.createWidget(widgetToken, config).build();
  } catch (error) {
    throw new Error(`Error al cargar el Wiget: ${error}`);
  }
};
export default belvoWidgetConfig;
