import { generateMonthNames } from 'shared/util/grids';

export const PFAE_AND_PF_SAT_KEY = 612625;
export const PFAE_SAT_KEY = 612;
export const PF_SAT_KEY = 625;
export const RESICO_SAT_KEY = 626;
export const SALARIES_SAT_KEY = 605;
export const SAT_KEYS_DICT = {
  [PFAE_AND_PF_SAT_KEY]: 'PFAE Y PLATAFORMAS',
  [PFAE_SAT_KEY]: 'PFAE',
  [PF_SAT_KEY]: 'PLATAFORMAS',
  [RESICO_SAT_KEY]: 'RESICO',
};
export type SatKeysDict = keyof typeof SAT_KEYS_DICT;

export const HEADER_ROW_ID = 'header';
export const EMPTY_ROW_ID = 'empty';
export const FORMAT_MONTH = 'MMMM';
export const ROW_HEIGHT = 32;
export const HEADING_ROW_HEIGHT = 40;
export const MONTHS = generateMonthNames(FORMAT_MONTH);
export const COL_WIDTH = 110;
