import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Flex, LabelWrapper, Text } from '@konta/ui';
import IntlTelInput from 'react-intl-tel-input';

const processNumber = ({ isValid, phone, country, id }) => {
  if (isValid && !phone.startsWith('+')) {
    const input = document.getElementById(id);
    const phoneWithDialCode = `+${country.dialCode}${phone}`;
    const len = phoneWithDialCode.length;
    setTimeout(() => {
      input?.setSelectionRange?.(len, len);
    }, 200);
    return phoneWithDialCode;
  }
  return phone;
};

export default function FormikPhoneInput({
  name,
  label,
  disabled = false,
  onChange = null,
  onBlur = null,
  ...restProps
}) {
  const ref = useRef();
  const formik = useFormikContext();
  const [field, meta, helpers] = useField({
    name,
  });

  const handleBlur = (isValid) => {
    helpers.setTouched(true);
    if (onBlur) {
      onBlur(isValid);
    }
  };
  const handleChange = (isValid, phone, country, formattedNumber) => {
    helpers.setValue(processNumber({ isValid, phone, country, id: name }));
    if (onChange) {
      onChange(isValid, phone, country, formattedNumber);
    }
  };

  const touched = meta.touched || formik.submitCount > 0;
  const error = meta.error && touched ? meta.error : null;

  return (
    <Flex direction="column" gap={6} css={{ flex: 1 }}>
      <LabelWrapper label={label} {...restProps}>
        <IntlTelInput
          {...field}
          {...restProps}
          ref={ref}
          name={name}
          disabled={disabled || formik.isSubmitting}
          fieldId={name}
          fieldName={name}
          containerClassName="intl-tel-input contactMethod__edit_input"
          inputClassName="form-control"
          onPhoneNumberChange={handleChange}
          onPhoneNumberBlur={handleBlur}
          placeholder="2221234567"
          country="mx"
          defaultCountry="mx"
          preferredCountries={['mx', 'us']}
        />
      </LabelWrapper>
      {error && (
        <Text xs lineHeight="xs" color="error500">
          {error}
        </Text>
      )}
    </Flex>
  );
}

FormikPhoneInput.propTypes = {
  disabled: PropTypes.bool,
  formatOnBlur: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

FormikPhoneInput.defaultProps = {
  disabled: false,
  formatOnBlur: null,
  onChange: null,
  label: '',
};
