import formatBytes from 'shared/util/formatBytes';
import { FileWithPath } from 'react-dropzone';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardOption,
  Flex,
  Icon,
  OptionsSelect,
  Spacer,
  Text,
} from '@konta/ui';
import NextStepButton from '@components/NextStepButton';
import ProcessStepLayout from '@components/ProcessStepLayout';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepDescription from '@components/ProcessStepDescription';
import Loader from '@components/Loader';
import { FormikProvider } from 'formik';
import Dropzone from 'shared/components/Dropzone';
import { File2OutlineIcon, TrashCanOutlineIcon } from '@konta/icons';
import usePreferencesAnnual from './usePreferencesAnnual';
import {
  borderNoneCss,
  dropzoneWrapperCss,
  fileCardContentCss,
  fileCardCss,
  fileCardIconCss,
  flex1Css,
  gap0Css,
  mt16Css,
  py16Css,
  py32Css,
} from './styled';

export default function PreferencesAnnual() {
  const {
    hasError,
    payload,
    process,
    isLoading,
    formatBalance,
    formatBalanceResico,
    textForBalance,
    textForBalanceResico,
    enabledNextBtn,
    formik,
    balanceInFavor,
    balanceInFavorResico,
    hasResico,
    extraPayload,
    hasDeclaration,
    isEnabledPaymentInInstallments,
  } = usePreferencesAnnual();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Declaraciones Anuales"
      titleChip="En curso"
      contentCss={gap0Css}
      content={
        hasError ? (
          <Flex>
            <Alert color="error" css={flex1Css}>
              Ocurrió un error al cargar tu declaración anual.
              <Spacer />
              Por favor intenta recargar la página o contacta a soporte.
            </Alert>
          </Flex>
        ) : (
          <FormikProvider value={formik}>
            {hasDeclaration && (
              <>
                <Flex direction="column" gap={8}>
                  <Flex gap={8} column css={py16Css}>
                    <ProcessStepTitle>
                      Presentación de declaración con saldo a {textForBalance}
                    </ProcessStepTitle>
                    <ProcessStepDescription>
                      El saldo a {` ${textForBalance}`} de tu declaración anual
                      es de <b>{formatBalance} MXN</b>
                    </ProcessStepDescription>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {balanceInFavor ? (
                      <ProcessStepDescription>
                        Tu declaración anual tiene un saldo a favor que puede
                        ser utilizado como crédito para tus futuras
                        declaraciones fiscales. Por favor selecciona la
                        siguiente opción para indicarnos que deseas utilizar
                        este saldo a tu favor:
                      </ProcessStepDescription>
                    ) : isEnabledPaymentInInstallments ? (
                      <ProcessStepDescription>
                        Para pagar este saldo, el SAT ofrece tres opciones de
                        pago: Pago en una sola exhibición, pago en 3
                        parcialidades y pago en 6 parcialidades. Es importante
                        tener en cuenta que si elige las opciones de pago en
                        parcialidades, el SAT agregará una cuota por el
                        financiamiento del pago.
                      </ProcessStepDescription>
                    ) : (
                      <ProcessStepDescription>
                        Para pagar este saldo, el SAT ofrece la opción de pago
                        en una sola exhibición.
                      </ProcessStepDescription>
                    )}
                  </Flex>
                </Flex>
                <Flex direction="column" gap={10}>
                  {!balanceInFavor && (
                    <Text bold l>
                      ¿Cómo deseas pagar tu declaración?
                    </Text>
                  )}
                  <OptionsSelect
                    value={[formik.values.balance]}
                    onChange={async (value: string[]) => {
                      await formik.setFieldValue('balance', value[0]);
                      await formik.setFieldValue('bank_statement', null);
                    }}
                  >
                    {balanceInFavor ? (
                      <Flex column gap={10}>
                        <CardOption
                          buttonProps={{
                            'data-cy': 'compensation',
                          }}
                          fullWidth
                          value="compensation"
                          header={
                            <>
                              <Text as="b" size="s">
                                Utilizar el saldo a favor para futuras
                                declaraciones. - RECOMENDADO
                              </Text>
                              <br />
                              <Text size="s">
                                Al hacer esto, te aseguras de que los montos a
                                pagar para futuras declaraciones serán menores.
                              </Text>
                            </>
                          }
                        />
                        <CardOption
                          contentCollapsible
                          buttonProps={{
                            'data-cy': 'return',
                          }}
                          fullWidth
                          value="return"
                          header={
                            <>
                              <Text as="b" size="s">
                                Solicitar la devolución del saldo.{' '}
                              </Text>
                              <br />
                              <Text size="s">
                                Ten en cuenta que si solicitas la devolución del
                                saldo, el SAT podrá realizar requerimientos
                                adicionales y es posible que tu solicitud sea
                                rechazada. También se podría incurrir en costos
                                extras por este servicio.
                              </Text>
                            </>
                          }
                          {...(formik.values.balance === 'return' && {
                            content: (
                              <Flex gap={10} column css={dropzoneWrapperCss}>
                                <Dropzone
                                  fileState={
                                    formik.values.bank_statement
                                      ? [formik.values.bank_statement]
                                      : []
                                  }
                                  setFileState={(files) => {
                                    void formik.setFieldValue(
                                      'bank_statement',
                                      files[0],
                                    );
                                  }}
                                  maxFiles={1}
                                  filesTypes={['.pdf']}
                                  name="bank_statement"
                                  cardCss={borderNoneCss}
                                  customText={
                                    <Text>
                                      Arrastra tu documento o haz click en esta
                                      área
                                    </Text>
                                  }
                                >
                                  {({ removeFile }) => (
                                    <Flex column gap={8}>
                                      {!!formik.values.bank_statement && (
                                        <Card outlined css={fileCardCss}>
                                          <CardContent css={fileCardContentCss}>
                                            <Icon waves css={fileCardIconCss}>
                                              <File2OutlineIcon />
                                            </Icon>
                                            <Flex column css={flex1Css}>
                                              <Text size="s" color="gray700">
                                                {
                                                  formik.values.bank_statement
                                                    .path
                                                }
                                              </Text>
                                              <Text size="s" color="gray500">
                                                {formatBytes(
                                                  formik.values.bank_statement
                                                    .size,
                                                )}
                                              </Text>
                                              {/* Show loader state down here */}
                                            </Flex>
                                            <Button
                                              size="xs"
                                              variant="minimal"
                                              onClick={() =>
                                                removeFile(
                                                  formik.values
                                                    .bank_statement as FileWithPath,
                                                )
                                              }
                                              icon
                                            >
                                              <TrashCanOutlineIcon />
                                            </Button>
                                          </CardContent>
                                        </Card>
                                      )}
                                    </Flex>
                                  )}
                                </Dropzone>
                              </Flex>
                            ),
                          })}
                        />
                      </Flex>
                    ) : (
                      <Flex column gap={16}>
                        <CardOption
                          buttonProps={{
                            'data-cy': 'payNowBtn',
                          }}
                          fullWidth
                          value="pay_now"
                          header={
                            <Text as="b">Pago en una sola exhibición</Text>
                          }
                        />
                        {isEnabledPaymentInInstallments && (
                          <>
                            <CardOption
                              buttonProps={{
                                'data-cy': '2MonthPaymentBtn',
                              }}
                              fullWidth
                              value="2_month_payment"
                              header={
                                <Text as="b">Pago en 2 parcialidades</Text>
                              }
                            />
                            <CardOption
                              buttonProps={{
                                'data-cy': '3MonthPaymentBtn',
                              }}
                              fullWidth
                              value="3_month_payment"
                              header={
                                <Text as="b">Pago en 3 parcialidades</Text>
                              }
                            />
                            <CardOption
                              buttonProps={{
                                'data-cy': '4MonthPaymentBtn',
                              }}
                              fullWidth
                              value="4_month_payment"
                              header={
                                <Text as="b">Pago en 4 parcialidades</Text>
                              }
                            />
                            <CardOption
                              buttonProps={{
                                'data-cy': '5MonthPaymentBtn',
                              }}
                              fullWidth
                              value="5_month_payment"
                              header={
                                <Text as="b">Pago en 5 parcialidades</Text>
                              }
                            />
                            <CardOption
                              buttonProps={{
                                'data-cy': '6MonthPaymentBtn',
                              }}
                              fullWidth
                              value="6_month_payment"
                              header={
                                <Text as="b">Pago en 6 parcialidades</Text>
                              }
                            />
                          </>
                        )}
                      </Flex>
                    )}
                  </OptionsSelect>
                </Flex>
              </>
            )}
            {/** RESICO */}
            {hasResico && (
              <>
                <Flex direction="column" gap={8} css={py32Css}>
                  <ProcessStepTitle>
                    Presentación de declaración con saldo a{' '}
                    {textForBalanceResico}: RESICO
                  </ProcessStepTitle>
                  <ProcessStepDescription>
                    El saldo a {` ${textForBalanceResico}`} de tu declaración
                    anual es de <b>{formatBalanceResico} MXN</b>
                  </ProcessStepDescription>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {balanceInFavorResico ? (
                    <ProcessStepDescription>
                      Tu declaración anual de <b>RESICO</b> tiene un saldo a
                      favor que puede ser utilizado como crédito para tus
                      futuras declaraciones fiscales. Por favor selecciona la
                      siguiente opción para indicarnos que deseas utilizar este
                      saldo a tu favor:
                    </ProcessStepDescription>
                  ) : isEnabledPaymentInInstallments ? (
                    <ProcessStepDescription>
                      Para pagar este saldo, el SAT ofrece tres opciones de
                      pago: Pago en una sola exhibición, pago en 3 parcialidades
                      y pago en 6 parcialidades. Es importante tener en cuenta
                      que si elige las opciones de pago en parcialidades, el SAT
                      agregará una cuota por el financiamiento del pago.
                    </ProcessStepDescription>
                  ) : (
                    <ProcessStepDescription>
                      Para pagar este saldo de tu declaración de <b>RESICO</b>,
                      el SAT ofrece la opción de pago en una sola exhibición.
                    </ProcessStepDescription>
                  )}
                </Flex>
                <Flex direction="column" gap={16}>
                  {!balanceInFavorResico && (
                    <Text bold l>
                      ¿Cómo deseas pagar tu declaración?
                    </Text>
                  )}
                  <OptionsSelect
                    value={[formik.values.balance_resico]}
                    onChange={async (value: string[]) => {
                      await formik.setFieldValue('balance_resico', value[0]);
                      await formik.setFieldValue('bank_statement_resico', null);
                    }}
                  >
                    {balanceInFavorResico ? (
                      <Flex column gap={16}>
                        <CardOption
                          buttonProps={{
                            'data-cy': 'compensationResicoBtn',
                          }}
                          fullWidth
                          value="compensation"
                          header={
                            <>
                              <Text as="b" size="s">
                                Utilizar el saldo a favor para futuras
                                declaraciones. - RECOMENDADO
                              </Text>
                              <br />
                              <Text size="s">
                                Al hacer esto, te aseguras de que los montos a
                                pagar para futuras declaraciones serán menores.
                              </Text>
                            </>
                          }
                        />
                        <CardOption
                          contentCollapsible
                          buttonProps={{
                            'data-cy': 'returnResicoBtn',
                          }}
                          fullWidth
                          value="return"
                          header={
                            <>
                              <Text as="b" size="s">
                                Solicitar la devolución del saldo.{' '}
                              </Text>
                              <br />
                              <Text size="s">
                                Ten en cuenta que si solicitas la devolución del
                                saldo a favor, el SAT podría realizar
                                requerimientos adicionales y es posible que tu
                                solicitud sea rechazada, también se podría
                                incurrir en costos extras por este servicio.
                                Teniendo esto en cuenta puedes continuar
                                subiendo la carátula de tu estado de cuenta
                                donde sea visible la cuenta CLABE.
                              </Text>
                            </>
                          }
                          {...(formik.values.balance_resico === 'return' && {
                            content: (
                              <Flex gap={10} column css={dropzoneWrapperCss}>
                                <Dropzone
                                  fileState={
                                    formik.values.bank_statement_resico
                                      ? [formik.values.bank_statement_resico]
                                      : []
                                  }
                                  setFileState={(files) => {
                                    void formik.setFieldValue(
                                      'bank_statement_resico',
                                      files[0],
                                    );
                                  }}
                                  maxFiles={1}
                                  filesTypes={['.pdf']}
                                  name="bank_statement_resico"
                                  cardCss={borderNoneCss}
                                  customText={
                                    <Text>
                                      Arrastra tu documento o haz click en esta
                                      área
                                    </Text>
                                  }
                                >
                                  {({ removeFile }) => (
                                    <Flex column gap={8}>
                                      {!!formik.values
                                        .bank_statement_resico && (
                                        <Card outlined css={fileCardCss}>
                                          <CardContent css={fileCardContentCss}>
                                            <Icon waves css={fileCardIconCss}>
                                              <File2OutlineIcon />
                                            </Icon>
                                            <Flex column css={flex1Css}>
                                              <Text size="s" color="gray700">
                                                {
                                                  formik.values
                                                    .bank_statement_resico.path
                                                }
                                              </Text>
                                              <Text size="s" color="gray500">
                                                {formatBytes(
                                                  formik.values
                                                    .bank_statement_resico.size,
                                                )}
                                              </Text>
                                              {/* Show loader state down here */}
                                            </Flex>
                                            <Button
                                              size="xs"
                                              variant="minimal"
                                              onClick={() =>
                                                removeFile(
                                                  formik.values
                                                    .bank_statement_resico as FileWithPath,
                                                )
                                              }
                                              icon
                                            >
                                              <TrashCanOutlineIcon />
                                            </Button>
                                          </CardContent>
                                        </Card>
                                      )}
                                    </Flex>
                                  )}
                                </Dropzone>
                              </Flex>
                            ),
                          })}
                        />
                      </Flex>
                    ) : (
                      <Flex column gap={16}>
                        <CardOption
                          buttonProps={{
                            'data-cy': 'payNowResicoBtn',
                          }}
                          fullWidth
                          value="pay_now"
                          header={
                            <Text as="b">Pago en una sola exhibición</Text>
                          }
                        />
                        {isEnabledPaymentInInstallments && (
                          <>
                            <CardOption
                              buttonProps={{
                                'data-cy': '2MonthPaymentResicoBtn',
                              }}
                              fullWidth
                              value="2_month_payment"
                              header={
                                <Text as="b">Pago en 2 parcialidades</Text>
                              }
                            />
                            <CardOption
                              buttonProps={{
                                'data-cy': '3MonthPaymentResicoBtn',
                              }}
                              fullWidth
                              value="3_month_payment"
                              header={
                                <Text as="b">Pago en 3 parcialidades</Text>
                              }
                            />
                            <CardOption
                              buttonProps={{
                                'data-cy': '4MonthPaymentResicoBtn',
                              }}
                              fullWidth
                              value="4_month_payment"
                              header={
                                <Text as="b">Pago en 4 parcialidades</Text>
                              }
                            />
                            <CardOption
                              buttonProps={{
                                'data-cy': '5MonthPaymentResicoBtn',
                              }}
                              fullWidth
                              value="5_month_payment"
                              header={
                                <Text as="b">Pago en 5 parcialidades</Text>
                              }
                            />
                            <CardOption
                              buttonProps={{
                                'data-cy': '6MonthPaymentResicoBtn',
                              }}
                              fullWidth
                              value="6_month_payment"
                              header={
                                <Text as="b">Pago en 6 parcialidades</Text>
                              }
                            />
                          </>
                        )}
                      </Flex>
                    )}
                  </OptionsSelect>
                </Flex>
              </>
            )}
            <Flex gap={12} justify="end">
              <NextStepButton
                css={mt16Css}
                currentProcessType={process}
                data-cy="preferencesAnnualNextBtn"
                text="Guardar preferencias"
                disabled={!enabledNextBtn}
                payload={payload}
                extraPayload={extraPayload}
                onClick={() => {
                  void formik.submitForm();
                }}
              />
            </Flex>
          </FormikProvider>
        )
      }
    />
  );
}
