import React from 'react';
import {
  Card,
  CardContent,
  Text,
  Button,
  Flex,
  useMediaQuery,
  config,
} from '@konta/ui';
import { Download02Icon } from '@konta/icons';
import useUserTaxStatus from '@hooks/useUserTaxStatus';
import useUserComplianceOpinion from '@hooks/useUserComplianceOpinion';
import { useTaxableEntity } from '@hooks';

export default function FiscalInformation() {
  const { taxStatus, taxStatusLoading } = useUserTaxStatus();
  const { taxable_entity: taxableEntity } = useTaxableEntity();
  const { complianceOpinionPdf, complianceOpinionPdfLoading } =
    useUserComplianceOpinion(taxableEntity.id);
  const isTabletOrMobile = useMediaQuery(config.media['<sm']);
  return (
    <Flex direction={isTabletOrMobile ? 'column' : 'row'} gap="10">
      <Card outlined css={{ width: '100%' }}>
        <CardContent
          css={{
            flexDirection: isTabletOrMobile ? 'column' : 'row',
            alignItems: isTabletOrMobile ? 'none' : 'center',
            justifyContent: 'space-between',
            gap: '$10',
          }}
        >
          <Text l semibold>
            {!taxStatus
              ? 'No hemos podido conseguir tu Constancia de Situación Fiscal'
              : 'Constancia de Situación Fiscal (CSF)'}
          </Text>
          <Button
            onClick={() => {
              window.open(taxStatus?.file_url, '_blank');
            }}
            css={{
              path: { stroke: 'white' },
            }}
            size="xs"
            color="primary"
            disabled={!taxStatus}
            loading={taxStatusLoading}
            leftIcon={<Download02Icon />}
          >
            Descargar PDF
          </Button>
        </CardContent>
      </Card>
      <Card outlined css={{ width: '100%' }}>
        <CardContent
          css={{
            flexDirection: isTabletOrMobile ? 'column' : 'row',
            alignItems: isTabletOrMobile ? 'none' : 'center',
            justifyContent: 'space-between',
            gap: '$10',
          }}
        >
          <Text l semibold>
            {!complianceOpinionPdf
              ? 'No hemos podido conseguir tu Opinión de cumplimiento'
              : 'Opinión de cumplimiento'}
          </Text>
          <Button
            onClick={async () => {
              const a = document.createElement('a');
              a.href = `data:application/pdf;base64,${complianceOpinionPdf}`;
              const fileName = `opinion_cumplimiento.pdf`;
              a.download = fileName;
              a.click();
              a.remove();
            }}
            css={{
              path: { stroke: 'white' },
            }}
            color="primary"
            size="xs"
            loading={complianceOpinionPdfLoading}
            disabled={!complianceOpinionPdf}
            leftIcon={<Download02Icon />}
          >
            Descargar PDF
          </Button>
        </CardContent>
      </Card>
    </Flex>
  );
}
