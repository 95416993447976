import api from '@api';

export default async function postProduct({ taxableId, product }) {
  const token = localStorage.getItem('user_id');
  const { data } = await api.post(
    `taxable_entities/${taxableId}/products`,
    { product },
    { headers: { Authorization: token } },
  );

  return data;
}
