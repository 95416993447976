import { styled } from '@konta/ui';

export const ActionsContainer = styled('div', {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  borderTop: '1px solid $gray200',
  background: 'white',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 3,
});

export const navigationButtonCss = {
  flex: 1,
  py: '$24!important',
  borderRadius: 0,
  '&:not(:first-child)': {
    borderLeft: '1px solid $gray200',
  },
};
