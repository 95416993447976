/* eslint-disable @typescript-eslint/no-unsafe-argument */

export default function jsonToFormData(
  json: Record<string, any>,
  formData: FormData = new FormData(),
  parentKey = '',
): FormData {
  Object.entries(json).forEach(([key, value]) => {
    const fullKey = parentKey ? `${parentKey}[${key}]` : key;

    if (value instanceof File || value instanceof Blob) {
      formData.append(fullKey, value);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        const arrayKey = `${fullKey}[]`;
        if (item instanceof File || item instanceof Blob) {
          formData.append(arrayKey, item);
        } else {
          jsonToFormData(item, formData, arrayKey);
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      jsonToFormData(value, formData, fullKey);
    } else {
      formData.append(fullKey, value);
    }
  });
  return formData;
}
