import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, ImageVideoModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

function ExpencesClassification() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />

      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p className="font-weight-bold">Gastos no deducibles</p>
          <p>
            Son aquellos que no se consideran en el cálculo del beneficio neto
            antes de impuestos. Cuando un gasto no se encuentra relacionado
            directamente con la actividad de la empresa, o cuando no tiene un
            comprobante fiscal que lo justifique, no se podrá considerar en la
            determinación del resultado fiscal.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
      <Row>
        <Colxx md="12">
          <p className="font-weight-bold">Nota de crédito</p>
          <p>
            La emisión de una nota crédito funciona de la misma manera que la de
            un CFDI de ingresos regular. Puede realizarla directo en el portal
            del SAT, o en cualquier plataforma de facturación electrónica como
            Konta.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
    </>
  );
}
export default ExpencesClassification;
