import Loader from '@components/Loader';
import LineSeries from 'shared/components/LineSeries';
import useAccumulatedIncomesAndExpensesEntries from 'shared/hooks/useAccumulatedIncomesAndExpensesEntries';
import getRegimeAliasBySayKey from 'shared/util/getRegimeAliasBySatKey';
import type { FiscalRegime, Workflow } from 'types/entities';
import { ChartContainer, Root } from './styled';

interface AccumulatedEntriesLineSeriesByRegimesProps {
  workflow: Workflow | null;
  fiscalRegimes: FiscalRegime[];
  type?: 'incomes' | 'expenses';
}
export default function AccumulatedEntriesLineSeriesByRegimes({
  workflow,
  fiscalRegimes,
  type = 'incomes',
}: AccumulatedEntriesLineSeriesByRegimesProps) {
  const [year, month] = workflow?.start_date?.split('-') ?? [];
  const {
    incomesLinearSeriesDataByRegimes,
    expensesLinearSeriesDataByRegimes,
    isLoading,
    isDesktop,
  } = useAccumulatedIncomesAndExpensesEntries({
    filters: {
      year,
      month,
      accumulate_periods: true,
      from: 'AccumulatedEntriesLineSeriesByRegimes',
    },
    queryOptions: {
      enabled: !!workflow,
      staleTime: 1000 * 15,
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Root>
      {(type === 'incomes'
        ? incomesLinearSeriesDataByRegimes
        : expensesLinearSeriesDataByRegimes
      ).map((data) => {
        const { dataSet, accumulatedTotal } = data;
        if (!fiscalRegimes.find((regime) => regime.sat_key === +data.satKet)) {
          return null;
        }
        const regimeAlias = getRegimeAliasBySayKey(
          fiscalRegimes,
          +data.satKet,
          'lowercase',
        );
        const titleFromType = type === 'incomes' ? 'Ingreso' : 'Gasto';
        const typeWord = type === 'incomes' ? 'ingresos' : 'gastos';
        const typeWordPlural = type === 'incomes' ? 'emitidas' : 'recibidas';
        const title = `${titleFromType} por ${regimeAlias}`;
        return (
          <ChartContainer key={data.satKet}>
            <LineSeries
              id={`accumulated-entries-${data.satKet}`}
              isDesktop={isDesktop}
              dataSet={dataSet}
              title={title}
              textTitleHover={`El gráfico muestra el cambio a lo largo del tiempo en los ${typeWord} acumulados basados en facturas ${typeWordPlural}.`}
              total={accumulatedTotal}
            />
          </ChartContainer>
        );
      })}
    </Root>
  );
}
