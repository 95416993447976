import { useEffect, useMemo } from 'react';
import { useMultiSelectableList } from 'rooks';
import { CheckedState } from '@components/FormikCheckbox';

export interface UseMultiSelectableProps<T> {
  data: T[];
  initialSelections?: number[];
  allowUnselected?: boolean;
  setSelectedData?: (data: T[]) => void;
}
export type UseMultiSelectableReturn<T> = ReturnType<
  typeof useMultiSelectable<T>
>;
export default function useMultiSelectable<T>({
  data,
  initialSelections = [],
  allowUnselected = true,
  setSelectedData,
}: UseMultiSelectableProps<T>) {
  const [selection, { matchSelection, toggleSelection, updateSelections }] =
    useMultiSelectableList(data, initialSelections, allowUnselected);
  const [selectedIndices, selectedData] = selection;

  const isAllSelected =
    !!selectedIndices.length && selectedIndices.length === data.length;
  const isSomeSelected = !isAllSelected && selectedIndices.length > 0;
  const toggleAll = () => {
    if (isAllSelected || isSomeSelected) {
      updateSelections({
        indices: [],
      })();
      return;
    }

    updateSelections({
      indices: data.map((_, i) => i),
    })();
  };

  const resetSelections = () => {
    updateSelections({
      indices: [],
    })();
  };

  const checkedControl = useMemo((): CheckedState => {
    if (isAllSelected) return true;
    if (isSomeSelected) return 'indeterminate';
    return false;
  }, [isAllSelected, isSomeSelected]);

  useEffect(() => {
    if (selectedIndices.length > 0) {
      resetSelections();
    }
  }, [data]);

  useEffect(() => {
    if (setSelectedData && selectedData) {
      setSelectedData(selectedData as T[]);
    }
  }, [selectedData]);

  return {
    selectedIndices: selectedIndices as number[],
    selectedData: selectedData as T[],
    isAllSelected,
    isSomeSelected,
    toggleAll,
    matchSelection,
    toggleSelection,
    updateSelections,
    checkedControl,
    resetSelections,
  };
}
