import camelcaseKeys from 'camelcase-keys';
import type { Address, Declaration, TaxableEntity } from 'types/entities';
import { getMonthAndYearDate } from './Utils';
import getTotalsFromDeclarationIncome from '../shared/util/getTotalsFromDeclarationIncome';

interface PreviewPublicInvoiceProps {
  address: Address | null;
  declaration: Declaration;
  taxableEntity: TaxableEntity | null;
  receiverRfc?: string;
  receiverName?: string;
  receiverCfdiUsage?: string;
}
export default function parsePreviewPublicInvoice({
  address,
  taxableEntity,
  receiverRfc = 'XAXX010101000',
  receiverName = 'Público en General',
  receiverCfdiUsage = 'G03',
  declaration,
}: PreviewPublicInvoiceProps) {
  const { fiscalRegime, startDate, declarationIncome } =
    camelcaseKeys(declaration);
  const monthAndYear = getMonthAndYearDate(new Date(startDate));
  const {
    totalIvaBase16Unbilled,
    totalIvaBase8Unbilled,
    totalExemptBasesUnbilled,
    totalZeroBasesUnbilled,
    totalOfPublicInvoice,
    subTotalOfPublicInvoice,
    totalIvaUnbilled,
  } = getTotalsFromDeclarationIncome(declarationIncome);

  return {
    total: totalOfPublicInvoice,
    subtotal: subTotalOfPublicInvoice,
    expedition_place: address?.postcode,
    issuer_attributes: {
      rfc: taxableEntity?.rfc,
      name: taxableEntity?.legal_name,
      fiscal_regime: fiscalRegime.sat_key,
    },
    receiver_attributes: {
      rfc: receiverRfc,
      name: receiverName,
      cfdi_usage: receiverCfdiUsage,
    },
    concepts_attributes: [
      totalIvaBase16Unbilled > 0 && {
        description: `Ventas al público en general del mes de ${monthAndYear} tasa 16%`,
        unit_value: totalIvaBase16Unbilled,
        amount: totalIvaBase16Unbilled,
        quantity: '1',
        property_account: {},
      },
      totalIvaBase8Unbilled > 0 && {
        description: `Ventas al público en general del mes de ${monthAndYear} tasa 8%`,
        unit_value: totalIvaBase8Unbilled,
        amount: totalIvaBase8Unbilled,
        quantity: '1',
        property_account: {},
      },
      totalZeroBasesUnbilled > 0 && {
        description: `Ventas al público en general del mes de ${monthAndYear} tasa 0%`,
        unit_value: totalZeroBasesUnbilled,
        amount: totalZeroBasesUnbilled,
        quantity: '1',
        property_account: {},
      },
      Math.abs(totalExemptBasesUnbilled) > 0 && {
        description: `Ventas al público en general del mes de ${monthAndYear} exento de iva`,
        unit_value: totalExemptBasesUnbilled,
        amount: totalExemptBasesUnbilled,
        quantity: '1',
        property_account: {},
      },
    ],
    total_taxes_transferred: totalIvaUnbilled > 0 ? totalIvaUnbilled : null,
    transferred_attributes_import: totalIvaUnbilled > 0 && totalIvaUnbilled,
  };
}
