import React from 'react';
import { DebtsCardFp, DebtsCardOrder } from '@containers';
import { useFiscalPeriods, useTaxableEntity } from '@hooks';
import UnfinishedProcesses from '@components/UnfinishedProcesses';
import { TaskActive } from './tasks-active';
import { TaskDemo } from './tasks-demo';
import { TaskInactive } from './tasks-inactive';

export default function Tasks() {
  const { taxable_entity } = useTaxableEntity();
  const { fiscal_period: fiscalPeriods } = useFiscalPeriods('fiscal_period');

  return (
    <div className="app-container">
      <UnfinishedProcesses />
      <DebtsCardOrder title="Pendiente de pago" />
      {{
        demo: <TaskDemo />, // 0
        active: <TaskActive />, // 1
        pending_payment: <TaskActive />, // 2
        inactive: <TaskInactive />, // 3
        cancelled: null, // 4
      }[taxable_entity.status] || null}
      <DebtsCardFp
        type="FiscalPeriod"
        title="Pago de impuestos"
        fiscalPeriods={fiscalPeriods}
      />
    </div>
  );
}
