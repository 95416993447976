import React, { useMemo } from 'react';
import { Cell, Row, Table, TableBody, TableHead } from '@konta/ui';
import toCurrency from '@util/toCurrency';
import useActiveDeclarationIncome from '@hooks/useActiveDeclarationIncome';

export default function PublicInvoiceTable() {
  const {
    totalBases,
    totalIva,
    totalBasesWithIva,
    totalBasesOfCfdis,
    totalIvaOfCfdis,
    totalBasesWithIvaOfCfdis,
    totalUnbilled,
    totalIvaUnbilled,
    totalBasesWithIvaUnbilled,
    total,
  } = useActiveDeclarationIncome();

  const rows = useMemo(
    () => [
      {
        description: 'Total de ingresos capturados',
        base: total,
        tax: totalIva,
        total: totalBasesWithIva,
        prefix: '+',
      },
      {
        description: 'Total de ingresos ya facturados en el mes',
        base: totalBasesOfCfdis,
        tax: totalIvaOfCfdis,
        total: totalBasesWithIvaOfCfdis,
        prefix: '-',
      },
      {
        description: 'Total por facturar',
        base: totalUnbilled,
        tax: totalIvaUnbilled,
        total: totalBasesWithIvaUnbilled,
        bold: true,
      },
    ],
    [
      totalBases,
      totalBasesOfCfdis,
      totalBasesWithIva,
      totalBasesWithIvaOfCfdis,
      totalBasesWithIvaUnbilled,
      totalIva,
      totalIvaOfCfdis,
      totalIvaUnbilled,
      totalUnbilled,
    ],
  );

  return (
    <Table fullWidth>
      <TableHead>
        <Row transparent>
          <Cell />
          <Cell>BASE</Cell>
          <Cell>IMPUESTO</Cell>
          <Cell>TOTAL</Cell>
        </Row>
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          const cellClass =
            row.bold || (row.prefix === '-' ? 'text-danger' : 'text-success');
          return (
            <Row
              key={row.description}
              active={row.bold}
              css={{ fontWeight: row.bold ? '$bold' : '$medium' }}
            >
              <Cell>{row.description}</Cell>
              <Cell className={cellClass}>
                {row.prefix}
                {toCurrency(row.base)}
              </Cell>
              <Cell className={cellClass}>
                {row.prefix}
                {toCurrency(row.tax)}
              </Cell>
              <Cell className={cellClass}>
                {row?.prefix}
                {toCurrency(row.total)}
              </Cell>
            </Row>
          );
        })}
      </TableBody>
    </Table>
  );
}
