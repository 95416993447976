import { Dispatch, SetStateAction } from 'react';
import getRegimeNameFromFiscalRegime, {
  shortNames,
} from 'shared/util/getRegimeNameFromFiscalRegime';
import { EyeLineIcon } from '@konta/icons';
import { Button, Tooltip } from '@konta/ui';
import type { Declaration } from 'types/entities';
import getTotalsFromDeclarationIncome from '../shared/util/getTotalsFromDeclarationIncome';
import toCurrency from './toCurrency';

export default function getTableRowsFromDeclarationIncome(
  declaration: Declaration,
  getDataForPreviewByDeclaration: (declaration: Declaration) => object,
  setPreviewData: Dispatch<SetStateAction<object | undefined>>,
) {
  const {
    totalRetainedOfCfdis,
    totalTaxes,
    totalIvaBases,
    totalExemptBases,
    totalZeroBases,
    totalIVA8,
    totalIVA16,
    totalIvaBasesOfCfdis,
    totalOfPublicInvoice,
    totalIvaOfCfdis,
    totalZeroBasesOfCfdis,
    totalIvaExemptBasesOfCfdis,
  } = getTotalsFromDeclarationIncome(declaration.declaration_income);
  const regimeName =
    shortNames[declaration.fiscal_regime.sat_key] ||
    getRegimeNameFromFiscalRegime(declaration.fiscal_regime);
  const grandTotalBases = totalIvaBases + totalExemptBases + totalZeroBases;
  const grantTotal = grandTotalBases;
  const publicInvoicePreview = getDataForPreviewByDeclaration(declaration);
  const totals = [
    {
      key: `IVA16Income`,
      label: `Ingresos con IVA al 16%`,
      amount: `${toCurrency(totalIVA16)} MXN`,
    },
    {
      key: `IVA8Income`,
      label: `Ingresos con IVA al 8%`,
      amount: `${toCurrency(totalIVA8)} MXN`,
    },
    {
      key: `IVA0Income`,
      label: `Ingresos con IVA al 0%`,
      amount: `${toCurrency(totalZeroBases)} MXN`,
    },
    {
      key: `IVAExemptIncome`,
      label: `Ingresos con IVA Exento`,
      amount: `${toCurrency(totalExemptBases)} MXN`,
    },
    {
      key: 'grandTotal',
      label: `Total de ingresos de ${regimeName}`,
      amount: `${toCurrency(grantTotal)} MXN`,
      isBold: true,
    },
    {
      key: `totalBilledIncome`,
      label: `Total de ingresos facturados`,
      amount: `${toCurrency(
        totalIvaBasesOfCfdis +
          totalIvaOfCfdis +
          totalZeroBasesOfCfdis +
          totalIvaExemptBasesOfCfdis -
          totalRetainedOfCfdis,
      )} MXN`,
      withBackground: true,
    },
    {
      key: `incomeToBill`,
      label: `Ingresos por facturar`,
      amount: `${toCurrency(totalOfPublicInvoice)} MXN`,
      withBackground: true,
      button: (
        <Tooltip
          triggerElement={
            <Button
              size="xs"
              variant="text"
              noFill
              icon
              onClick={() => setPreviewData(publicInvoicePreview)}
            >
              <EyeLineIcon />
            </Button>
          }
        >
          Ver vista previa de la factura
        </Tooltip>
      ),
    },
  ];
  return {
    regimeName,
    total: grantTotal,
    totals,
  };
}
