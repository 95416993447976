import api from '@api';
import { call, select, put } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import { NotificationManager } from '@components';
import { fetchOrders, clearCart, fetchSubscriptions } from '@redux/actions';
import {
  chargePaymentMethodFailure,
  chargePaymentMethodSuccess,
} from '../actions';

export default function* chargeSaga({
  payload: { amount, navigate, callback },
}) {
  try {
    const { taxable_entity } = yield select((state) => state.taxableEntity);
    const { ui } = yield select((state) => state.paymentMethods);

    if (ui.selected_card && ui.selected_card.source_type === 'card') {
      const access_token = localStorage.getItem('user_id');
      const response = yield call(
        api.post,
        `taxable_entities/${taxable_entity.id}/payment_methods/${ui.selected_card.id}/charge`,
        {
          amount,
        },
        {
          headers: { Authorization: `${access_token}` },
        },
      );
      yield call(StatusHandler, {
        response,
        title: 'Cobro Realizado',
        message: 'El cobro fue realizado con éxito',
        status: response.status,
        success: chargePaymentMethodSuccess,
        failure: chargePaymentMethodFailure,
      });
      if (callback) callback();

      yield put(fetchOrders());
      yield put(fetchSubscriptions());
      navigate('/app/pendientes');
    } else {
      NotificationManager.error(
        'Verifique que tenga un método de pago de tipo tarjeta seleccionado.',
        'Upss..',
        4000,
        null,
        null,
        null,
      );
      yield put(chargePaymentMethodFailure());
    }
  } catch (error) {
    if (error.response.status === 500) {
      yield put(clearCart());
    } else {
      yield call(ErrorHandler, {
        error,
        action: chargePaymentMethodFailure,
        showBodyErrors: true,
      });
    }
  }
}
