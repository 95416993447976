import { useMutation, UseMutationOptions } from 'react-query';
import putProcesses, { PutProcessesOptions } from '@api/putProcesses';
import { Process } from 'types/entities';

export default function useUpdateProcessesMutation(
  options?: UseMutationOptions<Process, unknown, PutProcessesOptions>,
) {
  return useMutation<Process, unknown, PutProcessesOptions>(
    (variables) => putProcesses(variables),
    options,
  );
}
