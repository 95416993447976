import { useMemo } from 'react';
import { useMediaQuery } from '@konta/ui';
import useAccumulatedSales from '@hooks/useAccumulatedSales';

interface AccumulatedIncomesAndExpensesProps {
  filter?: string;
  monthFilter?: string;
}

export default function useTopTenAccumulatedSales({
  filter,
  monthFilter,
}: AccumulatedIncomesAndExpensesProps) {
  const isDesktop = useMediaQuery('(min-width: 1512px)');
  const { accumulatedSales, accumulatedSalesLoading } = useAccumulatedSales(
    {
      params: {
        year: filter,
        month: monthFilter,
      },
    },
    {
      staleTime: 1000 * 15,
    },
  );

  const dateSet = useMemo(() => {
    const salesAmount = accumulatedSales?.amount || [];
    if (salesAmount.length > 0 && Object.values(salesAmount[0]).length === 1) {
      return {
        label: 'No hay datos',
        currency: false,
        data: [],
      };
    }
    return {
      label: 'Top 10 clientes con mayores ventas',
      currency: true,
      data: salesAmount,
    };
  }, [accumulatedSales?.amount]);

  return {
    isDesktop,
    isLoading: accumulatedSalesLoading,
    dateSet,
  };
}
