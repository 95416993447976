import { NotificationManager } from '@components/Notifications';
import { put } from 'redux-saga/effects';
import C from '@constants/httpStatus';
import { getMessageFromError } from '@util/Utils';
import handleNewError from '@redux/errors/actions';
import getErrorMessage from '@util/getErrorMessage';

export default function* ({
  title = 'Upss...',
  response,
  message = null,
  error = null,
  TIMEOUT,
  PRIORITY,
  CLASSNAME,
  action,
  showBodyErrors = false,
}) {
  let messageForError = message;
  let titleForError = title;
  if (
    showBodyErrors &&
    (error?.response?.data?.debug_message || error?.response?.data?.errors)
  ) {
    messageForError =
      message ||
      error.response.data.debug_message ||
      getMessageFromError(error.response.data.errors);

    if (typeof messageForError !== 'string') {
      messageForError = getErrorMessage(error);
    }
  } else {
    messageForError = message || error?.response?.data?.error;
  }

  const status =
    response?.data?.status ||
    response?.status ||
    error?.response?.status ||
    error?.response?.data?.status ||
    error?.message;
  // List of special errors everything else will display message and
  if (!showBodyErrors) {
    switch (status) {
      case C.NETWORK:
        titleForError = `Error de red ${status}`;
        messageForError =
          'Verifique tu conexión a internet e intentalo de nuevo.';
        break;
      case C.UNPROCESSABLE_ENTITY:
        messageForError =
          'Verifique que los datos sean correctos e intentalo de nuevo.';
        break;
      case C.NOT_FOUND:
        messageForError = 'No se encontró el recurso en el sistema';
        break;
      case C.UNAUTHORIZED:
        // default title
        messageForError = 'Usuario o contraseña no válidos';
        break;
      default:
        titleForError = 'Error en el sistema';
        messageForError = 'Contáctanos por chat para resolver el error';
        break;
    }
  }

  NotificationManager.error(
    messageForError,
    titleForError,
    TIMEOUT,
    PRIORITY,
    CLASSNAME,
  );
  if (action) {
    yield put(action());
  }
  if (error && error.response) {
    yield put(handleNewError(error.response));
  }
}
