import { useMemo } from 'react';
import dayjs from 'dayjs';
import LetterAvatar from 'react-avatar';
import type { ColumnDef } from '@tanstack/react-table';
import { EyeLineIcon } from '@konta/icons';
import { Button, Chip, Flex, Text, Tooltip } from '@konta/ui';
import {
  getAccountingEntryTypeLabel,
  getDocument,
  getReceiverOrIssuerLegalName,
  getReceiverOrIssuerRfc,
  getSourceTypeLabel,
  getEntryConceptOrDescription,
  getCfdiUsage,
  getCfdiPaymentMethod,
  getEntryPaymentForm,
  getCurrency,
  getCfdiFiscalFolio,
  getCfdiUIFolio,
  getExchangeRate,
  getGeneralType,
  getDateIssued,
  getReceiverOrIssuerFiscalRegime,
} from '@util/declarationEntries';
import {
  ACCOUNTING_STATUS_FILTER_CLIENT_OPTS,
  GENERAL_DECLARATION_ENTRY_TYPE_FILTER_CLIENT_OPTS,
  SOURCE_TYPE_FILTER_CLIENT_OPTS,
} from 'backoffice/constants/declarationEntries';
import { accountingStatusColors } from '@constants/declarationEntries';
import type { DeclarationEntry } from 'types/entities';
import Cell from 'shared/components/Cell';
import TextOverflow from 'shared/components/TextOverflow';
import CfdiPreviewPdfModal from 'backoffice/components/CfdiPreviewPdfModal';
import { PAYMENT_TYPE_FILTER_CLIENT_OPTS } from 'backoffice/constants/cfdi';
import getRegimeNameFromFiscalRegime from 'shared/util/getRegimeNameFromFiscalRegime';
import { getCurrencyColumns } from 'shared/util/entries/columns';
import { cellBoldCss, cellCss, cellUpperCss, headerCss } from './styled';

export const PAYMENT_METHODS = [
  {
    value: 'PUE',
    label: 'PUE',
  },
  {
    value: 'PPD',
    label: 'PPD',
  },
];

const COLUMN_NUMBER_SIZE = 100;

interface UseEntriesColumnsTable {
  fiscalRegimeOpts: { value: string; label: string }[];
  accessToken: string;
}

export default function useEntriesColumnsTable({
  fiscalRegimeOpts,
  accessToken,
}: UseEntriesColumnsTable) {
  return useMemo<ColumnDef<DeclarationEntry>[]>(
    () => [
      {
        header: () => <Text css={headerCss}>FECHA</Text>,
        size: 100,
        label: ' Fecha de emisión',
        accessorKey: 'accounting_date',
        id: 'accounting_date',
        cell: ({ row }) => (
          <Cell textCss={cellUpperCss}>
            {row.original.accounting_date
              ? dayjs(row.original.accounting_date).format('DD MMM')
              : '-'}
          </Cell>
        ),
        accessorFn: (entry) =>
          entry.accounting_date
            ? dayjs(entry.accounting_date).format('YYYY/MM/DD')
            : '-',
        filterFn: 'rangeDate',
        filterHandler: {
          type: 'dateRange',
          initialFilter: '',
        },
      },
      {
        header: () => <Text css={headerCss}>Tipo de documento</Text>,
        label: 'Tipo de documento',
        size: 130,
        accessorKey: 'source_type',
        id: 'source_type',
        cell: ({ row }) => (
          <Cell textCss={cellCss}>
            <TextOverflow maxLen={125}>
              {getSourceTypeLabel(row.original) || '-'}
            </TextOverflow>
          </Cell>
        ),
        accessorFn: (entry) => getSourceTypeLabel(entry) || '-',
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: SOURCE_TYPE_FILTER_CLIENT_OPTS,
        },
      },
      {
        header: () => <Text css={headerCss}>Estatus</Text>,
        size: 190,
        label: 'Estatus',
        accessorKey: 'accounting_status',
        id: 'accounting_status',
        accessorFn: (entry) => getAccountingEntryTypeLabel(entry).label || '',
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: ACCOUNTING_STATUS_FILTER_CLIENT_OPTS,
        },
        cell: ({ row }) => {
          const [color, contrastColor] =
            accountingStatusColors[row.original.accounting_status];
          return (
            <Chip
              css={{
                '--color': color,
                '--contrastColor': contrastColor,
              }}
              size="s"
              label={getAccountingEntryTypeLabel(row.original).label || ''}
            />
          );
        },
      },
      {
        header: () => <Text css={headerCss}>Cliente/Proveedor</Text>,
        accessorKey: 'legal_name',
        label: 'Cliente/Proveedor',
        id: 'legal_name',
        size: 500,
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
        accessorFn: (entry) => getReceiverOrIssuerLegalName(entry) || '-',
        cell: ({ row }) => {
          const legalName = getReceiverOrIssuerLegalName(row.original);
          return (
            <Flex css={cellBoldCss} gap={12} itemsCenter>
              <span className="classify-expenses-logo-container">
                <LetterAvatar
                  maxInitials={2}
                  name={legalName || '-'}
                  size="28"
                  round
                />
              </span>
              <TextOverflow maxLen={380} s lineHeight="s" color="gray900">
                {legalName}
              </TextOverflow>
            </Flex>
          );
        },
      },
      {
        header: () => <Text css={headerCss}>Régimen fiscal de factura</Text>,
        accessorKey: 'cfdi_fiscal_regime',
        label: 'Régimen fiscal de factura',
        id: 'cfdi_fiscal_regime',
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
        accessorFn: (entry) => getReceiverOrIssuerFiscalRegime(entry) || '-',
        cell: ({ row }) => {
          const fiscalRegime = getReceiverOrIssuerFiscalRegime(row.original);
          return (
            <Cell isCollapsedText textCss={cellUpperCss}>
              {fiscalRegime}
            </Cell>
          );
        },
      },
      {
        header: () => <Text css={headerCss}>RFC</Text>,
        label: 'RFC',
        accessorKey: 'rfc',
        id: 'rfc',
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
        accessorFn: (entry) => getReceiverOrIssuerRfc(entry) || '-',
        cell: ({ row }) => (
          <Flex css={cellUpperCss}>
            {getReceiverOrIssuerRfc(row.original) || '-'}
          </Flex>
        ),
      },
      {
        header: '',
        size: 100,
        label: 'Acciones',
        accessorKey: 'actions',
        id: 'actions',
        cell: ({ row }) => {
          const { url, id } = getDocument(row.original);
          const openShowPdfModal = [
            'Cfdi',
            'CancelledCfdi',
            'PaymentDetail',
            'FixedAsset',
          ].includes(row.original.source_type);
          return (
            <Flex itemsCenter gap={4} onClick={(e) => e.stopPropagation()}>
              {openShowPdfModal && (
                <Tooltip
                  delayduration={0}
                  size="xs"
                  triggerElement={
                    <span>
                      <CfdiPreviewPdfModal
                        accessToken={accessToken}
                        title="Vista del CFDI"
                        cfdiId={id}
                        onlyIcon
                      />
                    </span>
                  }
                >
                  Ver PDF de la factura
                </Tooltip>
              )}
              {!!url && !openShowPdfModal && (
                <Tooltip
                  delayduration={0}
                  size="xs"
                  triggerElement={
                    <Button
                      size="xs"
                      variant="outlined"
                      icon
                      noFill
                      onClick={() => {
                        window.open(url, '_blank');
                      }}
                    >
                      <EyeLineIcon color="#667085" />
                    </Button>
                  }
                >
                  Ver documento
                </Tooltip>
              )}
            </Flex>
          );
        },
      },
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Conceptos/Descripción</Text>
          </Flex>
        ),
        label: 'Conceptos/Descripción',
        accessorKey: 'concept_or_description',
        id: 'concept_or_description',
        cell: ({ row }) => (
          <Cell isCollapsedText textCss={cellCss}>
            {(getEntryConceptOrDescription(row.original) || '-').trim()}
          </Cell>
        ),
        accessorFn: (entry) =>
          (getEntryConceptOrDescription(entry) || '-').trim(),
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
      },
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Uso CFDI</Text>
          </Flex>
        ),
        size: 300,
        label: 'Uso CFDI',
        accessorKey: 'cfdi_use',
        id: 'cfdi_use',
        accessorFn: (entry) => getCfdiUsage(entry)?.name || '-',
        cell: ({ row }) => (
          <Cell isCollapsedText textCss={cellUpperCss}>
            {getCfdiUsage(row.original)?.name || '-'}
          </Cell>
        ),
      },
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Método de Pago</Text>
          </Flex>
        ),
        accessorFn: (entry) => getCfdiPaymentMethod(entry) || '-',
        label: 'Método de Pago',
        accessorKey: 'payment_method',
        id: 'payment_method',
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: PAYMENT_METHODS,
        },
        cell: ({ row }) => (
          <Cell textCss={cellUpperCss}>
            {getCfdiPaymentMethod(row.original) || '-'}
          </Cell>
        ),
      },
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Forma de Pago</Text>
          </Flex>
        ),
        label: 'Forma de Pago',
        accessorKey: 'payment_form',
        id: 'payment_form',
        size: 300,
        cell: ({ row }) => (
          <Cell isCollapsedText textCss={cellUpperCss}>
            {getEntryPaymentForm(row.original)?.name || '-'}
          </Cell>
        ),
        accessorFn: (entry) => getEntryPaymentForm(entry)?.name || '-',
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: PAYMENT_TYPE_FILTER_CLIENT_OPTS,
        },
      },
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Comentarios</Text>
          </Flex>
        ),
        label: 'Comentarios',
        accessorKey: 'comments',
        id: 'comments',
        size: 300,
        cell: ({ row }) => (
          <Cell isCollapsedText textCss={cellUpperCss}>
            {row.original.comments || '-'}
          </Cell>
        ),
      },
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Moneda</Text>
          </Flex>
        ),
        size: COLUMN_NUMBER_SIZE,
        label: 'Moneda',
        accessorKey: 'currency',
        id: 'currency',
        accessorFn: (entry) => getCurrency(entry) || '-',
        cell: ({ row }) => (
          <Cell textCss={cellUpperCss}>{getCurrency(row.original) || '-'}</Cell>
        ),
      },
      ...getCurrencyColumns('client'),
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Folio fiscal</Text>
          </Flex>
        ),
        label: 'Folio fiscal',
        accessorKey: 'cfdi_fiscal_folio',
        id: 'cfdi_fiscal_folio',
        cell: ({ row }) => (
          <Cell isCollapsedText textCss={cellUpperCss}>
            {getCfdiFiscalFolio(row.original) || '-'}
          </Cell>
        ),
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
        accessorFn: (entry) => getCfdiFiscalFolio(entry) || '-',
      },
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Folio UI</Text>
          </Flex>
        ),
        label: 'Folio UI',
        accessorKey: 'folio_ui',
        accessorFn: (entry) => getCfdiUIFolio(entry) || '-',
        id: 'folio_ui',
        cell: ({ row }) => (
          <Cell isCollapsedText textCss={cellUpperCss}>
            {getCfdiUIFolio(row.original) || '-'}
          </Cell>
        ),
      },
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Tipo de Cambio</Text>
          </Flex>
        ),
        label: 'Tipo de Cambio',
        accessorKey: 'exchange_rate',
        id: 'exchange_rate',
        accessorFn: (entry) => getExchangeRate(entry) || '-',
        cell: ({ row }) => (
          <Cell textCss={cellUpperCss}>
            {getExchangeRate(row.original) || '-'}
          </Cell>
        ),
      },
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Cuenta contable</Text>
          </Flex>
        ),
        label: 'Cuenta contable',
        accessorKey: 'general_declaration_entry_type',
        id: 'general_declaration_entry_type',
        cell: ({ row }) => (
          <Cell textCss={cellUpperCss}>
            {getGeneralType(row.original) === 'income' ? 'Ingresos' : 'Gastos'}
          </Cell>
        ),
        accessorFn: (entry) =>
          getGeneralType(entry) === 'income' ? 'Ingresos' : 'Gastos',
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: GENERAL_DECLARATION_ENTRY_TYPE_FILTER_CLIENT_OPTS,
        },
      },
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Régimen fiscal</Text>
          </Flex>
        ),
        label: 'Régimen fiscal',
        accessorKey: 'fiscal_regime',
        id: 'fiscal_regime',
        size: 300,
        cell: ({ row }) => (
          <Cell isCollapsedText textCss={cellUpperCss}>
            {getRegimeNameFromFiscalRegime(row.original.fiscal_regime)}
          </Cell>
        ),
        accessorFn: (entry) => entry.fiscal_regime.sat_key.toString() || '',
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: fiscalRegimeOpts,
        },
      },
      {
        header: () => (
          <Flex>
            <Text css={headerCss}>Fecha emisión/pago</Text>
          </Flex>
        ),
        size: 150,
        label: 'Fecha emisión/pago',
        accessorKey: 'created_at',
        accessorFn: (entry) => getDateIssued(entry),
        id: 'created_at',
        cell: ({ row }) => (
          <Cell textCss={cellUpperCss}>
            {dayjs(getDateIssued(row.original)).format('DD MMM YYYY') || ''}
          </Cell>
        ),
      },
    ],
    [accessToken, fiscalRegimeOpts],
  );
}
