import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '@api';
import FormikSelect from '@components/FormikSelect';

export default function FormikClientInvoiceSelect({
  client,
  newDesign,
  ...props
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCfdis = async () => {
    if (!client?.rfc) {
      setOptions(null);
      return;
    }

    setLoading(true);
    const response = await api.get(`cfdis/by_client?rfc=${client.rfc}`);
    setLoading(false);

    const parsedOptions = response.data.map((cfdi) => ({
      value: cfdi,
      label: `${cfdi.client_supplier.rfc} - ${cfdi.folio_fiscal} - ${cfdi.date_issued}`,
      uuid: cfdi.folio_fiscal,
    }));

    setOptions(parsedOptions);
  };

  useEffect(() => {
    fetchCfdis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return (
    <FormikSelect
      {...props}
      newDesign={newDesign}
      options={options}
      disabled={!client}
      loading={loading}
    />
  );
}

FormikClientInvoiceSelect.propTypes = {
  client: PropTypes.instanceOf(Object),
};

FormikClientInvoiceSelect.defaultProps = {
  client: null,
};
