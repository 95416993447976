import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

export default function Portal({ target, children }) {
  const targetElement =
    typeof target === 'string' ? window.document.querySelector(target) : target;

  return targetElement ? createPortal(children, targetElement) : children;
}

Portal.propTypes = {
  target: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Element),
  ]),
  children: PropTypes.node.isRequired,
};

Portal.defaultProps = {
  target: '#app-level',
};
