import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTaxableEntity } from '@hooks';
import { TaxableEntity } from 'types/entities';
import { Flag02LineIcon } from '@konta/icons';
import usePendingTasksStore from 'store/pendingTasksStore';
import { useQueryClient } from 'react-query';
import { PendingTask } from '@components/TaskCard/TaskCard';
import { PROCESSES } from '@constants/reactQueries';
import { useShallow } from 'zustand/react/shallow';

export default function useHandlPreOnboarding() {
  const { pendingTasks, addPendingTask } = usePendingTasksStore(
    useShallow((state) => ({
      pendingTasks: state.pendingTasks,
      cleanPendingTask: state.cleanPendingTask,
      cleanSelectedTask: state.cleanSelectedTask,
      addPendingTask: state.addPendingTask,
    })),
  );
  const preOnboardingTask = useMemo(
    () => pendingTasks.find((task) => task.type === 'subscription'),
    [pendingTasks],
  );
  const queryClient = useQueryClient();
  const { taxable_entity: taxableEntity } = useTaxableEntity() as {
    taxable_entity: TaxableEntity;
  };

  const [showPreOnboardingModalTask, setShowPreOnboardingModalTask] =
    useState<PendingTask | null>(null);

  const toggleShowPreOnboardingModalTask = useCallback(async () => {
    setShowPreOnboardingModalTask(null);
    await queryClient.invalidateQueries([PROCESSES]);
  }, [setShowPreOnboardingModalTask, queryClient]);

  const hasFirstInformation = useMemo(
    () => taxableEntity.status !== 'demo',
    [taxableEntity],
  );

  useEffect(() => {
    // If the preOnboardingTask is not in the pendingTasks, the modal should be closed
    if (!preOnboardingTask) {
      void toggleShowPreOnboardingModalTask();
    }
  }, [preOnboardingTask, toggleShowPreOnboardingModalTask]);

  useEffect(() => {
    if (!hasFirstInformation) {
      addPendingTask({
        id: 'subscription',
        type: 'subscription',
        status: 'requiredAction',
        title: 'Apertura de cuenta',
        description: 'Contrata un plan para poder continuar en la aplicación',
        details: [
          {
            label: 'Contratar un plan',
            icon: <Flag02LineIcon />,
            iconCss: {
              background: '$orange100',
              path: {
                fill: 'none',
                stroke: '$orange500',
              },
            },
          },
        ],
        handleAction: () => {
          setShowPreOnboardingModalTask({
            id: 'subscription',
            type: 'subscription',
            status: 'requiredAction',
            title: 'Apertura de cuenta',
            description:
              'Contrata un plan para poder continuar en la aplicación',
          });
        },
      });
    }
  }, [addPendingTask, hasFirstInformation, setShowPreOnboardingModalTask]);

  return {
    showPreOnboardingModalTask,
    toggleShowPreOnboardingModalTask,
  };
}
