import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Row, Collapse } from 'reactstrap';
import {
  Text,
  Table,
  Cell,
  Row as KontaRow,
  TableBody,
  TableHead,
  HeaderCell,
  Button as KontaButton,
} from '@konta/ui';
import { PricingCheckIcon, Eye2Icon } from '@konta/icons';
import { useToggle } from 'rooks';
import {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
} from '@redux/actions';
import toCurrency from '@util/toCurrency';
import { getMonthAndYearDate } from '@util/Utils';
import { Colxx } from '@components';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';

function TextExprencesResico({ workflow }) {
  const { total_income, declaration_income, iva_rate } =
    workflow.active_declaration;

  const [incomeSummaryCollapseOpen, setIncomeSummaryCollapseOpen] =
    useToggle(false);

  const enties = useMemo(
    () => [
      {
        id: 1,
        name: 'Total de ingresos',
        subtotal: declaration_income.total,
        tax: Number(declaration_income.total_iva_bases) * Number(iva_rate),
        total:
          Number(declaration_income.total) +
          Number(declaration_income.total_iva_bases) * Number(iva_rate),
        bold: true,
      },
      {
        id: 2,
        name: 'Ingresos al 16% o 8%',
        subtotal: declaration_income?.total_iva_bases,
        tax: Number(declaration_income.total_iva_bases) * Number(iva_rate),
        total:
          Number(declaration_income.total_iva_bases) +
          Number(declaration_income.total_iva_bases) * Number(iva_rate),
      },
      {
        id: 3,
        name: 'Ingresos al 0% y exento',
        subtotal:
          Number(declaration_income.total_exempt_bases) +
          Number(declaration_income.total_zero_bases),
        tax: 0,
        total:
          Number(declaration_income.total_exempt_bases) +
          Number(declaration_income.total_iva) +
          Number(declaration_income.total_zero_bases),
      },
    ],
    [declaration_income, iva_rate],
  );

  return (
    <WorkflowLayout
      header={<WorkflowHeader title="Sección de ingresos finalizada" />}
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton>Sí, continuar</PrimaryPhaseButton>
        </>
      }
    >
      <PricingCheckIcon fill="none" className="icon-large" />
      <Text x2l>
        ¡Excelente! 🚀 Tus ingresos del mes de{' '}
        {getMonthAndYearDate(new Date(workflow.start_date))} fueron{' '}
        {toCurrency(+total_income)}
        {' pesos'}
        {declaration_income?.total_iva_bases > 0 && ' + IVA'}.
      </Text>
      <div>
        <KontaButton
          color="neutral"
          leftIcon={<Eye2Icon />}
          variant={incomeSummaryCollapseOpen ? 'contained' : 'outlined'}
          onClick={setIncomeSummaryCollapseOpen}
        >
          Ver desglose final de ingresos
        </KontaButton>
      </div>
      <Collapse isOpen={incomeSummaryCollapseOpen}>
        <Row className="mb-3">
          <Colxx md="9">
            <Table>
              <TableHead>
                <KontaRow>
                  <HeaderCell />
                  <HeaderCell>Base</HeaderCell>
                  <HeaderCell>Impuesto</HeaderCell>
                  <HeaderCell>Total</HeaderCell>
                </KontaRow>
              </TableHead>
              <TableBody>
                {enties.map((item) => (
                  <KontaRow key={item.id}>
                    <Cell>
                      <Text bold={item.bold}>{item.name}</Text>
                    </Cell>
                    <Cell right>
                      <Text bold={item.bold}>{toCurrency(+item.subtotal)}</Text>
                    </Cell>
                    <Cell right>
                      <Text bold={item.bold}>{toCurrency(+item.tax)}</Text>
                    </Cell>
                    <Cell right>
                      <Text bold={item.bold}>{toCurrency(item.total)}</Text>
                    </Cell>
                  </KontaRow>
                ))}
              </TableBody>
            </Table>
          </Colxx>
        </Row>
      </Collapse>
      <Text x2l>
        Ahora procederemos a <strong>declarar tus gastos.</strong> ¿Estás listo?
      </Text>
      <Text css={{ fontFamily: 'Inter', fontSize: '16px' }} medium>
        Puntos importantes a considerar en la siguiente sección:
      </Text>

      <Text
        css={{ fontFamily: 'Inter', fontSize: '16px' }}
        color="secondary70"
        bold
      >
        Las facturas de gastos ya fueron pre clasificadas
      </Text>
      <Text
        color="neutral"
        css={{ fontFamily: 'Inter', fontSize: '16px' }}
        regular
      >
        Nosotros revisamos todas tus facturas y vemos cuales si son deducibles
        por el tipo de negocio que tienes. Cualquier factura que no entra tendrá
        un comentario con el motivo no es deducible.
      </Text>
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ workflows }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { workflow, wloading };
};

export default connect(mapStateToProps, {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
})(TextExprencesResico);
