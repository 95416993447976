import { call, put, select } from 'redux-saga/effects';
import { fetchSubscriptions, fetchTaxableEntity } from '@redux/actions';
import { ErrorHandler, StatusHandler } from '@util/Saga';
import api from '@api';
import gtmEvent from '@util/gtmEvent';
import { subscribeSucess, subscribeFailure } from '../actions';
/**
 * @todo Add pay fiscal periods API call
 * @param {string} card_id
 */
export default function* ({ payload: { subscription_params, callback } }) {
  try {
    const { taxable_entity } = yield select((state) => state.taxableEntity);

    const access_token = localStorage.getItem('user_id');

    const response = yield call(
      api.post,
      `/subscriptions/`,
      subscription_params,
      { headers: { Authorization: `${access_token}` } },
    );
    gtmEvent('AppEvent', 'subscribeSuccess');
    /*     yield put(subscribeSucess(response.data));
        yield call(
          NotificationManager.success(
            "El pago fue realizado con éxito.",
            "Suscripción creada",
            6000,
            null,
            null
          )
        ); */
    yield call(StatusHandler, {
      response,
      title: 'Suscripción creada',
      message: 'El pago fue realizado con éxito.',
      status: response.status,
      success: subscribeSucess,
      failure: subscribeFailure,
    });
    // yield put(fetchCurrentFiscalPeriod()); // TODO: Remove this becouse is sending a request error with a null parameter
    yield put(fetchSubscriptions());
    yield put(fetchTaxableEntity(taxable_entity.id));
    if (callback) callback();
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: subscribeFailure,
      showBodyErrors: true,
    });
  }
}
