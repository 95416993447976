import { NodeProps as XyFlowNodeProps } from '@xyflow/react';
import { DateStr, KontaScore } from './entities';

export interface NodeData extends Record<string, unknown> {
  label: string;
  isFirst?: boolean;
  isLast?: boolean;
  step: Step;
  flow_id: number;
}

export interface NodeProps extends XyFlowNodeProps {
  data: NodeData;
}

export interface Edge {
  id: number;
  source_step_id: number;
  target_step_id: number;
  created_at: DateStr;
  updated_at: DateStr;
}

export enum StepDataType {
  IMG = 'image',
  TEXT = 'text',
  BLOG = 'blog',
  TAX_TO_PAY = 'tax_to_pay',
  SCORE = 'score',
  PENDING_DECLARATIONS = 'pending_declarations',
  NON_DEDUCTIBLE_GAS_INVOICES = 'non_deductible_gas_invoices',
  DEDUCTIONS_LIMIT_REACHED = 'deductions_limit_reached',
  BALANCE_AVAILABLE_FOR_DEDUCTIONS = 'balance_available_for_deductions',
  WITHOUT_TAX_EFFECTS = 'without_tax_effects',
}

export interface CommonStep {
  id: number;
  flow_id: number;
  readonly created_at?: DateStr;
  readonly updated_at?: DateStr;
}
export interface TextStep extends CommonStep {
  type: StepDataType.TEXT;
  params: {
    body: string;
    emoji: string;
    title: string;
  };
}
export interface ImgStep extends CommonStep {
  type: StepDataType.IMG;
  params: {
    url: string;
  };
}
export interface BlogStep extends CommonStep {
  type: StepDataType.BLOG;
  params: {
    url: string;
  };
}
export interface TaxToPayStep extends CommonStep {
  type: StepDataType.TAX_TO_PAY;
  params: {
    amount: number;
  };
}
export interface PendingDeclarationsStep extends CommonStep {
  type: StepDataType.PENDING_DECLARATIONS;
  params: {
    amount: number;
  };
}
export interface ScoreStep extends CommonStep {
  type: StepDataType.SCORE;
  params: {
    amount: number;
  };
}
export interface NonDeductibleGasInvoicesStep extends CommonStep {
  type: StepDataType.NON_DEDUCTIBLE_GAS_INVOICES;
  params: {
    amount: number;
  };
}
export interface BalanceAvailableForDeductionsStep extends CommonStep {
  type: StepDataType.BALANCE_AVAILABLE_FOR_DEDUCTIONS;
  params: {
    amount: number;
  };
}
export interface DeductionsLimitReachedStep extends CommonStep {
  type: StepDataType.DEDUCTIONS_LIMIT_REACHED;
  params: {
    amount: number;
  };
}
export interface WithoutTaxEffectsStep extends CommonStep {
  type: StepDataType.WITHOUT_TAX_EFFECTS;
  params: {
    amount: number;
  };
}
export type Step =
  | TextStep
  | ImgStep
  | BlogStep
  | TaxToPayStep
  | DeductionsLimitReachedStep
  | PendingDeclarationsStep
  | ScoreStep
  | NonDeductibleGasInvoicesStep
  | BalanceAvailableForDeductionsStep
  | WithoutTaxEffectsStep;

export enum FlowDataType {
  INSIGHT = 'insight',
}

export interface CommonFlow {
  id: number;
  name: string;
  readonly created_at?: DateStr;
  readonly updated_at?: DateStr;
  steps: Step[];
  edges: Edge[];
}

export interface FlowInsight extends CommonFlow {
  type: FlowDataType.INSIGHT;
  image: {
    content: string;
    content_type: string;
  };
}

export type Flow = FlowInsight;

export interface CommonTaxableEntityFlowStep {
  id: number;
  flow_step_id: number;
  taxable_entity_flow_id: number;
  state: unknown;
  seen: boolean;
  completed: boolean;
  created_at: DateStr;
  updated_at: DateStr;
  flow_step: Step;
}

export interface WithoutTaxEffectsFlowStep extends CommonTaxableEntityFlowStep {
  flow_step: WithoutTaxEffectsStep;
  state: {
    count: number;
  };
}
export interface NonDeductibleGasInvoicesFlowStep
  extends CommonTaxableEntityFlowStep {
  flow_step: NonDeductibleGasInvoicesStep;
  state: {
    count: number;
  };
}
export interface ScoreFlowStep extends CommonTaxableEntityFlowStep {
  flow_step: ScoreStep;
  state: KontaScore;
}
export interface PendingDeclarationsFlowStep
  extends CommonTaxableEntityFlowStep {
  flow_step: PendingDeclarationsStep;
  state: string[];
}
export interface DeductionsLimitReachedFlowStep
  extends CommonTaxableEntityFlowStep {
  flow_step: DeductionsLimitReachedStep;
  state: {
    limit: number;
    accumulated_deductions: number;
  };
}
export interface BalanceAvailableForDeductionsFlowStep
  extends CommonTaxableEntityFlowStep {
  flow_step: BalanceAvailableForDeductionsStep;
  state: {
    available_balance: number;
  };
}
export interface TaxToPayFlowStep extends CommonTaxableEntityFlowStep {
  flow_step: TaxToPayStep;
  state: {
    to_pay: number;
  };
}
export type TaxableEntityFlowStep =
  | CommonTaxableEntityFlowStep
  | WithoutTaxEffectsFlowStep
  | NonDeductibleGasInvoicesFlowStep
  | ScoreFlowStep
  | PendingDeclarationsFlowStep
  | DeductionsLimitReachedFlowStep
  | BalanceAvailableForDeductionsFlowStep
  | TaxToPayFlowStep;

export type TaxableEntityFlowStatus = 'unbegun' | 'in_progress' | 'completed';
export interface TaxableEntityFlow {
  id: number;
  taxable_entity_id: number;
  flow_id: number;
  status: TaxableEntityFlowStatus;
  created_at: DateStr;
  updated_at: DateStr;
  steps: TaxableEntityFlowStep[];
  flow: Omit<Flow, 'steps'>;
}

export interface TaxableEntityFlowUpdatePayload {
  taxableEntityFlowId: number;
  payload: {
    status?: TaxableEntityFlowStatus;
    steps_attributes?: Pick<
      TaxableEntityFlowStep,
      'id' | 'seen' | 'completed'
    >[];
  };
}
