import React, { useEffect } from 'react';
import {
  Button,
  config,
  Divider,
  Flex,
  LabelWrapper,
  Text,
  useMediaQuery,
} from '@konta/ui';
import { Download02Icon, FileX02Icon } from '@konta/icons';
import { fetchCFDIfromXML } from '@redux/actions';
import { useDispatch } from 'react-redux';
import { useToggle } from 'rooks';
// eslint-disable-next-line import/no-cycle
import { useQueryClient } from 'react-query';
import ConfirmCancelInvoiceModal from '@components/ConfirmCancelInvoiceModal';
import PreviewDocument from '@components/PreviewDocument';
// eslint-disable-next-line import/no-cycle
import Loader from '@components/Loader';
import KontaWhatsappIcon from '@components/WhatsappIcon';
import useFiscalRegimesQuery from '@hooks/useFiscalRegimesQuery';
import useInvoiceDraftPdf from '@hooks/useInvoiceDraftPdf';
import useInvoiceDownloadLink from '@hooks/useXmlDownloadLink';
import SendByEmail from '@components/SendByEmail';
import { INVOICE_PDF_PREVIEW } from '@constants/reactQueries';

function PdfViewer({
  url,
  id,
  status,
  togglePreview,
  invoice,
  withShareButtons = true,
  withActions = true,
}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { isPdfLoading, pdf } = useInvoiceDraftPdf(id);
  const { fileLoading, fileLink } = useInvoiceDownloadLink(id);
  const [showCancelInvoice, toggleShowCancelInvoice] = useToggle();
  const isTabletOrMobile = useMediaQuery(config.media.md);

  useEffect(() => {
    if (url) dispatch(fetchCFDIfromXML(url));
  }, [url, dispatch]);
  useEffect(() => {
    return () => queryClient.removeQueries(INVOICE_PDF_PREVIEW);
  }, [queryClient]);

  const { regimes } = useFiscalRegimesQuery();

  if (regimes.length === 0) {
    return null;
  }
  const messageText = `Te comparto este Link donde podrás descargar una factura: ${process.env.REACT_APP_API_HOST}/invoices/${invoice?.invoice_draft?.uuid}/preview`;
  const pdfUrl = pdf === '' ? '' : `data:application/pdf;base64,${pdf}`;

  const hasDeclarationInAssociation =
    (invoice?.declarations || []).length > 0 &&
    invoice?.declarations.every(
      (declaration) => declaration?.workflow_status === 'done',
    );

  return (
    <Flex
      gap={10}
      direction={isTabletOrMobile ? 'row' : 'column'}
      css={{ flex: '1', width: '100%' }}
    >
      <>
        <Flex css={{ flex: '3', flexDirection: 'column' }}>
          {isPdfLoading ? <Loader /> : <PreviewDocument defaultFile={pdfUrl} />}
        </Flex>
        <Flex direction="column" css={{ flex: '1' }} gap={10}>
          <Flex direction="column" gap={8}>
            <Button
              color="primary600"
              variant="contained"
              css={{
                width: 'auto',
                path: { stroke: 'white' },
              }}
              onClick={async () => {
                const a = document.createElement('a');
                a.href = `data:application/pdf;base64,${pdf}`;
                const folioFiscal =
                  invoice?.folio_fiscal ||
                  invoice?.source?.cfdi?.folio_fiscal ||
                  'factura';
                a.download = `${folioFiscal}.pdf`;
                a.click();
                a.remove();
              }}
              leftIcon={<Download02Icon />}
              loading={isPdfLoading}
            >
              Descargar PDF
            </Button>
            <Button
              color="primary600"
              leftIcon={<Download02Icon />}
              variant="contained"
              loading={fileLoading}
              onClick={async () => {
                const a = document.createElement('a');
                a.href = fileLink.url;
                a.click();
                a.remove();
              }}
              css={{
                width: 'auto',
                path: { stroke: 'white' },
              }}
            >
              Descargar XML
            </Button>
          </Flex>
          {withShareButtons && (
            <>
              <Divider />
              <Flex direction="column" gap={10}>
                <Text bold>Compartir factura</Text>
                <SendByEmail id={id} />

                <Flex direction="column">
                  <LabelWrapper label="WhatsApp">
                    <Button
                      color="gray"
                      onClick={() => {
                        window.open(
                          !isTabletOrMobile
                            ? `whatsapp://send?text= ${messageText}`
                            : `https://web.whatsapp.com/send?text=${messageText}`,
                          'popup',
                          'height=700px,width=700px',
                        );
                      }}
                      variant="outlined"
                      css={{
                        width: 'auto',
                        path: { stroke: '#393d3d' },
                      }}
                      rightIcon={<KontaWhatsappIcon />}
                    >
                      Abrir
                    </Button>
                  </LabelWrapper>
                </Flex>
              </Flex>
            </>
          )}
          {withActions && (
            <>
              <Divider />
              <Flex direction="column" gap={8}>
                <Text bold>Acciones al CFDI</Text>
                <LabelWrapper
                  label="Generar cancelación"
                  labelHelpPopover={
                    hasDeclarationInAssociation
                      ? 'Si la factura se encuentra vinculada a una declaración, esta no podrá ser cancelada.'
                      : false
                  }
                >
                  <Button
                    variant="outlined"
                    leftIcon={<FileX02Icon />}
                    css={{
                      width: 'auto',
                      path: { fill: 'white' },
                    }}
                    onClick={toggleShowCancelInvoice}
                    disabled={hasDeclarationInAssociation}
                  >
                    {status === 'Pendiente de aceptación'
                      ? 'Validar cancelación'
                      : 'Cancelar factura'}
                  </Button>
                </LabelWrapper>
              </Flex>
              <ConfirmCancelInvoiceModal
                isOpen={showCancelInvoice}
                toggle={() => toggleShowCancelInvoice()}
                status={status}
                id={id}
                invoice={invoice}
                togglePreview={togglePreview}
              />
            </>
          )}
        </Flex>
      </>
    </Flex>
  );
}

export default PdfViewer;
