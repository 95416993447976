import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  contactMethodFetchFailure,
  contactMethodFetchSuccess,
} from '../actions';

export default function* (payload) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(api.get, `contact_methods`, {
      headers: { Authorization: `${access_token}` },
    });
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: contactMethodFetchSuccess,
      failure: contactMethodFetchFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: contactMethodFetchFailure,
    });
  }
}
