import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Select } from '@konta/ui';
import SelectInput from '@components/SelectInput';

export default function FormikSelect({
  label,
  name,
  options,
  isMulti,
  placeholder,
  withButton, // deprecated
  loading,
  disabled,
  toggle, // deprecated
  onAdd,
  withSecondaryButton, // deprecated
  onEdit,
  secondaryToggle, // deprecated
  onChange,
  newDesign,
  labelHelpPopover,
  ...restProps
}) {
  const formik = useFormikContext();
  const [field, meta, helpers] = useField({
    name,
    ...restProps,
  });
  const touched = meta.touched || formik.submitCount > 0;
  const error = meta.error && touched ? meta.error : null;

  const handleChange = (value) => {
    helpers.setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  if (newDesign) {
    return (
      <Select
        {...restProps}
        {...field}
        onAdd={onAdd || toggle}
        onEdit={onEdit || secondaryToggle}
        label={label}
        labelHelpPopover={labelHelpPopover}
        helperText={error}
        helperTextColor={error ? 'error' : 'normal'}
        options={options}
        isMulti={isMulti}
        placeholder={placeholder}
        isLoading={loading}
        isDisabled={disabled}
        onChange={handleChange}
        loadingMessage={() => 'Cargando información...'}
      />
    );
  }

  // TODO: deprecated, remove this
  return (
    <SelectInput
      {...field}
      label={label}
      labelHelpPopover={labelHelpPopover}
      error={error}
      options={options}
      isMulti={isMulti}
      placeholder={placeholder}
      isLoading={loading}
      isDisabled={disabled}
      onChange={handleChange}
      withButton={withButton}
      toggle={toggle}
      withSecondaryButton={withSecondaryButton}
      secondaryToggle={secondaryToggle}
    />
  );
}
