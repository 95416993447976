import { useCallback } from 'react';
import { useMutation } from 'react-query';
import deleteTwoFactorAuth from '@api/auth/deleteTwoFactorAuth';

/**
 * @param { import('react-query').UseMutationOptions } options
 */
export default function useDeleteTwoFactorAuth(options) {
  const deleteTwoFactorAuthRequest = useCallback((code) => {
    return deleteTwoFactorAuth({ code });
  }, []);

  return useMutation(deleteTwoFactorAuthRequest, options);
}
