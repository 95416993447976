import { styled } from '@konta/ui';
import { Handle as ReactFlowHandle } from '@xyflow/react';

export const CustomNode = styled('div', {
  border: '1px solid $gray300',
  borderRadius: '6px',
  padding: '8px',
  backgroundColor: '$gray50',
  width: '240px',
});

export const NodeIcon = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$gray300',
  width: '12px',
  height: '12px',
});

export const FlagIcon = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$gray600',
  width: '14px',
  height: '14px',
});

export const NodeHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  width: '100%',
  gap: '6px',
});

export const NodeTitleContainer = styled('div', {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '6px',
});

export const NodeTitle = styled('span', {
  color: '#475467',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
});

export const NodeContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  padding: '$8 $10',
  borderRadius: '5px',
  gap: '$6',
  alignSelf: 'stretch',
  border: '1px solid $gray200',
  cursor: 'pointer',
});

export const ContentText = styled('span', {
  marginRight: 'auto',
  color: '$gray600',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '18px',
});

export const ContentSearch = styled('span', {
  cursor: 'pointer',
  width: '14px',
  height: '14px',
  color: '$gray400',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ContentImage = styled('span', {
  cursor: 'pointer',
  width: '14px',
  height: '14px',
  color: '$gray600',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// @ts-ignore Avoiding type error
export const CustomHandle = styled(ReactFlowHandle, {
  '&.react-flow__handle': {
    width: '9px',
    height: '9px',
    backgroundColor: 'white',
    border: '2px solid $gray400',
  },
});
