import { ReactNode } from 'react';
import { Button, CSS, HoverCard } from '@konta/ui';
import { StyledButtonContainer, styledButtonCss } from './styled';

interface ButtonIconWithTooltipProps {
  action: () => void;
  icon: ReactNode;
  tooltipText: ReactNode;
  disabled?: boolean;
  styledButtonContainerCss?: CSS;
}

export default function ButtonIconWithTooltip({
  action,
  disabled,
  icon,
  tooltipText,
  styledButtonContainerCss,
}: ButtonIconWithTooltipProps) {
  return (
    <StyledButtonContainer css={styledButtonContainerCss}>
      <HoverCard
        side="top"
        openDelay={0}
        triggerElement={
          <Button
            size="xs"
            icon
            noFill
            variant="text"
            onClick={action}
            disabled={disabled}
            css={styledButtonCss}
          >
            {icon}
          </Button>
        }
      >
        {tooltipText}
      </HoverCard>
    </StyledButtonContainer>
  );
}
