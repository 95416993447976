import { Flex } from '@konta/ui';
import BlockUi from 'shared/components/BlockUi/BlockUi';
import NotificationPreferences from './NotificationPreferences';
import { Card, Container } from './styled';
import useNotificationCenter from './useNotificationCenter';

export default function NotificationCenter() {
  const {
    taxableEntityPreferences,
    getNotificationsUtils,
    bulkHandleChange,
    handleChange,
    loading,
  } = useNotificationCenter();

  return (
    <Container>
      <BlockUi blocking={loading} />
      <Card>
        <NotificationPreferences
          preferences={taxableEntityPreferences}
          bulkHandleChange={bulkHandleChange}
          handleChecked={handleChange}
          getNotificationsUtils={getNotificationsUtils}
        />
        {/* <AccountAndUpdates
          preferences={taxableEntityPreferences}
          handleChecked={handleChange}
        /> */}
      </Card>
      <Flex css={{ flex: 1 }} />
    </Container>
  );
}
