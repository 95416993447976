import { Declaration } from 'types/entities';
import getDeclarationTotalToPay from '@util/getDeclarationTotalToPay';

export type CalculateDeclarationsSummary = ReturnType<
  typeof calculateDeclarationsSummary
>;

function calculateInitialValues(
  currentDeclaration: Declaration,
  totalKey: keyof Declaration,
  inFavorKey: keyof Declaration,
) {
  const totalByKey = currentDeclaration[totalKey];
  const inFavorByKey = currentDeclaration[inFavorKey];

  let totalValue = 0;

  if (totalKey === 'isr_total') {
    totalValue = totalByKey ? Math.max(0, +totalByKey) : 0;
  } else {
    totalValue = totalByKey ? +totalByKey : 0;
  }

  const totalInFavor = inFavorByKey ? +inFavorByKey : 0;

  const total = Math.max(0, totalInFavor - totalValue);
  const platformInFavor = totalValue > 0 ? total : totalInFavor;
  const totalToPayInitial = totalValue - totalInFavor;

  return {
    total,
    totalInFavor,
    pfaeTotal: totalValue,
    platformInFavor,
    totalToPayInitial: totalToPayInitial > 0 ? totalToPayInitial : 0,
    totalToPaySecondary: 0,
  } as Accumulator;
}

type Accumulator = {
  total: number;
  platformInFavor: number;
  pfaeTotal: number;
  totalToPayInitial: number;
  totalToPaySecondary: number;
  inFavorKey?: keyof Declaration;
};

function calculateAccumulatedValue(
  acc: Accumulator,
  currentDeclaration: Declaration,
  totalKey: keyof Declaration,
  inFavorKey: keyof Declaration,
) {
  const totalByKey = currentDeclaration[totalKey];

  const totalValue = totalByKey ? +totalByKey : 0;

  const total = Math.max(0, acc.total - totalValue);

  const totalToPaySecondary = totalValue - acc.platformInFavor;

  return {
    total,
    platformInFavor: acc.platformInFavor > 0 ? acc.platformInFavor : 0,
    pfaeTotal: acc.pfaeTotal,
    totalToPayInitial: acc.totalToPayInitial,
    totalToPaySecondary: totalToPaySecondary > 0 ? totalToPaySecondary : 0,
  } as Accumulator;
}

function calculateNextPeriodValues(
  declarations: Declaration[],
  totalKey: string,
  inFavorKey: string,
) {
  return declarations.reduce(
    (acc, currentDeclaration, index) => {
      if (index === 0) {
        return calculateInitialValues(
          currentDeclaration,
          totalKey as keyof Declaration,
          inFavorKey as keyof Declaration,
        );
      }
      return calculateAccumulatedValue(
        acc,
        currentDeclaration,
        totalKey as keyof Declaration,
        inFavorKey as keyof Declaration,
      );
    },
    {
      total: 0,
      platformInFavor: 0,
      pfaeTotal: 0,
      totalToPayInitial: 0,
      totalToPaySecondary: 0,
    },
  );
}


const calculateTaxesBalance = (declarations: Declaration[]) => {
  if (declarations.length === 0) {
    return {
      currentPeriodIvaResult: 0,
      totalToPayIva: 0,
      currentPeriodIsrResult: 0,
      totalToPayIsr: 0,
      totalTaxesToPay: 0,
      ivaTotal: 0,
      isrTotal: 0,
      totalNextPeriodIva: 0,
      totalNextPeriodIsr: 0,
      negativeIvaTotalSum: 0,
      totalLateFees: 0,
    };
  }
  const ivaInFavorOfPreviousPeriods = +declarations[0].iva_in_favor;
  const isrInFavorOfPreviousPeriods = +declarations[0].isr_in_favor;
  return declarations.reduce(
    (acc, declaration) => {
      const ivaTotal = +acc.ivaTotal + +declaration.iva_total;
      acc.negativeIvaTotalSum += Math.abs(Math.min(0, +declaration.iva_total));

      const isrTotal = Math.max(0, acc.isrTotal + +declaration.isr_total);

      const currentPeriodIvaResult =
        acc.currentPeriodIvaResult + Math.max(0, +declaration.iva_total);
      const currentPeriodIsrResult =
        acc.currentPeriodIsrResult + Math.max(0, +declaration.isr_total);

      const totalToPayIva = Math.max(
        0,
        currentPeriodIvaResult - +ivaInFavorOfPreviousPeriods,
      );
      const totalToPayIsr = Math.max(
        0,
        currentPeriodIsrResult - +isrInFavorOfPreviousPeriods,
      );
      const availableIvaAmount = Math.min(
        +ivaInFavorOfPreviousPeriods,
        currentPeriodIvaResult,
      );

      const totalNextPeriodIva =
        ivaInFavorOfPreviousPeriods -
        availableIvaAmount +
        Math.abs(acc.negativeIvaTotalSum);

      const availableIsrAmount = Math.min(
        isrInFavorOfPreviousPeriods,
        currentPeriodIsrResult,
      );
      const totalNextPeriodIsr =
        +isrInFavorOfPreviousPeriods - availableIsrAmount;

      const totalTaxesToPay = totalToPayIva + totalToPayIsr;

      const totalLateFees =  acc.totalLateFees + +(declaration?.total_late_fees || 0);

      return {
        currentPeriodIvaResult,
        totalToPayIva,
        currentPeriodIsrResult,
        totalToPayIsr,
        totalTaxesToPay,
        ivaTotal,
        isrTotal,
        totalNextPeriodIva,
        totalNextPeriodIsr,
        negativeIvaTotalSum: acc.negativeIvaTotalSum,
        totalLateFees,
      };
    },
    {
      currentPeriodIvaResult: 0,
      totalToPayIva: 0,
      currentPeriodIsrResult: 0,
      totalToPayIsr: 0,
      totalTaxesToPay: 0,
      ivaTotal: 0,
      isrTotal: 0,
      totalNextPeriodIva: 0,
      totalNextPeriodIsr: 0,
      negativeIvaTotalSum: 0,
      totalLateFees: 0,
    },
  );
};

export default function calculateDeclarationsSummary(
  declarations: Declaration[],
) {
  let totalIncomes = 0;
  let totalExpenses = 0;

  const { platformInFavor: platformInFavorIva } = calculateNextPeriodValues(
    declarations,
    'iva_total',
    'iva_in_favor',
  );

  // Calcula los valores para ISR
  const { platformInFavor: platformInFavorIsr } = calculateNextPeriodValues(
    declarations,
    'isr_total',
    'isr_in_favor',
  );

  const {
    currentPeriodIvaResult,
    totalToPayIva,
    currentPeriodIsrResult,
    totalTaxesToPay,
    totalToPayIsr,
    totalNextPeriodIsr,
    totalNextPeriodIva,
    totalLateFees,
  } = calculateTaxesBalance(declarations);

  const declarationSummary = declarations.map((declaration, index) => {
    const {
      declaration_income,
      declaration_expense,
      iva_in_favor,
      iva_total,
      total_payroll_withholdings_to_pay,
      fiscal_regime,
      isr_in_favor,
      isr_total,
      total_late_fees,
      total_to_pay,
    } = declaration;

    const { nextPeriodIva, totalIsrToPay, totalIvaToPay } =
      getDeclarationTotalToPay(declaration);

    const incomes = +(declaration_income?.total || 0);
    const expenses = +(declaration_expense?.total || 0);

    totalIncomes += incomes;
    totalExpenses += expenses;

    const totalLateFees = +(total_late_fees || 0);
    const totalToPay = +(total_to_pay || 0);
    const taxes = {
      totalIvaToPay,
      totalIsrToPay,
      totalToPay,
      nextPeriodIva,
      ivaInFavor: index === 0 ? +iva_in_favor : platformInFavorIva,
      ivaTotal: iva_total,
      isrInFavor: index === 0 ? +isr_in_favor : platformInFavorIsr,
      isrTotal: +isr_total > 0 ? +isr_total : 0,
      totalPayrollWithholdingsToPay: +total_payroll_withholdings_to_pay,
      employmentSubsidyOfPayroll:
        +declaration_income?.employment_subsidy_of_payroll || 0,
      taxesWithheldOfPayroll:
        +declaration_income?.taxes_withheld_of_payroll || 0,
    };

    return {
      incomes,
      expenses,
      taxes,
      totalLateFees,
      fiscalRegime: fiscal_regime,
    };
  });

  return {
    declarationSummary,
    incomeAndExpenseTotals: {
      totalIncomes,
      totalExpenses,
    },
    totalNextPeriodIsr,
    totalNextPeriodIva,
    taxesBalance: {
      currentPeriodIvaResult,
      ivaInFavorOfPreviousPeriods: declarations[0]?.iva_in_favor || 0,
      isrInFavorOfPreviousPeriods: declarations[0]?.isr_in_favor || 0,
      totalToPayIva,
      currentPeriodIsrResult,
      totalTaxesToPay,
      totalToPayIsr,
      totalLateFees,
    },
  };
}
