import React from 'react';
import { useDispatch } from 'react-redux';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import {
  Alert,
  Button,
  Divider,
  Flex,
  Text,
  useMediaQuery,
  config,
} from '@konta/ui';
import { FileUploadInLc1Icon } from '@konta/icons';
import { updateTaxableEntitySuccess } from '@redux/actions';
import useUpdateTaxableEntityMutation from '@hooks/useUpdateTaxableEntityMutation';
import { NotificationManager } from '@components/Notifications';
import Backdrop from '@components/Backdrop';
import { useToggle } from 'rooks';
import CredentialsModal from './CredentialsModalForm';
import IssuerInfoForm from './IssuerInfoForm.tsx';

export default function InvoiceIssuerInfo({ invoice, hasBillingData }) {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery(config.media['<sm']);
  const [isOpen, setIsOpen] = useToggle(false);
  const { has_csd } = invoice.taxableEntity;
  const updateTaxableEntity = useUpdateTaxableEntityMutation();

  const handleSubmit = async ({ postcode, legalName }) => {
    try {
      const response = await updateTaxableEntity.mutateAsync({
        address_attributes: {
          postcode,
        },
        legal_name: legalName,
      });
      dispatch(updateTaxableEntitySuccess(response));
      NotificationManager.success(
        'Se ha actualizado correctamente',
        'Tu información',
      );
    } catch (error) {
      NotificationManager.error(
        'Hubo un error al actualizar la información',
        'Error',
      );
    }
  };

  if (hasBillingData) {
    return null;
  }

  return (
    <>
      <Flex direction="column" gap="4" css={{ marginBottom: '28px' }}>
        <div style={{ zIndex: 1200, position: 'relative' }}>
          <Text xl bold color="black">
            Tus datos 😉
          </Text>
          <Divider css={{ my: '4px' }} />
        </div>
        {!has_csd && (
          <Alert
            color="primary"
            icon={<InformationOutlineIcon />}
            css={{
              mb: '$20',
              zIndex: 1200,
              position: 'relative',
            }}
          >
            <Flex
              direction={isTabletOrMobile ? 'column' : 'row'}
              justify="between"
              gap="10"
            >
              <div>Todavía no subes tus certificados de sellos digitales.</div>
              <Flex gap="6">
                <Button size="xs" color="primary" onClick={setIsOpen}>
                  Subir y validar
                </Button>
                <Button
                  as="a"
                  href="https://konta.kustomer.help/es/-r1Xus4hhc"
                  target="_blank"
                  size="xs"
                  color="primary"
                  variant="outlined"
                >
                  ¿Qué es esto?
                </Button>
              </Flex>
            </Flex>
          </Alert>
        )}

        <Flex direction="column" gap="8" css={{ '@sm': { width: '450px' } }}>
          {!has_csd && (
            <Flex
              direction="column"
              gap={8}
              css={{
                zIndex: 1200,
                position: 'relative',
                backgroundColor: 'white',
              }}
            >
              <Text color="gray900">Sellos digitales</Text>
              <Text color="gray400">
                Es importante que subas tus sellos digitales ya que sin ellos no
                podrás emitir facturas.
              </Text>
              <Button
                color="primary"
                css={{ width: 'fit-content' }}
                leftIcon={<FileUploadInLc1Icon />}
                onClick={setIsOpen}
              >
                Subir sellos
              </Button>
            </Flex>
          )}
          <IssuerInfoForm invoice={invoice} onSubmit={handleSubmit} />
        </Flex>
        <CredentialsModal toggle={setIsOpen} isOpen={isOpen} />
      </Flex>
      <Backdrop visible={!has_csd} />
    </>
  );
}
