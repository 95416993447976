import {
  SUBSCRIBE,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
  SUBSCRIPTION_SELECT,
  DISPLAY_SUBSCRIPTION_PRICES,
  SUBSCRIPTION_DISCOUNT,
  PAYMENT_HISTORY_FETCH,
  PAYMENT_HISTORY_FETCH_SUCCESS,
  PAYMENT_HISTORY_FETCH_FAILURE,
  SUBSCRIPTIONS_FETCH,
  SUBSCRIPTIONS_FETCH_SUCCESS,
  SUBSCRIPTIONS_FETCH_FAILURE,
  SUBSCRIBTION_CANCEL,
  SUBSCRIBTION_CANCEL_SUCCESS,
  SUBSCRIBTION_CANCEL_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  status: null,
  payment_history: [],
  subscriptions: [],
  loading: false,
  error: null,
  ui: {
    selected_subscription: {
      subscription: null,
      // TODO remove employeeCounter
      employeeCounter: 0,
      discount: {
        value: 0,
        code: '',
      },
    },
    display_prices: true,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBSCRIBE:
    case PAYMENT_HISTORY_FETCH:
    case SUBSCRIPTIONS_FETCH:
    case SUBSCRIBTION_CANCEL:
      return { ...state, loading: true };
    case SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        subscription: action.payload,
      };
    case SUBSCRIBTION_CANCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptions: state.subscriptions.map((el) =>
          el.id === action.payload.id ? action.payload : el,
        ),
      };
    case SUBSCRIPTIONS_FETCH_SUCCESS:
      return { ...state, loading: false, subscriptions: action.payload };
    case SUBSCRIBE_FAILURE:
    case PAYMENT_HISTORY_FETCH_FAILURE:
      return { ...state, loading: false, status: null };
    case SUBSCRIPTION_SELECT:
      return {
        ...state,
        ui: {
          ...state.ui,
          selected_subscription: {
            ...state.ui.selected_subscription,
            subscription: action.payload.subscription,
            employeeCounter: action.payload.employeeCounter,
            receiptCounter: action.payload.receiptCounter,
          },
        },
      };
    case DISPLAY_SUBSCRIPTION_PRICES:
      return {
        ...state,
        ui: {
          ...state.ui,
          display_prices: action.payload,
        },
      };
    case SUBSCRIPTION_DISCOUNT:
      return {
        ...state,
        ui: {
          ...state.ui,
          selected_subscription: {
            ...state.ui.selected_subscription,
            discount: action.payload,
          },
        },
      };
    case PAYMENT_HISTORY_FETCH_SUCCESS:
      return { ...state, payment_history: action.payload, loading: false };
    case SUBSCRIBTION_CANCEL_FAILURE:
    case SUBSCRIPTIONS_FETCH_FAILURE:
      return { ...state, loading: false };
    default:
      return { ...state };
  }
};
