import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Chip,
  Divider,
  Flex,
  ModalHeader,
  Step,
  Stepper,
  Text,
} from '@konta/ui';
import CloseIcon from 'mdi-react/CloseIcon';
import getCurrentRouteByWorkflowPhase from '@util/getCurrentRouteByWorkflowPhase';
import { WorkflowPhaseKeyDict } from 'types/entities';
import WorkflowLayout from '../WorkflowLayout';
import { WorkflowLayoutProps } from '../WorkflowLayout/WorkflowLayout';
import useWorkflow from '../Workflow/hooks/useWorkflow';

interface WorkflowsLayoutProps extends WorkflowLayoutProps {
  title: string;
  titleChip?: string;
  handleOnClose?: () => void;
  showOldWorkflow?: boolean;
}

export default function WorkflowsLayout({
  title,
  titleChip,
  children,
  actions,
  header,
  isLoading,
  handleOnClose,
  showOldWorkflow,
}: WorkflowsLayoutProps) {
  const navigate = useNavigate();
  const { workflow } = useWorkflow();

  const handleClose = () => {
    if (!workflow) {
      return;
    }
    if (showOldWorkflow) {
      handleOnClose?.();
      return;
    }
    const route = getCurrentRouteByWorkflowPhase(
      WorkflowPhaseKeyDict.PendingDeclaration,
      workflow,
    );

    if (route) {
      navigate(route);
    }
  };
  const steps = [
    [
      WorkflowPhaseKeyDict.Intro,
      WorkflowPhaseKeyDict.DeclarationInfo,
      WorkflowPhaseKeyDict.IncomesByRegime,
    ],
    [WorkflowPhaseKeyDict.ExpensesByRegime],
    [
      WorkflowPhaseKeyDict.ResumeDeclaration,
      WorkflowPhaseKeyDict.PendingDeclaration,
      WorkflowPhaseKeyDict.CaptureLinePayment,
      WorkflowPhaseKeyDict.TaxesPayment,
    ],
  ];

  const currentStep = steps.findIndex((step) =>
    step.includes(workflow?.current_phase_key as WorkflowPhaseKeyDict),
  );

  return (
    <>
      <ModalHeader
        divided
        css={{
          marginBottom: 0,
          position: 'sticky',
          top: 0,
          zIndex: 999,
          backgroundColor: 'white',
        }}
      >
        <Button icon variant="text" onClick={handleClose}>
          <CloseIcon />
        </Button>
        <Divider vertical />
        <Text regular m lineHeight="m">
          {title}
        </Text>
        {!!titleChip && <Chip color="primary100" size="xs" label={titleChip} />}
      </ModalHeader>
      <Flex
        direction="column"
        data-cy="processStepLayoutContent"
        css={{
          overflow: 'auto',
          height: 'fit-content',
          flex: 1,
          gap: '32px',
          backgroundColor: 'white',
          padding: '16px 0 16px 24px',
          '@md': {
            padding: '32px 0 16px 48px',
          },
        }}
      >
        {workflow?.workflow_template_id === 4 && (
          <Flex
            direction="column"
            align="center"
            css={{
              pr: '$48',
            }}
          >
            <Box className="w-100">
              <Stepper value={currentStep} variant="dot" binary={false}>
                <Step id={0} title="1.- Ingresos" />
                <Step id={1} title="2.- Gastos" />
                <Step id={2} title="3.- Calcular impuestos" />
              </Stepper>
            </Box>
          </Flex>
        )}
        <WorkflowLayout actions={actions} header={header} isLoading={isLoading}>
          {children}
        </WorkflowLayout>
      </Flex>
    </>
  );
}
