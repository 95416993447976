import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useBoundingclientrect, useWindowSize } from 'rooks';

export default function TemplatePreview({ srcPreview, srcdoc, width, style }) {
  const ref = useRef();
  useWindowSize();
  const getBoundingClientRect = useBoundingclientrect(ref);
  const [scale, setScale] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (getBoundingClientRect) {
      const newScale = getBoundingClientRect.width / width;
      setScale(newScale);
      setHeight(getBoundingClientRect.height / newScale);
    }
  }, [getBoundingClientRect]);

  const iframeStyles = useMemo(() => {
    return {
      width: `${width}px`,
      transformOrigin: 'top left',
      height: `${height}px`,
      transform: `scale(${scale})`,
      border: 'none',
    };
  }, [height, scale, width]);

  return (
    <div
      ref={ref}
      style={{
        ...style,
        overflow: 'hidden',
        width:
          ref?.current?.previousSibling?.clientWidth ||
          ref?.current?.parentElement?.clientWidth ||
          0,
        height: '600px',
        boxShadow:
          '0 15px 35px 0 rgba(65,69,82,.08) , 0 5px 15px 0 rgba(0,0,0,.12)',
      }}
    >
      <iframe
        {...(!!srcPreview && { src: srcPreview })}
        {...(!!srcdoc && { srcdoc })}
        title="Vista previa"
        style={iframeStyles}
      />
    </div>
  );
}
