import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getMonthAndYearDate } from '@util/Utils';
import { showWorkflowFetch } from '@redux/actions';
import useFeature from '@hooks/useFeature';
import FlowContainer from '@routes/app/flows/flow-container';
import * as chat from '@util/chat';
import { AUTOMATION_PFAE_AND_PT } from '@constants/featureFlags';
import { CalendarLockedOuLcIcon, FileAddOuLc1Icon } from '@konta/icons';
import PreviousSummary from '@containers/Workflows/PreviousSummary';

const SPECIAL_REGIME_WORKFLOWS = [625, 612];
const PERMIT_WORKFLOW_STATUS = [
  'fetching_invoice',
  'classifying',
  'ready',
  'in_proccess',
  'verify_declaration',
  'submit_declaration',
  'done',
  'pending_payment',
];

function getStatusFromWorkflow(workflow) {
  const statuses = [
    'ready',
    'pending_payment',
    'in_proccess',
    'submit_declaration',
    'verify_declaration',
  ];
  return statuses.includes(workflow.status);
}

function SelectedDeclarationMonth({
  selectedMonth,
  selectedWorkflow,
  workflows,
  showOldWorkflow,
  setShowOldWorkflow,
}) {
  const [automationPfaeAndPt] = useFeature(AUTOMATION_PFAE_AND_PT);
  const dispatch = useDispatch();
  const currentDate = dayjs().format('YYYY-MM-DD');

  const isBeforeMonth = dayjs(selectedMonth.value).isBefore(
    currentDate,
    'month',
  );
  const availableWorkflow = workflows
    .slice()
    .reverse()
    .find(getStatusFromWorkflow);

  useEffect(() => {
    if (selectedWorkflow) {
      dispatch(showWorkflowFetch(selectedWorkflow.id));
    }
  }, [selectedWorkflow, dispatch]);

  const linkForActiveDeclaration = useMemo(() => {
    const [currentYear] = currentDate.split('-');
    if (availableWorkflow) {
      const [availableYear, availableMonth] =
        availableWorkflow.start_date.split('-');
      return `/app/declaraciones?month=${availableMonth}&year=${availableYear}`;
    }
    const currentBeforeMonth = dayjs().subtract(1, 'month').get('month') + 1;
    return `/app/declaraciones?month=${currentBeforeMonth}&year=${currentYear}`;
  }, [availableWorkflow, currentDate]);

  const declarations = selectedWorkflow?.declarations.filter(
    (declaration) => declaration.is_active,
  );

  const isNewWorkflow =
    declarations?.length === 2 &&
    declarations?.every((declaration) =>
      SPECIAL_REGIME_WORKFLOWS.includes(declaration.fiscal_regime.sat_key),
    );

  const hasPendingPreviousMonthWorkflow = useMemo(() => {
    const selectedYear = dayjs(selectedMonth.value).year();
    const selectedMonthIndex = dayjs(selectedMonth.value).month();

    if (selectedMonthIndex === 0) {
      return false;
    }

    // Busca cualquier workflow anterior que no esté 'done' dentro del rango definido.
    return workflows.some((workflow) => {
      const workflowStartDate = dayjs(workflow.start_date);
      const workflowMonthIndex = workflowStartDate.month();
      const workflowYear = workflowStartDate.year();
      const completedStatuses = [
        'done',
        'paid',
        'verify_declaration',
        'pending_payment',
        'submit_declaration',
      ];

      // Comprueba si el workflow está en el rango de meses del mismo año y aún no está completado.
      return (
        workflowYear === selectedYear &&
        workflowMonthIndex < selectedMonthIndex &&
        !completedStatuses.includes(workflow.status)
      );
    });
  }, [workflows, selectedMonth]);

  if (!selectedWorkflow) {
    if (isBeforeMonth) {
      return (
        <div className="workflow-empty-container p-3">
          <FileAddOuLc1Icon className="workflow-empty-add-icon" />
          <h1>
            ¿Quiere hacer la declaración del mes de{' '}
            {getMonthAndYearDate(dayjs(selectedMonth?.value).toDate())}?
          </h1>
          <p className="text-muted">
            No se ha iniciado una declaración para este mes, puede hacerlo
            presionando el botón de abajo. 👇🏼
          </p>
          <Button
            outline
            size="sm"
            color="primary"
            className="workflow-empty-request-button"
            onClick={chat.open}
          >
            Enviar mensaje para solicitar declaración
          </Button>
        </div>
      );
    }

    return (
      <div className="workflow-empty-container p-3">
        <CalendarLockedOuLcIcon className="workflow-empty-locked-icon" />
        <h1>Este mes no se encuentra disponible.</h1>
        <p className="text-muted">
          Este mes no está activo, por lo cual no se puede iniciar una
          declaración. Ve a la declaración activa presionando el botón de abajo.
          👇🏼
        </p>
        <NavLink to={linkForActiveDeclaration} exact>
          <Button
            outline
            size="sm"
            className="workflow-empty-request-button"
            color="primary"
          >
            Ir a la declaración activa
          </Button>
        </NavLink>
      </div>
    );
  }

  if (automationPfaeAndPt && showOldWorkflow) {
    return (
      <FlowContainer
        setShowOldWorkflow={setShowOldWorkflow}
        showOldWorkflow={showOldWorkflow}
      />
    );
  }

  if (
    automationPfaeAndPt &&
    PERMIT_WORKFLOW_STATUS.includes(selectedWorkflow.status)
  ) {
    return (
      <PreviousSummary
        workflow={selectedWorkflow}
        isNewWorkflow={isNewWorkflow}
        setShowOldWorkflow={setShowOldWorkflow}
        selectedMonth={selectedMonth}
        availableWorkflow={availableWorkflow}
        hasPendingPreviousMonthWorkflow={hasPendingPreviousMonthWorkflow}
        workflows={workflows}
      />
    );
  }
  return <FlowContainer />;
}

export default SelectedDeclarationMonth;
