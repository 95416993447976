import { useToggle } from 'rooks';
import { Popover } from '@konta/ui';
import { AnnotationPlusLineIcon } from '@konta/icons';
import FeedbackCard from '@components/FeedbackCard';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import type { TaxableEntity } from 'types/entities';
import type { AxiosResponse } from 'axios';

interface FeedbackSideItemProps {
  taxableEntity?: TaxableEntity;
  isMobile?: boolean;
}
export default function FeedbackSideItem({
  taxableEntity,
  isMobile,
}: FeedbackSideItemProps) {
  const [open, toggleOpen] = useToggle();
  const { spanRef, cardContainerRef } = useOutsideClick({
    open,
    toggleOpen,
  });

  const onSuccessFeedback = (response: AxiosResponse) => {
    if ([200, 201].includes(response?.status)) {
      toggleOpen();
    }
  };

  return (
    <Popover
      open={open}
      withArrow={false}
      css={{ width: isMobile ? '400px' : 'auto', marginLeft: '24px' }}
      triggerElement={
        <span
          ref={spanRef}
          role="button"
          tabIndex={-1}
          className="sidebar-nav-link-container sidebar-text"
          onClick={toggleOpen}
        >
          <AnnotationPlusLineIcon className="sidebar-nav-link-icon" />
          <div className="sidebar-nav-link">Sugerencias</div>
        </span>
      }
    >
      <div ref={cardContainerRef} onClick={(e) => e.stopPropagation()}>
        <FeedbackCard
          onSuccessFeedback={onSuccessFeedback}
          evaluatedId={taxableEntity?.id}
          evaluatedType={taxableEntity?.legal_name}
          subtitle="¿Cómo calificarías el servicio que te ha brindado Konta?"
          withoutCard
          legacySend
        />
      </div>
    </Popover>
  );
}
