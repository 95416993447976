import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { AccumulatedIncomeAndExpense } from 'types/entities';
import { getMonthlyAccumulatedIncomesAndExpenses } from '@api/accumulatedIncomesAndExpenses';
import { MONTHLY_ACCUMULATED_INCOMES_AND_EXPENSES } from '@constants/reactQueries';

export default function useMonthlyAccumulatedIncomesAndExpenses(
  taxableEntityId: number | string,
  axiosConfig?: AxiosRequestConfig<AccumulatedIncomeAndExpense>,
  queryOptions?: UseQueryOptions<AccumulatedIncomeAndExpense>,
) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<AccumulatedIncomeAndExpense>(
    [MONTHLY_ACCUMULATED_INCOMES_AND_EXPENSES, taxableEntityId, axiosConfig],
    async () => {
      const monthlyAccumulatedIncomesAndExpenses =
        await getMonthlyAccumulatedIncomesAndExpenses(
          taxableEntityId,
          axiosConfig,
        );
      return monthlyAccumulatedIncomesAndExpenses;
    },
    queryOptions,
  );

  return {
    monthlyAccumulatedIncomesAndExpensesLoading: isLoading || isFetching,
    monthlyAccumulatedIncomesAndExpenses: data,
    ...rest,
  };
}
