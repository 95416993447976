import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export default function withAuth(Component) {
  return function WithAuth(props) {
    const auth = useSelector(({ authUser }) => authUser.access_token);
    if (auth) {
      return <Component {...props} />;
    }
    return <Navigate to="/login" />;
  };
}
