import React from 'react';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';
import { Button, Text, Card, Flex, CardHeader, CardContent } from '@konta/ui';
import FormikTextarea from '@components/FormikTextarea';
import FeedbackRateSelect from '@components/RateSelect/FeedbackRateSelect';
import postFeedback from '@api/postFeedback';
import { NotificationManager } from '@components';
import getErrorMessage from '@util/getErrorMessage';

const onError = (error) => {
  const message = getErrorMessage(error);
  NotificationManager.error(message, 'Error', 10000);
};

const validationSchema = yup.object().shape({
  comment: yup.string().required('Campo requerido'),
  rating: yup.array().min(1, 'Selecciona al menos una opción'),
});

const initialValues = {
  comment: '',
  rating: [],
};
export default function FeedbackCard({
  subtitle,
  evaluatedType,
  evaluatedId,
  withoutCard,
  legacySend,
  onSuccessFeedback,
}) {
  const newFeedback = useMutation(postFeedback, {
    onSuccess: (data) => {
      if (onSuccessFeedback) {
        onSuccessFeedback(data);
      }
      NotificationManager.success(
        'Apreciamos mucho tus comentarios para seguir creciendo 💚 ',
        'Muchas gracias',
        3000,
      );
    },
    onError,
  });

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      newFeedback.mutate({
        ...values,
        rating: values.rating[0],
        evaluatedId,
        evaluatedType,
        legacySend,
      });
    },
  });

  if (withoutCard) {
    return (
      <Flex gap="12" direction="column">
        <Flex column>
          <Text xl>Ayúdanos a mejorar</Text>
          <Text m color="gray500">
            {subtitle}
          </Text>
        </Flex>
        <Flex direction="column" gap={6} css={{ flex: 1 }}>
          <FeedbackRateSelect
            setRating={(ratings) => formik.setFieldValue('rating', ratings)}
            rating={formik.values.rating}
          />
          {!!formik.errors.rating && (
            <Text xs lineHeight="xs" color="error500">
              {formik.errors.rating}
            </Text>
          )}
        </Flex>
        <FormikProvider value={formik}>
          <FormikTextarea
            name="comment"
            rows="7"
            label="¿Cuáles son las principales razones de tu calificación?"
          />
          <Flex align="end" justify="end">
            <div>
              <Button
                color="primary"
                onClick={formik.submitForm}
                loading={newFeedback.isLoading}
              >
                Calificar
              </Button>
            </div>
          </Flex>
        </FormikProvider>
      </Flex>
    );
  }
  return (
    <Flex>
      <Card outlined>
        <CardHeader
          css={{
            borderBottom: '1px solid $colors$gray300',
            padding: '16px 20px',
          }}
          header={<Text l>Ayúdanos a mejorar</Text>}
          subheader={
            <Text color="gray500" s>
              {subtitle}
            </Text>
          }
        />
        <CardContent>
          <Flex gap="10" direction="column">
            <Flex direction="column" gap={6} css={{ flex: 1 }}>
              <FeedbackRateSelect
                setRating={(ratings) => formik.setFieldValue('rating', ratings)}
                rating={formik.values.rating}
              />
              {!!formik.errors.rating && (
                <Text xs lineHeight="xs" color="error500">
                  {formik.errors.rating}
                </Text>
              )}
            </Flex>
            <FormikProvider value={formik}>
              <FormikTextarea
                name="comment"
                rows="7"
                label="¿Cuáles son las principales razones de tu calificación?"
              />
              <Flex align="end" justify="end">
                <div>
                  <Button
                    color="primary"
                    onClick={formik.submitForm}
                    loading={newFeedback.isLoading}
                  >
                    Calificar
                  </Button>
                </div>
              </Flex>
            </FormikProvider>
          </Flex>
        </CardContent>
      </Card>
    </Flex>
  );
}
