import React, { useEffect } from 'react';
// import PropTypes from 'prop-types'
import { Input } from 'reactstrap';

function TableFilters({
  data,
  placeholder,
  className,
  name,
  handleSetFilteredData,
}) {
  const [searchInput, setSearchInput] = React.useState('');
  // filter data every searchInput change
  useEffect(() => filterData(), [searchInput, data]);
  const handleChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filterData = () => {
    handleSetFilteredData(data, searchInput.toLowerCase());
  };

  return (
    <Input
      name={name}
      value={searchInput}
      onChange={handleChange}
      placeholder={placeholder}
      className={className}
    />
  );
}

export default TableFilters;
