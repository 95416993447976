import api from '@api';
import { call, select, put } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import { NotificationManager } from '@components/Notifications';

import { payOrderSuccess, payOrderFailure, clearCart } from '@redux/actions';

export default function* payOrderSaga({
  payload: { order_id, navigate, callback },
}) {
  try {
    const { ui } = yield select((state) => state.paymentMethods);
    if (ui.selected_card) {
      const access_token = localStorage.getItem('user_id');
      const response = yield call(
        api.post,
        `orders/${order_id}/pay`,
        {
          card_id: ui.selected_card.id,
        },
        {
          headers: { Authorization: `${access_token}` },
        },
      );
      if (callback) yield callback();
      yield call(StatusHandler, {
        response,
        title: 'Pago Realizado',
        message: 'El pago fue realizado con éxito',
        status: response.status,
        success: payOrderSuccess,
        failure: payOrderFailure,
      });
      navigate('/app/pendientes');
    } else {
      // TODO Pass this validation to component
      NotificationManager.primary(
        'Selecciona una tarjeta antes de pagar',
        'Upss...',
        3000,
        null,
        null,
      );
    }
  } catch (error) {
    if (error.response.status === 500) {
      yield put(clearCart());
    } else {
      yield call(ErrorHandler, {
        error,
        action: payOrderFailure,
        showBodyErrors: true,
      });
    }
  }
}
