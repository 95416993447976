import dayjs from 'dayjs';
import { Filters } from '@konta/ui';

const currentDay = dayjs();
const oneMonthAgo = currentDay.subtract(1, 'month');

const filtersList: Filters = [
  {
    id: 'legalName',
    label: 'Razón social',
    type: 'text',
    value: '',
  },
  {
    id: 'rfc',
    label: 'Rfc',
    type: 'text',
    value: '',
  },
  {
    id: 'issuedDates',
    label: 'Rango de fecha',
    type: 'dateRange',
    value: [oneMonthAgo.toString(), currentDay.toString()],
  },
];

export default filtersList;
