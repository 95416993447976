import { useCallback } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { postDeclarationEntriesCreate } from 'shared/api/declarationEntries';
import type { DeclarationEntry } from 'types/entities';
import type { AxiosRequestConfig } from 'axios';

export default function usePostDeclarationEntries(
  axiosConfig?: AxiosRequestConfig<DeclarationEntry>,
  queryOptions?: UseMutationOptions<DeclarationEntry, unknown, FormData>,
) {
  const postDeclarationEntriesRequest = useCallback(
    (payload: FormData) => postDeclarationEntriesCreate(payload, axiosConfig),
    [axiosConfig],
  );
  return useMutation(postDeclarationEntriesRequest, queryOptions);
}
