import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { cancelCFDISuccess, cancelCFDILFailure } from '../actions';

export default function* ({
  payload: { cfdi_id, cancellation_reason, callback },
}) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `/cfdis/${cfdi_id}/cancel`,
      {
        cfdi: {
          reason: cancellation_reason.reason,
          replacement_uuid: cancellation_reason.replacement_uuid,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: access_token,
        },
      },
    );

    if (callback) yield callback();
    yield call(StatusHandler, {
      response,
      title: 'Factura cancelada',
      message: 'La factura fue cancelada con éxito.',
      status: response.status,
      success: cancelCFDISuccess,
      failure: cancelCFDILFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      message: 'Verifique que los datos de su factura sean correctos.',
      action: cancelCFDILFailure,
      showBodyErrors: true,
    });
  }
}
