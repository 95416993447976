import { useEffect } from 'react';
import useCreateInvoiceDraft from '@hooks/useCreateInvoiceDraft';
import Loader from '../Loader';

export default function RedirectNewInvoice() {
  const { createInvoiceDraft, loadingCreateInvoiceDraft } =
    useCreateInvoiceDraft();

  useEffect(() => {
    void createInvoiceDraft();
  }, []);

  if (loadingCreateInvoiceDraft) {
    return <Loader />;
  }

  return null;
}
