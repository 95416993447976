import React from 'react';
import { Flex, Text, Divider } from '@konta/ui';
import toCurrency from '@util/toCurrency';

export default function InvoiceTotalTable({ invoice }) {
  const iva = invoice?.formik?.values?.iva?.amount || 0;
  const isr = invoice?.formik?.values?.isr?.amount || 0;
  const retainedIva = invoice?.formik?.values?.retainedIva?.amount || 0;
  const discount = +(invoice?.formik?.values?.discount || 0);
  const subtotal = +(invoice?.formik?.values?.subtotal || 0);
  const totalBeforeTaxes = subtotal - discount;

  return (
    <Flex direction="column" css={{ width: '320px', mb: '$12' }}>
      <Flex justify="between" align="center">
        <Text color="gray900" xs>
          Subtotal
        </Text>
        <Text color="gray900" xs>
          {toCurrency(subtotal)} MXN
        </Text>
      </Flex>
      {invoice.formik.values.discount > 0 && (
        <>
          <Divider css={{ mt: '4px', mb: '4px' }} />
          <Flex justify="between" align="center">
            <Text color="gray900" xs>
              Descuento
            </Text>
            <Text color="gray900" xs>
              -{toCurrency(discount)} MXN
            </Text>
          </Flex>
        </>
      )}
      <Divider css={{ my: '4px' }} />
      <Flex justify="between" align="center">
        <Text color="gray900" xs>
          Total antes de impuestos
        </Text>
        <Text color="gray900" xs>
          {toCurrency(totalBeforeTaxes)} MXN
        </Text>
      </Flex>
      <Divider css={{ my: '4px' }} />

      <Flex justify="between" align="center">
        <Text color="gray900" xs>
          IVA trasladado
        </Text>
        <Text color="gray900" xs>
          {toCurrency(iva)} MXN
        </Text>
      </Flex>
      {isr > 0 && (
        <>
          <Divider css={{ my: '4px' }} />
          <Flex justify="between" align="center">
            <Text color="gray900" xs>
              ISR retenido
            </Text>
            <Text color="gray900" xs>
              -{toCurrency(isr)} MXN
            </Text>
          </Flex>
        </>
      )}
      {retainedIva > 0 && (
        <>
          <Divider css={{ my: '4px' }} />
          <Flex justify="between" align="center">
            <Text color="gray900" xs>
              IVA retenido
            </Text>
            <Text color="gray900" xs>
              -{toCurrency(retainedIva)} MXN
            </Text>
          </Flex>
        </>
      )}
      <Divider />
      <Flex justify="between" align="center">
        <Text color="gray900" bold xs>
          Total
        </Text>
        <Text color="gray900" bold xs>
          {toCurrency(invoice.formik.values?.total)} MXN
        </Text>
      </Flex>
      <Divider css={{ my: '4px' }} />
    </Flex>
  );
}
