import { PreviewPDFModal } from '@components/Utils';
import TotalsButton from '@components/TotalsButton';
import { Root } from './styled';
import useTotalsButtons from './useTotalsButtons';

export default function TotalsButtons() {
  const { declarationsIncomeTableTotals, previewData, closePreview } =
    useTotalsButtons();
  return (
    <Root>
      {!!previewData && (
        <PreviewPDFModal
          showPreview={!!previewData}
          toggle={closePreview}
          cfdi={previewData}
          isWorkflowPublicInvoice
          title="Vista Previa"
        />
      )}
      {declarationsIncomeTableTotals.map(({ total, regimeName, totals }) => (
        <TotalsButton
          key={regimeName}
          regime={regimeName}
          totals={totals}
          total={total}
          isPreviewOpen={!!previewData}
        />
      ))}
    </Root>
  );
}
