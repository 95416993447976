import { AxiosRequestConfig } from 'axios';
import { AccumulatedIncomeAndExpense } from 'types/entities';
import api from './index';

export const getAnnualAccumulatedIncomesAndExpenses = async (
  taxableEntityId: number | string,
  config?: AxiosRequestConfig<AccumulatedIncomeAndExpense>,
) => {
  const { data } = await api.get<AccumulatedIncomeAndExpense>(
    `annual_accumulated_incomes_and_expenses/${taxableEntityId}`,
    config,
  );
  return data;
};

export const getMonthlyAccumulatedIncomesAndExpenses = async (
  taxableEntityId: number | string,
  config?: AxiosRequestConfig<AccumulatedIncomeAndExpense>,
) => {
  const { data } = await api.get<AccumulatedIncomeAndExpense>(
    `monthly_accumulated_incomes_and_expenses/${taxableEntityId}`,
    config,
  );
  return data;
};
