import { useState, useRef } from 'react';
import { Socket } from 'socket.io-client';
import ChatSidebar from './ChatSidebar/ChatSidebar';
import ChatBody from './ChatBody';
import ChatFooter from './ChatFooter';
import ChatHeader from './ChatHeader';
import {
  ChatContainer,
  ChatMain,
  ChatMessagesContainer,
  ChatMessagesWrapper,
  Root,
} from './styled';

export default function Chat() {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  return (
    <Root>
      <ChatContainer>
        <ChatSidebar />
        <ChatMain>
          <ChatHeader />
          <ChatMessagesWrapper>
            <ChatMessagesContainer>
              <ChatBody messages={messages} lastMessageRef={lastMessageRef} />
              <ChatFooter socket={socket} />
            </ChatMessagesContainer>
          </ChatMessagesWrapper>
        </ChatMain>
      </ChatContainer>
    </Root>
  );
}
