import { isEqual } from 'lodash';
import { useMemo, useRef, useState } from 'react';

export default function useShallowMemo<Type>(
  factory: () => Type,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shallowDeps: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: any[] = [],
): Type {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [memShallowDeps, setShallowMemDeps] = useState<any[] | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [memDeps, setMemDeps] = useState<any[] | undefined>();

  const valueRef = useRef<Type | null>(null);

  const value: Type = useMemo<Type>(() => {
    const changedDeps =
      !memDeps || deps.some((dep, index) => dep !== memDeps[index]);
    const changedShallowDeps =
      !memShallowDeps || !isEqual(memShallowDeps, shallowDeps);

    if (changedShallowDeps || changedDeps) {
      setShallowMemDeps(shallowDeps);
      setMemDeps(deps);
      valueRef.current = factory();
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      return valueRef.current as Type;
    }

    return valueRef.current as Type;
    // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-unsafe-assignment
  }, [...shallowDeps, ...deps]);

  return value;
}
