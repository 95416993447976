import React, { useState, Fragment, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Row,
  Card,
  CardTitle,
  CardBody,
  Button,
  Collapse,
  Label,
} from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvField,
  AvInput,
} from 'availity-reactstrap-validation';
import { NotificationManager } from '@components/Notifications';
import {
  contactMethodFetch,
  contactMethodDelete,
  contactMethodPost,
  contactMethodUpdate,
} from '@redux/actions';
import IntlTelInput from 'react-intl-tel-input';
import { Colxx, IconButton, IconConfirmModal } from '@components';
import 'react-intl-tel-input/dist/main.css';

function ContactMethodsItem({
  id,
  contactType,
  contactId,
  defaultItem,
  defaultItemUpdate,
}) {
  const dispatch = useDispatch();
  const [editContactMethod, setEditContactMethod] = useState(false);
  const [phoneNumberEdit, setPhoneNumberEdit] = useState({
    phone_number: null,
    error: false,
  });
  useEffect(() => {
    dispatch(contactMethodFetch());
  }, [defaultItemUpdate]);

  const deleteItem = () => {
    dispatch(contactMethodDelete(id));
  };

  const toggleEdit = () => {
    setEditContactMethod(!editContactMethod);
  };
  const handleEditChangePhone = (isValid, data, rawNumber, formattedNumber) => {
    if (!isValid) {
      setPhoneNumberEdit({
        phoneNumber: formattedNumber,
        error: true,
      });
    } else {
      setPhoneNumberEdit({
        error: false,
        phone_number: formattedNumber,
      });
    }
  };
  const handlePhoneSubmit = (event, errors, values) => {
    if (
      errors.length === 0 &&
      phoneNumberEdit.phone_number &&
      !phoneNumberEdit.error
    ) {
      const contact_method_update = {
        id,
        contact_method: {
          contact_type: contactType,
          contact_id: phoneNumberEdit.phone_number,
          primary: values.primary,
        },
      };

      dispatch(contactMethodUpdate(contact_method_update));
      toggleEdit();
    } else {
      NotificationManager.error(
        'Verifique que los datos sean correctos e intente de nuevo.',
        'Upss..',
        6000,
        null,
        null,
        null
      );
    }
  };
  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      const contact_method_update = {
        id,
        contact_method: {
          contact_type: contactType,
          contact_id: values.email
            ? values.email
            : values.phone_number
            ? values.phone_number
            : contactId,
          primary: values.primary,
        },
      };

      dispatch(contactMethodUpdate(contact_method_update));
      toggleEdit();
    } else {
      NotificationManager.error(
        'Verifique que los datos sean correctos e intente de nuevo.',
        'Upss..',
        6000,
        null,
        null,
        null
      );
    }
  };

  return (
    <>
      {!editContactMethod && (
        <div className="clearfix">
          <div className="float-left mt-1 ">
            <p className="ml-1">{contactId}</p>
          </div>

          <div className="float-right">
            <IconButton
              id={`edit-icon-${id}`}
              tooltipHelper={`${
                contactType === 'email'
                  ? 'Editar email'
                  : contactType === 'phone_number'
                  ? 'Editar teléfono'
                  : ''
              }`}
              hover="primary"
              iconName="simple-icon-pencil"
              className=""
              size="18px"
              handleClick={toggleEdit}
            />
          </div>
          <div className="float-right mr-3">
            {!defaultItem && (
              <IconConfirmModal
                cancelButton="Cancelar"
                confirmButton="Confirmar"
                iconId={`delete-icon-${id}`}
                iconTooltipHelper={`${
                  contactType === 'email'
                    ? 'Eliminar email'
                    : contactType === 'phone_number'
                    ? 'Eliminar teléfono'
                    : ''
                }`}
                iconHover="danger"
                handleSubmit={deleteItem}
                iconName="simple-icon-trash"
                iconClassName="ml-2"
                size="18px"
              >
                <i className="large-icon iconsmind-Danger" color="danger" />
                <p>
                  ¡Estás a punto borrar <strong>{contactId}</strong> de tu lista
                  de{' '}
                  {contactType === 'email'
                    ? 'emails'
                    : contactType === 'phone_number'
                    ? 'teléfonos'
                    : ''}
                  !
                </p>
              </IconConfirmModal>
            )}
          </div>
          <div className="float-right mr-3">
            {defaultItem && (
              <IconButton
                id={`star-icon-${id}`}
                tooltipHelper={`${
                  contactType === 'email'
                    ? 'Email principal'
                    : contactType === 'phone_number'
                    ? 'Teléfono principal'
                    : ''
                }`}
                iconName="simple-icon-star"
                hover=""
                className="simple-icon-star-outline"
                size="18px"
              />
            )}
          </div>
        </div>
      )}
      {editContactMethod &&
        (contactType === 'email' ? (
          <AvForm onSubmit={handleSubmit} className="has-float-label mb-5">
            <Row className="mt-4">
              <Colxx xxs={12}>
                <AvGroup>
                  <AvField
                    label="Edita el email"
                    type="email"
                    className="contactMethod__edit_input"
                    name={contactType}
                    value={contactId}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Verifica bien los datos',
                      },
                    }}
                  />
                </AvGroup>
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs={6}>
                {!defaultItem && (
                  <AvGroup check className="checkbox-form">
                    <AvInput
                      type="checkbox"
                      value={defaultItem}
                      name="primary"
                    />{' '}
                    <span>Definir como primario</span>
                  </AvGroup>
                )}
              </Colxx>
              <Colxx
                xxs={6}
                className="align-content-center justify-content-end "
              >
                <Button color="primary" size="xs" className="mb-1 mr-2 mt-1">
                  Guardar
                </Button>
                <Button
                  type="button"
                  color="danger"
                  size="xs"
                  className="mb-1 mt-1"
                  onClick={toggleEdit}
                >
                  Cancelar
                </Button>
              </Colxx>
            </Row>
          </AvForm>
        ) : (
          <AvForm onSubmit={handlePhoneSubmit} className="has-float-label mb-5">
            <Row className="mt-4">
              <Colxx xxs={12}>
                <AvGroup>
                  <Label htmlFor="phone_number">Escribe el teléfono</Label>
                  <IntlTelInput
                    country="mx"
                    containerClassName="intl-tel-input contactMethod__edit_input"
                    inputClassName=" form-control"
                    onPhoneNumberChange={handleEditChangePhone}
                    defaultValue={contactId}
                    placeholder={contactId}
                    onPhoneNumberBlur={handleEditChangePhone}
                    defaultCountry="mx"
                    preferredCountries={['mx']}
                  />
                </AvGroup>
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs={6}>
                {!defaultItem && (
                  <AvGroup check>
                    <AvInput
                      type="checkbox"
                      value={defaultItem}
                      name="primary"
                    />
                    <span>Definir como primario</span>
                  </AvGroup>
                )}
              </Colxx>
              <Colxx
                xxs={6}
                className="align-content-center justify-content-end "
              >
                <Button color="primary" size="xs" className="mb-1 mr-2 mt-1">
                  Guardar
                </Button>
                <Button
                  type="button"
                  color="danger"
                  size="xs"
                  className="mb-1 mt-1"
                  onClick={toggleEdit}
                >
                  Cancelar
                </Button>
              </Colxx>
            </Row>
          </AvForm>
        ))}
    </>
  );
}

function ContactMethods(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(contactMethodFetch());
  }, []);

  const [contactMethodUpdate, setContactMethodUpdate] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState({
    phone_number: null,
    error: false,
  });

  const handleChangePhone = (isValid, data, rawNumber, formattedNumber) => {
    if (!isValid) {
      setPhoneNumber({
        phoneNumber: formattedNumber,
        error: true,
      });
    } else {
      setPhoneNumber({
        error: false,
        phone_number: formattedNumber,
      });
    }
  };

  const [collapse, setCollapse] = useState({
    addEmail: false,
    addPhone: false,
  });

  const toggleAddEmail = () => {
    setCollapse({
      ...collapse,
      addEmail: !collapse.addEmail,
    });
  };

  const toggleAddPhone = () => {
    setCollapse({
      ...collapse,
      addPhone: !collapse.addPhone,
    });
  };
  const handlePhoneSubmit = (event, errors, values) => {
    if (errors.length === 0 && phoneNumber.phone_number && !phoneNumber.error) {
      const contact_method = {
        contact_type: 'phone_number',
        contact_id: phoneNumber.phone_number,
        primary: values.primary,
      };
      dispatch(contactMethodPost(contact_method));
      toggleAddPhone();
    } else {
      NotificationManager.error(
        'Verifique que los datos sean correctos e intente de nuevo.',
        'Upss..',
        6000,
        null,
        null,
        null
      );
    }
  };
  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      const contact_method = {
        contact_type: 'email',
        contact_id: values.email ? values.email : phoneNumber,
        primary: values.primary,
      };
      dispatch(contactMethodPost(contact_method));
      toggleAddEmail();
    } else {
      NotificationManager.error(
        'Verifique que los datos sean correctos e intente de nuevo.',
        'Upss..',
        6000,
        null,
        null,
        null
      );
    }
  };

  return (
    <Card data-test="component-contact_methods">
      <CardBody>
        <CardTitle data-test="contact_methods-cardTitle" tag="h4">
          Métodos de contacto
        </CardTitle>
        <h5 data-test="contact_methods-title">Email</h5>
        {props.contact_methods.length === 0 && (
          <h4>No hay emails agregados </h4>
        )}
        {props.contact_methods
          .filter((item) => item.contact_type === 'email')
          .map((item) => (
            <ContactMethodsItem
              data-test="contact_method-item"
              itemUpdate={setContactMethodUpdate}
              defaultItemUpdate={contactMethodUpdate}
              key={item.id}
              id={item.id}
              defaultItem={item.primary}
              contactType={item.contact_type}
              contactId={item.contact_id}
            />
          ))}
        <button
          data-test="add_contact_method-button"
          className="btn btn-link"
          onClick={toggleAddEmail}
        >
          + Agregar email
        </button>
        <Collapse isOpen={collapse.addEmail}>
          <AvForm
            data-test="contact_method-form"
            className="has-float-label"
            onSubmit={handleSubmit}
          >
            <Row className="mt-4">
              <Colxx xxs={12} sm={6} lg={12} xl={6} xxl={8}>
                <AvGroup>
                  <AvField
                    data-test="form-input"
                    label="Escribe el email"
                    type="email"
                    name="email"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Escribe un email válido',
                      },
                    }}
                  />
                </AvGroup>
              </Colxx>
              <Colxx xxs={6} xs={5} sm={3} lg={6} xl={3} xxl={2}>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      data-test="form-checkbox"
                      className="mt-3"
                      type="checkbox"
                      name="primary"
                    />
                    <p className="mt-2">
                      Definir como <br /> primario
                    </p>
                  </Label>
                </AvGroup>
              </Colxx>
              <Colxx xxs={6} xs={7} sm={3} lg={6} xl={3} xxl={2}>
                <Button
                  data-test="form-button"
                  className="mr-4"
                  color="primary"
                  size="sm"
                >
                  Agregar
                </Button>
              </Colxx>
            </Row>
          </AvForm>
        </Collapse>
        <hr />
        <h5 data-test="contact_methods-title">Whatsapp</h5>
        {props.contact_methods.length === 0 && (
          <h4>No hay teléfonos agregados</h4>
        )}
        {props.contact_methods
          .filter((item) => item.contact_type === 'phone_number')
          .map((item) => (
            <ContactMethodsItem
              data-test="contact_method-item"
              defaultItemUpdate={contactMethodUpdate}
              key={item.id}
              id={item.id}
              defaultItem={item.primary}
              contactType={item.contact_type}
              contactId={item.contact_id}
            />
          ))}
        <button
          data-test="add_contact_method-button"
          className="btn btn-link"
          onClick={toggleAddPhone}
        >
          + Agregar teléfono
        </button>
        <Collapse isOpen={collapse.addPhone}>
          <AvForm
            data-test="contact_method-form"
            className="has-float-label"
            onSubmit={handlePhoneSubmit}
          >
            <Row className="mt-4">
              <Colxx xxs={12} sm={6} lg={12} xl={6} xxl={8}>
                <AvGroup>
                  <Label htmlFor="phone_number">Escribe el teléfono</Label>
                  <IntlTelInput
                    country="mx"
                    inputClassName="form-control"
                    onPhoneNumberChange={handleChangePhone}
                    onPhoneNumberBlur={handleChangePhone}
                    defaultCountry="mx"
                    preferredCountries={['mx']}
                  />
                </AvGroup>
              </Colxx>
              <Colxx xxs={6} xs={5} sm={3} lg={6} xl={3} xxl={2}>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      data-test="form-checkbox"
                      className="mt-3"
                      type="checkbox"
                      name="primary"
                    />{' '}
                    <p className="mt-2">Definir como primario</p>
                  </Label>
                </AvGroup>
              </Colxx>
              <Colxx
                xxs={6}
                xs={7}
                sm={3}
                lg={6}
                xl={3}
                xxl={2}
                className="align-content-center justify-content-center"
              >
                <Button
                  data-test="form-button"
                  className="mr-4"
                  color="primary"
                  size="sm"
                >
                  Agregar
                </Button>
              </Colxx>
            </Row>
          </AvForm>
        </Collapse>
      </CardBody>
    </Card>
  );
}

const mapStateToProps = ({ contact_methods }) => {
  return contact_methods;
};

export default connect(mapStateToProps, {
  contactMethodFetch,
  contactMethodDelete,
  contactMethodPost,
  contactMethodUpdate,
})(ContactMethods);
