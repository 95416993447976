import { UseQueryOptions, useQuery } from 'react-query';
import { Cfdi } from 'types/entities';
import { AxiosRequestConfig } from 'axios';
import getCfdisByClients from 'shared/api/getCfdisByClients';

export interface UseCfdisOptions {
  startDate: string;
  endDate: string;
  queryOptions?: UseQueryOptions<Cfdi[]>;
  axiosConfig?: AxiosRequestConfig<Cfdi[]>;
}

export default function useCfdisByClients({
  startDate,
  endDate,
  axiosConfig,
  queryOptions,
}: UseCfdisOptions) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<Cfdi[]>(
    [`${startDate}`],
    async () => {
      const cfdis = await getCfdisByClients({
        startDate,
        endDate,
        axiosConfig,
      });
      return cfdis;
    },
    queryOptions,
  );

  return {
    cfdisLoading: isLoading || isFetching,
    cfdis: data,
    ...rest,
  };
}
