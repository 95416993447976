import { getSessionsCanny } from '@api/canny';
import { getZendesk } from '@api/zendesk';

// eslint-disable-next-line import/prefer-default-export
export const getApiConfigSSO = (
  source: string,
  params: Record<string, string> = {},
) => {
  const config = {
    params,
  };
  if (source === 'canny') {
    return {
      get: getSessionsCanny,
      config,
    };
  }
  if (source === 'zendesk') {
    return {
      get: getZendesk,
      config,
    };
  }
  return null;
};
