import dayjs from 'dayjs';
import {
  BIMONTHLY_PERIOD_OPTIONS,
  MONTHLY_PERIOD_OPTIONS,
} from '@constants/invoicing';

export default function getInitialMonthByPeriodicity(periodicity) {
  const currentMonth = dayjs().month() + 1;
  // bimonthly
  if (periodicity === '05') {
    const index = 12 + Math.ceil(currentMonth / 2);
    return BIMONTHLY_PERIOD_OPTIONS.find((item) => item.key === index);
  }

  return MONTHLY_PERIOD_OPTIONS.find((item) => item.key === currentMonth);
}
