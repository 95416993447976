import { useMemo, useState } from 'react';
import { EyeLineIcon } from '@konta/icons';
import { Button, Chip, Flex, Text, Tooltip } from '@konta/ui';
import type { ItemWithHover } from '@konta/ui/dist/components/DataTable/types';
import { ColumnDef } from '@tanstack/react-table';
import toCurrency from '@util/toCurrency';
import dayjs from 'dayjs';
import { useToggle } from 'rooks';
import { Cfdi } from 'types/entities';

export interface CfdisCollapseTableProps {
  invoiceType: 'incomes' | 'expenses';
  cfdis: Cfdi[];
}

const CFDI_STATUS_OPTIONS = [
  { value: 'stamped', label: 'Timbrada' },
  { value: 'cancelled', label: 'Cancelada' },
  { value: 'not_accepted', label: 'Rechazada' },
  { value: 'acceptance_pending', label: 'Pendiente de aceptación' },
  { value: 'cancellation_in_progress', label: 'Cancelación en proceso' },
  { value: 'pending', label: 'Pendiente' },
];

export type CfdiStatus = string;
const cfdiStatus: Record<CfdiStatus, [color: string, contrastColor: string]> = {
  stamped: ['#ECFDF3', '#027A48'],
  cancelled: ['#FEF3F2', '#B42318'],
  not_accepted: ['#FEF3F2', '#B42318'],
  acceptance_pending: ['#F8F9FC', '#363F72'],
  cancellation_in_progress: ['#F8F9FC', '#363F72'],
  pending: ['#FEF6EE', '#B93815'],
};
const cfdiStatusName: Record<CfdiStatus, string> = {
  stamped: 'Timbrado',
  cancelled: 'Cancelado',
  not_accepted: 'Rechazada',
  acceptance_pending: 'Pendiente de aceptación',
  cancellation_in_progress: 'Cancelación en proceso',
  pending: 'Pendiente',
};

const cfdiType: Record<string, [color: string, contrastColor: string]> = {
  payment: ['#ECFDF3', '#027A48'],
  expenses: ['#FEF3F2', '#B42318'],
  income: ['#FEF3F2', '#B42318'],
  payroll: ['#F8F9FC', '#363F72'],
};

const CFDI_TYPE_OPTIONS = [
  {
    label: 'PUE',
    value: true,
  },
  {
    label: 'PPD',
    value: false,
  },
];

const cfdiStatusCancelledList = [
  'cancelled',
  'not_accepted',
  'acceptance_pending',
  'cancellation_in_progress',
  'pending',
];

export default function useCfdisCollapseTable({
  invoiceType,
  cfdis,
}: CfdisCollapseTableProps) {
  const [showEntriesTable, toggleShowEntriesTable] = useToggle();
  const [cfdiModalObject, setCfdiModalObject] = useState<Cfdi>({} as Cfdi);
  const [togglePdf, setTogglePdf] = useToggle(false);
  const [itemHovered, setItemHovered] = useState<ItemWithHover<Cfdi>>(null);

  const defaultColumns = useMemo<ColumnDef<Cfdi>[]>(
    () => [
      {
        header: () => (
          <Text
            css={{
              fontWeight: 500,
            }}
          >
            FECHA DE EMISIÓN
          </Text>
        ),
        label: 'Fecha de emisión',
        accessorKey: 'date_issued',
        id: 'date_issued',
        size: 290,
        cell: ({ row }) => (
          <Text
            color="gray500"
            css={{
              fontWeight: 400,
            }}
          >
            {dayjs(row.original.date_issued).format('DD MMM').toUpperCase()}
          </Text>
        ),
      },
      {
        header: () => (
          <Text
            css={{
              fontWeight: 500,
            }}
          >
            RFC
          </Text>
        ),
        label: 'EMISOR',
        accessorKey: 'issuer_legal_name',
        id: 'issuer_legal_name',
        size: 380,
        cell: ({ row }) => (
          <Text
            color="gray500"
            css={{
              fontWeight: 400,
            }}
          >
            {invoiceType === 'incomes'
              ? row.original.receiver_legal_name || ''
              : row.original.issuer_legal_name || ''}
          </Text>
        ),
      },
      {
        header: () => (
          <Text
            css={{
              fontWeight: 500,
            }}
          >
            Tipo de factura
          </Text>
        ),
        label: 'Tipo de factura',
        accessorKey: 'is_pue',
        id: 'is_pue',
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: CFDI_TYPE_OPTIONS,
        },
        cell: ({ row }) => (
          <Chip
            color="gray500"
            size="s"
            label={row.original.is_pue ? 'PUE' : 'PPD'}
          />
        ),
      },
      {
        header: () => (
          <Text
            css={{
              fontWeight: 500,
            }}
          >
            Estatus
          </Text>
        ),
        label: 'Estatus',
        accessorKey: 'status',
        id: 'status',
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: CFDI_STATUS_OPTIONS,
        },
        cell: ({ row }) => {
          const [color, contrastColor] = cfdiStatus[row.original.status];
          return (
            <Chip
              css={{ '--color': color, '--contrastColor': contrastColor }}
              size="s"
              label={cfdiStatusName[row.original.status]}
            />
          );
        },
      },
      {
        header: '',
        label: 'Acciones',
        accessorKey: 'actions',
        id: 'actions',
        cell: ({ row }) => {
          if (itemHovered?.index !== row.index) return null;

          return (
            <Flex itemsCenter gap={4}>
              <Tooltip
                delayduration={0}
                size="xs"
                triggerElement={
                  <Button
                    size="xs"
                    variant="outlined"
                    icon
                    noFill
                    onClick={() => {
                      setCfdiModalObject(row.original);
                      setTogglePdf();
                    }}
                  >
                    <EyeLineIcon color="#667085" />
                  </Button>
                }
              >
                Ver PDF de la factura
              </Tooltip>
            </Flex>
          );
        },
      },
      {
        header: () => (
          <Text
            css={{
              fontWeight: 500,
            }}
          >
            Subtotal (Sin impuestos)
          </Text>
        ),
        label: 'Subtotal',
        size: 200,
        accessorKey: 'subtotal',
        id: 'subtotal',
        cell: ({ row }) => (
          <Text
            color="gray500"
            css={{
              fontWeight: 400,
              textAlign: 'right',
              width: '100%',
            }}
          >
            {toCurrency(row.original.subtotal || 0)}
          </Text>
        ),
      },
      {
        header: () => (
          <Text
            css={{
              fontWeight: 500,
            }}
          >
            Total (Con impuestos)
          </Text>
        ),
        size: 200,
        label: 'Total',
        accessorKey: 'total',
        id: 'total',
        cell: ({ row }) => (
          <Text
            color="gray500"
            css={{
              fontWeight: 400,
              textAlign: 'right',
              width: '100%',
            }}
          >
            {toCurrency(row.original.total || 0)}
          </Text>
        ),
      },
    ],
    [invoiceType, setTogglePdf, itemHovered?.index],
  );

  const totals = useMemo(
    () =>
      cfdis?.reduce((acc, cfdi) => {
        if (!cfdi.is_pue || cfdiStatusCancelledList.includes(cfdi.status)) {
          return acc + 0;
        }
        return acc + cfdi.total;
      }, 0),
    [cfdis],
  );

  return {
    showEntriesTable,
    toggleShowEntriesTable,
    cfdiModalObject,
    setCfdiModalObject,
    togglePdf,
    setTogglePdf,
    defaultColumns,
    totals,
    itemHovered,
    setItemHovered,
  };
}
