import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import axios from 'axios';
import {
  fetchPaymentHistorySuccess,
  fetchPaymentHistoryFailure,
} from '../actions';

export default function* () {
  try {
    const access_token = localStorage.getItem('user_id');
    /* const response = yield call(
      api.get,
      `taxable_entities/${taxable_entity.id}/products`,
      { headers: { Authorization: `${access_token}` } }
    ); */
    const response = yield call(
      axios.get,
      `https://8cc722f5-8fac-4f5e-8870-84bbe6bc56c7.mock.pstmn.io/api/v1/subscriptions/history`,
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: fetchPaymentHistorySuccess,
      failure: fetchPaymentHistoryFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: fetchPaymentHistoryFailure,
    });
  }
}
