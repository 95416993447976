import { Modal as ModalComponent } from 'reactstrap';
import { Button } from '@konta/ui';
import { XCloseLineIcon } from '@konta/icons';
import {
  ActionsWrapper,
  CloseIconWrapper,
  Content,
  Subtitle,
  Title,
  TitleSection,
} from './styled';
import SuccessBannerImg from './success-banner-img.png';

interface NotificationModalProps {
  modalSize?: 'sm' | 'lg' | 'xl';
  isOpen: boolean;
  toggleIsOpen: () => void;
  centered?: boolean;
  title?: string;
  subtitle?: string;
  btnText: string;
  type: 'success' | 'error';
}
export default function NotificationModal({
  title,
  subtitle,
  modalSize = 'lg',
  isOpen,
  toggleIsOpen,
  centered = true,
  btnText = 'Aceptar',
  type,
}: NotificationModalProps) {
  return (
    <ModalComponent
      isOpen={isOpen}
      toggle={toggleIsOpen}
      centered={centered}
      height="full"
      size={modalSize}
      wrapClassName="modal-new-design"
    >
      <CloseIconWrapper onClick={toggleIsOpen}>
        <XCloseLineIcon color="#667085" />
      </CloseIconWrapper>
      {type === 'success' && (
        <img alt="success-banner-img" src={SuccessBannerImg} />
      )}
      <Content>
        {title && (
          <TitleSection>
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </TitleSection>
        )}
      </Content>
      <ActionsWrapper>
        <Button variant="outlined" onClick={toggleIsOpen}>
          {btnText}
        </Button>
      </ActionsWrapper>
    </ModalComponent>
  );
}
