import api from '@api';
import axios from 'axios';

interface PostFeedbackProps {
  comment: string;
  rating: number;
  evaluatedType: string;
  evaluatedId: number;
  legacySend: boolean;
}

export default async function postFeedback({
  comment,
  rating,
  evaluatedType,
  evaluatedId,
  legacySend,
}: PostFeedbackProps) {
  if (legacySend) {
    const response =
      process.env.REACT_APP_ENV === 'PRODUCTION' &&
      (await axios.post(
        `https://hooks.zapier.com/hooks/catch/6665294/okyt3xa/silent/`,
        JSON.stringify({
          comment,
          star_rating: rating,
          taxable_entity_id: evaluatedId || '',
          legal_name: evaluatedType || '',
        }),
      ));

    return response;
  }
  const { data, status } = await api.post<PostFeedbackProps>(`/feedbacks`, {
    comment,
    rating,
    evaluated_id: evaluatedId,
    evaluated_type: evaluatedType,
  });

  return { ...data, status };
}
