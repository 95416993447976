import { all, fork, takeLatest } from 'redux-saga/effects';
import { ADDRESS_FETCH, ADDRESS_UPDATE } from '@constants/actionTypes';
import { addressFetchSaga } from './fetch';
import { updateAddressSaga } from './update';

export function* watchAddressFetch() {
  yield takeLatest(ADDRESS_FETCH, addressFetchSaga);
}

export function* watchAddressUpdate() {
  yield takeLatest(ADDRESS_UPDATE, updateAddressSaga);
}

export default function* rootSaga() {
  yield all([fork(watchAddressFetch), fork(watchAddressUpdate)]);
}
