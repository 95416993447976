import { Button, Flex, Text } from '@konta/ui';
import ResponsiveCenterContainer from 'shared/components/ResponsiveCenterContainer';
import * as chat from '@util/chat';

interface ScriptErrorInformationProps {
  withResponsiveCenterContainer?: boolean;
  onReload?: () => void;
  title: string;
}
export default function ScriptErrorInformation({
  withResponsiveCenterContainer = false,
  onReload = () => window.location.reload(),
  title,
}: ScriptErrorInformationProps) {
  const Container = withResponsiveCenterContainer
    ? ResponsiveCenterContainer
    : Flex;
  return (
    <Container column>
      <Flex direction="column" gap={14}>
        {withResponsiveCenterContainer && (
          <Flex css={{ mb: '20px' }}>
            <span>
              <span className="logo-single" />
            </span>
          </Flex>
        )}
        <Text l medium>
          {title}
        </Text>
        <Flex gap={8}>
          <Button color="primary" onClick={onReload}>
            Recargar
          </Button>
          <Button color="primary" onClick={() => chat.open}>
            Abrir chat
          </Button>
        </Flex>
      </Flex>
    </Container>
  );
}
