import { styled } from '@konta/ui';

export const TitleContainer = styled('div', {
  display: 'flex',
  width: '100%',
  padding: '16px 16px 24px 16px',
  alignItems: 'flex-start',
  flexDirection: 'column',
});

export const Title = styled('span', {
  fontFamily: 'Inter',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '28px',
  color: '$gray900',
});

export const SubTitle = styled('span', {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  color: '$gray500',
});
