import { ComponentProps, ReactNode } from 'react';
import { Button } from '@konta/ui';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import { useDispatch } from 'react-redux';
import { workflowNextPhase, workflowSetTransition } from '@redux/actions';

const defaultPayload = {
  workflow: {
    next_phase: 'secondary_phase',
  },
};

interface SecondaryPhaseButtonProps
  extends Omit<ComponentProps<typeof Button>, 'children'> {
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  payload?: Record<string, unknown>;
}

export default function SecondaryPhaseButton({
  children,
  disabled = false,
  loading = false,
  onClick,
  payload = defaultPayload,
  ...props
}: SecondaryPhaseButtonProps) {
  const dispatch = useDispatch();
  const { workflow, loading: loadingWorkflow } = useSelectedWorkflow();

  const handleClick = () => {
    dispatch(workflowSetTransition('phase-front'));
    dispatch(workflowNextPhase({ workflow_id: workflow?.id, body: payload }));
  };

  return (
    <Button
      color="primary"
      variant="outlined"
      data-testid="secondary-phase"
      disabled={disabled}
      loading={loadingWorkflow || loading}
      onClick={onClick || handleClick}
      {...props}
    >
      {children || 'Continuar'}
    </Button>
  );
}
