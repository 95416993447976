import { ParentSize } from '@visx/responsive';
import { Flex, Heatmap as HeatmapComp } from '@konta/ui';
import { ComponentProps } from 'react';

interface HeatmapProps
  extends Omit<ComponentProps<typeof HeatmapComp>, 'height' | 'width'> {
  debounceTime?: number;
}
export default function Heatmap({
  debounceTime = 100,
  margin = { top: 30, left: 27, right: 20, bottom: 35 },
  legendOrdinal,
  binData,
  xScaleDomain,
  yScaleDomain,
  onClickRect,
  getFillColor,
}: HeatmapProps) {
  return (
    <Flex
      column
      css={{
        flex: 1,
        overflow: 'none',
        width: '100%',
        '@md': { overflow: 'auto' },
      }}
    >
      <ParentSize debounceTime={debounceTime}>
        {({ width: visWidth }) => {
          const years = yScaleDomain.length;
          const gap = 5;
          const yearColumn = 24.5;
          const squareWidth =
            (visWidth - margin.left - margin.right - yearColumn) / 13 - gap;
          const height =
            (squareWidth + gap + 4.9) * years + margin.bottom + margin.top;
          return (
            <HeatmapComp
              margin={margin}
              legendOrdinal={legendOrdinal}
              width={visWidth}
              height={height}
              binData={binData}
              xScaleDomain={xScaleDomain}
              yScaleDomain={yScaleDomain}
              onClickRect={onClickRect}
              getFillColor={getFillColor}
            />
          );
        }}
      </ParentSize>
    </Flex>
  );
}
