import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { parseString } from 'xml2js';
import QRCode from 'qrcode';
import get from 'lodash/get';
import { fetchCFDIfromXMLSuccess } from '../actions';

export default function* ({ payload: { url } }) {
  try {
    // Funcion de utilidad para convertir la funcion parseString de xml2js de callback a promesa, con el fin de usarse eficientemente con saga
    const parserXML = (xmlData) =>
      new Promise((resolve, reject) => {
        parseString(xmlData, (error, result) => {
          if (result) resolve(result);
          else reject(error);
        });
      });

    // Obtener la informacion del xml del url de AWS S3.
    // Nota: Por cuestiones de CORS se activo el crossDomain en esta peticion al igual que en S3.
    const response = yield call(axios.get, url, {
      crossDomain: true,
    });

    // Obtener el objeto xml semi formateado usando la libreria de xml2js
    const cfdiJSON = yield parserXML(response.data);

    // Limpiar el JSON y guardarlo en un objeto
    const cfdi = yield call(cleanJSON, cfdiJSON);

    // Obtener los datos necesarios para el QR, el default de todos es un string vacio si son undefined
    // Nota: se uso GET para obtener los valores sin tener que validar los objetos
    const id = get(cfdi, 'Complemento.UUID', '');
    const sello = get(cfdi, 'Complemento.SelloCFD', '');
    const Emisor = get(cfdi, 'Emisor.Rfc', '');
    const Receptor = get(cfdi, 'Receptor.Rfc', '');
    const Total = get(cfdi, 'Total', '');

    // Generar el codigo QR con la libreria de QRCode, aplicando el formato del SAT
    const QR = yield QRCode.toDataURL(
      `https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?id=${id}&re=${Emisor}&rr=${Receptor}&tt=${Total}&fe=${sello.slice(
        -8
      )}`
    );

    // Guardar el XML
    yield put(fetchCFDIfromXMLSuccess({ QR, ...cfdi }));

    // Nota: no se exploraron los casos de error posibles, aparte del error estandar para este modulo
  } catch (error) {
    // catch throw
    console.error(error);
  }
}

const cleanJSON = (data) => {
  // Nota: se uso GET para obtener los valores sin tener que validar los objetos, se espera que el formato de los xml no cambien, de ser asi, se tendra que cambiar el accesor
  const all = data['cfdi:Comprobante'];
  const root = all.$;
  const Emisor = get(all, 'cfdi:Emisor.0.$');
  const Receptor = get(all, 'cfdi:Receptor.0.$');
  // Nota: Conceptos, impuestos y complementos regresan objetos, por tanto el default de ser nulo es un objeto, especifico para cada uno
  const Conceptos = [
    ...(get(all, 'cfdi:Conceptos.0.Concepto') ?? []),
    ...(get(all, 'cfdi:Conceptos.0.cfdi:Concepto') ?? []),
  ];

  const Impuestos = get(all, 'cfdi:Impuestos.0.$', {});
  const Retenciones = get(
    all,
    'cfdi:Impuestos.0.cfdi:Retenciones.0.cfdi:Retencion',
    {}
  );
  const Traslados = get(
    all,
    'cfdi:Impuestos.0.cfdi:Traslados.0.cfdi:Traslado',
    {}
  );

  const Complemento = get(
    all,
    'cfdi:Complemento.0.tfd:TimbreFiscalDigital.0.$',
    {
      Version: '',
      UUID: '',
      FechaTimbrado: '',
      RfcProvCertif: '',
      SelloCFD: '',
      NoCertificadoSAT: '',
      SelloSAT: '',
    }
  );

  // Regresa un objeto con todos los datos antes obtenidos
  return {
    ...root,
    Conceptos,
    Impuestos,
    Complemento,
    Retenciones,
    Traslados,
    Emisor,
    Receptor,
  };
};
