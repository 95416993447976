export const validationCardName = {
  required: {
    value: true,
    errorMessage: 'Introduzca su nombre',
  },
  pattern: {
    value: '^[A-Za-z ]+$',
    errorMessage: 'El nombre de solo puede tener letras',
  },
  minLength: {
    value: 5,
    errorMessage: 'El nombre debe de tener al menos 5 digitos',
  },
};

export const validationCardNumber = {
  required: {
    value: true,
    errorMessage: 'Introduzca su el número de la tarjeta',
  },
  pattern: {
    value: '^[0-9]+$',
    errorMessage: 'Introduzca solo números en este campo',
  },
  minLength: {
    value: 15,
    errorMessage: 'El número debe de tener al menos 15 digitos',
  },
  maxLength: {
    value: 16,
    errorMessage: 'El número no debe de tener más de 16 digitos',
  },
};

export const validationCardMonth = {
  required: {
    value: true,
    errorMessage: 'Introduzca el mes de expiración',
  },
  pattern: {
    value: '^[0-9]+$',
    errorMessage: 'Introduzca solo números en este campo',
  },
  minLength: {
    value: 2,
    errorMessage: 'El mes debe de tener 2 digitos',
  },
  maxLength: {
    value: 2,
    errorMessage: 'El mes debe de tener 2 digitos',
  },
};

export const validationCardYear = {
  required: {
    value: true,
    errorMessage: 'Introduzca el año de expiración',
  },
  pattern: {
    value: '^[0-9]+$',
    errorMessage: 'Introduzca el año de expiración',
  },
  minLength: {
    value: 2,
    errorMessage: 'El año debe de tener 2 digitos',
  },
  maxLength: {
    value: 2,
    errorMessage: 'El año debe de tener 2 digitos',
  },
};

export const validationCardCVC = {
  required: {
    value: true,
    errorMessage: 'Introduzca el CVC',
  },
  pattern: {
    value: '^[0-9]+$',
    errorMessage: 'Introduzca el CVC',
  },
  minLength: {
    value: 3,
    errorMessage: 'El CVC debe de tener minimo 3 digitos',
  },
  maxLength: {
    value: 4,
    errorMessage: 'El CVC debe de tener máximo 4 digitos',
  },
};
