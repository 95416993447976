import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, ImageVideoModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

export default function QuForeignExpences() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />

      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p className="font-weight-bold">Deducciones Personales</p>
          <p>
            Como contribuyente, tiene el derecho a a disminuir cierto tipo de
            gastos de sus ingresos acumulables.
            <span>
              <a
                className="clickable-text-color font-italic"
                href="https://www.sat.gob.mx/consulta/23972/conoce-las-deducciones-personales"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Consulte la lista de deducciones personales del SAT aquí.{' '}
              </a>
            </span>
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
      <Row>
        <Colxx md="12">
          <p className="font-weight-bold">
            Esquemas de pago en Plataformas Tecnológicas
          </p>
          <p>
            <span className="font-weight-bold">Pagos definitivos</span> -
            comúnmente se le conoce como la declaración mensual del IVA. Estos
            pagos son aquellos que declaramos al SAT haciendo la resta del IVA
            retenido e IVA acreditable al IVA causado para poder computar si
            todavía restan impuestos pendientes por trasladar.
          </p>
          <p>
            <span className="font-weight-bold">Pagos provisionales</span> -
            estos son todos los pagos que se computan en carácter de anticipo
            ante el SAT.
          </p>
        </Colxx>
        <Colxx md="12">
          <HelpCenterLink articleId="payment_scheme_platforms">
            Link al artículo
          </HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
    </>
  );
}
