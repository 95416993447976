import { Modal } from 'reactstrap';
import CloseIcon from 'mdi-react/CloseIcon';
import { CreditCard02LineIcon, User01LineIcon } from '@konta/icons';
import {
  Button,
  Flex,
  ModalContent,
  ModalHeader,
  Tab,
  Tabs,
  Text,
} from '@konta/ui';
import Loader from '@components/Loader';
import InvoiceTransactionGraph from '@components/InvoiceTransactionGraph';
import Metric from './Metric';
import Filters from './Filters/Filters';
import { MetricWrapper, modalHeaderCss, tabCss, Title, TopBar } from './styled';
import GraphSeries from '../GraphSeries';
import EntriesCollapseTable from './EntriesCollapseTable';
import useDeclarationEntriesTotalsModal from './useDeclarationEntriesTotalsModal';
import Icon from '../Icon';

interface DeclarationEntriesTotalsModalProps {
  open: boolean;
  toggleOpen: () => void;
  date?: string;
  type: 'incomes' | 'expenses';
}
export default function DeclarationEntriesTotalsModal({
  open,
  toggleOpen,
  date,
  type,
}: DeclarationEntriesTotalsModalProps) {
  const {
    filterProps,
    workflowsLoading,
    workflows,
    graphProps,
    graphsLoading,
    metrics,
    title,
    grouped,
    setGrouped,
    currentDate,
    closeModalInvoiceDetails,
    modalInvoiceDetails,
    setModalInvoiceDetails,
  } = useDeclarationEntriesTotalsModal({
    date,
    type,
  });
  return (
    <Modal
      size="xxl"
      isOpen={open}
      toggle={toggleOpen}
      wrapClassName="modal-backdrop-blur"
    >
      <ModalHeader css={modalHeaderCss}>
        <Title>{title}</Title>
        <Button icon variant="text" onClick={toggleOpen}>
          <CloseIcon />
        </Button>
      </ModalHeader>
      <ModalContent>
        <Flex column gap={24}>
          <TopBar>
            <MetricWrapper>
              {metrics.map((metric) => (
                <Metric
                  key={metric.title}
                  title={metric.title}
                  total={metric.total}
                  tooltipText={metric.tooltipText}
                  isCurrency={metric.isCurrency}
                />
              ))}
            </MetricWrapper>
            <Filters {...filterProps} />
          </TopBar>
          <Flex>
            {graphsLoading ? (
              <Flex column css={{ height: '270px', flex: 1 }}>
                <Loader />
              </Flex>
            ) : (
              <GraphSeries {...graphProps} />
            )}
          </Flex>
          <Flex>
            <Tabs
              size="s"
              value={grouped}
              onChange={setGrouped}
              variant="switch"
              indicator="bottom"
            >
              <Tab id="byTransaction" css={tabCss}>
                <Icon>
                  <CreditCard02LineIcon color="#344054" />
                </Icon>
                <Text>Por transacciones</Text>
              </Tab>
              <Tab id="byClient" css={tabCss}>
                <Icon>
                  <User01LineIcon color="#344054" />
                </Icon>
                <Text>Por cliente</Text>
              </Tab>
            </Tabs>
          </Flex>
          {workflowsLoading ? (
            <Flex
              itemsCenter
              column
              css={{ minHeight: '50px', flex: 1, justifyContent: 'center' }}
            >
              <Loader />
            </Flex>
          ) : (
            <Flex column gap={24}>
              {!!modalInvoiceDetails && (
                <InvoiceTransactionGraph
                  selectedData={modalInvoiceDetails}
                  open={!!modalInvoiceDetails}
                  toggleOpen={closeModalInvoiceDetails}
                />
              )}

              {workflows.map((workflow) => (
                <EntriesCollapseTable
                  setModalInvoiceDetails={setModalInvoiceDetails}
                  currentDate={currentDate}
                  type={type}
                  key={workflow.id}
                  workflow={workflow}
                  isGrouped={grouped === 'byClient'}
                />
              ))}
            </Flex>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
}
