import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import {
  BellIcon,
  CheckCrFrIcon,
  CoinDollar2Icon,
  Flag3Icon,
  HourglassIcon,
} from '@konta/icons';

export function MonthDatePickerMonthButton({
  indexId,
  isSelected,
  monthWithWorkflow,
  handleSelectMonth,
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const iconFromWorkflowStatus = (status) => {
    return {
      fetching_invoice: HourglassIcon,
      classifying: HourglassIcon,
      not_ready: HourglassIcon,
      ready: Flag3Icon,
      in_proccess: BellIcon,
      verify_declaration: HourglassIcon,
      submit_declaration: HourglassIcon,
      pending_payment: CoinDollar2Icon,
      paid: CheckCrFrIcon,
      done: CheckCrFrIcon,
    }[status];
  };

  const buttonTooglipMessage = (status) => {
    return {
      fetching_invoice: 'En estos momentos estamos preparando tu declaración',
      classifying: 'En estos momentos estamos preparando tu declaración',
      not_ready: 'En estos momentos estamos preparando tu declaración',
      ready: 'Ya puedes comenzar tu declaración',
      in_proccess: 'La declaración ya ha iniciado, continúela',
      verify_declaration: 'Un contador está verificando tu declaración 🧐',
      submit_declaration: 'La declaración está siendo presentada 🗂',
      pending_payment: 'Su declaración necesita ser pagada 🤗',
      paid: 'La declaración se encuentra completada',
      done: 'La declaración se encuentra completada',
    }[status];
  };
  const MonthIcon = iconFromWorkflowStatus(monthWithWorkflow.status);
  return (
    <>
      <Button
        id={`workflow-button-${indexId}`}
        className={`datepicker-month-button btttn text-button btttn-sm ${
          isSelected ? 'isSelected' : ''
        }`}
        onClick={() => handleSelectMonth(monthWithWorkflow)}
      >
        {monthWithWorkflow.status ? (
          <MonthIcon
            className={`button-icon month-status-${monthWithWorkflow.status}`}
          />
        ) : null}
        {monthWithWorkflow.label}
      </Button>
      {monthWithWorkflow.status && (
        <Tooltip
          placement="bottom"
          isOpen={tooltipOpen}
          target={`workflow-button-${indexId}`}
          toggle={toggle}
          data-testid="tooltiptrest"
        >
          {buttonTooglipMessage(monthWithWorkflow.status)}
        </Tooltip>
      )}
    </>
  );
}
