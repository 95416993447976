import { AxiosRequestConfig } from 'axios';
import type { AccumulatedSales } from 'types/entities';
import api from './index';

// eslint-disable-next-line import/prefer-default-export
export const getAccumulatedSales = async (
  config?: AxiosRequestConfig<AccumulatedSales>,
) => {
  const { data } = await api.get<AccumulatedSales>(`accumulated_sales`, config);
  return data;
};
