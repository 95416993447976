import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import useSearchParamsState from '@hooks/useSearchParamsState';
import useWorkflows from 'shared/hooks/useWorkflows';
import useTaxableEntity from '@hooks/useTaxableEntity';
import { AnnualDeclaration } from 'types/entities';
import usePreferredFiscalRegime from 'shared/hooks/usePreferredFiscalRegime';

const views = {
  resume: 'resume',
  resumeByRegime: 'resume-by-regime',
  entries: 'entries',
};

interface UseRoutesResumeAnnualProps {
  customPeriod?: number;
  annualDeclarations?: AnnualDeclaration[];
  loadingAnnualDeclarations?: boolean;
}

interface StateParams {
  filters: {
    month: string;
    type: 'income' | 'expense';
  };
}

export default function useRoutesResumeAnnual({
  customPeriod,
  annualDeclarations,
  loadingAnnualDeclarations,
}: UseRoutesResumeAnnualProps) {
  const [view, setView] = useSearchParamsState('view', views.resume);
  const [currentAnnualDeclaration, setCurrentAnnualDeclaration] =
    useState<AnnualDeclaration | null>(null);
  const [regimeSatKeys, setRegimeSatKeys] = useState<number[]>([]);
  const { taxable_entity } = useTaxableEntity();
  const [filters, setFilters] = useState<Record<string, string>>({
    month: '',
    type: '',
  });

  const { state } = useLocation();

  useEffect(() => {
    const savedState = state as StateParams;
    if (savedState?.filters) {
      setFilters(savedState.filters);
    }
  }, [state]);

  const period = customPeriod ?? dayjs().subtract(1, 'year').format('YYYY');

  const currentAnnualDeclarations = useMemo(
    () =>
      annualDeclarations?.length &&
      annualDeclarations
        .filter((annual) => annual.period === +period)
        .map((annualDeclaration) => {
          const { status, annual_declaration_totals } = annualDeclaration;
          const createdBy = status === 'open' ? 'konta_app' : 'retool_app';

          return {
            ...annualDeclaration,
            annual_declaration_totals: annual_declaration_totals?.filter(
              (_declaration) => _declaration.created_by === createdBy,
            ),
          } as AnnualDeclaration;
        }),
    [annualDeclarations, period],
  );

  const resumeByRegimeBreadcrumbs = [
    {
      label: 'Declaración anual',
      onClick: () => setView(views.resume),
      isActive: view === views.resume,
    },
    {
      label: 'Resumen por regímenes',
      onClick: () => setView(views.resumeByRegime),
      isActive: view === views.resumeByRegime,
    },
  ];

  const entriesBreadcrumbs = [
    ...resumeByRegimeBreadcrumbs,
    {
      label: 'Detalle de facturación',
      onClick: () => setView(views.entries),
      isActive: view === views.entries,
    },
  ];

  const { workflows, workflowsLoading } = useWorkflows({
    params: {
      'q[active_true]': 1,
      'q[start_date_gteq]': `${period}-01-01`,
      'q[start_date_lteq]': `${period}-12-31`,
    },
  });

  const { preferredFiscalRegime, loadingPreferredFiscalRegime } =
    usePreferredFiscalRegime();

  const workflowsId = useMemo(
    () => workflows?.map((workflow) => workflow.id),
    [workflows],
  );

  useEffect(() => {
    if (view === views.resumeByRegime && regimeSatKeys.length === 0) {
      setView(views.resume);
    }
  }, [regimeSatKeys, setView, view]);

  const onHandleResumeByRegime = (
    _regimeSatKeys: number[],
    _currentAnnualDeclaration: AnnualDeclaration,
  ) => {
    setRegimeSatKeys(_regimeSatKeys);
    setView(views.resumeByRegime);
    setCurrentAnnualDeclaration(_currentAnnualDeclaration);
  };
  const onHandleEntriesView = (month: string, type: 'income' | 'expense') => {
    setFilters({
      month,
      type,
    });
    setView(views.entries);
  };

  const isLoading =
    workflowsLoading ||
    loadingAnnualDeclarations ||
    loadingPreferredFiscalRegime;

  return {
    views,
    view,
    setRegimeSatKeys,
    regimeSatKeys,
    setView,
    onHandleResumeByRegime,
    filters,
    setFilters,
    resumeByRegimeBreadcrumbs,
    entriesBreadcrumbs,
    onHandleEntriesView,
    isLoading,
    workflows,
    workflowsId,
    taxableEntity: taxable_entity,
    period,
    annualDeclarations,
    currentAnnualDeclarations,
    currentAnnualDeclaration,
    preferredFiscalRegime,
  };
}
