import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

// TODO: add formatter prop to replace "formatOnBlur"
// DEPRECATED: use FormikTextInput
export default function FormikTextField({
  name,
  label,
  formatOnBlur,
  disabled,
  onChange,
  style,
  inputStyle,
  ...restProps
}) {
  const formik = useFormikContext();
  const [field, meta, helpers] = useField({
    name,
  });

  const handleBlur = (e) => {
    if (formatOnBlur) {
      const formattedValue = formatOnBlur(field.value);
      helpers.setValue(formattedValue);
    }
    field.onBlur(e);
  };

  const handleChange = (e) => {
    field.onChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  const touched = meta.touched || formik.submitCount > 0;
  const hasError = meta.error && touched;

  return (
    <FormGroup disabled={disabled} style={style}>
      {Boolean(label) && <Label htmlFor={name}>{label}</Label>}
      <Input
        name={name}
        invalid={hasError}
        disabled={disabled}
        style={inputStyle}
        {...field}
        {...restProps}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {meta.error && touched && <FormFeedback>{meta.error}</FormFeedback>}
    </FormGroup>
  );
}

FormikTextField.propTypes = {
  disabled: PropTypes.bool,
  formatOnBlur: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

FormikTextField.defaultProps = {
  disabled: false,
  formatOnBlur: null,
  onChange: null,
  label: '',
};
