// TODO: Move to ui and add all variants
import { ReactNode } from 'react';
import { Button, CSS, Flex, HoverCard, Text } from '@konta/ui';
import dayjs from 'dayjs';
import { useToggle } from 'usehooks-ts';
import { isString } from 'lodash';
import { InfoCircleLineIcon } from '@konta/icons';
import toCurrency from '../../../@util/toCurrency';

interface CellProps {
  children: ReactNode | dayjs.Dayjs;
  arrayDateFormat?: string;
  dateFormat?: string;
  arrayViewType?: 'hover' | 'collapse';
  collapseType?: 'hover' | 'click';
  isCollapsible?: boolean;
  isCollapsedText?: boolean;
  capitalize?: boolean;
  currency?: boolean;
  textCss?: CSS;
  uppercase?: boolean;
}

export default function Cell({
  children,
  arrayDateFormat = 'MMMM [del] YYYY',
  dateFormat = 'DD-MM-YYYY',
  arrayViewType = 'collapse',
  collapseType = 'click',
  isCollapsible,
  isCollapsedText,
  capitalize,
  currency,
  textCss,
  uppercase,
}: CellProps) {
  const [collapsible, toggleCollapsible] = useToggle();

  if (dayjs.isDayjs(children)) {
    if (children.isValid()) {
      return (
        <Text capitalize={capitalize} uppercase={uppercase} css={textCss}>
          {children.format(dateFormat)}
        </Text>
      );
    }
    return <Text>-</Text>;
  }

  if (isCollapsible) {
    if (collapseType === 'hover') {
      return (
        <HoverCard
          css={{ overflow: 'auto', maxHeight: '400px' }}
          openDelay={0}
          triggerElement={
            <Button size="xs" variant="minimal" icon noFill>
              <InfoCircleLineIcon color="#667085" />
            </Button>
          }
        >
          <Flex column>
            <Text css={{ marginRight: '$8', ...textCss }}>{children}</Text>
          </Flex>
        </HoverCard>
      );
    }
    return (
      <>
        <Flex tabIndex={-1} role="button">
          <Button size="xs" variant="outlined" onClick={toggleCollapsible}>
            {collapsible ? 'Ocultar' : `Mostrar`}
          </Button>
        </Flex>
        {collapsible && (
          <Flex column>
            <Text css={{ marginRight: '$8', ...textCss }}>{children}</Text>
          </Flex>
        )}
      </>
    );
  }

  if (Array.isArray(children)) {
    if (children.length === 0) {
      return <Text> -- </Text>;
    }
    if (children.length === 1) {
      return (
        <Text css={textCss}>
          {dayjs(children?.toString()).isValid()
            ? dayjs(children.toString()).format(arrayDateFormat)
            : 'Fecha no válida'}
        </Text>
      );
    }
    if (arrayViewType === 'hover') {
      return (
        <HoverCard
          openDelay={0}
          triggerElement={
            <Flex tabIndex={-1} role="button">
              Varios...
            </Flex>
          }
        >
          <Flex column>
            {children.map((child: string | number) => {
              let formatChild = child?.toString?.() || '';
              if (dayjs(child).isValid()) {
                formatChild = dayjs(child).format(arrayDateFormat);
              }
              return (
                <Text key={formatChild} css={{ marginRight: '$8', ...textCss }}>
                  {formatChild}
                </Text>
              );
            })}
          </Flex>
        </HoverCard>
      );
    }
    return (
      <>
        <Flex tabIndex={-1} role="button">
          <Button size="xs" variant="outlined" onClick={toggleCollapsible}>
            {collapsible ? 'Ocultar' : `Mostrar (${children.length})`}
          </Button>
        </Flex>
        {collapsible && (
          <Flex column>
            {children.map((child: string | number) => {
              let formatChild = child?.toString?.() || '';
              if (dayjs(child).isValid()) {
                formatChild = dayjs(child).format(arrayDateFormat);
              }
              return (
                <Text key={formatChild} css={{ marginRight: '$8', ...textCss }}>
                  {formatChild}
                </Text>
              );
            })}
          </Flex>
        )}
      </>
    );
  }

  if (
    isCollapsedText &&
    children &&
    isString(children) &&
    children?.toString()?.length > 30
  ) {
    return (
      <HoverCard
        openDelay={0}
        triggerElement={
          <Flex tabIndex={-1} role="button">
            <Text
              css={{
                width: '300px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...textCss,
              }}
            >
              {children}
            </Text>
          </Flex>
        }
      >
        <Flex column>
          <Text
            css={{
              lineBreak: 'anywhere',
            }}
          >
            {children}
          </Text>
        </Flex>
      </HoverCard>
    );
  }
  return (
    <Text capitalize={capitalize} uppercase={uppercase} css={textCss}>
      {currency ? toCurrency(+(children || 0)) : children}
    </Text>
  );
}
