import { useEffect } from 'react';
import useDeclarationEntriesPagination from 'shared/hooks/useDeclarationEntriesPagination';
import setLocalStorageFilters from 'shared/util/setLocalStorageFilters';
import { Workflow } from 'types/entities';

interface UseEntriesProps {
  workflowsId: number[];
  taxableEntityId: number;
  filters?: Record<string, string>;
  period: string | number;
  workflowFromGridTable: Workflow;
}

export default function useEntries({
  workflowsId,
  taxableEntityId,
  filters,
  period,
  workflowFromGridTable,
}: UseEntriesProps) {
  const { declarationEntries, declarationEntriesLoading, remove } =
    useDeclarationEntriesPagination({
      params: {
        per_page: 10000,
        'q[declaration_workflow_id_in]': workflowFromGridTable?.id
          ? workflowFromGridTable.id
          : 0,
        'q[declaration_is_active_true]': 1,
        'q[taxable_entity_id_eq]': taxableEntityId,
      },
      queryOptions: {
        enabled: workflowsId?.length > 0,
      },
    });

  useEffect(() => {
    const accountType = {
      expense: 'Gastos',
      income: 'Ingresos',
    }[filters?.type || ''];

    setLocalStorageFilters(
      'tableFilters-summary-declaration-entries-table-2-v2',
      {
        ...(filters?.type && {
          general_declaration_entry_type: {
            label: accountType,
            value: accountType,
          },
        }),
      },
    );
  }, [filters, period]);

  useEffect(() => {
    if (workflowsId?.length === 0) {
      remove();
    }
  }, [workflowsId?.length]);

  return {
    declarationEntries,
    declarationEntriesLoading,
  };
}
