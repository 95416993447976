import { SelectedCategory } from 'types/entities';
import { DataTable } from '@konta/ui';
import useCfdisTable from './useCfdisTable';

interface CfdisTableProps {
  source: 'incomes' | 'expenses';
  periodDate?: string;
  selectedCategory?: SelectedCategory | null;
}
export default function CfdisTable({
  source,
  periodDate,
  selectedCategory,
}: CfdisTableProps) {
  const {
    cfdis,
    columns,
    pagination,
    cfdisLoading,
    setTableInstance,
    setFilters,
  } = useCfdisTable({
    source,
    periodDate,
    selectedCategory,
  });
  return (
    <DataTable
      // @ts-ignore TODO: fix typing with react-table
      columns={columns}
      data={cfdis}
      withHover
      loading={cfdisLoading}
      dataTableId="deductionDetailsCfdisTable"
      manualPagination
      manualPaginationProps={pagination}
      setTableInstance={setTableInstance}
      onFiltersChange={setFilters}
      withFilteringControl={false}
      withoutExportControl
    />
  );
}
