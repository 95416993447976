import { Button, Flex } from '@konta/ui';
import { FormikProvider } from 'formik';
import FormikSelect from 'shared/components/FormikSelect';
import useInvoiceSettings from './useInvoiceSettings';

export default function InvoiceSettings() {
  const { formik, activeRegimes, isLoading } = useInvoiceSettings();

  return (
    <Flex>
      <FormikProvider value={formik}>
        <Flex gap={6} align="end">
          <Flex>
            <FormikSelect
              placeholder="Selecciona una opción"
              id="sat_key"
              name="sat_key"
              label="Régimen al que se generan las facturas del servicio*"
              options={activeRegimes}
            />
          </Flex>
          <div>
            <Button
              color="primary"
              onClick={formik.submitForm}
              loading={isLoading}
            >
              Guardar
            </Button>
          </div>
        </Flex>
      </FormikProvider>
    </Flex>
  );
}
