import React from 'react';
import { Modal } from 'reactstrap';
import { Button, Flex, ModalContent } from '@konta/ui';
import useUser from '@hooks/useUser';
import Banner1x from './img/banner.webp';
import Banner2x from './img/banner@2x.webp';
import Testimonies1x from './img/testimonies.webp';
import Testimonies2x from './img/testimonies@2x.webp';

const canSendTrack = () => {
  const watermark = localStorage?.getItem?.('watermark');
  return window.analytics && !watermark;
};

const CALENDLY_LINK =
  'https://meetings.hubspot.com/rodolfo-condado/asesoria-contabilidad';

export default function InvoiceBannerModal({ visible, onClose }) {
  const { user } = useUser();
  const email = user?.email || '';

  const redirectToCalendly = (e) => {
    if (canSendTrack()) {
      window.analytics.track(`Button clicked: ${e.target.textContent}`, {
        email,
      });
    }
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = CALENDLY_LINK;
    a.click();
    a.remove();
  };
  const closeModal = (e) => {
    if (canSendTrack()) {
      window.analytics.track(`Button clicked: ${e.target.textContent}`, {
        email,
      });
    }
    onClose();
  };

  return (
    <Modal centered isOpen={visible} toggle={onClose} size="xl">
      <ModalContent css={{ padding: 0 }}>
        <picture>
          <source
            media="(min-width: 900px)"
            type="image/webp"
            srcSet={`${Banner1x} 900pxw, ${Banner2x} 1280w`}
          />
          <img src={Banner1x} alt="banner-img" className="img-fluid w-100" />
        </picture>
        <picture>
          <source
            media="(min-width: 900px)"
            type="image/webp"
            srcSet={`${Testimonies1x} 900pxw, ${Testimonies2x} 1280w`}
          />
          <img
            src={Testimonies1x}
            alt="banner-img"
            className="img-fluid w-100"
          />
        </picture>
        <Flex
          align="center"
          justify="center"
          css={{ py: '$10', backgroundColor: '#f2f3fdff' }}
          gap={16}
        >
          <Button color="success" onClick={redirectToCalendly}>
            Quiero la promoción
          </Button>
          <Button onClick={closeModal}>Seguir facturando gratis</Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
