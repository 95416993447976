import toFixed from './toFixed';

function incrementTax({ invoiceTax, conceptTax, base }) {
  if (!conceptTax) {
    // not increment if concept has no tax
    return invoiceTax;
  }

  // Create tax if not created
  const tax = invoiceTax || { amount: 0, base: 0, rates: {} };

  // fix concept amounts
  const fixedAmount = toFixed(conceptTax.amount, 6);
  const fixedBase = toFixed(base, 6);

  // create tax rate if not exists
  const rateName = conceptTax.rate === null ? 'exempt' : conceptTax.rate;
  if (!tax.rates[rateName]) {
    tax.rates[rateName] = { amount: 0, base: 0 };
  }

  // increment tax rated amounts
  const ratedTax = tax.rates[rateName];
  ratedTax.amount += fixedAmount;
  ratedTax.base += fixedBase;

  // increment tax amounts
  tax.amount += fixedAmount;
  tax.base += fixedBase;

  return tax;
}

export default function calculateInvoice({ concepts }) {
  let subtotal = 0;
  let discount = 0;
  let total = 0;

  /**
   * @type { InvoiceTax }
   */
  let iva = { rates: {}, amount: 0, base: 0 };
  /**
   * @type { InvoiceTax }
   */
  let retainedIva = { rates: {}, amount: 0, base: 0 };
  /**
   * @type { InvoiceTax }
   */
  let ieps = { rates: {}, amount: 0, base: 0 };
  /**
   * @type { InvoiceTax }
   */
  let isr = { rates: {}, amount: 0, base: 0 };

  concepts.forEach((concept) => {
    if (!concept.price || !concept.quantity) {
      return;
    }

    const conceptSubtotal = concept.subtotal;
    const conceptDiscount = +concept.discount || 0;
    const conceptAmount = concept.amount;

    // increment totals
    subtotal += conceptSubtotal + conceptDiscount;
    discount += conceptDiscount;
    total += conceptAmount;

    if (concept.taxableCode.value !== '02') {
      return;
    }

    iva = incrementTax({
      invoiceTax: iva,
      conceptTax: concept.iva,
      base: concept.subtotal,
    });

    retainedIva = incrementTax({
      invoiceTax: retainedIva,
      conceptTax: concept.retainedIva,
      base: concept.subtotal,
    });

    // TODO check this code snippet
    /* ieps = incrementTax({
      invoiceTax: ieps,
      conceptTax: concept.ieps,
      base: concept.subtotal,
    }); */

    isr = incrementTax({
      invoiceTax: isr,
      conceptTax: concept.isr,
      base: concept.subtotal,
    });
  });

  subtotal = toFixed(subtotal, 2);
  discount = toFixed(discount, 2);
  total = toFixed(total, 2);

  return { subtotal, discount, total, iva, retainedIva, ieps, isr };
}
