import _ from 'lodash';
import { useMediaQuery } from '@konta/ui';
import useAnnualAccumulatedIncomesAndExpenses from '@hooks/useAnnualAccumulatedIncomesAndExpenses';
import useMonthlyAccumulatedIncomesAndExpenses from '@hooks/useMonthlyAccumulatedIncomesAndExpenses';
import { useCallback } from 'react';
import {
  AccumulatedIncomeAndExpense,
  AccumulatedIncomeAndExpenseFilters,
} from 'types/entities';
import useTaxableEntityShared from 'shared/hooks/useSharedTaxableEntity';
import getAuthorizationAxiosConfig from 'shared/util/getAuthorizationAxiosConfig';

interface AccumulatedIncomesAndExpensesProps {
  filter?: AccumulatedIncomeAndExpenseFilters;
  params?: Record<string, string | number | boolean>;
}

export default function useAccumulatedIncomesAndExpenses({
  filter,
  params,
}: AccumulatedIncomesAndExpensesProps) {
  const isDesktop = useMediaQuery('(min-width: 1512px)');
  const { taxableEntityId, userAccessToken } = useTaxableEntityShared();

  const {
    annualAccumulatedIncomesAndExpenses,
    annualAccumulatedIncomesAndExpensesLoading,
  } = useAnnualAccumulatedIncomesAndExpenses(
    taxableEntityId,
    {
      ...getAuthorizationAxiosConfig(userAccessToken),
      params,
    },
    {
      enabled: filter === 'currentYear',
      staleTime: 1000 * 15,
    },
  );
  const {
    monthlyAccumulatedIncomesAndExpenses,
    monthlyAccumulatedIncomesAndExpensesLoading,
  } = useMonthlyAccumulatedIncomesAndExpenses(
    taxableEntityId,
    {
      ...getAuthorizationAxiosConfig(userAccessToken),
      params,
    },
    {
      enabled: filter === 'currentMonth',
      staleTime: 1000 * 15,
    },
  );

  const getDataSetByIncomeOrExpense = useCallback(
    (label: string, dataType: keyof AccumulatedIncomeAndExpense) => {
      const xAxisLabel = filter === 'currentMonth' ? 'Día' : 'Mes';
      const data =
        filter === 'currentMonth'
          ? monthlyAccumulatedIncomesAndExpenses?.[dataType]
          : annualAccumulatedIncomesAndExpenses?.[dataType];
      const totalsByDate = Object.entries(data || {}).reduce<
        Record<string, { [key: string]: number }>
      >((acc, [satKet, incomes]) => {
        Object.entries(incomes).forEach(([date, total]) => {
          acc[date] = {
            ...acc[date],
            total: (acc[date]?.total || 0) + +total,
            [satKet]: +total,
          };
        });
        return acc;
      }, {});

      const collection = Object.entries(totalsByDate).map(([date, totals]) => ({
        name: date,
        value: +totals.total,
        extraData: totals,
      }));

      return {
        label,
        currency: true,
        data: collection,
        xAxisLabel: filter === 'currentMonth' ? 'Día' : 'Mes',
      };
    },
    [
      annualAccumulatedIncomesAndExpenses,
      filter,
      monthlyAccumulatedIncomesAndExpenses,
    ],
  );

  const incomesDataSet = getDataSetByIncomeOrExpense('Ingresos', 'incomes');
  const expensesDataSet = getDataSetByIncomeOrExpense('Gastos', 'expenses');
  const accumulatedIncomeTotal = _.last(incomesDataSet.data)?.value || 0;
  const accumulatedExpenseTotal = _.last(expensesDataSet.data)?.value || 0;

  return {
    annualAccumulatedIncomesAndExpensesLoading,
    monthlyAccumulatedIncomesAndExpensesLoading,
    annualAccumulatedIncomesAndExpenses,
    monthlyAccumulatedIncomesAndExpenses,
    isDesktop,
    incomesDataSet,
    expensesDataSet,
    accumulatedIncomeTotal,
    accumulatedExpenseTotal,
    isLoading:
      monthlyAccumulatedIncomesAndExpensesLoading ||
      annualAccumulatedIncomesAndExpensesLoading,
  };
}
