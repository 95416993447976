import { ReactNode } from 'react';
import { Flex, Text, Icon } from '@konta/ui';

interface DetailActionProps {
  label?: string;
  icon?: ReactNode;
  iconCss?: Record<string, string | object>;
}

export default function DetailAction({
  icon,
  label,
  iconCss,
}: DetailActionProps) {
  return (
    <Flex gap={8} align="center">
      <Icon
        size={24}
        css={{
          borderRadius: '50%',
          svg: {
            width: '$16',
            height: '$16',
          },
          ...iconCss,
        }}
      >
        {icon}
      </Icon>
      <Text m regular color="gray500" lineHeight="m">
        {label}
      </Text>
    </Flex>
  );
}
