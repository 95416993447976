import React from 'react';
import { Row } from 'reactstrap';
import DropzoneComponent from 'react-dropzone-component';
import { Colxx } from '@components/CustomBootstrap';
import 'dropzone/dist/min/dropzone.min.css';

const ReactDOMServer = require('react-dom/server');

// Dropzone config
const dropzoneComponentConfig = {
  postUrl: 'no-url',
};

const dropzoneConfig = {
  autoProcessQueue: false,
  acceptedFiles: '.xml',
  thumbnailHeight: 160,
  maxFilesize: 10,
  maxFiles: 1,
  dictDefaultMessage: 'Arrastra el archivo(.xml) o dé click en esta área',
  dictInvalidFileType: 'No puedes subir archivos de este tipo.',
  dictMaxFilesExceeded: 'Ya no puedes subir más archivos.',
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div
      style={{ maxWidth: '90%' }}
      className="dz-preview dz-file-preview mb-3"
    >
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            <img data-dz-thumbnail className="img-thumbnail border-0" alt="" />
            <i className="simple-icon-doc preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            <span data-dz-name />
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#" className="remove" data-dz-remove>
        <i className="glyph-icon simple-icon-trash" />{' '}
      </a>
    </div>
  ),
};

export function InvoiceIncomeUpload({ events }) {
  return (
    <Row className="mx-2">
      <Colxx xxs={12} className="mb-2">
        <DropzoneComponent
          config={dropzoneComponentConfig}
          eventHandlers={events}
          djsConfig={dropzoneConfig}
        />
      </Colxx>
    </Row>
  );
}
