import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, ImageVideoModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

function IncomeClassification() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />

      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p className="font-weight-bold">Comunicación con Konta</p>
          <p>
            Puede mantener comunicación con nosotros vía chat. Se cuenta con un
            tiempo de respuesta máximo de 24 horas hábiles en un horario de 9am
            a 6pm Centro de México.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
      <Row>
        <Colxx md="12">
          <p className="font-weight-bold">¿Cómo obtengo mi factura de Konta?</p>
          <p>
            Obtener su factura de Konta es tan sencillo como revisar que llegue
            a su correo posterior a hacer cualquier pago con nosotros. Todas
            nuestras facturas son emitidas automáticamente al momento que pagas.
            Recuerde revisar su bandeja de "Spam" en caso que no encuentre su
            factura en su bandeja principal.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
    </>
  );
}
export default IncomeClassification;
