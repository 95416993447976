import { ReactNode } from 'react';
import {
  Process,
  ServiceSubscription,
  TaxArrear,
  Workflow,
} from 'types/entities';
import { create } from 'zustand';

interface PendingTaskDetail {
  label?: string;
  icon?: ReactNode;
  iconCss?: Record<string, string | object>;
}

interface PendingTask {
  id: string;
  type:
    | 'onboarding'
    | 'finishOnboading'
    | 'annualDeclaration'
    | 'workflow'
    | 'taxArrear'
    | 'message'
    | 'subscription'
    | 'other'
    | string;
  status: 'requiredAction' | 'pending' | 'completed';
  title: string;
  extraDescription?:
    | string
    | number
    | null
    | boolean
    | ServiceSubscription
    | TaxArrear
    | Process
    | Workflow
    | Record<string, string | number | object | null>;
  description: string;
  details?: PendingTaskDetail[];
  handleAction?: () => void;
}

interface PendingTasksStore {
  pendingTasks: PendingTask[];
  selectedTask: PendingTask | null;
  setSelectedTask: (task: PendingTask) => void;
  addPendingTask: (newTask: PendingTask) => void;
  removePendingTask: (taskToRemove: PendingTask) => void;
  cleanPendingTask: () => void;
  cleanSelectedTask: () => void;
}

const usePendingTasksStore = create<PendingTasksStore>((set, get) => ({
  pendingTasks: [],
  selectedTask: null,
  setSelectedTask: (task: PendingTask) => set(() => ({ selectedTask: task })),
  addPendingTask: (newTask: PendingTask) =>
    set((state) => ({ pendingTasks: [...state.pendingTasks, newTask] })),
  removePendingTask: (taskToRemove: PendingTask) => {
    const { pendingTasks } = get();

    const filteredTasks = pendingTasks.filter(
      (task) => task.id !== taskToRemove.id,
    );
    set(() => ({ pendingTasks: filteredTasks }));
  },
  cleanPendingTask: () => set(() => ({ pendingTasks: [] })),
  cleanSelectedTask: () => set(() => ({ selectedTask: null })),
}));

export default usePendingTasksStore;
