import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@konta/ui';
import Divider from '@components/Divider';
import VideoButton from '@components/VideoButton';
import styles from './WorkflowHeader.module.scss';

export default function WorkflowHeader({
  children,
  description,
  title,
  videoId,
}) {
  return (
    <div>
      <div className={styles.main}>
        <div className={styles.description}>
          {!!title && (
            <Text l bold>
              {title}
            </Text>
          )}
          <Divider />
          {!!description && (
            <div>
              <Text color="neutral">{description}</Text>
            </div>
          )}
          {children}
        </div>
        {videoId && <VideoButton videoId={videoId} />}
      </div>
    </div>
  );
}

WorkflowHeader.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  videoId: PropTypes.string,
};

WorkflowHeader.defaultProps = {
  children: null,
  description: null,
  videoId: null,
};
