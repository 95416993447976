import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import putTaxableEntity from '@api/putTaxableEntity';

/**
 * @param { import('react-query').UseMutationOptions } options
 */
export default function useUpdateTaxableEntityMutation(options) {
  const taxableId = useSelector(
    (state) => state.taxableEntity?.taxable_entity?.id,
  );

  const putTaxableEntityRequest = useCallback(
    (payload) => {
      return putTaxableEntity({ taxableId, payload });
    },
    [taxableId],
  );

  return useMutation(putTaxableEntityRequest, options);
}
