import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Flex, Input, Text } from '@konta/ui';

export default function FormikTextInput({
  name,
  label,
  disabled = false,
  onChange = null,
  onBlur = null,
  ...restProps
}) {
  const formik = useFormikContext();
  const [field, meta] = useField({
    name,
  });

  const handleBlur = (e) => {
    field.onBlur(e);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleChange = (e) => {
    field.onChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  const touched = meta.touched || formik.submitCount > 0;
  const error = meta.error && touched ? meta.error : null;

  return (
    <Flex direction="column" gap={6} css={{ flex: 1 }}>
      <Input
        {...field}
        {...restProps}
        name={name}
        label={label}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        inputCss={{ alignItems: 'center' }}
      />
      {error && (
        <Text xs lineHeight="xs" color="error500">
          {error}
        </Text>
      )}
    </Flex>
  );
}

FormikTextInput.propTypes = {
  disabled: PropTypes.bool,
  formatOnBlur: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
};

FormikTextInput.defaultProps = {
  disabled: false,
  formatOnBlur: null,
  onChange: undefined,
  onBlur: undefined,
  label: '',
};
