import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box, Flex, HoverCard, Select, Text } from '@konta/ui';
import Icon from 'shared/components/Icon';
import useDoughnutChartData from 'backoffice/hooks/useDoughnutChartData';
import { HelpCircleLineIcon } from '@konta/icons';
import { ReactSelectItemProps } from 'types/entities';
import toCurrency from '@util/toCurrency';
import {
  RowItem,
  RowItemColor,
  RowItemLabel,
  RowItemNumber,
  StyledChartRowsContainer,
} from './styled';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface ChartNumberValueProps {
  type: 'percentage' | 'currency' | 'string';
  value: number;
  data: ChartData<'doughnut'>;
}

function ChartNumberValue({ type, value, data }: ChartNumberValueProps) {
  if (type === 'percentage') {
    return (
      <RowItemNumber>
        <span>{value}%</span>
      </RowItemNumber>
    );
  }
  if (type === 'currency') {
    return (
      <RowItemNumber>
        <span>{toCurrency(+value)}</span>
      </RowItemNumber>
    );
  }
  return (
    <RowItemNumber>
      <span>{value}</span>
    </RowItemNumber>
  );
}

interface DoughnutChartProps {
  data: ChartData<'doughnut'>;
  options?: ChartOptions<'doughnut'>;
  width?: number;
  height?: number;
  valueType?: 'currency' | 'percentage' | 'string';
  isDesktop: boolean;
  total?: number;
  textTitleHover?: string;
  title: string;
  yearFilterProps?: {
    options: ReactSelectItemProps<unknown>[];
    value: string;
    onChangeFilter: (filter: unknown) => void;
  };
  monthFilterProps?: {
    options: ReactSelectItemProps<unknown>[];
    value: string;
    onChangeFilter: (filter: unknown) => void;
  };
  onChangeFilter?: (filter: string) => void;
}

export default function DoughnutChart({
  data,
  options,
  width,
  height,
  valueType = 'string',
  isDesktop,
  total,
  textTitleHover,
  title,
  yearFilterProps,
  monthFilterProps,
}: DoughnutChartProps) {
  const { combinedData } = useDoughnutChartData({ data });

  return (
    <Flex
      css={{
        gap: 10,
        flexDirection: 'column',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <Flex>
        <Flex
          itemsCenter
          justify="between"
          css={{
            width: '100%',
          }}
        >
          <Flex gap={6} itemsCenter>
            <Text color="gray700" medium s lineHeight="s">
              {title}
            </Text>
            {!!textTitleHover && (
              <HoverCard
                side="top"
                openDelay={0}
                triggerElement={
                  <Icon tabIndex={-1} role="button">
                    <HelpCircleLineIcon />
                  </Icon>
                }
              >
                {textTitleHover}
              </HoverCard>
            )}
          </Flex>
          <Flex gap={8}>
            {!!yearFilterProps && (
              <Flex column itemsCenter>
                <Box css={{ height: '$4' }} />
                <Select
                  onChange={(newValue) => {
                    if (yearFilterProps?.onChangeFilter) {
                      yearFilterProps.onChangeFilter(newValue);
                    }
                  }}
                  size="xs"
                  options={yearFilterProps.options}
                  value={yearFilterProps.options.find(
                    (opt) => opt.value === yearFilterProps.value,
                  )}
                />
                <Box css={{ height: '$4' }} />
              </Flex>
            )}
            {!!monthFilterProps && (
              <Flex column itemsCenter>
                <Box css={{ height: '$4' }} />
                <Select
                  onChange={(newValue) => {
                    if (monthFilterProps?.onChangeFilter) {
                      monthFilterProps.onChangeFilter(newValue);
                    }
                  }}
                  size="xs"
                  options={monthFilterProps.options}
                  value={monthFilterProps.options.find(
                    (opt) => opt.value === monthFilterProps.value,
                  )}
                />
                <Box css={{ height: '$4' }} />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      {data?.labels?.length === 0 ? (
        <Flex justify="center" align="center">
          <Text>Sin datos</Text>
        </Flex>
      ) : (
        <>
          {total !== undefined && (
            <Text color="gray900" medium l lineHeight="l">
              {toCurrency(total)} MXN
            </Text>
          )}
          <Flex
            css={{
              gap: '$12',
              flex: '1',
              flexDirection: 'column',
              '@xs': {
                flexDirection: 'row',
              },
            }}
          >
            <Flex justify="center" align="center">
              <Doughnut
                data={data}
                options={options}
                redraw
                updateMode="resize"
                width={width}
                height={height}
              />
            </Flex>
            <Flex
              direction="row"
              gap={8}
              css={{
                flex: 1,
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridAutoRows: 'max-content',
                overflow: 'scroll',
              }}
            >
              {combinedData?.map((sliceData, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledChartRowsContainer key={index}>
                  <RowItem>
                    <RowItemColor
                      css={{
                        backgroundColor: sliceData.backgroundColor,
                      }}
                    />
                    <RowItemLabel>{sliceData.label as string}</RowItemLabel>
                  </RowItem>
                  <ChartNumberValue
                    type={valueType}
                    value={sliceData.data}
                    data={data}
                  />
                </StyledChartRowsContainer>
              ))}
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
}
