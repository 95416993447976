import { DataTable, FilterProvider } from '@konta/ui';
import type { Cfdi, Workflow } from 'types/entities';
import usePreviousPeriods from './usePreviousPeriodsTable';
import filtersList from './filtersList';
import DeclarationEntryFromCfdiModalForm from '../DeclarationEntryFromCfdiModalForm';

interface PreviousPeriodsTableProps {
  source: 'incomes' | 'expenses';
  regimeOptionsWithDeclarationId: {
    label: string;
    value: string;
    declarationId: number;
  }[];
  workflow: Workflow | null;
  toggleIsAddEntryModalOpen: () => void;
  isAddEntryModalOpen: boolean;
  setSelectedCfdis: (cfdis: Cfdi[]) => void;
}
export default function PreviousPeriodsTable({
  source,
  regimeOptionsWithDeclarationId,
  workflow,
  toggleIsAddEntryModalOpen,
  isAddEntryModalOpen,
  setSelectedCfdis,
}: PreviousPeriodsTableProps) {
  const {
    cfdis,
    columns,
    pagination,
    cfdisLoading,
    setFilters,
    selectedCfdis,
    refetchCfdis,
  } = usePreviousPeriods({
    source,
    setSelectedCfdis,
  });
  return (
    <FilterProvider
      filters={filtersList}
      filterStorageId="incomesPreviousPeriodsTable"
      onFilterChange={setFilters}
    >
      {isAddEntryModalOpen && (
        <DeclarationEntryFromCfdiModalForm
          refetchCfdis={refetchCfdis}
          workflow={workflow}
          isOpen={isAddEntryModalOpen}
          toggleIsOpen={toggleIsAddEntryModalOpen}
          cfdis={selectedCfdis}
          regimeOptionsWithDeclarationId={regimeOptionsWithDeclarationId}
        />
      )}

      <DataTable
        // @ts-ignore TODO: fix typing with react-table
        columns={columns}
        data={cfdis}
        withHover
        loading={cfdisLoading}
        dataTableId="incomesPreviousPeriodsTable"
        manualPagination
        manualPaginationProps={pagination}
        withFilterControl
      />
    </FilterProvider>
  );
}
