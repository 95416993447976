import dayjs from 'dayjs';
import _ from 'lodash';
import type { Workflow } from 'types/entities';

export function generateMonths(): number[] {
  return Array.from({ length: 12 }, (_, i) => i + 1);
}

export function generateMonthNames(
  format: 'MMMM' | 'MMM' | 'MM' | 'M' = 'MMM',
): string[] {
  dayjs.locale('es-mx');
  return generateMonths().map((month) =>
    dayjs()
      .month(month - 1)
      .format(format),
  );
}

export function generateMonthNamesForWorkflows(
  workflows: (Workflow | null)[] = [],
  format: 'MMMM' | 'MMM' | 'MM' | 'M' = 'MMM',
): string[][] {
  return generateMonths().map((month) => {
    const monthIdx = month - 1;
    const workflow = workflows.find(
      (wf) => dayjs(wf?.start_date).month() === monthIdx,
    );
    const extraText = [];
    if (workflow?.is_initial) {
      extraText.push('🏁');
    }
    if (workflow?.arrears) {
      extraText.push('🕑');
    }

    return [dayjs().month(monthIdx).format(format), extraText.join(' ')];
  });
}

export const isSelectedMonth = (
  month: string | null,
  selectedMonth: string | null,
): boolean => {
  if (!selectedMonth || !month) {
    return false;
  }
  return month === selectedMonth;
};

type MonthNameFromDateOptions = {
  date: string;
  capitalize?: boolean;
  format?: 'MMMM' | 'MMM' | 'MM' | 'M';
};
export function getMonthNameFromDate({
  date,
  format = 'MMM',
  capitalize,
}: MonthNameFromDateOptions) {
  const monthName = dayjs(date).format(format);
  return capitalize ? _.capitalize(monthName) : monthName;
}

export function sumGroupValues(values: number[]): number {
  return values.reduce(
    (prev, curr) =>
      (Number.isNaN(prev) ? 0 : prev) + (Number.isNaN(curr) ? 0 : curr),
    0,
  );
}

export function sumData<T extends Record<string, number>>(data: T, data2: T) {
  return Object.keys(data).reduce((acc, key) => {
    // @ts-ignore for match clean data
    acc[key] = data[key] + data2[key];
    return acc;
  }, {} as T);
}
