import { styled } from '@konta/ui';
export const Root = styled('div', {
  display: 'flex',
  gap: '$12',
  alignItems: 'center',
  justifyContent: 'end',
  padding: '$12',
  flex: 1,
  '@md': {
    padding: '$24',
  },
});
