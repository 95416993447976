import { useSearchParams } from 'react-router-dom';

export default function useTypeformParams() {
  const [search] = useSearchParams();
  const source = search.get('param') || '';
  const name = search.get('name') || '';
  const lastName = search.get('last_name') || '';
  const email = search.get('email') || '';
  const phone = search.get('phone') || '';
  const isFromTypeform = source === 'TFv1';

  const formData = {
    name,
    lastName,
    email,
    phone: phone.trim(),
  };
  return {
    isFromTypeform,
    formData,
  };
}
