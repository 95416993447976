import {
  ORDERS_FETCH,
  ORDERS_FETCH_SUCCESS,
  ORDERS_FETCH_FAILURE,
  ORDER_PAY,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAILURE,
} from '@constants/actionTypes';

export const fetchOrders = (_) => ({
  type: ORDERS_FETCH,
});
export const fetchOrdersSuccess = (orders) => ({
  type: ORDERS_FETCH_SUCCESS,
  payload: orders,
});
export const fetchOrdersFailure = (_) => ({
  type: ORDERS_FETCH_FAILURE,
});

export const payOrder = (order_id, navigate, callback) => ({
  type: ORDER_PAY,
  payload: {
    order_id,
    navigate,
    callback,
  },
});
export const payOrderSuccess = (order, callback) => ({
  type: ORDER_PAY_SUCCESS,
  payload: order,
  callback,
});
export const payOrderFailure = () => ({
  type: ORDER_PAY_FAILURE,
});
