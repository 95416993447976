import { useCallback, useEffect, useRef, useState } from 'react';
import { useToggle } from 'rooks';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import useFeature from '@hooks/useFeature';
import { COMMAND_MENU } from '@constants/featureFlags';

export default function useCommandMenuControl() {
  const [open, toggleOpen] = useToggle();
  const navigate = useNavigate();
  const [pages, setPages] = useState<string[]>(['home']);
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const [isCommandMenuEnabled] = useFeature(COMMAND_MENU);

  const navigateTo = (to: string, opt?: NavigateOptions) => {
    toggleOpen();
    navigate(to, opt);
  };

  const focusSearchInput = () => {
    inputSearchRef?.current?.focus();
  };

  const popPage = useCallback(() => {
    setPages((prevPages) => [...prevPages.slice(0, -1)]);
  }, []);

  const pushPage = useCallback((page: string) => {
    setPages((prevPages) => [...prevPages, page]);
  }, []);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (['k', 'K'].includes(e.key) && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        toggleOpen();
      }
    };
    if (isCommandMenuEnabled) {
      document.addEventListener('keydown', down);
    }
    return () => {
      if (isCommandMenuEnabled) {
        document.removeEventListener('keydown', down);
      }
    };
  }, [isCommandMenuEnabled, toggleOpen]);

  return {
    showCommandMenu: open,
    toggleShowCommandMenu: toggleOpen,
    navigateTo,
    inputSearchRef,
    focusSearchInput,
    popPage,
    pages,
    pushPage,
  };
}
export type CommandMenuControlReturnType = ReturnType<
  typeof useCommandMenuControl
>;
