import { ReactNode, useState } from 'react';
import { ConfirmModal } from '@components/Modal';
import { useNavigate } from 'react-router-dom';

interface RenderProps {
  confirm: () => void;
}

interface ShowOldInvoiceProps {
  children: (renderProps: RenderProps) => ReactNode;
}

export default function ShowOldInvoice({ children }: ShowOldInvoiceProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const toggleShowConfirmation = () => setShowConfirmation(!showConfirmation);
  const onAccept = () => {
    navigate('/app/legacy-invoice');
  };

  return (
    <>
      {children({ confirm: () => toggleShowConfirmation() })}
      <ConfirmModal
        open={showConfirmation}
        title="Atención"
        onClose={toggleShowConfirmation}
        onCancel={toggleShowConfirmation}
        onAccept={onAccept}
      >
        Esta acción borrará los datos actuales en el formulario ¿deseas
        continuar?
      </ConfirmModal>
    </>
  );
}
