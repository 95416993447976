import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'rooks';
import { Select } from '@konta/ui';
import { fetchClientsSuppliers } from '@redux/actions';
import clientToOption from '@util/clientToOption';
import isGenericRfc from '@util/isGenericRfc';
import ClientModalForm from '@components/ClientModalForm';
import SelectInput from '@components/SelectInput';
import ConfirmModal from '@components/Modal/ConfirmModal';

export default function FormikClientSelect({
  name,
  onClientUpdated,
  onChange,
  newDesign,
  ...props
}) {
  const formik = useFormikContext();
  const [field, meta, helpers] = useField({
    name,
    ...props,
  });

  const dispatch = useDispatch();
  const [showForm, toggleShowForm] = useToggle(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showConfirmation, toggleShowConfirmation] = useToggle(false);

  const taxableEntity = useSelector((state) => {
    return state.taxableEntity.taxable_entity;
  });

  useEffect(() => {
    dispatch(fetchClientsSuppliers(taxableEntity));
  }, [dispatch, taxableEntity]);

  // #region select
  const clients = useSelector((state) => {
    return (
      state.clientsSuppliers.clients_suppliers?.filter?.((item) => {
        return item.relationship_type === 'client';
      }) ?? []
    );
  });

  const options = useMemo(() => {
    return clients.map(clientToOption);
  }, [clients]);

  const onAddClientClick = () => {
    setSelectedClient(null);
    toggleShowForm();
  };

  const onEditClientClick = () => {
    setSelectedClient(field.value.value);
    toggleShowForm();
  };

  const setValue = (newValue) => {
    helpers.setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const onInputChange = (selection) => {
    const { value } = selection;

    const hasRegimes = value.fiscal_regimes?.length > 0;
    const missingPostcode =
      !isGenericRfc(value.rfc) && !value.address?.postcode;
    const hasMissingFields = missingPostcode || !hasRegimes;

    setSelectedClient(value);

    if (hasMissingFields) {
      toggleShowConfirmation();
    } else {
      setValue(selection);
    }
  };
  // #endregion

  // #region modal form
  const onSubmitModalForm = (upsertedClient) => {
    const newValue = clientToOption(upsertedClient);
    setValue(newValue);
    toggleShowForm();

    if (onClientUpdated) {
      onClientUpdated(upsertedClient);
    }
  };
  // #endregion

  // #region confirmation
  const onAcceptConfirmation = () => {
    toggleShowConfirmation();
    toggleShowForm();
  };
  const onCancelConfirmation = () => {
    toggleShowConfirmation();
  };
  const onCloseConfirmation = () => {
    toggleShowConfirmation();
  };
  // #endregion

  const touched = meta.touched || formik.submitCount > 0;
  const error = meta.error && touched ? meta.error : null;

  const filterOptions = (candidate, input) => {
    const candidateLabel = candidate.label.toLowerCase();
    const candidateSubLabel = candidate.data?.subLabel?.toLowerCase?.();
    const inputLower = input?.toLowerCase();

    return (
      candidateLabel.includes(inputLower) ||
      candidateSubLabel?.includes?.(inputLower)
    );
  };

  return (
    <>
      {newDesign ? (
        <Select
          label="Cliente"
          placeholder="Buscar o agregar cliente"
          {...props}
          {...field}
          helperText={error}
          helperTextColor={error ? 'error' : 'normal'}
          onChange={onInputChange}
          options={options}
          onAdd={onAddClientClick}
          onEdit={onEditClientClick}
          filterOption={filterOptions}
        />
      ) : (
        <SelectInput
          {...field}
          {...props}
          error={error}
          onChange={onInputChange}
          options={options}
          withButton
          toggle={onAddClientClick}
          withSecondaryButton={!!field.value}
          secondaryToggle={onEditClientClick}
        />
      )}

      <ClientModalForm
        relationship="client"
        isOpen={showForm}
        toggle={toggleShowForm}
        onSubmit={onSubmitModalForm}
        client={selectedClient}
      />

      <ConfirmModal
        title="Editar cliente"
        open={showConfirmation}
        onAccept={onAcceptConfirmation}
        onCancel={onCancelConfirmation}
        onClose={onCloseConfirmation}
      >
        La información del cliente está incompleta, desea capturarla ahora
        mismo?
      </ConfirmModal>
    </>
  );
}

FormikClientSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onClientUpdated: PropTypes.func,
};

FormikClientSelect.defaultProps = {
  onClientUpdated: null,
};
