/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Media,
  Spacer,
  Text,
  styled,
  Flex,
  Select,
} from '@konta/ui';
import CloseIcon from 'mdi-react/CloseIcon';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import { UseInvoice } from '@hooks/useInvoiceForm';
import ShowOldInvoice from './ShowOldInvoice';
import SaveInvoiceTemplate from './SaveInvoiceTemplate';
import { useParams } from 'react-router-dom';
import { InvoiceTemplate } from 'types/entities';
import useFeature from '@hooks/useFeature';
import { INVOICE_TEMPLATE } from '@constants/featureFlags';

const StyledHeader = styled('header', {
  display: 'flex',
  alignItems: 'center',
  lineHeight: '$l',
  gap: '$10',
  padding: '$24 $16 $8', // top, left-right, bottom
  marginRight: '$24', // close button space
  '@sm': {
    padding: '$24 $24 $8', // top, left-right, bottom
  },
  variants: {
    divided: {
      true: {
        borderBottom: 'solid 1px $gray200',
      },
    },
    fullScreen: {
      true: {
        margin: '0',
        padding: '$24',
      },
    },
  },
});

interface NewInvoiceModalHeaderProps {
  showInvoicePreview: boolean;
  toggleShowInvoicePreview: () => void;
  onClose: () => void;
  invoice: UseInvoice;
  onSubmit: () => void;
  refetchTemplates?: () => void;
  invoiceTemplates?: InvoiceTemplate[];
}

export default function NewInvoiceModalHeader({
  toggleShowInvoicePreview,
  showInvoicePreview,
  onClose,
  invoice,
  onSubmit,
  invoiceTemplates,
  refetchTemplates,
}: NewInvoiceModalHeaderProps) {
  const params = useParams();
  const [invoiceTemplate] = useFeature(INVOICE_TEMPLATE);
  const isEditingTemplate = !!params.template;

  const togglePreviewLabel = showInvoicePreview
    ? 'Ocultar vista previa'
    : 'Mostrar vista previa';

  const { has_csd: hasCsd } = invoice.taxableEntity;

  const invoiceTemplateOptions = invoiceTemplates?.map((template) => ({
    value: template,
    label: template.invoice_template.name,
  }));

  if (isEditingTemplate) {
    return (
      <StyledHeader divided fullScreen>
        <Button icon variant="text">
          <CloseIcon onClick={onClose} />
        </Button>
        <Flex gap={10}>
          <Text size="m" regular color="black">
            {/*@ts-ignore draft should not be a type of Cfdi*/}
            Editando plantilla: {invoice?.draft?.invoice_template?.name}
          </Text>
        </Flex>
        <Spacer />
        <Media query="sm">
          <Button
            size="xs"
            variant="outlined"
            onClick={toggleShowInvoicePreview}
          >
            {togglePreviewLabel}
          </Button>
          <SaveInvoiceTemplate invoice={invoice} refetch={refetchTemplates}>
            {({ confirm, loading }) => (
              <Button
                size="xs"
                variant="outlined"
                onClick={confirm}
                loading={loading}
              >
                Guardar plantilla
              </Button>
            )}
          </SaveInvoiceTemplate>
        </Media>
      </StyledHeader>
    );
  }

  return (
    <StyledHeader divided fullScreen>
      <Button icon variant="text">
        <CloseIcon onClick={onClose} />
      </Button>
      <Flex gap={10}>
        <Text size="m" regular color="black">
          Nueva factura
        </Text>
        {invoiceTemplate && (
          <Select
            css={{ minWidth: '150px' }}
            onChange={(newValue: unknown) => {
              invoice.setInvoiceTemplate(newValue);
            }}
            placeholder="Selecciona una plantilla"
            size="s"
            options={invoiceTemplateOptions}
          />
        )}
      </Flex>
      <Spacer />
      <Media query="sm">
        <ShowOldInvoice>
          {({ confirm }) => (
            <Button size="xs" variant="minimal" onClick={confirm}>
              Usar módulo de facturación anterior
            </Button>
          )}
        </ShowOldInvoice>
        <Button size="xs" variant="outlined" onClick={toggleShowInvoicePreview}>
          {togglePreviewLabel}
        </Button>
      </Media>
      {invoiceTemplate && (
        <SaveInvoiceTemplate invoice={invoice} refetch={refetchTemplates}>
          {({ confirm }) => (
            <Button size="xs" variant="outlined" onClick={confirm}>
              Guardar plantilla
            </Button>
          )}
        </SaveInvoiceTemplate>
      )}
      <Button
        color="primary"
        size="xs"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={onSubmit}
        disabled={!hasCsd}
        loading={invoice.isSubmitting}
      >
        Facturar
      </Button>
      <Media query="<sm">
        <ShowOldInvoice>
          {({ confirm }) => (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button icon variant="minimal" size="xs">
                  <DotsVerticalIcon />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={toggleShowInvoicePreview}>
                  {togglePreviewLabel}
                </DropdownMenuItem>
                <DropdownMenuItem onClick={confirm}>
                  Usar módulo de facturación anterior
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </ShowOldInvoice>
      </Media>
    </StyledHeader>
  );
}
