import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  belvoLinksConnectionDeleteSuccess,
  belvoLinksConnectionDeleteFailure,
} from '../actions';

export default function* ({ payload }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(api.delete, `belvo_links/${payload.id}`, {
      headers: { Authorization: `${access_token}` },
    });
    yield call(StatusHandler, {
      response,
      title: 'Desvinculado',
      message: `${payload.institution} desvinculado con éxito`,
      status: response.status,
      success: belvoLinksConnectionDeleteSuccess,
      failure: belvoLinksConnectionDeleteFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: belvoLinksConnectionDeleteFailure,
    });
  }
}
