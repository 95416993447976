import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@konta/ui';
import Sidebar from '@containers/Sidebar';
import TopNav from '@containers/TopNav';

const StyledMain = styled('main', {
  display: 'flex',
  flexDirection: 'column',
});

export default function UserLayout({ children }) {
  return (
    <>
      <div id="app-container" className="group-container">
        <Sidebar />
        <StyledMain>
          <TopNav />
          {children}
        </StyledMain>
        <div id="section-level" />
      </div>
      <div id="app-level" />
    </>
  );
}

UserLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
