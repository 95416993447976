import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { Button } from '@konta/ui';
import getErrorMessage from '@util/getErrorMessage';
import postInviteSuggestedReferral from '@api/postInviteSuggestedReferral';
import { NotificationManager } from '@components';

const onError = (error) => {
  const message = getErrorMessage(error);
  NotificationManager.error(message, 'Error', 10000);
};

export default function InviteSuggestedReferralButton({
  invitationId,
  onInvite,
}) {
  const inviteSuggestedReferral = useMutation(postInviteSuggestedReferral, {
    onSuccess: onInvite,
    onError,
  });

  return (
    <Button
      variant="text"
      color="primary"
      size="m"
      loading={inviteSuggestedReferral.isLoading}
      onClick={() => inviteSuggestedReferral.mutate(invitationId)}
    >
      Invitar
    </Button>
  );
}

InviteSuggestedReferralButton.propTypes = {
  invitationId: PropTypes.number.isRequired,
  onInvite: PropTypes.func,
};

InviteSuggestedReferralButton.defaultProps = {
  onInvite: null,
};
