import { all, fork, takeLatest } from 'redux-saga/effects';
import { DOCUMENTS_FETCH, DOCUMENTS_POST } from '@constants/actionTypes';
import fetchSaga from './fetch';
import postSaga from './post';

export function* watchFetch() {
  yield takeLatest(DOCUMENTS_FETCH, fetchSaga);
}

export function* watchPost() {
  yield takeLatest(DOCUMENTS_POST, postSaga);
}

export default function* rootSaga() {
  yield all([fork(watchFetch), fork(watchPost)]);
}
