import { useLocation, useNavigate } from 'react-router-dom';
import useStore from 'backoffice/store';
import useParentPath from './useParentPath';

export default function useCustomerHeaderActions(taxableEntityId: number) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const parentPath = useParentPath();
  const collapsed = useStore((state) => state.customerHeaderCollapsed);
  const toggleCollapsed = useStore(
    (state) => state.toggleCustomerHeaderCollapsed,
  );
  const previousTaxableEntities = useStore(
    (state) => state.previousTaxableEntities,
  );
  const goToCustomers = () => {
    navigate(parentPath);
  };
  const currentIndex = previousTaxableEntities.findIndex(
    (taxableEntity) => taxableEntity.id === taxableEntityId,
  );
  const hasPreviousCustomer = currentIndex > 0;
  const hasNextCustomer = currentIndex < previousTaxableEntities.length - 1;

  const pagination = {
    total: previousTaxableEntities.length || 1,
    currentPage: currentIndex === -1 ? 1 : currentIndex + 1,
    hasNextCustomer,
    hasPreviousCustomer,
  };

  const goToNextCustomer = () => {
    if (!hasNextCustomer) return;
    const nextCustomer = previousTaxableEntities[currentIndex + 1];
    const nextPath = pathname.replace(
      taxableEntityId.toString(),
      nextCustomer.id.toString(),
    );
    navigate(nextPath);
  };
  const goToPreviousCustomer = () => {
    if (!hasPreviousCustomer) return;
    const previousCustomer = previousTaxableEntities[currentIndex - 1];
    const previousPath = pathname.replace(
      taxableEntityId.toString(),
      previousCustomer.id.toString(),
    );
    navigate(previousPath);
  };

  const isLastPage = pagination.currentPage === pagination.total;

  return {
    collapsed,
    toggleCollapsed,
    goToCustomers,
    pagination,
    goToNextCustomer,
    goToPreviousCustomer,
    isLastPage,
  };
}
