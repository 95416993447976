import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardTitle, CardBody, Label } from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField,
} from 'availity-reactstrap-validation';

import { Colxx } from '@components/CustomBootstrap';
import { updateTaxableEntity } from '@redux/actions';
import { convertNullToEmpty } from '@util/Forms';

import { SimpleStateButton } from '@components';
import { rfcPattern } from '@constants/regex';

class InvoicingDataCard extends Component {
  handleSubmit = (event, errors, values) => {
    const { taxable_entity } = this.props.taxableEntity;
    if (errors.length === 0) {
      const body = {
        taxable_entity: values,
      };
      this.props.updateTaxableEntity(body, taxable_entity.id);
    }
  };

  render() {
    const { taxable_entity, loading } = this.props.taxableEntity;
    return (
      <Card>
        <CardBody>
          <CardTitle tag="h4">Datos de facturación</CardTitle>
          <Row>
            <Colxx xxs={12} md={12} className="mb-4">
              <AvForm
                onSubmit={this.handleSubmit}
                model={convertNullToEmpty(taxable_entity)}
                className="has-float-label"
              >
                <Row>
                  <Colxx lg={6}>
                    <AvGroup>
                      <Label htmlFor="invoicing_legal_name">
                        Nombre legal de la empresa
                      </Label>
                      <AvInput name="invoicing_legal_name" />
                      <AvFeedback>Nombre invalido!</AvFeedback>
                    </AvGroup>
                  </Colxx>
                  <Colxx lg={6}>
                    <AvGroup>
                      <Label htmlFor="invoicing_rfc">RFC de la empresa</Label>
                      <AvField
                        name="invoicing_rfc"
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              'Porfavor ingrese un RFC con formato válido. Ejemplo: XAX010101000',
                          },
                          pattern: {
                            value: rfcPattern,
                            errorMessage:
                              'Porfavor ingrese un RFC con formato válido. Ejemplo: XAX010101000',
                          },
                        }}
                      />
                    </AvGroup>
                  </Colxx>
                </Row>
                <Colxx
                  xxs="12"
                  className="d-flex align-content-center justify-content-center"
                >
                  <SimpleStateButton loading={loading}>
                    Guardar
                  </SimpleStateButton>
                </Colxx>
              </AvForm>
            </Colxx>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ taxableEntity }) => {
  return { taxableEntity };
};

export default connect(mapStateToProps, { updateTaxableEntity })(
  InvoicingDataCard
);
