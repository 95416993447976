import { useCallback, useState } from 'react';
import type { DeclarationEntry } from 'types/entities';
import type { ItemWithHover } from '@konta/ui/dist/components/DataTable/types';
import type { Row } from '@tanstack/react-table';
import {
  getIssuerLegalName,
  getIssuerRfc,
  getReceiverLegalName,
  getReceiverRfc,
} from '@util/declarationEntries';

import useEntriesColumns from './useEntriesColumns';
import getModalInvoiceData from '../util/getModalInvoiceData';

interface UseEntriesTable {
  type: 'incomes' | 'expenses';
  setModalInvoiceDetails: (value: {
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  }) => void;
}

export default function useEntriesTable({
  type,
  setModalInvoiceDetails,
}: UseEntriesTable) {
  const [itemHovered, setItemHovered] =
    useState<ItemWithHover<DeclarationEntry>>(null);
  const openCfdisModal = useCallback(
    (row: Row<DeclarationEntry>) => {
      const isIncome = type === 'incomes';
      const entry = row.original;
      const rfc = isIncome ? getReceiverRfc(entry) : getIssuerRfc(entry);
      const legalName = isIncome
        ? getReceiverLegalName(entry)
        : getIssuerLegalName(entry);
      const { hasCfdiModal, modalInvoiceDetails } = getModalInvoiceData(
        rfc,
        legalName,
        isIncome,
      );
      if (!hasCfdiModal) return;
      setModalInvoiceDetails(modalInvoiceDetails);
    },
    [setModalInvoiceDetails, type],
  );
  const declarationEntriesColumns = useEntriesColumns({
    type,
    itemHovered,
    openCfdisModal,
  });

  return {
    declarationEntriesColumns,
    setItemHovered,
    openCfdisModal,
  };
}
