import React, { Fragment } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  TYPE_DATA_TRANS,
  REGIMEN_FISCAL_TRANS,
  PAYMENT_TYPE_TRANS,
  METHOD_DATA_TRANS,
} from '@util/Translate';
import { numberWithCommas } from '@util/Utils';
import { EXPORT_CODES } from '@data/newInvoiceData';
import getInvoicePdfColumnFlex from '@util/getInvoicePdfColumnFlex';
import { USE_CFDI_OPTIONS } from '@constants/invoicing';

// Para usar una font (esto incluye variantes de una font para bold/italic), antes es indispensable registrarla bajo un nombre

// Objeto final del pdf, cuyas partes fueron separadas debajo del mismo.
// NOTA: Document y page son objetos necesarios para crear un pdf, y tienen atributos asociados a la totalidad del pdf, como herramientas de debugging.
export function InvoicePDF({ cfdi, regimes, taxableEntity }) {
  return (
    <Document>
      <Page style={styles.container}>
        <HeadPDF cfdi={cfdi} taxableEntity={taxableEntity} />
        <InfoPDF cfdi={cfdi} regimes={regimes} />
        <TablePDF cfdi={cfdi} />
        <BaseTablePDF cfdi={cfdi} />
        {!!cfdi.taxes_attributes && <TaxesTablePDF cfdi={cfdi} />}
        {!!cfdi.discount && <DiscountTablePDF cfdi={cfdi} />}
        <TotalTablePDF cfdi={cfdi} />
      </Page>
    </Document>
  );
}

// Stilos para el documento, deben de agregarse por medio de un objeto o un array en el atributo de style del componente pdf
// Nota: no todos los estilos son válidos y algunos como los asociados al text o los tamaños funcionan de manera diferente, para mas informacion consultar react-pdf
const styles = StyleSheet.create({
  container: {
    fontSize: 10,
    padding: 15,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  separatorBottom: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#696969',
  },
  pt20: {
    paddingTop: 20,
  },
  pb20: {
    paddingBottom: 20,
  },
  bold: { fontFamily: 'Helvetica-Bold' },
});

function HeadPDF({ cfdi, regimes, taxableEntity }) {
  const {
    date,
    folio_number,
    expedition_place,
    export_code,
    payment_way,
    payment_method,
    type_of_receipt,
    currency,
    exchange_rate,
  } = cfdi;
  const exportCode =
    export_code === '01'
      ? null
      : EXPORT_CODES.find((item) => item.value === export_code);

  return (
    <View
      style={[
        styles.font,
        styles.row,
        styles.separatorBottom,
        styles.pb20,
        {
          justifyContent: 'space-between',
        },
      ]}
    >
      <View>
        <Text>
          Tipo: {type_of_receipt}-{TYPE_DATA_TRANS[type_of_receipt]}
        </Text>
        {!!payment_method && (
          <Text>
            Método de pago: {payment_method}-{METHOD_DATA_TRANS[payment_method]}
          </Text>
        )}
        <Text>Fecha Expedición: {new Date(date).toLocaleString('es-MX')}</Text>
        <Text>Lugar Expedición: {expedition_place}</Text>
        {!!folio_number && <Text>Folio: {folio_number}</Text>}
        {!!payment_way && (
          <Text>
            Forma de pago: {payment_way}-{PAYMENT_TYPE_TRANS[payment_way]}
          </Text>
        )}
        {!!exportCode && <Text>Objeto de impuesto: {exportCode.label}</Text>}
        <Text>Tipo de moneda: {currency}</Text>
        {!!exchange_rate && (
          <Text>Tipo de cambio: {numberWithCommas(exchange_rate)}</Text>
        )}
      </View>
      {!!taxableEntity?.logo_url && taxableEntity?.show_logo_in_invoice && (
        <Image
          src={taxableEntity.logo_url}
          style={{
            width: 50,
            height: 50,
          }}
        />
      )}
    </View>
  );
}

function InfoPDF({ cfdi, regimes }) {
  const { issuer_attributes, receiver_attributes, expedition_place } = cfdi;
  const issuerRegime = regimes?.find(
    (regime) => regime.sat_key === issuer_attributes?.fiscal_regime,
  );
  const receiverRegime = regimes?.find(
    (regime) => regime.sat_key === receiver_attributes?.fiscal_regime,
  );

  return (
    <View
      style={[styles.row, styles.separatorBottom, styles.pt20, styles.pb20]}
    >
      <View style={{ flex: 1, paddingRight: 20 }}>
        <Text style={[styles.bold]}>Emisor:</Text>
        <Text>{issuer_attributes.rfc}</Text>
        <Text>{issuer_attributes.name}</Text>
        <Text>
          Regimen Fiscal:{' '}
          {issuerRegime?.description || issuer_attributes.fiscal_regime}
          {REGIMEN_FISCAL_TRANS[issuer_attributes.fiscal_regime]}
        </Text>
        <Text>Código postal: {expedition_place}</Text>
      </View>
      <View style={{ flex: 1, paddingRight: 20 }}>
        <Text style={[styles.bold]}>Receptor:</Text>
        <Text>{receiver_attributes?.rfc}</Text>
        <Text>{receiver_attributes?.name}</Text>
        <Text>
          Regimen Fiscal:{' '}
          {receiverRegime?.description || receiver_attributes.fiscal_regime}
          {REGIMEN_FISCAL_TRANS[receiver_attributes.fiscal_regime]}
        </Text>
        <Text>Código postal: {receiver_attributes.zip_code}</Text>
        <Text>
          Uso CFDI: {receiver_attributes.cfdi_usage} -{' '}
          {
            USE_CFDI_OPTIONS.find(
              (useCFDI) => useCFDI.value === receiver_attributes.cfdi_usage,
            )?.label
          }
        </Text>
      </View>
    </View>
  );
}

function TablePDF({ cfdi: { concepts_attributes } }) {
  const attributes = {
    Cantidad: 'quantity',
    'NoIdent.': 'identification_number',
    'ClaveProd/Serv': 'prod_or_svc_key',
    Unidad: 'unit_measure',
    Descripcion: 'description',
    ValorUnitario: 'unit_value',
    Importe: 'amount',
  };

  return (
    <>
      <View
        style={[
          styles.row,
          styles.separatorBottom,
          {
            backgroundColor: '#f2f2f2',
            paddingBottom: 7,
            paddingTop: 7,
            fontSize: 8.5,
          },
        ]}
      >
        {Object.keys(attributes).map((attr, index) => (
          <View
            style={{
              flex: getInvoicePdfColumnFlex(attr),
              textAlign: 'right',
              paddingRight: 7,
            }}
            key={`pdf-header-${index}`}
          >
            <Text style={[styles.bold]}>
              {attr.replace(/([A-Z])/g, ' $1').trim()}
            </Text>
          </View>
        ))}
      </View>
      <View style={[styles.separatorBottom, { paddingBottom: 7 }]}>
        {concepts_attributes.map((item, i) => (
          <View style={[styles.row, { paddingTop: 7 }]} key={`products-${i}`}>
            {Object.values(attributes).map((attr, j) => (
              <Fragment key={`products-${i}${j}`}>
                <View
                  style={{
                    flex: getInvoicePdfColumnFlex(attr),
                    textAlign: 'right',
                    paddingRight: 7,
                    fontSize: 8.5,
                  }}
                >
                  <Text>
                    {attr === 'unit_value' || attr === 'amount'
                      ? numberWithCommas(item[attr])
                      : item[attr]}
                  </Text>
                </View>
              </Fragment>
            ))}
          </View>
        ))}
      </View>
    </>
  );
}

function BaseTablePDF({ cfdi: { subtotal } }) {
  return (
    <View
      style={[
        styles.row,
        styles.pt20,
        styles.pb20,
        {
          textAlign: 'right',
          backgroundColor: '#f2f2f2',
        },
      ]}
    >
      <View style={{ flex: 4, paddingRight: 10 }}>
        <Text style={styles.bold}>Subtotal: </Text>
      </View>
      <View style={{ flex: 1, paddingRight: 10 }}>
        <Text>{numberWithCommas(+subtotal)}</Text>
      </View>
    </View>
  );
}

function TaxesTablePDF({
  cfdi: {
    taxes_attributes: {
      total_taxes_transferred,
      total_taxes_detained,
      transferred_attributes,
      detained_attributes,
    },
  },
}) {
  return (
    <>
      {transferred_attributes?.length > 0 && (
        <>
          {!!transferred_attributes &&
            transferred_attributes.map((transferred, index) => {
              return (
                <View
                  key={`transfered-${index}`}
                  style={[
                    styles.row,
                    styles.pt5,
                    {
                      textAlign: 'right',
                      backgroundColor: '#f2f2f2',
                    },
                  ]}
                >
                  {Number(transferred.import) !== 0 ? (
                    <>
                      <View style={{ flex: 4, paddingRight: 10 }}>
                        <Text>
                          {' '}
                          {transferred.tax === '002' ? `IVA` : 'IEPS'}
                        </Text>
                      </View>
                      <View style={{ flex: 1, paddingRight: 10 }}>
                        <Text>
                          {transferred.factor_type === 'Tasa' &&
                            numberWithCommas(Number(transferred.import))}
                          {transferred.factor_type === 'Exento' && 'Exento'}
                        </Text>
                      </View>
                    </>
                  ) : (
                    <>
                      <View style={{ flex: 4, paddingRight: 10 }}>
                        <Text>
                          {' '}
                          {transferred.tax === '002' ? `IVA 0%` : 'IEPS 0%'}
                        </Text>
                      </View>
                      <View style={{ flex: 1, paddingRight: 10 }}>
                        <Text>
                          {numberWithCommas(Number(transferred.import))}
                        </Text>
                      </View>
                    </>
                  )}
                </View>
              );
            })}
          <View
            style={[
              styles.row,
              styles.pb20,
              {
                textAlign: 'right',
                backgroundColor: '#f2f2f2',
              },
            ]}
          >
            <View style={{ flex: 4, paddingRight: 10 }}>
              <Text style={styles.bold}>Total Impuestos Trasladados</Text>
            </View>
            <View style={{ flex: 1, paddingRight: 10 }}>
              <Text>{numberWithCommas(total_taxes_transferred || 0)}</Text>
            </View>
          </View>
        </>
      )}
      {detained_attributes?.length > 0 && (
        <>
          {!!detained_attributes &&
            detained_attributes.map((detained, index) => {
              return (
                <View
                  key={`detained-${index}`}
                  style={[
                    styles.row,
                    styles.pt5,
                    {
                      textAlign: 'right',
                      backgroundColor: '#f2f2f2',
                    },
                  ]}
                >
                  <View style={{ flex: 4, paddingRight: 10 }}>
                    <Text>
                      {detained.tax === '002' ? `IVA Retenido` : 'ISR'}
                    </Text>
                  </View>
                  <View style={{ flex: 1, paddingRight: 10 }}>
                    <Text>- {numberWithCommas(Number(detained.import))}</Text>
                  </View>
                </View>
              );
            })}
          <View
            style={[
              styles.row,
              styles.pb20,
              {
                textAlign: 'right',
                backgroundColor: '#f2f2f2',
              },
            ]}
          >
            <View style={{ flex: 4, paddingRight: 10 }}>
              <Text style={styles.bold}>Total Impuestos Retenidos</Text>
            </View>
            <View style={{ flex: 1, paddingRight: 10 }}>
              <Text>{`- ${numberWithCommas(total_taxes_detained)}`}</Text>
            </View>
          </View>
        </>
      )}
    </>
  );
}
function DiscountTablePDF({ cfdi: { discount } }) {
  return (
    <View
      style={[
        styles.row,
        styles.pb20,
        {
          textAlign: 'right',
          backgroundColor: '#f2f2f2',
        },
      ]}
    >
      <View style={{ flex: 4, paddingRight: 10 }}>
        <Text style={styles.bold}>Descuento:</Text>
      </View>
      <View style={{ flex: 1, paddingRight: 10 }}>
        <Text>{`- ${numberWithCommas(discount)}`}</Text>
      </View>
    </View>
  );
}

function TotalTablePDF({ cfdi: { total } }) {
  return (
    <View
      style={[
        styles.row,
        styles.separatorBottom,
        {
          textAlign: 'right',
          paddingBottom: 20,
          backgroundColor: '#f2f2f2',
        },
      ]}
    >
      <View style={{ flex: 4 }}>
        <Text style={styles.bold}>Total: </Text>
      </View>
      <View style={{ flex: 1, paddingRight: 10 }}>
        <Text style={styles.bold}>{numberWithCommas(total)}</Text>
      </View>
    </View>
  );
}
