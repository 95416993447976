export const fiscalRegimens = [
  {
    id: 2,
    description: 'Régimen de incorporación fiscal',
    sat_key: 621,
    alias: null,
  },
  {
    id: 3,
    description: 'Persona Moral',
    sat_key: 601,
    alias: null,
  },
  {
    id: 5,
    description: 'Arrendamiento',
    sat_key: 606,
    alias: null,
  },
  {
    id: 6,
    description: 'Régimen Simplificado de Confianza',
    sat_key: 626,
    alias: 'RESICO',
  },
  {
    id: 7,
    description: 'Sin obligaciones fiscales',
    sat_key: 616,
    alias: '',
  },
  {
    id: 8,
    description: 'Personas Morales con Fines no Lucrativos',
    sat_key: 603,
    alias: null,
  },
  {
    id: 9,
    description: 'Opcional para Grupos de Sociedades',
    sat_key: 623,
    alias: null,
  },
  {
    id: 10,
    description: 'Ingresos por Dividendos (socios y accionistas)',
    sat_key: 611,
    alias: null,
  },
  {
    id: 11,
    description: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
    sat_key: 622,
    alias: null,
  },
  {
    id: 13,
    description: 'Régimen de Enajenación o Adquisición de Bienes',
    sat_key: 607,
    alias: null,
  },
  {
    id: 14,
    description: 'Demás ingresos',
    sat_key: 608,
    alias: null,
  },
  {
    id: 15,
    description: 'Ingresos por intereses',
    sat_key: 614,
    alias: null,
  },
  {
    id: 16,
    description:
      'Residentes en el Extranjero sin Establecimiento Permanente en México',
    sat_key: 610,
    alias: null,
  },
  {
    id: 17,
    description: 'Régimen de los ingresos por obtención de premios',
    sat_key: 615,
    alias: null,
  },
  {
    id: 18,
    description:
      'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
    sat_key: 620,
    alias: null,
  },
  {
    id: 19,
    description: 'Coordinados',
    sat_key: 624,
    alias: null,
  },
  {
    id: 4,
    description:
      'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    sat_key: 625,
    alias: 'Plataformas',
  },
  {
    id: 12,
    description: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
    sat_key: 605,
    alias: 'Sueldos y Salarios',
  },
  {
    id: 1,
    description: 'Persona física con actividad empresarial',
    sat_key: 612,
    alias: 'PFAE',
  },
];

const fiscalRegimensOptions = fiscalRegimens.map((regime) => ({
  label: regime.description,
  key: regime.id,
  value: regime.id,
}));

export default fiscalRegimensOptions;
