import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '@components/CustomBootstrap';

export function StatementUploadNothing() {
  return (
    <Row className="mx-2">
      <Colxx>
        <p>
          Sino tuviste ningun ingreso este period fiscal, tanto en efectivo como
          en banco, selecciona el botón para continuar con el proceso.
        </p>
      </Colxx>
    </Row>
  );
}
