import { useEffect, useState, useCallback } from 'react';
import { useSwipeable } from 'react-swipeable';
import { ChevronLeftLineIcon, ChevronRightLineIcon } from '@konta/icons';
import {
  Dialog,
  DialogContent,
  VisuallyHidden,
  DialogTitle,
  DialogDescription,
} from '@konta/ui';
import { DialogHeader } from './DialogHeader';
import { DialogBody } from './DialogBody';
import { DialogActions } from './DialogActions';
import { customDialogContentCss, ArrowContainer, TapArea } from './styled';
import { InsightsDialogProps } from './types';
import NavigationArrowWithTooltip from './NavigationArrowWithTooltip';

function InsightsDialog({
  open,
  onOpenChange,
  taxableEntityFlows,
  currentFlowIndex,
  currentSlideIndex,
  onFlowChange,
  onSlideChange,
  headerProps,
  closeOnOutsideClick = false,
  closeOnEscapeKeyDown = false,
  onFlowVisit,
  onStepVisit,
  onFlowComplete,
  previousFlowTooltip,
  nextFlowTooltip,
  autoFocusOnOpen = false,
  swipeableOptions = {
    trackMouse: false,
    trackTouch: true,
    delta: 50,
  },
  isActiveDialog = true,
  contentCss,
  isLoading = false,
}: InsightsDialogProps) {
  const [animationState, setAnimationState] = useState<
    | 'exiting-left'
    | 'exiting-right'
    | 'entering-left'
    | 'entering-right'
    | 'none'
  >('none');

  const currentFlow = taxableEntityFlows[currentFlowIndex];
  const currentStep = currentFlow?.steps[currentSlideIndex];

  const hasNextFlow = currentFlowIndex < taxableEntityFlows.length - 1;
  const hasPreviousFlow = currentFlowIndex > 0;
  const hasNextSlide =
    currentStep && currentSlideIndex < currentFlow.steps.length - 1;
  const hasPreviousSlide = currentSlideIndex > 0;
  const hasNext = hasNextFlow || hasNextSlide;
  const hasPrevious = hasPreviousFlow || hasPreviousSlide;

  const handleFlowChange = useCallback(
    (newFlowIndex: number, isPrevious = false) => {
      if (isPrevious) {
        setAnimationState('exiting-left');
        setTimeout(() => {
          onFlowChange(newFlowIndex);
          onSlideChange(taxableEntityFlows[newFlowIndex].steps.length - 1);
          setAnimationState('entering-left');
        }, 300);
      } else {
        setAnimationState('exiting-right');
        setTimeout(() => {
          onFlowChange(newFlowIndex);
          onSlideChange(0);
          setAnimationState('entering-right');
        }, 300);
      }

      setTimeout(() => {
        setAnimationState('none');
      }, 600);
    },
    [onFlowChange, onSlideChange, taxableEntityFlows],
  );

  const handlePrevious = () => {
    if (isLoading) return;
    if (hasPreviousSlide) {
      onSlideChange(currentSlideIndex - 1);
    } else if (hasPreviousFlow) {
      handleFlowChange(currentFlowIndex - 1, true);
    }
  };

  const handleNext = () => {
    if (isLoading) return;
    if (hasNextSlide) {
      onSlideChange(currentSlideIndex + 1);
    } else if (hasNextFlow) {
      handleFlowChange(currentFlowIndex + 1);
    }
  };

  const handleNextFlow = () => {
    if (isLoading) return;
    if (hasNextFlow) {
      handleFlowChange(currentFlowIndex + 1);
    }
  };

  const handlePreviousFlow = () => {
    if (isLoading) return;
    if (hasPreviousFlow) {
      handleFlowChange(currentFlowIndex - 1, true);
    }
  };

  useEffect(() => {
    if (onFlowVisit && currentFlow) {
      onFlowVisit(currentFlow, currentFlowIndex);
    }
  }, [currentFlowIndex, taxableEntityFlows]);

  useEffect(() => {
    if (onStepVisit && currentFlow && currentStep) {
      onStepVisit(
        currentStep,
        currentSlideIndex,
        currentFlow,
        currentFlowIndex,
      );
    }
  }, [currentSlideIndex]);

  useEffect(() => {
    if (
      currentFlow &&
      currentStep &&
      currentSlideIndex === currentFlow.steps.length - 1 &&
      onFlowComplete
    ) {
      onFlowComplete(currentFlow, currentFlowIndex);
    }
  }, [currentSlideIndex]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft' && !isLoading) {
        handlePrevious();
      } else if (event.key === 'ArrowRight' && !isLoading) {
        handleNext();
      }
    };

    if (isActiveDialog) {
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [currentFlowIndex, currentSlideIndex, isActiveDialog, isLoading]);

  const handlers = useSwipeable({
    onSwipedLeft: handleNextFlow,
    onSwipedRight: handlePreviousFlow,
    ...swipeableOptions,
  });

  const swipeProps = isActiveDialog ? handlers : {};

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        closeOnOutsideClick={closeOnOutsideClick}
        closeOnEscapeKeyDown={closeOnEscapeKeyDown}
        autoFocusOnOpen={autoFocusOnOpen}
        closeButton={false}
        css={{ ...customDialogContentCss, ...contentCss }}
        data-state-animation={animationState}
        withOverlayAnimation={false}
        {...swipeProps}
      >
        <TapArea
          disabled={isLoading}
          role="button"
          aria-label="Anterior"
          tabIndex={hasPrevious ? 0 : -1}
          onClick={handlePrevious}
          side="left"
          withCursorPointer={hasPrevious}
        />
        <TapArea
          disabled={isLoading}
          role="button"
          aria-label="Siguiente"
          tabIndex={hasNext ? 0 : -1}
          onClick={handleNext}
          side="right"
          withCursorPointer={hasNext}
        />
        <VisuallyHidden.Root>
          <DialogTitle>
            {`Contenido del flujo ${currentFlowIndex} diapositiva ${currentSlideIndex}`}
          </DialogTitle>
          <DialogDescription>
            Recomendaciones {currentFlowIndex} {currentSlideIndex}
          </DialogDescription>
        </VisuallyHidden.Root>
        <ArrowContainer side="left">
          <NavigationArrowWithTooltip
            onClick={handlePrevious}
            disabled={!hasPrevious || isLoading}
            ariaLabel="Anterior"
            tooltipText={previousFlowTooltip}
          >
            <ChevronLeftLineIcon />
          </NavigationArrowWithTooltip>
        </ArrowContainer>

        {currentFlow && currentStep && (
          <>
            <DialogHeader
              currentSlide={currentSlideIndex + 1}
              totalSlides={currentFlow.steps.length}
              {...headerProps}
            />
            <DialogBody
              taxableEntityFlowStep={currentStep}
              isLoading={isLoading}
            />
            <DialogActions
              onPrevious={handlePrevious}
              onNext={handleNext}
              hasPrevious={hasPrevious}
              hasNext={hasNext}
              isLoading={isLoading}
            />
          </>
        )}

        <ArrowContainer side="right">
          <NavigationArrowWithTooltip
            onClick={handleNext}
            disabled={!hasNext || isLoading}
            ariaLabel="Siguiente"
            tooltipText={nextFlowTooltip}
          >
            <ChevronRightLineIcon />
          </NavigationArrowWithTooltip>
        </ArrowContainer>
      </DialogContent>
    </Dialog>
  );
}

export default InsightsDialog;
