import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Flex, Text, Divider } from '@konta/ui';
import type { TaxableEntity, Workflow } from 'types/entities';
import DeclarationEntriesTotalsModal from 'shared/components/DeclarationEntriesTotalsModal';
import WorkflowStatus from './WorkflowStatus';
import usePreviousSummary from './usePreviousSummary';
import SingleSimplifiedCalculation from './SingleSimplifiedCalculation';
import Wrapper from './Wrapper';
import Entries from './Entries';

interface PreviousSummaryProps {
  workflow: Workflow;
  setShowOldWorkflow: () => void;
  selectedMonth: string;
  availableWorkflow: Workflow | null;
  hasPendingPreviousMonthWorkflow: boolean;
  workflows: Workflow[];
}

interface ContentComponentProps {
  selectedWorkflow: Workflow;
  workflows: Workflow[];
  onHandleEntriesView: (
    monthIdx: string,
    type: 'income' | 'expense',
    selectedWorkflow: Workflow,
  ) => void;
  detailView: string;
  setDetailView: (detailView: string) => void;
  breadcrumbs: {
    label: string;
    onClick: () => void;
    isActive: boolean;
  }[];
  detailViews: Record<string, string>;
  taxableEntity: TaxableEntity | null;
  filters: Record<string, string>;
  workflowFromGridTable: Workflow;
}

function ContentComponent({
  workflows,
  selectedWorkflow,
  onHandleEntriesView,
  detailView,
  setDetailView,
  breadcrumbs,
  detailViews,
  taxableEntity,
  filters,
  workflowFromGridTable,
}: ContentComponentProps) {
  const filteredWorkflows = useMemo(() => {
    const mainDate = dayjs(selectedWorkflow.start_date).month();
    return workflows
      .filter((dateObject) => dayjs(dateObject.start_date).month() === mainDate)
      .map((wf) => wf.id);
  }, [workflows, selectedWorkflow]);

  if (detailView === detailViews.entries) {
    return (
      <Wrapper
        breadcrumbs={breadcrumbs}
        onClickBackBtn={() => setDetailView(detailViews.grid)}
      >
        <Entries
          workflowsId={filteredWorkflows}
          taxableEntity={taxableEntity}
          filters={filters}
          period={dayjs(selectedWorkflow.start_date).year()}
          workflowFromGridTable={workflowFromGridTable}
        />
      </Wrapper>
    );
  }
  return (
    <SingleSimplifiedCalculation
      workflows={workflows}
      selectedWorkflow={selectedWorkflow}
      onHandleEntriesView={onHandleEntriesView}
    />
  );
}
export default function PreviousSummary({
  workflow,
  setShowOldWorkflow,
  selectedMonth,
  availableWorkflow,
  hasPendingPreviousMonthWorkflow,
  workflows,
}: PreviousSummaryProps) {
  const {
    workflowInitialDate,
    closeEntriesTotalsModal,
    entriesTotalsModalType,
    detailView,
    setDetailView,
    breadcrumbs,
    detailViews,
    onHandleEntriesView,
    filters,
    taxableEntity,
    workflowFromGridTable,
  } = usePreviousSummary(workflow);

  return (
    <Flex
      className="app-container"
      css={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '$10',
        flexShrink: 0,
      }}
    >
      {!!entriesTotalsModalType && (
        <DeclarationEntriesTotalsModal
          open={!!entriesTotalsModalType}
          toggleOpen={closeEntriesTotalsModal}
          date={workflow.start_date}
          type={entriesTotalsModalType}
        />
      )}
      <Flex
        css={{
          width: '100%',
          gap: '$10',
          flexDirection: 'column',
        }}
      >
        <Flex column>
          <Text bold xl data-cy="workflow-date-title">
            Declaración de {workflowInitialDate}
          </Text>
          <Divider />
        </Flex>
        <Flex column>
          <WorkflowStatus
            workflow={workflow}
            setShowOldWorkflow={setShowOldWorkflow}
            selectedMonth={selectedMonth}
            availableWorkflow={availableWorkflow}
            hasPendingPreviousMonthWorkflow={hasPendingPreviousMonthWorkflow}
          />
        </Flex>
      </Flex>
      <Flex
        css={{
          width: '100%',
        }}
      >
        <ContentComponent
          workflows={workflows}
          selectedWorkflow={workflow}
          detailView={detailView}
          setDetailView={setDetailView}
          breadcrumbs={breadcrumbs}
          detailViews={detailViews}
          onHandleEntriesView={onHandleEntriesView}
          filters={filters}
          taxableEntity={taxableEntity}
          workflowFromGridTable={workflowFromGridTable}
        />
      </Flex>
    </Flex>
  );
}
