import React, { useContext, useMemo } from 'react';
import { Flex } from '@konta/ui';
import { ScheduleCallContext } from '@providers/ScheduleCallProvider';
import useFeature from '@hooks/useFeature';
import * as chat from '@util/chat';
import { CALENDLY_MODAL } from '@constants/featureFlags';
import AdvisoryChannelsGroup from './AdvisoryChannelsGroup';
import { ReactComponent as ScheduleCallIcon } from './icons/schedule-call.svg';
import { ReactComponent as EmailIcon } from './icons/email.svg';
import { ReactComponent as ChatIcon } from './icons/chat.svg';
import { ReactComponent as PhoneCallIcon } from './icons/phone-call.svg';
import { ReactComponent as WhatsappIcon } from './icons/whatsapp.svg';
import { ReactComponent as TalkToSupervisorIcon } from './icons/talk-to-a-supervisor.svg';
import { ReactComponent as TaxConsultingIcon } from './icons/tax-consulting.svg';
import { ReactComponent as InvoicingConsultingIcon } from './icons/invoicing-consulting.svg';
import { ReactComponent as AppConsultingIcon } from './icons/app-consulting.svg';
import { ReactComponent as CalculatorISRIcon } from './icons/calculator-ISR.svg';
import { ReactComponent as CalculatorIVAIcon } from './icons/calculator-IVA.svg';
import { ReactComponent as ChooseRegimenIcon } from './icons/choose-regimen.svg';
import { ReactComponent as KontaAcademyIcon } from './icons/konta-academy.svg';
import { ReactComponent as WebinarsIcon } from './icons/webinars.svg';
import { ReactComponent as YoutubeIcon } from './icons/youtube.svg';
import { ReactComponent as HelpCenterIcon } from './icons/help-center.svg';
import { ReactComponent as BlogIcon } from './icons/blog.svg';

export default function AdvisoryChannels() {
  const { toggleCalendly } = useContext(ScheduleCallContext);
  const [calendlyModalEnabled] = useFeature(CALENDLY_MODAL);
  const advisoryChannels = useMemo(() => {
    return [
      {
        title: 'Canales de asesoría',
        channels: [
          {
            title: 'Whatsapp Oficial',
            description: 'Tiempo de espera: 15 minutos',
            icon: WhatsappIcon,
            button: {
              link: 'https://wa.me/525570055271',
              text: 'Ir a Whatsapp',
            },
          },
          {
            title: 'Chat en Plataforma',
            description: 'Tiempo de espera: 5-10 minutos',
            icon: ChatIcon,
            button: {
              action: chat.open,
              text: 'Ir al Chat',
            },
          },
          {
            title: 'Teléfono',
            description: 'Sin tiempo de espera',
            icon: PhoneCallIcon,
            button: {
              target: '_self',
              link: 'tel:+525570055271',
              text: '55 7005 5271',
            },
          },
          {
            title: 'Correo',
            description: 'Tiempo de espera: 4 horas',
            icon: EmailIcon,
            button: {
              target: '_self',
              link: 'mailto:soporte@konta.com',
              text: 'Ir al correo',
            },
          },
          ...(calendlyModalEnabled
            ? [
                {
                  title: 'Agendar llamada',
                  description: 'Sin tiempo de espera',
                  icon: ScheduleCallIcon,
                  button: {
                    text: 'Ir a agendar',
                    action: toggleCalendly,
                  },
                },
              ]
            : []),
        ],
      },
      {
        title: 'Asesoría personalizada',
        channels: [
          {
            title: 'Asesoría sobre cómo facturar',
            description:
              'Recibe una capacitación personalizada sobre cómo facturar en nuestro portal, en el SAT o en cualquier portal de facturación.',
            icon: InvoicingConsultingIcon,
            button: {
              link: 'https://wa.me/525570055271?text=Hola,%20solicito%20una%20asesoría%20personalizada%20de%20facturación',
              text: 'Solicitar Asesoría',
            },
          },
          {
            title: 'Asesoría fiscal de impuestos',
            description:
              'Recibe unas asesoría personalizada con un contador fiscalista para saber cómo pagar menos impuestos o resolver dudas.',
            icon: TaxConsultingIcon,
            button: {
              link: 'https://wa.me/525570055271?text=Hola,%20solicito%20una%20asesoría%20personalizada%20de%20impuestos',
              text: 'Solicitar Asesoría',
            },
          },
          {
            title: 'Asesoría sobre la plataforma',
            description:
              'Recibe unas asesoría personalizada con un contador fiscalista para saber cómo pagar menos impuestos o resolver dudas.',
            icon: AppConsultingIcon,
            button: {
              link: 'https://wa.me/525570055271?text=Hola,%20solicito%20una%20asesoría%20personalizada%20de%20konta.com',
              text: 'Solicitar Asesoría',
            },
          },
          {
            title: 'Tengo un problema, hablar con un supervisor',
            description:
              'Si tuviste un problema que por alguna razón no pudiste solucionar con nuestro equipo de atención, agenda una llamada con un supervisor.',
            icon: TalkToSupervisorIcon,
            button: {
              link: 'https://wa.me/525570055271?text=Hola,%20solicito%20una%20llamada%20con%20un%20supervisor',
              text: 'Solicitar Asesoría',
            },
          },
        ],
      },
      {
        title: 'Capacitación',
        channels: [
          {
            title: 'Konta Academy',
            description:
              'Toma un curso para aprender lo mínimo indispensable para sobrevivir al SAT y tener una mejor administración fiscal y financiera.',
            icon: KontaAcademyIcon,
            button: {
              link: 'https://konta.com/cursos/konta-academy',
              text: 'Ir a Konta Academy',
            },
          },
          {
            title: 'Webinars',
            description:
              'Tenemos dos webinars en vivo a la semana de capacitación sobre impuestos, konta.com y para resolver dudas generales.',
            icon: WebinarsIcon,
            button: {
              link: 'https://landing.konta.com/capacitaciones',
              text: 'Ir a Webinars',
            },
          },
          {
            title: 'Youtube',
            description:
              'Visita nuestro canal de youtube donde encontrarás tutoriales y contenido de todo tipo para resolver tus dudas.',
            icon: YoutubeIcon,
            button: {
              link: 'https://www.youtube.com/@KontaMexicoOficial',
              text: 'Ir a Youtube',
            },
          },
          {
            title: 'Centro de Ayuda',
            description:
              'Con nuestro centro de ayuda podrás resolver cualquier duda que tengas sin la necesidad de hablar con un humano.',
            icon: HelpCenterIcon,
            button: {
              link: 'https://konta.kustomer.help/',
              text: 'Ir al centro de Ayuda',
            },
          },
          {
            title: 'Blog',
            description:
              'Visita nuestro blog y aprende de lso temas que más te interesen sobre emprendimiento, freelancing e impuestos.',
            icon: BlogIcon,
            button: {
              link: 'https://konta.com/blog',
              text: 'Ir al Blog',
            },
          },
        ],
      },
      {
        title: 'Herramientas',
        channels: [
          {
            title: 'Calculadora de IVA',
            description:
              'Utiliza la calculadora de IVA para estimar de manera sencilla el IVA a pagar.',
            icon: CalculatorIVAIcon,
            button: {
              link: 'https://konta.com/herramientas/calculadora-iva',
              text: 'Ir calculadora (IVA)',
            },
          },
          {
            title: 'Calculadora de ISR',
            description:
              'Utiliza la calculadora de ISR para estimar de manera sencilla el ISR a pagar.',
            icon: CalculatorISRIcon,
            button: {
              link: 'https://konta.com/herramientas/calculadora-isr',
              text: 'Ir calculadora (ISR)',
            },
          },
          {
            title: 'Escoge tu Régimen Fiscal',
            description:
              'Responde unas preguntas y obtén el régimen que más se adecúa a tus necesidades y donde pagarás menos impuestos.',
            icon: ChooseRegimenIcon,
            button: {
              link: 'https://k4mz6rhhujx.typeform.com/to/JlNuXSoc',
              text: 'Ir al Quiz',
            },
          },
        ],
      },
    ];
  }, [calendlyModalEnabled, toggleCalendly]);

  return (
    <Flex className="app-container" gap={20} direction="column">
      {advisoryChannels.map((advisoryChannel) => (
        <AdvisoryChannelsGroup
          key={advisoryChannel.title}
          advisoryChannelGroup={advisoryChannel}
        />
      ))}
    </Flex>
  );
}
