import React, { useEffect } from 'react';
import { Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { belvoTokenGet } from '@redux/actions';
import { Colxx } from '@components';
import { Text } from '@konta/ui';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import SecondaryPhaseButton from '@components/SecondaryPhaseButton';

const primaryPayload = {
  workflow: {
    next_phase: 'primary',
  },
};

const secondaryPayload = {
  workflow: {
    next_phase: 'secondary_phase',
  },
};

export default function HasExemptIva() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(belvoTokenGet());
  }, [dispatch]);

  return (
    <WorkflowLayout
      header={<WorkflowHeader title="Ayudanos a contestar esta pregunta" />}
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton payload={primaryPayload}>Si</PrimaryPhaseButton>
          <SecondaryPhaseButton variant="contained" payload={secondaryPayload}>
            No (continuar)
          </SecondaryPhaseButton>
        </>
      }
    >
      <Text x2l>
        ¿Vendiste algún producto o servicio especial que tenga IVA al 0% o al
        8%? o ¿Vendiste a empresas en el extranjero? (No es común)
      </Text>
      <div>
        <p className="text-muted mb-1">
          Ejemplos de productos o servicios con IVA al 0%:
        </p>
        <div className="text-muted">
          <Row>
            <Colxx md={2}>
              <li>Comida</li>
              <li>Hielo</li>
            </Colxx>
            <Colxx md={3}>
              <li>Agua</li>
              <li>Servicios Médicos</li>
            </Colxx>
          </Row>
        </div>
      </div>
    </WorkflowLayout>
  );
}
