import {
  Card,
  Text,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from '@konta/ui';
import * as chat from '@util/chat';
import Loader from '@components/Loader';
import { Overlay } from './styled';

interface NotCiecModalProps {
  isLoading?: boolean;
}

export default function NotCiecModal({ isLoading }: NotCiecModalProps = {}) {
  return (
    <Overlay>
      <Card>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <CardHeader
              header={
                <Text xl>No pudimos sincronizar tu información con el SAT</Text>
              }
            />
            <CardContent>
              <Text>
                La sincronización con el SAT es crucial para una contabilidad
                precisa y eficiente. Para poder ver tu Visor Fiscal es necesario
                actualizar o configurar tu ciec desde la configuración.
              </Text>
              <br />
              <Text>¿Tienes preguntas?, Contáctanos por el chat</Text>
            </CardContent>
            <CardActions gap={10} justify="center">
              <Button variant="outlined" color="primary" onClick={chat.open}>
                Abrir chat
              </Button>
            </CardActions>
          </>
        )}
      </Card>
    </Overlay>
  );
}
