import { all, fork, takeLatest } from 'redux-saga/effects';
import { FISCAL_REGIMENS_FETCH } from '@constants/actionTypes';

import fetchSaga from './fetch';

export function* watchFetch() {
  yield takeLatest(FISCAL_REGIMENS_FETCH, fetchSaga);
}

export default function* rootSaga() {
  yield all([fork(watchFetch)]);
}
