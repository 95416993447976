import LetterAvatar from 'react-avatar';
import { Box, Flex, Text, styled } from '@konta/ui';
import { Img } from '@components';

export const ContainerIcon = styled(Box, {
  cursor: 'pointer',
});

export const Content = styled(Flex, {
  p: '$24',
  gap: 10,
  pt: '0',
});

export const HeaderContainer = styled('div', {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '$20',
  '@md': { flexDirection: 'row' },
  py: '$8',
  borderBottom: '1px solid $gray200',
  variants: {
    alignItems: {
      true: {
        alignItems: 'center',
      },
    },
  },
});

export const HeaderContent = styled(Flex, {
  width: '100%',
});

export const LogoContainer = styled(Box, {
  height: '100px',
  width: '100px',
  display: 'none',
  '@sm': { display: 'block' },
});

export const Avatar = styled(LetterAvatar, {
  mb: '$4',
  variants: {
    collapsed: {
      true: {
        width: '24px!important',
        height: '24px!important',
      },
    },
  },
});
export const Logo = styled(Img, {
  height: '100%',
});
export const Title = styled(Text, {
  fontSize: '$m',
  '@md': { fontSize: '$l' },
});

export const CustomerName = styled('span', {
  fontSize: '$l',
  fontWeight: '$medium',
  lineHeight: '$l',
  '@md': { fontSize: '$2xl', lineHeight: '$x2l' },
});

export const MobileLogoContainer = styled(Box, {
  display: 'block',
  '@sm': { display: 'none' },
});

export const Divider = styled('div', {
  background: '$gray200',
  height: '1px',
  width: '100%',
});

export const CollapsedAvatarContainer = styled(Box, {
  height: '24px',
});

export const CollapsedCustomerName = styled(Text, {
  fontSize: '$s',
  fontWeight: '$medium',
  lineHeight: '$s',
});

export const PaginationText = styled(Text, {
  variants: {
    isLastPage: {
      false: {
        color: '$gray500',
      },
    },
  },
});

export const SimpleContainer = styled(Flex, {
  border: '1px solid $gray200',
  borderRadius: '$m',
  p: '$12',
});
