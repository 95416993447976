import React from 'react';
import { Card, CardHeader, CardContent, Flex, Text, Button } from '@konta/ui';
import { useToggle } from 'rooks';
import useUser from '@hooks/useUser';
import ScanQrModal from './ScanQrModal';
import DisabledTwoFactorModal from './DisabledTwoFactorModal';
import BackupCodesModal from './BackupCodesModal';

export default function SettingSecurity() {
  const { user } = useUser();
  const [isOpenBackupCodesModal, toggleBackupCodesModal] = useToggle(false);
  const [isOpenScanQrModal, toggleScanQrModal] = useToggle(false);
  const [isOpenDisabled2FactorModal, toggleDisabled2FactorModal] =
    useToggle(false);

  return (
    <>
      {isOpenScanQrModal && (
        <ScanQrModal
          isOpen={isOpenScanQrModal}
          toggle={toggleScanQrModal}
          toggleBackupCodeModal={toggleBackupCodesModal}
        />
      )}
      {isOpenBackupCodesModal && (
        <BackupCodesModal
          isOpen={isOpenBackupCodesModal}
          toggle={toggleBackupCodesModal}
        />
      )}
      {isOpenDisabled2FactorModal && (
        <DisabledTwoFactorModal
          isOpen={isOpenDisabled2FactorModal}
          toggle={toggleDisabled2FactorModal}
        />
      )}

      <Flex css={{ maxWidth: '100%', '@md': { maxWidth: '50%' } }}>
        <Card>
          <CardHeader
            header={
              <Text bold xl>
                Autenticación de dos pasos
              </Text>
            }
            subheader={
              <Text m>
                Konta sugiere que actives la autenticación de dos pasos para
                mantener tu cuenta segura. Al utilizar una aplicación de
                autenticación además de tu contraseña, te aseguras que ninguna
                otra persona inicie sesión en tu cuenta.
              </Text>
            }
          />
          <CardContent>
            <Flex>
              {user?.otp_required_for_login ? (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={toggleDisabled2FactorModal}
                >
                  Desactivar autenticación de dos pasos
                </Button>
              ) : (
                <Button color="primary" onClick={toggleScanQrModal}>
                  Habilitar autenticación de dos pasos
                </Button>
              )}
            </Flex>
          </CardContent>
        </Card>
      </Flex>
    </>
  );
}
