import { useEffect, useState } from 'react';

// Based on https://www.npmjs.com/package/use-synced-local-storage

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, (newValue: T) => boolean] {
  const checkGetLocalValue = () => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      return initialValue;
    }
  };

  const [storage, setStorage] = useState<T>(checkGetLocalValue());

  const setStorageContent = (value: T | ((prevValue: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storage) : value;

      setStorage(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      window.dispatchEvent(new StorageEvent('storage'));
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener('storage', () => setStorage(checkGetLocalValue()));
    return () => {
      window.removeEventListener('storage', () =>
        setStorage(checkGetLocalValue()),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storage, setStorageContent];
}
