import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Text } from '@konta/ui';
import { useTaxableEntity } from '@hooks';
import useUser from '@hooks/useUser';
import Loader from '@components/Loader';
import useCurrentProcessesQuery from '@hooks/useCurrentProcessesQuery';
import ProcessStatusCard from './ProcessStatusCard.tsx';
import ProcessesList from './ProcessesList.tsx';

export default function ProcessesHome() {
  const { taxable_entity: taxableEntity } = useTaxableEntity();
  const { user } = useUser();
  const [selectedProcess, setSelectedProcess] = useState();
  const { currentProcesses, loadingCurrentProcesses } =
    useCurrentProcessesQuery();

  const activeUserName =
    user?.nickname || taxableEntity?.legal_name || user?.first_name;

  const filteredProcesses = useMemo(
    () =>
      currentProcesses.filter((process) =>
        ['OnboardingOnboardingProcess', 'FinishOnboardingProcess'].includes(
          process.type,
        ),
      ),
    [currentProcesses],
  );

  useEffect(() => {
    if (filteredProcesses?.length > 0) {
      setSelectedProcess(filteredProcesses[0]);
    }
  }, [filteredProcesses]);

  if (loadingCurrentProcesses) {
    return <Loader />;
  }

  return (
    <Flex direction="column" className="app-container">
      <Text x3l css={{ mb: '$16' }}>
        ¡Vamos a ponerte en orden, {activeUserName}!
      </Text>
      <Flex
        justify="between"
        css={{ marginBottom: '$16' }}
        gap={16}
        wrap="wrap"
      >
        <Flex direction="column" css={{ flex: 1 }}>
          <ProcessesList
            processes={filteredProcesses}
            setSelectedProcess={setSelectedProcess}
          />
        </Flex>
        {selectedProcess && (
          <ProcessStatusCard selectedProcess={selectedProcess} />
        )}
      </Flex>
    </Flex>
  );
}
