import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useField, useFormikContext } from 'formik';
import { useToggle } from 'rooks';
import { fetchProducts } from '@redux/actions';
import { Select } from '@konta/ui';
import productToOption from '@util/productToOption';
import ProductModalForm from '@components/ProductModalForm';
import SelectInput from '@components/SelectInput';

export default function FormikUserProductsSelect({
  name,
  onChange,
  newDesign,
  ...props
}) {
  const [field, meta, helpers] = useField({
    name,
    ...props,
  });
  const formik = useFormikContext();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const [showForm, toggleShowForm] = useToggle(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const taxableEntity = useSelector((state) => {
    return state.taxableEntity.taxable_entity;
  });

  useEffect(() => {
    dispatch(fetchProducts(taxableEntity));
  }, [dispatch, taxableEntity]);

  const options = useMemo(() => {
    return products.map(productToOption);
  }, [products]);

  // #region select
  const onInputChange = (value) => {
    helpers.setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const onAddClick = () => {
    setSelectedProduct(null);
    toggleShowForm();
  };

  const onEditClick = () => {
    setSelectedProduct(field.value.value);
    toggleShowForm();
  };

  const touched = meta.touched || formik.submitCount > 0;
  const error = meta.error && touched ? meta.error : null;
  // #endregion

  // #region modal form
  const onSubmit = (updatedProduct) => {
    // TODO: Don't merge objects to complete missing data when backend can response the updated product
    const product = {
      ...selectedProduct,
      ...updatedProduct,
    };

    toggleShowForm();
    onInputChange(productToOption(product));
  };
  // #endregion

  const fiscalRegime = useMemo(
    () => formik?.values?.fiscalRegime?.value,
    [formik?.values?.fiscalRegime?.value],
  );
  const client = useMemo(
    () => formik?.values?.client?.value,
    [formik?.values?.client?.value],
  );

  return (
    <>
      {newDesign ? (
        <Select
          placeholder="Buscar o agregar producto"
          {...props}
          {...field}
          helperText={error}
          helperTextColor={error ? 'error' : 'normal'}
          onChange={onInputChange}
          options={options}
          onAdd={onAddClick}
          onEdit={onEditClick}
        />
      ) : (
        <SelectInput
          {...props}
          {...field}
          error={error}
          onChange={onInputChange}
          options={options}
          withButton
          toggle={onAddClick}
          withSecondaryButton={!!field.value?.value}
          secondaryToggle={onEditClick}
        />
      )}
      <ProductModalForm
        fiscalRegime={fiscalRegime}
        client={client}
        product={selectedProduct}
        isOpen={showForm}
        onClose={toggleShowForm}
        onSubmit={onSubmit}
      />
    </>
  );
}

FormikUserProductsSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  newDesign: PropTypes.bool,
};

FormikUserProductsSelect.defaultProps = {
  label: 'Producto',
  onChange: null,
  newDesign: false,
};
