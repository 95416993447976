import React, { Component } from 'react';
import { Row, Card, CardTitle, Button } from 'reactstrap';

import { Colxx } from '@components/CustomBootstrap';

class GlobalError extends Component {
  render() {
    return (
      <main className="main-center-container px-3 px-md-1">
        <div className="fixed-background" />
        <Row className="h-100">
          <Colxx xxs="12" md="5" className="mx-auto my-auto">
            <Card className="auth-card">
              <div className="form-side">
                <div className="d-flex justify-content-center align-items-center mb-5">
                  <div className="white">
                    <span className="logo-single" />
                  </div>
                </div>
                <div className="text-center">
                  <CardTitle tag="h4" className="mb-4">
                    ¡Perdón, no eres tu, soy yo!
                  </CardTitle>
                  <p className="mb-0 text-muted  mb-0">
                    Estamos experimentando un error de la plataforma, por favor
                    deja un feedback para ayudarnos a resolver el problema.
                  </p>
                  <p className="display-1 font-weight-bold mb-5">500</p>
                  <Button
                    onClick={this.props.handleOnReportClick}
                    color="primary"
                    className="btn-shadow"
                    size="lg"
                  >
                    FEEDBACK
                  </Button>
                </div>
              </div>
            </Card>
          </Colxx>
        </Row>
      </main>
    );
  }
}
export default GlobalError;
