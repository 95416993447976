import dayjs from 'dayjs';
import { useEffect } from 'react';
import useDeclarationEntriesPagination from 'shared/hooks/useDeclarationEntriesPagination';
import setLocalStorageFilters from 'shared/util/setLocalStorageFilters';

interface UseEntriesProps {
  workflowsId: number[];
  taxableEntityId: number;
  filters?: Record<string, string>;
  period: string | number;
}

export default function useEntries({
  workflowsId,
  taxableEntityId,
  filters,
  period,
}: UseEntriesProps) {
  const { declarationEntries, declarationEntriesLoading, remove } =
    useDeclarationEntriesPagination({
      itemsPerPage: 30000,
      params: {
        'q[declaration_workflow_id_in]': workflowsId,
        'q[declaration_is_active_true]': 1,
        'q[taxable_entity_id_eq]': taxableEntityId,
      },
      queryOptions: {
        enabled: workflowsId?.length > 0,
      },
    });

  useEffect(() => {
    const accountType = {
      expense: 'Gastos',
      income: 'Ingresos',
    }[filters?.type || ''];

    // get date from index month
    const indexMonth = filters?.month;
    const date = indexMonth
      ? dayjs()
          .month(+indexMonth - 1)
          .year(+period)
      : null;

    setLocalStorageFilters('tableFilters-annual-declaration-entries-table-v2', {
      ...(filters?.type && {
        general_declaration_entry_type: {
          label: accountType,
          value: accountType,
        },
      }),
      ...(date && {
        accounting_date: [
          date.startOf('month').toString(),
          date.endOf('month').toString(),
        ],
      }),
    });
  }, [filters, period]);

  useEffect(() => {
    if (workflowsId?.length === 0) {
      remove();
    }
  }, [workflowsId?.length]);

  return {
    declarationEntries,
    declarationEntriesLoading,
  };
}
