import { styled } from '@konta/ui';

const FormFieldWrapper = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
  flexDirection: 'column',
  gap: '$10',
  '@md': { flexDirection: 'row', gap: '$20' },
  '> div': { width: '100%', flex: 1 },
});

export default FormFieldWrapper;
