import { useQuery } from 'react-query';
import { FOREIGN_PRODUCTS } from '@constants/reactQueries';
import { ForeignDocument } from 'types/entities';
import getForeignProducts from '@api/getForeignProducts';

export default function useForeignProducts() {
  const {
    data = null,
    isLoading,
    ...rest
  } = useQuery<ForeignDocument[]>([FOREIGN_PRODUCTS], async () => {
    const foreignProducts = await getForeignProducts();
    return foreignProducts;
  });
  return {
    foreignProductsLoading: isLoading,
    foreignProducts: (data ?? []).map((entry) => ({
      ...entry,
      entry_type: 'foreign_products',
    })),
    ...rest,
  };
}
