import type { AxiosRequestConfig } from 'axios';
import type { TaxableEntityPublic } from 'types/entities';
import api from './index';

// eslint-disable-next-line import/prefer-default-export
export const getTPublicTaxableEntity = async (
  taxableEntityUuid: number | string,
  config?: AxiosRequestConfig<TaxableEntityPublic>,
) => {
  const { data } = await api.get<TaxableEntityPublic>(
    `taxable_entities/${taxableEntityUuid}/public_data/`,
    config,
  );
  return data;
};
