import React, { Component } from 'react';
import { Row, TabContent, TabPane, Nav } from 'reactstrap';
import { TabItem, Colxx, DocumentsTable, DocumentsUpload } from '@components';
import FiscalInformation from '@components/FiscalInformation';
import { fetchDocs } from '@redux/actions';
import { connect } from 'react-redux';

class Documents extends Component {
  state = {
    activeIndex: -1,
    activeFirstTab: '1',
    currentCategory: 'invoices',
    title: 'Facturas',
  };

  componentDidMount() {
    this.props.fetchDocs();
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }

    let category = '';
    switch (tab) {
      case '1':
        category = 'invoicesDocs';
        break;

      case '2':
        category = 'sales';
        break;

      case '3':
        category = 'declaration';
        break;

      default:
    }

    this.setState({
      title: this.getTitle(category),
    });
  };

  getTitle = (category) => {
    let title = '';
    switch (category) {
      case 'invoices':
        title = 'Facturas';
        break;

      case 'sales':
        title = 'Ingresos';
        break;

      case 'due':
        title = 'Gastos';
        break;

      case 'taxes':
        title = 'Ventas';
        break;

      default:
    }
    return title;
  };

  render() {
    const { documents, loading } = this.props;
    const { activeFirstTab } = this.state;
    return (
      <div className="app-container">
        <Row className="mb-4">
          <Colxx xxs="12" xs="12" md="12" lg="12">
            <Nav
              tabs
              className="separator-tabs position-relative ml-0 mr-0 mb-5"
            >
              <TabItem
                tabName="documents.tabs.upload"
                tabNumber="1"
                activeTab={activeFirstTab}
                toggleTab={this.toggleTab}
              />
              <TabItem
                tabName="documents.tabs.documents"
                tabNumber="2"
                activeTab={activeFirstTab}
                toggleTab={this.toggleTab}
              />
              <TabItem
                tabName="documents.tabs.declarations"
                tabNumber="3"
                activeTab={activeFirstTab}
                toggleTab={this.toggleTab}
              />
              <TabItem
                tabName="documents.tabs.fiscalInfo"
                tabNumber="4"
                activeTab={activeFirstTab}
                toggleTab={this.toggleTab}
              />
              {/*   <LinkVideoHelper
                label="Ver video"
                videoId="-FTM5VBhmEI"
                tooltipHelper="Ver video"
                iconId="VideoHelper-1"
                className="position-absolute btn-datepicker pr-0"
              /> */}
            </Nav>

            <TabContent activeTab={activeFirstTab}>
              {activeFirstTab === '1' && (
                <TabPane tabId="1">
                  <DocumentsUpload />
                </TabPane>
              )}
              {activeFirstTab === '2' && (
                <TabPane tabId="2">
                  {!loading && (
                    <DocumentsTable
                      category="legal_documents"
                      documents={documents.filter((doc) => doc.category.id !== 3)}
                      loading={loading}
                    />
                  )}
                </TabPane>
              )}
              {activeFirstTab === '3' && (
                <TabPane tabId="3">
                  {!loading && (
                    <DocumentsTable
                      category="declarations"
                      documents={documents.filter((doc) => doc.category.id === 3)}
                      loading={loading}
                      filterCategory={false}
                    />
                  )}
                </TabPane>
              )}
              {activeFirstTab === '4' && (
                <TabPane tabId="4">{!loading && <FiscalInformation />}</TabPane>
              )}
            </TabContent>
          </Colxx>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ documents }) => {
  return { ...documents };
};

export default connect(mapStateToProps, { fetchDocs })(Documents);
