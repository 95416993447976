import React, { useState } from 'react';
import { Flex, Tab, Tabs, Text } from '@konta/ui';
import { Link } from 'react-router-dom';
import TemplatePreview from '../TemplatePreview';

const INVOICE_HTML_URL = `${process.env.REACT_APP_API_HOST}/invoices/[uuid]`;
const EMAIL_HTML_URL = `${process.env.REACT_APP_API_HOST}/email_preview/[uuid]`;

/**
 * @param {{ invoice: import('@hooks/useInvoiceForm').UseInvoice }} param0
 */
export default function InvoicePreview({
  invoice,
  withSettingsText,
  withEmailHeader,
}) {
  const { draft } = invoice;
  const [tab, setTab] = useState(1);

  const invoicePdfUrl = INVOICE_HTML_URL.replace('[uuid]', draft?.uuid);
  const invoiceEmailUrl = EMAIL_HTML_URL.replace('[uuid]', draft?.uuid);
  const clientEmail = invoice.formik.values.client?.value?.email;

  return (
    <>
      <Flex direction="column">
        <Text xl bold lineHeight="xl" css={{ color: '#2B2B34' }}>
          Vista previa
        </Text>
        {withSettingsText && (
          <Text>
            Puedes personalizar tus facturas e emails en la{' '}
            <Link to="/app/settings/brand">
              <Text color="primary700">
                configuración de imagen de tu marca.
              </Text>
            </Link>
          </Text>
        )}
      </Flex>
      <Flex
        direction="column"
        css={{
          marginBottom: 0,
          position: 'sticky',
          top: 0,
          zIndex: 999,
          backgroundColor: 'white',
        }}
      >
        {/* Tabs */}
        <Flex gap={10} direction="column">
          <Tabs value={tab} onChange={setTab} variant="text">
            <Tab id={1}>Correo electrónico</Tab>
            <Tab id={2}>Factura en PDF</Tab>
          </Tabs>
          {tab === 1 && (
            <Flex direction="column">
              {withEmailHeader && (
                <Flex
                  direction="column"
                  css={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    p: '$12',
                    background: '$white',
                    boxShadow:
                      '0 15px 35px 0 rgba(65,69,82,.08) , 0 5px 15px 0 rgba(0,0,0,.12)',
                  }}
                >
                  <Text>Está es una vista previa de correo.</Text>
                  <Text>
                    <b>De:</b> soporte@konta.com
                  </Text>
                  <Text>
                    <b> Para:</b> {clientEmail}
                  </Text>
                </Flex>
              )}
              <TemplatePreview
                srcPreview={`${invoiceEmailUrl}?updated_at=${draft?.updated_at}`}
                width={600}
                style={{
                  ...(!withEmailHeader && {
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }),
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                }}
              />
            </Flex>
          )}
          {tab === 2 && (
            <TemplatePreview
              srcPreview={`${invoicePdfUrl}?updated_at=${draft?.updated_at}`}
              width={816}
              style={{ borderRadius: 10 }}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
}
