import { styled } from '@konta/ui';

export const IconContainer = styled('div', {
  display: 'flex',
  fontWeight: '500',
  fontSize: '32px',
  lineHeight: 'normal',
  '& img': {
    width: '32px',
    height: '32px',
    objectFit: 'contain',
  },
  '& svg': {
    width: 'auto',
    height: '32px',
  },
});

export const Title = styled('span', {
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '32px',
  color: '$primary700',
  alignSelf: 'stretch',
});

export const Subtitle = styled('span', {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  color: '$gray700',
  alignSelf: 'stretch',
  whiteSpace: 'pre-wrap',
});
