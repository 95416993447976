import { styled } from '@konta/ui';

export const BreadcrumbsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  pb: '22px',
  background: '$white',
  width: '100%',
  borderBottom: '1px solid $gray200',
});
