import { Flex } from '@konta/ui';
import ModalForm from 'shared/components/ModalForm';
import FormikWrapper from 'shared/components/FormikWrapper';
import FormikCheckbox from '@components/FormikCheckbox';
import FormikTextarea from '@components/FormikTextarea';
import NotificationModal from 'shared/components/NotificationModal';
import useRequestAdjustmentModal from './useRequestAdjustmentModal';
import { formWrapperCss, textAreaWrapperCss } from './styled';

interface RequestAdjustmentModalProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
}
export default function RequestAdjustmentModal({
  isOpen,
  toggleIsOpen,
}: RequestAdjustmentModalProps) {
  const {
    formik,
    isLoading,
    someValueIsTrue,
    openNotification,
    toggleNotification,
  } = useRequestAdjustmentModal({
    toggleIsOpen,
  });

  return (
    <>
      <NotificationModal
        isOpen={openNotification}
        toggleIsOpen={toggleNotification}
        type="success"
        btnText="Finalizar"
        title="Tu solicitud fue enviada con éxito"
        subtitle="Nuestro equipo de expertos estará revisando los detalles de tu solicitud. Pronto estaremos dando respuesta a tu requerimiento."
      />
      <ModalForm
        modalSize="sm"
        isLoading={isLoading}
        isOpen={isOpen}
        modalTitle="Asistencia al usuario"
        submitTextBtn="Continuar"
        modalSubtitle="A continuación selecciona el motivo por el cual estás solicitando un ajuste en tu declaración anual."
        submitForm={formik.submitForm}
        toggleIsOpen={toggleIsOpen}
        disableSubmit={!someValueIsTrue}
      >
        <FormikWrapper formik={formik} wrapperCss={formWrapperCss}>
          <FormikCheckbox
            name="imNotAgreeWithTaxes"
            supportText="El ISR a cargo es muy elevado"
            label="No estoy de acuerdo con el impuesto a cargo"
          />
          <FormikCheckbox
            name="wantToVerifyMyPartialities"
            supportText="Verificar cuanto más debo pagar"
            label="Quiero verificar mis parcialidades"
          />
          <FormikCheckbox
            name="wantToKnowPersonalDeductionBreakdown"
            supportText="Estaremos enviando el detalle a tu correo"
            label="Quiero conocer el desgloce de mis deducciones personales"
          />
          <FormikCheckbox
            name="wantToKnowTaxBreakdown"
            supportText="Estaremos enviando el detalle a tu correo"
            label="Quiero conocer el desgloce de mis impuestos"
          />
          <Flex direction="column" gap={10}>
            <FormikCheckbox
              name="wantToScheduleACall"
              supportText="Describa el motivo de su llamada"
              label="Quiero agendar una llamada con mi kontador"
              onChange={(value) => {
                if (!value) {
                  void formik.setFieldValue('callReason', '');
                }
              }}
            />
            {formik.values.wantToScheduleACall && (
              <Flex column css={textAreaWrapperCss}>
                <FormikTextarea
                  id="callReason"
                  name="callReason"
                  placeholder="Describa la razón de la llamada"
                  label={undefined}
                  rows={5}
                />
              </Flex>
            )}
          </Flex>
          <Flex direction="column" gap={10}>
            <FormikCheckbox
              name="others"
              supportText="Describa su inquietud o sugerencia"
              label="Otros"
              onChange={(value) => {
                if (!value) {
                  void formik.setFieldValue('othersReason', '');
                }
              }}
            />
            {formik.values.others && (
              <Flex column css={textAreaWrapperCss}>
                <FormikTextarea
                  id="othersReason"
                  name="othersReason"
                  placeholder="Describa su requerimiento"
                  label={undefined}
                  rows={5}
                />
              </Flex>
            )}
          </Flex>
        </FormikWrapper>
      </ModalForm>
    </>
  );
}
