import { AnualProcessStepType } from './entities';

export enum AnnualRouteType {
  Welcome = '/app/processes/annual/:processId/welcome',
  Charges = '/app/processes/annual/:processId/charges',
  Documents = '/app/processes/annual/:processId/documents',
  Resume = '/app/processes/annual/:processId/resume',
  Preferences = '/app/processes/annual/:processId/preferences',
  Rejection = '/app/processes/annual/:processId/rejection',
  Recommendations = '/app/processes/annual/:processId/recommendations',
  Upload = '/app/processes/annual/:processId/upload',
  Informative = '/app/processes/annual/:processId/informative',
}

export type AnnualSteps = {
  [type in AnualProcessStepType]: AnnualRouteType;
};
