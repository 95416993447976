import { styled } from '@konta/ui';

export const Container = styled('div', {
  display: 'flex',
  // padding: '10px', remove comment when add profile component
  alignItems: 'stretch',
  gap: '16px',
  alignSelf: 'stretch',
  flexDirection: 'column',
  '@md': {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
});
export const Card = styled('div', {
  display: 'flex',
  padding: '20px',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '24px',
  flex: '1 0 0',
  borderRadius: '8px',
  border: '1px solid $colors$gray200',
  background: '#FFF',
});

export const Divider = styled('hr', {
  all: 'unset',
  borderTop: 'solid 1px $colors$gray200',
  width: '100%',
});
