import {
  CredentialsProcessStep,
  ExpenseProcessStep,
  FeedbackProcessStep,
  IncomeProcessStep,
  MeantimeProcessStep,
  OnboardingProcessStep,
  OnboardingProcessStepType,
  OnboardingStep,
  ReferralsProcessStep,
  ScheduleProcessStep,
  WelcomeProcessStep,
  ProcessType,
  OnboardingProcessStepTypes,
} from 'types/entities';

export default function getOnboardingSteps(
  steps: OnboardingProcessStep[],
): OnboardingStep {
  const getStep = (type: OnboardingProcessStepType) =>
    steps.find((step) => step.type === type);

  return {
    type: ProcessType.OnboardingOnboardingProcess,
    welcome: getStep(OnboardingProcessStepTypes.Welcome) as WelcomeProcessStep,
    schedule: getStep(
      OnboardingProcessStepTypes.Schedule,
    ) as ScheduleProcessStep,
    meantime: getStep(
      OnboardingProcessStepTypes.Meantime,
    ) as MeantimeProcessStep,
    feedback: getStep(
      OnboardingProcessStepTypes.Feedback,
    ) as FeedbackProcessStep,
    referrals: getStep(
      OnboardingProcessStepTypes.Referrals,
    ) as ReferralsProcessStep,
    income: getStep(OnboardingProcessStepTypes.Income) as IncomeProcessStep,
    expense: getStep(OnboardingProcessStepTypes.Expense) as ExpenseProcessStep,
    credentials: getStep(
      OnboardingProcessStepTypes.Credentials,
    ) as CredentialsProcessStep,
  };
}
