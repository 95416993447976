/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Row } from 'reactstrap';
import { toCurrency as $ } from '@util/Utils';
import { Loader, Dropzone, ButtonConfirmModal, Colxx } from '@components';
import { uploadFiscalPeriodReceipt } from '@redux/actions';
import { useDispatch } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';

const dropzoneConfig = {
  autoProcessQueue: false,
  thumbnailHeight: 160,
  maxFilesize: 1,
  dictDefaultMessage: 'Sube tu comprobante de pago (opcional)',
  dictInvalidFileType: 'No puedes subir archivos de este tipo.',
  dictMaxFilesExceeded: 'Ya no puedes subir más archivos.',
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div
      style={{ maxWidth: '90%' }}
      className="dz-preview dz-file-preview mb-3"
    >
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            <img data-dz-thumbnail className="img-thumbnail border-0" />
            <i className="simple-icon-doc preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            <span data-dz-name />
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#" className="remove" data-dz-remove>
        <i className="glyph-icon simple-icon-trash" />
      </a>
    </div>
  ),
};

function CaptureLine({ fiscal_period, loading }) {
  const dispatch = useDispatch();
  const [files, setFiles] = React.useState([]);
  const updateHandler = (_) => {
    // Send files
    const formData = new FormData();
    for (const file of files) {
      formData.append('capture_line_receipts[]', file);
    }
    // formData.append("capture_line_receipts[]", files);
    formData.append('status', 'check_payment');
    dispatch(uploadFiscalPeriodReceipt(formData, fiscal_period.id));
  };

  const eventHandlers = {
    addedfile: (file) => {
      files.push(file);
      setFiles(files);
    },
    maxfilesexceeded: (file) => {
      files.pop();
      files.push(file);
      setFiles(files);
    },
    removedfile: (file) => {
      files.forEach((f, i) => {
        if (f.name === file.name && f.size === file.size) {
          files.splice(i, 1);
        }
      });
      setFiles(files);
    },
  };
  const isExpired = new Date(fiscal_period?.validation_date) < new Date();
  return (
    <Loader loading={loading}>
      {fiscal_period ? (
        <>
          <Row>
            <Colxx>
              <p>
                El importe a cargo determinado en esta declaración, deberá ser
                pagado en las instituciones de crédito autorizadas, utilizando
                para tal efecto la línea de captura que se indica.
              </p>
              <div className="p-3 border">
                <Row className="mb-3">
                  <div />
                  <Colxx md={7} className="w-min-300px mt-2">
                    Línea de Captura:
                    <div
                      className={`font-weight-bold text-small ${
                        fiscal_period.capture_line && isExpired && 'text-danger'
                      }`}
                    >
                      {fiscal_period.capture_line
                        ? isExpired
                          ? 'Línea de captura vencida'
                          : fiscal_period.capture_line
                        : 'NA'}
                    </div>
                  </Colxx>
                  <Colxx md={5} className="w-min-300px mt-2">
                    Importe total a pagar:
                    <div className="font-weight-bold text-small">
                      {$(fiscal_period.isr_total + fiscal_period.iva_total)}
                    </div>
                  </Colxx>
                </Row>
                <Row>
                  <Colxx md={7}>
                    Vigente hasta:
                    <div
                      className={`font-weight-bold text-small ${
                        fiscal_period.validation_date &&
                        isExpired &&
                        'text-danger'
                      }`}
                    >
                      {fiscal_period.validation_date
                        ? isExpired
                          ? 'Línea de captura vencida'
                          : fiscal_period.validation_date
                        : 'NA'}
                    </div>
                  </Colxx>
                </Row>
              </div>
            </Colxx>
          </Row>
          <Row className="p-3">
            <Colxx>
              <Dropzone djsConfig={dropzoneConfig} events={eventHandlers} />
            </Colxx>
          </Row>
          <Row className="justify-content-center pb-3">
            <ButtonConfirmModal
              buttonColor="primary"
              handleSubmit={updateHandler}
              cancelButton="Cancelar"
              confirmButton="Confirmar"
              buttonLabel="Pagado por línea de captura"
              loading={loading}
              isButtonDisabled={loading}
            >
              <i className="large-icon iconsmind-Danger" color="danger" />
              <p>Asegurate que el pago en el SAT ya haya sido realizado.</p>
            </ButtonConfirmModal>
          </Row>
        </>
      ) : null}
    </Loader>
  );
}

CaptureLine.propTypes = {
  fiscal_period: PropTypes.any,
  loading: PropTypes.any,
};

export default CaptureLine;
