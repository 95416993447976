import {
  ADDRESS_FETCH,
  ADDRESS_FETCH_SUCCESS,
  ADDRESS_FETCH_FAILURE,
  ADDRESS_UPDATE,
  ADDRESS_UPDATE_SUCCESS,
  ADDRESS_UPDATE_FAILURE,
} from '@constants/actionTypes';

export const addressFetch = (_) => ({
  type: ADDRESS_FETCH,
});
export const addressFetchSucess = (address) => ({
  type: ADDRESS_FETCH_SUCCESS,
  payload: address,
});
export const addressFetchFailure = (_) => ({
  type: ADDRESS_FETCH_FAILURE,
});

export const addressUpdate = (address, options, callback) => ({
  type: ADDRESS_UPDATE,
  payload: { address, options, callback },
});
export const addressUpdateSucess = (address) => ({
  type: ADDRESS_UPDATE_SUCCESS,
  payload: address,
});
export const addressUpdateFailure = (_) => ({
  type: ADDRESS_UPDATE_FAILURE,
});
