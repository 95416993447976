import { TWO_FACTOR_QR_CODE } from '@constants/reactQueries';
import { useQuery } from 'react-query';
import getQrCodeForTwoFactor from '@api/auth/getQrCodeForTwoFactor';

export default function useQrCodeTwoFactor() {
  const {
    data = [],
    isLoading,
    ...rest
  } = useQuery(TWO_FACTOR_QR_CODE, getQrCodeForTwoFactor);

  return { loadingQrCode: isLoading, data, ...rest };
}
