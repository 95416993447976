import React, { ReactNode, useState } from 'react';
import { Text, Flex } from '@konta/ui';
import * as yup from 'yup';
import { ConfirmModal, NotificationManager } from '@components';
import { useParams } from 'react-router-dom';
import putInvoiceDraft from '../../@api/putInvoiceDraft';
import invoiceFormToPayload from '../../@util/invoiceFormToPayload';
import { FormikProvider, useFormik } from 'formik';
import FormikTextInput from '../FormikTextInput';
import getErrorMessage from '../../@util/getErrorMessage';

interface RenderProps {
  confirm: () => void;
  loading?: boolean;
}

interface ShowOldInvoiceProps {
  children: (renderProps: RenderProps) => ReactNode;
  invoice: any;
  refetch?: () => void;
}

export default function SaveInvoiceTemplate({
  children,
  invoice,
  refetch,
}: ShowOldInvoiceProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const isEditTemplate = !!params.template;
  const toggleShowConfirmation = () => setShowConfirmation(!showConfirmation);
  const onAccept = async (values: { make_template_with_name?: string }) => {
    setLoading(true);
    try {
      const draftId = params.uuid as string;
      await putInvoiceDraft(draftId, {
        formikValues: invoice.formik.values,
        // @ts-ignore TODO: fix typing of invoiceFormToPayload
        stamp: invoiceFormToPayload({
          taxableEntity: invoice.taxableEntity,
          values: invoice.formik.values,
        }),
        make_template_with_name: !isEditTemplate
          ? values.make_template_with_name
          : undefined,
      });
      NotificationManager.success(
        'Se ha guardado la plantilla correctamente',
        'Plantilla de facturación',
      );
      if (!isEditTemplate && refetch) refetch();
    } catch (e) {
      const errorMessage = getErrorMessage(e) as string;
      NotificationManager.error(errorMessage, 'Plantilla de facturación');
    } finally {
      toggleShowConfirmation();
      setLoading(false);
    }
  };
  const validationSchema = yup.object().shape({
    make_template_with_name: yup.string().required('Campo requerido'),
  });

  const formik = useFormik({
    initialValues: {
      make_template_with_name:
        invoice.draft?.invoice_template?.name ||
        invoice?.templateInitialValues?.invoice_template?.name ||
        '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: onAccept,
  });

  if (isEditTemplate)
    return <>{children({ loading, confirm: () => onAccept({}) })}</>;

  return (
    <>
      {children({ confirm: () => toggleShowConfirmation() })}
      <FormikProvider value={formik}>
        <ConfirmModal
          open={showConfirmation}
          title="Guardar plantilla"
          onClose={toggleShowConfirmation}
          onCancel={toggleShowConfirmation}
          onAccept={formik.submitForm}
          loading={loading}
        >
          <Flex column gap={16}>
            <Text>
              Estás por guardar esta factura como plantila, podrás reutilizarla
              en el futuro. ¿Qué nombre le quieres dar?
            </Text>
            <FormikTextInput
              label="Nombre de la plantilla"
              name="make_template_with_name"
              placeholder="Personalizada 1"
              type="text"
            />
          </Flex>
        </ConfirmModal>
      </FormikProvider>
    </>
  );
}
