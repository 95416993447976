export * from './forms-validations';

export const convertNullToEmpty = (obj) => {
  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) {
        obj[key] = '';
      }
    });
    return obj;
  }
};
