import { BalanceAvailableForDeductionsFlowStep } from 'types/flows';
import toCurrency from '@util/toCurrency';
import { ReactComponent as Img } from './img.svg';
import {
  AvailableBalanceContainer,
  ImageContainer,
  Container,
  TextContainer,
} from './styled';

interface BalanceAvailableForDeductionsStepContentProps {
  balanceAvailableForDeductionsFlowStep: BalanceAvailableForDeductionsFlowStep;
}

export default function BalanceAvailableForDeductionsStepContent({
  balanceAvailableForDeductionsFlowStep,
}: BalanceAvailableForDeductionsStepContentProps) {
  const { state } = balanceAvailableForDeductionsFlowStep;
  const { available_balance } = state;

  return (
    <>
      <ImageContainer>
        <Img />
      </ImageContainer>
      <Container>
        <AvailableBalanceContainer>
          <span data-type="s">Saldo disponible al día de hoy</span>
          <span data-type="l">{toCurrency(+(available_balance || 0))}</span>
        </AvailableBalanceContainer>
        <TextContainer>
          Este saldo representa la cantidad total que tienes disponible para
          deducir en tu anual
        </TextContainer>
      </Container>
    </>
  );
}
