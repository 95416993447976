import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  Text,
} from '@konta/ui';
import { ChevronDownCrFr1OutlineIcon } from '@konta/icons';
import type { DeclarationEntry } from 'types/entities';
import getRegimeNameFromFiscalRegime from 'shared/util/getRegimeNameFromFiscalRegime';
import { UseIncomeListReturnType } from '@components/Workflow/EditIncomes/ClassifyIncomes/IncomesList/useIncomesList';

interface AccountingStatusDropdownProps {
  declarationEntry: DeclarationEntry;
  setSelectedEntry: UseIncomeListReturnType['setSelectedEntry'];
  activeDeclarations: UseIncomeListReturnType['activeDeclarations'];
  preferredFiscalRegime?: string | number | null;
}
export default function FiscalRegimeDropdown({
  declarationEntry,
  setSelectedEntry,
  activeDeclarations,
  preferredFiscalRegime,
}: AccountingStatusDropdownProps) {
  return (
    <Flex column css={{ minWidth: '110px' }}>
      <DropdownMenu>
        <DropdownMenuTrigger
          data-disabled
          asChild
          disabled={!!preferredFiscalRegime}
        >
          <Button
            size="xs"
            variant="outlined"
            rightIcon={<ChevronDownCrFr1OutlineIcon />}
          >
            {getRegimeNameFromFiscalRegime(
              activeDeclarations.find(
                (active) =>
                  active.fiscal_regime_id === declarationEntry.fiscal_regime_id,
              )?.fiscal_regime,
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={5}>
          {activeDeclarations.map((declaration) => (
            <DropdownMenuItem
              size="xs"
              onSelect={() => {
                const newRegimenName = getRegimeNameFromFiscalRegime(
                  declaration.fiscal_regime,
                );
                setSelectedEntry({
                  ...declarationEntry,
                  newDeclarationId: declaration.id,
                  modalHeader: 'Actualizar régimen fiscal',
                  title:
                    '¿Quieres actualizar el régimen fiscal de este ingreso?',
                  subtitle: (
                    <Text>
                      Este ingreso pasará a ser considerado para el régimen
                      fiscal
                      <Text bold>{newRegimenName}</Text>.
                    </Text>
                  ),
                });
              }}
            >
              <Flex align="center">
                {getRegimeNameFromFiscalRegime(declaration.fiscal_regime)}
              </Flex>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Flex>
  );
}
