import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '@redux/actions';
import { Card, CardBody, Button } from 'reactstrap';

function LogoutCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser(navigate));
  };

  return (
    <Card data-test="logout-card">
      <CardBody>
        <div className="clearfix">
          <div className="float-left mt-1">
            <h5 data-test="logout-text">Salir de la plataforma</h5>
          </div>
          <div className="float-right">
            <Button
              data-test="logout-button"
              color="danger"
              size="xs"
              onClick={handleLogout}
              className="mb-1"
              outline
            >
              Logout
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default LogoutCard;
