import { styled } from '@konta/ui';

export const Root = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const Item = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

export const Text = styled('span', {
  color: '$gray500',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '18px',
  variants: {
    active: {
      true: {
        color: '$gray700',
      },
    },
  },
});

export const Icon = styled('div', {
  width: '16px',
  height: '16px',
  svg: {
    path: {
      stroke: '$gray300',
    },
  },
});
