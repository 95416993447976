import { useCallback, useEffect, useMemo, useState } from 'react';
import useSSOParams from 'shared/hooks/useSSOParams';
import { getApiConfigSSO } from 'shared/util/sso';

export default function useSSO() {
  const [loading, setLoading] = useState(true);
  const { isFromSSO, accessToken, SSO } = useSSOParams();
  const { source, params } = SSO;

  const apiSSOConfig = useMemo(
    () => getApiConfigSSO(source, params),
    [params, source],
  );

  const doSSORequest = useCallback(async () => {
    if (!accessToken || !isFromSSO) return;
    setLoading(true);
    const redirectTo = await apiSSOConfig?.get(apiSSOConfig.config);
    window.location.replace(redirectTo || '');
  }, [accessToken, apiSSOConfig, isFromSSO]);

  useEffect(() => {
    doSSORequest().finally(() => {
      setLoading(false);
    });
  }, []);

  return loading;
}
