import { useSearchParams } from 'react-router-dom';
import { PaginationParamsManager, PaginationParams } from 'types/pagination';
import useShallowMemo from './useShallowMemo';

export interface UseUrlParamsPaginationOptions {
  updatingStrategy?: 'push' | 'replace'; // replace by default
  prefix?: string;
  params?: string[];
}

export default function useUrlParamsPagination({
  updatingStrategy = 'replace',
  params = [],
  prefix,
}: UseUrlParamsPaginationOptions = {}): PaginationParamsManager {
  const [searchParams, setSearchParams] = useSearchParams();

  const watchedParams =
    useShallowMemo(
      () =>
        ['page', ...params]
          .map((name) => [`${prefix || ''}${name}`, name])
          .reduce((result, [urlName, name]) => {
            const value = searchParams.get(urlName) || null;
            return { ...result, [name]: value };
          }, {} as PaginationParams),
      [params],
      [prefix, searchParams],
    ) || {};

  const setParam = (
    key: string,
    value: string[] | string | null | number | boolean | object | undefined,
  ) => {
    const currentUrlParams = Object.fromEntries(searchParams.entries());
    setSearchParams(
      { ...currentUrlParams, [`${prefix || ''}${key}`]: value as string },
      { replace: updatingStrategy === 'replace' },
    );
  };

  const setParams = (allParams: PaginationParams) => {
    Object.entries(allParams).forEach(([key, value]) => setParam(key, value));
  };

  return {
    params: watchedParams,
    setParam,
    setParams,
  };
}
