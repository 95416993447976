import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  BELVO_TOKEN_GET,
  BELVO_ORPHAN_LINKS_FETCH,
  BELVO_BANK_ACCOUNTS_FETCH,
  BELVO_LINKS_CONNECTION_CREATE,
  BELVO_LINKS_FETCH,
  BELVO_LINKS_CONNECTION_DELETE,
} from '@constants/actionTypes';

import belvoTokenSaga from './get_token';
import belvoOrphanLinksSaga from './orphan_links_fetch';
import belvoBankAccountsSaga from './accounts_fetch';
import belvoLinksCreateSaga from './belvo_links_create';
import belvoLinksFetchSaga from './belvo_links_fetch';
import belvoLinksDeleteSaga from './belvo_links_delete';

export function* watchBelvoTokenGet() {
  yield takeLatest(BELVO_TOKEN_GET, belvoTokenSaga);
}

export function* watchBelvoOrphanLinksFetch() {
  yield takeLatest(BELVO_ORPHAN_LINKS_FETCH, belvoOrphanLinksSaga);
}

export function* watchBelvoBankAccountsFetch() {
  yield takeLatest(BELVO_BANK_ACCOUNTS_FETCH, belvoBankAccountsSaga);
}

export function* watchBelvoLinksCreate() {
  yield takeLatest(BELVO_LINKS_CONNECTION_CREATE, belvoLinksCreateSaga);
}

export function* watchBelvoLinksFetch() {
  yield takeLatest(BELVO_LINKS_FETCH, belvoLinksFetchSaga);
}

export function* watchBelvoLinksDelete() {
  yield takeLatest(BELVO_LINKS_CONNECTION_DELETE, belvoLinksDeleteSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchBelvoTokenGet),
    fork(watchBelvoOrphanLinksFetch),
    fork(watchBelvoBankAccountsFetch),
    fork(watchBelvoLinksCreate),
    fork(watchBelvoLinksFetch),
    fork(watchBelvoLinksDelete),
  ]);
}
