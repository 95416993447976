import api from '@api';
import { TaxOrder } from 'types/entities';

interface PostPayOrderProps {
  orderId: number;
  paymentMethodId: number;
}

export default async function postPayOrder(payload: PostPayOrderProps) {
  const { data } = await api.post<TaxOrder>(`/orders/${payload.orderId}/pay`, {
    card_id: payload.paymentMethodId,
  });

  return data;
}
