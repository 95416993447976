import { fiscalRegimeParser } from '@util/Utils';
import type { TaxableEntity } from 'types/entities';
import { useTaxableEntity } from './taxable_entity';
import useSelectedWorkflow from './useSelectedWorkflow';

export default function useActiveFiscalRegime(
  isWorkflow: boolean,
  isPfaeAndPlatform: boolean,
) {
  const { taxable_entity } = useTaxableEntity() as {
    taxable_entity: TaxableEntity;
  };
  const { workflow } = useSelectedWorkflow();

  if (isWorkflow || isPfaeAndPlatform) {
    const activeDeclarations = workflow?.active_declarations;
    return activeDeclarations?.length === 1
      ? fiscalRegimeParser([activeDeclarations[0].fiscal_regime])
      : fiscalRegimeParser(
          activeDeclarations?.map((declaration) => declaration.fiscal_regime),
        );
  }
  if (!isWorkflow && taxable_entity.fiscal_regimes.length === 1) {
    return fiscalRegimeParser([taxable_entity.fiscal_regimes[0]]);
  }

  return fiscalRegimeParser(taxable_entity.fiscal_regimes);
}
