import { useCallback } from 'react';
import _ from 'lodash';
import { useMediaQuery } from '@konta/ui';
import type {
  AccumulatedIncomeAndExpense,
  AccumulatedOfEntries,
} from 'types/entities';
import useTaxableEntityShared from 'shared/hooks/useSharedTaxableEntity';
import getAuthorizationAxiosConfig from 'shared/util/getAuthorizationAxiosConfig';
import getDataSetByIncomeOrExpense from 'shared/util/getDataSetByIncomeOrExpense';
import getLinearSeriesDataByRegimes from 'shared/util/getLinearSeriesDataByRegimes';
import useAccumulatedOfEntries from '@hooks/useAccumulatedOfEntries';
import { UseQueryOptions } from 'react-query';

interface AccumulatedIncomesAndExpensesProps {
  filters?: Record<string, string | boolean | number>;
  queryOptions?: UseQueryOptions<AccumulatedOfEntries>;
}

export default function useAccumulatedIncomesAndExpensesEntries({
  filters,
  queryOptions,
}: AccumulatedIncomesAndExpensesProps) {
  const isDesktop = useMediaQuery('(min-width: 1512px)');
  const { userAccessToken } = useTaxableEntityShared();
  const { accumulatedOfEntries, accumulatedOfEntriesLoading } =
    useAccumulatedOfEntries(
      {
        ...getAuthorizationAxiosConfig(userAccessToken),
        params: {
          ...filters,
        },
      },
      queryOptions,
    );

  const xAxisLabel = filters?.accumulate_periods ? 'Acumulado' : 'Totales';

  const getDataSet = useCallback(
    (label: string, dataType: keyof AccumulatedIncomeAndExpense) =>
      getDataSetByIncomeOrExpense(
        label,
        dataType,
        accumulatedOfEntries,
        xAxisLabel,
      ),
    [accumulatedOfEntries, xAxisLabel],
  );

  const getLinearSeriesDataByRegimesCallback = useCallback(
    (label: string, dataType: keyof AccumulatedIncomeAndExpense) =>
      getLinearSeriesDataByRegimes(
        label,
        dataType,
        accumulatedOfEntries,
        xAxisLabel,
      ),
    [accumulatedOfEntries, xAxisLabel],
  );

  const incomesDataSet = getDataSet('Ingresos', 'incomes');
  const expensesDataSet = getDataSet('Gastos', 'expenses');
  const accumulatedIncomeTotal = _.last(incomesDataSet.data)?.value || 0;
  const accumulatedExpenseTotal = _.last(expensesDataSet.data)?.value || 0;
  const incomesLinearSeriesDataByRegimes = getLinearSeriesDataByRegimesCallback(
    'Ingresos',
    'incomes',
  );
  const expensesLinearSeriesDataByRegimes =
    getLinearSeriesDataByRegimesCallback('Gastos', 'expenses');

  return {
    accumulatedOfEntries,
    isDesktop,
    incomesDataSet,
    expensesDataSet,
    accumulatedIncomeTotal,
    accumulatedExpenseTotal,
    isLoading: accumulatedOfEntriesLoading,
    incomesLinearSeriesDataByRegimes,
    expensesLinearSeriesDataByRegimes,
  };
}
