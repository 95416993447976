import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Flex, Text } from '@konta/ui';
import useFeature from '@hooks/useFeature';
import { CHECKOUT_NEXT_PAYMENT_DATE } from '@constants/featureFlags';

const dateTemplate = 'DD [de] MMMM [de] YYYY';
interface NextPaymentProps {
  plan: {
    subscription?: {
      durationMoths?: number;
    };
  };
}
export default function NextPayment({ plan }: NextPaymentProps) {
  const [checkoutNextPaymentDateEnabled] = useFeature(
    CHECKOUT_NEXT_PAYMENT_DATE,
  );

  const durationMonths = +(plan?.subscription?.durationMoths || 0);
  const nextPaymentText = useMemo(() => {
    const numberOfDate = dayjs().date();
    const hasDuration = durationMonths === 6 || durationMonths === 12;
    const sumMonths = hasDuration ? durationMonths : 0;

    if (hasDuration && numberOfDate <= 24) {
      return dayjs().date(6).add(durationMonths, 'month').format(dateTemplate);
    }
    if (numberOfDate <= 24 || hasDuration) {
      return dayjs()
        .date(6)
        .add(sumMonths + 1, 'month')
        .format(dateTemplate);
    }
    return dayjs()
      .date(6)
      .add(sumMonths + 2, 'month')
      .format(dateTemplate);
  }, [durationMonths]);

  if (!durationMonths || !plan || !checkoutNextPaymentDateEnabled) {
    return null;
  }

  return (
    <Flex column itemsCenter>
      <Text s medium color="gray900" data-cy="nextPaymentLabel">
        Tu próximo pago será el {nextPaymentText}
      </Text>
      <Text s color="gray900" data-cy="nextPaymentLabel">
        *Estos cargos se generan de forma automática los días 6 de cada mes
      </Text>
    </Flex>
  );
}
