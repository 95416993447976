import api from '@api';
import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';

import { fetchOrdersSuccess, fetchOrdersFailure } from '../actions';

export default function* fetchOrdersSaga(_) {
  try {
    const access_token = localStorage.getItem('user_id');

    const response = yield call(api.get, 'orders/', {
      headers: {
        Authorization: `${access_token}`,
      },
    });

    yield call(StatusHandler, {
      response,
      status: response.status,
      success: fetchOrdersSuccess,
      failure: fetchOrdersFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: fetchOrdersFailure,
    });
  }
}
