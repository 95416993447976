import * as yup from 'yup';
import { TAX_RATE_16 } from 'shared/constants/taxRates';
import calculateIva from 'shared/util/calculateIva';
import type { DeclarationEntry, Workflow } from 'types/entities';

export const validationSchema = yup.object().shape({
  totalOfExtras: yup.number().min(0, 'No puede ser negativo'),
  extraZeroBases: yup.number().min(0, 'No puede ser negativo'),
});

export const defaultValues = {
  totalOfExtras: 0,
  extraZeroBases: 0,
};

export type HasExtraIncomeFormValues = typeof defaultValues;

export const valuesToPayload = (
  values: HasExtraIncomeFormValues,
  workflow: Workflow | null,
  declarationEntry: DeclarationEntry | null,
  declarationId?: number,
) => {
  const { totalOfExtras = 0, extraZeroBases = 0 } = values;

  if (!workflow) {
    throw new Error('No se encontró el workflow');
  }
  if (!declarationId) {
    throw new Error('No se encontró el id de la declaración');
  }

  const startDate = workflow.start_date;
  const taxableEntityId = workflow.taxable_entity_id;
  const total16 = +totalOfExtras;

  const { base: ivaBase16, iva: iva16 } = calculateIva(total16, TAX_RATE_16);

  const totalZero = +extraZeroBases;
  const total = total16 + totalZero;

  const payload = new FormData();
  payload.append(
    'declaration_entry[source_attributes][taxable_entity_id]',
    `${taxableEntityId}`,
  );
  payload.append('declaration_entry[declaration_id]', `${declarationId}`);
  payload.append('declaration_entry[accounting_status]', 'is_deductible');
  payload.append('declaration_entry[source_type]', 'ExtraIncome');
  payload.append('declaration_entry[accounting_date]', startDate);
  payload.append('declaration_entry[source_attributes][total]', `${total}`);
  payload.append('declaration_entry[source_attributes][total8]', `0`);
  payload.append('declaration_entry[source_attributes][total16]', `${total16}`);
  payload.append('declaration_entry[source_attributes][description]', '');
  payload.append('declaration_entry[source_attributes][exempt_base]', `0`);
  payload.append(
    'declaration_entry[source_attributes][zero_base]',
    `${totalZero}`,
  );
  payload.append('declaration_entry[source_attributes][iva_base8]', `0`);
  payload.append('declaration_entry[source_attributes][iva8]', `0`);
  payload.append(
    'declaration_entry[source_attributes][iva_base16]',
    `${ivaBase16}`,
  );
  payload.append('declaration_entry[source_attributes][iva16]', `${iva16}`);
  if (declarationEntry) {
    const sourceId = declarationEntry.source_id;
    payload.append('declaration_entry[source_attributes][id]', `${sourceId}`);
  }

  return payload;
};
