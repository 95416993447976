import { AccumulatedIncomeAndExpense } from 'types/entities';
import _ from 'lodash';

export default function getLinearSeriesDataByRegimes(
  label: string,
  dataType: keyof AccumulatedIncomeAndExpense,
  accumulatedData: AccumulatedIncomeAndExpense | null,
  xAxisLabel: string,
) {
  const dataByType = accumulatedData?.[dataType] || {};
  return Object.entries(dataByType).map(([satKet, data]) => {
    const collection = Object.entries(data).map(([date, total]) => ({
      name: date,
      value: +total,
      extraData: {
        satKet,
        date,
        value: +total,
      },
    }));
    const accumulatedTotal = _.last(collection)?.value || 0;
    return {
      satKet,
      accumulatedTotal,
      dataSet: {
        label,
        currency: true,
        data: collection,
        xAxisLabel,
      },
    };
  });
}
