import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { ButtonConfirmModal } from '@components/Modal';
import { InvoicePDF } from '@components/Utils/PDF/PreviewPDF/preview-pdf';
import useFiscalRegimesQuery from '@hooks/useFiscalRegimesQuery';
import { useTaxableEntity } from '@hooks';
import styles from './ConfirmInvoiceModal.module.scss';

export default function ConfirmInvoiceModal({
  invoice,
  isOpen,
  onConfirm,
  toggle,
}) {
  const { regimes, loadingRegimens } = useFiscalRegimesQuery();
  const { loading } = useSelector((state) => state.cfdis.loading);
  const { taxable_entity: taxableEntity } = useTaxableEntity();

  const invoicePdf = useMemo(() => {
    if (!invoice || regimes.length === 0) {
      return null;
    }

    return (
      <InvoicePDF
        cfdi={invoice}
        regimes={regimes}
        taxableEntity={taxableEntity}
      />
    );
  }, [invoice, loadingRegimens, regimes, taxableEntity]);

  if (!invoice) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={styles.modal}>
      <ModalHeader toggle={toggle}>Vista previa</ModalHeader>
      <ModalBody className={styles.content}>
        <PDFViewer className={styles.pdf} showToolbar={false}>
          {invoicePdf}
        </PDFViewer>
        <Card className={styles.card}>
          <CardBody className={styles.cardBody}>
            <ButtonConfirmModal
              buttonColor="primary"
              handleSubmit={onConfirm}
              cancelButton="Cancelar"
              confirmButton="Confirmar"
              buttonLabel="Emitir Factura"
              isButtonDisabled={loading}
              loading={loading}
            >
              <i className="large-icon iconsmind-Danger" color="danger" />
              <p>¡Estas a punto de timbrar esta factura!</p>
            </ButtonConfirmModal>
            <PDFDownloadLink
              fileName={`vista-previa-${invoice.receiver_attributes.rfc}.pdf`}
              document={invoicePdf}
            >
              {({ loading: loadingPdf, error }) =>
                !error && (
                  <Button color="primary" size="sm" download="image2">
                    {loadingPdf ? '...Cargando' : 'Descargar PDF'}
                  </Button>
                )
              }
            </PDFDownloadLink>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
}

ConfirmInvoiceModal.propTypes = {
  invoice: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  toggle: PropTypes.func,
};

ConfirmInvoiceModal.defaultProps = {
  invoice: null,
  isOpen: null,
  onConfirm: null,
  toggle: null,
};
