export default function getInvoicePdfColumnFlex(column) {
  if (column === 'Descripcion' || column === 'description') {
    return 3;
  }
  if (column === 'NoIdentificacion' || column === 'identification_number') {
    return 0.9;
  }
  if (column === 'Cantidad' || column === 'quantity') {
    return 0.8;
  }
  if (column === 'ClaveProd/Serv' || column === 'prod_or_svc_key') {
    return 1.2;
  }
  return 1;
}
