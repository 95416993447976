import { useMemo } from 'react';
import camelcaseKeys from 'camelcase-keys';
import useSelectedWorkflow from './useSelectedWorkflow';

export default function useActiveDeclarationIncome() {
  const { workflow } = useSelectedWorkflow();
  const { ivaRate = 0, declarationIncome = {} } = camelcaseKeys(
    workflow.active_declaration,
  );
  const {
    total = 0,
    totalIvaBases = 0,
    totalIvaBasesOfCfdis = 0,
    totalIvaExemptBasesOfCfdis = 0,
    totalZeroBasesOfCfdis = 0,
    totalIvaOfCfdis = 0,
    totalUnbilled = 0,
    totalIvaBasesUnbilled = 0,
    totalRetainedIvaOfCfdis = 0,
    totalRetainedIsrOfCfdis = 0,
    totalZeroBases = 0,
    totalExemptBases = 0,
    totalZeroBasesUnbilled = 0,
    totalExemptBasesUnbilled = 0,
    totalIvaBase16Unbilled = 0,
    totalIvaBase8Unbilled = 0,
    totalIva = 0,
  } = camelcaseKeys(declarationIncome);

  return useMemo(() => {
    const totalBasesOfCfdis =
      +totalIvaBasesOfCfdis +
      +totalIvaExemptBasesOfCfdis +
      +totalZeroBasesOfCfdis;
    const totalTaxableBasesOfCfdis = +totalIvaBasesOfCfdis;

    const totalIvaUnbilled =
      +totalIvaBase16Unbilled * 0.16 + +totalIvaBase8Unbilled * 0.08;

    const totalRetainedOfCfdis =
      +totalRetainedIvaOfCfdis + +totalRetainedIsrOfCfdis;

    return {
      totalBases: +totalIvaBases,
      totalIva: +totalIva,
      total: +total,
      totalExemptBases: +totalExemptBases,
      totalZeroBases: +totalZeroBases,
      totalZeroBasesUnbilled: +totalZeroBasesUnbilled,
      totalExemptBasesUnbilled: +totalExemptBasesUnbilled,
      totalBasesWithIva: +total + +totalIva,
      totalBasesOfCfdis,
      totalTaxableBasesOfCfdis,
      totalIvaBasesOfCfdis: +totalIvaBasesOfCfdis,
      totalIvaExemptBasesOfCfdis: +totalIvaExemptBasesOfCfdis,
      totalZeroBasesOfCfdis: +totalZeroBasesOfCfdis,
      totalIvaOfCfdis: +totalIvaOfCfdis,
      totalRetainedOfCfdis,
      totalBasesWithIvaOfCfdis:
        +totalBasesOfCfdis +
        +totalIvaOfCfdis -
        +totalRetainedIvaOfCfdis -
        +totalRetainedIsrOfCfdis,
      totalUnbilled: +totalUnbilled,
      totalIvaBasesUnbilled: +totalIvaBasesUnbilled,
      totalIvaUnbilled,
      totalBasesWithIvaUnbilled: +totalUnbilled + +totalIvaUnbilled,
      totalRetainedIvaOfCfdis: +totalRetainedIvaOfCfdis,
      totalRetainedIsrOfCfdis: +totalRetainedIsrOfCfdis,
    };
  }, [
    totalIvaBasesOfCfdis,
    totalIvaExemptBasesOfCfdis,
    totalZeroBasesOfCfdis,
    totalIvaBasesUnbilled,
    ivaRate,
    totalIvaBases,
    totalRetainedIvaOfCfdis,
    totalRetainedIsrOfCfdis,
    total,
    totalExemptBases,
    totalZeroBases,
    totalZeroBasesUnbilled,
    totalExemptBasesUnbilled,
    totalIvaOfCfdis,
    totalUnbilled,
  ]);
}
