import { useMemo, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import useWorkflow, {
  ReturnTypeUseWorkflow,
} from '@components/Workflow/hooks/useWorkflow';
import { useQueryClient } from 'react-query';
import { DECLARATION_ENTRIES_BY_WORKFLOW } from 'shared/constants/reactQueries';
import usePostDeclarationEntries from 'shared/hooks/usePostDeclarationEntries';
import { NotificationManager } from '@components/Notifications';
import getErrorMessage from '@util/getErrorMessage';

const IVA_OPTIONS = [
  {
    label: 'IVA 16%',
    value: 0.16,
    key: 1,
    name: 'iva_rate_of_bank_transactions',
  },
  {
    label: 'IVA 8%',
    value: 0.08,
    key: 2,
    name: 'iva_rate_of_bank_transactions',
  },
];

const validationSchema = yup.object({
  description: yup.string(),
  totalIncome: yup.number().min(0, 'El monto debe ser mayor o igual a 0'),
  exempt_base: yup.number().min(0, 'El monto debe ser mayor o igual a 0'),
  zero_base: yup.number().min(0, 'El monto debe ser mayor o igual a 0'),
  iva_rate: yup
    .object({
      label: yup.string().required(),
      value: yup.number().required(),
    })
    .nullable(),
  select_fiscal_regime: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
      declarationId: yup.number().required(),
    })
    .nullable()
    .required('Campo requerido'),
});

export type ManualIncomesFormPayload = yup.InferType<typeof validationSchema>;

function useInitialValues(
  regimeOptionsWithDeclarationId: ReturnTypeUseWorkflow['regimeOptionsWithDeclarationId'],
  defaultRegimeOptionBySatkey?: ReturnTypeUseWorkflow['defaultRegimeOptionBySatkey'],
): ManualIncomesFormPayload {
  return useMemo(
    () => ({
      description: '',
      totalIncome: 0,
      exempt_base: 0,
      iva_rate: IVA_OPTIONS[0] as typeof IVA_OPTIONS[0] | null,
      zero_base: 0,
      select_fiscal_regime:
        defaultRegimeOptionBySatkey || regimeOptionsWithDeclarationId[0],
    }),
    [regimeOptionsWithDeclarationId, defaultRegimeOptionBySatkey],
  );
}

interface UseUploadManualIncomesModalProps {
  toggleOpenUploadIncomes: () => void;
}

export default function useUploadManualIncomesModal({
  toggleOpenUploadIncomes,
}: UseUploadManualIncomesModalProps) {
  const postDeclarationEntries = usePostDeclarationEntries();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const queryClient = useQueryClient();

  const {
    workflow,
    currentRegimes,
    isLoadingWorkflow,
    activeDeclarations,
    regimeOptionsWithDeclarationId,
    updateCurrentWorkflow,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  } = useWorkflow();

  const onSubmit = async (values: ManualIncomesFormPayload) => {
    const {
      description,
      totalIncome = 0,
      iva_rate,
      exempt_base = 0,
      zero_base = 0,
      select_fiscal_regime,
    } = values;

    const total8 = iva_rate?.value === 0.08 ? totalIncome : 0;
    const total16 = iva_rate?.value === 0.16 ? totalIncome : 0;
    const ivaBase16 = total16 / ((iva_rate?.value || 0.16) + 1);
    const ivaBase8 = total8 / ((iva_rate?.value || 0.08) + 1);

    try {
      const payload = new FormData();

      payload.append(
        'declaration_entry[declaration_id]',
        select_fiscal_regime.declarationId.toString(),
      );
      payload.append('declaration_entry[accounting_status]', 'is_deductible');
      payload.append('declaration_entry[source_type]', 'ExtraIncome');
      payload.append(
        'declaration_entry[accounting_date]',
        workflow?.start_date.toString() || '',
      );
      payload.append(
        'declaration_entry[source_attributes][total]',
        (+total8 + +total16 + +exempt_base + +zero_base).toString(),
      );
      payload.append(
        'declaration_entry[source_attributes][total8]',
        total8.toString(),
      );
      payload.append(
        'declaration_entry[source_attributes][total16]',
        total16.toString(),
      );
      payload.append(
        'declaration_entry[source_attributes][description]',
        description?.toString() || '',
      );
      payload.append(
        'declaration_entry[source_attributes][exempt_base]',
        exempt_base.toString(),
      );
      payload.append(
        'declaration_entry[source_attributes][zero_base]',
        zero_base.toString(),
      );
      payload.append(
        'declaration_entry[source_attributes][iva_base8]',
        ivaBase8.toString(),
      );
      payload.append(
        'declaration_entry[source_attributes][iva8]',
        (ivaBase8 * (iva_rate?.value || 0.08)).toString(),
      );
      payload.append(
        'declaration_entry[source_attributes][iva_base16]',
        ivaBase16.toString(),
      );
      payload.append(
        'declaration_entry[source_attributes][iva16]',
        (ivaBase16 * (iva_rate?.value || 0.16)).toString(),
      );

      await postDeclarationEntries.mutateAsync(payload);
      updateCurrentWorkflow();
      await queryClient.invalidateQueries([DECLARATION_ENTRIES_BY_WORKFLOW]);
      NotificationManager.success(
        `Se ha agregado el ingreso exitosamente`,
        'Ingreso agregado',
      );
      toggleOpenUploadIncomes();
    } catch (error) {
      const errorMessage = getErrorMessage(error) as string;
      NotificationManager.error(errorMessage, 'Error al subir en ingreso');
    }
  };

  const initialValues = useInitialValues(
    regimeOptionsWithDeclarationId,
    defaultRegimeOptionBySatkey,
  );
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  const toggleShowConfirmation = () => {
    setShowConfirmation(!showConfirmation);
    formik.resetForm();
  };

  const isLoading = postDeclarationEntries.isLoading || isLoadingWorkflow;

  return {
    showConfirmation,
    toggleShowConfirmation,
    workflow,
    currentRegimes,
    isLoadingWorkflow,
    activeDeclarations,
    initialValues,
    formik,
    regimeOptionsWithDeclarationId,
    IVA_OPTIONS,
    isLoading,
    taxableEntityPreferences,
  };
}
