import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  FETCH_PAYMENT_METHODS,
  ADD_PAYMENT_METHOD,
  DEFAULT_PAYMENT_METHODS,
  DELETE_PAYMENT_METHOD,
  UI_PAYMENT_METHODS_DISPLAY_CARDS,
  CHARGE_PAYMENT_METHOD,
} from '@constants/actionTypes';
import fetch from './fetch';
import defaultSaga from './default';
import { addPaymentMethodWithAccessToken } from './post';
import displayCardsSaga from './ui/display-cards';
import { deletePaymentMethod } from './delete';
import chargePaymentMethodSaga from './charge';

export function* watchFetchPaymentMethods() {
  yield takeLatest(FETCH_PAYMENT_METHODS, fetch);
}
export function* watchDefaultCard() {
  yield takeLatest(DEFAULT_PAYMENT_METHODS, defaultSaga);
}

export function* watchAddPaymentMethod() {
  yield takeLatest(ADD_PAYMENT_METHOD, addPaymentMethodWithAccessToken);
}
export function* watchDeletePaymentMethod() {
  yield takeLatest(DELETE_PAYMENT_METHOD, deletePaymentMethod);
}
// UI
export function* watchDisplayCards() {
  yield takeLatest(UI_PAYMENT_METHODS_DISPLAY_CARDS, displayCardsSaga);
}

export function* watchChargePaymentMethod() {
  yield takeLatest(CHARGE_PAYMENT_METHOD, chargePaymentMethodSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchDisplayCards),
    fork(watchFetchPaymentMethods),
    fork(watchDefaultCard),
    fork(watchAddPaymentMethod),
    fork(watchDeletePaymentMethod),
    fork(watchChargePaymentMethod),
  ]);
}
