import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardTitle, CardBody, Label } from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField,
} from 'availity-reactstrap-validation';
import { Colxx, SimpleStateButton } from '@components';

import { convertNullToEmpty } from '@util/Forms';
import { addressFetch, addressUpdate } from '@redux/actions';

/**
 * @todo Compare form structure with db structure
 * @todo Change to Formik
 */
class AddressFormCard extends Component {
  handleSubmit = (_event, errors, values) => {
    if (errors.length === 0) {
      this.props.addressUpdate(values);
    }
  };

  componentDidMount() {
    const { address } = this.props.addresses;
    address || this.props.addressFetch();
  }

  render() {
    const { address, loading } = this.props.addresses;
    return (
      <Card data-test="address-card">
        <CardBody>
          <CardTitle tag="h4">Dirección de la empresa</CardTitle>
          {address && (
            <AvForm
              onSubmit={this.handleSubmit}
              model={convertNullToEmpty(address)}
              className="has-float-label"
            >
              <Row>
                <Colxx md={12}>
                  <AvGroup>
                    <Label htmlFor="street">Calle</Label>
                    <AvInput
                      data-test="form-input"
                      name="street"
                      required
                      disabled
                    />
                    <AvFeedback data-test="form-feedback-error">
                      Calle inválida
                    </AvFeedback>
                  </AvGroup>
                </Colxx>
                <Colxx md={6}>
                  <AvGroup>
                    <Label htmlFor="number">Número exterior</Label>
                    <AvInput
                      disabled
                      data-test="form-input"
                      name="number"
                      required
                    />
                    <AvFeedback data-test="form-feedback-error">
                      Número invalido!
                    </AvFeedback>
                  </AvGroup>
                </Colxx>
                <Colxx md={6}>
                  <AvGroup>
                    <Label>Número interior</Label>
                    <AvInput
                      data-test="form-input"
                      name="interior_number"
                      required
                      disabled
                    />
                    <AvFeedback data-test="form-feedback-error">
                      Número interior invalido!
                    </AvFeedback>
                  </AvGroup>
                </Colxx>
                <Colxx md={12}>
                  <AvGroup>
                    <Label htmlFor="between_street">Entre calle</Label>
                    <AvInput
                      data-test="form-input"
                      name="between_street"
                      required
                      disabled
                    />
                    <AvFeedback data-test="form-feedback-error">
                      Entre calle inválida
                    </AvFeedback>
                  </AvGroup>
                </Colxx>
                <Colxx md={12}>
                  <AvGroup>
                    <Label htmlFor="between_street_two">Y entre calle</Label>
                    <AvInput
                      data-test="form-input"
                      name="between_street_two"
                      required
                      disabled
                    />
                    <AvFeedback data-test="form-feedback-error">
                      Entre calle inválida
                    </AvFeedback>
                  </AvGroup>
                </Colxx>
                <Colxx md={12}>
                  <AvGroup>
                    <Label htmlFor="street_two">Colonia</Label>
                    <AvInput
                      data-test="form-input"
                      name="street_two"
                      required
                      disabled
                    />
                    <AvFeedback data-test="form-feedback-error">
                      Colonia inválida
                    </AvFeedback>
                  </AvGroup>
                </Colxx>
                <Colxx md={6}>
                  <AvGroup>
                    <Label htmlFor="city">Ciudad</Label>
                    <AvInput
                      disabled
                      data-test="form-input"
                      name="city"
                      required
                    />
                    <AvFeedback data-test="form-feedback-error">
                      Ciudad inválida
                    </AvFeedback>
                  </AvGroup>
                </Colxx>
                <Colxx md={6}>
                  <AvGroup>
                    <Label htmlFor="state">Estado</Label>
                    <AvInput
                      disabled
                      data-test="form-input"
                      name="state"
                      required
                    />
                    <AvFeedback>Estado invalido!</AvFeedback>
                  </AvGroup>
                </Colxx>
                <Colxx md={6}>
                  <AvGroup>
                    <Label htmlFor="postcode">Código Postal</Label>
                    <AvField
                      data-test="form-input"
                      name="postcode"
                      type="number"
                      disabled
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Porfavor ingrese un código postal',
                        },
                        maxLength: {
                          value: 5,
                          errorMessage:
                            'El código postal debe contener 5 digitos',
                        },
                        minLength: {
                          value: 5,
                          errorMessage:
                            'El código postal debe contener 5 digitos',
                        },
                      }}
                    />
                  </AvGroup>
                </Colxx>
                <Colxx md={6}>
                  <AvGroup>
                    <Label htmlFor="phone_number">Teléfono fijo</Label>
                    <AvInput
                      data-test="form-input"
                      name="phone_number"
                      required
                      disabled
                    />
                    <AvFeedback data-test="form-feedback-error">
                      Teléfono fijo invalido!
                    </AvFeedback>
                  </AvGroup>
                </Colxx>

                <Colxx
                  xxs="12"
                  className="d-flex align-content-center justify-content-center"
                >
                  <SimpleStateButton
                    data-test="form-button"
                    type="submit"
                    loading={loading}
                    disabled
                  >
                    Guardar
                  </SimpleStateButton>
                </Colxx>
              </Row>
            </AvForm>
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ addresses }) => ({ addresses });

export default connect(mapStateToProps, {
  addressUpdate,
  addressFetch,
})(AddressFormCard);
