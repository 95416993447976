import { useState } from 'react';
import { PaginationParamsManager, PaginationParams } from 'types/pagination';

export default function useMemoryParamsPagination(
  initialState?: PaginationParams,
): PaginationParamsManager {
  const [params, setParams] = useState<PaginationParams>(initialState || {});

  const setParam = (key: string, value: string | null) => {
    setParams({ ...params, [key]: value });
  };

  return { params, setParams, setParam };
}
