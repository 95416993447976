import { FilePlus02LineIcon } from '@konta/icons';
import ContainerButton from 'shared/components/ContainerButton';
import ForeignInvoiceModalForm from '@components/ForeignInvoiceModalForm';
import useUploadForeignInvoice from './useUploadForeignInvoice';

interface ExpenseUploadWayProps {
  toggleOpenUploadExpense: () => void;
}
export default function UploadForeignInvoice({
  toggleOpenUploadExpense,
}: ExpenseUploadWayProps) {
  const {
    toggleShowConfirmation,
    showConfirmation,
    handleSubmit,
    regimeOptionsWithDeclarationId,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  } = useUploadForeignInvoice({ toggleOpenUploadExpense });

  return (
    <>
      <ContainerButton
        icon={<FilePlus02LineIcon />}
        title="Agregar facturas en el extranjero"
        onClick={toggleShowConfirmation}
      />
      {showConfirmation && (
        <ForeignInvoiceModalForm
          withBlur
          centered
          isOpen={showConfirmation}
          onClose={toggleShowConfirmation}
          onSubmit={handleSubmit}
          isFromWorkflow
          regimeOptions={regimeOptionsWithDeclarationId}
          taxableEntityPreferences={taxableEntityPreferences}
          defaultRegimeOption={defaultRegimeOptionBySatkey}
        />
      )}
    </>
  );
}
