import {
  SUBSCRIBE,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
  SUBSCRIBTION_CANCEL,
  SUBSCRIBTION_CANCEL_SUCCESS,
  SUBSCRIBTION_CANCEL_FAILURE,
  SUBSCRIPTION_SELECT,
  DISPLAY_SUBSCRIPTION_PRICES,
  SUBSCRIPTION_DISCOUNT,
  PAYMENT_HISTORY_FETCH,
  PAYMENT_HISTORY_FETCH_SUCCESS,
  PAYMENT_HISTORY_FETCH_FAILURE,
  SUBSCRIPTIONS_FETCH,
  SUBSCRIPTIONS_FETCH_SUCCESS,
  SUBSCRIPTIONS_FETCH_FAILURE,
} from '@constants/actionTypes';

export const subscribe = (subscription_params, callback) => ({
  type: SUBSCRIBE,
  payload: { subscription_params, callback },
});
export const subscribeSucess = (response) => ({
  type: SUBSCRIBE_SUCCESS,
  payload: response,
});
export const subscribeFailure = (_) => ({
  type: SUBSCRIBE_FAILURE,
});

export const subcriptionCancel = (
  subscription_id,
  { cancellationReasons, otherReason },
) => ({
  type: SUBSCRIBTION_CANCEL,
  payload: { subscription_id, cancellationReasons, otherReason },
});
export const subcriptionCancelSucess = (subscription) => ({
  type: SUBSCRIBTION_CANCEL_SUCCESS,
  payload: subscription,
});
export const subcriptionCancelFailure = (_) => ({
  type: SUBSCRIBTION_CANCEL_FAILURE,
});

// UI
export const subcriptionSelect = (
  subscription,
  employeeCounter,
  receiptCounter,
) => {
  return {
    type: SUBSCRIPTION_SELECT,
    payload: receiptCounter
      ? { subscription, employeeCounter, receiptCounter }
      : { subscription, employeeCounter },
  };
};

export const subscriptionPrices = (boolean) => ({
  type: DISPLAY_SUBSCRIPTION_PRICES,
  payload: boolean,
});

export const subscriptionDiscount = (value, code) => ({
  type: SUBSCRIPTION_DISCOUNT,
  payload: { value, code },
});

export const fetchPaymentHistory = () => ({
  type: PAYMENT_HISTORY_FETCH,
});

export const fetchPaymentHistorySuccess = (payment_history) => ({
  type: PAYMENT_HISTORY_FETCH_SUCCESS,
  payload: payment_history,
});

export const fetchPaymentHistoryFailure = () => ({
  type: PAYMENT_HISTORY_FETCH_FAILURE,
});

export const fetchSubscriptions = () => ({
  type: SUBSCRIPTIONS_FETCH,
});

export const fetchSubscriptionsSuccess = (subscriptions) => ({
  type: SUBSCRIPTIONS_FETCH_SUCCESS,
  payload: subscriptions,
});

export const fetchSubscriptionsFailure = () => ({
  type: SUBSCRIPTIONS_FETCH_FAILURE,
});
