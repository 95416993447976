import { useMemo } from 'react';
import toCurrency from '@util/toCurrency';

export default function useAnnualExpenses(annualTotals) {
  const sourceTypes = useMemo(
    () => ['digital_platform', 'business_activity', 'resico'],
    [],
  );

  const expenses = useMemo(() => {
    return annualTotals.reduce(
      (prevValue, { source_type, transaction_type, amount }) => {
        const isExpense =
          transaction_type === 'expense' && sourceTypes.includes(source_type);
        if (isExpense) {
          return +prevValue + +amount;
        }
        return prevValue;
      },
      0,
    );
  }, [annualTotals, sourceTypes]);

  const fixedAssetDepreciation = useMemo(() => {
    return annualTotals.reduce(
      (prevValue, { source_type, transaction_type, amount }) => {
        const isFixedAsset =
          transaction_type === 'fixed_asset' &&
          source_type === 'digital_platform';
        if (isFixedAsset) {
          return prevValue + +amount;
        }
        return prevValue;
      },
      0,
    );
  }, [annualTotals]);

  const personalDeduction = useMemo(() => {
    return annualTotals.reduce(
      (prevValue, { source_type, transaction_type, amount }) => {
        const isFixedAsset =
          transaction_type === 'personal_deduction' && source_type === 'salary';
        if (isFixedAsset) {
          return prevValue + +amount;
        }
        return prevValue;
      },
      0,
    );
  }, [annualTotals]);

  return useMemo(
    () => [
      {
        key: 1,
        label: 'Gastos',
        amount: `${toCurrency(expenses)} MXN`,
        id: 'expenses',
        value: expenses,
      },
      {
        key: 2,
        label: 'Depreciación de activo fijo',
        amount: `${toCurrency(fixedAssetDepreciation)} MXN`,
        id: 'fixedAssetDepreciation',
        value: fixedAssetDepreciation,
      },
      {
        key: 3,
        label: 'Deducciones Personales',
        amount: `${toCurrency(personalDeduction)} MXN`,
        id: 'personalDeduction',
        value: personalDeduction,
      },
      {
        key: 4,
        label: 'Total de gastos',
        amount: `${toCurrency(
          expenses + fixedAssetDepreciation + personalDeduction,
        )} MXN`,
        isBold: true,
        id: 'totalExpenses',
        value: expenses + fixedAssetDepreciation + personalDeduction,
      },
    ],
    [expenses, fixedAssetDepreciation, personalDeduction],
  );
}
