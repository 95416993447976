import { AxiosRequestConfig } from 'axios';
import { AccumulatedOfEntries } from 'types/entities';
import api from './index';

// eslint-disable-next-line import/prefer-default-export
export const getAccumulatedOfEntries = async (
  config?: AxiosRequestConfig<AccumulatedOfEntries>,
) => {
  const { data } = await api.get<AccumulatedOfEntries>(
    `accumulated_of_entries`,
    config,
  );
  return data;
};
