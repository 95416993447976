import api from '@api';

interface SubmitInvoiceData {
  draftId: string;
  email?: string;
  phone?: string;
  templateName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function submitInvoice({
  draftId,
  email,
  payload,
  templateName,
  phone,
}: SubmitInvoiceData) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data } = await api.post(`/invoice_drafts/${draftId}/submit`, {
    email: email || undefined,
    template_name: templateName || undefined,
    phone_number: phone || undefined,
    body: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      receipt: payload,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data;
}
