import _ from 'lodash';
import type { FiscalRegime } from 'types/entities';

export const shortNames: {
  [key: number]: string;
} = {
  625: 'Plataformas',
  612: 'PFAE',
};
export default function getRegimeNameFromFiscalRegime(
  fiscalRegime?: FiscalRegime,
  shortName = true,
  format:
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'start_case' = 'capitalize',
) {
  if (!fiscalRegime) return 'Sin régimen';
  const name =
    (shortName
      ? fiscalRegime?.alias || fiscalRegime?.description
      : fiscalRegime?.description || fiscalRegime?.alias) ||
    fiscalRegime?.sat_key.toString();

  if (format === 'capitalize') {
    return _.capitalize(name);
  }
  if (format === 'uppercase') {
    return name.toUpperCase();
  }
  if (format === 'lowercase') {
    return name.toLowerCase();
  }
  if (format === 'start_case') {
    return _.startCase(name);
  }

  return name;
}
