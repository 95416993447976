import { useEffect, useState } from 'react';
import axios from 'axios';
import { FileWithPath } from 'react-dropzone';
import { parseString } from 'xml2js';
import QRCode from 'qrcode';
import { ParsedInvoiceXML } from 'types/entities';
import get from 'lodash/get';

interface UseXmlParserProps {
  file: FileWithPath | string;
}

interface ParsedConstancyXML {
  constancyEmisor: string;
  constancyRfcEmisor: string;
  constancyReceptor: string;
  constancyMontoTotOperacion: string;
  constancyFechaTimbrado: string;
}

export default function useXmlParser({ file }: UseXmlParserProps) {
  const parseXMLToJSON = (xmlData: string): Promise<any> =>
    new Promise((resolve, reject) => {
      parseString(xmlData, (error, result) => {
        if (result) resolve(result);
        else reject(error);
      });
    });

  const isUrl = typeof file === 'string';

  const url = isUrl ? file : URL.createObjectURL(file);
  const [fetchInvoice, setFetchInvoice] = useState<ParsedInvoiceXML>();
  const [constancyXml, setConstancyXml] = useState<ParsedConstancyXML>();
  const [isFetching, setIsFetching] = useState(false);
  const [fileType, setFileType] = useState('');

  // Esta función convierte un XML en un objeto JSON con una estructura específica
  const cleanJSON = (data: any): ParsedInvoiceXML => {
    const all = data['cfdi:Comprobante'];
    const root = all.$;
    const Emisor = get(all, 'cfdi:Emisor.0.$');
    const Receptor = get(all, 'cfdi:Receptor.0.$');
    const Conceptos = [
      ...(get(all, 'cfdi:Conceptos.0.Concepto') ?? []),
      ...(get(all, 'cfdi:Conceptos.0.cfdi:Concepto') ?? []),
    ];

    const Impuestos = get(all, 'cfdi:Impuestos.0.$', {});
    const Retenciones = get(
      all,
      'cfdi:Impuestos.0.cfdi:Retenciones.0.cfdi:Retencion',
      {},
    );
    const Traslados = get(
      all,
      'cfdi:Impuestos.0.cfdi:Traslados.0.cfdi:Traslado',
      {},
    );

    const Complemento = get(
      all,
      'cfdi:Complemento.0.tfd:TimbreFiscalDigital.0.$',
      {
        Version: '',
        UUID: '',
        FechaTimbrado: '',
        RfcProvCertif: '',
        SelloCFD: '',
        NoCertificadoSAT: '',
        SelloSAT: '',
      },
    );

    const complementPath = all['cfdi:Complemento']?.[0];

    const paymentKey = Object.keys(complementPath || {})
      .find((key) => key.startsWith('pago'))
      ?.split(':')[0];

    const paymentPath = `cfdi:Complemento.0.${paymentKey}:Pagos.0.${paymentKey}:Pago.0.$`;

    const PaymentDetail = paymentKey ? get(all, paymentPath, {}) : {};

    return {
      ...root,
      Conceptos,
      Impuestos,
      Complemento,
      Retenciones,
      Traslados,
      Emisor,
      Receptor,
      PaymentDetail,
    };
  };

  const fetchInvoiceData = async (fileUrl: string): Promise<void> => {
    try {
      setIsFetching(true);
      const response = await axios.get(fileUrl);

      if (response.status !== 200) throw new Error('Error fetching XML');

      const cfdiJson = (await parseXMLToJSON(
        response.data as string,
      )) as Record<string, never>;

      const xmlType = Object.keys(cfdiJson)[0].split(':')[0];
      setFileType(xmlType);

      if (xmlType === 'retenciones') {
        const constancy = get(
          cfdiJson,
          'retenciones:Retenciones',
          {},
        ) as Record<string, never>;

        const constancyData: ParsedConstancyXML = {
          constancyEmisor: get(
            constancy,
            '["retenciones:Emisor"][0].$.NomDenRazSocE',
            '',
          ),
          constancyRfcEmisor:
            get(constancy, '["retenciones:Emisor"][0].$.RFCEmisor', '') ||
            get(constancy, '["retenciones:Emisor"][0].$.RfcE', ''),
          constancyReceptor: get(
            constancy,
            '["retenciones:Receptor"][0]["retenciones:Nacional"][0].$.NomDenRazSocR',
            '',
          ),
          constancyMontoTotOperacion:
            get(
              constancy,
              '["retenciones:Totales"][0].$.montoTotOperacion',
              '',
            ) ||
            get(
              constancy,
              '["retenciones:Totales"][0].$.MontoTotOperacion',
              '',
            ),
          constancyFechaTimbrado: get(
            constancy,
            '["retenciones:Complemento"][0]["tfd:TimbreFiscalDigital"][0].$.FechaTimbrado',
            '',
          ),
        };

        setConstancyXml(constancyData);
        // setIsFetching(false);
        return;
      }
      const cfdi = cleanJSON(cfdiJson);

      const id = get(cfdi, 'Complemento.UUID', '');
      const sello = get(cfdi, 'Complemento.SelloCFD', '');
      const Emisor = get(cfdi, 'Emisor.Rfc', '');
      const Receptor = get(cfdi, 'Receptor.Rfc', '');
      const Total = get(cfdi, 'Total', '');

      const QR = await QRCode.toDataURL(
        `https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?id=${id}&re=${Emisor}&rr=${Receptor}&tt=${Total}&fe=${sello.slice(
          -8,
        )}`,
      );

      setFetchInvoice({ ...cfdi, QR });
    } catch (error) {
      console.error('Error al procesar la factura:', error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchInvoiceData(url);
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [file]);
  return {
    fetchInvoice,
    isFetching,
    constancyXml,
    url,
    fileType,
  };
}
