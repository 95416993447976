import {
  FINANCIAL_TRANSACTIONS_FETCH,
  FINANCIAL_TRANSACTIONS_FETCH_SUCCESS,
  FINANCIAL_TRANSACTIONS_FETCH_FAILURE,
  UI_SET_DATE,
} from '@constants/actionTypes';

const INIT_STATE = {
  financial_transactions: [],
  loading: false,
  error: null,
  ui: {
    date: new Date(),
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FINANCIAL_TRANSACTIONS_FETCH:
      return { ...state, loading: true };
    case FINANCIAL_TRANSACTIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        financial_transactions: action.payload,
      };
    case FINANCIAL_TRANSACTIONS_FETCH_FAILURE:
      return { ...state, loading: false, financial_transactions: [] };
    case UI_SET_DATE:
      return {
        ...state,
        ui: {
          ...state.ui,
          date: action.payload,
        },
      };
    default:
      return { ...state };
  }
};
