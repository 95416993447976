import React from 'react';
import { GlobalErrorBoundary } from '@containers/handleErrors';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CustomReactQueryProvider from '@providers/CustomReactQueryProvider';
import GlobalErrorHandler from '@components/GlobalErrorHandler/GlobalErrorHandler';
import trackerRedux from '@openreplay/tracker-redux';
import TrackerProvider from '@providers/TrackerProvider';
import { CommandMenuProvider } from '@providers/CommandMenuProvider';
import ReduxProvider from '@providers/ReduxProvider';
import trackerAssist from '@openreplay/tracker-assist';

const is_production = process.env.REACT_APP_ENV === 'PRODUCTION';
if (is_production) {
  Sentry.init({
    dsn: 'https://e2178a71adae444082be47788f7b4ce1@o389762.ingest.sentry.io/5232168',
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'TypeError: Illegal invocation',
      'Error: WebWorker: sender not initialised. Received auth.',
      'Uncaught Error: WebWorker: writer not initialised. Service Should be Started.',
      'Error: WebWorker: writer not initialised. Service Should be Started.',
      "Cannot read property '__openreplay_id' of null",
      "Cannot read properties of null (reading '__openreplay_id')",
      'undefined is not an object (evaluating \'a.search.split("?")[1].split\')',
      'a.search.split(...)[1] is undefined',
    ],
    denyUrls: [/googletagmanager\.com/i],
    beforeSend: (event) => {
      if (
        event.exception?.values?.[0]?.stacktrace?.frames?.some((f) =>
          f.filename?.includes(`https://www.googletagmanager.com/gtm.js`),
        )
      ) {
        return null;
      }
      return event;
    },
  });
}

const trackerPlugins = [
  {
    fn: trackerRedux,
    name: 'redux',
    config: {},
  },
  {
    fn: trackerAssist,
    name: 'assist',
    config: {},
  },
];

export default function Providers({ children }) {
  return (
    <React.StrictMode>
      <GlobalErrorBoundary>
        <TrackerProvider config={{ plugins: trackerPlugins }}>
          <ReduxProvider>
            <CustomReactQueryProvider>
              <GlobalErrorHandler />
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              >
                <BrowserRouter>
                  <CommandMenuProvider>
                    <Routes>
                      <Route path="*" element={children} />
                    </Routes>
                  </CommandMenuProvider>
                </BrowserRouter>
              </GoogleOAuthProvider>
            </CustomReactQueryProvider>
          </ReduxProvider>
        </TrackerProvider>
      </GlobalErrorBoundary>
    </React.StrictMode>
  );
}
