import React, { Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Divider, Text, Flex } from '@konta/ui';
import { Pencil2OutlineIcon } from '@konta/icons';
import formatNumber from '@util/formatNumber';
import styles from './NewInvoiceModal.module.scss';
import SaveInvoiceTemplate from './SaveInvoiceTemplate';
import useFeature from '@hooks/useFeature';
import { INVOICE_TEMPLATE } from '@constants/featureFlags';

/**
 * @param {{ invoice: import('@hooks/useInvoiceForm').UseInvoice }} param0
 */
export default function InvoiceDetails({
  invoice,
  onEdit,
  onSubmit,
  refetchTemplates,
}) {
  const params = useParams();
  const isEditingTemplate = !!params.template;
  const [invoiceTemplate] = useFeature(INVOICE_TEMPLATE);

  const {
    invoiceType,
    paymentType,
    paymentMethod,
    invoiceNumber,
    currency,
    exchangeRate,
    taxResidency,
    orderNumber,
    cfdiUsage,
  } = invoice.formik.values;

  const currencyLabel =
    currency.value !== 'MXN'
      ? `${currency.label} | ${formatNumber(+exchangeRate)}`
      : `${currency.label} | 1.00`;

  const details = useMemo(() => {
    return [
      invoiceType && { key: 'Tipo de factura', value: invoiceType?.label },
      paymentType && { key: 'Forma de pago', value: paymentType?.label },
      paymentMethod && { key: 'Método de pago', value: paymentMethod?.label },
      invoiceNumber ? { key: 'Folio interno', value: invoiceNumber } : {},
      taxResidency && { key: 'Residencia Fiscal', value: taxResidency?.label },
      cfdiUsage && { key: 'Uso del CFDI', value: cfdiUsage?.label },
      orderNumber && { key: 'Número de orden', value: orderNumber },
      currencyLabel && { key: 'Moneda de cambio', value: currencyLabel },
    ].filter(Boolean);
  }, [
    invoiceType,
    paymentType,
    paymentMethod,
    invoiceNumber,
    taxResidency,
    cfdiUsage,
    orderNumber,
    currencyLabel,
  ]);

  const { has_csd: hasCsd } = invoice.taxableEntity;

  return (
    <Flex direction="column" gap="4" css={{ marginBottom: '28px' }}>
      <div>
        <Text xl bold color="black">
          Factura
        </Text>
        <Divider css={{ my: '4px' }} />
      </div>
      <Flex direction="column" gap="8">
        <Text sm color="black" medium>
          Datos de factura
        </Text>
        <div className={styles.detailsTable}>
          {details.map(({ key, value }) => (
            <Fragment key={key}>
              <Text color="gray500">{key}</Text>
              <Text color="gray500">{value}</Text>
            </Fragment>
          ))}
        </div>
        <div>
          <Button
            color="primary"
            onClick={onEdit}
            variant="text"
            leftIcon={<Pencil2OutlineIcon />}
          >
            Editar datos de facturación
          </Button>
        </div>
        <Flex direction="column" gap="10" justify="end" align="end">
          <Flex gap={10}>
            {invoiceTemplate && (
              <SaveInvoiceTemplate invoice={invoice} refetch={refetchTemplates}>
                {({ confirm, loading }) => (
                  <Button
                    size="xs"
                    variant="outlined"
                    onClick={confirm}
                    loading={loading}
                  >
                    Guardar plantilla
                  </Button>
                )}
              </SaveInvoiceTemplate>
            )}
            {!isEditingTemplate && (
              <Button
                color="primary"
                size="xs"
                onClick={onSubmit}
                disabled={!hasCsd}
                loading={invoice.isSubmitting}
              >
                Facturar
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
