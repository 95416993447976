import { useEffect, useState } from 'react';
import { useDebounceFn } from 'rooks';
import type { Hit } from '@algolia/client-search';
import algoliasearch from 'algoliasearch/lite';
import { Command, useCommandState } from 'cmdk';
import { File02LineIcon } from '@konta/icons';
import Loader from 'shared/components/Loader';
import { helpCenterArticleUrl } from '@constants/helpCenter';
import openZendeskLinkWithSSO from '@util/openZendeskLinkWithSSO';
import FeatureItem from '../FeatureItem';
import { HelpCenterArticle } from './types';

const searchClient = algoliasearch(
  'P7I225HBMZ',
  '1d8fc7a2ebfa196064602acbb544df6e',
);
const index = searchClient.initIndex('zendesk_konta_articles');

export default function HelpCenterPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [articles, setArticles] = useState<Array<Hit<HelpCenterArticle>>>();
  const search = useCommandState((state) => state.search);

  const [debouncedRefetch] = useDebounceFn(async () => {
    if (!search) return;
    try {
      setIsLoading(true);
      const searchResponse = await index?.search<HelpCenterArticle>(search);
      if (searchResponse) {
        setArticles(searchResponse.hits);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, 300);

  useEffect(() => {
    debouncedRefetch();
  }, [search]);

  return (
    <>
      {!search.length && (
        <div className="information-text">
          <span>Escriba para iniciar la búsqueda</span>
        </div>
      )}
      {isLoading && (
        <Command.Loading>
          <Loader />
        </Command.Loading>
      )}
      <Command.Empty className="cmdk-empty">Sin resultados</Command.Empty>
      {!isLoading &&
        articles?.map((art) => {
          const { title, _snippetResult, id } = art;
          const cleanSubtitle = _snippetResult.body_safe.value.replace(
            /<[^>]*>?/gm,
            '',
          );
          return (
            <FeatureItem
              value={title}
              key={id}
              title={title}
              subtitle={cleanSubtitle}
              icon={<File02LineIcon />}
              onSelect={() => {
                const url = `${helpCenterArticleUrl}${id}`;
                void openZendeskLinkWithSSO(url, true);
              }}
            />
          );
        })}
    </>
  );
}
