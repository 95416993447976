import formatNumber from './formatNumber';
import toFixed from './toFixed';

function createTax(rate, price, ignoreZeros = true) {
  if (rate === 0 && ignoreZeros) return null;

  const fixedRate = rate || 0;
  const fixedPrice = price || 0;

  return {
    rate,
    amount: toFixed(fixedPrice * fixedRate, 2),
  };
}

export default function calculateInvoiceConcept(concept) {
  // Reset totals when concept has no product
  if (!concept.product) {
    return {
      ...concept,
      amount: 0,
      subtotal: 0,
      ieps: null,
      isr: null,
      iva: null,
      retainedIva: null,
      formattedAmount: formatNumber(0, { decimals: 6 }),
      formattedSubtotal: formatNumber(0, { decimals: 6 }),
    };
  }

  const product = concept.product.value;

  // calculate
  const price = +concept.price || 0;
  const quantity = +concept.quantity || 0;
  const discount = +(concept.discount || 0);
  const subtotal = price * quantity - discount;

  const iva = createTax(product.iva, subtotal, false);
  const ieps = createTax(product.ieps, subtotal);
  const isr = createTax(product.isr, subtotal);
  const retainedIva = createTax(product.retained_iva, subtotal);

  const withTaxes = concept.taxableCode.value === '02';

  let amount = subtotal;
  if (withTaxes) {
    amount +=
      toFixed(ieps?.amount ?? 0, 6) +
      toFixed(iva?.amount ?? 0, 6) -
      toFixed(isr?.amount ?? 0, 6) -
      toFixed(retainedIva?.amount ?? 0, 6);
  }

  // assign values
  const result = {
    ...concept,
    amount,
    subtotal,
    ieps,
    isr,
    iva,
    retainedIva,
    formattedAmount: formatNumber(amount, { decimals: 6 }), // TODO: remove formatted amounts, format it on jsx
    formattedSubtotal: formatNumber(subtotal, { decimals: 6 }), // TODO: remove formatted amounts, format it on jsx
  };

  return result;
}
