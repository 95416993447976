import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Flex, Select, Text } from '@konta/ui';

export default function FormikKontaSelect({
  label,
  name,
  options,
  placeholder,
  onChange = null,
  onBlur = null,
  ...restProps
}) {
  const formik = useFormikContext();
  const [field, meta, helpers] = useField({
    name,
  });

  const touched = meta.touched || formik.submitCount > 0;
  const error = meta.error && touched ? meta.error : null;

  const handleChange = (newValue) => {
    helpers.setValue(newValue);
    // for prevent bug https://github.com/jaredpalmer/formik/issues/2457
    setTimeout(() => {
      helpers.setTouched(true);
    }, 300);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleBlur = (e) => {
    // for prevent bug https://github.com/jaredpalmer/formik/issues/2457
    setTimeout(() => {
      helpers.setTouched(true);
    }, 100);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Flex direction="column" gap={6} css={{ flex: 1 }}>
      <Select
        {...field}
        {...restProps}
        blurInputOnSelect={false}
        onChange={handleChange}
        label={label}
        options={options}
        placeholder={placeholder}
        onBlur={handleBlur}
      />
      {error && (
        <Text xs lineHeight="xs" color="error500">
          {error}
        </Text>
      )}
    </Flex>
  );
}
