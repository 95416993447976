import { styled } from '@konta/ui';

const FormWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$12',
  label: { marginBottom: 0 },
  variants: {
    withMarginTop: {
      true: { mt: '$24' },
      false: { mt: '$0' },
    },
  },
  defaultVariants: {
    withMarginTop: true,
  },
});

export default FormWrapper;
