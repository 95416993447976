import React from 'react';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { Colxx } from '@components';
import {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
} from '@redux/actions';
import { Button, Text } from '@konta/ui';
import { FileDownloadInLcOutlineIcon } from '@konta/icons';
import FeedbackCard from '@components/FeedbackCard';
import WorkflowLayout from '@components/WorkflowLayout';
import useLocalStorage from '@hooks/useLocalStorage';
import useHasPayrollRetention from '@hooks/useHasPayrollRetention';

const getDataFromDeclarationDocument = (
  declarationDocument = null,
  isSalary = false,
) => {
  if (
    declarationDocument &&
    isSalary &&
    !!declarationDocument?.salary_document
  ) {
    return {
      validationDate: declarationDocument.salary_validation_date
        ? dayjs(declarationDocument.salary_validation_date).format('DD-MM-YYYY')
        : 'Sin fecha',
      captureLine: declarationDocument?.salary_capture_line || '',
      urlDocument: declarationDocument.salary_document || '',
    };
  }
  if (declarationDocument && !!declarationDocument?.document) {
    return {
      validationDate: declarationDocument?.validation_date
        ? dayjs(declarationDocument?.validation_date).format('DD-MM-YYYY')
        : 'Sin fecha',
      captureLine: declarationDocument?.capture_line || '',
      urlDocument: declarationDocument?.document || '',
    };
  }
  return null;
};

function WorkflowEnd({
  workflow: { active_declaration: currentDeclaration, id: workflowId },
}) {
  const { hasPayrollRetentions } = useHasPayrollRetention();

  const [workflowFeedbacks, setWorkFlowFeedbacks] = useLocalStorage(
    'workflow-feedbacks',
    [],
  );
  const declarationFile = getDataFromDeclarationDocument(
    currentDeclaration?.declaration_document,
  );
  const salaryDeclarationFile = getDataFromDeclarationDocument(
    currentDeclaration?.declaration_document,
    true,
  );
  const persistWorkflowFeedback = () => {
    if (currentDeclaration?.id) {
      setWorkFlowFeedbacks([...workflowFeedbacks, currentDeclaration.id]);
    }
  };
  const onSuccessFeedback = (response) => {
    if ([200, 201].includes(response?.status)) {
      persistWorkflowFeedback();
    }
  };

  const showFeedback = !workflowFeedbacks.includes(currentDeclaration?.id);

  return (
    <WorkflowLayout>
      <Text css={{ fontFamily: 'Inter' }} x4l color="secondary70" bold>
        ¡Acabaste! 🥳
      </Text>
      <Text css={{ fontFamily: 'Inter' }} l>
        ¡Felicidades! Tómate un café y nos vemos el siguiente mes. 🚀
      </Text>
      {declarationFile && (
        <div>
          <Row>
            <Colxx xxs={{ size: 6, offset: 0 }} className="mb-2">
              <a
                download
                target="_blank"
                href={declarationFile.urlDocument}
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  leftIcon={<FileDownloadInLcOutlineIcon />}
                  onClick={() => {}}
                >
                  Descargar declaración
                </Button>
              </a>
            </Colxx>
          </Row>
        </div>
      )}
      {hasPayrollRetentions && salaryDeclarationFile && (
        <div>
          <Row>
            <Colxx xxs={{ size: 6, offset: 0 }} className="mb-2">
              <a
                download
                target="_blank"
                href={salaryDeclarationFile.urlDocument}
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  leftIcon={<FileDownloadInLcOutlineIcon />}
                  onClick={() => {}}
                >
                  Descargar declaración de sueldos y salarios
                </Button>
              </a>
            </Colxx>
          </Row>
        </div>
      )}
      {showFeedback && (
        <FeedbackCard
          onSuccessFeedback={onSuccessFeedback}
          evaluatedId={workflowId}
          evaluatedType="Workflow"
          subtitle="¿Cómo calificarías el nuestro trabajo en el proceso de la declaración?"
        />
      )}
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ workflows }) => {
  const {
    selected_workflow: { workflow },
  } = workflows;
  return { workflow };
};

const mapDispatchToProps = {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowEnd);
