import React, { useMemo } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { InvoicePDF } from '@components';
import useFiscalRegimesQuery from '@hooks/useFiscalRegimesQuery';
import usePreviewOfPublicInvoice from '@components/Workflow/hooks/usePreviewOfPublicInvoice';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';

export default function MissingPublicInvoicePreview() {
  const { workflow } = useSelectedWorkflow();
  const { getDataForPreviewByDeclaration } = usePreviewOfPublicInvoice();
  const publicInvoicePreview = getDataForPreviewByDeclaration(
    workflow.active_declaration,
  );
  const { regimes } = useFiscalRegimesQuery();
  return useMemo(
    () => (
      <PDFViewer
        width="100%"
        height="100%"
        style={{
          minHeight: '70vh',
        }}
        key={Math.random()}
      >
        <InvoicePDF cfdi={publicInvoicePreview.receipt} regimes={regimes} />
      </PDFViewer>
    ),
    [publicInvoicePreview, regimes],
  );
}
