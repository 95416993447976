import api from '@api';
import { AxiosRequestConfig } from 'axios';
import { Cfdi } from 'types/entities';

export interface GetCfdisProps {
  startDate: string;
  endDate: string;
  axiosConfig?: AxiosRequestConfig<Cfdi[]>;
}

export default async function getCfdisByClients({
  startDate,
  endDate,
  axiosConfig,
}: GetCfdisProps) {
  const { data } = await api.get<Cfdi[]>(
    `/cfdis?q[date_issued_gteq_all]=${startDate}&q[date_issued_lteq_all]=${endDate}`,
    axiosConfig,
  );
  return data;
}
