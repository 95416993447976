import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  fetchTaxableEntitySuccess,
  fetchTaxableEntityFailure,
} from '../actions';

export default function* ({ payload: { taxableEntityId } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.get,
      `/taxable_entities/${taxableEntityId}`,
      {
        headers: { Authorization: `${access_token}` },
      },
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: fetchTaxableEntitySuccess,
      failure: fetchTaxableEntityFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: fetchTaxableEntityFailure,
    });
  }
}
