import { useCallback } from 'react';
import patchTaxableEntityPreferredRegime from 'shared/api/taxableEntity';
import { useMutation, UseMutationOptions } from 'react-query';
import type {
  TaxableEntity,
  TaxableEntityPreferredFiscalRegimePatchRequest,
} from 'types/entities';
import type { AxiosRequestConfig } from 'axios';

export default function usePatchTaxableEntityPreferredFiscalRegime(
  axiosConfig?: AxiosRequestConfig<TaxableEntity>,
  queryOptions?: UseMutationOptions<
    TaxableEntity,
    unknown,
    TaxableEntityPreferredFiscalRegimePatchRequest
  >,
) {
  const patchTaxableEntityPreferredRegimeRequest = useCallback(
    (payload: TaxableEntityPreferredFiscalRegimePatchRequest) =>
      patchTaxableEntityPreferredRegime(payload, axiosConfig),
    [axiosConfig],
  );
  return useMutation(patchTaxableEntityPreferredRegimeRequest, queryOptions);
}
