import { styled } from '@konta/ui';

export const GRADIENT_BACKGROUND =
  'linear-gradient(to left bottom, #717cdb, #686dc9, #605eb7, #5750a5, #4e4294, #4e4294, #4e4294, #4e4294, #5750a5, #605eb7, #686dc9, #717cdb);, #6d72d8, #717cdb);';

const GradientBackground = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: '100vh',
  backgroundImage: GRADIENT_BACKGROUND,
  variants: {
    withBackground: {
      false: {
        backgroundImage: 'none',
      },
    },
  },
});

export default GradientBackground;
