import { styled } from '@konta/ui';

export const Container = styled('div', {
  display: 'flex',
  height: '100px',
  paddingLeft: '0px',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  border: '1px solid $gray200',
  '@<xs': {
    flexDirection: 'column',
    height: 'auto',
    alignItems: 'stretch',
    py: '$12',
    pr: '$12',
  },
});

export const FileDetails = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  pl: '$24',
});

export const FileActions = styled('div', {
  px: '$24',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderLeft: '1px solid $gray200',
  flex: 1,
  '@<xs': {
    flexDirection: 'column',
  },
});
