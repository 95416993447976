import { ONBOARDING_STEPS } from './onboarding';
import { ANNUAL_STEPS } from './annual';

export const PROCESSES_HOME_ROUTE = '/app/processes';

export const PROCESSES_ROUTE = {
  OnboardingOnboardingProcess: ONBOARDING_STEPS,
  AnnualDeclarationProcess: ANNUAL_STEPS,
  FinishOnboardingProcess: {},
};
