import { PaginationControl, Text } from '@konta/ui';
import { ReactNode } from 'react';
import type { PaginationControlProps } from '@konta/ui/dist/components/PaginationControl/PaginationControl';
import { EmptyWrapper, HeaderWrapper, LoaderWrapper, Root } from './styled';
import Loader from '../Loader';

export interface ListProps {
  isLoading?: boolean;
  header?: ReactNode;
  isEmpty?: boolean;
  children?: ReactNode;
  paginationProps?: PaginationControlProps;
}

function Content({
  isLoading,
  isEmpty,
  children,
}: Pick<ListProps, 'isLoading' | 'isEmpty' | 'children'>) {
  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }
  if (isEmpty) {
    return (
      <EmptyWrapper>
        <Text xl>Sin entradas</Text>
      </EmptyWrapper>
    );
  }

  return children as JSX.Element;
}

export default function List({
  header,
  paginationProps,
  isLoading,
  isEmpty,
  ...contentProps
}: ListProps) {
  return (
    <Root>
      {header && <HeaderWrapper>{header}</HeaderWrapper>}
      <Content isLoading={isLoading} isEmpty={isEmpty} {...contentProps} />
      {!!paginationProps && !isLoading && (
        <PaginationControl {...paginationProps} />
      )}
    </Root>
  );
}
