import { useQuery, UseQueryOptions } from 'react-query';
import { TaxableEntity } from 'types/entities';
import { getTaxableEntities } from '../api/taxableEntity';
import {
  TAXABLE_ENTITIES,
  TAXABLE_ENTITIES_TABLE,
} from '../constants/reactQueries';

export default function useTaxableEntities(
  params?: Record<string, string | number | object | boolean>,
  queryOptions?: UseQueryOptions<TaxableEntity[]>,
  queryKey:
    | typeof TAXABLE_ENTITIES_TABLE
    | typeof TAXABLE_ENTITIES = 'TAXABLE_ENTITIES',
) {
  const {
    data = [],
    isLoading,
    isFetching,
    ...rest
  } = useQuery<TaxableEntity[]>(
    [queryKey, params],
    async () => {
      const taxableEntites = await getTaxableEntities(params);
      return taxableEntites;
    },
    queryOptions,
  );

  return {
    taxableEntitiesLoading: isLoading || isFetching,
    taxableEntities: data,
    ...rest,
  };
}
