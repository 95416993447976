import { useEffect, useState } from 'react';
import api from '@api';
import type { CfdiRelatedAttributes } from 'types/cfdi';
import type {
  Cfdi,
  ClientSupplier,
  ReactSelectItemProps,
} from 'types/entities';
import useDraftSelectsParser from './useDraftSelectsParser';

export default function useCfdiRelatedAttributesParser(
  client?: ClientSupplier,
  cfdiRelatedAttributes?: CfdiRelatedAttributes,
) {
  const [options, setOptions] = useState<ReactSelectItemProps<Cfdi>[]>([]);

  useEffect(() => {
    const fetchCfdis = async () => {
      try {
        if (!cfdiRelatedAttributes) {
          return;
        }
        if (!client?.rfc) {
          setOptions([]);
          return;
        }
        const response = await api.get<Cfdi[]>(
          `cfdis/by_client?rfc=${client.rfc}`,
        );
        const parsedOptions = response.data.map((cfdi) => ({
          value: cfdi,
          key: cfdi.folio_fiscal,
          label: `${cfdi?.client_supplier?.rfc || ''} - ${
            cfdi.folio_fiscal
          } - ${cfdi.date_issued}`,
          uuid: cfdi.folio_fiscal,
        }));

        setOptions(parsedOptions);
      } catch (e) {
        console.error(e);
      }
    };
    void fetchCfdis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const creditNoteInvoice = useDraftSelectsParser(
    cfdiRelatedAttributes?.related_attributes[0].uuid,
    options,
    undefined,
    'uuid',
  );

  return {
    creditNoteInvoice,
  };
}
