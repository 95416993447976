import { Chip, Flex, HoverCard, Text } from '@konta/ui';
import * as chat from '@util/chat';
import ScoreGraph from 'shared/components/ScoreGraph';
import useHeader from './useHeader';
import {
  Root,
  KontaStatusContainer,
  KontaTextContainer,
  SatLastUpdateContainer,
  SatLastUpdate,
  SatContainer,
  SatText,
  Title,
  HelpCircleIcon,
  SatTextContainer,
  scoreFontCss,
} from './styled';

interface HeaderProps {
  hasNotActiveSubscription: boolean;
}

export default function Header({ hasNotActiveSubscription }: HeaderProps) {
  const { kontaScore, nickName } = useHeader();
  return (
    <Root>
      <KontaStatusContainer>
        <ScoreGraph fontCss={scoreFontCss} score={kontaScore.score} />
      </KontaStatusContainer>
      <KontaTextContainer>
        {hasNotActiveSubscription ? (
          <Title>DATOS DE PRUEBA</Title>
        ) : (
          <Title>Hola 👋🏼 {nickName}</Title>
        )}
        <SatContainer>
          <SatTextContainer>
            <SatText>Tu calificación fiscal es</SatText>
            <Chip
              label={kontaScore.status}
              color={kontaScore.chipColor}
              size={{ '@initial': '2xs', '@md': 'xs' }}
            />
          </SatTextContainer>
          <SatLastUpdateContainer>
            <SatLastUpdate>
              Última actualización: {kontaScore.lastUpdated}
            </SatLastUpdate>
            <HoverCard
              side="top"
              openDelay={0}
              triggerElement={
                <Flex itemsCenter tabIndex={-1} role="button">
                  <HelpCircleIcon />
                </Flex>
              }
            >
              <Flex column gap={6}>
                <Text>
                  En esta sección encontrarás tu <Text bold>KontaEstatus</Text>.
                </Text>
                <Text>
                  Esta calificación es una representación de tu{' '}
                  <b>estatus en el SAT</b>
                  según Konta.
                </Text>
                <Text>
                  Tu calificación puede ir del <b>0% al 100%</b> (mientras más
                  alto mejor) y representa <b>que tan bien estás con el SAT</b>.
                  Sirve para estar tranquilo si no tienes pendientes y a saber
                  cómo mejorar si estás mal.
                </Text>
                <Text>
                  El objetivo principal de nuestro servicio es ayudarte a
                  mejorar y mantener esta calificación alta.
                </Text>
                <Text>
                  Calculamos el porcentaje de declaraciones atrasadas en los
                  últimos 12 meses con respecto a tus obligaciones
                </Text>
                <Text>
                  ¿Tienes preguntas?{' '}
                  <Text as="a" color="primary700" href="#" onClick={chat.open}>
                    Contáctanos en el chat.
                  </Text>
                </Text>
              </Flex>
            </HoverCard>
          </SatLastUpdateContainer>
        </SatContainer>
      </KontaTextContainer>
    </Root>
  );
}
