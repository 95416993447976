import React from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { CustomSelect } from '../Forms';

export default function SelectInput({ label, name, error, ...restProps }) {
  return (
    <FormGroup>
      {Boolean(label) && <Label htmlFor={name}>{label}</Label>}
      <CustomSelect {...restProps} name={name} hasError={!!error} />
      {!!error && (
        <FormFeedback style={{ display: 'block' }}>{error}</FormFeedback>
      )}
    </FormGroup>
  );
}
