import React, { useState } from 'react';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { Flex } from '@konta/ui';
import FormikTextarea from '@components/FormikTextarea';
import FormikKontaSelect from '@components/FormikKontaSelect';
import ProcessStepLayout from '@components/ProcessStepLayout';
import { ProcessType } from 'types/entities.ts';
import NextStepButton from '@components/NextStepButton';
import ProcessStepDescription from '@components/ProcessStepDescription';
import ProcessStepTitle from '@components/ProcessStepTitle';

const RATE_OPTIONS = [
  { value: 'Super', label: 'Súper 😎' },
  { value: 'Bien', label: 'Bien 👍🏼' },
  { value: 'Mal', label: 'Mal 👎' },
];

const process = ProcessType.OnboardingOnboardingProcess;

const validationSchema = yup.object().shape({
  howWasTheCall: yup.object().nullable().required('Campo requerido'),
  whatDoYouLikeMostAboutTheCall: yup.string().required('Campo requerido'),
  whatWeCanImprove: yup.string().required('Campo requerido'),
});

const initialValues = {
  howWasTheCall: '',
  whatDoYouLikeMostAboutTheCall: '',
  whatWeCanImprove: '',
};

export default function OnboardingFeedback() {
  const [payload, setPayload] = useState(null);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setPayload({
        how_was_the_call: values.howWasTheCall.value,
        what_do_you_like_most_about_the_call:
          values.whatDoYouLikeMostAboutTheCall,
        what_we_can_improve: values.whatWeCanImprove,
      });
    },
  });
  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Apertura de cuenta"
      titleChip="En curso"
      content={
        <FormikProvider value={formik}>
          <Flex direction="column" gap={12} css={{ maxWidth: '670px' }}>
            <ProcessStepTitle>
              Ayudanos a mejorar ¿Cómo te fue en la llamada?
            </ProcessStepTitle>
            <ProcessStepDescription>
              Ya estás cerca de finalizar tu proceso de bienvenida.
            </ProcessStepDescription>
          </Flex>
          <Flex direction="column" gap={12} css={{ maxWidth: '400px' }}>
            <FormikKontaSelect
              id="howWasTheCall"
              options={RATE_OPTIONS}
              name="howWasTheCall"
              label="¿Cómo te fue en la llamada?"
            />
            <FormikTextarea
              name="whatDoYouLikeMostAboutTheCall"
              data-cy="feedbackWhatDoYouLikeMostAboutTheCallInput"
              rows={5}
              label="¿Qué fue lo que más te gustó de la llamada?"
              placeholder="Ej. Creo que no me preguntaron de.... "
            />
            <FormikTextarea
              name="whatWeCanImprove"
              data-cy="feedbackWhatWeCanImproveInput"
              rows={5}
              label="¿En qué podríamos mejorar?"
              placeholder="Ej. Creo que no me preguntaron de.... "
            />
          </Flex>
          <Flex gap={12}>
            <NextStepButton
              currentProcessType={process}
              text="Continuar"
              onClick={formik.submitForm}
              payload={payload}
              data-cy="onboardingFeedbackNextStepBtn"
            />
          </Flex>
        </FormikProvider>
      }
      rightSidebarContent
    />
  );
}
