import React, { useState } from 'react';
import { useToggle } from 'rooks';
import ComplementTableForm from '@components/ComplementTableForm';
import { PDFModal } from '@components/Utils';
import InvoiceDraft from '@components/InvoiceDraft';
import InvoiceTemplates from '@components/InvoiceTemplates';
import InvoiceTransactionGraph from '@components/InvoiceTransactionGraph';
import getInvoiceStatus from '@util/getInvoiceStatus';
import EmittedInvoices from './EmittedCfdis';
import ReceivedCfdis from './ReceivedCfdis';
import useCfdiStore from '../../store/cfdisStore';

export default function UserInvoicesTable() {
  const selectedTab = useCfdiStore((state) => state.selectedTab);
  const [cfdi, setCfdi] = useState(false);
  const [togglePdf, setTogglePdf] = useToggle(false);
  const [toggleComplement, setToggleComplement] = useToggle(false);
  const [modalTransactionData, setModalTransactionData] = useState(null);

  const closeInvoiceDetails = () => {
    setModalTransactionData(null);
  };

  const handleShowReports = (selectedCfdi) => {
    const isEmitted = selectedCfdi.is_emited;
    setModalTransactionData({
      rfc: isEmitted ? selectedCfdi.receiver_rfc : selectedCfdi.issuer_rfc,
      legalName: isEmitted
        ? selectedCfdi.receiver_legal_name
        : selectedCfdi.issuer_legal_name,
      isEmitted,
    });
  };

  return (
    <>
      {selectedTab === 'emitted' && (
        <EmittedInvoices
          handleShowReports={handleShowReports}
          setCfdi={setCfdi}
          setToggleComplement={setToggleComplement}
          setTogglePdf={setTogglePdf}
        />
      )}
      {selectedTab === 'received' && (
        <ReceivedCfdis
          handleShowReports={handleShowReports}
          setCfdi={setCfdi}
          setToggleComplement={setToggleComplement}
          setTogglePdf={setTogglePdf}
        />
      )}
      {selectedTab === 'drafts' && <InvoiceDraft />}
      {selectedTab === 'templates' && <InvoiceTemplates />}
      {!!cfdi && (
        <ComplementTableForm
          cfdi={cfdi}
          toggle={toggleComplement}
          toggleModal={setToggleComplement}
        />
      )}
      <PDFModal
        url={cfdi.xml_url}
        title="Vista previa de la factura"
        id={cfdi.id}
        status={getInvoiceStatus(cfdi.status)}
        onToggle={setTogglePdf}
        togglePdf={togglePdf}
        invoice={cfdi}
      />
      {!!modalTransactionData && (
        <InvoiceTransactionGraph
          selectedData={modalTransactionData}
          open={!!modalTransactionData}
          toggleOpen={closeInvoiceDetails}
        />
      )}
    </>
  );
}
