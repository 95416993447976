import impersonate from '@api/impersonate';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useImpersonation() {
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();

  const impersonateToken = search.get('token');

  const impersonateLogin = useCallback(async () => {
    if (!impersonateToken) return;
    setLoading(true);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const user = await impersonate(impersonateToken);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const accessToken: string = user.access_token;

    localStorage.setItem('user_id', `${accessToken}retool`);
    window.location.href = window.location.origin;
  }, [impersonateToken]);

  useEffect(() => {
    impersonateLogin().finally(() => {
      setLoading(false);
    });
  }, [impersonateLogin]);

  return loading;
}
