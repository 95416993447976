import { AnnualRouteType, AnnualSteps } from 'types/annual';
import { AnualProcessStepTypes } from 'types/entities';

// eslint-disable-next-line import/prefer-default-export
export const ANNUAL_STEPS: AnnualSteps = {
  [AnualProcessStepTypes.Welcome]: AnnualRouteType.Welcome,
  [AnualProcessStepTypes.Charges]: AnnualRouteType.Charges,
  [AnualProcessStepTypes.Documents]: AnnualRouteType.Documents,
  [AnualProcessStepTypes.Informative]: AnnualRouteType.Informative,
  [AnualProcessStepTypes.Upload]: AnnualRouteType.Upload,
  [AnualProcessStepTypes.Resume]: AnnualRouteType.Resume,
  [AnualProcessStepTypes.Preferences]: AnnualRouteType.Preferences,
  [AnualProcessStepTypes.Recommendations]: AnnualRouteType.Recommendations,
  [AnualProcessStepTypes.Rejection]: AnnualRouteType.Rejection,
};
