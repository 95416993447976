import React, { useMemo, useState } from 'react';
import { Flex, Input, InputAction } from '@konta/ui';
import postInvitation from '@api/postInvitation';
import { NotificationManager } from '@components';
import getErrorMessage from '@util/getErrorMessage';
import isValidEmail from '@util/isValidEmail';
import useInvitedReferralsPagination from './useInvitedReferralsPagination.ts';
import { ReactComponent as SendIcon } from './img/send-icon.svg';

export default function ReferByEmailInput() {
  const { refetch } = useInvitedReferralsPagination();
  const [email, setEmail] = useState('');
  const [touched, setTouched] = useState(false);
  const isValid = useMemo(
    () => (touched ? !email || isValidEmail(email) : true),
    [email, touched],
  );

  const sendInvite = async () => {
    if (!isValidEmail(email)) return;

    try {
      await postInvitation({ email });

      refetch();
      setEmail('');
      setTouched(false);
      NotificationManager.success(
        'Invitación enviada por email',
        'Referido',
        3000,
      );
    } catch (error) {
      const message = getErrorMessage(error);
      NotificationManager.error(message, 'Error', 6000);
    }
  };

  return (
    <Input
      value={email}
      css={{ label: { margin: 0 } }}
      onChange={({ target }) => setEmail(target.value)}
      size="s"
      label="Invitar por email"
      placeholder="demo@gmail.com"
      color={isValid ? 'gray' : 'error'}
      helperText={!isValid && 'Email invalido'}
      helperTextColor="error"
      onBlur={() => setTouched(true)}
      rightActions={
        <InputAction onClick={sendInvite}>
          <Flex gap="4" align="center">
            <SendIcon />
            Invitar
          </Flex>
        </InputAction>
      }
    />
  );
}
