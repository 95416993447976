import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import api from '@api';
import { CFDI_PDF_BY_ID } from 'backoffice/constants/reactQueries';

export default function useCfdiPdfById(
  cfdiId: number | string,
  axiosConfig?: AxiosRequestConfig<string | null>,
  queryOptions?: UseQueryOptions<string | null>,
) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<string | null>(
    [CFDI_PDF_BY_ID, cfdiId, axiosConfig],
    async () => {
      const response = await api.get<string>(`/cfdis/${cfdiId}.pdf`, {
        responseType: 'arraybuffer',
        ...axiosConfig,
      });
      if (!response.data) return null;
      const buffer = Buffer.from(response.data, 'binary').toString('base64');
      return `data:application/pdf;base64,${buffer}`;
    },
    queryOptions,
  );

  return {
    cfdiPdfLoading: isLoading || isFetching,
    cfdiPdf: data,
    ...rest,
  };
}
