import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

if (process.env.REACT_APP_ENV === 'BACKOFFICE') {
  try {
    const item = window.localStorage.getItem('environments');
    const environments = item
      ? JSON.parse(item)
      : process.env.REACT_APP_API_URL;
    if (!environments.api) {
      throw Error('Error reading localStorage key “environments”');
    }
    api.defaults.baseURL = environments.api;
  } catch (error) {
    console.error(error);
  }
}

api.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  const hasAuth = config.headers.authorization || config.headers.Authorization;
  if (!hasAuth) {
    config.headers.Authorization = localStorage.getItem('user_id');
  }
  return config;
});

export default api;
