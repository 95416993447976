import useApiPagination from 'shared/hooks/useApiPagination';
import type { UseApiPaginationOptions } from 'shared/hooks/useApiPagination';
import type { DeclarationEntry } from 'types/entities';

type DeclarationEntriesPaginationOptions =
  UseApiPaginationOptions<DeclarationEntry>;

export default function useDeclarationEntriesPagination(
  options?: Partial<Omit<DeclarationEntriesPaginationOptions, 'endpoint'>>,
) {
  const endpoint = `declaration_entries`;

  const { loading, items, ...restProps } = useApiPagination<DeclarationEntry>({
    ...options,
    endpoint,
  });

  return {
    ...restProps,
    declarationEntriesLoading: loading,
    declarationEntries: items,
  };
}
