import React from 'react';
import { Row, Card, CardBody, Collapse } from 'reactstrap';
import { Colxx } from '@components';

export function CustomAccordion(props) {
  const handleAccordionClick = () => {
    if (!props.isOpen) {
      props.setSelectedUploadIncomeMethod(props.incomeMethod);
    } else {
      props.setSelectedUploadIncomeMethod(null);
    }
    // setSelectedAccordion(!props.isOpen);
  };

  return (
    <Card className="mb-4 onboarding-card">
      <CardBody
        className="py-4 onboarding-button"
        data-testid={`${props.testId}-option`}
        onClick={() => handleAccordionClick()}
      >
        <Row>
          <Colxx md={10}>
            <div className="d-flex align-items-center">
              <div className="mr-3 onboarding-icon">
                {props.titleIcon()}
                {props.isOpen && (
                  <span className="mr-3 text-center center-layout onboarding-step-completed onboarding-complete">
                    <div>
                      <svg
                        className="onboarding-check"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13.933 3.09a.35.35 0 0 0-.495.026l-7.236 8.012-3.757-3.393a.35.35 0 0 0-.495.025l-.86.951a.35.35 0 0 0 .026.495l4.96 4.48a.34.34 0 0 0 .17.082.346.346 0 0 0 .34-.107l8.324-9.217a.35.35 0 0 0-.026-.494l-.951-.86z" />
                      </svg>
                    </div>
                  </span>
                )}
              </div>
              <div>{props.title()}</div>
            </div>
          </Colxx>
          {props.textBadge ? props.textBadge() : null}
        </Row>
      </CardBody>
      {props.isOpen && (
        <div className="d-flex onboarding-progress-bar">
          <div
            className="d-flex onboarding-progress-bar-complete"
            style={{ width: '100%' }}
          />
          {/* <div className="d-flex onboarding-progress-bar-incomplete" style={{ width: firstStatusBarWidths.incomplete }} ></div> */}
        </div>
      )}
      <Collapse isOpen={props.isOpen}>{props.children}</Collapse>
    </Card>
  );
}
