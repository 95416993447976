import { Flex } from '@konta/ui';
import { containerCss } from '../styled';

export default function Opt1() {
  return (
    <Flex column css={containerCss}>
      <iframe
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fcp3k2g9CntEaCdL1dRt660%2F2024%3Fpage-id%3D2023%253A36820%26node-id%3D3193-36535%26viewport%3D583%252C126%252C0.06%26t%3DqvYNOAJBxOkpEeKp-1%26scaling%3Dmin-zoom%26content-scaling%3Dfixed%26starting-point-node-id%3D3193%253A36535"
        allowFullScreen
      ></iframe>
    </Flex>
  );
}
