import React from 'react';
import { Bar } from 'react-chartjs-2';

const lineShadowPlugin = {
  id: 'custom_line_shadow',
  beforeDraw: (chart) => {
    const ctx = chart.canvas.getContext('2d');
    ctx.save();
    ctx.shadowColor = 'rgba(0,0,0,0.15)';
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 10;
    ctx.responsive = true;
    ctx.stroke();
    ctx.restore();
  },
};

function LineShadowChart(props) {
  return <Bar plugins={[lineShadowPlugin]} {...props} />;
}

export default LineShadowChart;
