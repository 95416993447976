import { keyframes, styled, Text } from '@konta/ui';

export const slideInDesktop = keyframes({
  '0%': { transform: 'translateX(100%)' },
  '100%': { transform: 'translateX(0)' },
});

export const slideOutDesktop = keyframes({
  '0%': { transform: 'translateX(0)' },
  '100%': { transform: 'translateX(100%)' },
});

export const slideInMobile = keyframes({
  '0%': { transform: 'translateY(100%)' },
  '100%': { transform: 'translateY(0)' },
});

export const slideOutMobile = keyframes({
  '0%': { transform: 'translateY(0)' },
  '100%': { transform: 'translateY(100%)' },
});

export const SidebarContainer = styled('div', {
  position: 'fixed',
  backgroundColor: '$gray25',
  borderLeft: '1px solid $colors$gray200',
  zIndex: 1000,
  padding: '16px',
  overflowY: 'auto',
  transition: 'transform 0.3s ease-in-out',
  variants: {
    isMobile: {
      true: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: '70%',
        width: '100%',
        paddingTop: '1rem',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        transform: 'translateY(100%)',
        boxShadow:
          '0px -12px 16px -4px rgba(16, 24, 40, 0.08), 0px -4px 6px -2px rgba(16, 24, 40, 0.03);',
        '&.open': {
          transform: 'translateY(0)',
        },
      },
      false: {
        top: 0,
        right: 0,
        bottom: 0,
        width: '480px',
        transform: 'translateX(100%)',
        '&.open': {
          transform: 'translateX(0)',
          animation: `${slideInDesktop} 0.3s ease-in-out`,
        },
        '&.closed': {
          animation: `${slideOutDesktop} 0.3s ease-in-out`,
        },
      },
    },
  },
});

export const MobileHandle = styled('div', {
  width: '72px',
  height: '4px',
  backgroundColor: '$gray500',
  borderRadius: '2px',
  margin: '0 auto 1rem',
});

export const Overlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
});

export const Title = styled(Text, {
  fontSize: '$m',
  fontWeight: '600',
});

export const IconTitleCss = {
  width: '18px',
  height: '18px',
  svg: {
    width: '18px',
    height: '18px',
  },
};

export const SidebarHeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignContent: 'flex-start',
});
