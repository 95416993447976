import React, { useMemo } from 'react';
import { Cell, Row, Table, TableBody, TableHead } from '@konta/ui';
import useActiveDeclarationIncome from '@hooks/useActiveDeclarationIncome';
import toCurrency from '@util/toCurrency';

export default function IncomeSummaryTable() {
  const {
    totalBases,
    totalIva,
    totalBasesWithIva,
    totalIvaBasesUnbilled,
    totalIvaOfCfdis,
    totalBasesWithIvaOfCfdis,
    totalTaxableBasesOfCfdis,
    totalIvaUnbilled,
    totalBasesWithIvaUnbilled,
    totalIvaExemptBasesOfCfdis,
    totalZeroBasesOfCfdis,
    totalZeroBases,
    totalExemptBases,
    totalZeroBasesUnbilled,
    totalExemptBasesUnbilled,
  } = useActiveDeclarationIncome();

  const rows = useMemo(
    () => [
      {
        description: 'Total de ingresos capturados',
        base: totalBases,
        exemptBase: totalExemptBases,
        zeroBase: totalZeroBases,
        tax: totalIva,
        total: totalBasesWithIva,
        prefix: '+',
      },
      {
        description: 'Total de ingresos según el SAT',
        base: totalTaxableBasesOfCfdis,
        exemptBase: totalIvaExemptBasesOfCfdis,
        zeroBase: totalZeroBasesOfCfdis,
        tax: totalIvaOfCfdis,
        total: totalBasesWithIvaOfCfdis,
        prefix: '-',
      },
      {
        description: 'Diferencias',
        base: totalIvaBasesUnbilled,
        exemptBase: totalExemptBasesUnbilled,
        zeroBase: totalZeroBasesUnbilled,
        tax: totalIvaUnbilled,
        total: totalBasesWithIvaUnbilled,
        bold: true,
      },
    ],
    [
      totalIvaBasesUnbilled,
      totalTaxableBasesOfCfdis,
      totalBases,
      totalBasesWithIva,
      totalBasesWithIvaOfCfdis,
      totalBasesWithIvaUnbilled,
      totalExemptBases,
      totalExemptBasesUnbilled,
      totalIva,
      totalIvaExemptBasesOfCfdis,
      totalIvaOfCfdis,
      totalIvaUnbilled,
      totalZeroBases,
      totalZeroBasesOfCfdis,
      totalZeroBasesUnbilled,
    ],
  );

  return (
    <Table fullWidth>
      <TableHead>
        <Row transparent>
          <Cell />
          <Cell>Ingresos al 16% ó 8%</Cell>
          <Cell>Ingresos exentos</Cell>
          <Cell>Ingresos al 0%</Cell>
          <Cell>Impuestos</Cell>
          <Cell>Total</Cell>
        </Row>
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          const cellClass =
            row.bold || (row.prefix === '-' ? 'text-danger' : 'text-success');
          return (
            <Row
              key={row.description}
              active={row.bold}
              css={{ fontWeight: row.bold ? '$bold' : '$medium' }}
            >
              <Cell>{row.description}</Cell>
              <Cell className={cellClass}>
                {row.prefix}
                {toCurrency(row.base)}
              </Cell>
              <Cell className={cellClass}>
                {row.prefix}
                {toCurrency(row.exemptBase)}
              </Cell>
              <Cell className={cellClass}>
                {row.prefix}
                {toCurrency(row.zeroBase)}
              </Cell>
              <Cell className={cellClass}>
                {row.prefix}
                {toCurrency(row.tax)}
              </Cell>
              <Cell className={cellClass}>
                {row?.prefix}
                {toCurrency(row.total)}
              </Cell>
            </Row>
          );
        })}
      </TableBody>
    </Table>
  );
}
