import { FocusEventHandler, ComponentProps, FocusEvent, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { Flex, Select, Text } from '@konta/ui';
import { ReactSelectItemProps } from 'types/entities';

interface FormikKontaSelectProps<T>
  extends Omit<ComponentProps<typeof Select>, 'isMulti'> {
  label: string;
  name: string;
  options: ReactSelectItemProps<T>[];
  placeholder?: string;
  onChange?: (newValue: unknown) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  id?: string;
  isMulti?: boolean;
  optionKey?: 'value' | 'label' | 'key';
  isDisabled?: boolean;
}

export default function FormikSelect<T>({
  label,
  name,
  options,
  placeholder = 'Selecciona una opción',
  onChange,
  onBlur,
  id,
  isMulti,
  optionKey = 'value',
  isDisabled,
  ...restProps
}: FormikKontaSelectProps<T>) {
  const formik = useFormikContext();
  const [field, meta, helpers] = useField({
    name,
  });

  const touched = meta.touched || formik.submitCount > 0;
  const error = meta.error && touched ? meta.error : null;

  const handleChange = (newValue: unknown) => {
    if (isMulti) {
      helpers.setValue(
        (newValue as { value: string; label: string; key?: number }[]).map(
          (option) => option[optionKey],
        ),
      );
    } else {
      helpers.setValue(
        newValue
          ? (newValue as { value: string; label: string; key?: number })[
              optionKey
            ]
          : newValue,
      );
    }
    // for prevent bug https://github.com/jaredpalmer/formik/issues/2457
    setTimeout(() => {
      helpers.setTouched(true);
    }, 300);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    // for prevent bug https://github.com/jaredpalmer/formik/issues/2457
    setTimeout(() => {
      helpers.setTouched(true);
    }, 100);
    if (onBlur) {
      onBlur(e);
    }
  };

  const value = useMemo(() => {
    if (isMulti) {
      if (!Array.isArray(field.value)) return [];
      return options.filter((option) =>
        (field as { value: (string | number | boolean)[] })?.value.includes(
          option[optionKey] as string | number | boolean,
        ),
      );
    }
    return options.find((option) => option[optionKey] === field.value) || '';
  }, [field, isMulti, optionKey, options]);

  return (
    <Flex direction="column" gap={6} css={{ flex: 1 }}>
      <Select
        {...field}
        {...restProps}
        blurInputOnSelect={false}
        onChange={handleChange}
        label={label}
        options={options}
        placeholder={placeholder}
        onBlur={handleBlur}
        value={value}
        id={id}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isMulti={isMulti}
        isDisabled={isDisabled}
      />
      {error && (
        <Text xs lineHeight="xs" color="error500">
          {error}
        </Text>
      )}
    </Flex>
  );
}
