import React, { createContext, useMemo, useReducer } from 'react';
import Tracker from '@openreplay/tracker';
import { UUIDgen } from '@util/Utils';
import * as Sentry from '@sentry/react';
import api from '@api';
import { IS_LOCAL } from '@constants/enviroments';

export const TrackerContext = createContext();

function newTracker(config) {
  const getUserId = () => {
    if (config?.userIdEnabled && config?.getUserId) {
      return config.getUserId;
    }
    return new UUIDgen().generate();
  };

  let userId = null;
  const trackerConfig = {
    projectKey: config?.projectKey || process.env.REACT_APP_OPEN_REPLAY_KEY,
    __DISABLE_SECURE_MODE:
      // eslint-disable-next-line no-underscore-dangle
      config?.__DISABLE_SECURE_MODE || IS_LOCAL,
    obscureTextNumbers: false,
    obscureTextEmails: false,
    network: {
      capturePayload: true,
      axiosInstances: [api],
      sanitiser: (data) => {
        if (data?.url?.endsWith('api/v1/sign_in')) {
          data.request.body = null;
        }
        if (data?.url?.endsWith('api/v1/') && data?.method === 'POST') {
          data.request.body = null;
        }
        if (data?.request?.body?.taxable_entity?.ciec) {
          data.request.body.taxable_entity.ciec = '<SECRET>';
        }
        if (data?.request?.body?.user?.password) {
          data.request.body.user.password = '<SECRET>';
        }
        if (data?.request?.body?.user?.password_confirmation) {
          data.request.body.user.password_confirmation = '<SECRET>';
        }
        if (data?.status < 400 && data?.response?.body?.access_token) {
          data.response.body.access_token = '<SECRET>';
        }
        if (data?.status < 400 && data?.response?.body?.otp_secret) {
          data.response.body.otp_secret = '<SECRET>';
        }

        return data;
      },
    },
  };
  if (config?.ingestPoint || process.env.REACT_APP_OPEN_REPLAY_INGEST_POINT) {
    trackerConfig.ingestPoint =
      config?.ingestPoint || process.env.REACT_APP_OPEN_REPLAY_INGEST_POINT;
  }

  const tracker = new Tracker({
    ...trackerConfig,
    onStart: ({ sessionToken }) => {
      Sentry.setTag('openReplaySessionToken', sessionToken);
      Sentry.setTag('openReplaySessionURL', tracker.getSessionURL());
    },
  });
  if (config?.userIdEnabled) {
    userId = getUserId();
    tracker.setUserID(userId);
  }
  return tracker;
}
function reducer(state, action) {
  switch (action.type) {
    case 'init': {
      if (!state.tracker) {
        const t = newTracker(state.config);
        const pluginsReturnedValue = {};
        if (state.config.plugins) {
          state.config.plugins.forEach((p) => {
            pluginsReturnedValue[p.name] = t.use(p.fn(p.config));
          });
        }
        return {
          ...state,
          pluginsReturnedValue,
          tracker: t,
        };
      }
      return state;
    }
    case 'start': {
      state.tracker.start();
      return state;
    }
    case 'logEvent': {
      state.tracker?.event(action.payload?.name, action.payload?.data);
      return state;
    }
    case 'logIssue': {
      state.tracker?.issue(action.payload?.name, action.payload?.data);
      return state;
    }
    default:
      return state;
  }
}
export default function TrackerProvider({ children, config = {} }) {
  const [state, dispatch] = useReducer(reducer, {
    tracker: null,
    pluginsReturnedValue: {},
    config,
  });

  const value = useMemo(
    () => ({
      startTracking: () => dispatch({ type: 'start' }),
      initTracker: () => dispatch({ type: 'init' }),
      logEvent: (evnt) => dispatch({ type: 'logEvent', payload: evnt }),
      logIssue: (evnt) => dispatch({ type: 'logIssue', payload: evnt }),
      pluginsReturnedValues: { ...state.pluginsReturnedValue },
      tracker: state.tracker,
    }),
    [state.pluginsReturnedValue, state.tracker],
  );

  return (
    <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>
  );
}
