const color = [
  'gray100',
  'gray200',
  'gray300',
  'gray400',
  'gray500',
  'gray600',
  'gray700',
  'gray800',
  'gray900',
  'primary100',
  'primary200',
  'primary300',
  'primary400',
  'primary500',
  'primary600',
  'primary700',
  'primary800',
  'primary900',
  'error100',
  'error200',
  'error300',
  'error400',
  'error500',
  'error600',
  'error700',
  'error800',
  'error900',
  'warning100',
  'warning200',
  'warning300',
  'warning400',
  'warning500',
  'warning600',
  'warning700',
  'warning800',
  'warning900',
  'success100',
  'success200',
  'success300',
  'success400',
  'success500',
  'success600',
  'success700',
  'success800',
  'success900',
  'gray',
  'primary',
  'success',
  'warning',
  'error',
];

// eslint-disable-next-line import/prefer-default-export
export const randomColor = (number: number, key: string) => {
  const storedColor = localStorage.getItem(`storedColor-${key}`);

  if (storedColor) {
    return storedColor;
  }

  const filteredColors = color.filter((c) => c.includes(number.toString()));

  if (filteredColors.length === 0) {
    return 'gray200';
  }

  const index = Math.floor(Math.random() * filteredColors.length);
  const newRandomColor = filteredColors[index];

  // Save the generated color to localStorage for future use
  localStorage.setItem(`storedColor-${key}`, newRandomColor);

  return newRandomColor;
};
