import api from '@api';
import { call } from 'redux-saga/effects';

import { ErrorHandler, StatusHandler } from '@util/Saga';
import {
  deletePaymentMethodSuccess,
  deletePaymentMethodFailure,
} from '../actions';

export function* deletePaymentMethod({ payload: { taxable_entity, card } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.delete,
      `taxable_entities/${taxable_entity}/payment_methods/${card}`,
      { headers: { Authorization: `${access_token}` } }
    );
    response.data = card; // when call success set card to delete in store deletePaymentMethodSuccess
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: deletePaymentMethodSuccess,
      failure: deletePaymentMethodFailure,
      title: 'Éxito',
      message: 'La tarjeta ha sido borrada exitosamente',
    });
  } catch (error) {
    // catch throw
    let message = 'Algo salió mal en el borrado de la tarjeta';
    const errorsMessage = error?.response?.data?.default_card || [];
    if (errorsMessage.includes('cannot delete your default payment method')) {
      message = 'No puedes borrar tu tarjeta predeterminada';
    }
    yield call(ErrorHandler, {
      error,
      showBodyErrors: true,
      message,
      action: deletePaymentMethodFailure,
    });
  }
}
