import React, { useState } from 'react';
import { Input } from 'reactstrap';
import userSearch from '@components/Utils/userSearch';

function HelpCenterSearch({ className, placeholder = 'Buscar' }) {
  const [searchKeyword, setSearchKeyword] = useState('');

  const search = () => {
    userSearch({ search: searchKeyword });
    setSearchKeyword('');
  };

  const handleSearchInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      search();
    }
  };

  const handleSearchIconClick = (e) => {
    search();
    e.stopPropagation();
  };

  return (
    <div className={className || 'd-flex justify-content-center mt-4 mb-4'}>
      <div className="help-search">
        <Input
          name="searchKeyword"
          id="searchKeyword"
          placeholder={placeholder}
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          onKeyPress={(e) => handleSearchInputKeyPress(e)}
        />
        <span className="search-icon" onClick={(e) => handleSearchIconClick(e)}>
          <i className="simple-icon-magnifier" />
        </span>
      </div>
    </div>
  );
}

export default HelpCenterSearch;
