import React from 'react';
import { Row } from 'reactstrap';
import { useToggle } from 'rooks';
import { Button, Text } from '@konta/ui';
import { Eye2Icon } from '@konta/icons';
import { PreviewPDFModal } from '@components/Utils';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowLayout from '@components/WorkflowLayout';
import WorkflowHeader from '@components/WorkflowHeader';
import toCurrency from '@util/toCurrency';
import PublicInvoiceTable from '@components/PublicInvoiceTable/PublicInvoiceTable';
import { Colxx } from '@components';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import usePreviewOfPublicInvoice from '@components/Workflow/hooks/usePreviewOfPublicInvoice';

export default function CreateMisingPublicInvoiceResico() {
  const { workflow, loading: loadingWorkflow } = useSelectedWorkflow();
  const { getDataForPreviewByDeclaration, previewLoading } =
    usePreviewOfPublicInvoice();
  const publicInvoicePreview = getDataForPreviewByDeclaration(
    workflow.active_declaration,
  );
  const isLoading = loadingWorkflow || previewLoading;
  const [invoicePreview, toggleInvoicePreview] = useToggle();
  const total = +(publicInvoicePreview?.receipt?.total || 0);

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Genera tu factura al público en general"
          description="Hemos detectado que el monto de tus facturas de ingreso es menor a tus ingresos totales reportados. Debes realizar una factura al público en general para que tu declaración este correcta."
          videoId="iK0XWP8k9KU"
        />
      }
      actions={
        <>
          <PrevPhaseButton loading={isLoading} />
          <PrimaryPhaseButton loading={isLoading} />
        </>
      }
    >
      <Text x2l>
        Hay una <b>diferencia</b> entre el total de tus ingresos y tus facturas
        de ingresos.
        <br /> Hemos calculado tu factura al público en general en automático.
        😉
      </Text>
      <Text as="h3">
        El total que se facturará será de
        <b> {toCurrency(total)}</b>
      </Text>
      <Row>
        <Colxx md="12" lg="6">
          <PublicInvoiceTable />
        </Colxx>
      </Row>
      <div>
        <Button
          leftIcon={<Eye2Icon />}
          onClick={toggleInvoicePreview}
          variant="outlined"
          loading={isLoading}
        >
          Ver vista previa de la factura
        </Button>
      </div>
      {invoicePreview && (
        <PreviewPDFModal
          showPreview={invoicePreview}
          toggle={toggleInvoicePreview}
          cfdi={publicInvoicePreview}
          isWorkflowPublicInvoice
          title="Vista Previa"
        />
      )}
    </WorkflowLayout>
  );
}
