import * as yup from 'yup';
import isHexColor from '@util/isHexColor';
import { ImageListType } from 'react-images-uploading';

export const templatesOpts = [
  { label: 'Clásico', value: 'classic', key: 0 },
  { label: 'Moderno', value: 'modern', key: 1 },
];
export const validationSchema = yup.object().shape({
  hasLogoInsteadOfIcon: yup.boolean().required('Campo requerido'),
  template: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
      key: yup.number().required(),
    })
    .nullable()
    .required('Campo requerido'),
  brandColor: yup
    .string()
    .nullable()
    .required('Color requerido')
    .test('isValidHexColor', 'Color inválido', (color) =>
      isHexColor(color || ''),
    ),
  logo: yup.array<ImageListType>().nullable(),
  showLogoInInvoice: yup.boolean().required('Campo requerido'),
  incrementInternalFolio: yup.boolean().required('Campo requerido'),
  internalFolioPrefix: yup.string().nullable(),
  internalFolioNumberLength: yup.number().nullable(),
  uniqueInternalFolio: yup.boolean(),
  nextInternalFolio: yup.number().nullable(),
});

export type SettingsBrandFormPayload = yup.InferType<typeof validationSchema>;
