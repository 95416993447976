import { useQuery, UseQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';
import { TAXABLE_ENTITY_PREFERENCES } from '@constants/reactQueries';
import getTaxableEntityPreferences from '@api/getTaxableEntityPreferences';
import type { TaxableEntityPreference } from 'types/entities';
import type { IRootState } from 'types/redux';

export default function useTaxableEntityPreferences(
  taxableEntityId?: number,
  options?: UseQueryOptions<TaxableEntityPreference>,
) {
  const taxableEntity = useSelector(
    (state: IRootState) => state.taxableEntity.taxable_entity,
  );
  const id = taxableEntityId ?? taxableEntity?.id ?? 0;
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<TaxableEntityPreference>(
    TAXABLE_ENTITY_PREFERENCES,
    () => getTaxableEntityPreferences(id),
    { enabled: !!id, ...options },
  );

  const preferredRegimeSatKey = data?.preferred_fiscal_regime;

  return {
    loadingTaxableEntityPreferences: isLoading || isFetching,
    taxableEntityPreferences: data,
    preferredRegimeSatKey,
    ...rest,
  };
}
