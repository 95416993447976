import {
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_FAILURE,
  ADD_PAYMENT_METHOD_SUCCESS,
  DEFAULT_PAYMENT_METHODS,
  DEFAULT_PAYMENT_METHODS_FAILURE,
  DEFAULT_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS,
  FETCH_PAYMENT_METHODS_FAILURE,
  FETCH_PAYMENT_METHODS_SUCCESS,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_FAILURE,
  DELETE_PAYMENT_METHOD_SUCCESS,
  UI_PAYMENT_METHODS_TOOGLE,
  UI_PAYMENT_METHODS_SELECT_CARD,
  UI_PAYMENT_METHODS_DISPLAY_CARDS,
  CHARGE_PAYMENT_METHOD,
  CHARGE_PAYMENT_METHOD_SUCCESS,
  CHARGE_PAYMENT_METHOD_FAILURE,
} from '@constants/actionTypes';

// REST CALLS
export const fetchPaymentMethods = (_) => ({
  type: FETCH_PAYMENT_METHODS,
});
export const fetchPaymentMethodsSuccess = (payment_methods) => ({
  type: FETCH_PAYMENT_METHODS_SUCCESS,
  payload: payment_methods,
});
export const fetchPaymentMethodsFailure = () => ({
  type: FETCH_PAYMENT_METHODS_FAILURE,
});
export const addPaymentMethod = (card) => ({
  type: ADD_PAYMENT_METHOD,
  payload: { card },
});
export const addPaymentMethodSuccess = (payment_methods) => ({
  type: ADD_PAYMENT_METHOD_SUCCESS,
  payload: payment_methods,
});
export const addPaymentMethodFailure = () => ({
  type: ADD_PAYMENT_METHOD_FAILURE,
});
export const defaultPaymentMethod = (taxable_entity, card) => ({
  type: DEFAULT_PAYMENT_METHODS,
  payload: { taxable_entity, card },
});
export const defaultPaymentMethodSuccess = (payment_methods) => ({
  type: DEFAULT_PAYMENT_METHODS_SUCCESS,
  payload: payment_methods,
});
export const defaultPaymentMethodFailure = () => ({
  type: DEFAULT_PAYMENT_METHODS_FAILURE,
});

export const deletePaymentMethod = (taxable_entity, card) => ({
  type: DELETE_PAYMENT_METHOD,
  payload: { taxable_entity, card },
});
export const deletePaymentMethodSuccess = (payment_method) => ({
  type: DELETE_PAYMENT_METHOD_SUCCESS,
  payload: payment_method,
});
export const deletePaymentMethodFailure = () => ({
  type: DELETE_PAYMENT_METHOD_FAILURE,
});

// UI
export const paymentMethodsToogle = () => ({
  type: UI_PAYMENT_METHODS_TOOGLE,
});

export const paymentMethodsDisplayCards = () => ({
  type: UI_PAYMENT_METHODS_DISPLAY_CARDS,
});

export const paymentMethodsSelect = (selected_card) => ({
  type: UI_PAYMENT_METHODS_SELECT_CARD,
  payload: selected_card,
});

export const chargePaymentMethod = (amount, navigate, callback) => ({
  type: CHARGE_PAYMENT_METHOD,
  payload: {
    amount,
    navigate,
    callback,
  },
});
export const chargePaymentMethodSuccess = () => ({
  type: CHARGE_PAYMENT_METHOD_SUCCESS,
});
export const chargePaymentMethodFailure = () => ({
  type: CHARGE_PAYMENT_METHOD_FAILURE,
});
