import { QueryOptions, useQuery } from 'react-query';
import { USER_COMPLIANCE_OPINION } from '@constants/reactQueries';
import getUserComplianceOpinion from '@api/getUserComplianceOpinion';

export default function useUserComplianceOpinion(
  taxableId: number,
  queryOptions?: QueryOptions,
) {
  const {
    data = null,
    isLoading,
    ...rest
  } = useQuery(
    [USER_COMPLIANCE_OPINION],
    async () => {
      const userTaxStatus = await getUserComplianceOpinion(taxableId);
      return userTaxStatus;
    },
    {
      ...queryOptions,
    },
  );
  return {
    complianceOpinionPdfLoading: isLoading,
    complianceOpinionPdf: data,
    ...rest,
  };
}
