import React from 'react';
import { Button, Card } from 'reactstrap';
import { Link } from 'react-router-dom';

export function NewFactureCard() {
  return (
    <Card
      body
      className=" progress-banner d-flex justify-content-center align-items-center border-primary"
    >
      <Link
        to="//wa.me/5215570055271"
        target="_blank"
        className="d-md-inline-block"
      >
        <Button color="primary">Hablar con mi contador</Button>
      </Link>
    </Card>
  );
}
