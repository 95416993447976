import { useMutation, UseMutationOptions } from 'react-query';
import postInvoiceDraftNew from '@api/postInvoiceDraftNew';
import { Cfdi } from '../types/cfdi';

export default function useCreateInvoiceDraftNewMutation(
  queryOptions?: UseMutationOptions<string, unknown, Cfdi>,
) {
  return useMutation(
    (payload: Cfdi) => postInvoiceDraftNew(payload),
    queryOptions,
  );
}
