import { useState } from 'react';
import usePostDeclarationEntries from 'shared/hooks/usePostDeclarationEntries';
import { NotificationManager } from '@components/Notifications';
import getErrorMessage from '@util/getErrorMessage';
import type { Cfdi, Workflow } from 'types/entities';
import { useQueryClient } from 'react-query';
import { DECLARATION_ENTRIES_BY_WORKFLOW } from 'shared/constants/reactQueries';
import useWorkflow from '@components/Workflow/hooks/useWorkflow';

const notificationTitle = 'Agregar facturas a tu declaración';

const createPayload = (
  workflow: Workflow | null,
  declarationId: number,
  cfdi: Cfdi,
): FormData => {
  if (!workflow) throw new Error('Workflow is null');

  const payload = new FormData();
  payload.append(`declaration_entry[accounting_status]`, 'is_deductible');
  payload.append('declaration_entry[source_type]', 'Cfdi');
  payload.append('declaration_entry[declaration_id]', `${declarationId}`);
  payload.append(
    'declaration_entry[taxable_entity_id]',
    `${workflow.taxable_entity_id}`,
  );
  payload.append('declaration_entry[source_id]', `${cfdi.id}`);
  payload.append('declaration_entry[accounting_date]', workflow.start_date);
  return payload;
};

type SelectedRegime = {
  label: string;
  value: string;
  declarationId: number;
} | null;
interface UseClassifyEntryModalProps {
  onSuccessSubmit?: () => void;
  cfdis: Cfdi[];
  workflow: Workflow | null;
  toggleIsOpen: () => void;
  refetchCfdis?: () => void;
}
export default function useDeclarationEntryFromCfdiModalForm({
  cfdis,
  workflow,
  toggleIsOpen,
  refetchCfdis,
}: UseClassifyEntryModalProps) {
  const { taxableEntityPreferences, defaultRegimeOptionBySatkey } =
    useWorkflow();
  const postDeclarationEntries = usePostDeclarationEntries();
  const queryClient = useQueryClient();
  const [selectedRegime, setSelectedRegime] = useState(
    defaultRegimeOptionBySatkey,
  );
  const cfdisLen = cfdis.length;
  const titleText = `Agregar ${cfdis.length} ${
    cfdisLen > 1 ? 'facturas' : 'factura'
  } a tus ingresos`;

  const onSuccessSubmit = () => {
    toggleIsOpen();
    refetchCfdis?.();
  };

  const bulkCreateDeclarationEntries = async () => {
    try {
      if (!selectedRegime?.declarationId) {
        throw new Error('Declaration id is null');
      }
      await Promise.all(
        cfdis.map(async (cfdi) => {
          const payload = createPayload(
            workflow,
            selectedRegime.declarationId,
            cfdi,
          );
          await postDeclarationEntries.mutateAsync(payload);
        }),
      );
      onSuccessSubmit();
      void queryClient.invalidateQueries([DECLARATION_ENTRIES_BY_WORKFLOW]);
      NotificationManager.success(
        `${cfdisLen} factura${cfdisLen > 1 ? 's' : ''}  agregad${
          cfdisLen > 1 ? 'as' : 'a'
        }`,
        notificationTitle,
      );
    } catch (e) {
      const message = getErrorMessage(e) as string;
      NotificationManager.error(message, notificationTitle);
    }
  };

  const { isLoading } = postDeclarationEntries;

  const isDisabledFiscalRegimeDropdown =
    !!taxableEntityPreferences?.preferred_fiscal_regime;

  return {
    titleText,
    setSelectedRegime,
    selectedRegime,
    bulkCreateDeclarationEntries,
    isLoading,
    isDisabledFiscalRegimeDropdown,
  };
}
