import { type CSS, ScoreGauge } from '@konta/ui';

interface ScoreGraphProps {
  score: number;
  fontCss?: CSS;
  size?: number;
  gapAngle?: number;
  strokeWidth?: number;
}
export default function ScoreGraph({
  score,
  fontCss,
  size,
  gapAngle = 0.165,
  strokeWidth = 14,
}: ScoreGraphProps) {
  return (
    <ScoreGauge
      strokeWidth={strokeWidth}
      gapAngle={gapAngle}
      sidePadding={5}
      bottomPadding={26}
      fontCss={fontCss}
      score={score}
      segments={[6, 6, 6, 12, 24, 46]}
      colorArray={[
        '#F04438',
        '#EF6820',
        '#F79009',
        '#FDE272',
        '#A6EF67',
        '#32D583',
      ]}
      {...(size && { size })}
    />
  );
}
