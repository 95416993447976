import {
  CFDI_XML_FETCH,
  CFDI_XML_FETCH_SUCCESS,
  CFDI_XML_FETCH_FAILURE,
  CFDI_CANCEL_FAILURE,
  CFDI_CANCEL_SUCCESS,
  CFDI_CANCEL,
  CFDI_POST,
  CFDI_POST_FAILURE,
  CFDI_POST_SUCCESS,
  CFDIS_FETCH,
  CFDIS_FETCH_SUCCESS,
  CFDIS_FETCH_FAILURE,
  UI_SET_DATE_CFDIS,
  UI_CHANGE_DATE_CFDIS,
  CFDIS_FETCH_BY_CLIENT,
  CFDIS_FETCH_BY_CLIENT_SUCCESS,
  CFDIS_FETCH_BY_CLIENT_FAILURE,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  CFDIS_FETCH_BY_PPDS,
  CFDIS_FETCH_BY_PPDS_SUCCESS,
  CFDIS_FETCH_BY_PPDS_FAILURE,
  GET_COMPLEMENTS,
  GET_COMPLEMENTS_SUCCESS,
  GET_COMPLEMENTS_FAILURE,
  CFDI_PPD_COMPLEMENT_POST,
  CFDI_PPD_COMPLEMENT_POST_SUCCESS,
  CFDI_PPD_COMPLEMENT_POST_FAILURE,
  CFDI_PPD_COMPLEMENT_DELETE,
  CFDI_PPD_COMPLEMENT_DELETE_SUCCESS,
  CFDI_PPD_COMPLEMENT_DELETE_FAILURE,
} from '@constants/actionTypes';

// fetch from xml
export const fetchCFDIfromXML = (url) => ({
  type: CFDI_XML_FETCH,
  payload: { url },
});

export const fetchCFDIfromXMLSuccess = (cfdi) => ({
  type: CFDI_XML_FETCH_SUCCESS,
  payload: cfdi,
});
export const fetchCFDIfromXMLFailure = () => ({
  type: CFDI_XML_FETCH_FAILURE,
});

// CANCEL from xml
export const cancelCFDI = (cfdi_id, cancellation_reason, callback) => ({
  type: CFDI_CANCEL,
  payload: { cfdi_id, cancellation_reason, callback },
});

export const cancelCFDISuccess = (cfdi) => ({
  type: CFDI_CANCEL_SUCCESS,
  payload: cfdi,
});
export const cancelCFDILFailure = () => ({
  type: CFDI_CANCEL_FAILURE,
});

// Post invoice

export const postCFDI = (cfdi, navigate, ppdId, callback) => {
  return {
    type: CFDI_POST,
    payload: { cfdi, navigate, ppdId, callback },
  };
};
export const postCFDISuccess = (cfdi) => ({
  type: CFDI_POST_SUCCESS,
  payload: cfdi,
});
export const postCFDIFailure = () => ({
  type: CFDI_POST_FAILURE,
});

// fetch from xml
export const fetchCFDIS = (fiscal_period) => ({
  type: CFDIS_FETCH,
  payload: { fiscal_period },
});

export const fetchCFDISSuccess = (cfdis) => ({
  type: CFDIS_FETCH_SUCCESS,
  payload: cfdis,
});
export const fetchCFDISFailure = () => ({
  type: CFDIS_FETCH_FAILURE,
});

export const uiSetDateCFDIS = (date) => ({
  type: UI_SET_DATE_CFDIS,
  payload: date,
});
export const uiChangeDateCFDIS = (date) => ({
  type: UI_CHANGE_DATE_CFDIS,
  payload: { date },
});

export const fetchCFDISByClient = (client_id) => ({
  type: CFDIS_FETCH_BY_CLIENT,
  payload: { client_id },
});

export const fetchCFDISByClientSuccess = (cfdis) => ({
  type: CFDIS_FETCH_BY_CLIENT_SUCCESS,
  payload: cfdis,
});
export const fetchCFDISByClientFailure = () => ({
  type: CFDIS_FETCH_BY_CLIENT_FAILURE,
});

// fetch by ppds
export const fetchCfdisByPpds = (client_id) => ({
  type: CFDIS_FETCH_BY_PPDS,
  payload: { client_id },
});
export const fetchCfdisByPpdsSuccess = (client_id) => ({
  type: CFDIS_FETCH_BY_PPDS_SUCCESS,
  payload: { client_id },
});
export const fetchCfdisByPpdsFailure = (client_id) => ({
  type: CFDIS_FETCH_BY_PPDS_FAILURE,
  payload: { client_id },
});

export const getComplements = (cfdiId) => ({
  type: GET_COMPLEMENTS,
  payload: cfdiId,
});
export const getComplementsSuccess = (payload) => ({
  type: GET_COMPLEMENTS_SUCCESS,
  payload,
});
export const getComplementsFailure = (payload) => ({
  type: GET_COMPLEMENTS_FAILURE,
  payload,
});

export const addPpdComplement = (payload) => ({
  type: CFDI_PPD_COMPLEMENT_POST,
  payload,
});

export const addPpdComplementSuccess = (payload) => ({
  type: CFDI_PPD_COMPLEMENT_POST_SUCCESS,
  payload,
});

export const addPpdComplementFailure = (payload) => ({
  type: CFDI_PPD_COMPLEMENT_POST_FAILURE,
  payload,
});

export const deletePpdComplement = (entry_id) => ({
  type: CFDI_PPD_COMPLEMENT_DELETE,
  payload: { entry_id },
});

export const deletePpdComplementSuccess = (payload) => {
  return {
    type: CFDI_PPD_COMPLEMENT_DELETE_SUCCESS,
    payload,
  };
};

export const deletePpdComplementFailure = () => ({
  type: CFDI_PPD_COMPLEMENT_DELETE_FAILURE,
});

// Send invoice pdf

export const sendEmail = (payload) => {
  return {
    type: SEND_EMAIL,
    payload,
  };
};

export const sendEmailSuccess = () => {
  return {
    type: SEND_EMAIL_SUCCESS,
  };
};

export const sendEmailFailure = () => {
  return {
    type: SEND_EMAIL_FAILURE,
  };
};
