import { call, put } from 'redux-saga/effects';
import { ErrorLayout, ErrorHandler } from '@util/Saga';
import api from '@api';
import { deletePpdComplementSuccess } from '@redux/cfdi/actions';
import {
  declarationEntriesDeleteSuccess,
  declarationEntriesDeleteFailure,
} from '../actions';

export default function* ({ payload: { declaration_entries_id, ppd_id } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.delete,
      `declaration_entries/${declaration_entries_id}`,
      { headers: { Authorization: `${access_token}` } }
    );
    response.data = declaration_entries_id;
    if (ppd_id) {
      yield put(deletePpdComplementSuccess({ ppd_id, declaration_entries_id }));
    }
    yield call(ErrorLayout, {
      response,
      status: response.status,
      success: declarationEntriesDeleteSuccess,
      failure: declarationEntriesDeleteFailure,
      title: 'Éxito',
      message: 'Se ha borrado correctamente',
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: declarationEntriesDeleteFailure,
    });
  }
}
