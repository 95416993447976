import dayjs from 'dayjs';
import { getMonthName } from '@util/Utils';

const PENDING_PAYMENT_DANGER = 12;
const PENDING_PAYMENT_LIMIT = 17;
const STATEMENT_UPLOAD_DANGER = 10;
const STATEMENT_UPLOAD_LIMIT = 15;

const STATEMENT_UPLOAD = 'STATEMENT_UPLOAD';
const ACCOUNTING = 'ACCOUNTING';
const PENDING_PAYMENT = 'PENDING_PAYMENT';
const PAID = 'PAID';
const CHECK_PAYMENT = 'CHECK_PAYMENT';

const STATEMENT_UPLOAD_DATA = (mm, days) => ({
  message: `Para subir tus ingresos de ${mm}`,
  days: `${days} días`,
  icon: 'File-Upload',
  color: 'primary',
});
const STATEMENT_UPLOAD_DATA_DANGER = (mm, days) => ({
  message: `Sube tu estado de cuenta urgentemente de ${mm}`,
  days: `${days} días`,
  icon: 'File-Upload',
  color: 'danger',
});
const ACCOUNTING_DATA = (mm, days) => ({
  message: `Calculando tus impuestos de ${mm}`,
  days: 'Espera unos dias mientras hacemos el trabajo por tí.',
  icon: 'Timer-2',
  color: 'primary',
});
const PENDING_PAYMENT_DATA = (mm, days) => ({
  message: `Para pagar tus impuestos de ${mm}`,
  days: `${days} días`,
  icon: 'Dollar',
  color: 'primary',
});

const PENDING_PAYMENT_DATA_DANGER = (mm, days) => ({
  message: `Para pagar tus impuestos de ${mm}`,
  days: `${days} días`,
  icon: 'Dollar',
  color: 'danger',
});

const PAID_DATA = (mm, days) => ({
  message: `Tu contabilidad del mes de ${mm}, está completa.`,
  days: `${days} días`,
  icon: 'Yes',
  color: 'success',
});

const DEFAULT_DATA = () => ({
  message: 'Bienvenido a Konta',
  days: '',
  icon: 'File-Upload',
  color: 'primary',
});

export const getStatusDay = (fp) => {
  const now = dayjs(); // todays date
  if (fp && fp.status) {
    let end = null;
    let days = '';
    let data = '';

    const { status } = fp;
    const mm = getMonthName(new Date(fp.start_date));

    switch (status.toUpperCase()) {
      case STATEMENT_UPLOAD:
        end = dayjs().date(STATEMENT_UPLOAD_LIMIT);
        days = Math.floor(dayjs.duration(end.diff(now)).asDays());
        data =
          now.date() < STATEMENT_UPLOAD_DANGER
            ? STATEMENT_UPLOAD_DATA(mm, days)
            : STATEMENT_UPLOAD_DATA_DANGER(mm, days);
        break;
      case ACCOUNTING:
        data = ACCOUNTING_DATA(mm, days);
        break;
      case CHECK_PAYMENT:
        end = dayjs().endOf('month');
        days = Math.floor(dayjs.duration(end.diff(now)).asDays());
        data = PAID_DATA(mm, days);
        break;
      case PENDING_PAYMENT:
        end = dayjs().date(PENDING_PAYMENT_LIMIT);
        days = Math.floor(dayjs.duration(end.diff(now)).asDays());
        data =
          now.date() < PENDING_PAYMENT_DANGER
            ? PENDING_PAYMENT_DATA(mm, days)
            : PENDING_PAYMENT_DATA_DANGER(mm, days);
        break;
      case PAID:
        end = dayjs().endOf('month');
        days = Math.floor(dayjs.duration(end.diff(now)).asDays());
        data = PAID_DATA(mm, days);
        break;
      default:
    }
    return data;
  }
  return DEFAULT_DATA();
};
