export const cellCss = {
  fontWeight: 400,
  color: '$gray500',
};

export const cellUpperCss = {
  fontWeight: 400,
  color: '$gray500',
  textTransform: 'uppercase',
};

export const cellBoldCss = {
  fontWeight: 500,
  color: '$gray900',
  textTransform: 'uppercase',
};

export const footerCellAmountCss = {
  ...cellCss,
  color: '$primary700',
  fontWeight: 500,
  textAlign: 'right',
};
export const cellAmountCss = {
  ...cellCss,
  textAlign: 'right',
};

export const headerCss = {
  color: '$gray500',
  fontWeight: 500,
  textTransform: 'uppercase',
};

export const totalCss = {
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '28px',
};
