import React from 'react';
import { Divider, Flex, useMediaQuery } from '@konta/ui';
import PropTypes from 'prop-types';
import ReferralsHeader from './ReferralsHeader';
import ReferralTotals from './ReferralTotals';
import SuggestedReferrals from './SuggestedReferrals';
import InvitedReferrals from './InvitedReferrals';

export default function Referrals({
  withBackground,
  withSuggested,
  withInvitations,
}) {
  const isDesktop = useMediaQuery('(min-width: 768px)');

  return (
    <Flex direction="column" className="app-container bg-white" gap="24">
      <ReferralsHeader withBackground={withBackground} />
      <Divider />
      <ReferralTotals />
      <Divider />
      <Flex
        gap="24"
        css={{ flexDirection: 'column', '@sm': { flexDirection: 'row' } }}
      >
        {withSuggested && (
          <>
            <SuggestedReferrals />
            <Divider vertical={isDesktop} />
          </>
        )}
        {withInvitations && <InvitedReferrals />}
      </Flex>
      {/* TODO: define the requirements for ReferralRewards */}
      {/* <Divider />
      <ReferralsRewards /> */}
    </Flex>
  );
}

Referrals.propTypes = {
  withBackground: PropTypes.bool,
  withSuggested: PropTypes.bool,
  withInvitations: PropTypes.bool,
};

Referrals.defaultProps = {
  withBackground: false,
  withSuggested: false,
  withInvitations: false,
};
