import api from '@api';
import { select, call, put } from 'redux-saga/effects';
import { NotificationManager } from '@components/Notifications';
import ConektaHelper from '@util/ConektaHelper';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import gtmEvent from '@util/gtmEvent';
import * as Sentry from '@sentry/react';
import { addPaymentMethodSuccess, addPaymentMethodFailure } from '../actions';

// TODO: export default for all sagas
// eslint-disable-next-line import/prefer-default-export
export function* addPaymentMethodWithAccessToken({ payload: { card } }) {
  try {
    yield call(ConektaHelper.initConekta, 'subscription');
    const subscriptionToken = yield call(
      ConektaHelper.tokenize,
      card.number,
      card.name,
      card.expiryMonth,
      card.expiryYear,
      card.cvc,
      handleSuccessCallback,
      handleErrorCallback,
    );
    yield call(ConektaHelper.initConekta, 'fiscal_period');
    const fiscalPeriodToken = yield call(
      ConektaHelper.tokenize,
      card.number,
      card.name,
      card.expiryMonth,
      card.expiryYear,
      card.cvc,
      handleSuccessCallback,
      handleErrorCallback,
    );

    const { taxable_entity } = yield select((state) => state.taxableEntity);
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.post,
      `/taxable_entities/${taxable_entity.id}/payment_methods`,
      {
        payment_method: {
          source_type: 'card',
          token_taxes_id: fiscalPeriodToken,
          token_subscription_id: subscriptionToken,
        },
      },
      { headers: { Authorization: `${access_token}` } },
    );

    yield call(StatusHandler, {
      response,
      title: 'Tarjeta añadida',
      message: 'La tarjeta fue agregada correctamente.',
      status: response.status,
      success: addPaymentMethodSuccess,
      failure: addPaymentMethodFailure,
    });
    gtmEvent('AppEvent', 'newpaymentmethodSuccess');
  } catch (error) {
    // Log for DEV-3458 issue: show on OpenReplay
    console.log(error);
    if (Sentry?.captureException) {
      Sentry.captureException(error);
    }
    if (error?.statusText?.message_to_purchaser) {
      NotificationManager.error(
        `${error.statusText.message_to_purchaser}`,
        'Upss..',
        6000,
        null,
        null,
      );
      yield put(addPaymentMethodFailure());
      return;
    }
    if (error.message_to_purchaser) {
      NotificationManager.error(
        `${error.message_to_purchaser}`,
        'Upss..',
        6000,
        null,
        null,
      );
      yield put(addPaymentMethodFailure());
    } else {
      yield call(ErrorHandler, {
        error,
        action: addPaymentMethodFailure,
      });
    }
  }
}

function handleSuccessCallback(response) {}

function handleErrorCallback(response) {
  if (response?.message) {
    console.error(`Conekta error: ${response.message}`);
  }

  if (Sentry?.captureException) {
    Sentry.captureException(response);
  }
}
