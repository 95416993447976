import React, { useState } from 'react';
import { Card, CardBody, Row, Collapse, Button } from 'reactstrap';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import usePendingTasksStore from 'store/pendingTasksStore';
import {
  Colxx,
  InvoiceCheckoutTotal,
  CustomSelect,
  OrderServicesSubscriptionDetails,
  OrderServicesTADetails,
  Separator,
  Loader,
} from '@components';
import { PaymentCards } from '@containers';
import { payOrder, chargePaymentMethod } from '@redux/actions';
import PaymentMethodItem from '@components/Payment/payment-methods/payment-methods-item';
import ReferenceCard from '@components/Payment/payment-methods/reference-payment-card';
import { usePaymentMethods } from '@hooks';

export default function Checkout() {
  const location = useLocation();
  const { selectedTask } = usePendingTasksStore();
  const { removePendingTask } = usePendingTasksStore();
  const [showCollapse, setShowCollapse] = useState(false);
  const [showCollapseTA, setShowCollapseTA] = useState(false);
  const [showCollapsePaymentMehod, setShowCollapsePaymentMehod] =
    useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const {
    ui: { selected_card },
    payment_methods,
    loading,
  } = usePaymentMethods();
  const { orders_to_pay } = useSelector(
    (state) => state.checkout,
    shallowEqual,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (orders_to_pay.length > 0) {
    const locationState = location.state;
    const cards = payment_methods.filter(
      (payment_method) => payment_method.source_type === 'card',
    );
    const spei = payment_methods.find(
      (payment_method) => payment_method.source_type === 'spei_recurrent',
    );
    const oxxo = payment_methods.find(
      (payment_method) => payment_method.source_type === 'oxxo_recurrent',
    );

    const handlePaymentMethodChange = (selectedPaymentMethod) => {
      setSelectedPaymentMethod(selectedPaymentMethod);
    };
    const handleOrderPayment = (amount) => {
      if (locationState?.order_id) {
        dispatch(
          payOrder(locationState.order_id, navigate, () =>
            removePendingTask(selectedTask),
          ),
        );
      } else {
        dispatch(
          chargePaymentMethod(amount, navigate, () =>
            removePendingTask(selectedTask),
          ),
        );
      }
    };

    const paymentMethodOptions = [
      { label: 'Tarjet de credito/debito', value: 'card', key: 1 },
    ];
    if (spei) paymentMethodOptions.push({ label: 'SPEI', value: spei, key: 2 });
    if (oxxo)
      paymentMethodOptions.push({ label: 'Oxxo Pay', value: oxxo, key: 3 });

    const subscription_services = [];
    const tax_arrear_services = [];
    orders_to_pay.forEach((order) => {
      if (order.source_type === 'Subscription') {
        order.subscriptions.forEach((subscription) => {
          subscription.service_subscriptions.forEach((service) => {
            subscription_services.push(service);
          });
        });
      } else {
        order.tax_arrears.forEach((tax_arrear) => {
          tax_arrear_services.push(tax_arrear);
        });
      }
    });
    return (
      <div className="app-container">
        <h1>
          Pago de orden <i className="iconsmind-Shopping-Cart" />
        </h1>
        <Separator className="mb-3" />
        <Row>
          <Colxx xxs="12" md="7">
            {subscription_services.length > 0 && (
              <Row className="mb-4">
                <Colxx xxs="12">
                  <Card>
                    <CardBody>
                      <h4>Suscripciones</h4>
                      <Button
                        color="light"
                        className="accordion-title bg-transparent"
                        onClick={() => setShowCollapse(!showCollapse)}
                      >
                        {`${subscription_services.length} servicios seleccionados.`}
                        <i
                          className={`${
                            showCollapse
                              ? 'simple-icon-arrow-up'
                              : 'simple-icon-arrow-down'
                          } align-middle ml-1`}
                        />
                      </Button>
                      <Collapse isOpen={showCollapse}>
                        <OrderServicesSubscriptionDetails
                          services={subscription_services}
                        />
                      </Collapse>
                    </CardBody>
                  </Card>
                </Colxx>
              </Row>
            )}
            {tax_arrear_services.length > 0 && (
              <Row className="mb-4">
                <Colxx xxs="12">
                  <Card>
                    <CardBody>
                      <h4>Cobro de actualizaciones</h4>
                      <Button
                        color="light"
                        className="accordion-title bg-transparent"
                        onClick={() => setShowCollapseTA(!showCollapseTA)}
                      >
                        {`${tax_arrear_services.length} servicios seleccionados.`}
                        <i
                          className={`${
                            showCollapseTA
                              ? 'simple-icon-arrow-up'
                              : 'simple-icon-arrow-down'
                          } align-middle ml-1`}
                        />
                      </Button>
                      <Collapse isOpen={showCollapseTA}>
                        <OrderServicesTADetails
                          services={tax_arrear_services}
                        />
                      </Collapse>
                    </CardBody>
                  </Card>
                </Colxx>
              </Row>
            )}
            <Loader loading={loading}>
              <Row>
                <Colxx xxs="12">
                  <Card>
                    <CardBody>
                      <h4>Método de pago</h4>
                      {payment_methods.length > 0 ? (
                        <>
                          {cards ? (
                            <PaymentMethodItem
                              item={
                                selectedPaymentMethod.value &&
                                selectedPaymentMethod.key !== 1
                                  ? selectedPaymentMethod.value
                                  : selected_card
                              }
                              checked
                              name="payment-card"
                              index="payments-"
                            />
                          ) : (
                            <PaymentMethodItem
                              item={spei || oxxo}
                              name="payment-card"
                              index="payments-"
                            />
                          )}

                          <Row>
                            <Colxx xxs="12">
                              <Button
                                color="link"
                                className="btn-right"
                                onClick={() =>
                                  setShowCollapsePaymentMehod(
                                    !showCollapsePaymentMehod,
                                  )
                                }
                              >
                                Cambiar método de pago
                              </Button>
                            </Colxx>
                          </Row>

                          <Collapse isOpen={showCollapsePaymentMehod}>
                            <Row>
                              {/* <PaymentMethodTabs/> */}
                              <Colxx xxs="12" className="mb-2">
                                <CustomSelect
                                  onChange={handlePaymentMethodChange}
                                  options={paymentMethodOptions}
                                  value={selectedPaymentMethod}
                                />
                              </Colxx>
                              {selectedPaymentMethod.key === 1 && (
                                <Colxx xxs="12">
                                  <PaymentCards filter />
                                </Colxx>
                              )}
                            </Row>
                          </Collapse>
                        </>
                      ) : (
                        <PaymentCards />
                      )}
                    </CardBody>
                  </Card>
                </Colxx>
              </Row>
            </Loader>
          </Colxx>
          <Colxx xxs="12" md="5">
            <Row>
              <InvoiceCheckoutTotal
                orders={orders_to_pay}
                showPayButton={
                  selected_card?.source_type === 'card' &&
                  ((selectedPaymentMethod && selectedPaymentMethod.key === 1) ||
                    Object.keys(selectedPaymentMethod).length < 1)
                }
                handlePay={handleOrderPayment}
                loadingPaymentMethods={loading}
              />
            </Row>
            {(selectedPaymentMethod.key === 2 ||
              selectedPaymentMethod.key === 3 ||
              selected_card?.source_type !== 'card') && (
              <Row>
                <ReferenceCard
                  item={selectedPaymentMethod.value || selected_card}
                />
              </Row>
            )}
          </Colxx>
        </Row>
      </div>
    );
  }
  return (
    <Navigate
      to={{
        pathname: `/app/settings/plan`,
      }}
    />
  );
}
