import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import { Text } from '@konta/ui';
import styles from './Calendly.module.scss';

export default function Calendly({ isOpen, toggle, size }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={size} className={styles.modal}>
      <ModalHeader toggle={toggle}>
        <Text css={{ mb: '$20' }} as="h2">
          Agenda una asesoría
        </Text>
      </ModalHeader>
      <iframe
        className="w-100 d-block"
        style={{ minHeight: '600px' }}
        src="https://calendly.com/somoskonta/intro"
        title="Soporte"
      />
    </Modal>
  );
}

Calendly.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  size: PropTypes.string,
};

Calendly.defaultProps = {
  size: 'lg',
};
