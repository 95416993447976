import React, { useMemo, useCallback, ReactNode } from 'react';
import {
  ErrorCode,
  useDropzone,
  FileWithPath,
  FileError,
} from 'react-dropzone';
import { Text, Flex, Card, Button, CardContent, Icon, CSS } from '@konta/ui';
import {
  File2OutlineIcon,
  TrashCanOutlineIcon,
  UploadCloud02LineIcon,
} from '@konta/icons';
import formatBytes from 'shared/util/formatBytes';
import PreviewPdfBtnModal from 'shared/components/PreviewPdfBtnModal';

interface RenderProps {
  removeFile: (file: FileWithPath) => void;
  loading?: boolean;
}

interface RejectedFileProps {
  file?: FileWithPath;
  errors?: Array<FileError>;
}

// React.Dispatch<React.SetStateAction<NewFilter[]>>
interface DropzoneProps {
  fileState?: Array<FileWithPath>;
  setFileState?: (files: FileWithPath[]) => void;
  maxFiles?: number;
  filesTypes?: Array<string>;
  fileTypesText?: string;
  name?: string;
  children?: (renderProps: RenderProps) => ReactNode;
  onDropZoneChange?: (files: FileWithPath[]) => void;
  customText?: ReactNode;
  cardCss?: CSS;
}

export default function Dropzone({
  setFileState,
  fileState,
  maxFiles,
  filesTypes,
  fileTypesText,
  name,
  children,
  onDropZoneChange,
  customText,
  cardCss,
}: DropzoneProps) {
  const onDrop = useCallback(
    (acceptedFiles: Array<FileWithPath>) => {
      setFileState?.([...(fileState || []), ...acceptedFiles]);
      onDropZoneChange?.([...(fileState || []), ...acceptedFiles]);
    },
    [fileState, setFileState, onDropZoneChange],
  );

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles || 1,
    accept: {
      'application/octet-stream': filesTypes || ['.xml', '.pdf'],
    },
    onDrop,
  });

  const removeFile = (file: FileWithPath) => () => {
    const newFiles = [...(fileState || [])];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFileState?.(newFiles);
  };

  const files = fileState?.map((file: FileWithPath) => (
    <Card
      key={file.path}
      outlined
      css={{
        padding: '$16',
        alignItems: 'flex-start',
        gap: '$4',
        alignSelf: 'stretch',
      }}
    >
      <CardContent
        css={{
          flexDirection: 'row',
          padding: '0',
          gap: '$16',
          position: 'relative',
        }}
      >
        <Icon
          waves
          css={{
            width: '30px',
            height: '30px',
            flexShrink: 0,
            border: 'solid var(--space) $primary50',
            backgroundColor: '$primary100',
            svg: {
              width: '16px',
              height: '16px',
              path: {
                fill: '$primary600',
              },
            },
          }}
        >
          <File2OutlineIcon />
        </Icon>
        <Flex
          column
          css={{
            flex: '1',
          }}
        >
          <Text size="s" color="gray700">
            {file.path || file.name}
          </Text>
          <Text size="s" color="gray500">
            {formatBytes(file.size)}
          </Text>
          {/* Show loader state down here */}
        </Flex>
        <Flex gap={2} itemsCenter>
          <Button
            size="xs"
            variant="minimal"
            onClick={() => removeFile(file)()}
            icon
          >
            <TrashCanOutlineIcon />
          </Button>
          <PreviewPdfBtnModal
            buttonProps={{
              variant: 'minimal',
            }}
            onlyIcon
            title="Vista previa"
            file={file}
          />
        </Flex>
      </CardContent>
    </Card>
  ));

  const fileErrors = useMemo(
    () =>
      fileRejections.map(({ file, errors }: RejectedFileProps) => (
        <Flex direction="column" key={file?.path}>
          <Text key={file?.path} xs>
            {file?.path}
          </Text>
          {errors?.map((e) => (
            <Text key={e.code} color="error500" bold>
              {
                {
                  [ErrorCode.FileInvalidType]: 'Archivo inválido',
                  [ErrorCode.FileTooLarge]: 'Tamaño del archivo muy largo',
                  [ErrorCode.FileTooSmall]: 'Tamaño del archivo muy pequeño',
                  [ErrorCode.TooManyFiles]: 'Excedió al límite de archivos',
                }[e.code]
              }
            </Text>
          ))}
        </Flex>
      )),
    [fileRejections],
  );

  return (
    <Flex column gap={16}>
      {maxFiles !== fileState?.length && (
        <Flex
          css={{
            padding: '16px 24px',
            alignItems: 'center',
            flexDirection: 'column',
            alignSelf: 'stretch',
            gap: '$4',
            border: '1px solid $gray200',
            borderRadius: '8px',
            '&:hover': {
              cursor: 'pointer',
            },
            ...cardCss,
          }}
        >
          <Flex
            {...getRootProps()}
            css={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '$12',
              width: '100%',
              height: '100%',
            }}
          >
            <input name={name} {...getInputProps()} />
            <Icon
              waves
              css={{
                width: '40px',
                height: '40px',
                svg: {
                  width: '40px',
                  height: '40px',
                  path: {
                    fill: 'none',
                    stroke: '$gray600',
                  },
                },
              }}
            >
              <UploadCloud02LineIcon />
            </Icon>
            <Flex
              column
              justify="center"
              align="center"
              css={{
                width: '100%',
              }}
            >
              <Text
                css={{
                  lineHeight: '20px',
                }}
                color="gray500"
              >
                {customText || (
                  <>
                    <Text color="primary700" css={{ fontWeight: '500' }}>
                      Click para subir
                    </Text>{' '}
                    or arrastra y suelta el archivo
                  </>
                )}
              </Text>
              <Text
                css={{
                  lineHeight: '20px',
                }}
                color="gray500"
              >
                {fileTypesText}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
      {fileErrors}
      {children
        ? children({
            removeFile: (file) => {
              removeFile(file)();
            },
          })
        : files}
    </Flex>
  );
}
