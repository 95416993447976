import {
  CLIENTS_SUPPLIERS_FETCH,
  CLIENTS_SUPPLIERS_FETCH_SUCCESS,
  CLIENTS_SUPPLIERS_FETCH_FAILURE,
  CLIENTS_SUPPLIERS_POST,
  CLIENTS_SUPPLIERS_POST_SUCCESS,
  CLIENTS_SUPPLIERS_POST_FAILURE,
  CLIENTS_SUPPLIERS_EDIT,
  CLIENTS_SUPPLIERS_EDIT_SUCCESS,
  CLIENTS_SUPPLIERS_EDIT_FAILURE,
  CLIENTS_SUPPLIERS_DELETE,
  CLIENTS_SUPPLIERS_DELETE_SUCCESS,
  CLIENTS_SUPPLIERS_DELETE_FAILURE,
} from '@constants/actionTypes';

export const fetchClientsSuppliers = (taxable_entity) => ({
  type: CLIENTS_SUPPLIERS_FETCH,
  payload: { taxable_entity },
});
export const fetchClientsSuppliersSuccess = (clients_suppliers) => ({
  type: CLIENTS_SUPPLIERS_FETCH_SUCCESS,
  payload: clients_suppliers,
});
export const fetchClientsSuppliersFailure = (_) => ({
  type: CLIENTS_SUPPLIERS_FETCH_FAILURE,
});
export const postClientsSuppliers = (taxable_entity, client_supplier) => ({
  type: CLIENTS_SUPPLIERS_POST,
  payload: { taxable_entity, client_supplier },
});
export const postClientsSuppliersSuccess = (clients_suppliers) => ({
  type: CLIENTS_SUPPLIERS_POST_SUCCESS,
  payload: clients_suppliers,
});
export const postClientsSuppliersFailure = (_) => ({
  type: CLIENTS_SUPPLIERS_POST_FAILURE,
});
export const editClientsSuppliers = (
  taxable_entity,
  client_supplier,
  client_id
) => ({
  type: CLIENTS_SUPPLIERS_EDIT,
  payload: { taxable_entity, client_supplier, client_id },
});
export const editClientsSuppliersSuccess = (client_supplier) => ({
  type: CLIENTS_SUPPLIERS_EDIT_SUCCESS,
  payload: client_supplier,
});
export const editClientsSuppliersFailure = (_) => ({
  type: CLIENTS_SUPPLIERS_EDIT_FAILURE,
});

export const deleteClientsSuppliers = (taxable_entity, client_supplier_id) => ({
  type: CLIENTS_SUPPLIERS_DELETE,
  payload: { taxable_entity, client_supplier_id },
});
export const deleteClientsSuppliersSuccess = (client_supplier_id) => ({
  type: CLIENTS_SUPPLIERS_DELETE_SUCCESS,
  payload: client_supplier_id,
});
export const deleteClientsSuppliersFailure = (_) => ({
  type: CLIENTS_SUPPLIERS_DELETE_FAILURE,
});
