import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import WorkflowLayout from '@components/WorkflowLayout';
import WorkflowHeader from '@components/WorkflowHeader';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import BankList from '@components/BelvoBanks/BankList';

const payload = {
  workflow: {
    next_phase: 'primary',
  },
};

function BelvoConnection() {
  const { belvo_links } = useSelector(({ belvo }) => belvo);

  const hasPendingAccounts = useMemo(
    () =>
      belvo_links?.some(
        (link) =>
          link.aasm_state === 'pending' || link.aasm_state === 'sync_accounts',
      ),
    [belvo_links],
  );
  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Conecta tus cuentas de banco"
          description="Puedes vincular tus bancos con Konta presionando el siguiente botón, de esta forma se abrirá una ventana donde debe seguir los pasos para que la conexión sea exitosa."
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton payload={payload} disabled={hasPendingAccounts} />
        </>
      }
    >
      <BankList isWorkflow />
    </WorkflowLayout>
  );
}

export default BelvoConnection;
