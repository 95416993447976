import { Flex, Button } from '@konta/ui';
import { ArrowRightLineIcon } from '@konta/icons';
import { ConfirmModal } from '@components/Modal';
import FullModal from '@components/FullModal';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import useSearchParamsState from '@hooks/useSearchParamsState';
import ClassifyExpenses from './ClassifyExpenses';
import ExpenseUploadWay from './ExpenseUploadWay';
import { Feature, Header, Root, Title } from '../styled';
import TotalsButtons from './TotalsButtons';

interface EditExpenseProps {
  showEditExpense: boolean;
  toggleShowEditExpense: () => void;
}
export default function EditExpenses({
  showEditExpense,
  toggleShowEditExpense,
}: EditExpenseProps) {
  const [action, setAction] = useSearchParamsState('action', 'new');
  const openUploadExpense = action === 'new';
  const toggleOpenUploadExpense = () =>
    setAction(openUploadExpense ? 'edit' : 'new');

  return (
    <FullModal
      open={showEditExpense}
      toggleClose={toggleShowEditExpense}
      id="editDeclarationExpense"
      title="Declaración mensual"
    >
      <WorkflowLayout
        actions={
          <>
            <PrevPhaseButton onClick={toggleShowEditExpense} />
            <TotalsButtons />
            <PrimaryPhaseButton
              onClick={toggleShowEditExpense}
              rightIcon={<ArrowRightLineIcon />}
            >
              Guardar y regresar
            </PrimaryPhaseButton>
          </>
        }
      >
        <Root>
          <Header>
            <Title>
              Verifica que todos los gastos están correctamente clasificados
            </Title>
            <Feature>
              Uno de nuestros contadores expertos ya revisó las facturas. Sin
              embargo, es importante que se revise una por una para verificar
              que todo esté correcto.
            </Feature>
          </Header>
          <Flex>
            <Button color="primary600" onClick={toggleOpenUploadExpense}>
              Agregar nuevos gastos
            </Button>
          </Flex>
          <Flex />
          <ClassifyExpenses />
        </Root>
      </WorkflowLayout>
      {openUploadExpense && (
        <ConfirmModal
          open={openUploadExpense}
          title="Agregar gastos"
          onClose={toggleOpenUploadExpense}
          size="xl"
          withBlur
          centered
          withFooter={false}
        >
          <ExpenseUploadWay toggleOpenUploadExpense={toggleOpenUploadExpense} />
        </ConfirmModal>
      )}
    </FullModal>
  );
}
