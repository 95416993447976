import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  USER_FETCH,
  USER_UPDATE,
  CHANGE_PASSWORD,
  ONBOARDING_USER,
  LOGIN_WITH_GOOGLE_USER,
} from '@constants/actionTypes';
import loginUserSaga from './login';
import registerUserSaga from './register';
import logoutUserSaga from './logout';
import fetchUserSaga from './fetch';
import updateUserSaga from './update';
import changePasswordSaga from './new_password';
import onboardingUserSaga from './onboarding';
import loginWithGoogle from './loginWithGoogle';

export function* watchLogin() {
  yield takeLatest(LOGIN_USER, loginUserSaga);
}
export function* watchRegister() {
  yield takeLatest(REGISTER_USER, registerUserSaga);
}
export function* watchLogout() {
  yield takeLatest(LOGOUT_USER, logoutUserSaga);
}
export function* watchFetch() {
  yield takeLatest(USER_FETCH, fetchUserSaga);
}
export function* watchUpdate() {
  yield takeLatest(USER_UPDATE, updateUserSaga);
}
export function* watchChangePassword() {
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
}

export function* watchOnboarding() {
  yield takeLatest(ONBOARDING_USER, onboardingUserSaga);
}
export function* watchLoginWithGoogle() {
  yield takeLatest(LOGIN_WITH_GOOGLE_USER, loginWithGoogle);
}

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchRegister),
    fork(watchLogout),
    fork(watchFetch),
    fork(watchUpdate),
    fork(watchChangePassword),
    fork(watchOnboarding),
    fork(watchLoginWithGoogle),
  ]);
}
