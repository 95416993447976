import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import type { IRootState } from 'types/redux';
import { showWorkflowFetch } from '@redux/actions';

// eslint-disable-next-line import/prefer-default-export
export const useWorkflows = () => {
  const dispatch = useDispatch();
  const { loading, workflows, selected_workflow, ui } = useSelector(
    (state: IRootState) => state.workflows,
    shallowEqual,
  );

  const handleShowWorkflow = (id: number) => {
    dispatch(showWorkflowFetch(id));
  };
  return { loading, handleShowWorkflow, workflows, selected_workflow, ui };
};
