import { styled } from '@konta/ui';

export const Alert = styled('div', {
  display: 'flex',
  padding: '$16',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: 12,
  borderRadius: '8px',
  border: '1px solid $primary300',
  background: '$primary25',
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '20px',
  svg: {
    color: '$primary700',
    width: '$20',
    height: '$20',
  },
  variants: {
    mt2: {
      true: {
        mt: '$16',
      },
    },
  },
});

export const AlertContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch',
});

export const AlertText = styled('span', {
  color: '$primary700',
  fontWeight: 500,
});

export const AlertDescription = styled('span', {
  color: '$primary600',
  fontWeight: 400,
});
