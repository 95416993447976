import Loader from '@components/Loader';
import { Row } from 'reactstrap';
import { Colxx } from '@components';
import toCurrency from '@util/toCurrency';
import useHasPayrollRetention from '@hooks/useHasPayrollRetention';

interface ValueProps {
  label: string;
  value: number;
  boldValue?: boolean;
}
function Value({ label, value, boldValue }: ValueProps) {
  return (
    <Row>
      <Colxx
        xxs={{ size: 8, offset: 0 }}
        style={{ fontSize: '1rem' }}
        className="font-weight-bold"
      >
        <div>{label}</div>
      </Colxx>
      <Colxx
        className={boldValue ? 'font-weight-bold' : ''}
        xxs={{ size: 3, offset: 1 }}
      >
        {toCurrency(value)}
      </Colxx>
    </Row>
  );
}
interface SalariesRetentionComputeResultProps {
  taxesWithheldOfPayroll: number;
  employmentSubsidyOfPayroll: number;
  totalPayrollWithholdingsToPay: number;
}
export default function SalariesRetentionComputeResult({
  taxesWithheldOfPayroll,
  employmentSubsidyOfPayroll,
  totalPayrollWithholdingsToPay,
}: SalariesRetentionComputeResultProps) {
  const { loadingPayrollRetention, hasPayrollRetentions } =
    useHasPayrollRetention();

  if (loadingPayrollRetention) {
    return <Loader />;
  }
  if (hasPayrollRetentions) {
    return (
      <>
        <hr />
        <Value label="Retenciones de nómina" value={taxesWithheldOfPayroll} />
        <Value label="Subsidios de empleo" value={employmentSubsidyOfPayroll} />
        <Value
          label="Total a pagar de Salarios"
          value={totalPayrollWithholdingsToPay}
          boldValue
        />
      </>
    );
  }
  return null;
}
