import {
  DefaultCellTypes,
  CellStyle,
  NumberCell,
  TextCell,
  ChevronCell,
  Id,
} from '@silevis/reactgrid';
import { isSelectedMonth } from '../../shared/util/grids';

export const emptyTextCell: TextCell = {
  type: 'text',
  text: '',
};

const numberFormat = new Intl.NumberFormat('es-MX', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  currency: 'MXN',
  style: 'currency',
});

export const textCell = (
  text: string,
  className = '',
  style?: CellStyle,
): TextCell => ({ type: 'text', text, className, style });

export const numberCell = (
  value: number,
  className = '',
  style?: CellStyle,
): NumberCell => ({
  type: 'number',
  value,
  className,
  style,
  format: numberFormat,
});

export const chevronCell = (
  text: string,
  hasChildren?: boolean,
  parentId?: Id,
  indent?: number,
  className = '',
  style?: CellStyle,
): ChevronCell => ({
  type: 'chevron',
  text,
  className,
  style,
  hasChildren,
  parentId,
  indent,
});

export const nonEditable = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  nonEditable: true,
});

export const showZero = (cell: NumberCell): NumberCell => ({
  ...cell,
  nanToZero: true,
  hideZero: false,
});

export const bottomLine = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      bottom: {
        width: '1px',
        color: '#98A2B3',
        style: 'solid',
      },
    },
  },
});

export const topLine = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      top: {
        width: '1px',
        color: '#98A2B3',
        style: 'solid',
      },
    },
  },
});

export const noSideBorders = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      left: {
        style: 'none',
      },
      right: {
        style: 'none',
      },
    },
  },
});

export const noRightBorder = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      right: {
        style: 'none',
      },
    },
  },
});
export const noLeftBorder = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      left: {
        style: 'none',
      },
    },
  },
});
export const leftBorder = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      left: {
        width: '1px',
        color: 'rgb(232, 232, 232)',
        style: 'solid',
      },
    },
  },
});
export const rightBorder = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      right: {
        width: '1px',
        color: 'rgb(232, 232, 232)',
        style: 'solid',
      },
    },
  },
});
export const noTopBorders = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      bottom: {
        style: 'none',
      },
      top: {
        style: 'none',
      },
    },
  },
});

export function monthHeaderCell(
  month: string,
  additionalClassNames = '',
): DefaultCellTypes {
  return nonEditable(
    textCell(month, ` text-uppercase ${additionalClassNames}`, {
      background: '#F9FAFB',
      color: '#667085',
      border: {
        bottom: { style: 'none' },
        left: { style: 'none' },
        right: { style: 'none' },
      },
    }),
  );
}

export function borderRight(cell: DefaultCellTypes): DefaultCellTypes {
  return {
    ...cell,
    style: {
      ...cell.style,
      border: {
        ...cell.style?.border,
        right: {
          width: '2px',
          color: '#6941C6',
          style: 'solid',
        },
      },
    },
  };
}

export function borderLeft(cell: DefaultCellTypes): DefaultCellTypes {
  return {
    ...cell,
    style: {
      ...cell.style,
      border: {
        ...cell.style?.border,
        left: {
          width: '2px',
          color: '#6941C6',
          style: 'solid',
        },
      },
    },
  };
}

export function borderTop(cell: DefaultCellTypes): DefaultCellTypes {
  return {
    ...cell,
    style: {
      ...cell.style,
      border: {
        ...cell.style?.border,
        top: {
          width: '2px',
          color: '#6941C6',
          style: 'solid',
        },
      },
    },
  };
}
export function grayBorderTop(cell: DefaultCellTypes): DefaultCellTypes {
  return {
    ...cell,
    style: {
      ...cell.style,
      border: {
        ...cell.style?.border,
        top: {
          width: '1px',
          color: '#98A2B3',
          style: 'solid',
        },
      },
    },
  };
}
export function borderBottom(cell: DefaultCellTypes): DefaultCellTypes {
  return {
    ...cell,
    style: {
      ...cell.style,
      border: {
        ...cell.style?.border,
        bottom: {
          width: '2px',
          color: '#6941C6',
          style: 'solid',
        },
      },
    },
  };
}

export function borderLeftRight(cell: DefaultCellTypes): DefaultCellTypes {
  return borderLeft(borderRight(cell));
}

export function borderLeftRightTop(cell: DefaultCellTypes): DefaultCellTypes {
  return borderLeft(borderRight(borderTop(cell)));
}

export function borderLeftRightBottom(
  cell: DefaultCellTypes,
): DefaultCellTypes {
  return borderLeft(borderRight(borderBottom(cell)));
}
export function borderLeftRightTopBottom(
  cell: DefaultCellTypes,
): DefaultCellTypes {
  return borderLeft(borderRight(borderTop(borderBottom(cell))));
}

export const noBorders = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      top: { style: 'none' },
      bottom: { style: 'none' },
      left: { style: 'none' },
      right: { style: 'none' },
    },
  },
});

export function generateEmptyCellForMonths(
  selectedMonth: string | null,
  type: 'empty' | 'group',
  months: string[],
  nonBorders = false,
) {
  return months.map((month) => {
    const isSelected = isSelectedMonth(month, selectedMonth);
    if (type === 'empty') {
      const cell = nonEditable(emptyTextCell);
      if (isSelected) {
        return borderLeftRight(cell);
      }
      if (nonBorders) {
        return noBorders(cell);
      }
      return noSideBorders(cell);
    }
    const cell = noSideBorders(bottomLine(nonEditable(emptyTextCell)));
    if (isSelected) {
      return borderLeftRight(cell);
    }
    return cell;
  });
}
