import { FilterProvider, Flex, Text } from '@konta/ui';
import List from '@components/List';
import { getDocument } from '@util/declarationEntries';
import EntryUpdateConfirmModal from '@components/EntryUpdateConfirmModal';
import TableRowItem from 'shared/components/TableRowItem/TableRowItem';
import useExpensesList from './useExpensesList';
import Filters from './Filters';

export default function ExpensesList() {
  const {
    expensesEntries,
    paginationProps,
    isLoading,
    setActiveFilters,
    regimeOptions,
    filters,
    selectedEntry,
    setSelectedEntry,
    toggleEntryModal,
    refetch,
    activeDeclarations,
    updateCurrentWorkflow,
    updateWorkflowGraphs,
    preferredFiscalRegime,
  } = useExpensesList();

  return (
    <FilterProvider
      filters={filters}
      filterStorageId="appClassificationExpenseList"
      onFilterChange={(newFilters) => setActiveFilters(newFilters)}
    >
      {!!selectedEntry && (
        <EntryUpdateConfirmModal
          isOpen={!!selectedEntry}
          toggle={toggleEntryModal}
          entry={selectedEntry}
          onSuccess={refetch}
          updateCurrentWorkflow={updateCurrentWorkflow}
          updateWorkflowGraphs={updateWorkflowGraphs}
          withBlur
          centered
        />
      )}

      <Flex column gap={10} css={{ width: '100%' }}>
        <Filters regimeOptions={regimeOptions} />
        <List
          isLoading={isLoading}
          isEmpty={!expensesEntries.length}
          paginationProps={paginationProps}
          header={
            <Text color="gray500" medium m lineHeight="m">
              Gastos por categorizar
            </Text>
          }
        >
          {expensesEntries.map((declarationEntry) => {
            const { url } = getDocument(declarationEntry);
            return (
              <TableRowItem
                activeDeclarations={activeDeclarations}
                declarationEntry={declarationEntry}
                setSelectedEntry={setSelectedEntry}
                key={declarationEntry.id}
                url={url}
                preferredFiscalRegime={preferredFiscalRegime}
                entryType="expense"
              />
            );
          })}
        </List>
      </Flex>
    </FilterProvider>
  );
}
