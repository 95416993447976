import { AxiosRequestConfig } from 'axios';
import type { ZendeskLoginRequest, ZendeskLoginResponse } from 'types/entities';
import api from './index';

export const postZendesk = async (
  payload: ZendeskLoginRequest,
  config?: AxiosRequestConfig<ZendeskLoginResponse>,
) => {
  const { data } = await api.post<ZendeskLoginResponse>(
    `sessions/zendesk`,
    payload,
    config,
  );
  return data;
};

export const getZendesk = async (
  config?: AxiosRequestConfig<ZendeskLoginResponse>,
) => {
  const { data } = await api.get<ZendeskLoginResponse>(
    `sessions/zendesk`,
    config,
  );
  return data;
};
