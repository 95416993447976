import React, { useMemo } from 'react';
import { Alert, Flex } from '@konta/ui';
import InfoCircleOutlineIcon from 'mdi-react/InfoCircleOutlineIcon';
import { TaxableEntity } from 'types/entities';
import { PFAE_SAT_KEY } from 'shared/constants/grids';

interface PendingArrearsAlertProps {
  taxableEntity?: TaxableEntity | null;
  satKey?: number | null;
}

function PendingArrearsAlert({
  taxableEntity,
  satKey = null,
}: PendingArrearsAlertProps) {
  const showPendingArrearsAlert = useMemo(() => {
    const arrearStatus = taxableEntity?.arrears_status;
    const hasPendingArrears = [
      'konta_is_working_on_arrears',
      'another_accounter_is_working_on_arrears',
    ].includes(arrearStatus ?? '');

    if (satKey) {
      return [PFAE_SAT_KEY].includes(satKey) && hasPendingArrears;
    }

    return hasPendingArrears;
  }, [satKey, taxableEntity?.arrears_status]);

  if (!showPendingArrearsAlert) return null;

  return (
    <Flex column css={{ mb: '$12' }}>
      <Alert icon={<InfoCircleOutlineIcon />} color="primary">
        Detectamos que actualmente cuenta con declaraciones pendientes en
        atrasos. En consecuencia, esta declaración será presentada hasta que
        regularicemos todas las declaraciones pendientes en atrasos.
      </Alert>
    </Flex>
  );
}

export default PendingArrearsAlert;
