import React from 'react';
import { DebtsCardOrder } from '@containers';
import { TaskInactiveJumbotron } from '@components';
import Subscription from '@components/Subscription';
import { useTaxableEntity } from '@hooks';
import UserPlans from '@components/UserPlans';

function SettingsPlan() {
  const { taxable_entity } = useTaxableEntity();

  return (
    <>
      <DebtsCardOrder title="Pendiente de pago" />
      {
        {
          demo: <UserPlans />, // 0
          active: <Subscription />, // 1
          pending_payment: <Subscription />, // 2
          inactive: <TaskInactiveJumbotron />, // 3
        }[taxable_entity.status] || null /* default */
      }
    </>
  );
}

export default SettingsPlan;
