import React from 'react';
import PropTypes from 'prop-types';
import { Rating as RatingUi } from '@konta/ui';

export default function Rating(props) {
  return (
    <RatingUi {...props}>
      <Star />
    </RatingUi>
  );
}

function Star(props) {
  const starProps = { ...props };
  const nameMap = {
    isDisabled: 'is-disabled',
    isActive: 'is-active',
    isActiveHalf: 'is-active-half',
    willBeActive: 'will-be-active',
  };

  const className = Object.keys(nameMap)
    // eslint-disable-next-line
    .filter(prop => (delete starProps[prop], props[prop]))
    .map((prop) => nameMap[prop])
    .join(' ');

  return (
    <div className={`react-rater-star outline ${className}`} {...starProps} />
  );
}

Star.defaultProps = {
  willBeActive: false,
  isActive: false,
  isActiveHalf: false,
  isDisabled: false,
};

Star.propTypes = {
  isActive: PropTypes.bool,
  isActiveHalf: PropTypes.bool,
  willBeActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
