import { createContext, useContext, ReactNode, FC } from 'react';
import useCommandMenuControl, {
  CommandMenuControlReturnType,
} from '@components/CommandMenu/useCommandMenuControl';

const CommandMenuContext = createContext<
  CommandMenuControlReturnType | undefined
>(undefined);

type CommandMenuProviderProps = {
  children: ReactNode;
};
// eslint-disable-next-line react/function-component-definition
export const CommandMenuProvider: FC<CommandMenuProviderProps> = ({
  children,
}) => {
  const commandMenuControl = useCommandMenuControl();

  return (
    <CommandMenuContext.Provider value={commandMenuControl}>
      {children}
    </CommandMenuContext.Provider>
  );
};

export const useCommandMenuContext = () => {
  const context = useContext(CommandMenuContext);
  if (!context) {
    throw new Error(
      'useCommandMenuContext must be used within a CommandMenuProvider',
    );
  }
  return context;
};
