import { useSelector } from 'react-redux';
import useTaxableEntityPreferences from '@hooks/useTaxableEntityPreferences';
import type { IRootState } from 'types/redux';

export default function usePreferredFiscalRegime() {
  const taxableEntity = useSelector(
    (state: IRootState) => state.taxableEntity.taxable_entity,
  );
  const { taxableEntityPreferences, loadingTaxableEntityPreferences } =
    useTaxableEntityPreferences(taxableEntity?.id || 0, {
      staleTime: 60 * 1000,
    });

  const preferredFiscalRegime =
    taxableEntityPreferences?.preferred_fiscal_regime;

  return {
    preferredFiscalRegime,
    taxableEntityPreferences,
    loadingPreferredFiscalRegime: loadingTaxableEntityPreferences,
  };
}
