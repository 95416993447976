import React from 'react';
import { Row, Col, CardTitle } from 'reactstrap';
// eslint-disable-next-line import/no-cycle
import { LinkVideoHelper } from '@components';

function Colxx(props) {
  return (
    <Col {...props} widths={['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']} />
  );
}
function Separator(props) {
  return <div className={`separator ${props.className}`} />;
}

function CardHeader({ title, withHelper, videoId }) {
  return (
    <Row>
      <Colxx Colxx xxs="8" md="10">
        <CardTitle tag="h4">{title && title}</CardTitle>
      </Colxx>
      <Colxx xxs="4" md="2" className="text-right">
        {withHelper && (
          <LinkVideoHelper
            label="Ver video"
            videoId={videoId}
            tooltipHelper="Ver video"
            iconId={`VideoHelper-${videoId}`}
            className="pt-0 pl-0"
          />
        )}
      </Colxx>
    </Row>
  );
}

export { Colxx, Separator, CardHeader };
