import React, { useState, useEffect } from 'react';
import {
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  Col,
} from 'reactstrap';
import { useToggle } from 'rooks';
import IntlMessages from '@util/IntlMessages';
import { useTaxableEntity } from '@hooks';
import useGetTaxableEntityCouponsQuery from '@hooks/useGetTaxableEntityCouponsQuery';
import { Text, Button as KontaButtom } from '@konta/ui';
import useSubscriptions from '@hooks/useSubscriptions';

export default function Discount({ source = 'app' }) {
  const { taxable_entity } = useTaxableEntity();
  const { isCouponsLoading, lastCoupon } = useGetTaxableEntityCouponsQuery(
    taxable_entity.id,
  );
  const [code, setCode] = useState('');
  const [discountInputToggle, setDiscountInputToggle] = useToggle();
  const { handleDiscount } = useSubscriptions();
  const validateOnChangeInput = (e) => {
    setCode(e.target.value.toString());
  };

  const storedCoupon = window?.localStorage?.getItem('rc');
  useEffect(() => {
    if (source === 'fiscal-visor') {
      handleDiscount(null, 'fiscal_visor');
      setCode('fiscal_visor');
    }

    if (source !== 'fiscal-visor' && !isCouponsLoading && !lastCoupon) {
      setDiscountInputToggle();
      handleDiscount(null, storedCoupon);
      setCode(storedCoupon);
    }
  }, [
    isCouponsLoading,
    lastCoupon,
    handleDiscount,
    setDiscountInputToggle,
    storedCoupon,
    source,
  ]);

  if (lastCoupon || storedCoupon || source === 'fiscal-visor') {
    return null;
  }

  return (
    <>
      <Row className="justify-content-center">
        <Col xs="6" className="d-flex justify-content-center">
          <KontaButtom color="minimal">
            <Text className="clickable-text" onClick={setDiscountInputToggle}>
              ¿Descuento o cupón?
            </Text>
          </KontaButtom>
        </Col>
      </Row>
      {discountInputToggle && (
        <Row className="justify-content-center mt-3">
          <Col xs="4">
            <InputGroup className={`mb- `}>
              <Input
                className="mb-2"
                onChange={validateOnChangeInput}
                type="text"
                value={code}
              />
              <InputGroupAddon addonType="append">
                <Button
                  size="sm"
                  color="primary"
                  className="mb-2"
                  onClick={(e) => handleDiscount(e, code)}
                >
                  <IntlMessages id="pages.dicount-validate" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      )}
    </>
  );
}
