import api from '@api';
import { call, put, select } from 'redux-saga/effects';
import { ErrorHandler, StatusHandler } from '@util/Saga';
import { NotificationManager } from '@components/Notifications';
import {
  payFiscalPeriodFailure,
  payFiscalPeriodSuccess,
  fetchCurrentFiscalPeriod,
} from '../actions';

/**
 * @param {string} card_id
 */
export default function* ({ payload: { fiscal_period } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const {
      taxableEntity: { taxable_entity },
      paymentMethods: { ui },
    } = yield select((state) => state);
    if (ui.selected_card && ui.selected_card.source_type === 'card') {
      const response = yield call(
        api.post,
        `fiscal_periods/${fiscal_period.id}/pay`,
        {
          fiscal_period: {
            card_id: ui.selected_card.card_taxes_id,
          },
        },
        {
          headers: { Authorization: `${access_token}` },
        },
      );
      yield call(StatusHandler, {
        response,
        status: response.status,
        success: payFiscalPeriodSuccess,
        failure: payFiscalPeriodFailure,
        title: 'Éxito',
        message: 'Su pago ha sido realizado con satisfactoriamente',
      });
      if (taxable_entity.current_fiscal_period === fiscal_period.id)
        yield put(fetchCurrentFiscalPeriod());
    } else {
      // TODO Pass this validation to component
      NotificationManager.error(
        'Verifique que tenga un método de pago de tipo tarjeta seleccionado.',
        'Upss..',
        4000,
        null,
        null,
        null,
      );
      yield put(payFiscalPeriodFailure());
    }
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: payFiscalPeriodFailure,
      showBodyErrors: true,
    });
  }
}
