import { ComponentProps, useMemo } from 'react';
import { Text } from '@konta/ui';
import useWorkflow from '@components/Workflow/hooks/useWorkflow';

const determineTextColor = (
  isValid: boolean,
): ComponentProps<typeof Text>['color'] => {
  if (!isValid) {
    return 'error500';
  }
  return 'black';
};

export default function useCaptureLinePayment() {
  const {
    grandTotalToPay,
    handleRequireCaptureLine,
    requireCaptureLineLoading,
    declarationsDocuments,
    loadingPayrollRetention,
  } = useWorkflow();

  const captureLineDocuments = useMemo(
    () => declarationsDocuments.filter((document) => document.totalToPay > 0),
    [declarationsDocuments],
  );

  const hasAnyExpired = useMemo(
    () => captureLineDocuments.some((document) => !document.isValid),
    [captureLineDocuments],
  );

  const hasAnyDebt = useMemo(
    () =>
      !hasAnyExpired &&
      captureLineDocuments.some((document) => document.hasDebt),
    [captureLineDocuments, hasAnyExpired],
  );

  const hasNoDebts = useMemo(
    () =>
      !hasAnyExpired &&
      captureLineDocuments.every((document) => document.hasNoDebt),
    [captureLineDocuments, hasAnyExpired],
  );

  const primaryButtonProps = useMemo(() => {
    if (hasAnyExpired) {
      return {
        onClick: handleRequireCaptureLine,
        children: 'Solicitar nueva línea de captura',
      };
    }
    if (hasNoDebts) {
      return {
        children: 'Continuar',
      };
    }
    if (hasAnyDebt) {
      return {
        children: 'Pagar por tarjeta',
      };
    }
    return {};
  }, [hasAnyExpired, hasNoDebts, hasAnyDebt, handleRequireCaptureLine]);

  function getCaptureLineInfo(isValid: boolean, captureLine: string) {
    const determineTextContent = () => {
      if (!isValid) {
        return `${captureLine} - Vencida`;
      }
      if (captureLine) {
        return captureLine;
      }
      return 'N/A';
    };

    const textColor = determineTextColor(isValid);
    const textContent = determineTextContent();

    return { textColor, textContent };
  }

  function getValidityInfo(
    isValid: boolean,
    captureLine: string,
    validationDate: string,
  ) {
    const determineTextContent = () => {
      if (captureLine && validationDate) {
        return validationDate;
      }
      return 'N/A';
    };

    const textColor = determineTextColor(isValid);
    const textContent = determineTextContent();

    return { textColor, textContent };
  }
  function getPaymentValidityInfo(
    isValid: boolean,
    captureLine: string,
    paymentLimitDate: string,
  ) {
    const determineTextContent = () => {
      if (captureLine && paymentLimitDate) {
        return paymentLimitDate;
      }
      return 'N/A';
    };

    const textColor = determineTextColor(isValid);
    const textContent = determineTextContent();

    return { textColor, textContent };
  }

  const isLoading = loadingPayrollRetention || requireCaptureLineLoading;

  return {
    declarationsDocuments: captureLineDocuments,
    hasAnyExpired,
    hasAnyDebt,
    hasNoDebts,
    handleRequireCaptureLine,
    primaryButtonProps,
    getValidityInfo,
    getCaptureLineInfo,
    grandTotalToPay,
    isLoading,
    getPaymentValidityInfo,
  };
}
