import { useMemo } from 'react';
import _ from 'lodash';

export default function useLastComplement({ isWorkflow, cfdi, complements }) {
  const lastComplement = useMemo(() => {
    const paymentDetails = isWorkflow
      ? cfdi.declaration_entry_payments
          .filter((complement) => {
            return (
              complement.source.payment_detail.cfdi.status !== 'cancelled' &&
              complement.source.payment_detail.cfdi.status !==
                'cancellation_in_progress'
            );
          })
          .map((entry) => {
            return entry.source.payment_detail;
          })
      : complements.filter((complement) => {
          return (
            complement.cfdi?.status !== 'cancelled' &&
            complement.cfdi?.status !== 'cancellation_in_progress'
          );
        });

    return _.maxBy(paymentDetails, 'partiality_number');
  }, [isWorkflow, complements, cfdi.declaration_entry_payments]);

  return lastComplement;
}
