import * as yup from 'yup';

export const validationSchema = yup
  .object()
  .nullable()
  .shape({
    imNotAgreeWithTaxes: yup.boolean(),
    wantToVerifyMyPartialities: yup.boolean(),
    wantToKnowPersonalDeductionBreakdown: yup.boolean(),
    wantToKnowTaxBreakdown: yup.boolean(),
    wantToScheduleACall: yup.boolean(),
    callReason: yup.string().when('wantToScheduleACall', {
      is: true,
      then: yup.string().required('Campo requerido'),
    }),
    others: yup.boolean(),
    othersReason: yup.string().when('others', {
      is: true,
      then: yup.string().required('Campo requerido'),
    }),
  });

export type FormValues = {
  imNotAgreeWithTaxes: boolean;
  wantToVerifyMyPartialities: boolean;
  wantToKnowPersonalDeductionBreakdown: boolean;
  wantToKnowTaxBreakdown: boolean;
  wantToScheduleACall: boolean;
  callReason: string;
  others: boolean;
  othersReason: string;
};
export const defaultInitialValues: FormValues = {
  imNotAgreeWithTaxes: false,
  wantToVerifyMyPartialities: false,
  wantToKnowPersonalDeductionBreakdown: false,
  wantToKnowTaxBreakdown: false,
  wantToScheduleACall: false,
  callReason: '',
  others: false,
  othersReason: '',
};
