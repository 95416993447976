import { useMemo } from 'react';
import dayjs from 'dayjs';
import type { IRootState } from 'types/redux';
import useSelectedWorkflow from './useSelectedWorkflow';
import useHasPayrollRetention from './useHasPayrollRetention';

export default function useIsValidCaptureLine() {
  const { workflow } = useSelectedWorkflow() as IRootState['selected_workflow'];

  const { hasPayrollRetentions } = useHasPayrollRetention();

  const isValid = useMemo(() => {
    if (!hasPayrollRetentions) {
      return true;
    }
    const { declaration_document } = workflow?.active_declaration || {};

    const declarationDate = dayjs(declaration_document?.salary_validation_date);
    const isExpired = declarationDate.subtract(1, 'day') < dayjs();

    return !isExpired;
  }, [workflow, hasPayrollRetentions]);

  return isValid;
}
