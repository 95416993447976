import React from 'react';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { Colxx, DebtsTable } from '@components';
import { useFiscalPeriods } from '@hooks';
import { sumAll } from '@util/Utils';

function DebtsCardFp({ title, type, fiscalPeriods }) {
  const { loading: fpLoading } = useFiscalPeriods('fiscal_period');

  const pending_fps = fiscalPeriods.filter(
    (fp) =>
      fp.status === 'pending_payment' &&
      sumAll(
        fp.afore,
        fp.imss,
        fp.infonavit,
        fp.isr_over_salary,
        fp.isr_total,
        fp.iva_total,
        fp.taxes_over_payroll,
      ) > 0,
  );

  if (pending_fps.length) {
    return (
      <Row>
        <Colxx>
          <Card className="mb-4 border-top-2 border-danger">
            <CardBody>
              <CardTitle tag="h4">{title}</CardTitle>
              <DebtsTable type={type} loading={fpLoading} data={pending_fps} />
            </CardBody>
          </Card>
        </Colxx>
      </Row>
    );
  }
  return null;
}

export default DebtsCardFp;
