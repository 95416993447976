import React from 'react';
import PropTypes from 'prop-types';
import FormikSelect from '@components/FormikSelect';
import {
  BIMONTHLY_PERIOD_OPTIONS,
  MONTHLY_PERIOD_OPTIONS,
} from '@constants/invoicing';
import FormikKontaSelect from '@components/FormikKontaSelect';

export default function FormikPeriodicityMonthField({
  name,
  periodicity,
  newDesign,
  labelHelpPopover,
}) {
  const isBimonthly = periodicity === '05';

  if (newDesign) {
    return (
      <FormikKontaSelect
        name={name}
        label={isBimonthly ? 'Bimestre' : 'Mes'}
        labelHelpPopover={labelHelpPopover}
        options={
          isBimonthly ? BIMONTHLY_PERIOD_OPTIONS : MONTHLY_PERIOD_OPTIONS
        }
      />
    );
  }

  return (
    <FormikSelect
      name={name}
      label={isBimonthly ? 'Bimestre' : 'Mes'}
      labelHelpPopover={labelHelpPopover}
      options={isBimonthly ? BIMONTHLY_PERIOD_OPTIONS : MONTHLY_PERIOD_OPTIONS}
    />
  );
}

FormikPeriodicityMonthField.propTypes = {
  name: PropTypes.string.isRequired,
  periodicity: PropTypes.string,
};

FormikPeriodicityMonthField.defaultProps = {
  periodicity: null,
};
