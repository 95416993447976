import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import IntlMessages from '@util/IntlMessages';

export function TabItem({
  activeTab,
  toggleTab,
  tabNumber,
  tabName,
  toLink = '#',
  navLinkPill = true,
  outlinedTabs,
  noIntl,
}) {
  return (
    <NavItem>
      <NavLink
        className={classnames({
          active: activeTab === tabNumber,
          'nav-link': navLinkPill,
          inactive: activeTab !== tabNumber && outlinedTabs,
          'mr-2': outlinedTabs,
        })}
        onClick={(_) => toggleTab(tabNumber)}
        style={{ cursor: 'pointer' }}
        to={toLink}
      >
        {noIntl ? tabName : <IntlMessages id={tabName} />}
      </NavLink>
    </NavItem>
  );
}

export const TabList = ({
  tabs = [],
  activeTab = '',
  toggleTab,
  outlinedTabs,
  noIntl = false,
}) => {
  return tabs.map((item, index) => (
    <TabItem
      key={item}
      tabName={item}
      noIntl={noIntl}
      tabNumber={`${index + 1}`}
      activeTab={activeTab}
      toggleTab={toggleTab}
      outline
      className="mr-1"
      outlinedTabs={outlinedTabs}
    />
  ));
};
