import { useEffect, useMemo, useState } from 'react';
import { Filters } from '@konta/ui';
import useCfdisPagination from 'shared/hooks/useCfdisPagination';
import useMultiSelectable from 'shared/hooks/useMultiSelectable';
import type { Cfdi } from 'types/entities';
import usePreviousPeriodsColumnsTable from './usePreviousPeriodsColumnsTable';
import getRansackFilters from './getRansackFilters';

interface UsePreviousPeriodsProps {
  source: 'incomes' | 'expenses';
  setSelectedCfdis: (cfdis: Cfdi[]) => void;
}
export default function usePreviousPeriods({
  source,
  setSelectedCfdis,
}: UsePreviousPeriodsProps) {
  const [filters, setFilters] = useState<Filters>([]);
  const ransackFilters = useMemo(
    () => getRansackFilters(filters, source),
    [filters, source],
  );
  const {
    pagination,
    cfdisLoading,
    cfdis,
    refetch: refetchCfdis,
  } = useCfdisPagination({
    filters: ransackFilters,
  });
  const multiSelectable = useMultiSelectable({
    data: cfdis,
  });
  const columns = usePreviousPeriodsColumnsTable(source, multiSelectable);
  const selectedCfdis = multiSelectable.selectedData;
  const selectedCfdisLength = selectedCfdis.length;

  useEffect(() => {
    if (selectedCfdis) {
      setSelectedCfdis(selectedCfdis);
    }
  }, [selectedCfdisLength]);

  return {
    filters,
    setFilters,
    pagination,
    cfdis,
    columns,
    cfdisLoading,
    selectedCfdis,
    refetchCfdis,
  };
}
