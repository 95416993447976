import { call } from 'redux-saga/effects';
import api from '@api';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import { fetchFiscalPeriodFailure, fetchFiscalPeriodSuccess } from '../actions';

export default function* ({ payload: { year } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(api.get, `fiscal_periods/`, {
      headers: { Authorization: `${access_token}` },
      params: {
        year,
      },
    });
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: fetchFiscalPeriodSuccess,
      failure: fetchFiscalPeriodFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: fetchFiscalPeriodFailure,
    });
  }
}
