export const rootCss = {
  flex: '1',
  width: '100%',
  flexDirection: 'row',
  '@md': { flexDirection: 'column' },
};
export const containerCss = {
  flex: '1',
  flexDirection: 'column',
  gap: '20px',
};
export const buttonsCss = {
  width: 'auto',
  path: { stroke: 'white' },
};
