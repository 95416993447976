import { useCallback, useEffect, useMemo } from 'react';
import usePreviousValue from '@hooks/usePreviousValue';
import { useLocalStorage } from 'usehooks-ts';
import { ENVIRONMENTS } from 'backoffice/constants/enviroments';

type Environments = typeof ENVIRONMENTS;

export type EnvironmentsKeys = keyof Environments;
export default function useEnvironments() {
  const [environments, setEnvironments] = useLocalStorage(
    'environments',
    ENVIRONMENTS.production,
  );
  const prevEnvironments = usePreviousValue(environments);

  const handleEnvironmentChange = useCallback(
    (key: EnvironmentsKeys) => {
      setEnvironments(ENVIRONMENTS[key]);
    },
    [setEnvironments],
  );

  const environmentKeys = useMemo(
    () => Object.keys(ENVIRONMENTS) as EnvironmentsKeys[],
    [],
  );

  useEffect(() => {
    if (prevEnvironments && environments.api !== prevEnvironments?.api) {
      window.location.href = '/login';
    }
  }, [environments, prevEnvironments]);

  return { environments, handleEnvironmentChange, environmentKeys };
}
