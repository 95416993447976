import { ComponentProps, ReactNode } from 'react';
import { Command } from 'cmdk';
import { Flex, Text } from '@konta/ui';
import { FeatureIcon } from './styled';

interface FeaturedItemProps extends ComponentProps<typeof Command.Item> {
  title: string;
  subtitle: string;
  shortcut?: string;
  icon?: ReactNode;
}

export default function FeaturedItem({
  title,
  subtitle,
  shortcut,
  onSelect,
  value,
  icon,
  ...props
}: FeaturedItemProps) {
  return (
    <Command.Item
      className="help-center-item"
      onSelect={onSelect}
      value={value}
      {...props}
    >
      <Flex gap={12} itemsCenter>
        {!!icon && <FeatureIcon>{icon}</FeatureIcon>}
        <Flex column>
          <Text s lineHeight="s" semibold>
            {title}
          </Text>
          <Text>{subtitle}</Text>
        </Flex>
      </Flex>
      {shortcut && (
        <div cmdk-shortcuts="">
          {shortcut.split(' ').map((key) => (
            <kbd key={key}>{key}</kbd>
          ))}
        </div>
      )}
    </Command.Item>
  );
}
