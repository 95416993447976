import {
  CarouselItem,
  useCarouselControl,
  useMediaQuery,
  config,
} from '@konta/ui';
import dayjs from 'dayjs';
import { useMemo, useState, useEffect } from 'react';
import { useInsightsDialog } from './InsightsDialogContext';

export default function useInsights() {
  const [hasShownInitialLoader, setHasShownInitialLoader] = useState(false);
  const {
    handleFlowSelect,
    sortedTaxableEntityFlows,
    taxableEntityFlowsLoading,
  } = useInsightsDialog();
  const { carouselRef, buttonState, handleExternalPrev, handleExternalNext } =
    useCarouselControl();

  const showNavigation =
    !buttonState.isNextDisabled || !buttonState.isPrevDisabled;

  const carouselItems: CarouselItem[] = useMemo(
    () =>
      sortedTaxableEntityFlows.map((taxableEntityFlow) => ({
        id: taxableEntityFlow.id.toString(),
        taxableEntityFlow,
        type: 'image',
        content: `data:${taxableEntityFlow.flow.image.content_type};base64,${taxableEntityFlow.flow.image.content}`,
      })),
    [sortedTaxableEntityFlows],
  );

  const isMobileOrTablet = useMediaQuery(config.media['<md']);

  const currentMonth = dayjs().format('MMMM');

  useEffect(() => {
    if (!taxableEntityFlowsLoading && !hasShownInitialLoader) {
      setHasShownInitialLoader(true);
    }
  }, [taxableEntityFlowsLoading, hasShownInitialLoader]);

  return {
    carouselItems,
    carouselRef,
    buttonState,
    handleExternalPrev,
    handleExternalNext,
    isMobileOrTablet,
    currentMonth,
    handleFlowSelect,
    taxableEntityFlowsLoading:
      taxableEntityFlowsLoading && !hasShownInitialLoader,
    showNavigation,
  };
}
