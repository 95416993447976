import postProduct from '@api/postProduct';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '@redux/actions';
import { NotificationManager } from '@components';

/**
 * @param { import('react-query').UseMutationOptions } options
 */
export default function useCreateProduct(options) {
  const dispatch = useDispatch();
  const taxableId = useSelector(
    (state) => state.taxableEntity?.taxable_entity?.id,
  );

  const request = useCallback(
    async (product) => {
      const resp = await postProduct({ taxableId, product });
      dispatch(fetchProducts({ id: taxableId }));

      NotificationManager.success(
        'Producto agregado con éxito',
        'Producto añadido',
        3000,
      );

      return resp;
    },
    [dispatch, taxableId],
  );

  return useMutation(request, options);
}
