import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { Modal, ModalHeader } from 'reactstrap';
import cx from 'classnames';
import { Button, ModalContent, ModalActions, Flex } from '@konta/ui';

export default function FormikFormModal({
  title,
  toggle,
  isOpen,
  onSubmit,
  initialValues,
  children,
  submitText,
  isLoading,
  disabled,
  size,
  enableReinitialize,
  validationSchema,
  withCancelBtn,
  extraButton,
}) {
  const formikRef = useRef();
  const formik = formikRef.current;
  const closable = !disabled && !isLoading;

  const handleSubmit = (values, { setErrors, resetForm }) => {
    onSubmit({ values, formik, setErrors, resetForm });
  };

  return (
    <Modal isOpen={isOpen} toggle={closable ? toggle : null} size={size}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize={enableReinitialize}
        validationSchema={validationSchema}
      >
        {(renderProps) => (
          <Form>
            <ModalHeader toggle={closable ? toggle : null}>{title}</ModalHeader>
            <ModalContent css={{ color: '$gray900' }}>
              {typeof children === 'function'
                ? children(renderProps)
                : children}
            </ModalContent>
            <ModalActions
              className={cx('d-flex', {
                'justify-content-center': withCancelBtn,
              })}
              css={
                extraButton && {
                  flexDirection: 'column',
                }
              }
            >
              <Flex
                gap={12}
                css={{
                  width: '100%',
                  justifyContent: extraButton ? 'end' : 'center',
                }}
              >
                {withCancelBtn && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={toggle}
                    loading={isLoading}
                  >
                    Cancelar
                  </Button>
                )}

                <Button
                  color="primary"
                  type="submit"
                  loading={isLoading}
                  disabled={disabled}
                >
                  {submitText}
                </Button>
              </Flex>
              {extraButton}
            </ModalActions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

FormikFormModal.propTypes = {
  title: PropTypes.string,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  initialValues: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  submitText: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  enableReinitialize: PropTypes.bool,
  validationSchema: PropTypes.instanceOf(Object),
  withCancelBtn: PropTypes.bool,
};

FormikFormModal.defaultProps = {
  title: 'Formulario',
  toggle: null,
  isOpen: false,
  initialValues: {},
  onSubmit: null,
  children: null,
  submitText: 'Guardar',
  disabled: false,
  size: 'lg',
  isLoading: false,
  enableReinitialize: false,
  validationSchema: null,
  withCancelBtn: false,
};
