import { styled } from '@konta/ui';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});
export const Title = styled('span', {
  textAlign: 'center',
  fontFamily: 'DM Sans',
  fontSize: '28px',
  fontWeight: '500',
  lineHeight: '36px',
  color: '$primary700',
  b: {
    fontWeight: '700',
  },
});
export const Subtitle = styled('div', {
  fontFamily: 'DM Sans',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '500',
  lineHeight: 'normal',
  color: '$primary700',
  'span[data-type="s"]': {
    fontSize: '16px',
  },
  'span[data-type="m"]': {
    fontSize: '20px',
  },
});

export const ImageContainer = styled('div', {
  position: 'relative',
  '& svg': {
    width: '100%',
    height: 'auto',
  },
});
