import { useState } from 'react';
import dayjs from 'dayjs';
import { File02LineIcon } from '@konta/icons';
import DeclarationEntriesTotalsModal from 'shared/components/DeclarationEntriesTotalsModal';
import Item from '../FeatureItem';

export default function AccountingAccountsPage() {
  const [entriesTotalsModalType, setEntriesModalType] = useState<
    'incomes' | 'expenses' | ''
  >('');

  const closeEntriesTotalsModal = () => {
    setEntriesModalType('');
  };

  const date = dayjs().format('YYYY-MM-DD');

  return (
    <>
      {!!entriesTotalsModalType && (
        <DeclarationEntriesTotalsModal
          open={!!entriesTotalsModalType}
          toggleOpen={closeEntriesTotalsModal}
          date={date}
          type={entriesTotalsModalType}
        />
      )}
      <Item
        title="Ingresos cobrados"
        subtitle="Ver reporte de ingresos cobrados"
        icon={<File02LineIcon />}
        value="Ingresos cobrados - Ver reporte de ingresos cobrados"
        onSelect={() => {
          setEntriesModalType('incomes');
        }}
      />
      <Item
        title="Gastos pagados"
        subtitle="Ver reporte de gastos pagados"
        value="Gastos pagados - Ver reporte de gastos pagados"
        icon={<File02LineIcon />}
        onSelect={() => {
          setEntriesModalType('expenses');
        }}
      />
    </>
  );
}
