import postTwoFactorAuth from '@api/auth/postTwoFactorAuth';
import { useCallback } from 'react';
import { useMutation } from 'react-query';

/**
 * @param { import('react-query').UseMutationOptions } options
 */
export default function useCreateTwoFactorAuth(options) {
  const postTwoFactorAuthRequest = useCallback((payload) => {
    return postTwoFactorAuth({ payload });
  }, []);

  return useMutation(postTwoFactorAuthRequest, options);
}
