import api from '@api';
import { USER_ADDRESS } from '@constants/reactQueries';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export default function useUserAddressQuery() {
  const taxableEntity = useSelector((state) => {
    return state.taxableEntity.taxable_entity;
  });

  const { data, isLoading, ...rest } = useQuery({
    queryKey: [USER_ADDRESS, taxableEntity.id],
    queryFn: async () => {
      const uri = `/taxable_entities/${taxableEntity.id}/addresses/${taxableEntity.address}`;
      const response = await api.get(uri, {
        headers: {
          Authorization: localStorage.getItem('user_id'),
        },
      });

      return response.data;
    },
  });

  return { userAddress: data, userAddressIsLoading: isLoading, ...rest };
}
