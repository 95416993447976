import { Navigate } from 'react-router-dom';
import modulesConfig from '../modules/modulesConfig';
import KontaUtils from '../@konta/utils/KontaUtils';
import settingsConfig from './settingsConfig';

const routeConfigs = [...modulesConfig];

const routes = [
  ...KontaUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth,
  ),
  {
    path: '/',
    element: <Navigate replace to="/dashboard" />,
  },
  {
    path: '*',
    element: <Navigate replace to="/not-found" />,
  },
];

export default routes;
