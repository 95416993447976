import React, { useState } from 'react';
import { Button } from 'reactstrap';
import ModalVideo from 'react-modal-video';
import { IconButton } from './button-icon';

export function ButtonVideoHelper({
  videoId,
  tooltipHelper,
  iconId,
  tooltipPlacement,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <IconButton
        imageIcon
        iconName={require('@assets/img/icons/youtube.png')}
        width="20px"
        tooltipHelper={tooltipHelper}
        className="ml-1 mb-1 icon-image-button"
        id={iconId}
        handleClick={toggleModal}
        tooltipPlacement={tooltipPlacement}
      />

      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}

export function LinkVideoHelper({ videoId, label, className = '' }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Button
        color="link"
        className={`button-video-modal font-weight-bold ${className}`}
        onClick={toggleModal}
      >
        {label}
        <i className="simple-icon-info ml-1 font-weight-bold" />
      </Button>

      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}
