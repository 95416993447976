import { Button, Flex, Icon, Text } from '@konta/ui';
import { MessageChatSquareLineIcon, FeaturedRefreshIcon } from '@konta/icons';
import * as chat from '@util/chat';
import {
  AlertContainer,
  IconWrapper,
  ContentContainer,
  Title,
  Description,
} from './styled';

interface FeaturedAlertProps {
  withChatButton?: boolean;
  footerText?: string;
  withLeftIcon?: boolean;
  onButtonAction?: () => void;
  buttonText?: string;
  title?: string;
  description?: string;
}

export default function FeaturedAlert({
  withChatButton = false,
  footerText,
  withLeftIcon = false,
  onButtonAction,
  buttonText = 'Actualizar',
  title,
  description,
}: FeaturedAlertProps) {
  return (
    <Flex direction="column" gap="12" css={{ width: '100%' }}>
      <AlertContainer>
        <Flex gap="12">
          <IconWrapper>
            {withLeftIcon && (
              <Icon
                css={{
                  width: '48px',
                  height: '48px',
                  svg: {
                    width: 'auto',
                    height: 'auto',
                    color: '$white',
                    path: {
                      fill: 'none',
                    },
                  },
                }}
              >
                <FeaturedRefreshIcon />
              </Icon>
            )}
          </IconWrapper>
          <ContentContainer>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </ContentContainer>
        </Flex>

        {onButtonAction && (
          <Button variant="contained" color="white" onClick={onButtonAction}>
            <Text color="primary700">{buttonText}</Text>
          </Button>
        )}
      </AlertContainer>

      {footerText && (
        <Description>
          <Text color="gray700">{footerText}</Text>{' '}
          {withChatButton && (
            <Button
              css={{
                fontWeight: '600',
                color: '$primary700',
                svg: {
                  path: {
                    stroke: '$primary700',
                  },
                },
              }}
              size="xs"
              variant="text"
              onClick={() => {
                chat.open();
              }}
              noFill
              rightIcon={<MessageChatSquareLineIcon />}
            >
              Hablar con un asesor
            </Button>
          )}
        </Description>
      )}
    </Flex>
  );
}
