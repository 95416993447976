import React, { useState, useEffect } from 'react';
import { Divider, Flex, Text, GroupButton, Spacer, Button } from '@konta/ui';
import { Collapse } from 'reactstrap';
import { TrashCanOutlineIcon, Pencil2OutlineIcon } from '@konta/icons';
import FormikUserProductsSelect from '@components/FormikUserProductsSelect';
import toCurrency from '@util/toCurrency';
import ConceptTaxes from '@components/ConceptTaxes';
import FormikKontaSelect from '@components/FormikKontaSelect';
import { TAXABLE_CODE_OPTIONS, UNIT_TYPE_OPTIONS } from '@constants/invoicing';
import FormikTextInput from '@components/FormikTextInput';
import Backdrop from '@components/Backdrop';

const rowCss = {
  gap: '$8',
  '>*': {
    flex: '1',
  },
};

/**
 * @param {{ invoice: import('@hooks/useInvoiceForm').UseInvoice, concept: import('@hooks/useInvoiceForm').InvoiceConcept, index: number }} param0
 */
export default function InvoiceConcept({ concept, invoice, index }) {
  const { errors, touched, setTouched, values, setFieldTouched } =
    invoice.formik;
  const isSingleConcept = values.concepts.length === 1;

  const [showCollapsible, setShowCollapsible] = useState(false);

  useEffect(() => {
    const conceptErrors = errors.concepts?.[index];
    if (showCollapsible && conceptErrors) {
      Object.keys(conceptErrors).forEach((key) => {
        setFieldTouched(`concepts[${index}].${key}`, true);
      });
    }
  }, [errors.concepts, index, setFieldTouched, showCollapsible]);

  const expandIfHasMissingFields = (product) => {
    if (!product.unit_id || product.price === 0) {
      setShowCollapsible(true);
    }
  };

  const setTouchedConcept = (newTouched) => {
    const conceptTouch = touched.concepts?.[index];
    if (!conceptTouch) return;

    Object.keys(conceptTouch).forEach((key) => {
      conceptTouch[key] = newTouched;
    });

    setTouched(touched);
  };

  const collapse = () => {
    const conceptErrors = errors.concepts?.[index];
    if (!conceptErrors) {
      setShowCollapsible(false);
      return;
    }

    setTouchedConcept(true);
  };

  const remove = () => {
    if (isSingleConcept) {
      invoice.setConceptProduct(null, index);
      setTouchedConcept(false);
    } else {
      invoice.removeConcept(index);
    }
  };

  if (!concept.product) {
    return (
      <Flex direction="row" gap="4" justify="between">
        <Flex direction="column" gap="4" css={{ '@sm': { width: '450px' } }}>
          <FormikUserProductsSelect
            newDesign
            name={`concepts[${index}].product`}
            label="Producto o servicio"
            onChange={(option) => {
              expandIfHasMissingFields(option.value);
              invoice.setConceptProduct(option, index);
            }}
          />
        </Flex>
        <div>
          {!isSingleConcept && (
            <div>
              <GroupButton
                buttons={[
                  {
                    id: '2',
                    rightIcon: <TrashCanOutlineIcon />,
                    onClick: () => invoice.removeConcept(index),
                  },
                ]}
              />
            </div>
          )}
        </div>
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="4" justify="between">
      <Flex
        direction="row"
        gap="4"
        justify="between"
        css={{ '@<xs': { flexDirection: 'column' } }}
      >
        <Flex direction="column" gap="4" css={{ '@sm': { width: '450px' } }}>
          <Text medium color="gray900" lineHeight="l">
            {concept.product.label} {` x ${concept.quantity}`}
          </Text>
          <Flex>
            <Text color="gray700" xs>
              Clave unidad: {concept.unit?.label}
            </Text>
            <Divider vertical />
            <Text color="gray700" xs>
              Descuento: {toCurrency(+concept.discount)} MXN
            </Text>
          </Flex>
          {concept?.product?.value?.description?.length > 1000 && (
            <Text color="error700" s>
              El concepto debe tener máximo 1000 caracteres
            </Text>
          )}
        </Flex>
        <Flex direction="column" gap="4">
          <Flex align="center" gap="8">
            <Spacer />
            <ConceptTaxes
              iva={concept.iva}
              retainedIva={concept.retainedIva}
              ieps={concept.ieps}
              isr={concept.isr}
            />
            <Text color="gray900" truncate>
              {toCurrency(+concept.price * +concept.quantity)} MXN
            </Text>
            <GroupButton
              buttons={[
                {
                  id: '1',
                  rightIcon: <Pencil2OutlineIcon />,
                  onClick: () => setShowCollapsible(true),
                },
                {
                  id: '2',
                  rightIcon: <TrashCanOutlineIcon />,
                  onClick: () => remove(),
                },
              ]}
            />
          </Flex>
          <Flex align="center" gap="8">
            <Spacer />

            <Text color="gray900" xs>
              {toCurrency(+concept.price)} MXN por unidad
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Collapse isOpen={showCollapsible}>
        <Flex
          gap="8"
          direction="column"
          css={{
            zIndex: 1200,
            position: 'relative',
            backgroundColor: 'white',
            padding: '10px',
          }}
        >
          <Flex css={rowCss}>
            <FormikUserProductsSelect
              newDesign
              name={`concepts[${index}].product`}
              label="Producto o servicios"
              onChange={(option) => {
                invoice.setConceptProduct(option, index);
              }}
            />
            <FormikKontaSelect
              name={`concepts[${index}].taxableCode`}
              label="Objeto de impuesto"
              onChange={(value) => {
                invoice.setConceptTaxableCode(value, index);
              }}
              options={TAXABLE_CODE_OPTIONS}
              disabled={!concept.product}
            />
          </Flex>
          <Flex css={rowCss}>
            <FormikTextInput
              name={`concepts[${index}].quantity`}
              label="Cantidad"
              type="number"
              disabled={!concept.product}
              onChange={({ target }) =>
                invoice.setConceptQuantity(target.value, index)
              }
            />
            <FormikKontaSelect
              name={`concepts[${index}].unit`}
              label="Unidad"
              options={UNIT_TYPE_OPTIONS}
              disabled={!concept.product}
            />
          </Flex>
          <Flex css={rowCss}>
            <FormikTextInput
              name={`concepts[${index}].price`}
              label="Precio unitario"
              type="number"
              disabled={!concept.product}
              onChange={({ target }) =>
                invoice.setConceptPrice(target.value, index)
              }
            />
            <FormikTextInput
              name={`concepts[${index}].discount`}
              label="Descuento fijo"
              type="number"
              min={0}
              placeholder="Ej. $200.00"
              disabled={!concept.product}
              step={0.01}
              onChange={({ target }) => {
                if (target?.value < 0) {
                  return;
                }
                invoice.setConceptDiscount(target.value, index);
              }}
            />
          </Flex>
          <Flex justify="end" css={{ marginTop: '$12' }} gap={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => collapse()}
            >
              Guardar y cerrar
            </Button>
          </Flex>
        </Flex>
      </Collapse>
      <Backdrop visible={showCollapsible} />
    </Flex>
  );
}
