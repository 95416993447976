import { Chip } from '@konta/ui';
import { ComponentProps } from 'react';
import { KontaScoreStatus } from 'types/entities';

export type ChipColorType = ComponentProps<typeof Chip>['color'];

export const kontaStatusChip: Record<KontaScoreStatus, ChipColorType> = {
  Excelente: 'success600',
  'Muy Buena': 'success500',
  Buena: 'success400',
  Regular: 'warning500',
  Mala: 'error500',
  'Muy Mala': 'error600',
  'No disponible': 'gray500',
};
