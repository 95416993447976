import { useCallback, useEffect, useRef } from 'react';
import { useKey } from 'rooks';

interface UseOutsideClickProps {
  open: boolean;
  toggleOpen: () => void;
}
export default function useOutsideClick({
  open,
  toggleOpen,
}: UseOutsideClickProps) {
  const refs = {
    spanRef: useRef<HTMLSpanElement>(null),
    cardContainerRef: useRef<HTMLDivElement>(null),
  };

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (refs.cardContainerRef.current?.contains(target)) return;
      if (refs.spanRef.current?.contains(target)) return;
      toggleOpen();
    },
    [refs.cardContainerRef, refs.spanRef, toggleOpen]
  );

  useKey(['Escape'], () => {
    if (open) {
      toggleOpen();
    }
  });

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [open, handleOutsideClick]);

  return refs;
}
