import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import {
  PAYMENT_METHOD_OPTIONS,
  UNIT_TYPE_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  USE_CFDI_OPTIONS,
  DEFAULT_CFDI_OPTION,
  DEFAULT_PAYMENT_METHOD_OPTION,
} from '@constants/invoicing';
import { addressUpdate } from '@redux/actions';
import dayjs from 'dayjs';
import regimeToOption from '@util/regimeToOption';
import invoiceFormToPayload from '@util/invoiceFormToPayload';
import getServerDate from '@util/getServerDate';
import getErrorMessage from '@util/getErrorMessage';
import calculateInvoiceConcept from '@util/calculateInvoiceConcept';
import createInvoiceConcept from '@util/createInvoiceConcept';
import calculateInvoice from '@util/calculateInvoice';
import getInitialMonthByPeriodicity from '@util/getInitialMonthByPeriodicity';
import { NotificationManager } from '@components';
import isGenericRfc from '@util/isGenericRfc';
import isGeneralPublicRfc from '@util/isGeneralPublicRfc';
import isForeignRfc from '@util/isForeignRfc';
import handleNewError from '@redux/errors/actions';
import toFixedString from '@util/toFixedString';
import { useDebounceFn } from 'rooks';
import putInvoiceDraft from '@api/putInvoiceDraft';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import submitInvoice from '@api/submitInvoice';
import api from '@api';
import useInvoiceDraftPayload from './useInvoiceDraftPayload';
import { INVOICE_DRAFT } from '../@constants/reactQueries';

export const conceptSchema = yup.object().shape({
  product: yup.object().nullable().required('Campo requerido'),
  taxableCode: yup.object().nullable().required('Campo requerido'),
  quantity: yup
    .number()
    .typeError('Invalido')
    .required('Requerido')
    .test('decimalPlaces', 'Máximo 6 decimales', (number) =>
      /^\d+(\.\d{1,6})?$/.test(number),
    )
    .moreThan(0, 'Debe ser mayor a cero'),

  unit: yup.object().nullable().required('Requerido'),
  price: yup
    .number()
    .typeError('Invalido')
    .required('Campo requerido')
    .moreThan(0, 'Debe ser mayor a cero'),
  discount: yup
    .number()
    .typeError('Invalido')
    .moreThan(-1, 'Debe ser mayor o igual a cero')
    .test(
      'lessThan',
      'El descuento debe de ser mayor que el precio del producto',
      (discount, context) => {
        const { value } = context.from[0];
        const total = (value?.quantity || 0) * (value?.price || 0);
        return discount ? discount < total : true;
      },
    ),
});

export const validationSchema = yup
  .object()
  .nullable()
  .shape({
    legalName: yup.string().nullable().required('Campo requerido'),
    fiscalRegime: yup.object().nullable().required('Campo requerido'),
    invoiceType: yup.object().nullable().required('Campo requerido'),
    paymentType: yup
      .object()
      .nullable()
      .required('Campo requerido')
      .test(
        'paymentType',
        'No se puede seleccionar Por definir cuando es tipo PUE',
        function (value) {
          const paymentMethod = this.parent.paymentMethod?.value;
          const paymentTypeValue = value?.value;
          if (paymentMethod && value) {
            return !(paymentMethod === 'PUE' && paymentTypeValue === '99');
          }
          return true;
        },
      ),
    paymentMethod: yup.object().nullable().required('Campo requerido'),
    client: yup.object().nullable().required('Campo requerido'),
    clientRegime: yup.object().nullable().required('Campo requerido'),
    cfdiUsage: yup.object().nullable().required('Campo requerido'),
    exportCode: yup.object().nullable().required('Campo requerido'),
    postcode: yup
      .string()
      .nullable()
      .typeError('Tipo invalido, consulte con soporte')
      .test(
        'format',
        'Formato invalido (deben ser 5 números)',
        (value) => !!value && /^\d{5}$/.test(value),
      ),
    currency: yup.object().nullable().required('Campo requerido'),
    createdDate: yup.string().test({
      test: async (value) => {
        if (!value) return true;
        const serverDate = await getServerDate();
        const nowDate = serverDate ? dayjs(serverDate) : dayjs();
        const endDate = dayjs(nowDate).subtract(72, 'hour');

        return dayjs(value).isBetween(endDate, nowDate);
      },
      message: 'Máximo 72 horas de rango',
    }),
    periodicity: yup
      .object()
      .nullable()
      .when(['client.value.rfc', 'invoiceType'], {
        is: (rfc, invoiceType) =>
          isGeneralPublicRfc(rfc) && invoiceType.value !== 'E',
        then: yup.object().nullable().required('Campo requerido'),
      }),
    periodicityMonth: yup
      .object()
      .nullable()
      .when(['client.value.rfc', 'invoiceType'], {
        is: (rfc, invoiceType) =>
          isGeneralPublicRfc(rfc) && invoiceType.value !== 'E',
        then: yup.object().nullable().required('Campo requerido'),
      }),
    periodicityYear: yup.string().when(['client.value.rfc', 'invoiceType'], {
      is: (rfc, invoiceType) =>
        isGeneralPublicRfc(rfc) && invoiceType.value !== 'E',
      then: yup.string().required('Campo requerido'),
    }),
    taxResidency: yup
      .object()
      .nullable()
      .when('client.value.rfc', {
        is: isForeignRfc,
        then: yup.object().nullable().required('Campo requerido'),
      }),
    taxIdentityRegistrationNumber: yup.string().when('client.value.rfc', {
      is: isForeignRfc,
      then: yup.string().required('Campo requerido'),
    }),
    exchangeRate: yup.number().when('currency.value', {
      is: 'MXN', // See it in CURRENCY_OPTIONS
      otherwise: yup
        .number()
        .typeError('Valor invalido')
        .required('Campo requerido'),
    }),
    creditNoteInvoice: yup
      .object()
      .nullable()
      .when('invoiceType.value', {
        is: 'E',
        then: yup.object().nullable().required('Campo requerido'),
      }),
    concepts: yup
      .array()
      .of(conceptSchema)
      .min(1, 'Es necesario agregar al menos un concepto'),
  });

export default function useInvoiceForm({ draftId, onSubmitForm, onSuccess }) {
  const [templateInitialValues, setTemplateInitialValues] = useState(null);

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const serverDate = useQuery('server-date', {
    queryFn: getServerDate,
    refetchInterval: 60 * 1000,
    staleTime: 60 * 1000,
  });

  const userHasPostcode = useSelector((state) => {
    return !!state.addresses.address?.postcode;
  });
  const taxableEntity = useSelector((state) => {
    return state.taxableEntity.taxable_entity;
  });

  const submitInvoiceMutation = useMutation(submitInvoice, {
    onSuccess: (response) => {
      onSuccess(response);
    },
    onError: (error) => {
      dispatch(handleNewError(error.response));
      const errorMessage = getErrorMessage(error);
      NotificationManager.error(errorMessage, 'Upss...');
    },
  });

  const { initialValues, invoiceDraft, invoiceDraftLoading } =
    useInvoiceDraftPayload(draftId, templateInitialValues);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      const serverDateResponse = await serverDate.refetch();
      const payload = invoiceFormToPayload({
        values,
        taxableEntity,
        serverDate: serverDateResponse?.data || serverDate.data,
      });

      if (onSubmitForm) {
        await onSubmitForm({ values, payload });
      }
    },
  });

  const clientRfc = formik.values.client?.value?.rfc;
  const isPublicRfc = isGenericRfc(clientRfc); // -> Validate foreign and national RFC
  const generalPublicRfc = isGeneralPublicRfc(clientRfc);
  const foreignRfc = isForeignRfc(clientRfc);

  const [debouncedRefetch] = useDebounceFn(async (values) => {
    if (!draftId) return;
    if (_.isEqual(initialValues, values)) return;
    const invoiceDraftResponse = await putInvoiceDraft(draftId, {
      stamp: invoiceFormToPayload({
        serverDate: serverDate.data,
        taxableEntity,
        values,
      }),
      formikValues: values,
    });
    if (!invoiceDraftResponse) return;
    queryClient.setQueryData(
      [INVOICE_DRAFT, draftId, undefined],
      () => invoiceDraftResponse,
    );
  }, 500);

  useEffect(() => {
    debouncedRefetch(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const setClient = (option) => {
    const client = option?.value;

    const genericRfc = isGenericRfc(client?.rfc);
    const foreignGenericRfc = isForeignRfc(client?.rfc);
    const regime = client?.fiscal_regimes?.[0];
    const clientCfdiUsage = client?.cfdi_use?.key;
    const clientRegime = regime ? regimeToOption(regime) : null;
    const clientPaymentForm = client?.payment_form?.key;
    const paymentType = PAYMENT_TYPE_OPTIONS.find(
      (item) => item.value === clientPaymentForm,
    );
    let cfdiUsage;
    if (clientCfdiUsage) {
      cfdiUsage = USE_CFDI_OPTIONS.find((item) => {
        return item.value === clientCfdiUsage;
      });
    } else if (formik.values.invoiceType.value === 'E' && !genericRfc) {
      cfdiUsage = USE_CFDI_OPTIONS.find((item) => item.value === 'G02');
    } else if (genericRfc) {
      cfdiUsage = USE_CFDI_OPTIONS.find((item) => item.value === 'S01');
    } else {
      cfdiUsage = DEFAULT_CFDI_OPTION;
    }

    const taxIdentityRegistrationNumber = foreignGenericRfc
      ? formik.values.taxIdentityRegistrationNumber
      : '';
    const taxResidency = foreignGenericRfc ? formik.values.taxResidency : null;

    formik.setValues({
      ...formik.values,
      cfdiUsage,
      client: option,
      clientRegime,
      creditNoteInvoice: null,
      taxIdentityRegistrationNumber,
      taxResidency,
      paymentType,
    });
  };

  const updateInvoice = () => {
    const { concepts } = formik.values;

    const { subtotal, discount, total, iva, retainedIva, ieps, isr } =
      calculateInvoice({ concepts });

    formik.setValues({
      ...formik.values,
      subtotal,
      discount,
      total,
      iva,
      retainedIva,
      ieps,
      isr,
    });
  };

  const updateConcept = (index) => {
    const updatedConcept = calculateInvoiceConcept(
      formik.values.concepts[index],
    );

    formik.values.concepts[index] = updatedConcept;

    updateInvoice();
  };

  const setConceptProduct = async (productOption, index) => {
    if (productOption) {
      const concept = formik.values.concepts[index];
      const product = productOption.value;

      concept.price = toFixedString(+product.price || 0);
      concept.quantity = 1;
      concept.discount = 0;
      concept.unit = UNIT_TYPE_OPTIONS.find((x) => x.id === product.unit_id);
      concept.product = productOption;
    } else {
      // clear concept
      formik.values.concepts[index] = createInvoiceConcept();
    }

    updateConcept(index);
  };

  const setConceptQuantity = (value, index) => {
    const concept = formik.values.concepts[index];

    concept.quantity = value;

    updateConcept(index);
  };

  const setConceptPrice = (value, index) => {
    const concept = formik.values.concepts[index];

    concept.price = value;

    updateConcept(index);
  };

  const setConceptDiscount = (value, index) => {
    const concept = formik.values.concepts[index];

    concept.discount = value;

    updateConcept(index);
  };

  const setConceptTaxableCode = (value, index) => {
    const concept = formik.values.concepts[index];

    concept.taxableCode = value;

    updateConcept(index);
  };

  const removeConcept = (index) => {
    const { concepts } = formik.values;
    concepts.splice(index, 1);
    formik.setValues({ concepts });
    updateInvoice();
  };

  const addConcept = () => {
    const { concepts } = formik.values;
    concepts.push(createInvoiceConcept());

    formik.setFieldValue('concepts', concepts);
  };

  // limpiar este campo cuando se cambie el tipo de factura
  const setCreditNoteInvoice = (creditNoteInvoice) => {
    const invoice = creditNoteInvoice.value;
    let cfdiUsage;

    const paymentType =
      invoice.payment_form_id &&
      PAYMENT_TYPE_OPTIONS.find((item) => {
        return item.key === invoice.payment_form_id;
      });

    const paymentMethod = PAYMENT_METHOD_OPTIONS.find((item) => {
      return invoice.payment_method && item.value === invoice.payment_method;
    });

    if (isPublicRfc) {
      cfdiUsage = USE_CFDI_OPTIONS.find((item) => item.value === 'S01');
    } else {
      cfdiUsage = USE_CFDI_OPTIONS.find((item) => {
        return item.key === invoice.cfdi_use_id;
      });
    }

    formik.setValues({
      ...formik.values,
      paymentType,
      paymentMethod,
      cfdiUsage,
      creditNoteInvoice,
    });
  };

  const setInvoiceType = (selection) => {
    const formValues = {
      ...formik.values,
      invoiceType: selection,
      creditNoteInvoice: null,
    };
    // Egreso
    if (selection?.value === 'E' && !isPublicRfc) {
      formValues.cfdiUsage = USE_CFDI_OPTIONS.find(
        (item) => item.value === 'G02',
      );
    } else if (isPublicRfc) {
      formValues.cfdiUsage = USE_CFDI_OPTIONS.find(
        (item) => item.value === 'S01',
      );
    } else {
      formValues.cfdiUsage = DEFAULT_CFDI_OPTION;
    }
    if (selection.value === 'I') {
      formValues.paymentMethod = DEFAULT_PAYMENT_METHOD_OPTION;
    }

    formik.setValues(formValues);
  };

  const onFailure = (error) => {
    const errorMessage = getErrorMessage(error);
    NotificationManager.error(errorMessage, 'Upss...');
  };

  // old way to stamp invoices
  const oldStamp = async (payload) => {
    try {
      const authorization = localStorage.getItem('user_id');
      const response = await api.post(
        '/cfdis/stamp',
        { receipt: payload },
        {
          headers: {
            authorization,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!userHasPostcode) {
        dispatch(
          addressUpdate({ postcode: formik.values.postcode }, { silent: true }),
        );
      }

      onSuccess(response.data);
    } catch (error) {
      dispatch(handleNewError(error.response));
      onFailure(error);
    }
  };

  // new way to stamp invoices
  const stamp = async ({ payload, email, phone }) => {
    submitInvoiceMutation.mutate({
      draftId,
      payload,
      phone,
      email,
      // templateName: 'default',
    });
  };

  const setPeriodicity = (periodicity) => {
    formik.setValues({
      ...formik.values,
      periodicity,
      periodicityMonth: getInitialMonthByPeriodicity(periodicity?.value),
    });
  };

  const setInvoicePaymentMethod = (selection) => {
    const formValues = {
      ...formik.values,
      paymentMethod: selection,
    };
    if (selection.value === 'PPD') {
      formValues.paymentType = PAYMENT_TYPE_OPTIONS.find(
        ({ value }) => value === '99',
      );
    }
    formik.setValues(formValues);
  };

  const setCurrency = (currency) => {
    const formValues = {
      ...formik.values,
      currency,
    };
    if (currency?.value === 'MXN') {
      formValues.exchangeRate = '';
    }
    formik.setValues(formValues);
  };

  const isPPD = formik.values.paymentMethod.value === 'PPD';
  const isForeignClient = isForeignRfc(formik.values.client?.value?.rfc);

  const setInvoiceTemplate = (newTemplate) => {
    setTemplateInitialValues(newTemplate.value);
  };

  return {
    userHasPostcode,
    genericRfc: generalPublicRfc || foreignRfc,
    generalPublicRfc,
    foreignRfc,
    formik,
    stamp,
    oldStamp,
    addConcept,
    removeConcept,
    setConceptProduct,
    setConceptQuantity,
    setConceptDiscount,
    setConceptPrice,
    setConceptTaxableCode,
    setCreditNoteInvoice,
    setInvoiceType,
    setClient,
    setPeriodicity,
    setInvoicePaymentMethod,
    setCurrency,
    taxableEntity,
    isPPD,
    draft: invoiceDraft,
    updatingDraft: invoiceDraftLoading,
    isSubmitting: submitInvoiceMutation.isLoading || invoiceDraftLoading,
    isForeignClient,
    setInvoiceTemplate,
    templateInitialValues,
  };
}

/**
 * @typedef { ReturnType<typeof createInvoiceConcept> } InvoiceConcept
 */

/**
 * @typedef { ReturnType<typeof useInvoiceForm> } UseInvoice
 */
