import { useState } from 'react';
import { Flex, Tab, Tabs } from '@konta/ui';
import UserSubscription from '../UserSubscription/UserSubscription';

export default function Subscriptions() {
  const [tab, setTab] = useState(0);
  return (
    <Flex column gap={8}>
      <Flex>
        <Tabs
          size="s"
          value={tab}
          onChange={setTab}
          variant="text"
          indicator="bottom"
          css={{ button: { pt: 0, pb: '$4' } }}
        >
          <Tab id={0}>Periodo actual</Tab>
          <Tab id={1}>Periodo siguiente</Tab>
        </Tabs>
      </Flex>
      <Flex>
        {tab === 0 && (
          <UserSubscription serviceSubscriptionType="currentServiceSubscription" />
        )}
        {tab === 1 && (
          <UserSubscription serviceSubscriptionType="nextServiceSubscription" />
        )}
      </Flex>
    </Flex>
  );
}
