// eslint-disable-next-line import/prefer-default-export
export const countryOptions = [
  { label: 'México', value: 'México' },
  { label: 'Estados Unidos', value: 'Estados Unidos' },
  { label: 'Canadá', value: 'Canadá' },
  { label: 'Brasil', value: 'Brasil' },
  { label: 'Argentina', value: 'Argentina' },
  { label: 'España', value: 'España' },
  { label: 'Francia', value: 'Francia' },
  { label: 'Alemania', value: 'Alemania' },
  { label: 'Italia', value: 'Italia' },
  { label: 'Japón', value: 'Japón' },
  { label: 'China', value: 'China' },
  { label: 'India', value: 'India' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Reino Unido', value: 'Reino Unido' },
  { label: 'Rusia', value: 'Rusia' },
  { label: 'Sudáfrica', value: 'Sudáfrica' },
  { label: 'Corea del Sur', value: 'Corea del Sur' },
  { label: 'Marruecos', value: 'Marruecos' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Chile', value: 'Chile' },
  { label: 'Egipto', value: 'Egipto' },
  { label: 'Turquía', value: 'Turquía' },
  { label: 'Nigeria', value: 'Nigeria' },
  { label: 'Arabia Saudita', value: 'Arabia Saudita' },
  { label: 'Vietnam', value: 'Vietnam' },
  { label: 'Filipinas', value: 'Filipinas' },
  { label: 'Indonesia', value: 'Indonesia' },
  { label: 'Perú', value: 'Perú' },
  { label: 'Noruega', value: 'Noruega' },
];
