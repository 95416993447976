import { memo } from 'react';
import { Button, Flex } from '@konta/ui';
import { Download02Icon } from '@konta/icons';
import Loader from 'shared/components/Loader';
import PreviewDocument from '@components/PreviewDocument/PreviewDocument';
import useCfdiPreviewPdf from './useCfdiPreviewPdf';
import { buttonsCss, containerCss, rootCss } from './styled';

interface CfdiPreviewPdfProps {
  cfdiId?: string | number;
  accessToken?: string;
}
function CfdiPreviewPdf({ cfdiId, accessToken }: CfdiPreviewPdfProps) {
  const {
    cfdiPdf,
    cfdiPdfLoading,
    handleOnClickDownloadPdf,
    handleOnClickDownloadXml,
    downloadXmlLoading,
  } = useCfdiPreviewPdf({
    cfdiId,
    accessToken,
  });

  return (
    <Flex css={rootCss}>
      <Flex css={containerCss}>
        {cfdiPdfLoading ? (
          <Loader />
        ) : (
          <>
            <Flex gap={8}>
              <Button
                color="primary600"
                variant="contained"
                css={buttonsCss}
                onClick={handleOnClickDownloadPdf}
                leftIcon={<Download02Icon />}
                loading={cfdiPdfLoading}
              >
                Descargar PDF
              </Button>
              <Button
                color="primary600"
                leftIcon={<Download02Icon />}
                variant="contained"
                loading={downloadXmlLoading}
                onClick={handleOnClickDownloadXml}
                css={buttonsCss}
              >
                Descargar XML
              </Button>
            </Flex>
            <PreviewDocument maxWidth={800} defaultFile={cfdiPdf} />
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default memo(CfdiPreviewPdf);
