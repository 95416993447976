import React, { useState } from 'react';
import { Divider, Flex, Text } from '@konta/ui';
import { ProcessType } from 'types/entities';
import useProcess from '@hooks/useProcess';
import VideoButton from '@components/VideoButton';
import ProcessStepLayout from '@components/ProcessStepLayout';
import ProcessStepDescription from '@components/ProcessStepDescription';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepHelpFeature from '@components/ProcessStepHelpFeature';

const process = ProcessType.OnboardingOnboardingProcess;

export default function OnboardingCallWaiting() {
  useProcess(process, {
    refetchInterval: 10000,
  });
  const [titleVideo1, setTitleVideo1] = useState('');
  const [titleVideo2, setTitleVideo2] = useState('');
  const [titleVideo3, setTitleVideo3] = useState('');
  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Apertura de cuenta"
      titleChip="En curso"
      content={
        <>
          <Flex direction="column" gap={12} css={{ maxWidth: '670px' }}>
            <ProcessStepTitle>Mientras esperas la llamada...</ProcessStepTitle>
            <ProcessStepDescription>
              Mientras esperas la llamada de tu Konta experto, te sugerimos que
              veas estos videos, ya que te ayudarán a comprender mejor cómo
              funciona la plataforma, te proporcionarán información útil para
              que estés preparado para la llamada con tu konta experto.
            </ProcessStepDescription>
          </Flex>
          <Flex direction="column" gap={12}>
            <Flex direction="column" gap={8}>
              <Flex
                direction="column"
                css={{ maxWidth: '42px', padding: '7px 0px 0px' }}
              >
                <Divider />
              </Flex>
            </Flex>
            <Flex
              justify="between"
              css={{ maxWidth: '680px' }}
              gap={12}
              direction="column"
            >
              <Flex direction="column" gap={10}>
                <Text m lineHeight="m">
                  {titleVideo1}
                </Text>
                <VideoButton
                  withSpacing={false}
                  withLink={false}
                  videoId="MH_PWiU8w-M"
                  onTitleFetched={(title) => setTitleVideo1(title as string)}
                />
              </Flex>
              <Flex direction="column" gap={10}>
                <Text m lineHeight="m">
                  {titleVideo2}
                </Text>
                <VideoButton
                  withSpacing={false}
                  withLink={false}
                  videoId="axlLVh-NB8o"
                  onTitleFetched={(title) => setTitleVideo2(title as string)}
                />
              </Flex>
              <Flex direction="column" gap={10}>
                <Text m lineHeight="m">
                  {titleVideo3}
                </Text>
                <VideoButton
                  withSpacing={false}
                  withLink={false}
                  videoId="iqcnRD-vAPI"
                  onTitleFetched={(title) => setTitleVideo3(title as string)}
                />
              </Flex>
            </Flex>
          </Flex>
        </>
      }
      rightSidebarContent={
        <>
          <ProcessStepHelpFeature
            title="¿Cómo puedo saber a cuál me inscribo?"
            description={
              <>
                <Text
                  as="a"
                  color="primary700"
                  href="https://k4mz6rhhujx.typeform.com/to/JlNuXSoc"
                  target="_blank"
                >
                  Nuestra calculadora
                </Text>{' '}
                de regímenes te puede dar la mejor opción para que puedas pagar
                la menor cantidad de impuestos posibles
              </>
            }
          />
          <ProcessStepHelpFeature
            title="¿Cómo puedo saber mi régimen actual?"
            description={
              <>
                <Text
                  as="a"
                  color="primary700"
                  href="https://konta.com/blog/regimenes-fiscales-para-personas-fisicas-en-mexico"
                  target="_blank"
                >
                  Esta guía
                </Text>{' '}
                te puede ayudar a encontrar tu régimen en el SAT.
              </>
            }
          />
        </>
      }
    />
  );
}
