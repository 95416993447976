import { Chip, config, Flex, Text, useMediaQuery } from '@konta/ui';
import InformationText from 'backoffice/components/InformationText';
import useStore from 'backoffice/store';
import {
  Avatar,
  CollapsedAvatarContainer,
  CollapsedCustomerName,
  CustomerName,
  HeaderContainer,
  HeaderContent,
  Logo,
  LogoContainer,
  MobileLogoContainer,
  SimpleContainer,
} from './styled';
import useCustomer from './useCustomer';
import CustomerHeaderActions from './CustomerHeaderActions';

export default function CustomerHeader() {
  const {
    taxableEntity,
    rfc,
    taxableEntityId,
    name,
    legalName,
    fiscalRegimes,
    taxableEntityDetails,
    userDetails,
    settingsDetails,
    onboardingCallNotes,
    mainChipBadges,
    collapsed,
  } = useCustomer();
  const isTabletOrMobile = useMediaQuery(config.media['<md']);
  const isMobile = useMediaQuery(config.media['<sm']);

  return (
    <HeaderContainer>
      {collapsed ? (
        <Flex gap={16} itemsCenter>
          <CollapsedAvatarContainer>
            {taxableEntity?.logo_url ? (
              <Logo roundedCircle src={taxableEntity.logo_url} />
            ) : (
              <Avatar collapsed maxInitials={2} name={legalName} round />
            )}
          </CollapsedAvatarContainer>
          <Flex gap={8} itemsCenter>
            <CollapsedCustomerName>
              {taxableEntityId} - {legalName}
            </CollapsedCustomerName>
            <CollapsedCustomerName regular color="gray500">
              {rfc}
            </CollapsedCustomerName>
          </Flex>
        </Flex>
      ) : (
        <Flex gap={20} css={{ flex: 1 }} column>
          <Flex gap={20} css={{ flex: 1 }}>
            <LogoContainer>
              {taxableEntity?.logo_url ? (
                <Logo roundedCircle src={taxableEntity.logo_url} />
              ) : (
                <Avatar maxInitials={2} name={legalName} round />
              )}
            </LogoContainer>
            <HeaderContent column gap={4}>
              <Flex justify="between" itemsCenter gap={10}>
                <Flex itemsCenter gap={4}>
                  {isMobile && (
                    <MobileLogoContainer>
                      {taxableEntity?.logo_url ? (
                        <Logo roundedCircle src={taxableEntity.logo_url} />
                      ) : (
                        <Avatar
                          collapsed
                          maxInitials={2}
                          name={legalName}
                          round
                        />
                      )}
                    </MobileLogoContainer>
                  )}
                  <CustomerName>
                    {taxableEntityId} - {legalName}
                  </CustomerName>
                </Flex>
                {!isTabletOrMobile && (
                  <CustomerHeaderActions taxableEntityId={taxableEntityId} />
                )}
              </Flex>
              <Text medium>{taxableEntity?.rfc}</Text>
              <InformationText
                label="Regímenes Fiscales"
                value={fiscalRegimes}
              />
              <InformationText value={name} label="Nombre de contacto" />
            </HeaderContent>
          </Flex>
          <Flex gap={12} css={{ overflow: 'auto', scrollbarWidth: 'thin' }}>
            {mainChipBadges.map(
              ({ value, color }, index) =>
                value && (
                  <Chip key={index} size="s" label={value} color={color} />
                ),
            )}
          </Flex>
          <Flex gap={12}>
            <SimpleContainer
              column
              css={{
                flex: 1,
                width: '100%',
              }}
            >
              <Flex gap={16} column>
                <Text l>Usuario</Text>
                <Flex gap={12} column>
                  {userDetails.map(
                    (detail) =>
                      !detail.hide && (
                        <InformationText
                          value={detail.value}
                          label={detail.title}
                          css={{
                            width: '100%',
                          }}
                        />
                      ),
                  )}
                </Flex>
              </Flex>
            </SimpleContainer>
            <SimpleContainer
              column
              css={{
                flex: 1,
                width: '100%',
              }}
            >
              <Flex gap={16} column>
                <Text l>Empresa/Negocio</Text>
                <Flex gap={12} column>
                  {taxableEntityDetails.map(
                    (detail) =>
                      !detail.hide && (
                        <InformationText
                          value={detail.value}
                          label={detail.title}
                          css={{
                            width: '100%',
                          }}
                        />
                      ),
                  )}
                </Flex>
              </Flex>
            </SimpleContainer>
          </Flex>
          <Flex gap={12}>
            <SimpleContainer
              column
              css={{
                flex: 1,
                width: '100%',
              }}
            >
              <Flex gap={16} column>
                <Text l>Notas</Text>
                <Flex gap={12} column>
                  {onboardingCallNotes}
                </Flex>
              </Flex>
            </SimpleContainer>
            <SimpleContainer
              column
              css={{
                flex: 1,
                width: '100%',
              }}
            >
              <Flex gap={16} column>
                <Text l>Configuración de plataforma</Text>
                <Flex gap={12} column>
                  {settingsDetails.map(
                    (detail) =>
                      !detail.hide && (
                        <InformationText
                          value={detail.value}
                          label={detail.title}
                          css={{
                            width: '100%',
                          }}
                        />
                      ),
                  )}
                </Flex>
              </Flex>
            </SimpleContainer>
          </Flex>
        </Flex>
      )}
      {(collapsed || isTabletOrMobile) && (
        <CustomerHeaderActions taxableEntityId={taxableEntityId} />
      )}
    </HeaderContainer>
  );
}
