import React from 'react';
import InvoiceForm from '@components/InvoiceForm';

export default function NewInvoice() {
  return (
    <div className="app-container">
      <InvoiceForm />
    </div>
  );
}
