import React from 'react';
import { Button } from '@konta/ui';
import { AnnouncementIcon } from '@konta/icons';

export default function ChangelogWidgetButton() {
  return (
    <>
      <Button
        icon
        variant="outlined"
        data-canny-changelog
        css={{
          svg: { width: '16px', height: '16px', margin: '2px' },
          path: { stroke: 'rgb(107, 111, 118)' },
        }}
        noFill
      >
        <AnnouncementIcon className="button-icon" />
      </Button>
    </>
  );
}
