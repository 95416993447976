import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import chatApp from './chat/reducer';
import pulse from './pulse/reducer';
import reports from './reports/reducer';
import documents from './documents/reducer';
import taxableEntity from './taxable_entity/reducer';
import paymentMethods from './payment_methods/reducer';
import fiscalPeriods from './fiscal_periods/reducer';
import addresses from './address/reducer';
import subscriptions from './subscriptions/reducer';
import financialTransactions from './financial_transactions/reducer';
import products from './products/reducer';
import clientsSuppliers from './clients_suppliers/reducer';
import cfdis from './cfdi/reducer';
import orders from './orders/reducer';
import checkout from './checkout/reducer';
import contact_methods from './contact_methods/reducer';
import notification_preferences from './notification_preferences/reducer';
import fiscal_regimens from './fiscal_regimens/reducer';
import workflows from './workflows/reducer';
import declaration_entries from './declaration_entries/reducer';
import belvo from './belvo/reducer';
import bank_transactions_declarations from './bank_transactions_declarations/reducer';
import errors from './errors/reducer';

const appReducers = combineReducers({
  menu,
  settings,
  authUser,
  chatApp,
  pulse,
  reports,
  documents,
  taxableEntity,
  paymentMethods,
  fiscalPeriods,
  addresses,
  subscriptions,
  financialTransactions,
  products,
  clientsSuppliers,
  cfdis,
  orders,
  checkout,
  contact_methods,
  notification_preferences,
  fiscal_regimens,
  workflows,
  declaration_entries,
  belvo,
  bank_transactions_declarations,
  errors,
});

const rootReducer = (state, action) => {
  if (action.type === 'STATE_RESET') {
    state = undefined;
  }
  return appReducers(state, action);
};

export default rootReducer;
