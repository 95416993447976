import { useCallback, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  subscribe,
  subcriptionCancel,
  subcriptionSelect,
  subscriptionPrices,
  subscriptionDiscount,
  fetchSubscriptions,
} from '@redux/actions';
import { useLocation } from 'react-router-dom';
import usePendingTasksStore from 'store/pendingTasksStore';
import { NotificationManager } from '@components/Notifications';
import getCoupon from '@api/getCoupon';
import dayjs from 'dayjs';
import { BILLING_PERIOD_QUANTITY } from '@constants/subscription';
import subscriptionService from 'shared/services/subscription';
import toFixedString from '@util/toFixedString';
import { useTaxableEntity } from './taxable_entity';
import useGetTaxableEntityCouponsQuery from './useGetTaxableEntityCouponsQuery';

const { BASE_PRICES } = subscriptionService.constants;

export default function useSubscriptions() {
  const { selectedTask } = usePendingTasksStore();
  const dispatch = useDispatch();
  const { removePendingTask } = usePendingTasksStore();
  const location = useLocation();
  const prevPathRef = useRef(location.pathname);

  const subscriptions = useSelector(
    (state) => state.subscriptions,
    shallowEqual,
  );

  const { taxable_entity } = useTaxableEntity();
  const { lastCoupon } = useGetTaxableEntityCouponsQuery(taxable_entity.id);

  const subscription = useMemo(
    () => subscriptions.subscriptions[0],
    [subscriptions.subscriptions],
  );

  const {
    ui: { selected_card },
  } = useSelector((state) => state.paymentMethods, shallowEqual);

  useEffect(() => {
    if (subscriptions?.subscriptions?.length < 1) {
      dispatch(fetchSubscriptions());
    }
  }, [dispatch, subscriptions.subscriptions.length]);

  useEffect(() => {
    if (prevPathRef.current !== location.pathname) {
      dispatch(subscriptionPrices(true));
      prevPathRef.current = location.pathname;
    }
  }, [location.pathname, dispatch]);

  const handleSelect = (
    _event,
    _subscription,
    employeeCounter,
    receiptCounter,
  ) => {
    dispatch(subscriptionDiscount(0, ''));
    dispatch(subcriptionSelect(_subscription, employeeCounter, receiptCounter));
    dispatch(subscriptionPrices(false));
  };

  const handlePlanSelect = (planId, plans, billingPeriod) => {
    const selectedPlan = plans.find((p) => p.id === planId);
    if (!selectedPlan) {
      NotificationManager.error(
        'Selecciona un plan válido',
        'Error al seleccionar plan',
      );
      return;
    }
    const extraData = {
      assisted_plan: {
        extra: 0,
        extraReceipt: 0,
        serviceDb: 'assisted_plan',
        serviceId: 20,
        employeeServiceId: 21,
        title: 'Plan asistido',
        service: 'Asistido',
        basePrice: BASE_PRICES.assisted_plan,
      },
      personalized_plan: {
        extra: 0,
        extraReceipt: 0,
        serviceDb: 'personalized_plan',
        serviceId: 22,
        employeeServiceId: 21,
        title: 'Plan personalizado',
        service: 'Personalizado',
        basePrice: BASE_PRICES.personalized_plan,
      },
      fiscal_visor_plan: {
        basePrice: BASE_PRICES.fiscal_visor_plan,
        extra: 0,
        extraReceipt: 0,
        serviceDb: 'fiscal_visor_plan',
        serviceId: 24,
        employeeServiceId: 21,
        title: 'Visor Fiscal',
        service: 'Visor Fiscal',
      },
    };
    const selectedExtraData = extraData[planId];
    const selectedPlanData = {
      price: selectedPlan.pricing.price,
      basePrice: selectedExtraData.basePrice,
      extra: selectedExtraData.extra,
      extraReceipt: selectedExtraData.extraReceipt,
      service_id: selectedExtraData.serviceId,
      employee_service_id: selectedExtraData.employeeServiceId,
      service_db: selectedExtraData.serviceDb,
      title: selectedExtraData.title,
      service: selectedExtraData.service,
      billingPeriod,
      durationMoths: BILLING_PERIOD_QUANTITY[selectedPlan.billingPeriod],
    };
    handleSelect(null, selectedPlanData, 0, 30);
  };

  // TODO: rename to applyDiscount
  const handleDiscount = useCallback(
    async (_event, code) => {
      if (!code) {
        return;
      }
      const lowerCaseCoupon = code.toLowerCase();
      try {
        const coupon = await getCoupon(lowerCaseCoupon);
        if (!coupon.active) {
          dispatch(subscriptionDiscount(0, ''));
          NotificationManager.error(
            'El código ingresado no está activo',
            'Descuento no válido',
            6000,
            null,
            null,
          );
          return;
        }
        dispatch(subscriptionDiscount(coupon, lowerCaseCoupon));
        NotificationManager.success(
          'Descuento aplicado',
          'Exito!',
          6000,
          null,
          null,
        );
      } catch {
        dispatch(subscriptionDiscount(0, ''));
        NotificationManager.error(
          'Favor de verificar que el cupón esté bien escrito',
          'Descuento no válido',
          6000,
          null,
          null,
        );
      }
    },
    [dispatch],
  );

  const hidePrices = () => {
    dispatch(subscriptionPrices(true));
  };

  // TODO check if this function is used in other place
  // Submit subscription
  const handlePayment = (subscription_params) => {
    if (selected_card) {
      dispatch(subscribe(subscription_params));
    } else {
      NotificationManager.primary(
        'Selecciona una tarjeta antes de pagar',
        'Upss...',
        6000,
        null,
        null,
      );
    }
  };

  const handleSubscriptionPayment = () => {
    if (!selected_card) {
      NotificationManager.warning(
        'Selecciona una tarjeta antes de pagar',
        'Upss...',
        6000,
        null,
        null,
      );
      return;
    }

    if (!subscriptions.ui?.selected_subscription) {
      NotificationManager.warning(
        'Selecciona un plan antes de pagar',
        'Upss...',
        6000,
        null,
        null,
      );
      return;
    }

    const { subscription: selectedSubscription, discount } =
      subscriptions.ui.selected_subscription;

    const subscriptionParams = {
      subscriptions: [
        {
          service_subscriptions_attributes: [
            {
              service_id: selectedSubscription.service_id,
              quantity: 1,
              duration_months: selectedSubscription.durationMoths,
            },
          ],
          subscription_type: 'contabilidad',
        },
      ],
      payment_way: 'card',
      payment_method_id: selected_card?.id,
      discountCode: discount.code || undefined,
    };

    if (lastCoupon) {
      delete subscriptionParams.discountCode;
    }

    dispatch(
      subscribe(subscriptionParams, () => {
        removePendingTask(selectedTask);
      }),
    );
  };

  const handleCancel = (subscription_id, reasons) => {
    dispatch(subcriptionCancel(subscription_id, reasons));
  };

  const taxableEntityCoupon = useMemo(() => {
    if (!subscription?.taxable_entity_coupon) {
      return {};
    }
    return subscription.taxable_entity_coupon;
  }, [subscription?.taxable_entity_coupon]);

  const firstSubscription = useMemo(
    () => _.first(subscriptions.subscriptions),
    [subscriptions.subscriptions],
  );
  const isQuotationSubscription = subscription?.status === 'incomplete';

  const getServiceSubscriptionByType = useCallback(
    (type) => {
      const serviceSubscriptions = firstSubscription?.[type];
      if (!serviceSubscriptions || serviceSubscriptions?.length === 0) {
        return subscriptionService.constants.defaultServiceSubscription;
      }

      // Base service
      const serviceSubscription = serviceSubscriptions.find(
        (service) => service.service.base,
      );

      if (!serviceSubscription) {
        return subscriptionService.constants.defaultServiceSubscription;
      }
      const formatDateTemplate = 'DD [de] MMMM [del] YYYY';

      const dateSubscribedWithFormat = isQuotationSubscription
        ? 'Sin fecha'
        : dayjs(serviceSubscription.date_subscribed).format(formatDateTemplate);
      const dateValidToWithFormat = isQuotationSubscription
        ? 'Sin fecha'
        : dayjs(serviceSubscription.valid_to).format(formatDateTemplate);

      // Extra services
      const extraRegimeService = serviceSubscriptions.find(
        (service) => service.service.id === 21,
      );

      const extraTaxesService = serviceSubscriptions.find(
        (service) => service.service.id === 23,
      );

      const totalOfExtraRegimesPayment = +(extraRegimeService?.total || 0);
      const totalOfExtraTaxesPayment = +(extraTaxesService?.total || 0);

      const extraRegimeQuantity = extraRegimeService?.quantity || 0;
      const hasExtraTaxesService = !!extraTaxesService;

      const totalOfExtraRegimeWithoutIva = +toFixedString(
        +totalOfExtraRegimesPayment / 1.16,
      );
      const totalOfExtraTaxesWithoutIva = +toFixedString(
        +totalOfExtraTaxesPayment / 1.16,
      );

      const totalIvaOfExtraRegime = +toFixedString(
        totalOfExtraRegimeWithoutIva * 0.16,
      );
      const totalIvaOfExtraTaxes = +toFixedString(
        totalOfExtraTaxesWithoutIva * 0.16,
      );

      // Total with extra services and discount
      const totalOfNextPayment = +serviceSubscription.total;
      const totalOfNextPaymentWithoutIva = +toFixedString(
        totalOfNextPayment / 1.16,
      );
      const totalIvaOfNextPayment = totalOfNextPaymentWithoutIva * 0.16;

      const totalPaymentWithExtraServices =
        totalOfNextPayment +
        +totalOfExtraRegimesPayment +
        +totalOfExtraTaxesPayment;

      const subtotalPayment =
        totalOfNextPaymentWithoutIva +
        totalOfExtraRegimeWithoutIva +
        totalOfExtraTaxesWithoutIva;

      const {
        totalWithDiscount: totalOfNextPaymentWithDiscount,
        discountText,
      } = subscriptionService.utils.calculatePlanDiscountAndTotal(
        taxableEntityCoupon?.coupon,
        totalPaymentWithExtraServices,
        serviceSubscription.duration_months,
        serviceSubscription.service.name,
      );
      const nextDiscount =
        totalPaymentWithExtraServices - totalOfNextPaymentWithDiscount;
      const nextPrice = +serviceSubscription.price;
      const nextPriceWithoutIva = +toFixedString(nextPrice / 1.16);

      const regimePrice = extraRegimeService?.service?.price || 0;
      const taxesPrice = extraTaxesService?.service?.price || 0;

      const nextRegimePriceWithoutIva = +toFixedString(regimePrice / 1.16);
      const nextTaxesPriceWithoutIva = +toFixedString(taxesPrice / 1.16);

      const isActive = subscription.status !== 'cancellation_in_progress';

      return {
        id: serviceSubscription.id,
        isActive,
        dateSubscribed: serviceSubscription.date_subscribed,
        nextPrice,
        quantity: serviceSubscription.quantity,
        totalIvaOfTotalNextPaymentWithDiscount: +toFixedString(
          totalOfNextPaymentWithDiscount * 0.16,
        ),
        totalOfNextPayment,
        totalOfNextPaymentWithDiscountWithoutIva: +toFixedString(
          totalOfNextPaymentWithDiscount / 1.16,
        ),
        validTo: serviceSubscription.valid_to,
        nextPlanName:
          subscriptionService.constants.PLAN_NAMES[
            serviceSubscription.service.name
          ],
        durationMonth: serviceSubscription.duration_months,
        billingPeriod:
          subscriptionService.constants.DURATION_TO_BILLING_PERIOD_DICT[
            serviceSubscription.duration_months
          ],
        dateValidToWithFormat,
        dateSubscribedWithFormat,
        totalOfNextPaymentWithoutIva,
        totalOfNextPaymentWithDiscount,
        totalIvaOfNextPayment,
        nextDiscount,
        nextPriceWithoutIva,
        discountText,
        extraRegimeQuantity,
        hasExtraTaxesService,
        totalOfExtraRegimesPayment,
        totalOfExtraTaxesPayment,
        totalOfExtraRegimeWithoutIva,
        totalOfExtraTaxesWithoutIva,
        nextRegimePriceWithoutIva,
        nextTaxesPriceWithoutIva,
        subtotalPayment,
        totalIvaOfExtraRegime,
        totalIvaOfExtraTaxes,
      };
    },
    [isQuotationSubscription, taxableEntityCoupon?.coupon, firstSubscription],
  );

  const hasNotActiveSubscription = useMemo(
    () =>
      subscriptions.subscriptions.every(
        (subscription_) =>
          subscriptions.subscriptions.length === 0 ||
          ['cancelled', 'incomplete'].includes(subscription_.status),
      ),
    [subscriptions],
  );

  return {
    ...subscriptions,
    hidePrices,
    handleSelect,
    handlePayment,
    handleSubscriptionPayment,
    handleCancel,
    handleDiscount,
    nextServiceSubscription: getServiceSubscriptionByType(
      'next_service_subscriptions',
    ),
    currentServiceSubscription: getServiceSubscriptionByType(
      'current_service_subscriptions',
    ),
    getServiceSubscriptionByType,
    handlePlanSelect,
    hasNotActiveSubscription,
  };
}
