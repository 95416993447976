import { useMemo } from 'react';
import _ from 'lodash';
import getRegimeNameFromFiscalRegime from 'shared/util/getRegimeNameFromFiscalRegime';
import type { DeclarationEntry } from 'types/entities';
import useEntriesColumnsTable from './useEntriesColumnsTable';

interface UseEntriesTableProps {
  declarationEntries: DeclarationEntry[];
  accessToken: string;
}
export default function useEntriesTable({
  declarationEntries,
  accessToken,
}: UseEntriesTableProps) {
  const fiscalRegimeOpts = useMemo(
    () =>
      _.uniqBy(declarationEntries, 'fiscal_regime_id').map((entry) => {
        const regimeName = getRegimeNameFromFiscalRegime(entry.fiscal_regime);
        return {
          label: regimeName,
          value: entry.fiscal_regime.sat_key.toString(),
        };
      }),
    [declarationEntries],
  );
  const columns = useEntriesColumnsTable({
    fiscalRegimeOpts,
    accessToken,
  });

  return { columns };
}
