/* eslint-disable import/prefer-default-export */
import React, { useMemo } from 'react';
import { Row, Card, CardBody } from 'reactstrap';
import { CalculatorLineIcon } from '@konta/icons';
import { Colxx, NewFactureCard, TaskNothing } from '@components';
import {
  ProgressCard,
  StatementUploadCard,
  TaskPayment,
  FpFeedback,
} from '@containers';
import OnboardingCard from '@containers/Onboarding/OnboardingCard';
import { useTaxableEntity, useFiscalPeriods } from '@hooks';
// eslint-disable-next-line import/extensions
import CollapseSection from 'shared/components/CollapseSection/CollapseSection';
import { Flex } from '@konta/ui';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';

function TaskActiveStatus(props) {
  const { fiscalPeriods } = props;

  const uniquePendingPeriods = useMemo(() => {
    const pendingPeriods = fiscalPeriods?.filter(({ status }) =>
      ['statement_upload', 'pending_payment'].includes(status),
    );
    return uniqBy(pendingPeriods, 'start_date');
  }, [fiscalPeriods]);

  const hasPendingPeriods = uniquePendingPeriods.length > 0;

  if (hasPendingPeriods) {
    return (
      <Flex
        direction="column"
        gap={18}
        css={{
          padding: '20px 20px',
        }}
      >
        {uniquePendingPeriods.map((period) => {
          return (
            <CollapseSection
              collapsed={uniquePendingPeriods.length === 1}
              btnLeftIcon={<CalculatorLineIcon />}
              btnText={`Ver estado de cuenta de ${dayjs(
                period.start_date,
              ).format('MMMM YYYY')}`}
              key={period.id}
            >
              <Flex
                direction="column"
                gap={10}
                css={{ flex: 1, alignSelf: 'stretch' }}
              >
                {{
                  pending_payment: (
                    <TaskPayment {...props} currentPeriod={period} />
                  ), // 2
                  statement_upload: (
                    <StatementUploadCard currentPeriod={period} />
                  ),
                }[period?.status] || <TaskNothing />}
              </Flex>
            </CollapseSection>
          );
        })}
      </Flex>
    );
  }
  return (
    <CardBody>
      <Flex direction="column">
        <TaskNothing />
      </Flex>
    </CardBody>
  );
}

export function TaskActive(props) {
  const { taxable_entity } = useTaxableEntity();

  const {
    current: fiscal_period,
    loading,
    fiscal_period: fiscalPeriods,
  } = useFiscalPeriods();
  return (
    <>
      {(!taxable_entity.has_fiel ||
        !taxable_entity.has_ciec ||
        !taxable_entity.fiscal_regimes.length > 0 ||
        !taxable_entity.legal_email ||
        !taxable_entity.address) && (
        <Row className="mb-4">
          <Colxx xxs="12" className="mb-6">
            <OnboardingCard />
          </Colxx>
        </Row>
      )}
      <Row className="mb-4">
        <Colxx xxs="12" xs="12" md="5" lg="4">
          <Row className="progress-banner__container mb-4">
            <Colxx xs="12" md="12">
              <ProgressCard fiscal_period={fiscal_period} loading={loading} />
            </Colxx>
            <Colxx xs="12" md="12" className="mb-4">
              <NewFactureCard />
            </Colxx>
          </Row>
        </Colxx>
        <Colxx xxs="12" xs="12" md="7" lg="8">
          <Card className="border-top-2 border-primary h-100">
            <TaskActiveStatus
              currentPeriod={fiscal_period}
              fiscalPeriods={fiscalPeriods}
              {...props}
            />
          </Card>
        </Colxx>
      </Row>
      {fiscal_period?.status === 'paid' && !fiscal_period?.feedback_posted && (
        <Row className="mb-4">
          <Colxx xs="12" md="4">
            <FpFeedback loading={loading} fiscal_period_id={fiscal_period.id} />
          </Colxx>
        </Row>
      )}
    </>
  );
}
