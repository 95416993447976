import { ReactNode, MouseEvent } from 'react';
import { useToggle } from 'rooks';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import { ConfirmModal } from '@components/Modal';
import { useDispatch } from 'react-redux';
import { workflowNextPhase, workflowSetTransition } from '@redux/actions';

interface NextPhaseConfirmationProps {
  trigger: (props: { onClick: () => void }) => ReactNode;
  children: ReactNode;
  payload: Record<string, unknown>;
  title: string;
  acceptText?: string;
  cancelText?: string;
  onAccept?: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
export default function NextPhaseConfirmation({
  trigger,
  children,
  payload,
  acceptText,
  cancelText,
  title,
  onAccept,
}: NextPhaseConfirmationProps) {
  const dispatch = useDispatch();
  const { workflow } = useSelectedWorkflow();
  const [showModal, toggleShowModal] = useToggle();

  const handleAccept = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    toggleShowModal();

    if (onAccept) {
      onAccept(e);
    } else {
      dispatch(workflowSetTransition('phase-front'));
      dispatch(workflowNextPhase({ workflow_id: workflow?.id, body: payload }));
    }
  };

  return (
    <>
      {trigger({ onClick: toggleShowModal })}
      <ConfirmModal
        title={title}
        acceptText={acceptText}
        cancelText={cancelText}
        open={showModal}
        onClose={toggleShowModal}
        onCancel={toggleShowModal}
        onAccept={handleAccept}
      >
        {children}
      </ConfirmModal>
    </>
  );
}
