import type {
  DeclarationPayer,
  DeclarationTransaction,
  DeclarationTransactionType,
} from 'types/entities';

export default function calculateAmountsByTransactionType(
  transactions: (DeclarationPayer | DeclarationTransaction)[],
): Record<DeclarationTransactionType, number> {
  return transactions.reduce(
    (amounts, declarationPayer) => {
      // eslint-disable-next-line no-param-reassign
      amounts[declarationPayer.transaction_type] += +(
        declarationPayer.amount || 0
      );
      return amounts;
    },
    {
      iva: 0,
      isr: 0,
      undue_payment_iva: 0,
      undue_payment_isr: 0,
      isr_tax_loss: 0,
    },
  );
}
