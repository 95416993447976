import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  SUBSCRIBE,
  SUBSCRIBTION_CANCEL,
  PAYMENT_HISTORY_FETCH,
  SUBSCRIPTIONS_FETCH,
} from '@constants/actionTypes';
import subscribeSaga from './post';
import cancelSaga from './cancel';
import fetchPaymentHistorySaga from './payment_history';
import fetchSubscriptionsSaga from './fetch';

function* watchSubscribe() {
  yield takeLatest(SUBSCRIBE, subscribeSaga);
}

function* watchCancel() {
  yield takeLatest(SUBSCRIBTION_CANCEL, cancelSaga);
}

function* watchPaymenHistoryFetch() {
  yield takeLatest(PAYMENT_HISTORY_FETCH, fetchPaymentHistorySaga);
}

function* watchSubscriptionsFetch() {
  yield takeLatest(SUBSCRIPTIONS_FETCH, fetchSubscriptionsSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchSubscribe),
    fork(watchCancel),
    fork(watchPaymenHistoryFetch),
    fork(watchSubscriptionsFetch),
  ]);
}
