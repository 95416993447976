import api from '@api';
import { call, select } from 'redux-saga/effects';
import { StatusHandler, ErrorLayout, ErrorHandler } from '@util/Saga';
import gtmEvent from '@util/gtmEvent';
import getErrorMessage from '@util/getErrorMessage';
import {
  updateTaxableEntitySuccess,
  updateTaxableEntityFailure,
  uploadTaxableEntityFielSuccess,
  uploadTaxableEntityFielFailure,
  uploadTaxableEntityPayrollCredentialsSuccess,
  uploadTaxableEntityPayrollCredentialsFailure,
  uploadTaxableEntityCsdSuccess,
  uploadTaxableEntityCsdFailure,
} from '../actions';

export function* updateTaxableEntityWithAccessToken({
  payload: { taxable_entity, id, options, callback },
}) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `/taxable_entities/${id}`,
      taxable_entity,
      {
        headers: { Authorization: `${access_token}` },
      },
    );
    if (callback) callback();
    yield call(StatusHandler, {
      response,
      options,
      status: response.status,
      success: updateTaxableEntitySuccess,
      failure: updateTaxableEntityFailure,
      title: 'Éxito',
      message: 'La actualización se realizó con éxito.',
    });
  } catch (error) {
    let errorMessage = getErrorMessage(error);

    if (errorMessage === 'ya ha sido tomado') {
      errorMessage = 'Ya existe un cliente con este RFC';
    }
    yield call(ErrorHandler, {
      error,
      action: updateTaxableEntityFailure,
      showBodyErrors: true,
      message: errorMessage,
    });
  }
}

export function* uploadTaxableEntityFielWithAccessToken({
  payload: { formdata },
}) {
  try {
    const access_token = localStorage.getItem('user_id');
    const { taxable_entity } = yield select((state) => state.taxableEntity);
    const response = yield call(
      api.put,
      `/taxable_entities/${taxable_entity.id}/upload_fiel`,
      formdata,
      {
        headers: { Authorization: `${access_token}` },
      },
    );
    if (response.status === 200) {
      gtmEvent('AppEvent', 'fieluploadSuccess');
    }
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: uploadTaxableEntityFielSuccess,
      failure: uploadTaxableEntityFielFailure,
      title: 'Éxito',
      message: 'Su fiel se actualizó correctamente',
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: uploadTaxableEntityFielFailure,
      showBodyErrors: true,
    });
  }
}

export function* uploadTaxableEntityPayrollCredentialsWithAccessToken({
  payload: { formdata },
}) {
  try {
    const access_token = localStorage.getItem('user_id');
    const { taxable_entity } = yield select((state) => state.taxableEntity);
    const response = yield call(
      api.put,
      `/taxable_entities/${taxable_entity.id}/upload_payroll_credentials`,
      formdata,
      {
        headers: { Authorization: `${access_token}` },
      },
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: uploadTaxableEntityPayrollCredentialsSuccess,
      failure: uploadTaxableEntityPayrollCredentialsFailure,
      title: 'Éxito',
      message: 'Su fiel se a actualizó correctamente',
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: uploadTaxableEntityPayrollCredentialsFailure,
    });
  }
}

export function* uploadTaxableEntityCsd({ payload: { formdata } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const { taxable_entity } = yield select((state) => state.taxableEntity);
    const response = yield call(
      api.put,
      `/taxable_entities/${taxable_entity.id}/upload_csd`,
      formdata,
      {
        headers: { Authorization: `${access_token}` },
      },
    );
    yield call(ErrorLayout, {
      response,
      status: response.status,
      success: uploadTaxableEntityCsdSuccess,
      failure: uploadTaxableEntityCsdFailure,
      title: 'Éxito',
      message: 'Sus sellos CSD se han actualizado correctamente',
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: uploadTaxableEntityCsdFailure,
      showBodyErrors: true,
    });
  }
}
