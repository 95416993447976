import React, { useEffect } from 'react';
import { Row, Card, CardBody } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import toCurrency from '@util/toCurrency';
import {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
  workflowNextPhase,
  declarationEntriesFetch,
} from '@redux/actions';
import { Colxx } from '@components';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';

function EntriesSummary({ platform_entries: { platform_entries }, workflow }) {
  const dispatch = useDispatch();
  useEffect(() => {
    const workflow_id = workflow.id;
    dispatch(declarationEntriesFetch({ workflow_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow.id]);

  const {
    total_income_constancy = 0,
    extra_income = 0,
    extra_expenses = 0,
  } = workflow;

  const total_national_expenses = platform_entries
    ? platform_entries.reduce((accumulator, currentValue) => {
        if (
          currentValue.entry_type === 'national_income' ||
          currentValue.entry_type === 'foreign_income' ||
          currentValue.entry_type === 'foreign_products' ||
          currentValue.entry_type === 'foreign_invoices'
        ) {
          return accumulator + 0;
        }
        if (currentValue.accounting_status === 'fixed_asset') {
          return accumulator + parseFloat(currentValue.depreciation_amount);
        }
        if (currentValue.accounting_status === 'is_deductible') {
          const amount = currentValue.cfdi
            ? currentValue.cfdi.subtotal
            : currentValue.amount;
          return accumulator + parseFloat(amount);
        }
        return accumulator + 0;
      }, 0)
    : 0;

  const total_foreign_expenses = platform_entries
    ? platform_entries.reduce((accumulator, currentValue) => {
        if (
          currentValue.entry_type === 'national_income' ||
          currentValue.entry_type === 'foreign_income' ||
          currentValue.entry_type === 'national_expenses' ||
          currentValue.entry_type === 'national_egress'
        ) {
          return accumulator + 0;
        }
        if (currentValue.accounting_status === 'fixed_asset') {
          return accumulator + parseFloat(currentValue.depreciation_amount);
        }
        if (currentValue.accounting_status === 'is_deductible') {
          const amount = currentValue.cfdi
            ? currentValue.cfdi.subtotal
            : currentValue.amount;
          return accumulator + parseFloat(amount);
        }
        return accumulator + 0;
      }, 0)
    : 0;

  const tax_profit =
    parseFloat(total_income_constancy) +
    parseFloat(extra_income || 0) -
    (parseFloat(total_national_expenses + total_foreign_expenses) +
      parseFloat(extra_expenses));
  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Resumen del mes"
          description="El cálculo de la utilidad fiscal se obtiene sumando los ingresos facturados menos los gastos deducibles. Recuerda que mientras menos utilidad fiscal, ¡menos impuestos!"
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton>¡Listo!</PrimaryPhaseButton>
        </>
      }
    >
      <div className="d-flex align-items-center w-100">
        <div className="w-100">
          <div className="w-80 w-sm-100">
            <h1>
              Ya está todo listo. Ahora sigue calcular los impuestos. ¿Está
              listo?
            </h1>
          </div>
          <Row>
            <Colxx md="6">
              <Card className="border border-theme-3 mb-4">
                <CardBody>
                  <section>
                    <Row
                      className="font-weight-bold text-primary"
                      style={{ fontSize: '1rem' }}
                    >
                      <Colxx xxs={{ size: 'auto', offset: 0 }}>Ingresos</Colxx>
                    </Row>

                    <Row>
                      <Colxx xxs={{ size: 5 }} md={{ size: 7, offset: 1 }}>
                        Ingresos en constancia
                      </Colxx>
                      <Colxx xxs={{ size: 7 }} md={{ size: 3 }}>
                        {toCurrency(total_income_constancy)}
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs={{ size: 5 }} md={{ size: 7, offset: 1 }}>
                        Ingresos fuera de la constancia
                      </Colxx>
                      <Colxx xxs={{ size: 7 }} md={{ size: 3 }}>
                        {toCurrency(extra_income)}
                      </Colxx>
                    </Row>

                    {/* Impuestos SS  */}
                    <Row
                      className="font-weight-bold text-primary"
                      style={{ fontSize: '1rem' }}
                    >
                      <Colxx xxs={{ size: 'auto', offset: 0 }}>Gastos</Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs={{ size: 5 }} md={{ size: 7, offset: 1 }}>
                        Gastos nacionales
                      </Colxx>
                      <Colxx xxs={{ size: 7 }} md={{ size: 3 }}>
                        {toCurrency(total_national_expenses)}
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs={{ size: 5 }} md={{ size: 7, offset: 1 }}>
                        Gastos en el extranjero
                      </Colxx>
                      <Colxx xxs={{ size: 7 }} md={{ size: 3 }}>
                        {toCurrency(total_foreign_expenses)}
                      </Colxx>
                    </Row>
                  </section>
                  <hr />
                  <Row>
                    <Colxx
                      xxs={{ size: 8, offset: 0 }}
                      style={{ fontSize: '1rem' }}
                      className="font-weight-bold"
                    >
                      <div>Utilidad fiscal </div>
                    </Colxx>
                    <Colxx xxs={{ size: 7 }} md={{ size: 3 }}>
                      {toCurrency(tax_profit)}
                    </Colxx>
                  </Row>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        </div>
      </div>
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ platform_entries, workflows }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { workflow, wloading, platform_entries };
};
export default connect(mapStateToProps, {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
  workflowNextPhase,
  declarationEntriesFetch,
})(EntriesSummary);
