import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { UseQueryOptions } from 'react-query';
import { ProcessesRouteType } from 'types/process';
import { Process, ProcessStepType, ProcessType } from 'types/entities';
import getStepsByProcessType from '@util/getStepsByProcessType';
import { NotificationManager } from '@components';
import useUpdateProcessesMutation from './useUpdateProcessesMutation';
import useCurrentProcessesQuery from './useCurrentProcessesQuery';
import getActiveStepRouteByProcessType from '../@util/getActiveStepRouteByProcessType';

export default function useProcess(
  currentProcessType: ProcessType,
  queryOptions?: UseQueryOptions<Process[]>,
  processId?: number,
) {
  const { processId: processIdFromParams } = useParams();
  const sourceProcessId = processId || +(processIdFromParams || 0);
  const updateProcess = useUpdateProcessesMutation();
  const { getProcess, loadingCurrentProcesses, updateCacheProcess } =
    useCurrentProcessesQuery(queryOptions);

  const process = useMemo(
    () => getProcess(currentProcessType, sourceProcessId),
    [getProcess, currentProcessType, sourceProcessId],
  );

  const groupSteps = useMemo(
    () => Object.entries(process?.grouped_steps || {}),
    [process?.grouped_steps],
  );

  const availableSteps = useMemo(
    () => Object.values(process?.grouped_steps ?? {}).flat(),
    [process?.grouped_steps],
  );

  const steps = useMemo(
    () => getStepsByProcessType(process?.type, availableSteps),
    [availableSteps, process?.type],
  );

  const currentStep = process?.current_step || availableSteps[0]?.type;

  const activeIndex = useMemo(
    () => availableSteps.findIndex((item) => item.type === currentStep),
    [availableSteps, currentStep],
  );

  const activeStep = useMemo(() => {
    if (!process) return null;

    return availableSteps.find((item) => item.type === currentStep);
  }, [currentStep, process, availableSteps]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const next = async (payload: any) => {
    if (!process) return;
    try {
      const newProcess = await updateProcess.mutateAsync({
        id: process.id,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        payload,
      });

      updateCacheProcess(newProcess);
    } catch (e) {
      NotificationManager.error(
        'Ocurrió un error, por favor contacta a soporte.',
        'Error en el proceso',
      );
    }
  };

  const goTo = async (target: number) => {
    if (!process) return;
    // To change manually the current step we need to update the process
    const payload = {
      current_step: target,
    };
    try {
      const newProcess = await updateProcess.mutateAsync({
        id: process.id,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        payload,
      });

      updateCacheProcess(newProcess);
    } catch (e) {
      NotificationManager.error(
        'Ocurrió un error, por favor contacta a soporte.',
        'Error en el proceso',
      );
    }
  };

  const activeStepRoute = useMemo<ProcessesRouteType | null>(() => {
    const activeStepRouteByProcessType = getActiveStepRouteByProcessType(
      currentProcessType,
      currentStep,
    );
    if (
      sourceProcessId &&
      activeStepRouteByProcessType?.includes(':processId')
    ) {
      return activeStepRouteByProcessType?.replace(
        ':processId',
        sourceProcessId.toString(),
      ) as ProcessesRouteType;
    }
    return activeStepRouteByProcessType;
  }, [currentProcessType, currentStep, sourceProcessId]);

  const getIndexByStepType = (stepType: ProcessStepType) =>
    availableSteps.findIndex((item) => item.type === stepType);

  return {
    next,
    goTo,
    steps,
    activeStep,
    loadingCurrentProcesses,
    updatingProcess: updateProcess.isLoading,
    process,
    activeStepRoute,
    groupSteps,
    availableSteps,
    activeIndex,
    getIndexByStepType,
  };
}
