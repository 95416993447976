import { User } from 'types/entities';
import { NotificationManager } from '@components';
import { QueryClient } from 'react-query';
import { getImpersonationToken } from '../api/taxableEntity';

export const validateUserForBackOffice = (
  user: User,
  navigate: (path: string) => void,
) => {
  const { role } = user;
  if (['account_holder_admin', 'account_holder_assistant'].includes(role)) {
    NotificationManager.error(
      'Error de autentificación, se necesitan los permisos necesarios para operar esta app.',
      'Inicio de sesión con Google',
    );
    return;
  }
  localStorage.setItem('user_id', user.access_token);
  navigate('/');
};

export const logout = (
  navigate: (path: string) => void,
  queryClient: QueryClient,
) => {
  localStorage.removeItem('user_id');
  queryClient.clear();
  navigate('/login');
};

export const impersonationLogin = async (taxableEntityId: number) => {
  try {
    const impersonationToken = await getImpersonationToken(taxableEntityId);
    if (!impersonationToken) {
      throw new Error('No valid impersonation token found');
    }

    const environment = JSON.parse(
      localStorage.getItem('environments' || '') || '{}',
    ) as { env?: 'production' | 'develop' };

    const url = new URL(
      {
        production: 'https://app.konta.com/login',
        develop: 'https://develop.konta.com/login',
      }[environment.env || 'production'],
    );

    url.searchParams.append('token', impersonationToken);
    window.open(url, '_blank');
  } catch (error) {
    console.log(error);
    NotificationManager.error('Ocurrió un error al iniciar sesión', 'Clientes');
  }
};
