import { styled, Text } from '@konta/ui';

export const Root = styled('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '4px',
  flex: '1 0 0',
  fontFamily: 'Inter',
  borderRadius: '8px',
  border: '1px solid $gray200',
  background: '$gray25',
  variants: {
    isLoading: {
      true: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    pointer: {
      true: {
        cursor: 'pointer',
      },
    },
    status: {
      success: {
        border: '2px solid $success200',
      },
      warning: {
        border: '2px solid $warning200',
      },
      error: {
        border: '2px solid $error200',
      },
    },
    isDisabled: {
      true: {
        opacity: '0.7',
        border: '2px solid $gray200',
      },
    },
  },
  compoundVariants: [
    {
      isLoading: true,
      pointer: true,
      css: {
        cursor: 'progress',
      },
      status: 'success',
    },
  ],
});

export const ContainerRoot = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  width: '100%',
  gap: '2px',
});

export const HeadingText = styled(Text, {
  overflow: 'hidden',
  color: '$gray600',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '18px',
});
export const AmountText = styled('span', {
  color: '$gray700',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '38px',
  display: 'flex',
  gap: '8px',
  alignItems: 'end',
});
export const CurrencyText = styled(Text, {
  color: '$gray700',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '28px',
});

export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  flex: 1,
  alignItems: 'start',
  width: '100%',
});
export const Icon = styled('div', {
  width: '16px',
  height: '16px',
  color: '$gray500',
});

export const FooterTextContainer = styled('div', {
  display: 'flex',
  padding: '8px 16px',
  width: '100%',
  gap: '4px',
  borderTop: '1px solid $gray200',
});

export const FooterText = styled(Text, {
  overflow: 'hidden',
  color: '$gray700',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
  fontStyle: 'normal',
});

export const HeadingContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
});

export const Alert = styled('div', {
  display: 'flex',
  padding: '4px 10px',
  borderRadius: '8px',
  gap: '4px',
  alignItems: 'center',
  background: '$error50',
});

export const AlertText = styled(Text, {
  color: '$error600',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '18px',
});

export const ValueContainer = styled('div', {
  display: 'flex',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'end',
  flexWrap: 'wrap',
  variants: {
    wrap: {
      wrap: {
        flexWrap: 'wrap',
        justifyContent: 'start',
      },
    },
  },
});
