import React, { useMemo } from 'react';
import { Chip } from '@konta/ui';
import PropTypes from 'prop-types';

export default function BankStatus({ linkStatus }) {
  const [colorStatus, descriptionStatus] = useMemo(() => {
    return (
      {
        valid: ['success100', 'La conexión funciona con éxito'],
        invalid: ['error100', 'Las credenciales ya no son válidas'],
        unconfirmed: ['error100', 'La conexión no se pudo crear correctamente'],
        token_required: [
          'warning100',
          'La conexión anterior ha expirado, se requiere un nuevo acceso',
        ],
      }[linkStatus] || ['success100', 'La conexión funciona con éxito']
    );
  }, [linkStatus]);
  return (
    <Chip
      variant="contained"
      label={descriptionStatus}
      color={colorStatus}
      size="s"
    />
  );
}

BankStatus.propTypes = {
  linkStatus: PropTypes.string.isRequired,
};
