// eslint-disable-next-line import/prefer-default-export
export const styles = {
  tab: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tabIcon: {
    svg: {
      path: {
        fill: 'none',
      },
    },
  },
  loaderContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabsContainer: {
    width: '100%',
    overflow: 'auto',
    alignSelf: 'flex-start',
    '@media (hover: none) and (pointer: coarse)': {
      overflowX: 'scroll',
      '-webkit-overflow-scrolling': 'touch',
      cursor: 'grab',
    },
    '> * > button': {
      whiteSpace: 'nowrap',
    },
  },
} as const;
