import { useMemo } from 'react';
import dayjs from 'dayjs';
import LetterAvatar from 'react-avatar';
import type { ColumnDef, Row } from '@tanstack/react-table';
import { EyeLineIcon, LinkExternal02LineIcon } from '@konta/icons';
import { Button, Chip, Flex, Text, Tooltip } from '@konta/ui';
import {
  getAccountingEntryTypeLabel,
  calculateAmountAndConversion,
  getDocument,
  getIssuerLegalName,
  getIssuerRfc,
  getReceiverLegalName,
  getReceiverRfc,
  getSourceTypeLabel,
} from '@util/declarationEntries';
import {
  ACCOUNTING_STATUS_OPTIONS,
  accountingStatusColors,
  declarationEntryExpensesFilterOptions,
  declarationEntryIncomesFilterOptions,
} from '@constants/declarationEntries';
import toCurrency from '@util/toCurrency';
import type { DeclarationEntry } from 'types/entities';
import { PDFModal } from '@components';
import type { ItemWithHover } from '@konta/ui/dist/components/DataTable/types';
import Cell from '../../Cell';
import {
  cellAmountCss,
  cellBoldCss,
  cellCss,
  cellUpperCss,
  headerCss,
} from '../styled';
import getExpenseEntryTypeFilterValue from '../util/getExpenseEntryTypeFilterValue';

interface UseEntriesColumns {
  type: 'incomes' | 'expenses';
  itemHovered: ItemWithHover<DeclarationEntry>;
  openCfdisModal: (row: Row<DeclarationEntry>) => void;
}

export default function useEntriesColumns({
  type,
  itemHovered,
  openCfdisModal,
}: UseEntriesColumns) {
  const isIncome = type === 'incomes';
  return useMemo<ColumnDef<DeclarationEntry>[]>(
    () => [
      {
        header: () => <Text css={headerCss}>Fecha de emisión</Text>,
        size: 170,
        label: ' Fecha de emisión',
        accessorKey: 'accounting_date',
        id: 'accounting_date',
        cell: ({ row }) => (
          <Cell textCss={cellUpperCss}>
            {dayjs(row.original.accounting_date).format('DD MMM') || ''}
          </Cell>
        ),
      },
      {
        header: () => (
          <Text css={headerCss}>
            Nombre del {isIncome ? 'receptor' : 'emisor'}
          </Text>
        ),
        accessorKey: 'legal_name',
        label: isIncome ? 'Receptor' : 'Emisor',
        id: 'legal_name',
        size: 350,
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
        accessorFn: (entry) =>
          (isIncome
            ? getReceiverLegalName(entry)
            : getIssuerLegalName(entry)) || '-',
        cell: ({ row }) => {
          const legalName = isIncome
            ? getReceiverLegalName(row.original)
            : getIssuerLegalName(row.original);
          return (
            <Flex css={cellBoldCss} gap={12} itemsCenter>
              <span className="classify-expenses-logo-container">
                <LetterAvatar
                  maxInitials={2}
                  name={legalName || '-'}
                  size="28"
                  round
                />
              </span>
              {legalName}
            </Flex>
          );
        },
      },
      {
        header: () => <Text css={headerCss}>RFC</Text>,
        label: 'RFC',
        accessorKey: 'rfc',
        id: 'rfc',
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
        accessorFn: (entry) =>
          (isIncome ? getReceiverRfc(entry) : getIssuerRfc(entry)) || '-',
        cell: ({ row }) => {
          return (
            <Flex css={cellUpperCss}>
              {(isIncome
                ? getReceiverRfc(row.original)
                : getIssuerRfc(row.original)) || '-'}
            </Flex>
          );
        },
      },
      {
        header: () => <Text css={headerCss}>Tipo</Text>,
        label: 'Tipo',
        accessorKey: 'source_type',
        id: 'source_type',
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: isIncome
            ? declarationEntryIncomesFilterOptions
            : declarationEntryExpensesFilterOptions,
        },
        accessorFn: (entry) =>
          (isIncome
            ? entry.source.type
            : getExpenseEntryTypeFilterValue(entry)) || '-',
        cell: ({ row }) => (
          <Cell textCss={cellCss}>
            {getSourceTypeLabel(row.original) || '-'}
          </Cell>
        ),
      },
      {
        header: () => <Text css={headerCss}>Estatus</Text>,
        label: 'Estatus',
        accessorKey: 'accounting_status',
        id: 'accounting_status',
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: ACCOUNTING_STATUS_OPTIONS,
        },
        cell: ({ row }) => {
          const [color, contrastColor] =
            accountingStatusColors[row.original.accounting_status];
          return (
            <Chip
              css={{
                '--color': color,
                '--contrastColor': contrastColor,
              }}
              size="s"
              label={getAccountingEntryTypeLabel(row.original).label || ''}
            />
          );
        },
      },
      {
        header: '',
        size: 100,
        label: 'Acciones',
        accessorKey: 'actions',
        id: 'actions',
        cell: ({ row }) => {
          if (itemHovered?.index !== row.index) return null;
          const { url, status, id } = getDocument(row.original);
          const openShowPdfModal = [
            'Cfdi',
            'CancelledCfdi',
            'PaymentDetail',
            'FixedAsset',
          ].includes(row.original.source_type);
          const rfc = isIncome
            ? getReceiverRfc(row.original)
            : getIssuerRfc(row.original);
          return (
            <Flex itemsCenter gap={4} onClick={(e) => e.stopPropagation()}>
              {!!rfc && (
                <Tooltip
                  delayduration={0}
                  size="xs"
                  triggerElement={
                    <Button
                      size="xs"
                      variant="outlined"
                      icon
                      noFill
                      onClick={() => {
                        openCfdisModal(row);
                      }}
                    >
                      <LinkExternal02LineIcon color="#667085" />
                    </Button>
                  }
                >
                  Abrir reporte de facturas
                </Tooltip>
              )}
              {openShowPdfModal && (
                <Tooltip
                  delayduration={0}
                  size="xs"
                  triggerElement={
                    <span>
                      <PDFModal
                        url={url}
                        title="Vista del CFDI"
                        id={id}
                        status={status}
                        invoice={row.original}
                        onlyIcon
                        withActions={false}
                        withShareButtons={false}
                        togglePdf={undefined}
                        onToggle={undefined}
                        uuid={undefined}
                      />
                    </span>
                  }
                >
                  Ver PDF de la factura
                </Tooltip>
              )}
              {!!url && !openShowPdfModal && (
                <Tooltip
                  delayduration={0}
                  size="xs"
                  triggerElement={
                    <Button
                      size="xs"
                      variant="outlined"
                      icon
                      noFill
                      onClick={() => {
                        window.open(url, '_blank');
                      }}
                    >
                      <EyeLineIcon color="#667085" />
                    </Button>
                  }
                >
                  Ver documento
                </Tooltip>
              )}
            </Flex>
          );
        },
      },
      {
        header: () => (
          <Flex justify="end">
            <Text css={headerCss}>Total</Text>
          </Flex>
        ),
        size: 50,
        label: 'Total',
        accessorKey: 'total',
        id: 'total',
        cell: ({ row }) => (
          <Flex justify="end" css={cellAmountCss}>
            {toCurrency(calculateAmountAndConversion(row.original).amount) ||
              '-'}
          </Flex>
        ),
      },
    ],
    [isIncome, itemHovered?.index, openCfdisModal],
  );
}
