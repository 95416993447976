import { Calendar02OutlineIcon, Flag02LineIcon } from '@konta/icons';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useWorkflows from 'shared/hooks/useWorkflows';
import usePendingTasksStore from 'store/pendingTasksStore';

export default function useHandleWorkflows() {
  const navigate = useNavigate();
  const { workflows, workflowsLoading } = useWorkflows();
  const { addPendingTask } = usePendingTasksStore();

  useEffect(() => {
    if (workflows.length > 0 && !workflowsLoading) {
      workflows.forEach((workflow) => {
        const workflowStatus = {
          fetching_invoice: 'pending',
          classifying: 'pending',
          verify_declaration: 'pending',
          ready: 'requiredAction',
          in_proccess: 'requiredAction',
          submit_declaration: 'pending',
          pending_payment: 'requiredAction',
          paid: 'pending',
          done: 'completed',
          bank_payment: 'pending',
        }[workflow.status] as 'pending' | 'requiredAction' | 'completed';

        const workflowStatusText = {
          fetching_invoice: 'Extrayendo las facturas del mes',
          classifying: 'Clasificando las facturas del mes',
          verify_declaration: 'Verificando las facturas del mes',
          ready: 'Empieza tu declaración del mes',
          in_proccess: 'Continúa tu declaración del mes',
          submit_declaration: 'Enviando tu declaración del mes',
          pending_payment: 'Continúa tu declaración del mes',
          paid: 'Declaración del mes pagada',
          done: 'Declaración del mes completada',
          bank_payment: 'Pendiente de pago',
        }[workflow.status];

        const [year, month] = workflow.start_date.split('-');

        addPendingTask({
          id: `workflow-${workflow.id}`,
          type: 'workflow',
          status: workflowStatus,
          title: `Declaración de ${dayjs(workflow.start_date).format(
            'MMMM [de] YYYY',
          )}`,
          description: 'Proceso mensual de impuestos al final del mes',
          extraDescription: workflow,
          details: [
            {
              label: workflowStatusText,
              icon: <Flag02LineIcon />,
              iconCss: {
                background: '$violet100',
                path: {
                  fill: 'none',
                  stroke: '$violet500',
                },
              },
            },
            {
              label: `Fecha límite: ${dayjs(workflow.end_date).format(
                'DD [de] MMMM',
              )}`,
              icon: <Calendar02OutlineIcon />,
              iconCss: {
                path: {
                  fill: '$gray500',
                },
              },
            },
          ],
          handleAction: () => {
            navigate(`/app/declaraciones?month=${month}&year=${year}`);
          },
        });
      });
    }
  }, [workflows, workflowsLoading, addPendingTask, navigate]);

  return {
    workflows,
    workflowsLoading,
  };
}
