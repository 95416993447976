import { Flex, Plans as KontaPlans, Tab, Tabs } from '@konta/ui';
import usePricing from './usePricing';

interface PricingProps {
  asCard?: boolean;
  source: 'fiscal-visor' | 'app';
}

export default function Pricing({ asCard, source }: PricingProps) {
  const {
    billingPeriod,
    setBillingPeriod,
    handlePlanSelect,
    plans,
    isSmall,
    isTablet,
    showBillingPeriodicity,
  } = usePricing({ source });

  return (
    <Flex
      column
      css={{ flex: 1 }}
      className="bg-white py-2"
      data-cy="wrapperPlans"
    >
      {(isTablet || asCard) && showBillingPeriodicity && (
        <Tabs
          variant="switch"
          value={billingPeriod}
          onChange={setBillingPeriod}
          indicator="bottom"
          css={{
            width: 'fit-content',
            ml: asCard ? '$10' : '0',
            mb: '$20',
            '@md': { mb: '0' },
          }}
        >
          <Tab id="monthly">Pago mensual</Tab>
          <Tab id="biannual">Pago semestral</Tab>
          <Tab id="yearly">Pago anual</Tab>
        </Tabs>
      )}

      <Flex
        css={{
          p: '$10',
          pt: '18px',
          '@md': showBillingPeriodicity ? { p: asCard ? '$40' : '$5' } : {},
          width: '100%',
        }}
        column
      >
        <KontaPlans
          plans={plans}
          headerComponent={
            showBillingPeriodicity && (
              <Flex css={{ mt: '-12px' }}>
                <Tabs
                  size={isSmall ? 'xs' : 's'}
                  variant="switch"
                  value={billingPeriod}
                  onChange={setBillingPeriod}
                  indicator="bottom"
                >
                  <Tab id="monthly">Pago mensual</Tab>
                  <Tab id="biannual">Pago semestral</Tab>
                  <Tab id="yearly">Pago anual</Tab>
                </Tabs>
              </Flex>
            )
          }
          onSelectPlan={(planId) =>
            handlePlanSelect(planId, plans, billingPeriod)
          }
          card={asCard}
          orientation="horizontal"
          tableCss={{ width: '100%' }}
        />
      </Flex>
    </Flex>
  );
}
