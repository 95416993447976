import { useQuery, UseQueryOptions } from 'react-query';
import { getAccumulatedOfEntries } from '@api/accumulatedIncomesAndExpensesEntries';
import { ACCUMULATED_OF_ENTRIES } from '@constants/reactQueries';
import type { AxiosRequestConfig } from 'axios';
import type { AccumulatedOfEntries } from 'types/entities';

export default function useAccumulatedOfEntries(
  axiosConfig?: AxiosRequestConfig<AccumulatedOfEntries>,
  queryOptions?: UseQueryOptions<AccumulatedOfEntries>,
) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<AccumulatedOfEntries>(
    [ACCUMULATED_OF_ENTRIES, axiosConfig],
    async () => {
      const acummulatedOfEntries = await getAccumulatedOfEntries(axiosConfig);
      return acummulatedOfEntries;
    },
    queryOptions,
  );

  return {
    accumulatedOfEntriesLoading: isLoading || isFetching,
    accumulatedOfEntries: data,
    ...rest,
  };
}
