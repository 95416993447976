import React from 'react';
import { PlanCard } from '@konta/ui';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Colxx, DebtsTable } from '@components';
import { toFixedWithoutRound } from '@util/Utils';
import { useOrders } from '@hooks';
import toFixedString from '@util/toFixedString';
import { addOrdersToCart } from '@redux/actions';

function DebtsCardOrder({ title, type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { debts, loading } = useOrders(type);

  if (debts.length) {
    if (
      debts[0]?.subscriptions[0] &&
      debts[0].subscriptions[0].status === 'incomplete'
    ) {
      const basicFeatures = [
        'Emisión de facturas ilimitadas',
        'Soporte por WhatsApp, Correo y Chat en la plataforma',
        'Asesoría contable (1 hora al mes)',
        'Hasta 50 facturas de gastos contabilizadas al mes',
        '1 régimen fiscal (sin contar Sueldos y Salarios o Dividendos)',
      ];
      const proFeatures = [
        'Emisión de facturas ilimitadas',
        'Soporte por WhatsApp, Correo, Chat en la plataforma y Teléfono',
        'Asesoría contable ilimitada',
        'Sin límite de facturas de gastos contabilizadas',
        'Más de 2 regímenes fiscales con obligaciones mensuales (sin contar Sueldos y Salarios o Dividendos)',
        'Soporte prioritario (horario extendido y atención preferente)',
      ];

      const newCommonFeatures = [
        'Aclaraciones necesarias para la aprobación y presentación ante el SAT.',
        'Diagnóstico fiscal gratis',
        'Facturación ilimitada',
        'Movimientos ilimitados',
        'Konta Estatus',
      ];
      const assistedFeatures = [...newCommonFeatures];
      const personalizedFeatures = [
        ...newCommonFeatures,
        'Asesor personalizado',
        'Conciliación de tu estado de cuenta con las facturas emitidas y presentación de tus impuestos.',
      ];
      const data = {
        discount: 0,
        price: toFixedString((debts[0].total - debts[0].balance) / 1.16),
        billingPeriod: 'monthly',
        planName: {
          starter_plan: 'Básico',
          standard_plan: 'Pro',
          assisted_plan: 'Asistido',
          personalized_plan: 'Personalizado',
        }[debts[0].subscriptions[0].base],
        totalText: `Total $${toFixedWithoutRound(debts[0].total)}`,
        features: {
          starter_plan: basicFeatures,
          standard_plan: proFeatures,
          assisted_plan: assistedFeatures,
          personalized_plan: personalizedFeatures,
        }[debts[0].subscriptions[0].base],
        isCurrentPlan: false,
        badgeText: '+ IVA',
        btnText: 'Pagar ahora',
        onSelectPlan: () => {
          dispatch(addOrdersToCart([debts[0]]));
          navigate('/app/checkout');
        },
        planDescription:
          'Tienes un pago pendiente, al realizarlo no perderás los beneficios de tu suscripción con Konta 😉 ',
      };

      return (
        <Row className="equal-height-container justify-content-center  pricing__table">
          <Colxx xxs="12" md="6" lg="6" xl="5" className="col-lg mb-4">
            <PlanCard {...data} />
          </Colxx>
        </Row>
      );
    }
    return (
      <Row>
        <Colxx>
          <Card className="mb-4 border-top-2 border-danger">
            <CardBody>
              <CardTitle tag="h4">{title}</CardTitle>
              <DebtsTable type={type} loading={loading} data={debts} />
            </CardBody>
          </Card>
        </Colxx>
      </Row>
    );
  }
  return null;
}

export default DebtsCardOrder;
