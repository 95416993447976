import { ReactNode } from 'react';
import CloseIcon from 'mdi-react/CloseIcon';
import { Button, Divider, Modal, Text } from '@konta/ui';
import Portal from '../Portal';
import { ModalContent, ModalHeader } from './styled';

interface FullModalProps {
  toggleClose: () => void;
  open: boolean;
  title: string;
  children: ReactNode;
  id: string;
  dataCyTitle?: string;
  dataCyCloseButton?: string;
}
export default function FullModal({
  toggleClose,
  title,
  children,
  open,
  id,
  dataCyTitle,
  dataCyCloseButton,
}: FullModalProps) {
  return (
    <Portal target="#root">
      <Modal
        open={open}
        onClose={toggleClose}
        size="full-screen"
        id={id}
        css={{
          '@<sm': {
            pr: '$24',
          },
        }}
      >
        <ModalHeader>
          <Button
            icon
            variant="text"
            onClick={toggleClose}
            data-cy={dataCyCloseButton}
          >
            <CloseIcon />
          </Button>
          <Divider vertical />
          <Text regular m lineHeight="m" data-cy={dataCyTitle}>
            {title}
          </Text>
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
      </Modal>
    </Portal>
  );
}
