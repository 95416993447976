import { ComponentProps, useEffect, useState } from 'react';
import api from '@api';
import { AxiosRequestConfig } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { usePreviousDifferent } from 'rooks';
import {
  PaginationParamsManager,
  PaginationParams,
  PaginationSchema,
} from 'types/pagination';
import { DataTable } from '@konta/ui';
import useMemoryParamsPagination from './useMemoryParamsPagination';

export interface UseApiPaginationOptions<T> {
  endpoint: string;
  params?: PaginationParams;
  paramsManager?: PaginationParamsManager;
  itemsPerPage?: number;
  filters?: PaginationParams;
  axiosConfig?: AxiosRequestConfig<T>;
  queryOptions?: Omit<
    UseQueryOptions<PaginationSchema<T>>,
    'queryFn' | 'queryKey'
  >;
}

export default function useApiPagination<Type = unknown>({
  endpoint,
  params,
  paramsManager,
  itemsPerPage = 10,
  filters = {},
  axiosConfig,
  queryOptions,
}: UseApiPaginationOptions<Type>) {
  const [pageSize, setPageSize] = useState(itemsPerPage);
  const memoryParamsManager = useMemoryParamsPagination(params || {});
  const fixedParamsManager = paramsManager || memoryParamsManager;

  const fixedParams = { ...fixedParamsManager.params, ...filters, ...params };

  const prevFilters = usePreviousDifferent(filters);
  const prevPageSize = usePreviousDifferent(pageSize);

  const { data, isFetching, refetch, isLoading, ...rest } = useQuery(
    [endpoint, pageSize, fixedParams, axiosConfig, filters],
    async () => {
      const response = await api.get(endpoint, {
        ...axiosConfig,
        params: {
          per_page: pageSize,
          page: fixedParams.page || 1,
          ...fixedParams,
        },
      });
      return response.data as PaginationSchema<Type>;
    },
    queryOptions,
  );

  // parsed values
  const items = data?.data ?? [];
  const total = data?.meta?.total_count ?? 0;

  // calculated values
  const page = +(fixedParamsManager.params.page || 1);
  const lastPage = Math.ceil(total / itemsPerPage);
  const hasNext = page < lastPage;
  const hasPrev = page > 1;

  // methods
  const setPage = (newPage: number | string) => {
    fixedParamsManager.setParam('page', `${newPage}`);
  };

  useEffect(() => {
    if (prevPageSize) {
      setPage(1);
    }
  }, [prevPageSize]);
  useEffect(() => {
    if (Object.values(prevFilters || {}).length > 0) {
      setPage(1);
    }
  }, [prevFilters]);

  const next = () => {
    const nextPage = Math.min(page + 1, lastPage);
    setPage(nextPage);
  };

  const prev = () => {
    const prevPage = Math.max(page - 1, 0);
    setPage(prevPage);
  };

  const pagination: ComponentProps<typeof DataTable>['manualPaginationProps'] =
    {
      pages: Math.ceil(total / pageSize),
      value: page - 1,
      nextPage: next,
      previousPage: prev,
      setPage,
      onChange: (value: number) => setPage(value + 1),
      hasPrevPage: hasPrev,
      hasNextPage: hasNext,
      setPageSize,
      pageSize,
    };

  return {
    refetch,
    page,
    setPage,
    total,
    items,
    loading: isFetching || isLoading,
    lastPage,
    hasNext,
    hasPrev,
    next,
    prev,
    pageSize,
    setPageSize,
    pagination,
    ...rest,
  };
}
