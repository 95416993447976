import { styled, DialogClose } from '@konta/ui';

export const HeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const HeaderContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'space-between',
  padding: '8px 0',
});

export const LeftSection = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const AvatarGroup = styled('div', {
  display: 'flex',
  marginRight: '15px',
});

export const Avatar = styled('img', {
  width: '24px',
  height: '24px',
  borderRadius: '200px',
  border: '1.5px solid white',
  marginRight: '-4px',
});

export const HeaderText = styled('span', {
  color: '$gray600',
  fontSize: '10px',
  fontWeight: '500',
  lineHeight: '12px',
  b: {
    fontWeight: '600',
    color: '$gray700',
  },
});

export const CloseButton = styled(DialogClose, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  p: '10px',
  color: '$gray500',
  zIndex: 10,
  svg: {
    width: '24px',
    height: '24px',
  },
});

export const SliderIndicators = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  gap: '8px',
  alignItems: 'center',
});

export const Indicator = styled('div', {
  display: 'flex',
  flex: 1,
  height: '2px',
  backgroundColor: '$gray300',
  transition: 'background-color 0.3s ease',

  variants: {
    active: {
      true: {
        backgroundColor: '$gray500',
      },
    },
  },
});
