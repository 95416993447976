import { ReactNode } from 'react';
import { Tooltip } from '@konta/ui';
import { NavigationArrow } from './styled';

interface NavigationArrowWithTooltipProps {
  onClick: () => void;
  disabled: boolean;
  ariaLabel: string;
  tooltipText?: string;
  children: ReactNode;
}

function NavigationArrowWithTooltip({
  onClick,
  disabled,
  ariaLabel,
  tooltipText,
  children,
}: NavigationArrowWithTooltipProps) {
  if (tooltipText) {
    return (
      <Tooltip
        size="xs"
        triggerElement={
          <NavigationArrow
            onClick={onClick}
            disabled={disabled}
            aria-label={ariaLabel}
          >
            {children}
          </NavigationArrow>
        }
      >
        {tooltipText}
      </Tooltip>
    );
  }
  return (
    <NavigationArrow
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {children}
    </NavigationArrow>
  );
}

export default NavigationArrowWithTooltip;
