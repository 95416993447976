import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import type { Workflow } from 'types/entities';
import useFeature from '@hooks/useFeature';
import { MODAL_INSIGHTS } from '@constants/featureFlags';
import useTaxableEntity from '@hooks/useTaxableEntity';

const detailViews = {
  grid: 'grid',
  entries: 'entries',
};

export default function usePreviousSummary(workflow: Workflow) {
  const [filters, setFilters] = useState<Record<string, string>>({
    month: '',
    type: '',
  });
  const [detailView, setDetailView] = useState(detailViews.grid);
  const [workflowFromGridTable, setWorkflowFromGridTable] = useState<Workflow>(
    {} as Workflow,
  );

  const { taxable_entity } = useTaxableEntity();
  const declarations = useMemo(
    () => workflow.declarations.filter((declaration) => declaration.is_active),
    [workflow],
  );
  const [modalInsightsEnabled] = useFeature(MODAL_INSIGHTS);

  const workflowInitialDate = dayjs(workflow.start_date).format(
    'MMMM [de] YYYY',
  );

  const selectedGridMonth = useMemo(() => {
    if (filters.month === '0') {
      return 'enero';
    }

    return dayjs(filters.month).add(1, 'month').format('[de] MMMM');
  }, [filters]);

  const totals = useMemo(
    () =>
      declarations.reduce(
        (acc, declaration) => {
          acc.totalTax += +declaration.total_to_pay;
          acc.totalIncome += +declaration.declaration_income.total;
          acc.totalExpense += +declaration.declaration_expense.total;
          return acc;
        },
        {
          totalTax: 0,
          totalIncome: 0,
          totalExpense: 0,
        },
      ),
    [declarations],
  );

  const [entriesTotalsModalType, setEntriesModalType] = useState<
    'incomes' | 'expenses' | ''
  >('');

  const closeEntriesTotalsModal = () => {
    setEntriesModalType('');
  };

  const breadcrumbs = useMemo(
    () => [
      {
        label: 'Cálculo simplificado',
        onClick: () => setDetailView(detailViews.grid),
        isActive: detailView === detailViews.grid,
      },
      {
        label: `Entradas ${selectedGridMonth}`,
        onClick: () => setDetailView(detailViews.entries),
        isActive: detailView === detailViews.entries,
      },
    ],
    [detailView, selectedGridMonth],
  );

  const onHandleEntriesView = (
    month: string,
    type: 'income' | 'expense',
    selectedWorkflow: Workflow,
  ) => {
    setWorkflowFromGridTable(selectedWorkflow);
    setFilters({
      month,
      type,
    });

    setDetailView(detailViews.entries);
  };

  useEffect(() => {
    setDetailView(detailViews.grid);
  }, [workflow]);

  return {
    declarations,
    workflowInitialDate,
    totals,
    closeEntriesTotalsModal,
    entriesTotalsModalType,
    setEntriesModalType,
    modalInsightsEnabled,
    detailView,
    setDetailView,
    breadcrumbs,
    taxableEntity: taxable_entity,
    detailViews,
    onHandleEntriesView,
    filters,
    setFilters,
    workflowFromGridTable,
  };
}
