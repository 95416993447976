import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { InfoCircleLineIcon } from '@konta/icons';
import { Card, CardContent, Text, Flex } from '@konta/ui';
import postRequireCaptureLine from '@api/postRequireCaptureLine';
import toCurrency from '@util/toCurrency';
import getErrorMessage from '@util/getErrorMessage';
import useIsValidCaptureLineSalary from '@hooks/useIsValidCaptureLineSalary';
import useHasPayrollRetention from '@hooks/useHasPayrollRetention';
import useIsValidCaptureLine from '@hooks/useIsValidCaptureLine';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import { NotificationManager } from '@components';
import {
  Alert,
  AlertContent,
  AlertText,
  AlertDescription,
} from '@components/Workflow/styled';
import WorkflowLayout from '@components/WorkflowLayout';
import Loader from '@components/Loader';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import NextPhaseConfirmation from '@components/NextPhaseConfirmation';
import WorkflowHeader from '@components/WorkflowHeader';
import SecondaryPhaseButton from '@components/SecondaryPhaseButton';

const secondaryPayload = {
  workflow: {
    next_phase: 'secondary_phase',
  },
};

const onError = (error) => {
  const message = getErrorMessage(error);
  NotificationManager.error(message, 'Error', 10000);
};

function CaptureLinePaymentResico() {
  const { workflow } = useSelectedWorkflow();

  const {
    declaration_document,
    total_to_pay,
    total_payroll_withholdings_to_pay,
  } = workflow?.active_declaration || {};
  const { hasPayrollRetentions, loadingPayrollRetention } =
    useHasPayrollRetention();
  const isSalaryCaptureLineValid = useIsValidCaptureLineSalary();
  const isCaptureLineValid = useIsValidCaptureLine();

  const isExpired = !isCaptureLineValid || !isSalaryCaptureLineValid;

  const hasNoDebt =
    !isExpired &&
    +total_to_pay === 0 &&
    +total_payroll_withholdings_to_pay === 0;
  const hasDebt = !isExpired && !hasNoDebt;

  const requireCaptureLine = useMutation(postRequireCaptureLine, {
    onSuccess: () => {
      NotificationManager.success(
        'Se ha solicitado la línea de captura correctamente ',
        'Línea de captura',
        3000,
      );
    },
    onError,
  });

  const handleRequireCaptureLine = () => {
    requireCaptureLine.mutate({
      declarationId: workflow.active_declaration.id,
      workflowId: workflow.id,
    });
  };

  if (loadingPayrollRetention) {
    return <Loader />;
  }

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Pagar Declaración"
          description="En este paso puede elegir si pagar la declaración mediante una línea de captura o mediante su banco"
        />
      }
      actions={
        <>
          <NextPhaseConfirmation
            payload={secondaryPayload}
            trigger={({ onClick }) => (
              <SecondaryPhaseButton onClick={onClick}>
                Pagar por mi banco
              </SecondaryPhaseButton>
            )}
            title="Confirmación de pago por banco"
            acceptText="Confirmar"
          >
            <div className="m-auto d-flex flex-column justify-content-center text-center">
              <i className="large-icon iconsmind-Danger" color="danger" />
              <p>
                Al dar click en &quot;Confirmar&quot; te saltarás el pago por la
                platafroma y finalizarás tu declaración este mes.
              </p>
            </div>
          </NextPhaseConfirmation>

          <PrimaryPhaseButton
            {...(isExpired && { onClick: () => handleRequireCaptureLine() })}
          >
            {isExpired && 'Solicitar nueva línea de captura'}
            {hasNoDebt && 'Continuar'}
            {hasDebt && 'Pagar por tarjeta'}
          </PrimaryPhaseButton>
        </>
      }
    >
      <div className="d-flex align-items-center">
        <div>
          <h1>
            {isExpired &&
              '¡Su línea de captura está vencida! Para generar una nueva y volver calcular sus impuestos con sus atrasos, debe solicitar una nueva línea de captura.'}
            {hasNoDebt && '¡Felicidades! No hay nada por pagar 🥳'}
            {hasDebt &&
              '¿Lo pagarías por tarjeta o te encargas de pagarlo a través de tu banco?'}
          </h1>
          <Flex direction="column" gap={12}>
            {parseFloat(total_to_pay) > 0 && (
              <p>
                El importe a cargo determinado en esta declaración, deberá ser
                pagado en las instituciones de crédito autorizadas, utilizando
                para tal efecto la línea de captura que se indica.
              </p>
            )}
            <Flex>
              <Alert mt2>
                <InfoCircleLineIcon />
                <AlertContent>
                  <AlertText>Fecha límite de pago en la plataforma:</AlertText>
                  <AlertDescription>
                    Recuerda, para poder realizar el pago de tus impuestos desde
                    nuestra plataforma, debes hacerlo 1 día antes del
                    vencimiento de la línea de captura, es decir, si vence el
                    29, tienes plazo hasta el 28. Sin embargo, en tu banca
                    electrónica puedes hacer el pago hasta el último día de
                    vencimiento de esta.
                    <br />
                    Puedes descargar tu línea de captura en pdf en la sección de{' '}
                    <Text
                      bold
                      as={Link}
                      to="/app/documents"
                      css={{
                        color: '$primary500',
                        textDecoration: 'underline',
                      }}
                    >
                      {' '}
                      documentos {'->'} declaraciones.
                    </Text>
                  </AlertDescription>
                </AlertContent>
              </Alert>
            </Flex>
            <Card>
              <CardContent
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 12,
                }}
              >
                <Flex gap={12}>
                  <Text bold>Línea de captura: </Text>{' '}
                  <Text color={`${!isCaptureLineValid ? 'error500' : 'black'}`}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {declaration_document?.capture_line
                      ? !isCaptureLineValid
                        ? 'Línea de captura vencida'
                        : declaration_document?.capture_line
                      : 'NA'}{' '}
                  </Text>
                </Flex>
                <Flex gap={12}>
                  <Text bold>Importe total a pagar: </Text>{' '}
                  <Text bold>
                    {toCurrency(
                      +total_to_pay - +total_payroll_withholdings_to_pay,
                    )}
                  </Text>
                </Flex>
                <Flex gap={12}>
                  <Text bold>Vigente hasta: </Text>{' '}
                  <Text color={`${!isCaptureLineValid ? 'error500' : 'black'}`}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {declaration_document?.capture_line
                      ? !isCaptureLineValid
                        ? 'Línea de captura vencida'
                        : declaration_document?.validation_date
                      : 'NA'}
                  </Text>
                </Flex>
              </CardContent>
            </Card>
            {hasPayrollRetentions && (
              <Card>
                <CardContent
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 12,
                  }}
                >
                  <Flex gap={12}>
                    <Text bold>Línea de captura para salarios: </Text>{' '}
                    <Text
                      color={`${
                        !isSalaryCaptureLineValid ? 'error500' : 'black'
                      }`}
                    >
                      {!isSalaryCaptureLineValid
                        ? 'Línea de captura vencida'
                        : declaration_document.salary_capture_line}{' '}
                    </Text>
                  </Flex>
                  <Flex gap={12}>
                    <Text bold>Importe total a pagar: </Text>{' '}
                    <Text bold>
                      {toCurrency(+total_payroll_withholdings_to_pay)}{' '}
                    </Text>
                  </Flex>
                  <Flex gap={12}>
                    <Text bold>Vigente hasta: </Text>{' '}
                    <Text
                      color={`${
                        !isSalaryCaptureLineValid ? 'error500' : 'black'
                      }`}
                    >
                      {!isSalaryCaptureLineValid
                        ? 'Línea de captura vencida'
                        : declaration_document.salary_validation_date}{' '}
                    </Text>
                  </Flex>
                </CardContent>
              </Card>
            )}
          </Flex>
        </div>
      </div>
    </WorkflowLayout>
  );
}

export default CaptureLinePaymentResico;
