import ResponsiveCenterContainer from 'shared/components/ResponsiveCenterContainer';
import { Button, Flex, Text } from '@konta/ui';

interface GlobalErrorProps {
  handleOnReportClick: () => void;
}

export default function GlobalError({ handleOnReportClick }: GlobalErrorProps) {
  return (
    <ResponsiveCenterContainer>
      <Flex column itemsCenter gap={12}>
        <Text as="h2"> ¡Perdón, no eres tu, soy yo!</Text>
        <Text x6l>😣</Text>
        <Text as="h1" bold x7l>
          500
        </Text>
        <Text l css={{ textAlign: 'center' }}>
          Estamos experimentando un error de la plataforma, por favor deja un
          feedback para ayudarnos a resolver el problema.
        </Text>
        <Button
          onClick={handleOnReportClick}
          color="primary"
          css={{ mt: '$18' }}
        >
          Feedback
        </Button>
      </Flex>
    </ResponsiveCenterContainer>
  );
}
