import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('El correo electrónico debe ser válido')
    .nullable()
    .required('Campo requerido'),
  password: yup.string().nullable().required('Campo requerido'),
});

const defaultInitialValues = {
  email: '',
  password: '',
};

export type LoginFormValues = yup.InferType<typeof validationSchema>;

function useInitialValues(): LoginFormValues {
  return defaultInitialValues;
}

interface UseLoginFormProps {
  onSubmitForm: (payload: LoginFormValues) => void;
}
export default function useLoginForm({ onSubmitForm }: UseLoginFormProps) {
  const initialValues = useInitialValues();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmitForm(values);
    },
  });

  return {
    formik,
  };
}
