import { StateCreator } from 'zustand';
import { IStoreState, ITaxableEntitySlice } from 'backoffice/store/types';

const initialState = {
  taxableEntity: null,
};
const createTaxableEntitySlice: StateCreator<
  IStoreState,
  [],
  [],
  ITaxableEntitySlice
> = (set) => ({
  ...initialState,
  setTaxableEntity: (taxableEntity) => set({ taxableEntity }),
});

export default createTaxableEntitySlice;
