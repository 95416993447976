import React from 'react';
import {
  DebtsCardOrder,
  OnboardingHomeCard,
  PlatformWorkflowsCard,
} from '@containers';
import { Flex } from '@konta/ui';

import { useTaxableEntity } from '@hooks';
import UnfinishedProcesses from '@components/UnfinishedProcesses';

function Home() {
  const { taxable_entity } = useTaxableEntity();

  const hasFirstInformation = taxable_entity.status !== 'demo';

  return (
    <Flex direction="column" css={{ background: '#fff', width: '100%' }}>
      <UnfinishedProcesses />
      <DebtsCardOrder title="Pendiente de pago" />
      {hasFirstInformation ? <PlatformWorkflowsCard /> : <OnboardingHomeCard />}
    </Flex>
  );
}

export default Home;
