import { styled } from '@konta/ui';

export const MetricTotalContainer = styled('div', {
  display: 'flex',
  padding: '$12 0',
  alignItems: 'center',
  alignSelf: 'stretch',
});

export const MetricTotalContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: 1,
});

export const MetricTotalContentTitle = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
});
