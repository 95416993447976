import { InvoiceTemplate } from 'types/entities';
import useApiPagination, {
  UseApiPaginationOptions,
} from 'shared/hooks/useApiPagination';

type UseInvoiceTemplatesOptions<T> = Omit<
  UseApiPaginationOptions<T>,
  'endpoint'
>;

export default function useInvoiceTemplates<T>({
  itemsPerPage = 10,
  params,
  filters,
}: UseInvoiceTemplatesOptions<T>) {
  const { items, loading, ...rest } = useApiPagination<InvoiceTemplate>({
    endpoint: 'invoice_drafts',
    itemsPerPage,
    params,
    filters,
  });

  return {
    invoiceTemplatesLoading: loading,
    invoiceTemplates: items,
    ...rest,
  };
}
