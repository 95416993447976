import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as analytics from '@util/analytics';

export default function useFeature(
  feature: string,
  { source = '' } = {},
): [boolean] {
  const flags = useFlags();
  const featureEnabled = flags[feature] as boolean;
  useEffect(() => {
    if (source) {
      analytics.track('Feature Viewed', {
        [`experiment_${feature}`]: featureEnabled,
        [`experiment_source`]: source,
      });
    }
  }, [feature, featureEnabled, flags, source]);

  return [featureEnabled];
}
