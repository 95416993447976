import { call, put } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { fetchCFDISSuccess, fetchCFDISFailure } from '../actions';

export default function* ({ payload: { fiscal_period } }) {
  try {
    if (fiscal_period) {
      const access_token = localStorage.getItem('user_id');
      const response = yield call(
        api.get,
        `fiscal_periods/${fiscal_period.id}/cfdis`,
        { headers: { Authorization: `${access_token}` } }
      );
      yield call(StatusHandler, {
        status: response.status,
        response,
        success: fetchCFDISSuccess,
        failure: fetchCFDISFailure,
      });
    } else {
      yield put(fetchCFDISSuccess([]));
    }
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: fetchCFDISFailure,
    });
  }
}
