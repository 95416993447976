import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  bankTransactionsDeclarationsBulkUpdateFailure,
  bankTransactionsDeclarationsBulkUpdateSuccess,
} from '../actions';

export default function* ({ payload: { body } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `bank_transactions_declarations/bulk_update`,
      // axios.put,
      // "https://1e36ca2e-f616-4c3f-9797-6b42f4a4f4cb.mock.pstmn.io/api/v1/bank_transactions_declarations/bulk_update",
      body,
      { headers: { Authorization: `${access_token}` } }
    );

    yield call(StatusHandler, {
      response,
      title: 'Exito!',
      message: 'Moivimientos de banco correctamnete editados',
      status: response.status,
      success: bankTransactionsDeclarationsBulkUpdateSuccess,
      failure: bankTransactionsDeclarationsBulkUpdateFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      message: 'Algo salió mal al editar el gasto',
      action: bankTransactionsDeclarationsBulkUpdateFailure,
    });
  }
}
