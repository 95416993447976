import { useNavigate } from 'react-router-dom';
import { Text, Flex, Button, Divider } from '@konta/ui';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import { Process } from 'types/entities';
import useProcess from '@hooks/useProcess';
import { NotificationManager } from '@components/Notifications';
import { PROCESSES_HOME_ROUTE } from '@constants/process';

interface UnfinishedOnboardingProps {
  process: Process;
}

export default function UnfinishedOnboarding({
  process,
}: UnfinishedOnboardingProps) {
  const navigate = useNavigate();
  const { activeStepRoute } = useProcess(process.type);

  const handleClick = () => {
    if (process?.completed) {
      navigate(PROCESSES_HOME_ROUTE);
      return;
    }
    if (!activeStepRoute) {
      NotificationManager.error(
        'Ocurrió un error al abrir el onboarding, por favor contacta a soporte.',
        'Apertura de cuenta',
      );
      return;
    }
    navigate(activeStepRoute);
  };

  return (
    <>
      <Flex direction="column" gap={12}>
        <Text x3l bold>
          Continua la apertura de tu cuenta.
        </Text>
        <Text l color="gray700">
          Para brindarte el servicio que pagaste, es importante seguir adelante
          con el proceso de apertura de cuenta. Haz clic en el botón
          &quot;Continuar apertura de cuenta&quot; para continuar.
        </Text>
        <div>
          <Button
            rightIcon={<ArrowRightIcon />}
            onClick={handleClick}
            color="primary"
            css={{ mt: '$12' }}
            data-testid="OnboardingProcessUnfinished"
          >
            Continuar apertura de cuenta
          </Button>
        </div>
      </Flex>
      <Divider />
    </>
  );
}
