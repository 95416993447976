import dayjs from 'dayjs';

export const getYearWithMonths = (
  current_date = dayjs().format('YYYY-MM-DD')
) => {
  const start_date = dayjs(current_date).startOf('year');

  const arr_year_months = [...Array(1)].map((_, i) => {
    const months = 12;
    const children = [...Array(months)].map((_, m) => {
      return {
        label: dayjs(start_date.endOf('year'))
          .subtract(i, 'years')
          .subtract(m, 'month')
          .startOf('month')
          .format('MMM'),
        value: dayjs(start_date.endOf('year'))
          .subtract(i, 'years')
          .subtract(m, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        number: dayjs(start_date.endOf('year'))
          .subtract(i, 'years')
          .subtract(m, 'month')
          .startOf('month')
          .format('MM'),
      };
    });
    return {
      year: dayjs(start_date).format('YYYY'),
      months: children,
    };
  });

  return arr_year_months;
};
