// eslint-disable-next-line import/prefer-default-export
export const containerCss = {
  width: '100%',
  height: '100%',
  iframe: {
    width: '100%',
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
};
