import { ThemeColors } from '@util/ThemeColors';

const colors = ThemeColors();

export const chartTooltip = {
  backgroundColor: ThemeColors().foregroundColor,
  titleFontColor: ThemeColors().primaryColor,
  borderColor: ThemeColors().separatorColor,
  borderWidth: 0.5,
  bodyFontColor: ThemeColors().primaryColor,
  bodySpacing: 10,
  padding: 15,
  cornerRadius: 0.15,
  mode: 'nearest',
  intersect: false,
};

export const chartThemes = (fill) => [
  {
    borderColor: colors.themeColor1,
    pointBackgroundColor: colors.foregroundColor,
    pointBorderColor: colors.themeColor1,
    pointHoverBackgroundColor: colors.themeColor1,
    pointHoverBorderColor: colors.foregroundColor,
    pointRadius: 6,
    pointBorderWidth: 2,
    pointHoverRadius: 8,
    backgroundColor: colors.themeColor1,
    fill,
  },
  {
    borderColor: '#c43d4b',
    pointBackgroundColor: colors.foregroundColor,
    pointBorderColor: '#c43d4b',
    pointHoverBackgroundColor: '#c43d4b',
    pointHoverBorderColor: colors.foregroundColor,
    pointRadius: 6,
    pointBorderWidth: 2,
    pointHoverRadius: 8,
    backgroundColor: '#c43d4b',
    fill,
  },
  {
    borderColor: colors.themeColor2,
    pointBackgroundColor: colors.foregroundColor,
    pointBorderColor: colors.themeColor2,
    pointHoverBackgroundColor: colors.themeColor2,
    pointHoverBorderColor: colors.foregroundColor,
    pointRadius: 6,
    pointBorderWidth: 2,
    pointHoverRadius: 8,
    backgroundColor: colors.themeColor2,
    fill,
  },
  {
    borderColor: colors.themeColor1_10,
    pointBackgroundColor: colors.foregroundColor,
    pointBorderColor: colors.themeColor1_10,
    pointHoverBackgroundColor: colors.themeColor1_10,
    pointHoverBorderColor: colors.foregroundColor,
    pointRadius: 6,
    pointBorderWidth: 2,
    pointHoverRadius: 8,
    backgroundColor: colors.themeColor1_10,
    fill,
  },
];

export const lineChartConfig = {
  themeX_primary: chartThemes(true)[0],
  themeY: {
    gridLines: {
      display: true,
      lineWidth: 1,
      color: 'rgba(0,0,0,0.1)',
      drawBorder: false,
    },
  },
  legend: {
    display: false,
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: chartTooltip,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        gridLines: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false,
        },
        beginAtZero: true,
        min: 50,
        max: 70,
        ticks: {
          stepSize: 5,
          padding: 20,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  },
  data: {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: '',
        data: [54, 63, 60, 65, 60, 68, 60],
        borderColor: colors.themeColor1,
        pointBackgroundColor: colors.foregroundColor,
        pointBorderColor: colors.themeColor1,
        pointHoverBackgroundColor: colors.themeColor1,
        pointHoverBorderColor: colors.foregroundColor,
        pointRadius: 6,
        pointBorderWidth: 2,
        pointHoverRadius: 8,
        fill: false,
      },
    ],
  },
};

export const barChartConfig = {
  legend: {
    position: 'bottom',
    labels: {
      padding: 30,
      usePointStyle: true,
      fontSize: 12,
    },
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            lineWidth: 1,
            color: 'rgba(0,0,0,0.1)',
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: 100,
            min: 300,
            max: 800,
            padding: 20,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: chartTooltip,
  },
  data: {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Cakes',
        borderColor: colors.themeColor1,
        backgroundColor: colors.themeColor1_10,
        data: [456, 479, 324, 569, 702, 600],
        borderWidth: 2,
      },
      {
        label: 'Desserts',
        borderColor: colors.themeColor2,
        backgroundColor: colors.themeColor2_10,
        data: [364, 504, 605, 400, 345, 320],
        borderWidth: 2,
      },
    ],
  },
};
