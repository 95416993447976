import { styled } from '@konta/ui';

export const Root = styled('div', {
  padding: '0 32px 20px 32px',
});

export const Form = styled('form', {
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const Textarea = styled('textarea', {
  height: '100%',
  borderRadius: '8px',
  border: '1px solid $gray300',
  outline: 'none',
  flex: 1,
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  background: '$white',
  padding: '10px 14px',
  color: '$gray900',
  fontSize: '16px',
  lineHeight: '24px',
  '&::placeholder': {
    color: '$gray500',
  },
});
