import {
  CONTACT_METHOD_FETCH,
  CONTACT_METHOD_FETCH_SUCCESS,
  CONTACT_METHOD_FETCH_FAILURE,
  CONTACT_METHOD_UPDATE,
  CONTACT_METHOD_UPDATE_SUCCESS,
  CONTACT_METHOD_UPDATE_FAILURE,
  CONTACT_METHOD_DELETE,
  CONTACT_METHOD_DELETE_SUCCESS,
  CONTACT_METHOD_DELETE_FAILURE,
  CONTACT_METHOD_POST,
  CONTACT_METHOD_POST_SUCCESS,
  CONTACT_METHOD_POST_FAILURE,
} from '@constants/actionTypes';

export const contactMethodFetch = () => ({
  type: CONTACT_METHOD_FETCH,
});

export const contactMethodFetchSuccess = (payload) => ({
  type: CONTACT_METHOD_FETCH_SUCCESS,
  payload,
});

export const contactMethodFetchFailure = (payload) => ({
  type: CONTACT_METHOD_FETCH_FAILURE,
  payload,
});

export const contactMethodPost = (payload) => {
  return {
    type: CONTACT_METHOD_POST,
    payload,
  };
};

export const contactMethodPostSuccess = (payload) => ({
  type: CONTACT_METHOD_POST_SUCCESS,
  payload,
});

export const contactMethodPostFailure = (payload) => ({
  type: CONTACT_METHOD_POST_FAILURE,
  payload,
});

export const contactMethodUpdate = (payload) => ({
  type: CONTACT_METHOD_UPDATE,
  payload,
});

export const contactMethodUpdateSuccess = (payload) => ({
  type: CONTACT_METHOD_UPDATE_SUCCESS,
  payload,
});

export const contactMethodUpdateFailure = (payload) => ({
  type: CONTACT_METHOD_UPDATE_FAILURE,
  payload,
});

export const contactMethodDelete = (payload) => ({
  type: CONTACT_METHOD_DELETE,
  payload,
});

export const contactMethodDeleteSuccess = (payload) => ({
  type: CONTACT_METHOD_DELETE_SUCCESS,
  payload,
});

export const contactMethodDeleteFailure = (payload) => ({
  type: CONTACT_METHOD_DELETE_FAILURE,
  payload,
});
