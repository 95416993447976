import { DataTable } from '@konta/ui';
import useEmittedCfdis from './useEmittedCfdis';

interface EmittedInvoicesProps {
  setCfdi: (cfdi: any) => void;
  setTogglePdf: () => void;
  setToggleComplement: () => void;
  handleShowReports: (original: any) => void;
}

export default function EmittedInvoices({
  setCfdi,
  setTogglePdf,
  setToggleComplement,
  handleShowReports,
}: EmittedInvoicesProps) {
  const { columns, pagination, cfdis, cfdisLoading, setFilters } =
    useEmittedCfdis({
      setCfdi,
      setTogglePdf,
      setToggleComplement,
      handleShowReports,
    });
  return (
    <DataTable
      initialColumnPinning={{
        right: ['actionsBtn'],
      }}
      manualPaginationProps={pagination}
      manualPagination
      data={cfdis}
      columns={columns}
      loading={cfdisLoading}
      noDataLabel="No hay facturas emitidas"
      dataTableId="emittedInvoicesV2"
      onFiltersChange={setFilters}
      withGroupingControl
    />
  );
}
