import { useCallback } from 'react';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { patchAnnualDeductions } from '@api/annualDeductions';
import { AnnualDeduction, AnnualDeductionPayload } from 'types/entities';
import { ANNUAL_DEDUCTION_LIMITS } from '@constants/reactQueries';

export default function useUpdateAnnualDeductions(
  axiosConfig?: AxiosRequestConfig<AnnualDeduction>,
  queryOptions?: UseMutationOptions<
    AnnualDeduction,
    unknown,
    AnnualDeductionPayload
  >,
) {
  const queryClient = useQueryClient();
  const updateDeclarationBalanceRequest = useCallback(
    (payload: AnnualDeductionPayload) =>
      patchAnnualDeductions(payload, axiosConfig),
    [axiosConfig],
  );
  return useMutation(updateDeclarationBalanceRequest, {
    ...queryOptions,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ANNUAL_DEDUCTION_LIMITS]);
    },
  });
}
