import { useState } from 'react';
import { Flex, OptionsSelect, CardOption } from '@konta/ui';
import dayjs from 'dayjs';
import { ArrowRightLineIcon } from '@konta/icons';
import useNextPhase from '@hooks/useNextPhase';
import WorkflowsLayout from '@components/WorkflowsLayout';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import PrevPhaseButton from '@components/PrevPhaseButton';
import AccumulatedEntriesLineSeriesByRegimes from 'shared/components/AccumulatedEntriesLineSeriesByRegimes';
import useExpensesByRegime from './useExpensesByRegime';
import { Header, Title, Root, SubtitleHighlight, Subtitle } from '../styled';
import EditExpenses from '../EditExpenses';

export default function ExpensesByRegime() {
  const {
    showEditExpense,
    toggleShowEditExpense,
    workflow,
    totalDeclarationExpensesFormatted,
    currentRegimes,
  } = useExpensesByRegime();
  const [modifyExpenses, setModifyExpenses] = useState([1]);
  const nextPhase = useNextPhase();

  const month = dayjs(workflow?.start_date).format('MMMM [de] YYYY');

  const handleShowEditExpenses = () => {
    if (modifyExpenses[0] === 2) {
      toggleShowEditExpense();
      return;
    }
    nextPhase({
      workflow: {
        next_phase: 'primary_phase',
      },
    });
  };

  return (
    <WorkflowsLayout
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton
            rightIcon={<ArrowRightLineIcon />}
            onClick={handleShowEditExpenses}
          />
        </>
      }
      title="Declaración mensual"
    >
      <Root>
        <Header>
          <Title>¿Estos son todos tus gastos de {month}?</Title>

          <Flex>
            <Subtitle>Total de gastos en {month}:</Subtitle>
            &nbsp;
            <SubtitleHighlight>
              {totalDeclarationExpensesFormatted} pesos + IVA.
            </SubtitleHighlight>
          </Flex>
        </Header>

        <AccumulatedEntriesLineSeriesByRegimes
          workflow={workflow}
          fiscalRegimes={currentRegimes}
          type="expenses"
        />
      </Root>
      <Flex
        css={{
          width: '50%',
          flexDirection: 'column',
          gap: '$12',
        }}
      >
        <OptionsSelect value={modifyExpenses} onChange={setModifyExpenses}>
          <CardOption
            value={1}
            header={
              <span data-testid="has-no-income-option">
                Si, mis gastos son correctos.
              </span>
            }
          />
          <CardOption
            value={2}
            header={
              <span data-testid="has-income-option">
                No, quiero modificar o revisar mis gastos.
              </span>
            }
          />
        </OptionsSelect>
      </Flex>
      <EditExpenses
        showEditExpense={showEditExpense}
        toggleShowEditExpense={toggleShowEditExpense}
      />
    </WorkflowsLayout>
  );
}
