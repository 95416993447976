import { FileWithPath } from 'react-dropzone';

/* export function getBase64FromFile(file: File | FileWithPath) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        console.log(reader.result);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
} */

export const getBase64FromFile = (file: File | FileWithPath): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export function getBase64FromFileObj(file: File | FileWithPath): Promise<{
  bytes: number[];
  base64StringFile: string;
  fileName: string;
  fileType: string;
}> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error('Error parsing file'));
    };
    reader.onload = function () {
      const bytes = Array.from(new Uint8Array(this.result as ArrayBufferLike));

      const base64StringFile = btoa(
        bytes.map((item) => String.fromCharCode(item)).join(''),
      );

      resolve({
        bytes,
        base64StringFile,
        fileName: file.name,
        fileType: file.type,
      });
    };
    reader.readAsArrayBuffer(file);
  });
}
