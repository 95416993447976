import { FeaturedSearchIcon } from '@konta/icons';
import { Flex, Icon, Text } from '@konta/ui';

interface TaskEmptyStateProps {
  tab: 'requiredAction' | 'pending' | 'completed';
}

export default function TaskEmptyState({ tab }: TaskEmptyStateProps) {
  const title = {
    requiredAction: 'No hay tareas que requieran tu atención',
    pending: 'No hay tareas que estén en espera de Konta',
    completed: 'No hay tareas completadas',
  }[tab];

  const subtitle = {
    requiredAction: 'Aquí encontrarás las tareas que requieren tu atención.',
    pending:
      'Aquí encontrarás las tareas que están en espera de Konta para ser completadas.',
    completed: 'Aquí encontrarás las tareas que ya completaste.',
  }[tab];
  return (
    <Flex
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        alignSelf: 'stretch',
      }}
    >
      <Icon
        css={{
          width: '48px',
          height: '48px',
          svg: {
            width: 'auto',
            height: 'auto',
            path: {
              fill: 'none',
            },
          },
        }}
      >
        <FeaturedSearchIcon />
      </Icon>
      <Flex column gap={8} align="center" justify="center">
        <Text bold l>
          {title}
        </Text>
        <Text color="gray500">{subtitle}</Text>
      </Flex>
    </Flex>
  );
}
