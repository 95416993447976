import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { INVOICE_DRAFT } from '@constants/reactQueries';
import getInvoiceDraft from '@api/getInvoiceDraft';
import { Cfdi } from '../types/cfdi';

export default function useInvoiceDraftQuery(
  draftId: number | string,
  axiosConfig?: AxiosRequestConfig<Cfdi>,
  queryOptions?: UseQueryOptions<Cfdi>,
) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<Cfdi>(
    [INVOICE_DRAFT, draftId, axiosConfig],
    async () => getInvoiceDraft(draftId),
    queryOptions,
  );

  return {
    invoiceDraftLoading: isLoading || isFetching,
    invoiceDraft: data,
    ...rest,
  };
}
