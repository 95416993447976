import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { generateMonthNames, getMonthNameFromDate } from 'shared/util/grids';
import type { Workflow } from 'types/entities';
import type { Range } from 'types/grids';
import { getDataFromDeclarations } from 'shared/util/getDataFromDeclarations';
import useTaxableEntityPreferences from '@hooks/useTaxableEntityPreferences';

const FORMAT_MONTH = 'MMM';

export type PlatformsGrid = {
  totalIncomes: number[];
  totalExpenses: number[];
  profitOrLoss: number[];
  ivaInFavorOrAgainst: number[];
  isrToPay: number[];
  salaryRetentions: number[];
  totalLateFees: number[];
  totalToPay: number[];
  workflowId?: number;
  ivaInFavorFromPeriods: number[];
};

interface UseSingleSimplifiedCalculationProps {
  workflows: Workflow[];
  selectedWorkflow: Workflow | null;
  onHandleEntriesView: (
    monthIdx: string,
    type: 'income' | 'expense',
    selectedWorkflow: Workflow,
  ) => void;
}

export default function useSingleSimplifiedCalculation({
  workflows,
  selectedWorkflow,
  onHandleEntriesView,
}: UseSingleSimplifiedCalculationProps) {
  const startDate = dayjs(selectedWorkflow?.start_date).year();
  const { preferredRegimeSatKey } = useTaxableEntityPreferences();
  const selectedWorkflows = useMemo(
    () =>
      workflows.filter(
        (workflow) => dayjs(workflow.start_date).year() === startDate,
      ),
    [workflows, startDate],
  );

  const dataSet = useMemo(
    () =>
      generateMonthNames(FORMAT_MONTH).reduce<PlatformsGrid>(
        (acc, month) => {
          const workflow = selectedWorkflows.find(
            (wf) =>
              getMonthNameFromDate({
                date: wf.start_date,
                format: FORMAT_MONTH,
              }) === month,
          );

          const amounts = getDataFromDeclarations(
            workflow?.declarations || [],
            preferredRegimeSatKey,
            workflow,
          );

          acc.totalIncomes.push(amounts.totalIncome);
          acc.totalExpenses.push(amounts.totalExpense);
          acc.profitOrLoss.push(amounts.profitOrLoss);
          acc.ivaInFavorOrAgainst.push(amounts.ivaInFavorOrAgainst);
          acc.isrToPay.push(amounts.isrToPay);
          acc.salaryRetentions.push(amounts.salaryRetentions);
          acc.totalLateFees.push(amounts.totalLateFees);
          acc.totalToPay.push(amounts.totalToPay);
          acc.ivaInFavorFromPeriods.push(amounts.ivaInFavorFromPeriods);

          return acc;
        },
        {
          totalIncomes: [],
          totalExpenses: [],
          profitOrLoss: [],
          isrToPay: [],
          ivaInFavorOrAgainst: [],
          salaryRetentions: [],
          totalLateFees: [],
          totalToPay: [],
          ivaInFavorFromPeriods: [],
        },
      ),
    [selectedWorkflows, preferredRegimeSatKey],
  );

  const onSelectionChanged = useCallback(
    (range: Range[]) => {
      const {
        first: { column, row },
      } = range[0];
      const monthIdx = column.idx - 1;
      if (monthIdx < 0 || monthIdx > 11 || row.idx < 1 || row.idx > 2) {
        return;
      }

      const month = column.columnId as string;

      const workflow = selectedWorkflows.find(
        (wf) =>
          getMonthNameFromDate({
            date: wf.start_date,
            format: FORMAT_MONTH,
          }) === month,
      ) as Workflow;

      const entryType = row.idx === 2 ? 'expense' : 'income';

      onHandleEntriesView(`${monthIdx}`, entryType, workflow);
    },
    [onHandleEntriesView, selectedWorkflows],
  );

  return {
    dataSet,
    onSelectionChanged,
  };
}
