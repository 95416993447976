import { ReactNode, useMemo } from 'react';
import { Flex, Text, Divider, Alert, Spacer } from '@konta/ui';
import { ProcessType, AnnualStep } from 'types/entities';
import toCurrency from '@util/toCurrency';
import { CSS } from '@konta/ui/dist/stitches.config';
import useAnnualOrder from '@hooks/useAnnualOrder';
import useProcess from '@hooks/useProcess';
import Loader from '@components/Loader';

interface DescriptionTextProps {
  bold?: boolean;
  children: ReactNode;
  css?: CSS;
}
function DescriptionText({ bold, children, css = {} }: DescriptionTextProps) {
  return (
    <Text
      color={bold ? 'gray900' : 'gray600'}
      css={css}
      s
      lineHeight="s"
      medium
    >
      {children}
    </Text>
  );
}

function SectionDivider() {
  return (
    <Flex
      css={{ padding: '10px 0 0', hr: { marginBottom: '0' } }}
      direction="column"
    >
      <Divider />
    </Flex>
  );
}

export default function AnnualCheckout() {
  const { steps } = useProcess(ProcessType.AnnualDeclarationProcess);

  const orderId = (steps as AnnualStep).welcome?.answers.order_id;

  const { annualOrder, annualOrderLoading, isError } = useAnnualOrder(orderId);

  const order = useMemo(() => {
    if (!annualOrder) {
      return null;
    }
    const subtotal = annualOrder.total / 1.16;
    const totalIva = annualOrder.total - subtotal;
    return {
      subtotal,
      totalIva,
      total: annualOrder.total,
      discount: annualOrder.discount,
    };
  }, [annualOrder]);

  if (annualOrderLoading) {
    return <Loader />;
  }
  if (isError || !order) {
    return (
      <Flex>
        <Alert color="error" css={{ flex: 1 }}>
          Ocurrió un error al cargar la orden de pago
          <Spacer />
          Por favor intenta recargar la página.
        </Alert>
      </Flex>
    );
  }

  return (
    <Flex
      css={{
        padding: '16px',
        border: '1px solid #EAECF0',
        borderRadius: '8px',
        width: '100%',
      }}
      direction="column"
    >
      <Flex css={{ padding: '8px 0 0' }} justify="between">
        <Flex direction="column">
          <DescriptionText bold>Declaración Anual</DescriptionText>
          <Text xs lineHeight="xs" color="gray500">
            Cantidad 1
          </Text>
        </Flex>
        <Flex direction="column" align="end">
          <DescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
            {toCurrency(+order.subtotal)} MXN
          </DescriptionText>
          <Text xs lineHeight="xs" color="gray500" css={{ textAlign: 'end' }}>
            {toCurrency(+order.subtotal)} MXN por unidad
          </Text>
        </Flex>
      </Flex>
      <Flex css={{ padding: '24px 0 0' }} justify="between">
        <DescriptionText bold>Subtotal</DescriptionText>
        <DescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
          {toCurrency(+order.subtotal)} MXN
        </DescriptionText>
      </Flex>
      <SectionDivider />
      <Flex css={{ padding: '14px 0 0' }} justify="between">
        <DescriptionText bold css={{ flex: 1 }}>
          Total antes de impuestos
        </DescriptionText>
        <DescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
          {toCurrency(+order.subtotal)} MXN
        </DescriptionText>
      </Flex>
      <Flex css={{ padding: '14px 0 0' }} justify="between">
        <DescriptionText>Impuestos (16%)</DescriptionText>
        <DescriptionText css={{ flex: 1, textAlign: 'end' }}>
          {toCurrency(+order.totalIva)} MXN
        </DescriptionText>
      </Flex>
      {order.discount > 0 && (
        <Flex css={{ padding: '14px 0 0' }} justify="between">
          {/* <DescriptionText css={{ flex: 1 }}>
            Descuento {invoice.discountText}
          </DescriptionText> */}
          <DescriptionText css={{ flex: 1, textAlign: 'end' }}>
            -{toCurrency(+order.discount)} MXN
          </DescriptionText>
        </Flex>
      )}
      <SectionDivider />
      <Flex css={{ padding: '14px 0 0' }} justify="between">
        <DescriptionText bold>Total</DescriptionText>
        <DescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
          {toCurrency(+order.total)} MXN
        </DescriptionText>
      </Flex>
    </Flex>
  );
}
