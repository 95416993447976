import dayjs from 'dayjs';
import memoize from 'lodash/memoize';
import type { FiscalRegime } from 'types/entities';

const filterActiveRegimes = (regimes: FiscalRegime[] = []): FiscalRegime[] =>
  regimes.filter(
    (regime) => !regime?.end_date || dayjs(regime.end_date).isAfter(dayjs()),
  );

export default memoize(filterActiveRegimes, (regimes) =>
  JSON.stringify(regimes),
);
