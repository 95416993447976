import { Select } from '@konta/ui';
import type { Cfdi, Workflow } from 'types/entities';
import ConfirmModal from '@components/Modal/ConfirmModal';
import useDeclarationEntryFromCfdiModalForm from './useDeclarationEntryFromCfdiModalForm';

interface DeclarationEntryFromCfdiModalFormProps {
  toggleIsOpen: () => void;
  isOpen: boolean;
  cfdis: Cfdi[];
  regimeOptionsWithDeclarationId: {
    label: string;
    value: string;
    declarationId: number;
  }[];
  workflow: Workflow | null;
  refetchCfdis?: () => void;
}

export default function DeclarationEntryFromCfdiModalForm({
  toggleIsOpen,
  isOpen,
  cfdis,
  regimeOptionsWithDeclarationId,
  workflow,
  refetchCfdis,
}: DeclarationEntryFromCfdiModalFormProps) {
  const {
    titleText,
    selectedRegime,
    bulkCreateDeclarationEntries,
    setSelectedRegime,
    isLoading,
    isDisabledFiscalRegimeDropdown,
  } = useDeclarationEntryFromCfdiModalForm({
    workflow,
    cfdis,
    toggleIsOpen,
    refetchCfdis,
  });

  return (
    <ConfirmModal
      open={isOpen}
      title={titleText}
      onClose={toggleIsOpen}
      onCancel={toggleIsOpen}
      onAccept={bulkCreateDeclarationEntries}
      loading={isLoading}
      withBlur
      isAcceptBtnEnabled={!!selectedRegime}
      centered
    >
      <Select
        size="s"
        label="Régimen fiscal"
        isDisabled={isDisabledFiscalRegimeDropdown}
        placeholder="Selecciona régimen fiscal *"
        options={regimeOptionsWithDeclarationId}
        value={selectedRegime}
        onChange={(newValue) => {
          setSelectedRegime(newValue as typeof selectedRegime);
        }}
      />
    </ConfirmModal>
  );
}
