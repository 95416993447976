import React, { useEffect, useState, useMemo } from 'react';
import { Row, Button, Badge, Tooltip } from 'reactstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Flex, Text } from '@konta/ui';
import { Colxx, ButtonConfirmModal } from '@components';
// eslint-disable-next-line import/no-cycle
import { RetentionPpdInvoicesTable } from '@containers';
import { PlusCrFr1Icon, Eye2Icon } from '@konta/icons';
import toCurrency from '@util/toCurrency';
import PpdInvoicePreview from '@containers/Workflows/Phases/PpdInvoicePreview';
import {
  declarationEntriesFetch,
  fetchCfdisByPpds,
  addressFetch,
} from '@redux/actions';
import ComplementTableForm from '@components/ComplementTableForm';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import NextPhaseConfirmation from '@components/NextPhaseConfirmation';
import WorkflowHeader from '@components/WorkflowHeader';
import { useToggle } from 'rooks';

const payload = {
  workflow: {
    next_phase: 'primary_phase',
  },
};

export default function ClassifyIncomesResico() {
  const {
    selected_workflow: { workflow },
    cfdi_by_ppds,
  } = useSelector((state) => {
    return {
      ...state.workflows,
      ...state.cfdis,
      ...state,
    };
  }, shallowEqual);

  const ppdInvoices = useMemo(
    () => cfdi_by_ppds.filter((cfdi) => cfdi.status !== 'cancelled'),
    [cfdi_by_ppds],
  );

  const dispatch = useDispatch();

  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [toggleClassifyPpdModal, setToggleClassifyPpeModal] = useToggle(false);

  useEffect(() => {
    dispatch(declarationEntriesFetch({ workflow_id: workflow.id }));
    dispatch(fetchCfdisByPpds());
  }, [workflow.id, dispatch]);

  useEffect(() => {
    dispatch(addressFetch());
  }, [dispatch]);

  const complementNumberFilter = (rows, id, filterValue) => {
    return rows.filter((row) => {
      return filterValue
        ? row.values.declaration_entry_payments.length === 0
        : row.values.declaration_entry_payments;
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Emisor',
        accessor: 'client_supplier.legal_name',
        cellClass: 'cell-small-text',
        width: 150,
        Cell: ({ value }) => <p className="list-item-heading"> {value} </p>,
      },
      {
        Header: 'RFC',
        accessor: 'client_supplier.rfc',
        width: 120,
        cellClass: 'cell-small-text',
        Cell: ({ value }) => <p className="list-item-heading"> {value} </p>,
      },
      {
        Header: 'Fecha',
        accessor: 'date_issued',
        width: 100,
        cellClass: 'cell-small-text',
        Cell: ({ value }) => <p className="list-item-heading"> {value} </p>,
      },
      {
        Header: 'Total',
        accessor: 'total',
        width: 90,
        cellClass: 'cell-small-text',
        Cell: ({ value }) => (
          <p className="list-item-heading"> {toCurrency(+value)} </p>
        ),
      },
      {
        Header: 'Total por saldar',
        accessor: 'total_to_pay',
        width: 100,
        cellClass: 'cell-small-text',
        Cell: ({ row }) => {
          const { declaration_entry_payments } = row.original;
          let totalComplementsPaid = 0;
          declaration_entry_payments.forEach((complement) => {
            if (
              complement?.source.payment_detail.cfdi.status !== 'cancelled' &&
              complement?.source.payment_detail.cfdi.status !==
                'cancellation_in_progress'
            )
              totalComplementsPaid += Number(
                complement?.source.payment_detail.amount_paid,
              );
          });

          const totalToPay = row.original.total - totalComplementsPaid;

          return (
            <p className="list-item-heading"> {toCurrency(+totalToPay)} </p>
          );
        },
      },
      {
        Header: 'Archivo',
        accessor: 'xml_url',
        width: 120,
        cellClass: 'cell-small-text',
        Cell: ({ row }) => {
          return (
            <ButtonConfirmModal
              className="w-100"
              header="Factura"
              bodyClassName="d-flex"
              buttonColor="primary"
              buttonClassName="new-btn mr-3"
              handleSubmit={() => {}}
              confirmButton="Cerrar"
              buttonLabel="Ver factura"
              isButtonDisabled={false}
              outline
              modalSize="lg"
              lineIcon={Eye2Icon}
            >
              <PpdInvoicePreview invoiceObject={row.original} />
            </ButtonConfirmModal>
          );
        },
      },
      {
        Header: 'Tipo',
        accessor: 'payment_method',
        width: 64,
        cellClass: 'cell-small-text',
        Cell: ({ value, row }) => {
          const [tooltipOpen, setTooltipOpen] = useState(false);
          const toggle = () => setTooltipOpen(!tooltipOpen);
          const tooltipText = {
            PPD: 'Pago en parcialidades o diferido',
          }[value];
          return (
            <>
              <Tooltip
                placement="left-start"
                isOpen={tooltipOpen}
                autohide={false}
                target={`ppd-${row.original.id}`}
                toggle={toggle}
                data-testid="tooltiptrest"
              >
                {tooltipText}
              </Tooltip>
              <Badge
                id={`ppd-${row.original.id}`}
                color="purple"
                className="cursor-pointer badge-clear"
              >
                {value}
              </Badge>
            </>
          );
        },
      },

      {
        Header: 'Estatus de pago',
        accessor: 'declaration_entry_payments',
        width: 100,
        filter: complementNumberFilter,
        maxWidth: 90,
        Cell: ({ row }) => {
          const { declaration_entry_payments } = row.original;

          let totalComplementsPaid = 0;
          declaration_entry_payments.forEach((complement) => {
            if (
              complement?.source.payment_detail.cfdi.status !== 'cancelled' &&
              complement?.source.payment_detail.cfdi.status !==
                'cancellation_in_progress'
            )
              totalComplementsPaid += Number(
                complement?.source.payment_detail.amount_paid,
              );
          });

          let ppdStatus;
          if (
            declaration_entry_payments.length > 0 &&
            (totalComplementsPaid < row.original.total) &
              (totalComplementsPaid !== 0)
          ) {
            ppdStatus = 'Incompleto';
          } else if (
            declaration_entry_payments.length > 0 &&
            totalComplementsPaid === row.original.total
          ) {
            ppdStatus = 'Pagado';
          } else if (
            declaration_entry_payments.length === 0 ||
            totalComplementsPaid === 0
          ) {
            ppdStatus = 'No pagado';
          } else {
            ppdStatus = 'Pagado';
          }

          return (
            <Badge
              id={`transaction-${row.original.id}`}
              color={
                {
                  Pagado: 'success',
                  Incompleto: 'warning',
                  'No pagado': 'light',
                }[ppdStatus]
              }
              className="cursor-pointer badge-clear"
            >
              {ppdStatus}
            </Badge>
          );
        },
      },
      {
        Header: '',
        accessor: 'actions',
        width: 115,
        cellClass: 'cell-small-text',
        Cell: ({ row }) => {
          return (
            <Button
              outline
              size="sm"
              className="new-btn mr-3"
              color="primary"
              // eslint-disable-next-line no-use-before-define
              onClick={() => openClassifyPpdModal(row.original)}
            >
              <PlusCrFr1Icon className="button-icon" />
              {row.original.payment_method === 'PPD' ? 'Agregar pago' : 'Pagar'}
            </Button>
          );
        },
      },
    ],
    [ppdInvoices],
  );

  const openClassifyPpdModal = (cfdi) => {
    const ppdIndex = ppdInvoices.findIndex((ppd) => {
      return ppd.id === cfdi.id;
    });
    setSelectedInvoice(ppdIndex);
    setToggleClassifyPpeModal();
  };

  const notPpdsCompleted = useMemo(() => {
    return ppdInvoices.some(
      ({ declaration_entry_payments }) =>
        declaration_entry_payments.length === 0,
    );
  }, [ppdInvoices]);

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Genera los complementos de pagos de tus facturas tipo PPD"
          description="Una factura en “Pago en Parcialidades o Diferidos” o PPD indica que la venta ya se realizó pero aún no haz recibido el pago, una vez recibas el pago tendrás que hacer un Complemento de Pago para indicarle al SAT que esa factura ya está pagada y pagar los impuestos correspondientes. 💰"
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          {notPpdsCompleted ? (
            <NextPhaseConfirmation
              payload={payload}
              trigger={({ onClick }) => (
                <PrimaryPhaseButton onClick={onClick}>
                  Continuar
                </PrimaryPhaseButton>
              )}
              title="Confirmación para continuar"
              cancelText=""
              acceptText="Continuar"
            >
              <Flex align="center" direction="column">
                <i className="large-icon iconsmind-Danger" color="danger" />
                <p>
                  ¿Deseas continuar aunque algunas de tus facturas aún no tengan
                  complementos de pago?
                </p>
              </Flex>
            </NextPhaseConfirmation>
          ) : (
            <PrimaryPhaseButton />
          )}
        </>
      }
    >
      <div>
        <Text x2l>
          ¡Vaya! Tienes facturas que aún no han sido cobradas completamente.
        </Text>
        <Text x2l>
          Utiliza esta vista para generar tus complementos de pago. 😎
        </Text>
      </div>

      <p className="text-muted">
        Para generar un complemento de pago haz click en “Agregar pago”.
      </p>

      <Flex direction="column">
        {ppdInvoices[selectedInvoice] && (
          <Row className="my-3">
            <Colxx md={12} className="d-flex mb-2">
              <div className="d-flex align-items-center">
                <ComplementTableForm
                  cfdi={ppdInvoices[selectedInvoice]}
                  toggle={toggleClassifyPpdModal}
                  toggleModal={setToggleClassifyPpeModal}
                  isWorkflow
                />
              </div>
            </Colxx>
          </Row>
        )}
        <Row>
          <Colxx sm={12} className="table-overflow">
            <RetentionPpdInvoicesTable
              columns={columns}
              complementNumberFilter={complementNumberFilter}
              data={ppdInvoices}
            />
          </Colxx>
        </Row>
      </Flex>
    </WorkflowLayout>
  );
}
