import { Button, Flex, Tooltip } from '@konta/ui';
import {
  LayoutBottomLineIcon,
  RefreshCcw02LineIcon,
  ChevronUpLineIcon,
  ChevronDownLineIcon,
  LayoutTopLineIcon,
  UserRight01LineIcon,
} from '@konta/icons';
import { impersonationLogin } from 'backoffice/util/auth';
import useCustomerHeaderActions from './useCustomerHeaderAction';
import { PaginationText } from './styled';

interface CustomerHeaderActionsProps {
  taxableEntityId: number;
}
export default function CustomerHeaderActions({
  taxableEntityId,
}: CustomerHeaderActionsProps) {
  const {
    collapsed,
    goToCustomers,
    toggleCollapsed,
    pagination,
    goToPreviousCustomer,
    goToNextCustomer,
    isLastPage,
  } = useCustomerHeaderActions(taxableEntityId);
  return (
    <Flex column>
      <Flex gap={6} itemsCenter>
        <PaginationText s medium isLastPage={isLastPage}>
          {pagination.currentPage} /
        </PaginationText>
        <PaginationText s medium>
          {pagination.total}
        </PaginationText>
        <Button
          size="xs"
          noFill
          icon
          variant="outlined"
          onClick={goToNextCustomer}
          disabled={!pagination.hasNextCustomer}
        >
          <ChevronUpLineIcon />
        </Button>
        <Button
          size="xs"
          noFill
          icon
          variant="outlined"
          onClick={goToPreviousCustomer}
          disabled={!pagination.hasPreviousCustomer}
        >
          <ChevronDownLineIcon />
        </Button>
        <Button
          size="xs"
          variant="minimal"
          noFill
          leftIcon={<RefreshCcw02LineIcon />}
          onClick={goToCustomers}
        >
          Cambiar cliente
        </Button>
        <Tooltip
          side="top"
          size="xs"
          triggerElement={
            <Button
              size="xs"
              icon
              noFill
              variant="text"
              onClick={() => {
                void impersonationLogin(taxableEntityId);
              }}
            >
              <UserRight01LineIcon />
            </Button>
          }
        >
          Iniciar sesión en la plataforma
        </Tooltip>
        <Tooltip
          side="top"
          size="xs"
          triggerElement={
            <Button
              size="xs"
              icon
              noFill
              variant="text"
              onClick={toggleCollapsed}
            >
              {collapsed ? <LayoutTopLineIcon /> : <LayoutBottomLineIcon />}
            </Button>
          }
        >
          {collapsed ? 'Expandir' : 'Colapsar'}
        </Tooltip>
      </Flex>
    </Flex>
  );
}
