// TODO: delete this file
// Don't use this, use "@constants/invoicing" instead

export const CURRENCY_DATA = [
  { label: 'Dólar estadounidense', value: 'USD', key: 24 },
  { label: 'Peso mexicano', value: 'MXN', key: 137 },
  { label: 'Euro', value: 'EUR', key: 150 },
  { label: 'Dólar canadiense', value: 'CAD', key: 27 },
  { label: 'Libra Esterlina', value: 'GBP', key: 153 },
];

export const TYPE_DATA = [
  { label: 'Ingreso', value: 'I', key: 0 },
  { label: 'Egreso', value: 'E', key: 1 },
  /*   { label: "Complemento de pago", value: "P", key: 2 },
  { label: "Nómina", value: "N", key: 3 },
  { label: "Traslado", value: "T", key: 4 }, */
];

export const PAYMENT_METHOD = [
  { label: 'Pago en una sola exhibición', value: 'PUE', key: 0 },
  { label: 'Pago en parcialidades o diferido', value: 'PPD', key: 1 },
];

export const UNIT_DATA = [
  { label: 'Pieza', value: 'H87', key: 0, id: 1 },
  { label: 'Elemento', value: 'EA', key: 1, id: 2 },
  { label: 'Unidad de Servicio', value: 'E48', key: 2, id: 3 },
  { label: 'Actividad', value: 'ACT', key: 3, id: 4 },
  { label: 'Kilogramo', value: 'KGM', key: 4, id: 5 },
  { label: 'Trabajo', value: 'E51', key: 5, id: 6 },
  { label: 'Tarifa', value: 'A9', key: 6, id: 7 },
  { label: 'Metro', value: 'MTR', key: 7, id: 8 },
  { label: 'Paquete a granel', value: 'AB', key: 8, id: 9 },
  { label: 'Caja base', value: 'BB', key: 9, id: 10 },
  { label: 'Kit', value: 'KT', key: 10, id: 11 },
  { label: 'Conjunto', value: 'SET', key: 11, id: 12 },
  { label: 'Litro', value: 'LTR', key: 12, id: 13 },
  { label: 'Caja', value: 'XBX', key: 13, id: 14 },
  { label: 'Mes ', value: 'MON', key: 14, id: 15 },
  { label: 'Hora', value: 'HUR', key: 15, id: 16 },
  { label: 'Metro cuadrado', value: 'MTK', key: 16, id: 17 },
  { label: 'Equipos', value: '11', key: 17, id: 18 },
  { label: 'Miligramo', value: 'MGM', key: 18, id: 19 },
  { label: 'Paquete', value: 'XPK', key: 19, id: 20 },
  { label: 'Conjunto de piezas', value: 'XKI', key: 20, id: 21 },
  { label: 'Variedad', value: 'AS', key: 21, id: 22 },
  { label: 'Gramo', value: 'GRM', key: 22, id: 23 },
  { label: 'Par', value: 'PR', key: 23, id: 24 },
  { label: 'Docenas de piezas', value: 'DPC', key: 24, id: 25 },
  { label: 'Unidad', value: 'XUN', key: 25, id: 26 },
  { label: 'Día', value: 'DAY', key: 26, id: 27 },
  { label: 'Lote', value: 'XLT', key: 27, id: 28 },
  { label: 'Grupos', value: '10', key: 28, id: 29 },
  { label: 'Mililitro', value: 'MLT', key: 29, id: 30 },
  { label: 'Viaje', value: 'E54', key: 30, id: 31 },
];

export const USE_CFDI = [
  { label: 'Adquisición de mercancias', value: 'G01', key: 1 },
  { label: 'Devoluciones, descuentos o bonificaciones', value: 'G02', key: 2 },
  { label: 'Gastos en general', value: 'G03', key: 2 },
  { label: 'Construcciones', value: 'I01', key: 3 },
  {
    label: 'Mobilario y equipo de oficina por inversiones',
    value: 'I02',
    key: 4,
  },
  { label: 'Equipo de transporte', value: 'I03', key: 5 },
  { label: 'Equipo de computo y accesorios', value: 'I04', key: 6 },
  {
    label: 'Dados, troqueles, moldes, matrices y herramental',
    value: 'I05',
    key: 7,
  },
  { label: 'Comunicaciones telefónicas', value: 'I06', key: 8 },
  { label: 'Comunicaciones satelitales', value: 'I07', key: 9 },
  { label: 'Otra maquinaria y equipo', value: 'I08', key: 10 },
  {
    label: 'Honorarios médicos, dentales y gastos hospitalarios.',
    value: 'D01',
    key: 11,
  },
  {
    label: 'Gastos médicos por incapacidad o discapacidad',
    value: 'D02',
    key: 12,
  },
  { label: 'Gastos funerales.', value: 'D03', key: 13 },
  { label: 'Donativos.', value: 'D04', key: 14 },
  {
    label:
      'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
    value: 'D05',
    key: 15,
  },
  { label: 'Aportaciones voluntarias al SAR.', value: 'D06', key: 16 },
  { label: 'Primas por seguros de gastos médicos.', value: 'D07', key: 17 },
  {
    label: 'Gastos de transportación escolar obligatoria.',
    value: 'D08',
    key: 18,
  },
  {
    label:
      'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
    value: 'D09',
    key: 19,
  },
  {
    label: 'Pagos por servicios educativos (colegiaturas)',
    value: 'D10',
    key: 20,
  },
  {
    label: 'Sin efectos fiscales',
    value: 'S01',
    key: 21,
  },
  {
    label: 'Pagos',
    value: 'CP01',
    key: 22,
  },
];

export const PAYMENT_TYPE = [
  { label: 'Efectivo', value: '01', key: 1 },
  { label: 'Cheque nominativo', value: '02', key: 2 },
  { label: 'Transferencia electrónica de fondos', value: '03', key: 3 },
  { label: 'Tarjeta de crédito', value: '04', key: 4 },
  { label: 'Monedero electrónico', value: '05', key: 5 },
  { label: 'Dinero electrónico', value: '06', key: 6 },
  { label: 'Vales de despensa', value: '08', key: 7 },
  { label: 'Dación en pago', value: '12', key: 8 },
  { label: 'Pago por subrogación', value: '13', key: 9 },
  { label: 'Pago por consignación', value: '14', key: 10 },
  { label: 'Condonación', value: '15', key: 11 },
  { label: 'Compensación', value: '17', key: 12 },
  { label: 'Novación', value: '23', key: 13 },
  { label: 'Confusión', value: '24', key: 14 },
  { label: 'Remisión de deuda', value: '25', key: 15 },
  { label: 'Prescripción o caducidad', value: '26', key: 16 },
  { label: 'A satisfacción del acreedor', value: '27', key: 17 },
  { label: 'Tarjeta de débito', value: '28', key: 18 },
  { label: 'Tarjeta de servicios', value: '29', key: 19 },
  { label: 'Aplicación de anticipos', value: '30', key: 20 },
  { label: 'Intermediario pagos', value: '31', key: 21 },
  { label: 'Por definir', value: '99', key: 22 },
];

export const IVA_OPTIONS = [
  { label: 'IVA 16%', value: 0.16, key: 1 },
  { label: 'IVA 8%', value: 0.08, key: 2 },
  { label: 'IVA 0%', value: 0, key: 3 },
  { label: 'IVA exento', value: null, key: 4 },
];

export const IVA_RET_OPTIONS = [
  /*   { label: "IVA RET 16%", value: 0.16, key: 1 },
  { label: "IVA RET 10.6668%", value: 0.106668, key: 2 }, */
  { label: 'IVA RET 10.6667%', value: 0.106667, key: 1 },
  /*   { label: "IVA RET 10.6666%", value: 0.106666, key: 4 }, */
  /*    { label: "IVA RET 10.67%", value: 0.1067, key: 5 },
  { label: "IVA RET 10.66%", value: 0.1066, key: 6 },
  { label: "IVA RET 10.6%", value: 0.106, key: 7 },
  { label: "IVA RET 10%", value: 0.1, key: 8 },
  { label: "IVA RET 8%", value: 0.08, key: 9 }, */
  { label: 'IVA RET 6%', value: 0.06, key: 10 },
  { label: 'IVA RET 3%', value: 0.03, key: 14 },
  { label: 'IVA RET 4%', value: 0.04, key: 13 },
  /* { label: "IVA RET 5%", value: 0.05, key: 11 },
  { label: "IVA RET 5.3333%", value: ".053333", key: 12 },
  { label: "IVA RET 2.5%", value: 0.025, key: 15 },
  { label: "IVA RET 2%", value: 0.02, key: 16 },
  { label: "IVA RET 0.7%", value: 0.007, key: 17 },
  { label: "IVA RET 0.5%", value: 0.005, key: 18 },
  { label: "IVA RET 0.2%", value: 0.002, key: 19 },  */
  { label: '-', value: 0, key: 2 },
];

export const IEPS_OPTIONS = [
  { label: 'IEPS 300%', value: 3, key: 1 },
  { label: 'IEPS 160%', value: 1.6, key: 2 },
  { label: 'IEPS 53%', value: 0.53, key: 3 },
  { label: 'IEPS 50%', value: 0.5, key: 4 },
  { label: 'IEPS 35%', value: 0.35, key: 5 },
  { label: 'IEPS 30.4%', value: 0.304, key: 6 },
  { label: 'IEPS 30%', value: 0.3, key: 7 },
  { label: 'IEPS 29.88%', value: 0.2988, key: 8 },
  { label: 'IEPS 26.5%', value: 0.265, key: 9 },
  { label: 'IEPS 25%', value: 0.25, key: 10 },
  { label: 'IEPS 9%', value: 0.09, key: 11 },
  { label: 'IEPS 8%', value: 0.08, key: 12 },
  { label: 'IEPS 7%', value: 0.07, key: 13 },
  { label: 'IEPS 6%', value: 0.06, key: 14 },
  { label: 'IEPS 5.91%', value: 0.0591, key: 15 },
  { label: 'IEPS 4%', value: 0.04, key: 16 },
  { label: '-', value: 0, key: 17 },
];

export const ISR_OPTIONS = [
  /*   { label: "ISR 20%", value: 0.2, key: 1 }, */
  { label: 'ISR 10%', value: 0.1, key: 1 },
  { label: 'ISR 1.25%', value: 0.0125, key: 2 },
  /*  { label: "ISR 2%", value: 0.02, key: 3 }, */
  { label: '-', value: 0, key: 3 },
];

export const FISCAL_REGIME = [
  {
    label: 'Sin obligaciones fiscales',
    value: '616',
    id: 'no_obligations',
    key: 0,
  },
  {
    label: 'Persona física con actividad empresarial',
    value: '612',
    id: 'business_activity',
    key: 1,
  },
  {
    label: 'Régimen de incorporación fiscal',
    value: '621',
    id: 'fiscal_incorporation',
    key: 2,
  },
  { label: 'Persona Moral', value: '601', id: 'legal_entity', key: 3 },
  {
    label:
      'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    value: '625',
    id: 'platform_activity',
    key: 4,
  },
  { label: 'Arrendamiento', id: 'lease', value: '606', key: 5 },
  {
    label: 'Régimen Simplificado de Confianza',
    value: '626',
    id: 'resico',
    key: 6,
  },
  {
    label: 'Persona moral con fines no lucrativos',
    value: '603',
    id: 'non_profit_legal_entity',
    key: 7,
  },
];

export const LEGAL_TYPES = {
  business_activity: '612',
  legal_entity: '601',
  fiscal_incorporation: '621',
};

export const FISCAL_RESIDENCES = [
  {
    value: 'USA',
    label: 'Estados Unidos',
    key: 1,
  },
  {
    value: 'CHN',
    label: 'China',
    key: 2,
  },
  {
    value: 'CAN',
    label: 'Canadá',
    key: 3,
  },
  {
    value: 'GBR',
    label: 'Reino Unido',
    key: 4,
  },
  {
    value: 'FRA',
    label: 'Francia',
    key: 5,
  },
  {
    value: 'ESP',
    label: 'España',
    key: 6,
  },
];

export const CANCELLATION_REASONS = [
  {
    value: '01',
    label: 'Comprobante emitido con errores con relación',
    key: 1,
  },
  {
    value: '02',
    label: 'Comprobante emitido con errores sin relación',
    key: 2,
  },
  {
    value: '03',
    label: 'No se llevó a cabo la operación',
    key: 3,
  },
  {
    value: '04',
    label: 'Operación nominativa relacionada en la factura global',
    key: 4,
  },
];

export const EXPORT_CODES = [
  {
    value: '01',
    label: 'No aplica',
    key: 1,
  },
  {
    value: '02',
    label: 'Definitiva',
    key: 2,
  },
  {
    value: '03',
    label: 'Temporal',
    key: 3,
  },
  {
    value: '04',
    label: 'Definitiva con clave distinta a A1',
    key: 4,
  },
];

export const TAXABLE_CODES = [
  {
    value: '01',
    label: 'No objeto de impuesto.',
    key: 1,
  },
  {
    value: '02',
    label: 'Sí objeto de impuesto.',
    key: 2,
  },
  {
    value: '03',
    label: 'Sí objeto de impuesto y no obligado al desglose.',
    key: 3,
  },
];

export const PERIODICITY_OPTIONS = [
  {
    value: '01',
    label: 'Diario',
    key: 1,
  },
  {
    value: '02',
    label: 'Semanal',
    key: 2,
  },
  {
    value: '03',
    label: 'Quincenal',
    key: 3,
  },
  {
    value: '04',
    label: 'Mensual',
    key: 4,
  },
  {
    value: '05',
    label: 'Bimestral',
    key: 5,
  },
];

export const PERIODICITY_MONTHS_OPTIONS = [
  {
    value: '01',
    label: 'Enero',
    key: 1,
  },
  {
    value: '02',
    label: 'Febrero',
    key: 2,
  },
  {
    value: '03',
    label: 'Marzo',
    key: 3,
  },
  {
    value: '04',
    label: 'Abril',
    key: 4,
  },
  {
    value: '05',
    label: 'Mayo',
    key: 5,
  },
  {
    value: '06',
    label: 'Junio',
    key: 6,
  },
  {
    value: '07',
    label: 'Julio',
    key: 7,
  },
  {
    value: '08',
    label: 'Agosto',
    key: 8,
  },
  {
    value: '09',
    label: 'Septiembre',
    key: 9,
  },
  {
    value: '10',
    label: 'Octubre',
    key: 10,
  },
  {
    value: '11',
    label: 'Noviembre',
    key: 11,
  },
  {
    value: '12',
    label: 'Diciembre',
    key: 12,
  },
  {
    value: '13',
    label: 'Enero-Febrero',
    key: 13,
  },
  {
    value: '14',
    label: 'Marzo-Abril',
    key: 14,
  },
  {
    value: '15',
    label: 'Mayo-Junio',
    key: 15,
  },
  {
    value: '16',
    label: 'Julio-Agosto',
    key: 16,
  },
  {
    value: '17',
    label: 'Septiembre-Octubre',
    key: 17,
  },
  {
    value: '18',
    label: 'Noviembre-Diciembre',
    key: 18,
  },
];
