import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCfdisByPpds } from '@redux/actions';
import { IRootState } from 'types/redux';
import { ColumnDef } from '@tanstack/react-table';
import { Cfdi } from 'types/entities';
import { ButtonConfirmModal } from '@components/Modal';
import { Eye2Icon, PlusCrFr1Icon } from '@konta/icons';
import PpdInvoicePreview from '@containers/Workflows/Phases/PpdInvoicePreview';
import { Button, Flex, Text } from '@konta/ui';
import toCurrency from '@util/toCurrency';
import { useToggle } from 'rooks';
import useWorkflow from '@components/Workflow/hooks/useWorkflow';

export default function useUploadInvoiceComplementModal() {
  const dispatch = useDispatch();
  const {
    updateAllWorkflow,
    updateCurrentWorkflow,
    taxableEntityPreferences,
    defaultRegimeOptionBySatkey,
  } = useWorkflow();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const toggleShowConfirmation = () => setShowConfirmation(!showConfirmation);

  const [selectedPpd, setSelectedPpd] = useState({});
  const [toggleOpenModal, setToggleOpenModal] = useToggle(false);

  useEffect(() => {
    dispatch(fetchCfdisByPpds());
  }, [dispatch]);
  const { cfdisLoading, ppds } = useSelector((state: IRootState) => ({
    ppds: state.cfdis.cfdi_by_ppds.filter(
      (cfdi) => cfdi.status !== 'cancelled',
    ),
    cfdisLoading: state.cfdis.loading,
  }));

  const togglePpdFormModal = useCallback(async () => {
    setSelectedPpd({});
    dispatch(fetchCfdisByPpds());
    await updateAllWorkflow();
  }, [updateAllWorkflow, dispatch]);
  const tableColumns = useMemo<ColumnDef<Cfdi>[]>(
    () => [
      {
        header: 'Emisor',
        label: 'Emisor',
        accessorKey: 'client_supplier.legal_name',
        size: 300,
      },
      {
        header: 'RFC',
        label: 'RFC',
        accessorKey: 'client_supplier.rfc',
        width: 120,
      },
      {
        header: 'Fecha',
        label: 'Fecha',
        accessorKey: 'date_issued',
      },
      {
        header: 'Total',
        label: 'Total',
        accessorKey: 'total',
        cell: ({ row }) => <Text> {toCurrency(+row.original.total)} </Text>,
      },
      {
        header: 'Total por saldar',
        label: 'Total por saldar',
        accessorKey: 'total_to_pay',
        cell: ({ row }) => {
          const { payment_details } = row.original;
          let totalComplementsPaid = 0;
          payment_details?.forEach((complement) => {
            if (
              complement?.cfdi.status !== 'cancelled' &&
              complement?.cfdi.status !== 'cancellation_in_progress'
            )
              totalComplementsPaid += Number(complement?.amount_paid);
          });

          const totalToPay = row.original.total - totalComplementsPaid;

          return <Text> {toCurrency(+totalToPay)} </Text>;
        },
      },

      {
        header: 'Archivo',
        label: 'Archivo',
        accessorKey: 'xml_url',
        cell: ({ row }) => (
          <ButtonConfirmModal
            header="Factura"
            bodyClassName="d-flex"
            buttonColor="primary"
            buttonClassName="new-btn mr-3"
            handleSubmit={() => {}}
            confirmButton="Cerrar"
            buttonLabel="Ver factura"
            isButtonDisabled={!row.original.xml_url}
            outline
            modalSize="lg"
            // @ts-ignore ignore default null value
            lineIcon={Eye2Icon}
          >
            {/* @ts-ignore ignore  PpdInvoicePreview boolean return */}
            <PpdInvoicePreview invoiceObject={row.original} />
          </ButtonConfirmModal>
        ),
      },
      {
        header: '',
        label: 'Acciones',
        accessorKey: 'actions',
        cell: ({ row }) => (
          <Flex>
            <Button
              size="xs"
              color="gray"
              // onClick={toggle}
              variant="outlined"
              leftIcon={<PlusCrFr1Icon />}
              // eslint-disable-next-line no-use-before-define
              onClick={() => setSelectedPpd(row.original)}
            >
              {row.original.payment_method === 'PPD' ? 'Agregar pago' : 'Pagar'}
            </Button>
          </Flex>
        ),
      },
      {
        header: 'Estatus de pago',
        label: 'Estatus de pago',
        accessorKey: 'declaration_entry_payments',
        cell: ({ row }) => {
          const { declaration_entry_payments } = row.original;

          let totalComplementsPaid = 0;
          declaration_entry_payments?.forEach((complement) => {
            if (
              complement?.source.payment_detail.cfdi.status !== 'cancelled' &&
              complement?.source.payment_detail.cfdi.status !==
                'cancellation_in_progress'
            )
              totalComplementsPaid += Number(
                complement?.source.payment_detail.amount_paid,
              );
          });

          let ppdStatus;
          if (
            (declaration_entry_payments || []).length > 0 &&
            totalComplementsPaid < row.original.total &&
            totalComplementsPaid !== 0
          ) {
            ppdStatus = 'Incompleto';
          } else if (
            (declaration_entry_payments || []).length > 0 &&
            totalComplementsPaid === row.original.total
          ) {
            ppdStatus = 'Pagado';
          } else if (
            (declaration_entry_payments || []).length === 0 ||
            totalComplementsPaid === 0
          ) {
            ppdStatus = 'No pagado';
          } else {
            ppdStatus = 'Pagado';
          }
          return ppdStatus;
        },
      },
    ],
    [],
  );

  const onCloseModal = () => {
    toggleShowConfirmation();
    updateCurrentWorkflow();
  };

  const isLoading = cfdisLoading;
  return {
    isLoading,
    ppds,
    showConfirmation,
    toggleShowConfirmation,
    tableColumns,
    selectedPpd,
    setSelectedPpd,
    togglePpdFormModal,
    toggleOpenModal,
    setToggleOpenModal,
    updateAllWorkflow,
    updateCurrentWorkflow,
    onCloseModal,
    taxableEntityPreferences,
    defaultRegimeOptionBySatkey,
  };
}
