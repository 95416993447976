import React from 'react';
import FlowContainer from './flow-container';

export default function Flows() {
  return (
    <div className="main-container">
      <FlowContainer />
    </div>
  );
}
