// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import _ from 'lodash';

const getValue = (value: unknown) => {
  if (_.isPlainObject(value) && 'value' in value && 'label' in value) {
    return value.value;
  }
  return value;
};

const setValues = (values: [], arr: []) => {
  values.forEach((v) => {
    const newValue = {
      id: v.id,
      value: getValue(v.value),
    };
    const index = arr.findIndex((p) => p.id === v.id);
    if (index !== -1) {
      arr[index] = newValue;
    } else {
      arr.push(newValue);
    }
  });
};

export default function setLocalStorageFilters<T>(
  key: string,
  value: T,
  options: { merge?: boolean } = { merge: true },
) {
  try {
    const { merge } = options || {};
    const prevValues = localStorage.getItem(key);
    let newValues;

    if (prevValues) {
      const parsedPrevValues = JSON.parse(prevValues) as T;

      if (Array.isArray(parsedPrevValues)) {
        newValues = merge ? [...parsedPrevValues] : [];
        if (Array.isArray(value)) {
          setValues(value, newValues);
        } else if (_.isPlainObject(value)) {
          setValues(
            Object.entries(value).map(([k, v]) => ({ id: k, value: v })),
            newValues,
          );
        }
      } else if (merge) {
        newValues = { ...parsedPrevValues, ...value };
      } else {
        newValues = value;
      }
    } else {
      newValues = value;
    }

    localStorage.setItem(key, JSON.stringify(newValues));

    const event = new StorageEvent('storage', {
      key,
      oldValue: prevValues,
      newValue: JSON.stringify(newValues),
      storageArea: localStorage,
    });

    window.dispatchEvent(event);
  } catch (error) {
    console.error(error);
  }
}
