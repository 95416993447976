import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
} from '@konta/ui';
import { ChevronDownLineIcon } from '@konta/icons';

interface FiltersProps {
  filters: Record<string, string>;
  setFilters: (filters: Record<string, string>) => void;
  graphsLoading?: boolean;
}
export default function Filters({
  filters,
  setFilters,
  graphsLoading,
}: FiltersProps) {
  return (
    <Flex gap={10} css={{ minWidth: '110px' }}>
      <DropdownMenu>
        <DropdownMenuTrigger disabled={graphsLoading} data-disabled asChild>
          <Button
            size="xs"
            variant="outlined"
            noFill
            rightIcon={<ChevronDownLineIcon />}
            loading={graphsLoading}
            disabled={graphsLoading}
          >
            {filters.type === 'accumulated' ? 'Acumulado' : 'Totales'}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={5}>
          <DropdownMenuItem
            size="xs"
            onSelect={() => {
              setFilters({ ...filters, type: 'accumulated' });
            }}
          >
            <Flex align="center">Acumulado</Flex>
          </DropdownMenuItem>
          <DropdownMenuItem
            size="xs"
            onSelect={() => {
              setFilters({ ...filters, type: 'day' });
            }}
          >
            <Flex align="center">Totales</Flex>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuTrigger disabled={graphsLoading} data-disabled asChild>
          <Button
            size="xs"
            variant="outlined"
            noFill
            rightIcon={<ChevronDownLineIcon />}
            loading={graphsLoading}
            disabled={graphsLoading}
          >
            {filters.period === 'currentMonth' ? 'Este mes' : 'Este año'}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={5}>
          <DropdownMenuItem
            size="xs"
            onSelect={() => {
              setFilters({ ...filters, period: 'currentMonth' });
            }}
          >
            <Flex align="center">Este mes</Flex>
          </DropdownMenuItem>
          <DropdownMenuItem
            size="xs"
            onSelect={() => {
              setFilters({ ...filters, period: 'currentYear' });
            }}
          >
            <Flex align="center">Este año</Flex>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </Flex>
  );
}
