import useApiPagination from 'shared/hooks/useApiPagination';
import useUrlParamsPagination from '@hooks/useUrlParamsPagination';

export default function useSuggestedReferralsPagination() {
  const paramsManager = useUrlParamsPagination({ prefix: 'suggested_' });

  return useApiPagination({
    endpoint: 'invitations',
    itemsPerPage: 5,
    params: { 'q[status_eq]': 'suggestion' },
    paramsManager,
  });
}
