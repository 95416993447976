import { useMemo } from 'react';
import getTableRowsFromDeclarationExpenseTotals from '@util/getTableRowsFromDeclarationExpenseTotals';
import useWorkflow from '../../hooks/useWorkflow';

export default function useTotalsButtons() {
  const { activeDeclarations } = useWorkflow();
  const declarationsExpensesTableTotals = useMemo(
    () =>
      activeDeclarations.map((declaration) =>
        getTableRowsFromDeclarationExpenseTotals(declaration),
      ),
    [activeDeclarations],
  );

  return {
    declarationsExpensesTableTotals,
  };
}
