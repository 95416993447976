import api from '@api';

export default async function getUserComplianceOpinion(taxableId: number) {
  const response = await api.get<string>(
    `/taxable_entities/${taxableId}/compliance_opinion.pdf`,
    {
      responseType: 'arraybuffer',
    },
  );
  if (response.data)
    return Buffer.from(response.data, 'binary').toString('base64');
  return null;
}
