import { MouseEvent } from 'react';
import { getZendesk } from '@api/zendesk';

function open(url: string, isTargetBlank = false) {
  if (isTargetBlank) {
    window.open(url, '_blank');
    return;
  }
  window.location.replace(url);
}
export default async function openZendeskLinkWithSSO(
  url: string,
  isTargetBlank = false,
  event?: MouseEvent<HTMLElement>,
) {
  try {
    event?.preventDefault?.();
    if (!url) {
      return;
    }

    const redirectTo = await getZendesk({
      params: {
        return_to: url,
      },
    });
    open(redirectTo, isTargetBlank);
  } catch {
    open(url, isTargetBlank);
  }
}
