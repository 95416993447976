import React from 'react';
import Loading from '@components/Utils/Loading';

export function Img({
  src,
  alt = 'Imagen de Perfil',
  className,
  // react-Bootstrap
  rounded,
  roundedCircle,

  fallbacksize,
  ...rest
}) {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  return (
    <>
      {error ? (
        <ImageFallback alt={alt} fallbacksize={fallbacksize} />
      ) : (
        loading && <Loading />
      )}
      <img
        className={`${loading || error ? 'd-none' : 'd-visible'} ${className} ${
          rounded ? 'rounded' : ''
        } ${roundedCircle ? 'rounded-circle' : ''}`}
        src={src}
        alt={alt}
        onError={(_) => {
          setError(true);
        }}
        onLoad={(_) => {
          setLoading(false);
        }}
        {...rest}
      />
    </>
  );
}

export function ImageFallback({ alt = 'Imagen', fallbacksize = '1em' }) {
  return (
    <div className="d-flex flex-column align-items-center text-muted">
      <i
        className="iconsmind-Photo d-block"
        style={{
          fontSize: fallbacksize,
        }}
      />
      <p>{alt}</p>
    </div>
  );
}
