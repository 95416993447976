import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Row, Label } from 'reactstrap';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
// eslint-disable-next-line import/no-cycle
import { Colxx } from '@components/CustomBootstrap';
// eslint-disable-next-line import/no-cycle
import { NotificationManager } from '@components';
import { updateTaxableEntity } from '@redux/actions';
import { useTaxableEntity } from '@hooks';
import { rfcPattern } from '@constants/regex';

// TODO: implement formik
function UploadCiec() {
  const dispatch = useDispatch();
  const { taxable_entity } = useTaxableEntity();
  // TODO: remove this if not neccesary, or move to useMemo
  const [ciecData] = useState({
    rfc: '',
    ciec: '',
    disableCiecButton: true,
    disabled: false,
    error: false,
  });
  const [ciecPassword, setCiecPassword] = useState();
  const [rfc, setRfc] = useState('');
  useEffect(() => {
    setRfc(taxable_entity?.rfc ?? '');
  }, [taxable_entity.rfc]);

  const updateCiecPassword = (e) => {
    setCiecPassword(e.target.value);
  };
  const updateRFC = (e) => {
    setRfc(e.target.value.toUpperCase());
  };

  const uploadCiecSubmit = () => {
    const regex =
      /^([A-ZÑ]|&)?[A-ZÑ]{3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$/;

    if (!regex.test(rfc)) {
      NotificationManager.error(
        'El RFC es incorrecto, favor de verificar formato',
        'Upss..',
        4000,
        null,
        null,
        null,
      );
      return;
    }
    if (ciecPassword.length > 0) {
      const body = {
        ciec: ciecPassword,
        rfc,
        has_ciec: true,
      };
      dispatch(updateTaxableEntity(body, taxable_entity.id));
    }
  };

  return (
    <Row className="mb-4">
      <Colxx sm="12">
        <AvForm onValidSubmit={uploadCiecSubmit} autoComplete="off">
          <Row className="mb-2">
            <Colxx md={12} className="mb-2">
              <AvGroup>
                <Label for="rfc">RFC</Label>
                <AvField
                  name="rfc"
                  type="search"
                  autoComplete="off"
                  disabled={!!taxable_entity.rfc}
                  value={rfc}
                  onChange={updateRFC}
                  validate={{
                    required: {
                      value: true,
                      errorMessage:
                        'Porfavor ingrese un RFC con formato válido. Ejemplo: XAX010101000',
                    },
                    pattern: {
                      value: rfcPattern,
                      errorMessage:
                        'Porfavor ingrese un RFC con formato válido. Ejemplo: XAX010101000',
                    },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <AvField
                  name="ciec"
                  label="Contraseña"
                  autoComplete="off"
                  type="password"
                  value={ciecData.ciec}
                  onChange={updateCiecPassword}
                  validate={{
                    minLength: {
                      value: '8',
                      errorMessage: 'Mínimo 8 caracteres',
                    },
                    required: {
                      value: true,
                      errorMessage: 'Campo requerido',
                    },
                  }}
                />
              </AvGroup>
            </Colxx>
          </Row>
          <Row className="mt-1 mb-2">
            <Colxx
              className="d-flex align-content-center justify-content-center"
              xxs={12}
            >
              <Button color="primary" size="sm">
                Guardar
              </Button>
            </Colxx>
          </Row>
        </AvForm>
      </Colxx>
    </Row>
  );
}

export default UploadCiec;
