import { StepperPattern } from '@konta/ui';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Process } from 'types/entities';
import getActiveStepRouteByProcessType from '@util/getActiveStepRouteByProcessType';
import ProcessStepItem from './ProcessStepItem';

export interface ProcessesListProps {
  processes: Process[];
  setSelectedProcess: (value: Process) => void;
}

export default function ProcessesList({
  processes,
  setSelectedProcess,
}: ProcessesListProps) {
  const navigate = useNavigate();

  const value = useMemo(
    () =>
      processes.reduce(
        (acc, process) => ({
          ...acc,
          [process.id]: process.completed || 'indeterminate',
        }),
        {},
      ),
    [processes],
  );

  return (
    // TODO: mark "binary" as optional in @konta/ui
    <StepperPattern binary={false} value={value}>
      {processes.map((process) => {
        const currentStep = process.steps.find((step) => !step.completed);

        const handleContinueProcess = () => {
          if (!currentStep || !currentStep?.type) return;

          const route = getActiveStepRouteByProcessType(
            process.type,
            currentStep.type,
          );

          if (route) {
            navigate(route);
          }
        };

        return (
          <ProcessStepItem
            key={process.id}
            process={process}
            onContinue={handleContinueProcess}
            onClick={() => setSelectedProcess(process)}
          />
        );
      })}
    </StepperPattern>
  );
}
