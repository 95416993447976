import api from '@api';
import type { AxiosRequestConfig } from 'axios';
import type { KontaScore } from 'types/entities';

// eslint-disable-next-line import/prefer-default-export
export const getKontaScore = async (
  config?: AxiosRequestConfig<KontaScore>,
) => {
  const { data } = await api.get<KontaScore>(`konta_score`, config);
  return data;
};
