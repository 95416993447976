import { ComponentProps } from 'react';
import { Chip } from '@konta/ui';
import type {
  ServiceSubscriptionDurationMonths,
  SubscriptionStatus,
} from 'types/entities';
import type {
  BadgeText,
  BasePrices,
  BillingPeriod,
  BillingPeriodNumber,
  DiscountAndText,
  Discounts,
  ServiceSubscriptionData,
} from 'types/subscription';
import { NEW_PRICING_PLANS } from '@constants/featureFlags';

export const DURATION_TO_BILLING_PERIOD_DICT: Record<number, [string, string]> =
  {
    1: ['Mensual', 'mes'],
    6: ['Semestral', 'semestre'],
    12: ['Anual', 'año'],
  };

export const DURATION_TO_BILLING_PERIOD: Record<
  BillingPeriodNumber,
  BillingPeriod
> = {
  1: 'monthly',
  6: 'biannual',
  12: 'yearly',
};

export const PLAN_NAMES = {
  standard_plan: 'Plan pro',
  starter_plan: 'Plan básico',
  started_plan: 'Plan básico',
  assisted_plan: 'Plan asistido',
  personalized_plan: 'Plan personalizado',
  fiscal_visor_plan: 'Plan fiscal',
};

export const defaultServiceSubscription: ServiceSubscriptionData = {
  id: 0,
  nextPlanName: 'Sin plan',
  durationMonth: 0,
  billingPeriod: ['Sin plan', ''],
  validTo: null,
  dateValidToWithFormat: 'Sin fecha',
  dateSubscribed: null,
  dateSubscribedWithFormat: 'Sin fecha',
  totalOfNextPayment: 0,
  totalOfNextPaymentWithoutIva: 0,
  totalOfNextPaymentWithDiscount: 0,
  totalOfNextPaymentWithDiscountWithoutIva: 0,
  totalIvaOfTotalNextPaymentWithDiscount: 0,
  totalIvaOfNextPayment: 0,
  nextDiscount: 0,
  nextPrice: 0,
  nextPriceWithoutIva: 0,
  quantity: 0,
  discountText: '',
  extraRegimeQuantity: 0,
  hasExtraTaxesService: false,
  totalOfExtraRegimesPayment: 0,
  totalOfExtraTaxesPayment: 0,
  totalOfExtraRegimeWithoutIva: 0,
  totalOfExtraTaxesWithoutIva: 0,
  nextRegimePriceWithoutIva: 0,
  nextTaxesPriceWithoutIva: 0,
  subtotalPayment: 0,
  totalIvaOfExtraRegime: 0,
  totalIvaOfExtraTaxes: 0,
};

export const subscriptionStatusDict: {
  [type in SubscriptionStatus]: [string, ComponentProps<typeof Chip>['color']];
} = {
  pending_payment: ['Pendiente de pago', 'warning'],
  cancellation_in_progress: ['Cancelación en progreso', 'warning'],
  active: ['Activo', 'success'],
  inactive: ['Inactivo', 'error'],
  incomplete: ['Cotización', 'warning'], // 6
  cancelled: ['Cancelado', 'error'],
};

const oldPlanOptions = [
  { value: 18, label: 'Plan Básico (starter_plan) 699' },
  { value: 19, label: 'Plan Standar (standar_plan) 999' },
  { value: 17, label: '⚠️ Starter plan (starter_plan) 500' },
];

const newPlanOptions = [
  { value: 20, label: 'Plan asistido (assisted_plan) $749' },
  { value: 22, label: 'Plan personalizado (personalized_plan) $1,749' },
];

export const subscriptionTypeOptions = NEW_PRICING_PLANS
  ? newPlanOptions
  : oldPlanOptions;

export const monthsOptions: {
  value: ServiceSubscriptionDurationMonths;
  label: string;
}[] = [
  { value: 1, label: '1 mes' },
  { value: 6, label: '6 meses' },
  { value: 12, label: '12 meses' },
];

export const BILLING_PERIOD_TEXT: Record<BillingPeriod, string> = {
  monthly: 'Mensual',
  biannual: 'Semestral',
  yearly: 'Anual',
};
export const BILLING_PERIODICITY_TEXT: Record<BillingPeriod, string> = {
  monthly: 'mensualidad',
  biannual: 'semestralidad',
  yearly: 'anualidad',
};
export const BASE_PRICES: BasePrices = {
  assisted_plan: 749,
  personalized_plan: 1749,
  starter_plan: 699,
  started_plan: 699,
  standard_plan: 999,
  fiscal_visor_plan: 50,
};
export const DISCOUNTS: Discounts = {
  assisted_plan: {
    monthly: 0,
    biannual: 0.143,
    yearly: 0.214,
  },
  personalized_plan: {
    monthly: 0,
    biannual: 0.15,
    yearly: 0.2,
  },
  starter_plan: {
    monthly: 0,
    biannual: 100 / 699,
    yearly: 150 / 699,
  },
  started_plan: {
    monthly: 0,
    biannual: 100 / 699,
    yearly: 150 / 699,
  },
  standard_plan: {
    monthly: 0,
    biannual: 150 / 999,
    yearly: 200 / 999,
  },
  fiscal_visor_plan: {
    monthly: 0,
    biannual: 0,
    yearly: 0,
  },
};
export const DEFAULT_DISCOUNT: DiscountAndText = {
  discount: 0,
  discountText: 'Sin descuento',
};
export const ADDON_PRICES = {
  extraRegimen: 250,
};

export const BADGE_TEXT: BadgeText = {
  monthly: '/Mes + IVA',
  biannual: '/Semestre + IVA',
  yearly: '/Año + IVA',
};

export const FISCAL_VISOR_NAMES_PLANS = ['fiscal_visor_plan'];
export const GENERAL_NAMES_PLANS = ['assisted_plan', 'personalized_plan'];
export const PLANS_WITH_BILLING_PERIOD = [...GENERAL_NAMES_PLANS];
