import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './divider.scss';

export default function Divider({ vertical, className }) {
  return (
    <hr className={classNames('divider-component', { vertical }, className)} />
  );
}

Divider.propTypes = {
  vertical: PropTypes.bool,
  className: PropTypes.string,
};

Divider.defaultProps = {
  vertical: false,
  className: '',
};
