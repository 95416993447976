import { useMemo } from 'react';
import getStepsByProcessType from '@util/getStepsByProcessType';
import useApiPagination, {
  UseApiPaginationOptions,
} from 'shared/hooks/useApiPagination';
import onboardingTaskOrder from 'backoffice/util/onboardingTaskOrder';
import type { OnboardingProcessWrapper, ProcessWrapper } from 'types/entities';

type UseOnboardingsWrappersOptions = UseApiPaginationOptions<ProcessWrapper>;

export default function useOnboardingsWrappers(
  options?: Partial<Omit<UseOnboardingsWrappersOptions, 'endpoint'>>,
) {
  const { items, loading, ...rest } = useApiPagination<ProcessWrapper>({
    ...options,
    endpoint: 'process_wrappers',
  });

  const onboardingWrappers = useMemo(
    () =>
      items.map((wrapper) => ({
        ...wrapper,
        processes: wrapper.processes
          .map((process) => {
            const availableSteps = Object.values(
              process.grouped_steps ?? {},
            ).flat();

            const steps = getStepsByProcessType(process?.type, availableSteps);
            return { ...process, step: steps };
          })
          .reduce((acc, process) => ({ ...acc, [process.type]: process }), {}),
      })) as OnboardingProcessWrapper[],
    [items],
  );

  // sort tasks by id
  onboardingWrappers.forEach((wrapper) => {
    wrapper.tasks.sort(
      (a, b) => onboardingTaskOrder(a) - onboardingTaskOrder(b),
    );
  });

  return {
    onboardingsWrapperLoading: loading,
    onboardingWrappers,
    ...rest,
  };
}
