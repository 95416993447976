import { Button, Chip } from '@konta/ui';
import { Edit05LineIcon } from '@konta/icons';
import {
  Header,
  Root,
  TicketAvatar,
  TicketDescription,
  TicketItem,
  TicketItemContent,
  TicketItemInfo,
  TicketItemInfoWrapper,
  TicketsWrapper,
  TicketTitle,
  Title,
} from './styled';

const tickets = [
  {
    sockedId: '1',
  },
];
export default function ChatSidebar() {
  return (
    <Root>
      <Header>
        <Title>Mensajes</Title>
        <Button variant="outlined" icon noFill size="xs">
          <Edit05LineIcon />
        </Button>
      </Header>
      <TicketsWrapper>
        {tickets.map((ticket) => (
          <TicketItem key={ticket.sockedId}>
            <TicketItemContent>
              <TicketItemInfoWrapper>
                <TicketAvatar maxInitials={2} name="Rosita" round />
                <TicketItemInfo>
                  <TicketTitle>Rosita AI ✨</TicketTitle>
                  <TicketDescription>Secretaria</TicketDescription>
                </TicketItemInfo>
              </TicketItemInfoWrapper>
              <TicketItemInfo alignEnd>
                <TicketDescription>20min ago</TicketDescription>
                <Chip size="xs" color="primary100" label="Siempre en línea" />
              </TicketItemInfo>
            </TicketItemContent>
          </TicketItem>
        ))}
      </TicketsWrapper>
    </Root>
  );
}
