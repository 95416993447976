import { ComponentProps, forwardRef, ReactNode } from 'react';
import { styled } from '@konta/ui';

export const StyledIcon = styled('div', {
  cursor: 'pointer',
  color: '$gray400',
  svg: {
    width: 'var(--size)',
    height: 'var(--size)',
    display: 'block',
    verticalAlign: 'middle',
  },
  variants: {
    size: {
      xs: {
        '--size': '$sizes$12',
      },
      s: {
        '--size': '$sizes$14',
      },
      m: {
        '--size': '$sizes$16',
      },
      l: {
        '--size': '$sizes$18',
      },
      xl: {
        '--size': '$sizes$20',
      },
    },
  },
  defaultVariants: {
    size: 'm',
  },
});

export interface IconProps extends ComponentProps<typeof StyledIcon> {
  children?: ReactNode;
}

const Icon = forwardRef<HTMLDivElement, IconProps>(
  ({ children, ...props }, forwardedRef) => (
    <StyledIcon {...props} ref={forwardedRef}>
      {children}
    </StyledIcon>
  ),
);

Icon.displayName = 'Icon';

export default Icon;
