import React, { useState } from 'react';
import { Flex, Input, InputAction } from '@konta/ui';
import WhatsappIcon from '@components/WhatsappIcon';
import getErrorMessage from '@util/getErrorMessage';
import { NotificationManager } from '@components';
import postInvitation from '@api/postInvitation';
import useInvitedReferralsPagination from './useInvitedReferralsPagination.ts';

export default function ReferByWhatsappInput() {
  const [phone, setPhone] = useState('');
  const { refetch } = useInvitedReferralsPagination();

  const sendInvite = async () => {
    if (!phone) return;

    const IntlPhoneNumber = `52${phone}`.slice(-12);

    try {
      await postInvitation({ phone: IntlPhoneNumber });

      refetch();
      setPhone('');
      NotificationManager.success(
        'Invitación enviada por Whatsapp',
        'Referido',
        3000,
      );
    } catch (error) {
      const message = getErrorMessage(error);
      NotificationManager.error(message, 'Error', 6000);
    }
  };

  return (
    <Input
      css={{ label: { margin: 0 } }}
      size="s"
      label="Invitar por whatsapp"
      placeholder="123 456 7890"
      value={phone}
      onChange={({ target }) => setPhone(target.value)}
      rightActions={
        <InputAction onClick={sendInvite}>
          <Flex gap="4" align="center">
            <WhatsappIcon />
            Invitar
          </Flex>
        </InputAction>
      }
    />
  );
}
