import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { Edit01LineIcon } from '@konta/icons';
import {
  Button,
  Flex,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Text,
  DataTable,
  Icon,
} from '@konta/ui';
import { useNavigate } from 'react-router-dom';
import useInvoiceTemplates from '../../@hooks/useInvoiceTemplates';
import { InvoiceTemplate } from '../../types/entities';
import { ColumnDef } from '@tanstack/react-table';

export default function InvoiceTemplates() {
  const navigate = useNavigate();

  const {
    invoiceTemplates,
    invoiceTemplatesLoading,
    total,
    setPage,
    page,
    next,
    hasNext,
    prev,
    hasPrev,
    pageSize,
    setPageSize,
  } = useInvoiceTemplates({
    params: {
      'q[invoice_template_id_not_null]': 1,
      'q[invoice_template_deleted_at_not_null]': 1,
    },
  });

  const columns = useMemo<ColumnDef<InvoiceTemplate>[]>(
    () => [
      {
        header: 'Acciones',
        accessorKey: 'actions',
        label: 'Acciones',
        cell: ({ row: { original } }) => (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="2xs" color="gray" variant="minimal">
                <div
                  style={{
                    fontSize: '25px',
                    paddingBottom: '50%',
                  }}
                >
                  &#x2026;
                </div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() =>
                  navigate(`/app/invoicing/template/${original.uuid}`)
                }
              >
                <Flex align="center">
                  <Icon
                    css={{
                      svg: {
                        path: {
                          fill: 'none',
                        },
                      },
                    }}
                  >
                    <Edit01LineIcon />
                  </Icon>
                  <Text css={{ mr: '$16', ml: '$12' }}>Editar</Text>
                </Flex>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },

      {
        header: 'Nombre de la plantilla',
        accessorKey: 'invoice_template.name',
        label: 'Nombre de la plantilla',
        id: 'invoice_template.name',
      },
      {
        header: 'Fecha de creación',
        accessorKey: 'invoice_template.created_at',
        label: 'Fecha de creación',
        id: 'invoice_template.created_at',
        cell: ({ row: { original } }) =>
          dayjs(original.invoice_template.created_at).format('DD/MM/YYYY'),
      },
    ],
    [],
  );

  return (
    <Flex direction="column" gap={10}>
      <DataTable
        // @ts-ignore TODO: fix typing with react-table
        columns={columns}
        data={invoiceTemplates}
        loading={invoiceTemplatesLoading}
        dataTableId="invoiceTemplatesTable"
        manualPagination
        manualPaginationProps={{
          pages: Math.ceil(total / pageSize),
          value: page - 1,
          nextPage: next,
          previousPage: prev,
          setPage,
          onChange: (value: number) => setPage(value + 1),
          hasPrevPage: hasPrev,
          hasNextPage: hasNext,
          pageSize,
          setPageSize,
        }}
      />
    </Flex>
  );
}
