import { useQuery } from 'react-query';
import { FOREIGN_INVOICES } from '@constants/reactQueries';
import { ForeignDocument } from 'types/entities';
import getForeignInvoices from '@api/getForeignInvoices';

export default function useForeignInvoices() {
  const {
    data = null,
    isLoading,
    ...rest
  } = useQuery<ForeignDocument[]>([FOREIGN_INVOICES], async () => {
    const foreignInvoices = await getForeignInvoices();
    return foreignInvoices;
  });
  return {
    foreignInvoicesLoading: isLoading,
    foreignInvoices: (data ?? []).map((entry) => ({
      ...entry,
      entry_type: 'foreign_invoices',
    })),
    ...rest,
  };
}
