import { useEffect, useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import PrevPhaseButton from '@components/PrevPhaseButton';
import WorkflowLayout from '@components/WorkflowLayout';
import WorkflowHeader from '@components/WorkflowHeader';
import useNextPhase from '@hooks/useNextPhase';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import FormikOptionsSelect from '@components/FormikOptionsSelect';
import { CardOption } from '@konta/ui';
import { Collapse, Row } from 'reactstrap';
import { Colxx, NotificationManager } from '@components';
import FormikKontaSelect from '@components/FormikKontaSelect';
import usePostDeclarationEntries from 'shared/hooks/usePostDeclarationEntries';
import FormikTextInput from 'shared/components/FormikTextInput';
import useDeclarationEntriesByWorkflow from 'shared/hooks/useDeclarationEntriesByWorkflow';
import usePutDeclarationEntries from 'shared/hooks/usePutDeclarationEntries';
import * as Sentry from '@sentry/react';
import type { ExtraIncome } from 'types/entities';
import {
  defaultValues,
  HasExtraIncomeFormValues,
  IVA_OPTIONS,
  validationSchema,
  valuesToPayload,
} from './form';
import style from './HasExtraIncome.module.scss';

export default function HasExtraIncome() {
  const nextPhase = useNextPhase();
  const { workflow } = useSelectedWorkflow();
  const { iva_rate, id } = workflow?.active_declaration ?? {};
  const postDeclarationEntries = usePostDeclarationEntries();
  const putDeclarationEntry = usePutDeclarationEntries();

  const { declarationEntries, declarationEntriesLoading } =
    useDeclarationEntriesByWorkflow(
      {
        params: {
          workflow_id: workflow?.id,
        },
      },
      {
        enabled: !!workflow?.id,
      },
    );

  const extraIncome = useMemo(() => {
    const extraIncomes = declarationEntries.filter(
      (entry) => entry.source_type === 'ExtraIncome',
    );
    if (Sentry?.captureException && extraIncomes.length > 1) {
      Sentry.captureException(
        `Existe más de un ingreso extra en la declaración ${id || 'sin id'}`,
      );
    }
    return extraIncomes.length ? extraIncomes[0] : null;
  }, [declarationEntries, id]);

  const initialValues = useMemo(() => {
    if (!extraIncome) {
      return defaultValues;
    }
    const {
      total8,
      total16,
      exempt_base: exemptBase,
      zero_base: zeroBase,
    } = extraIncome.source.extra_income as ExtraIncome;
    const totalOfExtras = +total8 + +total16;
    const iva = +total8 > 0 ? IVA_OPTIONS[1] : IVA_OPTIONS[0];

    return {
      totalOfExtras,
      iva,
      extraTotalZeroIva: +zeroBase,
      extraExemptBases: +exemptBase,
    };
  }, [extraIncome]);

  const [hasExtraIncomes, setHasExtraIncomes] = useState([false]);

  const onSubmit = async (values: HasExtraIncomeFormValues) => {
    try {
      const withExtraIncomes = hasExtraIncomes[0];
      const payload = valuesToPayload(
        withExtraIncomes ? values : defaultValues,
        workflow,
        extraIncome,
        id,
      );
      if (extraIncome) {
        await putDeclarationEntry.mutateAsync({
          declarationEntryId: extraIncome.id,
          payload,
        });
      } else {
        await postDeclarationEntries.mutateAsync(payload);
      }

      nextPhase({
        workflow: {
          next_phase: 'primary_phase',
        },
      });
    } catch (error) {
      console.log(error);
      NotificationManager.error(
        'No se pudo actualizar la declaración',
        'Error al actualizar',
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (iva_rate) {
      const iva = IVA_OPTIONS.find((option) => option.value === +iva_rate);
      if (iva) void formik.setFieldValue('iva', iva);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iva_rate]);

  useEffect(() => {
    const { totalOfExtras, extraTotalZeroIva, extraExemptBases } =
      initialValues;
    setHasExtraIncomes([
      !!totalOfExtras || !!extraExemptBases || !!extraTotalZeroIva,
    ]);
  }, [initialValues]);

  const isLoading =
    declarationEntriesLoading || postDeclarationEntries.isLoading;

  return (
    <WorkflowLayout
      header={<WorkflowHeader title="Ingresos Extra" videoId="3fdqd8aCrxE" />}
      actions={
        <>
          <PrevPhaseButton loading={isLoading} />
          <PrimaryPhaseButton onClick={formik.submitForm} loading={isLoading} />
        </>
      }
    >
      <h2>
        Si tuviste ingresos extra este mes, en la sección de abajo puedes
        agregar los totales.
      </h2>
      <FormikProvider value={formik}>
        <div className={style.options}>
          <FormikOptionsSelect
            name="hasIncome"
            noEmpty
            setHasExtraIncomes={setHasExtraIncomes}
            hasExtraIncomes={hasExtraIncomes}
          >
            <CardOption
              value={false}
              header={
                <span data-testid="has-no-income-option">
                  No obtuve otros ingresos
                </span>
              }
            />
            <CardOption
              value
              header={
                <span data-testid="has-income-option">
                  Si obtuve ingresos extra
                </span>
              }
            />
          </FormikOptionsSelect>
        </div>

        <Collapse isOpen={hasExtraIncomes[0]}>
          <Row>
            <Colxx xxs={12} md={6}>
              <FormikTextInput
                name="totalOfExtras"
                data-testid="totalOfExtras"
                type="number"
                label="Monto de ingresos con IVA"
                placeholder="Ej. 200"
              />
            </Colxx>
            <Colxx xxs={12} md={6}>
              <FormikKontaSelect
                options={IVA_OPTIONS}
                name="iva"
                label="IVA"
                data-testid="iva"
                placeholder="Selecciona una opción"
              />
            </Colxx>
            <Colxx xxs={12} md={6}>
              <FormikTextInput
                name="extraTotalZeroIva"
                data-testid="extraTotalZeroIva"
                type="number"
                label="Monto de ingresos extra con el 0% de IVA"
                placeholder="Ej. 200"
              />
            </Colxx>
            <Colxx xxs={12} md={6}>
              <FormikTextInput
                name="extraExemptBases"
                data-testid="extraExemptBases"
                type="number"
                label="Monto de ingresos extra con IVA exento"
                placeholder="Ej. 200"
              />
            </Colxx>
          </Row>
        </Collapse>
      </FormikProvider>
    </WorkflowLayout>
  );
}
