import { ReactNode } from 'react';
import { styled } from '@konta/ui';
import Sidebar from 'backoffice/components/Sidebar';
import TopNav from 'backoffice/components/TopNav';

const StyledMain = styled('main', {
  display: 'flex',
  flexDirection: 'column',
});

export type BackOfficeLayoutProps = {
  children: ReactNode;
};

export default function BackOfficeLayout({ children }: BackOfficeLayoutProps) {
  return (
    <>
      <div id="app-container" className="group-container">
        <Sidebar />
        <StyledMain>
          <TopNav />
          {children}
        </StyledMain>
        <div id="section-level" />
      </div>
      <div id="app-level" />
    </>
  );
}
