import api from '@api';

export default async function postClient({ taxableId, payload }) {
  const token = localStorage.getItem('user_id');
  const { data } = await api.post(
    `taxable_entities/${taxableId}/client_suppliers`,
    {
      client_supplier: payload,
    },
    {
      headers: { Authorization: token },
    },
  );

  return data;
}
