import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Alert, Row } from 'reactstrap';
import { Text } from '@konta/ui';
import { addressUpdate, updateTaxableEntity } from '@redux/actions';
import useVerifyRfc from '@hooks/useVerifyRfc';
import FormikFormModal from '@components/FormikFormModal';
import FormikTextField from '@components/FormikTextField';
import { NotificationManager } from '@components/Notifications';
import { Colxx } from '@components';
import { useTaxableEntity } from '@hooks';

const validationSchema = yup.object().shape({
  postcode: yup.string().required('Campo requerido'),
  rfc: yup.string().required('Campo requerido'),
  legalName: yup.string().required('Campo requerido'),
});

export default function PostCodeForm({ open, onClose, hasError }) {
  const dispatch = useDispatch();
  const [alertError, setAlertError] = useState(null);
  const verifyRfc = useVerifyRfc({ source: 'ClientModalForm' });
  const { taxable_entity: taxableEntity } = useTaxableEntity();
  const { userPostCode } = useSelector((state) => {
    return {
      userPostCode: state.addresses.address?.postcode,
    };
  });

  const handlePostalCodeSubmit = async ({ values, setErrors }) => {
    try {
      setAlertError(null);
      const { errors, fixedName } = await verifyRfc({
        rfc: values.rfc,
        zipCode: values.postcode,
        legalName: values.legalName,
        strictValidation: true,
      });
      if (errors) {
        setErrors({
          rfc: errors.rfc,
          legalName: errors.name,
          postcode: errors.cp,
        });
        if (errors.other) {
          setAlertError(errors.other);
        }
        return;
      }
      const payload = {
        legal_name: fixedName,
        rfc: values.rfc,
      };
      dispatch(
        updateTaxableEntity(payload, taxableEntity.id, undefined, () => {
          dispatch(
            addressUpdate(values, undefined, () => {
              onClose(false);
            }),
          );
        }),
      );
    } catch (error) {
      NotificationManager.error(
        'Intente nuevamente o comunicate con el servicio',
        'Verifique la información',
        4000,
        null,
        null,
        null,
      );
    }
  };

  const initialValues = useMemo(() => {
    return {
      rfc: taxableEntity?.rfc || '',
      legalName: taxableEntity?.legal_name || '',
      postcode: userPostCode || '',
    };
  }, [taxableEntity, userPostCode]);

  return (
    <FormikFormModal
      title="Actualizar datos fiscales"
      toggle={onClose}
      isOpen={open}
      size="s"
      onSubmit={handlePostalCodeSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      submitText="Actualizar"
    >
      <div className=" d-flex flex-column">
        <Row className="mb-3">
          {hasError && (
            <Colxx md={12}>
              <Alert color="danger" className="rounded-alert">
                Hemos detectado un error con el código postal registrado, es
                necesario actualizar tu información para poder timbrar facturas.
                <ul className="pl-3 pt-2 m-0">
                  <li>Verifica que no tenga letras ni espacios en blanco.</li>
                </ul>
              </Alert>
            </Colxx>
          )}
          <Colxx>
            <Text>
              Debido a los nuevos cambios del CFDI 4.0 necesitamos actualizar tu
              información fiscal, es importante que la información sea idéntica
              a la que está en tu{' '}
              <Text
                as="a"
                href="https://www.youtube.com/watch?v=sd-9nwakK3E"
                target="_blank"
                underline
                bold
                color="secondary"
              >
                Constancia de Situación Fiscal (CSF).
              </Text>
              {' Al finalizar da click en “Actualizar”.'}
            </Text>
          </Colxx>
        </Row>
      </div>
      <Row className="mt-3">
        <Colxx xxs="12">
          <FormikTextField label="Razón social" name="legalName" type="text" />
        </Colxx>
        <Colxx xxs="6">
          <FormikTextField
            label="Código postal"
            name="postcode"
            type="text"
            placeholder="Ej. 97000"
          />
        </Colxx>
        <Colxx xxs="6">
          <FormikTextField
            label="RFC"
            name="rfc"
            type="text"
            placeholder="Ej. XAX010101000"
          />
        </Colxx>
      </Row>
      {!!alertError && (
        <Colxx md={12} className="pt-3">
          <Alert color="danger" className="rounded-alert">
            {alertError}
          </Alert>
        </Colxx>
      )}
    </FormikFormModal>
  );
}

PostCodeForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
