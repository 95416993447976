import { payOrder, fetchOrders } from '@redux/actions';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';

export const useOrders = (type) => {
  const dispatch = useDispatch();
  const { orders, loading, ui } = useSelector(
    (state) => state.orders,
    shallowEqual,
  );

  useEffect((_) => {
    orders.length || dispatch(fetchOrders());
  }, []);

  const payHandler = useCallback(
    (order_id) => {
      dispatch(payOrder(order_id));
    },
    [dispatch],
  );

  const debts = useMemo(
    (_) => {
      return orders.filter((order) => {
        const taxArrear = order?.tax_arrears?.[0];
        const arrearMonth = taxArrear?.arrear_months?.[0];
        const productType = taxArrear?.product_type;
        const orderType = order?.source_type;
        const isDeclarationProductType = productType === 'Declaration';
        const isTaxArrearOrderType = orderType === 'TaxArrear';
        const isNotValidDateToShow = arrearMonth === '2024-04-01';

        return (
          order.payment_status === 'pending' &&
          !(
            isDeclarationProductType &&
            isTaxArrearOrderType &&
            isNotValidDateToShow
          )
        );
      });
    },
    [orders],
  );

  return { orders, loading, ui, debts, payHandler };
};
