import { CursorBoxLineIcon } from '@konta/icons';
import { Flex } from '@konta/ui';

interface SubtitleProps {
  isMobileOrTablet: boolean;
}

export default function Subtitle({ isMobileOrTablet }: SubtitleProps) {
  return isMobileOrTablet ? (
    <Flex>Toca las miniaturas para visualizar las recomendaciones</Flex>
  ) : (
    <Flex align="center" gap={4}>
      Haz click <CursorBoxLineIcon width={14} hanging={14} /> sobre las
      miniaturas para visualizar las recomendaciones
    </Flex>
  );
}
