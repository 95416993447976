import { useState, KeyboardEvent, FormEvent } from 'react';
import { Socket } from 'socket.io-client';
import { Button } from '@konta/ui';
import { Form, Root, Textarea } from './styled';

type ChatFooterProps = {
  socket: Socket | null;
};

export default function ChatFooter({ socket }: ChatFooterProps) {
  const [message, setMessage] = useState<string>('');

  const handleTyping = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const textArea = e.target as HTMLTextAreaElement;
    if (['NumpadEnter', 'Enter'].includes(e.code)) {
      e.preventDefault();
      textArea.form?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  };

  const handleSendMessage = (e: FormEvent) => {
    e.preventDefault();
    if (message.trim() && localStorage.getItem('user_id')) {
      socket?.emit('message', {
        text: message,
        name: localStorage.getItem('user_id'),
        id: `${socket.id}${Math.random()}`,
        socketID: socket.id,
      });
    }
    setMessage('');
  };

  return (
    <Root>
      <Form onSubmit={handleSendMessage}>
        <Textarea
          rows={3}
          placeholder="Escribe un mensaje"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleTyping}
        />
        <Button color="primary600" className="sendBtn" size="xs" type="submit">
          Enviar
        </Button>
      </Form>
    </Root>
  );
}
