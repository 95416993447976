import { styled } from '@konta/ui';

export const StyledButtonContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$14 $18',
  borderRight: '1px solid $gray200',
});

export const styledButtonCss = {
  color: '$gray600',
  '&:disabled': {
    path: {
      fill: 'none',
    },
  },
};
