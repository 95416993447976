import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row } from 'reactstrap';

import { Colxx } from '@components/CustomBootstrap';
import { updateTaxableEntity, uploadTaxableEntityFiel } from '@redux/actions';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

function BookCall({ taxable_entity }) {
  const dispatch = useDispatch();

  const handleChange = () => {
    const body = {
      taxable_entity: {
        onboarding_detail_attributes: {
          has_booked_call: true,
        },
      },
    };
    dispatch(updateTaxableEntity(body, taxable_entity.id));
  };

  useCalendlyEventListener({
    onEventScheduled: handleChange,
  });

  function CustomButton({ url }) {
    return (
      <div className="w-100">
        <InlineWidget
          styles={{
            height: '500px',
          }}
          url={url}
          pageSettings={{
            backgroundColor: 'fff',
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
            primaryColor: '6941C6',
            textColor: '1D2939',
            hideGdprBanner: true,
          }}
        />
      </div>
    );
  }

  return (
    <Row className="p-5">
      <Colxx
        xxs={12}
        md={12}
        className="d-flex align-items-center justify-content-center"
      >
        <CustomButton url="https://calendly.com/buhotax/demo-de-buhotax?embed_domain=konta.com&embed_type=Inline" />
      </Colxx>
    </Row>
  );
}

const mapStateToProps = ({ taxableEntity }) => {
  const { taxable_entity, error, loading } = taxableEntity;
  return { taxable_entity, error, loading };
};
export default connect(mapStateToProps, {
  updateTaxableEntity,
  uploadTaxableEntityFiel,
})(BookCall);
