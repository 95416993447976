import { CSS, Flex, styled } from '@konta/ui';

export const Root = styled(Flex, {
  color: '$gray500',
  fontSize: '$xs',
  flexDirection: 'column!important',
  '@sm': {
    flexDirection: 'row!important',
  },
});
export const defaultLabelCss: CSS = {
  maxWidth: '180px',
  flex: 1,
  fontWeight: '$semibold',
};
export const defaultValueCss: CSS = {
  width: '100%',
};

export const defaultWrapValueCss: CSS = {
  flex: 1,
};

export type { CSS };
