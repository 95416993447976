import { styled } from '@konta/ui';

export const Root = styled('div', {
  display: 'flex',
  flex: 1,
  width: '100%',
  flexWrap: 'wrap',
  flexDirection: 'column',
  '@md': {
    flexDirection: 'row',
  },
});

export const ChartContainer = styled('div', {
  display: 'flex',
  flex: 1,
});
