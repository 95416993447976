import { Row } from '@silevis/reactgrid';
import {
  generateMonthNamesForWorkflows,
  isSelectedMonth,
  sumGroupValues,
} from 'shared/util/grids';
import {
  nonEditable,
  borderLeft,
  borderRight,
  numberCell,
  showZero,
  textCell,
  noBorders,
  leftBorder,
  rightBorder,
  grayBorderTop,
} from 'backoffice/util/cells';
import { getEmptyRow, getHeaderRow } from 'backoffice/util/rows';
import {
  MONTHS,
  ROW_HEIGHT,
  HEADING_ROW_HEIGHT,
  EMPTY_ROW_ID,
  HEADER_ROW_ID,
  FORMAT_MONTH,
} from 'shared/constants/grids';
import type { SimplifiedDataGrid } from 'types/grids';
import type { AnnualDeclaration, Workflow } from 'types/entities';

function getRow(
  title: string,
  values: number[],
  selectedMonth: string | null,
  isTotal = false,
  cursorPointer = false,
  withClickableCells = false,
  noConsiderNegativesOnSum = false,
  selectDecemberAsTotal = false,
): Row {
  const cursorClass = cursorPointer ? 'cursor-pointer corner-indicator' : '';
  const cellMonths = values.map((value, idx) => {
    const isCurrentMonth = isSelectedMonth(MONTHS[idx], selectedMonth);
    if (isCurrentMonth) {
      return borderRight(
        borderLeft(nonEditable(numberCell(value, cursorClass))),
      );
    }
    if (isTotal) {
      return grayBorderTop(
        noBorders(
          nonEditable(showZero(numberCell(value, `font-bold ${cursorClass} `))),
        ),
      );
    }
    return noBorders(nonEditable(numberCell(value, cursorClass)));
  });

  const filteredValues = noConsiderNegativesOnSum
    ? values.filter((value) => value >= 0)
    : values;

  const total = selectDecemberAsTotal
    ? values[values.length - 1]
    : sumGroupValues(filteredValues);
  const tileClass = isTotal ? 'font-bold gray-border-top' : '';
  const lastClass = isTotal ? 'gray-border-top font-bold' : '';
  return {
    rowId: title,
    height: ROW_HEIGHT,
    cells: [
      leftBorder(noBorders(nonEditable(textCell(title, tileClass)))),
      ...cellMonths,
      rightBorder(
        noBorders(nonEditable(showZero(numberCell(total, lastClass)))),
      ),
    ],
  };
}

interface UseSimplifiedRowsProps {
  dataSet?: SimplifiedDataGrid;
  selectedMonth: string | null;
  title?: string;
  withClickableCells?: boolean;
  period?: string | number;
  workflows?: Workflow[];
  selectedAnnualDeclaration?: AnnualDeclaration | null;
}

export default function useSimplifiedRows({
  dataSet,
  selectedMonth,
  title,
  withClickableCells,
  workflows,
}: UseSimplifiedRowsProps): Row[] {
  const totalIncomes = dataSet?.totalIncomes || [];
  const totalExpenses = dataSet?.totalExpenses || [];
  const profitOrLoss = dataSet?.profitOrLoss || [];
  const ivaInFavorOrAgainst = dataSet?.ivaInFavorOrAgainst || [];
  const isrToPay = dataSet?.isrToPay || [];
  const salaryRetentions = dataSet?.salaryRetentions || [];
  const totalToPay = dataSet?.totalToPay || [];
  const totalLateFees = dataSet?.totalLateFees || [];
  const months = generateMonthNamesForWorkflows(workflows, FORMAT_MONTH);
  const ivaInfavorFromPeriods = dataSet?.ivaInFavorFromPeriods || [];

  return [
    getHeaderRow(
      selectedMonth,
      'ACUMULADO',
      months,
      HEADING_ROW_HEIGHT,
      HEADER_ROW_ID,
      title,
    ),
    getRow(
      'Total de ingresos cobrados',
      totalIncomes,
      selectedMonth,
      false,
      true,
      withClickableCells,
    ),
    getRow(
      'Total de gastos pagados',
      totalExpenses,
      selectedMonth,
      false,
      true,
      withClickableCells,
    ),
    getRow('Utilidad o pérdida', profitOrLoss, selectedMonth),
    getEmptyRow(
      selectedMonth,
      MONTHS,
      HEADING_ROW_HEIGHT,
      `${EMPTY_ROW_ID}-${1}`,
      true,
    ),
    getRow(
      'IVA a cargo o a favor',
      ivaInFavorOrAgainst,
      selectedMonth,
      false,
      false,
      false,
      true, // no consider negatives on sum
    ),
    getRow(
      'ISR causado del periodo',
      isrToPay,
      selectedMonth,
      false,
      false,
      false,
      true, // no consider negatives on sum
    ),
    getRow('Retenciones de salario', salaryRetentions, selectedMonth),
    getRow(
      'IVA a favor de meses anteriores',
      ivaInfavorFromPeriods,
      selectedMonth,
      false,
      false,
      false,
      false,
      true, // select December as total
    ),
    getEmptyRow(
      selectedMonth,
      MONTHS,
      HEADING_ROW_HEIGHT,
      `${EMPTY_ROW_ID}-${2}`,
      true,
    ),
    getRow('Recargos', totalLateFees, selectedMonth),
    getEmptyRow(
      selectedMonth,
      MONTHS,
      HEADING_ROW_HEIGHT,
      `${EMPTY_ROW_ID}-${3}`,
      true,
    ),
    getRow('Total a pagar', totalToPay, selectedMonth, true),
  ];
}
