import { QueryOptions, useQuery } from 'react-query';
import { ANNUAL_ORDER } from '@constants/reactQueries';
import getAnnualOrder from '@api/getAnnualOrder';
import { TaxOrder } from 'types/entities';

export default function useAnnualOrder(
  id: number,
  queryOptions?: QueryOptions<TaxOrder>,
) {
  const {
    data = null,
    isLoading,
    ...rest
  } = useQuery<TaxOrder>(
    [ANNUAL_ORDER],
    async () => {
      const annualOrder = await getAnnualOrder(id);
      return annualOrder;
    },
    {
      ...queryOptions,
    },
  );
  return {
    annualOrderLoading: isLoading,
    annualOrder: data,
    ...rest,
  };
}
