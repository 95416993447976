import React from 'react';

export function Emoji({ label, symbol, className }) {
  return (
    <span
      className={`emoji ${className}`}
      role="img"
      aria-label={label || ''}
      aria-hidden={label ? 'false' : 'true'}
    >
      {symbol}
    </span>
  );
}
