import api from '@api';
import { ANNUAL_DECLARATIONS } from '@constants/reactQueries';
import { useQuery } from 'react-query';
import { AnnualDeclaration } from 'types/entities';
import { useMemo } from 'react';
import dayjs from 'dayjs';

type AnnualDeclarationArray = Array<AnnualDeclaration>;

const lastYear = dayjs().subtract(1, 'year').year();

export default function useAnnualsDeclarationQuery() {
  const {
    data = [],
    isLoading,
    ...rest
  } = useQuery<AnnualDeclarationArray>([ANNUAL_DECLARATIONS], async () => {
    const response = await api.get<AnnualDeclarationArray>(
      '/annual_declarations',
    );
    return response?.data || [];
  });

  const lastYearDeclaration = useMemo(
    () => data?.find((declaration) => declaration?.period === lastYear),
    [data],
  );

  return {
    loadingDeclaration: isLoading,
    declarations: data,
    lastYearDeclaration,
    ...rest,
  };
}
