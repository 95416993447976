import { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import LetterAvatar from 'react-avatar';
import { Chip, Flex, Popover, Text } from '@konta/ui';
import type { ColumnDef } from '@tanstack/react-table';
import {
  EyeLineIcon,
  FileAddInLc1OutlineIcon,
  ListLineIcon,
  QuestionMarkCrFr2OutlineIcon,
} from '@konta/icons';
import Cell from 'shared/components/Cell';
import ActionButton from 'shared/components/ActionButton';
import downloadPdfFromCfdi from 'shared/util/downloadPdfFromCfdi';
import downloadXmlFromCfdi from 'shared/util/downloadXmlFromCfdi';
import { ReactComponent as PdfIcon } from '@assets/icons/pdf-icon.svg';
import { ReactComponent as XmlIcon } from '@assets/icons/xml-icon.svg';
import getInvoiceStatus from '@util/getInvoiceStatus';
import getPaymentStatus from '@util/getPaymentStatus';
import toCurrency from '@util/toCurrency';
import type { Cfdi } from 'types/entities';
import TextOverflow from 'shared/components/TextOverflow';
import useFeature from '@hooks/useFeature';
import { MODAL_INSIGHTS } from '@constants/featureFlags';
import {
  cfdiInvoiceType,
  cfdiInvoiceTypeDbOpts,
  cfdiStatusDbOpts,
  invoiceTypeChipColor,
} from '@constants/cfdis';
import useCfdiStore from 'store/cfdisStore';

interface UseCommonCfdiColumnsProps {
  setCfdi: (cfdi: any) => void;
  setTogglePdf: () => void;
  setToggleComplement: () => void;
  handleShowReports: (original: any) => void;
}
export default function useCommonCfdiColumns({
  setCfdi,
  setTogglePdf,
  setToggleComplement,
  handleShowReports,
}: UseCommonCfdiColumnsProps) {
  const [modalInsights] = useFeature(MODAL_INSIGHTS);
  const selectedTab = useCfdiStore(useShallow((state) => state.selectedTab));
  return useMemo<ColumnDef<Cfdi>[]>(() => {
    const isEmitted = selectedTab === 'emitted';
    return [
      {
        header: isEmitted ? 'Receptor' : 'Emisor',
        label: isEmitted ? 'Receptor' : 'Emisor',
        id: 'legalName',
        accessorFn: (cfdi) =>
          isEmitted ? cfdi.receiver_legal_name : cfdi.issuer_legal_name,
        cell: ({ row }) => {
          const legalName = isEmitted
            ? row.original.receiver_legal_name
            : row.original.issuer_legal_name;
          return (
            <Flex
              css={{
                fontWeight: 500,
                color: '$gray900',
                textTransform: 'uppercase',
              }}
              gap={12}
              itemsCenter
            >
              <span className="classify-expenses-logo-container">
                <LetterAvatar
                  maxInitials={2}
                  name={legalName || '-'}
                  size="28"
                  round
                />
              </span>
              <TextOverflow maxLen={380} s lineHeight="s" color="gray900">
                {legalName}
              </TextOverflow>
            </Flex>
          );
        },
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
      },
      {
        header: 'RFC',
        label: 'RFC',
        id: 'rfc',
        accessorFn: (cfdi) => cfdi.client_supplier?.rfc || '',
        cell: ({ row }) => (
          <Cell uppercase>{row.original.client_supplier?.rfc || ''}</Cell>
        ),
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
      },
      {
        header: 'Fecha',
        label: 'Fecha',
        accessorKey: 'date_issued',
        cell: ({ row }) => <Cell>{row.original.date_issued}</Cell>,
      },
      {
        header: 'Total (MXN)',
        label: 'Total',
        id: 'total',
        accessorFn: (cfdi) => {
          const { total, exchange_rate, banxico_exchange_rate } = cfdi;
          const exchangeRate = +(exchange_rate || 0);
          const banxicoExchangeRate = +(banxico_exchange_rate || 0);
          return +total * (exchangeRate || banxicoExchangeRate || 1);
        },
        cell: ({ row }) => {
          const { total, exchange_rate, banxico_exchange_rate } = row.original;
          const exchangeRate = +(exchange_rate || 0);
          const banxicoExchangeRate = +(banxico_exchange_rate || 0);
          const totalInMxn =
            +total * (exchangeRate || banxicoExchangeRate || 1);
          return <Flex justify="end">{toCurrency(totalInMxn)}</Flex>;
        },
        filterFn: 'number',
        filterHandler: {
          type: 'number',
          initialFilter: null,
        },
      },
      {
        header: 'Estatus',
        label: 'Estatus',
        id: 'status',
        accessorFn: (cfdi) => getInvoiceStatus(cfdi.status),
        cell: ({ row }) => <Cell>{getInvoiceStatus(row.original.status)}</Cell>,
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: cfdiStatusDbOpts,
        },
      },
      {
        header: 'Método de pago',
        id: 'paymentMethod',
        label: 'Método de pago',
        filterHandler: {
          type: 'select',
          initialFilter: '',
          options: [
            {
              label: 'PUE',
              value: '0',
            },
            {
              label: 'PPD',
              value: '1',
            },
          ],
        },
        accessorFn: (cfdi) => (cfdi.is_pue ? 'PUE' : 'PPD'),
        cell: ({ row }) => (
          <Chip label={row.original.is_pue ? 'PUE' : 'PPD'} color="gray500" />
        ),
      },
      {
        header: isEmitted
          ? 'Tipo de factura'
          : () => (
              <Flex justify="between" align="center">
                <Popover
                  openDelay={100}
                  triggerElement={
                    <Flex
                      justify="between"
                      align="center"
                      css={{
                        width: '100%',
                        path: {
                          fill: '$gray500',
                        },
                        cursor: 'pointer',
                      }}
                    >
                      <Text>Tipo de factura</Text>
                      <QuestionMarkCrFr2OutlineIcon width={14} height={14} />
                    </Flex>
                  }
                >
                  Esta columna representa el tipo de factura utilizada por tu
                  proveedor:
                  <Flex column>
                    <Text>
                      <Text bold>Ingreso:</Text> Utilizado para representar una
                      venta realizada por el emisor (🤫 psst un gasto para ti).{' '}
                    </Text>
                    <Text>
                      <Text bold>Egreso ó Nota de crédito:</Text> Utilizado para
                      representar una devolución o nota de crédito del
                      producto/servicio que adquiriste.{' '}
                    </Text>
                    <Text>
                      <Text bold>Nómina:</Text> Utilizado para representar un
                      pago de Nómina del emisor hacia ti.{' '}
                    </Text>
                  </Flex>
                </Popover>
              </Flex>
            ),
        accessorKey: 'invoice_type',
        label: 'Tipo de factura',
        filterHandler: {
          type: 'select',
          initialFilter: null,
          options: cfdiInvoiceTypeDbOpts,
        },
        cell: ({ row }) => {
          const invoiceType = row.original.invoice_type;

          return (
            <Chip
              label={cfdiInvoiceType[invoiceType]}
              color={invoiceTypeChipColor[invoiceType]}
            />
          );
        },
      },
      {
        header: 'Estatus de pago',
        label: 'Estatus de pago',
        id: 'paymentStatus',
        accessorFn: (cfdi) => getPaymentStatus(cfdi),
        cell: ({ row }) => {
          const paymentStatus = getPaymentStatus(row.original);
          const color = {
            Completo: 'success200' as const,
            'Sin pagos': 'gray200' as const,
            Incompleto: 'warning200' as const,
          };

          return <Chip color={color[paymentStatus]} label={paymentStatus} />;
        },
      },
      {
        header: 'Folio fiscal',
        label: 'Folio fiscal',
        accessorKey: 'folio_fiscal',
        cell: ({ row }) => (
          <Cell uppercase isCollapsedText>
            {row.original.folio_fiscal || ''}
          </Cell>
        ),
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
      },
      {
        id: 'actionsBtn',
        header: 'Acciones',
        label: 'Acciones',
        enableSorting: false,
        enableHiding: false,
        enableGrouping: false,
        cell: ({ row: { original } }) => (
          <Flex gap={4}>
            <ActionButton
              tooltip="Ver factura"
              onClick={() => {
                setCfdi(original);
                setTogglePdf();
              }}
            >
              <EyeLineIcon />
            </ActionButton>
            <ActionButton
              tooltip="Descargar PDF"
              onClick={async () => {
                const folioFiscal = original?.folio_fiscal;
                await downloadPdfFromCfdi(original?.id, folioFiscal);
              }}
            >
              <PdfIcon />
            </ActionButton>
            <ActionButton
              tooltip="Descargar XML"
              onClick={async () => {
                const folioFiscal = original?.folio_fiscal;
                await downloadXmlFromCfdi(original?.id, folioFiscal);
              }}
            >
              <XmlIcon />
            </ActionButton>
            {original.is_emited &&
              !original.is_pue &&
              original.invoice_type !== 'payment' && (
                <ActionButton
                  tooltip="Complementos de pago"
                  onClick={() => {
                    setCfdi(original);
                    setToggleComplement();
                  }}
                >
                  <FileAddInLc1OutlineIcon />
                </ActionButton>
              )}
            {modalInsights && (
              <ActionButton
                tooltip="Ver reportes"
                onClick={() => handleShowReports(original)}
              >
                <ListLineIcon />
              </ActionButton>
            )}
          </Flex>
        ),
      },
    ];
  }, [
    handleShowReports,
    modalInsights,
    selectedTab,
    setCfdi,
    setToggleComplement,
    setTogglePdf,
  ]);
}
