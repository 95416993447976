import { Flex, Text } from '@konta/ui';
import { Divider } from 'backoffice/modules/customer/Customer/styled';

export default function Header() {
  return (
    <Flex direction="column">
      <Flex
        direction="column"
        css={{ background: '#fff', width: '100%' }}
        gap={12}
      >
        <Text x3l bold data-cy="pending-tasks-title">
          Tareas pendientes
        </Text>
        <Text l color="gray700">
          Supervisa y completa tus tareas asignadas.
        </Text>
      </Flex>
      <Divider
        css={{
          my: '$24',
        }}
      />
    </Flex>
  );
}
