import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { changePasswordSuccess, changePasswordFailure } from '../actions';

export default function* ({ payload: { user, navigate } }) {
  try {
    const response = yield call(
      api.put,
      '/password',
      user,
      {}
      // { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      response,
      title: 'Exito!',
      message: 'La contraseña se ha restablecido correctamente!',
      status: response.status,
      success: changePasswordSuccess,
      failure: changePasswordFailure,
    });
    navigate('/login');
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: changePasswordFailure,
      showBodyErrors: false,
    });
  }
}
