import {
  ProcessType,
  ProcessStepType,
  OnboardingProcessStepType,
  AnualProcessStepType,
} from 'types/entities';
import { PROCESSES_ROUTE } from '@constants/process';

export default function getActiveStepRouteByProcessType(
  processType: ProcessType | undefined,
  currentStep: ProcessStepType | null | undefined,
) {
  if (processType === ProcessType.OnboardingOnboardingProcess) {
    const onboardingCurrentStep = currentStep as OnboardingProcessStepType;
    return PROCESSES_ROUTE.OnboardingOnboardingProcess[onboardingCurrentStep];
  }
  if (processType === ProcessType.AnnualDeclarationProcess) {
    const annualCurrentStep = currentStep as AnualProcessStepType;
    return PROCESSES_ROUTE.AnnualDeclarationProcess[annualCurrentStep];
  }

  return null;
}
