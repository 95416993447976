import {
  CONTACT_METHOD_FETCH,
  CONTACT_METHOD_FETCH_SUCCESS,
  CONTACT_METHOD_FETCH_FAILURE,
  CONTACT_METHOD_POST,
  CONTACT_METHOD_POST_SUCCESS,
  CONTACT_METHOD_POST_FAILURE,
  CONTACT_METHOD_UPDATE,
  CONTACT_METHOD_UPDATE_SUCCESS,
  CONTACT_METHOD_UPDATE_FAILURE,
  CONTACT_METHOD_DELETE,
  CONTACT_METHOD_DELETE_SUCCESS,
  CONTACT_METHOD_DELETE_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  contact_methods: [],
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONTACT_METHOD_FETCH:
      return { ...state, loading: true };
    case CONTACT_METHOD_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        contact_methods: action.payload,
      };
    case CONTACT_METHOD_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONTACT_METHOD_POST:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_METHOD_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        contact_methods: [...state.contact_methods, action.payload],
      };
    case CONTACT_METHOD_POST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONTACT_METHOD_UPDATE:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_METHOD_UPDATE_SUCCESS:
      if (action.payload.primary) {
        state.contact_methods
          .filter((item) => item.contact_type === action.payload.contact_type)
          .map((contactMethod) =>
            contactMethod.primary === true
              ? (contactMethod.primary = false)
              : contactMethod
          );
      }
      return {
        ...state,
        loading: false,
        contact_methods: state.contact_methods.map((contactMethod) =>
          contactMethod.id === action.payload.id
            ? action.payload
            : contactMethod
        ),
      };
    case CONTACT_METHOD_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONTACT_METHOD_DELETE:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_METHOD_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        contact_methods: state.contact_methods.filter(
          (contact_method) => contact_method.id !== action.payload
        ),
      };
    case CONTACT_METHOD_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return { ...state };
  }
};
