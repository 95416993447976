import { ArrowRightLineIcon } from '@konta/icons';
import WorkflowsLayout from '@components/WorkflowsLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import convertNumberToWords from '@util/convertNumberToWords';
import useWorkflow from '../hooks/useWorkflow';
import { Feature, Header, Root, Title } from '../styled';
import declarationInfoImage from './declarationInfo.svg';

export default function DeclarationInfo() {
  const { currentRegimes } = useWorkflow();
  const regimesLen = convertNumberToWords(currentRegimes.length);
  return (
    <WorkflowsLayout
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton rightIcon={<ArrowRightLineIcon />} />
        </>
      }
      title="Declaración mensual"
    >
      <Root>
        <Header
          css={{
            mb: '0',
          }}
        >
          <Title>¿Cómo funciona tu declaración?</Title>
          <Feature>
            Al tener {regimesLen} regímenes fiscales que declarar, Konta tiene
            que presentarte {regimesLen} declaraciones en&nbsp;
            {regimesLen} lugares distintos en el portal del SAT es importante
            que sepas que el SAT requiere la información de tus negocios por
            separado, con el fin de determinar el impuesto proporcional de cada
            régimen.
          </Feature>
        </Header>
        <img
          style={{
            width: '100%',
          }}
          src={declarationInfoImage}
          alt="Declaration info"
        />
      </Root>
    </WorkflowsLayout>
  );
}
