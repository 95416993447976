import api from '@api';
import { ForeignDocumentQuery } from 'types/entities';

export default async function getForeignProducts() {
  const { data } = await api.get<ForeignDocumentQuery>(
    `/foreign_products?per_page=10000`,
  );

  return data.data;
}
