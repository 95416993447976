import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import classnames from 'classnames';
import toCurrency from '@util/toCurrency';
import { TablePagination, IconButton, IconConfirmModal } from '@components';

function ForeignProductsTable({ data, loading, deleteRecord, editRecord }) {
  const columns = React.useMemo(
    () => [
      {
        Header: (
          <span className="list-item-heading text-capitalize">Descripción</span>
        ),
        accessor: 'description',
        Cell: (props) => <p className="list-item-heading"> {props.value} </p>,
      },
      {
        Header: 'Total',
        accessor: 'amount',
        Cell: (props) => {
          const { foreign_product } = props.row.original.source;
          return (
            <p className="list-item-heading text-capitalize">
              {toCurrency(+foreign_product.taxable_amount)}
            </p>
          );
        },
      },
      {
        Header: 'Descargar',
        accessor: 'documents_url',
        Cell: (props) => {
          const { foreign_product } = props.row.original.source;
          return foreign_product.documents ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={foreign_product.documents}
            >
              <i className="iconsmind-Download mr-3 font-weight-bold" />
              Descargar
            </a>
          ) : null;
        },
      },
      {
        Header: '',
        accessor: 'acc',
        Cell: (props) => {
          return (
            <>
              <IconButton
                id={`edit-icon-${props.row.original.id}`}
                tooltipHelper="Editar invoice"
                hover="primary"
                iconName="simple-icon-pencil"
                handleClick={() => editRecord(props.row.original)}
                className=""
              />
              <IconConfirmModal
                cancelButton="Cancelar"
                confirmButton="Confirmar"
                iconId={`delete-icon-${props.row.original.id}`}
                iconTooltipHelper="Borrar producto"
                iconHover="danger"
                iconName="simple-icon-trash"
                handleSubmit={() => deleteRecord(props.row.original.id)}
                iconClassName="ml-2"
              >
                <i className="large-icon iconsmind-Danger" color="danger" />
                <p>¡Estas a punto de borrar un gasto en el extranjero!</p>
              </IconConfirmModal>
            </>
          );
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 4 },
    },
    useSortBy,
    usePagination,
  );
  const noDataText = 'No hay registros que mostrar';
  const divided = false;

  return (
    <>
      <table
        {...getTableProps()}
        className={`r-table table ${classnames({ 'table-divided': divided })}`}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sorted-desc'
                        : 'sorted-asc'
                      : ''
                  }
                >
                  {column.render('Header')}
                  <span />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.length > 0 && !loading ? (
            page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      key={`td_${cellIndex}`}
                      {...cell.getCellProps({
                        className: cell.column.cellClass,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <>
              {[...Array(pageSize)].map((row, rowIndex) => {
                return (
                  <tr role="row" key={`row-${rowIndex}`}>
                    {[...Array(columns.length)].map((row, cellIndex) => {
                      return (
                        <td role="cell" key={`cell-${cellIndex}`}>
                          <div className="list-item-heading"> </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
      {page.length === 0 && <div className="rt-noData">{noDataText}</div>}
      <div className={`rt-loading ${loading ? 'rt-active' : ''}`}>
        <div className="rt-loading-inner">Cargando...</div>
      </div>

      <TablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[4, 10, 20, 30, 40, 50]}
        showPageSizeOptions
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />
    </>
  );
}

export default ForeignProductsTable;
