import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { BlobProvider } from '@react-pdf/renderer';
import useFiscalRegimesQuery from '@hooks/useFiscalRegimesQuery';
import PreviewDocument from '@components/PreviewDocument';
import { InvoicePDF } from './preview-pdf';
// TODO: fix this
// eslint-disable-next-line import/no-cycle
import { PreviewPdfViewer } from './preview-pdf-viewer';

export default function PreviewPdfModal({
  cfdi,
  title,
  showPreview,
  toggle,
  updateLegalType = false,
  updateAddress = false,
  newLegalType = '',
  newPostCode = '',
  isWorkflowPublicInvoice,
}) {
  const { regimes } = useFiscalRegimesQuery();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
    setOpen(true);
    return () => setOpen(false);
  });

  useEffect(() => {
    if (showPreview === false) {
      setOpen(false);
    }
  }, [showPreview]);

  if (regimes.length === 0) {
    return null;
  }

  return (
    <>
      {/* {
        /!* Modal del 80% de la pantalla, y minimo del 50 % de la altura de la pantalla *!/
      } */}
      <Modal
        isOpen={showPreview}
        toggle={toggle}
        style={{
          maxWidth: '1600px',
          minHeight: '50vh',
          width: '80vw',
          background: 'white',
        }}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {open && showPreview && (
            <>
              {isWorkflowPublicInvoice ? (
                <Row>
                  <Col md={9}>
                    <BlobProvider
                      document={
                        <InvoicePDF cfdi={cfdi.receipt} regimes={regimes} />
                      }
                    >
                      {({ url, loading }) => (
                        <PreviewDocument
                          defaultFile={url}
                          loading={loading}
                          maxWidth={900}
                        />
                      )}
                    </BlobProvider>
                  </Col>
                </Row>
              ) : (
                <PreviewPdfViewer
                  receipt={cfdi}
                  updateLegalType={updateLegalType}
                  updateAddress={updateAddress}
                  newLegalType={newLegalType}
                  newPostCode={newPostCode}
                >
                  <InvoicePDF cfdi={cfdi.receipt} regimes={regimes} />
                </PreviewPdfViewer>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
