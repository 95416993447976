import React, { useMemo, useState } from 'react';
import { CircularLoading, Flex, Input, InputAction } from '@konta/ui';
import postEmailInvoice from '@api/postEmailInvoice';
import { NotificationManager } from '@components';
import getErrorMessage from '@util/getErrorMessage';
import isValidEmail from '@util/isValidEmail';
import { ReactComponent as SendIcon } from '@assets/img/icons/send-icon.svg';

export default function SendByEmail({ id }) {
  const [email, setEmail] = useState('');
  const [touched, setTouched] = useState(false);
  const [loading, setIsloading] = useState(false);
  const isValid = useMemo(
    () => (touched ? !email || isValidEmail(email) : true),
    [email, touched],
  );
  const sendInvite = async () => {
    if (!isValidEmail(email)) return;
    setIsloading(true);
    try {
      const body = {
        id,
        email,
      };
      await postEmailInvoice(body);
      setEmail('');
      setTouched(false);
      NotificationManager.success('Factura enviada por email', 'Factura', 3000);
      setIsloading(false);
    } catch (error) {
      const message = getErrorMessage(error);
      NotificationManager.error(message, 'Error', 6000);
    }
  };

  return (
    <Input
      value={email}
      css={{ label: { margin: 0 } }}
      onChange={({ target }) => setEmail(target.value)}
      size="s"
      label="Email"
      type="email"
      placeholder="example@example.com"
      color={isValid ? 'gray' : 'error'}
      helperText={!isValid && 'Email inválido'}
      helperTextColor="error"
      onBlur={() => setTouched(true)}
      rightActions={
        <InputAction onClick={sendInvite} loading={loading} disabled={loading}>
          <Flex gap="4" align="center">
            {loading ? <CircularLoading /> : <SendIcon />}
          </Flex>
        </InputAction>
      }
    />
  );
}
