import { FilePlus02LineIcon } from '@konta/icons';
import ContainerButton from 'shared/components/ContainerButton';
import ForeignProductModalForm from '@components/ForeignProductModalForm';
import useUploadForeignProduct from './useUploadForeignProduct';

interface UploadForeignProductProps {
  toggleOpenUploadExpense: () => void;
}
export default function UploadForeignProduct({
  toggleOpenUploadExpense,
}: UploadForeignProductProps) {
  const {
    toggleShowConfirmation,
    showConfirmation,
    handleSubmit,
    regimeOptionsWithDeclarationId,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  } = useUploadForeignProduct({ toggleOpenUploadExpense });

  return (
    <>
      <ContainerButton
        icon={<FilePlus02LineIcon />}
        title="Agregar pedimentos de importación"
        onClick={toggleShowConfirmation}
      />
      {showConfirmation && (
        <ForeignProductModalForm
          withBlur
          centered
          isOpen={showConfirmation}
          onClose={toggleShowConfirmation}
          onSubmit={handleSubmit}
          isFromWorkflow
          regimeOptions={regimeOptionsWithDeclarationId}
          // @ts-ignore ForeignProductModalForm is not a ts component yet
          defaultRegimeOption={defaultRegimeOptionBySatkey}
          taxableEntityPreferences={taxableEntityPreferences}
        />
      )}
    </>
  );
}
