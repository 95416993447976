import { AxiosRequestConfig } from 'axios';
import api from '@api';
import type { Declaration, DeclarationAttributes } from 'types/entities';

// eslint-disable-next-line import/prefer-default-export
export const putDeclaration = async (
  payload: DeclarationAttributes,
  declarationId: number | string,
  config?: AxiosRequestConfig<Declaration>,
) => {
  const { data } = await api.put<Declaration>(
    `declarations/${declarationId}`,
    payload,
    config,
  );
  return data;
};
