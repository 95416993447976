import React, { Fragment } from 'react';
import { Button, Row, Label, Form, Input } from 'reactstrap';
import { Colxx } from '@components/CustomBootstrap';

export function StatementUploadCash({
  handleBillingChange,
  billingStatements,
  deleteStatement,
  addConcept,
}) {
  return (
    <Form>
      <Row className="mx-2">
        {billingStatements.map((billingStatement, idx) => (
          <Fragment key={idx}>
            <Colxx xxs={5} className="mb-2">
              <div className="form-group">
                <Label for={`amount-${idx}`}>Cantidad</Label>
                <Input
                  name={`amount-${idx}`}
                  value={billingStatement.amount}
                  id={`amount-${idx}`}
                  type="number"
                  required
                  onChange={(e) => handleBillingChange(idx, e)}
                />
              </div>
            </Colxx>
            <Colxx xxs={5} className="mb-2">
              <div className="form-group">
                <Label className="av-label" for={`description-${idx}`}>
                  Concepto
                </Label>
                <Input
                  placeholder="Breve despcripción"
                  name={`description-${idx}`}
                  value={billingStatements[idx].description}
                  id={`description-${idx}`}
                  required
                  onChange={(e) => handleBillingChange(idx, e)}
                />
              </div>
            </Colxx>
            <Colxx xxs={1} className="d-flex align-items-center mb-2">
              <i
                onClick={() => deleteStatement(idx)}
                style={{ fontSize: 20 }}
                className="simple-icon-trash btn"
              />
            </Colxx>
          </Fragment>
        ))}
        <Colxx xss={12}>
          <Button
            outline
            size="sm"
            className="mr-2"
            color="primary"
            onClick={addConcept}
          >
            Añadir
          </Button>
        </Colxx>
      </Row>
    </Form>
  );
}
