import { useMemo, useState } from 'react';
import { useToggle } from 'rooks';
import { useQueryClient } from 'react-query';
import { DECLARATION_ENTRIES_BY_WORKFLOW } from 'shared/constants/reactQueries';
import type { Cfdi } from 'types/entities';

interface UsePreviousPeriodsModalTableProps {
  updateCurrentWorkflow?: () => void;
  source: 'incomes' | 'expenses';
  toggleIsOpen?: () => void;
}
export default function usePreviousPeriodsModalTable({
  updateCurrentWorkflow,
  source,
  toggleIsOpen,
}: UsePreviousPeriodsModalTableProps) {
  const queryClient = useQueryClient();
  const [isAddEntryModalOpen, toggleIsAddEntryModalOpen] = useToggle();
  const [selectedCfdis, setSelectedCfdis] = useState<Cfdi[]>([]);
  const selectedCfdisLength = selectedCfdis.length;
  const addInvoiceBtnText = useMemo(
    () =>
      `Agregar ${selectedCfdisLength > 1 ? 'facturas' : 'factura'} a tus ${
        source === 'incomes' ? 'ingresos' : 'gastos'
      }`,
    [selectedCfdisLength, source],
  );

  const isAddInvoiceBtnEnabled = useMemo(
    () => selectedCfdisLength > 0,
    [selectedCfdisLength],
  );

  const onCloseModal = () => {
    toggleIsOpen?.();
    updateCurrentWorkflow?.();
    void queryClient.invalidateQueries([DECLARATION_ENTRIES_BY_WORKFLOW]);
  };

  return {
    isAddEntryModalOpen,
    toggleIsAddEntryModalOpen,
    selectedCfdis,
    setSelectedCfdis,
    addInvoiceBtnText,
    isAddInvoiceBtnEnabled,
    onCloseModal,
  };
}
