import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Text, Flex } from '@konta/ui';
import {
  ISR_OPTIONS,
  IVA_OPTIONS,
  IVA_RET_OPTIONS,
  UNIT_TYPE_OPTIONS,
} from '@constants/invoicing';
import FormikTextarea from '@components/FormikTextarea';
import FormikTextInput from '@components/FormikTextInput';
import FormikKontaSelect from '@components/FormikKontaSelect';
import useUpdateProduct from '@hooks/useUpdateProduct';
import useCreateProduct from '@hooks/useCreateProduct';
import FormFieldWrapper from '@components/FormFieldWrapper';
import FormWrapper from '@components/FormWrapper';
import sanitizeTextForStamp from 'shared/util/sanitizeTextForStamp';
import isGenericRfc from '@util/isGenericRfc';
import isForeignRfc from '@util/isForeignRfc';
import './productForm.scss';

const validationSchema = yup
  .object()
  .nullable()
  .shape({
    description: yup
      .string()
      .max(1000, 'Máximo 1000 caracteres')
      .required('Campo requerido')
      .test({
        test: (value) => !(value || '').includes('|'),
        message: 'No se permite el caracter "|"',
      })
      .matches(/[^|]+$/g, 'No se permite el caracter "|"'),
    satCode: yup.string().required('Campo requerido'),
    propertyTaxAccount: yup
      .string()
      .nullable()
      .test({
        test: (value) => !(value || '').includes('-'),
        message: 'Sólo se permiten números y letras',
      })
      .matches(/^[a-zA-Z0-9]*$/g, 'Sólo se permiten números y letras'),
    price: yup
      .number()
      .required('Campo requerido')
      .moreThan(0, 'Debe ser mayor a 0'),
    unit: yup.object().nullable().required('Campo requerido'),
  });

function productToValues(product, client) {
  if (!product) {
    const iva = isForeignRfc(client?.rfc)
      ? IVA_OPTIONS.find((item) => item.key === 3) // IVA 0%
      : IVA_OPTIONS.find((item) => item.key === 1); // IVA 16%
    return {
      name: '',
      description: '',
      price: '0',
      internalId: '',
      unit: null,
      iva,
      isr: null,
      retainedIva: null,
      satCode: '',
      propertyTaxAccount: '', // cuenta predial
    };
  }

  return {
    name: product.name,
    description: product.description,
    price: product.price || '0',
    internalId: product.product_id || '',
    unit: UNIT_TYPE_OPTIONS.find((item) => item.id === product.unit_id),
    iva: IVA_OPTIONS.find((item) => item.value === product.iva),
    isr: ISR_OPTIONS.find((item) => item.value === product.isr),
    retainedIva: IVA_RET_OPTIONS.find(
      (item) => item.value === product.retained_iva,
    ),
    satCode: product.sat_product_id,
    propertyTaxAccount: product.property_tax_account, // cuenta predial
  };
}

function valuesToPayload(product, values) {
  const sanitizedDescription = sanitizeTextForStamp(values.description);
  return {
    id: product?.id,
    name: sanitizeTextForStamp(values.name),
    description: sanitizedDescription.substring(0, 1000),
    price: values.price,
    product_id: values.internalId,
    unit_id: values.unit?.id,
    iva: values.iva?.value,
    isr: values.isr?.value,
    retained_iva: values.retainedIva?.value,
    sat_product_id: values.satCode,
    property_tax_account: values.propertyTaxAccount,
  };
}

export default function ProductForm({
  product,
  onSubmit,
  onLoading,
  fiscalRegime,
  client,
}) {
  const updateProduct = useUpdateProduct({ onSuccess: onSubmit });
  const createProduct = useCreateProduct({ onSuccess: onSubmit });
  const initialValues = useMemo(
    () => productToValues(product, client),
    [client, product],
  );
  const isLoading = updateProduct.isLoading || createProduct.isLoading;

  useEffect(() => {
    onLoading(isLoading);
  }, [isLoading, onLoading]);

  const handleSubmit = (values) => {
    const payload = valuesToPayload(product, values);

    if (payload.id) {
      updateProduct.mutate(payload);
    } else {
      createProduct.mutate(payload);
    }
  };

  const ivaOptions = useMemo(() => {
    // if is platform regime put tooltip and disabled option iva 8%
    if (fiscalRegime?.sat_key === 625) {
      return IVA_OPTIONS.map((item) => {
        if (item.value === 0.08) {
          return {
            ...item,
            tooltip:
              'Este beneficio fiscal no está disponible en el régimen fiscal del emisor.',
            isDisabled: true,
          };
        }
        return item;
      });
    }
    return IVA_OPTIONS;
  }, [fiscalRegime?.sat_key]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const clientRfc = client?.rfc;

  const isEnabledRetainedIva = useMemo(() => {
    if (isGenericRfc(clientRfc)) {
      return !!formik.values.retainedIva && formik.values.retainedIva.value > 0;
    }
    return true;
  }, [clientRfc, formik.values.retainedIva]);

  const isEnabledIsr = useMemo(() => {
    if (isGenericRfc(clientRfc)) {
      return !!formik.values.isr && formik.values.isr.value > 0;
    }
    return true;
  }, [clientRfc, formik.values.isr]);

  return (
    <FormikProvider value={formik}>
      <Form id="product-form">
        <FormWrapper>
          <FormFieldWrapper>
            <FormikTextarea
              rows={3}
              name="description"
              label="Descripción *"
              placeholder="Máximo 1000 caracteres"
              helperText="Máximo 1000 caracteres"
              maxLength={1000}
              maxTextLength={1000}
            />
            <FormikTextInput
              name="name"
              label="Nombre interno"
              placeholder="Producto"
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormikTextInput
              type="number"
              name="price"
              step="any"
              label="Precio *"
            />
            <FormikTextInput
              name="satCode"
              placeholder="555333777"
              label={
                <>
                  {'Código del SAT * '}
                  <Text
                    underline
                    as="a"
                    href="http://pys.sat.gob.mx/PyS/catPyS.aspx"
                    target="_blank"
                    size="xs"
                    color="primary"
                  >
                    Catálogo
                  </Text>
                </>
              }
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormikTextInput
              name="internalId"
              label="No identificación interno"
              placeholder="123"
            />
            <FormikTextInput
              name="propertyTaxAccount"
              label="Cuenta Predial"
              placeholder="4567"
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormikKontaSelect
              name="unit"
              label="Unidad *"
              options={UNIT_TYPE_OPTIONS}
            />
          </FormFieldWrapper>
          <Flex direction="column" gap={10} css={{ mt: '$10' }}>
            <Text>Impuestos federales del producto</Text>
            <FormFieldWrapper>
              <FormikKontaSelect name="iva" label="IVA" options={ivaOptions} />
              <FormikKontaSelect
                name="retainedIva"
                label="IVA RET"
                options={IVA_RET_OPTIONS}
                isDisabled={!isEnabledRetainedIva}
              />
              <FormikKontaSelect
                name="isr"
                label="ISR"
                options={ISR_OPTIONS}
                isDisabled={!isEnabledIsr}
              />
            </FormFieldWrapper>
          </Flex>
        </FormWrapper>
      </Form>
    </FormikProvider>
  );
}

ProductForm.propTypes = {
  // TODO: move this to productToValues
  product: PropTypes.instanceOf(Object),
  fiscalRegime: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func,
  onLoading: PropTypes.func,
};

ProductForm.defaultProps = {
  product: null,
  onSubmit: null,
  onLoading: null,
  fiscalRegime: null,
  client: null,
};
