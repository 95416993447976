import { useEffect, useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { getMonthAndYearDate } from '@util/Utils';
import toCurrency from '@util/toCurrency';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import WorkflowLayout from '@components/WorkflowLayout';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import PrevPhaseButton from '@components/PrevPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import FormikOptionsSelect from '@components/FormikOptionsSelect';
import { CardOption } from '@konta/ui';
import { Collapse, Row } from 'reactstrap';
import useNextPhase from '@hooks/useNextPhase';
import { Colxx, NotificationManager } from '@components';
import usePostDeclarationEntries from 'shared/hooks/usePostDeclarationEntries';
import usePutDeclarationEntries from 'shared/hooks/usePutDeclarationEntries';
import useDeclarationEntriesByWorkflow from 'shared/hooks/useDeclarationEntriesByWorkflow';
import * as Sentry from '@sentry/react';
import type { ExtraIncome } from 'types/entities';
import FormikTextInput from 'shared/components/FormikTextInput';
import {
  defaultValues,
  HasExtraIncomeFormValues,
  valuesToPayload,
  validationSchema,
} from './form';
import style from './NotPlatformIncome.module.scss';

export default function NotPlatformIncome() {
  const nextPhase = useNextPhase();
  const { workflow } = useSelectedWorkflow();
  const { id, declaration_income } = workflow?.active_declaration ?? {};
  const postDeclarationEntries = usePostDeclarationEntries();
  const putDeclarationEntry = usePutDeclarationEntries();

  const { declarationEntries, declarationEntriesLoading } =
    useDeclarationEntriesByWorkflow(
      {
        params: {
          workflow_id: workflow?.id,
        },
      },
      {
        enabled: !!workflow?.id,
      },
    );

  const extraIncome = useMemo(() => {
    const extraIncomes = declarationEntries.filter(
      (entry) => entry.source_type === 'ExtraIncome',
    );
    if (Sentry?.captureException && extraIncomes.length > 1) {
      Sentry.captureException(
        `Existe más de un ingreso extra en la declaración ${id || 'sin id'}`,
      );
    }
    return extraIncomes.length ? extraIncomes[0] : null;
  }, [declarationEntries, id]);

  const initialValues = useMemo(() => {
    if (!extraIncome) {
      return defaultValues;
    }
    const { total16, zero_base: zeroBase } = extraIncome.source
      .extra_income as ExtraIncome;
    const totalOfExtras = +total16;

    return {
      totalOfExtras,
      extraZeroBases: +zeroBase,
    };
  }, [extraIncome]);

  const [hasExtraIncomes, setHasExtraIncomes] = useState([false]);

  const onSubmit = async (values: HasExtraIncomeFormValues) => {
    try {
      const withExtraIncomes = hasExtraIncomes[0];
      const payload = valuesToPayload(
        withExtraIncomes ? values : defaultValues,
        workflow,
        extraIncome,
        id,
      );
      if (extraIncome) {
        await putDeclarationEntry.mutateAsync({
          declarationEntryId: extraIncome.id,
          payload,
        });
      } else {
        await postDeclarationEntries.mutateAsync(payload);
      }

      nextPhase({
        workflow: {
          next_phase: 'primary_phase',
        },
      });
    } catch (error) {
      console.log(error);
      NotificationManager.error(
        'No se pudo actualizar la declaración',
        'Error al actualizar',
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const { totalOfExtras, extraZeroBases } = initialValues;
    setHasExtraIncomes([!!totalOfExtras || !!extraZeroBases]);
  }, [initialValues]);

  const isLoading =
    declarationEntriesLoading || postDeclarationEntries.isLoading;

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Captura tus ingresos fuera de las constancias"
          description="Se deben declarar todos los ingresos por ventas obtenidas en el mes y que hayan ingresado a su cuenta de banco personal."
        />
      }
      actions={
        <>
          <PrevPhaseButton loading={isLoading} />
          <PrimaryPhaseButton loading={isLoading} onClick={formik.submitForm} />
        </>
      }
    >
      <div>
        <h1>
          {'Según tu constancia de retención del mes de '}
          {getMonthAndYearDate(new Date(workflow?.start_date || ''))}
          {!!declaration_income && (
            <>
              {', se obtuvieron ingresos por ventas de '}
              {toCurrency(+declaration_income.total_of_constancies)}.
            </>
          )}
        </h1>
        <h1>
          ¿Obtuviste más ingresos por ventas fuera de las plataformas digitales?
          (No considerar ingresos por sueldos y salarios / empleo formal)
        </h1>
        <p className="m-0 text-muted">
          Ejemplo: Le vendí uno de mis productos a un conocido y me pagó 116
          pesos (IVA Incluido) en efectivo.
        </p>
      </div>
      <FormikProvider value={formik}>
        <div className={style.options}>
          <FormikOptionsSelect
            name="hasIncome"
            noEmpty
            setHasExtraIncomes={setHasExtraIncomes}
            hasExtraIncomes={hasExtraIncomes}
          >
            <CardOption
              value={false}
              header={
                <span data-testid="has-no-income-option">
                  No obtuve otros ingresos
                </span>
              }
            />
            <CardOption
              value
              header={
                <span data-testid="has-income-option">
                  Si obtuve más ingresos por ventas
                </span>
              }
            />
          </FormikOptionsSelect>
        </div>

        <Collapse isOpen={hasExtraIncomes[0]}>
          <Row>
            <Colxx xxs={12} md={6}>
              <FormikTextInput
                name="totalOfExtras"
                data-testid="totalOfExtras"
                type="number"
                label="Monto de ingresos con IVA"
                placeholder="Ej. 200"
              />
            </Colxx>

            <Colxx xxs={12} md={6}>
              <FormikTextInput
                name="extraZeroBases"
                data-testid="extraZeroBases"
                type="number"
                label="Monto de ingresos extra con el 0% de IVA"
                placeholder="Ej. 200"
              />
            </Colxx>
          </Row>
        </Collapse>
      </FormikProvider>
    </WorkflowLayout>
  );
}
