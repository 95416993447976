import { Section as KontaSection } from '@konta/ui';
import Section from 'shared/components/Section';
import IncomesLineSeries from 'shared/components/IncomesLineSeries';
import ExpensesLineSeries from 'shared/components/ExpensesLineSeries';

export default function LineSeries() {
  return (
    <KontaSection
      title="Tus ingresos y gastos 👍🏼"
      subtitle={null}
      content={
        <Section>
          <IncomesLineSeries />
          <ExpensesLineSeries />
        </Section>
      }
    />
  );
}
