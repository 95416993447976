import { QueryOptions, useQuery } from 'react-query';
import { USER_TAX_STATUS } from '@constants/reactQueries';
import { TaxStatus } from 'types/entities';
import getUserTaxStatus from '@api/getUserTaxStatus';

export default function useUserTaxStatus(
  queryOptions?: QueryOptions<TaxStatus>,
) {
  const {
    data = null,
    isLoading,
    ...rest
  } = useQuery<TaxStatus>(
    USER_TAX_STATUS,
    async () => {
      const userTaxStatus = await getUserTaxStatus();
      return userTaxStatus ?? null;
    },
    {
      ...queryOptions,
    },
  );

  return {
    taxStatusLoading: isLoading,
    taxStatus: data,
    ...rest,
  };
}
