import React, { Fragment } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  TYPE_DATA_TRANS,
  REGIMEN_FISCAL_TRANS,
  PAYMENT_TYPE_TRANS,
  METHOD_DATA_TRANS,
} from '@util/Translate';
import { numberWithCommas } from '@util/Utils';
import { USE_CFDI_OPTIONS } from '@constants/invoicing';
// Para usar una font (esto incluye variantes de una font para bold/italic), antes es indispensable registrarla bajo un nombre

// Objeto final del pdf, cuyas partes fueron separadas debajo del mismo.
// NOTA: Document y page son objetos necesarios para crear un pdf, y tienen atributos asociados a la totalidad del pdf, como herramientas de debugging.
export default function PpdPdfPreview({ cfdi, taxable_entity }) {
  return (
    <Document>
      <Page style={styles.container}>
        <HeadPDF cfdi={cfdi} taxable_entity={taxable_entity} />
        <InfoPDF cfdi={cfdi} />
        <TablePDF cfdi={cfdi} />
        <BaseTablePDF cfdi={cfdi} />
        <TaxesTablePDF cfdi={cfdi} />
        {!!cfdi.Descuento && <DiscountTablePDF cfdi={cfdi} />}
        <TotalTablePDF cfdi={cfdi} />
        <FooterSAT cfdi={cfdi} />
        <FooterTable cfdi={cfdi} />
      </Page>
    </Document>
  );
}

// Stilos para el documento, deben de agregarse por medio de un objeto o un array en el atributo de style del componente pdf
// Nota: no todos los estilos son válidos y algunos como los asociados al text o los tamaños funcionan de manera diferente, para mas informacion consultar react-pdf
const styles = StyleSheet.create({
  container: {
    fontSize: 10,
    padding: 40,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  separatorBottom: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#696969',
  },
  pt20: {
    paddingTop: 20,
  },
  pb20: {
    paddingBottom: 20,
  },
  bold: { fontFamily: 'Helvetica-Bold' },
});

function HeadPDF({
  cfdi: {
    Fecha,
    Folio,
    LugarExpedicion,
    TipoDeComprobante,
    NoCertificado,
    QR,
    FormaPago,
    MetodoPago,
    Moneda,
    TipoCambio,
  },
  taxable_entity,
}) {
  return (
    <View
      style={[
        styles.font,
        styles.row,
        styles.separatorBottom,
        styles.pb20,
        {
          justifyContent: 'space-between',
        },
      ]}
    >
      <View>
        <Text>
          Tipo: {TipoDeComprobante}-{TYPE_DATA_TRANS[TipoDeComprobante]}
        </Text>
        {MetodoPago && (
          <Text>
            Método de pago: {MetodoPago}-{METHOD_DATA_TRANS[MetodoPago]}
          </Text>
        )}
        <Text>Certificado: {NoCertificado}</Text>
        <Text>Fecha Expedición: {new Date(Fecha).toLocaleString('es-MX')}</Text>
        <Text>Lugar Expedición: {LugarExpedicion}</Text>
        {!!Folio && <Text>Folio: {Folio}</Text>}
        {!!FormaPago && (
          <Text>
            Forma de pago: {FormaPago}-{PAYMENT_TYPE_TRANS[FormaPago]}
          </Text>
        )}
        <Text>Tipo de moneda: {Moneda}</Text>
        {!!TipoCambio && (
          <Text>Tipo de cambio: {numberWithCommas(TipoCambio)}</Text>
        )}
      </View>
      {!!taxable_entity?.logo_url && taxable_entity?.show_logo_in_invoice && (
        <Image
          src={taxable_entity.logo_url}
          style={{
            width: 50,
            height: 50,
          }}
        />
      )}
    </View>
  );
}

function InfoPDF({ cfdi: { Emisor, Receptor } }) {
  return (
    <View
      style={[styles.row, styles.separatorBottom, styles.pt20, styles.pb20]}
    >
      <View style={{ flex: 1, paddingRight: 20 }}>
        <Text style={[styles.bold]}>Emisor:</Text>
        <Text>{Emisor.Rfc}</Text>
        <Text>{Emisor.Nombre}</Text>
        <Text>
          Regimen Fiscal: {Emisor.RegimenFiscal}{' '}
          {REGIMEN_FISCAL_TRANS[Emisor.RegimenFiscal]}
        </Text>
      </View>
      <View style={{ flex: 1, paddingRight: 20 }}>
        <Text style={[styles.bold]}>Receptor:</Text>
        <Text>{Receptor.Rfc}</Text>
        <Text>{Receptor.Nombre}</Text>
        <Text>
          Uso CFDI: {Receptor.UsoCFDI} -{' '}
          {
            USE_CFDI_OPTIONS.find(
              (useCFDI) => useCFDI.value === Receptor.UsoCFDI,
            )?.label
          }
        </Text>
      </View>
    </View>
  );
}

function TablePDF({ cfdi: { Conceptos } }) {
  const attributes = ['Cantidad', 'Descripcion', 'ValorUnitario', 'Importe'];
  return (
    <>
      <View
        style={[
          styles.row,
          styles.separatorBottom,
          { backgroundColor: '#f2f2f2', paddingBottom: 10, paddingTop: 10 },
        ]}
      >
        {attributes.map((attr, index) => (
          <View
            style={{
              flex: attr === 'Descripcion' ? 3 : 1,
              textAlign: 'right',
              paddingRight: 10,
            }}
            key={`pdf-header-${index}`}
          >
            <Text style={[styles.bold]}>
              {attr.replace(/([A-Z])/g, ' $1').trim()}
            </Text>
          </View>
        ))}
      </View>
      <View style={[styles.separatorBottom, { paddingBottom: 10 }]}>
        {Conceptos.map(({ $: item }, i) => (
          <View style={[styles.row, { paddingTop: 10 }]} key={`products-${i}`}>
            {attributes.map((attr, j) => (
              <View
                style={{
                  flex: attr === 'Descripcion' ? 3 : 1,
                  textAlign: 'right',
                  paddingRight: 10,
                }}
                key={`products-${i}${j}`}
              >
                <Text>
                  {attr === 'Descripcion' || attr === 'Cantidad'
                    ? item[attr]
                    : numberWithCommas(item[attr])}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    </>
  );
}

function BaseTablePDF({ cfdi: { SubTotal } }) {
  return (
    <View
      style={[
        styles.row,
        styles.pt20,
        styles.pb20,
        {
          textAlign: 'right',
          backgroundColor: '#f2f2f2',
        },
      ]}
    >
      <View style={{ flex: 4, paddingRight: 10 }}>
        <Text style={styles.bold}>Subtotal: </Text>
      </View>
      <View style={{ flex: 1, paddingRight: 10 }}>
        <Text>{numberWithCommas(SubTotal)}</Text>
      </View>
    </View>
  );
}

const TaxesTablePDF = ({ cfdi: { Impuestos, Retenciones, Traslados } }) => {
  return Object.entries(Impuestos).map(([key, value]) => (
    <Fragment key={key}>
      {key && key === 'TotalImpuestosRetenidos' && Number(value) > 0
        ? Retenciones.map((retencion, index) => {
            return (
              <View
                key={`retained-${index}`}
                style={[
                  styles.row,
                  styles.pt5,
                  {
                    textAlign: 'right',
                    backgroundColor: '#f2f2f2',
                  },
                ]}
              >
                <View style={{ flex: 4, paddingRight: 10 }}>
                  <Text>
                    {' '}
                    {retencion.$.Impuesto === '002' ? `IVA Retenido` : 'ISR'}
                  </Text>
                </View>
                <View style={{ flex: 1, paddingRight: 10 }}>
                  <Text>- {numberWithCommas(Number(retencion.$.Importe))}</Text>
                </View>
              </View>
            );
          })
        : key === 'TotalImpuestosTrasladados' &&
          Object.keys(Traslados).length > 0 &&
          Number(value) >= 0 &&
          Traslados.map((traslado, index) => {
            return (
              <View
                key={`traslado-${index}`}
                style={[
                  styles.row,
                  styles.pt5,
                  {
                    textAlign: 'right',
                    backgroundColor: '#f2f2f2',
                  },
                ]}
              >
                {traslado.$.Importe !== '0.00' ? (
                  <>
                    <View style={{ flex: 4, paddingRight: 10 }}>
                      <Text>
                        {' '}
                        {traslado.$.Impuesto === '002' ? `IVA` : 'IEPS'}
                      </Text>
                    </View>
                    <View style={{ flex: 1, paddingRight: 10 }}>
                      <Text>
                        {numberWithCommas(Number(traslado.$.Importe))}
                      </Text>
                    </View>
                  </>
                ) : (
                  <>
                    <View style={{ flex: 4, paddingRight: 10 }}>
                      <Text>
                        {' '}
                        {traslado.$.Impuesto === '002' ? `IVA 0%` : 'IEPS'}
                      </Text>
                    </View>
                    <View style={{ flex: 1, paddingRight: 10 }}>
                      <Text>
                        {numberWithCommas(Number(traslado.$.Importe))}
                      </Text>
                    </View>
                  </>
                )}
              </View>
            );
          })}
      {Number(value) > 0 && (
        <View
          style={[
            styles.row,
            styles.pb20,
            {
              textAlign: 'right',
              backgroundColor: '#f2f2f2',
            },
          ]}
        >
          <View style={{ flex: 4, paddingRight: 10 }}>
            <Text style={styles.bold}>
              {key.replace(/([A-Z])/g, ' $1').trim()}:
            </Text>
          </View>
          <View style={{ flex: 1, paddingRight: 10 }}>
            <Text>
              {key === 'TotalImpuestosRetenidos'
                ? `- ${numberWithCommas(value)}`
                : numberWithCommas(value)}
            </Text>
          </View>
        </View>
      )}
    </Fragment>
  ));
};
function DiscountTablePDF({ cfdi: { Descuento } }) {
  return (
    <View
      style={[
        styles.row,
        styles.pb20,
        {
          textAlign: 'right',
          backgroundColor: '#f2f2f2',
        },
      ]}
    >
      <View style={{ flex: 4, paddingRight: 10 }}>
        <Text style={styles.bold}>Descuento:</Text>
      </View>
      <View style={{ flex: 1, paddingRight: 10 }}>
        <Text>{`- ${numberWithCommas(Descuento)}`}</Text>
      </View>
    </View>
  );
}

function TotalTablePDF({ cfdi: { Total } }) {
  return (
    <View
      style={[
        styles.row,
        styles.separatorBottom,
        {
          textAlign: 'right',
          paddingBottom: 20,
          backgroundColor: '#f2f2f2',
        },
      ]}
    >
      <View style={{ flex: 4 }}>
        <Text style={styles.bold}>Total: </Text>
      </View>
      <View style={{ flex: 1, paddingRight: 10 }}>
        <Text style={styles.bold}>{numberWithCommas(Total)}</Text>
      </View>
    </View>
  );
}

function FooterSAT({
  cfdi: {
    QR,
    Complemento: { RfcProvCertif, UUID, FechaTimbrado, NoCertificadoSAT },
  },
}) {
  return (
    <View
      style={[styles.row, styles.pt20, styles.pb20, styles.separatorBottom]}
    >
      <View style={{ flex: 2 }}>
        <Image
          src={QR}
          style={{
            width: 125,
            height: 125,
          }}
        />
      </View>
      <View style={([styles.row], { flex: 4 })}>
        <View style={{ paddingBottom: 10 }}>
          <Text style={styles.bold}>Fecha / Hora de Certificación:</Text>
          <Text style={{ fontSize: 10 }}>
            {new Date(FechaTimbrado).toLocaleString('es-MX')}
          </Text>
        </View>
        <View style={{ paddingBottom: 10 }}>
          <Text style={styles.bold}>Certificado Digital SAT:</Text>
          <Text style={{ fontSize: 10 }}>{NoCertificadoSAT}</Text>
        </View>
        <View style={{ paddingBottom: 10 }}>
          <Text style={styles.bold}>RFC de Proveedor Certificado:</Text>
          <Text style={{ fontSize: 10 }}>{RfcProvCertif}</Text>
        </View>
        <View style={{ paddingBottom: 10 }}>
          <Text style={styles.bold}>Folio Fiscal:</Text>
          <Text style={{ fontSize: 10 }}>{UUID}</Text>
        </View>
      </View>
    </View>
  );
}

function FooterTable({
  cfdi: {
    Complemento: {
      FechaTimbrado,
      NoCertificadoSAT,
      SelloCFD,
      SelloSAT,
      Version,
      UUID,
    },
  },
}) {
  return (
    <View style={[styles.pt17]}>
      <Text style={styles.bold}>
        Cadena Original del complemento de Certificación Digital del SAT
      </Text>
      <Text
        style={{ fontSize: 8, margin: 8, marginTop: 0 }}
      >{`||${Version}|${UUID}|${FechaTimbrado}|${SelloCFD}|${NoCertificadoSAT}||`}</Text>
      <Text style={styles.bold}>Sello Digital del SAT</Text>
      <Text style={{ fontSize: 8, margin: 8, marginTop: 0 }}>{SelloSAT}</Text>
      <Text style={styles.bold}>Sello Digital del CFDI</Text>
      <Text style={{ fontSize: 8, margin: 8, marginTop: 0 }}>{SelloCFD}</Text>
      <Text>Este documento es una representación impresa de un CFDI</Text>
    </View>
  );
}
