import React from 'react';
import { Box } from '@konta/ui';

interface BackdropProps {
  visible: boolean;
}

export default function Backdrop({ visible }: BackdropProps) {
  return (
    <Box
      css={{
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        zIndex: 1040,
        backgroundColor: 'white',
        opacity: visible ? 0.7 : 0,
        pointerEvents: visible ? 'auto' : 'none',
        transition: 'all 300ms ease',
      }}
    />
  );
}
