import { styled } from '@konta/ui';

export const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});
export const ChatContainer = styled('div', {
  display: 'flex',
  fontFamily: '$inter',
  width: '100%',
  height: '99%',
  alignItems: 'center',
});
export const ChatMain = styled('div', {
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  height: '100%',
});
export const ChatMessagesWrapper = styled('div', {
  display: 'flex',
  flex: '1 1 auto',
  height: '0',
});
export const ChatMessagesContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1',
  position: 'relative',
  overflowY: 'auto',
  background: '$gray25',
});
