import { XCloseLineIcon } from '@konta/icons';
import { DialogHeaderProps } from './types';
import {
  HeaderContainer,
  LeftSection,
  AvatarGroup,
  Avatar,
  HeaderText,
  CloseButton,
  SliderIndicators,
  Indicator,
  HeaderContent,
} from './styled';

function DialogHeader({
  avatars,
  text,
  currentSlide,
  totalSlides,
}: DialogHeaderProps) {
  return (
    <HeaderContainer>
      <SliderIndicators>
        {Array.from({ length: totalSlides }).map((_, index) => (
          <Indicator key={index} active={index + 1 === currentSlide} />
        ))}
      </SliderIndicators>
      <HeaderContent>
        <LeftSection>
          <AvatarGroup>
            {avatars.map((avatar, index) => (
              <Avatar
                key={avatar.id}
                src={avatar.src}
                alt={`Avatar ${index + 1}`}
              />
            ))}
          </AvatarGroup>
          <HeaderText>{text}</HeaderText>
        </LeftSection>
        <CloseButton data-intercom-target="insights-close-button">
          <XCloseLineIcon />
        </CloseButton>
      </HeaderContent>
    </HeaderContainer>
  );
}

export default DialogHeader;
