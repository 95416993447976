import React from 'react';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { Row } from 'reactstrap';

import { Rating, SimpleStateButton, Colxx } from '@components';

export default function FeedbackForm({
  handleSubmit,
  onValidSubmit,
  loading = false,
  rating = null,
  setRating,
}) {
  return (
    <AvForm onSubmit={handleSubmit} onValidSubmit={onValidSubmit}>
      <Row>
        <Colxx lg="12" className="mb-2">
          <Rating
            total={5}
            rating={rating}
            onRate={(rating) => setRating(rating.rating)}
          />
        </Colxx>
        {rating <= 4 && rating && (
          <Colxx lg="12" className="mb-2">
            <AvGroup>
              <AvInput
                id="additional_comments"
                name="additional_comments"
                type="textarea"
                placeholder="Comentarios adicionales (opcional)"
              />
            </AvGroup>
          </Colxx>
        )}

        <Colxx lg="12">
          <SimpleStateButton
            loading={loading}
            disabled={loading || rating === null}
            className="btn-right"
          >
            Calificar
          </SimpleStateButton>
        </Colxx>
      </Row>
    </AvForm>
  );
}
