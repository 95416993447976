import getInvoiceType from './getInvoiceType';

export default function getPaymentStatus(cfdi) {
  if (getInvoiceType(cfdi) !== 'PPD') return 'Completo';
  const amountPaid = (cfdi.payment_details || [])
    .filter((complement) => {
      return (
        complement.cfdi?.status !== 'cancelled' &&
        complement.cfdi?.status !== 'cancellation_in_progress'
      );
    })
    .map((detail) => {
      return +detail.amount_paid;
    })

    .reduce((subtotal, paid) => subtotal + paid, 0);

  if (amountPaid >= cfdi.total) return 'Completo';

  if (amountPaid === 0) return 'Sin pagos';

  return 'Incompleto';
}
