import { useState } from 'react';
import {
  AccumulatedIncomeAndExpenseFilters,
  ReactSelectItemProps,
} from 'types/entities';
import Loader from '@components/Loader';
import StackGraph from '../StackGraph';
import useTopTenAccumulatedSales from '../useTopTenAccumulatedSales';

const generateFilterOpts = (numberOfYears: number) =>
  Array.from({ length: numberOfYears }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return { value: year.toString(), label: year.toString() };
  });

export default function BestSellingClients() {
  const filterOpts = generateFilterOpts(2);
  const [filter, setFilter] = useState<ReactSelectItemProps<string>>(
    filterOpts[0],
  );
  const { dateSet, isLoading, isDesktop } = useTopTenAccumulatedSales({
    filter: filter.value,
  });
  if (isLoading) {
    return <Loader />;
  }
  return (
    <StackGraph
      id="top10Series"
      isDesktop={isDesktop}
      dataSet={dateSet}
      title="Top 10 clientes con mayores ventas"
      textTitleHover="Lorem ipsum dolor sit amet."
      filterProps={{
        onChangeFilter: (newFilter) => {
          setFilter(
            newFilter as ReactSelectItemProps<AccumulatedIncomeAndExpenseFilters>,
          );
        },
        options: filterOpts,
        value: filter.value,
      }}
    />
  );
}
