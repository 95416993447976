import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { Img } from '@components';

export function IconButton({
  imageIcon,
  iconName = '',
  handleClick,
  size,
  width = '20px',
  hover = '',
  className = '',
  tooltipHelper = null,
  id = null,
  tooltipPlacement = 'top',
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      {imageIcon ? (
        <Img
          id={id}
          onClick={handleClick}
          src={iconName}
          style={{ width }}
          className={className}
          data-testid="img"
        />
      ) : (
        <i
          className={`icon-button ${iconName} ${className} ${
            hover && `icon-button-${hover}`
          }`}
          color="primary"
          onClick={handleClick}
          id={id}
          style={{ fontSize: size || '15px' }}
          data-testid="icon"
        />
      )}
      {tooltipHelper && (
        <Tooltip
          placement={tooltipPlacement}
          isOpen={tooltipOpen}
          target={id}
          toggle={toggle}
          data-testid="tooltiptrest"
        >
          {tooltipHelper}
        </Tooltip>
      )}
    </>
  );
}
