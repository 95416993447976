import { ReactNode } from 'react';
import { Flex, Text } from '@konta/ui';
import TooltipIcon from '../../../backoffice/components/TooltipIcon';

export interface SectionHeaderProps {
  title: string;
  subtitle?: string;
  textTooltip?: string;
  filters?: ReactNode;
}

export default function SectionHeader({
  title,
  subtitle,
  textTooltip,
  filters,
}: SectionHeaderProps) {
  return (
    <Flex column>
      <Flex itemsCenter justify="between">
        <Flex gap={6} itemsCenter>
          <Text color="gray700" medium s lineHeight="s">
            {title}
          </Text>
          {!!textTooltip && <TooltipIcon>{textTooltip}</TooltipIcon>}
        </Flex>
        {!!filters && filters}
      </Flex>
      {!!subtitle && (
        <Text color="gray900" medium l lineHeight="l">
          {subtitle}
        </Text>
      )}
    </Flex>
  );
}
