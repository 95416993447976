import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Flex, Text, Button } from '@konta/ui';
import { MailOutlineIcon } from '@konta/icons';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { NotificationManager } from '@components';
import FormikTextInput from '@components/FormikTextInput';
import { useMutation } from 'react-query';
import api from '@api';

export default function ForgotPassword() {
  const mutation = useMutation({
    mutationFn: (body) => {
      return api.post('password', body);
    },
    onSuccess: () => {
      NotificationManager.success(
        'Ya hemos enviado el correo, revisa tu bandeja de entrada y sigue las instrucciones',
        'Correo enviado',
        4000,
        null,
        null,
      );
    },
    onError: () => {
      NotificationManager.error(
        'El correo ingresado no existe',
        'No se envió el correo',
        4000,
        null,
        null,
      );
    },
  });

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Correo electrónico inválido')
      .required('Campo requerido'),
  });

  const handleRecoverPassword = (values) => {
    const body = {
      user: {
        email: values.email,
      },
    };
    mutation.mutate(body);
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleRecoverPassword,
  });
  const GRADIENT_BACKGROUND =
    'linear-gradient(to left bottom, #717cdb, #686dc9, #605eb7, #5750a5, #4e4294, #4e4294, #4e4294, #4e4294, #5750a5, #605eb7, #686dc9, #717cdb);, #6d72d8, #717cdb);';

  return (
    <Flex
      direction="row"
      css={{ backgroundImage: GRADIENT_BACKGROUND, height: '100vh' }}
      wrap="wrapReverse"
      justify="center"
      align="center"
    >
      <Flex
        direction="row"
        align="center"
        css={{
          height: 'auto',
          py: '80px',
          width: '90%',
          px: '30px',
          '@md': { width: '90%', px: '50px' },
          '@lg': { width: '35%', px: '50px' },
          background: 'white',
          borderRadius: '10px',
        }}
      >
        <Flex css={{ width: '100%' }}>
          <Flex direction="column" css={{ width: '100%' }}>
            <Flex css={{ mb: '40px' }}>
              <NavLink to="/">
                <span className="logo-single" />
              </NavLink>
            </Flex>
            <Flex direction="column" css={{ mb: '34px' }}>
              <Text color="gray800" xl bold>
                Revisa tu correo y sigue las instrucciones
              </Text>
              <Text color="gray500" m css={{ mt: '$8' }}>
                Si olvidaste tu contraseña te enviaremos un enlace a tu correo
                para que puedas cambiarla.
              </Text>
            </Flex>
            <FormikProvider value={formik}>
              <Flex direction="column" gap="18">
                <FormikTextInput
                  label="Correo electrónico"
                  name="email"
                  size="m"
                  placeholder="Introduce tu correo electrónico"
                  type="email"
                  leftIcon={<MailOutlineIcon />}
                  required
                />
              </Flex>
              <Flex
                direction="column"
                justify="between"
                align="stretch"
                css={{ mt: '$8' }}
              >
                <Flex direction="column" align="stretch" css={{ mt: '$24' }}>
                  <Button
                    color="primary600"
                    type="submit"
                    loading={mutation.isLoading}
                    disabled={mutation.isLoading}
                    fontMedium
                    onClick={formik.submitForm}
                    size="m"
                    css={{ mb: '18px' }}
                  >
                    Enviar correo
                  </Button>
                </Flex>
                <Button
                  color="gray"
                  as={Link}
                  to="/login"
                  loading={mutation.isLoading}
                  disabled={mutation.isLoading}
                  variant="minimal"
                  leftIcon={<ArrowLeftIcon />}
                  fontMedium
                  size="m"
                  css={{ mb: '18px' }}
                >
                  Regresa a inicio de sesión
                </Button>
                <Flex justify="center" align="end">
                  <Text color="gray400">
                    ¿Aún no tienes cuenta?{' '}
                    <Text
                      color="primary700"
                      css={{ '&:hover': { color: '$gray500' } }}
                      as={Link}
                      to="/register"
                      s
                      underline
                    >
                      Regístrate
                    </Text>{' '}
                  </Text>
                  {'  '}
                </Flex>
              </Flex>
            </FormikProvider>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
