import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  fetchCFDISByClientSuccess,
  fetchCFDISByClientFailure,
} from '../actions';

export default function* ({ payload: { client_id } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.get,
      `cfdis/by_client?client_supplier_id=${client_id}`,
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      status: response.status,
      response,
      success: fetchCFDISByClientSuccess,
      failure: fetchCFDISByClientFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: fetchCFDISByClientFailure,
    });
  }
}
