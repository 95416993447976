import api from '@api';

interface PutInvoiceDraftData {
  email?: string;
  templateName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stamp: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikValues: any;
  make_template_with_name?: string;
}

export default async function putInvoiceDraft(
  draftId: string,
  {
    email,
    templateName,
    stamp,
    formikValues,
    make_template_with_name,
  }: PutInvoiceDraftData,
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data } = await api.put(`/invoice_drafts/${draftId}`, {
    email,
    template_name: templateName,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    formik_values: formikValues,
    body: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      receipt: stamp,
    },
    make_template_with_name,
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data;
}
