import { ComponentProps, useMemo, useState } from 'react';
import {
  BarSeries,
  Button,
  Flex,
  Icon,
  ModalContent,
  ModalHeader,
  Tab,
  Tabs,
  Text,
} from '@konta/ui';
import LetterAvatar from 'react-avatar';
import CloseIcon from 'mdi-react/CloseIcon';
import { Modal } from 'reactstrap';
import { CreditCard02LineIcon } from '@konta/icons';
import Loader from '@components/Loader';
import Metric from './Metric';
import Filters from './Filters/Filters';
import { MetricWrapper, modalHeaderCss, Title, TopBar } from './styled';
import GraphSeries from '../GraphSeries';
import CfdisCollapseTable from '../CfdisCollapseTable/CfdisCollapseTable';
import useCfdisTotalsModal from './useCfdisTotalsModal';

interface CfdisTotalsModalsProps {
  open: boolean;
  toggleOpen: () => void;
  title: string;
  id: string;
  dataSet: ComponentProps<typeof BarSeries>[`dataSet`];
  filters: Record<string, string>;
  setFilters: (filters: Record<string, string>) => void;
  series: 'area' | 'line' | 'none';
  invoiceType: 'incomes' | 'expenses';
  selectedData: {
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  };
  datasetLoading: boolean;
}

type MetricValue = {
  title: string;
  total: number;
  tooltipText: string;
  isText?: boolean;
};

export default function CfdisTotalsModals({
  open,
  toggleOpen,
  id,
  dataSet,
  title,
  filters,
  setFilters,
  series,
  invoiceType,
  selectedData,
  datasetLoading,
}: CfdisTotalsModalsProps) {
  const { cfdis, periodWithInvoices, cfdisLoading } = useCfdisTotalsModal({
    source: invoiceType,
    currentDateFilter: filters.period,
    cfdi: selectedData,
    openModal: open,
  });

  const isLoading = datasetLoading || cfdisLoading;

  const [toggleTab, setToggleTab] = useState('transactions');

  const metrics = useMemo<MetricValue[]>(
    () => [
      {
        title: 'Total (Con Impuestos)',
        total: cfdis?.reduce((acc, cfdi) => acc + cfdi.total, 0) || 0,
        tooltipText: '',
      },
      {
        title: 'Subtotal (Sin Impuestos)',
        total: cfdis?.reduce((acc, cfdi) => acc + cfdi.subtotal, 0) || 0,
        tooltipText: '',
      },
      {
        title: 'Número de transacciones',
        total: cfdis?.length || 0,
        tooltipText: '',
        isText: true,
      },
    ],
    [cfdis],
  );

  const avatarName = selectedData.legalName;

  return (
    <Modal
      size="xxl"
      isOpen={open}
      toggle={toggleOpen}
      wrapClassName="modal-backdrop-blur"
    >
      <ModalHeader css={modalHeaderCss}>
        <Flex gap={8} align="center" justify="center">
          <LetterAvatar maxInitials={2} name={avatarName} size="48" round />
          <Title>{title}</Title>
        </Flex>
        <Button icon variant="text" onClick={toggleOpen}>
          <CloseIcon />
        </Button>
      </ModalHeader>
      <ModalContent>
        <TopBar>
          {isLoading ? (
            <Loader />
          ) : (
            <MetricWrapper>
              {metrics.map((metric) => (
                <Metric
                  key={metric.title}
                  title={metric.title}
                  total={metric.total}
                  tooltipText={metric.tooltipText}
                  isText={metric.isText}
                />
              ))}
            </MetricWrapper>
          )}

          <Filters filters={filters} setFilters={setFilters} />
        </TopBar>
        <Flex column gap={24}>
          <GraphSeries
            id={id}
            dataSet={dataSet}
            onlyGraph
            series={series}
            loader={isLoading}
          />
          <Flex>
            <Tabs value={toggleTab} variant="switch" indicator="bottom">
              <Tab
                id="transactions"
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <Icon
                  css={{
                    svg: {
                      path: {
                        fill: 'none',
                      },
                    },
                  }}
                >
                  <CreditCard02LineIcon />
                </Icon>
                <Text>Por transacciones</Text>
              </Tab>
            </Tabs>
          </Flex>
          <Flex column gap={24}>
            {periodWithInvoices.map(({ invoices, period }, index) => (
              <CfdisCollapseTable
                key={period}
                period={period}
                invoiceType={invoiceType}
                index={index}
                cfdis={invoices || []}
                loader={isLoading}
              />
            ))}
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
