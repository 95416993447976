import React from 'react';
import PostCodeForm from '@components/PostCodeForm';
import { useToggle } from 'rooks';
import usePostCodeErrors from '@hooks/useWatchPostCodeErrors';

export default function GlobalErrorHanler() {
  const [addressFormOpen, toggleAddressFormOpen] = useToggle(false);

  usePostCodeErrors(toggleAddressFormOpen);

  return (
    <PostCodeForm
      open={addressFormOpen}
      onClose={toggleAddressFormOpen}
      hasError
    />
  );
}
