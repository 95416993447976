import { OnboardingRouteType, OnboardingSteps } from 'types/onboarding';
import { OnboardingProcessStepTypes } from '../types/entities';

export const BUSINESS_OPTIONS = [
  {
    label: 'Más populares',
    options: [
      {
        value: 1,
        label: 'Vendedor de productos en marketplaces',
      },
      {
        value: 2,
        label: 'Doctor / Médico / Dentista / Psicólogo / Nutrición',
      },
      {
        value: 3,
        label: 'Creadores de contenido',
      },
    ],
  },
  {
    label: 'Tipo de negocio',
    options: [
      {
        value: 4,
        label: 'Actor',
      },
      {
        value: 5,
        label: 'Creadores de contenido',
      },
      {
        value: 6,
        label: 'Comisionistas externos',
      },
      {
        value: 7,
        label: 'Maestro, coach o profesional de la educación',
      },
      {
        value: 8,
        label: 'Vendedor de productos en marketplaces',
      },
      {
        value: 9,
        label: 'Vendedor de productos en tienda física',
      },
      {
        value: 10,
        label: 'Vendedor de productos en tienda online propia',
      },
      {
        value: 11,
        label: 'Vendedor de productos ambulante',
      },
      {
        value: 12,
        label: 'Fabricante',
      },
      {
        value: 13,
        label: 'Programador / Desarrollador',
      },
      {
        value: 14,
        label: 'Ingeniero',
      },
      {
        value: 15,
        label: 'Diseñador (gráfico, interiores, etc) / Fotógrafo',
      },
      {
        value: 16,
        label: 'Experto en Marketing',
      },
      {
        value: 17,
        label: 'Doctor / Médico / Dentista / Psicólogo / Nutrición',
      },
      {
        value: 18,
        label: 'Asesor inmobiliario',
      },
      {
        value: 19,
        label: 'Comisionista o broker (seguros, créditos, etc)',
      },
      {
        value: 20,
        label: 'Rentero de inmuebles / Arrendamiento',
      },
      {
        value: 21,
        label: 'Renta por AirBnb',
      },
      {
        value: 22,
        label: 'Inversiones',
      },
      {
        value: 23,
        label: 'Abogado',
      },
      {
        value: 24,
        label: 'Servicios de mantenimiento / Construcción',
      },
      {
        value: 25,
        label: 'Productor musical',
      },
      {
        value: 26,
        label: 'Servicios de consultoría',
      },
      {
        value: 27,
        label: 'Agencia de viajes',
      },
      {
        value: 28,
        label: 'Organización de eventos',
      },
      {
        value: 29,
        label: 'Asociación Civil u ONG',
      },
      {
        value: 30,
        label: 'Criptomonedas / Cryptos',
      },
      {
        value: 31,
        label: 'Uber / DiDi / Rappi / Cornershop',
      },
      {
        value: 32,
        label: 'Transportación / Logística / Envíos',
      },
      {
        value: 33,
        label: 'Criptomonedas / Cryptos',
      },
      {
        value: 34,
        label: 'Vende servicios (freelance)',
      },
      {
        value: 35,
        label: 'Peluquería / Salón de belleza / Barbería',
      },
      {
        value: 36,
        label: 'Otros',
      },
    ],
  },
];
export const OCCUPATIONS_OPTIONS = [
  {
    value: 1,
    label: 'Vendo productos o servicios en ecommerce propio',
  },
  { value: 2, label: 'Vendo productos o servicios en marketplaces' },
  { value: 3, label: 'Vendo productos o servicios en persona' },
  { value: 4, label: 'Arrendo bienes inmuebles' },
  { value: 5, label: 'Otros' },
];

export const FLATTEN_BUSINESS_OPTIONS = BUSINESS_OPTIONS.map(
  (group) => group.options,
).flat();

export const INVESTMENTS_OPTIONS = [
  { value: 1, label: 'Cryptomonedas' },
  { value: 2, label: 'Acciones de bolsa de valores' },
  { value: 3, label: 'YoTePresto' },
  { value: 4, label: '100 ladrillos' },
  { value: 5, label: 'Cetes' },
  { value: 6, label: 'Otros' },
];

export const ONBOARDING_STEPS: OnboardingSteps = {
  [OnboardingProcessStepTypes.Welcome]: OnboardingRouteType.Welcome,
  [OnboardingProcessStepTypes.Income]: OnboardingRouteType.Income,
  [OnboardingProcessStepTypes.Expense]: OnboardingRouteType.Expenses,
  [OnboardingProcessStepTypes.Credentials]: OnboardingRouteType.Ciec,
  [OnboardingProcessStepTypes.Schedule]: OnboardingRouteType.BookCall,
  [OnboardingProcessStepTypes.Meantime]: OnboardingRouteType.CallWaiting,
  [OnboardingProcessStepTypes.Feedback]: OnboardingRouteType.Feedback,
  [OnboardingProcessStepTypes.Referrals]: OnboardingRouteType.Referrals,
};

export const ONBOARDING_STEPS_OPTIONS = [
  {
    value: 'WelcomeOnboardingProcessStep',
    label: 'Bienvenida',
  },
  {
    value: 'ScheduleOnboardingProcessStep',
    label: 'Agendar llamada',
  },
  {
    value: 'MeantimeOnboardingProcessStep',
    label: 'En espera de llamada',
  },
  {
    value: 'FeedbackOnboardingProcessStep',
    label: 'Feedback',
  },
  {
    value: 'ReferralsOnboardingProcessStep',
    label: 'Referidos',
  },
  {
    value: 'IncomeOnboardingProcessStep',
    label: 'Ingresos',
  },
  {
    value: 'ExpenseOnboardingProcessStep',
    label: 'Gastos',
  },
  {
    value: 'CredentialsOnboardingProcessStep',
    label: 'Credenciales',
  },
];

export const ONBOARDING_STATUS = [
  {
    value: 1,
    label: 'Completado',
  },
  {
    value: 0,
    label: 'No completado',
  },
];
export const USER_SUBSCRIPTION_STATUS = [
  {
    value: 'active',
    label: 'Activo',
  },
  {
    value: 'inactive',
    label: 'Inactivo',
  },
  {
    value: 'incomplete',
    label: 'Con contización',
  },
];
