import React, { useEffect } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useFlexLayout,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import classnames from 'classnames';
import { Filter2Icon } from '@konta/icons';
import {
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Row,
  Collapse,
  Input,
  CustomInput,
} from 'reactstrap';

import { TablePagination, Colxx } from '@components';

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const [collapseOpen, setCollapseOpen] = React.useState();
  const onChange = useAsyncDebounce((item) => {
    setGlobalFilter(item || undefined);
  }, 200);

  const handleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  return (
    <>
      <PopoverBody>
        <div className="filter-select">
          <CustomInput
            id="globalFilter"
            type="checkbox"
            name="find-filter"
            label="Buscar en toda la tabla"
            onChange={handleCollapse}
            checked={value}
            inline
          />
        </div>
      </PopoverBody>
      <Collapse isOpen={collapseOpen} className="filter-select-collapse">
        <Input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} facturas...`}
        />
      </Collapse>
    </>
  );
}

function InvoiceWithoutComplementFilterOption({
  setComplementAmountFilter,
  state,
}) {
  const entryPaymentFilter = React.useMemo(
    () =>
      state.filters.find(
        (filter) => filter.id === 'declaration_entry_payments',
      ),
    [state],
  );
  const [checkedInput, setCheckedInput] = React.useState(
    entryPaymentFilter?.value,
  );
  const handleInvoiceFilterOption = (event) => {
    setComplementAmountFilter(event.target.checked);
    setCheckedInput(!checkedInput);
  };

  return (
    <PopoverBody>
      <div className="filter-select">
        <CustomInput
          id="invoiceWithoutComplement"
          type="checkbox"
          checked={checkedInput}
          name="declaration_entry_payments"
          label="Facturas sin complementos"
          onChange={handleInvoiceFilterOption}
          inline
        />
      </div>
    </PopoverBody>
  );
}

function InvoiceTypeFilterOption({
  invoiceTypeFilters,
  setInvoiceTypeFilters,
  state,
  preFilteredRows,
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const setOptions = new Set();
    preFilteredRows.forEach((row) => {
      setOptions.add(row.values[state.filters[0]?.id]);
    });
    return [...setOptions.values()];
  }, [state, preFilteredRows]);

  // Render a multi-select box
  const [collapseInvoiceTypeOpen, setCollapseInvoiceTypeOpen] =
    React.useState();
  const handleStatusCollapse = () => {
    setCollapseInvoiceTypeOpen(!collapseInvoiceTypeOpen);
  };

  const handleInvoiceTypeFilter = (event) => {
    if (event.target.checked) {
      setInvoiceTypeFilters([...invoiceTypeFilters, event.target.value]);
    } else if (!event.target.checked) {
      setInvoiceTypeFilters(
        invoiceTypeFilters.filter((item) => item !== event.target.value),
      );
    }
  };

  return (
    <>
      <PopoverBody>
        <div className="filter-select">
          <CustomInput
            id="invoiceType"
            type="checkbox"
            name="find-filter"
            label="Tipo de factura"
            onChange={handleStatusCollapse}
            inline
          />
        </div>
      </PopoverBody>
      <Collapse
        isOpen={collapseInvoiceTypeOpen}
        className="filter-select-collapse"
      >
        {options.map((option, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index}>
              <CustomInput
                id={index}
                type="checkbox"
                name="ppd"
                label="PPD"
                value={option}
                onChange={handleInvoiceTypeFilter}
                inline
              />
            </span>
          );
        })}
      </Collapse>
    </>
  );
}

export default function RetentionPpdInvoicesTable({ loading, columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    state: { pageIndex, pageSize },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    setFilter,
    preFilteredRows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
  );

  const [invoiceTypeFilters, setInvoiceTypeFilters] = React.useState([]);

  const [complementAmountFilter, setComplementAmountFilter] = React.useState(
    [],
  );

  const [popoverFiltersOpen, setPopoverFilterOpen] = React.useState(false);

  useEffect(() => {
    invoiceTypeFilters.map((filterValue) =>
      setFilter('payment_method', filterValue),
    );
  }, [invoiceTypeFilters, setFilter]);

  useEffect(() => {
    setFilter('declaration_entry_payments', complementAmountFilter);
  }, [complementAmountFilter, setFilter]);

  const noDataText = 'No hay facturas';

  return (
    <>
      <table {...getTableProps()} className="r-table table compact-table">
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <Row>
                <Colxx md={12} className="d-flex justify-content-end">
                  <Button
                    id="popover-filters"
                    outline
                    size="sm"
                    className="new-btn mr-3"
                    color="primary"
                    onClick={() => setPopoverFilterOpen(true)}
                  >
                    <Filter2Icon className="button-icon" />
                    Filtrar
                  </Button>
                  <UncontrolledPopover
                    placement="left"
                    target="popover-filters"
                    trigger="legacy"
                    popperClassName="table-filters"
                    hideArrow
                    flip
                    toggle={() => setPopoverFilterOpen(!popoverFiltersOpen)}
                    isOpen={popoverFiltersOpen}
                  >
                    <PopoverHeader className="d-flex justify-content-between align-items-center">
                      <span> Filtros </span>
                      <Button
                        size="sm"
                        className="new-btn"
                        color="primary"
                        onClick={() => setPopoverFilterOpen(false)}
                      >
                        Listo
                      </Button>
                    </PopoverHeader>
                    <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={state.globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                    <InvoiceWithoutComplementFilterOption
                      complementAmountFilter={complementAmountFilter}
                      setComplementAmountFilter={setComplementAmountFilter}
                      state={state}
                      preFilteredRows={preFilteredRows}
                    />
                  </UncontrolledPopover>
                </Colxx>
              </Row>
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => {
                return (
                  <th
                    // eslint-disable-next-line react/no-array-index-key
                    key={`th_${columnIndex}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames({
                      'sorted-desc': column.isSorted && column.isSortedDesc,
                      'sorted-asc': column.isSorted && !column.isSortedDesc,
                    })}
                  >
                    {column.render('Header')}
                    <span />
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.length > 0 && !loading ? (
            page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={row.isSelected ? 'selected' : ''}
                >
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      key={`td_${cellIndex}`}
                      {...cell.getCellProps({
                        className: cell.column.cellClass,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <>
              {[...Array(pageSize)].map((row, rowIndex) => {
                return (
                  <tr role="row" key={`row-${rowIndex}`}>
                    {[...Array(columns.length)].map((columnRow, cellIndex) => {
                      return (
                        <td role="cell" key={`cell-${cellIndex}`}>
                          <div className="list-item-heading"> </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
      {page.length === 0 && <div className="rt-noData">{noDataText}</div>}
      <div className={`rt-loading ${loading ? 'rt-active' : ''}`}>
        <div className="rt-loading-inner">Cargando...</div>
      </div>

      <TablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[10, 20, 40, 150]}
        showPageSizeOptions
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />
    </>
  );
}
