export const phoneRegExp = /^(\+?)(\d{1,2})(\d{3})(\d{3})(\d{4})$/;

export default function formatPhoneNumber(raw: string) {
  if (!phoneRegExp.test(raw)) {
    return raw;
  }
  return raw.replace(
    phoneRegExp,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (_, _sign, a, b, c, d) => `+(${a}) (${b}) ${c}-${d}`,
  );
}
