import { useCallback } from 'react';
import getRegimeNameFromFiscalRegime from 'shared/util/getRegimeNameFromFiscalRegime';
import toCurrency from '@util/toCurrency';
import type { Declaration } from 'types/entities';

export default function useDeclarationTotalWithRegimenByType(
  declarations: Declaration[],
) {
  const getDeclarationTotalByRegimen = useCallback(
    (type: 'declaration_income' | 'declaration_expense') =>
      declarations.map((declaration) => {
        const regimeName = getRegimeNameFromFiscalRegime(
          declaration.fiscal_regime,
        );
        return {
          regimeName,
          total: toCurrency(+declaration[type].total),
        };
      }),
    [declarations],
  );

  const declarationsIncomeTotalByRegimen =
    getDeclarationTotalByRegimen('declaration_income');

  const declarationsExpenseTotalByRegimen = getDeclarationTotalByRegimen(
    'declaration_expense',
  );

  return {
    declarationsIncomeTotalByRegimen,
    declarationsExpenseTotalByRegimen,
    getDeclarationTotalByRegimen,
  };
}
