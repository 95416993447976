import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import * as chat from '@util/chat';
import { SimpleStateButton, Colxx } from '@components';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import IncomeSummaryTable from '@components/IncomeSummaryTable';

function TotalConstancyExceeded({ workflow, wloading }) {
  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Clasificar ingresos"
          description="Si se encuentra en esta sección es posible que sus ingresos extra no cuadren con los de su constancia de retención por lo que deberá clasificarlos "
        />
      }
      actions={<PrevPhaseButton />}
    >
      <div className="d-flex align-items-center">
        <div>
          <div className="w-80 w-sm-100">
            <h1>
              Sus ingresos son mayores a los de su Constancia de Retención, por
              lo que debe validar y clasificar sus ingresos junto con su
              contador para que todo esté en orden.
            </h1>
          </div>
          <Row>
            <Colxx xxs="12" md="3">
              <SimpleStateButton
                loading={
                  wloading ||
                  workflow.current_phase_key !== 'total_constancy_exceeded'
                }
                color="primary"
                size="sm"
                className="mb-2 w-100"
                onClick={chat.open}
              >
                Hablar con un Agente
              </SimpleStateButton>
            </Colxx>
          </Row>
          <Row>
            <Col md="12" lg="7">
              <IncomeSummaryTable />
            </Col>
          </Row>
        </div>
      </div>
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ workflows }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { workflow, wloading };
};

export default connect(mapStateToProps)(TotalConstancyExceeded);
