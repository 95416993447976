const WATERMARK = 'retool';

export const getAccessToken = () => {
  const accessToken = localStorage.getItem('user_id');

  if (accessToken.endsWith(WATERMARK)) {
    localStorage.setItem('watermark', WATERMARK);
    localStorage.setItem(
      'user_id',
      accessToken.slice(0, accessToken.length - WATERMARK.length),
    );
  }

  return localStorage.getItem('user_id');
};

/* export const handleAuthentication = () => {
  const access_token = getAccessToken();

  if (!access_token) {
    this.emit('onNoAccessToken');

    return;
  }

  if (this.isAuthTokenValid(access_token)) {
    this.setSession(access_token);
    this.emit('onAutoLogin', true);
  } else {
    this.setSession(null);
    this.emit('onAutoLogout', 'access_token expired');
  }
}; */

export const setSession = (access_token) => {
  if (access_token) {
    localStorage.setItem('user_id', access_token);
    // axios.defaults.headers.common.Authorization = access_token;
  } else {
    localStorage.removeItem('user_id');
    // delete axios.defaults.headers.common.Authorization;
  }
};
