import {
  LOGIN_USER,
  LOGIN_WITH_GOOGLE_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  LOGOUT_USER,
  USER_FETCH,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  STATE_RESET,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  ONBOARDING_USER,
  ONBOARDING_USER_FAILURE,
  ONBOARDING_USER_SUCCESS,
  USER_UPDATE_OTP_REQUIRED,
  USER_UPDATE_OTP_TOKEN,
} from '@constants/actionTypes';

export const loginUser = (
  user,
  navigate,
  captchaToken,
  featureClient,
  SSO,
) => ({
  type: LOGIN_USER,
  payload: { user, navigate, captchaToken, featureClient, SSO },
});
export const loginWithGoogleUser = (
  user,
  navigate,
  featureClient,
  response,
) => ({
  type: LOGIN_WITH_GOOGLE_USER,
  payload: { user, navigate, featureClient, response },
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserFailure = () => ({
  type: LOGIN_USER_FAILURE,
});

export const registerUser = (
  user,
  navigate,
  captchaToken,
  featureClient,
  callback,
) => ({
  type: REGISTER_USER,
  payload: { user, navigate, captchaToken, featureClient, callback },
});
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});
export const registerUserFailure = () => ({
  type: REGISTER_USER_FAILURE,
});

export const logoutUser = (navigate) => ({
  type: LOGOUT_USER,
  payload: { navigate },
});
export const stateReset = () => ({
  type: STATE_RESET,
});

export const fetchUser = (navigate, featureClient) => ({
  type: USER_FETCH,
  payload: { navigate, featureClient },
});
export const fetchUserSuccess = (user) => ({
  type: USER_FETCH_SUCCESS,
  payload: user,
});
export const fetchUserFailure = () => ({
  type: USER_FETCH_FAILURE,
});

export const updateUser = (user, options) => ({
  type: USER_UPDATE,
  payload: { user, options },
});
export const updateUserSuccess = (user) => ({
  type: USER_UPDATE_SUCCESS,
  payload: user,
});
export const updateUserFailure = () => ({
  type: USER_UPDATE_FAILURE,
});
export const updateUserOtpRequiredForLogin = (otpRequiredForLogin) => ({
  type: USER_UPDATE_OTP_REQUIRED,
  payload: otpRequiredForLogin,
});

export const updateUserOtpToken = (response) => ({
  type: USER_UPDATE_OTP_TOKEN,
  payload: response,
});

//
export const changePassword = (user, navigate) => ({
  type: CHANGE_PASSWORD,
  payload: { user, navigate },
});
export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});
export const changePasswordFailure = () => ({
  type: CHANGE_PASSWORD_FAILURE,
});

export const onboardingUser = (user, options, featureClient) => ({
  type: ONBOARDING_USER,
  payload: { user, options, featureClient },
});
export const onboardingUserSuccess = (user) => ({
  type: ONBOARDING_USER_SUCCESS,
  payload: user,
});
export const onboardingUserFailure = () => ({
  type: ONBOARDING_USER_FAILURE,
});
