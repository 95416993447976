import { useTaxableEntity } from '@hooks';
import useFeature from '@hooks/useFeature';
import { NEW_PENDINGS_VIEW } from '@constants/featureFlags';
import { TaxableEntity } from 'types/entities';
import NewHome from '../NewHome';
import Home from '../home';
import Tasks from '../tasks';

export default function PendingRoutes() {
  const [newPendingsView] = useFeature(NEW_PENDINGS_VIEW);
  const { taxable_entity: taxableEntity } = useTaxableEntity() as {
    taxable_entity: TaxableEntity;
  };
  const isRejected = taxableEntity?.onboarding_detail?.is_rejected_user;

  if (newPendingsView && !isRejected) {
    return <NewHome />;
  }
  if (!isRejected) {
    return <Home />;
  }
  return <Tasks />;
}
