import React from 'react';
import { Flex, Text, Divider } from '@konta/ui';
import {
  ProcessType,
  UploadDataAnnualProcessStepAnswers,
} from 'types/entities';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepDescription from '@components/ProcessStepDescription';
import ProcessStepLayout from '@components/ProcessStepLayout';
import useProcess from '@hooks/useProcess';
import _ from 'lodash';

const process = ProcessType.AnnualDeclarationProcess;

const statusDict: Record<string, string> = {
  difference_in_income: 'Diferencia en ingresos',
  difference_in_expenses: 'Diferencia en gastos',
  difference_in_withholdings: 'Diferencia en retenciones',
  difference_in_provisional_payments: 'Diferencia en pagos provisionales',
  credentials_error: 'Error en credenciales',
  missing_information: 'Faltan datos informativos',
  already_submitted_declaration: 'Declaración ya presentada',
  pending_declarations: 'Declaraciones pendientes',
  not_obligated: 'No está obligado',
};

export default function UploadAnnual() {
  const { activeStep } = useProcess(process, {
    refetchInterval: 10000,
  });
  const answers = (
    _.isPlainObject(activeStep?.answers) ? activeStep?.answers || {} : {}
  ) as UploadDataAnnualProcessStepAnswers;

  const { is_data_uploaded, lock_status } = answers;

  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Apertura de cuenta"
      titleChip="En curso"
      content={
        <Flex direction="column">
          <Flex direction="column" gap={12} css={{ maxWidth: '100%' }}>
            {!is_data_uploaded && !!lock_status ? (
              <>
                <ProcessStepTitle>
                  Estamos en proceso de verificación
                </ProcessStepTitle>
                <ProcessStepDescription>
                  Nos encontramos verificando su información para brindarle las
                  mejores soluciones posibles.
                </ProcessStepDescription>
                <ProcessStepDescription>
                  Hemos encontrado{' '}
                  <Text color="primary700">
                    &quot;{statusDict[lock_status]}&quot;
                  </Text>{' '}
                  en relación a su declaración, nuestro equipo contable está
                  trabajando activamente y tan pronto tengamos actualizaciones o
                  resoluciones listas, nos comunicaremos con usted de inmediato.
                </ProcessStepDescription>
                <ProcessStepDescription>
                  Mientras tanto, si tiene alguna pregunta o información
                  adicional para compartir, no dude en{' '}
                  <Text
                    as="a"
                    color="primary700"
                    href="https://wa.me/525570055271"
                    target="_blank"
                  >
                    contactarnos.
                  </Text>
                </ProcessStepDescription>{' '}
              </>
            ) : (
              <>
                <ProcessStepTitle>
                  Excelente, vamos a trabajar en tu declaración anual
                </ProcessStepTitle>
                <ProcessStepDescription>
                  Estamos procesando tu información y nuestro equipo fiscal está
                  trabajando para hacer el cálculo de tu declaración anual.
                </ProcessStepDescription>
                <ProcessStepDescription>
                  Si tienes alguna pregunta o inquietud, no dudes en{' '}
                  <Text
                    as="a"
                    color="primary700"
                    href="https://wa.me/525570055271"
                    target="_blank"
                  >
                    contactarnos.
                  </Text>
                </ProcessStepDescription>
              </>
            )}
          </Flex>
          <Flex direction="column">
            <Flex direction="column" gap={8}>
              <Flex
                direction="column"
                css={{ maxWidth: '42px', padding: '7px 0px 0px' }}
              >
                <Divider />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      }
    />
  );
}
