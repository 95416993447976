import React, { Component } from 'react';
import { Row, Card, CardTitle } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { Colxx } from '@components/CustomBootstrap';

class Maintenance extends Component {
  render() {
    return (
      <main className="main-center-container px-3 px-md-1">
        <div className="fixed-background" />
        <Row className="h-100">
          <Colxx xxs="12" md="6" className="mx-auto my-auto">
            <Card className="auth-card">
              <div className="form-side">
                <div className="d-flex justify-content-center align-items-center mb-5">
                  <NavLink to="/" className="white">
                    <span className="logo-single" />
                  </NavLink>
                </div>
                <div className="text-center">
                  <CardTitle tag="h4" className="mb-4">
                    ¡En breve estaremos de vuelta!
                  </CardTitle>
                  <h1 className="font-weight-bold mb-5">
                    La página se encuentra bajo mantenimiento
                  </h1>
                </div>
              </div>
            </Card>
          </Colxx>
        </Row>
      </main>
    );
  }
}
export default Maintenance;
