import { useRef, useEffect, useState, ComponentProps } from 'react';
import { Tooltip, Text, styled } from '@konta/ui';

const css = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};
const Root = styled('span', {
  ...css,
  lineHeight: 0,
});

const textCss = css;

type OverflowTipProps = ComponentProps<typeof Text> & {
  maxLen?: number;
};
export default function TextOverflow({
  children,
  maxLen,
  ...props
}: OverflowTipProps) {
  const textElementRef = useRef<HTMLSpanElement>(null);
  const [hoverStatus, setHover] = useState<boolean>(false);

  const compareSize = () => {
    if (textElementRef.current) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  if (!hoverStatus) {
    return (
      <Root
        ref={textElementRef}
        css={{ ...(maxLen && { maxWidth: `${maxLen}px` }) }}
      >
        <Text {...props}>{children}</Text>
      </Root>
    );
  }

  return (
    <Tooltip
      side="top"
      size="s"
      delayDuration={0}
      triggerElement={
        <Root
          ref={textElementRef}
          css={{ ...(maxLen && { maxWidth: `${maxLen}px` }) }}
        >
          <Text
            css={{
              ...props.css,
              ...textCss,
            }}
            {...props}
          >
            {children}
          </Text>
        </Root>
      }
    >
      {children}
    </Tooltip>
  );
}
