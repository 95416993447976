import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { menuHiddenBreakpoint } from '@constants/defaultValues';
import userSearch from '@components/Utils/userSearch';

function OldTopNavHelpCenterSearch({ className, placeholder = 'Buscar' }) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [inputWidth, setInputWidth] = useState(false);

  const search = () => {
    userSearch({ search: searchKeyword });
    setSearchKeyword('');
  };

  const handleSearchIconClick = (e) => {
    if (window.innerWidth < menuHiddenBreakpoint) {
      let elem = e.target;
      if (!e.target.classList.contains('search')) {
        if (e.target.parentElement.classList.contains('search')) {
          elem = e.target.parentElement;
        } else if (
          e.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = e.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        // elem.classList.remove('mobile-view');
        // removeEventsSearch();
        search();
      } else {
        elem.classList.add('mobile-view');
        addEventsSearch();
      }
    } else {
      search();
    }
    e.stopPropagation();
  };

  const handleDocumentClickSearch = (e) => {
    let isSearchClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains('navbar') ||
        e.target.classList.contains('simple-icon-magnifier'))
    ) {
      isSearchClick = true;
      if (e.target.classList.contains('simple-icon-magnifier')) {
        search();
      }
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      e.target.parentElement.classList.contains('search')
    ) {
      isSearchClick = true;
    }

    if (!isSearchClick) {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) input.classList.remove('mobile-view');
      removeEventsSearch();
      setSearchKeyword('');
    }
  };

  const removeEventsSearch = () => {
    document.removeEventListener('click', handleDocumentClickSearch, true);
  };

  const addEventsSearch = () => {
    document.addEventListener('click', handleDocumentClickSearch, true);
  };

  const handleSearchInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      search();
    }
  };

  const handleInputClick = (e) => {
    const input = document.querySelector('.mobile-view');
    if (!input) {
      setInputWidth(true);
    }
  };

  const handleOnblurChange = () => {
    setInputWidth(false);
  };
  return (
    <div className={className || 'd-flex justify-content-center mt-4 mb-4'}>
      <div
        className={`${inputWidth ? 'search-expanded' : 'search'}`}
        onBlur={handleOnblurChange}
        onClick={handleInputClick}
      >
        <Input
          name="searchKeyword"
          id="searchKeyword"
          placeholder={
            !inputWidth ? placeholder : 'Buscar en el Centro de Ayuda'
          }
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          onKeyPress={(e) => handleSearchInputKeyPress(e)}
        />
        <span className="search-icon" onClick={(e) => handleSearchIconClick(e)}>
          <i className="simple-icon-magnifier" />
        </span>
      </div>
    </div>
  );
}
export default OldTopNavHelpCenterSearch;
