import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Text,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Flex,
  DataTable,
} from '@konta/ui';
import { ConfirmModal } from '@components/Modal';
import { Edit2OutlineIcon, TrashCanOutlineIcon } from '@konta/icons';
import { deleteProduct } from '@redux/actions';
import toCurrency from '@util/toCurrency';
import ProductModalForm from '@components/ProductModalForm';
import { useToggle } from 'rooks';

export default function ClientProducts() {
  const dispatch = useDispatch();
  const taxableEntity = useSelector(
    (state) => state.taxableEntity.taxable_entity,
  );
  const [selectedProduct, setSelectedProduct] = useState();
  const [deleteItem, setDeleteItem] = useState();
  const [openDeleteModal, toggleOpenDeleteModal] = useToggle();
  const { loading, products } = useSelector((state) => state.products);

  const callDeleteProduct = useCallback(
    (productId) => {
      dispatch(deleteProduct(taxableEntity, productId));
      toggleOpenDeleteModal();
    },
    [dispatch, taxableEntity, toggleOpenDeleteModal],
  );

  const handleSubmit = () => {
    setSelectedProduct(null);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Descripción',
        label: 'Descripción',
        accessorKey: 'description',
        width: 350,
        cell: ({ renderValue }) => {
          return <p className="list-item-heading">{renderValue()}</p>;
        },
      },
      {
        header: 'Nombre interno',
        label: 'Nombre interno',
        accessorKey: 'name',
        width: 350,
        cell: ({ renderValue }) => {
          return <p className="list-item-heading">{renderValue()}</p>;
        },
      },
      {
        header: 'Código del SAT',
        label: 'Código del SAT',
        accessorKey: 'sat_product_id',
        width: 350,
        cell: ({ renderValue }) => {
          return <p className="list-item-heading">{renderValue()}</p>;
        },
      },
      {
        header: 'Precio Unitario',
        label: 'Precio Unitario',
        accessorKey: 'price',
        cell: ({ row, renderValue }) => (
          <p className="list-item-heading text-capitalize">
            {row.original && toCurrency(renderValue())}
          </p>
        ),
      },
      {
        header: 'IVA',
        label: 'IVA',
        accessorKey: 'iva',
        cell: ({ renderValue }) => (
          <p className="list-item-heading">{`${renderValue() * 100}%`}</p>
        ),
      },
      {
        header: 'IVA Retenido',
        label: 'IVA Retenido',
        accessorKey: 'retained_iva',
        cell: ({ renderValue }) => (
          <p className="list-item-heading">{`${renderValue() * 100}%`}</p>
        ),
      },
      {
        header: 'Acciones',
        label: 'Acciones',
        accessorKey: 'actions',
        cell: ({ row: { original } }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  size="2xs"
                  color="gray"
                  variant="minimal"
                  squaredPadding
                >
                  <div
                    style={{
                      fontSize: '25px',
                      paddingBottom: '50%',
                    }}
                  >
                    &#x2026;
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => setSelectedProduct(original)}>
                  <Flex align="center">
                    <Edit2OutlineIcon />
                    <Text css={{ mr: '$16', ml: '$12' }}>Editar</Text>
                  </Flex>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    setDeleteItem(original.id);
                    toggleOpenDeleteModal();
                  }}
                >
                  <Flex align="center">
                    <TrashCanOutlineIcon />
                    <Text css={{ mr: '$16', ml: '$12' }}>Eliminar</Text>
                  </Flex>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    [toggleOpenDeleteModal],
  );

  return (
    <>
      <DataTable
        data={products}
        columns={columns}
        loading={loading}
        noDataText="No hay facturas emitidas"
        dataTableId="clientProducts"
      />
      <ProductModalForm
        isOpen={!!selectedProduct}
        product={selectedProduct}
        onClose={() => setSelectedProduct(null)}
        onSubmit={handleSubmit}
      />
      <ConfirmModal
        title="Eliminar Producto"
        open={openDeleteModal}
        onAccept={() => callDeleteProduct(deleteItem)}
        onCancel={toggleOpenDeleteModal}
        onClose={toggleOpenDeleteModal}
      >
        ¡Estas a punto de borrar un producto de tu catálogo!
      </ConfirmModal>
    </>
  );
}
