import { styled } from '@konta/ui';

export const TitleContainer = styled('div', {
  zIndex: 1,
  display: 'flex',
  padding: '12px 16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  border: '1px solid $primary400',
  background: '#FFF',
  color: '$primary700',
  textAlign: 'center',
  fontFamily: 'DM Sans',
  lineHeight: 'normal',
  'span[data-type="s"]': {
    fontSize: '14px',
    fontWeight: '500',
  },
  'span[data-type="l"]': {
    fontSize: '40px',
    fontWeight: '700',
  },
});

export const Subtitle = styled('div', {
  fontFamily: 'DM Sans',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '500',
  lineHeight: 'normal',
  color: '$primary700',
  mt: '-40px',
  'span[data-type="s"]': {
    fontSize: '14px',
  },
});

export const ImageContainer = styled('div', {
  position: 'relative',
  top: '-40px',
  '& svg': {
    width: '100%',
    height: 'auto',
  },
});
