import { Filters } from '@konta/ui';
import {
  declarationEntryExpensesFilterOptions,
  expensesAccountingStatusFilterOptions,
} from '@constants/declarationEntries';

// eslint-disable-next-line import/prefer-default-export
export const filtersList: Filters = [
  {
    id: 'fiscal_regime_id',
    label: 'Regimen',
    type: 'select',
    value: null,
    options: [],
  },
  {
    id: 'declaration_entry_type',
    label: 'Tipo de gasto',
    type: 'select',
    value: null,
    options: declarationEntryExpensesFilterOptions,
  },
  {
    id: 'accounting_status',
    label: 'Estatus',
    type: 'select',
    value: null,
    options: expensesAccountingStatusFilterOptions,
  },
];
