import { Flex } from '@konta/ui';
import { Feature } from '@components/Workflow/styled';
import PreviousPeriods from '@components/Workflow/PreviousPeriods';
import UploadForeignInvoice from './UploadForeignInvoice';
import UploadForeignProduct from './UploadForeignProduct';
import UploadXmlModal from './UploadXmlModal/UploadXmlModal';

interface ExpenseUploadWayProps {
  toggleOpenUploadExpense: () => void;
}
export default function ExpenseUploadWay({
  toggleOpenUploadExpense,
}: ExpenseUploadWayProps) {
  return (
    <Flex column gap={8}>
      <Feature>Selecciona el tipo de gastos que te gustaría agregar.</Feature>
      <Flex gap={10} direction="row" wrap="wrap">
        <UploadForeignInvoice
          toggleOpenUploadExpense={toggleOpenUploadExpense}
        />
        <UploadForeignProduct
          toggleOpenUploadExpense={toggleOpenUploadExpense}
        />
        <PreviousPeriods source="expenses" />
        <UploadXmlModal toggleOpenUploadExpense={toggleOpenUploadExpense} />
      </Flex>
    </Flex>
  );
}
