import { styled, keyframes } from '@konta/ui';

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

export const ImageContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  zIndex: 1,
  transition: 'filter 0.5s',
  div: {
    width: '100%',
    flex: 1,
  },
  variants: {
    withFilter: {
      true: {
        filter: 'blur(20px)',
      },
      false: {
        filter: 'none',
      },
    },
  },
});

export const FadeInImage = styled('img', {
  maxWidth: '100%',
  height: 'auto',
  objectFit: 'contain',
  opacity: 0,
  display: 'none',
});
