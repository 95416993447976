import { useEffect } from 'react';
import Cookies from 'js-cookie';

export default function useCookieToken() {
  useEffect(() => {
    const setLandingRegisterToken = () => {
      const landingRegisterToken = Cookies.get('landing_user_token', {
        domain: '.konta.com',
      });
      if (!landingRegisterToken) {
        return;
      }
      localStorage.setItem('user_id', landingRegisterToken);
      Cookies.remove('landing_user_token', {
        domain: '.konta.com',
      });
      window.location.href = window.location.origin;
    };
    setLandingRegisterToken();
  }, []);
}
