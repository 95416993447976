import type { Declaration } from 'types/entities';
import getRegimeNameFromFiscalRegime, {
  shortNames,
} from 'shared/util/getRegimeNameFromFiscalRegime';
import toCurrency from './toCurrency';
import getTotalsFromDeclarationExpense from '../shared/util/getTotalsFromDeclarationExpense';

export default function getTableRowsFromDeclarationExpenseTotals(
  declaration: Declaration,
) {
  const { tax16GrandTotal, tax0GrandTotal, taxExemptGrandTotal, grandTotal } =
    getTotalsFromDeclarationExpense(declaration.declaration_expense);
  const regimeName =
    shortNames[declaration.fiscal_regime.sat_key] ||
    getRegimeNameFromFiscalRegime(declaration.fiscal_regime);

  const totals = [
    {
      key: `IVA16Income`,
      label: `Gastos con IVA al 16% o al 8%`,
      amount: `${toCurrency(tax16GrandTotal)} MXN`,
    },
    {
      key: `IVA0Income`,
      label: `Gastos con IVA al 0%`,
      amount: `${toCurrency(tax0GrandTotal)} MXN`,
    },
    {
      key: `IVAExemptIncome`,
      label: `Gastos con IVA Exento`,
      amount: `${toCurrency(taxExemptGrandTotal)} MXN`,
    },
    {
      key: 'grandTotal',
      label: `Total de gastos de ${regimeName}`,
      amount: `${toCurrency(grandTotal)} MXN`,
      isBold: true,
    },
  ];

  return {
    regimeName,
    total: grandTotal,
    totals,
  };
}
