import { call, put } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { addPpdComplementSuccess } from '@redux/cfdi/actions';
import getErrorMessage from '@util/getErrorMessage';
import {
  declarationEntriesCreateSuccess,
  declarationEntriesCreateFailure,
} from '../actions';

export default function* ({
  payload: {
    body,
    callback = null,
    successMessage = 'Gasto agregado con éxito',
    successTitle = 'Gasto agregado',
  },
}) {
  try {
    const iva = body?.get ? body.get('[iva]') : null;
    const response = yield call(
      api.post,
      `${iva === '0' ? 'foreign_invoices' : 'declaration_entries'}`,
      body,
    );

    if (response.data.source_type === 'PaymentDetail') {
      yield put(addPpdComplementSuccess(response.data));
    }

    if (callback) {
      callback();
    }

    yield call(StatusHandler, {
      response,
      title: successTitle,
      message: successMessage,
      status: response.status,
      success: declarationEntriesCreateSuccess,
      failure: declarationEntriesCreateFailure,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    const hasUnknownError =
      errorMessage === 'Error desconocido' || !errorMessage;

    // catch throw
    yield call(ErrorHandler, {
      error,
      action: declarationEntriesCreateFailure,
      title: 'Error al agregar el ingreso o gasto',
      message: hasUnknownError
        ? 'Algo salió mal al agregar el gasto'
        : errorMessage,
      showBodyErrors: true,
    });
  }
}
