import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getTaxDelays } from 'shared/api/tax_delays';
import transformWorkflowDataForHeatmap from 'shared/util/transformWorkflowDataForHeatmap';
import { TAX_DELAYS } from 'shared/constants/reactQueries';
import type { UseQueryOptions } from 'react-query';
import type { WorkflowHeatmapData } from 'types/entities';
import type { AxiosRequestConfig } from 'axios';

export default function useTaxDelays(
  axiosConfig?: AxiosRequestConfig<WorkflowHeatmapData>,
  queryOptions?: UseQueryOptions<WorkflowHeatmapData>,
) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<WorkflowHeatmapData>(
    [TAX_DELAYS, axiosConfig],
    async () => {
      const taxDelays = await getTaxDelays(axiosConfig);
      return taxDelays;
    },
    queryOptions,
  );

  const heatmapData = useMemo(
    () => transformWorkflowDataForHeatmap(data),
    [data],
  );

  return {
    taxDelaysLoading: isLoading || isFetching,
    taxDelays: data,
    heatmapData,
    ...rest,
  };
}
