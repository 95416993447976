import {
  FETCH_FISCAL_PERIOD,
  FETCH_FISCAL_PERIOD_SUCCESS,
  FETCH_FISCAL_PERIOD_FAILURE,
  FETCH_CURRENT_FISCAL_PERIOD,
  FETCH_CURRENT_FISCAL_PERIOD_SUCCESS,
  FETCH_CURRENT_FISCAL_PERIOD_FAILURE,
  PAY_FISCAL_PERIOD,
  PAY_FISCAL_PERIOD_SUCCESS,
  PAY_FISCAL_PERIOD_FAILURE,
  UPLOAD_FISCAL_PERIOD_STATEMENT,
  UPLOAD_FISCAL_PERIOD_STATEMENT_SUCCESS,
  UPLOAD_FISCAL_PERIOD_STATEMENT_FAILURE,
  UPDATE_STATUS_FISCAL_PERIOD,
  UPDATE_STATUS_FISCAL_PERIOD_SUCCESS,
  UPDATE_STATUS_FISCAL_PERIOD_FAILURE,
  SELECT_FISCAL_PERIOD,
  UPLOAD_FISCAL_PERIOD_RECEIPT,
  UPLOAD_FISCAL_PERIOD_RECEIPT_SUCCESS,
  UPLOAD_FISCAL_PERIOD_RECEIPT_FAILURE,
  POST_FEEDBACK,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  fiscal_period: [],
  current: null,
  loading: false,
  error: null,
  ui: {
    selected_period: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_FISCAL_PERIOD:
    case POST_FEEDBACK:
      return { ...state, loading: true };
    case FETCH_FISCAL_PERIOD_SUCCESS:
      return { ...state, loading: false, fiscal_period: action.payload };
    case FETCH_FISCAL_PERIOD_FAILURE:
      return { ...state, loading: false, fiscal_period: [] };
    case FETCH_CURRENT_FISCAL_PERIOD:
      return { ...state, loading: true };
    case FETCH_CURRENT_FISCAL_PERIOD_SUCCESS:
      return { ...state, loading: false, current: action.payload };
    case FETCH_CURRENT_FISCAL_PERIOD_FAILURE:
      return { ...state, loading: false, current: null };
    case PAY_FISCAL_PERIOD:
      return { ...state, loading: true };
    case PAY_FISCAL_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        fiscal_period: state.fiscal_period.map((el) =>
          el.id === action.payload.id ? action.payload : el,
        ),
      };
    case PAY_FISCAL_PERIOD_FAILURE:
      return { ...state, loading: false };
    case UPLOAD_FISCAL_PERIOD_STATEMENT:
      return { ...state, loading: true };
    case UPLOAD_FISCAL_PERIOD_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.payload,
        fiscal_period: state.fiscal_period.map((period) =>
          period.id === action.payload.id ? action.payload : period,
        ),
      };
    case UPLOAD_FISCAL_PERIOD_STATEMENT_FAILURE:
      return { ...state, loading: false };
    case UPDATE_STATUS_FISCAL_PERIOD:
      return { ...state, loading: true };
    case UPDATE_STATUS_FISCAL_PERIOD_SUCCESS:
      return { ...state, loading: false, current: action.payload };
    case UPDATE_STATUS_FISCAL_PERIOD_FAILURE:
      return { ...state, loading: false };
    case SELECT_FISCAL_PERIOD:
      return {
        ...state,
        ui: {
          ...state.ui,
          selected_period: action.payload,
        },
      };
    case UPLOAD_FISCAL_PERIOD_RECEIPT:
      return { ...state, loading: true };
    case UPLOAD_FISCAL_PERIOD_RECEIPT_SUCCESS:
      return {
        ...state,
        loading: false,
        fiscal_period: state.fiscal_period.map((el) =>
          el.id === action.payload.id ? action.payload : el,
        ),
      };
    case UPLOAD_FISCAL_PERIOD_RECEIPT_FAILURE:
      return { ...state, loading: false };
    case POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        current: { ...state.current, feedback_posted: true },
      };
    case POST_FEEDBACK_FAILURE:
      return { ...state, loading: false };

    default:
      return { ...state };
  }
};
