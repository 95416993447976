import React, { createContext, useMemo } from 'react';
import { useToggle } from 'rooks';
import Calendly from '@components/Calendly';

export const ScheduleCallContext = createContext();

export default function ScheduleCallProvider({ children }) {
  const [isCalendlyOpen, toggleCalendly] = useToggle();
  const value = useMemo(
    () => ({
      isCalendlyOpen,
      toggleCalendly,
    }),
    [isCalendlyOpen, toggleCalendly],
  );
  return (
    <ScheduleCallContext.Provider value={value}>
      <Calendly isOpen={isCalendlyOpen} toggle={toggleCalendly} />
      {children}
    </ScheduleCallContext.Provider>
  );
}
