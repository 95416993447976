import { useCallback } from 'react';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { putDeclaration } from 'backoffice/api/declarations';
import type { AxiosRequestConfig } from 'axios';
import type { DeclarationUpdatePayload, Declaration } from 'types/entities';
import { WORKFLOWS } from '../../backoffice/constants/reactQueries';

export default function useUpdateDeclaration(
  axiosConfig?: AxiosRequestConfig<Declaration>,
  queryOptions?: UseMutationOptions<
    Declaration,
    unknown,
    DeclarationUpdatePayload
  >,
) {
  const queryClient = useQueryClient();
  const updateDeclarationRequest = useCallback(
    ({ declarationId, payload }: DeclarationUpdatePayload) =>
      putDeclaration({ declarationId, payload }, axiosConfig),
    [axiosConfig],
  );
  return useMutation(updateDeclarationRequest, {
    ...queryOptions,
    onSuccess: async () => {
      await queryClient.invalidateQueries([WORKFLOWS]);
    },
  });
}
