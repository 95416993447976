import { Flex } from '@konta/ui';
import { Feature } from '@components/Workflow/styled';
import PreviousPeriods from '@components/Workflow/PreviousPeriods';
import UploadXmlModal from './UploadXmlModal/UploadXmlModal';
import UploadInvoiceComplementModal from './UploadInvoiceComplementModal/UploadInvoiceComplementModal';
import UploadConstancyModal from './UploadConstancyModal/UploadConstancyModal';
import UploadManualIncomes from './UploadManualIncomesModal/UploadManualIncomesModal';

interface IncomeUploadWayProps {
  toggleOpenUploadIncomes: () => void;
}

export default function IncomeUploadWay({
  toggleOpenUploadIncomes,
}: IncomeUploadWayProps) {
  return (
    <Flex column gap={8}>
      <Feature>Selecciona el tipo de ingresos que te gustaría agregar.</Feature>
      <Flex gap={10} direction="row" wrap="wrap">
        <UploadXmlModal toggleOpenUploadIncomes={toggleOpenUploadIncomes} />
        <PreviousPeriods source="incomes" />
        <UploadInvoiceComplementModal />
        <UploadConstancyModal
          toggleOpenUploadIncomes={toggleOpenUploadIncomes}
        />
        <UploadManualIncomes
          toggleOpenUploadIncomes={toggleOpenUploadIncomes}
        />
      </Flex>
    </Flex>
  );
}
