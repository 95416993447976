import React from 'react';
import { GlobalError } from '@routes/error';
import * as Sentry from '@sentry/react';
import NewGlobalError from 'backoffice/pages/GlobalError.tsx';

class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { hasError, eventId } = this.state;
    const { children, newDesign } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      if (newDesign) {
        return (
          <NewGlobalError
            handleOnReportClick={() => Sentry.showReportDialog({ eventId })}
          />
        );
      }
      return (
        <GlobalError
          handleOnReportClick={() => Sentry.showReportDialog({ eventId })}
        />
      );
    }

    return children;
  }
}

export default GlobalErrorBoundary;
