import React, { useMemo, useState } from 'react';
import { Divider, Flex, config, useMediaQuery, Text } from '@konta/ui';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import {
  BUSINESS_OPTIONS,
  FLATTEN_BUSINESS_OPTIONS,
  INVESTMENTS_OPTIONS,
  OCCUPATIONS_OPTIONS,
} from '@constants/onboarding';
import FormikTextarea from '@components/FormikTextarea';
import FormikCheckbox from '@components/FormikCheckbox';
import FormikKontaSelect from '@components/FormikKontaSelect';
import FormikTextInput from '@components/FormikTextInput';
import ProcessStepLayout from '@components/ProcessStepLayout';
import isReactElement from '@util/isReactElement';
import useProcess from '@hooks/useProcess';
import { ProcessType } from 'types/entities.ts';
import NextStepButton from '@components/NextStepButton';
import ProcessStepDescription from '@components/ProcessStepDescription';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepHelpFeature from '@components/ProcessStepHelpFeature';
import useOnboardingHelpFeature from '../useOnboardingHelpFeature';
import OnboardingField from '../OnboardingField';

const HELP_FEATURES = {
  occupation: {
    title: '¿No estás seguro de tu actividad?',
    description:
      'No te preocupes, podrás platicar sobre las respuestas seleccionadas en una llamada con un asesor. Por ahora, elige la que creas que sea la mejor para seguir adelante.',
  },
  has_investments: {
    title: '¿Cómo saber si tienes inversiones?',
    description:
      'Este video te puede servir para saber si tienes inversiones y cómo identificarlas.',
  },
};
const process = ProcessType.OnboardingOnboardingProcess;

const validationSchema = yup.object().shape({
  occupation: yup
    .array()
    .test(
      'occupation-include',
      'Selecciona al menos alguna opción',
      (value) => value.length > 0,
    ),
  otherOccupation: yup.string().when('occupation', (occupations) => {
    const hasOtherOption = occupations?.some?.((opt) => opt?.label === 'Otros');
    if (hasOtherOption) return yup.string().required('Campo requerido');
    return yup.string();
  }),
  business_details: yup
    .array()
    .test(
      'business_details-include',
      'Selecciona al menos alguna opción',
      (value) => value.length > 0,
    ),
  otherBusinessDetail: yup
    .string()
    .when('business_details', (business_details) => {
      const hasOtherOption = business_details?.some?.(
        (opt) => opt?.label === 'Otros',
      );
      if (hasOtherOption) return yup.string().required('Campo requerido');
      return yup.string();
    }),
  business_description: yup.string().required('Campo requerido'),
  has_foreign_income: yup.boolean().required('Campo requerido'),
  has_investments: yup.boolean().required('Campo requerido'),
  investments: yup.array().when('has_investments', {
    is: true,
    then: yup
      .array()
      .test(
        'investments',
        'Selecciona al menos alguna opción',
        (value) => value.length > 0,
      ),
  }),
  otherInvestments: yup.string().when('investments', (investments) => {
    const hasOtherOption = investments?.some?.((opt) => opt?.label === 'Otros');
    if (hasOtherOption) return yup.string().required('Campo requerido');
    return yup.string();
  }),
});

function useInitialValues() {
  const { activeStep } = useProcess(process);

  const initialValues = useMemo(() => {
    let otherOccupation = '';
    const occupations = (activeStep?.answers?.occupation ?? []).map((item) => {
      const occupation = OCCUPATIONS_OPTIONS.find((opt) => opt.label === item);
      if (!occupation) {
        otherOccupation += item;
      }
      return occupation;
    });

    let otherBusinessDetail = '';
    const business_details = (activeStep?.answers.business_details ?? []).map(
      (item) => {
        const business = FLATTEN_BUSINESS_OPTIONS.find(
          (opt) => opt.label === item,
        );
        if (!business) {
          otherBusinessDetail += item;
        }
        return business;
      },
    );

    let otherInvestments = '';
    const investments = (activeStep?.answers?.investments ?? []).map((item) => {
      const investment = INVESTMENTS_OPTIONS.find((opt) => opt.label === item);
      if (!investment) {
        otherInvestments += item;
      }
      return investment;
    });

    return {
      occupation: occupations,
      otherOccupation,
      business_details,
      otherBusinessDetail,
      investments,
      otherInvestments,
      business_description: activeStep?.answers.business_description ?? '',
      has_foreign_income: activeStep?.answers.has_foreign_income ?? false,
      has_investments: activeStep?.answers.has_investments ?? false,
    };
  }, [
    activeStep?.answers.business_description,
    activeStep?.answers.business_details,
    activeStep?.answers.has_foreign_income,
    activeStep?.answers.has_investments,
    activeStep?.answers.investments,
    activeStep?.answers.occupation,
  ]);

  return initialValues;
}

export default function OnboardingIncomes() {
  const isTabletOrMobile = useMediaQuery(config.media['<md']);
  /**
   * @type { ReturnType<typeof useState<import('types/entities').IncomeProcessStepAnswers> }
   */
  const [payload, setPayload] = useState();
  const { currentHelpFeature, addHelpFeature, removeHelpFeature } =
    useOnboardingHelpFeature({ helpFeatures: HELP_FEATURES });

  const initialValues = useInitialValues();
  const formik = useFormik({
    validateOnMount: true,
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { otherOccupation } = values;
      if (otherOccupation) {
        values.occupation.push({ label: otherOccupation });
      }

      const { otherInvestments } = values;
      if (otherInvestments) {
        values.investments.push({ label: otherInvestments });
      }

      const { otherBusinessDetail } = values;
      if (otherBusinessDetail) {
        values.business_details.push({ label: otherBusinessDetail });
      }

      setPayload({
        has_foreign_income: values.has_foreign_income,
        has_investments: values.has_investments,
        business_description: values.business_description,
        business_details: values.business_details.map((item) => item?.label),
        investments: values.investments.map((item) => item?.label),
        occupation: values.occupation.map((item) => item?.label),
      });
    },
  });

  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Apertura de cuenta"
      titleChip="En curso"
      content={
        <FormikProvider value={formik}>
          <Flex direction="column" gap={12} css={{ maxWidth: '670px' }}>
            <ProcessStepTitle>
              Comencemos con algo básico. ¿A qué te dedicas y cómo generas
              ingresos?
            </ProcessStepTitle>
            <ProcessStepDescription>
              Escoge la opción de ingresos que más de adapte a ti. Si no estás
              completamente seguro/a, elige las opciones que más se aproximen a
              lo que buscas.
            </ProcessStepDescription>
          </Flex>
          <Flex direction="column" gap={12} css={{ maxWidth: '400px' }}>
            <OnboardingField
              addHelpFeature={addHelpFeature}
              removeHelpFeature={removeHelpFeature}
              fieldName="occupation"
            >
              <Flex direction="column" gap={12} css={{ flex: 1 }}>
                <FormikKontaSelect
                  id="occupation"
                  isMulti
                  name="occupation"
                  options={OCCUPATIONS_OPTIONS}
                  label="¿En qué tipo de actividad trabajas?"
                  className="onboarding-multi-select"
                  onChange={(val) => {
                    if (!val.some((opt) => opt.label === 'Otros')) {
                      formik.setFieldValue('otherOccupation', '');
                    }
                  }}
                  onFocus={(e) => addHelpFeature(e, 'occupation')}
                  onBlur={removeHelpFeature}
                  helperText={
                    isTabletOrMobile
                      ? HELP_FEATURES.occupation.description
                      : '*Selecciona todos los que apliquen'
                  }
                />
                {formik.values.occupation?.some(
                  (opt) => opt?.label === 'Otros',
                ) && (
                  <FormikTextInput
                    name="otherOccupation"
                    data-cy="otherOccupationInput"
                    placeholder="Otra opción de actividad de trabajo"
                    label="Otra opción de actividad de trabajo"
                    onFocus={(e) => addHelpFeature(e, 'occupation')}
                    onBlur={removeHelpFeature}
                    helperText={
                      isTabletOrMobile && HELP_FEATURES.occupation.description
                    }
                  />
                )}
              </Flex>
            </OnboardingField>
            <Flex direction="column" gap={12} css={{ flex: 1 }}>
              <FormikKontaSelect
                id="business_details"
                isMulti
                name="business_details"
                options={BUSINESS_OPTIONS}
                label="Explícanos más en qué consiste tu negocio."
                className="onboarding-multi-select"
                onChange={(val) => {
                  if (!val.some((opt) => opt.label === 'Otros')) {
                    formik.setFieldValue('otherBusinessDetail', '');
                  }
                }}
                helperText={
                  !isTabletOrMobile && '*Selecciona todos los que apliquen'
                }
              />
              {formik.values.business_details?.some(
                (opt) => opt?.label === 'Otros',
              ) && (
                <FormikTextInput
                  name="otherBusinessDetail"
                  placeholder="Otra opción de en que consiste tu negocio"
                  label="Otra opción de en que consiste tu negocio"
                  data-cy="otherBusinessDetailInput"
                />
              )}
            </Flex>
            <FormikTextarea
              name="business_description"
              data-cy="businessDescriptionInput"
              rows={5}
              label="Danos una breve descripción de tu negocio."
              placeholder="Ej. Soy una empresa que vende..., Vendo maquinaria de..., Compro y vendo artículos por..., etc"
            />
          </Flex>
          <Flex direction="column">
            <Flex direction="column" gap={10} css={{ p: '10px' }}>
              <FormikCheckbox
                id="has_foreign_income"
                name="has_foreign_income"
                label="Recibo ingresos del extranjero"
                supportText="Si tienes clientes que residen en otro país y ellos te pagan por tus servicios o productos, entonces debes marcar esta opción. Esto indica que tienes ingresos provenientes de fuera del país."
                onCheckedChange={(value) =>
                  formik.setFieldValue('has_foreign_income', value)
                }
              />
              <Divider />
            </Flex>
            <OnboardingField
              addHelpFeature={addHelpFeature}
              removeHelpFeature={removeHelpFeature}
              fieldName="has_investments"
              direction="column"
              gap={10}
              css={{ p: '10px' }}
            >
              <FormikCheckbox
                id="has_investments"
                name="has_investments"
                label="Tengo inversiones"
                supportText="Si tienes activos que utilizas para generar ingresos o para obtener ganancias a largo plazo, entonces debes marcar esta opción. Algunos ejemplos de activos considerados como inversiones incluyen acciones en la bolsa de valores, bonos gubernamentales (como los Cetes), y plataformas de préstamos (como Yotepresto)."
                onFocus={(e) => addHelpFeature(e, 'has_investments')}
                onBlur={removeHelpFeature}
              />
              {formik.values.has_investments && (
                <Flex css={{ pl: '24px', pt: '8px' }}>
                  <Flex direction="column" gap={12} css={{ flex: 1 }}>
                    <FormikKontaSelect
                      id="investments"
                      isMulti
                      name="investments"
                      options={INVESTMENTS_OPTIONS}
                      label="¿Qué tipo de inversiones tienes actualmente?"
                      className="onboarding-multi-select"
                      onChange={(val) => {
                        if (!val.some((opt) => opt.label === 'Otros')) {
                          formik.setFieldValue('otherInvestments', '');
                        }
                      }}
                      helperText={
                        isTabletOrMobile
                          ? HELP_FEATURES.has_investments.description
                          : 'Selecciona todos los que apliquen'
                      }
                    />
                    {formik.values.investments?.some(
                      (opt) => opt?.label === 'Otros',
                    ) && (
                      <FormikTextInput
                        name="otherInvestments"
                        placeholder="Otra opción de inversión"
                        label="Otra opción de inversión"
                        data-cy="otherInvestmentsInput"
                      />
                    )}
                  </Flex>
                </Flex>
              )}

              <Divider />
            </OnboardingField>
          </Flex>
          <Flex gap={12}>
            <NextStepButton
              currentProcessType={process}
              text="Continuar"
              onClick={formik.submitForm}
              payload={payload}
              data-cy="onboardingIncomesNextBtn"
            />
          </Flex>
        </FormikProvider>
      }
      rightSidebarContent={
        isReactElement(currentHelpFeature) ? (
          currentHelpFeature
        ) : (
          <ProcessStepHelpFeature
            title="¿No sabes cómo continuar?"
            description={
              <>
                {'Ve '}
                <Text
                  as="a"
                  color="primary700"
                  href="https://konta.releasenotes.io/release/rmQak-nueva-funcionalidad-de-referidos-en-konta"
                  target="_blank"
                >
                  este video
                </Text>{' '}
                {
                  ' y sigue adelante con tu proceso. Te puede ayudar tener mayor claridad sobre cómo continuar abriendo tu cuenta.'
                }
              </>
            }
          />
        )
      }
    />
  );
}
