import { useMemo } from 'react';
import dayjs from 'dayjs';
import useSelectedWorkflow from './useSelectedWorkflow';

export default function useIsValidCaptureLine() {
  const { workflow } = useSelectedWorkflow();

  const isValid = useMemo(() => {
    const { declaration_document } = workflow?.active_declaration || {};

    const declarationDate = dayjs(declaration_document?.validation_date);
    const isExpired = declarationDate.subtract(1, 'day') < dayjs();

    return !isExpired;
  }, [workflow]);

  return isValid;
}
