import React, { ReactNode } from 'react';
import { Flex, Text } from '@konta/ui';
import { CSS } from '@konta/ui/dist/stitches.config';

interface ProcessStepHelpFeatureProps {
  title?: ReactNode;
  description?: ReactNode;
  css?: CSS;
}

export default function ProcessStepHelpFeature({
  title,
  description,
  css = {},
}: ProcessStepHelpFeatureProps) {
  return (
    <Flex direction="column" gap={8} css={{ maxWidth: '270px', ...css }}>
      {title && (
        <Text m lineHeight="m" medium color="gray600">
          {title}
        </Text>
      )}
      {description && (
        <Text s regular lineHeight="s" color="gray500">
          {description}
        </Text>
      )}
    </Flex>
  );
}
