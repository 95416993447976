import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  CLIENTS_SUPPLIERS_FETCH,
  CLIENTS_SUPPLIERS_POST,
  CLIENTS_SUPPLIERS_EDIT,
  CLIENTS_SUPPLIERS_DELETE,
} from '@constants/actionTypes';
import fetchSaga from './fetch';
import postSaga from './post';
import editSaga from './edit';
import deleteSaga from './delete';

export function* watchFetch() {
  yield takeLatest(CLIENTS_SUPPLIERS_FETCH, fetchSaga);
}

export function* watchPost() {
  yield takeLatest(CLIENTS_SUPPLIERS_POST, postSaga);
}
export function* watchEdit() {
  yield takeLatest(CLIENTS_SUPPLIERS_EDIT, editSaga);
}
export function* watchDelete() {
  yield takeLatest(CLIENTS_SUPPLIERS_DELETE, deleteSaga);
}
export default function* rootSaga() {
  yield all([
    fork(watchPost),
    fork(watchFetch),
    fork(watchEdit),
    fork(watchDelete),
  ]);
}
