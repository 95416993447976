import React, { useEffect, useState } from 'react';
import {
  Row,
  Button,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from 'reactstrap';
import { AvGroup, AvField } from 'availity-reactstrap-validation';
import { connect, useDispatch } from 'react-redux';
import { Edit1Icon } from '@konta/icons';
import { Flex } from '@konta/ui';
import toCurrency from '@util/toCurrency';
import {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
  bankTransactionsDeclarationsFetch,
  bankTransactionsDeclarationsBulkUpdate,
} from '@redux/actions';
import { Colxx, CustomModal } from '@components';
// eslint-disable-next-line import/no-cycle
import { UploadTransactionTable } from '@containers';
import WorkflowLayout from '@components/WorkflowLayout';
import WorkflowHeader from '@components/WorkflowHeader';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import PrevPhaseButton from '@components/PrevPhaseButton';
import NextPhaseConfirmation from '@components/NextPhaseConfirmation';
import useUploadTransactions from './useUploadTransactions';

function UploadTransaction({
  workflow,
  wloading,
  bank_transactions,
  bloading,
}) {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [toogleClassifyModal, setToogleClassifyModal] = useState(false);
  const [toogleAllRowsSelection, setToogleAllRowsSelection] = useState(true);

  const inflowBankTransactions = bank_transactions.filter(
    ({ transaction_type }) => transaction_type === 'inflow',
  );

  useEffect(() => {
    const workflow_id = workflow.id;
    dispatch(bankTransactionsDeclarationsFetch({ workflow_id }));
  }, []);

  const handleClassifyActionOnDropDown = (row) => {
    row.toggleRowSelected(true);
    setToogleClassifyModal(!toogleClassifyModal);
  };

  const data = React.useMemo(() => inflowBankTransactions, [bank_transactions]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Importe',
        accessor: 'bank_transaction.amount',
        width: 90,
        maxWidth: 90,
        Cell: ({ value }) => (
          <p className="list-item-heading"> {toCurrency(+value)} </p>
        ),
      },
      {
        Header: '',
        accessor: 'accounting_status',
        width: 90,
        maxWidth: 90,
        Cell: (props) => (
          <Badge
            color={
              {
                is_taxable: 'success',
                not_taxable: 'warning',
                not_categorized: 'light',
                // eslint-disable-next-line react/destructuring-assignment
              }[props.value]
            }
            onClick={() =>
              // eslint-disable-next-line react/destructuring-assignment
              props.row.toggleRowSelected(props.row.original.isSelected)
            }
            className="cursor-pointer badge-clear"
          >
            {
              {
                is_taxable: 'Si entra',
                not_taxable: 'No entra',
                not_categorized: 'Por clasificar',
                // eslint-disable-next-line react/destructuring-assignment
              }[props.value]
            }
          </Badge>
        ),
      },
      // {
      //   Header: "Clasificador",
      //   accessor: "classification_category",
      //   Cell: (props) => <p className="list-item-heading"> <IntlMessages id={`flow.${props.value}`} /></p>,
      // },
      {
        Header: 'Descripción',
        accessor: 'bank_transaction.description',
        width: 400,
        cellClass: 'cell-small-text',
        Cell: (props) => (
          <span className="list-item-heading"> {props.value} </span>
        ),
      },
      {
        Header: 'Referencia',
        accessor: 'bank_transaction.reference',
        width: 130,
        cellClass: 'cell-small-text',
        Cell: (props) => {
          return <span className="list-item-heading"> {props.value} </span>;
        },
      },
      {
        Header: 'Fecha',
        accessor: 'bank_transaction.date_issued',
        width: 90,
        maxWidth: 90,
        Cell: (props) => <p className="list-item-heading"> {props.value} </p>,
      },
      {
        Header: '',
        accessor: 'id',
        width: 60,
        maxWidth: 60,
        Cell: (props) => {
          const { row } = props;
          return (
            <div className="d-flex justify-content-center">
              <UncontrolledDropdown className="button-transparent-container">
                <DropdownToggle caret className="button-transparent">
                  <i className="glyph-icon simple-icon-options button-transparent-icon" />
                  {/* <MoreSqFrIcon className="button-transparent-icon"/> */}
                </DropdownToggle>
                <DropdownMenu right className="">
                  <DropdownItem
                    className="sidebar-header-dropdown-item"
                    onClick={() => handleClassifyActionOnDropDown(row)}
                  >
                    <Edit1Icon className="sidebar-header-dropdown-item-icon" />
                    <div className="sidebar-header-dropdown-item-text">
                      Clasificar
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          );
        },
      },
    ],
    [],
  );

  const handleCalassifyModalValidSumbit = (event, values) => {
    const { clasifyCategory } = values;
    const classificationValues = clasifyCategory.split('-');
    const bank_transactions_declarations = selectedRows.map((selectedRow) => ({
      id: selectedRow.original.id,
      declaration_id: selectedRow.original.declaration_id,
      classification_category: classificationValues[0],
      accounting_status: classificationValues[1],
    }));
    const body = { bank_transactions_declarations };
    dispatch(bankTransactionsDeclarationsBulkUpdate({ body }));
    setToogleClassifyModal(!toogleClassifyModal);
  };

  const totals = useUploadTransactions({ rows: data, selectedRows });

  const formattedTotalSelected = toCurrency(totals.amountSelected);
  const formattedTotalIncome = toCurrency(totals.income);
  const formattedTotalTaxableIncome = toCurrency(totals.taxableIncome);

  const NotCategorizedaBankTransaction = data.filter(
    ({ accounting_status }) => accounting_status === 'not_categorized',
  );

  const isLoading = wloading || bloading;

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Clasifica tus ingresos de banco"
          description="En este apartado podrás clasificar los ingresos en tus cuentas de banco."
          videoId="9OceY_s2w2M"
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <div>
            <Label className="av-label" for="global_total">
              <b>Total de ingresos</b>
            </Label>
            <h6>{formattedTotalIncome}</h6>
          </div>
          <div>
            <Label className="av-label" for="global_total">
              <b>Ingresos a declarar</b>
            </Label>
            <h6>{formattedTotalTaxableIncome}</h6>
          </div>
          {NotCategorizedaBankTransaction.length > 0 ? (
            <NextPhaseConfirmation
              payload={{
                workflow: {
                  next_phase: 'primary_phase',
                },
              }}
              trigger={({ onClick }) => (
                <PrimaryPhaseButton onClick={onClick}>
                  Continuar
                </PrimaryPhaseButton>
              )}
              title=""
              cancelText=""
              onAccept={() => {}}
            >
              <Flex align="center" direction="column">
                <i className="large-icon iconsmind-Danger" color="danger" />
                <p>Para continuar tienes que clasificar todos los ingresos</p>
                <p className="justify-content-start">
                  Asegurate que <strong>ningún movimiento de banco</strong>{' '}
                  tenga el estatus de:
                  <Badge color="light" className="cursor-pointer badge-clear">
                    Por clasificar
                  </Badge>
                </p>
              </Flex>
            </NextPhaseConfirmation>
          ) : (
            <PrimaryPhaseButton loading={isLoading}>
              Continuar
            </PrimaryPhaseButton>
          )}
        </>
      }
    >
      <Flex css={{ padding: '$20' }} direction="column">
        <Row>
          <Colxx md={6} className="d-flex mb-2">
            {selectedRows.length > 0 ? (
              <div className="d-flex align-items-center">
                <span>{selectedRows.length} seleccionados</span>
                <div className="selected-text-divider pl-3 ml-3" />
                <span
                  className="clickable-text-color"
                  onClick={() =>
                    setToogleAllRowsSelection(!toogleAllRowsSelection)
                  }
                >
                  Anular selección
                </span>
                <Button
                  size="sm"
                  className="new-btn ml-3"
                  color="primary"
                  onClick={() => setToogleClassifyModal(true)}
                >
                  <Edit1Icon className="button-icon" />
                  Clasificar
                </Button>
              </div>
            ) : (
              <div className="mb-5" />
            )}
          </Colxx>
        </Row>
        <Row>
          <Colxx className="table-overflow">
            <UploadTransactionTable
              columns={columns}
              data={data}
              setSelectedRows={setSelectedRows}
              toogleAllRowsSelection={toogleAllRowsSelection}
              loading={bloading}
            />
          </Colxx>
        </Row>
        <Row>
          <Colxx md={6} className="d-flex mb-2">
            {selectedRows.length > 0 ? (
              <div className="d-flex align-items-center">
                <span>{selectedRows.length} seleccionados</span>
                <div className="selected-text-divider pl-3 ml-3" />
                <span
                  className="clickable-text-color"
                  onClick={() =>
                    setToogleAllRowsSelection(!toogleAllRowsSelection)
                  }
                >
                  Anular selección
                </span>
                <Button
                  size="sm"
                  className="new-btn ml-3"
                  color="primary"
                  onClick={() => setToogleClassifyModal(true)}
                >
                  <Edit1Icon className="button-icon" />
                  Clasificar
                </Button>
                <CustomModal
                  title={`Clasificar ${selectedRows.length} movimiento${
                    selectedRows.length > 1 ? 's' : ''
                  }`}
                  toggle={() => setToogleClassifyModal(!toogleClassifyModal)}
                  isOpen={toogleClassifyModal}
                  size="lg"
                  handleSubmit={(_) => _}
                  handleValidSubmit={handleCalassifyModalValidSumbit}
                  submitText="Clasificar"
                  centered
                  // model={selectedProduct}
                >
                  <p>
                    ¡Clasificar movimiento es sencillo! Solo tendrás que
                    selecionar la razón de la clasificación para que de esa
                    manera nosotros podamos saber que es lo que entra en la
                    declaración de este mes y que no entra.{' '}
                    <span>
                      <a
                        className="clickable-text-color font-italic"
                        href="https://www.sat.gob.mx/consulta/23972/conoce-las-deducciones-personales"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Más información
                      </a>
                    </span>
                  </p>
                  <Row className="">
                    <Colxx xxs={6} className="mb-2">
                      <AvGroup>
                        <AvField
                          type="select"
                          name="clasifyCategory"
                          label="Razón de clasificacion"
                          // helpMessage="Idk, this is an example. Deal with it!"
                          required
                          errorMessage="Campo inválido, seleccione una opción válida"
                        >
                          <option value="" className="d-none" />
                          <option value="sales-is_taxable">
                            Ingresos por ventas (Si entra)
                          </option>
                          <option value="loan-not_taxable">
                            {' '}
                            Préstamos (No entra){' '}
                          </option>
                          <option value="donation-not_taxable">
                            {' '}
                            Donaciones (No entra){' '}
                          </option>
                          <option value="family_income-not_taxable">
                            Traspaso entre familia (No entra)
                          </option>
                          <option value="inheritance-not_taxable">
                            {' '}
                            Herencia (No entra){' '}
                          </option>
                          <option value="not_business-not_taxable">
                            Ingresos no relacionados con el negocio. (No entra)
                          </option>
                          <option value="payroll-not_taxable">
                            {' '}
                            Nómina (No entra){' '}
                          </option>
                          <option value="advance_payment-is_taxable">
                            Anticipo de clientes (Si entra)
                          </option>
                          {/* <option value="9"> Efectivo (Si entra) </option> */}
                          <option value="public_sales-is_taxable">
                            Ventas al público en general (Si entra)
                          </option>
                          <option value="investment_interest-is_taxable">
                            Intereses por inversión (Si entra)
                          </option>
                        </AvField>
                      </AvGroup>
                    </Colxx>
                  </Row>
                  <p>
                    El monto total a clasificar es de {formattedTotalSelected}{' '}
                    MXN
                  </p>
                </CustomModal>
              </div>
            ) : (
              <div className="mb-5" />
            )}
          </Colxx>
        </Row>
      </Flex>
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ workflows, bank_transactions_declarations }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  const {
    bank_transactions_declarations: bank_transactions,
    loading: bloading,
  } = bank_transactions_declarations;
  return { workflow, wloading, bank_transactions, bloading };
};

export default connect(mapStateToProps, {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
  bankTransactionsDeclarationsFetch,
  bankTransactionsDeclarationsBulkUpdate,
})(UploadTransaction);
