import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useToggle } from 'rooks';
import { updateTaxableEntity } from '@redux/actions';
import filterActiveRegimes from 'shared/util/filterActiveRegimes.ts';
import FormikFormModal from '../FormikFormModal/FormikFormModal';
import FormikRegimeSelect from '../FormikRegimeSelect';
import useFiscalRegimeOptions from '../../@hooks/useFiscalRegimeOptions';

const validationSchema = yup.object().shape({
  regimes: yup.array(yup.object().shape({})).test({
    message: 'Seleccione al menos un régimen fiscal',
    test: (value) => value?.length > 0,
  }),
});

export default function FormikUserRegimeSelect({
  onRegimesUpdated = null,
  name,
  disabledOnEmpty = false,
  newDesign,
  ...props
}) {
  const dispatch = useDispatch();
  const formik = useFormikContext();
  const [showModal, toggleShowModal] = useToggle(false);

  const taxableEntity = useSelector((state) => {
    return state.taxableEntity.taxable_entity;
  });
  const hasSubscription = taxableEntity?.status !== 'demo';

  const activeFiscalRegimes = filterActiveRegimes(
    taxableEntity?.fiscal_regimes || [],
  );

  const { regimeOptions } = useFiscalRegimeOptions({
    availableRegimes: activeFiscalRegimes,
  });

  const { regimeOptions: allRegimeOptions } = useFiscalRegimeOptions();

  const regimes = activeFiscalRegimes;
  const hasRegimes = regimes.length > 0;

  const onSubmit = ({ values }) => {
    const regimeIds = values.regimes.map((item) => item.value.id);
    const payload = {
      taxable_entity: {
        fiscal_regime_ids: regimeIds,
      },
    };

    dispatch(updateTaxableEntity(payload, taxableEntity.id));
    toggleShowModal();

    const regimeOption = allRegimeOptions.find(
      ({ value }) => value.id === regimeIds[0],
    );
    formik.setFieldValue(name, regimeOption);

    if (onRegimesUpdated) {
      onRegimesUpdated({ regimes: values.regimes });
    }
  };

  return (
    <>
      <FormikRegimeSelect
        {...props}
        newDesign={newDesign}
        name={name}
        availableRegimes={regimes}
        withButton
        disabled={disabledOnEmpty && !hasRegimes}
        {...(!hasSubscription && { toggle: toggleShowModal })}
      />

      <FormikFormModal
        title="Mis regímenes"
        toggle={toggleShowModal}
        isOpen={showModal}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        submitText="Guardar"
        size="md"
        initialValues={{
          regimes: regimeOptions,
        }}
      >
        <FormikRegimeSelect
          isMulti
          name="regimes"
          label="Selecciona tus regímenes"
          excludedRegimes={regimes}
        />
        <small>
          Los regímenes que agregues se guardarán de forma global en la
          aplicación.
        </small>
      </FormikFormModal>
    </>
  );
}

FormikUserRegimeSelect.propTypes = {
  onRegimesUpdated: PropTypes.func,
  name: PropTypes.string.isRequired,
};

FormikUserRegimeSelect.defaultProps = {
  onRegimesUpdated: null,
};
