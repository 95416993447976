import { NavLink } from 'react-router-dom';
import KontaLogo from '@assets/img/logo-sidebar.svg';
import { styled } from '@konta/ui';

export interface LogoProps {
  withLink?: boolean;
  linkTo?: string;
}

const StyledLogo = styled('span', {
  width: '200px',
  height: '50px',
  background: `url(${KontaLogo}) no-repeat`,
  backgroundPosition: 'center center',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundSize: 'contain',
});
export default function Logo({ withLink = true, linkTo = '/' }: LogoProps) {
  if (withLink && linkTo) {
    return (
      <NavLink to={linkTo}>
        <StyledLogo />
      </NavLink>
    );
  }
  return (
    <span>
      <StyledLogo />
    </span>
  );
}
