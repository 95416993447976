import React from 'react';
import { Emoji } from '@components';
import { useTaxableEntity } from '@hooks';
// eslint-disable-next-line import/no-cycle
import { FeedbackModal } from '@containers';
import WorkflowLayout from '@components/WorkflowLayout';
import WorkflowHeader from '@components/WorkflowHeader';

export default function PendingDeclaration() {
  const { taxable_entity } = useTaxableEntity();

  return (
    <WorkflowLayout header={<WorkflowHeader title="" />}>
      <div className="d-flex align-items-center">
        <div>
          <div className="w-80 w-sm-100">
            <h1>
              ¡Listo! <Emoji symbol="🥳" label="party" /> Le enviaremos su
              declaración ya presentada en el SAT a su correo{' '}
              <span className="text-primary">{taxable_entity.legal_email}</span>
              , en el caso de tener IVA o ISR a pagar se adjuntará la línea de
              captura. ¡Nos vemos el siguiente mes!
            </h1>
          </div>
          <p>
            {'¡Nos encantaría saber que opina! '}
            <FeedbackModal clickText="Dejenos una sugerencia haciendo click aquí." />
          </p>
        </div>
      </div>
    </WorkflowLayout>
  );
}
