export const PAYMENT_TYPE_OPTIONS = [
  { label: 'Efectivo', value: '01', key: 1 },
  { label: 'Cheque nominativo', value: '02', key: 2 },
  { label: 'Transferencia electrónica de fondos', value: '03', key: 3 },
  { label: 'Tarjeta de crédito', value: '04', key: 4 },
  { label: 'Monedero electrónico', value: '05', key: 5 },
  { label: 'Dinero electrónico', value: '06', key: 6 },
  { label: 'Vales de despensa', value: '08', key: 7 },
  { label: 'Dación en pago', value: '12', key: 8 },
  { label: 'Pago por subrogación', value: '13', key: 9 },
  { label: 'Pago por consignación', value: '14', key: 10 },
  { label: 'Condonación', value: '15', key: 11 },
  { label: 'Compensación', value: '17', key: 12 },
  { label: 'Novación', value: '23', key: 13 },
  { label: 'Confusión', value: '24', key: 14 },
  { label: 'Remisión de deuda', value: '25', key: 15 },
  { label: 'Prescripción o caducidad', value: '26', key: 16 },
  { label: 'A satisfacción del acreedor', value: '27', key: 17 },
  { label: 'Tarjeta de débito', value: '28', key: 18 },
  { label: 'Tarjeta de servicios', value: '29', key: 19 },
  { label: 'Aplicación de anticipos', value: '30', key: 20 },
  { label: 'Intermediario pagos', value: '31', key: 21 },
  { label: 'Por definir', value: '99', key: 22 },
];

export const PAYMENT_TYPE_FILTER_CLIENT_OPTS = PAYMENT_TYPE_OPTIONS.map(
  ({ label }) => ({
    label,
    value: label,
  }),
);

export const USE_CFDI_OPTS = [
  { label: 'Adquisición de mercancías', value: 'G01', key: 1 },
  { label: 'Devoluciones, descuentos o bonificaciones', value: 'G02', key: 2 },
  { label: 'Gastos en general', value: 'G03', key: 2 },
  { label: 'Construcciones', value: 'I01', key: 3 },
  {
    label: 'Mobilario y equipo de oficina por inversiones',
    value: 'I02',
    key: 4,
  },
  { label: 'Equipo de transporte', value: 'I03', key: 5 },
  { label: 'Equipo de computo y accesorios', value: 'I04', key: 6 },
  {
    label: 'Dados, troqueles, moldes, matrices y herramental',
    value: 'I05',
    key: 7,
  },
  { label: 'Comunicaciones telefónicas', value: 'I06', key: 8 },
  { label: 'Comunicaciones satelitales', value: 'I07', key: 9 },
  { label: 'Otra maquinaria y equipo', value: 'I08', key: 10 },
  {
    label: 'Honorarios médicos, dentales y gastos hospitalarios.',
    value: 'D01',
    key: 11,
  },
  {
    label: 'Gastos médicos por incapacidad o discapacidad',
    value: 'D02',
    key: 12,
  },
  { label: 'Gastos funerales.', value: 'D03', key: 13 },
  { label: 'Donativos.', value: 'D04', key: 14 },
  {
    label:
      'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
    value: 'D05',
    key: 15,
  },
  { label: 'Aportaciones voluntarias al SAR.', value: 'D06', key: 16 },
  { label: 'Primas por seguros de gastos médicos.', value: 'D07', key: 17 },
  {
    label: 'Gastos de transportación escolar obligatoria.',
    value: 'D08',
    key: 18,
  },
  {
    label:
      'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
    value: 'D09',
    key: 19,
  },
  {
    label: 'Pagos por servicios educativos (colegiaturas)',
    value: 'D10',
    key: 20,
  },
  {
    label: 'Sin efectos fiscales',
    value: 'S01',
    key: 20,
  },
];
