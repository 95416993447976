import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { removeEmptyKeys } from '@util/Utils';
import { postProductsSuccess, postProductsFailure } from '../actions';

export default function* ({ payload: { taxable_entity, product } }) {
  try {
    removeEmptyKeys(product);
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.post,
      `taxable_entities/${taxable_entity.id}/products`,
      { product },
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      response,
      title: 'Producto añadido',
      message: 'Producto agregado con éxito',
      status: response.status,
      success: postProductsSuccess,
      failure: postProductsFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: postProductsFailure,
    });
  }
}
