import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ModalContent } from '@konta/ui';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import ProductForm from '../ProductForm';

export default function ProductModalForm({
  isOpen,
  onClose,
  onSubmit,
  product,
  fiscalRegime,
  client,
}) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" height="full">
      <ModalHeader
        toggle={onClose}
        style={{ fontWeight: '500', fontSize: '18px', color: '$gray900' }}
      >
        {product ? 'Editar producto' : 'Nuevo producto'}
      </ModalHeader>

      <ModalContent>
        {`En esta vista puedes ${product ? 'editar' : 'crear'} un producto`}
        <ProductForm
          fiscalRegime={fiscalRegime}
          client={client}
          product={product}
          onSubmit={onSubmit}
          onLoading={setIsLoading}
        />
      </ModalContent>
      <ModalFooter>
        <Button color="white" loading={isLoading} onClick={onClose} size="m">
          Cancelar
        </Button>
        <Button
          form="product-form"
          type="submit"
          color="primary"
          loading={isLoading}
          size="m"
        >
          Confirmar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ProductModalForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  product: PropTypes.instanceOf(Object),
  fiscalRegime: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object),
};

ProductModalForm.defaultProps = {
  product: null,
  fiscalRegime: null,
  client: null,
};
