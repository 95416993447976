import { all, fork, takeLatest } from 'redux-saga/effects';

import {
  NOTIFICATION_PREFERENCES_FETCH,
  NOTIFICATION_PREFERENCES_UPDATE,
} from '@constants/actionTypes';

import updateSaga from './edit';
import fetchSaga from './fetch';

export function* watchFetch() {
  yield takeLatest(NOTIFICATION_PREFERENCES_FETCH, fetchSaga);
}

export function* watchUpdate() {
  yield takeLatest(NOTIFICATION_PREFERENCES_UPDATE, updateSaga);
}

export default function* rootSaga() {
  yield all([fork(watchFetch), fork(watchUpdate)]);
}
