import {
  DECLARATION_ENTRIES_FETCH,
  DECLARATION_ENTRIES_FETCH_SUCCESS,
  DECLARATION_ENTRIES_FETCH_FAILURE,
  DECLARATION_ENTRIES_CREATE,
  DECLARATION_ENTRIES_CREATE_SUCCESS,
  DECLARATION_ENTRIES_CREATE_FAILURE,
  DECLARATION_ENTRIES_UPDATE,
  DECLARATION_ENTRIES_UPDATE_SUCCESS,
  DECLARATION_ENTRIES_UPDATE_FAILURE,
  DECLARATION_ENTRIES_DELETE,
  DECLARATION_ENTRIES_DELETE_SUCCESS,
  DECLARATION_ENTRIES_DELETE_FAILURE,
  UPLOAD_CFDI_XML,
  UPLOAD_CFDI_XML_SUCCESS,
  UPLOAD_CFDI_XML_FAILURE,
  DECLARATION_ENTRIES_BULK_EDIT,
  DECLARATION_ENTRIES_BULK_EDIT_SUCCESS,
  DECLARATION_ENTRIES_BULK_EDIT_FAILURE,
} from '@constants/actionTypes';

const INITI_STATE = {
  declaration_entries: [],
  loading: false,
  error: null,
};

export default (state = INITI_STATE, action) => {
  switch (action.type) {
    case DECLARATION_ENTRIES_FETCH:
      return {
        ...state,
        loading: true,
      };
    case DECLARATION_ENTRIES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        declaration_entries: action.payload,
      };
    case DECLARATION_ENTRIES_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DECLARATION_ENTRIES_CREATE:
      return {
        ...state,
        loading: true,
      };
    case DECLARATION_ENTRIES_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        declaration_entries: [...state.declaration_entries, action.payload],
      };
    case DECLARATION_ENTRIES_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DECLARATION_ENTRIES_UPDATE:
      return {
        ...state,
        loading: true,
      };
    case DECLARATION_ENTRIES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        declaration_entries: state.declaration_entries.map((platform_entires) =>
          platform_entires.id === action.payload.id
            ? action.payload
            : platform_entires,
        ),
      };
    case DECLARATION_ENTRIES_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DECLARATION_ENTRIES_DELETE:
      return {
        ...state,
        loading: true,
      };
    case DECLARATION_ENTRIES_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        declaration_entries: state.declaration_entries.filter(
          (platform_entires) => platform_entires.id !== action.payload,
        ),
      };
    case DECLARATION_ENTRIES_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_CFDI_XML:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_CFDI_XML_SUCCESS:
      return {
        ...state,
        loading: false,
        declaration_entries: [...state.declaration_entries, action.payload],
      };
    case UPLOAD_CFDI_XML_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DECLARATION_ENTRIES_BULK_EDIT:
      return {
        ...state,
        loading: true,
      };
    case DECLARATION_ENTRIES_BULK_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        declaration_entries: state.declaration_entries.map((entry) => {
          const objIndex = action.payload.findIndex(
            (obj) => obj.id === entry.id,
          );
          return objIndex === -1 ? entry : action.payload[objIndex];
        }),
      };
    case DECLARATION_ENTRIES_BULK_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};
