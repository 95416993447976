import React, { useEffect } from 'react';
// import PropTypes from 'prop-types'
import { Input } from 'reactstrap';

function TabTableFilters({
  data,
  placeholder,
  className,
  name,
  handleSetFilteredData,
  activeTab,
  tabFilter,
}) {
  const [searchInput, setSearchInput] = React.useState('');
  // filter data every searchInput change
  useEffect(() => filterData(), [searchInput, activeTab]);

  const handleChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filterData = () => {
    handleSetFilteredData(
      data,
      searchInput.toLowerCase(),
      tabFilter[parseInt(activeTab) - 1]
    );
  };

  return (
    <Input
      name={name}
      value={searchInput}
      onChange={handleChange}
      placeholder={placeholder}
      className={className}
    />
  );
}

export default TabTableFilters;
