import { useSearchParams } from 'react-router-dom';

const permittedSources = ['zendesk', 'canny'];

export default function useSSOParams() {
  const [search] = useSearchParams();
  const source = search.get('source') || '';
  const isFromSSO = permittedSources.includes(source);
  const accessToken = localStorage.getItem('user_id');
  const params = Object.fromEntries(search);

  const SSO = {
    source,
    isFromSSO,
    params,
  };
  return {
    isFromSSO,
    SSO,
    accessToken,
  };
}
