import { ReactNode } from 'react';
import { Text } from '@konta/ui';
import usePendingTasksStore from 'store/pendingTasksStore';
import DetailAction from './DetailAction';
import { Container, Content, Details, Heading, HeadingText } from './styles';

export interface PendingTaskDetail {
  label?: string;
  icon?: ReactNode;
  iconCss?: Record<string, string | object>;
}

export interface PendingTask {
  id: string;
  type:
    | 'onboarding'
    | 'anualDeclaration'
    | 'monthlyDeclaration'
    | 'taxArrear'
    | 'message'
    | 'subscription'
    | 'other'
    | string;
  status: 'requiredAction' | 'pending' | 'completed';
  title: string;
  description: string;
  details?: PendingTaskDetail[];
  handleAction?: () => void;
}

export default function TaskCard({ task }: { task: PendingTask }) {
  const { setSelectedTask } = usePendingTasksStore();
  return (
    <Container
      onClick={() => {
        setSelectedTask(task);
        task.handleAction?.();
      }}
      data-cy={`task-${task.id}`}
    >
      <Content>
        <Heading>
          <HeadingText>
            <Text bold m lineHeight="m" medium>
              {task.title}
            </Text>
            <Text s regular color="gray500" lineHeight="s">
              {task.description}
            </Text>
          </HeadingText>
        </Heading>
        <Details>
          {task.details?.map((detail, index) => (
            <DetailAction
              key={index}
              icon={detail.icon}
              label={detail.label}
              iconCss={detail.iconCss}
            />
          ))}
        </Details>
      </Content>
    </Container>
  );
}
