import api from '@api';
import jsonToFormData from 'shared/util/jsonToFormData';
import type { AxiosRequestConfig } from 'axios';
import type {
  Workflow,
  WorkflowNextPhasePayload,
  WorkflowDeletePayload,
  WorkflowPayload,
  WorkflowUpdatePayload,
  WorkflowApprovePayload,
  WorkflowBulkPayload,
  WorkflowBulkEditPayload,
  WorkflowUploadDeclarationAckPayload,
  WorkflowSwitchTemplatePayload,
  WorkflowBulkDeletePayload,
} from 'types/entities';

export const getWorkflows = async (config?: AxiosRequestConfig<Workflow[]>) => {
  const { data } = await api.get<Workflow[]>(`workflows`, config);
  return data;
};

export const postWorkflow = async (
  payload: WorkflowPayload,
  config?: AxiosRequestConfig<Workflow>,
) => {
  const { data } = await api.post<Workflow>(`workflows`, payload, config);
  return data;
};

export const postWorkflowBulk = async (
  payload: WorkflowBulkPayload,
  config?: AxiosRequestConfig<Workflow[]>,
) => {
  const { data } = await api.post<Workflow[]>(
    `workflows/bulk_create`,
    payload,
    config,
  );
  return data;
};

export const putWorkflowBulkUpdate = async (
  payload: WorkflowBulkEditPayload,
  config?: AxiosRequestConfig<Workflow[]>,
) => {
  const { data } = await api.put<Workflow[]>(
    `workflows/bulk_update`,
    payload,
    config,
  );
  return data;
};

export const putRestartWorkflow = async (
  { workflowId }: WorkflowUpdatePayload,
  config?: AxiosRequestConfig<Workflow>,
) => {
  const { data } = await api.put<Workflow>(
    `workflows/${workflowId}/restart`,
    config,
  );
  return data;
};

export const putBackWorkflowPhase = async (
  workflowId: string | number,
  config?: AxiosRequestConfig<Workflow>,
) => {
  const { data } = await api.put<Workflow>(
    `workflows/${workflowId}/previous_phase`,
    config,
  );
  return data;
};

export const putNextWorkflowPhase = async (
  { workflowId, payload }: WorkflowNextPhasePayload,
  config?: AxiosRequestConfig<Workflow>,
) => {
  const { data } = await api.put<Workflow>(
    `workflows/${workflowId}/next_phase`,
    payload,
    config,
  );
  return data;
};

export const putWorkflow = async (
  { workflowId, payload }: WorkflowUpdatePayload,
  config?: AxiosRequestConfig<Workflow>,
) => {
  const { data } = await api.put<Workflow>(
    `workflows/${workflowId}`,
    payload,
    config,
  );
  return data;
};

export const deleteWorkflow = async (
  { workflowId }: WorkflowDeletePayload,
  config?: AxiosRequestConfig<void>,
) => {
  await api.delete(`workflows/${workflowId}`, config);
};

export const deleteWorkflowBulk = async (
  payload: WorkflowBulkDeletePayload,
  config?: AxiosRequestConfig<void>,
) => {
  await api.delete(`workflows/bulk_delete`, { ...config, data: payload });
};

export const putApproveWorkflow = async (
  { workflowId }: WorkflowApprovePayload,
  config?: AxiosRequestConfig<Workflow>,
) => {
  const { data } = await api.put<Workflow>(
    `workflows/${workflowId}/approve`,
    undefined,
    config,
  );
  return data;
};

export const postUploadDeclarationAck = async (
  { workflowId, payload }: WorkflowUploadDeclarationAckPayload,
  config?: AxiosRequestConfig<Workflow>,
) => {
  const formDataPayload = jsonToFormData(payload || {});
  const { data } = await api.post<Workflow>(
    `workflows/${workflowId}/upload_declaration_ack`,
    formDataPayload,
    config,
  );
  return data;
};

export const postSwitchWorkflowTemplate = async (
  { workflowId, payload }: WorkflowSwitchTemplatePayload,
  config?: AxiosRequestConfig<Workflow>,
) => {
  const { data } = await api.post<Workflow>(
    `workflows/${workflowId}/switch_template`,
    payload,
    config,
  );
  return data;
};
