import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ProcessType } from 'types/entities';
import useProcess from '@hooks/useProcess';
import useCurrentProcessesQuery from '@hooks/useCurrentProcessesQuery';
import { PROCESSES_HOME_ROUTE } from '@constants/process';

const PENDING_ROUTE = '/app/pendientes';

export default function useProcessNavigation(
  processType: ProcessType,
  processId?: number,
) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { process, activeStepRoute } = useProcess(
    processType,
    undefined,
    processId,
  );
  const { currentProcesses, completedProcesses } = useCurrentProcessesQuery();

  useEffect(() => {
    if (completedProcesses) {
      navigate(PENDING_ROUTE);
    }
  }, [navigate, currentProcesses, completedProcesses]);

  useEffect(() => {
    if (activeStepRoute) {
      navigate({
        pathname: activeStepRoute,
        search: searchParams.toString(),
      });
    }
  }, [activeStepRoute, navigate, processType]);

  useEffect(() => {
    if (process?.completed) {
      navigate(
        process?.type === ProcessType.OnboardingOnboardingProcess
          ? PROCESSES_HOME_ROUTE
          : PENDING_ROUTE,
      );
    }
  }, [navigate, process]);
}
