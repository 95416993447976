import dayjs from 'dayjs';
import { WORKFLOW_ROUTES } from '@constants/workflow';
import { Workflow, WorkflowPhaseKeyDict } from 'types/entities';

export default function getCurrentRouteByWorkflowPhase(
  workflowPhase: WorkflowPhaseKeyDict,
  workflow: Workflow,
) {
  if (workflowPhase === WorkflowPhaseKeyDict.PendingDeclaration) {
    const startDate = dayjs(workflow.start_date);
    const currentMonth = startDate.month() + 1;
    const currentYear = startDate.year();
    return WORKFLOW_ROUTES[workflowPhase]?.replace(
      'month=:month&year=:year',
      `month=${currentMonth}&year=${currentYear}`,
    );
  }
  if (!WORKFLOW_ROUTES[workflowPhase]) {
    return `/app/workflow/${workflow.id}/default`;
  }
  return WORKFLOW_ROUTES[workflowPhase]?.replace(
    ':workflowId',
    workflow.id.toString(),
  );
}
