import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { Button } from '@konta/ui';
import postRemindInvitation from '@api/postRemindInvitation';

export default function RemindInvitationButton({
  invitationId,
  onSuccess,
  onError,
}) {
  const remindInvitation = useMutation(
    async () => {
      await postRemindInvitation(invitationId);
    },
    {
      // TODO: fix useApiPagination (it trigger refetches unexpectedly) to implement
      // useInvitedReferralsPagination here instead callbacks by props
      onSuccess,
      onError,
    },
  );

  return (
    <Button
      size="m"
      color="primary"
      variant="text"
      loading={remindInvitation.isLoading}
      onClick={() => remindInvitation.mutate()}
    >
      Recordar
    </Button>
  );
}

RemindInvitationButton.propTypes = {
  invitationId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
