import { styled } from '@konta/ui';

export const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  flex: '1 0 0',
  width: '100%',
});

export const LoaderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100px',
});

export const EmptyWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100px',
  alignItems: 'center',
  justifyContent: 'center',
});

export const HeaderWrapper = styled('div', {
  display: 'flex',
  height: '44px',
  padding: '12px 24px',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
  borderBottom: '1px solid $gray200',
  backgroundColor: '$gray50',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
});
