import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { fetchCfdisByPpdsSuccess, fetchCfdisByPpdsFailure } from '../actions';

export default function* () {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(api.get, `cfdis/by_ppd`, {
      headers: { Authorization: `${access_token}` },
    });
    yield call(StatusHandler, {
      status: response.status,
      response,
      success: fetchCfdisByPpdsSuccess,
      failure: fetchCfdisByPpdsFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: fetchCfdisByPpdsFailure,
    });
  }
}
