import { Content, Root } from './styled';
import IncomesList from './IncomesList';

export default function ClassifyIncomes() {
  return (
    <Root>
      <Content>
        <IncomesList />
      </Content>
    </Root>
  );
}
