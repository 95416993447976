import api from '@api';
import { useQuery, UseQueryOptions } from 'react-query';
import { FOREIGN_TAXES_PAYERS } from 'shared/constants/reactQueries';
// TYPES
import type { AxiosRequestConfig } from 'axios';
import type { ForeignTaxPayer } from 'types/entities';

export default function useForeignTaxesPayers(
  axiosConfig?: AxiosRequestConfig<ForeignTaxPayer[]>,
  queryOptions?: UseQueryOptions<ForeignTaxPayer[]>,
) {
  const {
    data = [],
    isLoading,
    ...rest
  } = useQuery<ForeignTaxPayer[]>(
    [FOREIGN_TAXES_PAYERS, axiosConfig],
    async () => {
      const response = await api.get<ForeignTaxPayer[]>(
        '/foreign_taxpayers',
        axiosConfig,
      );
      return response.data;
    },
    queryOptions,
  );

  return { loadingTaxesPayers: isLoading, taxesPayers: data, ...rest };
}
