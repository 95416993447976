import type { Table } from '@tanstack/react-table';
import {
  calculateAmountAndConversion,
  getBaseIVA0,
  getBaseIVA16,
  getBaseIVA8,
  getBaseIVAExempt,
  getCfdi,
  getDiscount,
  getRetainedISR,
  getRetainedIVA,
  getSubtotal,
  getTotalIva,
  getTotalRetentions,
} from '@util/declarationEntries';
import type { DeclarationEntry } from 'types/entities';
import { PF_SAT_KEY } from '../../constants/grids';
import { CurrencyType, SourceType } from './types';

const valueTypeToFunctionMap: Record<
  CurrencyType,
  (entry: DeclarationEntry) => { convertedAmount: number }
> = {
  total: calculateAmountAndConversion,
  subtotal: getSubtotal,
  baseIva16: getBaseIVA16,
  baseIva8: getBaseIVA8,
  baseIva0: getBaseIVA0,
  baseIvaExempt: getBaseIVAExempt,
  discount: getDiscount,
  iva: getTotalIva,
  retainedIva: getRetainedIVA,
  retainedIsr: getRetainedISR,
  totalRetained: getTotalRetentions,
};

export const getValue = (
  declarationEntry: DeclarationEntry,
  valueType: CurrencyType,
): number => {
  const getValueFunction = valueTypeToFunctionMap[valueType];
  return getValueFunction
    ? getValueFunction(declarationEntry).convertedAmount
    : 0;
};

const shouldSkipEntry = (entry: DeclarationEntry, entryTypeFilter: unknown) => {
  const isPlatformRegime = entry.fiscal_regime.sat_key === PF_SAT_KEY;
  const isCfdiEmitted = !!getCfdi(entry.source)?.is_emited;
  const isNotDeductible =
    !!entryTypeFilter && entry.accounting_status !== 'is_deductible';

  return (isPlatformRegime && isCfdiEmitted) || isNotDeductible;
};

export const getClientFooterValue = (
  table: Table<DeclarationEntry>,
  valueType: CurrencyType,
) => {
  const entryTypeFilter = table
    .getState()
    .columnFilters?.find?.(
      (filter) => filter.id === 'general_declaration_entry_type',
    )?.value;

  return table.getFilteredRowModel().rows.reduce((acc, row) => {
    const entry = row.original;
    if (shouldSkipEntry(entry, entryTypeFilter)) {
      return acc;
    }
    return acc + getValue(entry, valueType);
  }, 0);
};

export const getBackofficeFooterValue = (
  table: Table<DeclarationEntry>,
  valueType: CurrencyType,
) =>
  table.getFilteredRowModel().rows.reduce((acc, row) => {
    const entry = row.original;
    return acc + getValue(entry, valueType);
  }, 0);

export const getFooterValue = (
  table: Table<DeclarationEntry>,
  source: SourceType,
  valueType: CurrencyType,
) =>
  source === 'client'
    ? getClientFooterValue(table, valueType)
    : getBackofficeFooterValue(table, valueType);
