import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  declarationEntriesBulkEditFailure,
  declarationEntriesBulkEditSuccess,
} from '../actions';

export default function* ({ payload: { body } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `declaration_entries/bulk_update`,
      body,
      { headers: { Authorization: `${access_token}` } }
    );

    yield call(StatusHandler, {
      response,
      title: 'Exito!',
      message: 'Las facturas se han actualizado correctamente.',
      status: response.status,
      success: declarationEntriesBulkEditSuccess,
      failure: declarationEntriesBulkEditFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      message: 'Algo salió mal al editar el gasto',
      action: declarationEntriesBulkEditFailure,
    });
  }
}
