import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Popover, Button } from 'reactstrap';
import { MONTHS } from '@constants/time';

export function DatePickerPopover({
  date,
  target = 'popover-datepicker',
  handleChange,
  trigger = 'legacy',
  placement = 'left',
}) {
  const [isOpen, toggle] = useState(false);
  const sw = () => toggle(!isOpen);
  return (
    <>
      <Button
        color="link"
        id={target}
        className="position-absolute btn-datepicker font-weight-bold"
      >
        <i className="iconsmind-Calendar mr-2 font-weight-bold" />
        {MONTHS[+date.getMonth()]} {date.getFullYear()}
      </Button>
      <Popover
        placement={placement}
        trigger={trigger}
        target={target}
        isOpen={isOpen}
        toggle={sw}
      >
        <CustomDatePicker handleChange={handleChange} date={date} />
      </Popover>
    </>
  );
}

function CustomDatePicker({ date, handleChange }) {
  return (
    <div className="datepicker">
      <DatePicker
        calendarClassName="mb-3"
        selected={date}
        onChange={handleChange}
        onYearChange={handleChange}
        inline
        selectsStart
        dateFormat="MMMM, QQQQ, yyyy"
        showMonthYearPicker
      />
    </div>
  );
}
export default CustomDatePicker;
