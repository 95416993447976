import { Flex, Text } from '@konta/ui';
import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import toCurrency from '@util/toCurrency';
import type { GroupedEntries } from 'types/entities';
import LetterAvatar from 'react-avatar';
import { cellAmountCss, cellBoldCss, cellUpperCss, headerCss } from '../styled';

interface UseEntriesGroupedColumns {
  type: 'incomes' | 'expenses';
}

export default function useEntriesGroupedColumns({
  type,
}: UseEntriesGroupedColumns) {
  const isIncome = type === 'incomes';
  return useMemo<ColumnDef<GroupedEntries>[]>(
    () => [
      {
        header: () => (
          <Text css={headerCss}>
            Nombre del {isIncome ? 'receptor' : 'emisor'}
          </Text>
        ),
        label: isIncome ? 'Receptor' : 'Emisor',
        accessorKey: 'legal_name',
        id: 'legal_name',
        cell: ({ row }) => {
          const { issuer_legal_name: legalName } = row.original;
          return (
            <Flex css={cellBoldCss} gap={12} itemsCenter>
              <span className="classify-expenses-logo-container">
                <LetterAvatar
                  maxInitials={2}
                  name={legalName || '-'}
                  size="28"
                  round
                />
              </span>
              {legalName}
            </Flex>
          );
        },
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
      },
      {
        header: () => <Text css={headerCss}>RFC</Text>,
        label: 'RFC',
        accessorKey: 'rfc',
        id: 'rfc',
        filterHandler: {
          type: 'text',
          initialFilter: '',
        },
        cell: ({ row }) => <Flex css={cellUpperCss}>{row.original.rfc}</Flex>,
      },
      {
        header: () => (
          <Flex justify="end">
            <Text css={headerCss}>Total</Text>
          </Flex>
        ),
        label: 'Total',
        accessorKey: 'total',
        id: 'total',
        cell: ({ renderValue }) => (
          <Flex justify="end" css={cellAmountCss}>
            {toCurrency(renderValue())}
          </Flex>
        ),
      },
    ],
    [isIncome],
  );
}
