/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react';
import {
  DataTable,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  Flex,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Button,
  Text,
} from '@konta/ui';
import { Edit2OutlineIcon, TrashCanOutlineIcon } from '@konta/icons';
import { useDispatch } from 'react-redux';
import { ConfirmModal } from '@components/Modal';
import { deleteClientsSuppliers } from '@redux/actions';
import ClientModalForm from '@components/ClientModalForm';
import { useToggle } from 'usehooks-ts';

// TODO remove this component
export default function ClientsProvidersCard({
  clients,
  suppliers,
  loading,
  taxable_entity,
}) {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(1);

  const [selectedClient, setSelectedClient] = useState();
  const [deleteItem, setDeleteItem] = useState();
  const [openDeleteModal, toggleOpenDeleteModal] = useToggle();
  const [isClientsOpen, setIsClientsOpen] = useState(false);

  const toggleClients = (result) => {
    setIsClientsOpen(!isClientsOpen);
  };

  const editClient = (client) => {
    setSelectedClient(client);
    toggleClients();
  };

  const deleteClient = (product_id) => {
    dispatch(deleteClientsSuppliers(taxable_entity, product_id));
    toggleOpenDeleteModal();
  };

  const columns = useMemo(
    () => [
      {
        header: 'Nombre legal',
        accessorKey: 'legal_name',
        width: 350,
        label: 'Nombre legal',
        cell: ({ renderValue }) => {
          return <p className="list-item-heading">{renderValue() || ''}</p>;
        },
      },
      {
        header: 'RFC',
        accessorKey: 'rfc',
        label: 'RFC',
        cell: ({ renderValue }) => (
          <p className="list-item-heading text-capitalize">
            {renderValue() || ''}
          </p>
        ),
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        label: 'Acciones',
        cell: ({ row }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  size="2xs"
                  color="gray"
                  variant="minimal"
                  squaredPadding
                >
                  <div
                    style={{
                      fontSize: '25px',
                      paddingBottom: '50%',
                    }}
                  >
                    &#x2026;
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  onClick={() => setSelectedClient(row.original)}
                >
                  <Flex align="center">
                    <Edit2OutlineIcon />
                    <Text css={{ mr: '$16', ml: '$12' }}>Editar</Text>
                  </Flex>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    setDeleteItem(row.original.id);
                    toggleOpenDeleteModal();
                  }}
                >
                  <Flex align="center">
                    <TrashCanOutlineIcon />
                    <Text css={{ mr: '$16', ml: '$12' }}>Eliminar</Text>
                  </Flex>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Card css={{ pt: '0' }}>
        {' '}
        <CardHeader
          css={{ padding: '0' }}
          header={
            <Flex
              css={{
                flexDirection: 'column',
                '@sm': {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
              }}
            >
              <Tabs
                value={tab}
                onChange={setTab}
                variant="text"
                indicator="top"
              >
                <Tab id={1}>Clientes</Tab>
                <Tab id={2}>Proveedores</Tab>
              </Tabs>
            </Flex>
          }
        />
        {tab === 1 && (
          <CardContent css={{ pt: '5px' }}>
            <DataTable
              loading={loading}
              noDataText="Sin información."
              defaultPageSize={10}
              data={clients}
              columns={columns}
              dataTableId="clients"
            />
          </CardContent>
        )}
        {tab === 2 && (
          <CardContent css={{ pt: '5px' }}>
            <DataTable
              loading={loading}
              noDataText="Sin información"
              defaultPageSize={10}
              data={suppliers}
              columns={columns}
              dataTableId="suppliers"
            />
          </CardContent>
        )}
      </Card>
      <ClientModalForm
        relationship="client"
        isOpen={!!selectedClient}
        client={selectedClient}
        onSubmit={toggleClients}
        toggle={() => setSelectedClient(null)}
      />
      <ConfirmModal
        title="Eliminar Cliente o Proovedor"
        open={openDeleteModal}
        onAccept={() => deleteClient(deleteItem)}
        onCancel={toggleOpenDeleteModal}
        onClose={toggleOpenDeleteModal}
      >
        ¡Estas a punto de borrar un este cliente o proveedor de tu catálogo!
      </ConfirmModal>
    </>
  );
}
