import { call, put } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  postCFDIFailure,
  postCFDISuccess,
  addPpdComplementSuccess,
} from '../actions';

export default function* ({ payload: { cfdi, navigate, ppdId, callback } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.post,
      '/cfdis/stamp',
      cfdi,
      // { headers: { Authorization: `${access_token}` } }
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: access_token,
        },
      },
    );
    // invoice_type
    if (response.data.invoice_type === 'payment') {
      yield put(addPpdComplementSuccess({ ...response.data, ppdId }));
    }
    if (callback) callback();

    yield call(StatusHandler, {
      response,
      title: 'Factura creada',
      message: 'Factura creada con éxito.',
      status: response.status,
      success: postCFDISuccess,
      failure: postCFDIFailure,
    });
    if (navigate) {
      navigate('/app/invoicing');
    }
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: postCFDIFailure,
      showBodyErrors: true,
    });
  }
}
