import React from 'react';
import { Row, NavLink } from 'reactstrap';
import { Colxx, ImageVideoModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

function NotPlatformIncome() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />

      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p className="font-weight-bold">
            Declaración de ingresos en el extranjero
          </p>
          <p>
            En conformidad a la Ley del Impuesto Sobre la Renta, todas las
            personas físicas y morales están obligadas a pagar ISR conforme su
            residencia en México o en el extranjero.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
      <Row>
        <Colxx md="12">
          <p className="font-weight-bold">
            ¿Cómo se si mis productos llevan IVA?
          </p>
          <p>
            Puede consultar un desglose completo de los requerimientos de IVA en
            el{' '}
            <span>
              {' '}
              <NavLink
                className="pl-0 clickable-text-color font-italic"
                href="https://www.sat.gob.mx/articulo/06071/articulo-2-a"
                target="_blank"
              >
                Artículo 2-A de la Ley del Impuesto al Valor Agregado.
              </NavLink>{' '}
            </span>
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
    </>
  );
}
export default NotPlatformIncome;
