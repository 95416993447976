import {
  FETCH_PAYMENT_METHODS,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILURE,
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAILURE,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAILURE,
  UI_PAYMENT_METHODS_TOOGLE,
  UI_PAYMENT_METHODS_SELECT_CARD,
  DEFAULT_PAYMENT_METHODS,
  DEFAULT_PAYMENT_METHODS_SUCCESS,
  DEFAULT_PAYMENT_METHODS_FAILURE,
  CHARGE_PAYMENT_METHOD,
  CHARGE_PAYMENT_METHOD_SUCCESS,
  CHARGE_PAYMENT_METHOD_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  payment_methods: [],
  loading: false,
  error: null,
  ui: {
    selected_card: null,
    payment_methods_toogle: false,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHODS:
    case ADD_PAYMENT_METHOD:
    case DELETE_PAYMENT_METHOD:
    case DEFAULT_PAYMENT_METHODS:
    case CHARGE_PAYMENT_METHOD:
      return { ...state, loading: true };
    case FETCH_PAYMENT_METHODS_SUCCESS:
    case ADD_PAYMENT_METHOD_SUCCESS:
    case DEFAULT_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        payment_methods: action.payload,
        ui: {
          ...state.ui,
          selected_card: action.payload.find((payment) => payment.default_card),
          payment_methods_toogle: action.payload.length !== 0,
        },
      };
    case DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        payment_methods: state.payment_methods.filter(
          (payment_method) => payment_method.id !== action.payload
        ),
        ui: {
          ...state.ui,
          selected_card:
            state.payment_methods.find(
              (payment) => payment.default_card && payment.id !== action.payload
            ) || null,
          payment_methods_toogle:
            state.payment_methods.filter(
              (payment_method) => payment_method.id !== action.payload
            ).length !== 0,
        },
      };
    case ADD_PAYMENT_METHOD_FAILURE:
    case DELETE_PAYMENT_METHOD_FAILURE:
    case FETCH_PAYMENT_METHODS_FAILURE:
    case DEFAULT_PAYMENT_METHODS_FAILURE:
    case CHARGE_PAYMENT_METHOD_SUCCESS:
    case CHARGE_PAYMENT_METHOD_FAILURE:
      return { ...state, loading: false };
    case UI_PAYMENT_METHODS_SELECT_CARD:
      return {
        ...state,
        ui: { ...state.ui, selected_card: action.payload },
      };
    case UI_PAYMENT_METHODS_TOOGLE:
      return {
        ...state,
        ui: {
          ...state.ui,
          payment_methods_toogle: !state.ui.payment_methods_toogle,
        },
      };
    default:
      return { ...state };
  }
};
