import { styled } from '@konta/ui';

export const modalHeaderCss = {
  fontWeight: '500',
  fontSize: '18px',
  color: '$gray900',
  mr: '0!important',
  justifyContent: 'space-between',
  mb: '$16',
};

export const Title = styled('span', {
  fontFamily: 'Inter',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '38px',
});

export const TopBar = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$8',
  flexDirection: 'column',
  '@md': {
    flexDirection: 'row',
  },
});

export const tabCss = {
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  '&:hover': {
    cursor: 'pointer',
  },
};
export const pointerCss = {
  cursor: 'pointer',
};
export const MetricWrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '34px',
});

export const cellCss = {
  fontWeight: 400,
  color: '$gray500',
};

export const cellUpperCss = {
  fontWeight: 400,
  color: '$gray500',
  textTransform: 'uppercase',
};

export const cellBoldCss = {
  fontWeight: 500,
  color: '$gray900',
  textTransform: 'uppercase',
};

export const cellAmountCss = {
  ...cellCss,
  textAlign: 'right',
};

export const headerCss = {
  color: '$gray500',
  fontWeight: 500,
  textTransform: 'uppercase',
};

export const totalCss = {
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '28px',
};
