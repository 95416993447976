import React from 'react';
import { Button, Divider, Flex, Text } from '@konta/ui';
import classNames from 'classnames/bind';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import styles from './Invoice.module.scss';

const cx = classNames.bind(styles);

export default function Invoice() {
  return (
    <Flex className={styles.root}>
      <Flex className={styles.main}>
        <Flex className={styles.companyContainer}>
          <div className={styles.logo} />
          <Text className={cx('companyText', 'black')}>npm, Inc.</Text>
        </Flex>
        <Flex className={styles.invoiceContainer}>
          <Flex className={cx('card', 'invoiceTop')}>
            <Flex className={styles.invoiceHeader}>
              <Flex className={styles.invoiceHeaderTexts}>
                <Text className={cx('bold')}>Receipt from npm, Inc.</Text>
                <Text className={styles.bigNumber}>$7.00</Text>
                <Text className={cx('bold')}>Paid October 5, 2022</Text>
                <Divider className={styles.divider} />
                <Flex gap={12}>
                  <Button variant="text" leftIcon={<ArrowDownIcon />}>
                    Download Invoice
                  </Button>
                  <Button variant="text" leftIcon={<ArrowDownIcon />}>
                    Download Receipt
                  </Button>
                </Flex>
              </Flex>
              <Flex className={styles.invoiceImg}>
                <img
                  alt="invoice-img"
                  src="https://stripe-images.s3.amazonaws.com/emails/invoices_invoice_illustration.png"
                />
              </Flex>
            </Flex>
            <Flex className={styles.invoiceContent}>
              <Flex direction="column">
                <Text>Receipt number </Text>
                <Text>Invoice number </Text>
                <Text>Payment method </Text>
              </Flex>
              <Flex direction="column" align="end">
                <Text className={cx('black')}>2535-4371</Text>
                <Text className={cx('black')}>779D9D74-0007</Text>
                <Text className={cx('black')}>Mastercard - 9605 </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex className={cx('card', 'invoiceBottom')}>
            <Text className={cx('invoiceNumberText')}>Receipt #2535-4371</Text>
            <Text className={cx('invoiceBillingDate')}>
              OCT 5 – NOV 5, 2022
            </Text>
            <Flex className={cx('invoiceItems')}>
              <Flex className={cx('invoiceItemText')}>
                <Text className={cx('bold', 'black')}>npm-paid-org-7</Text>
                <Text>Qty 1</Text>
              </Flex>
              <Flex>
                <Text className={cx('bold', 'black')}>$7.00</Text>
              </Flex>
            </Flex>
            <Divider className={styles.itemDivider} />
            <Flex className={cx('invoiceItems')}>
              <Text className={cx('bold', 'black')}>Total</Text>
              <Text className={cx('bold', 'black')}>$7.00</Text>
            </Flex>
            <Divider className={styles.itemDivider} />
            <Flex className={cx('invoiceItems')}>
              <Text className={cx('bold', 'black')}>Amount paid</Text>
              <Text className={cx('bold', 'black')}>$7.00 </Text>
            </Flex>
            <Divider className={styles.itemDivider} />
            <div>
              Questions? Visit our support site at{' '}
              <a href="https://www.npmjs.com/support" className={cx('link')}>
                https://www.npmjs.com/support
              </a>{' '}
              or contact us at{' '}
              <a href="mailto:support@npmjs.com" className={cx('link')}>
                support@npmjs.com
              </a>
              .
            </div>
          </Flex>
        </Flex>
        <Flex className={styles.footer}>
          Powered by stripe logo | Learn more about Stripe Billing
        </Flex>
      </Flex>
    </Flex>
  );
}
