import { call, put } from 'redux-saga/effects';
import { ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  uploadFiscalPeriodStatementSuccess,
  uploadFiscalPeriodStatementFailure,
} from '../actions';

export default function* ({ payload: { statement, fiscal_period } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `/fiscal_periods/${fiscal_period.id}/upload_statement`,
      statement,
      { headers: { Authorization: `${access_token}` } },
    );
    if (response.status >= 200 && response.status < 300) {
      yield put(uploadFiscalPeriodStatementSuccess(response.data));
    } else {
      console.log('upload statement fail:', response.data.error);
    }
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      showBodyErrors: error.response.data.errors.upload_statement
        ? error.response.data.errors.upload_statement
        : false,
      message: error.response.data.errors.upload_statement
        ? 'Lamentablemente hubo una intermitencia, recarge la página'
        : null,
      action: uploadFiscalPeriodStatementFailure,
    });
  }
}
