import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { IStoreState } from './types';
import createCustomerSlice from '../modules/customer/store/customerSlice';
import createTaxableEntitySlice from './taxableEntitySlice';
import createTaxesModuleSlice from '../modules/customer/Customer/CustomerTabs/Taxes/taxesModuleSlice';
import createFlowsSlice from '../modules/flows/store/flowsSlice';

const useStore = create<IStoreState>()(
  persist(
    (...a) => ({
      ...createCustomerSlice(...a),
      ...createTaxableEntitySlice(...a),
      ...createTaxesModuleSlice(...a),
      ...createFlowsSlice(...a),
    }),
    {
      name: 'konta-backoffice-store',
      partialize: (state) => ({
        customerHeaderCollapsed: state.customerHeaderCollapsed,
        periodFilterValue: state.periodFilterValue,
        periodFilterType: state.periodFilterType,
      }),
    },
  ),
);

export default useStore;
