import dayjs from 'dayjs';
import Loader from '@components/Loader';
import TaxDelaysHeatmap from 'shared/components/TaxDelaysHeatmap';
import useTaxDelays from 'shared/hooks/useTaxDelays';

export default function Heatmap() {
  const { heatmapData, taxDelaysLoading } = useTaxDelays({
    params: { from: dayjs().subtract(5, 'year').year() },
  });
  const subtitle = `${heatmapData.arrearsCount} con atrasos`;
  if (taxDelaysLoading) {
    return <Loader />;
  }
  return (
    <TaxDelaysHeatmap
      heatmapData={heatmapData}
      subtitle={subtitle}
      tooltipText="Esta gráfica representa los atrasos que existen en las declaraciones en un periodo de dos años"
    />
  );
}
