import dayjs from 'dayjs';
import { getDateObjectWithInvoiceFormat } from './Utils';

export default function getUtcDateByServerDate(
  clientDate: string,
  serverDate: string,
) {
  if (clientDate) {
    const currentUtcDate = clientDate
      ? dayjs(clientDate).format()
      : dayjs().utc().format();

    return getDateObjectWithInvoiceFormat(new Date(currentUtcDate));
  }

  return serverDate?.split?.('.')?.[0] || dayjs().utc().format();
}
