import { useCallback, useMemo } from 'react';
import type { AnnualDeclaration, Workflow } from 'types/entities';
import getSimplifiedDataGridBySatKeys from 'shared/util/getSimplifiedDataGridBySatKeys';
import useFeature from '@hooks/useFeature';
import { NEW_INCOMES_BY_REGIME_GRID } from '@constants/featureFlags';
import type { Range } from 'types/grids';
import useSimplifiedColumnsOld from 'shared/hooks/grids/useSimplifiedColumns';
import useSimplifiedRowsOld from 'shared/hooks/grids/useSimplifiedRows';
import useSimplifiedColumnsNew from './useSimplifiedColumns';
import useSimplifiedRowsNew from './useSimplifiedRows';

interface UseGridOpts {
  satKeys: number[];
  workflows: Workflow[];
  onHandleEntriesView: (monthIdx: string, type: 'income' | 'expense') => void;
  period?: string | number;
  selectedAnnualDeclaration: AnnualDeclaration | null;
  title: string;
}
export default function useGrid({
  satKeys,
  workflows,
  onHandleEntriesView,
  period,
  selectedAnnualDeclaration,
  title,
}: UseGridOpts) {
  const [automationPfaeAndPt] = useFeature(NEW_INCOMES_BY_REGIME_GRID);
  const useSimplifiedColumns = automationPfaeAndPt
    ? useSimplifiedColumnsNew
    : useSimplifiedColumnsOld;

  const columns = useSimplifiedColumns();
  const dataSet = useMemo(
    () =>
      getSimplifiedDataGridBySatKeys({
        satKey: satKeys,
        workflows,
        isForAnnualDeclaration: true,
      }),
    [satKeys, workflows],
  );

  const useSimplifiedRows = automationPfaeAndPt
    ? useSimplifiedRowsNew
    : useSimplifiedRowsOld;

  const rows = useSimplifiedRows({
    dataSet,
    selectedMonth: null,
    title,
    period,
    selectedAnnualDeclaration,
  });

  const onSelectionChanged = useCallback(
    (range: Range[]) => {
      const {
        first: { column, row },
      } = range[0];
      const monthIdx = column.idx - 1;
      const isExpense = row.idx === 2;

      if (monthIdx < 1 || monthIdx > 12 || row.idx < 1 || row.idx > 2) {
        return;
      }
      onHandleEntriesView(`${monthIdx}`, isExpense ? 'expense' : 'income');
    },
    [onHandleEntriesView],
  );

  return { columns, rows, onSelectionChanged };
}
