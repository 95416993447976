import { styled } from '@konta/ui';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid $gray200',
  borderRadius: '8px',
});

export const Controls = styled('div', {
  padding: '$14',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
});

export const ExtraControls = styled('div', {
  padding: '12px 24px',
  borderTop: '1px solid $gray200',
  display: 'flex',
  height: 'auto',
  gap: '$12',
  alignItems: 'start',
  alignSelf: 'stretch',
  flexDirection: 'column',
  '@md': {
    alignItems: 'center',
    flexDirection: 'row',
    gap: '$6',
    height: '50px',
    padding: '0px 24px',
  },
});
