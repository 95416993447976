import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '@components/CustomBootstrap';
import { ProfileFormCard } from '@containers';
import OnboardingCard from '@containers/Onboarding/OnboardingCard';

import { TaskJumbotron } from '@components';
import { useTaxableEntity } from '@hooks';

export function TaskDemo(_) {
  const { taxable_entity } = useTaxableEntity();

  if (
    !taxable_entity.has_fiel ||
    !taxable_entity.has_ciec ||
    !taxable_entity.fiscal_regimes.length > 0 ||
    !taxable_entity.legal_email ||
    !taxable_entity.address
  ) {
    return (
      <Row className="mb-4">
        <Colxx xxs="12" className="mb-6">
          <OnboardingCard />
        </Colxx>
      </Row>
    );
  }
  return (
    <>
      <TaskJumbotron />
      {!taxable_entity.legal_name && (
        <Row>
          <Colxx xxs={12} className="mb-4">
            <ProfileFormCard />
          </Colxx>
        </Row>
      )}
    </>
  );
}
