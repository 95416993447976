import { useCallback } from 'react';
import { QueryOptions, useMutation } from 'react-query';
import patchTaxableEntityPreferences from '@api/patchTaxableEntityPreferences';
import { TaxableEntityPreference } from 'types/entities';

export default function useUpdateTaxableEntityPreferencesMutation(
  taxableEntityId: number,
  options?: QueryOptions<TaxableEntityPreference>,
) {
  const putTaxableEntityPreferenceRequest = useCallback(
    (payload: TaxableEntityPreference) =>
      patchTaxableEntityPreferences({ taxableEntityId, payload }),
    [taxableEntityId],
  );

  return useMutation(putTaxableEntityPreferenceRequest, options);
}
