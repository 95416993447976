/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import { Colxx, SimpleStateButton } from '@components';
import { ReactComponent as FielUploadSvg } from '@assets//img/fiel_upload_v2.svg';

import 'dropzone/dist/min/dropzone.min.css';

class FielDownloadCard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { taxable_entity, activeTab, setActiveTab } = this.props;

    const fielBody = (
      <Row className="d-flex justify-content-between2">
        <Colxx
          md={12}
          lg={4}
          className="mb-4 d-flex align-items-center justify-content-center"
        >
          <FielUploadSvg className="w-60 w-lg-75 w-md-40 w-sm-50" />
        </Colxx>
        <Colxx md={12} lg={8}>
          <h2 className="mb-5">
            Descarga los archivos de tu FIEL (e.firma) para conectar Konta con
            el SAT
          </h2>
          <Row className="d-flex justify-content-between2">
            <Colxx md={6} xxs={12} className="mb-4">
              <a
                href={taxable_entity.key_url}
                download={`FIEL-${taxable_entity.rfc}.key`}
                className="download-link"
              >
                <i className="iconsmind-Download-2 mr-1" />
                Descargar .key
              </a>
            </Colxx>
            <Colxx md={6} xxs={12} className="mb-4">
              <a
                href={taxable_entity.cer_url}
                download={`FIEL-${taxable_entity.rfc}.cer`}
                className="download-link"
              >
                <i className="iconsmind-Download-2 mr-1" />
                Descargar .cer
              </a>
            </Colxx>
          </Row>
          <Row className="mb-4">
            {activeTab && (
              <Colxx
                className="d-flex align-items-center justify-content-center"
                md={{ size: 4, offset: 8 }}
              >
                <SimpleStateButton
                  onClick={() => {
                    setActiveTab(String(+activeTab + 1));
                  }}
                >
                  Continuar
                </SimpleStateButton>
              </Colxx>
            )}
          </Row>
        </Colxx>
      </Row>
    );
    return (
      <>
        {activeTab ? (
          fielBody
        ) : (
          <Card>
            <CardBody>{fielBody}</CardBody>
          </Card>
        )}
      </>
    );
  }
}

FielDownloadCard.propTypes = {
  loading: PropTypes.bool,
  payroll: PropTypes.bool,
  taxable_entity: PropTypes.object,
  uploadTaxableEntityFiel: PropTypes.func,
  uploadTaxableEntityPayrollCredentials: PropTypes.func,
};

const mapStateToProps = ({ taxableEntity }) => ({ ...taxableEntity });

export default connect(mapStateToProps, null)(FielDownloadCard);
