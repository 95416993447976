import { Command } from 'cmdk';
import Loader from 'shared/components/Loader';
import InvoiceTransactionGraph from '@components/InvoiceTransactionGraph';
import type { ClientSupplier } from 'types/entities';
import ClientSupplierItem from './ClientSupplierItem';

interface ClientSupplierListProps {
  clientSuppliersLoading: boolean;
  clientSuppliers: ClientSupplier[];
  modalInvoiceDetails: {
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  } | null;
  closeModalInvoiceDetails: () => void;
  onSelectItem: (data: { rfc: string; legalName: string }) => void;
}

export default function ClientSupplierList({
  clientSuppliersLoading,
  clientSuppliers,
  modalInvoiceDetails,
  closeModalInvoiceDetails,
  onSelectItem,
}: ClientSupplierListProps) {
  return (
    <>
      {!!modalInvoiceDetails && (
        <InvoiceTransactionGraph
          selectedData={modalInvoiceDetails}
          open={!!modalInvoiceDetails}
          toggleOpen={closeModalInvoiceDetails}
        />
      )}
      {clientSuppliersLoading && (
        <Command.Loading>
          <Loader />
        </Command.Loading>
      )}
      {!clientSuppliersLoading &&
        clientSuppliers.map((clientSupplier) => {
          const { id, rfc, legal_name } = clientSupplier;
          const value = `${legal_name || '-'}`;
          return (
            <ClientSupplierItem
              onSelect={() => {
                if (!rfc) return;
                onSelectItem({
                  rfc,
                  legalName: value,
                });
              }}
              clientSupplier={clientSupplier}
              key={`client-supplier-${id}`}
            />
          );
        })}
    </>
  );
}
