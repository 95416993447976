import { all, fork, takeLatest } from 'redux-saga/effects';

import {
  DECLARATION_ENTRIES_FETCH,
  DECLARATION_ENTRIES_CREATE,
  DECLARATION_ENTRIES_DELETE,
  DECLARATION_ENTRIES_UPDATE,
  UPLOAD_CFDI_XML,
  DECLARATION_ENTRIES_BULK_EDIT
} from '@constants/actionTypes';

import fetchSaga from './fetch';
import postSaga from './post';
import deleteSaga from './delete';
import updateSaga from './edit';
import uploadSaga from './upload';
import bulkEdit from './bulk_edit';

export function* watchFetch() {
  yield takeLatest(DECLARATION_ENTRIES_FETCH, fetchSaga);
}

export function* watchCreate() {
  yield takeLatest(DECLARATION_ENTRIES_CREATE, postSaga);
}

export function* watchUpdate() {
  yield takeLatest(DECLARATION_ENTRIES_UPDATE, updateSaga);
}

export function* watchDelete() {
  yield takeLatest(DECLARATION_ENTRIES_DELETE, deleteSaga);
}

export function* watchUploadXml() {
  yield takeLatest(UPLOAD_CFDI_XML, uploadSaga);
}
export function* watchBulkEdit() {
  yield takeLatest(DECLARATION_ENTRIES_BULK_EDIT, bulkEdit);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetch),
    fork(watchCreate),
    fork(watchDelete),
    fork(watchUpdate),
    fork(watchUploadXml),
    fork(watchBulkEdit)
  ]);
}
