import { OnboardingProcessStepType } from 'types/entities';

export enum OnboardingRouteType {
  Welcome = '/app/processes/onboarding/welcome',
  Income = '/app/processes/onboarding/incomes',
  Expenses = '/app/processes/onboarding/expenses',
  Ciec = '/app/processes/onboarding/ciec',
  BookCall = '/app/processes/onboarding/book',
  CallWaiting = '/app/processes/onboarding/call-waiting',
  Feedback = '/app/processes/onboarding/feedback',
  Referrals = '/app/processes/onboarding/referrals',
}

export type OnboardingSteps = {
  [type in OnboardingProcessStepType]: OnboardingRouteType;
};
