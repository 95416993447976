import { ReactNode } from 'react';
import CloseIcon from 'mdi-react/CloseIcon';
import { Button, Flex } from '@konta/ui';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftLineIcon } from '@konta/icons';
import {
  CentralContent,
  Content,
  ContentWrapper,
  LeftSidebar,
  Main,
  TopBar,
  Wrapper,
} from './styled';

export interface FullContentProps {
  content: ReactNode;
  topBar?: ReactNode;
  leftSidebar?: ReactNode;
  withCloseButton?: boolean;
  onClose?: () => void;
  withPb?: boolean;
  closeIcon?: 'close' | 'back';
}

export default function FullContent({
  content,
  leftSidebar,
  topBar,
  withCloseButton = true,
  onClose,
  withPb = false,
  closeIcon = 'close',
}: FullContentProps) {
  const navigate = useNavigate();
  const handleClose = () => {
    if (onClose) {
      onClose();
      return;
    }
    navigate(-1);
  };

  return (
    <Wrapper>
      <Main withPb={withPb}>
        {topBar && (
          <TopBar>
            {withCloseButton && (
              <Button icon variant="text" noFill={closeIcon === 'back'}>
                {closeIcon === 'close' ? (
                  <CloseIcon onClick={handleClose} />
                ) : (
                  <ChevronLeftLineIcon onClick={handleClose} />
                )}
              </Button>
            )}
            <Flex justify="center" css={{ flex: 1 }}>
              {topBar}
            </Flex>
          </TopBar>
        )}
        <ContentWrapper>
          <Content>
            <CentralContent>{content}</CentralContent>
            {leftSidebar && <LeftSidebar>{leftSidebar}</LeftSidebar>}
          </Content>
        </ContentWrapper>
      </Main>
    </Wrapper>
  );
}
