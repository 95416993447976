/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_WITH_GOOGLE_USER = 'LOGIN_WITH_GOOGLE_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const STATE_RESET = 'STATE_RESET';

/* USER */
export const USER_FETCH = 'USER_FETCH';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAILURE = 'USER_FETCH_FAILURE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';
export const USER_UPDATE_OTP_REQUIRED = 'USER_UPDATE_OTP_REQUIRED';
export const USER_UPDATE_OTP_TOKEN = 'USER_UPDATE_OTP_TOKEN';

export const USER_UPDATE_USER_BEGIN = 'USER_UPDATE_USER_BEGIN';
export const USER_UPDATE_USER_FAILURE = 'USER_UPDATE_USER_FAILURE';
export const USER_USER_UPDATE_SUCCESS = 'USER_USER_UPDATE_SUCCESS';

export const ONBOARDING_USER = 'ONBOARDING_USER';
export const ONBOARDING_USER_FAILURE = 'ONBOARDING_USER_FAILURE';
export const ONBOARDING_USER_SUCCESS = 'ONBOARDING_USER_SUCCESS';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

/* TAXABLE ENTITY */
export const TAXABLE_ENTITY_FETCH = 'TAXABLE_ENTITY_FETCH';
export const TAXABLE_ENTITY_FETCH_SUCCESS = 'TAXABLE_ENTITY_FETCH_SUCCESS';
export const TAXABLE_ENTITY_FETCH_FAILURE = 'TAXABLE_ENTITY_FETCH_FAILURE';
export const TAXABLE_ENTITY_UPDATE = 'TAXABLE_ENTITY_UPDATE';
export const TAXABLE_ENTITY_UPDATE_SUCCESS = 'TAXABLE_ENTITY_UPDATE_SUCCESS';
export const TAXABLE_ENTITY_UPDATE_FAILURE = 'TAXABLE_ENTITY_UPDATE_FAILURE';
export const TAXABLE_ENTITY_FIEL_UPLOAD = 'TAXABLE_ENTITY_FIEL_UPLOAD';
export const TAXABLE_ENTITY_FIEL_UPLOAD_SUCCESS =
  'TAXABLE_ENTITY_FIEL_UPLOAD_SUCCESS';
export const TAXABLE_ENTITY_FIEL_UPLOAD_FAILURE =
  'TAXABLE_ENTITY_FIEL_UPLOAD_FAILURE';
export const TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD =
  'TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD';
export const TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_SUCCESS =
  'TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_SUCCESS';
export const TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_FAILURE =
  'TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_FAILURE';
export const TAXABLE_ENTITY_CSD_UPLOAD = 'TAXABLE_ENTITY_CSD_UPLOAD';
export const TAXABLE_ENTITY_CSD_UPLOAD_SUCCESS =
  'TAXABLE_ENTITY_CSD_UPLOAD_SUCCESS';
export const TAXABLE_ENTITY_CSD_UPLOAD_FAILURE =
  'TAXABLE_ENTITY_CSD_UPLOAD_FAILURE';
export const TAXABLE_ENTITY_CREDENTIALS_FETCH =
  'TAXABLE_ENTITY_CREDENTIALS_FETCH';
export const TAXABLE_ENTITY_CREDENTIALS_FETCH_SUCCESS =
  'TAXABLE_ENTITY_CREDENTIALS_FETCH_SUCCESS';
export const TAXABLE_ENTITY_CREDENTIALS_FETCH_FAILURE =
  'TAXABLE_ENTITY_CREDENTIALS_FETCH_FAILURE';

/* PAYMENT METHODS  */
export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';
export const ADD_PAYMENT_METHOD_FAILURE = 'ADD_PAYMENT_METHOD_FAILURE';
export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
export const FETCH_PAYMENT_METHODS_FAILURE = 'FETCH_PAYMENT_METHODS_FAILURE';
export const DEFAULT_PAYMENT_METHODS = 'DEFAULT_PAYMENT_METHODS';
export const DEFAULT_PAYMENT_METHODS_SUCCESS =
  'DEFAULT_PAYMENT_METHODS_SUCCESS';
export const DEFAULT_PAYMENT_METHODS_FAILURE =
  'DEFAULT_PAYMENT_METHODS_FAILURE';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE';
export const UI_PAYMENT_METHODS_TOOGLE = 'UI_PAYMENT_METHODS_TOOGLE';
export const UI_PAYMENT_METHODS_SELECT_CARD = 'UI_PAYMENT_METHODS_SELECT_CARD';
export const UI_PAYMENT_METHODS_DISPLAY_CARDS =
  'UI_PAYMENT_METHODS_DISPLAY_CARDS';
export const CHARGE_PAYMENT_METHOD = 'CHARGE_PAYMENT_METHOD';
export const CHARGE_PAYMENT_METHOD_SUCCESS = 'CHARGE_PAYMENT_METHOD_SUCCESS';
export const CHARGE_PAYMENT_METHOD_FAILURE = 'CHARGE_PAYMENT_METHOD_FAILURE';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_END = 'CHAT_GET_CONTACTS_END';
export const CHAT_GET_CONTACTS_BEGIN = 'CHAT_GET_CONTACTS_BEGIN';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_BEGIN = 'CHAT_GET_CONVERSATIONS_BEGIN';
export const CHAT_GET_CONVERSATIONS_END = 'CHAT_GET_CONVERSATIONS_END';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION_END =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION_END';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION_BEGIN =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION_BEGIN';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION_SUCCESS =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION_SUCCESS';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION_FAILURE =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION_FAILURE';
export const CHAT_LOAD_NEXT_MESSAGES = 'CHAT_LOAD_NEXT_MESSAGES';
export const CHAT_LOAD_NEXT_MESSAGES_END = 'CHAT_LOAD_NEXT_MESSAGES_END';
export const CHAT_LOAD_NEXT_MESSAGES_BEGIN = 'CHAT_LOAD_NEXT_MESSAGES_BEGIN';
export const CHAT_LOAD_NEXT_MESSAGES_SUCCESS = 'CHAT_LOAD_NEXT_MESSAGES_SUCESS';
export const CHAT_LOAD_NEXT_MESSAGES_FAILURE =
  'CHAT_LOAD_NEXT_MESSAGES_FAILURE';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* PULSE */
export const PULSE_FETCH_UNCATEGORIZED_INVOICES =
  'PULSE_FETCH_UNCATEGORIZED_INVOICES';
export const PULSE_FETCH_UNCATEGORIZED_INVOICES_FAILURE =
  'PULSE_FETCH_UNCATEGORIZED_INVOICES_FAILURE';
export const PULSE_FETCH_UNCATEGORIZED_INVOICES_SUCCESS =
  'PULSE_FETCH_UNCATEGORIZED_INVOICES_SUCCESS';

export const PULSE_CATEGORIZE_INVOICE = 'PULSE_CATEGORIZE_INVOICE';
export const PULSE_CATEGORIZE_INVOICE_BEGIN = 'PULSE_CATEGORIZE_INVOICE_BEGIN';
export const PULSE_CATEGORIZE_INVOICE_SUCCESS =
  'PULSE_CATEGORIZE_INVOICE_SUCCESS';
export const PULSE_CATEGORIZE_INVOICE_FAILURE =
  'PULSE_CATEGORIZE_INVOICE_FAILURE';
export const PULSE_CATEGORIZE_INVOICE_END = 'PULSE_CATEGORIZE_INVOICE_END';
export const PULSE_UPDATE_DESCRIPTION_BEGIN = 'PULSE_UPDATE_DESCRIPTION_BEGIN';
export const PULSE_UPDATE_DESCRIPTION_SUCCESS =
  'PULSE_UPDATE_DESCRIPTION_SUCCESS';
export const PULSE_UPDATE_DESCRIPTION_END = 'PULSE_UPDATE_DESCRIPTION_END';
export const PULSE_UPDATE_DESCRIPTION_FAILURE =
  'PULSE_UPDATE_DESCRIPTION_FAILURE';
export const PULSE_ADD_STATEMENT_END = 'PULSE_ADD_STATEMENT_END';
export const PULSE_ADD_STATEMENT_BEGIN = 'PULSE_ADD_STATEMENT_BEGIN';
export const PULSE_ADD_STATEMENT_SUCCESS = 'PULSE_ADD_STATEMENT_SUCCESS';
export const PULSE_ADD_STATEMENT_FAILURE = 'PULSE_ADD_STATEMENT_FAILURE';

// REPORTS
export const REPORTS_FETCH_INCOMES_BEGIN = 'REPORTS_FETCH_INCOMES_BEGIN';
export const REPORTS_FETCH_INCOMES_SUCCESS = 'REPORTS_FETCH_INCOMES_SUCCESS';
export const REPORTS_FETCH_INCOMES_FAILURE = 'REPORTS_FETCH_INCOMES_FAILURE';
export const REPORTS_FETCH_EGRESSES_BEGIN = 'REPORTS_FETCH_EGRESSES_BEGIN';
export const REPORTS_FETCH_EGRESSES_SUCCESS = 'REPORTS_FETCH_EGRESSES_SUCCESS';
export const REPORTS_FETCH_EGRESSES_FAILURE = 'REPORTS_FETCH_FAILURE';
export const REPORTS_FETCH_INCOME_BEGIN = 'REPORTS_FETCH_INCOME_BEGIN';
export const REPORTS_FETCH_INCOME_FAILURE = 'REPORTS_FETCH_INCOME_FAILURE';
export const REPORTS_FETCH_INCOME_SUCCESS = 'REPORTS_FETCH_INCOME_SUCCESS';
export const REPORTS_FETCH_EGRESS_BEGIN = 'REPORTS_FETCH_EGRESS_BEGIN';
export const REPORTS_FETCH_EGRESS_FAILURE = 'REPORTS_FETCH_EGRESS_FAILURE';
export const REPORTS_FETCH_EGRESS_SUCCESS = 'REPORTS_FETCH_EGRESS_SUCCESS';

/* DOCUMENTS */
export const DOCUMENTS_FETCH = 'DOCUMENTS_FETCH';
export const DOCUMENTS_FETCH_SUCCESS = 'DOCUMENTS_FETCH_SUCCESS';
export const DOCUMENTS_FETCH_FAILURE = 'DOCUMENTS_FETCH_FAILURE';
export const DOCUMENTS_POST = 'DOCUMENTS_POST';
export const DOCUMENTS_POST_SUCCESS = 'DOCUMENTS_POST_SUCCESS';
export const DOCUMENTS_POST_FAILURE = 'DOCUMENTS_POST_FAILURE';

/* FISCAL PERIODS */
export const FETCH_FISCAL_PERIOD = 'FETCH_FISCAL_PERIOD';
export const SELECT_FISCAL_PERIOD = 'SELECT_FISCAL_PERIOD';
export const FETCH_FISCAL_PERIOD_SUCCESS = 'FETCH_FISCAL_PERIOD_SUCCESS';
export const FETCH_FISCAL_PERIOD_FAILURE = 'FETCH_FISCAL_PERIOD_FAILURE';
export const FETCH_CURRENT_FISCAL_PERIOD = 'FETCH_CURRENT_FISCAL_PERIOD';
export const FETCH_CURRENT_FISCAL_PERIOD_SUCCESS =
  'FETCH_CURRENT_FISCAL_PERIOD_SUCCESS';
export const FETCH_CURRENT_FISCAL_PERIOD_FAILURE =
  'FETCH_CURRENT_FISCAL_PERIOD_FAILURE';
export const PAY_FISCAL_PERIOD = 'PAY_FISCAL_PERIOD';
export const PAY_FISCAL_PERIOD_SUCCESS = 'PAY_FISCAL_PERIOD_SUCCESS';
export const PAY_FISCAL_PERIOD_FAILURE = 'PAY_FISCAL_PERIOD_FAILURE';
export const UPLOAD_FISCAL_PERIOD_STATEMENT = 'UPLOAD_FISCAL_PERIOD_STATEMENT';
export const UPLOAD_FISCAL_PERIOD_STATEMENT_SUCCESS =
  'UPLOAD_FISCAL_PERIOD_STATEMENT_SUCCESS';
export const UPLOAD_FISCAL_PERIOD_STATEMENT_FAILURE =
  'UPLOAD_FISCAL_PERIOD_STATEMENT_FAILURE';
export const UPDATE_STATUS_FISCAL_PERIOD = 'UPDATE_STATUS_FISCAL_PERIOD';
export const UPDATE_STATUS_FISCAL_PERIOD_SUCCESS =
  'UPDATE_STATUS_FISCAL_PERIOD_SUCCESS';
export const UPDATE_STATUS_FISCAL_PERIOD_FAILURE =
  'UPDATE_STATUS_FISCAL_PERIOD_FAILURE';
export const UPLOAD_FISCAL_PERIOD_RECEIPT = 'UPLOAD_FISCAL_PERIOD_RECEIPT';
export const UPLOAD_FISCAL_PERIOD_RECEIPT_SUCCESS =
  'UPLOAD_FISCAL_PERIOD_RECEIPT_SUCCESS';
export const UPLOAD_FISCAL_PERIOD_RECEIPT_FAILURE =
  'UPLOAD_FISCAL_PERIOD_RECEIPT_FAILURE';

export const POST_FEEDBACK = 'POST_FEEDBACK';
export const POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS';
export const POST_FEEDBACK_FAILURE = 'POST_FEEDBACK_FAILURE';

/* SUBSCRIPTIONS */
export const SUBSCRIBE = 'SUBSCRIBE';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE';
export const SUBSCRIBTION_CANCEL = 'SUBSCRIBTION_CANCEL';
export const SUBSCRIBTION_CANCEL_SUCCESS = 'SUBSCRIBTION_CANCEL_SUCCESS';
export const SUBSCRIBTION_CANCEL_FAILURE = 'SUBSCRIBTION_CANCEL_FAILURE';
export const PAYMENT_HISTORY_FETCH = 'PAYMENT_HISTORY_FETCH';
export const PAYMENT_HISTORY_FETCH_SUCCESS = 'PAYMENT_HISTORY_FETCH_SUCCESS';
export const PAYMENT_HISTORY_FETCH_FAILURE = 'PAYMENT_HISTORY_FETCH_FAILURE';
export const SUBSCRIPTIONS_FETCH = 'SUBSCRIPTIONS_FETCH_FETCH';
export const SUBSCRIPTIONS_FETCH_SUCCESS = 'SUBSCRIPTIONS_FETCH_SUCCESS';
export const SUBSCRIPTIONS_FETCH_FAILURE = 'SUBSCRIPTIONS_FETCH_FAILURE';

//  SUBSCRIPTIONS UI
export const SUBSCRIPTION_SELECT = 'SUBSCRIPTION_SELECT';
export const DISPLAY_SUBSCRIPTION_PRICES = 'DISPLAY_SUBSCRIPTION_PRICES';
export const SUBSCRIPTION_DISCOUNT = 'SUBSCRIPTION_DISCOUNT';

/* ADDRESS */
export const ADDRESS_FETCH = 'ADDRESS_FETCH';
export const ADDRESS_FETCH_SUCCESS = 'ADDRESS_FETCH_SUCCESS';
export const ADDRESS_FETCH_FAILURE = 'ADDRESS_FETCH_FAILURE';
export const ADDRESS_UPDATE = 'ADDRESS_UPDATE';
export const ADDRESS_UPDATE_SUCCESS = 'ADDRESS_UPDATE_SUCCESS';
export const ADDRESS_UPDATE_FAILURE = 'ADDRESS_UPDATE_FAILURE';

/* FINANICAL_TRANSACTIONS */
export const FINANCIAL_TRANSACTIONS_FETCH = 'FINANCIAL_TRANSACTIONS_FETCH';
export const FINANCIAL_TRANSACTIONS_FETCH_SUCCESS =
  'FINANCIAL_TRANSACTIONS_FETCH_SUCCESS';
export const FINANCIAL_TRANSACTIONS_FETCH_FAILURE =
  'FINANCIAL_TRANSACTIONS_FETCH_FAILURE';

/* ORDERS */
export const ORDERS_FETCH = 'ORDERS_FETCH';
export const ORDERS_FETCH_SUCCESS = 'ORDERS_FETCH_SUCCESS';
export const ORDERS_FETCH_FAILURE = 'ORDERS_FETCH_FAILURE';
export const ORDER_PAY = 'ORDER_PAY';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAILURE = 'ORDER_PAY_FAILURE';

/* CLIENTS/SUPPLIERS */
export const CLIENTS_SUPPLIERS_FETCH = 'CLIENTS_SUPPLIERS_FETCH';
export const CLIENTS_SUPPLIERS_FETCH_SUCCESS =
  'CLIENTS_SUPPLIERS_FETCH_SUCCESS';
export const CLIENTS_SUPPLIERS_FETCH_FAILURE =
  'CLIENTS_SUPPLIERS_FETCH_FAILURE';
export const CLIENTS_SUPPLIERS_POST = 'CLIENTS_SUPPLIERS_POST';
export const CLIENTS_SUPPLIERS_POST_SUCCESS = 'CLIENTS_SUPPLIERS_POST_SUCCESS';
export const CLIENTS_SUPPLIERS_POST_FAILURE = 'CLIENTS_SUPPLIERS_POST_FAILURE';
export const CLIENTS_SUPPLIERS_EDIT = 'CLIENTS_SUPPLIERS_EDIT';
export const CLIENTS_SUPPLIERS_EDIT_SUCCESS = 'CLIENTS_SUPPLIERS_EDIT_SUCCESS';
export const CLIENTS_SUPPLIERS_EDIT_FAILURE = 'CLIENTS_SUPPLIERS_EDIT_FAILURE';
export const CLIENTS_SUPPLIERS_DELETE = 'CLIENTS_SUPPLIERS_DELETE';
export const CLIENTS_SUPPLIERS_DELETE_SUCCESS =
  'CLIENTS_SUPPLIERS_DELETE_SUCCESS';
export const CLIENTS_SUPPLIERS_DELETE_FAILURE =
  'CLIENTS_SUPPLIERS_DELETE_FAILURE';

/* PRODUCTS */
export const PRODUCTS_FETCH = 'PRODUCTS_FETCH';
export const PRODUCTS_FETCH_SUCCESS = 'PRODUCTS_FETCH_SUCCESS';
export const PRODUCTS_FETCH_FAILURE = 'PRODUCTS_FETCH_FAILURE';
export const PRODUCTS_POST = 'PRODUCTS_POST';
export const PRODUCTS_POST_SUCCESS = 'PRODUCTS_POST_SUCCESS';
export const PRODUCTS_POST_FAILURE = 'PRODUCTS_POST_FAILURE';
export const PRODUCTS_EDIT = 'PRODUCTS_EDIT';
export const PRODUCTS_EDIT_SUCCESS = 'PRODUCTS_EDIT_SUCCESS';
export const PRODUCTS_EDIT_FAILURE = 'PRODUCTS_EDIT_FAILURE';
export const PRODUCTS_DELETE = 'PRODUCTS_DELETE';
export const PRODUCTS_DELETE_SUCCESS = 'PRODUCTS_DELETE_SUCCESS';
export const PRODUCTS_DELETE_FAILURE = 'PRODUCTS_DELETE_FAILURE';

/* CFDI */
export const CFDI_XML_FETCH = 'CFDI_XML_FETCH';
export const CFDI_XML_FETCH_SUCCESS = 'CFDI_XML_FETCH_SUCCESS';
export const CFDI_XML_FETCH_FAILURE = 'CFDI_XML_FETCH_FAILURE';

export const CFDIS_FETCH = 'CFDIS_FETCH';
export const CFDIS_FETCH_SUCCESS = 'CFDIS_FETCH_SUCCESS';
export const CFDIS_FETCH_FAILURE = 'CFDIS_FETCH_FAILURE';

export const CFDI_CANCEL = 'CFDI_CANCEL';
export const CFDI_CANCEL_SUCCESS = 'CFDI_CANCEL_SUCCESS';
export const CFDI_CANCEL_FAILURE = 'CFDI_CANCEL_FAILURE';

export const CFDI_POST = 'CFDI_POST';
export const CFDI_POST_FAILURE = 'CFDI_POST_FAILURE';
export const CFDI_POST_SUCCESS = 'CFDI_POST_SUCCESS';

export const CFDIS_FETCH_BY_CLIENT = 'CFDIS_FETCH_BY_CLIENT';
export const CFDIS_FETCH_BY_CLIENT_SUCCESS = 'CFDIS_FETCH_BY_CLIENT_SUCCESS';
export const CFDIS_FETCH_BY_CLIENT_FAILURE = 'CFDIS_FETCH_BY_CLIENT_FAILURE';

export const CFDIS_FETCH_BY_PPDS = 'CFDIS_FETCH_BY_PPDS';
export const CFDIS_FETCH_BY_PPDS_SUCCESS = 'CFDIS_FETCH_BY_PPDS_SUCCESS';
export const CFDIS_FETCH_BY_PPDS_FAILURE = 'CFDIS_FETCH_BY_PPDS_FAILURE';

export const GET_COMPLEMENTS = 'GET_COMPLEMENTS';
export const GET_COMPLEMENTS_SUCCESS = 'GET_COMPLEMENTS_SUCCESS';
export const GET_COMPLEMENTS_FAILURE = 'GET_COMPLEMENTS_FAILURE';

export const CFDI_PPD_COMPLEMENT_POST = 'CFDI_PPD_COMPLEMENT_POST';
export const CFDI_PPD_COMPLEMENT_POST_SUCCESS =
  'CFDI_PPD_COMPLEMENT_POST_SUCCESS';
export const CFDI_PPD_COMPLEMENT_POST_FAILURE =
  'CFDI_PPD_COMPLEMENT_POST_FAILURE';

export const CFDI_PPD_COMPLEMENT_DELETE = 'CFDI_PPD_COMPLEMENT_DELETE';
export const CFDI_PPD_COMPLEMENT_DELETE_SUCCESS =
  'CFDI_PPD_COMPLEMENT_DELETE_SUCCESS';
export const CFDI_PPD_COMPLEMENT_DELETE_FAILURE =
  'CFDI_PPD_COMPLEMENT_DELETE_FAILURE';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

/* UI */

/* TIME */
export const UI_SET_DATE = 'UI_SET_DATE';
export const UI_CHANGE_DATE = 'UI_CHANGE_DATE';

export const UI_SET_DATE_CFDIS = 'UI_SET_DATE_CFDIS';
export const UI_CHANGE_DATE_CFDIS = 'UI_CHANGE_DATE_CFDIS';

export const ADD_TO_CART = 'ADD_TO_CART';
export const CLEAR_CART = 'CLEAR_CART';

/* CONTACT METHODS */

export const CONTACT_METHOD_FETCH = 'CONTACT_METHOD_FETCH';
export const CONTACT_METHOD_FETCH_SUCCESS = 'CONTACT_METHOD_FETCH_SUCCESS';
export const CONTACT_METHOD_FETCH_FAILURE = 'CONTACT_METHOD_FETCH_FAILURE';

export const CONTACT_METHOD_UPDATE = 'CONTACT_METHOD_UPDATE';
export const CONTACT_METHOD_UPDATE_SUCCESS = 'CONTACT_METHOD_UPDATE_SUCCESS';
export const CONTACT_METHOD_UPDATE_FAILURE = 'CONTACT_METHOD_UPDATE_FAILURE';

export const CONTACT_METHOD_DELETE = 'CONTACT_METHOD_DELETE';
export const CONTACT_METHOD_DELETE_SUCCESS = 'CONTACT_METHOD_DELETE_SUCCESS';
export const CONTACT_METHOD_DELETE_FAILURE = 'CONTACT_METHOD_DELETE_FAILURE';

export const CONTACT_METHOD_POST = 'CONTACT_METHOD_POST';
export const CONTACT_METHOD_POST_SUCCESS = 'CONTACT_METHOD_POST_SUCCESS';
export const CONTACT_METHOD_POST_FAILURE = 'CONTACT_METHOD_POST_FAILURE';

/*  NOTIFICATION PREFERENCES  */

export const NOTIFICATION_PREFERENCES_FETCH = 'NOTIFICATION_PREFERENCES_FETCH';
export const NOTIFICATION_PREFERENCES_FETCH_SUCCESS =
  'NOTIFICATION_PREFERENCES_FETCH_SUCCESS';
export const NOTIFICATION_PREFERENCES_FETCH_FAILURE =
  'NOTIFICATION_PREFERENCES_FETCH_FAILURE';

export const NOTIFICATION_PREFERENCES_UPDATE =
  'NOTIFICATION_PREFERENCES_UPDATE';
export const NOTIFICATION_PREFERENCES_UPDATE_SUCCESS =
  'NOTIFICATION_PREFERENCES_UPDATE_SUCCESS';
export const NOTIFICATION_PREFERENCES_UPDATE_FAILURE =
  'NOTIFICATION_PREFERENCES_UPDATE_FAILURE';

/* FISCAL REGIMENS */

export const FISCAL_REGIMENS_FETCH = 'FISCAL_REGIMENS_FETCH';
export const FISCAL_REGIMENS_FETCH_SUCCESS = 'FISCAL_REGIMENS_FETCH_SUCCESS';
export const FISCAL_REGIMENS_FETCH_FAILURE = 'FISCAL_REGIMENS_FETCH_FAILURE';

/* WORKFLOWS */

export const WORKFLOWS_FETCH = 'WORKFLOWS_FETCH';
export const WORKFLOWS_FETCH_SUCCESS = 'WORKFLOWS_FETCH_SUCCESS';
export const WORKFLOWS_FETCH_FAILURE = 'WORKFLOWS_FETCH_FAILURE';

export const SHOW_WORKFLOW_FETCH = 'SHOW_WORKFLOW_FETCH';
export const SHOW_WORKFLOW_FETCH_SUCCESS = 'SHOW_WORKFLOW_FETCH_SUCCESS';
export const SHOW_WORKFLOW_FETCH_FAILURE = 'SHOW_WORKFLOW_FETCH_FAILURE';

export const WORKFLOW_UPDATE = 'WORKFLOW_UPDATE';
export const WORKFLOW_UPDATE_SUCCESS = 'WORKFLOW_UPDATE_SUCCESS';
export const WORKFLOW_UPDATE_FAILURE = 'WORKFLOW_UPDATE_FAILURE';
export const SET_WORKFLOW_PHASE_TRANSITION = 'SET_WORKFLOW_PHASE_TRANSITION';

export const WORKFLOW_PREVIOUS_PHASE = 'WORKFLOW_PREVIOUS_PHASE';
export const WORKFLOW_PREVIOUS_PHASE_SUCCESS =
  'WORKFLOW_PREVIOUS_PHASE_SUCCESS';
export const WORKFLOW_PREVIOUS_PHASE_FAILURE =
  'WORKFLOW_PREVIOUS_PHASE_FAILURE';

export const WORKFLOW_NEXT_PHASE = 'WORKFLOW_NEXT_PHASE';
export const WORKFLOW_NEXT_PHASE_SUCCESS = 'WORKFLOW_NEXT_PHASE_SUCCESS';
export const WORKFLOW_NEXT_PHASE_FAILURE = 'WORKFLOW_NEXT_PHASE_FAILURE';

/* PLATFORM_ENTRIES */
export const DECLARATION_ENTRIES_FETCH = 'DECLARATION_ENTRIES_FETCH';
export const DECLARATION_ENTRIES_FETCH_SUCCESS =
  'DECLARATION_ENTRIES_FETCH_SUCCESS';
export const DECLARATION_ENTRIES_FETCH_FAILURE =
  'DECLARATION_ENTRIES_FETCH_FAILURE';
export const DECLARATION_ENTRIES_CREATE = 'DECLARATION_ENTRIES_CREATE';
export const DECLARATION_ENTRIES_CREATE_SUCCESS =
  'DECLARATION_ENTRIES_CREATE_SUCCESS';
export const DECLARATION_ENTRIES_CREATE_FAILURE =
  'DECLARATION_ENTRIES_CREATE_FAILURE';
export const DECLARATION_ENTRIES_UPDATE = 'DECLARATION_ENTRIES_UPDATE';
export const DECLARATION_ENTRIES_UPDATE_SUCCESS =
  'DECLARATION_ENTRIES_UPDATE_SUCCESS';
export const DECLARATION_ENTRIES_UPDATE_FAILURE =
  'DECLARATION_ENTRIES_UPDATE_FAILURE';
export const DECLARATION_ENTRIES_DELETE = 'DECLARATION_ENTRIES_DELETE';
export const DECLARATION_ENTRIES_DELETE_SUCCESS =
  'DECLARATION_ENTRIES_DELETE_SUCCESS';
export const DECLARATION_ENTRIES_DELETE_FAILURE =
  'DECLARATION_ENTRIES_DELETE_FAILURE';
export const UPLOAD_CFDI_XML = 'UPLOAD_CFDI_XML';
export const UPLOAD_CFDI_XML_SUCCESS = 'UPLOAD_CFDI_XML_SUCCESS';
export const UPLOAD_CFDI_XML_FAILURE = 'UPLOAD_CFDI_XML_FAILURE';
export const DECLARATION_ENTRIES_BULK_EDIT = 'DECLARATION_ENTRIES_BULK_EDIT';
export const DECLARATION_ENTRIES_BULK_EDIT_SUCCESS =
  'DECLARATION_ENTRIES_BULK_EDIT_SUCCESS';
export const DECLARATION_ENTRIES_BULK_EDIT_FAILURE =
  'DECLARATION_ENTRIES_BULK_EDIT_FAILURE';

/* BELVO */
export const BELVO_TOKEN_GET = 'BELVO_TOKEN_GET';
export const BELVO_TOKEN_GET_SUCCESS = 'BELVO_TOKEN_GET_SUCCESS';
export const BELVO_TOKEN_GET_FAILURE = 'BELVO_TOKEN_GET_FAILURE';

export const BELVO_ORPHAN_LINKS_FETCH = 'BELVO_ORPHAN_LINKS_FETCH';
export const BELVO_ORPHAN_LINKS_FETCH_SUCCESS =
  'BELVO_ORPHAN_LINKS_FETCH_SUCCESS';
export const BELVO_ORPHAN_LINKS_FETCH_FAILURE =
  'BELVO_ORPHAN_LINKS_FETCH_FAILURE';

export const BELVO_BANK_ACCOUNTS_FETCH = 'BELVO_BANK_ACCOUNTS_FETCH';
export const BELVO_BANK_ACCOUNTS_FETCH_SUCCESS =
  'BELVO_BANK_ACCOUNTS_FETCH_SUCCESS';
export const BELVO_BANK_ACCOUNTS_FETCH_FAILURE =
  'BELVO_BANK_ACCOUNTS_FETCH_FAILURE';

/* BELVO LINKS */

export const BELVO_LINKS_CONNECTION_CREATE = 'BELVO_LINKS_CONNECTION_CREATE';
export const BELVO_LINKS_CONNECTION_CREATE_SUCCESS =
  'BELVO_LINKS_CONNECTION_CREATE_SUCCESS';
export const BELVO_LINKS_CONNECTION_CREATE_FAILURE =
  'BELVO_LINKS_CONNECTION_CREATE_FAILURE';

export const BELVO_LINKS_CONNECTION_DELETE = 'BELVO_LINKS_CONNECTION_DELETE';
export const BELVO_LINKS_CONNECTION_DELETE_SUCCESS =
  'BELVO_LINKS_CONNECTION_DELETE_SUCCESS';
export const BELVO_LINKS_CONNECTION_DELETE_FAILURE =
  'BELVO_LINKS_CONNECTION_DELETE_FAILURE';

export const BELVO_LINKS_FETCH = 'BELVO_LINKS_FETCH';
export const BELVO_LINKS_FETCH_SUCCESS = 'BELVO_LINKS_FETCH_SUCCESS';
export const BELVO_LINKS_FETCH_FAILURE = 'BELVO_LINKS_FETCH_FAILURE';

/* PLATFORM_ENTRIES */
export const BANK_TRANSACTIONS_DECLARATIONS_FETCH =
  'BANK_TRANSACTIONS_DECLARATIONS_FETCH';
export const BANK_TRANSACTIONS_DECLARATIONS_FETCH_SUCCESS =
  'BANK_TRANSACTIONS_DECLARATIONS_FETCH_SUCCESS';
export const BANK_TRANSACTIONS_DECLARATIONS_FETCH_FAILURE =
  'BANK_TRANSACTIONS_DECLARATIONS_FETCH_FAILURE';
export const BANK_TRANSACTIONS_DECLARATIONS_UPDATE =
  'BANK_TRANSACTIONS_DECLARATIONS_UPDATE';
export const BANK_TRANSACTIONS_DECLARATIONS_UPDATE_SUCCESS =
  'BANK_TRANSACTIONS_DECLARATIONS_UPDATE_SUCCESS';
export const BANK_TRANSACTIONS_DECLARATIONS_UPDATE_FAILURE =
  'BANK_TRANSACTIONS_DECLARATIONS_UPDATE_FAILURE';
export const BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE =
  'BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE';
export const BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_SUCCESS =
  'BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_SUCCESS';
export const BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_FAILURE =
  'BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE_FAILURE';

/* HANDLE ERRORS */
export const CATCH_NEW_ERROR = 'CATCH_NEW_ERROR';
