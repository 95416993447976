import { useMemo } from 'react';
import regimeToOption from '@util/regimeToOption';
import useFiscalRegimesQuery from './useFiscalRegimesQuery';

export default function useFiscalRegimeOptions({
  availableRegimes,
  excludedRegimes,
} = {}) {
  const query = useFiscalRegimesQuery();

  // TODO: move filters to "useFiscalRegimesQuery"
  const regimes = useMemo(() => {
    let filtered = query.regimes;

    if (availableRegimes) {
      filtered = filtered.filter((regime) => {
        const includesSatKey = availableRegimes.find((item) => {
          return item === regime.sat_key || item.sat_key === regime.sat_key;
        });
        const includesId = availableRegimes.find((item) => {
          return item === regime.id || item.id === regime.id;
        });

        return includesId || includesSatKey;
      });
    }

    if (excludedRegimes) {
      filtered = filtered.filter((regime) => {
        const includesSatKey = excludedRegimes.find((item) => {
          return item === regime.sat_key || item.sat_key === regime.sat_key;
        });
        const includesId = excludedRegimes.find((item) => {
          return item === regime.id || item.id === regime.id;
        });

        return !includesId && !includesSatKey;
      });
    }

    return filtered;
  }, [query.regimes, availableRegimes, excludedRegimes]);

  const regimeOptions = useMemo(() => {
    return regimes.map(regimeToOption);
  }, [regimes]);

  return { ...query, regimes, regimeOptions };
}
