import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardTitle, CardBody, Label } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import IntlMessages from '@util/IntlMessages';
import { Colxx, SimpleStateButton, CustomSelect } from '@components';
import DropzoneComponent from 'react-dropzone-component';
import { postDocs } from '@redux/actions';
import { dropzoneConfig, dropzoneComponentConfig } from './dropzone';

class DocumentsUpload extends Component {
  state = {
    dropzone: null,
    files: [],
    selectedCategory: null,
    isDirty: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.documents.length !== this.props.documents.length) {
      this.state.dropzone.removeAllFiles();
    }
  }

  handleAdd = (file) => {
    const { files } = this.state;
    files.push(file);
    this.setState({ files });
  };

  handleRemove = (file) => {
    const { files } = this.state;
    files.forEach((f, i) => {
      if (f.name === file.name && f.size === file.size) {
        files.splice(i, 1);
      }
    });
    this.setState({ files });
  };
  
  sendDocuments = (_) => {
    if (this.state.selectedCategory)
      this.props.postDocs(this.state.files, this.state.selectedCategory.key);
  };

  handleSubmit = (event, values, errors) => {
    this.setState({
      isDirty: true,
    });
  };

  handleCategoryChange = (selectedCategory) => {
    this.setState({ selectedCategory });
  };

  render() {
    const { isDirty } = this.state;
    const eventHandlers = {
      init: (dropzone) => {
        this.setState({ dropzone });
      },
      addedfile: this.handleAdd,
      maxfilesexceeded: this.handleMaxFilesExceeded,
      removedfile: this.handleRemove,
    };
    const categories = [
      {
        label: 'Documentos fiscales',
        value: 'legal_documents',
        key: 2,
      },
      {
        label: 'Constancía de retención',
        value: 'retention_certificate',
        key: 4,
      },
      {
        label: 'Pedimentos de importación',
        value: 'import_pediments',
        key: 5,
      },
      { label: 'Facturas del extranjero', value: 'abroad_billings', key: 6 },
      { label: 'Otros', value: 'others', key: 7 },
    ];
    const { loading } = this.props;
    const { files, selectedCategory } = this.state;

    return (
      <Card>
        <CardBody>
          <CardTitle tag="h4">
            <IntlMessages id="documents.tabs.upload" />
          </CardTitle>
          <AvForm
            onSubmit={this.handleSubmit}
            onValidSubmit={this.sendDocuments}
          >
            <Row className="mb-2">
              <Colxx sm="12">
                <p className="text-muted ">
                  En este apartado puedes subir tus documentos.
                </p>
                <DropzoneComponent
                  config={dropzoneComponentConfig}
                  eventHandlers={eventHandlers}
                  djsConfig={dropzoneConfig}
                />
              </Colxx>
            </Row>
            <Row>
              <Colxx lg={6} className="mb-3">
                <Label className="av-label" for="category_id">
                  Categoría de documentos
                </Label>
                <CustomSelect
                  name="category_id"
                  value={selectedCategory}
                  onChange={this.handleCategoryChange}
                  options={categories}
                  isDirty={isDirty}
                />
              </Colxx>
            </Row>
            <Row>
              <Colxx className="d-flex justify-content-center p-4">
                <SimpleStateButton
                  color="primary"
                  loading={loading}
                  disabled={!files.length}
                >
                  Subir documentos
                </SimpleStateButton>
              </Colxx>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ documents }) => {
  return { ...documents };
};

export default connect(mapStateToProps, {
  postDocs,
})(DocumentsUpload);
