import dayjs from 'dayjs';
import type { CaptureLineDocument, Declaration } from 'types/entities';
import getRegimeNameFromFiscalRegime from 'shared/util/getRegimeNameFromFiscalRegime';
import camelcaseKeys from 'camelcase-keys';
import isValidationDateValid from './isValidationDateValid';

function getValidationDate(date?: string) {
  return dayjs(date).isValid() ? dayjs(date).format('DD-MM-YYYY') : '';
}

export default function getDocumentFromDeclaration(
  declaration: Declaration,
  hasSalaryRegime?: boolean,
): CaptureLineDocument | null {
  const declarationDocument = declaration.declaration_document;
  const regimeName = hasSalaryRegime
    ? 'Sueldos y salarios'
    : getRegimeNameFromFiscalRegime(declaration.fiscal_regime);
  const documentType = hasSalaryRegime ? 'salary_' : '';

  if (!declarationDocument) {
    return null;
  }
  const documentUrl = declarationDocument[`${documentType}document`] || '';
  const validationDate = declarationDocument[`${documentType}validation_date`];
  const captureLine = declarationDocument[`${documentType}capture_line`] || '';

  if (declarationDocument && documentUrl) {
    const isValid = isValidationDateValid(validationDate);
    const paymentLimitDate = dayjs(validationDate)
      .subtract(1, 'day')
      .format('DD-MM-YYYY');
    const { totalPayrollWithholdingsToPay, totalToPay } =
      camelcaseKeys(declaration);
    const hasNoDebt =
      isValid &&
      (hasSalaryRegime
        ? +totalPayrollWithholdingsToPay === 0
        : +totalToPay === 0);
    const hasDebt = isValid && !hasNoDebt;
    const grandTotalToPay = hasSalaryRegime
      ? +totalPayrollWithholdingsToPay
      : +totalToPay - +totalPayrollWithholdingsToPay;

    return {
      validationDate: getValidationDate(validationDate),
      captureLine,
      documentUrl,
      regimeName,
      isValid,
      hasNoDebt,
      hasDebt,
      totalToPay: grandTotalToPay,
      paymentLimitDate,
    };
  }

  return null;
}
