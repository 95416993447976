import React from 'react';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import IntlMessages from '@util/IntlMessages';
import { LinkVideoHelper } from '@components';

export function TabLink({ tabName, toLink = '#', navLinkPill = true }) {
  return (
    <NavItem>
      <NavLink
        end
        className={classnames({
          'nav-link': navLinkPill,
        })}
        style={{ cursor: 'pointer' }}
        to={toLink}
      >
        <IntlMessages id={tabName} />
      </NavLink>
    </NavItem>
  );
}

export function LinkList({
  tabs = [],
  links = [],
  base,
  current,
  videoHelper = false,
  videoID,
}) {
  return (
    <>
      {links.map((link, index) => {
        const tab = tabs[index];
        const toLink = `${base}${link.length ? `/${link}` : ''}`;
        const active = toLink === current;
        return (
          <TabLink key={tab} tabName={tab} toLink={toLink} active={active} />
        );
      })}
      {videoHelper && (
        <NavItem>
          <LinkVideoHelper
            label="Ver video"
            videoId={videoID}
            tooltipHelper="Ver video"
            iconId="VideoHelper-1"
            className="position-absolute btn-datepicker pr-0"
          />
        </NavItem>
      )}
    </>
  );
}
