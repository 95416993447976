import type { HeaderContext } from '@tanstack/react-table';
import type { DeclarationEntry } from 'types/entities';
import { getFooterValue } from './values';
import { CurrencyCell } from '../table/cells';
import type { CurrencyType, SourceType } from './types';

export const getCurrencyFooter = (source: SourceType, type: CurrencyType) =>
  function Footer({ table }: HeaderContext<DeclarationEntry, unknown>) {
    const value = getFooterValue(table, source, type);

    return <CurrencyCell isFooter value={value} />;
  };
