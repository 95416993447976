import { Card, CardContent, Flex, Text } from '@konta/ui';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowsLayout from '@components/WorkflowsLayout';
import SecondaryPhaseButton from '@components/SecondaryPhaseButton';
import NextPhaseConfirmation from '@components/NextPhaseConfirmation';
import toCurrency from '@util/toCurrency';
import { InfoCircleLineIcon } from '@konta/icons';
import {
  Alert,
  AlertContent,
  AlertText,
  AlertDescription,
  Feature,
  Header,
  Root,
  Title,
} from '@components/Workflow/styled';
import useCaptureLinePayment from './useCaptureLinePayment';

const secondaryPayload = {
  workflow: {
    next_phase: 'secondary_phase',
  },
};

export default function CaptureLinePayment() {
  const {
    declarationsDocuments,
    hasAnyExpired,
    hasAnyDebt,
    hasNoDebts,
    primaryButtonProps,
    getValidityInfo,
    getCaptureLineInfo,
    grandTotalToPay,
    isLoading,
    getPaymentValidityInfo,
  } = useCaptureLinePayment();

  return (
    <WorkflowsLayout
      isLoading={isLoading}
      actions={
        <>
          <NextPhaseConfirmation
            payload={secondaryPayload}
            // eslint-disable-next-line react/no-unstable-nested-components
            trigger={({ onClick }) => (
              <SecondaryPhaseButton onClick={onClick}>
                Pagar por mi banco
              </SecondaryPhaseButton>
            )}
            title="Confirmación de pago por banco"
            acceptText="Confirmar"
          >
            <div className="m-auto d-flex flex-column justify-content-center text-center">
              <i className="large-icon iconsmind-Danger" color="danger" />
              <p>
                Al dar click en &quot;Confirmar&quot; te saltarás el pago por la
                plataforma y finalizarás tu declaración este mes.
              </p>
            </div>
          </NextPhaseConfirmation>
          <PrimaryPhaseButton {...primaryButtonProps} />
        </>
      }
      title="Declaración mensual"
    >
      <Root>
        <Header>
          <Title>Pagar Declaración</Title>
          <Feature>
            En esta etapa, tienes la opción de elegir cómo deseas efectuar el
            pago de tus declaraciones: mediante una línea de captura o a través
            de tu entidad bancaria.
          </Feature>
        </Header>
        <Flex column gap={12}>
          <Feature>
            {hasAnyExpired &&
              '¡Su línea de captura está vencida! Para generar una nueva y volver calcular sus impuestos con sus atrasos, debe solicitar una nueva línea de captura.'}
            {hasNoDebts && '¡Felicidades! No hay nada por pagar 🥳'}
            {hasAnyDebt &&
              '¿Lo pagarías por tarjeta o te encargas de pagarlo a través de tu banco?'}
          </Feature>
          <Flex>
            <Alert mt2>
              <InfoCircleLineIcon />
              <AlertContent>
                <AlertText>Fecha límite de pago en la plataforma:</AlertText>
                <AlertDescription>
                  Recuerda, para poder realizar el pago de tus impuestos desde
                  nuestra plataforma, debes hacerlo 1 día antes del vencimiento
                  de la línea de captura, es decir, si vence el 29, tienes plazo
                  hasta el 28. Sin embargo, en tu banca electrónica puedes hacer
                  el pago hasta el último día de vencimiento de esta.
                </AlertDescription>
              </AlertContent>
            </Alert>
          </Flex>
          <Flex direction="column" gap={12}>
            {grandTotalToPay > 0 && (
              <p>
                El importe a cargo determinado en esta declaración, deberá ser
                pagado en las instituciones de crédito autorizadas, utilizando
                para tal efecto la línea de captura que se indica.
              </p>
            )}

            {declarationsDocuments.map(
              ({
                isValid,
                captureLine,
                totalToPay,
                validationDate,
                regimeName,
                paymentLimitDate,
              }) => {
                const captureLineInfo = getCaptureLineInfo(
                  isValid,
                  captureLine,
                );
                const validityInfo = getValidityInfo(
                  isValid,
                  captureLine,
                  validationDate,
                );
                const validityPaymentInfo = getPaymentValidityInfo(
                  isValid,
                  captureLine,
                  paymentLimitDate,
                );
                return (
                  <Card key="regimeName">
                    <CardContent
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 12,
                      }}
                    >
                      <Flex gap={12}>
                        <Text bold>Régimen: </Text> <Text>{regimeName}</Text>
                      </Flex>
                      <Flex gap={12}>
                        <Text bold>Línea de captura: </Text>{' '}
                        <Text color={captureLineInfo.textColor}>
                          {captureLineInfo.textContent}
                        </Text>
                      </Flex>
                      <Flex gap={12}>
                        <Text bold>Importe total a pagar: </Text>{' '}
                        <Text bold>{toCurrency(totalToPay)}</Text>
                      </Flex>
                      <Flex gap={12}>
                        <Text bold>Vigente hasta: </Text>{' '}
                        <Text color={validityInfo.textColor}>
                          {validityInfo.textContent}
                        </Text>
                      </Flex>
                      <Flex gap={12}>
                        <Text bold>
                          Fecha límite de pago en la plataforma:{' '}
                        </Text>{' '}
                        <Text color={validityPaymentInfo.textColor}>
                          {validityPaymentInfo.textContent}
                        </Text>
                      </Flex>
                    </CardContent>
                  </Card>
                );
              },
            )}
          </Flex>
        </Flex>
      </Root>
    </WorkflowsLayout>
  );
}
