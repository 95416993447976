import { ModalHeader as ModalHeaderUi, styled, Text } from '@konta/ui';

export const ModalHeader = styled(ModalHeaderUi, {
  marginBottom: 0,
  position: 'sticky',
  top: 0,
  zIndex: 999,
  backgroundColor: 'white',
});

export const ModalContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  height: 'fit-content',
  flex: 1,
  gap: '32px',
  padding: '16px 0 16px 24px',
  '@md': {
    padding: '32px 0 16px 48px',
  },
});
