import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  bankTransactionsDeclarationsUpdateFailure,
  bankTransactionsDeclarationsUpdateSuccess,
} from '../actions';

export default function* ({
  payload: {
    delaration_id,
    declaration_entries_id,
    body,
    successMessage = 'Gasto agregado con éxito',
    successTitle = 'Gasto agregado',
  },
}) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `declaration_entries/${declaration_entries_id}`,
      body,
      { headers: { Authorization: `${access_token}` } }
    );

    yield call(StatusHandler, {
      response,
      title: successTitle,
      message: successMessage,
      status: response.status,
      success: bankTransactionsDeclarationsUpdateSuccess,
      failure: bankTransactionsDeclarationsUpdateFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      message: 'Algo salió mal al editar el gasto',
      action: bankTransactionsDeclarationsUpdateFailure,
    });
  }
}
