import { NotificationManager } from '@components';
import useTaxableEntity from '@hooks/useTaxableEntity';
import { useFormik } from 'formik';
import * as yup from 'yup';
import getErrorMessage from '@util/getErrorMessage';
import usePatchTaxableEntityPreferredFiscalRegime from 'shared/hooks/usePatchTaxableEntityPreferredFiscalRegime';
import filterActiveRegimes from 'shared/util/filterActiveRegimes';
import getRegimeNameFromFiscalRegime from 'shared/util/getRegimeNameFromFiscalRegime';

const validationSchema = yup.object({
  sat_key: yup.string().required('Este campo es requerido'),
});

export default function useInvoiceSettings() {
  const { taxable_entity } = useTaxableEntity();
  const taxableEntity = taxable_entity;
  const patchPreferredRegime = usePatchTaxableEntityPreferredFiscalRegime();
  const formik = useFormik({
    initialValues: {
      sat_key: taxableEntity?.preferred_invoice_fiscal_regime_key || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const taxableEntityId = taxableEntity?.id;
        if (!taxableEntityId) {
          throw new Error('Taxable entity id is not defined');
        }
        await patchPreferredRegime.mutateAsync({
          taxableEntityId,
          payload: {
            sat_key: +values.sat_key,
          },
        });
        NotificationManager.success(
          'Se ha guardo la preferencia de régimen fiscal',
          'Configuración de facturación',
          3000,
        );
      } catch (error) {
        const message = getErrorMessage(error) as string;
        NotificationManager.error(message, 'Error', 10000);
      }
    },
  });

  const activeRegimes = filterActiveRegimes(
    taxableEntity?.fiscal_regimes || [],
  ).map((regime) => ({
    label: getRegimeNameFromFiscalRegime(regime),
    value: regime.sat_key,
  }));

  return {
    formik,
    activeRegimes,
    isLoading: patchPreferredRegime.isLoading,
  };
}
