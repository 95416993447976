import { useToggle } from 'rooks';
import { useFormik } from 'formik';
import getErrorMessage from '@util/getErrorMessage';
import { NotificationManager } from '@components/Notifications';
import useProcess from '@hooks/useProcess';
import { ProcessType } from 'types/entities';
import { validationSchema, defaultInitialValues, FormValues } from './form';

interface UseRequestAdjustmentModalProps {
  toggleIsOpen: () => void;
}
export default function useRequestAdjustmentModal({
  toggleIsOpen,
}: UseRequestAdjustmentModalProps) {
  const { next, activeStep, updatingProcess, loadingCurrentProcesses } =
    useProcess(ProcessType.AnnualDeclarationProcess);
  const [openNotification, toggleNotification] = useToggle(false);

  const handleOnSubmit = async (values: FormValues, resetForm: () => void) => {
    try {
      const {
        imNotAgreeWithTaxes,
        wantToVerifyMyPartialities,
        wantToKnowPersonalDeductionBreakdown,
        wantToKnowTaxBreakdown,
        wantToScheduleACall,
        callReason,
        others,
        othersReason,
      } = values;
      const answers = {
        im_not_agree_with_taxes: imNotAgreeWithTaxes,
        want_to_verify_my_partialities: wantToVerifyMyPartialities,
        want_to_know_personal_deduction_breakdown:
          wantToKnowPersonalDeductionBreakdown,
        want_to_know_tax_breakdown: wantToKnowTaxBreakdown,
        want_to_schedule_a_call: wantToScheduleACall,
        call_reason: wantToScheduleACall ? callReason : undefined,
        others: others ? othersReason : undefined,
      };
      await next({
        steps_attributes: [{ id: activeStep?.id, answers }],
      });
      toggleIsOpen();
      toggleNotification();
      resetForm();
    } catch (e) {
      const errorMessage = getErrorMessage(e) as string;
      NotificationManager.error(errorMessage, 'Actualizar resumen anual');
    }
  };

  const formik = useFormik({
    initialValues: defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values, { resetForm }) => {
      await handleOnSubmit(values, resetForm);
    },
  });

  const someValueIsTrue = Object.values(formik.values).some((value) => !!value);

  return {
    someValueIsTrue,
    isLoading:
      updatingProcess || formik.isSubmitting || loadingCurrentProcesses,
    formik,
    openNotification,
    toggleNotification,
  };
}
