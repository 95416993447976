import api from '@api';

function mapError({ message, successMessage, errorsMap }) {
  if (successMessage === message) return null;

  return errorsMap[message] || message;
}

/**
 * @param { string } message
 * @return {{ other?: string, rfc?: string, name?: string, cp?: string }}
 */
function parseErrors(message) {
  if (message === 'RFC Erróneo.') {
    return { rfc: 'Por favor, introduce un RFC válido' };
  }

  const messages = message
    .split('.')
    .filter((segment) => segment)
    .map((segment) => segment.trim());

  if (messages.length !== 4) {
    return { other: message };
  }

  const [rfcMessage, nameMessage, cpMessage, rfc2Message] = messages;

  const rfc =
    mapError({
      message: rfcMessage,
      successMessage: 'RFC correcto',
      errorsMap: {},
    }) ||
    mapError({
      message: rfc2Message,
      successMessage: 'RFC no está en la lista negra',
      errorsMap: {
        'RFC está en la lista negra como "Desvirtuado"':
          'Por favor, introduce un RFC que no esté en la lista negra',
      },
    });

  const name = mapError({
    message: nameMessage,
    successMessage: 'Nombre/Razón social correcta',
    errorsMap: {
      'Nombre/Razón social errónea':
        'Por favor, introduce una razón social válida',
    },
  });

  const cp = mapError({
    message: cpMessage,
    successMessage: 'CP correcto',
    errorsMap: {
      'CP pendiente por revisar':
        'Por favor, introduce un código postal válido.',
      'CP erróneo': 'Por favor, introduce un código postal válido.',
    },
  });

  return { rfc, name, cp };
}

/**
 * @returns { Promise<{ fixedName?: string, errors?: ReturnType<parseErrors> }> }
 */
export default async function verifyRfc({
  rfc,
  zipCode,
  legalName,
  strictValidation = false,
}) {
  try {
    const { data } = await api.post(
      'taxable_entities/validate_rfc',
      {
        rfc,
        zip_code: zipCode,
        legal_name: legalName,
      },
      {
        headers: {
          Authorization: localStorage.getItem('user_id'),
        },
      },
    );

    return {
      fixedName: data.legal_name,
    };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.errors ?? 'Ha ocurrido un error inesperado';

    // TODO: find a better way to handle this error
    // if service crashes, ignore validation
    if (
      errorMessage === 'Error en el servicio, intente más tarde' &&
      !strictValidation
    ) {
      return { fixedName: legalName };
    }

    return {
      errors: parseErrors(errorMessage),
    };
  }
}
