export const objectValuestoString = (o) => {
  Object.keys(o).forEach((k) => {
    if (typeof o[k] === 'object') {
      return toString(o[k]);
    }

    o[k] = o[k].toString();
  });

  return o;
};

export const removeEmptyKeys = (obj) => {
  Object.keys(obj).forEach((key) => {
    // recurse
    if (obj[key] && typeof obj[key] === 'object') removeEmptyKeys(obj[key]);
    else if (obj[key] == null || obj[key] === '') delete obj[key];
  });
};

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const addCommas = (nStr) => {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2');
  }
  return x1 + x2;
};

export const toCurrency = (value) => {
  return `$${(value || 0).toFixed(2)}`;
};

export const toPercentage = (value) => {
  return `${value || 0}%`;
};

export const validateObjects = (...params) => {
  for (const item of params) {
    if (item === null || item === undefined || item.length < 1) return false;
  }
  return true;
};

export const sumAll = (...params) => {
  params = params.filter((param) => typeof param === 'number');
  return params.reduce((prev, current) => prev + current);
};

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export class UUIDgen {
  generate() {
    this.lut = [];
    for (let i = 0; i < 256; i++) {
      this.lut[i] = (i < 16 ? '0' : '') + i.toString(16);
    }
    this.d0 = (Math.random() * 0xffffffff) | 0;
    this.d1 = (Math.random() * 0xffffffff) | 0;
    this.d2 = (Math.random() * 0xffffffff) | 0;
    this.d3 = (Math.random() * 0xffffffff) | 0;
    return `${this.lut[this.d0 & 0xff] +
      this.lut[(this.d0 >> 8) & 0xff] +
      this.lut[(this.d0 >> 16) & 0xff] +
      this.lut[(this.d0 >> 24) & 0xff]
      }-${this.lut[this.d1 & 0xff]}${this.lut[(this.d1 >> 8) & 0xff]}-${this.lut[((this.d1 >> 16) & 0x0f) | 0x40]
      }${this.lut[(this.d1 >> 24) & 0xff]}-${this.lut[(this.d2 & 0x3f) | 0x80]}${this.lut[(this.d2 >> 8) & 0xff]
      }-${this.lut[(this.d2 >> 16) & 0xff]}${this.lut[(this.d2 >> 24) & 0xff]}${this.lut[this.d3 & 0xff]
      }${this.lut[(this.d3 >> 8) & 0xff]}${this.lut[(this.d3 >> 16) & 0xff]}${this.lut[(this.d3 >> 24) & 0xff]
      }`;
  }
}

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getDateObjectWithInvoiceFormat = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;

  const yyyy = date.getFullYear();

  let hh = date.getHours();
  let min = String(date.getMinutes());
  let ss = String(date.getSeconds());
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  if (hh < 10) {
    hh = `0${hh}`;
  }

  if (min < 10) {
    min = `0${min}`;
  }

  if (ss < 10) {
    ss = `0${ss}`;
  }
  const formattedDate = `${String(yyyy)}-${String(mm)}-${String(dd)}T${String(
    hh,
  )}:${String(min)}:${String(ss)}`;
  return formattedDate;
};

export const months = [
  'Enero', // 0
  'Febrero', // 1
  'Marzo', // 2
  'Abril', // 3
  'Mayo', // 4
  'Junio', // 5
  'Julio', // 6
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const getDateObjectWithFormat = (date) => {
  let dd = date.getUTCDate();
  const mm = date.getUTCMonth();

  const yyyy = date.getUTCFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  return `${dd} de ${months[mm]} del ${yyyy} `;
};

export const getMonthAndYearDate = (date) => {
  const mm = date.getUTCMonth();

  const yyyy = date.getUTCFullYear();

  return `${months[mm]} del ${yyyy} `;
};

// deprecated
export const numberWithCommas = (x) => {
  return parseFloat(x).toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};
export const getMonthName = (date) => {
  const mm = date.getUTCMonth();
  return months[mm];
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const quarter = (date, Q = true) => {
  const month = date.getMonth() + 1;
  return `Q${Math.ceil(month / 3)}`;
};

export const checkProperties = (obj) => {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== '') return false;
  }
  return true;
};

// Message errors on errorhandler
export const getMessageFromError = (errors) => {
  try {
    const message = Object.keys(errors).map((key) => errors[key].join(' '));
    return message.join(' ');
  } catch {
    return errors || 'Contáctanos por chat para resolver el error';
  }
};

export const toFixed = (num, precision) =>
  (+`${Math.round(+`${num}e${precision}`)}e${-precision}`).toFixed(precision);

export const toFixedWithoutRound = function (input, decimals) {
  const arr = `${input}`.split('.');
  if (arr.length === 1) return input;
  const int = arr[0];
  const max = arr[1].length;
  const dec = arr[1].substr(0, decimals > max ? max : decimals);
  return decimals === 0 ? int : [int, '.', dec].join('');
};
export const fiscalRegimeParser = (fiscal_regimens) => {
  if (fiscal_regimens) {
    let select_values = [];
    fiscal_regimens.map((item) => {
      select_values = [
        ...select_values,
        {
          label: item.description,
          value: item.sat_key,
          key: item.id,
          sat_key: item.sat_key,
        },
      ];
    });
    return select_values;
  }
  return [];
};
export const getBase64FromUrl = async (url) => {
  try {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  } catch (error) {
    console.error(error);
  }
};

export const generateWorkflowPublicInvoice = ({
  subtotal,
  total,
  expedition_place,
  issuer_attributes,
  receiver_attributes,
  total_taxes_transferred,
  transferred_attributes_import,
  concepts_attributes,
}) => {
  return {
    receipt: {
      date: new Date(),
      payment_way: '99',
      subtotal,
      discount: null,
      total,
      type_of_receipt: 'I',
      payment_method: 'PUE',
      expedition_place,
      issuer_attributes,
      receiver_attributes,
      concepts_attributes: concepts_attributes.filter(
        (concept) => concept !== false,
      ),
      taxes_attributes: {
        total_taxes_transferred,
        total_taxes_detained: null,
        transferred_attributes: [
          {
            tax: '002',
            factor_type: 'Tasa',
            rate_or_fee: '0.160000',
            import: transferred_attributes_import,
          },
        ],
        detained_attributes: null,
      },
    },
  };
};

export const cfdiSelectParser = (cfdiList) => {
  if (cfdiList) {
    let select_values = [];
    cfdiList.map((item) => {
      select_values = [
        ...select_values,
        {
          label: item.description,
          value: item.id,
          key: item.id,
          sat_key: item.sat_key,
        },
      ];
    });
    return select_values;
  }
  return false;
};

export function downloadFile(file) {
  const url = URL.createObjectURL(file);

  const a = document.createElement('a');
  a.href = url;
  a.download = file.name;

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
