import { styled } from '@konta/ui';

export const Heading = styled('div', {
  display: 'flex',
  width: '100%',
  padding: '$16 0',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$12',
});

export const Content = styled('div', {
  display: 'flex',
  width: '100%',
  padding: '$0px 0px 100px 0px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$32',
});
