import api from '@api';
import { call, select } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import { addressFetchFailure, addressFetchSucess } from '../actions';

export function* updateAddressSaga({
  payload: { address, options, callback },
}) {
  try {
    const { taxable_entity } = yield select((state) => state.taxableEntity);
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `/taxable_entities/${taxable_entity.id}/addresses/${taxable_entity.address}`,
      {
        address,
      },
      { headers: { Authorization: `${access_token}` } },
    );
    if (callback) callback();
    yield call(StatusHandler, {
      response,
      options,
      status: response.status,
      success: addressFetchSucess,
      failure: addressFetchFailure,
      title: 'Actualizada',
      message: 'Dirección ha sido modificada correctamente.',
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: addressFetchFailure,
    });
  }
}
