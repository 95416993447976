import { useState } from 'react';
import { Divider, Flex } from '@konta/ui';
import {
  FileList2OutlineIcon,
  FileCheckInLc2OutlineIcon,
  BankCardOutlineIcon,
} from '@konta/icons';
import { ProcessType } from 'types/entities';
import NextStepButton from '@components/NextStepButton';
import ProcessStepLayout from '@components/ProcessStepLayout';
import ProcessStepDescription from '@components/ProcessStepDescription';
import ProcessStepTitle from '@components/ProcessStepTitle';
import WelcomeFeatureText from './WelcomeFeatureText';

export default function WelcomeAnnual() {
  const [payload, setPayload] = useState<boolean>();
  return (
    <ProcessStepLayout
      withDefaultLeftSidebar={false}
      currentProcessType={ProcessType.AnnualDeclarationProcess}
      title="Declaraciones Anuales"
      content={
        <>
          <Flex direction="column" gap={12}>
            <ProcessStepTitle>Comienza tu declaración anual</ProcessStepTitle>
            <ProcessStepDescription>
              Te damos la bienvenida al proceso en línea para tener tu
              declaración anual. A través de nuestro flujo en línea, podremos
              presentar tu declaración anual de manera sencilla y eficiente.
            </ProcessStepDescription>
            <ProcessStepDescription>
              En cada paso del proceso, te guiaremos claramente para que puedas
              cumplir con tus obligaciones fiscales sin complicaciones. Además,
              en nuestra plataforma podrás visualizar el cálculo de tu
              declaración anual y te mostraremos los detalles de tus ingresos y
              gastos de cada mes del ejercicio fiscal.
            </ProcessStepDescription>
            <ProcessStepDescription>
              Al final del proceso, podrás revisar y aprobar los detalles de tu
              declaración antes de presentarla.
            </ProcessStepDescription>
            <Flex css={{ pt: '$24' }}>
              <NextStepButton
                currentProcessType={ProcessType.AnnualDeclarationProcess}
                data-cy="annualWelcomeNextBtn"
                text="Comenzar"
                payload={payload}
                onClick={() => setPayload(true)}
              />
            </Flex>
          </Flex>
          <Flex direction="column" gap={24}>
            <Divider />
            <Flex
              css={{
                gap: '32px',
                flexDirection: 'column',
                '@sm': { flexDirection: 'row' },
              }}
            >
              <WelcomeFeatureText
                icon={<BankCardOutlineIcon />}
                title="1. Solicita y paga tu declaración anual"
                description="El servicio de declaración anual tiene el costo equivalente a un mes de tu suscripción mensual. Solicita tu declaración anual antes del 15 de abril para que la podamos presentar."
              />
              <WelcomeFeatureText
                icon={<FileList2OutlineIcon />}
                title="2. Te informaremos cuando tu cálculo se encuentre listo"
                description="Nuestro equipo fiscal entrará en el portal del SAT para confirmar que la información cargada esté completa. Al finalizar, compartirá el cálculo de tu declaración anual en la plataforma para que lo puedas aprobar."
              />
              <WelcomeFeatureText
                icon={<FileCheckInLc2OutlineIcon />}
                title="3. Aprueba tu declaración anual"
                description="Al final del proceso, vas a poder ver el resultado de tu declaración anual, y te mostraremos las opciones disponibles para que la podamos presentar."
              />
            </Flex>
          </Flex>
        </>
      }
    />
  );
}
