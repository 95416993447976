/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react';
import { useTable, usePagination, useSortBy, Column } from 'react-table';
import toCurrency from '@util/toCurrency';
import {
  ConfiguredReactTable,
  Button,
  Text,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Flex,
  Chip,
} from '@konta/ui';
import { useMutation, useQueryClient } from 'react-query';
import { NotificationManager } from '@components/Notifications';
import {
  TrashCanOutlineIcon,
  EyeOutlineIcon,
  Edit2OutlineIcon,
  Download01Icon,
} from '@konta/icons';
import { useToggle } from 'usehooks-ts';
import deleteForeignDocument from '@api/deleteForeignDocument';
import { ForeignDocument } from 'types/entities';
import getErrorMessage from '@util/getErrorMessage';
import { DECLARATION_ENTRY_TYPE_DICT } from '@constants/declarations';
import { ConfirmModal } from '../Modal';
import ForeignProductModalForm from '../ForeignProductModalForm';
import ForeignInvoiceModalForm from '../ForeignInvoiceModalForm';

interface ForeignDocumentsTableProps {
  loading: boolean;
  isAnnualProcess: boolean;
  foreignDocuments: ForeignDocument[];
}

const chipColor = (value: string) => {
  if (value === 'foreign_invoices') {
    return 'gray200';
  }
  if (value === 'foreign_products') {
    return 'primary200';
  }
  return 'warning200';
};

export default function ForeignDocumentsTable({
  loading,
  foreignDocuments,
  isAnnualProcess,
}: ForeignDocumentsTableProps) {
  const queryClient = useQueryClient();
  const [deleteItem, setDeleteItem] = useState<ForeignDocument>();
  const [openDeleteModal, toggleOpenDeleteModal] = useToggle();
  const [selectedForeignProduct, setSelectedForeignProduct] =
    useState<ForeignDocument | null>();
  const [selectedForeignInvoice, setSelectedForeignInvoice] =
    useState<ForeignDocument | null>();
  const [readOnly, setReadonly] = useState(false);

  const setSelectedItem = (item: ForeignDocument) => {
    setSelectedForeignInvoice(item);
  };

  const cleanSelectedItems = () => {
    setSelectedForeignInvoice(null);
    setSelectedForeignProduct(null);
  };

  const deleteSelectedDocument = (currentDocument: ForeignDocument) => {
    queryClient.setQueryData(
      ['FOREIGN_PRODUCTS'] || ['FOREIGN_INVOICES'],
      (oldForeignDocuments: ForeignDocument[] | undefined) => {
        if (!oldForeignDocuments) return []; // only for ts validation
        return oldForeignDocuments.filter(
          (doc: ForeignDocument) => doc.id !== currentDocument.id,
        );
      },
    );
  };

  const deleteDocument = useMutation(deleteForeignDocument);

  const foreignDocumentsColumns: Column<ForeignDocument>[] = useMemo(
    () => [
      {
        Header: <Text m>Descripción</Text>,
        accessor: 'description',
        Cell: ({ value }) => <Text m>{value}</Text>,
      },
      {
        Header: <Text m>Total</Text>,
        accessor: 'amount',
        Cell: ({ row, value }) => {
          const foreignProductAmount = +row.original.taxable_amount;
          const foreignInvoiceAmount = +value;

          return (
            <Text m>
              {toCurrency(foreignInvoiceAmount || foreignProductAmount || 0)}
            </Text>
          );
        },
      },
      {
        Header: <Text m>Tipo</Text>,
        accessor: 'entry_type',
        Cell: ({ value }) => (
          <Chip
            color={chipColor(value)}
            label={
              DECLARATION_ENTRY_TYPE_DICT.find((entry) => entry.type === value)
                ?.text || 'Sin tipo'
            }
          />
        ),
      },
      {
        Header: <Text m>Acciones</Text>,
        accessor: 'taxable_entity_id',
        Cell: ({ row }) => {
          const foreignProductDocument = row.original?.documents;
          const foreignInvoiceDocument = row.original?.file;
          const documentUrl = foreignInvoiceDocument || foreignProductDocument;

          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="2xs" color="gray" variant="minimal">
                  <div
                    style={{
                      fontSize: '25px',
                      paddingBottom: '50%',
                    }}
                  >
                    &#x2026;
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {!isAnnualProcess && (
                  <>
                    <DropdownMenuItem
                      onClick={() => {
                        setReadonly(true);
                        setSelectedItem(row.original);
                      }}
                    >
                      <Flex align="center">
                        <EyeOutlineIcon />
                        <Text css={{ mr: '$16', ml: '$12' }}>Ver detalle</Text>
                      </Flex>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        setReadonly(false);
                        setSelectedItem(row.original);
                      }}
                    >
                      <Flex align="center">
                        <Edit2OutlineIcon />
                        <Text css={{ mr: '$16', ml: '$12' }}>Editar</Text>
                      </Flex>
                    </DropdownMenuItem>
                  </>
                )}
                <DropdownMenuItem
                  onClick={() => {
                    setDeleteItem(row.original);
                    toggleOpenDeleteModal();
                  }}
                >
                  <Flex align="center">
                    <TrashCanOutlineIcon />
                    <Text css={{ mr: '$16', ml: '$12' }}>Eliminar</Text>
                  </Flex>
                </DropdownMenuItem>
                {!!documentUrl && (
                  <DropdownMenuItem
                    onClick={() => {
                      window.open(documentUrl);
                    }}
                  >
                    <Flex align="center">
                      <Download01Icon />
                      <Text css={{ mr: '$16', ml: '$12' }}>
                        Descargar documento
                      </Text>
                    </Flex>
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    [toggleOpenDeleteModal, isAnnualProcess],
  );

  const foreignDeclarationEntriesTable = useTable<ForeignDocument>(
    {
      columns: foreignDocumentsColumns,
      data: foreignDocuments,
    },
    useSortBy,
    usePagination,
  );

  const deleteProduct = async (entry: ForeignDocument) => {
    try {
      await deleteDocument.mutateAsync({
        id: entry.id,
        documentType: entry.entry_type,
      });
      deleteSelectedDocument(entry);
      NotificationManager.success(
        'Se eliminado el documento correctamente',
        'Documento eliminado',
        3000,
      );
    } catch (error) {
      const message = getErrorMessage(error) as string;
      NotificationManager.error(message, 'Error', 10000);
    } finally {
      toggleOpenDeleteModal();
    }
  };

  return (
    <>
      <ConfirmModal
        title="Eliminar"
        open={openDeleteModal}
        onAccept={() => deleteProduct(deleteItem as ForeignDocument)}
        onCancel={toggleOpenDeleteModal}
        onClose={toggleOpenDeleteModal}
        loading={deleteDocument.isLoading}
      >
        ¡Estas a punto de eliminar un gasto en el extranjero!
      </ConfirmModal>
      <ForeignProductModalForm
        isOpen={!!selectedForeignProduct}
        foreignProduct={selectedForeignProduct}
        onClose={cleanSelectedItems}
        onSubmit={cleanSelectedItems}
        readOnly={readOnly}
        setReadOnly={setReadonly}
        isAnnualProcess
        defaultRegimeOption={null}
        taxableEntityPreferences={null}
      />
      <ForeignInvoiceModalForm
        isOpen={!!selectedForeignInvoice}
        foreignInvoice={selectedForeignInvoice}
        onClose={cleanSelectedItems}
        onSubmit={cleanSelectedItems}
        readOnly={readOnly}
        setReadOnly={setReadonly}
        isAnnualProcess
        taxableEntityPreferences={null}
        defaultRegimeOption={null}
      />
      <ConfiguredReactTable
        {...foreignDeclarationEntriesTable}
        loading={loading}
      />
    </>
  );
}
