import React from 'react';
import { Row, Jumbotron, Card } from 'reactstrap';
import { Colxx } from '@components/CustomBootstrap';

export function TaskJumbotron(props) {
  return (
    <Row>
      <Colxx xxs="12" className="mb-4">
        <Card body>
          <Jumbotron className="mb-0">
            <h1 className="display-4">¡Bienvenido!</h1>
            <p className="lead">
              Nuestro equipo está preparandose para tener una llamada contigo
              <span role="img" aria-label="hand">
                👋🏼
              </span>
            </p>
          </Jumbotron>
        </Card>
      </Colxx>
    </Row>
  );
}
