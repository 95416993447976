import { styled } from '@konta/ui';

export const Root = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
});

export const HeaderWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
});

export const Total = styled('span', {
  color: '$gray900',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  '@md': {
    fontSize: '18px',
    lineHeight: '28px',
  },
});

export const Title = styled('span', {
  color: '$gray700',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '18px',
  '@md': {
    fontSize: '14px',
    lineHeight: '20px',
  },
});

export const TotalWrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
});
