import { useMemo } from 'react';
import toCurrency from '@util/toCurrency';

export default function useAnnualIncomes(annualTotals) {
  const sourceTypes = useMemo(
    () => ['digital_platform', 'business_activity', 'resico'],
    [],
  );
  const bySalary = useMemo(
    () =>
      annualTotals.reduce(
        (prevValue, { source_type, transaction_type, amount }) => {
          const isBySalary =
            transaction_type === 'income' && source_type === 'salary';
          if (isBySalary) {
            return +prevValue + +amount;
          }
          return prevValue;
        },
        0,
      ),
    [annualTotals],
  );

  const businessIncomes = useMemo(
    () =>
      annualTotals.reduce(
        (prevValue, { source_type, transaction_type, amount }) => {
          const isBusinessIncomes =
            transaction_type === 'income' && sourceTypes.includes(source_type);
          if (isBusinessIncomes) {
            return prevValue + +amount;
          }
          return prevValue;
        },
        0,
      ),
    [annualTotals, sourceTypes],
  );

  return useMemo(() => {
    return [
      {
        key: 1,
        label: 'Ingresos por salarios',
        amount: `${toCurrency(bySalary)} MXN`,
        id: 'incomesBySalary',
        value: bySalary,
      },
      {
        key: 2,
        label: 'Ingresos del negocio cobrados',
        amount: `${toCurrency(businessIncomes)} MXN`,
        id: 'businessIncomes',
        value: businessIncomes,
      },
      {
        key: 3,
        label: 'Total de ingresos',
        amount: `${toCurrency(bySalary + businessIncomes)} MXN`,
        id: 'totalIncomes',
        isBold: true,
        value: bySalary + businessIncomes,
      },
    ];
  }, [bySalary, businessIncomes]);
}
