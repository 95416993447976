import TotalsButton from '@components/TotalsButton';
import { Root } from './styled';
import useTotalsButtons from './useTotalsButtons';

export default function TotalsButtons() {
  const { declarationsExpensesTableTotals } = useTotalsButtons();
  return (
    <Root>
      {declarationsExpensesTableTotals.map(({ total, regimeName, totals }) => (
        <TotalsButton
          key={regimeName}
          regime={regimeName}
          totals={totals}
          total={total}
          source="expenses"
        />
      ))}
    </Root>
  );
}
