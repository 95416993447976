import { TWO_FACTOR_BACKUP_CODES } from '@constants/reactQueries';
import { useQuery } from 'react-query';
import getBackupCodesTwoFactor from '@api/auth/getBackupCodesTwoFactor';

export default function UseBackupCodesTwoFactor() {
  const {
    data = {},
    isLoading,
    ...rest
  } = useQuery(TWO_FACTOR_BACKUP_CODES, getBackupCodesTwoFactor);

  return {
    loadingBackupCodes: isLoading,
    backupCodes: data?.two_factor?.backup_codes || '',
    ...rest,
  };
}
