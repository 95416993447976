import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Row } from 'reactstrap';
import { useTaxableEntity } from '@hooks';
import { AvForm } from 'availity-reactstrap-validation';
import { Colxx } from '@components/CustomBootstrap';
import { UploadFiel } from '@components';
import {
  updateUser,
  addressUpdate,
  addressFetch,
  fetchTaxableEntityCredentials,
} from '@redux/actions';

// import { statementUpload } from '@redux/actions'

function UploadFielStep({ user, loading, address }) {
  const dispatch = useDispatch();
  const { taxable_entity } = useTaxableEntity();
  useEffect(() => {
    dispatch(fetchTaxableEntityCredentials(taxable_entity));
  }, [taxable_entity.has_fiel]);
  const handleSumbit = (event, values, errors) => {};

  return (
    <Row className="p-5">
      <Colxx xxs={12} md={8} className="mb-4">
        <AvForm onValidSubmit={handleSumbit} model={user} autoComplete="off">
          <Row className="mb-2">
            <Colxx xxs={12}>
              <p>
                Agrega tus archivos CER y KEY. Es importante para que Konta
                pueda conectar con el SAT
              </p>
            </Colxx>
            {!taxable_entity.has_fiel ? (
              <UploadFiel buttonSubmit="Guardar y continuar" />
            ) : (
              <Row className="d-flex justify-content-center align-items-center">
                <Colxx md={6} xxs={12} className="mb-4">
                  <a
                    href={taxable_entity.key_url}
                    download={`FIEL-${taxable_entity.rfc}.key`}
                    className="btn btn-outline-primary btn-sm"
                  >
                    Descargar .key
                  </a>
                </Colxx>
                <Colxx md={6} xxs={12} className="mb-4">
                  <a
                    href={taxable_entity.cer_url}
                    download={`FIEL-${taxable_entity.rfc}.cer`}
                    className="btn btn-outline-primary btn-sm"
                  >
                    Descargar .cer
                  </a>
                </Colxx>
              </Row>
            )}
          </Row>
          {taxable_entity.has_fiel && (
            <Row>
              <Colxx xxs={12} md={4}>
                <Button color="primary" size="sm" disabled={loading}>
                  Guardar y continuar
                </Button>
              </Colxx>
            </Row>
          )}
        </AvForm>
      </Colxx>
    </Row>
  );
}

const mapStateToProps = ({ authUser, addresses }) => {
  const { user, error, loading } = authUser;
  const { address } = addresses;
  return { user, error, loading, address };
};
export default connect(mapStateToProps, {
  updateUser,
  addressUpdate,
  addressFetch,
})(UploadFielStep);
