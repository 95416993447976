/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Flex, Text, Button } from '@konta/ui';
import {
  Lock1OutlineIcon,
  MailOutlineIcon,
  UserOutlineIcon,
  EyeOutlineIcon,
  EyeOffOutlineIcon,
  GoogleIcon,
} from '@konta/icons';
import { useGoogleLogin } from '@react-oauth/google';
import {
  registerUser,
  loginWithGoogleUser,
  onboardingUser,
  updateTaxableEntity,
} from '@redux/actions';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import useFeature from '@hooks/useFeature';
import FormikTextInput from '@components/FormikTextInput';
import api from '@api';
import ForbesImage from '@assets/img/forbes.webp';
import KariImage from '@assets/img/karirosas.webp';
import TemoImage from '@assets/img/temo.webp';
import { GOOGLE_LOGIN_ENABLED } from '@constants/featureFlags';
import FormikCheckbox from '@components/FormikCheckbox';
import useTypeformParams from 'shared/hooks/useTypeformParams.ts';
import getErrorMessage from '@util/getErrorMessage';
import { NotificationManager } from '@components';
import useGoogleRecaptchaScript from 'shared/hooks/useGoogleRecaptchaScript';
import Loader from 'shared/components/Loader';
import ScriptErrorInformation from 'shared/components/ScriptErrorInformation/ScriptErrorInformation';

function useInitialValues(formData, isFromTypeform) {
  if (isFromTypeform && formData) {
    return {
      name: formData.name,
      lastname: formData.lastName,
      email: formData.email,
      password: '',
      password_confirmation: '',
      terms: false,
    };
  }
  return {
    name: '',
    lastname: '',
    email: '',
    password: '',
    password_confirmation: '',
    terms: false,
  };
}

function Register() {
  const { recaptcha, isGoogleScriptLoading, hasGoogleScriptError } =
    useGoogleRecaptchaScript();
  const dispatch = useDispatch();
  const [googleLoginEnabled] = useFeature(GOOGLE_LOGIN_ENABLED);
  const { loading } = useSelector((state) => state.authUser);
  const navigate = useNavigate();
  const featureClient = useLDClient();
  const [isLoginWithGoogle, setIsLoginWithGoogle] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
  const { formData, isFromTypeform } = useTypeformParams();
  const initialValues = useInitialValues(formData, isFromTypeform);

  const validationSchema = yup.object().shape({
    name: yup.string().required('Campo requerido'),
    email: yup
      .string()
      .email('Correo electrónico inválido')
      .required('Campo requerido'),
    lastname: yup.string().required('Campo requerido'),
    password: yup
      .string()
      .min(6, 'La contraseña debe ser mayor a 6 dígitos')
      .required('Campo requerido')
      .matches(
        /^(?=.*[0-9])(?=.*[a-zA-Z])(.*)$/,
        'La contraseña debe contener números y letras',
      ),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir')
      .required('Campo requerido'),
    terms: yup
      .boolean()
      .oneOf([true], 'Los Términos y Condiciones deben ser aceptados.'),
  });

  const registerSuccess = (response) => {
    if (!isFromTypeform || !formData.phone) return;
    dispatch(
      onboardingUser(
        {
          user: {
            phone_number: formData.phone,
          },
        },
        { silent: true },
        featureClient,
      ),
    );
    const rc = localStorage.getItem('rc');

    dispatch(
      updateTaxableEntity(
        {
          taxable_entity: {
            referal: rc && rc,
            onboarding_detail_attributes: {
              is_rejected_user: false,
            },
          },
        },
        response.taxable_entity.id,
        {
          silent: true,
        },
      ),
    );
  };

  const onUserRegister = (values) => {
    const body = {
      ...values,
      first_name: `${values.name} ${values.lastname}`,
      phone_number: formData.phone || undefined,
      lastname: undefined,
      name: undefined,
    };
    recaptcha?.ready(async () => {
      try {
        const token = await recaptcha.execute(
          process.env.REACT_APP_CAPTCHA_KEY || '',
          {
            action: 'login',
          },
        );
        dispatch(
          registerUser(body, navigate, token, featureClient, registerSuccess),
        );
      } catch (e) {
        console.error(e);
        const errorMessage = getErrorMessage(e);
        NotificationManager.error(errorMessage, 'Registro fallido');
      }
    });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: onUserRegister,
  });

  const googleLogin = useGoogleLogin({
    ux_mode: 'popup',
    onSuccess: async (codeResponse) => {
      setIsLoginWithGoogle(false);
      const { data } = await api.post(
        '/auth/google_oauth2/callback',
        codeResponse,
      );
      dispatch(
        loginWithGoogleUser(formik.values, navigate, featureClient, {
          data,
        }),
      );
    },
    onError: (codeResponse) => {
      setIsLoginWithGoogle(false);
    },
  });

  const isLoading = loading;

  const GRADIENT_BACKGROUND =
    'linear-gradient(to left bottom, #717cdb, #686dc9, #605eb7, #5750a5, #4e4294, #4e4294, #4e4294, #4e4294, #5750a5, #605eb7, #686dc9, #717cdb);, #6d72d8, #717cdb);';

  if (isGoogleScriptLoading) {
    return <Loader />;
  }

  if (hasGoogleScriptError) {
    return (
      <ScriptErrorInformation
        withResponsiveCenterContainer
        title="Ocurrió un error al cargar el captcha de Google, por favor recarga la página o contáctanos."
      />
    );
  }

  return (
    <Flex
      direction="row"
      css={{ backgroundColor: 'white', height: '100vh', overflow: 'auto' }}
      wrap="wrapReverse"
    >
      <Flex
        direction="column"
        align="center"
        justify="center"
        css={{
          '@<md': { display: 'none' },
          width: '50%',
          px: '82px',
          backgroundImage: GRADIENT_BACKGROUND,
        }}
      >
        <Text color="white" x3l css={{ mb: '24px', textAlign: 'center' }} bold>
          Únete a los miles de emprendedores que confían en nosotros.
        </Text>

        <Flex direction="column" align="center" css={{ mb: '20px' }}>
          <Text
            color="white"
            m
            css={{ textAlign: 'center', fontStyle: 'italic' }}
          >
            "Konta.com es una startup con una plataforma tecnológica que atiende
            a personas físicas con actividad empresarial principalmente, la cual
            les permite subir su estado de cuenta, calcular y pagar impuestos."{' '}
            <Text bold>- Forbes MX (Revista de negocios)</Text>
          </Text>
          <img
            alt="browser"
            className="payment-card-images"
            src={ForbesImage}
            style={{
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              marginTop: '6px',
              objectFit: 'cover',
            }}
          />
        </Flex>
        <Flex direction="column" align="center" css={{ mb: '20px' }}>
          <Text
            color="white"
            m
            css={{ textAlign: 'center', fontStyle: 'italic' }}
          >
            "Como emprendedores nos da mucho miedo esta parte del SAT, pero
            existen empresas como Konta.com, que justo nos ayudan guiarnos y
            darnos recomendaciones de como hacer las cosas con el SAT."{' '}
            <Text bold>- Kari Rosas (Especialista en redes sociales)</Text>
          </Text>
          <img
            alt="browser"
            className="payment-card-images"
            src={KariImage}
            style={{
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              marginTop: '6px',
              objectFit: 'cover',
            }}
          />
        </Flex>
        <Flex direction="column" align="center" css={{ mb: '20px' }}>
          <Text
            color="white"
            m
            css={{ textAlign: 'center', fontStyle: 'italic' }}
          >
            "Son expertos en negocios digitales lo que nos facilita bastante las
            cosas y nos quita muchos dolores de cabeza. Por si fuera poco sus
            precios son muy accesibles."{' '}
            <Text bold>- Temo Valle (Activos de Valor)</Text>
          </Text>
          <img
            alt="browser"
            className="payment-card-images"
            src={TemoImage}
            style={{
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              marginTop: '6px',
              objectFit: 'cover',
            }}
          />
        </Flex>
      </Flex>
      <Flex
        direction="row"
        align="center"
        css={{
          width: '50%',
          px: '120px',
          '@<md': { width: '100%', p: '16px' },
        }}
      >
        <Flex css={{ width: '100%' }}>
          <Flex direction="column" css={{ width: '100%' }}>
            <Flex css={{ mb: '40px' }}>
              <NavLink to="/">
                <span className="logo-single" />
              </NavLink>
            </Flex>
            <Flex direction="column" css={{ mb: '34px' }}>
              <Text color="gray800" x4l bold>
                ¡Bienvenido a Konta!
              </Text>
              <Text color="gray500" m css={{ mt: '$8' }}>
                {isFromTypeform
                  ? 'Crea tu contraseña para acceder a la plataforma'
                  : 'Regístrate para acceder a la plataforma'}
              </Text>
            </Flex>
            <FormikProvider value={formik}>
              <Flex direction="column" gap="18">
                <Flex gap={20} css={{ width: '100%' }}>
                  <FormikTextInput
                    label="Nombre"
                    name="name"
                    autoFocus={!isFromTypeform}
                    autoComplete="off"
                    size="m"
                    leftIcon={<UserOutlineIcon />}
                    placeholder="Introduce tu nombre"
                    type="text"
                    data-testid="register-name"
                  />
                  <FormikTextInput
                    leftIcon={<UserOutlineIcon />}
                    label="Apellido"
                    name="lastname"
                    autoComplete="off"
                    size="m"
                    placeholder="Introduce tu apellido"
                    type="text"
                    required
                    data-testid="register-last_name"
                  />
                </Flex>
                <FormikTextInput
                  label="Correo electrónico"
                  name="email"
                  autoComplete="off"
                  size="m"
                  placeholder="Introduce tu correo electrónico"
                  type="email"
                  leftIcon={<MailOutlineIcon />}
                  required
                  data-testid="register-email"
                />
                <FormikTextInput
                  label="Contraseña"
                  name="password"
                  autoFocus={isFromTypeform}
                  autoComplete="off"
                  size="m"
                  data-openreplay-obscured
                  type={passwordShown ? 'text' : 'password'}
                  leftIcon={<Lock1OutlineIcon />}
                  rightIcon={
                    !passwordShown ? (
                      <EyeOutlineIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => setPasswordShown(!passwordShown)}
                      />
                    ) : (
                      <EyeOffOutlineIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => setPasswordShown(!passwordShown)}
                      />
                    )
                  }
                  placeholder="Introduce tu contraseña"
                  required
                  data-testid="register-password"
                />
                <FormikTextInput
                  label="Confirmar contraseña"
                  name="password_confirmation"
                  autoComplete="off"
                  size="m"
                  data-openreplay-obscured
                  type={passwordConfirmShown ? 'text' : 'password'}
                  leftIcon={<Lock1OutlineIcon />}
                  rightIcon={
                    !passwordConfirmShown ? (
                      <EyeOutlineIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setPasswordConfirmShown(!passwordConfirmShown)
                        }
                      />
                    ) : (
                      <EyeOffOutlineIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setPasswordConfirmShown(!passwordConfirmShown)
                        }
                      />
                    )
                  }
                  placeholder="Confirmar contraseña"
                  required
                  data-testid="register-repeat-password"
                />
                <FormikCheckbox
                  data-testid="register-terms-checkbox"
                  label={
                    <>
                      Acepto los{' '}
                      <Text
                        as="a"
                        color="primary700"
                        href="https://konta.com/terminos-y-condiciones"
                        target="_blank"
                      >
                        Términos y Condiciones
                      </Text>
                    </>
                  }
                  name="terms"
                />
              </Flex>
              <Flex
                direction="column"
                justify="between"
                align="stretch"
                css={{ height: '160px', mt: '$8' }}
              >
                <Flex direction="column" align="stretch" css={{ mt: '$24' }}>
                  <Button
                    color="primary600"
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                    fontMedium
                    onClick={formik.submitForm}
                    size="m"
                    css={{ mb: '18px' }}
                    data-testid="register-submit"
                  >
                    Registrarse
                  </Button>
                  {googleLoginEnabled && (
                    <Button
                      color="gray"
                      variant="outlined"
                      loading={isLoading}
                      disabled={isLoading}
                      leftIcon={<GoogleIcon />}
                      onClick={() => {
                        setIsLoginWithGoogle(true);
                        googleLogin();
                      }}
                      fontMedium
                      size="m"
                      u
                      css={{ mb: '18px' }}
                    >
                      Registrarse con Google
                    </Button>
                  )}
                </Flex>
                <Flex justify="center" align="end">
                  <Text color="gray400">
                    ¿Ya tienes cuenta?{' '}
                    <Text
                      color="primary700"
                      css={{ '&:hover': { color: '$gray500' } }}
                      as={Link}
                      to="/login"
                      s
                      underline
                    >
                      Inicia sesión
                    </Text>{' '}
                  </Text>
                  {'  '}
                </Flex>
              </Flex>
            </FormikProvider>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
  /* return (
    <Register {...props} navigate={navigate} featureClient={featureClient} />
  ); */
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading, error } = authUser;
  return { user, loading, error };
};

export default connect(mapStateToProps, {
  registerUser,
})(Register);
