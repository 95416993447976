import api from '@api';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import { call, select } from 'redux-saga/effects';
import { fetchDocsSuccess, fetchDocsFailure } from '../actions';

/**
 *  fetch documents
 * @todo change to real API
 */
export default function* () {
  try {
    const { taxable_entity } = yield select((state) => state.taxableEntity);
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.get,
      `taxable_entities/${taxable_entity.id}/documents`,
      {
        headers: { Authorization: `${access_token}` },
      }
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: fetchDocsSuccess,
      failure: fetchDocsFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: fetchDocsFailure,
    });
  }
}
