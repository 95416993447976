import dayjs from 'dayjs';
import { Filters } from '@konta/ui';
import { PaginationParams } from 'types/pagination';

const format = 'YYYY-MM-DD';
export default function getRansackFilters(
  filters: Filters,
  type: 'incomes' | 'expenses',
): PaginationParams {
  const isFromIncomes = type === 'incomes';
  const filterMap: Record<string, string> = {
    rfc: isFromIncomes ? 'q[receiver_rfc_i_cont]' : 'q[emitter_rfc_i_cont]',
    legalName: isFromIncomes
      ? 'q[receiver_legal_name_i_cont]'
      : 'q[emitter_legal_name_i_cont]',
  };

  const defaultFilters: PaginationParams = {
    'q[is_emited_true]': isFromIncomes ? 1 : 0,
    'q[not_declaration_entries]': 1,
    'q[is_pue_true]': 1,
    'q[status_eq]': 1,
  };

  const ransackFilters: PaginationParams = { ...defaultFilters };

  filters.forEach((filter) => {
    const ransackKey = filterMap[filter.id];
    if (ransackKey && filter.value) {
      ransackFilters[ransackKey] = filter.value as string;
    }

    if (
      filter.type === 'dateRange' &&
      filter.id === 'issuedDates' &&
      filter.value
    ) {
      const [startDate, endDate] = filter.value.map((dateString) =>
        dayjs(dateString).isValid() ? dayjs(dateString).format(format) : null,
      );
      if (startDate) ransackFilters['q[date_issued_gteq_all]'] = startDate;
      if (endDate) ransackFilters['q[date_issued_lteq_all]'] = endDate;
    }
  });

  return ransackFilters;
}
