import { call } from 'redux-saga/effects';
import { ErrorLayout, ErrorHandler } from '@util/Saga';
import api from '@api';
import { deleteProductSuccess, deleteProductFailure } from '../actions';

export default function* ({ payload: { taxable_entity, product_id } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.delete,
      `taxable_entities/${taxable_entity.id}/products/${product_id}`,
      { headers: { Authorization: `${access_token}` } }
    );
    response.data = product_id;

    yield call(ErrorLayout, {
      response,
      status: response.status,
      success: deleteProductSuccess,
      failure: deleteProductFailure,
      title: 'Éxito',
      message: 'El producto de ha borrado exitosamente',
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: deleteProductFailure,
    });
  }
}
