import React from 'react';
import { Row } from 'reactstrap';
import { PLAN_NAMES, EXTRA_SERVICE_NAMES } from '@constants/subscription';
import { Colxx as Col } from '@components/CustomBootstrap';
import IntlMessages from '@util/IntlMessages';

export function OrderServicesSubscriptionDetails({ services }) {
  if (services) {
    const PRODUCT_NAMES = {
      ...PLAN_NAMES,
      ...EXTRA_SERVICE_NAMES,
    };

    return (
      <div>
        <Row
          className="font-weight-bold text-primary mt-2"
          style={{ fontSize: '1rem' }}
        >
          <Col xxs={{ size: 'auto', offset: 0 }}>
            <IntlMessages id="settings.plan.summary" />
          </Col>
        </Row>
        {services.map((service, idx) => (
          <Row key={`subscription-${idx}`}>
            <Col xxs={{ size: 7, offset: 1 }}>
              {PRODUCT_NAMES[service?.service.name]} (Mensual) x{' '}
              {service.quantity}
            </Col>
            <Col xxs={{ size: 3 }} align="right">
              ${service.total}
            </Col>
          </Row>
        ))}
      </div>
    );
  }
  return null;
}

export function OrderServicesTADetails({ services }) {
  if (services) {
    return (
      <div>
        <Row
          className="font-weight-bold text-primary mt-2"
          style={{ fontSize: '1rem' }}
        >
          <Col xxs={{ size: 'auto', offset: 0 }}>
            <IntlMessages id="settings.plan.summary" />
          </Col>
        </Row>
        {services.map((service, idx) => (
          <Row key={`ta-${idx}`}>
            <Col xxs={{ size: 7, offset: 1 }}>
              Meses de atraso: {service.arrear_months}
            </Col>
            <Col xxs={{ size: 3 }} align="right">
              ${service.total}
            </Col>
          </Row>
        ))}
      </div>
    );
  }
  return null;
}
