import { Notification } from 'types/entities';
import { Checkbox } from '@konta/ui';
import { CheckedState } from '@components/FormikCheckbox';
import { RowItem, TableRow } from './styled';
import useNotificationCenter from '../useNotificationCenter';

type UseNotificationCenter = ReturnType<typeof useNotificationCenter>;
interface NotificationGroupProps {
  getNotificationsUtils: UseNotificationCenter['getNotificationsUtils'];
  title: string;
  notifications: Notification[];
  bulkHandleChange: UseNotificationCenter['bulkHandleChange'];
  isSubtitle?: boolean;
}

export default function NotificationGroup({
  getNotificationsUtils,
  title,
  notifications,
  bulkHandleChange,
  isSubtitle,
}: NotificationGroupProps) {
  const allNotifications = notifications.reduce((acc, notification) => {
    if (notification.notifications) {
      return [...acc, ...notification.notifications];
    }
    return [...acc, notification];
  }, [] as Notification[]);

  const {
    hasEmailNotifications,
    hasWhatsappNotifications,
    emailNotifications,
    whatsappNotifications,
    emailCheckState,
    whatsappCheckState,
  } = getNotificationsUtils(allNotifications);

  return (
    <TableRow {...(isSubtitle && { type: 'subtitle' })} key={title}>
      <RowItem {...(isSubtitle && { size: 'm' })}>{title}</RowItem>
      <RowItem>
        {hasEmailNotifications && (
          <Checkbox
            checked={emailCheckState}
            onCheckedChange={(checked: CheckedState) => {
              bulkHandleChange(
                !checked,
                emailNotifications.map(
                  ({ emailKey: innerEmailKey }) => innerEmailKey,
                ),
              );
            }}
          />
        )}
      </RowItem>
      <RowItem>
        {hasWhatsappNotifications && (
          <Checkbox
            checked={whatsappCheckState}
            onCheckedChange={(checked: CheckedState) => {
              bulkHandleChange(
                !checked,
                whatsappNotifications.map(
                  ({ whatsappKey: innerWhatsappKey }) => innerWhatsappKey,
                ),
              );
            }}
          />
        )}
      </RowItem>
    </TableRow>
  );
}
