import { styled } from '@konta/ui';
import LetterAvatar from 'react-avatar';

export const Header = styled('header', {
  display: 'flex',
  alignItems: 'center',
  padding: '20px 24px',
  borderBottom: '1px solid $gray200',
  gap: '16px',
});
export const HeaderAvatar = styled(LetterAvatar, {
  width: '54px!important',
  height: '54px!important',
});

export const HeaderInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});
export const HeaderTitle = styled('span', {
  color: '$gray900',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '28px',
  display: 'flex',
  gap: '8px',
});
export const HeaderDescription = styled('span', {
  color: '$gray500',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
});
