import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { updateUserSuccess, updateUserFailure } from '../actions';

export default function* ({ payload: { user, options } }) {
  try {
    const response = yield call(api.put, '/', user, {
      headers: { Authorization: `${localStorage.getItem('user_id')}` },
    });
    const { access_token } = response.data;
    localStorage.setItem('user_id', access_token);
    yield call(StatusHandler, {
      response,
      options,
      title: 'Exito!',
      message: 'La contraseña fue actualizada correctamente',
      success: updateUserSuccess,
      failure: updateUserFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: updateUserFailure,
    });
  }
}
