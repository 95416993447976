import dayjs from 'dayjs';

export default function getGreetingText(date = dayjs()) {
  const hour = Number(date.format('H'));
  const splitAfternoon = 12;
  const splitEvening = 18;

  const isMorning = 5 <= hour && hour < splitAfternoon;
  const isAfternoon = splitAfternoon <= hour && hour < splitEvening;

  if (isMorning) {
    return 'Buenos días';
  }
  if (isAfternoon) {
    return 'Buenas tardes';
  }

  return 'Buenas noches';
}
