import { Section, Carousel, Flex, Button } from '@konta/ui';
import { TaxableEntityFlow } from 'types/flows';
import Loader from 'shared/components/Loader';
import { PlusLineIcon } from '@konta/icons';
import Navigation from './Navigation';
import Subtitle from './Subtitle';
import InsightsDialog from './InsightsDialog';
import useInsights from './useInsights';
import { slideStyles, seenCss, unseenCss } from './styled';

export default function Insights() {
  const {
    carouselRef,
    buttonState,
    handleExternalPrev,
    handleExternalNext,
    isMobileOrTablet,
    currentMonth,
    handleFlowSelect,
    carouselItems,
    taxableEntityFlowsLoading,
    showNavigation,
  } = useInsights();
  if (!carouselItems.length && !taxableEntityFlowsLoading) return null;
  return (
    <>
      <InsightsDialog />
      <Section
        data-intercom-target="insights-section"
        title={`Tus recomendaciones mensuales de ${currentMonth} 👍🏼`}
        subtitle={<Subtitle isMobileOrTablet={isMobileOrTablet} />}
        subtitleRightElement={
          <Navigation
            handleExternalPrev={handleExternalPrev}
            handleExternalNext={handleExternalNext}
            buttonState={buttonState}
            isVisible={!isMobileOrTablet && showNavigation}
          />
        }
        content={
          taxableEntityFlowsLoading ? (
            <Flex
              css={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                height: '40px',
              }}
            >
              <Loader />
            </Flex>
          ) : (
            <Flex gap={16} column align="start">
              <Carousel
                trackProps={{
                  // @ts-ignore TODO: Stitches error
                  'data-intercom-target': 'insights-carousel',
                }}
                items={carouselItems}
                ref={carouselRef}
                externalControl
                slideStyles={(item) => {
                  const flow = item.taxableEntityFlow as TaxableEntityFlow;
                  return {
                    ...slideStyles,
                    ...(flow?.status === 'completed' ? seenCss : unseenCss),
                  };
                }}
                onButtonStateChange={buttonState.setButtonState}
                onSlideClick={(item, index) => {
                  handleFlowSelect(index);
                }}
              />
              <Button
                onClick={() =>
                  window.open(
                    'https://konta.canny.io/feature-request',
                    '_blank',
                  )
                }
                size="xs"
                css={{ color: '$primary700', fontWeight: '500' }}
                color="primary600"
                variant="text"
                rightIcon={<PlusLineIcon />}
              >
                Sugerir una recomendación
              </Button>
            </Flex>
          )
        }
      />
    </>
  );
}
