import React, { useEffect, useMemo, useState } from 'react';
import { Row, Label } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { getMonthAndYearDate } from '@util/Utils';
import toCurrency from '@util/toCurrency';
import { declarationEntriesFetch } from '@redux/actions';
// eslint-disable-next-line import/no-cycle
import WorkflowLayout from '@components/WorkflowLayout';
import {
  declarationEntryExpensesFilterOptions,
  expensesAccountingStatusFilterOptions,
} from '@constants/declarationEntries';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import getTotalExpenses from '@util/getTotalExpenses';
import TableRowItem from 'shared/components/TableRowItem/TableRowItem';
import { getDocument } from '@util/declarationEntries';
import EntryUpdateConfirmModal from '@components/EntryUpdateConfirmModal';
import List from '@components/List';
import { FilterControl, FilterProvider, Flex, Text } from '@konta/ui';
import filteredDeclarationsEntries from '@util/filteredDeclarationsEntries';
import useListPagination from '@hooks/useListPagination';

const filtersList = [
  {
    id: 'declaration_entry_type',
    label: 'Tipo de gasto',
    type: 'select',
    value: null,
    options: declarationEntryExpensesFilterOptions,
  },
  {
    id: 'accounting_status',
    label: 'Estatus',
    type: 'select',
    value: null,
    options: expensesAccountingStatusFilterOptions,
  },
];

function ExpencesClassification({
  declaration_entries: { declaration_entries },
  workflow,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    const workflow_id = workflow.id;
    dispatch(declarationEntriesFetch({ workflow_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow.id]);

  const [selectedEntry, setSelectedEntry] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  const formattedTotal = useMemo(
    () => toCurrency(+getTotalExpenses(declaration_entries)),
    [declaration_entries],
  );

  const expensesEntries = useMemo(() => {
    return declaration_entries.filter(
      (entry) =>
        entry.declaration_entry_type === 'foreign_expenses' ||
        entry.declaration_entry_type === 'national_expenses' ||
        entry.declaration_entry_type === 'foreign_products' ||
        entry.declaration_entry_type === 'foreign_invoices' ||
        entry.declaration_entry_type === 'national_egress_received' ||
        entry.declaration_entry_type === 'pastmonths_cancelled_received' ||
        entry.declaration_entry_type === 'pastmonths_egress_cancelled_received',
    );
  }, [declaration_entries]);

  const activeDeclarations = useMemo(
    () => workflow?.active_declarations ?? [],
    [workflow?.active_declarations],
  );

  const filteredData = useMemo(
    () => filteredDeclarationsEntries(expensesEntries ?? [], activeFilters),
    [activeFilters, expensesEntries],
  );
  const { paginatedData, paginationProps } = useListPagination(filteredData);

  const toggleEntryModal = () => {
    setSelectedEntry(null);
  };
  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Verifica tus facturas y otros tipos de gastos"
          description={`En esta lista encontrarás las facturas del mes de ${getMonthAndYearDate(
            new Date(workflow.start_date),
          )}. Uno de nuestros contadores expertos ya revisó las facturas. Sin embargo, es importante que se revise una por una para verificar que todo esté correcto.`}
          videoId="K5b54p7VQmA"
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <div>
            <Label className="av-label" for="global_total">
              <b>Total de gastos</b>
            </Label>
            <h6>{formattedTotal}</h6>
          </div>
          <PrimaryPhaseButton />
        </>
      }
    >
      <FilterProvider
        filters={filtersList}
        filterStorageId="appExpencesClassification"
        onFilterChange={(newFilters) => setActiveFilters(newFilters)}
      >
        <Flex>
          <FilterControl />
        </Flex>
        <div className="w-100">
          <Row
            style={{
              gap: '10px',
            }}
          >
            <List
              isEmpty={!paginatedData.length}
              paginationProps={paginationProps}
              header={
                <Text color="gray500" medium m lineHeight="m">
                  Gastos por categorizar
                </Text>
              }
            >
              {paginatedData.map((declarationEntry) => {
                const { url } = getDocument(declarationEntry);

                return (
                  <TableRowItem
                    activeDeclarations={activeDeclarations}
                    declarationEntry={declarationEntry}
                    setSelectedEntry={setSelectedEntry}
                    key={declarationEntry.id}
                    url={url}
                    oldWorkflow
                    entryType="expense"
                  />
                );
              })}
            </List>
          </Row>
        </div>
      </FilterProvider>
      {!!selectedEntry && (
        <EntryUpdateConfirmModal
          isOpen={!!selectedEntry}
          toggle={toggleEntryModal}
          entry={selectedEntry}
          withBlur
          centered
        />
      )}
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ declaration_entries, workflows }) => {
  const { current_phase_id } = workflows.selected_workflow.workflow;
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { declaration_entries, current_phase_id, workflow, wloading };
};

export default connect(mapStateToProps)(ExpencesClassification);
