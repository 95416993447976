import { useMemo, useState } from 'react';
import { Flex } from '@konta/ui';
import { ProcessType, ForeignDocument } from 'types/entities';
import NextStepButton from '@components/NextStepButton';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepDescription from '@components/ProcessStepDescription';
import ProcessStepLayout from '@components/ProcessStepLayout';
import NewForeignDeclarationEntryButton from '@components/NewForeignDeclarationEntryButton';
import ForeignDocumentsTable from '@components/ForeignDocumentsTable/ForeignDocumentsTable';
import useForeignInvoices from '@hooks/useForeignInvoices';
import useForeignProducts from '@hooks/useForeignProducts';

export default function DocumentsAnnual() {
  const [payload, setPayload] = useState<boolean>();
  const { foreignInvoices, foreignInvoicesLoading } = useForeignInvoices();
  const { foreignProducts, foreignProductsLoading } = useForeignProducts();
  const data = useMemo(
    () => [...foreignInvoices, ...foreignProducts] as ForeignDocument[],
    [foreignInvoices, foreignProducts],
  );
  const process = ProcessType.AnnualDeclarationProcess;

  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Apertura de cuenta"
      titleChip="En curso"
      content={
        <>
          <Flex direction="column" gap={12}>
            <ProcessStepTitle>Gastos en el extranjero</ProcessStepTitle>
            <ProcessStepDescription>
              En esta sección debes indicar tus gastos en el extranjero con su
              respectiva documentación.
            </ProcessStepDescription>
          </Flex>
          <Flex direction="column" gap={10}>
            <Flex gap={10} wrap="wrap">
              <NewForeignDeclarationEntryButton
                type="foreign"
                isAnnualProcess
              />
              <NewForeignDeclarationEntryButton type isAnnualProcess />
            </Flex>
          </Flex>
          <Flex direction="column" gap={10}>
            <ForeignDocumentsTable
              foreignDocuments={data}
              loading={foreignInvoicesLoading || foreignProductsLoading}
              isAnnualProcess
            />
          </Flex>
          <Flex gap={12}>
            <NextStepButton
              currentProcessType={process}
              text="Continuar"
              onClick={() => {
                setPayload(true);
              }}
              payload={payload}
              data-cy="informativeAnnualNextBtn"
            />
          </Flex>
        </>
      }
    />
  );
}
