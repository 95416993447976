import React, { useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Row, Nav } from 'reactstrap';
import { Colxx } from '@components/CustomBootstrap';
import { LinkList } from '@components';
import { useTaxableEntity } from '@hooks';
import useFeature from '@hooks/useFeature';
import { NOTIFICATION_CENTER } from '@constants/featureFlags';
import SettingsPlan from './settings-plan';
import SettingsCards from './settings-cards';
import SettingsBusiness from './settings-business';
import SettingsCredentials from './settings-credentials';
import SettingsMyBanks from './settings-my-banks';
import SettingSecurity from './security/SettingsSecurity';
import SettingsBrand from './brand/SettingsBrand';
import NotificationCenter from './NotificationCenter';

const FISCAL_REGIMEN_RESICO_ID = 6;
const featureFlagSource = {
  source: 'SettingsTab',
};
export default function SettingsTab() {
  const [isNotificationCenterEnabled] = useFeature(
    NOTIFICATION_CENTER,
    featureFlagSource,
  );
  const { pathname } = useLocation();
  const {
    taxable_entity: { fiscal_regimes, onboarding_detail },
  } = useTaxableEntity();
  const hasResicoRegimen = fiscal_regimes.some(
    ({ id }) => id === FISCAL_REGIMEN_RESICO_ID,
  );

  const settings = useMemo(() => {
    const tabs = [
      'settings.tabs.business',
      'settings.tabs.plan',
      'settings.tabs.payment-methods',
      'settings.tabs.credentials',
    ];
    const links = [
      'app/settings',
      'app/settings/plan',
      'app/settings/methods',
      'app/settings/credentials',
    ];
    if (isNotificationCenterEnabled) {
      tabs.push('settings.tabs.notifications-center');
      links.push('app/settings/notifications-center');
    }
    tabs.push('settings.tabs.security');
    links.push('app/settings/security');
    tabs.push('settings.tabs.brand');
    links.push('app/settings/brand');

    if (!onboarding_detail?.is_rejected_user && hasResicoRegimen) {
      tabs.push('settings.tabs.my-banks');
      links.push('app/settings/my-banks');
    }
    return {
      tabs,
      links,
    };
  }, [hasResicoRegimen, onboarding_detail?.is_rejected_user]);

  return (
    <div className="app-container">
      <Row>
        <Colxx xxs="12">
          <Nav tabs className="separator-tabs position-relative ml-0 mr-0 mb-5">
            <LinkList
              base=""
              current={pathname}
              tabs={settings.tabs}
              links={settings.links}
            />
          </Nav>
          <Routes>
            <Route path="/" element={<SettingsBusiness />} />
            <Route path="/plan" element={<SettingsPlan />} />
            <Route path="/brand" element={<SettingsBrand />} />
            <Route path="/methods" element={<SettingsCards />} />
            <Route path="/credentials" element={<SettingsCredentials />} />
            {hasResicoRegimen && (
              <Route path="/my-banks" element={<SettingsMyBanks />} />
            )}
            <Route path="/security" element={<SettingSecurity />} />
            {isNotificationCenterEnabled && (
              <Route
                path="/notifications-center"
                element={<NotificationCenter />}
              />
            )}
          </Routes>
        </Colxx>
      </Row>
    </div>
  );
}
