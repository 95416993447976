import { useMemo, useState } from 'react';
import useAnnualsDeclarationQuery from '@hooks/useAnnualsDeclarationQuery';
import dayjs from 'dayjs';
import {
  BalancePaymentType,
  PreferencesAnnualProcessStep,
  ProcessType,
} from 'types/entities';
import toCurrency from '@util/toCurrency';
import usePreferencesAnnualForm, {
  PreferencesAnnualPayload,
} from '@hooks/usePreferencesAnnualForm';
import { RESICO_SAT_KEY } from 'shared/constants/grids';
import { getBase64FromFile } from 'backoffice/util/getBase64FromFile';
import useFeature from '@hooks/useFeature';
import { PAYMENT_IN_INSTALLMENTS_FOR_PREFERENCES_STEP } from '@constants/featureFlags';

const oneYearAgo = dayjs().subtract(1, 'year').year();
type PreferencesAnnualAnswersStep = PreferencesAnnualProcessStep['answers'];

interface BankStatement {
  name: string;
  contents: string | null;
}

export default function usePreferencesAnnual() {
  const {
    loadingDeclaration: loadingAnnualDeclarations,
    declarations: annualDeclarations,
    isError,
  } = useAnnualsDeclarationQuery();
  const [payload, setPayload] = useState<PreferencesAnnualAnswersStep>();
  const [extraPayload, setExtraPayload] = useState<
    Record<string, BankStatement>
  >({});
  const process = ProcessType.AnnualDeclarationProcess;

  const [isEnabledPaymentInInstallments] = useFeature(
    PAYMENT_IN_INSTALLMENTS_FOR_PREFERENCES_STEP,
  );

  const currentDeclaration = useMemo(
    () =>
      annualDeclarations?.find(
        (declaration) =>
          declaration?.period === oneYearAgo &&
          !declaration?.fiscal_regime?.sat_key,
      ),
    [annualDeclarations],
  );

  const currentDeclarationResico = useMemo(
    () =>
      annualDeclarations?.find(
        (declaration) =>
          declaration?.period === oneYearAgo &&
          declaration?.fiscal_regime?.sat_key === RESICO_SAT_KEY,
      ),
    [annualDeclarations],
  );

  const hasResico = Boolean(currentDeclarationResico);
  const hasDeclaration = Boolean(currentDeclaration);

  const hasError =
    isError || (!currentDeclaration && !currentDeclarationResico);

  const handleSubmit = async (values: PreferencesAnnualPayload) => {
    const { balance, bank_name, balance_resico, bank_name_resico } = values;
    const bankStatement = values.bank_statement as File;
    const bankStatementResico = values.bank_statement_resico as File;
    setExtraPayload({
      ...(bankStatement && {
        bank_statement: {
          name: bankStatement.name,
          contents: await getBase64FromFile(bankStatement),
        },
      }),
      ...(bankStatementResico && {
        bank_statement_resico: {
          name: bankStatementResico.name,
          contents: await getBase64FromFile(bankStatementResico),
          type: bankStatementResico.type,
        },
      }),
    });

    const hasTwoDeclarations = balance && balance_resico;

    setPayload({
      balance:
        (balance as BalancePaymentType) ||
        (balance_resico as BalancePaymentType),
      ...(bank_name && { bank_name }),
      ...(hasTwoDeclarations &&
        balance_resico && {
          balance_resico: balance_resico as BalancePaymentType,
        }),
      ...(bank_name_resico && {
        bank_name_resico,
      }),
    });
  };

  const { formik } = usePreferencesAnnualForm({
    onSubmitForm: handleSubmit,
  });

  const isLoading = loadingAnnualDeclarations;

  const creditBalance = +(currentDeclaration?.profit_or_loss || 0);
  const creditBalanceResico = +(currentDeclarationResico?.profit_or_loss || 0);
  const balanceInFavor = creditBalance < 0;
  const balanceInFavorResico = creditBalanceResico < 0;
  const formatBalance = toCurrency(Math.abs(creditBalance));
  const formatBalanceResico = toCurrency(Math.abs(creditBalanceResico));
  const textForBalance = balanceInFavor ? 'favor' : 'pagar';
  const textForBalanceResico = balanceInFavorResico ? 'favor' : 'pagar';

  const enabledNextBtn = useMemo(() => {
    if (hasResico && hasDeclaration) {
      return (
        !!formik.values.balance &&
        !!formik.values.balance_resico &&
        formik.isValid
      );
    }
    if (hasResico) {
      return !!formik.values.balance_resico && formik.isValid;
    }
    if (hasDeclaration) {
      return !!formik.values.balance && formik.isValid;
    }
    return false;
  }, [
    formik.isValid,
    formik.values.balance,
    formik.values.balance_resico,
    hasDeclaration,
    hasResico,
  ]);

  return {
    currentDeclaration,
    hasError,
    payload,
    setPayload,
    process,
    isLoading,
    formatBalance,
    formatBalanceResico,
    textForBalance,
    textForBalanceResico,
    enabledNextBtn,
    formik,
    balanceInFavor,
    balanceInFavorResico,
    creditBalanceResico,
    extraPayload,
    hasResico,
    hasDeclaration,
    isEnabledPaymentInInstallments,
  };
}
