import { NotificationsType } from 'types/entities';

const notificationsPreferences: NotificationsType = [
  {
    title: 'General',
    notifications: [
      {
        name: 'Notificaciones de consejos konta',
        notifications: [
          {
            name: 'ConsejosKonta',
            whatsappKey: 'disable_whatsapp_onb_consejos_konta',
          },
          {
            name: 'Información sobre la Línea Konta',
            whatsappKey: 'disable_whatsapp_onb_atencion',
            emailKey: 'disable_email_konta_line',
          },
          {
            name: 'Aprender a facturar',
            whatsappKey: 'disable_whatsapp_onb_aprender_facturar',
            emailKey: 'disable_email_invoicing',
          },
          {
            name: 'Aprender a facturar PPD',
            whatsappKey: 'disable_whatsapp_onb_onb_aprender_facturar_ppd',
            emailKey: 'disable_email_how_to_invoice_ppd',
          },
          {
            name: 'Impuestos 101',
            whatsappKey: 'disable_whatsapp_onb_impuestos101',
            emailKey: 'disable_email_taxes101',
          },
          {
            name: 'Mitos sobre los impuestos',
            whatsappKey: 'disable_whatsapp_onb_mitos_impuestos',
            emailKey: 'disable_email_taxes_myths',
          },
          {
            name: 'Checkpoint: cómo va tu experiencia?',
            whatsappKey: 'disable_whatsapp_onb_checkpoint_1',
            emailKey: 'disable_email_how_is_your_experience',
          },
          {
            name: 'Recordatorio: realiza tu factura al público general',
            whatsappKey: 'disable_whatsapp_onb_factura_publico_general',
            emailKey: 'disable_email_how_to_invoice_general',
            underline: true,
          },
        ],
      },
    ],
  },
];

export default notificationsPreferences;
