import PropTypes from 'prop-types';
import React from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

// Loading component, just displays the loading icon
export default function (_props) {
  return <div className="loading--component" />;
}

export function Loader({ children, loading = false, ...rest }) {
  return (
    // Block UI and display loading spinner if blocking is true
    <BlockUi
      loader={<i className="loading--component" />}
      tag="section"
      blocking={loading}
      {...rest}
    >
      <>{children}</>
    </BlockUi>
  );
}

Loader.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
};
