import React from 'react';
import { Flex, Text, DataTable } from '@konta/ui';
import { FileAttachment03LineIcon } from '@konta/icons';
import { ConfirmModal } from '@components/index';
import ComplementTableForm from '@components/ComplementTableForm';
import ContainerButton from 'shared/components/ContainerButton';
import useUploadInvoiceComplementModal from './useUploadInvoiceComplementModal';

export default function UploadInvoiceComplement() {
  const {
    isLoading,
    ppds,
    showConfirmation,
    toggleShowConfirmation,
    tableColumns,
    selectedPpd,
    togglePpdFormModal,
    onCloseModal,
    taxableEntityPreferences,
  } = useUploadInvoiceComplementModal();

  return (
    <>
      <ContainerButton
        title="Selecciona La factura ppd a la que le quieres emitir un complemento de
        pago"
        icon={<FileAttachment03LineIcon />}
        onClick={toggleShowConfirmation}
      />

      <ConfirmModal
        open={showConfirmation}
        title="Complementos de facturas PPD"
        onClose={onCloseModal}
        onCancel={onCloseModal}
        onAccept={onCloseModal}
        loading={isLoading}
        size="xl"
        withBlur
        centered
      >
        <Flex column gap={12}>
          <Text
            color="gray700"
            l
            css={{
              lineHeight: '28px',
            }}
          >
            Selecciona La factura ppd a la que le quieres emitir un complemento
            de pago.{' '}
          </Text>
          <DataTable // @ts-ignore TODO: fix typing with react-table
            columns={tableColumns}
            data={ppds}
            loading={isLoading}
            dataTableId="invoicePpdsTable"
          />
        </Flex>
        {Object.keys(selectedPpd).length > 0 && (
          <ComplementTableForm
            cfdi={selectedPpd}
            toggle={selectedPpd}
            isWorkflow={false}
            toggleModal={togglePpdFormModal}
            isPfaeAndPlatform
            taxableEntityPreferences={taxableEntityPreferences}
          />
        )}
      </ConfirmModal>
    </>
  );
}
