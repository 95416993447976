import React, { Fragment, useState } from 'react';
import { FieldArray, FormikProvider } from 'formik';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Collapse,
  Label,
  Row,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useToggle } from 'rooks';
import {
  CURRENCY_OPTIONS,
  EXPORT_CODE_OPTIONS,
  FISCAL_RESIDENCE_OPTIONS,
  INVOICE_TYPE_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  PERIODICITY_OPTIONS,
  TAXABLE_CODE_OPTIONS,
  UNIT_TYPE_OPTIONS,
  USE_CFDI_OPTIONS,
} from '@constants/invoicing';
import useInvoiceForm from '@hooks/useInvoiceForm';
import toCurrency from '@util/toCurrency';
import { Colxx, Separator } from '@components/CustomBootstrap';
import FormikTextField from '@components/FormikTextField';
import FormikSelect from '@components/FormikSelect';
import FormikUserRegimeSelect from '@components/FormikUserRegimeSelect';
import UploadCsdCard from '@components/UploadCsdCard';
import FormikClientSelect from '@components/FormikClientSelect';
import FormikRegimeSelect from '@components/FormikRegimeSelect';
import FormikUserProductsSelect from '@components/FormikUserProductsSelect';
import FormikTaxesField from '@components/FormikTaxesField';
import ConfirmInvoiceModal from '@components/ConfirmInvoiceModal';
import FormikClientInvoiceSelect from '@components/FormikClientInvoiceSelect';
import FormikPeriodicityMonthField from '@components/FormikPeriodicityMonthField';
import { NotificationManager } from '@components/Notifications';

export default function InvoiceForm() {
  const navigate = useNavigate();
  const [payload, setPayload] = useState(null);
  const invoice = useInvoiceForm({
    onSubmitForm: (data) => {
      setPayload(data.payload);
    },
    onSuccess: () => {
      NotificationManager.success(
        'Factura creada con éxito.',
        'Factura creada',
        6000,
      );
      navigate('/app/invoicing');
    },
  });
  const { values } = invoice.formik;

  const taxableEntity = useSelector((state) => {
    return state.taxableEntity.taxable_entity;
  });
  const user = useSelector((state) => state.authUser.user);
  const [showOptions, toggleShowOptions] = useToggle(false);

  const hasCsd = taxableEntity?.has_csd;
  const hasRegimes = taxableEntity?.fiscal_regimes.length > 0;
  const disableCfdiUsage =
    invoice.genericRfc &&
    !!invoice.formik.values.creditNoteInvoice?.value.cfdi_use_id;

  return (
    <>
      <FormikProvider value={invoice.formik}>
        <h1>Nueva factura</h1>
        {!hasCsd && (
          <Alert color="primary" className="mb-3 rounded-alert">
            Recuerda que debes de actualizar tus certificados de sellos
            digitales CSD.
          </Alert>
        )}
        {!hasRegimes && (
          <Alert color="danger" className="mb-3 rounded-alert">
            Debes agregar algún régimen fiscal para poder continuar con la
            facturación
          </Alert>
        )}
        <Card>
          <CardBody className="row">
            <Colxx md={12}>
              <h3>Datos del emisor</h3>
              <Separator className="mb-2" />
            </Colxx>
            <Colxx lg={6} className="mb-3">
              <FormikUserRegimeSelect
                disabledOnEmpty
                label="Régimen fiscal"
                name="fiscalRegime"
              />
            </Colxx>
            {!invoice.userHasPostcode && (
              <Colxx md={3}>
                <FormikTextField name="postcode" label="Código postal" />
              </Colxx>
            )}
            {!hasCsd && (
              <Colxx xxs={12}>
                <UploadCsdCard />
              </Colxx>
            )}
            <Colxx md="12">
              <h3>Datos de la factura</h3>
              <Separator className="mb-2" />
            </Colxx>
            <Colxx md={12} className="mt-2 mb-3">
              <p className="font-weight-bold">
                {taxableEntity?.legal_name || user?.first_name}
              </p>
            </Colxx>
            <Colxx lg={4} className="mb-4">
              <FormikSelect
                name="invoiceType"
                label="Tipo de factura"
                options={INVOICE_TYPE_OPTIONS}
                onChange={invoice.setInvoiceType}
              />
            </Colxx>
            <Colxx xxs={12} lg={4}>
              <FormikSelect
                disabled={invoice.isPPD}
                name="paymentType"
                label="Forma de pago"
                options={PAYMENT_TYPE_OPTIONS}
              />
            </Colxx>
            <Colxx xxs={12} lg={4} className="mb-4">
              <FormikSelect
                name="paymentMethod"
                label="Método de pago"
                options={PAYMENT_METHOD_OPTIONS}
                onChange={invoice.setInvoicePaymentMethod}
              />
            </Colxx>
            <Colxx lg={4}>
              <FormikClientSelect
                editable
                name="client"
                label="Cliente"
                onChange={invoice.setClient}
              />
            </Colxx>
            <Colxx lg={4}>
              <FormikRegimeSelect
                name="clientRegime"
                label="Régimen"
                availableRegimes={values.client?.value.fiscal_regimes}
                disabled={!values.client}
              />
            </Colxx>
            <Colxx lg={4} className="mb-3">
              <FormikSelect
                name="cfdiUsage"
                label="Uso CFDI"
                disabled={disableCfdiUsage}
                options={USE_CFDI_OPTIONS}
              />
            </Colxx>
            <Colxx lg={4} className="mb-4">
              <FormikSelect
                name="exportCode"
                label="Clave de exportación"
                options={EXPORT_CODE_OPTIONS}
              />
            </Colxx>
            {invoice.generalPublicRfc && values.invoiceType.value !== 'E' && (
              <>
                <Colxx lg={3} className="mb-3">
                  <FormikSelect
                    name="periodicity"
                    label="Periodicidad"
                    options={PERIODICITY_OPTIONS}
                    onChange={invoice.setPeriodicity}
                  />
                </Colxx>

                <Colxx lg={3} className="mb-3">
                  <FormikPeriodicityMonthField
                    name="periodicityMonth"
                    periodicity={values.periodicity?.value}
                  />
                </Colxx>

                <Colxx lg={2}>
                  <FormikTextField
                    name="periodicityYear"
                    label="Año"
                    type="number"
                  />
                </Colxx>
              </>
            )}
            {invoice.foreignRfc && (
              <>
                <Colxx lg={4}>
                  <FormikSelect
                    name="taxResidency"
                    label="Residencia Fiscal"
                    options={FISCAL_RESIDENCE_OPTIONS}
                  />
                </Colxx>
                <Colxx lg={4}>
                  <FormikTextField
                    name="taxIdentityRegistrationNumber"
                    label="Clave de identificación fiscal"
                  />
                </Colxx>
              </>
            )}
            {values.invoiceType?.value === 'E' && (
              <Colxx lg={12} className="mb-4">
                <FormikClientInvoiceSelect
                  name="creditNoteInvoice"
                  onChange={invoice.setCreditNoteInvoice}
                  label="Facturas"
                  client={values.client?.value}
                />
              </Colxx>
            )}
            <Colxx md={12}>
              <Button
                color="link"
                className="btn-right"
                onClick={toggleShowOptions}
              >
                Ver opciones adicionales
                <i
                  className={`${
                    showOptions
                      ? 'simple-icon-arrow-down'
                      : 'simple-icon-arrow-up'
                  } align-middle ml-1`}
                />
              </Button>
            </Colxx>
            <Collapse isOpen={showOptions} className="w-100 m-3">
              <Row>
                <Colxx md={12}>
                  <h3>Opciones adicionales</h3>
                  <Separator className="mb-3" />
                </Colxx>
                {invoice.userHasPostcode && (
                  <Colxx xxs={6} md={3}>
                    <FormikTextField name="postcode" label="Código postal" />
                  </Colxx>
                )}
                <Colxx xxs={6} lg={3} className="mb-4">
                  <FormikSelect
                    name="currency"
                    label="Moneda"
                    options={CURRENCY_OPTIONS}
                  />
                </Colxx>
                <Colxx xxs={6} lg={3}>
                  <FormikTextField
                    name="exchangeRate"
                    label="Tipo de cambio"
                    type="number"
                    disabled={values.currency.value === 'MXN'}
                  />
                </Colxx>
                <Colxx xs={6} lg={3}>
                  <FormikTextField name="orderNumber" label="Número de orden" />
                </Colxx>
                <Colxx xs={6} lg={3}>
                  <FormikTextField name="invoiceNumber" label="Folio Interno" />
                </Colxx>
                <Colxx xs={6} lg={3}>
                  <FormikTextField
                    name="createdDate"
                    label="Fecha de emisión"
                    type="datetime-local"
                  />
                </Colxx>
                {!invoice.foreignRfc && (
                  <Colxx xs={6} lg={3}>
                    <FormikSelect
                      name="taxResidency"
                      label="Residencia Fiscal"
                      options={FISCAL_RESIDENCE_OPTIONS}
                    />
                  </Colxx>
                )}
              </Row>
            </Collapse>
            <Colxx md="12">
              <h3>Conceptos</h3>
              <Separator className="mb-3" />
            </Colxx>
            <FieldArray
              name="concepts"
              render={() => (
                <>
                  {values.concepts.map((concept, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                      <Colxx lg="7">
                        <FormikUserProductsSelect
                          name={`concepts[${index}].product`}
                          label="Producto"
                          onChange={(value) => {
                            invoice.setConceptProduct(value, index);
                          }}
                        />
                      </Colxx>
                      <Colxx lg={5} className="mb-3">
                        <FormikSelect
                          name={`concepts[${index}].taxableCode`}
                          label="Objeto de impuesto"
                          onChange={(value) => {
                            invoice.setConceptTaxableCode(value, index);
                          }}
                          options={TAXABLE_CODE_OPTIONS}
                          disabled={!concept.product}
                        />
                      </Colxx>
                      <Colxx lg="1">
                        <FormikTextField
                          name={`concepts[${index}].quantity`}
                          label="Cantidad"
                          type="number"
                          disabled={!concept.product}
                          onChange={({ target }) =>
                            invoice.setConceptQuantity(target.value, index)
                          }
                        />
                      </Colxx>
                      <Colxx lg="2">
                        <FormikSelect
                          name={`concepts[${index}].unit`}
                          label="Unidad"
                          options={UNIT_TYPE_OPTIONS}
                          disabled={!concept.product}
                        />
                      </Colxx>
                      <Colxx lg="2">
                        <FormikTextField
                          name={`concepts[${index}].price`}
                          label="Precio unitario"
                          type="number"
                          disabled={!concept.product}
                          onChange={({ target }) =>
                            invoice.setConceptPrice(target.value, index)
                          }
                        />
                      </Colxx>
                      <Colxx lg="2">
                        <FormikTextField
                          name={`concepts[${index}].formattedSubtotal`}
                          label="Subtotal"
                          disabled
                        />
                      </Colxx>
                      <Colxx lg="1">
                        <FormikTaxesField
                          label="Impuestos"
                          name={`concepts[${index}]`}
                        />
                      </Colxx>
                      <Colxx lg="2">
                        <FormikTextField
                          name={`concepts[${index}].discount`}
                          label="Descuento fijo"
                          type="number"
                          placeholder="Ej. $200.00"
                          disabled={!concept.product}
                          step={0.01}
                          onChange={({ target }) =>
                            invoice.setConceptDiscount(target.value, index)
                          }
                        />
                      </Colxx>
                      <Colxx lg="2">
                        <FormikTextField
                          name={`concepts[${index}].formattedAmount`}
                          label="Importe"
                          disabled
                        />
                      </Colxx>
                      <Colxx lg="12" className="mb-2">
                        {values.concepts.length > 1 && (
                          <Button
                            outline
                            color="danger"
                            className="d-flex btn-right"
                            onClick={() => invoice.removeConcept(index)}
                          >
                            <i className="simple-icon-trash" />
                          </Button>
                        )}
                      </Colxx>
                    </Fragment>
                  ))}
                  <Colxx lg="12" className="mb-4">
                    <Button
                      outline
                      size="sm"
                      data-test="add-concept-button"
                      className="d-flex"
                      onClick={invoice.addConcept}
                    >
                      <i
                        className="iconsmind-Add mr-2"
                        style={{
                          fontSize: 20,
                        }}
                      />
                      Agregar Concepto
                    </Button>
                  </Colxx>
                </>
              )}
            />
            <Colxx sm={12}>
              <Separator className="my-3" />
            </Colxx>
            <Colxx sm={7} className="offset-md-3 mt-2">
              <Row className="float-right">
                <Colxx sm="auto" className="text-right">
                  <Label className="av-label">
                    <b>Subtotal</b>
                  </Label>
                  <h6 data-testid="subtotal-label">
                    {toCurrency(values.subtotal)}
                  </h6>
                </Colxx>
                {values.iva?.rates?.[0.08]?.amount >= 0 && (
                  <Colxx sm="auto" className="text-right">
                    <Label className="av-label">
                      <b>IVA 8%</b>
                    </Label>
                    <h6 data-testid="iva8-label">
                      {toCurrency(values.iva.rates[0.08].amount)}
                    </h6>
                  </Colxx>
                )}
                {values.iva?.rates?.[0.16]?.amount >= 0 && (
                  <Colxx sm="auto" className="text-right">
                    <Label className="av-label">
                      <b>IVA 16%</b>
                    </Label>
                    <h6 data-testid="iva16-label">
                      {toCurrency(values.iva.rates[0.16].amount)}
                    </h6>
                  </Colxx>
                )}
                {values.retainedIva?.amount > 0 && (
                  <Colxx sm="auto" className="text-right">
                    <Label className="av-label">
                      <b>IVA RET</b>
                    </Label>
                    <h6 data-testid="ivaret-label">
                      {toCurrency(values.retainedIva.amount)}
                    </h6>
                  </Colxx>
                )}
                {values.ieps?.amount > 0 && (
                  <Colxx sm="auto" className="text-right">
                    <Label className="av-label">
                      <b>IEPS</b>
                    </Label>
                    <h6 data-testid="ieps-label">
                      {toCurrency(values.ieps.amount)}
                    </h6>
                  </Colxx>
                )}
                {values.isr?.amount > 0 && (
                  <Colxx sm="auto" className="text-right">
                    <Label className="av-label">
                      <b>ISR</b>
                    </Label>
                    <h6 data-testid="isr-label">
                      {toCurrency(values.isr.amount)}
                    </h6>
                  </Colxx>
                )}
                {values.discount > 0 && (
                  <Colxx sm="auto" className="text-right">
                    <Label className="av-label">
                      <b>Descuento</b>
                    </Label>
                    <h6 data-testid="discount-label">
                      {toCurrency(values.discount)}
                    </h6>
                  </Colxx>
                )}

                <Colxx sm="auto" className="text-right">
                  <Label className="av-label">
                    <b>Total</b>
                  </Label>
                  <h6 data-testid="total-label">{toCurrency(values.total)}</h6>
                </Colxx>
              </Row>
            </Colxx>
            <Colxx sm={2}>
              <Row>
                <Colxx sm={12} className="mt-3">
                  <Button
                    color="primary"
                    onClick={() => invoice.formik.submitForm()}
                    data-testid="submit-button"
                  >
                    Emitir factura
                  </Button>
                </Colxx>
              </Row>
            </Colxx>
          </CardBody>
        </Card>
      </FormikProvider>
      <ConfirmInvoiceModal
        isOpen={!!payload}
        invoice={payload}
        toggle={() => setPayload(null)}
        onConfirm={() => invoice.oldStamp(payload)}
      />
    </>
  );
}
