import { useQuery, UseQueryOptions } from 'react-query';
import { SingInResponse, User } from 'types/entities';
import { SIGN_IN } from 'backoffice/constants/reactQueries';
import { getSignIn } from 'backoffice/api/auth';
import { setUserDataToOpenReplay } from '../../@util/OpenReplay';

export default function useSignIn(
  queryOptions?: UseQueryOptions<SingInResponse>,
) {
  const {
    data = null,
    isLoading,
    ...rest
  } = useQuery<SingInResponse>(
    [SIGN_IN],
    async () => {
      const signInData = await getSignIn();
      return signInData;
    },
    {
      ...queryOptions,
      onSuccess: (user) => {
        setUserDataToOpenReplay({
          user,
          taxable_entity: user.taxable_entity,
        });
      },
      onError: () => {
        window.localStorage.removeItem('user_id');
      },
    },
  );
  const { taxable_entity, ...user } = data || {};
  return {
    signInLoading: isLoading,
    user: user as User,
    taxableEntity: taxable_entity,
    ...rest,
  };
}
