import React, { useState } from 'react';
import { Flex, Text } from '@konta/ui';
import { FormikProvider } from 'formik';
import { CSS } from '@konta/ui/dist/stitches.config';
import {
  DateStr,
  InformativeDataAnnualStep,
  ProcessType,
} from 'types/entities';
import ProcessStepLayout from '@components/ProcessStepLayout';
import useInformativeAnnualForm, {
  InformativeAnnualPayload,
} from '@hooks/useInformativeAnnualForm';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepDescription from '@components/ProcessStepDescription';
import FormikCheckbox from '@components/FormikCheckbox';
import FormikTextInput from '@components/FormikTextInput';
import NextStepButton from '@components/NextStepButton';
import FormikSelect from 'shared/components/FormikSelect';
import { countryOptions } from 'backoffice/constants/countryOptions';

const fieldCss: CSS = { pl: '24px', maxWidth: '400px' };
const process = ProcessType.AnnualDeclarationProcess;
type InformativeAnnualAnswerPayload = InformativeDataAnnualStep['answers'];
export default function InformativeAnnual() {
  const [payload, setPayload] = useState<InformativeAnnualAnswerPayload | null>(
    null,
  );
  const handleSubmit = (values: InformativeAnnualPayload) => {
    const {
      vehicle_sale_amount,
      vehicle_sale_date,
      vehicle_purchased_amount,
      vehicle_purchased_date,
      donations_amount,
      governmental_support_amount,
      loans_amount,
      real_state_sale_amount,
      rewards,
      inheritance,
      charged_viaticals,
      has_foreign_income_or_pending_taxes,
      income_received,
      foreign_tax_paid,
      foreign_tax_paid_dividends,
      foreign_tax_credit,
      total_accumulable_income,
      taxpayer_id_foreign_country,
      country_income_obtained,
      has_vehicle_sale,
    } = values;

    setPayload({
      vehicle_sale_amount,
      ...(has_vehicle_sale && {
        vehicle_sale_date: vehicle_sale_date as DateStr,
        vehicle_purchased_amount: vehicle_purchased_amount as number | null,
        vehicle_purchased_date: vehicle_purchased_date as DateStr,
      }),
      real_state_sale_amount,
      loans_amount,
      donations_amount,
      governmental_support_amount,
      rewards,
      inheritance,
      charged_viaticals,
      ...(has_foreign_income_or_pending_taxes && {
        income_received: income_received as number,
        foreign_tax_paid: foreign_tax_paid as number,
        foreign_tax_paid_dividends: foreign_tax_paid_dividends as number,
        foreign_tax_credit: foreign_tax_credit as number,
        total_accumulable_income: total_accumulable_income as number,
        taxpayer_id_foreign_country: taxpayer_id_foreign_country as string,
        country_income_obtained: country_income_obtained as string,
      }),
      foreign_income_or_pending_taxes: has_foreign_income_or_pending_taxes
        ? 'Si'
        : 'No',
    });
  };
  const { formik } = useInformativeAnnualForm({
    onSubmitForm: handleSubmit,
  });
  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Apertura de cuenta"
      titleChip="En curso"
      content={
        <FormikProvider value={formik}>
          <Flex direction="column" gap={12}>
            <ProcessStepTitle>Datos informativos</ProcessStepTitle>
            <ProcessStepDescription>
              En esta sección debes indicar si has tenido ingresos adicionales
              durante el año. Estos ingresos no son obligatorios, pero son
              necesarios para completar tu declaración anual.
              <Text>
                En caso de no haber tenido ingresos adicionales, simplemente haz
                clic en continuar.
              </Text>
            </ProcessStepDescription>
          </Flex>
          <Flex direction="column" gap={10}>
            <FormikCheckbox
              data-cy="has_vehicle_sale"
              id="has_vehicle_sale"
              name="has_vehicle_sale"
              label="Fecha en la que vendiste un automóvil"
              supportText="Indica si has vendido uno o más vehículos personales durante el año."
            />
            {formik.values.has_vehicle_sale && (
              <Flex css={fieldCss}>
                <Flex direction="column" gap={12} css={{ flex: 1 }}>
                  <FormikTextInput
                    data-cy="vehicle_sale_amount"
                    leftIcon="$"
                    name="vehicle_sale_amount"
                    label="Monto de la venta"
                    type="number"
                  />
                  <FormikTextInput
                    data-cy="vehicle_sale_date"
                    name="vehicle_sale_date"
                    label="Fecha en que vendiste un automóvil"
                    type="date"
                  />
                  <FormikTextInput
                    data-cy="vehicle_purchased_amount"
                    leftIcon="$"
                    name="vehicle_purchased_amount"
                    label="Monto en el que compraste el automóvil que vendiste"
                    type="number"
                  />
                  <FormikTextInput
                    data-cy="vehicle_purchased_date"
                    name="vehicle_purchased_date"
                    label="Fecha en que compraste el automóvil que vendiste"
                    type="date"
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
          <Flex direction="column" gap={10}>
            <FormikCheckbox
              data-cy="has_real_state_sale"
              id="has_real_state_sale"
              name="has_real_state_sale"
              label="Venta de alguna propiedad a tu nombre"
              supportText="Indica si has vendido una propiedad registrada a tu nombre y ya recibiste el pago por la misma"
            />
            {formik.values.has_real_state_sale && (
              <Flex css={fieldCss}>
                <FormikTextInput
                  data-cy="real_state_sale_amount"
                  leftIcon="$"
                  name="real_state_sale_amount"
                  label="Monto de la venta"
                  type="number"
                />
              </Flex>
            )}
          </Flex>
          <Flex direction="column" gap={10}>
            <FormikCheckbox
              data-cy="has_loans"
              id="has_loans"
              name="has_loans"
              label="Préstamos"
              supportText="Indica si obtuviste un prestamos bancarios durante el año e indica el monto total."
            />
            {formik.values.has_loans && (
              <Flex css={fieldCss}>
                <FormikTextInput
                  data-cy="loans_amount"
                  leftIcon="$"
                  name="loans_amount"
                  label="Monto del préstamo"
                  type="number"
                />
              </Flex>
            )}
          </Flex>
          <Flex direction="column" gap={10}>
            <FormikCheckbox
              data-cy="has_donations"
              id="has_donations"
              name="has_donations"
              label="Donativo"
              supportText="Indica si has recibido donativos de algún familiar o tercero. Ejemplo, un depósito de tu padre, madre, tío, amigo"
            />
            {formik.values.has_donations && (
              <Flex css={fieldCss}>
                <FormikTextInput
                  data-cy="donations_amount"
                  leftIcon="$"
                  name="donations_amount"
                  label="Monto del donativo"
                  type="number"
                />
              </Flex>
            )}
          </Flex>
          <Flex direction="column" gap={10}>
            <FormikCheckbox
              data-cy="has_rewards"
              id="has_rewards"
              name="has_rewards"
              label="Premios"
              supportText="Indica si has recibido premios durante el año, como premios de lotería, concursos, etc."
            />
            {formik.values.has_rewards && (
              <Flex css={fieldCss}>
                <FormikTextInput
                  data-cy="rewardsInput"
                  leftIcon="$"
                  name="rewards"
                  label="Monto de los premios"
                  type="number"
                />
              </Flex>
            )}
          </Flex>
          <Flex direction="column" gap={10}>
            <FormikCheckbox
              data-cy="has_inheritance"
              id="has_inheritance"
              name="has_inheritance"
              label="Herencias"
              supportText="Indica si has recibido herencias durante el año, como herencias de familiares, etc."
            />
            {formik.values.has_inheritance && (
              <Flex css={fieldCss}>
                <FormikTextInput
                  data-cy="inheritanceInput"
                  leftIcon="$"
                  name="inheritance"
                  label="Monto de las herencias"
                  type="number"
                />
              </Flex>
            )}
          </Flex>
          <Flex direction="column" gap={10}>
            <FormikCheckbox
              data-cy="hasChargedViaticals"
              id="has_charged_viaticals"
              name="has_charged_viaticals"
              label="Viáticos cobrados"
              supportText="Indica si has recibido viáticos por parte de tu empleador, como por ejemplo, un pago por gastos de traslado, hospedaje, alimentación, etc."
            />
            {formik.values.has_charged_viaticals && (
              <Flex css={fieldCss}>
                <FormikTextInput
                  data-cy="chargedViaticalsInput"
                  leftIcon="$"
                  name="charged_viaticals"
                  label="Monto de los viáticos cobrados"
                  type="number"
                />
              </Flex>
            )}
          </Flex>
          <Flex direction="column" gap={10}>
            <FormikCheckbox
              data-cy="has_governmental_support"
              id="has_governmental_support"
              name="has_governmental_support"
              label="Ingresos obtenidos por apoyos gubernamentales"
              supportText="Indica si has recibido apoyos o subsidios del gobierno, como becas, programas de asistencia social o estímulos económicos"
            />
            {formik.values.has_governmental_support && (
              <Flex css={fieldCss}>
                <FormikTextInput
                  data-cy="governmental_support_amount"
                  leftIcon="$"
                  name="governmental_support_amount"
                  label="Monto del apoyo gubernamental"
                  type="number"
                />
              </Flex>
            )}
          </Flex>
          <Flex direction="column" gap={10}>
            <FormikCheckbox
              data-cy="has_foreign_income_or_pending_taxes"
              id="has_foreign_income_or_pending_taxes"
              name="has_foreign_income_or_pending_taxes"
              label="¿Obtuviste ingresos y pagaste impuestos en el extranjero o tienes impuesto pendiente de acreditar?"
              supportText="Indica si has obtenido ingresos en el extranjero y has pagado impuestos en el extranjero o tienes impuestos pendientes de acreditar"
            />
            {formik.values.has_foreign_income_or_pending_taxes && (
              <Flex css={fieldCss}>
                <Flex direction="column" gap={12} css={{ flex: 1 }}>
                  <FormikTextInput
                    data-cy="income_received"
                    leftIcon="$"
                    name="income_received"
                    label="Ingreso percibido"
                    type="number"
                  />
                  <FormikTextInput
                    data-cy="foreign_tax_paid"
                    leftIcon="$"
                    name="foreign_tax_paid"
                    label="Impuesto pagado en el extranjero"
                    type="number"
                  />
                  <FormikTextInput
                    data-cy="foreign_tax_paid_dividends"
                    leftIcon="$"
                    name="foreign_tax_paid_dividends"
                    label="Impuesto pagado en el extranjero por dividendos"
                    type="number"
                  />
                  <FormikTextInput
                    data-cy="foreign_tax_credit"
                    leftIcon="$"
                    name="foreign_tax_credit"
                    label="Impuesto pagado en el extranjero a acreditar"
                    type="number"
                  />
                  <FormikTextInput
                    data-cy="total_accumulable_income"
                    leftIcon="$"
                    name="total_accumulable_income"
                    label="Total de ingreso acumulable"
                    type="number"
                  />
                  <FormikTextInput
                    data-cy="taxpayer_id_foreign_country"
                    name="taxpayer_id_foreign_country"
                    label="Identificación fiscal del contribuyente en el extranjero"
                    type="string"
                  />
                  <FormikSelect
                    label="País donde se obtuvo el ingreso"
                    name="country_income_obtained"
                    data-cy="country_income_obtained"
                    id="country_income_obtained"
                    options={countryOptions}
                  />
                </Flex>
              </Flex>
            )}
          </Flex>

          <Flex gap={12}>
            <NextStepButton
              currentProcessType={process}
              text="Continuar"
              onClick={() => {
                void formik.submitForm();
              }}
              payload={payload}
              data-cy="informativeAnnualNextBtn"
            />
          </Flex>
        </FormikProvider>
      }
    />
  );
}
