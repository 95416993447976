import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { ColumnFiltersState, Table as TableModel } from '@tanstack/react-table';
import useCfdisPagination from 'shared/hooks/useCfdisPagination';
import {
  AnnualDeductionCategoryKey,
  Cfdi,
  SelectedCategory,
} from 'types/entities';
import getRansackFilters from './getRansackFilters';
import useCfdisTableColumns from './useCfdisTableColumns';

interface UseCfdisTableProps {
  source: 'incomes' | 'expenses';
  periodDate?: string;
  selectedCategory?: SelectedCategory | null;
}
export default function useCfdisTable({
  source,
  periodDate,
  selectedCategory,
}: UseCfdisTableProps) {
  const cfdiUse = useMemo(
    () =>
      ({
        health: 'D01',
        education: 'D10',
        mortgage_interest: 'D05',
        retirement_fund: 'D09',
        donations: 'D04',
        insurance: 'D07',
        funeral_expenses: 'D03',
        school_transportation: 'D08',
      }[selectedCategory?.category as AnnualDeductionCategoryKey] ?? null),
    [selectedCategory?.category],
  );
  const [tableInstance, setTableInstance] = useState<TableModel<Cfdi> | null>(
    null,
  );
  const [filters, setFilters] = useState<ColumnFiltersState>([]);

  useEffect(() => {
    const currentDate = dayjs(periodDate);
    if (periodDate && periodDate?.length > 0 && currentDate.isValid()) {
      const startDate = currentDate.startOf('month').toString();
      const endDate = currentDate.endOf('month').toString();
      tableInstance
        ?.getColumn('dateIssued')
        ?.setFilterValue([startDate, endDate]);
    }

    if (selectedCategory?.name) {
      tableInstance?.getColumn('cfdiUse')?.setFilterValue(cfdiUse);
    }
  }, [periodDate, tableInstance, cfdiUse, selectedCategory]);

  const ransackFilters = useMemo(
    () =>
      getRansackFilters(
        filters,
        source,
        periodDate,
        selectedCategory?.category,
      ),
    [filters, source, periodDate, selectedCategory],
  );

  const {
    pagination,
    cfdisLoading,
    cfdis,
    refetch: refetchCfdis,
  } = useCfdisPagination({
    filters: ransackFilters,
    apiVersion: 2,
  });

  const columns = useCfdisTableColumns(source);

  return {
    pagination,
    cfdis,
    columns,
    cfdisLoading,
    refetchCfdis,
    tableInstance,
    setTableInstance,
    setFilters,
  };
}
