import { styled } from '@konta/ui';

// eslint-disable-next-line import/prefer-default-export
export const BodyContainer = styled('div', {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignSelf: 'stretch',
  height: 'calc(100% - 62px - 24px - 24px)',
  padding: '$24 $16',
  gap: '$8',
  variants: {
    stepType: {
      blog: {
        padding: '0',
      },
      text: {},
      image: {},
      tax_to_pay: {},
      deductions_limit_reached: {},
      pending_declarations: {},
      non_deductible_gas_invoices: {},
      score: {},
      balance_available_for_deductions: {},
      without_tax_effects: {},
    },
  },
});
