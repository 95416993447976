import type { Filters } from '@konta/ui';
import type { DeclarationEntry } from 'types/entities';

export default function filteredDeclarationsEntries(
  declarationEntries: DeclarationEntry[],
  filters: Filters,
) {
  return declarationEntries.filter((item) =>
    filters.every((filter) => {
      if (!filter.value) {
        return true;
      }
      if (filter.id === 'fiscal_regime_id') {
        return filter.value === item.fiscal_regime_id.toString();
      }
      if (filter.id === 'accounting_status') {
        return filter.value === item.accounting_status;
      }
      if (filter.id === 'declaration_entry_type') {
        const { source } = item;
        if (!source) {
          return true;
        }
        const { type } = source;

        if (filter.value === 'cfdi') {
          return type === 'cfdi';
        }
        if (filter.value === 'payment_detail') {
          return type === 'payment_detail';
        }
        if (filter.value === 'constancy') {
          return type === 'constancy';
        }
        if (filter.value === 'extra_income') {
          return type === 'extra_income';
        }
        if (filter.value === 'cancelled_cfdi') {
          return type === 'cancelled_cfdi';
        }
      }
      return true;
    }),
  );
}
