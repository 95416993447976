import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Row } from 'reactstrap';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { Colxx } from '@components/CustomBootstrap';
import { updateUser, addressUpdate, addressFetch } from '@redux/actions';

// import { statementUpload } from '@redux/actions'

function BasicContactInfo({ user, loading, address }) {
  const dispatch = useDispatch();
  const handleSumbit = (event, values, errors) => {
    if (values.nickname.length > 0) {
      let body = {
        user: {
          nickname: values.nickname,
        },
      };
      dispatch(updateUser(body));
      body = {
        postcode: values.postcode,
      };
      dispatch(addressUpdate(body, { silent: true }));
    }
  };

  return (
    <Row className="p-5">
      <Colxx xxs={12} md={8} className="mb-4">
        <AvForm onValidSubmit={handleSumbit} model={user} autoComplete="off">
          <Row className="mb-2">
            <Colxx xxs={12}>
              <p>
                Para nosotros poder identificarte durante el proceso de
                registro, es necesario llenar el formulario con unas preguntas
                sobre ti
              </p>
            </Colxx>
            <Colxx xxs={12} md={8}>
              <AvGroup>
                <AvField
                  name="nickname"
                  label="Apodo"
                  autoComplete="off"
                  type="text"
                  required
                />
              </AvGroup>
            </Colxx>
            <Colxx xxs={12} md={8}>
              <AvGroup>
                <AvField
                  name="postcode"
                  label="Código Postal"
                  autoComplete="off"
                  type="number"
                  defaultValue={address?.postcode}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Porfavor ingrese un código postal',
                    },
                    maxLength: {
                      value: 5,

                      errorMessage: 'El código postal debe contener 5 digitos',
                    },
                    minLength: {
                      value: 5,
                      errorMessage: 'El código postal debe contener 5 digitos',
                    },
                  }}
                />
              </AvGroup>
            </Colxx>
          </Row>
          <Row>
            <Colxx xxs={12} md={4}>
              <Button color="primary" size="sm" disabled={loading}>
                Guardar y continuar
              </Button>
            </Colxx>
          </Row>
        </AvForm>
      </Colxx>
    </Row>
  );
}

const mapStateToProps = ({ authUser, addresses }) => {
  const { user, error, loading } = authUser;
  const { address } = addresses;
  return { user, error, loading, address };
};
export default connect(mapStateToProps, {
  updateUser,
  addressUpdate,
  addressFetch,
})(BasicContactInfo);
