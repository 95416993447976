import api from '@api';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { TAXABLE_ENTITY_COUPONS } from '@constants/reactQueries';

export default function useGetTaxableEntityCouponsQuery(taxableId) {
  const {
    data = [],
    isLoading,
    ...rest
  } = useQuery(TAXABLE_ENTITY_COUPONS, async () => {
    const response = await api.get('/taxable_entity_coupons', {
      params: {
        'q[taxable_entity_id_eq]': taxableId,
      },
    });
    return response.data;
  });

  const lastCoupon = data.length !== 0 ? _.maxBy(data, 'id') : null;

  return { isCouponsLoading: isLoading, lastCoupon, ...rest };
}
