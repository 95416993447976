import toFixed from './toFixed';

export default function toFixedString(value, decimals = 2) {
  const [integersSegment, decimalsSegment = ''] = `${toFixed(
    value,
    decimals,
  )}`.split('.');

  const fill = Math.max(decimals - decimalsSegment.length, 0);

  return `${integersSegment}.${decimalsSegment}${'0'.repeat(fill)}`;
}
