import api from '@api';
import {
  TaxableEntity,
  TaxableEntityCredentials,
  TaxableEntityPreference,
  TaxableEntityPreferencePatchPayload,
  TaxableEntityUpdateRequest,
  NotifyAboutPresentedDeclarationRequest,
  NotifyAboutPresentedDeclarationResponse,
  NotifyAboutPendingApproveDeclarationRequest,
  NotifyAboutPendingApproveDeclarationResponse,
} from 'types/entities';
import type { AxiosRequestConfig } from 'axios';

export const getTaxableEntities = async (
  params?: Record<string, string | number | object | boolean>,
) => {
  const { data } = await api.get<TaxableEntity[]>(`taxable_entities`, {
    params,
  });
  return data;
};

export const getTaxableEntityCredentials = async (
  taxableEntityId: number | string,
  config?: AxiosRequestConfig<TaxableEntityCredentials>,
) => {
  const { data } = await api.get<TaxableEntityCredentials>(
    `taxable_entities/${taxableEntityId}/credentials`,
    config,
  );
  return data;
};

export const getTaxableEntityPreferences = async (
  taxableEntityId: number | string,
  config?: AxiosRequestConfig<TaxableEntityPreference>,
) => {
  const { data } = await api.get<TaxableEntityPreference>(
    `taxable_entities/${taxableEntityId}/preferences`,
    config,
  );
  return data;
};

export default async function patchTaxableEntityPreferences(
  { payload, taxableEntityId }: TaxableEntityPreferencePatchPayload,
  config?: AxiosRequestConfig<TaxableEntityPreference>,
) {
  const { data } = await api.patch<TaxableEntityPreference>(
    `taxable_entities/${taxableEntityId}/preferences`,
    payload,
    config,
  );
  return data;
}

export const getImpersonationToken = async (
  taxableEntityId: number | string,
) => {
  const { data } = await api.get<string>(
    `taxable_entities/${taxableEntityId}/impersonation_token`,
  );
  return data;
};

export const putTaxableEntity = async (
  { taxableEntityId, payload }: TaxableEntityUpdateRequest,
  config?: AxiosRequestConfig<TaxableEntity>,
) => {
  const { data } = await api.put<TaxableEntity>(
    `taxable_entities/${taxableEntityId}`,
    payload,
    config,
  );
  return data;
};

export const postNotifyAboutPresentedDeclaration = async (
  { taxableEntityId, payload }: NotifyAboutPresentedDeclarationRequest,
  config?: AxiosRequestConfig<NotifyAboutPresentedDeclarationResponse>,
) => {
  const { data } = await api.post<NotifyAboutPresentedDeclarationResponse>(
    `taxable_entities/${taxableEntityId}/notify_about_presented_declaration`,
    payload,
    config,
  );
  return data;
};

export const postNotifyAboutPendingDeclaration = async (
  { taxableEntityId, payload }: NotifyAboutPendingApproveDeclarationRequest,
  config?: AxiosRequestConfig<NotifyAboutPendingApproveDeclarationResponse>,
) => {
  const { data } = await api.post<NotifyAboutPendingApproveDeclarationResponse>(
    `taxable_entities/${taxableEntityId}/notify_about_pending_approve_declaration`,
    payload,
    config,
  );
  return data;
};
