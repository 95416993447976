import useStore from 'backoffice/store';

const parentRoutes = {
  customers: '/customers',
  declarations: '/declarations/uncategorized-taxable-entities',
};
export default function useParentPath() {
  const fromComponent = useStore((state) => state.fromComponent);
  return parentRoutes[fromComponent];
}
