import React, { useMemo, useState } from 'react';
import { Flex, Plans as KontaPlans, Tab, Tabs } from '@konta/ui';
import useSubscriptions from '@hooks/useSubscriptions';
import { toFixedWithoutRound } from '@util/Utils';
import { BILLING_PERIOD_QUANTITY } from '@constants/subscription';

const PLAN_PRICES = {
  basic: {
    monthly: { discount: 0, price: 699 },
    biannual: { discount: 14.3, price: 599 },
    yearly: { discount: 21.4, price: 549 },
  },
  pro: {
    monthly: { discount: 0, price: 999 },
    biannual: { discount: 15, price: 849 },
    yearly: { discount: 20, price: 799 },
  },
};

export default function PricingPlans({ card }) {
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const { handleSelect } = useSubscriptions();
  const plans = useMemo(() => {
    const sharedFeatures = [
      {
        title: 'Emisión de facturas ilimitadas',
        value: true,
        type: 'feature',
      },
      {
        title: 'Soporte por Whatsapp',
        value: true,
        type: 'feature',
      },
      {
        title: 'Soporte por Correo',
        value: true,
        type: 'feature',
      },
      {
        title: 'Soporte por Chat en la plataforma',
        value: true,
        type: 'feature',
      },
    ];
    const basicFeatures = [
      {
        title: 'Funcionalidades',
        id: 'functionalities',
        features: [
          ...sharedFeatures,
          {
            title: 'Asesoría contable',
            value: '1 hora al mes',
            type: 'feature',
          },
          {
            title: 'Facturas de gastos contabilizadas al mes',
            value: '50 facturas',
            type: 'feature',
          },
          {
            title:
              'Regímenes fiscales (sin contar Sueldos y Salarios o Dividendos)',
            value: '1 régimen',
            type: 'feature',
          },
          {
            title: 'Soporte por teléfono',
            value: false,
            type: 'feature',
          },
          {
            title:
              'Soporte prioritario (horario extendido y atención preferente)',
            value: false,
            type: 'feature',
          },
        ],
      },
    ];
    const proFeatures = [
      {
        title: 'Funcionalidades',
        id: 'functionalities',
        features: [
          ...sharedFeatures,
          {
            title: 'Asesoría contable',
            value: 'Horas ilimitadas',
            type: 'feature',
          },
          {
            title: 'Facturas de gastos contabilizadas al mes',
            value: 'Facturas ilimitadas ',
            type: 'feature',
          },
          {
            title:
              'Regímenes fiscales (sin contar Sueldos y Salarios o Dividendos)',
            value: '2 o más regímenes',
            type: 'feature',
          },
          {
            title: 'Soporte por teléfono',
            value: true,
            type: 'feature',
          },
          {
            title:
              'Soporte prioritario (horario extendido y atención preferente)',
            value: true,
            type: 'feature',
          },
        ],
      },
    ];
    return [
      {
        withPeriodText: true,
        id: 'basicPlan',
        basePrice: PLAN_PRICES.basic.monthly.price,
        name: 'Básico',
        extra: 0,
        extraReceipt: 0,
        serviceDb: 'starter_plan',
        serviceId: 18,
        employeeServiceId: 16,
        badgeText: '+ IVA',
        totalText: `Total IVA incluido $${toFixedWithoutRound(
          PLAN_PRICES.basic[billingPeriod].price * 1.16,
          0,
        )}`,
        title: 'Plan Básico',
        service: 'Plan Básico',
        description:
          'El mejor plan para negocios que están empezando a formalizarse con el SAT. Incluye 1 régimen fiscal.',
        billingPeriod,
        pricing: {
          price: PLAN_PRICES.basic[billingPeriod].price,
          discount: PLAN_PRICES.basic[billingPeriod].discount,
        },
        isCurrentPlan: false,
        isMostPopular: true,
        mobileFeatures: [
          'Emisión de facturas ilimitadas',
          'Soporte por Whatsapp',
          'Soporte por Correo',
          'Soporte por Chat en la plataforma',
          'Asesoría contable (1 hora al mes)',
          'Hasta 50 facturas de gastos contabilizadas al mes',
          '1 régimen fiscal (sin contar Sueldos y Salarios o Dividendos)',
        ],
        features: basicFeatures,
      },
      {
        withPeriodText: true,
        id: 'proPlan',
        name: 'Pro',
        extra: 0,
        basePrice: PLAN_PRICES.pro.monthly.price,
        extraReceipt: 0,
        badgeText: '+ IVA',
        serviceDb: 'standard_plan',
        serviceId: 19,
        employeeServiceId: 16,
        service: 'Plan Pro',
        title: 'Plan Pro',
        totalText: `Total IVA incluido $${toFixedWithoutRound(
          PLAN_PRICES.pro[billingPeriod].price * 1.16,
          0,
        )}`,
        pricing: {
          price: PLAN_PRICES.pro[billingPeriod].price,
          discount: PLAN_PRICES.pro[billingPeriod].discount,
        },
        description:
          'Diseñado para negocios con más volumen de facturas. Incluye 2 o más regímenes.',
        billingPeriod,
        isCurrentPlan: false,
        isMostPopular: false,
        mobileFeatures: [
          <b key="allPlanBasic">Todo lo del plan Básico</b>,
          'Soporte por teléfono',
          'Asesoría contable ilimitada',
          'Sin límite de facturas de gastos contabilizadas',
          'Más de 2 regímenes fiscales con obligaciones mensuales (sin contar Sueldos y Salarios o Dividendos)',
          'Soporte prioritario (horario extendido y atención preferente)',
        ],
        features: proFeatures,
      },
    ];
  }, [billingPeriod]);

  const handlePlanSelect = (planId) => {
    const selectedPlan = plans.find((p) => p.id === planId);
    const selectedPlanData = {
      price: selectedPlan.pricing.price,
      basePrice: selectedPlan.basePrice,
      extra: selectedPlan.extra,
      extraReceipt: selectedPlan.extraReceipt,
      service_id: selectedPlan.serviceId,
      employee_service_id: selectedPlan.employeeServiceId,
      service_db: selectedPlan.serviceDb,
      title: selectedPlan.title,
      service: selectedPlan.service,
      billingPeriod,
      durationMoths: BILLING_PERIOD_QUANTITY[selectedPlan.billingPeriod],
    };
    handleSelect(null, selectedPlanData, 0, 30);
  };

  return (
    <Flex className="bg-white py-2" direction="column" data-cy="wrapperPlans">
      <Tabs
        variant="switch"
        value={billingPeriod}
        onChange={setBillingPeriod}
        indicator="bottom"
        css={{
          width: 'fit-content',
          ml: card ? '$10' : 0,
          mb: '$20',
          '@md': { mb: '0' },
        }}
      >
        <Tab id="monthly">Pago mensual</Tab>
        <Tab id="biannual">Pago semestral</Tab>
        <Tab id="yearly">Pago anual</Tab>
      </Tabs>

      <Flex
        css={{
          p: '$10',
          pt: '18px',
          '@md': { p: card ? '$40' : '$5' },
          overflow: 'auto',
          width: '100%',
        }}
      >
        <KontaPlans
          plans={plans}
          onSelectPlan={handlePlanSelect}
          card={card}
          orientation="horizontal"
          tableCss={{ width: '100%' }}
        />
      </Flex>
    </Flex>
  );
}
