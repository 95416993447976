import React, { useState } from 'react';
import { StepperStatus, Colxx, CardHeader } from '@components';
import { Card, CardBody, Row } from 'reactstrap';
import { FielandCiecTab } from '@containers';
import { useTaxableEntity } from '@hooks';

export default function OnboardingCard() {
  const [activeTab, setActiveTab] = useState('0');

  const { taxable_entity } = useTaxableEntity();
  const steps = [
    { label: 'Subir FIEL', completed: taxable_entity.has_fiel },
    { label: 'Subir CIEC', completed: taxable_entity.has_ciec },
    {
      label: 'Datos personales',
      completed:
        taxable_entity.fiscal_regimes.length > 0 &&
        taxable_entity.legal_email !== null &&
        taxable_entity.legal_email !== '' &&
        taxable_entity.address !== null &&
        taxable_entity.address !== '',
    },
  ];
  React.useEffect(() => {
    if (taxable_entity.has_fiel) {
      steps[0] = {
        label: 'Descargar FIEL',
        completed: taxable_entity.has_fiel,
      };
      if (taxable_entity.has_ciec) {
        setActiveTab('2');
      } else setActiveTab('1');
    }
  }, []);
  return (
    <Card>
      <CardBody>
        <CardHeader withHelper videoId="-FTM5VBhmEI" />
        <Row>
          <Colxx xxs={12}>
            <StepperStatus
              activeStep={activeTab}
              steps={steps}
              setActiveTab={setActiveTab}
            />
          </Colxx>
        </Row>
        <FielandCiecTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          has_fiel={taxable_entity.has_fiel}
        />
      </CardBody>
    </Card>
  );
}
