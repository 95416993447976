import { lazy } from 'react';

const Onboardings = lazy(() => import('./Onboardings'));
const Tasks = lazy(() => import('./MyOnboardings/tasks'));
const MyOnboardings = lazy(() => import('./MyOnboardings'));
const UnassignedOnboardings = lazy(() => import('./UnassignedOnboardings'));

const OnboardingConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'my-onboardings',
      element: <MyOnboardings />,
    },
    {
      path: 'my-onboardings/:onboardingId/tasks/:taskId',
      element: <Tasks />,
    },
    {
      path: 'unassigned-onboardings',
      element: <UnassignedOnboardings />,
    },
    {
      path: 'onboardings',
      element: <Onboardings />,
    },
  ],
};

export default OnboardingConfig;
