import { ComponentProps, ReactNode, Ref } from 'react';
import { Button, Flex } from '@konta/ui';
import { useToggle } from 'rooks';
import { ChevronLeftLineIcon, ChevronDownLineIcon } from '@konta/icons';

type ButtonProps = ComponentProps<typeof Button>;
export interface CollapseSectionProps {
  children: ReactNode;
  btnText: ButtonProps['children'];
  btnLeftIcon?: ButtonProps['leftIcon'];
  collapsed?: boolean;
  controls?: ReturnType<typeof useToggle>;
  ref?: Ref<HTMLDivElement>;
}

export default function CollapseSection({
  children,
  btnText,
  btnLeftIcon,
  collapsed = false,
  ref,
  controls,
}: CollapseSectionProps) {
  const toggleControls = useToggle(collapsed);
  const [isCollapsed, toggleIsCollapsed] = controls || toggleControls;

  return (
    <Flex
      itemsStart
      column
      gap={8}
      css={{
        borderBottom: '1px solid $gray200',
        pb: '$24',
        flex: 1,
      }}
      ref={ref}
    >
      <Button
        size="m"
        variant="text"
        noFill
        leftIcon={btnLeftIcon}
        rightIcon={
          isCollapsed ? <ChevronDownLineIcon /> : <ChevronLeftLineIcon />
        }
        onClick={toggleIsCollapsed}
      >
        {btnText}
      </Button>
      {!!isCollapsed && children}
    </Flex>
  );
}
