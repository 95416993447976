import React from 'react';
import { Row } from 'reactstrap';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Colxx } from '@components';
import { UUIDgen } from '@util/Utils';
import { paymentMethodsSelect } from '@redux/actions';
import PaymentMethodItem from './payment-methods-item';

function PaymentOptionsForm(props) {
  const dispatch = useDispatch();

  const {
    payment_methods,
    ui: { selected_card },
  } = useSelector((state) => state.paymentMethods, shallowEqual);

  const uuids = React.useMemo(() => new UUIDgen().generate(), []);
  const changeHandler = React.useCallback(
    (item) => dispatch(paymentMethodsSelect(item)),
    [dispatch]
  );
  const name = '';
  return (
    <Row className="form-row">
      <Colxx>
        {props.filter
          ? payment_methods
              .filter((method) => method.source_type === 'card')
              .map((item, index) => (
                <PaymentMethodItem
                  item={item}
                  checked={selected_card ? selected_card.id === item.id : false}
                  name={`${uuids}-${name}`}
                  key={`${uuids}-${index}`}
                  onChange={(_) => changeHandler(item)}
                  index={`payments-${uuids}-${index}`}
                />
              ))
          : payment_methods.map((item, index) => (
              <PaymentMethodItem
                item={item}
                checked={selected_card ? selected_card.id === item.id : false}
                name={`${uuids}-${name}`}
                key={`${uuids}-${index}`}
                onChange={(_) => changeHandler(item)}
                index={`payments-${uuids}-${index}`}
              />
            ))}
      </Colxx>
    </Row>
  );
}

PaymentOptionsForm.defaultProps = {
  payment_methods: [],
};

export default PaymentOptionsForm;
