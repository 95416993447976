import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import useTaxableEntityShared from 'shared/hooks/useSharedTaxableEntity';
import getAuthorizationAxiosConfig from 'shared/util/getAuthorizationAxiosConfig';
import useKontaScore from 'shared/hooks/useKontaScore';
import { kontaStatusChip } from 'shared/constants/kontaStatus';
import { IRootState } from 'types/redux';

export default function useHeader() {
  const { userAccessToken, taxableEntity } = useTaxableEntityShared();
  const authUser = useSelector((state: IRootState) => state.authUser.user);

  const authorizedAxiosConfig = getAuthorizationAxiosConfig(userAccessToken);
  const { kontaScore } = useKontaScore(authorizedAxiosConfig);

  const kontaScoreData = useMemo(() => {
    const lastUpdated = kontaScore?.last_update
      ? dayjs(kontaScore.last_update).format('DD/MM/YYYY')
      : '-';
    const status = kontaScore?.status || 'No disponible';
    const score = kontaScore?.score || 0;
    const chipColor = kontaStatusChip[status];
    return {
      lastUpdated,
      status,
      score,
      chipColor,
    };
  }, [kontaScore]);

  const nickName =
    authUser?.nickname || authUser?.first_name || taxableEntity?.legal_name;

  return {
    kontaScore: kontaScoreData,
    nickName,
  };
}
