import { styled } from '@konta/ui';

export const TableContainer = styled('div', {
  display: 'flex',
  flexFlow: 'column nowrap',
  backgroundColor: 'white',
  width: '100%',
  margin: '0 auto',
  borderRadius: '4px',
});

export const TableRow = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
  width: '100%',
  pb: '12px',
  '&:first-child': {
    borderBottom: '1px solid $colors$gray200',
  },
  variants: {
    type: {
      subtitle: {
        py: '24px',
      },
      underline: {
        pb: '24px',
        borderBottom: '1px solid $colors$gray200',
      },
    },
  },
});

export const RowItem = styled('div', {
  display: 'flex',
  flex: 1,
  fontWeight: 400,
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.15s ease-in-out',
  '&:first-child': {
    justifyContent: 'flex-start',
    flex: 3,
  },
  variants: {
    size: {
      s: {
        fontSize: '$xs',
        lineHeight: '$xs',
        '@sm': {
          fontSize: '$s',
          lineHeight: '$s',
        },
      },
      m: {
        fontSize: '$s',
        lineHeight: '$s',
        '@sm': {
          fontSize: '$m',
          lineHeight: '$m',
        },
      },
      l: {
        fontSize: '$m',
        lineHeight: '$m',
        '@sm': {
          fontSize: '$l',
          lineHeight: '$l',
        },
      },
    },
  },
  defaultVariants: {
    size: 's',
  },
});
