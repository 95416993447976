import { call, put } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { workflowNextPhase } from '@redux/actions';
import { uploadXmlCfdiFailure, uploadXmlCfdiSuccess } from '../actions';

export default function* ({ payload: { body, workflow_id, nextPhase } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(api.post, 'declaration_entries', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    });

    if (nextPhase) {
      const bodyNextPhase = {
        workflow: {
          next_phase: 'primary_phase',
        },
      };
      yield put(workflowNextPhase({ workflow_id, body: bodyNextPhase }));
    }

    yield call(StatusHandler, {
      response,
      title: 'Factura agregada',
      message: 'Se agregó una factura con éxito.',
      status: response.status,
      success: uploadXmlCfdiSuccess,
      failure: uploadXmlCfdiFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: uploadXmlCfdiFailure,
      showBodyErrors: true,
    });
  }
}
