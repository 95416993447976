import { HelpCircleLineIcon } from '@konta/icons';
import { styled } from '@konta/ui';

export const Root = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch',
  '@md': {
    gap: '16px',
  },
});

export const KontaTextContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: 1,
  minWidth: 0,
  '@md': {
    gap: '4px',
  },
});

export const KontaStatusContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '114px',
  '@md': {
    width: '123px',
  },
  '& > div': {
    height: 'auto',
  },
});

export const Title = styled('span', {
  fontFamily: 'Inter',
  color: '#2B2B34',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '30px',
  width: '100%',
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '@md': {
    lineHeight: '38px',
    fontSize: '30px',
  },
});

export const SatTextContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const SatContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  '@md': {
    gap: '10px',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

export const SatText = styled('span', {
  fontFamily: 'Inter',
  color: '$gray700',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '16px',
  '@md': {
    lineHeight: '18px',
  },
});

export const SatLastUpdateContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

export const SatLastUpdate = styled('span', {
  fontFamily: 'Inter',
  color: '$gray500',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '18px',
  '@md': {
    fontSize: '12px',
  },
});

export const HelpCircleIcon = styled(HelpCircleLineIcon, {
  cursor: 'pointer',
  color: '$gray400',
  width: '12px',
  height: '12px',
  '@md': {
    width: '16px',
    height: '16px',
  },
});

export const scoreFontCss = {
  fontSize: '18px',
  lineHeight: '24px',
};
