import { ComponentProps } from 'react';
import { Chip } from '@konta/ui';
import {
  BankTransaction,
  Cfdi,
  CfdiUsage,
  Constancy,
  DeclarationEntry,
  DeclarationEntryIncomeType,
  ExtraIncome,
  ForeignInvoice,
  ForeignProduct,
  PaymentForm,
  Source,
} from 'types/entities';
import { declarationEntryIncomeTypes } from '@constants/declarationEntries';
import {
  accountingStatusTranslation,
  entryTypeNames,
  sourceTypeTranslation,
} from 'backoffice/constants/declarationEntries';
import dayjs from 'dayjs';
import { cfdiStatusName } from '../@constants/cfdis';

type AmountWithConversion = {
  amount: number;
  convertedAmount: number;
};
type NullableString = string | null;
const DEFAULT_AMOUNT_WITH_CONVERSION: AmountWithConversion = {
  amount: 0,
  convertedAmount: 0,
};

export function isCreditNoteByEntryType(declarationEntryType: string): boolean {
  const declarationEntryTypes = [
    'national_egress_received',
    'national_egress_issued',
  ];
  return declarationEntryTypes.includes(declarationEntryType);
}

const formatDateString = (date?: NullableString): NullableString =>
  date ? dayjs(date).format('MM-YYYY') : null;

const formatNumber = (amount: number, declarationEntryType: string) => {
  const isCreditNote = isCreditNoteByEntryType(declarationEntryType);
  return isCreditNote ? amount * -1 : amount;
};

export function getCfdi(source: Source): Cfdi | null {
  if (source.cfdi) return source.cfdi;
  if (source.cancelled_cfdi) return source.cancelled_cfdi.cfdi;
  if (source.fixed_asset) return source.fixed_asset.cfdi;
  if (source.payment_detail) return source.payment_detail?.cfdi;
  return null;
}

export function getExchangeRate(declarationEntry: DeclarationEntry): number {
  const { source } = declarationEntry;
  const DEFAULT_EXCHANGE_RATE = 1;
  const cfdi = getCfdi(source);

  if (cfdi && cfdi.currency !== 'MXN') {
    const exchangeRate = +(cfdi.exchange_rate || 0);
    const banxicoExchangeRate = +(cfdi.banxico_exchange_rate || 0);
    return exchangeRate || banxicoExchangeRate || DEFAULT_EXCHANGE_RATE;
  }

  return DEFAULT_EXCHANGE_RATE;
}
export function getIssuerLegalName(
  declarationEntry: DeclarationEntry,
): NullableString {
  const { description, source } = declarationEntry;
  const cfdi = getCfdi(source);
  if (cfdi?.issuer_legal_name) return cfdi.issuer_legal_name;
  if (source.extra_income?.description) return source.extra_income.description;
  if (source.constancy?.social_reason) return source.constancy.social_reason;
  if (description) return description;
  return null;
}
export function getIssuerRfc(
  declarationEntry: DeclarationEntry,
): NullableString {
  const { source } = declarationEntry;
  const cfdi = getCfdi(source);
  if (cfdi?.issuer_rfc) return cfdi.issuer_rfc;
  return null;
}

export function getDateIssued(
  declarationEntry: DeclarationEntry,
): NullableString {
  const { source } = declarationEntry;
  if (!source) {
    return null;
  }
  if (source.payment_detail?.payment_date) {
    return source.payment_detail.payment_date;
  }
  if (source.constancy?.start_month) {
    return source.constancy.start_month;
  }
  const cfdi = getCfdi(declarationEntry.source);
  if (cfdi) return cfdi.date_issued;
  if (declarationEntry.accounting_date) return declarationEntry.accounting_date;
  return null;
}
export const calculateForeignProductAmount = (
  foreignProduct: ForeignProduct,
  amountType: 'total' | 'subtotal' | 'iva' | 'baseIva16' | 'baseExemptIva',
): AmountWithConversion => {
  const { taxable_amount, dta, igi, prv, non_taxable_amount, creditable_iva } =
    foreignProduct;
  let amount = 0;
  if (amountType === 'total') {
    amount =
      (+taxable_amount + +dta + +igi + +prv) * 1.16 + +non_taxable_amount;
  } else if (['subtotal', 'baseIva16'].includes(amountType)) {
    amount = +taxable_amount;
  } else if (amountType === 'baseExemptIva') {
    amount = +non_taxable_amount;
  } else if (amountType === 'iva') {
    amount = +creditable_iva;
  }

  return {
    amount,
    convertedAmount: amount,
  };
};
export const calculateFixedAssetAmount = (
  declarationEntry: DeclarationEntry,
  amountType: 'total' | 'subtotal' | 'iva' | 'baseIva16',
): AmountWithConversion => {
  const { source, accounting_date } = declarationEntry;
  if (!source?.fixed_asset) return DEFAULT_AMOUNT_WITH_CONVERSION;
  let amount = 0;
  const {
    monthly_depreciation,
    iva,
    cfdi: { date_issued },
  } = source.fixed_asset;
  const ivaAmount = +(iva || 0);
  const monthlyDepreciationAmount = +monthly_depreciation;
  if (amountType === 'total') {
    const accountingDate = formatDateString(accounting_date);
    const fixedAssetDateIssued = formatDateString(date_issued);
    amount =
      accountingDate === fixedAssetDateIssued
        ? ivaAmount + monthlyDepreciationAmount
        : monthlyDepreciationAmount;
  } else if (['subtotal', 'baseIva16'].includes(amountType)) {
    amount = monthlyDepreciationAmount;
  } else if (amountType === 'iva') {
    amount = ivaAmount;
  }

  amount = formatNumber(amount, declarationEntry.declaration_entry_type);

  return {
    amount,
    convertedAmount: amount * getExchangeRate(declarationEntry),
  };
};

const calculatePaymentDetailAmount = (
  declarationEntry: DeclarationEntry,
  amountType:
    | 'total'
    | 'subtotal'
    | 'iva'
    | 'baseIva16'
    | 'baseIva8'
    | 'baseIva0'
    | 'baseExemptIva'
    | 'ivaRetained'
    | 'isrRetained'
    | 'ivaAndIsrRetained',
): AmountWithConversion => {
  const paymentDetail = declarationEntry.source.payment_detail;
  if (!paymentDetail) return DEFAULT_AMOUNT_WITH_CONVERSION;
  let amount = 0;
  const {
    iva_base8,
    total_zero,
    iva_base16,
    total_exempt,
    iva16_amount,
    iva8_amount,
    retained_iva_base8,
    retained_iva_base16,
    retained_iva_base0,
    retained_iva_base_exempt,
    retained_isr_base8,
    retained_isr_base16,
    retained_isr_base0,
    retained_isr_base_exempt,
  } = paymentDetail;

  const iva = +iva16_amount + +iva8_amount;
  const ivaRetained =
    +retained_iva_base8 +
    +retained_iva_base16 +
    +retained_iva_base0 +
    +retained_iva_base_exempt;

  const isrRetained =
    +retained_isr_base8 +
    +retained_isr_base16 +
    +retained_isr_base0 +
    +retained_isr_base_exempt;

  const subtotal = +iva_base16 + +iva_base8 + +total_zero + +total_exempt;
  const totalRetained = ivaRetained + isrRetained;
  const total = subtotal + iva - totalRetained;

  if (amountType === 'total') {
    amount = total;
  } else if (amountType === 'subtotal') {
    amount = subtotal;
  } else if (amountType === 'iva') {
    amount = iva;
  } else if (amountType === 'baseIva16') {
    amount = +iva_base16;
  } else if (amountType === 'baseIva8') {
    amount = +iva_base8;
  } else if (amountType === 'baseIva0') {
    amount = +total_zero;
  } else if (amountType === 'baseExemptIva') {
    amount = +total_exempt;
  } else if (amountType === 'ivaRetained') {
    amount = ivaRetained;
  } else if (amountType === 'isrRetained') {
    amount = isrRetained;
  } else if (amountType === 'ivaAndIsrRetained') {
    amount = totalRetained;
  }

  amount = formatNumber(amount, declarationEntry.declaration_entry_type);
  return {
    amount,
    convertedAmount: amount * getExchangeRate(declarationEntry),
  };
};
const calculateCfdiAmount = (
  declarationEntry: DeclarationEntry,
  amountType:
    | 'total'
    | 'subtotal'
    | 'iva'
    | 'baseIva16'
    | 'baseIva8'
    | 'baseIva0'
    | 'baseExemptIva'
    | 'ivaRetained'
    | 'isrRetained'
    | 'ivaAndIsrRetained',
): AmountWithConversion => {
  const { source, declaration_entry_type } = declarationEntry;
  const cfdi = getCfdi(source);
  if (!cfdi) return DEFAULT_AMOUNT_WITH_CONVERSION;
  let amount = 0;
  const {
    iva_16_percent,
    iva_8_percent,
    iva_0_percent,
    iva_exempt,
    retained_isr,
    retained_iva,
    iva,
  } = cfdi;

  if (amountType === 'total') {
    amount =
      iva_16_percent * 1.16 +
      iva_8_percent * 1.08 +
      iva_0_percent +
      iva_exempt -
      (+retained_isr + +retained_iva);
  } else if (amountType === 'subtotal') {
    amount = +iva_16_percent + +iva_8_percent + iva_0_percent + +iva_exempt;
  } else if (amountType === 'iva') {
    amount = +iva;
  } else if (amountType === 'baseIva16') {
    amount = +iva_16_percent;
  } else if (amountType === 'baseIva8') {
    amount = +iva_8_percent;
  } else if (amountType === 'baseIva0') {
    amount = +iva_0_percent;
  } else if (amountType === 'baseExemptIva') {
    amount = +iva_exempt;
  } else if (amountType === 'ivaRetained') {
    amount = +retained_iva;
  } else if (amountType === 'isrRetained') {
    amount = +retained_isr;
  } else if (amountType === 'ivaAndIsrRetained') {
    amount = +retained_iva + +retained_isr;
  }
  amount = formatNumber(amount, declaration_entry_type);
  return {
    amount,
    convertedAmount: amount * getExchangeRate(declarationEntry),
  };
};
const calculateForeignInvoiceAmount = (
  foreignInvoice: ForeignInvoice,
  amountType: 'total' | 'subtotal' | 'iva' | 'baseIva16',
): AmountWithConversion => {
  const { amount: foreignInvoiceAmount, iva } = foreignInvoice;
  let amount = 0;
  if (['total', 'subtotal'].includes(amountType)) {
    amount = +foreignInvoiceAmount;
  } else if (amountType === 'iva') {
    amount = +iva;
  } else if (amountType === 'baseIva16') {
    amount = +iva > 0 ? +foreignInvoiceAmount : 0;
  }
  return {
    amount,
    convertedAmount: amount,
  };
};
const calculateBankTransactionAmount = (
  bankTransaction: BankTransaction,
  amountType: 'total' | 'subtotal',
): AmountWithConversion => {
  const { amount } = bankTransaction;
  if (['total', 'subtotal'].includes(amountType)) {
    return {
      amount: +amount,
      convertedAmount: +amount,
    };
  }

  return DEFAULT_AMOUNT_WITH_CONVERSION;
};
const calculateConstancyAmount = (
  constancy: Constancy,
  amountType:
    | 'total'
    | 'subtotal'
    | 'iva'
    | 'baseIva16'
    | 'ivaRetained'
    | 'isrRetained'
    | 'ivaAndIsrRetained',
): AmountWithConversion => {
  const {
    total_base_iva,
    total_base_exempt,
    total_iva_transferred,
    total_iva_retained,
    total_isr_retained,
  } = constancy;
  let amount = 0;
  if (amountType === 'total') {
    amount =
      +total_base_iva +
      +total_base_exempt +
      +total_iva_transferred -
      (+total_iva_retained + +total_isr_retained);
  } else if (['subtotal', 'baseIva16'].includes(amountType)) {
    amount = +total_base_iva;
  } else if (amountType === 'iva') {
    amount = +total_iva_transferred;
  } else if (amountType === 'ivaRetained') {
    amount = +total_iva_retained;
  } else if (amountType === 'isrRetained') {
    amount = +total_isr_retained;
  } else if (amountType === 'ivaAndIsrRetained') {
    amount = +total_iva_retained + +total_isr_retained;
  }
  return {
    amount,
    convertedAmount: amount,
  };
};
const calculateExtraIncomeAmount = (
  extraIncome: ExtraIncome,
  amountType:
    | 'total'
    | 'iva'
    | 'baseIva16'
    | 'baseIva8'
    | 'baseIva0'
    | 'baseExemptIva',
): AmountWithConversion => {
  const { total, iva_base16, exempt_base, zero_base, iva_base8, iva16, iva8 } =
    extraIncome;
  let amount = 0;
  if (amountType === 'total') {
    amount = +total;
  } else if (amountType === 'iva') {
    amount = +iva16 + +iva8;
  } else if (amountType === 'baseIva16') {
    amount = +iva_base16;
  } else if (amountType === 'baseIva8') {
    amount = +iva_base8;
  } else if (amountType === 'baseIva0') {
    amount = +zero_base;
  } else if (amountType === 'baseExemptIva') {
    amount = +exempt_base;
  }
  return {
    amount,
    convertedAmount: amount,
  };
};

export function calculateAmountAndConversion(
  declarationEntry: DeclarationEntry,
): AmountWithConversion {
  const { source } = declarationEntry;
  if (!source) {
    return DEFAULT_AMOUNT_WITH_CONVERSION;
  }
  if (source.foreign_product) {
    return calculateForeignProductAmount(source.foreign_product, 'total');
  }
  if (source.foreign_invoice) {
    return calculateForeignInvoiceAmount(source.foreign_invoice, 'total');
  }
  if (source.bank_transaction) {
    return calculateBankTransactionAmount(source.bank_transaction, 'total');
  }
  if (source.constancy) {
    return calculateConstancyAmount(source.constancy, 'total');
  }
  if (source.extra_income) {
    return calculateExtraIncomeAmount(source.extra_income, 'total');
  }
  if (source.fixed_asset) {
    return calculateFixedAssetAmount(declarationEntry, 'total');
  }
  if (source.payment_detail) {
    return calculatePaymentDetailAmount(declarationEntry, 'total');
  }
  return calculateCfdiAmount(declarationEntry, 'total');
}

export function getCurrency(declarationEntry: DeclarationEntry): string {
  const cfdi = getCfdi(declarationEntry.source);
  if (cfdi) {
    return cfdi.currency;
  }
  return 'MXN';
}

export function getDocument(declarationEntry: DeclarationEntry) {
  const { source } = declarationEntry;
  if (!source) {
    return { type: null, url: null };
  }
  const {
    cfdi,
    cancelled_cfdi,
    foreign_product,
    foreign_invoice,
    fixed_asset,
    payment_detail,
    constancy,
  } = source;

  if (payment_detail) {
    return {
      type: 'xml',
      url: payment_detail.cfdi.xml_url,
      issuer_legal_name: payment_detail.cfdi.issuer_legal_name,
      date_issued: payment_detail.cfdi.date_issued,
      id: payment_detail.cfdi.id,
      status: payment_detail.cfdi.status,
      sourceType: 'PaymentDetail',
    };
  }
  if (cfdi) {
    return {
      type: 'xml',
      url: cfdi.xml_url,
      issuer_legal_name: cfdi.issuer_legal_name,
      date_issued: cfdi.date_issued,
      id: cfdi.id,
      status: cfdi.status,
      sourceType: 'Cfdi',
    };
  }
  if (cancelled_cfdi) {
    return {
      type: 'xml',
      url: cancelled_cfdi.cfdi.xml_url,
      issuer_legal_name: cancelled_cfdi.cfdi.issuer_legal_name,
      date_issued: cancelled_cfdi.cfdi.date_issued,
      id: cancelled_cfdi.cfdi.id,
      status: cancelled_cfdi.cfdi.status,
      sourceType: 'CancelledCfdi',
    };
  }
  if (foreign_product) {
    return {
      type: 'pdf',
      url: foreign_product.documents,
      sourceType: 'ForeignProduct',
    };
  }
  if (foreign_invoice) {
    return {
      type: 'pdf',
      url: declarationEntry.file,
      sourceType: 'ForeignInvoice',
    };
  }
  if (fixed_asset) {
    return {
      type: 'pdf',
      url: fixed_asset.cfdi.xml_url,
      sourceType: 'FixedAsset',
    };
  }
  if (constancy) {
    return {
      type: 'pdf',
      url: declarationEntry.file,
      sourceType: 'Constancy',
    };
  }
  return { type: null, url: null, sourceType: null };
}

export function getSourceTypeLabel(
  declarationEntry: DeclarationEntry,
): NullableString {
  const { source, declaration_entry_type } = declarationEntry;
  if (!source) {
    return null;
  }
  const { type } = source;
  if (type === 'cfdi') {
    if (isCreditNoteByEntryType(declaration_entry_type)) {
      return sourceTypeTranslation.CreditNote;
    }
    return sourceTypeTranslation.Cfdi;
  }
  if (type === 'cancelled_cfdi') {
    return sourceTypeTranslation.CancelledCfdi;
  }
  if (type === 'foreign_product') {
    return sourceTypeTranslation.ForeignProduct;
  }
  if (type === 'foreign_invoice') {
    return sourceTypeTranslation.ForeignInvoice;
  }
  if (type === 'fixed_asset') {
    return sourceTypeTranslation.FixedAsset;
  }
  if (type === 'constancy') {
    return sourceTypeTranslation.Constancy;
  }
  if (type === 'payment_detail') {
    return sourceTypeTranslation.PaymentDetail;
  }
  if (type === 'extra_income') {
    return sourceTypeTranslation.ExtraIncome;
  }
  return null;
}

export function getAccountingEntryTypeLabel(
  declarationEntry: DeclarationEntry,
) {
  const { accounting_status } = declarationEntry;
  const label = accountingStatusTranslation[accounting_status] || 'N/A';

  const color =
    {
      not_deductible: 'gray',
      is_deductible: 'success',
      not_paid: 'gray',
      paid: 'gray',
      fixed_asset: 'gray',
      annual: 'gray',
      cancelled: 'gray',
      in_review: 'warning700',
      pending_classification: 'error200',
    }[accounting_status] || 'gray';

  return { label, color: color as ComponentProps<typeof Chip>['color'] };
}

export function getEntryPaymentForm(
  declarationEntry: DeclarationEntry,
): PaymentForm | null {
  const cfdi = getCfdi(declarationEntry.source);
  if (cfdi) {
    return cfdi.payment_form;
  }
  return null;
}

export function getCfdiConcept(declarationEntry: DeclarationEntry): string {
  const cfdi = getCfdi(declarationEntry.source);
  if (cfdi) {
    return cfdi.concept_descriptions;
  }
  return '';
}

export function getCfdiPaymentMethod(
  declarationEntry: DeclarationEntry,
): NullableString {
  const cfdi = getCfdi(declarationEntry.source);
  if (cfdi) {
    return cfdi.payment_method;
  }
  return null;
}

export function getCfdiUsage(
  declarationEntry: DeclarationEntry,
): CfdiUsage | null {
  const cfdi = getCfdi(declarationEntry.source);
  if (cfdi) {
    return cfdi.cfdi_use;
  }
  return null;
}

export function getCfdiFiscalFolio(declarationEntry: DeclarationEntry): string {
  const cfdi = getCfdi(declarationEntry.source);
  if (cfdi) {
    return cfdi.folio_fiscal;
  }
  return '';
}

export function getCfdiUIFolio(declarationEntry: DeclarationEntry): string {
  const cfdi = getCfdi(declarationEntry.source);
  if (cfdi) {
    return cfdi.folio_ui ?? '';
  }
  return '';
}

export function getSubtotal(
  declarationEntry: DeclarationEntry,
): AmountWithConversion {
  const { source } = declarationEntry;
  if (!source) return DEFAULT_AMOUNT_WITH_CONVERSION;
  const amountType = 'subtotal';
  if (source.foreign_product) {
    return calculateForeignProductAmount(source.foreign_product, amountType);
  }
  if (source.foreign_invoice) {
    return calculateForeignInvoiceAmount(source.foreign_invoice, amountType);
  }
  if (source.fixed_asset) {
    return calculateFixedAssetAmount(declarationEntry, amountType);
  }
  if (source.bank_transaction) {
    return calculateBankTransactionAmount(source.bank_transaction, amountType);
  }
  if (source.constancy) {
    return calculateConstancyAmount(source.constancy, amountType);
  }
  if (source.payment_detail) {
    return calculatePaymentDetailAmount(declarationEntry, amountType);
  }
  return calculateCfdiAmount(declarationEntry, amountType);
}

export function getTotalIva(
  declarationEntry: DeclarationEntry,
): AmountWithConversion {
  const { source } = declarationEntry;
  if (!source) return DEFAULT_AMOUNT_WITH_CONVERSION;
  const amountType = 'iva';
  if (source.extra_income) {
    return calculateExtraIncomeAmount(source.extra_income, amountType);
  }
  if (source.foreign_product) {
    return calculateForeignProductAmount(source.foreign_product, amountType);
  }
  if (source.foreign_invoice) {
    return calculateForeignInvoiceAmount(source.foreign_invoice, amountType);
  }
  if (source.fixed_asset) {
    return calculateFixedAssetAmount(declarationEntry, amountType);
  }
  if (source.constancy) {
    return calculateConstancyAmount(source.constancy, amountType);
  }
  if (source.payment_detail) {
    return calculatePaymentDetailAmount(declarationEntry, amountType);
  }
  return calculateCfdiAmount(declarationEntry, amountType);
}

export function getCfdiStatus(
  declarationEntry: DeclarationEntry,
): NullableString {
  const cfdi = getCfdi(declarationEntry.source);
  if (cfdi?.status) {
    return cfdiStatusName[cfdi.status];
  }
  return null;
}

export function exchangeToPesos(declarationEntry: DeclarationEntry) {
  const { source } = declarationEntry;
  const cfdi = getCfdi(source);
  const defaultValues = {
    total: 0,
    subtotal: 0,
    iva: 0,
  };
  if (source.type !== 'cfdi' || cfdi?.currency === 'MXN') {
    return defaultValues;
  }

  if (cfdi) {
    const {
      iva_16_percent,
      iva_8_percent,
      iva_0_percent,
      iva_exempt,
      exchange_rate,
      iva,
    } = cfdi;
    const total =
      +(
        iva_16_percent * 1.16 +
        iva_8_percent * 1.08 +
        iva_0_percent +
        iva_exempt
      ) * +(exchange_rate || 0);

    const subtotal =
      +(+iva_16_percent + +iva_8_percent + +iva_0_percent + +iva_exempt) *
      +(exchange_rate || 0);
    return {
      total,
      subtotal,
      iva: +iva,
    };
  }

  return defaultValues;
}

export function getReceiverLegalName(
  declarationEntry: DeclarationEntry,
): NullableString {
  const { description, source } = declarationEntry;
  if (!source) return null;
  const cfdi = getCfdi(source);
  if (cfdi) {
    return cfdi.receiver_legal_name;
  }
  const { constancy } = source;
  if (constancy) {
    return constancy.social_reason;
  }
  if (description) {
    return description;
  }

  return null;
}

export function getReceiverRfc(
  declarationEntry: DeclarationEntry,
): NullableString {
  const cfdi = getCfdi(declarationEntry.source);
  if (cfdi) {
    return cfdi.receiver_rfc;
  }
  return null;
}

export function getGeneralType(declarationEntry: DeclarationEntry) {
  if (
    declarationEntryIncomeTypes.includes(
      <DeclarationEntryIncomeType>declarationEntry.declaration_entry_type,
    )
  ) {
    return 'income';
  }

  return 'expense';
}

export function getReceiverOrIssuerLegalName(
  declarationEntry: DeclarationEntry,
) {
  const { source } = declarationEntry;
  if (!source) {
    return null;
  }
  const { cfdi, cancelled_cfdi, fixed_asset, payment_detail, constancy } =
    source;
  if (payment_detail) {
    return payment_detail.cfdi.is_emited
      ? payment_detail.cfdi.receiver_legal_name
      : payment_detail.cfdi.issuer_legal_name;
  }
  if (cfdi) {
    return cfdi.is_emited ? cfdi.receiver_legal_name : cfdi.issuer_legal_name;
  }
  if (cancelled_cfdi) {
    return cancelled_cfdi.cfdi.is_emited
      ? cancelled_cfdi.cfdi.receiver_legal_name
      : cancelled_cfdi.cfdi.issuer_legal_name;
  }
  if (fixed_asset) {
    return fixed_asset?.cfdi?.issuer_legal_name || '';
  }
  if (constancy) {
    return constancy.social_reason;
  }
  return null;
}

export function getReceiverOrIssuerRfc(declarationEntry: DeclarationEntry) {
  const { source } = declarationEntry;
  if (!source) {
    return null;
  }
  const { cfdi, cancelled_cfdi, fixed_asset, payment_detail } = source;
  if (payment_detail) {
    return payment_detail.cfdi.is_emited
      ? payment_detail.cfdi.receiver_rfc
      : payment_detail.cfdi.issuer_rfc;
  }
  if (cfdi) {
    return cfdi.is_emited ? cfdi.receiver_rfc : cfdi.issuer_rfc;
  }
  if (cancelled_cfdi) {
    return cancelled_cfdi.cfdi.is_emited
      ? cancelled_cfdi.cfdi.receiver_legal_name
      : cancelled_cfdi.cfdi.issuer_legal_name;
  }
  if (fixed_asset) {
    return fixed_asset?.cfdi?.receiver_rfc || '';
  }
  return null;
}

export function getEntryConceptOrDescription(
  declarationEntry: DeclarationEntry,
): string {
  const cfdi = getCfdi(declarationEntry.source);
  if (cfdi) {
    return cfdi.concept_descriptions;
  }
  // TODO add foreign_product and foreign_invoice description or concept
  return '';
}

export function getBaseIVA16(
  declarationEntry: DeclarationEntry,
): AmountWithConversion {
  const { source } = declarationEntry;
  if (!source) return DEFAULT_AMOUNT_WITH_CONVERSION;
  const amountType = 'baseIva16';
  if (source.extra_income) {
    return calculateExtraIncomeAmount(source.extra_income, amountType);
  }
  if (source.foreign_product) {
    return calculateForeignProductAmount(source.foreign_product, amountType);
  }
  if (source.foreign_invoice) {
    return calculateForeignInvoiceAmount(source.foreign_invoice, amountType);
  }
  if (source.fixed_asset) {
    return calculateFixedAssetAmount(declarationEntry, amountType);
  }
  if (source.constancy) {
    return calculateConstancyAmount(source.constancy, amountType);
  }
  if (source.payment_detail) {
    return calculatePaymentDetailAmount(declarationEntry, amountType);
  }
  return calculateCfdiAmount(declarationEntry, amountType);
}

export function getBaseIVA8(
  declarationEntry: DeclarationEntry,
): AmountWithConversion {
  const { source } = declarationEntry;
  if (!source) return DEFAULT_AMOUNT_WITH_CONVERSION;
  const amountType = 'baseIva8';
  if (source.extra_income) {
    return calculateExtraIncomeAmount(source.extra_income, amountType);
  }
  if (source.payment_detail) {
    return calculatePaymentDetailAmount(declarationEntry, amountType);
  }
  return calculateCfdiAmount(declarationEntry, amountType);
}

export function getBaseIVA0(
  declarationEntry: DeclarationEntry,
): AmountWithConversion {
  const { source } = declarationEntry;
  if (!source) return DEFAULT_AMOUNT_WITH_CONVERSION;
  const amountType = 'baseIva0';
  if (source.extra_income) {
    return calculateExtraIncomeAmount(source.extra_income, amountType);
  }
  if (source.payment_detail) {
    return calculatePaymentDetailAmount(declarationEntry, amountType);
  }
  return calculateCfdiAmount(declarationEntry, amountType);
}

export function getBaseIVAExempt(
  declarationEntry: DeclarationEntry,
): AmountWithConversion {
  const { source } = declarationEntry;
  if (!source) return DEFAULT_AMOUNT_WITH_CONVERSION;
  const amountType = 'baseExemptIva';
  if (source.extra_income) {
    return calculateExtraIncomeAmount(source.extra_income, amountType);
  }
  if (source.foreign_product) {
    return calculateForeignProductAmount(source.foreign_product, amountType);
  }
  if (source.payment_detail) {
    return calculatePaymentDetailAmount(declarationEntry, amountType);
  }
  return calculateCfdiAmount(declarationEntry, amountType);
}

export function getDiscount(
  declarationEntry: DeclarationEntry,
): AmountWithConversion {
  const { source, declaration_entry_type } = declarationEntry;
  if (!source) return DEFAULT_AMOUNT_WITH_CONVERSION;
  const cfdi = getCfdi(source);

  if (cfdi) {
    const { discount } = cfdi;
    const amount = formatNumber(+(discount || 0), declaration_entry_type);
    return {
      amount,
      convertedAmount: amount * getExchangeRate(declarationEntry),
    };
  }

  return DEFAULT_AMOUNT_WITH_CONVERSION;
}

export function getRetainedIVA(
  declarationEntry: DeclarationEntry,
): AmountWithConversion {
  const { source } = declarationEntry;
  if (!source) return DEFAULT_AMOUNT_WITH_CONVERSION;
  const amountType = 'ivaRetained';
  if (source.constancy) {
    return calculateConstancyAmount(source.constancy, amountType);
  }
  if (source.payment_detail) {
    return calculatePaymentDetailAmount(declarationEntry, amountType);
  }
  return calculateCfdiAmount(declarationEntry, amountType);
}

export function getRetainedISR(
  declarationEntry: DeclarationEntry,
): AmountWithConversion {
  const { source } = declarationEntry;
  if (!source) return DEFAULT_AMOUNT_WITH_CONVERSION;
  const amountType = 'isrRetained';
  if (source.constancy) {
    return calculateConstancyAmount(source.constancy, amountType);
  }
  if (source.payment_detail) {
    return calculatePaymentDetailAmount(declarationEntry, amountType);
  }
  return calculateCfdiAmount(declarationEntry, amountType);
}

export function getTotalRetentions(
  declarationEntry: DeclarationEntry,
): AmountWithConversion {
  const { source } = declarationEntry;
  if (!source) return DEFAULT_AMOUNT_WITH_CONVERSION;
  const amountType = 'ivaAndIsrRetained';
  if (source.constancy) {
    return calculateConstancyAmount(source.constancy, amountType);
  }
  if (source.payment_detail) {
    return calculatePaymentDetailAmount(declarationEntry, amountType);
  }
  return calculateCfdiAmount(declarationEntry, amountType);
}

export function getExpensesCategory(declarationEntry: DeclarationEntry) {
  const { expense_category } = declarationEntry;
  if (!expense_category) {
    return null;
  }
  return expense_category.replace(/_/g, ' ');
}

export function getReceiverOrIssuerFiscalRegime(
  declarationEntry: DeclarationEntry,
) {
  const { source } = declarationEntry;
  if (!source) {
    return null;
  }
  const { cfdi, cancelled_cfdi, fixed_asset, payment_detail } = source;
  if (payment_detail) {
    return payment_detail.cfdi.is_emited
      ? payment_detail.cfdi.receiver_fiscal_regime
      : payment_detail.cfdi.issuer_fiscal_regime;
  }
  if (cfdi) {
    return cfdi.is_emited
      ? cfdi.receiver_fiscal_regime
      : cfdi.issuer_fiscal_regime;
  }
  if (cancelled_cfdi) {
    return cancelled_cfdi.cfdi.is_emited
      ? cancelled_cfdi.cfdi.receiver_fiscal_regime
      : cancelled_cfdi.cfdi.issuer_fiscal_regime;
  }
  if (fixed_asset) {
    return fixed_asset.cfdi.is_emited
      ? fixed_asset?.cfdi?.receiver_fiscal_regime
      : fixed_asset?.cfdi?.issuer_fiscal_regime;
  }

  return null;
}

export function getEntryTypeName(declarationEntry: DeclarationEntry) {
  const declarationEntryType = declarationEntry.declaration_entry_type;
  return entryTypeNames[declarationEntryType] || 'N/A';
}
