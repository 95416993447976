import { Flex } from '@konta/ui';
import { TaxableEntity, Workflow } from 'types/entities';
import { Root } from './styled';
import EntriesTable from './EntriesTable';
import useEntries from './useEntries';

interface EntriesProps {
  workflowsId: number[];
  taxableEntity: TaxableEntity | null;
  filters: Record<string, string>;
  period: string | number;
  workflowFromGridTable: Workflow;
}
export default function Entries({
  workflowsId,
  taxableEntity,
  filters,
  period,
  workflowFromGridTable,
}: EntriesProps) {
  const { declarationEntries, declarationEntriesLoading } = useEntries({
    workflowsId,
    taxableEntityId: taxableEntity?.id || 0,
    filters,
    period,
    workflowFromGridTable,
  });

  return (
    <Root>
      <Flex column css={{ flex: 1, gap: '40px' }}>
        <EntriesTable
          declarationEntries={declarationEntries}
          declarationEntriesLoading={declarationEntriesLoading}
          accessToken={taxableEntity?.user?.access_token || ''}
        />
      </Flex>
    </Root>
  );
}
