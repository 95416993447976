import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { sendEmailSuccess, sendEmailFailure } from '../actions';

export default function* ({ payload: { id, pdf, xml, email } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const formData = new FormData();
    formData.append('pdf', pdf);
    formData.append('xml', xml);
    formData.append('email', email);
    const response = yield call(api.post, `cfdis/${id}/send_email`, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: access_token,
      },
    });
    yield call(StatusHandler, {
      response,
      title: 'Se envió con éxito',
      message: `
            Se envió correctamente a ${email}. 
            Revisa la bandeja de entrada.`,
      status: response.status,
      success: sendEmailSuccess,
      failure: sendEmailFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: sendEmailFailure,
      showBodyErrors: true,
    });
  }
}
