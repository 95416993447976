import {
  REPORTS_FETCH_INCOMES_BEGIN,
  REPORTS_FETCH_INCOMES_SUCCESS,
  REPORTS_FETCH_INCOMES_FAILURE,
  REPORTS_FETCH_EGRESSES_BEGIN,
  REPORTS_FETCH_EGRESSES_FAILURE,
  REPORTS_FETCH_EGRESSES_SUCCESS,
  REPORTS_FETCH_INCOME_BEGIN,
  REPORTS_FETCH_INCOME_FAILURE,
  REPORTS_FETCH_INCOME_SUCCESS,
  REPORTS_FETCH_EGRESS_BEGIN,
  REPORTS_FETCH_EGRESS_FAILURE,
  REPORTS_FETCH_EGRESS_SUCCESS,
} from '@constants/actionTypes';

const INIT_STATE = {
  incomesLoading: false,
  incomesSuccess: false,
  incomesError: false,
  incomes: [],
  egressesLoading: false,
  egressesSuccess: false,
  egressesError: false,
  egresses: [],
  incomeLoading: false,
  incomeSuccess: false,
  incomeError: false,
  egressLoading: false,
  egressSuccess: false,
  egressError: false,
  egress: {},
  income: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REPORTS_FETCH_INCOMES_BEGIN: {
      return {
        ...state,
        incomesLoading: true,
        incomesError: false,
        incomesSuccess: false,
      };
    }
    case REPORTS_FETCH_INCOMES_FAILURE: {
      return {
        ...state,
        incomesLoading: false,
        incomesError: true,
      };
    }
    case REPORTS_FETCH_INCOMES_SUCCESS: {
      return {
        ...state,
        incomesLoading: false,
        incomesSuccess: true,
        incomes: action.payload,
      };
    }
    case REPORTS_FETCH_EGRESSES_BEGIN: {
      return {
        ...state,
        egressesLoading: true,
        egressesError: false,
        egressesSuccess: false,
      };
    }
    case REPORTS_FETCH_EGRESSES_FAILURE: {
      return {
        ...state,
        egressesLoading: false,
        egressesError: true,
      };
    }
    case REPORTS_FETCH_EGRESSES_SUCCESS: {
      return {
        ...state,
        egressesLoading: false,
        egressesSuccess: true,
        egresses: action.payload,
      };
    }
    case REPORTS_FETCH_INCOME_BEGIN: {
      return {
        ...state,
        incomeLoading: true,
        incomeError: false,
        incomeSuccess: false,
      };
    }
    case REPORTS_FETCH_INCOME_FAILURE: {
      return {
        ...state,
        incomeLoading: false,
        incomeError: true,
      };
    }
    case REPORTS_FETCH_INCOME_SUCCESS: {
      return {
        ...state,
        incomeLoading: false,
        incomeSuccess: true,
        income: action.payload,
      };
    }
    case REPORTS_FETCH_EGRESS_BEGIN: {
      return {
        ...state,
        egressLoading: true,
        egressError: false,
        egressSuccess: false,
      };
    }
    case REPORTS_FETCH_EGRESS_FAILURE: {
      return {
        ...state,
        egressLoading: false,
        egressError: true,
      };
    }
    case REPORTS_FETCH_EGRESS_SUCCESS: {
      return {
        ...state,
        egressLoading: false,
        egressSuccess: true,
        egress: action.payload,
      };
    }

    default:
      return state;
  }
};
