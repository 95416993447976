/* eslint-disable react-hooks/exhaustive-deps */
import * as yup from 'yup';
import { useEffect, useMemo } from 'react';
import {
  BalancePaymentType,
  PreferencesAnnualProcessStep,
  ProcessType,
} from 'types/entities';
import { useFormik } from 'formik';
import useProcess from './useProcess';

const isReturnOption = (balance: BalancePaymentType) => balance === 'return';
const notRequiredValidation = yup.string().nullable();
const validationSchema = yup.object({
  balance: notRequiredValidation,
  bank_statement: yup
    .mixed()
    .when('balance', {
      is: isReturnOption,
      then: yup.mixed().nullable().required('El documento es requerido'),
    })
    .default(null),
  bank_name: notRequiredValidation.default(null),
  balance_resico: notRequiredValidation,
  bank_statement_resico: yup
    .mixed()
    .nullable()
    .when('balance_resico', {
      is: isReturnOption,
      then: yup.mixed().nullable().required('El documento es requerido'),
    })
    .default(null),
  bank_name_resico: notRequiredValidation.default(null),
});

const defaultInitialValues = {
  balance: '',
  clabe: null,
  bank_statement: null,
  bank_name: null,
  balance_resico: '',
  clabe_resico: null,
  bank_name_resico: null,
  bank_statement_resico: null,
};

export type PreferencesAnnualPayload = yup.InferType<typeof validationSchema>;
function useInitialValues(): PreferencesAnnualPayload {
  const { activeStep } = useProcess(ProcessType.AnnualDeclarationProcess);

  return useMemo(() => {
    const preferencesData = activeStep as PreferencesAnnualProcessStep;

    if (!activeStep?.completed) {
      return defaultInitialValues;
    }

    const {
      clabe,
      bank_name,
      balance,
      balance_resico,
      clabe_resico,
      bank_name_resico,
    } = preferencesData.answers;

    const bankStatement = preferencesData.bank_statement;
    const bankStatementResico = preferencesData.bank_statement_resico;
    return {
      balance: balance || '',
      clabe: clabe || null,
      bank_name: bank_name || null,
      bank_statement: bankStatement || null,
      balance_resico: balance_resico || '',
      clabe_resico: clabe_resico || null,
      bank_name_resico: bank_name_resico || null,
      bank_statement_resico: bankStatementResico || null,
    };
  }, [activeStep]);
}

interface UsePreferencesAnnualFormProps {
  onSubmitForm: (payload: PreferencesAnnualPayload) => void;
}
export default function usePreferencesAnnualForm({
  onSubmitForm,
}: UsePreferencesAnnualFormProps) {
  const initialValues = useInitialValues();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmitForm(values);
    },
  });

  const { balance, balance_resico } = formik.values;

  useEffect(() => {
    if (balance !== 'return') {
      void formik.setFieldValue('bank_name', null);
      void formik.setFieldValue('clabe', null);
    }
  }, [balance]);

  useEffect(() => {
    if (balance_resico !== 'return') {
      void formik.setFieldValue('bank_name_resico', null);
      void formik.setFieldValue('clabe_resico', null);
    }
  }, [balance_resico]);

  return {
    formik,
  };
}
