import type { ReceiverAttributes } from 'types/cfdi';
import clientToOption from '@util/clientToOption';
import {
  DEFAULT_CFDI_OPTION,
  USE_CFDI_OPTIONS,
  FISCAL_RESIDENCE_OPTIONS,
} from '@constants/invoicing';
import useClients from './useClients';
import useDraftSelectsParser from './useDraftSelectsParser';
import { ClientSupplier, ReactSelectItemProps } from '../types/entities';

export default function useReceiverAttributesParser(
  receiverAttributes?: ReceiverAttributes,
) {
  const { clients } = useClients();
  const client = clients.find((item) => item.rfc === receiverAttributes?.rfc);
  const clientOpt = client
    ? (clientToOption(client) as ReactSelectItemProps<ClientSupplier>)
    : null;

  const cfdiUsage = useDraftSelectsParser(
    receiverAttributes?.cfdi_usage,
    USE_CFDI_OPTIONS,
    DEFAULT_CFDI_OPTION,
  );
  const taxResidency = useDraftSelectsParser(
    receiverAttributes?.tax_residency,
    FISCAL_RESIDENCE_OPTIONS,
  );

  return {
    client: clientOpt,
    cfdiUsage,
    taxResidency,
  };
}
