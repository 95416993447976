import { useMemo, useState } from 'react';
import { Flex } from '@konta/ui';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { ProcessType } from 'types/entities';
import FormikTextarea from '@components/FormikTextarea';
import NextStepButton from '@components/NextStepButton';
import ProcessStepLayout from '@components/ProcessStepLayout';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepDescription from '@components/ProcessStepDescription';

const validationSchema = yup.object().shape({
  whyAreNotInterested: yup.string().required('Campo requerido'),
  comments: yup.object().nullable(),
});

function useInitialValues() {
  const initialValues = useMemo(
    () => ({
      whyAreNotInterested: '',
      comments: '',
    }),
    [],
  );

  return initialValues;
}

export default function DeclineAnnual() {
  const initialValues = useInitialValues();
  const [payload, setPayload] = useState<Record<string, string>>();
  const process = ProcessType.AnnualDeclarationProcess;

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      const { whyAreNotInterested, comments } = values;
      setPayload({
        why_are_not_you_interested_on_this_benefit: whyAreNotInterested,
        comments,
      });
    },
  });

  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Declaraciones Anuales"
      titleChip="En curso"
      content={
        <FormikProvider value={formik}>
          <Flex direction="column" gap={12}>
            <ProcessStepTitle>Declinar declaración anual</ProcessStepTitle>
            <ProcessStepDescription>
              Entendemos que cada persona tiene diferentes necesidades y
              preferencias en cuanto a la presentación de su declaración anual.
              Si has decidido no utilizar nuestros servicios para este
              propósito, nos gustaría saber por qué para mejorar en lo que
              hacemos.
            </ProcessStepDescription>
          </Flex>

          <Flex direction="column" gap={16}>
            {/*
            // @ts-ignore  using a js file */}
            <FormikTextarea
              rows={5}
              maxlength="300"
              name="whyAreNotInterested"
              maxTextLength={300}
              placeholder="Ej. No aplica en mi régimen fiscal."
              label="¿Por qué no deseas que presentemos tu declaración anual?"
            />
            {/*
            // @ts-ignore  using a js file */}
            <FormikTextarea
              rows={5}
              maxlength="300"
              maxTextLength={300}
              name="comments"
              placeholder="Ej. Quisiera tener ayuda sobre las declaraciones anuales."
              label="Aquí puedes escribir cualquier comentario adicional que nos quieras compartir. Agradecemos tu opinión y esperamos poder servirte en el futuro."
            />
          </Flex>
          <Flex gap={12} justify="end">
            <NextStepButton
              currentProcessType={process}
              data-testid="decline-annual-next"
              text="Continuar"
              payload={payload}
              onClick={() => {
                void formik.submitForm();
              }}
            />
          </Flex>
        </FormikProvider>
      }
    />
  );
}
