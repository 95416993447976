import type { AxiosRequestConfig } from 'axios';
import type { CannyLoginRequest } from 'types/entities';
import api from './index';

// eslint-disable-next-line import/prefer-default-export
export const getSessionsCanny = async (
  config?: AxiosRequestConfig<CannyLoginRequest>,
) => {
  const { data } = await api.get<CannyLoginRequest>(`sessions/canny`, config);
  return data;
};
