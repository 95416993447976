import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, Emoji } from '@components';
import { Link } from 'react-router-dom';
import HelpCenterLink from '@components/HelpCenterLink';

function NotCsdUploadInvoice() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <Link to="//youtu.be/CqcqsEnJeek" target="_blank">
            <Emoji symbol="🚀" label="rocket" className="mr-2" />
            <span className="clickable-text-color">Tour por Konta</span>
          </Link>
        </Colxx>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />
      {/*
      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
        </Colxx>
        <Colxx md="12">
          <NavLink exact="true" className="pl-0"to={"app/pendientes"}>
            <span className="clickable-text-color">
              Link a artículo
            </span>
          </NavLink>
        </Colxx>
      </Row>
      <hr/>
      <Row>
        <Colxx md="12">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
        </Colxx>
        <Colxx md="12">
          <NavLink exact="true" className="pl-0"to={"app/pendientes"}>
            <span className="clickable-text-color">
              Link a artículo
            </span>
          </NavLink>
        </Colxx>
      </Row>
      <hr/>
      <Row>
        <Colxx md="12">
          <NavLink exact="true" className="pl-0"to={"app/pendientes"}>
            <span className="clickable-text-color">
              Link a artículo
            </span>
          </NavLink>
        </Colxx>
        <Colxx md="12">
          <NavLink exact="true" className="pl-0"to={"app/pendientes"}>
            <span className="clickable-text-color">
              Link a artículo
            </span>
          </NavLink>
        </Colxx>
      </Row>
      */}
    </>
  );
}
export default NotCsdUploadInvoice;
