import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, ImageVideoModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

function CreateForignAndPublicInvoice() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />

      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p className="font-weight-bold">Facturación electrónica</p>
          <p>
            Para que el SAT reconozca nuestro ingreso en tiempo y forma, debemos
            expedir el CFDI dentro del mes en el que ocurrió el ingreso.
            Consulte todos los requisitos en el siguiente artículos.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
      <Row>
        <Colxx md="12">
          <p className="font-weight-bold">Facturación a público en general</p>
          <p>
            La factura al Público en General es aquella que se emite a la clave
            genérica XAXX010101000 con el propósito de señalar un ingreso por el
            cual su cliente no le solicitó una factura.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
    </>
  );
}
export default CreateForignAndPublicInvoice;
