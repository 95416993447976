import {
  DOCUMENTS_FETCH,
  DOCUMENTS_FETCH_SUCCESS,
  DOCUMENTS_FETCH_FAILURE,
  DOCUMENTS_POST,
  DOCUMENTS_POST_SUCCESS,
  DOCUMENTS_POST_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  documents: [],
  loading: false,
  error: null,
  postSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOCUMENTS_FETCH:
    case DOCUMENTS_POST:
      return { ...state, loading: true };
    case DOCUMENTS_FETCH_SUCCESS:
    case DOCUMENTS_POST_SUCCESS:
      return { ...state, loading: false, documents: action.payload };
    case DOCUMENTS_FETCH_FAILURE:
    case DOCUMENTS_POST_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};
