import { useShallow } from 'zustand/react/shallow';
import { Flex } from '@konta/ui';
import TaskCard from '@components/TaskCard';
import usePendingTasksStore from 'store/pendingTasksStore';
import TaskEmptyState from './TaskEmptyState';

interface TasksListProps {
  tab: 'requiredAction' | 'pending' | 'completed';
}

export default function TasksList({ tab }: TasksListProps) {
  const pendingTasks = usePendingTasksStore(
    useShallow((state) => state.pendingTasks),
  );

  const filteredPendingTasks = pendingTasks.filter(
    (task) => task.status === tab,
  );

  if (filteredPendingTasks.length === 0) {
    return <TaskEmptyState tab={tab} />;
  }

  return (
    <Flex gap={12} direction="column">
      {filteredPendingTasks.map((task) => (
        <TaskCard key={task.id} task={task} />
      ))}
    </Flex>
  );
}
