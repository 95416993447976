import React, { useState } from 'react';
import { Modal, Row, NavLink } from 'reactstrap';
import { Colxx, Emoji } from '@components';

export function ImageVideoModal({
  channel = 'youtube',
  videoId,
  textLink,
  youtube = {
    autoplay: 1,
    cc_load_policy: 1,
    color: null,
    controls: 1,
    disablekb: 0,
    enablejsapi: 0,
    end: null,
    fs: 1,
    h1: null,
    iv_load_policy: 1,
    list: null,
    listType: null,
    loop: 0,
    modestbranding: null,
    origin: null,
    playlist: null,
    playsinline: null,
    rel: 0,
    showinfo: 1,
    start: 0,
    wmode: 'transparent',
    theme: 'dark',
    mute: 0,
  },
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const getVideoUrl = (videoId) => {
    if (channel === 'youtube') {
      return getYoutubeUrl(youtube, videoId);
    }
  };

  const getYoutubeUrl = (youtube, videoId) => {
    const query = getQueryString(youtube);
    return `//www.youtube.com/embed/${videoId}?${query}`;
  };

  const getQueryString = (obj) => {
    let url = '';
    for (const key in obj) {
      if (obj[key] !== null) {
        url += `${key}=${obj[key]}&`;
      }
    }
    return url.substr(0, url.length - 1);
  };

  return (
    <>
      {!textLink ? (
        <div className="text-center mb-4">
          <img
            src={require('@assets/img/video-button.png')}
            alt="video play"
            className="rounded image-button"
            onClick={() => toggleModal()}
          />
        </div>
      ) : (
        <NavLink onClick={() => toggleModal()} exact="true" to={textLink.link}>
          <span className="clickable-text-color">
            <Emoji symbol={textLink.emojiSymbol} label={textLink.emojiLabel} />{' '}
            {textLink.text}
          </span>
        </NavLink>
      )}
      <Modal
        centered
        isOpen={isOpen}
        toggle={toggleModal}
        className="video-modal"
        size="lg"
        onClose={() => setIsOpen(false)}
      >
        <Row className="">
          <Colxx md="12">
            <div className="video-modal-iframe-wrap">
              <iframe
                className="video-iframe"
                width="460"
                height="230"
                src={getVideoUrl(videoId)}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                tabIndex="-1"
              />
            </div>
          </Colxx>
        </Row>
      </Modal>
    </>
  );
}
