import { ColumnFilter } from '@tanstack/react-table';
import type { PaginationParams } from 'types/pagination';

type NumberFilter = {
  value: number;
  operand: string;
};

const convertOperandFilterToRansackOperand = (operand: string) =>
  ({
    greatherThan: 'gt',
    lessThan: 'lt',
    equals: 'eq',
    greatherThanEquals: 'gteq',
    lessThanEquals: 'lteq',
  }[operand] || 'eq');

export default function ransackTableParams(
  filters: ColumnFilter[],
  options: {
    dict: Record<string, string>;
    defaultFilters: PaginationParams;
  } = { dict: {}, defaultFilters: {} },
) {
  const filter: PaginationParams = {
    ...options.defaultFilters,
  };

  filters.forEach((columnFilter) => {
    const { id, value } = columnFilter;
    if (!value) return;
    const ransackKey = options.dict[id];
    if (ransackKey) {
      filter[ransackKey] = value as string;
      return;
    }
    if (typeof value === 'object' && 'operand' in value) {
      const operand = convertOperandFilterToRansackOperand(
        (value as NumberFilter).operand,
      );
      filter[`q[${id}_${operand}]`] = (value as NumberFilter).value;
    } else if (id === 'uuidOfCfdi') {
      filter[`q[cfdi_folio_fiscal_eq]`] = value;
    } else if (id === 'social_reason') {
      filter[`q[foreign_taxpayer_social_reason_cont]`] = value;
    } else {
      filter[`q[${id}_eq]`] = value;
    }
  });

  return filter;
}
