import api from '@api';
import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import {
  fetchSubscriptionsSuccess,
  fetchSubscriptionsFailure,
} from '../actions';

export default function* () {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.get,
      `/subscriptions/current_subscriptions`,
      {
        headers: { Authorization: `${access_token}` },
      }
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: fetchSubscriptionsSuccess,
      failure: fetchSubscriptionsFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: fetchSubscriptionsFailure,
    });
  }
}
