import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, ImageVideoModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

function ExpencesClassification() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />

      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p className="font-weight-bold">
            Régimen de Plataformas Tecnológicas
          </p>
          <p>
            Todos los contribuyentes bajo este régimen estan obligados a expedir
            a sus usuarios el comprobante que ampare el monto de los servicios o
            bienes otorgados. El comprobante fiscal deberá portar la clave 625
            del catálogo de tipo de régimen de la factura, así como contar con
            todos los requisitos indicados en el artículo 29-A del Código Fiscal
            de la Federación.
          </p>
        </Colxx>
        <Colxx md="12">
          <HelpCenterLink articleId="technology_platforms">
            Link al artículo
          </HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
      <Row>
        <Colxx md="12">
          <p className="font-weight-bold">
            Certificado de los sellos digitales
          </p>
          <p>
            El Certificado del Sello Digital (o CSD) funciona como una forma de
            identificación fiscal, y es requisita para facturar de forma
            electrónica para evitar robos o manipulaciones a los datos de las
            partes.
          </p>
        </Colxx>
        <Colxx md="12">
          <HelpCenterLink articleId="what_is_csd">
            Link al artículo
          </HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
    </>
  );
}
export default ExpencesClassification;
