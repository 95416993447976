import { styled } from '@konta/ui';
import LetterAvatar from 'react-avatar';

export const Root = styled('div', {
  height: '100%',
  backgroundColor: '$white',
  minWidth: '360px',
  borderRight: '1px solid $gray200',
});

export const Header = styled('div', {
  display: 'flex',
  padding: '20px 24px',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
  alignSelf: 'stretch',
});

export const Title = styled('span', {
  color: '$gray900',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
});

export const TicketsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: 'calc(100% - 64px)',
});

export const TicketItem = styled('div', {
  display: 'flex',
  padding: '16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  cursor: 'pointer',
  borderBottom: '1px solid $gray200',
  '&:hover': {
    backgroundColor: '$gray25',
  },
});

export const TicketItemContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignSelf: 'stretch',
  gap: '16px',
});

export const TicketItemInfoWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
});

export const TicketItemInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  variants: {
    alignEnd: {
      true: {
        alignItems: 'flex-end',
      },
    },
  },
});

export const TicketTitle = styled('span', {
  color: '$gray700',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
});

export const TicketDescription = styled('span', {
  color: '$gray500',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
});

export const TicketAvatar = styled(LetterAvatar, {
  width: '38px!important',
  height: '38px!important',
});
