import { css, CSS, styled } from '@konta/ui';
import { BookOpen01LineIcon } from '@konta/icons';

export const flexColumBaseCss: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
};

export const Container = styled('div', {
  ...flexColumBaseCss,
  padding: '$20 $16 0px',
  mb: '$40',
  gap: '$20',
});
export const CardContainer = styled('div', {
  display: 'flex',
  padding: '20px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  flex: '1 0 0',
  borderRadius: '8px',
  border: '1px solid $gray200',
  width: '100%',
});

export const Header = styled('div', {
  ...flexColumBaseCss,
  padding: '0',
  gap: '$12',
});

export const HeaderContent = styled('div', {
  ...flexColumBaseCss,
  gap: '$12',
});
export const TitleAndActions = styled('div', {
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
});
export const Heading = styled('div', {
  ...flexColumBaseCss,
  gap: '6px',
  flex: '1 0 0',
});

export const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const Divider = styled('hr', {
  all: 'unset',
  borderTop: 'solid 1px $colors$gray200',
  width: '100%',
});

export const FilterContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '6px',
  flex: '1 0 0',
});

export const Content = styled('div', {
  ...flexColumBaseCss,
  gap: '$20',
});
