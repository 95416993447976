import { useMemo } from 'react';
import useScript from 'shared/hooks/useScript';
import { GOOGLE_RECAPTCHA } from 'shared/constants/scripts';
import useFeature from '@hooks/useFeature';
import { ENABLE_GOOGLE_CAPTCHA } from '@constants/featureFlags';

export default function useGoogleRecaptchaScript() {
  const [isGoogleCaptchaEnabled] = useFeature(ENABLE_GOOGLE_CAPTCHA);
  const googleScriptStatus = useScript(
    isGoogleCaptchaEnabled ? GOOGLE_RECAPTCHA : '',
  );

  return useMemo(() => {
    if (isGoogleCaptchaEnabled) {
      const isGoogleScriptLoading = googleScriptStatus === 'loading';
      const isGoogleScriptReady = googleScriptStatus === 'ready';
      const hasGoogleScriptError =
        ['error', 'idle'].includes(googleScriptStatus) ||
        !window?.grecaptcha?.enterprise;
      const recaptcha = window?.grecaptcha?.enterprise;

      return {
        recaptcha,
        isGoogleScriptLoading,
        isGoogleScriptReady,
        hasGoogleScriptError,
      };
    }

    // Default values when Google Captcha is not enabled
    return {
      recaptcha: {
        execute: () => Promise.resolve('CAPTCHA_TOKEN'),
        ready: (callback: () => void) => callback(),
      },
      isGoogleScriptLoading: false,
      isGoogleScriptReady: true,
      hasGoogleScriptError: false,
    };
  }, [isGoogleCaptchaEnabled, googleScriptStatus]);
}
