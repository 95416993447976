import React, { useState } from 'react';
import { Flex } from '@konta/ui';
import Referrals from '@routes/app/Referrals';
import { ProcessType } from 'types/entities';
import NextStepButton from '@components/NextStepButton';
import ProcessStepLayout from '@components/ProcessStepLayout';

const process = ProcessType.OnboardingOnboardingProcess;

export default function OnboardingReferrals() {
  const [payload, setPayload] = useState<boolean>();
  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Apertura de cuenta"
      titleChip="En curso"
      content={
        <Flex direction="column">
          <Referrals />
          <Flex>
            <NextStepButton
              currentProcessType={process}
              data-cy="referralsNextBtn"
              text="Finalizar"
              payload={payload}
              onClick={() => setPayload(true)}
            />
          </Flex>
        </Flex>
      }
      rightSidebarContent
    />
  );
}
