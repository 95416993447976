import { Download01LineIcon, FileSearch02LineIcon } from '@konta/icons';
import { Button, Text } from '@konta/ui';
import Loader from '@components/Loader';
import type { AnnualDeclaration } from 'types/entities';
import { getBase64FromUrl } from '@util/Utils';
import { useState } from 'react';
import {
  CardContainer,
  CardDetailContent,
  CardDetails,
  CardFooter,
  ChartsContainer,
  DownloadText,
  ResumeContainer,
  ResumeHeading,
} from './styled';
import Metric from './Metric/Metric';
import useRegimeAnnualResume from '../useRegimeAnnualResume';
import Graph from './Graph/Graph';

interface RegimeCardProps {
  declarationsByRegimen?: Record<
    string,
    string | number | Record<string, number>
  >;
  regime?: string | null;
  totals?: ReturnType<typeof useRegimeAnnualResume>['totals'];
  onDetailClick?: () => void;
  isLoading?: boolean;
  currentAnnualDeclaration: AnnualDeclaration;
  preferredFiscalRegime?: number | null;
}

export default function RegimeCard({
  preferredFiscalRegime,
  declarationsByRegimen,
  regime,
  totals,
  isLoading,
  onDetailClick,
  currentAnnualDeclaration,
}: RegimeCardProps) {
  const [downloadAcusesLoading, setDownloadAcusesLoading] = useState(false);

  const regimeLabel = {
    pfaeAndPlatforms: 'PFAE + PT',
    pfae: 'PFAE',
    hasPlatforms: 'PT',
    resico: 'RESICO',
    salaries: 'SALARIOS',
    empty: 'Sin regimen',
  }[regime as string];

  const isResico = regime === 'resico';

  const payableTaxes = {
    pfaeAndPlatforms: totals?.payableTaxes,
    hasPlatforms: totals?.payableTaxes,
    pfae: totals?.payableTaxes,
    resico: totals?.payableTaxesResico,
    salaries: totals?.payableTaxes,
    empty: 0,
  }[regime as string];

  const title = payableTaxes && payableTaxes > 0 ? 'Total a pagar' : 'A favor';

  const hasAcuses =
    currentAnnualDeclaration?.status === 'closed' &&
    currentAnnualDeclaration?.acuses?.length > 0;

  const handleDownloadAcuses = async () => {
    try {
      setDownloadAcusesLoading(true);
      await Promise.all(
        currentAnnualDeclaration.acuses.map(async (link) => {
          const blobFile = (await getBase64FromUrl(link)) as string;
          const a = document.createElement('a');
          a.href = blobFile;
          a.download = `acuse-${currentAnnualDeclaration.period}.pdf`;
          a.click();
          a.remove();
        }),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDownloadAcusesLoading(false);
    }
  };

  if (isLoading) {
    return (
      <CardContainer>
        <CardDetails
          css={{
            justifyContent: 'center',
            height: '400px',
          }}
        >
          <Loader />
        </CardDetails>
      </CardContainer>
    );
  }

  return (
    <CardContainer>
      <CardDetails>
        <CardDetailContent>
          <ResumeContainer>
            <ResumeHeading>
              <Text semibold size="l" lineHeight="l">
                Resumen de impuestos: {regimeLabel}
              </Text>
              <Text color="gray500" size="xs">
                Esto es el total de impuestos durante el año
              </Text>
            </ResumeHeading>
            {regime !== 'salaries' && (
              <>
                <Metric title="Ingresos" value={totals?.incomes} />
                {!isResico && (
                  <Metric title="Gastos" value={totals?.expenses} />
                )}
              </>
            )}
            <Metric
              title={title}
              value={Math.abs(payableTaxes || 0)}
              isTotal
              detailButton={
                !!onDetailClick && (
                  <Button
                    data-cy="goToDetailsBtn"
                    size="xs"
                    variant="text"
                    color="primary600"
                    onClick={onDetailClick}
                    rightIcon={<FileSearch02LineIcon />}
                    css={{
                      svg: {
                        path: {
                          fill: 'none',
                        },
                      },
                    }}
                  >
                    Ver detalle
                  </Button>
                )
              }
            />
            {isResico && (
              <Text color="gray500" size="xs" medium>
                Recuerda que para este regimen no se toman en cuenta los gastos
              </Text>
            )}
          </ResumeContainer>
          <ChartsContainer>
            <Graph
              groupedDeclaration={declarationsByRegimen}
              regimeLabel={regimeLabel as string}
            />
          </ChartsContainer>
        </CardDetailContent>
        {hasAcuses && (
          <CardFooter>
            <DownloadText>Tu acuse esta listo para descargar</DownloadText>
            <Button
              loading={downloadAcusesLoading}
              onClick={handleDownloadAcuses}
              color="primary600"
              rightIcon={<Download01LineIcon color="white" />}
            >
              Descargar acuse
            </Button>
          </CardFooter>
        )}
      </CardDetails>
    </CardContainer>
  );
}
