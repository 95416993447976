import { BarSeries, Text, Flex, useMediaQuery, styled } from '@konta/ui';
import { useCallback } from 'react';

interface GraphProps {
  groupedDeclaration?: Record<string, string | number | Record<string, number>>;
  regimeLabel: string;
}

const CharDot = styled('div', {
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  variants: {
    color: {
      incomes: { backgroundColor: '$primary500' },
      expenses: { backgroundColor: '$primary300' },
    },
  },
});
export default function Graph({ groupedDeclaration, regimeLabel }: GraphProps) {
  const isDesktop = useMediaQuery('(min-width: 1790px)');
  const getGroupedDataSet = useCallback(() => {
    if (!groupedDeclaration) {
      return {
        label: '',
        currency: true,
        data: [],
      };
    }

    const incomesExpensesData = Object.entries(groupedDeclaration)
      .filter(
        ([key, value]) =>
          typeof value !== 'string' &&
          !['regimeSatKey', 'regimen', 'id'].includes(key),
      )
      .map(([month, value]) => ({
        column: month.substring(0, 3),
        Ingresos:
          typeof value !== 'string'
            ? +((value as Record<string, number>)?.incomes || 0)
            : 0,
        Gastos:
          typeof value !== 'string'
            ? +((value as Record<string, number>)?.expenses || 0)
            : 0,
      }));

    return {
      label: regimeLabel,
      currency: true,
      data: incomesExpensesData,
    };
  }, [groupedDeclaration, regimeLabel]);

  const dataSet = getGroupedDataSet();

  return (
    <Flex
      css={{
        width: '100%',
        flexDirection: 'column',
        '@md': { flexDirection: 'row' },
      }}
    >
      <Flex
        css={{ flex: 1, overflow: 'auto', gap: 10, flexDirection: 'column' }}
      >
        {regimeLabel !== 'SALARIOS' && (
          <Flex gap={16} justify="end">
            <Flex align="center" gap={8}>
              <CharDot color="incomes" />
              <Text size="s" color="gray700">
                Ingresos
              </Text>
            </Flex>
            {regimeLabel !== 'RESICO' && (
              <Flex align="center" gap={8}>
                <CharDot color="expenses" />
                <Text size="s" color="gray700">
                  Gastos
                </Text>
              </Flex>
            )}
          </Flex>
        )}

        <BarSeries
          margin={{ top: 45, right: 20, bottom: 40, left: 50 }}
          showTooltip
          dataSet={dataSet}
          height={280}
          id="incomesAndExpensesAnnualBarSeries"
          width={isDesktop ? undefined : 570}
          paddingInner={0.4}
          nTicks={12}
          showAnnotations
          type="groupedBar"
        />
      </Flex>
    </Flex>
  );
}
