import api from '@api';
import {
  SingInResponse,
  SingInRequest,
  SingInTwoFactorRequest,
  GoogleSignInRequest,
} from 'types/entities';

export const postGoogleOauth = async (payload: GoogleSignInRequest) => {
  const { data } = await api.post<SingInResponse>(
    `auth/google_oauth2/callback`,
    payload,
  );
  return data;
};

export const postSignIn = async (
  payload: SingInRequest | SingInTwoFactorRequest,
) => {
  const { data } = await api.post<SingInResponse>(`sign_in`, payload);
  return data;
};

export const getSignIn = async () => {
  const { data } = await api.get<SingInResponse>(`sign_in`);
  return data;
};
