import { StatusHandler, ErrorHandler } from '@util/Saga';
import { call, select } from 'redux-saga/effects';
import api from '@api';
import { postDocsSuccess, postDocsFailure } from '../actions';

export default function* ({ payload: { document, category } }) {
  try {
    const { taxable_entity } = yield select((state) => state.taxableEntity);
    const formData = new FormData();
    document.forEach(function (doc, i) {
      formData.append(`document[${i}][attachment]`, doc);
      formData.append(`document[${i}][category_id]`, category);
    });
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.post,
      `/taxable_entities/${taxable_entity.id}/documents`,
      formData,
      {
        headers: { Authorization: `${access_token}` },
      }
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: postDocsSuccess,
      failure: postDocsFailure,
      title: 'Documento guardado',
      message: 'Se ha almacenado el archivo correctamente.',
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: postDocsFailure,
    });
  }
}
