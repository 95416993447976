import { useCommandState } from 'cmdk';
import { Text } from '@konta/ui';
import { useCommandMenuContext } from '@providers/CommandMenuProvider';
import { HelpCircleLineIcon, MessageChatSquareLineIcon } from '@konta/icons';
import * as chat from '@util/chat';
import Item from '../Item';

export default function EmptyState() {
  const { pushPage, toggleShowCommandMenu } = useCommandMenuContext();
  const search = useCommandState((state) => state.search);
  const filtered = useCommandState((state) => state.filtered);
  const hasSearchResults = filtered.count > 0;

  return (
    <>
      {!hasSearchResults && search.length > 0 && (
        <>
          <Text css={{ mb: '$6' }}>
            “{search}” no coincide con ningúna búsqueda.
          </Text>
          <Item forceMount onSelect={() => pushPage('helpCenter')}>
            <HelpCircleLineIcon />
            Centro de ayuda
          </Item>
          <Item
            forceMount
            onSelect={() => {
              toggleShowCommandMenu();
              chat.open();
            }}
          >
            <MessageChatSquareLineIcon />
            Chatea con un agente
          </Item>
        </>
      )}
    </>
  );
}
