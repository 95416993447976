import { Box, Flex, Text } from '@konta/ui';
import React from 'react';

interface WelcomeFeatureTextProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

export default function WelcomeFeatureText({
  icon,
  title,
  description,
}: WelcomeFeatureTextProps) {
  return (
    <Flex gap={12} direction="column">
      <Box css={{ lineHeight: '0', svg: { width: '24px', height: '24px' } }}>
        {icon}
      </Box>
      <Text color="gray900" lineHeight="l" l medium>
        {title}
      </Text>
      <Text color="gray500" lineHeight="m" m medium>
        {description}
      </Text>
    </Flex>
  );
}
