import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { FormGroup, Label } from 'reactstrap';
import TaxBadge from './TaxBadge';

/**
 * @typedef {{ rate: number, amount: number }} Tax
 */

/**
 * @typedef {{ ieps: Tax, isr: Tax, iva: Tax, retainedIva: Tax }} Taxes
 */

function hasTaxes(value) {
  return !!value?.ieps || !!value?.isr || !!value?.iva || !!value?.retainedIva;
}

export default function FormikTaxesField({ name, label }) {
  const [{ value }] = useField({ name });

  /**
   * @type { Taxes }
   */
  const { iva, retainedIva, ieps, isr } = value || {};

  return (
    <FormGroup>
      <Label className="d-inline-block">{label}</Label>
      {hasTaxes(value) && (
        <>
          <TaxBadge label="IVA" rate={iva.rate} amount={iva.amount} />

          {retainedIva?.rate > 0 && (
            <TaxBadge
              label="IVA RET"
              rate={retainedIva.rate}
              amount={retainedIva.amount}
            />
          )}

          {ieps?.rate > 0 && (
            <TaxBadge label="IEPS" rate={ieps.rate} amount={ieps.amount} />
          )}

          {isr?.rate > 0 && (
            <TaxBadge label="ISR" rate={isr.rate} amount={isr.amount} />
          )}
        </>
      )}
    </FormGroup>
  );
}

FormikTaxesField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
