import zeroWidthTrim from './zeroWidthTrim';

export default function sanitizeTextForStamp(stringToSanitize: string) {
  if (!stringToSanitize) return '';
  let sanitizeText = zeroWidthTrim(stringToSanitize);
  sanitizeText = sanitizeText.replace(/[\n\r]/g, ' ');
  sanitizeText = sanitizeText.replace(/\s+/g, ' ');
  sanitizeText = sanitizeText.replaceAll('″', '"');
  sanitizeText = sanitizeText.trim();
  return sanitizeText;
}
