import React, { useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import {
  ErrorCode,
  useDropzone,
  FileWithPath,
  FileError,
} from 'react-dropzone';
import { Text, Flex, Card, Button, CardContent } from '@konta/ui';
// @ts-ignore for actions
import { TrashCanOutlineIcon } from '@konta/icons';

interface RejectedFileProps {
  file?: FileWithPath;
  errors?: Array<FileError>;
}

// React.Dispatch<React.SetStateAction<NewFilter[]>>
interface DropzoneProps {
  fileState?: Array<FileWithPath>;
  setFileState?: Dispatch<SetStateAction<FileWithPath[]>>;
  maxFiles?: number;
  filesTypes?: Array<string>;
}

export default function Dropzone({
  setFileState,
  fileState,
  maxFiles,
  filesTypes,
}: DropzoneProps) {
  const onDrop = useCallback(
    (acceptedFiles: Array<FileWithPath>) => {
      setFileState?.([...(fileState || []), ...acceptedFiles]);
    },
    [fileState, setFileState],
  );

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles || 1,
    accept: {
      'application/octet-stream': filesTypes || ['.xml', '.pdf'],
    },
    onDrop,
  });

  const removeFile = (file: FileWithPath) => () => {
    const newFiles = [...(fileState || [])];

    newFiles.splice(newFiles.indexOf(file), 1);
    setFileState?.(newFiles);
  };

  const files = fileState?.map((file: FileWithPath) => (
    <Card outlined>
      <CardContent
        css={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        key={file.path}
      >
        <Text s>{file.path}</Text>
        <Button
          onClick={removeFile(file)}
          size="xs"
          variant="outlined"
          leftIcon={<TrashCanOutlineIcon />}
        >
          Eliminar
        </Button>
      </CardContent>
    </Card>
  ));

  const fileErrors = useMemo(
    () =>
      fileRejections.map(({ file, errors }: RejectedFileProps) => (
        <Flex direction="column" key={file?.path}>
          <Text key={file?.path} xs>
            {file?.path}
          </Text>
          {errors?.map((e) => (
            <Text key={e.code} color="error500" bold>
              {
                {
                  [ErrorCode.FileInvalidType]: 'Archivo inválido',
                  [ErrorCode.FileTooLarge]: 'Tamaño del archivo muy largo',
                  [ErrorCode.FileTooSmall]: 'Tamaño del archivo muy pequeño',
                  [ErrorCode.TooManyFiles]: 'Excedió al límite de archivos',
                }[e.code]
              }
            </Text>
          ))}
        </Flex>
      )),
    [fileRejections],
  );

  return (
    <Flex direction="column" gap={10}>
      {files?.length === 0 && (
        <Flex
          {...getRootProps({ className: 'dropzone' })}
          align="center"
          justify="center"
        >
          <input {...getInputProps()} />
          <Text>Haz click aquí o arrastra tus archivos</Text>
        </Flex>
      )}
      {fileErrors}
      {files}
    </Flex>
  );
}
