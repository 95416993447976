import { ADD_TO_CART, CLEAR_CART } from '@constants/actionTypes';

export const addOrdersToCart = (orders) => {
  return {
    type: ADD_TO_CART,
    payload: orders,
  };
};

export const clearCart = () => {
  return {
    type: CLEAR_CART,
  };
};
