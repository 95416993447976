import ConfirmModal from '@components/Modal/ConfirmModal';
import { SelectedCategory } from 'types/entities';
import { Dispatch, SetStateAction } from 'react';
import { Flex } from '@konta/ui';
import useCfdisModalTable from './useCfdisModalTable';
import CfdisTable from '../CfdisTable';

interface CfdisModalTableProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  periodDate: string;
  title: string;
  setSelectedPeriodDate: Dispatch<SetStateAction<string | null>>;
  selectedCategory: SelectedCategory | null;
  setSelectedCategory: Dispatch<SetStateAction<SelectedCategory | null>>;
}
export default function CfdisModalTable({
  isOpen,
  toggleIsOpen,
  periodDate,
  title,
  selectedCategory,
  setSelectedCategory,
  setSelectedPeriodDate,
}: CfdisModalTableProps) {
  const { onCloseModal } = useCfdisModalTable({
    periodDate,
    toggleIsOpen,
    selectedCategory,
    setSelectedCategory,
    setSelectedPeriodDate,
  });

  return (
    <ConfirmModal
      open={isOpen}
      title={title}
      onClose={onCloseModal}
      onAccept={onCloseModal}
      withBlur
      centered
      size="xl"
      withFooter={false}
    >
      <Flex gap={16} column>
        <CfdisTable
          source="incomes"
          periodDate={periodDate}
          selectedCategory={selectedCategory || null}
        />
      </Flex>
    </ConfirmModal>
  );
}
