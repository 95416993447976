import api from '@api';
import { NotificationManager } from '@components';

export default async function downloadPdfFromCfdi(
  cfdiId: string | number,
  name?: string,
): Promise<void> {
  try {
    if (!cfdiId) {
      throw new Error('No se proporcionó un ID de CFDI');
    }
    const response = await api.get<Buffer>(`/cfdis/${cfdiId}.pdf`, {
      responseType: 'arraybuffer',
    });
    const pdfBase64 = Buffer.from(response.data).toString('base64');
    const linkSource = `data:application/pdf;base64,${pdfBase64}`;
    const downloadLink = document.createElement('a');
    const fileName = name ? `${name}.pdf` : `cfdi-${cfdiId}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
  } catch (e) {
    console.error(e);
    NotificationManager.error(
      'Ocurrió un error al descargar el PDF del CFDI',
      'Error',
    );
  }
}
