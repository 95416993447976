import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  addPaymentMethod,
  paymentMethodsToogle,
  fetchPaymentMethods,
  defaultPaymentMethod,
  deletePaymentMethod,
} from '@redux/actions';

export const usePaymentMethods = (_) => {
  const dispatch = useDispatch();
  const { loading, payment_methods, ui } = useSelector(
    (state) => state.paymentMethods,
    shallowEqual
  );

  const handleAdd = (card) => {
    dispatch(addPaymentMethod(card));
  };

  const handleToggle = (_) => {
    dispatch(paymentMethodsToogle());
  };

  const handleDefaultCard = (taxable_entity, selected_card) => {
    dispatch(defaultPaymentMethod(taxable_entity, selected_card));
  };

  const handleDeleteCard = (taxable_entity_id, selected_card_id) => {
    dispatch(deletePaymentMethod(taxable_entity_id, selected_card_id));
  };

  useEffect((_) => {
    payment_methods.length || dispatch(fetchPaymentMethods());
  }, []);

  return {
    loading,
    payment_methods,
    ui,
    handleAdd,
    handleToggle,
    handleDefaultCard,
    handleDeleteCard,
  };
};
