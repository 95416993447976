import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import type { KontaScore } from 'types/entities';
import { getKontaScore } from 'shared/api/kontaScore';
import { KONTA_SCORE } from 'shared/constants/reactQueries';

export default function useKontaScore(
  axiosConfig?: AxiosRequestConfig<KontaScore>,
  queryOptions?: UseQueryOptions<KontaScore>,
) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<KontaScore>(
    [KONTA_SCORE, axiosConfig],
    async () => {
      const kontaScore = await getKontaScore(axiosConfig);
      return kontaScore;
    },
    queryOptions,
  );

  return {
    kontaScoreLoading: isLoading || isFetching,
    kontaScore: data,
    ...rest,
  };
}
