import { useNavigate } from 'react-router-dom';
import { NotificationManager } from '@components';
import getErrorMessage from '@util/getErrorMessage';
import useCreateInvoiceDraftMutation from './useCreateInvoiceDraftMutation';

export default function useCreateInvoiceDraft() {
  const navigate = useNavigate();
  const createInvoiceDraftMutation = useCreateInvoiceDraftMutation();

  const createInvoiceDraft = async () => {
    try {
      const invoiceDraft = await createInvoiceDraftMutation.mutateAsync();
      if (!invoiceDraft?.uuid) {
        NotificationManager.error(
          'No se creó correctamente la factura',
          'Facturas',
        );
        return;
      }
      navigate(`/app/invoicing/${invoiceDraft.uuid}`);
    } catch (e) {
      NotificationManager.error(getErrorMessage(e), 'Facturas');
    }
  };
  return {
    createInvoiceDraft,
    loadingCreateInvoiceDraft: createInvoiceDraftMutation.isLoading,
  };
}
