const getLabelBoundingClient = (element) => {
  if (element.tagName === 'LABEL') {
    return element.getBoundingClientRect();
  }
  // eslint-disable-next-line no-param-reassign
  element = element.target;
  // REACT-SELECT
  if (element?.closest('div > span')?.previousSibling.tagName === 'LABEL') {
    return element
      .closest('div > span')
      .previousSibling.getBoundingClientRect();
  }
  // KONTA/UI INPUT
  if (element?.parentElement?.previousSibling?.tagName === 'LABEL') {
    return element.parentElement.previousSibling.getBoundingClientRect();
  }
  // KONTA/UI TEXTAREA
  if (element?.previousSibling?.tagName === 'LABEL') {
    return element.previousSibling.getBoundingClientRect();
  }
  // DEFAULT, CHECK, RADIO, ETC
  return element.getBoundingClientRect();
};

export default function getHelpFeatureCss(element) {
  return {
    position: 'relative',
    '@md': {
      position: 'absolute',
      top:
        getLabelBoundingClient(element).top +
        Math.abs(
          document
            .getElementById('onboarding-modal')
            ?.firstChild?.getBoundingClientRect()?.top || 0,
        ),
    },
  };
}
