import { FilterFunnel01LineIcon } from '@konta/icons';
import { Button, FilterControl, Flex, useFilterContext } from '@konta/ui';
import { ReactSelectItemProps } from 'types/entities';
import { FilterContainer, HeaderContent } from './styled';

interface FiltersProps {
  regimeOptions: ReactSelectItemProps<string>[];
}
export default function Filters({ regimeOptions }: FiltersProps) {
  const { handleFilterChange } = useFilterContext();

  return (
    <HeaderContent>
      <Flex
        css={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-end',
          '@<xs': {
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-end',
          },
        }}
      >
        <FilterContainer>
          <Button
            size="xs"
            variant="outlined"
            noFill
            leftIcon={<FilterFunnel01LineIcon />}
            onClick={() => {
              handleFilterChange('fiscal_regime_id', null);
            }}
          >
            Todas
          </Button>
          {regimeOptions.map((opt) => (
            <Button
              key={opt.value}
              size="xs"
              variant="outlined"
              noFill
              leftIcon={<FilterFunnel01LineIcon />}
              onClick={() => {
                handleFilterChange('fiscal_regime_id', opt.value);
              }}
            >
              {opt.label}
            </Button>
          ))}
        </FilterContainer>
        <Flex justify="end">
          <FilterControl />
        </Flex>
      </Flex>
    </HeaderContent>
  );
}
