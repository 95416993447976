import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  bankTransactionsDeclarationsFetchSuccess,
  bankTransactionsDeclarationsFetchFailure,
} from '../actions';

export default function* ({ payload: { workflow_id } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.get,
      `bank_transactions_declarations/by_workflow?workflow_id=${workflow_id}`,
      // axios.get,
      // "https://1e36ca2e-f616-4c3f-9797-6b42f4a4f4cb.mock.pstmn.io/api/v1/bank_transactions_declarations?workflow_id=1",
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: bankTransactionsDeclarationsFetchSuccess,
      failure: bankTransactionsDeclarationsFetchFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: bankTransactionsDeclarationsFetchFailure,
    });
  }
}
