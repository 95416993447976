import OnboardingConfig from './onboarding/OnboardingConfig';
import CustomerConfig from './customer/CustomerConfig';
import DashboardConfig from './dashboard/DashboardConfig';
import ReportsConfig from './reports/ReportsConfig';
import DeclarationsConfig from './declarations/DeclarationsConfig';
import RetoolConfig from './retool/RetoolConfig';
import FlowsConfig from './flows/FlowsConfig';

const modulesConfig = [
  OnboardingConfig,
  CustomerConfig,
  DashboardConfig,
  ReportsConfig,
  DeclarationsConfig,
  RetoolConfig,
  FlowsConfig,
];

export default modulesConfig;
