import React from 'react';
import { Flex, Text } from '@konta/ui';
import { useTaxableEntity } from '@hooks';
import toCurrency from '@util/toCurrency';
import getRewardAmount from '@util/getRewardAmount';

export default function ReferralTotals() {
  const { taxable_entity } = useTaxableEntity();
  const rewardType = taxable_entity.reward_type;

  const reward = getRewardAmount(
    rewardType,
    taxable_entity.pending_rewards_count,
  );

  return (
    <Flex column>
      {rewardType === 'product' ? (
        <>
          <Text color="gray900" bold l>
            Ganancia acumulada
          </Text>
          <Text color="gray500" s>
            Estas son las mensualidades que has ganado al compartir Konta con
            otras personas.
          </Text>
          <Text color="primary700" l>
            Tienes{' '}
            {reward === 1 ? `${reward} Mensualidad` : `${reward} Mensualidades`}{' '}
            gratis
          </Text>
        </>
      ) : (
        <>
          <Text color="gray900" bold l>
            Ganancia acumulada
          </Text>
          <Text color="gray500" s>
            Este es el saldo que has ganado al compartir Konta con otras
            personas.
          </Text>
          <Text color="primary700" l>
            Tienes {toCurrency(reward)} MXN de saldo a favor
          </Text>
        </>
      )}
    </Flex>
  );
}
