import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  PULSE_FETCH_UNCATEGORIZED_INVOICES,
  PULSE_CATEGORIZE_INVOICE,
} from '@constants/actionTypes';

import {
  fetchUncategorizedInvoicesSuccess,
  fetchUncategorizedInvoicesFailure,
  categorizeInvoiceSuccess,
  categorizeInvoiceFailure,
} from './actions';

const fetchUncategorizedInvoicesWithAccessTokenAsync = async () => {
  // return DEMO_UNCATEGORIZED_INCOICES;
  return await axios.get('http://localhost:8080/invoices');
};
function* fetchUncategorizedInvoicesWithAccessToken({ payload }) {
  try {
    const response = yield call(fetchUncategorizedInvoicesWithAccessTokenAsync);
    if (response.status >= 200 && response.status < 300) {
      yield put(fetchUncategorizedInvoicesSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchUncategorizedInvoicesFailure());
  }
}

const setCategoryInvoiceAsync = async (id, category) => {
  return await axios.get('http://localhost:8080/invoices');
};
function* setCategoryInvoice({ payload }) {
  const { category, id } = payload;
  try {
    const response = yield call(setCategoryInvoiceAsync, id, category);
    response.data.shift();
    if (response.status >= 200 && response.status < 300) {
      yield put(categorizeInvoiceSuccess(response.data));
    }
  } catch (error) {
    yield put(categorizeInvoiceFailure());
  }
}

export function* watchFetchUncategorizedInvoices() {
  yield takeLatest(
    PULSE_FETCH_UNCATEGORIZED_INVOICES,
    fetchUncategorizedInvoicesWithAccessToken
  );
}

export function* watchCategorizeInvoice() {
  yield takeLatest(PULSE_CATEGORIZE_INVOICE, setCategoryInvoice);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchUncategorizedInvoices),
    fork(watchCategorizeInvoice),
  ]);
}
