export const getUserDataForOpenReplay = ({ user, taxable_entity }) => {
  const obj = {};
  if (taxable_entity) {
    obj.hasCiec = taxable_entity.has_ciec ? 'Si' : 'No';
    obj.taxableEntityId = taxable_entity.id;
    obj.legalName = taxable_entity.legal_name || 'Sin razón social';
    obj.rfc = taxable_entity.rfc;
  }
  if (user) {
    obj.email = user.email;
    obj.phone = user.phone_number;
    obj.displayName = `${user.first_name} ${taxable_entity?.id || user.id}`;
  }
  return obj;
};

export const setUserDataToOpenReplay = (userData = {}) => {
  // eslint-disable-next-line no-underscore-dangle
  const OpenReplay = window?.__OPENREPLAY__;
  if (OpenReplay) {
    OpenReplay.setUserID(userData.user.id.toString());
    const metaData = getUserDataForOpenReplay(userData);
    Object.entries(metaData).forEach(([key, value]) => {
      OpenReplay.setMetadata(key, value?.toString() || 'Sin valor');
    });
  }
};
