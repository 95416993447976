import { useEffect } from 'react';
import { CustomerFromComponent } from 'backoffice/store/types';
import useStore from 'backoffice/store';
import { Outlet } from 'react-router-dom';
import CustomerTabs from './CustomerTabs';

export default function useFromComponent(fromComponent: CustomerFromComponent) {
  const setFromComponent = useStore((state) => state.setFromComponent);

  useEffect(() => {
    if (fromComponent) {
      setFromComponent(fromComponent);
    }
  }, [fromComponent, setFromComponent]);

  return fromComponent === 'declarations' ? Outlet : CustomerTabs;
}
