import { Row } from 'reactstrap';
import { Flex } from '@konta/ui';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowsLayout from '@components/WorkflowsLayout';
import Divider from '@components/Divider';
import NextPhaseConfirmation from '@components/NextPhaseConfirmation';
import PrevPhaseButton from '@components/PrevPhaseButton';
import { Colxx } from '@components/CustomBootstrap';
import { PaymentCards } from '@containers';
import toCurrency from '@util/toCurrency';
import useTaxesPayment from './useTaxesPayment';
import { Feature, Header, Root, Title } from '../styled';

export default function TaxesPayment() {
  const {
    hasAnyExpired,
    grandTotalToPay,
    isLoading,
    handlePrevPhase,
    payload,
  } = useTaxesPayment();

  return (
    <WorkflowsLayout
      isLoading={isLoading}
      actions={
        <>
          <PrevPhaseButton />
          {!hasAnyExpired ? (
            <NextPhaseConfirmation
              // eslint-disable-next-line react/no-unstable-nested-components
              trigger={({ onClick }) => (
                <PrimaryPhaseButton onClick={onClick}>Pagar</PrimaryPhaseButton>
              )}
              payload={payload}
              title="Confirmación de pago"
              acceptText="Confirmar"
            >
              <div className="m-auto d-flex flex-column justify-content-center text-center">
                <i className="large-icon iconsmind-Danger" color="danger" />
                <p>
                  Al dar click en &quot;Confirmar&quot; pagarás tus impuestos.
                </p>
              </div>
            </NextPhaseConfirmation>
          ) : (
            <PrimaryPhaseButton onClick={handlePrevPhase}>
              Solicitar otra línea de captura
            </PrimaryPhaseButton>
          )}
        </>
      }
      title="Declaración mensual"
    >
      <Root>
        <Header>
          <Title>Pagar impuestos</Title>
          {hasAnyExpired ? (
            <Feature>
              ¡Su línea de captura está vencida! Para generar una nueva y volver
              calcular sus impuestos con sus atrasos, debe solicitar una nueva
              línea de captura.
            </Feature>
          ) : (
            <Feature>Selecciona una tarjeta y paga tus impuestos</Feature>
          )}
        </Header>
        <Flex column gap={12}>
          <PaymentCards filter />
          <Divider />
          <Row>
            <Colxx
              xxs={{ size: 8, offset: 1 }}
              style={{ fontSize: '1rem' }}
              className="font-weight-bold mb-4"
            >
              <div>Total</div>
            </Colxx>
            <Colxx xxs={{ size: 3 }}>{toCurrency(grandTotalToPay)}</Colxx>
          </Row>
        </Flex>
      </Root>
    </WorkflowsLayout>
  );
}
