import type { ComponentProps, ReactNode } from 'react';
import { HelpCircleLineIcon } from '@konta/icons';
import { HoverCard, Flex, Text } from '@konta/ui';
import Icon from 'shared/components/Icon';
import _ from 'lodash';

export interface TooltipIconProps {
  children?: ReactNode;
  title?: string;
  iconProps?: ComponentProps<typeof Icon>;
  tooltipProps?: ComponentProps<typeof HoverCard>;
}

export default function TooltipIcon({
  children,
  iconProps,
  tooltipProps,
  title,
}: TooltipIconProps) {
  return (
    <HoverCard
      side="top"
      openDelay={0}
      css={{
        padding: '12px',
      }}
      {...tooltipProps}
      triggerElement={
        <Icon {...iconProps} tabIndex={-1} role="button">
          <HelpCircleLineIcon color="#344054" />
        </Icon>
      }
    >
      <Flex column gap={4}>
        {title && (
          <Text medium xs color="gray700">
            {title}
          </Text>
        )}
        {_.isString(children) ? (
          <Text xs color={title ? 'gray500' : 'gray700'}>
            {children}
          </Text>
        ) : (
          children
        )}
      </Flex>
    </HoverCard>
  );
}
