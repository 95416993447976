import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '@redux/actions';
import withAuth from '@hocs/withAuth';
import Newinvoice from '@routes/app/NewInvoice';
import ProcessesHome from '@components/ProcessesHome';
import UserLayout from '@layouts/UserLayout';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import useFeature from '@hooks/useFeature';
import { REPORTS_PAGE, TICKETS_CHAT } from '@constants/featureFlags';
import OnboardingRoutes from '@components/Onboarding';
import AnnualRoutes from '@components/Annual';
import WorkflowRoutes from '@components/Workflow';
import RedirectNewInvoice from '@components/NewInvoiceModal/RedirectNewInvoice';
import CommandMenu from '@components/CommandMenu';
import Reports from './reports';
import Documents from './documents';
import Settings from './settings';
import Invoices from './invoicing';
import Checkout from './checkout/checkout';
import Flows from './flows';
import Workflows from './workflows';
import Referrals from './Referrals';
import AdvisoryChannels from './AdvisoryChannels';
import InformationDashboard from './InformationDashboard';
import NewReports from './NewReports';
import PendingRoutes from './PendingRoutes';
import Chat from './Chat';

const featureFlagConfig = {
  source: 'appRoutes',
};

function AppRoutes() {
  const [reportsPageEnabled] = useFeature(REPORTS_PAGE, featureFlagConfig);
  const [ticketsChatEnabled] = useFeature(TICKETS_CHAT, featureFlagConfig);
  const access_token = useSelector(({ authUser }) => authUser.access_token);
  const user = useSelector(({ authUser }) => authUser.user);
  const taxable_entity = useSelector(
    ({ taxableEntity }) => taxableEntity.taxable_entity,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const featureClient = useLDClient();

  useEffect(() => {
    if (!user && access_token) {
      dispatch(fetchUser(navigate, featureClient));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRejected = taxable_entity?.onboarding_detail?.is_rejected_user;

  if (user && !user.phone_number) {
    return <Navigate to="/onboarding" />;
  }

  if (!user) {
    return <div className="loading" />;
  }

  return (
    <UserLayout>
      <CommandMenu />
      <Routes>
        {isRejected && <Route path="/reports/*" element={<Reports />} />}
        <Route path="/processes" element={<ProcessesHome />} />
        <Route path="/processes/onboarding/*" element={<OnboardingRoutes />} />
        <Route
          path="/processes/annual/:processId/*"
          element={<AnnualRoutes />}
        />
        <Route path="/workflow/:workflowId/*" element={<WorkflowRoutes />} />
        <Route path="/documents/*" element={<Documents />} />
        {ticketsChatEnabled && <Route path="/chat/*" element={<Chat />} />}
        <Route path="/pendientes/*" element={<PendingRoutes />} />
        <Route path="/declaraciones/*" element={<Workflows />} />
        {!isRejected && <Route path="/flow/:id/*" element={<Flows />} />}
        <Route path="/settings/*" element={<Settings />} />
        <Route path="/invoicing/*" element={<Invoices />} />
        <Route path="/legacy-invoice/*" element={<Newinvoice />} />
        <Route path="/newinvoice/*" element={<RedirectNewInvoice />} />
        <Route path="/checkout/*" element={<Checkout />} />
        <Route
          path="/referrals/*"
          element={<Referrals withBackground withSuggested withInvitations />}
        />
        <Route path="/advisory-channels" element={<AdvisoryChannels />} />
        <Route
          path="/information-dashboard"
          element={<InformationDashboard />}
        />
        {reportsPageEnabled && (
          <Route path="/new-reports" element={<NewReports />} />
        )}
        {/* TODO: remove redirect and add a "404" page */}
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
    </UserLayout>
  );
}

export default withAuth(AppRoutes);
