import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { getComplementsSuccess, getComplementsFailure } from '../actions';

export default function* ({ payload }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(api.get, `cfdis/${payload}/payment_details`, {
      headers: { Authorization: `${access_token}` },
    });
    yield call(StatusHandler, {
      status: response.status,
      response,
      success: getComplementsSuccess,
      failure: getComplementsFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: getComplementsFailure,
    });
  }
}
