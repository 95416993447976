import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import classnames from 'classnames';

import TablePagination from './TablePagination';

// filterable = false,
// showPageJump = false,
// showPageSizeOptions = true,
// className = "",
// loadingText = "Cargando...",
// getTrProps

function Table({
  columns,
  data,
  disablePagination,
  loading,
  noDataText = '',
  divided = false,
  defaultPageSize = 10,
}) {
  const memoColumns = React.useMemo(() => columns, [columns]);
  const memoData = React.useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: memoColumns,
      data: memoData,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    useSortBy,
    usePagination,
  );

  return (
    <>
      <table
        {...getTableProps()}
        className={`r-table table ${classnames({ 'table-divided': divided })}`}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sorted-desc'
                        : 'sorted-asc'
                      : ''
                  }
                >
                  {column.render('Header')}
                  <span />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.length > 0 && !loading ? (
            page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      key={`td_${cellIndex}`}
                      {...cell.getCellProps({
                        className: cell.column.cellClass,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <>
              {[...Array(pageSize)].map((row, rowIndex) => {
                return (
                  <tr role="row" key={`row-${rowIndex}`}>
                    {[...Array(columns.length)].map((row, cellIndex) => {
                      return (
                        <td role="cell" key={`cell-${cellIndex}`}>
                          <div className="list-item-heading"> </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
      {page.length === 0 && !loading && (
        <div className="rt-noData">{noDataText}</div>
      )}
      <div className={`rt-loading ${loading ? 'rt-active' : ''}`}>
        <div className="rt-loading-inner">Cargando...</div>
      </div>

      {!disablePagination && (
        <TablePagination
          page={pageIndex}
          pages={pageCount}
          canPrevious={canPreviousPage}
          canNext={canNextPage}
          pageSizeOptions={[4, 10, 20, 30, 40, 50]}
          showPageSizeOptions={true}
          showPageJump={false}
          defaultPageSize={pageSize}
          onPageChange={(p) => gotoPage(p)}
          onPageSizeChange={(s) => setPageSize(s)}
          paginationMaxSize={pageCount}
        />
      )}
    </>
  );
}

export default Table;
