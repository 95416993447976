import { styled } from '@konta/ui';

export const CardContainer = styled('div', {
  display: 'flex',
  width: '100%',
  padding: '$16 0',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: '$16',
  borderRadius: '$m',
});

export const CardDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: '$m',
  border: '1px solid $gray200',
  height: '100%',
  width: '100%',
});

export const CardDetailContent = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '$24',
  alignSelf: 'stretch',
  pb: '$16',
  '@<lg': {
    flexDirection: 'column',
  },
});

export const ResumeContainer = styled('div', {
  display: 'flex',
  width: '458px',
  padding: '20px 16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '@<lg': {
    width: '100%',
  },
});

export const ChartsContainer = styled('div', {
  display: 'flex',
  width: '100%',
  height: '344px',
  padding: '32px 16px 0px 16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flex: '1 0 0',
});

export const ResumeHeading = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  pb: '$8',
  justifyContent: 'center',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
});
export const CardFooter = styled('div', {
  display: 'flex',
  padding: '$16 $24',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$4',
  alignSelf: 'stretch',
  borderTop: '1px solid $gray200',
  background: '$gray25',
  borderRadius: '0 0 8px 8px',
});
export const DownloadText = styled('span', {
  color: '$gray700',
  fontFamily: '$inter',
  fontSize: '$12',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '$18',
});
