import toCurrency from '@util/toCurrency';
import { CalculateDeclarationsSummary } from './calculateDeclarationsSummary';

export default function generatePayrollTaxesRow(
  declarationSummary: CalculateDeclarationsSummary,
) {
  return declarationSummary.declarationSummary.map((declaration, index) => {
    const payrollRows = [
      {
        key: index.toString(),
        label: `Retenciones de nómina`,
        amount: `${toCurrency(+declaration.taxes.taxesWithheldOfPayroll)} MXN`,
      },
      {
        key: index.toString(),
        label: `Subsidios de empleo`,
        amount: `${toCurrency(
          +declaration.taxes.employmentSubsidyOfPayroll,
        )} MXN`,
      },
      {
        key: index.toString(),
        label: `Total a pagar de sueldos y salarios`,
        amount: `${toCurrency(
          +declaration.taxes.totalPayrollWithholdingsToPay,
        )} MXN`,
        isBold: true,
      },
    ];

    return payrollRows;
  });
}
