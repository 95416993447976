export const helpCenterUrl = 'https://konta.zendesk.com/hc/es-419';
export const helpCenterArticleUrl = `${helpCenterUrl}/articles/`;
export const articlesUrl = {
  what_is_ciec: `${helpCenterArticleUrl}18164384894235--C%C3%B3mo-genero-mi-CIEC-`,
  what_is_csd: `${helpCenterArticleUrl}18164361431067--C%C3%B3mo-puedo-tramitar-mi-Certificado-del-sello-digital-CSD-`,
  what_is_fiel: `${helpCenterArticleUrl}18164369663003--C%C3%B3mo-genero-mi-FIEL-`,
  public_invoice: `${helpCenterArticleUrl}18164371096859-Facturaci%C3%B3n-al-p%C3%BAblico-en-general`,
  how_isr_is_calculated: `${helpCenterArticleUrl}18164385381147--C%C3%B3mo-se-calcula-el-ISR-`,
  payment_scheme_platforms: `${helpCenterArticleUrl}18164369869723--Cu%C3%A1les-son-los-esquemas-de-pago-en-plataformas-tecnol%C3%B3gicas-`,
  technology_platforms: `${helpCenterArticleUrl}18164391650459-Sobre-el-R%C3%A9gimen-de-Actividad-Empresarial-en-Plataformas-Tecnol%C3%B3gicas`,
  taxable_activities_at_rate_0_and_8: `${helpCenterArticleUrl}18164356216603-Actividades-gravadas-a-la-tasa-0-y-8-de-IVA`,
  what_are_the_tax_withholding_taxes: `${helpCenterArticleUrl}18164385261851--Qu%C3%A9-son-las-retenciones-de-impuestos-`,
};
export type HelpCenterArticlesId = keyof typeof articlesUrl;
