import React from 'react';
import { AddCardButton, PaymentTabs, FiscalPeriodInvoice } from '@components';
import { getMonthName, sumAll } from '@util/Utils';
import { useFiscalPeriods } from '@hooks';

function TaskPayment({ currentPeriod }) {
  const { loading, handleFpPay } = useFiscalPeriods();
  const mm = getMonthName(new Date(currentPeriod.start_date));
  const { id, taxable_entity_id, ...rest } = currentPeriod;
  return (
    <PaymentTabs
      displayFiscalPeriodInvoice
      defaultActiveTab={sumAll(...Object.values(rest)) < 5000 ? '1' : '2'}
      cardTitle={`Paga tus impuestos de ${mm}`}
      tabs={['task.payment-methods-card', 'task.payment-methods-pipeline']}
      floading={loading}
      payButton
      handlePayment={() => handleFpPay(currentPeriod)}
      tabChangeButton
      filter
      selectedFiscalPeriod={currentPeriod}
    >
      <AddCardButton />
      <FiscalPeriodInvoice fiscal_period={currentPeriod} />
    </PaymentTabs>
  );
}

export default TaskPayment;
