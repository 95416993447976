import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { getYearWithMonths } from '@util';
import { MonthDatePickerControls } from './MonthDatePickerControls';
import { MonthDatePickerMonthButton } from './MonthDatePickerMonthButton';

function MonthDatePicker({
  dataWithDatesToMatch,
  setSelectedMonth,
  selectedMonth,
}) {
  const [, setSearch] = useSearchParams();
  const [monthlyList, setMonthlyList] = useState(null);

  useEffect(() => {
    const { year, months } = getYearWithMonths(selectedMonth.value)[0];
    setMonthlyList({
      year,
      months: months.reverse(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  useEffect(() => {
    const selectedDate = selectedMonth.value.split('-');
    setSearch({
      month: selectedDate[1],
      year: selectedDate[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  const monthsWithWorkflows =
    monthlyList?.months.map((item) => {
      return {
        ...dataWithDatesToMatch.find(
          (objWithStartDate) =>
            objWithStartDate.start_date === item.value && item,
        ),
        ...item,
      };
    }) || [];

  const handlePreviousYear = () => {
    const lastYear = dayjs(`${monthlyList?.year}-01-01`)
      .subtract(1, 'year')
      .format('YYYY-MM-DD');
    const { year, months } = getYearWithMonths(lastYear)[0];
    setMonthlyList({
      year,
      months: months.reverse(),
    });
    setSelectedMonth({
      value: dayjs(`${monthlyList?.year}-${selectedMonth.number}-01`)
        .subtract(1, 'year')
        .format('YYYY-MM-DD'),
      number: dayjs(`${monthlyList?.year}-${selectedMonth.number}-01`)
        .subtract(1, 'year')
        .format('MM'),
    });
  };

  const handleNextYear = () => {
    const nextYear = dayjs(`${monthlyList?.year}-01-01`)
      .add(1, 'year')
      .format('YYYY-MM-DD');
    const { year, months } = getYearWithMonths(nextYear)[0];
    setMonthlyList({
      year,
      months: months.reverse(),
    });

    setSelectedMonth({
      value: dayjs(`${monthlyList?.year}-${selectedMonth.number}-01`)
        .add(1, 'year')
        .format('YYYY-MM-DD'),
      number: dayjs(`${monthlyList?.year}-${selectedMonth.number}-01`)
        .add(1, 'year')
        .format('MM'),
    });
  };

  const handleCurrentYear = () => {
    const { year, months } = getYearWithMonths()[0];
    setMonthlyList({
      year,
      months: months.reverse(),
    });
    setSelectedMonth({
      value: dayjs().startOf('month').format('YYYY-MM-DD'),
      number: dayjs().startOf('month').format('MM'),
    });
  };

  const handleSelectMonth = async (month) => {
    setSelectedMonth(month);
  };

  return (
    <div className="datepicker-container">
      <MonthDatePickerControls
        handleCurrentYear={handleCurrentYear}
        handlePreviousYear={handlePreviousYear}
        handleNextYear={handleNextYear}
        monthlyList={monthlyList}
      />
      <div className="datepicker-months">
        {monthsWithWorkflows?.map((monthWithWorkflow, index) => {
          const isSelected = monthWithWorkflow.value === selectedMonth.value;
          return (
            <MonthDatePickerMonthButton
              key={`month-picker-${index}`}
              indexId={index}
              isSelected={isSelected}
              monthWithWorkflow={monthWithWorkflow}
              handleSelectMonth={handleSelectMonth}
            />
          );
        })}
      </div>
    </div>
  );
}

export default MonthDatePicker;
