import { useCallback } from 'react';
import type { Row } from '@tanstack/react-table';
import type { GroupedEntries } from 'types/entities';
import useEntriesGroupedColumns from './useEntriesGroupedColumns';
import getModalInvoiceData from '../util/getModalInvoiceData';

interface UseEntriesGroupedTable {
  type: 'incomes' | 'expenses';
  setModalInvoiceDetails: (value: {
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  }) => void;
}

export default function useEntriesGroupedTable({
  type,
  setModalInvoiceDetails,
}: UseEntriesGroupedTable) {
  const groupedDeclarationEntriesColumns = useEntriesGroupedColumns({
    type,
  });

  const openCfdisModal = useCallback(
    (row: Row<GroupedEntries>) => {
      const isIncome = type === 'incomes';
      const data = row.original;
      const { rfc, issuer_legal_name: legalName } = data;
      const { hasCfdiModal, modalInvoiceDetails } = getModalInvoiceData(
        rfc,
        legalName,
        isIncome,
      );
      if (!hasCfdiModal) return;
      setModalInvoiceDetails(modalInvoiceDetails);
    },
    [setModalInvoiceDetails, type],
  );

  return {
    groupedDeclarationEntriesColumns,
    openCfdisModal,
  };
}
