import { Flex } from '@konta/ui';
import useCurrentProcessesQuery from '@hooks/useCurrentProcessesQuery';
import UnfinishedOnboarding from '@components/Onboarding/UnfinishedOnboarding';
import UnfinishedAnnual from '@components/Annual/UnfinishedAnnual';
import UnfinishedFinishOnboarding from '@components/Onboarding/UnfinishedFinishOnboarding';

const UnfinishedComponents = {
  OnboardingOnboardingProcess: UnfinishedOnboarding,
  FinishOnboardingProcess: UnfinishedFinishOnboarding,
  AnnualDeclarationProcess: UnfinishedAnnual,
};

export default function UnfinishedProcesses() {
  const { currentProcesses, hasProcesses, completedProcesses } =
    useCurrentProcessesQuery();
  const showUnfinishedProcesses = hasProcesses && !completedProcesses;

  if (!showUnfinishedProcesses) {
    return null;
  }

  const sortedProcesses = currentProcesses
    .filter((process) => !process.completed)
    .sort((a, b) => a.id - b.id);

  return (
    <Flex direction="column" gap={10}>
      {sortedProcesses.map((process) => {
        const processType = process.type;
        const UnfinishedComponent = UnfinishedComponents[processType];
        if (!UnfinishedComponent) {
          return null;
        }
        return (
          <Flex key={process.type} direction="column" gap={8}>
            <UnfinishedComponent process={process} />
          </Flex>
        );
      })}
    </Flex>
  );
}
