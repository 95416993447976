import {
  CHAT_GET_CONTACTS_BEGIN,
  CHAT_GET_CONTACTS_END,
  CHAT_GET_CONTACTS_SUCCESS,
  CHAT_GET_CONTACTS_ERROR,
  CHAT_GET_CONVERSATIONS_BEGIN,
  CHAT_GET_CONVERSATIONS_SUCCESS,
  CHAT_GET_CONVERSATIONS_ERROR,
  CHAT_GET_CONVERSATIONS_END,
  CHAT_ADD_MESSAGE_TO_CONVERSATION_BEGIN,
  CHAT_ADD_MESSAGE_TO_CONVERSATION_FAILURE,
  CHAT_ADD_MESSAGE_TO_CONVERSATION_END,
  CHAT_CREATE_CONVERSATION,
  CHAT_SEARCH_CONTACT,
  CHAT_CHANGE_CONVERSATION,
  CHAT_LOAD_NEXT_MESSAGES_BEGIN,
  CHAT_LOAD_NEXT_MESSAGES_SUCCESS,
  CHAT_LOAD_NEXT_MESSAGES_FAILURE,
  CHAT_LOAD_NEXT_MESSAGES_END,
} from '@constants/actionTypes';

const INIT_STATE = {
  allContacts: null,
  contacts: null,
  conversations: null,
  searchKeyword: '',
  loadingContacts: false,
  loadingConversations: false,
  currentUser: null,
  currentUserID: 'LTI2cgzI9cAts9Ziz831',
  selectedUser: null,
  selectedUserId: null,
  contactsSuccess: false,
  contactsError: false,
  conversationsError: false,
  conversationsSuccess: false,
  addingMessage: false,
  addingMessageError: false,
  loadingNext: false,
  errorLoadingNext: false,
  msgsLength: 0,
  lastRefreshed: null,
  loadedNext: false,
  prevMsgLength: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHAT_GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loadingContacts: false,
        allContacts: action.payload.contacts,
        contacts: action.payload.contacts,
        contactsSuccess: true,
        currentUser: action.payload.contacts.users[0],
        selectedUser: action.payload.contacts.users[1],
      };

    case CHAT_GET_CONTACTS_ERROR:
      return {
        ...state,
        loadingContacts: false,
        contactsError: true,
        contactsSuccess: false,
      };

    case CHAT_GET_CONTACTS_END:
      return {
        ...state,
        loadingContacts: false,
        contactsError: false,
        contactsSuccess: true,
      };

    case CHAT_GET_CONTACTS_BEGIN:
      return { ...state, loadingContacts: true };

    case CHAT_GET_CONVERSATIONS_BEGIN:
      return { ...state, loadingConversations: true };

    case CHAT_GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loadingConversations: false,
        conversationsSuccess: true,
        conversations: action.payload.conversations,
        msgsLength: action.payload.conversations.msgs.length,
      };

    case CHAT_GET_CONVERSATIONS_ERROR:
      return {
        ...state,
        loadingConversations: false,
        conversationsError: true,
      };

    case CHAT_GET_CONVERSATIONS_END:
      return {
        ...state,
        loadingConversations: false,
        conversationsError: false,
        conversationsSuccess: false,
      };

    case CHAT_CHANGE_CONVERSATION:
      return {
        ...state,
        selectedUser: state.allContacts.find((x) => x.id === action.payload),
      };

    case CHAT_ADD_MESSAGE_TO_CONVERSATION_BEGIN:
      return {
        ...state,
        addingMessage: true,
        loadedNext: false,
      };

    case CHAT_ADD_MESSAGE_TO_CONVERSATION_FAILURE:
      return { ...state, addingMessage: false, addingMessageError: true };

    case CHAT_ADD_MESSAGE_TO_CONVERSATION_END:
      return { ...state, addingMessage: false, addingMessageError: false };

    case CHAT_LOAD_NEXT_MESSAGES_BEGIN:
      return { ...state, loadingNext: true };

    case CHAT_LOAD_NEXT_MESSAGES_FAILURE:
      return { ...state, loadingNext: false, errorLoadingNext: true };

    case CHAT_LOAD_NEXT_MESSAGES_END:
      return {
        ...state,
        loadingNext: false,
        errorLoadingNext: false,
        loadedNext: false,
      };

    case CHAT_LOAD_NEXT_MESSAGES_SUCCESS: {
      const previous = [...state.conversations.msgs];
      const next10 = [...action.payload.next10.msgs];
      const allMsgs = [...previous, ...next10].sort((a, b) => {
        if (a.data.timestamp > b.data.timestamp) return 1;
        return -1;
      });

      return {
        ...state,
        loadingNext: false,
        lastRefreshed: action.payload.lastRefreshed,
        conversations: {
          msgs: [...allMsgs],
        },
        loadedNext: true,
        prevMsgLength: previous.length,
      };
    }

    case CHAT_CREATE_CONVERSATION:
      return { ...state };

    case CHAT_SEARCH_CONTACT:
      if (action.payload === '') {
        return { ...state, contacts: state.allContacts };
      }
      const keyword = action.payload.toLowerCase();
      const searchedContacts = state.allContacts.filter(
        (item) => item.name.toLowerCase().indexOf(keyword) > -1
      );
      return { ...state, contacts: searchedContacts };

    default:
      return { ...state };
  }
};
