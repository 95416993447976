import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import PrevPhaseButton from '@components/PrevPhaseButton';
import WorkflowLayout from '@components/WorkflowLayout';
import WorkflowHeader from '@components/WorkflowHeader';
import useNextPhase from '@hooks/useNextPhase';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import FormikOptionsSelect from '@components/FormikOptionsSelect';
import { CardOption } from '@konta/ui';
import { Collapse, Row } from 'reactstrap';
import { Colxx, NotificationManager } from '@components';
import FormikTextField from '@components/FormikTextField';
import FormikSelect from '@components/FormikSelect';
import usePutDeclaration from 'shared/hooks/usePutDeclaration';
import style from './HasExtraIncome.module.scss';

const IVA_OPTIONS = [
  {
    label: 'IVA 16%',
    value: 0.16,
    key: 1,
    name: 'iva_rate_of_bank_transactions',
  },
  {
    label: 'IVA 8%',
    value: 0.08,
    key: 2,
    name: 'iva_rate_of_bank_transactions',
  },
];

const validationSchema = yup.object().shape({
  totalOfExtras: yup.number().min(0, 'No puede ser negativo'),
  extraTotalZeroIva: yup.number().min(0, 'No puede ser negativo'),
  extraExemptBases: yup.number().min(0, 'No puede ser negativo'),
});

function HasExtraIncome() {
  const nextPhase = useNextPhase();
  const { workflow } = useSelectedWorkflow();

  const { iva_rate, declaration_income, id } =
    workflow?.active_declaration ?? {};
  const putDeclaration = usePutDeclaration(id);

  const defaultWithExtra =
    declaration_income.total_of_extras > 0 ||
    declaration_income.extra_zero_bases > 0;
  const [hasExtraIncomes, setHasExtraIncomes] = useState([defaultWithExtra]);

  const onSubmit = async (values) => {
    const { totalOfExtras, iva, extraTotalZeroIva, extraExemptBases } = values;
    let payload = {
      declaration: {
        declaration_income_attributes: {
          extra_zero_bases: 0,
          total_of_extras: 0,
          total8_of_extras: 0,
          extra_exempt_bases: 0,
        },
      },
    };

    if (hasExtraIncomes[0]) {
      payload = {
        declaration: {
          declaration_income_attributes: {
            extra_zero_bases: extraTotalZeroIva || 0,
            extra_iva_bases: totalOfExtras || 0,
            total_of_extras: iva.value === 0.16 ? totalOfExtras : 0,
            total8_of_extras: iva.value === 0.08 ? totalOfExtras : 0,
            extra_exempt_bases: extraExemptBases || 0,
          },
        },
      };
    }

    try {
      await putDeclaration.mutateAsync(payload);
      nextPhase({
        workflow: {
          next_phase: 'primary_phase',
        },
      });
    } catch (error) {
      NotificationManager.error(
        'No se pudo actualizar la declaración',
        'Error al actualizar',
      );
    }
  };

  const initialTotalOfExtras =
    declaration_income?.total_of_extras > 0 ||
    declaration_income?.total8_of_extras > 0
      ? declaration_income?.total_of_extras ||
        declaration_income?.total8_of_extras
      : '';

  const initialIva = IVA_OPTIONS.find(
    (option) => option.value === +declaration_income.extra_iva_rate,
  );

  const initialValues = {
    totalOfExtras: initialTotalOfExtras,
    iva: initialIva,
    extraTotalZeroIva:
      declaration_income?.extra_zero_bases > 0
        ? declaration_income?.extra_zero_bases
        : '',
    extraExemptBases:
      declaration_income?.extra_exempt_bases > 0
        ? declaration_income?.extra_exempt_bases
        : '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (iva_rate) {
      formik.setFieldValue('iva', {
        label: `IVA ${iva_rate * 100}%`,
        value: Number(iva_rate),
        key: 3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iva_rate]);

  return (
    <WorkflowLayout
      header={<WorkflowHeader title="Ingresos Extra" videoId="3fdqd8aCrxE" />}
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton
            onClick={formik.submitForm}
            loading={putDeclaration.isLoading}
          />
        </>
      }
    >
      <h2>
        Si tuviste ingresos extra este mes, en la sección de abajo puedes
        agregar los totales.
      </h2>
      <FormikProvider value={formik}>
        <div className={style.options}>
          <FormikOptionsSelect
            name="hasIncome"
            noEmpty
            setHasExtraIncomes={setHasExtraIncomes}
            hasExtraIncomes={hasExtraIncomes}
          >
            <CardOption
              value={false}
              header={
                <span data-testid="has-no-income-option">
                  No obtuve otros ingresos
                </span>
              }
            />
            <CardOption
              value
              header={
                <span data-testid="has-income-option">
                  Si obtuve ingresos extra
                </span>
              }
            />
          </FormikOptionsSelect>
        </div>

        <Collapse isOpen={hasExtraIncomes[0]}>
          <Row>
            <Colxx xxs={12} md={6}>
              <FormikTextField
                name="totalOfExtras"
                data-testid="totalOfExtras"
                type="number"
                label="Monto de ingresos con IVA"
                placeholder="Ej. 200"
              />
            </Colxx>
            <Colxx xxs={12} md={6}>
              <FormikSelect
                options={IVA_OPTIONS}
                name="iva"
                label="IVA"
                data-testid="iva"
              />
            </Colxx>
            <Colxx xxs={12} md={6}>
              <FormikTextField
                name="extraTotalZeroIva"
                data-testid="extraTotalZeroIva"
                type="number"
                label="Monto de ingresos extra con el 0% de IVA"
                placeholder="Ej. 200"
              />
            </Colxx>
            <Colxx xxs={12} md={6}>
              <FormikTextField
                name="extraExemptBases"
                data-testid="extraExemptBases"
                type="number"
                label="Monto de ingresos extra con IVA exento"
                placeholder="Ej. 200"
              />
            </Colxx>
          </Row>
        </Collapse>
      </FormikProvider>
    </WorkflowLayout>
  );
}

export default HasExtraIncome;
