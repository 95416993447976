import React from 'react';
import { Text } from '@konta/ui';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import SecondaryPhaseButton from '@components/SecondaryPhaseButton';
import HelpCenterLink from '@components/HelpCenterLink';

export default function QuForeignExpencesResico() {
  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Dinos si tuviste gastos en el extranjero"
          description="Las empresas en el extranjero no emiten facturas mexicanas (CFDIs). Para poder deducir la compra de productos en el extranjero es necesario contar con el pedimento de importación.
      En el RESICO no es posible deducir los gastos por servicios en el extranjero “Invoice” debido a que estos no llevan IVA."
          videoId="5cfz5YEukmE"
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton>Si compré productos</PrimaryPhaseButton>
          <SecondaryPhaseButton variant="contained">
            No (continuar)
          </SecondaryPhaseButton>
        </>
      }
    >
      <Text x2l>
        ¿Compraste este mes productos en el extranjero y tienes el pedimento de
        importación?
      </Text>
      <div>
        <p className="text-muted mb-0">
          Ejemplo: Compré productos en Alibaba y mi agente aduanal me mandó el{' '}
          <strong>&quot;pedimento&quot;</strong>.
        </p>
        <div className="d-flex">
          <p className="text-muted">Consulta nuestros artículos para obtener</p>
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </div>
      </div>
    </WorkflowLayout>
  );
}
