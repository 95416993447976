import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { EyeLineIcon, EyeOutlineIcon } from '@konta/icons';
import { Button, Text, ModalContent, config, useMediaQuery } from '@konta/ui';
import { useToggle } from 'rooks';
// eslint-disable-next-line import/no-cycle
import PdfViewer from './pdf-viewer';

function PDFModal({
  url,
  title,
  id,
  status,
  togglePdf,
  onToggle,
  uuid,
  invoice,
  withShareButtons = true,
  withActions = true,
  onlyIcon = false,
}) {
  const [open, toggleOpen] = useToggle(false);
  const isTabletOrMobile = useMediaQuery(config.media['<xl']);

  const toggle = () => {
    toggleOpen();
    if (onToggle) onToggle();
  };
  return (
    <>
      {onlyIcon && !onToggle && (
        <Button size="xs" variant="outlined" icon noFill onClick={toggle}>
          <EyeLineIcon color="#667085" />
        </Button>
      )}
      {!onToggle && !onlyIcon && (
        <Button
          size="2xs"
          color="gray"
          onClick={toggle}
          variant="outlined"
          leftIcon={<EyeOutlineIcon />}
        >
          <Text>{isTabletOrMobile ? 'Visualizar' : 'Ver Factura'}</Text>
        </Button>
      )}
      <Modal
        isOpen={!onToggle ? open : togglePdf}
        toggle={toggle}
        size="lg"
        width="auto"
      >
        <ModalHeader toggle={toggle}> {title} </ModalHeader>
        <ModalContent css={{ minHeight: '700px', display: 'flex' }}>
          <PdfViewer
            url={url}
            id={id}
            uuid={uuid}
            status={status}
            togglePreview={toggle}
            invoice={invoice}
            withShareButtons={withShareButtons}
            withActions={withActions}
          />
        </ModalContent>
      </Modal>
    </>
  );
}

export default PDFModal;
