import {
  FETCH_FISCAL_PERIOD,
  FETCH_FISCAL_PERIOD_SUCCESS,
  FETCH_FISCAL_PERIOD_FAILURE,
  PAY_FISCAL_PERIOD,
  PAY_FISCAL_PERIOD_SUCCESS,
  PAY_FISCAL_PERIOD_FAILURE,
  UPLOAD_FISCAL_PERIOD_STATEMENT,
  UPLOAD_FISCAL_PERIOD_STATEMENT_SUCCESS,
  UPLOAD_FISCAL_PERIOD_STATEMENT_FAILURE,
  FETCH_CURRENT_FISCAL_PERIOD,
  FETCH_CURRENT_FISCAL_PERIOD_SUCCESS,
  FETCH_CURRENT_FISCAL_PERIOD_FAILURE,
  UPDATE_STATUS_FISCAL_PERIOD,
  UPDATE_STATUS_FISCAL_PERIOD_SUCCESS,
  UPDATE_STATUS_FISCAL_PERIOD_FAILURE,
  SELECT_FISCAL_PERIOD,
  UPLOAD_FISCAL_PERIOD_RECEIPT,
  UPLOAD_FISCAL_PERIOD_RECEIPT_SUCCESS,
  UPLOAD_FISCAL_PERIOD_RECEIPT_FAILURE,
  POST_FEEDBACK,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAILURE,
} from '@constants/actionTypes';

export const fetchFiscalPeriod = (year) => ({
  type: FETCH_FISCAL_PERIOD,
  payload: { year },
});
export const fetchFiscalPeriodSuccess = (fiscal_periods) => ({
  type: FETCH_FISCAL_PERIOD_SUCCESS,
  payload: fiscal_periods,
});
export const fetchFiscalPeriodFailure = (_) => ({
  type: FETCH_FISCAL_PERIOD_FAILURE,
});

export const fetchCurrentFiscalPeriod = (taxable_entity) => ({
  type: FETCH_CURRENT_FISCAL_PERIOD,
  payload: { taxable_entity },
});

export const fetchCurrentFiscalPeriodSuccess = (fiscal_period) => ({
  type: FETCH_CURRENT_FISCAL_PERIOD_SUCCESS,
  payload: fiscal_period,
});

export const fetchCurrentFiscalPeriodFailure = (_) => ({
  type: FETCH_CURRENT_FISCAL_PERIOD_FAILURE,
});

export const payFiscalPeriod = (fiscal_period) => ({
  type: PAY_FISCAL_PERIOD,
  payload: { fiscal_period },
});

export const payFiscalPeriodSuccess = (fiscal_period) => ({
  type: PAY_FISCAL_PERIOD_SUCCESS,
  payload: fiscal_period,
});

export const payFiscalPeriodFailure = (_) => ({
  type: PAY_FISCAL_PERIOD_FAILURE,
});

export const uploadFiscalPeriodStatement = (statement, fiscal_period) => ({
  type: UPLOAD_FISCAL_PERIOD_STATEMENT,
  payload: { statement, fiscal_period },
});

export const uploadFiscalPeriodStatementSuccess = (fiscal_period) => ({
  type: UPLOAD_FISCAL_PERIOD_STATEMENT_SUCCESS,
  payload: fiscal_period,
});

export const uploadFiscalPeriodStatementFailure = () => ({
  type: UPLOAD_FISCAL_PERIOD_STATEMENT_FAILURE,
});
export const updateStatusFiscalPeriod = (status) => ({
  type: UPDATE_STATUS_FISCAL_PERIOD,
  payload: { status },
});
export const updateStatusFiscalPeriodSuccess = (fiscal_period) => ({
  type: UPDATE_STATUS_FISCAL_PERIOD_SUCCESS,
  payload: fiscal_period,
});
export const updateStatusFiscalPeriodFailure = (_) => ({
  type: UPDATE_STATUS_FISCAL_PERIOD_FAILURE,
});
export const selectFiscalPeriod = (fiscal_period) => ({
  type: SELECT_FISCAL_PERIOD,
  payload: fiscal_period,
});

export const uploadFiscalPeriodReceipt = (statement, fiscal_period_id) => ({
  type: UPLOAD_FISCAL_PERIOD_RECEIPT,
  payload: { statement, fiscal_period_id },
});

export const uploadFiscalPeriodReceiptSuccess = (fiscal_period) => ({
  type: UPLOAD_FISCAL_PERIOD_RECEIPT_SUCCESS,
  payload: fiscal_period,
});

export const uploadFiscalPeriodReceiptFailure = () => ({
  type: UPLOAD_FISCAL_PERIOD_RECEIPT_FAILURE,
});

export const postFeedback = (feedback) => ({
  type: POST_FEEDBACK,
  payload: { feedback },
});

export const postFeedbackSuccess = (feedback_posted) => ({
  type: POST_FEEDBACK_SUCCESS,
  payload: feedback_posted,
});

export const postFeedbackFailure = () => ({
  type: POST_FEEDBACK_FAILURE,
});
