import { all, fork, takeLatest } from 'redux-saga/effects';

import {
  BANK_TRANSACTIONS_DECLARATIONS_FETCH,
  BANK_TRANSACTIONS_DECLARATIONS_UPDATE,
  BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE,
} from '@constants/actionTypes';

import fetchSaga from './fetch';
import updateSaga from './edit';
import bulkUpdateSaga from './bulk_edit';

export function* watchFetch() {
  yield takeLatest(BANK_TRANSACTIONS_DECLARATIONS_FETCH, fetchSaga);
}

export function* watchUpdate() {
  yield takeLatest(BANK_TRANSACTIONS_DECLARATIONS_UPDATE, updateSaga);
}

export function* watchBulkUpdate() {
  yield takeLatest(BANK_TRANSACTIONS_DECLARATIONS_BULK_UPDATE, bulkUpdateSaga);
}

export default function* rootSaga() {
  yield all([fork(watchFetch), fork(watchUpdate), fork(watchBulkUpdate)]);
}
