import api from '@api';
import { ProcessWrapper } from 'types/entities';
import { PaginationSchema } from 'types/pagination';

export default async function getProcessWrappers() {
  const { data } = await api.get<PaginationSchema<ProcessWrapper>>(
    `/process_wrappers`,
  );

  return data.data;
}
