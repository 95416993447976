import FullModal from '@components/FullModal';
import { DebtsCardOrder } from '@containers';

interface PreOnboardingHomeCardProps {
  openModal: boolean;
  toggleOpenModal: () => void;
}

export default function DebtsOrdersModal({
  openModal,
  toggleOpenModal,
}: PreOnboardingHomeCardProps) {
  return (
    <FullModal
      open={openModal}
      toggleClose={toggleOpenModal}
      id="preOnboardingHomeCard"
      title="Pagar dedudas pendientes"
      dataCyTitle="debts-orders-modal-title"
      dataCyCloseButton="debts-orders-modal-close-button"
    >
      <DebtsCardOrder title="Pendiente de pago" type="" />
    </FullModal>
  );
}
