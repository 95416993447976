import { DataTable, Flex } from '@konta/ui';
import { DeclarationEntry } from 'types/entities';
import useEntriesTable from './useEntriesTable';

interface EntriesTableProps {
  declarationEntries: DeclarationEntry[];
  declarationEntriesLoading: boolean;
  accessToken: string;
}

export default function EntriesTable({
  declarationEntries,
  declarationEntriesLoading,
  accessToken,
}: EntriesTableProps) {
  const { columns } = useEntriesTable({
    declarationEntries,
    accessToken,
  });
  return (
    <Flex column>
      <DataTable
        data={declarationEntries}
        loading={declarationEntriesLoading}
        // @ts-ignore TODO: fix typing with react-table
        columns={columns}
        withHover
        exportActionsToHide={['pdf']}
        dataTableId="summary-declaration-entries-table-2"
      />
    </Flex>
  );
}
