import { styled } from '@konta/ui';

export const Title = styled('div', {
  color: '$primary700',
  textAlign: 'center',
  fontFamily: 'DM Sans',
  lineHeight: 'normal',
  fontSize: '24px',
  fontWeight: '500',
});

export const ImageContainer = styled('div', {
  position: 'relative',
  '& svg': {
    width: '100%',
    height: 'auto',
  },
});

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '8px',
  border: '1px solid $primary400',
  background: '#FFF',
  mt: '-14px',
});
export const AccumulatedAmountContainer = styled('div', {
  display: 'flex',
  padding: '10px 16px 4px 16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '-4px',
  alignSelf: 'stretch',
  textAlign: 'center',
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  lineHeight: 'normal',
  span: {
    width: '100%',
  },
  'span[data-type="s"]': {
    color: '$primary700',
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  'span[data-type="l"]': {
    fontSize: '40px',
    color: '$primary600',
    fontWeight: '700',
  },
});
export const AvailableBalanceContainer = styled('div', {
  display: 'flex',
  padding: '8px 16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderTop: '1px solid $primary500',
  textAlign: 'center',
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  lineHeight: 'normal',
  span: {
    width: '100%',
  },
  'span[data-type="xs"]': {
    fontSize: '9px',
    color: '$primary700',
    fontWeight: '500',
  },
  'span[data-type="s"]': {
    color: '$primary600',
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'uppercase',
    b: {
      fontWeight: '700',
    },
  },
});
