import _ from 'lodash';
import { Route, RouteConfig, RouteConfigs } from '../types/routesConfig';

class KontaUtils {
  static setRoutes(config: RouteConfig, defaultAuth: string[]) {
    const routes = [...config.routes];

    return routes.map((route) => {
      let auth =
        config.auth || config.auth === null ? config.auth : defaultAuth || null;
      auth = route.auth || route.auth === null ? route.auth : auth;
      const settings = _.merge({}, config.settings, route.settings);

      return {
        ...route,
        settings,
        auth,
      };
    });
  }

  static generateRoutesFromConfigs(
    configs: RouteConfigs,
    defaultAuth: string[],
  ): Route[] {
    return configs.flatMap((config) => this.setRoutes(config, defaultAuth));
  }
}

export default KontaUtils;
