import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useWorkflow from '@components/Workflow/hooks/useWorkflow';
import { usePaymentMethods } from '@hooks';
import {
  workflowPreviousPhase,
  workflowSetTransition,
} from '@redux/workflows/actions';

export default function useTaxesPayment() {
  const dispatch = useDispatch();

  const {
    ui: { selected_card },
  } = usePaymentMethods() as {
    ui: { selected_card: { id: string } };
  };
  const {
    handleRequireCaptureLine,
    requireCaptureLineLoading,
    workflowId,
    declarationsDocuments,
    grandTotalToPay,
    loadingPayrollRetention,
  } = useWorkflow();

  const captureLineDocuments = useMemo(
    () => declarationsDocuments.filter((document) => document.totalToPay > 0),
    [declarationsDocuments],
  );

  const hasAnyExpired = useMemo(
    () => captureLineDocuments.some((document) => !document.isValid),
    [captureLineDocuments],
  );

  const payload = useMemo(
    () => ({
      workflow: {
        next_phase: 'primary_phase',
        card_id: selected_card?.id,
      },
    }),
    [selected_card?.id],
  );

  const handlePrevPhase = async () => {
    if (hasAnyExpired) {
      await handleRequireCaptureLine();
      return;
    }
    dispatch(workflowSetTransition('phase-back'));
    dispatch(workflowPreviousPhase({ workflow_id: workflowId }));
  };

  const isLoading = loadingPayrollRetention || requireCaptureLineLoading;

  return {
    declarationsDocuments: captureLineDocuments,
    hasAnyExpired,
    handleRequireCaptureLine,
    grandTotalToPay,
    isLoading,
    handlePrevPhase,
    payload,
  };
}
