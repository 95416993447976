import React from 'react';
import { Button } from 'reactstrap';

export function MonthDatePickerControls({
  handleCurrentYear,
  handlePreviousYear,
  handleNextYear,
  monthlyList,
}) {
  return (
    <div className="datepicker-buttons">
      <Button
        onClick={handleCurrentYear}
        className="btttn text-button btttn-sm"
      >
        Mes actual
      </Button>
      <span
        aria-hidden="true"
        onClick={handlePreviousYear}
        className="datepicker-year-button btttn text-button btttn-sm"
      >
        <i className="simple-icon-arrow-left" />
      </span>
      <p className="mb-0 datepicker-year-title">{monthlyList?.year}</p>
      <span
        aria-hidden="true"
        onClick={handleNextYear}
        className="datepicker-year-button btttn text-button btttn-sm"
      >
        <i className="simple-icon-arrow-right" />
      </span>
    </div>
  );
}
