import DoughnutChart from 'backoffice/components/DoughnutChart/DoughnutChart';
import React, { useMemo, useState } from 'react';
import Loader from '@components/Loader';
import {
  AccumulatedIncomeAndExpenseFilters,
  ReactSelectItemProps,
} from 'types/entities';
import { Flex } from '@konta/ui';
import useTopTenAccumulatedSales from '../useTopTenAccumulatedSales';

const generateYearOpts = (numberOfYears: number) =>
  Array.from({ length: numberOfYears }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return { value: year.toString(), label: year.toString() };
  });

const generateMonthOpts = () => {
  const months = Array.from({ length: 12 }, (_, index) => {
    const currentDate = new Date(0, index);
    const monthName = currentDate.toLocaleString('default', { month: 'long' });
    return { value: (index + 1).toString(), label: monthName };
  });

  return months;
};

export default function BestSellingClientsDoughnut() {
  const yearFilterOpts = generateYearOpts(2);
  const monthFilterOpts = generateMonthOpts();
  const [yearFilter, setYearFilter] = useState<ReactSelectItemProps<string>>(
    yearFilterOpts[0],
  );
  const [monthFilter, setMonthFilter] = useState<ReactSelectItemProps<string>>(
    monthFilterOpts[0],
  );

  const { dateSet, isLoading, isDesktop } = useTopTenAccumulatedSales({
    filter: yearFilter.value,
    monthFilter: monthFilter.value,
  });

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const totalClientSells = useMemo(() => {
    const clientAmounts = dateSet.data.reduce((acc, obj) => {
      Object.keys(obj).forEach((key) => {
        if (key !== 'column') {
          acc[key] = (acc[key] ?? 0) + +obj[key];
        }
      });
      return acc;
    }, {} as { [key: string]: number });

    const totals = Object.entries(clientAmounts).map(([key, value]) => ({
      [key]: value,
    }));
    return totals;
  }, [dateSet]);

  const data = {
    labels: totalClientSells.map((values) => Object.keys(values)),
    datasets: [
      {
        label: '# of Votes',
        data: totalClientSells.map((item) => Object.values(item)[0]),
        backgroundColor: [
          '#83C7FF',
          '#A29BFE',
          '#FFB8A1',
          '#FF5A89',
          '#F4C222',
          '#9AD8D8',
          '#F97850',
          '#33CCCC',
          '#64B9A1',
          '#919DAF',
          '#F68484',
          '#83C7FF',
          '#A29BFE',
          '#FFB8A1',
        ],
        borderColor: [
          '#83C7FF',
          '#A29BFE',
          '#FFB8A1',
          '#FF5A89',
          '#F4C222',
          '#9AD8D8',
          '#F97850',
          '#33CCCC',
          '#64B9A1',
          '#919DAF',
          '#F68484',
          '#83C7FF',
          '#A29BFE',
          '#FFB8A1',
        ],
        cutout: '50%',
      },
    ],
  };
  if (isLoading) {
    return (
      <Flex
        css={{
          flex: 1,
          overflow: 'auto',
          gap: 10,
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Loader />
      </Flex>
    );
  }

  return (
    <DoughnutChart
      data={data}
      valueType="currency"
      width={190}
      height={200}
      options={chartOptions}
      isDesktop={isDesktop}
      title="Top 10 clientes con mayores ventas"
      textTitleHover="Lorem ipsum dolor sit amet."
      yearFilterProps={{
        onChangeFilter: (newFilter) => {
          setYearFilter(
            newFilter as ReactSelectItemProps<AccumulatedIncomeAndExpenseFilters>,
          );
        },
        options: yearFilterOpts,
        value: yearFilter.value,
      }}
      monthFilterProps={{
        onChangeFilter: (newFilter) => {
          setMonthFilter(
            newFilter as ReactSelectItemProps<AccumulatedIncomeAndExpenseFilters>,
          );
        },
        options: generateMonthOpts(),
        value: monthFilter.value,
      }}
    />
  );
}
