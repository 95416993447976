import { useMemo } from 'react';
import {
  PF_SAT_KEY,
  PFAE_SAT_KEY,
  RESICO_SAT_KEY,
  SALARIES_SAT_KEY,
} from 'shared/constants/grids';

function getDeclarationTitle(satKeys: number[]) {
  if (satKeys.length === 0) {
    return 'Sin título';
  }
  if (
    satKeys.length === 2 &&
    satKeys.every((satKey) => [PF_SAT_KEY, PFAE_SAT_KEY].includes(satKey))
  ) {
    return 'PFAE + PLATAFORMAS';
  }
  if (satKeys.includes(PFAE_SAT_KEY)) {
    return 'PFAE';
  }
  if (satKeys.includes(PF_SAT_KEY)) {
    return 'PLATAFORMAS';
  }
  if (satKeys.includes(RESICO_SAT_KEY)) {
    return 'RESICO';
  }
  if (satKeys.includes(SALARIES_SAT_KEY)) {
    return 'SALARIOS';
  }

  return 'Sin título';
}
interface UseResumeByRegimeOpts {
  satKeys: number[];
  preferredFiscalRegime?: number | null;
}
export default function useResumeByRegime({
  satKeys,
  preferredFiscalRegime,
}: UseResumeByRegimeOpts) {
  const title = getDeclarationTitle(satKeys);

  const filteredSatKeys = useMemo(
    () =>
      satKeys.filter(
        (satKey) => !preferredFiscalRegime || preferredFiscalRegime === satKey,
      ),
    [satKeys, preferredFiscalRegime],
  );

  return { title, filteredSatKeys };
}
