import { DataTable, Text } from '@konta/ui';
import type { DeclarationEntry } from 'types/entities';
import toCurrency from '@util/toCurrency';
import useEntriesTable from './useEntriesTable';
import { totalCss } from '../styled';

export interface EntriesCollapseTableProps {
  declarationEntries: DeclarationEntry[];
  declarationEntriesLoading: boolean;
  type: 'incomes' | 'expenses';
  workflowId: number;
  setModalInvoiceDetails: (value: {
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  }) => void;
  total: number;
}

export default function EntriesTable({
  declarationEntries,
  declarationEntriesLoading,
  type,
  workflowId,
  setModalInvoiceDetails,
  total,
}: EntriesCollapseTableProps) {
  const { declarationEntriesColumns, setItemHovered, openCfdisModal } =
    useEntriesTable({
      type,
      setModalInvoiceDetails,
    });
  return (
    <DataTable
      data={declarationEntries}
      // @ts-ignore TODO: fix typing with react-table
      columns={declarationEntriesColumns}
      withHover
      loading={declarationEntriesLoading}
      dataTableId={`modal-${type}-${workflowId}-entries-table`}
      // onFiltersChange={setFilters}
      withoutExportControl
      withoutHideControl
      onItemHover={setItemHovered}
      onRowClick={openCfdisModal}
      extraFooterRightText={
        <>
          <Text css={totalCss}>Total:</Text>
          <Text css={totalCss} color="primary700">
            {toCurrency(total)}
          </Text>
        </>
      }
    />
  );
}
