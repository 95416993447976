import { workflowNextPhase, workflowSetTransition } from '@redux/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useSelectedWorkflow from './useSelectedWorkflow';

export default function useNextPhase() {
  const dispatch = useDispatch();
  const { workflow } = useSelectedWorkflow();

  const nextPhase = useCallback(
    (body) => {
      dispatch(workflowSetTransition('phase-front'));
      dispatch(workflowNextPhase({ workflow_id: workflow.id, body }));
    },
    [dispatch, workflow.id],
  );

  return nextPhase;
}
