/// <reference types="@types/segment-analytics" />

export const page = () => {
  window.analytics.page();
};
export default function canSendTrack() {
  const watermark = localStorage?.getItem?.('watermark');
  return window.analytics && !watermark;
}
export const track = (
  name: string,
  properties: Record<string, string | boolean>,
) => {
  if (canSendTrack()) {
    window.analytics.track(name, properties);
  }
};
