import { call, select } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  updateStatusFiscalPeriodSuccess,
  updateStatusFiscalPeriodFailure,
} from '../actions';

export default function* ({ payload: { status } }) {
  try {
    const { current } = yield select((state) => state.fiscalPeriods);
    const fiscal_period = {
      ...current,
      status,
    };
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `fiscal_periods/${current.id}`,
      {
        fiscal_period,
      },
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: updateStatusFiscalPeriodSuccess,
      failure: updateStatusFiscalPeriodFailure,
      title: 'Éxito',
      message: 'El periodo fiscal se actualizó',
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: updateStatusFiscalPeriodFailure,
    });
  }
}
