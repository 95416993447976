import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Card, CardTitle, CardBody } from 'reactstrap';
import * as chat from '@util/chat';
import { Colxx } from '@components';

function OtherServicesCard({ taxable_entity }) {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Otros Servicios</CardTitle>
        <Row>
          {taxable_entity.status == !'Demo' && (
            <Colxx md="12" className="mb-2">
              <Link onClick={chat.open}>
                <span className="clickable-text">
                  Agendar una Konta llamada
                </span>
              </Link>
            </Colxx>
          )}
          <Colxx md="12" className="mb-2">
            <Link
              to={`//airtable.com/shrhzj9LXJPBz8Q1A?prefill_TE=${taxable_entity.id}`}
              target="_blank"
            >
              <span className="clickable-text">Guia para generar ciec</span>
            </Link>
          </Colxx>
          <Colxx md="12" className="mb-2">
            <Link
              to={`//airtable.com/shr4SpgYxykHXFUSw?prefill_TE=${taxable_entity.id}`}
              target="_blank"
            >
              <span className="clickable-text">
                Solicitar trámite de padrón de importadores
              </span>
            </Link>
          </Colxx>
          <Colxx md="12" className="mb-2">
            <Link
              to={`//airtable.com/shrlUglKwbb3cIkJu?prefill_TE=${taxable_entity.id}`}
              target="_blank"
            >
              <span className="clickable-text">
                Solicitar trámite de cambio de domicilio
              </span>
            </Link>
          </Colxx>
          <Colxx md="12" className="mb-2">
            <Link
              to={`//airtable.com/shr56dUKVdaRcuX15?prefill_TE=${taxable_entity.id}`}
              target="_blank"
            >
              <span className="clickable-text">
                Solicitar renovación de sellos digitales
              </span>
            </Link>
          </Colxx>
        </Row>
      </CardBody>
    </Card>
  );
}

const mapStateToProps = ({ taxableEntity }) => {
  const { taxable_entity } = taxableEntity;
  return { taxable_entity };
};
export default connect(mapStateToProps, {})(OtherServicesCard);
