import toCurrency from '@util/toCurrency';
import getRegimeNameFromFiscalRegime from 'shared/util/getRegimeNameFromFiscalRegime';
import { CalculateDeclarationsSummary } from './calculateDeclarationsSummary';

export default function generateRegimeTotalRows(
  declarationSummary: CalculateDeclarationsSummary,
) {
  return declarationSummary.declarationSummary.map((declaration, index) => {
    const { totalLateFees } = declaration;

    const baseRows = [
      {
        key: `${index}`,
        label: `Ingresos`,
        amount: `${toCurrency(+declaration.incomes)} MXN`,
      },
      {
        key: `${index}`,
        label: `Gastos`,
        amount: `${toCurrency(+declaration.expenses)} MXN`,
      },
      {
        key: `${index}`,
        label: `Resultado de IVA`,
        amount: `${toCurrency(+declaration.taxes.ivaTotal)} MXN`,
      },
      {
        key: `${index}`,
        label: `Resultado de ISR`,
        amount: `${toCurrency(+declaration.taxes.isrTotal)} MXN`,
      },
      {
        key: `${index}`,
        label: `Total a pagar de ISR`,
        amount: `${toCurrency(+declaration.taxes.totalIsrToPay)} MXN`,
      },
      ...(totalLateFees > 0
        ? [
            {
              key: `${index}`,
              label: `Recargos`,
              amount: `${toCurrency(totalLateFees)} MXN`,
            },
          ]
        : []),
      {
        key: `${index}`,
        label: `Total a pagar`,
        amount: `${toCurrency(+declaration.taxes.totalToPay)} MXN`,
      },
    ];

    return {
      name: getRegimeNameFromFiscalRegime(declaration.fiscalRegime),
      rows: baseRows,
    };
  });
}
