import { WithoutTaxEffectsFlowStep } from 'types/flows';
import { ReactComponent as Img } from './img.svg';
import { ImageContainer, Title, Subtitle } from './styled';

interface WithoutTaxEffectsStepContentProps {
  withoutTaxEffectsFlowStep: WithoutTaxEffectsFlowStep;
}

export default function WithoutTaxEffectsStepContent({
  withoutTaxEffectsFlowStep,
}: WithoutTaxEffectsStepContentProps) {
  const { state } = withoutTaxEffectsFlowStep;
  const { count } = state;

  return (
    <>
      <Title>
        El mes pasado tuviste ({count}) factura{count === 1 ? '' : 's'} sin
        efectos fiscales
      </Title>
      <ImageContainer>
        <Img />
      </ImageContainer>
      <Subtitle>
        <span data-type="s">Para garantizar que puedas deducir</span>
        <span data-type="s">correctamente tus gastos toma</span>
        <span data-type="s">
          en consideración lo siguiente <small>👉🏼</small>
        </span>
      </Subtitle>
    </>
  );
}
