import React, { useState } from 'react';
import { useTaxableEntity } from '@hooks';
import { Flex, CircularLoading, Row } from '@konta/ui';
import { Colxx } from '@components';
import OnboardingInformationCollapse from './OnboardingInformationCollapse';

export default function OnboardingHomeCard() {
  const { loading } = useTaxableEntity();
  const [selectedAccordion, setSelectedAccordion] = useState(1);

  const handleAccordionClick = (step) => {
    if (selectedAccordion === step) {
      setSelectedAccordion(0);
    } else {
      setSelectedAccordion(step);
    }
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" css={{ height: '100%' }}>
        <CircularLoading xl />
      </Flex>
    );
  }

  return (
    <Row>
      <Colxx lg={12}>
        <OnboardingInformationCollapse
          handleAccordionClick={handleAccordionClick}
          selectedAccordion={selectedAccordion}
        />
      </Colxx>
    </Row>
  );
}
