import React from 'react';
import { Button, Flex, styled, Text } from '@konta/ui';
import * as chat from '@util/chat';
import { ArrowLeftLineIcon, ArrowUpRightLineIcon } from '@konta/icons';
import ProcessStepDescription from '@components/ProcessStepDescription';
import ProcessStepTitle from '@components/ProcessStepTitle';

const Iframe = styled('iframe', {
  width: '560px',
  height: '315px',
  borderRadius: '8px',
  '@<md': {
    width: '100%',
  },
});

interface OnboardingCiecRequestProps {
  setShowHelpForRequestCiec: () => void;
}

export default function OnboardingCiecRequest({
  setShowHelpForRequestCiec,
}: OnboardingCiecRequestProps) {
  return (
    <>
      <Flex direction="column" gap={12}>
        <ProcessStepTitle>Crear una nueva CIEC</ProcessStepTitle>
        <ProcessStepDescription>
          Es fácil e instantáneo (Si funciona el SAT 😂)
        </ProcessStepDescription>
        <ProcessStepDescription>
          Para ayudarte a restablecer tu contraseña, hemos creado un video con
          instrucciones paso a paso. Al finalizar, proporciona la nueva CIEC.
        </ProcessStepDescription>
      </Flex>
      <Flex
        style={{
          gap: '32px',
        }}
        direction="column"
      >
        <div>
          <Button
            color="primary600"
            onClick={() => {
              window.open(
                'https://www.siat.sat.gob.mx/PTSC/auth/faces/pages/restablecer/recuperarContrasenia.jsf',
                '_blank',
              );
            }}
            rightIcon={<ArrowUpRightLineIcon />}
            variant="text"
          >
            Genera tu CIEC aquí
          </Button>
        </div>
        <Iframe
          src="https://www.youtube.com/embed/myNMhcucWNo?modestbranding=1&rel=0&color=white"
          title="¿Cómo sacar mi contraseña del SAT?"
          frameBorder="0"
          allowFullScreen
        />
        <div>
          <Text>¿Tienes dificultades?</Text>{' '}
          <Button color="primary600" onClick={chat.open} variant="text">
            Contáctanos en el chat
          </Button>
        </div>
      </Flex>
      <Flex gap={12}>
        <Button
          color="gray"
          variant="outlined"
          onClick={setShowHelpForRequestCiec}
          leftIcon={<ArrowLeftLineIcon />}
        >
          Regresar a subir mi CIEC
        </Button>
      </Flex>
    </>
  );
}
