import { styled } from '@konta/ui';

export const ContainerButton = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$10',
  padding: '$24',
  border: '1px solid $gray200',
  borderRadius: '8px',
  width: '300px',
  height: '206px',
  '&:hover': {
    cursor: 'pointer',
  },
});
