import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  CONTACT_METHOD_FETCH,
  CONTACT_METHOD_POST,
  CONTACT_METHOD_UPDATE,
  CONTACT_METHOD_DELETE,
} from '@constants/actionTypes';

import postSaga from './post';
import fetchSaga from './fetch';
import deleteSaga from './delete';
import editSaga from './edit';

export function* watchFetch() {
  yield takeLatest(CONTACT_METHOD_FETCH, fetchSaga);
}

export function* watchPost() {
  yield takeLatest(CONTACT_METHOD_POST, postSaga);
}

export function* watchDelete() {
  yield takeLatest(CONTACT_METHOD_DELETE, deleteSaga);
}

export function* watchEdit() {
  yield takeLatest(CONTACT_METHOD_UPDATE, editSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetch),
    fork(watchPost),
    fork(watchEdit),
    fork(watchDelete),
  ]);
}
