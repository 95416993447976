import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Label } from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { Colxx } from '@components/CustomBootstrap';
import { convertNullToEmpty } from '@util/Forms';
import { SimpleStateButton, CustomSelect } from '@components';
import {
  updateTaxableEntity,
  addressFetch,
  addressUpdate,
  fiscalRegimensFetch,
} from '@redux/actions';
import { ReturnButton } from '@components/Buttons';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { NotificationManager } from '@components/Notifications';
import { fiscalRegimeParser } from '@util/Utils';

class PersonalInfoCard extends Component {
  state = {
    legal_type: null,
  };

  handleInputChange = (isValid, data, rawNumber, formattedNumber) => {
    if (!isValid) {
      this.setState({
        ...this.state,
        phone_number: formattedNumber,
        error: false,
      });
    } else {
      this.setState({
        ...this.state,
        phone_number: formattedNumber,
        error: true,
      });
    }
  };

  handleSubmit = (event, errors, values) => {
    const { taxable_entity } = this.props;
    if (
      errors.length === 0 &&
      this.state.phone_number &&
      this.state.error &&
      this.state.legal_type
    ) {
      const body = {
        taxable_entity: {
          ...values,
          fiscal_regime_ids: this.state.legal_type?.map((item) => item.key),
          phone_number: this.state.phone_number,
        },
      };
      this.props.updateTaxableEntity(body, taxable_entity.id);
      this.props.addressUpdate({ postcode: values.postcode }, { silent: true });
    } else if (!this.state.error) {
      NotificationManager.error(
        'Verifique que el formato del teléfono sea correcto e intente de nuevo.',
        'Error el el número teléfono',
        6000,
        null,
        null,
        null
      );
    } else if (!this.state.legal_type) {
      NotificationManager.error(
        'Debes agregar uno más de un régimen fiscal.',
        'Régimen fiscal requerido',
        6000,
        null,
        null,
        null
      );
    }
  };

  handleFiscalRegimenChange = (selectedFiscalRegimen) => {
    this.setState({ legal_type: selectedFiscalRegimen });
  };

  handleBack = (e) => {
    e.preventDefault();
    const { activeTab, setActiveTab } = this.props;
    setActiveTab(String(+activeTab - 1));
  };

  componentDidMount() {
    const { taxable_entity, address, fiscal_regimens } = this.props;

    address || this.props.addressFetch();
    this.props.fiscalRegimensFetch();
    this.setState({
      legal_type: fiscal_regimens.find(
        ({ id }) => id === taxable_entity.legal_type
      ),
    });
  }

  render() {
    const { taxable_entity, loading, address, fiscal_regimens } = this.props;
    const { legal_type } = this.state;
    const fiscal_regimen_list = fiscalRegimeParser(fiscal_regimens);
    return (
      <Row>
        <Colxx xxs={12} className="mb-4">
          <AvForm
            onSubmit={this.handleSubmit}
            model={convertNullToEmpty(taxable_entity)}
            className="has-float-label"
          >
            <Row>
              <Colxx lg={12}>
                <AvGroup>
                  <Label htmlFor="legal_email">Email de contacto</Label>
                  <AvInput name="legal_email" />
                  <AvFeedback>Email invalido!</AvFeedback>
                </AvGroup>
              </Colxx>
              <Colxx lg={6}>
                <AvGroup>
                  <Label htmlFor="postcode">Codigo postal de la empresa</Label>
                  <AvInput name="postcode" value={address?.postcode || ''} />
                </AvGroup>
              </Colxx>
              <Colxx lg={6}>
                <AvGroup>
                  <Label htmlFor="phone_number">Teléfono de contacto</Label>
                  <IntlTelInput
                    country="mx"
                    containerClassName="intl-tel-input contactMethod__edit_input"
                    inputClassName=" form-control"
                    onPhoneNumberChange={this.handleInputChange}
                    onPhoneNumberBlur={this.handleInputChange}
                    defaultCountry="mx"
                    defaultValue={taxable_entity.phone_number}
                    preferredCountries={['mx']}
                  />
                </AvGroup>
              </Colxx>
              <Colxx lg={12}>
                <Colxx lg={6} className="mb-3 p-0">
                  <Label className="av-label" for="fiscal_regimen">
                    Régimen fiscal
                  </Label>
                  <CustomSelect
                    name="fiscal_regimen"
                    value={legal_type}
                    onChange={this.handleFiscalRegimenChange}
                    options={fiscal_regimen_list}
                    isMulti
                  />
                </Colxx>
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="6" className="text-right">
                <ReturnButton
                  handleReturn={this.handleBack}
                  size="sm"
                  className="ml-2"
                />
              </Colxx>
              <Colxx xxs="6">
                <SimpleStateButton loading={loading}>Guardar</SimpleStateButton>
              </Colxx>
            </Row>
          </AvForm>
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({
  taxableEntity: { taxable_entity, loading },
  addresses: { address },
  fiscal_regimens: { fiscal_regimens },
}) => {
  return { taxable_entity, loading, address, fiscal_regimens };
};

export default connect(mapStateToProps, {
  updateTaxableEntity,
  addressFetch,
  addressUpdate,
  fiscalRegimensFetch,
})(PersonalInfoCard);
