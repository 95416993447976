import camelcaseKeys from 'camelcase-keys';
import { Declaration, DeclarationIncome, Workflow } from 'types/entities';
import { getPreviousTaxTotalByTaxType } from './getDeclarationTotalsForSatPresentation';

// ISR causado del periodo or IVA a cargo o a favor
export function getInFavorOrAgainst({
  declaration,
  totalKey,
  favorKey,
  unduePaymentKey,
  declarations = [],
  workflow,
}: {
  declaration: Declaration;
  totalKey: string;
  favorKey: string;
  unduePaymentKey: string;
  declarations?: Declaration[];
  workflow?: Workflow;
}) {
  const taxType = totalKey === 'ivaTotal' ? 'iva' : 'isr';
  const previousIva = getPreviousTaxTotalByTaxType({
    declaration,
    workflow: workflow || null,
    taxType: taxType || 'iva',
  });
  const workflowStatus = [
    'submit_declaration',
    'pending_payment',
    'paid',
    'done',
  ];

  // IMPORTANT this validation won't be removed until backend has Saldos feature
  const isWorkflowOnRecalculationStatus =
    workflow && workflowStatus.includes(workflow.status);

  const values: { [key: string]: any } = camelcaseKeys(declaration);
  const valuesForSingleRegime: { [key: string]: any } = camelcaseKeys(
    declarations[0],
  );

  const total = +values[totalKey];
  const favor = valuesForSingleRegime
    ? +valuesForSingleRegime[favorKey]
    : +values[favorKey];

  const unduePayment = +(values[unduePaymentKey] || 0);
  const result =
    total -
    (isWorkflowOnRecalculationStatus ? previousIva : Math.min(total, favor)); // IMPORTANT this line will use only Math.min(total, favor)) once backend has Saldos feature

  return result - Math.min(result, unduePayment);
}

// Total a pagar
export function getTotalToPayOfPeriod(
  declaration: Declaration,
  ivaInFavorOrAgainst: number,
  isrInFavorOrAgainst: number,
) {
  const { totalPayrollWithholdingsToPay, totalLateFees } =
    camelcaseKeys(declaration);
  return (
    Math.max(ivaInFavorOrAgainst, 0) +
    Math.max(isrInFavorOrAgainst, 0) +
    +totalPayrollWithholdingsToPay +
    +(totalLateFees || 0)
  );
}

// Pagos provisionales realizados
export function getAccruedPayment(declaration: Declaration) {
  const { accruedPayment } = camelcaseKeys(declaration);
  return +(accruedPayment || 0);
}

// Retenciones de sueldos y salarios por pagar
export function getTotalPayrollWithholdingsToPay(declaration: Declaration) {
  const { totalPayrollWithholdingsToPay } = camelcaseKeys(declaration);
  return +(totalPayrollWithholdingsToPay || 0);
}

// Compensación aplicada
export function getUnduePaymentUsedOfIsr(declaration: Declaration) {
  const { unduePaymentUsedOfIsr } = camelcaseKeys(declaration);
  return +(unduePaymentUsedOfIsr || 0);
}

// Saldo a favor de años anteriores
export function getIsrInFavor(declaration: Declaration) {
  const { isrInFavor } = camelcaseKeys(declaration);
  return +(isrInFavor || 0);
}

// Resultado de ISR del periodo
export function getIsrTotalRemaining(declaration: Declaration) {
  const { isrTotal } = camelcaseKeys(declaration);
  return +isrTotal || 0;
}

// Resultado de IVA del periodo
export function getIvaTotalRemaining(declaration: Declaration) {
  const { ivaTotal } = camelcaseKeys(declaration);
  return +ivaTotal || 0;
}

// Recargos
export function getTotalLateFees(declaration: Declaration) {
  const { totalLateFees } = camelcaseKeys(declaration);
  return +(totalLateFees || 0);
}

// Saldo a favor de meses anteriores
export function getIvaInFavor(declaration: Declaration, workflow?: Workflow) {
  const previousIva = getPreviousTaxTotalByTaxType({
    declaration,
    workflow: workflow || null,
    taxType: 'iva',
  });

  // IMPORTANT this return will be descomented when backend has Saldos feature
  // return +ivaInFavor;
  return previousIva;
}

// Pago de lo indebido de ISR
export function getUnduePaymentIsrInFavor(declaration: Declaration) {
  const { unduePaymentIsrInFavor } = camelcaseKeys(declaration);
  return +(unduePaymentIsrInFavor || 0);
}

// Pago de lo indebido de IVA
export function getUnduePaymentIvaInFavor(declaration: Declaration) {
  const { unduePaymentIvaInFavor } = camelcaseKeys(declaration);
  return +(unduePaymentIvaInFavor || 0);
}

// Pérdida fiscal
export function getIsrTaxLoss(declaration: Declaration) {
  const { isrTaxLoss } = camelcaseKeys(declaration);
  return +(isrTaxLoss || 0);
}

// Compensaciones aplicadas
export function getCompensationsApplied(declaration: Declaration) {
  const isrInFavorOrAgainst = getInFavorOrAgainst({
    declaration,
    totalKey: 'isrTotal',
    favorKey: 'isrInFavor',
    unduePaymentKey: 'unduePaymentIsrInFavor',
  });
  const isrInFavor = getIsrInFavor(declaration);
  return Math.min(isrInFavorOrAgainst, isrInFavor);
}

export function getTotalWithIvaOfManualInput(
  declarationIncome: DeclarationIncome,
) {
  const { totalWithIvaOfManualInput } = camelcaseKeys(declarationIncome);
  return +totalWithIvaOfManualInput;
}

export function getTotalZeroBasesOfManualInput(
  declarationIncome: DeclarationIncome,
) {
  const { totalZeroBasesOfManualInput, extraZeroBases } =
    camelcaseKeys(declarationIncome);
  return +totalZeroBasesOfManualInput + +extraZeroBases;
}

export function getTotalExemptBasesOfManualInput(
  declarationIncome: DeclarationIncome,
) {
  const { totalExemptBasesOfManualInput, extraExemptBases } =
    camelcaseKeys(declarationIncome);
  return +totalExemptBasesOfManualInput + +extraExemptBases;
}

export function getTotalWithIva16OfManualInput(
  declarationIncome: DeclarationIncome,
) {
  const { totalWithIva16OfManualInput } = camelcaseKeys(declarationIncome);
  return +totalWithIva16OfManualInput || 0;
}

export function getTotalWithIva8OfManualInput(
  declarationIncome: DeclarationIncome,
) {
  const { totalWithIva8OfManualInput } = camelcaseKeys(declarationIncome);
  return +totalWithIva8OfManualInput || 0;
}

export function getTotalBases(declarationIncome: DeclarationIncome) {
  const { totalIvaBases } = camelcaseKeys(declarationIncome);
  return +totalIvaBases;
}

export function getTotalIvaBases(declarationIncome: DeclarationIncome) {
  const { totalIvaBases } = camelcaseKeys(declarationIncome);
  return +totalIvaBases;
}

export function getTotalIva(declarationIncome: DeclarationIncome) {
  const { totalIva } = camelcaseKeys(declarationIncome);
  return +totalIva;
}

export function getTotal(declarationIncome: DeclarationIncome) {
  const { total } = camelcaseKeys(declarationIncome);
  return +total;
}

export function getTotalExemptBases(declarationIncome: DeclarationIncome) {
  const { totalExemptBases } = camelcaseKeys(declarationIncome);
  return +totalExemptBases;
}

export function getTotalZeroBases(declarationIncome: DeclarationIncome) {
  const { totalZeroBases } = camelcaseKeys(declarationIncome);
  return +totalZeroBases;
}

export function getTotalZeroBasesUnbilled(
  declarationIncome: DeclarationIncome,
) {
  const { totalZeroBasesUnbilled } = camelcaseKeys(declarationIncome);
  return +totalZeroBasesUnbilled;
}

export function getTotalExemptBasesUnbilled(
  declarationIncome: DeclarationIncome,
) {
  const { totalExemptBasesUnbilled } = camelcaseKeys(declarationIncome);
  return +totalExemptBasesUnbilled;
}

export function getTotalBasesWithIva(declarationIncome: DeclarationIncome) {
  const { total, totalIva } = camelcaseKeys(declarationIncome);
  return +total + +totalIva;
}

export function getTotalBasesOfCfdis(declarationIncome: DeclarationIncome) {
  const {
    totalIvaBasesOfCfdis,
    totalIvaExemptBasesOfCfdis,
    totalZeroBasesOfCfdis,
  } = camelcaseKeys(declarationIncome);
  return (
    +totalIvaBasesOfCfdis + +totalIvaExemptBasesOfCfdis + +totalZeroBasesOfCfdis
  );
}

export function getTotalTaxableBasesOfCfdis(
  declarationIncome: DeclarationIncome,
) {
  const { totalIvaBasesOfCfdis } = camelcaseKeys(declarationIncome);
  return +totalIvaBasesOfCfdis;
}

export function getTotalIvaBasesOfCfdis(declarationIncome: DeclarationIncome) {
  const { totalIvaBasesOfCfdis } = camelcaseKeys(declarationIncome);
  return +totalIvaBasesOfCfdis;
}

export function getTotalIvaExemptBasesOfCfdis(
  declarationIncome: DeclarationIncome,
) {
  const { totalIvaExemptBasesOfCfdis } = camelcaseKeys(declarationIncome);
  return +totalIvaExemptBasesOfCfdis;
}

export function getTotalZeroBasesOfCfdis(declarationIncome: DeclarationIncome) {
  const { totalZeroBasesOfCfdis } = camelcaseKeys(declarationIncome);
  return +totalZeroBasesOfCfdis;
}

export function getTotalIvaOfCfdis(declarationIncome: DeclarationIncome) {
  const { totalIvaOfCfdis } = camelcaseKeys(declarationIncome);
  return +totalIvaOfCfdis;
}

export function getTotalRetainedOfCfdis(declarationIncome: DeclarationIncome) {
  const { totalRetainedIvaOfCfdis, totalRetainedIsrOfCfdis } =
    camelcaseKeys(declarationIncome);
  return +totalRetainedIvaOfCfdis + +totalRetainedIsrOfCfdis;
}

export function getTotalBasesWithIvaOfCfdis(
  declarationIncome: DeclarationIncome,
) {
  const {
    totalIvaBasesOfCfdis,
    totalIvaExemptBasesOfCfdis,
    totalZeroBasesOfCfdis,
    totalIvaOfCfdis,
    totalRetainedIvaOfCfdis,
    totalRetainedIsrOfCfdis,
  } = camelcaseKeys(declarationIncome);
  return (
    +totalIvaBasesOfCfdis +
    +totalIvaExemptBasesOfCfdis +
    +totalZeroBasesOfCfdis +
    +totalIvaOfCfdis -
    +totalRetainedIvaOfCfdis -
    +totalRetainedIsrOfCfdis
  );
}

export function getTotalUnbilled(declarationIncome: DeclarationIncome) {
  const { totalUnbilled } = camelcaseKeys(declarationIncome);
  return +totalUnbilled;
}

export function getTotalIvaBasesUnbilled(declarationIncome: DeclarationIncome) {
  const { totalIvaBasesUnbilled } = camelcaseKeys(declarationIncome);
  return +totalIvaBasesUnbilled;
}

export function getTotalIvaUnbilled(declarationIncome: DeclarationIncome) {
  const { totalIvaBase8Unbilled, totalIvaBase16Unbilled } =
    camelcaseKeys(declarationIncome);
  return +totalIvaBase8Unbilled * 0.08 + +totalIvaBase16Unbilled * 0.16;
}

export function getTotalBasesWithIvaUnbilled(
  declarationIncome: DeclarationIncome,
) {
  const { totalUnbilled } = camelcaseKeys(declarationIncome);
  const totalIvaUnbilled = getTotalIvaUnbilled(declarationIncome);
  return +totalUnbilled + totalIvaUnbilled;
}

export function getTotalRetainedIvaOfCfdis(
  declarationIncome: DeclarationIncome,
) {
  const { totalRetainedIvaOfCfdis } = camelcaseKeys(declarationIncome);
  return +totalRetainedIvaOfCfdis;
}

export function getTotalRetainedIsrOfCfdis(
  declarationIncome: DeclarationIncome,
) {
  const { totalRetainedIsrOfCfdis } = camelcaseKeys(declarationIncome);
  return +totalRetainedIsrOfCfdis;
}

export function getTotalIvaBase8Unbilled(declarationIncome: DeclarationIncome) {
  const { totalIvaBase8Unbilled } = camelcaseKeys(declarationIncome);
  return +totalIvaBase8Unbilled;
}

export function getTotalIvaBase16Unbilled(
  declarationIncome: DeclarationIncome,
) {
  const { totalIvaBase16Unbilled } = camelcaseKeys(declarationIncome);
  return +totalIvaBase16Unbilled;
}

export function getTotalIva8Bases(declarationIncome: DeclarationIncome) {
  const { totalIva8Bases } = camelcaseKeys(declarationIncome);
  return +totalIva8Bases;
}

export function getTotalIva16Bases(declarationIncome: DeclarationIncome) {
  const { totalIva16Bases } = camelcaseKeys(declarationIncome);
  return +totalIva16Bases;
}

export function getTotalOfPublicInvoice(declarationIncome: DeclarationIncome) {
  const {
    totalIvaBase8Unbilled,
    totalIvaBase16Unbilled,
    totalExemptBasesUnbilled,
    totalZeroBasesUnbilled,
  } = camelcaseKeys(declarationIncome);
  return (
    +totalIvaBase8Unbilled * 1.08 +
    +totalIvaBase16Unbilled * 1.16 +
    +totalExemptBasesUnbilled +
    +totalZeroBasesUnbilled
  );
}

export function getSubTotalOfPublicInvoice(
  declarationIncome: DeclarationIncome,
) {
  const { totalUnbilled } = camelcaseKeys(declarationIncome);
  return +totalUnbilled;
}

export function getTotalTaxes(declarationIncome: DeclarationIncome) {
  const { totalIva8Bases, totalIva16Bases } = camelcaseKeys(declarationIncome);
  return +totalIva8Bases * 0.08 + +totalIva16Bases * 0.16;
}

export function getTotalRetentions(declarationIncome: DeclarationIncome) {
  const { totalRetainedIsr, totalRetainedIva } =
    camelcaseKeys(declarationIncome);
  return +totalRetainedIsr + +totalRetainedIva;
}

export function getTotalIVA8(declarationIncome: DeclarationIncome) {
  const { totalIva8Bases, totalRetainedIsr8OfCfdis, totalRetainedIva8OfCfdis } =
    camelcaseKeys(declarationIncome);
  return (
    +totalIva8Bases * 1.08 -
    +totalRetainedIsr8OfCfdis -
    +totalRetainedIva8OfCfdis
  );
}

export function getTotalIVA16(declarationIncome: DeclarationIncome) {
  const {
    totalIva16Bases,
    totalRetainedIsr16OfCfdis,
    totalRetainedIva16OfCfdis,
  } = camelcaseKeys(declarationIncome);
  return (
    +totalIva16Bases * 1.16 -
    +totalRetainedIsr16OfCfdis -
    +totalRetainedIva16OfCfdis
  );
}

export function getGrandTotalBases(declarationIncome: DeclarationIncome) {
  const { totalIvaBases, totalExemptBases, totalZeroBases } =
    camelcaseKeys(declarationIncome);
  return +totalIvaBases + +totalExemptBases + +totalZeroBases;
}

export function getGrandTotal(declarationIncome: DeclarationIncome) {
  const grandTotalBases = getGrandTotalBases(declarationIncome);
  const totalTaxes = getTotalTaxes(declarationIncome);
  return grandTotalBases + totalTaxes;
}

export function getSumIvaBasesOfConstancies(
  declarationIncome: DeclarationIncome,
) {
  const { sumIvaBasesOfConstancies } = camelcaseKeys(declarationIncome);
  return +sumIvaBasesOfConstancies;
}

export function getSumExemptBasesOfConstancies(
  declarationIncome: DeclarationIncome,
) {
  const { sumExemptBasesOfConstancies } = camelcaseKeys(declarationIncome);
  return +sumExemptBasesOfConstancies;
}

export function getExtraIva(declarationIncome: DeclarationIncome) {
  const { extraIva } = camelcaseKeys(declarationIncome);
  return +extraIva;
}

export function getExtraZeroBases(declarationIncome: DeclarationIncome) {
  const { extraZeroBases } = camelcaseKeys(declarationIncome);
  return +extraZeroBases;
}

export function getTotalRetainedIva(declarationIncome: DeclarationIncome) {
  const { totalRetainedIva } = camelcaseKeys(declarationIncome);
  return +totalRetainedIva;
}

export function getSumRetainedIsrOfConstancies(
  declarationIncome: DeclarationIncome,
) {
  const { sumRetainedIsrOfConstancies } = camelcaseKeys(declarationIncome);
  return +sumRetainedIsrOfConstancies;
}

export function getTaxesWithheldOfPayroll(
  declarationIncome: DeclarationIncome,
) {
  const { taxesWithheldOfPayroll } = camelcaseKeys(declarationIncome);
  return +taxesWithheldOfPayroll;
}

export function getEmploymentSubsidyOfPayroll(
  declarationIncome: DeclarationIncome,
) {
  const { employmentSubsidyOfPayroll } = camelcaseKeys(declarationIncome);
  return +employmentSubsidyOfPayroll;
}

export function getSumIvaBase16OfManualInput(
  declarationIncome: DeclarationIncome,
) {
  const { sumIvaBase16OfManualInput, extraIvaBases16 } =
    camelcaseKeys(declarationIncome);
  return +sumIvaBase16OfManualInput + +extraIvaBases16;
}

export function getSumIvaBase8OfManualInput(
  declarationIncome: DeclarationIncome,
) {
  const { sumIvaBase8OfManualInput, extraIvaBases8 } =
    camelcaseKeys(declarationIncome);
  return +sumIvaBase8OfManualInput + +extraIvaBases8;
}

export function getSumIvaBasesOfEgress(declarationIncome: DeclarationIncome) {
  const { sumIvaBasesOfEgress } = camelcaseKeys(declarationIncome);
  return +sumIvaBasesOfEgress;
}

export function getAccruedIsrRetention(declarationIncome: DeclarationIncome) {
  const { accruedIsrRetention } = camelcaseKeys(declarationIncome);
  return +accruedIsrRetention;
}

export function getTotalRetainedIsr(declarationIncome: DeclarationIncome) {
  const { totalRetainedIsr } = camelcaseKeys(declarationIncome);
  return +totalRetainedIsr;
}

export function getSumRetainedIvaOfConstancies(
  declarationIncome: DeclarationIncome,
) {
  const { sumRetainedIvaOfConstancies } = camelcaseKeys(declarationIncome);
  return +sumRetainedIvaOfConstancies;
}

export function getExtraIvaBases16(declarationIncome: DeclarationIncome) {
  const { extraIvaBases16 } = camelcaseKeys(declarationIncome);
  return +extraIvaBases16;
}

export function getExtraIvaBases8(declarationIncome: DeclarationIncome) {
  const { extraIvaBases8 } = camelcaseKeys(declarationIncome);
  return +extraIvaBases8;
}

export function getExtraExemptBases(declarationIncome: DeclarationIncome) {
  const { extraExemptBases } = camelcaseKeys(declarationIncome);
  return +extraExemptBases;
}
