import api from '@api';
import { NotificationManager } from '@components';

export default async function downloadXmlFromCfdi(
  cfdiId: string | number,
  name?: string,
): Promise<void> {
  try {
    if (!cfdiId) {
      throw new Error('No se proporcionó un ID de CFDI');
    }
    const response = await api.post<{
      url: string;
    }>(`/download`, {
      resource: 'cfdi',
      id: cfdiId,
      attribute: 'xml',
    });
    const fileLink = response.data;
    const downloadLink = document.createElement('a');
    const fileName = name ? `${name}.xml` : `cfdi-${cfdiId}.xml`;
    downloadLink.href = fileLink.url;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
  } catch (e) {
    console.error(e);
    NotificationManager.error(
      'Ocurrió un error al descargar el xml del CFDI',
      'Error',
    );
  }
}
