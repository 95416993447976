import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { getTaxableEntity } from 'shared/api/taxableEntity';
import { TAXABLE_ENTITY } from 'shared/constants/reactQueries';
import { TaxableEntity } from 'types/entities';

export default function useTaxableEntity(
  taxableEntityId: number | string,
  axiosConfig?: AxiosRequestConfig<TaxableEntity>,
  queryOptions?: UseQueryOptions<TaxableEntity>,
) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<TaxableEntity>(
    [TAXABLE_ENTITY, axiosConfig],
    async () => {
      const taxableEntity = await getTaxableEntity(
        taxableEntityId,
        axiosConfig,
      );
      return taxableEntity;
    },
    queryOptions,
  );

  return {
    taxableEntityLoading: isLoading || isFetching,
    taxableEntity: data,
    ...rest,
  };
}
