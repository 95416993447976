import { ReactNode } from 'react';
import { Flex } from '@konta/ui';
import Loader from '../Loader';
import styles from './WorkflowLayout.module.scss';

export interface WorkflowLayoutProps {
  actions?: ReactNode;
  header?: ReactNode;
  children: ReactNode;
  isLoading?: boolean;
}
export default function WorkflowLayout({
  actions,
  header,
  children,
  isLoading,
}: WorkflowLayoutProps) {
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Flex
      direction="column"
      gap="16"
      align="stretch"
      css={{ mb: '50px', '@md': { paddingRight: '70px' } }}
    >
      {!!header && header}
      <Flex direction="column" className="h-100" gap="16">
        {children}
      </Flex>
      {!!actions && (
        <Flex gap="16" className={styles.actions} align="center">
          {actions}
        </Flex>
      )}
    </Flex>
  );
}
