import api from '@api';
import { IS_BACKOFFICE } from '@constants/enviroments';
import type { AxiosRequestConfig } from 'axios';
import type {
  NewWorkflowHeatmapData,
  TaxableEntityPreferredFiscalRegimePatchRequest,
  TaxableEntity,
} from 'types/entities';
import * as process from 'process';
import { TaxableEntityFlow } from 'types/flows';

export const getDeclarationStatus = async (
  taxableEntityId: number | string,
  config?: AxiosRequestConfig<NewWorkflowHeatmapData>,
) => {
  const { data } = await api.get<NewWorkflowHeatmapData>(
    `taxable_entities/${taxableEntityId}/declarations_status`,
    config,
  );
  return data;
};

export default async function patchTaxableEntityPreferredRegime(
  { payload, taxableEntityId }: TaxableEntityPreferredFiscalRegimePatchRequest,
  config?: AxiosRequestConfig<TaxableEntity>,
) {
  let host = process.env.REACT_APP_API_HOST || '';
  if (IS_BACKOFFICE) {
    const environment = JSON.parse(
      localStorage.getItem('environments' || '') || '{}',
    ) as { host: string };
    host = environment?.host;
  }
  if (!host) {
    throw new Error('Host is not defined');
  }
  const url = `${host}/api/v2/taxable_entities/${taxableEntityId}/set_preferred_fiscal_regime`;
  const { data } = await api.patch<TaxableEntity>(url, payload, config);
  return data;
}

export const getTaxableEntity = async (
  taxableEntityId: number | string,
  config?: AxiosRequestConfig<TaxableEntity>,
) => {
  const { data } = await api.get<TaxableEntity>(
    `taxable_entities/${taxableEntityId}`,
    config,
  );
  return data;
};
