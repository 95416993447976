import api from '@api';
import { useQuery } from 'react-query';
import { INVOICE_DOWNLOAD_LINK } from '@constants/reactQueries';

export default function useXmlDownloadLink(cfdiId) {
  const {
    data = '',
    isLoading,
    ...rest
  } = useQuery(INVOICE_DOWNLOAD_LINK, async () => {
    const response = await api.post(`/download`, {
      resource: 'cfdi',
      id: cfdiId,
      attribute: 'xml',
    });
    return response.data;
  });

  return { fileLoading: isLoading, fileLink: data, ...rest };
}
