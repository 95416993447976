import React, { Component } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import { Colxx, CustomSelect } from '@components';
import { Table, TableFilters } from '@components/DataTables';
import { DOCUMENT_CATEGORIES } from '@data/DocumentsData';

const columns = [
  {
    Header: 'Nombre',
    accessor: 'file_name',
    Cell: (props) => <p className="list-item-heading">{props.value}</p>,
  },
  {
    Header: 'Fecha de Creación',
    accessor: 'created_at',
    Cell: (props) => (
      <p className="list-item-heading">
        {new Date(props.value).toLocaleDateString()}
      </p>
    ),
  },
  {
    Header: 'Documento',
    accessor: 'attachment_url',
    Cell: (props) => (
      <a target="_blank" rel="noopener noreferrer" href={props.value || '#'}>
        <i className="iconsmind-Download mr-3 font-weight-bold" />
        Descargar
      </a>
    ),
  },
];

export default class DocumentsTable extends Component {
  state = { data: [], selectedCategory: null, filteredData: [] };

  handleCategoryChange = ({ value }) => {
    const { documents } = this.props;
    const data = documents.filter((doc) => doc.category.name === value);
    this.setState({ data });
  };

  componentDidMount() {
    const { documents } = this.props;
    this.setState({
      data: documents.filter(
        (document) => document.category.name !== 'compliance_opinion',
      ),
      filteredData: documents,
    });
  }

  handleSetFilteredData = (data, searchInput) => {
    const filteredData = data.filter((item) =>
      item.file_name.toLowerCase().includes(searchInput),
    );
    this.setState({ filteredData });
  };

  render() {
    const { title, loading, category, filterCategory } = this.props;
    if (loading) return <div className="loading" />;
    return (
      <Card className="mb-4">
        <CardBody>
          <DocumentsTableHeader
            category={category}
            title={title}
            selectedCategory={this.selectedCategory}
            handleCategoryChange={this.handleCategoryChange}
            handleSetFilteredData={this.handleSetFilteredData}
            data={this.state.data}
            filterCategory={filterCategory}
          />
          {!!this.state.filteredData.length && (
            <Table
              noDataText="Sube un documento de esta categoría para verlo reflejado en este espacio."
              defaultPageSize={10}
              data={this.state.filteredData}
              columns={columns}
            />
          )}
        </CardBody>
      </Card>
    );
  }
}

function DocumentsTableHeader({
  selectedCategory,
  handleCategoryChange,
  data,
  handleSetFilteredData,
  filterCategory = true,
}) {
  return (
    <Row className="d-flex align-content-center align-items-center justify-content-space-between mb-3">
      <Colxx xxs="1" xs="0" sm="2" md="1" lg="5">
        <h5>{selectedCategory?.label}</h5>
      </Colxx>
      <Colxx xxs="12" xs="12" sm="4" md="4" lg="3">
        {filterCategory && (
          <CustomSelect
            styles={{ 'font-size': '14.4px' }}
            value={selectedCategory}
            onChange={handleCategoryChange}
            options={DOCUMENT_CATEGORIES}
            placeholder="Categoría"
          />
        )}
      </Colxx>
      <Colxx xxs="12" xs="12" sm="6" md="6" lg="4">
        <TableFilters
          data={data}
          handleSetFilteredData={handleSetFilteredData}
          className="ml-auto search"
          name="searchInput"
          placeholder="Filtra por nombre de documento"
        />
      </Colxx>
    </Row>
  );
}
