import { Chip } from '@konta/ui';
import {
  Header,
  HeaderAvatar,
  HeaderDescription,
  HeaderInfo,
  HeaderTitle,
} from './styled';

export default function ChatHeader() {
  return (
    <Header>
      <HeaderAvatar maxInitials={2} name="Rosita" round />
      <HeaderInfo>
        <HeaderTitle>
          Rosita AI ✨ <Chip color="success100" label="Siempre en línea" />
        </HeaderTitle>
        <HeaderDescription>Secretaria</HeaderDescription>
      </HeaderInfo>
    </Header>
  );
}
