import { Dispatch, SetStateAction } from 'react';
import { SelectedCategory } from 'types/entities';

interface UseCfdisModalTableProps {
  periodDate: string | null;
  toggleIsOpen?: () => void;
  setSelectedPeriodDate?: Dispatch<SetStateAction<string | null>>;
  selectedCategory: SelectedCategory | null;
  setSelectedCategory: Dispatch<SetStateAction<SelectedCategory | null>>;
}
export default function useCfdisModalTable({
  toggleIsOpen,
  setSelectedPeriodDate,
  setSelectedCategory,
}: UseCfdisModalTableProps) {
  const onCloseModal = () => {
    toggleIsOpen?.();
    setSelectedPeriodDate?.(null);
    setSelectedCategory?.(null);
  };

  return {
    onCloseModal,
  };
}
