import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '@konta/ui';
import { declarationEntriesFetch } from '@redux/actions';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import useDeclarationEntries from '@hooks/useDeclarationEntries';
import ForeignDeclarationEntriesTable from '@components/ForeignDeclarationEntriesTable';
import NewForeignDeclarationEntryButton from '@components/NewForeignDeclarationEntryButton';

// PFAE AND PLATFORMS
const satKeysWithForeignInvoice = [612, 625];

export default function AddForeignExpense() {
  const dispatch = useDispatch();
  const { workflow } = useSelectedWorkflow();
  const { declaration_entries } = useDeclarationEntries();

  useEffect(() => {
    const workflowId = workflow?.id;
    if (workflowId) {
      dispatch(declarationEntriesFetch({ workflow_id: workflowId }));
    }
  }, [dispatch, workflow.id]);

  const foreignDeclarationEntries = useMemo(() => {
    return declaration_entries.filter((item) =>
      ['ForeignInvoice', 'ForeignProduct'].includes(item.source_type),
    );
  }, [declaration_entries]);

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Declarar gastos en el extranjero"
          description="Para poder deducir los gastos en el extranjero necesitamos tener los pedimentos de importación."
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton />
        </>
      }
    >
      <Flex direction="column" gap={20}>
        <Flex
          css={{
            gap: 10,
            flexDirection: 'column',
            '@md': { flexDirection: 'row' },
          }}
        >
          <NewForeignDeclarationEntryButton />
          {satKeysWithForeignInvoice.includes(
            workflow?.active_declaration?.fiscal_regime.sat_key,
          ) && <NewForeignDeclarationEntryButton type="foreign" />}
        </Flex>
        <ForeignDeclarationEntriesTable
          foreignDeclarationEntries={foreignDeclarationEntries}
        />
      </Flex>
    </WorkflowLayout>
  );
}
