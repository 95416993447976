import api from '@api';
import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';

import { subcriptionCancelSucess, subcriptionCancelFailure } from '../actions';

export default function* ({
  payload: { subscription_id, otherReason, cancellationReasons },
}) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `/subscriptions/${subscription_id}/unsubscribe_request`,
      {
        reason: cancellationReasons,
        comments: otherReason,
      },
      {
        headers: { Authorization: `${access_token}` },
      },
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: subcriptionCancelSucess,
      failure: subcriptionCancelFailure,
      message: 'Nos pondremos en contacto contigo!',
      title: 'Petición de cancelación exítosa',
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: subcriptionCancelFailure,
    });
  }
}
