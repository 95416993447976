import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Alert, Flex, Text } from '@konta/ui';
import { useMutation, useQueryClient } from 'react-query';
import FormikTextarea from '@components/FormikTextarea';
import FormikTextInput from '@components/FormikTextInput';
import { FOREIGN_PRODUCTS } from '@constants/reactQueries';
import { ProductUpload } from '@containers/Workflows/Phases/AddForeignExpence/ProductUpload';
import {
  declarationEntriesCreate,
  declarationEntriesUpdate,
} from '@redux/declaration_entries/actions';
import { useDispatch } from 'react-redux';
import useDeclarationEntries from '@hooks/useDeclarationEntries';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import { useToggle } from 'rooks';
import postForeignProduct from '@api/postForeignProduct';
import { NotificationManager } from '@components/Notifications';
import getErrorMessage from '@util/getErrorMessage';
import FormikKontaSelect from '../FormikKontaSelect';

const numberValidation = yup
  .number()
  .required('Campo requerido')
  .moreThan(-1, 'Debe ser mayor o igual a 0');

const customsValueValidation = yup
  .number()
  .required('Campo requerido')
  .moreThan(0, 'Debe ser mayor a 0');

const validationSchema = yup
  .object()
  .nullable()
  .shape({
    description: yup
      .string()
      .required('Campo requerido')
      .test({
        test: (value) => !(value || '').includes('|'),
        message: 'No se permite el caracter "|"',
      })
      .matches(/[^|]+$/g, 'No se permite el caracter "|"'),
    customs_value: customsValueValidation,
    dta: numberValidation,
    igi: numberValidation,
    prv: numberValidation,
    iva: numberValidation,
    iva_prv: numberValidation,
    file: yup.mixed().required('Documento requerido'),
    isFromWorkflow: yup.string().nullable(),
    fiscalRegime: yup
      .object()
      .nullable()
      .when('isFromWorkflow', {
        is: (value) => value === 'true',
        then: yup.object().nullable().required('Campo requerido'),
      }),
  });

function productToValues(product, isFromWorkflow, defaultRegimeOption) {
  if (product?.source?.foreign_product) {
    const { dta, igi, prv, iva, iva_prv, customs_value, documents } =
      product.source.foreign_product;

    return {
      description: product?.description,
      customs_value,
      dta,
      igi,
      prv,
      iva,
      iva_prv,
      file: documents,
    };
  }
  return {
    description: '',
    customs_value: 0,
    dta: 0,
    igi: 0,
    prv: 0,
    iva: 0,
    iva_prv: 0,
    file: null,
    fiscalRegime: defaultRegimeOption || null,
    isFromWorkflow,
  };
}

function valuesToPayload({
  foreignProduct,
  values,
  declaration,
  isFromWorkflow,
}) {
  const data = new FormData();
  const declarationId = declaration?.id;
  const sourceId = foreignProduct?.source_id;
  const id = foreignProduct?.id;
  const {
    file,
    customs_value,
    dta,
    igi,
    prv,
    iva,
    iva_prv,
    description,
    fiscalRegime,
  } = values;

  if (file && file instanceof File) {
    data.append(
      'declaration_entry[source_attributes][document_attributes][attachment]',
      file,
    );
    data.append(
      'declaration_entry[source_attributes][document_attributes][category_id]',
      '5',
    );
  }
  data.append('declaration_entry[accounting_status]', 'is_deductible');
  data.append(
    'declaration_entry[source_attributes][customs_value]',
    customs_value || '0',
  );
  data.append('declaration_entry[source_type]', 'ForeignProduct');
  data.append('declaration_entry[source_attributes][taxable_amount]', '0');
  data.append('declaration_entry[source_attributes][non_taxable_amount]', '0');
  data.append('declaration_entry[source_attributes][dta]', dta);
  data.append('declaration_entry[source_attributes][igi]', igi);
  data.append('declaration_entry[source_attributes][prv]', prv);
  data.append('declaration_entry[source_attributes][iva]', iva);
  data.append('declaration_entry[source_attributes][iva_prv]', iva_prv);
  data.append('declaration_entry[description]', description);
  data.append('declaration_entry[accounting_date]', declaration.start_date);
  data.append(
    'declaration_entry[source_attributes][period]',
    declaration.start_date,
  );
  data.append('declaration_entry[declaration_entry_type]', 'foreign_products');
  if (sourceId) {
    data.append('declaration_entry[source_attributes][id]', sourceId);
  }
  if (isFromWorkflow) {
    const fiscalRegimeDeclarationId = fiscalRegime.declarationId;
    data.append('declaration_entry[declaration_id]', fiscalRegimeDeclarationId);
  } else {
    data.append('declaration_entry[declaration_id]', declarationId);
  }

  return {
    body: data,
    ...(sourceId && {
      id: sourceId,
      declaration_entries_id: id,
    }),
  };
}

function valuesToForeignProductPayload({ values }) {
  const data = new FormData();
  const { file, dta, igi, prv, description, iva, iva_prv, customs_value } =
    values;

  if (file && file instanceof File) {
    data.append('document_attributes[attachment]', file);
    data.append('document_attributes[category_id]', '5');
  }

  data.append('description', description);
  data.append('non_taxable_amount', '0');
  data.append('taxable_amount', '0');
  data.append('customs_value', customs_value);
  data.append('dta', dta);
  data.append('igi', igi);
  data.append('prv', prv);
  data.append('iva', iva);
  data.append('iva_prv', iva_prv);
  data.append('period', dayjs().subtract(1, 'year').format('YYYY-MM-DD'));
  return data;
}

export default function ForeignProductForm({
  foreignProduct,
  onSubmit,
  innerRef,
  onLoading,
  readOnly,
  isAnnualProcess,
  isFromWorkflow = false,
  regimeOptions = [],
  defaultRegimeOption,
  taxableEntityPreferences,
}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { workflow } = useSelectedWorkflow();
  const { loading: isLoading } = useDeclarationEntries();

  const initialValues = useMemo(
    () => productToValues(foreignProduct, isFromWorkflow, defaultRegimeOption),
    [foreignProduct, isFromWorkflow, defaultRegimeOption],
  );

  const addNewDocument = (newDocument) => {
    queryClient.setQueryData([FOREIGN_PRODUCTS], (oldForeignDocuments) => {
      if (!oldForeignDocuments) return []; // only for ts validation
      return [...oldForeignDocuments, newDocument];
    });
  };

  const uploadForeignProduct = useMutation(postForeignProduct);

  const addAnnualForeignInvoice = async (values) => {
    try {
      onLoading(true);
      const payload = valuesToForeignProductPayload({
        foreignProduct,
        values,
        isAnnualProcess,
      });
      const response = await uploadForeignProduct.mutateAsync(payload);
      addNewDocument(response);
      onSubmit();
      NotificationManager.success(
        'Se ha guardo el pedimento de importación',
        'Factura subida',
        3000,
      );
    } catch (error) {
      const message = getErrorMessage(error);
      NotificationManager.error(message, 'Error', 10000);
    } finally {
      onLoading(false);
    }
  };

  const handleSubmit = (values) => {
    if (isAnnualProcess) {
      addAnnualForeignInvoice(values);
      return;
    }
    const declaration = workflow.active_declaration;
    const payload = valuesToPayload({
      foreignProduct,
      values,
      declaration,
      isFromWorkflow,
    });
    Object.assign(payload, {
      callback: onSubmit,
    });

    if (payload.id) {
      dispatch(declarationEntriesUpdate(payload));
    } else {
      dispatch(declarationEntriesCreate(payload));
    }
  };
  const isEditingMode = Boolean(foreignProduct);

  const fileName = useMemo(() => {
    const document = foreignProduct?.source?.foreign_product?.documents;
    if (document) {
      const url = new URL(document);
      const urlParams = new URLSearchParams(url?.search);
      const param = urlParams.get('response-content-disposition');
      const file = param?.split?.('filename')?.[1]?.split?.('"')?.[1];
      if (file) {
        return file;
      }
    }
    return '';
  }, [foreignProduct?.source?.foreign_product?.documents]);
  const [hasFile, toggleHasFile] = useToggle(!!fileName);

  useEffect(() => {
    onLoading(isLoading);
  }, [isLoading, onLoading]);

  const eventHandlers = {
    addedfile: (file) => {
      innerRef.current.setFieldValue('file', file);
    },
    removedfile: (file) => {
      const currentFile = innerRef.current.values.file;
      if (file.size === currentFile?.size && file.name === currentFile?.name) {
        innerRef.current.setFieldValue('files', null);
      }
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={innerRef}
    >
      {({ errors }) => (
        <Flex direction="column" gap={24} css={{ mt: '24px' }}>
          {isFromWorkflow && (
            <FormikKontaSelect
              name="fiscalRegime"
              label="Régimen Fiscal *"
              options={regimeOptions}
              isDisabled={
                readOnly || !!taxableEntityPreferences?.preferred_fiscal_regime
              }
            />
          )}
          <Flex gap={20}>
            <FormikTextarea
              rows={1}
              name="description"
              label="Descripción *"
              placeholder="Máximo 1000 caracteres"
              readOnly={readOnly}
            />
          </Flex>
          <Flex gap={20}>
            <FormikTextInput
              type="number"
              name="customs_value"
              label="Valor aduanal *"
              placeholder="Ej 200 pesos"
              readOnly={readOnly}
            />
          </Flex>
          <Flex gap={20}>
            <FormikTextInput
              type="number"
              name="dta"
              label="DTA *"
              placeholder="0.00"
              readOnly={readOnly}
            />
            <FormikTextInput
              type="number"
              name="igi"
              label="IGI/IGE *"
              placeholder="0.00"
              readOnly={readOnly}
            />
            <FormikTextInput
              type="number"
              name="prv"
              label="PRV *"
              placeholder="0.00"
              readOnly={readOnly}
            />
          </Flex>
          <Flex gap={20}>
            <FormikTextInput
              type="number"
              name="iva"
              label="IVA *"
              placeholder="0.00"
              readOnly={readOnly}
            />
            <FormikTextInput
              type="number"
              name="iva_prv"
              label="IVA/PVR *"
              placeholder="0.00"
              readOnly={readOnly}
            />
          </Flex>

          {isEditingMode && fileName && hasFile && (
            <Alert {...(!readOnly && { onClose: toggleHasFile })}>
              <b>Nombre del documento: </b>
              {fileName}
            </Alert>
          )}
          {!hasFile && !readOnly && (
            <Flex direction="column" gap={6} css={{ flex: 1 }}>
              <ProductUpload events={eventHandlers} />
              {errors.file && (
                <Text xs lineHeight="xs" color="error500">
                  {errors.file}
                </Text>
              )}
            </Flex>
          )}
        </Flex>
      )}
    </Formik>
  );
}

ForeignProductForm.propTypes = {
  foreignProduct: PropTypes.instanceOf(Object),
  // eslint-disable-next-line react/forbid-prop-types
  innerRef: PropTypes.any,
  onSubmit: PropTypes.func,
  onLoading: PropTypes.func,
  readOnly: PropTypes.bool,
};

ForeignProductForm.defaultProps = {
  foreignProduct: null,
  innerRef: null,
  onSubmit: null,
  onLoading: null,
  readOnly: false,
};
