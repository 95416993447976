import truncate from './truncate';

export default function formatNumber(
  value,
  { decimals = 2, round = true, currency = false, notation } = {},
) {
  if (typeof value !== 'number') {
    throw Error(`value "${value}" must be a number`);
  }

  if (typeof decimals !== 'number') {
    throw Error(`decimals "${decimals}" must be a number`);
  }

  const fixedValue = round ? value : truncate(value, decimals);

  // Create our number formatter.
  const formatter = new Intl.NumberFormat('es-MX', {
    style: currency ? 'currency' : undefined,
    currency: 'MXN',
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
    ...(!!notation && { notation }),
  });

  return formatter.format(fixedValue);
}
