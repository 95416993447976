import api from '@api';
import { Invitation } from 'types/entities';

export default async function postDiscardSuggestedReferrals(
  invitationId: number,
) {
  const { data } = await api.post<Invitation>(
    `/invitations/${invitationId}/discard`,
  );

  return data;
}
