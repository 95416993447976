import { NotificationManager } from '@components/Notifications';
import { PROCESSES_HOME_ROUTE } from '@constants/process';
import useProcess from '@hooks/useProcess';
import { Navigate } from 'react-router-dom';
import usePendingTasksStore from 'store/pendingTasksStore';
import { Process, ProcessType } from 'types/entities';

export default function ProcessModal() {
  const { selectedTask } = usePendingTasksStore();

  const isAnnualDeclaration = selectedTask?.type === 'annualDeclaration';

  const processType = !isAnnualDeclaration
    ? ProcessType.OnboardingOnboardingProcess
    : ProcessType.AnnualDeclarationProcess;

  const process = selectedTask?.extraDescription as Process;
  const { activeStepRoute } = useProcess(processType, undefined, process.id);

  const errorTitle = !isAnnualDeclaration
    ? 'Apertura de cuenta'
    : 'Declaración anual';

  if (process?.completed) {
    return <Navigate to={PROCESSES_HOME_ROUTE} />;
  }
  if (!activeStepRoute) {
    NotificationManager.error(
      'Ocurrió un error al abrir el proceso, por favor contacta a soporte.',
      errorTitle,
    );
    return null;
  }
  return <Navigate to={activeStepRoute} />;
}
