import { useSelector } from 'react-redux';
import { NotificationManager } from '@components';
import useUpdateTaxableEntityPreferencesMutation from '@hooks/useUpdateTaxableEntityPreferencesMutation';
import getErrorMessage from '@util/getErrorMessage';
import useTaxableEntityPreferences from '@hooks/useTaxableEntityPreferences';
import { CheckedState } from '@components/FormikCheckbox';
import type {
  TaxableEntityPreferencePayload,
  TaxableEntityPreference,
} from 'types/entities';
import type { IRootState } from 'types/redux';
import {
  Notification,
  NotificationWithEmailKey,
  NotificationWithWhatsappKey,
  TaxableEntityPreferenceKey,
} from 'types/entities';
import { useCallback } from 'react';

export default function useNotificationCenter() {
  const taxableEntity = useSelector(
    (state: IRootState) => state.taxableEntity.taxable_entity,
  );
  const taxableEntityId = taxableEntity?.id ?? 0;
  const {
    taxableEntityPreferences,
    refetch: refetchTxEntityPreferencesQuery,
    loadingTaxableEntityPreferences,
  } = useTaxableEntityPreferences(taxableEntityId);
  const updateTaxableEntityPreference =
    useUpdateTaxableEntityPreferencesMutation(taxableEntityId);

  const uploadTaxableEntityPreference = async (
    payload: TaxableEntityPreferencePayload,
  ) => {
    try {
      await updateTaxableEntityPreference.mutateAsync(payload);
      await refetchTxEntityPreferencesQuery();
      NotificationManager.success(
        'Se han actualizado las preferencias correctamente.',
        'Imagen de marca',
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e) as string;
      NotificationManager.error(errorMessage, 'Imagen de marca');
    }
  };

  const handleChange = (
    checked: CheckedState,
    preference: keyof TaxableEntityPreference,
  ) => {
    void uploadTaxableEntityPreference({
      [preference]: checked,
    });
  };

  const bulkHandleChange = (
    checked: CheckedState,
    preferences: (keyof TaxableEntityPreference)[],
  ) => {
    void uploadTaxableEntityPreference(
      preferences.reduce(
        (acc, preference) => ({
          ...acc,
          [preference]: checked,
        }),
        {},
      ),
    );
  };

  const getNotificationsUtils = useCallback(
    (notifications: Notification[]) => {
      const emailNotifications = notifications.filter(
        (notification): notification is NotificationWithEmailKey =>
          !!notification.emailKey,
      );
      const whatsappNotifications = notifications.filter(
        (notification): notification is NotificationWithWhatsappKey =>
          !!notification.whatsappKey,
      );

      const hasEmailNotifications = emailNotifications.length > 0;
      const hasWhatsappNotifications = whatsappNotifications.length > 0;

      const getCheckState = (
        key: 'whatsappKey' | 'emailKey',
        notificationList: Notification[],
      ) => {
        if (
          notificationList.every(
            ({ [key]: notificationKey }) =>
              !taxableEntityPreferences?.[
                notificationKey as TaxableEntityPreferenceKey
              ],
          )
        ) {
          return true;
        }
        if (
          notificationList.every(
            ({ [key]: notificationKey }) =>
              taxableEntityPreferences?.[
                notificationKey as TaxableEntityPreferenceKey
              ],
          )
        ) {
          return false;
        }
        return 'indeterminate';
      };

      const getEmailCheckState = () =>
        getCheckState('emailKey', emailNotifications);
      const getWhatsappCheckState = () =>
        getCheckState('whatsappKey', whatsappNotifications);

      return {
        emailNotifications,
        whatsappNotifications,
        hasEmailNotifications,
        hasWhatsappNotifications,
        emailCheckState: getEmailCheckState() as CheckedState,
        whatsappCheckState: getWhatsappCheckState() as CheckedState,
      };
    },
    [taxableEntityPreferences],
  );

  return {
    uploadTaxableEntityPreference,
    taxableEntityPreferences,
    handleChange,
    bulkHandleChange,
    loading:
      loadingTaxableEntityPreferences ||
      updateTaxableEntityPreference.isLoading,
    getNotificationsUtils,
  };
}
