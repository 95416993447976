import React, { useMemo } from 'react';
import { Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { Flex, Button, ModalContent, Text } from '@konta/ui';
import { useDispatch } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { declarationEntriesUpdate } from '@redux/actions';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import FormikTextInput from '@components/FormikTextInput';
import FormikTextarea from '@components/FormikTextarea';
import HelpCenterLink from '../HelpCenterLink';

const useInitialValues = (entry) => {
  const initialValues = useMemo(() => {
    return {
      userComments: '',
      isFixedAsset: entry?.newAccountingStatus === 'fixed_asset',
      depreciation_rate: '',
      depreciation_amount: '',
      depreciation_start_date: '',
    };
  }, [entry]);

  return initialValues;
};

const validationSchema = yup.object().shape({
  userComments: yup.string().nullable().required('Campo requerido'),
  isFixedAsset: yup.boolean(),
  depreciation_rate: yup
    .string()
    .nullable()
    .when('isFixedAsset', {
      is: (value) => value,
      then: yup.string().required('Campo requerido'),
    }),
  depreciation_amount: yup
    .string()
    .nullable()
    .when('isFixedAsset', {
      is: true,
      then: yup.string().required('Campo requerido'),
    }),
  depreciation_start_date: yup
    .string()
    .nullable()
    .when('isFixedAsset', {
      is: true,
      then: yup.string().required('Campo requerido'),
    }),
});

export default function EntryUpdateConfirmModal({
  toggle,
  isOpen,
  entry,
  onSuccess,
  withBlur = false,
  centered = false,
  updateCurrentWorkflow,
  updateWorkflowGraphs,
}) {
  const dispatch = useDispatch();
  const { workflow } = useSelectedWorkflow();
  const initialValues = useInitialValues(entry);

  const handleSubmit = (values, { resetForm }) => {
    const body = new FormData();
    if (entry?.newAccountingStatus) {
      body.append(
        'declaration_entry[accounting_status]',
        entry.newAccountingStatus,
      );
    }
    if (
      entry?.newAccountingStatus &&
      entry?.newAccountingStatus === 'fixed_asset'
    ) {
      body.append(
        'declaration_entry[depreciation_rate]',
        values.depreciation_rate,
      );
      body.append(
        'declaration_entry[depreciation_amount]',
        values.depreciation_amount,
      );
      body.append(
        'declaration_entry[depreciation_start_date]',
        values.depreciation_start_date,
      );
    }
    if (entry?.newDeclarationId) {
      body.append('declaration_entry[declaration_id]', entry.newDeclarationId);
    }
    body.append('declaration_entry[user_comments]', values.userComments);

    dispatch(
      declarationEntriesUpdate({
        workflow_id: workflow.id,
        declaration_entries_id: entry.id,
        body,
        callback: () => {
          onSuccess?.();
          updateCurrentWorkflow?.();
          updateWorkflowGraphs?.();
        },
      }),
    );
    toggle();
    resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true, // should reset the form if the wrapped component props change
    onSubmit: handleSubmit,
  });

  return (
    <Modal
      {...(withBlur && {
        wrapClassName: 'modal-backdrop-blur',
      })}
      centered={centered}
      toggle={() => {
        toggle();
        formik.resetForm();
      }}
      isOpen={isOpen}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        {entry?.modalHeader}
      </ModalHeader>
      <ModalContent>
        <FormikProvider value={formik}>
          <Flex column gap="10">
            <Text l bold>
              {entry?.title}
            </Text>
            <Text>{entry?.subtitle}</Text>
            <HelpCenterLink isTargetBlank />
            <Flex column gap="10">
              {entry.newAccountingStatus === 'fixed_asset' && (
                <Flex gap="10">
                  <FormikTextInput
                    name="depreciation_rate"
                    label="Tasa"
                    placeholder="Ej. 0.16"
                  />
                  <FormikTextInput
                    name="depreciation_amount"
                    label="Cantidad"
                    placeholder="Ej. 200"
                  />
                  <FormikTextInput
                    name="depreciation_start_date"
                    label="Fecha de inicio"
                    type="date"
                  />
                </Flex>
              )}
              <FormikTextarea
                name="userComments"
                rows="2"
                placeholder="Ej. No considerar porque..."
                label="Comentario *"
                labelHelpPopover={
                  <Text>
                    Usa este campo para decirle a tu contador algún detalle
                    extra o el motivo del cambio.
                  </Text>
                }
              />
            </Flex>
          </Flex>
        </FormikProvider>
      </ModalContent>
      <ModalFooter>
        <Button
          color="gray"
          variant="outlined"
          onClick={() => {
            toggle();
            formik.resetForm();
          }}
        >
          Cancelar
        </Button>
        <Button color="primary" onClick={() => formik.submitForm()}>
          Guardar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
