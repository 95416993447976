import { CSS, styled } from '@konta/ui';

export const flexColumBaseCss: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
};
export const FilterContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '6px',
  flex: '1 0 0',
  flexWrap: 'wrap',
});

export const HeaderContent = styled('div', {
  ...flexColumBaseCss,
  gap: '$12',
});
