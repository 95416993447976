import {
  NOTIFICATION_PREFERENCES_FETCH,
  NOTIFICATION_PREFERENCES_FETCH_SUCCESS,
  NOTIFICATION_PREFERENCES_FETCH_FAILURE,
  NOTIFICATION_PREFERENCES_UPDATE,
  NOTIFICATION_PREFERENCES_UPDATE_SUCCESS,
  NOTIFICATION_PREFERENCES_UPDATE_FAILURE,
} from '@constants/actionTypes';

const INITI_STATE = {
  notification_preferences: [],
  loading: false,
  error: null,
};

export default (state = INITI_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_PREFERENCES_FETCH:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_PREFERENCES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        notification_preferences: action.payload,
      };
    case NOTIFICATION_PREFERENCES_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case NOTIFICATION_PREFERENCES_UPDATE:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_PREFERENCES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notification_preferences: state.notification_preferences.map(
          (preference) =>
            preference.id === action.payload.id ? action.payload : preference
        ),
      };
    case NOTIFICATION_PREFERENCES_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};
