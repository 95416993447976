import {
  CHAT_GET_CONTACTS_END,
  CHAT_GET_CONTACTS_SUCCESS,
  CHAT_GET_CONTACTS_ERROR,
  CHAT_GET_CONVERSATIONS_END,
  CHAT_GET_CONVERSATIONS_SUCCESS,
  CHAT_GET_CONVERSATIONS_ERROR,
  CHAT_GET_CONVERSATIONS_BEGIN,
  CHAT_ADD_MESSAGE_TO_CONVERSATION_END,
  CHAT_ADD_MESSAGE_TO_CONVERSATION_BEGIN,
  CHAT_ADD_MESSAGE_TO_CONVERSATION_FAILURE,
  CHAT_CREATE_CONVERSATION,
  CHAT_SEARCH_CONTACT,
  CHAT_CHANGE_CONVERSATION,
  CHAT_GET_CONTACTS_BEGIN,
  CHAT_LOAD_NEXT_MESSAGES_BEGIN,
  CHAT_LOAD_NEXT_MESSAGES_SUCCESS,
  CHAT_LOAD_NEXT_MESSAGES_FAILURE,
  CHAT_LOAD_NEXT_MESSAGES_END,
} from '@constants/actionTypes';

const apiUrl = 'https://buhoapi.glitch.me';

export const getContacts = (uid) => {
  return (dispatch) => {
    dispatch(getContactsBegin());
    return fetch(`${apiUrl}/contacts`)
      .then((res) => res.json())
      .then((json) => {
        dispatch(getContactsSuccess(json));
      })
      .catch((e) => {
        dispatch(getContactsError());
      });
  };
};

export const getConversations = (uid) => {
  return (dispatch) => {
    dispatch(getConversationsBegin());
    return fetch(`${apiUrl}/messages/${uid}`)
      .then((res) => res.json())
      .then((json) => {
        dispatch(getConversationsSuccess(json));
      })
      .catch((e) => {
        dispatch(getConversationsError());
      });
  };
};

export const getContactsBegin = () => {
  return {
    type: CHAT_GET_CONTACTS_BEGIN,
  };
};

export const getContactsSuccess = (contacts, currentUser) => {
  return {
    type: CHAT_GET_CONTACTS_SUCCESS,
    payload: { contacts },
  };
};

export const getContactsError = () => ({
  type: CHAT_GET_CONTACTS_ERROR,
});

export const getContactsEnd = () => ({
  type: CHAT_GET_CONTACTS_END,
});

export const getConversationsBegin = () => ({
  type: CHAT_GET_CONVERSATIONS_BEGIN,
});
export const getConversationsSuccess = (conversations) => ({
  type: CHAT_GET_CONVERSATIONS_SUCCESS,
  payload: { conversations },
});

export const getConversationsError = () => ({
  type: CHAT_GET_CONVERSATIONS_ERROR,
});

export const getConversationsEnd = () => ({
  type: CHAT_GET_CONVERSATIONS_END,
});

export const addMessageToConversation = (msg) => {
  return (dispatch) => {
    dispatch(addMessageToConversationBegin());
    return fetch(`${apiUrl}/messages`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...msg }),
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getConversations(msg.sender_id));
        } else {
          dispatch(addMessageToConversationFailure());
          dispatch(addMessageToConversationEnd());
        }
      })
      .catch((e) => {
        dispatch(addMessageToConversationFailure());
        dispatch(addMessageToConversationEnd());
      });
  };
};

export const addMessageToConversationBegin = () => {
  return {
    type: CHAT_ADD_MESSAGE_TO_CONVERSATION_BEGIN,
  };
};

export const addMessageToConversationFailure = () => {
  return {
    type: CHAT_ADD_MESSAGE_TO_CONVERSATION_FAILURE,
  };
};

export const addMessageToConversationEnd = () => {
  return {
    type: CHAT_ADD_MESSAGE_TO_CONVERSATION_END,
  };
};

export const loadNextMessages = (uid, oldestTimestamp) => {
  return (dispatch) => {
    dispatch(loadNextMessagesBegin());
    return fetch(`${apiUrl}/messages/${uid}/${oldestTimestamp}`)
      .then((res) => res.json())
      .then((json) => {
        dispatch(loadNextMessagesSuccess(json));
      })
      .catch((e) => {
        dispatch(loadNextMessagesFailure());
      });
  };
};

export const loadNextMessagesBegin = () => {
  return {
    type: CHAT_LOAD_NEXT_MESSAGES_BEGIN,
  };
};

export const loadNextMessagesFailure = () => {
  return {
    type: CHAT_LOAD_NEXT_MESSAGES_FAILURE,
  };
};

export const loadNextMessagesEnd = () => {
  return {
    type: CHAT_LOAD_NEXT_MESSAGES_END,
  };
};

export const loadNextMessagesSuccess = (next10, height) => {
  return {
    type: CHAT_LOAD_NEXT_MESSAGES_SUCCESS,
    payload: {
      next10,
      lastRefreshed: new Date().getTime(),
    },
  };
};

export const createConversation = (
  currentUserId,
  selectedUserId,
  allConversations
) => {
  return {
    type: CHAT_CREATE_CONVERSATION,
    payload: { currentUserId, selectedUserId, allConversations },
  };
};

export const searchContact = (keyword) => ({
  type: CHAT_SEARCH_CONTACT,
  payload: keyword,
});

export const changeConversation = (userId) => ({
  type: CHAT_CHANGE_CONVERSATION,
  payload: userId,
});
