import { Navigate, Route, Routes } from 'react-router-dom';
import { useToggle } from 'usehooks-ts';
import { Modal } from '@konta/ui';
import FlowContainer from '@routes/app/flows/flow-container';
import Portal from '../Portal';
import Intro from './Intro';
import DeclarationInfo from './DeclarationInfo';
import IncomesByRegime from './IncomesByRegime';
import useCurrentWorkflowFromParam from './hooks/useCurrentWorkflowFromParam';
import ExpensesByRegime from './ExpensesByRegime';
import ResumeDeclaration from './ResumeDeclaration';
import CaptureLinePayment from './CaptureLinePayment';
import TaxesPayment from './TaxesPayment';

export default function Workflow() {
  const [open] = useToggle(true);
  const [showOldWorkflow, setShowOldWorkflow] = useToggle(false);

  useCurrentWorkflowFromParam();
  return (
    <Portal target="#root">
      <Modal
        open={open}
        size="full-screen"
        id="workflow-modal"
        css={{
          '@<sm': {
            pr: '$24',
          },
        }}
      >
        <Routes>
          <Route
            path="/default"
            // @ts-ignore FlowContainer is a js file
            element={
              <FlowContainer
                setShowOldWorkflow={setShowOldWorkflow}
                showOldWorkflow={showOldWorkflow}
              />
            }
          />
          <Route path="/intro" element={<Intro />} />
          <Route path="/declaration-info" element={<DeclarationInfo />} />
          <Route path="/incomes-by-regime" element={<IncomesByRegime />} />
          <Route path="/expenses-by-regime" element={<ExpensesByRegime />} />
          <Route path="/resume" element={<ResumeDeclaration />} />
          <Route
            path="/capture-line-payment"
            element={<CaptureLinePayment />}
          />
          <Route path="/taxes-payment" element={<TaxesPayment />} />
          <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
      </Modal>
    </Portal>
  );
}
