type User = (string | number)[];
type Key = 'email' | 'role' | 'id';
// TODO: Remove this after the migration to the new permissions type
export type OldPermission = {
  users: User;
  key: Key;
};
export const environmentPermissions: OldPermission = {
  users: [
    'beto.orozco@konta.com',
    'julio_denis@buhocontable.com',
    'oscar@buhocontable.com',
    'pablo@konta.com',
    'pablo@buhocontable.com',
    'backoffice@konta.com',
    'gabriela.avila@konta.com',
    'adrian.cantu@konta.com',
    'betsy.mayorga@buhocontable.com',
    'alejandro.prado@konta.com',
  ],
  key: 'email',
};

export type Permission = {
  values: User;
  key: Key;
};
export const forceRestartWorkflowPermissions: Permission = {
  values: [
    'oscar@konta.com',
    'jahir@buhocontable.com',
    'pedro@buhocontable.com',
    'orlando.morales@konta.com',
    'guadalupe.luna@konta.com',
    'francia.rebollar@konta.com',
    'alejandra.escobar@konta.com',
    'gabriela.serrano@konta.com',
    'betsy.mayorga@buhocontable.com',
    'alizon.gonzalez@konta.com',
  ],
  key: 'email',
};
