import { Content, Root } from './styled';
import ExpensesList from './ExpensesList';

export default function ClassifyExpenses() {
  return (
    <Root>
      <Content>
        <ExpensesList />
      </Content>
    </Root>
  );
}
