import {
  PRODUCTS_FETCH,
  PRODUCTS_FETCH_SUCCESS,
  PRODUCTS_FETCH_FAILURE,
  PRODUCTS_POST,
  PRODUCTS_POST_SUCCESS,
  PRODUCTS_POST_FAILURE,
  PRODUCTS_EDIT,
  PRODUCTS_EDIT_SUCCESS,
  PRODUCTS_EDIT_FAILURE,
  PRODUCTS_DELETE,
  PRODUCTS_DELETE_SUCCESS,
  PRODUCTS_DELETE_FAILURE,
} from '@constants/actionTypes';

export const fetchProducts = (taxable_entity) => ({
  type: PRODUCTS_FETCH,
  payload: { taxable_entity },
});
export const fetchProductsSuccess = (products) => ({
  type: PRODUCTS_FETCH_SUCCESS,
  payload: products,
});
export const fetchProductsFailure = (_) => ({
  type: PRODUCTS_FETCH_FAILURE,
});
export const postProducts = (taxable_entity, product) => ({
  type: PRODUCTS_POST,
  payload: { taxable_entity, product },
});
export const postProductsSuccess = (products) => ({
  type: PRODUCTS_POST_SUCCESS,
  payload: products,
});
export const postProductsFailure = (_) => ({
  type: PRODUCTS_POST_FAILURE,
});

export const editProducts = (taxable_entity, product, product_id) => ({
  type: PRODUCTS_EDIT,
  payload: { taxable_entity, product, product_id },
});
export const editProductsSuccess = (product) => ({
  type: PRODUCTS_EDIT_SUCCESS,
  payload: product,
});
export const editProductsFailure = (_) => ({
  type: PRODUCTS_EDIT_FAILURE,
});

export const deleteProduct = (taxable_entity, product_id) => ({
  type: PRODUCTS_DELETE,
  payload: { taxable_entity, product_id },
});
export const deleteProductSuccess = (product_id) => ({
  type: PRODUCTS_DELETE_SUCCESS,
  payload: product_id,
});
export const deleteProductFailure = (_) => ({
  type: PRODUCTS_DELETE_FAILURE,
});
