import { TAXE_IDS } from '@constants/invoicing';
import isGenericRfc from './isGenericRfc';
import isForeignRfc from './isForeignRfc';
import isGeneralPublicRfc from './isGeneralPublicRfc';
import toFixedString from './toFixedString';
import getUtcDateByServerDate from './getUtcDateByServerDate';

function parseConceptTax({ tax, base, satId }) {
  // exempt tax
  if (typeof tax.rate !== 'number') {
    return {
      base: toFixedString(base, 6),
      tax: satId,
      factor_type: 'Exento',
    };
  }

  return {
    base: toFixedString(base, 6),
    tax: satId,
    factor_type: 'Tasa',
    rate_or_fee: toFixedString(tax.rate, 6),
    import: toFixedString(tax.amount),
  };
}

function parseConceptTaxes({
  ieps,
  isr,
  iva,
  retainedIva,
  subtotal,
  taxableCode,
}) {
  const transferred = [];
  const detained = [];

  if (taxableCode.value !== '02') {
    return { transferred, detained };
  }

  // Only iva can be exent or zero
  if (iva) {
    const tax = parseConceptTax({
      base: subtotal,
      satId: TAXE_IDS.iva,
      tax: iva,
    });
    transferred.push(tax);
  }
  if (ieps?.rate > 0 && ieps?.amount > 0) {
    const tax = parseConceptTax({
      base: subtotal,
      satId: TAXE_IDS.ieps,
      tax: ieps,
    });
    transferred.push(tax);
  }

  if (isr?.rate > 0 && isr?.amount > 0) {
    const tax = parseConceptTax({
      base: subtotal,
      satId: TAXE_IDS.isr,
      tax: isr,
    });
    detained.push(tax);
  }

  if (retainedIva?.rate > 0 && retainedIva?.amount > 0) {
    const tax = parseConceptTax({
      satId: TAXE_IDS.iva,
      base: subtotal,
      tax: retainedIva,
    });
    detained.push(tax);
  }

  return { transferred, detained };
}

function parseConcepts(concepts) {
  return concepts.map((concept) => {
    const { detained, transferred } = parseConceptTaxes(concept);

    const parsedConcept = {
      taxable_code: concept.taxableCode.value, // Objeto importación
      prod_or_svc_key: concept.product?.value?.sat_product_id, // clave del sat
      identification_number: concept.product?.value?.product_id, // - interna - pendiente,
      quantity: String(concept.quantity),
      unit_measure_key: concept.unit?.value,
      unit_measure: concept.unit?.label, // hay que checar esto
      description: concept.product?.value?.description,
      unit_value: toFixedString(+concept.price, 6),
      amount: toFixedString(+concept.price * +concept.quantity, 6),
      discount: toFixedString(+concept.discount || 0, 6),
      detained_attributes: detained,
      transferred_attributes: transferred,
      property_account_attributes: concept.product?.value?.property_tax_account
        ? {
            number: concept.product.value.property_tax_account,
          }
        : undefined,
    };

    if (parsedConcept.detained_attributes.length === 0) {
      delete parsedConcept.detained_attributes;
    }
    if (parsedConcept.transferred_attributes.length === 0) {
      delete parsedConcept.transferred_attributes;
    }
    if (+parsedConcept.discount === 0) {
      delete parsedConcept.discount;
    }

    return parsedConcept;
  });
}

function getTaxRatings(tax, satId) {
  if (!tax) return [];
  return Object.entries(tax.rates).map(([rate, ratedTax]) => ({
    rate: rate === 'exempt' ? rate : +rate,
    satId,
    amount: ratedTax.amount,
    base: ratedTax.base,
  }));
}

function sumTaxes(taxes) {
  return taxes.reduce((accumulator, tax) => {
    return accumulator + tax.amount;
  }, 0);
}

function parseInvoiceTaxes({ iva, retainedIva, isr, ieps }) {
  const transferredTaxes = [
    ...getTaxRatings(iva, TAXE_IDS.iva),
    ...getTaxRatings(ieps, TAXE_IDS.ieps),
  ].filter((tax) => tax.base > 0);

  const transferredTotal = sumTaxes(transferredTaxes);
  const transferredAttributes = transferredTaxes.map((tax) => {
    const exempt = tax.rate === 'exempt';

    return {
      base: toFixedString(tax.base),
      tax: tax.satId,
      factor_type: exempt ? 'Exento' : 'Tasa',
      rate_or_fee: exempt ? undefined : toFixedString(tax.rate, 6),
      import: exempt ? undefined : toFixedString(tax.amount),
    };
  });

  const detainedTaxes = [
    isr?.base > 0 &&
      isr.amount > 0 && {
        tax: TAXE_IDS.isr,
        import: toFixedString(isr.amount, 2), // HERE: se está redondeando para abajo
      },
    retainedIva?.base > 0 &&
      retainedIva.amount > 0 && {
        tax: TAXE_IDS.iva,
        import: toFixedString(retainedIva.amount, 2),
      },
  ];
  const detainedTotal = (retainedIva?.amount ?? 0) + (isr?.amount ?? 0);
  const detainedAttributes = detainedTaxes.filter(Boolean);
  const taxes = {
    total_taxes_transferred: transferredTaxes.every(
      (tax) => tax.rate === 'exempt',
    )
      ? undefined
      : toFixedString(transferredTotal, 2),
    total_taxes_detained: toFixedString(detainedTotal, 2),
    transferred_attributes: transferredAttributes,
    detained_attributes: detainedAttributes,
  };

  if (taxes.total_taxes_detained === '0.00') {
    delete taxes.total_taxes_detained;
  }
  if (taxes.transferred_attributes.length === 0) {
    delete taxes.transferred_attributes;
  }
  if (taxes.detained_attributes.length === 0) {
    delete taxes.detained_attributes;
  }

  return taxes;
}
/**
 * @typedef { ReturnType<typeof invoiceFormToPayload> } InvoiceFormToPayload
 */
export default function invoiceFormToPayload({
  values,
  taxableEntity,
  serverDate,
}) {
  const date = getUtcDateByServerDate(values.createdDate, serverDate);
  const exportCode = values.exportCode.value;
  const paymentType = values.paymentType?.value;
  const concepts = parseConcepts(values.concepts);
  const taxes = parseInvoiceTaxes(values);

  // flags
  const isMxn = values.currency.value === 'MXN';
  const genericRfc = isGenericRfc(values.client?.value.rfc);
  const foreignRfc = isForeignRfc(values.client?.value.rfc);
  const generalPublicRfc = isGeneralPublicRfc(values.client?.value.rfc);

  // issuer attributes
  const fiscalRegime = values.fiscalRegime?.value;

  // receiver attributes
  const client = values.client?.value;
  const clientRegime = values.clientRegime?.value;
  const cfdiUsage = values.cfdiUsage?.value;
  const taxResidency = values.taxResidency?.value;
  const payload = {
    version: '4.0',
    'xsi:schemaLocation':
      'http://www.sat.gob.mx/cfd/4 http://www.sat.gob.mx/sitio_internet/cfd/4/cfdv40.xsd',
    date,
    export_code: exportCode,
    serie: values.orderNumber,
    folio_number: values.invoiceNumber,
    payment_way: paymentType,
    subtotal: toFixedString(values.subtotal),
    discount: values.discount ? toFixedString(values.discount) : undefined,
    total: toFixedString(values.total),
    currency: values.currency.value,
    exchange_rate: !isMxn ? Number(values.exchangeRate) : undefined,
    type_of_receipt: values.invoiceType.value,
    payment_method: values.paymentMethod.value,
    expedition_place: values.postcode,
    cfdi_related_attributes: values.creditNoteInvoice
      ? {
          relationship_type: '01',
          related_attributes: [
            {
              uuid: values.creditNoteInvoice.uuid,
            },
          ],
        }
      : undefined,
    issuer_attributes: {
      rfc: taxableEntity.rfc,
      name: taxableEntity.legal_name,
      fiscal_regime: fiscalRegime?.sat_key,
    },
    receiver_attributes: {
      rfc: client?.rfc,
      name: client?.legal_name,
      zip_code: genericRfc ? values.postcode : client?.address?.postcode,
      fiscal_regime: clientRegime?.sat_key,
      cfdi_usage: cfdiUsage,
      tax_residency: taxResidency,
      tax_identity_registration_number: foreignRfc
        ? values.taxIdentityRegistrationNumber
        : undefined,
    },
    concepts_attributes: concepts,
    taxes_attributes: taxes,
    global_info_attributes:
      generalPublicRfc && values.invoiceType.value !== 'E'
        ? {
            periodicity: values.periodicity?.value,
            months: values.periodicityMonth?.value,
            year: `${values.periodicityYear}`,
          }
        : undefined,
  };

  return payload;
}
