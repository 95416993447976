import {
  FISCAL_REGIMENS_FETCH,
  FISCAL_REGIMENS_FETCH_FAILURE,
  FISCAL_REGIMENS_FETCH_SUCCESS,
} from '@constants/actionTypes';

export const fiscalRegimensFetch = (_) => {
  return {
    type: FISCAL_REGIMENS_FETCH,
  };
};

export const fiscalRegimensFetchSuccess = (payload) => {
  return {
    type: FISCAL_REGIMENS_FETCH_SUCCESS,
    payload,
  };
};

export const fiscalRegimensFetchFailure = (payload) => {
  return {
    type: FISCAL_REGIMENS_FETCH_FAILURE,
    payload,
  };
};
