import React from 'react';
import dayjs from 'dayjs';
import { Flex, Button, DataTable } from '@konta/ui';
import { FileAddInLc1OutlineIcon } from '@konta/icons';
import { useToggle } from 'rooks';
import { useSelector } from 'react-redux';
import ConstanciesModalForm from '@containers/Workflows/ConstanciesModalForm';
import toCurrency from '@util/toCurrency';
import useDeclarationEntriesPagination from 'shared/hooks/useDeclarationEntriesPagination';

import WorkflowHeader from '@components/WorkflowHeader';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';

function ClassifyConstancies() {
  const [showModal, toggleShowModal] = useToggle();
  const {
    selected_workflow: { workflow },
  } = useSelector(({ workflows }) => workflows);

  const {
    declarationEntries,
    declarationEntriesLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    pages,
    onChange,
    pageSize,
    previousPage,
    setPage,
    setPageSize,
    refetch,
  } = useDeclarationEntriesPagination({
    params: {
      'q[declaration_is_active_true]': 1,
      'q[workflow_id]': workflow?.id,
      'q[declaration_entry_type_eq]': 'national_income',
      'q[source_type_eq]': 'Constancy',
      'q[accounting_date_gteq]': dayjs(workflow?.start_date)
        .startOf('month')
        .format('YYYY-MM-DD'),
      'q[accounting_date_lteq]': dayjs(workflow?.end_date)
        .endOf('month')
        .format('YYYY-MM-DD'),
    },
    itemsPerPage: 100,
    axiosConfig: {
      headers: {
        Authorization: localStorage.getItem('user_id'),
      },
    },
  });

  const data = React.useMemo(
    () =>
      declarationEntries.filter(
        (entry) =>
          entry.declaration_entry_type === 'national_income' &&
          entry.source.type === 'constancy',
      ),
    [declarationEntries],
  );

  const columns = React.useMemo(
    () => [
      {
        header: 'Empresa',
        accessorKey: 'social_reason',
        label: 'Empresa',
        cellClass: 'cell-small-text',
        width: 80,
        cell: ({ row }) => {
          const { social_reason } = row.original;
          return <p className="list-item-heading"> {social_reason} </p>;
        },
      },
      {
        header: 'IVA Transferido',
        label: 'IVA Transferido',
        accessorKey: 'source.constancy.total_iva_transferred',
        width: 90,
        cellClass: 'cell-small-text',
        cell: ({ row }) => {
          const { source } = row.original;
          return (
            <p className="list-item-heading">
              {toCurrency(+source.constancy.total_iva_transferred)}
            </p>
          );
        },
      },
      {
        header: 'IVA Retenido',
        label: 'IVA Retenido',
        accessorKey: 'source.constancy.total_iva_retained',
        width: 90,
        cellClass: 'cell-small-text',
        cell: ({ row }) => {
          const { source } = row.original;
          return (
            <p className="list-item-heading">
              {toCurrency(+source.constancy.total_iva_retained)}
            </p>
          );
        },
      },
      {
        header: 'ISR Retenido',
        label: 'ISR Retenido',
        accessorKey: 'source.constancy.total_isr_retained',
        width: 90,
        cellClass: 'cell-small-text',
        cell: ({ row }) => {
          const { source } = row.original;
          return (
            <p className="list-item-heading">
              {toCurrency(+source.constancy.total_isr_retained)}
            </p>
          );
        },
      },
      {
        header: 'Total de ingreso',
        label: 'Total de ingreso',
        accessorKey: 'source.constancy.total_income',
        cellClass: 'cell-small-text',
        width: 90,
        cell: ({ row }) => {
          const { source } = row.original;
          return (
            <p className="list-item-heading">
              {toCurrency(+source.constancy.total_income)}
            </p>
          );
        },
      },
    ],
    [],
  );

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Verifica que estas sean todas sus contancias de retención"
          description="Konta ya sincronizó automáticamente todas tus facturas del SAT, de
      igual manera se descargaron las constancias de retención que
      encontramos en el proceso. Por favor verifica que estos sean todas
      sus fuentes de ingresos."
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton />
        </>
      }
    >
      <Flex gap={10} direction="column">
        <div>
          <Button
            onClick={toggleShowModal}
            leftIcon={<FileAddInLc1OutlineIcon />}
            variant="outlined"
          >
            Agregar constancia de retención
          </Button>
        </div>
        <DataTable
          columns={columns}
          data={data}
          loading={declarationEntriesLoading}
          dataTableId="constancies-table"
          autoWidth
          manualPagination
          manualPaginationProps={{
            hasNextPage,
            hasPrevPage,
            nextPage,
            pages,
            onChange,
            pageSize,
            previousPage,
            setPage,
            setPageSize,
          }}
        />
      </Flex>
      <ConstanciesModalForm
        isOpen={showModal}
        toggle={toggleShowModal}
        workflow={workflow}
        refetchConstancies={refetch}
      />
    </WorkflowLayout>
  );
}

export default ClassifyConstancies;
