import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import { workflowsFetchSuccess, workflowsFetchFailure } from '../actions';

export default function* (payload) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(api.get, `/workflows`, {
      headers: { Authorization: `${access_token}` },
    });
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: workflowsFetchSuccess,
      failure: workflowsFetchFailure,
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: workflowsFetchFailure,
    });
  }
}
