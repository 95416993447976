import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { workflowSetTransition, workflowNextPhase } from '@redux/actions';
import { Text, Flex, Stepper, Step } from '@konta/ui';
import {
  Coins021OutlineIcon,
  CalculatorOutlineIcon,
  Export1OutlineIcon,
} from '@konta/icons';
import { useCounter } from 'rooks';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import VideoButton from '@components/VideoButton';

function IntroResico() {
  const dispatch = useDispatch();
  const {
    selected_workflow: { workflow },
  } = useSelector(({ workflows }) => workflows);

  const { value, increment, decrement } = useCounter(1);
  const primaryPhaseHandler = () => {
    dispatch(workflowSetTransition('phase-front'));
    const workflow_id = workflow.id;
    const body = {
      workflow: {
        next_phase: 'primary_phase',
      },
    };
    dispatch(workflowNextPhase({ workflow_id, body }));
  };

  return (
    <WorkflowLayout
      actions={
        <>
          {value !== 1 && (
            <PrevPhaseButton onClick={decrement}>
              Regresar a paso anterior
            </PrevPhaseButton>
          )}
          <PrimaryPhaseButton
            onClick={value === 3 ? primaryPhaseHandler : increment}
          >
            {value !== 3 ? 'Siguiente' : '¡Entendido!'}
          </PrimaryPhaseButton>
        </>
      }
    >
      <div className="main-title">
        <Flex direction="column" gap="16">
          <Text
            css={{ fontFamily: 'Inter', flexGrow: 1 }}
            x4l
            color="secondary70"
            bold
          >
            ¡Bienvenido!
          </Text>
          <Text css={{ fontFamily: 'Inter' }} l>
            A continuación te haremos algunas preguntas sobre tus{' '}
            <span className="font-weight-bold">ingresos</span> y{' '}
            <span className="font-weight-bold">gastos</span> con el fin de
            calcular correctamente tus{' '}
            <span className="font-weight-bold">impuestos</span> y realizar la
            declaración mensual.
          </Text>
        </Flex>
        <VideoButton videoId="YqRuPW4ZxPY" />
      </div>

      <Text css={{ fontFamily: 'Inter', mt: '$16' }} l medium>
        Resumen del proceso completo:
      </Text>
      <Flex>
        <Stepper value={value} variant="tab">
          <Step
            id={1}
            title="1.- Ingresos"
            description="En esta sección vas introducir los ingresos que tuviste en el mes y quieres declarar. "
          />
          <Step
            id={2}
            title="2.- Gastos"
            description="En esta sección vas a verificar las facturas de gastos que aplican para tu negocio."
          />
          <Step
            id={3}
            title="3.- Calcular tus impuestos"
            description="Calcularemos automáticamente los impuestos a pagar, si no hay nada que pagar, nos vemos el siguiente mes 😉"
          />
        </Stepper>
      </Flex>
      <Flex direction="column" gap={16}>
        {value === 1 && (
          <>
            <Text css={{ fontFamily: 'Inter', fontSize: '16px' }} medium>
              Puntos importantes a considerar en esta sección:
            </Text>

            <Coins021OutlineIcon className="icon-large" />

            <Text
              css={{ fontFamily: 'Inter', fontSize: '16px' }}
              color="secondary70"
              bold
            >
              No somos el SAT, somos tu aliado 🙂
            </Text>

            <Text
              color="neutral"
              css={{ fontFamily: 'Inter', fontSize: '16px' }}
              regular
            >
              Los ingresos que decidas aplicar como “No entran” no serán
              presentados ante el SAT, nosotros recomendamos declarar todo lo
              que representó un ingreso para tu negocio.
            </Text>

            <Text
              css={{ fontFamily: 'Inter', fontSize: '16px' }}
              color="secondary70"
              bold
            >
              No se presenta mientras vas avanzando en el proceso.
            </Text>
            <Text
              color="neutral"
              css={{ fontFamily: 'Inter', fontSize: '16px' }}
              regular
            >
              Conforme vayas haciendo el proceso no se presenta en tiempo real,
              toda la información nos sirve para construir tu declaración,
              revisarla y posteriormente presentarla ante el SAT.
            </Text>
          </>
        )}
        {value === 2 && (
          <>
            <Text css={{ fontFamily: 'Inter', fontSize: '16px' }} medium>
              Puntos importantes a considerar en esta sección.
            </Text>

            <Export1OutlineIcon className="icon-large" />

            <Text
              css={{ fontFamily: 'Inter', fontSize: '16px' }}
              color="secondary70"
              bold
            >
              Las facturas de gastos ya fueron pre clasificadas
            </Text>
            <Text
              color="neutral"
              css={{ fontFamily: 'Inter', fontSize: '16px' }}
              regular
            >
              Nosotros revisamos todas tus facturas y vemos cuales si son
              deducibles por el tipo de negocio que tienes. Cualquier factura
              que no entra tendrá un comentario con el motivo no es deducible.
            </Text>
          </>
        )}
        {value === 3 && (
          <>
            <Text css={{ fontFamily: 'Inter', fontSize: '16px' }} medium>
              Puntos importantes a considerar en esta sección.
            </Text>

            <CalculatorOutlineIcon className="icon-large" />

            <Text
              css={{ fontFamily: 'Inter', fontSize: '16px' }}
              color="secondary70"
              bold
            >
              Al finalizar revisaremos tu declaración
            </Text>
            <Text
              color="neutral"
              css={{ fontFamily: 'Inter', fontSize: '16px' }}
              regular
            >
              La información que nos proporcionas es para que nosotros podamos
              construir tu declaración. Una vez revisada, armaremos tu
              declaración y la presentaremos ante el SAT.
            </Text>
          </>
        )}
      </Flex>
    </WorkflowLayout>
  );
}

export default IntroResico;
