import {
  NOTIFICATION_PREFERENCES_FETCH,
  NOTIFICATION_PREFERENCES_FETCH_SUCCESS,
  NOTIFICATION_PREFERENCES_FETCH_FAILURE,
  NOTIFICATION_PREFERENCES_UPDATE,
  NOTIFICATION_PREFERENCES_UPDATE_SUCCESS,
  NOTIFICATION_PREFERENCES_UPDATE_FAILURE,
} from '@constants/actionTypes';

export const notificationPreferencesFetch = () => ({
  type: NOTIFICATION_PREFERENCES_FETCH,
});

export const notificationPreferencesFetchSuccess = (payload) => ({
  type: NOTIFICATION_PREFERENCES_FETCH_SUCCESS,
  payload,
});

export const notificationPreferencesFetchFailure = (payload) => ({
  type: NOTIFICATION_PREFERENCES_FETCH_FAILURE,
  payload,
});

export const notificationPreferencesUpdate = (payload) => ({
  type: NOTIFICATION_PREFERENCES_UPDATE,
  payload,
});

export const notificationPreferencesUpdateSuccess = (payload) => ({
  type: NOTIFICATION_PREFERENCES_UPDATE_SUCCESS,
  payload,
});

export const notificationPreferencesUpdateFailure = (payload) => ({
  type: NOTIFICATION_PREFERENCES_UPDATE_FAILURE,
  payload,
});
