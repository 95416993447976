import { ComponentProps, useMemo } from 'react';
import { useWindowSize } from 'rooks';
import { HelpCircleLineIcon } from '@konta/icons';
import { BarSeries, Flex, HoverCard, Text, Select, Box } from '@konta/ui';
import toCurrency from '@util/toCurrency';
import Icon from 'shared/components/Icon';
import type {
  AccumulatedIncomeAndExpenseFilters,
  ReactSelectItemProps,
} from 'types/entities';

interface LineSeriesProps {
  dataSet: ComponentProps<typeof BarSeries>[`dataSet`];
  isDesktop: boolean;
  id: string;
  margin?: { top: number; right: number; bottom: number; left: number };
  total: number;
  textTitleHover?: string;
  title: string;
  filterProps?: {
    options: ReactSelectItemProps<AccumulatedIncomeAndExpenseFilters>[];
    value: string;
    onChangeFilter: (filter: unknown) => void;
  };
  tooltip?: ComponentProps<typeof BarSeries>[`tooltip`];
  onChangeFilter?: (filter: string) => void;
}

export default function LineSeries({
  dataSet,
  isDesktop,
  id,
  margin = { top: 5, right: 0, bottom: 43, left: 55 },
  total,
  textTitleHover,
  title,
  filterProps,
  tooltip,
}: LineSeriesProps) {
  const { outerWidth } = useWindowSize();

  const width = useMemo(() => {
    if (isDesktop || !outerWidth) {
      return undefined;
    }

    if (outerWidth <= 991) {
      return outerWidth - 50;
    }

    return undefined;
  }, [isDesktop, outerWidth]);

  return (
    <Flex
      css={{
        flex: 1,
        overflow: 'auto',
        gap: 10,
        flexDirection: 'column',
      }}
    >
      <Flex column>
        <Flex itemsCenter justify="between">
          <Flex gap={6} itemsCenter>
            <Text color="gray700" medium s lineHeight="s">
              {title}
            </Text>
            {!!textTitleHover && (
              <HoverCard
                side="top"
                openDelay={0}
                triggerElement={
                  <Icon tabIndex={-1} role="button">
                    <HelpCircleLineIcon />
                  </Icon>
                }
              >
                {textTitleHover}
              </HoverCard>
            )}
          </Flex>
          {!!filterProps && (
            <Flex column itemsCenter>
              <Box css={{ height: '$4' }} />
              <Select
                onChange={(newValue) => {
                  if (filterProps?.onChangeFilter) {
                    filterProps.onChangeFilter(newValue);
                  }
                }}
                size="xs"
                options={filterProps.options}
                value={filterProps.options.find(
                  (opt) => opt.value === filterProps.value,
                )}
              />
              <Box css={{ height: '$4' }} />
            </Flex>
          )}
        </Flex>
        <Text color="gray900" medium l lineHeight="l">
          {toCurrency(total)} MXN
        </Text>
      </Flex>
      <BarSeries
        tooltip={tooltip}
        series="area"
        yNumberTicks={6}
        margin={margin}
        showTooltip
        dataSet={dataSet}
        height={270}
        id={id}
        width={width}
        paddingInner={0.4}
        nTicks={6}
        snapTooltipToDatumX
        snapTooltipToDatumY
        showVerticalCross
      />
    </Flex>
  );
}
