import { useCallback } from 'react';
import useTaxableEntity from '@hooks/useTaxableEntity';
import useUserAddress from '@hooks/useUserAddress';
import { Declaration } from 'types/entities';
import { generateWorkflowPublicInvoice } from '@util/Utils';
import parsePreviewPublicInvoice from '@util/parsePreviewPublicInvoice';

export default function usePreviewOfPublicInvoice() {
  const { address, addressLoading } = useUserAddress();
  const { taxable_entity, taxableEntityLoading } = useTaxableEntity();
  const previewLoading = addressLoading || taxableEntityLoading;

  const getDataForPreviewByDeclaration = useCallback(
    (declaration: Declaration) => {
      const publicInvoiceData = parsePreviewPublicInvoice({
        address,
        taxableEntity: taxable_entity,
        declaration,
      });

      return generateWorkflowPublicInvoice(publicInvoiceData);
    },
    [address, taxable_entity],
  );
  return {
    getDataForPreviewByDeclaration,
    previewLoading,
  };
}
