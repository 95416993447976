import React from 'react';

import { CardHeader } from '@components';

export function TaskNothing(props) {
  return (
    <>
      <CardHeader title="Tareas Pendientes" />
      <div className="price-top-part text-muted task-default">
        <i className="large-icon iconsmind-Coffee" />
        <p className="">Puedes relajarte, no tienes tareas pendientes.</p>
      </div>
    </>
  );
}
