import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import gtmEvent from '@util/gtmEvent';
import { stateReset } from '../actions';
import {
  shutdown as shutdownIntercom
} from "@util/Intercom"

export default function* ({ payload: { navigate } }) {
  try {
    navigate('/');
    localStorage.removeItem('user_id');
    yield put(stateReset());
    gtmEvent('AppEvent', 'logoutSuccess');
    Sentry.configureScope((scope) => {
      scope.clear();
    });
    shutdownIntercom()
  } catch (error) {
    // catch throw
    navigate('/');
    localStorage.removeItem('user_id');
    yield put(stateReset());
  }
}
