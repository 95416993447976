import React, { useState } from 'react';
import { Nav, TabContent, TabPane } from 'reactstrap';
import { ReturnButton, TabList, CardHeader } from '@components';
import { Codi, CaptureLine } from '@components/Payment';
import { usePaymentMethods, useFiscalPeriods } from '@hooks';
import PropTypes from 'prop-types';
import { PaymentCard } from './payment-cards';

function PaymentTabs({
  tabs = [],
  children,
  cardTitle,
  handleSubmit,
  handleReturn = false,
  defaultActiveTab = '1',
  displayFiscalPeriodInvoice,
  floading,
  handlePayment,
  tabChangeButton,
  payButton,
  filter = false,
  selectedFiscalPeriod,
}) {
  const [activeTab, setActive] = useState(defaultActiveTab);
  const { ui, loading } = usePaymentMethods();
  const { current, loading: fpLoading } = useFiscalPeriods();
  return (
    <>
      <ReturnButton handleReturn={handleReturn} />{' '}
      {/* Buttons that execute the handleReturn function, if undefined, it won't appear */}
      {
        cardTitle && (
          <CardHeader title={cardTitle} />
        ) /* Display card title if card title is defined */
      }
      <Nav className={`nav-pills mb-4 ${tabs.length ? '' : 'd-none'}`}>
        <TabList
          tabs={tabs}
          activeTab={activeTab}
          toggleTab={setActive}
          outlinedTabs
        />
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {/* PaymentCard  */}
          <PaymentCard
            ui={ui}
            displayFiscalPeriodInvoice={displayFiscalPeriodInvoice}
            loading={loading}
            children={children}
            handleSubmit={handleSubmit}
            handleTabChange={setActive}
            floading={floading}
            payButton={payButton}
            handlePayment={handlePayment}
            tabChangeButton={tabChangeButton}
            fpIsExpired={
              new Date(selectedFiscalPeriod?.validation_date) < new Date()
            }
            filter={filter}
          />
        </TabPane>
        <TabPane tabId="2">
          <CaptureLine
            loading={fpLoading}
            fiscal_period={selectedFiscalPeriod}
          />
        </TabPane>
        <TabPane tabId="3">{activeTab === '3' && <Codi />}</TabPane>{' '}
        {/* Only render if tab is active, since QR affects performance */}
      </TabContent>
    </>
  );
}

PaymentTabs.propTypes = {
  cardTitle: PropTypes.any,
  children: PropTypes.any,
  handleReturn: PropTypes.any,
  handleSubmit: PropTypes.any,
  tabs: PropTypes.array,
};

export default PaymentTabs;
