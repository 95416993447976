import { call, put, select } from 'redux-saga/effects';
import { ErrorHandler, StatusHandler } from '@util/Saga';
import api from '@api';
import {
  uploadFiscalPeriodReceiptSuccess,
  uploadFiscalPeriodReceiptFailure,
  fetchCurrentFiscalPeriod,
} from '../actions';

export default function* ({ payload: { statement, fiscal_period_id } }) {
  try {
    const {
      taxable_entity: { current_fiscal_period },
    } = yield select((state) => state.taxableEntity);
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.put,
      `/fiscal_periods/${fiscal_period_id}/upload_receipt`,
      statement,
      { headers: { Authorization: `${access_token}` } }
    );
    yield call(StatusHandler, {
      response,
      status: response.status,
      success: uploadFiscalPeriodReceiptSuccess,
      failure: uploadFiscalPeriodReceiptFailure,
      title: 'Éxito',
      message: 'El periodo fiscal se actualizó',
    });
    if (current_fiscal_period === fiscal_period_id)
      yield put(fetchCurrentFiscalPeriod());
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: uploadFiscalPeriodReceiptFailure,
    });
  }
}
