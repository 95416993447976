import { CSS, Flex, Text } from '@konta/ui';
import dayjs from 'dayjs';
import toCurrency from '@util/toCurrency';

interface ListInformationTextProps {
  label?: string;
  value: string | number;
  isCurrency?: boolean;
  isUnderline?: boolean;
  isDate?: boolean;
  format?: string;
  css?: CSS;
  currency?: string;
}
function getValue({
  value,
  isDate,
  isCurrency,
  format = 'DD-MM-YYYY',
  currency,
}: ListInformationTextProps) {
  if (isDate) {
    const date = dayjs(value);
    return date.isValid() ? date.format(format) : '-';
  }
  if (isCurrency) {
    return `${toCurrency(+value)} ${currency || 'MXN'}`;
  }
  return value;
}
export default function ListInformationText({
  label,
  value,
  isCurrency,
  isUnderline,
  isDate,
  css = {},
  format,
  currency,
}: ListInformationTextProps) {
  return (
    <Flex gap={4} css={css}>
      {label && (
        <Text s lineHeight="s" color="gray500">
          {label}:
        </Text>
      )}
      <Text
        s
        lineHeight="s"
        color="gray400"
        {...(isUnderline && { underline: true })}
      >
        {getValue({ value, isDate, isCurrency, format, currency })}
      </Text>
    </Flex>
  );
}
