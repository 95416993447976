import api from '@api';

export default async function putClient({ taxableId, client }) {
  const token = localStorage.getItem('user_id');
  const { data } = await api.put(
    `taxable_entities/${taxableId}/client_suppliers/${client.id}`,
    {
      client_supplier: client,
    },
    {
      headers: { Authorization: token },
    },
  );

  return data;
}
