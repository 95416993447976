import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useToggle } from 'rooks';
import { Row, Collapse } from 'reactstrap';
import { Eye2Icon } from '@konta/icons';
import {
  Text,
  Table,
  Cell,
  Row as KontaRow,
  TableBody,
  TableHead,
  HeaderCell,
  Button as KontaButton,
} from '@konta/ui';
import { Colxx } from '@components';
import { getMonthAndYearDate } from '@util/Utils';
import toCurrency from '@util/toCurrency';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowLayout from '@components/WorkflowLayout';
import WorkflowHeader from '@components/WorkflowHeader';

function TextExpences({ workflow }) {
  const { total_income, declaration_income, iva_rate } =
    workflow.active_declaration;

  const [incomeSummaryCollapseOpen, setIncomeSummaryCollapseOpen] =
    useToggle(false);

  const entries = useMemo(
    () => [
      {
        id: 1,
        name: 'Total de ingresos',
        subtotal: declaration_income.total,
        tax: Number(declaration_income.total_iva_bases) * Number(iva_rate),
        total:
          Number(declaration_income.total) +
          Number(declaration_income.total_iva_bases) * Number(iva_rate),
        bold: true,
      },
      {
        id: 2,
        name: 'Ingresos al 16% o 8%',
        subtotal: declaration_income?.total_iva_bases,
        tax: Number(declaration_income.total_iva_bases) * Number(iva_rate),
        total:
          Number(declaration_income.total_iva_bases) +
          Number(declaration_income.total_iva_bases) * Number(iva_rate),
      },
      {
        id: 3,
        name: 'Ingresos al 0% y exento',
        subtotal:
          Number(declaration_income.total_exempt_bases) +
          Number(declaration_income.total_zero_bases),
        tax: 0,
        total:
          Number(declaration_income.total_exempt_bases) +
          Number(declaration_income.total_iva) +
          Number(declaration_income.total_zero_bases),
      },
    ],
    [declaration_income, iva_rate],
  );

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Resumen de Ingresos"
          description="Todos los ingresos deben de ser deducibles."
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton>Sí, continuar</PrimaryPhaseButton>
        </>
      }
    >
      <div className="d-flex align-items-center">
        <div>
          <div className="w-80 w-sm-100">
            <Text as="h1" x3l>
              ¡Excelente! 🚀 tus ingresos del mes de{' '}
              {getMonthAndYearDate(new Date(workflow.start_date))} fueron{' '}
              {toCurrency(+total_income)} pesos
              {declaration_income?.total_iva_bases > 0 && ' + IVA'}. Ahora
              procederemos a declarar tus gastos. ¿Estás listo?
            </Text>
            <KontaButton
              color="neutral"
              css={{ mb: '1rem' }}
              leftIcon={<Eye2Icon />}
              variant={incomeSummaryCollapseOpen ? 'contained' : 'outlined'}
              onClick={setIncomeSummaryCollapseOpen}
            >
              Ver desglose de ingresos
            </KontaButton>
            <Collapse isOpen={incomeSummaryCollapseOpen}>
              <Row className="mb-3">
                <Colxx md="9">
                  <Table>
                    <TableHead>
                      <KontaRow>
                        <HeaderCell />
                        <HeaderCell>Base</HeaderCell>
                        <HeaderCell>Impuesto</HeaderCell>
                        <HeaderCell>Total</HeaderCell>
                      </KontaRow>
                    </TableHead>
                    <TableBody>
                      {entries.map((item) => (
                        <KontaRow key={item.id}>
                          <Cell>
                            <Text bold={item.bold}>{item.name}</Text>
                          </Cell>
                          <Cell right>
                            <Text bold={item.bold}>
                              {toCurrency(+item.subtotal)}
                            </Text>
                          </Cell>
                          <Cell right>
                            <Text bold={item.bold}>
                              {toCurrency(+item.tax)}
                            </Text>
                          </Cell>
                          <Cell right>
                            <Text bold={item.bold}>
                              {toCurrency(item.total)}
                            </Text>
                          </Cell>
                        </KontaRow>
                      ))}
                    </TableBody>
                  </Table>
                </Colxx>
              </Row>
            </Collapse>
          </div>
        </div>
      </div>
    </WorkflowLayout>
  );
}
const mapStateToProps = ({ workflows }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { workflow, wloading };
};
export default connect(mapStateToProps)(TextExpences);
