import { useEffect } from 'react';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import { Button } from '@konta/ui';
import { ButtonProps } from '@konta/ui/dist/components/Button/Button';
import useProcess from '@hooks/useProcess';
import { ProcessType } from 'types/entities';
import { FileWithPath } from 'react-dropzone';

interface BankStatement {
  name: string;
  contents: string | null;
}

interface NextStepButtonProps extends ButtonProps {
  payload:
    | boolean
    | Record<string, string | [] | number | null>
    | undefined
    | string
    | FileWithPath
    | null;
  extraPayload?: Record<
    string,
    string | [] | number | null | File | BankStatement
  >;
  text?: string;
  currentProcessType: ProcessType;
}

export default function NextStepButton({
  payload,
  text = 'Continuar',
  onClick,
  currentProcessType,
  extraPayload,
  ...props
}: Omit<NextStepButtonProps, 'children'>) {
  const { next, activeStep, updatingProcess } = useProcess(currentProcessType);

  useEffect(() => {
    const activeStepId = activeStep?.id;
    if (payload && activeStepId) {
      void next({
        steps_attributes: [
          { id: activeStepId, answers: payload, ...extraPayload },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Button
      {...props}
      onClick={onClick}
      color="primary600"
      rightIcon={<ArrowRightIcon />}
      loading={updatingProcess || props.loading}
    >
      {text}
    </Button>
  );
}
