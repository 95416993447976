import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { workflowUpdate, workflowSetTransition } from '@redux/actions';

import { Default } from './HelpViews';
import {
  AddForeignExpence,
  CaptureLinePayment,
  ComputeResult,
  CreateForignAndPublicInvoice,
  CreateMisingPublicInvoice,
  EntriesSummary,
  ExpencesClassification,
  NotPlatformIncome,
  PaperWork,
  PendingDeclaration,
  QuForeignExpences,
  TaxesPayment,
  TextExpences,
  WorkflowEnd,
  WorkflowIntro,
  ConstancySummary,
  IncomeClassification,
  NotCsdUploadInvoice,
  TotalConstancyExceeded,
} from './HelpViews/Workflows';

function HelpCenter({ workflow }) {
  const location = useLocation();

  const getWorkflowsViews = () => {
    switch (workflow.current_phase_id) {
      case 2:
        return <WorkflowIntro />;
      case 3:
        return <PaperWork />;
      case 4:
        // return NotPlatformIncome
        return <ConstancySummary />;
      case 6:
        return <IncomeClassification />;
      case 7:
        return <NotPlatformIncome />;
      case 8:
        return <CreateForignAndPublicInvoice />;
      case 9:
        return <TotalConstancyExceeded />;
      case 10:
        return <CreateMisingPublicInvoice />;
      case 11:
        return <TextExpences />;
      case 12:
        return <QuForeignExpences />;
      case 13:
        return <AddForeignExpence />;
      case 14:
        return <ExpencesClassification />;
      case 15:
        return <EntriesSummary />;
      case 16:
        return <ComputeResult />;
      case 17:
        return <PendingDeclaration />;
      case 19:
        return <CaptureLinePayment />;
      case 20:
        return <TaxesPayment />;
      case 21:
        return <WorkflowEnd />;
      case 23:
        return <NotCsdUploadInvoice />;
      default:
        return <Default />;
    }
  };

  const getViewPageHelp = () => {
    if (location.pathname.includes('pendientes')) {
      // TODO THIS ROUTER DOES NOT EXIST, THIS BE A PROBLEM, NEEDS FIXED
      if (location.pathname.includes('declaracion')) {
        return getWorkflowsViews();
      }
      return <Default />;
    }
    if (location.pathname.includes('pendientes')) {
      return <Default />;
    }
    if (location.pathname.includes('invoicing')) {
      return <Default />;
    }
    if (location.pathname.includes('reports')) {
      return <Default />;
    }
    if (location.pathname.includes('documents')) {
      return <Default />;
    }
    if (location.pathname.includes('settings')) {
      return <Default />;
    }
    return <Default />;
  };

  return <>{getViewPageHelp()}</>;
}

const mapStateToProps = ({ workflows }) => {
  const {
    selected_workflow: { workflow },
  } = workflows;
  return { workflow };
};
export default connect(mapStateToProps, {
  workflowUpdate,
  workflowSetTransition,
})(HelpCenter);
