import api from '@api';
import type { AxiosRequestConfig } from 'axios';
import type { ClientSupplier } from 'types/entities';

// eslint-disable-next-line import/prefer-default-export
export const getClientSuppliers = async (
  taxableEntityId: string | number,
  config?: AxiosRequestConfig<ClientSupplier[]>,
) => {
  const { data } = await api.get<ClientSupplier[]>(
    `taxable_entities/${taxableEntityId}/client_suppliers`,
    config,
  );
  return data;
};
