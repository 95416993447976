import { AxiosRequestConfig } from 'axios';
import api from '@api';
// TYPES
import {
  Declaration,
  DeclarationUpdatePayload,
  RecomputeDeclarationPayload,
  DeclarationPaymentPayload,
  SubmitDeclarationPayload,
  CreateComplementaryDeclarationPayload,
} from 'types/entities';

// eslint-disable-next-line import/prefer-default-export
export const getDeclarations = async (
  config?: AxiosRequestConfig<Declaration[]>,
) => {
  const { data } = await api.get<Declaration[]>(`declarations`, config);
  return data;
};

export const getRecomputeDeclaration = async (
  { declarationId }: RecomputeDeclarationPayload,
  config?: AxiosRequestConfig<Declaration>,
) => {
  const { data } = await api.get<Declaration>(
    `declarations/${declarationId}/recompute_totals`,
    config,
  );
  return data;
};

export const putDeclaration = async (
  { declarationId, payload }: DeclarationUpdatePayload,
  config?: AxiosRequestConfig<Declaration>,
) => {
  const { data } = await api.put<Declaration>(
    `declarations/${declarationId}`,
    payload,
    config,
  );
  return data;
};

export const putUploadDeclarationPayment = async (
  { declarationId, payload }: DeclarationPaymentPayload,
  config?: AxiosRequestConfig<FormData | void>,
) => {
  await api.post(
    `declarations/${declarationId}/upload_payment_document`,
    payload,
    config,
  );
};

export const postSubmitDeclaration = async (
  { declarationId, payload }: SubmitDeclarationPayload,
  config?: AxiosRequestConfig<void>,
) => {
  const { data } = await api.post<void>(
    `declarations/${declarationId}/submit`,
    payload,
    config,
  );
  return data;
};

export const getDeclarationConsumers = async (
  declarationId: number,
  config?: AxiosRequestConfig<Declaration[]>,
) => {
  const { data } = await api.get<Declaration[]>(
    `declarations/${declarationId}/consumers`,
    config,
  );
  return data;
};

export const postCreateComplementaryDeclaration = async (
  { declarationId, payload }: CreateComplementaryDeclarationPayload,
  config?: AxiosRequestConfig<Declaration>,
) => {
  const { data } = await api.post<Declaration>(
    `declarations/${declarationId}/create_complementary`,
    payload,
    config,
  );
  return data;
};
