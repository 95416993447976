import React, { useState } from 'react';

import { Card, CardBody, CardTitle } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { FeedbackForm } from '@components';

import { Loader } from '@components/Utils/Loading';
import { postFeedback } from '@redux/actions';
import { useTaxableEntity } from '@hooks';

export default function FpFeedback({ loading, fiscal_period_id }) {
  const {
    taxable_entity: { id, legal_name },
  } = useTaxableEntity();
  const [rating, setRating] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = (event, values, errors) => {
    console.log(values, rating);
  };
  const onValidSubmit = (event, values, errors) => {
    const feedback = {
      comment: values.additional_comments,
      star_rating: rating,
      taxable_entity_id: id,
      legal_name,
      fiscal_period_id,
    };
    process.env.REACT_APP_ENV === 'PRODUCTION' && dispatch(postFeedback(feedback));
  };

  return (
    <Card
      className="border-top-2 border-primary justify-content-center align-items-center border-primary"
      outline
      color="primary"
    >
      <Loader loading={loading}>
        <CardBody>
          <CardTitle tag="h4">
            ¿Como calificarías a Konta durante este periodo fiscal?
          </CardTitle>
          <FeedbackForm
            handleSubmit={handleSubmit}
            onValidSubmit={onValidSubmit}
            loading={loading}
            rating={rating}
            setRating={setRating}
          />
        </CardBody>
      </Loader>
    </Card>
  );
}
