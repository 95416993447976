import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { AccumulatedIncomeAndExpense } from 'types/entities';
import { getAnnualAccumulatedIncomesAndExpenses } from '@api/accumulatedIncomesAndExpenses';
import { ANNUAL_ACCUMULATED_INCOMES_AND_EXPENSES } from '@constants/reactQueries';

export default function useAnnualAccumulatedIncomesAndExpenses(
  taxableEntityId: number | string,
  axiosConfig?: AxiosRequestConfig<AccumulatedIncomeAndExpense>,
  queryOptions?: UseQueryOptions<AccumulatedIncomeAndExpense>,
) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<AccumulatedIncomeAndExpense>(
    [ANNUAL_ACCUMULATED_INCOMES_AND_EXPENSES, taxableEntityId, axiosConfig],
    async () => {
      const annualAccumulatedIncomesAndExpenses =
        await getAnnualAccumulatedIncomesAndExpenses(
          taxableEntityId,
          axiosConfig,
        );
      return annualAccumulatedIncomesAndExpenses;
    },
    queryOptions,
  );

  return {
    annualAccumulatedIncomesAndExpensesLoading: isLoading || isFetching,
    annualAccumulatedIncomesAndExpenses: data,
    ...rest,
  };
}
