import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LetterAvatar from 'react-avatar';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Flex, Text, useMediaQuery, config, styled } from '@konta/ui';
import { Logout2Icon } from '@konta/icons';
import { useQueryClient } from 'react-query';
import { logout } from 'backoffice/util/auth';
import useSignIn from 'backoffice/hooks/useSignIn';
import { useAppContext } from 'backoffice/context/AppContext';

const StyledRoot = styled(Flex, {
  padding: '0px',
  height: '67px',
  borderBottom: '1px solid rgb(239, 241, 244)',
  minHeight: '67px',
});

export default function TopNav() {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { menu } = useAppContext();
  const title = useMemo(
    () => menu.titlesAndPaths.find((item) => item.to === pathname)?.title,
    [pathname, menu.titlesAndPaths],
  );

  const isDesktop = useMediaQuery(config.media.sm);

  const handleLogout = () => {
    logout(navigate, queryClient);
  };

  const { user } = useSignIn({ enabled: false });
  const avatarName = user.first_name || user.nickname || user.email;
  return (
    <StyledRoot justify="between">
      <div className="navbar-title w-50 d-flex align-items-center">
        <Flex
          css={{
            mt: '8px',
            mb: '4px',
            flexDirection: isDesktop ? 'row' : 'column',
            gap: 4,
          }}
        >
          <Text bold>{title || 'App'}</Text>
        </Flex>
      </div>
      <div className="navbar-left">
        <div className="sidebar-header-profile-container">
          <div className="sidebar-header-profile">
            <UncontrolledDropdown>
              <DropdownToggle caret className="sidebar-header-profile-button">
                <span className="sidebar-header-profile-image-container">
                  <LetterAvatar
                    maxInitials={2}
                    name={avatarName}
                    size="22"
                    round
                  />
                </span>
              </DropdownToggle>
              <DropdownMenu className="sidebar-header-dropdown-menu">
                <DropdownItem header className="sidebar-header-dropdown-item">
                  <div className="sidebar-header-dropdown-item-text">
                    {avatarName}
                  </div>
                </DropdownItem>
                <DropdownItem divider />
                {/* <NavLink to="/app/settings">
                  <DropdownItem className="sidebar-header-dropdown-item">
                    <SettingsIcon className="sidebar-header-dropdown-item-icon" />
                    <div className="sidebar-header-dropdown-item-text">
                      Configuración
                    </div>
                  </DropdownItem>
                </NavLink> */}
                <DropdownItem divider />
                <DropdownItem
                  className="sidebar-header-dropdown-item"
                  onClick={handleLogout}
                >
                  <Logout2Icon className="sidebar-header-dropdown-item-icon" />
                  <div className="sidebar-header-dropdown-item-text">
                    Cerrar sesión
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
    </StyledRoot>
  );
}
