import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useTaxableEntity } from '@hooks';
import { Colxx, UploadFiel, UploadCiec, UploadCsd } from '@components';
import { Card, CardBody, Row, Button, Collapse } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import {
  uploadTaxableEntityCsd,
  updateTaxableEntity,
  fetchTaxableEntityCredentials,
} from '@redux/actions';
import HelpCenterLink from '@components/HelpCenterLink';

function SettingCredentials(props) {
  const dispatch = useDispatch();
  const { taxable_entity } = useTaxableEntity();
  useEffect(() => {
    dispatch(fetchTaxableEntityCredentials(taxable_entity));
  }, [
    (taxable_entity.has_ciec, taxable_entity.has_csd, taxable_entity.has_fiel),
  ]);

  const isExpired = useMemo(() => {
    const csdExpirationDate = taxable_entity.csd_expiration_date;
    if (csdExpirationDate && dayjs(csdExpirationDate) < dayjs()) {
      return true;
    }
    return false;
  }, [taxable_entity]);

  const userCredentials = Object.entries(taxable_entity).reduce(
    (acum, [key, value]) => {
      if (key === 'has_csd' || key === 'has_ciec' || key === 'has_fiel') {
        acum = {
          ...acum,
          [key]: value,
        };
      }
      return acum;
    },
    [],
  );

  const hasValidCiec = useMemo(() => {
    return (
      taxable_entity.has_ciec && taxable_entity.ciec_status.status === 'valid'
    );
  }, [taxable_entity]);

  return (
    <Row>
      <Colxx lg={6} className="mb-4">
        <Card className="credential-card border-top-2 border-primary">
          <div className="d-flex justify-content-between mb-4">
            <div className="d-flex flex-row align-items-center">
              {hasValidCiec ? (
                <i className="iconsmind-Yes text-success large-icon mx-3 align-text-bottom d-inline-block" />
              ) : (
                <i className="iconsmind-Close  text-danger large-icon mx-3 align-text-bottom d-inline-block" />
              )}
              <div className="mt-3">
                <h2 className="mb-1">CIEC</h2>
                <p>Clave de Identificación Electrónica Confidencial</p>
              </div>
            </div>
            <HelpCenterLink
              articleId="what_is_ciec"
              className="nav-link invoice-concept-card-help mt-3 pt-0"
            >
              <small className="clickable-text-color font-italic">
                ¿Qué es la CIEC?
              </small>
            </HelpCenterLink>
          </div>
          <CardBody className="pt-1">
            <div>
              {/*
                <p>
                  <span className="font-weight-bold">Status:</span> { taxable_entity.has_ciec ? "Activo" : "Pendiente de subir"}
                </p>
              */}
              <p>
                {taxable_entity.has_ciec && (
                  <span className="font-weight-bold">
                    RFC:{' '}
                    <span className="font-weight-normal">
                      {taxable_entity.rfc}
                    </span>
                  </span>
                )}
              </p>
              <p>
                <span className="font-weight-bold">
                  <br />
                </span>
              </p>
            </div>
          </CardBody>
        </Card>
      </Colxx>
      <Colxx lg={6} className="mb-4">
        <Card className="credential-card border-top-2 border-primary">
          <div className="d-flex justify-content-between mb-4">
            <div className="d-flex flex-row align-items-center">
              {taxable_entity.has_csd && !isExpired ? (
                <i className="iconsmind-Yes text-success large-icon mx-3 align-text-bottom d-inline-block" />
              ) : (
                <i className="iconsmind-Close  text-danger large-icon mx-3 align-text-bottom d-inline-block" />
              )}
              <div className="mt-3">
                <h2 className="mb-1">CSD</h2>
                <p>Certificado del Sello Digital</p>
              </div>
            </div>
            <HelpCenterLink
              articleId="what_is_csd"
              className="nav-link invoice-concept-card-help mt-3 pt-0"
            >
              <small className="clickable-text-color font-italic">
                ¿Qué es el CSD?
              </small>
            </HelpCenterLink>
          </div>
          <CardBody className="pt-1">
            <div>
              <p>
                <span className="font-weight-bold">Status:</span>{' '}
                {taxable_entity.has_csd ? 'Activo' : 'Pendiente de subir'}
              </p>
              <p>
                {taxable_entity.has_csd && (
                  <span className="font-weight-bold">
                    Fecha de vencimiento:{' '}
                    <span className="font-weight-normal">
                      {taxable_entity.csd_expiration_date}
                    </span>
                  </span>
                )}
              </p>
            </div>
            <CsdUploadFile
              taxable_entity={taxable_entity}
              status={taxable_entity.has_csd}
            />
          </CardBody>
        </Card>
      </Colxx>
      <Colxx lg={6} className="mb-4">
        <Card className="credential-card border-top-2 border-primary">
          <div className="d-flex justify-content-between mb-4">
            <div className="d-flex flex-row align-items-center">
              {taxable_entity.has_fiel ? (
                <i className="iconsmind-Yes text-success large-icon mx-3 align-text-bottom d-inline-block" />
              ) : (
                <i className="iconsmind-Close  text-danger large-icon mx-3 align-text-bottom d-inline-block" />
              )}
              <div className="mt-3">
                <h2 className="mb-1">FIEL</h2>
                <p>Firma Electrónica Avanzada</p>
              </div>
            </div>
            <HelpCenterLink
              articleId="what_is_fiel"
              className="nav-link invoice-concept-card-help mt-3 pt-0"
            >
              <small className="clickable-text-color font-italic">
                ¿Qué es la FIEL?
              </small>
            </HelpCenterLink>
          </div>
          <CardBody className="pt-1">
            <div>
              <p>
                <span className="font-weight-bold">Status:</span>{' '}
                {taxable_entity.has_fiel ? 'Activo' : 'Pendiente de subir'}
              </p>
              <p>
                {taxable_entity.has_fiel && (
                  <span className="font-weight-bold">
                    Fecha de vencimiento:{' '}
                    <span className="font-weight-normal">
                      {taxable_entity.fiel_expiration_date}
                    </span>
                  </span>
                )}
              </p>
            </div>
            <FielUploadFile
              taxable_entity={taxable_entity}
              status={taxable_entity.has_fiel}
            />
          </CardBody>
        </Card>
      </Colxx>
    </Row>
  );
}

function FielUploadFile({ status, taxable_entity }) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const toggleCollapse = () => setCollapseOpen(!collapseOpen);
  return (
    <>
      {!status ? (
        <>
          <Button
            className="credential-btn border d-flex justify-content-center align-items-center"
            color="primary"
            size="sm"
            onClick={toggleCollapse}
          >
            {collapseOpen ? 'Cerrar' : 'Agregar'}
          </Button>
          <Collapse isOpen={collapseOpen}>
            <UploadFiel />
          </Collapse>
        </>
      ) : (
        <Row>
          <Colxx md={6} xxs={12} className="text-center">
            <a
              href={taxable_entity.key_url}
              download={`FIEL-${taxable_entity.rfc}.key`}
              className="btn btn-outline-primary btn-sm"
            >
              Descargar .key
            </a>
          </Colxx>
          <Colxx md={6} xxs={12} className="text-center">
            <a
              href={taxable_entity.cer_url}
              download={`FIEL-${taxable_entity.rfc}.cer`}
              className="btn btn-outline-primary btn-sm"
            >
              Descargar .cer
            </a>
          </Colxx>
        </Row>
      )}
    </>
  );
}

function CiecUploadFile({ status, taxable_entity }) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const toggleCollapse = () => setCollapseOpen(!collapseOpen);

  return (
    <>
      {!status && (
        <>
          <Button
            className="credential-btn border d-flex justify-content-center align-items-center"
            color="primary"
            size="sm"
            onClick={toggleCollapse}
          >
            {collapseOpen ? 'Cerrar' : 'Agregar'}
          </Button>
          <Collapse isOpen={collapseOpen}>
            <UploadCiec taxable_entity={taxable_entity} />
          </Collapse>
        </>
      )}
    </>
  );
}

function CsdUploadFile({ status, taxable_entity }) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const toggleCollapse = () => setCollapseOpen(!collapseOpen);

  const isExpired = useMemo(() => {
    const csdExpirationDate = taxable_entity.csd_expiration_date;
    if (csdExpirationDate && dayjs(csdExpirationDate) < dayjs()) {
      return true;
    }
    return false;
  }, [taxable_entity]);

  return (
    <>
      {!status || isExpired ? (
        <>
          <Button
            className="credential-btn border d-flex justify-content-center align-items-center"
            color="primary"
            size="sm"
            onClick={toggleCollapse}
          >
            {collapseOpen ? 'Cerrar' : 'Agregar'}
          </Button>
          <Collapse isOpen={collapseOpen}>
            <UploadCsd />
          </Collapse>
        </>
      ) : (
        <Row>
          <Colxx md={6} xxs={12} className="text-center">
            <a
              href={taxable_entity.csd_key_url}
              download={`CSD-${taxable_entity.rfc}.key`}
              className="btn btn-outline-primary btn-sm"
            >
              Descargar .key
            </a>
          </Colxx>
          <Colxx md={6} xxs={12} className="text-center">
            <a
              href={taxable_entity.csd_cer_url}
              download={`CSD-${taxable_entity.rfc}.cer`}
              className="btn btn-outline-primary btn-sm"
            >
              Descargar .cer
            </a>
          </Colxx>
        </Row>
      )}
    </>
  );
}

const mapStateToProps = ({
  taxableEntity: { taxable_entity, loading: tLoading },
  authUser: { user },
}) => {
  return {
    taxable_entity,
    user,
  };
};

export default connect(mapStateToProps, {
  uploadTaxableEntityCsd,
  updateTaxableEntity,
  fetchTaxableEntityCredentials,
})(SettingCredentials);
