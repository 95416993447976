// NOTE: Don't use "key" anywhere, it works only as react keys

export const CURRENCY_OPTIONS = [
  { label: 'Dólar estadounidense', value: 'USD', key: 0 },
  { label: 'Peso mexicano', value: 'MXN', key: 1 },
  { label: 'Euro', value: 'EUR', key: 2 },
  { label: 'Dólar canadiense', value: 'CAD', key: 3 },
  { label: 'Libra Esterlina', value: 'GBP', key: 4 },
];
export const DEFAULT_CURRENCY_OPTION = CURRENCY_OPTIONS.find(
  (item) => item.value === 'MXN',
);

export const INVOICE_TYPE_OPTIONS = [
  { label: 'Ingreso', value: 'I', key: 0 },
  { label: 'Egreso', value: 'E', key: 1 },
];
export const DEFAULT_INVOICE_TYPE_OPTION = INVOICE_TYPE_OPTIONS.find(
  (item) => item.value === 'I',
);

export const PAYMENT_METHOD_OPTIONS = [
  { label: 'Pago en una sola exhibición', value: 'PUE', key: 0 },
  { label: 'Pago en parcialidades o diferido', value: 'PPD', key: 1 },
];

// TODO: check if key and id are used, if not, delete them
export const UNIT_TYPE_OPTIONS = [
  { label: 'Pieza', value: 'H87', key: 0, id: 1 },
  { label: 'Elemento', value: 'EA', key: 1, id: 2 },
  { label: 'Unidad de Servicio', value: 'E48', key: 2, id: 3 },
  { label: 'Actividad', value: 'ACT', key: 3, id: 4 },
  { label: 'Kilogramo', value: 'KGM', key: 4, id: 5 },
  { label: 'Trabajo', value: 'E51', key: 5, id: 6 },
  { label: 'Tarifa', value: 'A9', key: 6, id: 7 },
  { label: 'Metro', value: 'MTR', key: 7, id: 8 },
  { label: 'Paquete a granel', value: 'AB', key: 8, id: 9 },
  { label: 'Caja base', value: 'BB', key: 9, id: 10 },
  { label: 'Kit', value: 'KT', key: 10, id: 11 },
  { label: 'Conjunto', value: 'SET', key: 11, id: 12 },
  { label: 'Litro', value: 'LTR', key: 12, id: 13 },
  { label: 'Caja', value: 'XBX', key: 13, id: 14 },
  { label: 'Mes ', value: 'MON', key: 14, id: 15 },
  { label: 'Hora', value: 'HUR', key: 15, id: 16 },
  { label: 'Metro cuadrado', value: 'MTK', key: 16, id: 17 },
  { label: 'Equipos', value: '11', key: 17, id: 18 },
  { label: 'Miligramo', value: 'MGM', key: 18, id: 19 },
  { label: 'Paquete', value: 'XPK', key: 19, id: 20 },
  { label: 'Conjunto de piezas', value: 'XKI', key: 20, id: 21 },
  { label: 'Variedad', value: 'AS', key: 21, id: 22 },
  { label: 'Gramo', value: 'GRM', key: 22, id: 23 },
  { label: 'Par', value: 'PR', key: 23, id: 24 },
  { label: 'Docenas de piezas', value: 'DPC', key: 24, id: 25 },
  { label: 'Unidad', value: 'XUN', key: 25, id: 26 },
  { label: 'Día', value: 'DAY', key: 26, id: 27 },
  { label: 'Lote', value: 'XLT', key: 27, id: 28 },
  { label: 'Grupos', value: '10', key: 28, id: 29 },
  { label: 'Mililitro', value: 'MLT', key: 29, id: 30 },
  { label: 'Viaje', value: 'E54', key: 30, id: 31 },
  { label: 'Mutuamente definida', value: 'ZZ', key: 31, id: 31 },
];

export const USE_CFDI_OPTIONS = [
  { label: 'Adquisición de mercancías', value: 'G01', key: 1 },
  { label: 'Devoluciones, descuentos o bonificaciones', value: 'G02', key: 2 },
  { label: 'Gastos en general', value: 'G03', key: 2 },
  { label: 'Construcciones', value: 'I01', key: 3 },
  {
    label: 'Mobilario y equipo de oficina por inversiones',
    value: 'I02',
    key: 4,
  },
  { label: 'Equipo de transporte', value: 'I03', key: 5 },
  { label: 'Equipo de computo y accesorios', value: 'I04', key: 6 },
  {
    label: 'Dados, troqueles, moldes, matrices y herramental',
    value: 'I05',
    key: 7,
  },
  { label: 'Comunicaciones telefónicas', value: 'I06', key: 8 },
  { label: 'Comunicaciones satelitales', value: 'I07', key: 9 },
  { label: 'Otra maquinaria y equipo', value: 'I08', key: 10 },
  {
    label: 'Honorarios médicos, dentales y gastos hospitalarios.',
    value: 'D01',
    key: 11,
  },
  {
    label: 'Gastos médicos por incapacidad o discapacidad',
    value: 'D02',
    key: 12,
  },
  { label: 'Gastos funerales.', value: 'D03', key: 13 },
  { label: 'Donativos.', value: 'D04', key: 14 },
  {
    label:
      'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
    value: 'D05',
    key: 15,
  },
  { label: 'Aportaciones voluntarias al SAR.', value: 'D06', key: 16 },
  { label: 'Primas por seguros de gastos médicos.', value: 'D07', key: 17 },
  {
    label: 'Gastos de transportación escolar obligatoria.',
    value: 'D08',
    key: 18,
  },
  {
    label:
      'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
    value: 'D09',
    key: 19,
  },
  {
    label: 'Pagos por servicios educativos (colegiaturas)',
    value: 'D10',
    key: 20,
  },
  {
    label: 'Sin efectos fiscales',
    value: 'S01',
    key: 20,
  },
];

export const DEFAULT_CFDI_OPTION = USE_CFDI_OPTIONS.find(
  (item) => item.value === 'G03',
);
export const DEFAULT_PAYMENT_METHOD_OPTION = PAYMENT_METHOD_OPTIONS.find(
  (item) => item.value === 'PUE',
);
export const PAYMENT_TYPE_OPTIONS = [
  { label: 'Efectivo', value: '01', key: 1 },
  { label: 'Cheque nominativo', value: '02', key: 2 },
  { label: 'Transferencia electrónica de fondos', value: '03', key: 3 },
  { label: 'Tarjeta de crédito', value: '04', key: 4 },
  { label: 'Monedero electrónico', value: '05', key: 5 },
  { label: 'Dinero electrónico', value: '06', key: 6 },
  { label: 'Vales de despensa', value: '08', key: 7 },
  { label: 'Dación en pago', value: '12', key: 8 },
  { label: 'Pago por subrogación', value: '13', key: 9 },
  { label: 'Pago por consignación', value: '14', key: 10 },
  { label: 'Condonación', value: '15', key: 11 },
  { label: 'Compensación', value: '17', key: 12 },
  { label: 'Novación', value: '23', key: 13 },
  { label: 'Confusión', value: '24', key: 14 },
  { label: 'Remisión de deuda', value: '25', key: 15 },
  { label: 'Prescripción o caducidad', value: '26', key: 16 },
  { label: 'A satisfacción del acreedor', value: '27', key: 17 },
  { label: 'Tarjeta de débito', value: '28', key: 18 },
  { label: 'Tarjeta de servicios', value: '29', key: 19 },
  { label: 'Aplicación de anticipos', value: '30', key: 20 },
  { label: 'Intermediario pagos', value: '31', key: 21 },
  { label: 'Por definir', value: '99', key: 22 },
];

export const IVA_OPTIONS = [
  { label: 'IVA 16%', value: 0.16, key: 1 },
  { label: 'IVA 8%', value: 0.08, key: 2 },
  { label: 'IVA 0%', value: 0, key: 3 },
  { label: 'IVA exento', value: null, key: 4 },
];

export const IVA_RET_OPTIONS = [
  /*   { label: "IVA RET 16%", value: 0.16, key: 1 },
  { label: "IVA RET 10.6668%", value: 0.106668, key: 2 }, */
  { label: 'IVA RET 10.6667%', value: 0.106667, key: 1 },
  /*   { label: "IVA RET 10.6666%", value: 0.106666, key: 4 }, */
  /*    { label: "IVA RET 10.67%", value: 0.1067, key: 5 },
  { label: "IVA RET 10.66%", value: 0.1066, key: 6 },
  { label: "IVA RET 10.6%", value: 0.106, key: 7 },
  { label: "IVA RET 10%", value: 0.1, key: 8 },
  { label: "IVA RET 8%", value: 0.08, key: 9 }, */
  { label: 'IVA RET 6%', value: 0.06, key: 10 },
  { label: 'IVA RET 3%', value: 0.03, key: 14 },
  { label: 'IVA RET 4%', value: 0.04, key: 13 },
  /* { label: "IVA RET 5%", value: 0.05, key: 11 },
  { label: "IVA RET 5.3333%", value: ".053333", key: 12 },
  { label: "IVA RET 2.5%", value: 0.025, key: 15 },
  { label: "IVA RET 2%", value: 0.02, key: 16 },
  { label: "IVA RET 0.7%", value: 0.007, key: 17 },
  { label: "IVA RET 0.5%", value: 0.005, key: 18 },
  { label: "IVA RET 0.2%", value: 0.002, key: 19 },  */
  { label: '-', value: 0, key: 2 },
];

export const IEPS_OPTIONS = [
  { label: 'IEPS 300%', value: 3, key: 1 },
  { label: 'IEPS 160%', value: 1.6, key: 2 },
  { label: 'IEPS 53%', value: 0.53, key: 3 },
  { label: 'IEPS 50%', value: 0.5, key: 4 },
  { label: 'IEPS 35%', value: 0.35, key: 5 },
  { label: 'IEPS 30.4%', value: 0.304, key: 6 },
  { label: 'IEPS 30%', value: 0.3, key: 7 },
  { label: 'IEPS 29.88%', value: 0.2988, key: 8 },
  { label: 'IEPS 26.5%', value: 0.265, key: 9 },
  { label: 'IEPS 25%', value: 0.25, key: 10 },
  { label: 'IEPS 9%', value: 0.09, key: 11 },
  { label: 'IEPS 8%', value: 0.08, key: 12 },
  { label: 'IEPS 7%', value: 0.07, key: 13 },
  { label: 'IEPS 6%', value: 0.06, key: 14 },
  { label: 'IEPS 5.91%', value: 0.0591, key: 15 },
  { label: 'IEPS 4%', value: 0.04, key: 16 },
  { label: '-', value: 0, key: 17 },
];

export const ISR_OPTIONS = [
  /*   { label: "ISR 20%", value: 0.2, key: 1 }, */
  { label: 'ISR 10%', value: 0.1, key: 1 },
  { label: 'ISR 1.25%', value: 0.0125, key: 2 },
  /*  { label: "ISR 2%", value: 0.02, key: 3 }, */
  { label: '-', value: 0, key: 3 },
];

export const FISCAL_REGIME_OPTIONS = [
  {
    label: 'Persona física con actividad empresarial',
    value: '612',
    id: 'business_activity',
    key: 1,
  },
  {
    label: 'Régimen de incorporación fiscal',
    value: '621',
    id: 'fiscal_incorporation',
    key: 2,
  },
  { label: 'Persona Moral', value: '601', id: 'legal_entity', key: 3 },
  {
    label:
      'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    value: '625',
    id: 'platform_activity',
    key: 4,
  },
  { label: 'Arrendamiento', id: 'lease', value: '606', key: 5 },
];

export const LEGAL_TYPE_OPTIONS = {
  business_activity: '612',
  legal_entity: '601',
  fiscal_incorporation: '621',
};

export const FISCAL_RESIDENCE_OPTIONS = [
  {
    label: 'Afganistán',
    key: 1,
    value: 'AFG',
  },
  {
    label: 'Albania',
    key: 2,
    value: 'ALB',
  },
  {
    label: 'Alemania',
    key: 3,
    value: 'DEU',
  },
  {
    label: 'Algeria',
    key: 4,
    value: 'DZA',
  },
  {
    label: 'Andorra',
    key: 5,
    value: 'AND',
  },
  {
    label: 'Angola',
    key: 6,
    value: 'AGO',
  },
  {
    label: 'Anguila',
    key: 7,
    value: 'AIA',
  },
  {
    label: 'Antártida',
    key: 8,
    value: 'ATA',
  },
  {
    label: 'Antigua y Barbuda',
    key: 9,
    value: 'ATG',
  },
  {
    label: 'Antillas Neerlandesas',
    key: 10,
    value: 'ANT',
  },
  {
    label: 'Arabia Saudita',
    key: 11,
    value: 'SAU',
  },
  {
    label: 'Argentina',
    key: 12,
    value: 'ARG',
  },
  {
    label: 'Armenia',
    key: 13,
    value: 'ARM',
  },
  {
    label: 'Aruba',
    key: 14,
    value: 'ABW',
  },
  {
    label: 'Australia',
    key: 15,
    value: 'AUS',
  },
  {
    label: 'Austria',
    key: 16,
    value: 'AUT',
  },
  {
    label: 'Azerbayán',
    key: 17,
    value: 'AZE',
  },
  {
    label: 'Bélgica',
    key: 18,
    value: 'BEL',
  },
  {
    label: 'Bahamas',
    key: 19,
    value: 'BHS',
  },
  {
    label: 'Bahrein',
    key: 20,
    value: 'BHR',
  },
  {
    label: 'Bangladesh',
    key: 21,
    value: 'BGD',
  },
  {
    label: 'Barbados',
    key: 22,
    value: 'BRB',
  },
  {
    label: 'Belice',
    key: 23,
    value: 'BLZ',
  },
  {
    label: 'Benín',
    key: 24,
    value: 'BEN',
  },
  {
    label: 'Bhután',
    key: 25,
    value: 'BTN',
  },
  {
    label: 'Bielorrusia',
    key: 26,
    value: 'BLR',
  },
  {
    label: 'Birmania',
    key: 27,
    value: 'MMR',
  },
  {
    label: 'Bolivia',
    key: 28,
    value: 'BOL',
  },
  {
    label: 'Bosnia y Herzegovina',
    key: 29,
    value: 'BIH',
  },
  {
    label: 'Botsuana',
    key: 30,
    value: 'BWA',
  },
  {
    label: 'Brasil',
    key: 31,
    value: 'BRA',
  },
  {
    label: 'Brunéi',
    key: 32,
    value: 'BRN',
  },
  {
    label: 'Bulgaria',
    key: 33,
    value: 'BGR',
  },
  {
    label: 'Burkina Faso',
    key: 34,
    value: 'BFA',
  },
  {
    label: 'Burundi',
    key: 35,
    value: 'BDI',
  },
  {
    label: 'Cabo Verde',
    key: 36,
    value: 'CPV',
  },
  {
    label: 'Camboya',
    key: 37,
    value: 'KHM',
  },
  {
    label: 'Camerún',
    key: 38,
    value: 'CMR',
  },
  {
    label: 'Canadá',
    key: 39,
    value: 'CAN',
  },
  {
    label: 'Chad',
    key: 40,
    value: 'TCD',
  },
  {
    label: 'Chile',
    key: 41,
    value: 'CHL',
  },
  {
    label: 'China',
    key: 42,
    value: 'CHN',
  },
  {
    label: 'Chipre',
    key: 43,
    value: 'CYP',
  },
  {
    label: 'Ciudad del Vaticano',
    key: 44,
    value: 'VAT',
  },
  {
    label: 'Colombia',
    key: 45,
    value: 'COL',
  },
  {
    label: 'Comoras',
    key: 46,
    value: 'COM',
  },
  {
    label: 'Congo',
    key: 47,
    value: 'COG',
  },
  {
    label: 'Congo',
    key: 48,
    value: 'COD',
  },
  {
    label: 'Corea del Norte',
    key: 49,
    value: 'PRK',
  },
  {
    label: 'Corea del Sur',
    key: 50,
    value: 'KOR',
  },
  {
    label: 'Costa de Marfil',
    key: 51,
    value: 'CIV',
  },
  {
    label: 'Costa Rica',
    key: 52,
    value: 'CRI',
  },
  {
    label: 'Croacia',
    key: 53,
    value: 'HRV',
  },
  {
    label: 'Cuba',
    key: 54,
    value: 'CUB',
  },
  {
    label: 'Dinamarca',
    key: 55,
    value: 'DNK',
  },
  {
    label: 'Dominica',
    key: 56,
    value: 'DMA',
  },
  {
    label: 'Ecuador',
    key: 57,
    value: 'ECU',
  },
  {
    label: 'Egipto',
    key: 58,
    value: 'EGY',
  },
  {
    label: 'El Salvador',
    key: 59,
    value: 'SLV',
  },
  {
    label: 'Emiratos Árabes Unidos',
    key: 60,
    value: 'ARE',
  },
  {
    label: 'Eritrea',
    key: 61,
    value: 'ERI',
  },
  {
    label: 'Eslovaquia',
    key: 62,
    value: 'SVK',
  },
  {
    label: 'Eslovenia',
    key: 63,
    value: 'SVN',
  },
  {
    label: 'España',
    key: 64,
    value: 'ESP',
  },
  {
    label: 'Estados Unidos de América',
    key: 65,
    value: 'USA',
  },
  {
    label: 'Estonia',
    key: 66,
    value: 'EST',
  },
  {
    label: 'Etiopía',
    key: 67,
    value: 'ETH',
  },
  {
    label: 'Filipinas',
    key: 68,
    value: 'PHL',
  },
  {
    label: 'Finlandia',
    key: 69,
    value: 'FIN',
  },
  {
    label: 'Fiyi',
    key: 70,
    value: 'FJI',
  },
  {
    label: 'Francia',
    key: 71,
    value: 'FRA',
  },
  {
    label: 'Gabón',
    key: 72,
    value: 'GAB',
  },
  {
    label: 'Gambia',
    key: 73,
    value: 'GMB',
  },
  {
    label: 'Georgia',
    key: 74,
    value: 'GEO',
  },
  {
    label: 'Ghana',
    key: 75,
    value: 'GHA',
  },
  {
    label: 'Gibraltar',
    key: 76,
    value: 'GIB',
  },
  {
    label: 'Granada',
    key: 77,
    value: 'GRD',
  },
  {
    label: 'Grecia',
    key: 78,
    value: 'GRC',
  },
  {
    label: 'Groenlandia',
    key: 79,
    value: 'GRL',
  },
  {
    label: 'Guadalupe',
    key: 80,
    value: 'GLP',
  },
  {
    label: 'Guam',
    key: 81,
    value: 'GUM',
  },
  {
    label: 'Guatemala',
    key: 82,
    value: 'GTM',
  },
  {
    label: 'Guayana Francesa',
    key: 83,
    value: 'GUF',
  },
  {
    label: 'Guernsey',
    key: 84,
    value: 'GGY',
  },
  {
    label: 'Guinea',
    key: 85,
    value: 'GIN',
  },
  {
    label: 'Guinea Ecuatorial',
    key: 86,
    value: 'GNQ',
  },
  {
    label: 'Guinea-Bissau',
    key: 87,
    value: 'GNB',
  },
  {
    label: 'Guyana',
    key: 88,
    value: 'GUY',
  },
  {
    label: 'Haití',
    key: 89,
    value: 'HTI',
  },
  {
    label: 'Honduras',
    key: 90,
    value: 'HND',
  },
  {
    label: 'Hong kong',
    key: 91,
    value: 'HKG',
  },
  {
    label: 'Hungría',
    key: 92,
    value: 'HUN',
  },
  {
    label: 'India',
    key: 93,
    value: 'IND',
  },
  {
    label: 'Indonesia',
    key: 94,
    value: 'IDN',
  },
  {
    label: 'Irán',
    key: 95,
    value: 'IRN',
  },
  {
    label: 'Irak',
    key: 96,
    value: 'IRQ',
  },
  {
    label: 'Irlanda',
    key: 97,
    value: 'IRL',
  },
  {
    label: 'Isla Bouvet',
    key: 98,
    value: 'BVT',
  },
  {
    label: 'Isla de Man',
    key: 99,
    value: 'IMN',
  },
  {
    label: 'Isla de Navidad',
    key: 100,
    value: 'CXR',
  },
  {
    label: 'Isla Norfolk',
    key: 101,
    value: 'NFK',
  },
  {
    label: 'Islandia',
    key: 102,
    value: 'ISL',
  },
  {
    label: 'Islas Bermudas',
    key: 103,
    value: 'BMU',
  },
  {
    label: 'Islas Caimán',
    key: 104,
    value: 'CYM',
  },
  {
    label: 'Islas Cocos (Keeling)',
    key: 105,
    value: 'CCK',
  },
  {
    label: 'Islas Cook',
    key: 106,
    value: 'COK',
  },
  {
    label: 'Islas de Åland',
    key: 107,
    value: 'ALA',
  },
  {
    label: 'Islas Feroe',
    key: 108,
    value: 'FRO',
  },
  {
    label: 'Islas Georgias del Sur y Sandwich del Sur',
    key: 109,
    value: 'SGS',
  },
  {
    label: 'Islas Heard y McDonald',
    key: 110,
    value: 'HMD',
  },
  {
    label: 'Islas Maldivas',
    key: 111,
    value: 'MDV',
  },
  {
    label: 'Islas Malvinas',
    key: 112,
    value: 'FLK',
  },
  {
    label: 'Islas Marianas del Norte',
    key: 113,
    value: 'MNP',
  },
  {
    label: 'Islas Marshall',
    key: 114,
    value: 'MHL',
  },
  {
    label: 'Islas Pitcairn',
    key: 115,
    value: 'PCN',
  },
  {
    label: 'Islas Salomón',
    key: 116,
    value: 'SLB',
  },
  {
    label: 'Islas Turcas y Caicos',
    key: 117,
    value: 'TCA',
  },
  {
    label: 'Islas Ultramarinas Menores de Estados Unidos',
    key: 118,
    value: 'UMI',
  },
  {
    label: 'Islas Vírgenes Británicas',
    key: 119,
    value: 'VG',
  },
  {
    label: 'Islas Vírgenes de los Estados Unidos',
    key: 120,
    value: 'VIR',
  },
  {
    label: 'Israel',
    key: 121,
    value: 'ISR',
  },
  {
    label: 'Italia',
    key: 122,
    value: 'ITA',
  },
  {
    label: 'Jamaica',
    key: 123,
    value: 'JAM',
  },
  {
    label: 'Japón',
    key: 124,
    value: 'JPN',
  },
  {
    label: 'Jersey',
    key: 125,
    value: 'JEY',
  },
  {
    label: 'Jordania',
    key: 126,
    value: 'JOR',
  },
  {
    label: 'Kazajistán',
    key: 127,
    value: 'KAZ',
  },
  {
    label: 'Kenia',
    key: 128,
    value: 'KEN',
  },
  {
    label: 'Kirgizstán',
    key: 129,
    value: 'KGZ',
  },
  {
    label: 'Kiribati',
    key: 130,
    value: 'KIR',
  },
  {
    label: 'Kuwait',
    key: 131,
    value: 'KWT',
  },
  {
    label: 'Líbano',
    key: 132,
    value: 'LBN',
  },
  {
    label: 'Laos',
    key: 133,
    value: 'LAO',
  },
  {
    label: 'Lesoto',
    key: 134,
    value: 'LSO',
  },
  {
    label: 'Letonia',
    key: 135,
    value: 'LVA',
  },
  {
    label: 'Liberia',
    key: 136,
    value: 'LBR',
  },
  {
    label: 'Libia',
    key: 137,
    value: 'LBY',
  },
  {
    label: 'Liechtenstein',
    key: 138,
    value: 'LIE',
  },
  {
    label: 'Lituania',
    key: 139,
    value: 'LTU',
  },
  {
    label: 'Luxemburgo',
    key: 140,
    value: 'LUX',
  },
  {
    label: 'México',
    key: 141,
    value: 'MEX',
  },
  {
    label: 'Mónaco',
    key: 142,
    value: 'MCO',
  },
  {
    label: 'Macao',
    key: 143,
    value: 'MAC',
  },
  {
    label: 'Macedônia',
    key: 144,
    value: 'MKD',
  },
  {
    label: 'Madagascar',
    key: 145,
    value: 'MDG',
  },
  {
    label: 'Malasia',
    key: 146,
    value: 'MYS',
  },
  {
    label: 'Malawi',
    key: 147,
    value: 'MWI',
  },
  {
    label: 'Mali',
    key: 148,
    value: 'MLI',
  },
  {
    label: 'Malta',
    key: 149,
    value: 'MLT',
  },
  {
    label: 'Marruecos',
    key: 150,
    value: 'MAR',
  },
  {
    label: 'Martinica',
    key: 151,
    value: 'MTQ',
  },
  {
    label: 'Mauricio',
    key: 152,
    value: 'MUS',
  },
  {
    label: 'Mauritania',
    key: 153,
    value: 'MRT',
  },
  {
    label: 'Mayotte',
    key: 154,
    value: 'MYT',
  },
  {
    label: 'Micronesia',
    key: 155,
    value: 'FSM',
  },
  {
    label: 'Moldavia',
    key: 156,
    value: 'MDA',
  },
  {
    label: 'Mongolia',
    key: 157,
    value: 'MNG',
  },
  {
    label: 'Montenegro',
    key: 158,
    value: 'MNE',
  },
  {
    label: 'Montserrat',
    key: 159,
    value: 'MSR',
  },
  {
    label: 'Mozambique',
    key: 160,
    value: 'MOZ',
  },
  {
    label: 'Namibia',
    key: 161,
    value: 'NAM',
  },
  {
    label: 'Nauru',
    key: 162,
    value: 'NRU',
  },
  {
    label: 'Nepal',
    key: 163,
    value: 'NPL',
  },
  {
    label: 'Nicaragua',
    key: 164,
    value: 'NIC',
  },
  {
    label: 'Niger',
    key: 165,
    value: 'NER',
  },
  {
    label: 'Nigeria',
    key: 166,
    value: 'NGA',
  },
  {
    label: 'Niue',
    key: 167,
    value: 'NIU',
  },
  {
    label: 'Noruega',
    key: 168,
    value: 'NOR',
  },
  {
    label: 'Nueva Caledonia',
    key: 169,
    value: 'NCL',
  },
  {
    label: 'Nueva Zelanda',
    key: 170,
    value: 'NZL',
  },
  {
    label: 'Omán',
    key: 171,
    value: 'OMN',
  },
  {
    label: 'Países Bajos',
    key: 172,
    value: 'NLD',
  },
  {
    label: 'Pakistán',
    key: 173,
    value: 'PAK',
  },
  {
    label: 'Palau',
    key: 174,
    value: 'PLW',
  },
  {
    label: 'Palestina',
    key: 175,
    value: 'PSE',
  },
  {
    label: 'Panamá',
    key: 176,
    value: 'PAN',
  },
  {
    label: 'Papúa Nueva Guinea',
    key: 177,
    value: 'PNG',
  },
  {
    label: 'Paraguay',
    key: 178,
    value: 'PRY',
  },
  {
    label: 'Perú',
    key: 179,
    value: 'PER',
  },
  {
    label: 'Polinesia Francesa',
    key: 180,
    value: 'PYF',
  },
  {
    label: 'Polonia',
    key: 181,
    value: 'POL',
  },
  {
    label: 'Portugal',
    key: 182,
    value: 'PRT',
  },
  {
    label: 'Puerto Rico',
    key: 183,
    value: 'PRI',
  },
  {
    label: 'Qatar',
    key: 184,
    value: 'QAT',
  },
  {
    label: 'Reino Unido',
    key: 185,
    value: 'GBR',
  },
  {
    label: 'República Centroafricana',
    key: 186,
    value: 'CAF',
  },
  {
    label: 'República Checa',
    key: 187,
    value: 'CZE',
  },
  {
    label: 'República Dominicana',
    key: 188,
    value: 'DOM',
  },
  {
    label: 'Reunión',
    key: 189,
    value: 'REU',
  },
  {
    label: 'Ruanda',
    key: 190,
    value: 'RWA',
  },
  {
    label: 'Rumanía',
    key: 191,
    value: 'ROU',
  },
  {
    label: 'Rusia',
    key: 192,
    value: 'RUS',
  },
  {
    label: 'Sahara Occidental',
    key: 193,
    value: 'ESH',
  },
  {
    label: 'Samoa',
    key: 194,
    value: 'WSM',
  },
  {
    label: 'Samoa Americana',
    key: 195,
    value: 'ASM',
  },
  {
    label: 'San Bartolomé',
    key: 196,
    value: 'BLM',
  },
  {
    label: 'San Cristóbal y Nieves',
    key: 197,
    value: 'KNA',
  },
  {
    label: 'San Marino',
    key: 198,
    value: 'SMR',
  },
  {
    label: 'San Martín (Francia)',
    key: 199,
    value: 'MAF',
  },
  {
    label: 'San Pedro y Miquelón',
    key: 200,
    value: 'SPM',
  },
  {
    label: 'San Vicente y las Granadinas',
    key: 201,
    value: 'VCT',
  },
  {
    label: 'Santa Elena',
    key: 202,
    value: 'SHN',
  },
  {
    label: 'Santa Lucía',
    key: 203,
    value: 'LCA',
  },
  {
    label: 'Santo Tomé y Príncipe',
    key: 204,
    value: 'STP',
  },
  {
    label: 'Senegal',
    key: 205,
    value: 'SEN',
  },
  {
    label: 'Serbia',
    key: 206,
    value: 'SRB',
  },
  {
    label: 'Seychelles',
    key: 207,
    value: 'SYC',
  },
  {
    label: 'Sierra Leona',
    key: 208,
    value: 'SLE',
  },
  {
    label: 'Singapur',
    key: 209,
    value: 'SGP',
  },
  {
    label: 'Siria',
    key: 210,
    value: 'SYR',
  },
  {
    label: 'Somalia',
    key: 211,
    value: 'SOM',
  },
  {
    label: 'Sri lanka',
    key: 212,
    value: 'LKA',
  },
  {
    label: 'Sudáfrica',
    key: 213,
    value: 'ZAF',
  },
  {
    label: 'Sudán',
    key: 214,
    value: 'SDN',
  },
  {
    label: 'Suecia',
    key: 215,
    value: 'SWE',
  },
  {
    label: 'Suiza',
    key: 216,
    value: 'CHE',
  },
  {
    label: 'Surinám',
    key: 217,
    value: 'SUR',
  },
  {
    label: 'Svalbard y Jan Mayen',
    key: 218,
    value: 'SJM',
  },
  {
    label: 'Swazilandia',
    key: 219,
    value: 'SWZ',
  },
  {
    label: 'Tadjikistán',
    key: 220,
    value: 'TJK',
  },
  {
    label: 'Tailandia',
    key: 221,
    value: 'THA',
  },
  {
    label: 'Taiwán',
    key: 222,
    value: 'TWN',
  },
  {
    label: 'Tanzania',
    key: 223,
    value: 'TZA',
  },
  {
    label: 'Territorio Británico del Océano Índico',
    key: 224,
    value: 'IOT',
  },
  {
    label: 'Territorios Australes y Antárticas Franceses',
    key: 225,
    value: 'ATF',
  },
  {
    label: 'Timor Oriental',
    key: 226,
    value: 'TLS',
  },
  {
    label: 'Togo',
    key: 227,
    value: 'TGO',
  },
  {
    label: 'Tokelau',
    key: 228,
    value: 'TKL',
  },
  {
    label: 'Tonga',
    key: 229,
    value: 'TON',
  },
  {
    label: 'Trinidad y Tobago',
    key: 230,
    value: 'TTO',
  },
  {
    label: 'Tunez',
    key: 231,
    value: 'TUN',
  },
  {
    label: 'Turkmenistán',
    key: 232,
    value: 'TKM',
  },
  {
    label: 'Turquía',
    key: 233,
    value: 'TUR',
  },
  {
    label: 'Tuvalu',
    key: 234,
    value: 'TUV',
  },
  {
    label: 'Ucrania',
    key: 235,
    value: 'UKR',
  },
  {
    label: 'Uganda',
    key: 236,
    value: 'UGA',
  },
  {
    label: 'Uruguay',
    key: 237,
    value: 'URY',
  },
  {
    label: 'Uzbekistán',
    key: 238,
    value: 'UZB',
  },
  {
    label: 'Vanuatu',
    key: 239,
    value: 'VUT',
  },
  {
    label: 'Venezuela',
    key: 240,
    value: 'VEN',
  },
  {
    label: 'Vietnam',
    key: 241,
    value: 'VNM',
  },
  {
    label: 'Wallis y Futuna',
    key: 242,
    value: 'WLF',
  },
  {
    label: 'Yemen',
    key: 243,
    value: 'YEM',
  },
  {
    label: 'Yibuti',
    key: 244,
    value: 'DJI',
  },
  {
    label: 'Zambia',
    key: 245,
    value: 'ZMB',
  },
  {
    label: 'Zimbabue',
    key: 246,
    value: 'ZWE',
  },
];

export const CANCELLATION_REASON_OPTIONS = [
  {
    value: '01',
    label: 'Comprobante emitido con errores con relación',
    key: 1,
  },
  {
    value: '02',
    label: 'Comprobante emitido con errores sin relación',
    key: 2,
  },
  {
    value: '03',
    label: 'No se llevó a cabo la operación',
    key: 3,
  },
  {
    value: '04',
    label: 'Operación nominativa relacionada en la factura global',
    key: 4,
  },
];

export const EXPORT_CODE_OPTIONS = [
  {
    value: '01',
    label: 'No aplica',
    key: 1,
  },
  {
    value: '02',
    label: 'Definitiva',
    key: 2,
  },
  {
    value: '03',
    label: 'Temporal',
    key: 3,
  },
  {
    value: '04',
    label: 'Definitiva con clave distinta a A1',
    key: 4,
  },
];

export const DEFAULT_EXPORT_CODE_OPTION = EXPORT_CODE_OPTIONS.find(
  (item) => item.value === '01',
);

export const TAXABLE_CODE_OPTIONS = [
  {
    value: '01',
    label: 'No objeto de impuesto.',
    key: 1,
  },
  {
    value: '02',
    label: 'Sí objeto de impuesto.',
    key: 2,
  },
  {
    value: '03',
    label: 'Sí objeto del impuesto y no obligado al desglose.',
    key: 3,
  },
];

export const TAXE_IDS = {
  isr: '001',
  iva: '002',
  ieps: '003',
};

export const PERIODICITY_OPTIONS = [
  {
    value: '01',
    label: 'Diario',
    key: 1,
  },
  {
    value: '02',
    label: 'Semanal',
    key: 2,
  },
  {
    value: '03',
    label: 'Quincenal',
    key: 3,
  },
  {
    value: '04',
    label: 'Mensual',
    key: 4,
  },
  {
    value: '05',
    label: 'Bimestral',
    key: 5,
  },
];

export const DEFAULT_PERIODICITY_OPTION = PERIODICITY_OPTIONS.find(
  (item) => item.key === 4,
);

export const MONTHLY_PERIOD_OPTIONS = [
  {
    value: '01',
    label: 'Enero',
    key: 1,
  },
  {
    value: '02',
    label: 'Febrero',
    key: 2,
  },
  {
    value: '03',
    label: 'Marzo',
    key: 3,
  },
  {
    value: '04',
    label: 'Abril',
    key: 4,
  },
  {
    value: '05',
    label: 'Mayo',
    key: 5,
  },
  {
    value: '06',
    label: 'Junio',
    key: 6,
  },
  {
    value: '07',
    label: 'Julio',
    key: 7,
  },
  {
    value: '08',
    label: 'Agosto',
    key: 8,
  },
  {
    value: '09',
    label: 'Septiembre',
    key: 9,
  },
  {
    value: '10',
    label: 'Octubre',
    key: 10,
  },
  {
    value: '11',
    label: 'Noviembre',
    key: 11,
  },
  {
    value: '12',
    label: 'Diciembre',
    key: 12,
  },
];

export const BIMONTHLY_PERIOD_OPTIONS = [
  {
    value: '13',
    label: 'Enero-Febrero',
    key: 13,
  },
  {
    value: '14',
    label: 'Marzo-Abril',
    key: 14,
  },
  {
    value: '15',
    label: 'Mayo-Junio',
    key: 15,
  },
  {
    value: '16',
    label: 'Julio-Agosto',
    key: 16,
  },
  {
    value: '17',
    label: 'Septiembre-Octubre',
    key: 17,
  },
  {
    value: '18',
    label: 'Noviembre-Diciembre',
    key: 18,
  },
];
