import React from 'react';
import { Card, CardBody } from 'reactstrap';

import BankList from '@components/BelvoBanks/BankList';

export default function SettingsMyBanks({ widget_token, belvo_token_id }) {
  return (
    <Card>
      <CardBody className="d-flex flex-column">
        <BankList />
      </CardBody>
    </Card>
  );
}
