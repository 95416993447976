import { styled } from '@konta/ui';

export const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid $gray200',
  borderRadius: '8px',
  width: '300px',
  height: '206px',
  padding: '24px',
  '@<md': {
    flexGrow: 1,
  },
  gap: 10,
  '&:hover': {
    cursor: 'pointer',
  },
  variants: {
    alignCenter: {
      true: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
});

export const IconWrapper = styled('div', {
  cursor: 'pointer',
  color: '#000',
  width: '64px',
  height: '64px',
  svg: {
    width: '64px',
    height: '64px',
    path: {
      fill: 'none',
      strokeWidth: '1px',
    },
  },
});

export const Title = styled('span', {
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '28px',
  textAlign: 'center',
  color: '$gray700',
  fontSize: '$l',
  flex: '1',
  variants: {
    noFlex: {
      true: {
        flex: 'none',
      },
    },
  },
});
