import { Flex } from '@konta/ui';
import { CalculatorLineIcon } from '@konta/icons';
import { ConfirmModal } from '@components/index';
import { FormikProvider } from 'formik';
import FormikTextInput from '@components/FormikTextInput';
import FormikKontaSelect from '@components/FormikKontaSelect';
import FormikTextarea from '@components/FormikTextarea';
import ContainerButton from 'shared/components/ContainerButton';
import useUploadManualIncomesModal from './useUploadManualIncomesModal';

interface UploadManualIncomesProps {
  toggleOpenUploadIncomes: () => void;
}

export default function UploadManualIncomes({
  toggleOpenUploadIncomes,
}: UploadManualIncomesProps) {
  const {
    showConfirmation,
    toggleShowConfirmation,
    formik,
    regimeOptionsWithDeclarationId,
    IVA_OPTIONS,
    isLoading,
    taxableEntityPreferences,
  } = useUploadManualIncomesModal({ toggleOpenUploadIncomes });

  return (
    <>
      <ContainerButton
        icon={<CalculatorLineIcon />}
        title="Agregar monto manual"
        onClick={toggleShowConfirmation}
      />

      <ConfirmModal
        open={showConfirmation}
        title="Agregar monto manual"
        onClose={toggleShowConfirmation}
        onCancel={toggleShowConfirmation}
        onAccept={formik.submitForm}
        loading={isLoading}
        withBlur
        centered
        size="lg"
      >
        <FormikProvider value={formik}>
          <Flex column gap={20}>
            <Flex gap={20}>
              <FormikTextInput
                data-cy="totalIncome"
                leftIcon="$"
                name="totalIncome"
                label="Monto de ingreso"
                type="number"
              />
              <FormikKontaSelect
                placeholder="Selecciona una opción"
                id="iva_rate"
                options={IVA_OPTIONS}
                name="iva_rate"
                label="Selecciona el IVA del ingreso"
              />
            </Flex>

            <Flex gap={20}>
              <FormikTextInput
                data-cy="zero_base"
                leftIcon="$"
                name="zero_base"
                label="Monto de total ingreso con el 0% de IVA"
                type="number"
              />
              <FormikTextInput
                data-cy="exempt_base"
                leftIcon="$"
                name="exempt_base"
                label="Monto de ingreso con IVA exento"
                type="number"
              />
            </Flex>
            <Flex gap={20}>
              <FormikKontaSelect
                placeholder="Selecciona una opción"
                id="select_fiscal_regime"
                name="select_fiscal_regime"
                label="Régimen del ingreso"
                isDisabled={!!taxableEntityPreferences?.preferred_fiscal_regime}
                options={regimeOptionsWithDeclarationId}
              />
              <FormikTextarea
                placeholder="Selecciona una opción"
                id="description"
                name="description"
                label="Descripción del ingreso"
              />
            </Flex>
          </Flex>
        </FormikProvider>
      </ConfirmModal>
    </>
  );
}
