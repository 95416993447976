import { styled } from '@konta/ui';

// eslint-disable-next-line import/prefer-default-export
export const Root = styled('div', {
  display: 'flex',
  marginTop: '$16',
  flexDirection: 'column',
  width: '100%',
  flex: 1,
});
