import React, { useState } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Input } from 'reactstrap';
import { toCurrency as $, getMonthAndYearDate } from '@util/Utils';
import classnames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { declarationEntriesUpdate } from '@redux/actions';
import { TablePagination, CustomModal, PDFModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

function IncomesTable({ data, loading, workflow }) {
  const dispatch = useDispatch();
  // edit classification date changeMonthOpen
  const [changeMonthObj, setChangeMonthObj] = useState({});
  const [changeMonthOpen, setChangeMonthOpen] = useState(false);

  // edit classification date
  const handleEditMonth = (date, platform_entry) => {
    const newAccountingDate = `${date}-01`;
    setChangeMonthObj({
      date: newAccountingDate,
      platform_entry,
    });
    setChangeMonthOpen(true);
  };

  const toggleChangeMonthDate = (e) => {
    setChangeMonthOpen(!true);
  };

  const handleDateChangeSubmit = () => {
    const body = {
      platform_entry: {
        accounting_date: changeMonthObj.date,
      },
    };
    const workflow_id = workflow.id;
    const platform_entries_id = changeMonthObj.platform_entry.id;
    const successMessage = 'Se actualizó la fecha con éxito';
    const successTitle = 'Fecha actualizada';
    dispatch(
      declarationEntriesUpdate({
        workflow_id,
        platform_entries_id,
        body,
        successTitle,
        successMessage,
      }),
    );
    toggleChangeMonthDate();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <span className="list-item-heading text-capitalize">
            Emisor/Descripción
          </span>
        ),
        accessor: 'cfdi.receiver_legal_name',
        Cell: (props) => {
          return props.value ? (
            <p className="list-item-heading"> {props.value} </p>
          ) : props.row.original.description ? (
            <p className="list-item-heading">
              {' '}
              {props.row.original.description}{' '}
            </p>
          ) : null;
        },
      },
      {
        Header: 'RFC',
        accessor: 'cfdi.issuer_rfc',
        Cell: (props) => (
          <p className="list-item-heading text-capitalize"> {props.value} </p>
        ),
      },
      {
        Header: 'Fecha de emisión',
        accessor: 'cfdi.date_issued',
        Cell: (props) => {
          return <p className="list-item-heading">{props.value}</p>;
        },
      },
      {
        Header: 'Fecha a considerar para la contabilidad',
        accessor: 'accounting_date',
        Cell: (props) => {
          return (
            <Input
              type="month"
              name="date"
              value={props.row.original.accounting_date.slice(0, -3)}
              onChange={(e) =>
                handleEditMonth(e.target.value, props.row.original)
              }
              className="w-80"
            />
          );
        },
      },
      {
        Header: 'Total',
        accessor: 'amount',
        Cell: (props) => {
          const amount = props.row.original.cfdi
            ? props.row.original.cfdi.total
            : props.value;
          return (
            <p className="list-item-heading">{`${$(parseFloat(amount))}`}</p>
          );
        },
      },
      {
        Header: 'Documento',
        accessor: 'doc',
        width: 130,
        Cell: ({ row: { original } }) => {
          const { xml_url, date_issued, issuer_legal_name, id, status } =
            original.cfdi || {};
          return xml_url ? (
            <PDFModal
              url={xml_url}
              title={`${issuer_legal_name} ${date_issued}`}
              id={id}
              status={status}
              invoice={original}
            />
          ) : original.documents_url ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={original.documents_url}
            >
              <i className="iconsmind-Download mr-3 font-weight-bold" />
              Descargar
            </a>
          ) : null;
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination,
  );
  const noDataText = 'No hay registros que mostrar';
  const divided = false;
  return (
    <>
      <table
        {...getTableProps()}
        className={`r-table table ${classnames({ 'table-divided': divided })}`}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sorted-desc'
                        : 'sorted-asc'
                      : ''
                  }
                >
                  {column.render('Header')}
                  <span />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.length > 0 && !loading ? (
            page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      key={`td_${cellIndex}`}
                      {...cell.getCellProps({
                        className: cell.column.cellClass,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <>
              {[...Array(pageSize)].map((row, rowIndex) => {
                return (
                  <tr role="row" key={`row-${rowIndex}`}>
                    {[...Array(columns.length)].map((row, cellIndex) => {
                      return (
                        <td role="cell" key={`cell-${cellIndex}`}>
                          <div className="list-item-heading"> </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
      {page.length === 0 && <div className="rt-noData">{noDataText}</div>}
      <div className={`rt-loading ${loading ? 'rt-active' : ''}`}>
        <div className="rt-loading-inner">Cargando...</div>
      </div>

      <TablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[4, 10, 20, 30, 40, 50]}
        showPageSizeOptions
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />

      <CustomModal
        title="Cambiar fecha de consideración en la contabilidad"
        toggle={toggleChangeMonthDate}
        isOpen={changeMonthOpen}
        handleValidSubmit={handleDateChangeSubmit}
        submitText="Guardar"
      >
        <div className="m-auto text-center">
          <i className="large-icon iconsmind-Danger" color="danger" />
          <h2>
            ¡Está por cambiar la fecha de esta factura para ser considerada en
            la contabilidad de{' '}
            <span className="font-weight-bold">
              {getMonthAndYearDate(new Date(changeMonthObj?.date))}
            </span>
            !
          </h2>
          <h3 className="font-weight-bold mb-0">¿Qué implica esto?</h3>
          <p className="mb-0">
            Esto quiere decir que será contemplada para otro mes.
          </p>
          <HelpCenterLink />
        </div>
      </CustomModal>
    </>
  );
}

const mapStateToProps = ({ workflows }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { workflow, wloading };
};
export default connect(mapStateToProps, { declarationEntriesUpdate })(
  IncomesTable,
);
