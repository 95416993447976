import React from 'react';
import { Card, Row, CardTitle } from 'reactstrap';
import { Colxx as Col } from '@components/CustomBootstrap';
import IntlMessages from '@util/IntlMessages';
import { toCurrency as $, sumAll } from '@util/Utils';

export function FiscalPeriodInvoice({ fiscal_period: fp, loading = false }) {
  if (loading) {
    return (
      <Card
        body
        className="d-flex justify-content-center align-items-center p-4"
      >
        <div className="m-4">Cargando...</div>
      </Card>
    );
  }
  if (fp) {
    const {
      total_income = 0,
      total_expenses = 0,
      id,
      taxable_entity_id,
      ...rest
    } = fp;
    const {
      iva_total = 0,
      isr_total = 0,
      isr_over_salary = 0,
      taxes_over_payroll = 0,
      imss = 0,
      afore = 0,
      infonavit = 0,
    } = rest;
    return (
      <Card body className="my-2 inner-card">
        <CardTitle tag="h4">
          <IntlMessages id="reports.resume.title" />
        </CardTitle>
        <section className="mb-3">
          <Row
            className="font-weight-bold text-primary justify-content-around"
            style={{ fontSize: '1rem' }}
          >
            <Col xxs="12" xs="6" md="5" lg="6">
              <Card
                body
                className="border-primary border rounded my-2 my-lg-0 py-2 px-4"
              >
                <div className="mt-2 mb-2" style={{ height: '40px' }}>
                  <IntlMessages id="reports.resume.ingress" />
                </div>
                <span className="text-right">{$(total_income)}</span>
              </Card>
            </Col>
            <Col xxs="12" xs="6" md="5" lg="6">
              <Card
                body
                className="border-primary border rounded my-2 my-lg-0 py-2 px-4"
              >
                <div className="mt-2 mb-2" style={{ height: '40px' }}>
                  <IntlMessages id="reports.resume.expenses" />
                </div>
                <span className="text-right">{$(total_expenses)}</span>
              </Card>
            </Col>
          </Row>
        </section>
        <section>
          <Row
            className="font-weight-bold text-primary"
            style={{ fontSize: '1rem' }}
          >
            <Col xxs={{ size: 'auto', offset: 1 }}>
              <IntlMessages id="reports.resume.title-payroll" />
            </Col>
          </Row>

          <Row>
            <Col xxs={{ size: 5 }} md={{ size: 7, offset: 2 }}>
              <IntlMessages id="reports.resume.expenses-iva" />
            </Col>
            <Col xxs={{ size: 7 }} md={{ size: 3 }}>
              {$(iva_total)}
            </Col>
          </Row>
          <Row>
            <Col xxs={{ size: 5 }} md={{ size: 7, offset: 2 }}>
              <IntlMessages id="reports.resume.expenses-isr" />
            </Col>
            <Col xxs={{ size: 7 }} md={{ size: 3 }}>
              {$(isr_total)}
            </Col>
          </Row>
          <Row>
            <Col xxs={{ size: 5 }} md={{ size: 7, offset: 2 }}>
              <IntlMessages id="reports.resume.expenses-isr-payroll" />
            </Col>
            <Col xxs={{ size: 5 }} md={{ size: 3 }}>
              {$(isr_over_salary)}
            </Col>
          </Row>

          {/* Impuestos SS  */}
          <Row
            className="font-weight-bold text-primary"
            style={{ fontSize: '1rem' }}
          >
            <Col xxs={{ size: 'auto', offset: 1 }}>
              <IntlMessages id="reports.resume.title-SS" />
            </Col>
          </Row>
          <Row>
            <Col xxs={{ size: 5 }} md={{ size: 7, offset: 2 }}>
              <IntlMessages id="reports.resume.SS-IMSS" />
            </Col>
            <Col xxs={{ size: 7 }} md={{ size: 3 }}>
              {$(imss)}
            </Col>
          </Row>
          <Row>
            <Col xxs={{ size: 5 }} md={{ size: 7, offset: 2 }}>
              <IntlMessages id="reports.resume.SS-AFORE" />
            </Col>
            <Col xxs={{ size: 7 }} md={{ size: 3 }}>
              {$(afore)}
            </Col>
          </Row>
          <Row>
            <Col xxs={{ size: 5 }} md={{ size: 7, offset: 2 }}>
              <IntlMessages id="reports.resume.SS-INFONAVIT" />
            </Col>
            <Col xxs={{ size: 7 }} md={{ size: 3 }}>
              {$(infonavit)}
            </Col>
          </Row>

          {/* Impuestos Estatales */}
          <Row
            className="font-weight-bold text-primary"
            style={{ fontSize: '1rem' }}
          >
            <Col xxs={{ size: 'auto', offset: 1 }}>
              <IntlMessages id="reports.resume.title-state" />
            </Col>
          </Row>
          <Row>
            <Col xxs={{ size: 5 }} md={{ size: 7, offset: 2 }}>
              <IntlMessages id="reports.resume.state-taxas-payroll" />
            </Col>
            <Col xxs={{ size: 7 }} md={{ size: 3 }}>
              {$(taxes_over_payroll)}
            </Col>
          </Row>
        </section>
        <hr />
        <Row>
          <Col
            xxs={{ size: 8, offset: 1 }}
            style={{ fontSize: '1rem' }}
            className="font-weight-bold"
          >
            <div>
              <IntlMessages id="reports.resume.expenses-total-final" />{' '}
            </div>
          </Col>
          <Col xxs={{ size: 3 }}>{$(sumAll(...Object.values(rest)))}</Col>
        </Row>
      </Card>
    );
  }
  return (
    <Card
      body
      className="d-flex justify-content-center align-items-center p-4 h-70"
    >
      <div className="m-4">No existe periodo fiscal para este mes.</div>
    </Card>
  );
}
