import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
} from '@konta/ui';
import { ChevronDownLineIcon } from '@konta/icons';
import { useMemo } from 'react';

interface FiltersProps {
  filters: Record<string, string>;
  setFilters: (filters: Record<string, string>) => void;
}
export default function Filters({ filters, setFilters }: FiltersProps) {
  const typeLabel = useMemo(() => {
    if (filters.type === 'accumulated') {
      return 'Acumulado';
    }
    if (filters.type === 'day' && filters.period === 'currentMonth') {
      return 'Por día';
    }
    if (filters.type === 'day' && filters.period === 'currentYear') {
      return 'Por mes';
    }
    return 'N/A';
  }, [filters]);
  return (
    <Flex gap={10} css={{ minWidth: '110px' }}>
      <DropdownMenu>
        <DropdownMenuTrigger data-disabled asChild>
          <Button
            size="xs"
            variant="outlined"
            noFill
            rightIcon={<ChevronDownLineIcon />}
          >
            {typeLabel}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={5}>
          <DropdownMenuItem
            size="xs"
            onSelect={() => {
              setFilters({ ...filters, type: 'accumulated' });
            }}
          >
            <Flex align="center">Acumulado</Flex>
          </DropdownMenuItem>
          {filters.period === 'currentMonth' ? (
            <DropdownMenuItem
              size="xs"
              onSelect={() => {
                setFilters({ ...filters, type: 'day' });
              }}
            >
              <Flex align="center">Totales</Flex>
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem
              size="xs"
              onSelect={() => {
                setFilters({ ...filters, type: 'day' });
              }}
            >
              <Flex align="center">Por mes</Flex>
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuTrigger data-disabled asChild>
          <Button
            size="xs"
            variant="outlined"
            noFill
            rightIcon={<ChevronDownLineIcon />}
          >
            {filters.period === 'currentMonth' ? 'Este mes' : 'Este año'}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={5}>
          <DropdownMenuItem
            size="xs"
            onSelect={() => {
              setFilters({ ...filters, period: 'currentMonth' });
            }}
          >
            <Flex align="center">Este mes</Flex>
          </DropdownMenuItem>
          <DropdownMenuItem
            size="xs"
            onSelect={() => {
              setFilters({ ...filters, period: 'currentYear' });
            }}
          >
            <Flex align="center">Este año</Flex>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </Flex>
  );
}
