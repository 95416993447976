import { useNavigate } from 'react-router-dom';
import { Text, Flex, Button, Divider } from '@konta/ui';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import { PROCESSES_HOME_ROUTE } from '@constants/process';
import useCurrentProcessesQuery from '@hooks/useCurrentProcessesQuery';

export default function UnfinishedFinishOnboarding() {
  const navigate = useNavigate();
  const { currentProcesses } = useCurrentProcessesQuery();

  const onboardingProcess = currentProcesses.find(
    (_process) => _process.type === 'OnboardingOnboardingProcess',
  );

  const handleClick = () => {
    navigate(PROCESSES_HOME_ROUTE);
  };

  if (!onboardingProcess?.completed) {
    return null;
  }

  return (
    <>
      <Flex direction="column" gap={12}>
        <Text x3l bold>
          Continua la apertura de tu cuenta.
        </Text>
        <Text l color="gray700">
          Para brindarte el servicio que pagaste, es importante seguir adelante
          con el proceso de apertura de cuenta. Haz clic en el botón
          &quot;Continuar apertura de cuenta&quot; para continuar.
        </Text>
        <div>
          <Button
            rightIcon={<ArrowRightIcon />}
            onClick={handleClick}
            color="primary"
            css={{ mt: '$12' }}
            data-testid="OnboardingProcessUnfinished"
          >
            Continuar apertura de cuenta
          </Button>
        </div>
      </Flex>
      <Divider />
    </>
  );
}
