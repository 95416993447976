import { AccountingStatus, DeclarationEntryType } from 'types/entities';

export const declarationEntryIncomeTypes: DeclarationEntryType[] = [
  'national_income',
  'foreign_income',
  'national_egress_issued',
  'pastmonths_cancelled_issued',
  'pastmonths_egress_cancelled_issued',
  'national_income_payment',
];

export const declarationEntryIncomesOptions = declarationEntryIncomeTypes.map(
  (type) => ({
    label: type,
    value: type,
  }),
);

export const declarationEntryIncomesFilterOptions = [
  {
    label: 'Facturas',
    value: 'cfdi',
  },
  {
    label: 'Complemento de pago',
    value: 'payment_detail',
  },
  {
    label: 'Constancia',
    value: 'constancy',
  },
  {
    label: 'Ingreso extra',
    value: 'extra_income',
  },
  {
    label: 'Facturas canceladas',
    value: 'cancelled_cfdi',
  },
];

export const declarationEntryExpenseTypes: DeclarationEntryType[] = [
  'foreign_expenses',
  'national_expenses',
  'foreign_products',
  'foreign_invoices',
  'national_egress_received',
  'pastmonths_cancelled_received',
  'pastmonths_egress_cancelled_received',
  'national_expenses_payment',
];

export const declarationEntryExpensesOptions = declarationEntryExpenseTypes.map(
  (type) => ({
    label: type,
    value: type,
  }),
);

export const declarationEntryExpensesFilterOptions = [
  {
    label: 'Facturas',
    value: 'cfdi',
  },
  {
    label: 'Notas de crédito',
    value: 'creditNote',
  },
  {
    label: 'Activos fijos',
    value: 'fixedAsset',
  },
  {
    label: 'Facturas en el extranjero',
    value: 'foreignInvoices',
  },
  {
    label: 'Pedimentos de importación',
    value: 'foreignProducts',
  },
];

export const declarationAnnualEntryExpensesTypes: DeclarationEntryType[] = [
  'foreign_invoices',
  // TODO ask oscar 'national_egress',
  'national_expenses',
];
export const generalDeclarationEntryTypes: {
  income: DeclarationEntryType[];
  expenses: DeclarationEntryType[];
} = {
  income: declarationEntryIncomeTypes,
  expenses: declarationEntryExpenseTypes,
};

export const ACCOUNTING_STATUS_OPTIONS = [
  { value: 'is_deductible', label: 'Si entra' },
  { value: 'not_deductible', label: 'No entra' },
  { value: 'not_paid', label: 'No está pagada' },
  { value: 'paid', label: 'Ya contemplada en meses pasados' },
  { value: 'fixed_asset', label: 'Activo fijo' },
  { value: 'annual', label: 'Considerado en la anual' },
  { value: 'cancelled', label: 'Cancelada' },
  { value: 'pending_classification', label: 'Pendiente de clasificación' },
  { value: 'in_review', label: 'En revisión' },
];

export const expensesAccountingStatusFilterOptions = [
  { value: 'is_deductible', label: 'Si entra' },
  { value: 'not_deductible', label: 'No entra' },
  { value: 'annual', label: 'Considerado en la anual' },
];

export const incomesAccountingStatusFilterOptions = [
  { value: 'is_deductible', label: 'Si entra' },
  { value: 'not_deductible', label: 'No entra' },
  { value: 'not_paid', label: 'Considerar el siguiente mes' },
];

export const INVOICE_TYPE_OPTIONS = [
  { value: 'cfdi', label: 'Factura' },
  { value: 'cancelled_cfdi', label: 'Factura cancelada' },
  { value: 'foreign_product', label: 'Producto Extranjero' },
  { value: 'foreign_invoice', label: 'Factura Extranjera' },
  { value: 'fixed_asset', label: 'Activo Fijo' },
  { value: 'constancy', label: 'Constancia' },
  { value: 'payment_detail', label: 'Complemento de pago' },
];

export const entryIncomeTypes = [
  'national_income',
  'foreign_income',
  'national_egress_issued',
  'pastmonths_egress_cancelled_issued',
  'pastmonths_cancelled_issued',
];

export const entryExpenseTypes = [
  'foreign_expenses',
  'national_expenses',
  'foreign_products',
  'foreign_invoices',
  'national_egress_received',
  'pastmonths_cancelled_received',
  'pastmonths_egress_cancelled_received',
];

export const accountingStatusColors: Record<
  AccountingStatus,
  [color: string, contrastColor: string]
> = {
  is_deductible: ['#ECFDF3', '#027A48'],
  not_deductible: ['#FEF3F2', '#B42318'],
  not_paid: ['#FEF6EE', '#B93815'],
  paid: ['#EEF4FF', '#3538CD'],
  fixed_asset: ['#F8F9FC', '#363F72'],
  annual: ['#F9F5FF', '#6941C6'],
  cancelled: ['#667085', '#344054'],
  pending_classification: ['#FDF2FA', '#B93815'],
  in_review: ['#EFF8FF', '#175CD3'],
};
