import React, { useMemo } from 'react';
import { Row } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { usePaymentMethods } from '@hooks';
// eslint-disable-next-line import/no-cycle
import { PaymentCards } from '@containers';
import { Colxx, NotificationManager } from '@components';
import toCurrency from '@util/toCurrency';
import { workflowPreviousPhase, workflowSetTransition } from '@redux/actions';
import useIsValidCaptureLine from '@hooks/useIsValidCaptureLine';
import useIsValidCaptureLineSalary from '@hooks/useIsValidCaptureLineSalary';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import NextPhaseConfirmation from '@components/NextPhaseConfirmation';
import WorkflowHeader from '@components/WorkflowHeader';
import Divider from '@components/Divider';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import postRequireCaptureLine from '@api/postRequireCaptureLine';
import getErrorMessage from '@util/getErrorMessage';

const onError = (error) => {
  const message = getErrorMessage(error);
  NotificationManager.error(message, 'Error', 10000);
};

function TaxesPayment({ workflow }) {
  const { total_to_pay } = workflow?.active_declaration || {};
  const {
    ui: { selected_card },
  } = usePaymentMethods();
  const dispatch = useDispatch();
  const isSalaryCaptureLineValid = useIsValidCaptureLineSalary();
  const isCaptureLineValid = useIsValidCaptureLine();

  const isExpired = !isCaptureLineValid || !isSalaryCaptureLineValid;

  const requireCaptureLine = useMutation(postRequireCaptureLine, {
    onSuccess: () => {
      NotificationManager.success(
        'Se ha solicitado la línea de captura correctamente ',
        'Línea de captura',
        3000,
      );
    },
    onError,
  });

  const handleRequireCaptureLine = () => {
    requireCaptureLine.mutate({
      declarationId: workflow.active_declaration.id,
      workflowId: workflow.id,
    });
  };

  const handlePrevPhase = () => {
    if (isExpired) {
      handleRequireCaptureLine();
      return;
    }
    dispatch(workflowSetTransition('phase-back'));
    dispatch(workflowPreviousPhase({ workflow_id: workflow.id }));
  };

  const payload = useMemo(
    () => ({
      workflow: {
        next_phase: 'primary_phase',
        card_id: selected_card?.id,
      },
    }),
    [selected_card?.id],
  );

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader title="Selecciona una tarjeta y paga tus impuestos" />
      }
      actions={
        <>
          <PrevPhaseButton />
          {!isExpired ? (
            <NextPhaseConfirmation
              trigger={({ onClick }) => (
                <PrimaryPhaseButton onClick={onClick}>Pagar</PrimaryPhaseButton>
              )}
              payload={payload}
              title="Confirmación de pago"
              acceptText="Confirmar"
            >
              <div className="m-auto d-flex flex-column justify-content-center text-center">
                <i className="large-icon iconsmind-Danger" color="danger" />
                <p>
                  Al dar click en &quot;Confirmar&quot; pagarás tus impuestos.
                </p>
              </div>
            </NextPhaseConfirmation>
          ) : (
            <PrimaryPhaseButton onClick={handlePrevPhase}>
              Solicitar otra línea de captura
            </PrimaryPhaseButton>
          )}
        </>
      }
    >
      <PaymentCards filter />

      <Divider />

      <Row>
        <Colxx
          xxs={{ size: 8, offset: 1 }}
          style={{ fontSize: '1rem' }}
          className="font-weight-bold mb-4"
        >
          <div>Total</div>
        </Colxx>
        <Colxx xxs={{ size: 3 }}>{toCurrency(+total_to_pay)}</Colxx>
      </Row>
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ workflows }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { workflow, wloading };
};

export default connect(mapStateToProps)(TaxesPayment);
