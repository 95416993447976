import { ComponentProps, ElementRef, forwardRef, ReactNode } from 'react';
import { styled } from '@konta/ui';
import type * as Stitches from '@stitches/react';
import es from 'date-fns/locale/es';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);

const IconContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  svg: {
    width: 'var(--icon-size)',
    height: 'var(--icon-size)',
  },
  path: {
    fill: '$gray400',
  },
});

export const StyledDate = styled(DatePicker, {
  border: 'none',
  outline: 'none',
  color: 'inherit',
  padding: '0px',
  width: '100%',
  background: 'transparent',
  fontSize: 'inherit',
  '&::placeholder': {
    color: '$gray400',
  },
});

const DateRoot = styled('div', {
  display: 'flex',
  gap: '$4',
  boxSizing: 'border-box',
  borderRadius: '$m',
  padding: '0 var(--padding-x)',
  '.react-datepicker': {
    fontFamily: '$inter',
  },
  '.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover':
    {
      backgroundColor: '$primary300',
    },
  '.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected':
    {
      backgroundColor: '$primary500',
    },
  '.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover':
    {
      backgroundColor: '$primary300',
    },
  '.react-datepicker-wrapper': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  '.react-datepicker__header': {
    backgroundColor: '$gray100',
  },
  variants: {
    disabled: {
      true: {
        backgroundColor: '$gray100',
        color: '$gray400 !important',
        border: '1px solid $gray300 !important',
        '--border-color': '$colors$gray300 !important',
        '--icon-color': '$colors$gray300 !important',
        '--action-color': '$colors$gray100 !important',
      },
      false: {},
    },
    color: {
      gray: {
        '--border-color': '$colors$gray300',
        '--icon-color': '$colors$gray400',
        '--action-color': '$colors$gray100',
        border: '1px solid $gray300',
        '&:focus-within': {
          '--border-color': '$colors$primary500',
          border: '1px solid $primary500',
          customBoxShadow: '$colors$primary200',
        },
      },
      primary: {
        '--border-color': '$colors$primary500',
        '--icon-color': '$colors$primary400',
        '--action-color': '$colors$primary100',
        border: '1px solid $primary500',
        '&:focus-within': {
          customBoxShadow: '#CDDBFE',
        },
      },
      success: {
        '--border-color': '$colors$success500',
        '--icon-color': '$colors$success400',
        '--action-color': '$colors$success100',
        border: '1px solid $success500',
        '&:focus-within': {
          customBoxShadow: '#A7F3D0',
        },
      },
      warning: {
        '--border-color': '$colors$warning500',
        '--icon-color': '$colors$warning400',
        '--action-color': '$colors$warning100',
        border: '1px solid $warning500',
        '&:focus-within': {
          customBoxShadow: '#FED7AA',
        },
      },
      error: {
        '--border-color': '$colors$error500',
        '--icon-color': '$colors$error400',
        '--action-color': '$colors$error100',
        border: '1px solid $error500',
        '&:focus-within': {
          customBoxShadow: '#FECACA',
        },
      },
    },
    size: {
      xs: {
        height: '$26',
        fontSize: '$xs',
        '--padding-x': '$space$10',
        '--icon-size': '$sizes$14',
      },
      s: {
        height: '$32',
        fontSize: '$s',
        '--padding-x': '$space$12',
        '--icon-size': '$sizes$16',
      },
      m: {
        height: '$40',
        fontSize: '$m',
        '--padding-x': '$space$14',
        '--icon-size': '$sizes$18',
      },
      l: {
        height: '$48',
        fontSize: '$l',
        '--padding-x': '$space$16',
        '--icon-size': '$sizes$20',
      },
    },
    hasRightActions: {
      true: {
        paddingRight: '0',
      },
    },
    hasLeftActions: {
      true: {
        paddingLeft: '0',
      },
    },
  },
  defaultVariants: {
    size: 's',
    color: 'gray',
  },
});

export type DateRootVariants = Stitches.VariantProps<typeof DateRoot>;

export interface InputWrapperProps
  extends Omit<ReactDatePickerProps, 'color' | 'css' | 'size' | 'ref'>,
    Omit<DateRootVariants, 'disabled'>,
    Pick<ComponentProps<typeof DateRoot>, 'color'> {
  inputCss?: Stitches.CSS;
  leftIcon?: ReactNode;
  leftActions?: ReactNode;
  rightIcon?: ReactNode;
  rightActions?: ReactNode;
}

const DatePickerBase = forwardRef<
  ElementRef<typeof StyledDate>,
  InputWrapperProps
>(
  (
    {
      size = 's',
      color = 'gray',
      disabled,
      leftIcon,
      rightIcon,
      leftActions,
      rightActions,
      inputCss,
      ...props
    },
    forwardedRef,
  ) => (
    <DateRoot
      disabled={disabled}
      size={size}
      color={color}
      hasRightActions={!!rightActions}
      hasLeftActions={!!leftActions}
      css={inputCss}
    >
      {!!leftIcon && <IconContainer>{leftIcon}</IconContainer>}
      <StyledDate
        disabled={disabled}
        ref={forwardedRef}
        locale="es"
        dateFormat={props.dateFormat || 'dd/MM/yyyy'}
        {...props}
      />
      {!!rightIcon && <IconContainer>{rightIcon}</IconContainer>}
    </DateRoot>
  ),
);

DatePickerBase.defaultProps = {
  leftIcon: null,
  rightIcon: null,
  rightActions: null,
};

DatePickerBase.displayName = 'DatePickerBase';

export default DatePickerBase;
