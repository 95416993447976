import React from 'react';
import useFeature from '@hooks/useFeature';
import { CUSTOM_VALUE } from '@constants/featureFlags';
import NewForeignProductForm from './NewForeignProductForm';
import ForeignProductForm from './ForeignProductForm';

export default function ConditionalForeignProductForm(props) {
  const [isCustomsValueEnabled] = useFeature(CUSTOM_VALUE);

  return isCustomsValueEnabled ? (
    <NewForeignProductForm {...props} />
  ) : (
    <ForeignProductForm {...props} />
  );
}
