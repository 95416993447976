export const ThemeColors = () => {
  const rootStyle = getComputedStyle(document.body);
  return {
    themeColor1:
      rootStyle.getPropertyValue('--theme-color-1').trim() || '#145388',
    themeColor2:
      rootStyle.getPropertyValue('--theme-color-2').trim() || '#2a93d5',
    themeColor4:
      rootStyle.getPropertyValue('--theme-color-4').trim() || '#6c90a1',
    themeColor3:
      rootStyle.getPropertyValue('--theme-color-3').trim() || '#365573',
    themeColor5:
      rootStyle.getPropertyValue('--theme-color-5').trim() || '#47799a',
    themeColor6:
      rootStyle.getPropertyValue('--theme-color-6').trim() || '#8e9599',
    themeColor1_10:
      rootStyle.getPropertyValue('--theme-color-1-10').trim() ||
      'rgba(20,83,136,0.1)',
    themeColor2_10:
      rootStyle.getPropertyValue('--theme-color-2-10').trim() ||
      'rgba(54,85,115,0.1)',
    themeColor3_10:
      rootStyle.getPropertyValue('--theme-color-3-10').trim() ||
      'rgba(108,144,161,0.1)',
    themeColor4_10:
      rootStyle.getPropertyValue('--theme-color-3-10').trim() ||
      'rgba(108,144,161,0.1)',
    themeColor5_10:
      rootStyle.getPropertyValue('--theme-color-3-10').trim() ||
      'rgba(108,144,161,0.1)',
    themeColor6_10:
      rootStyle.getPropertyValue('--theme-color-3-10').trim() ||
      'rgba(108,144,161,0.1)',
    primaryColor:
      rootStyle.getPropertyValue('--primary-color').trim() || '#212121',
    foregroundColor:
      rootStyle.getPropertyValue('--foreground-color').trim() || 'white',
    separatorColor:
      rootStyle.getPropertyValue('--separator-color').trim() || '#d7d7d7',
  };
};
