import React from 'react';
import {
  Text,
  Flex,
  Button,
  Chip,
  Icon,
  CircularLoading,
  Box,
} from '@konta/ui';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import formatPhoneNumber from '@util/formatPhoneNumber';
import getErrorMessage from '@util/getErrorMessage';
import { NotificationManager } from '@components';
import useInvitedReferralsPagination from './useInvitedReferralsPagination.ts';
import RemindInvitationButton from './RemindInvitationButton';

export default function InvitedReferrals() {
  const { loading, items, hasNext, next, hasPrev, prev, refetch } =
    useInvitedReferralsPagination();

  const onRemind = () => {
    NotificationManager.success(
      'Se ha mandado un recordatorio correctamente',
      'Listo',
      3000,
    );
  };
  const onRemindError = (error) => {
    if (error?.response.status === 404) {
      NotificationManager.error('El referido ya se registró', 'Error', 10000);
      refetch();
    } else {
      const message = getErrorMessage(error);
      NotificationManager.error(message, 'Error', 10000);
    }
  };

  return (
    <Flex column css={{ flex: '1' }}>
      <Flex column css={{ marginBottom: '$24' }}>
        <Text color="gray700" bold l>
          Invitaciones enviadas
        </Text>
        <Text color="gray500" s>
          ¿A quien quieres recordar?
        </Text>
      </Flex>
      {loading ? (
        <Flex justify="center">
          <CircularLoading xl />
        </Flex>
      ) : (
        <Flex css={{ overflowX: 'auto' }}>
          <Box css={{ flex: '1' }}>
            {items.map(({ id, status, email, phone_number }) => {
              return (
                <Flex
                  key={id}
                  gap={12}
                  justify="between"
                  css={{ marginBottom: '$16' }}
                >
                  <Text color="gray700" m>
                    {email || formatPhoneNumber(phone_number)}
                  </Text>
                  <Flex gap={12}>
                    {status === 'sent' && (
                      <RemindInvitationButton
                        invitationId={id}
                        onSuccess={onRemind}
                        onError={onRemindError}
                      />
                    )}
                    {status === 'accepted' && (
                      <Chip color="primary100" size="s" label="Registrado" />
                    )}
                    {status === 'onboarding' && (
                      <Chip color="primary100" size="s" label="En proceso" />
                    )}
                    {status === 'completed' && (
                      <Chip color="success100" size="s" label="Completado" />
                    )}
                    {status === 'rejected' && (
                      <Chip color="gray100" size="s" label="Rechazado" />
                    )}
                  </Flex>
                </Flex>
              );
            })}
          </Box>
        </Flex>
      )}
      <Flex justify="between">
        <Button icon variant="outlined" disabled={!hasPrev} onClick={prev}>
          <Icon css={{ display: 'flex' }}>
            <ChevronLeftIcon />
          </Icon>
        </Button>
        <Button icon variant="outlined" disabled={!hasNext} onClick={next}>
          <Icon css={{ display: 'flex' }}>
            <ChevronRightIcon />
          </Icon>
        </Button>
      </Flex>
    </Flex>
  );
}
