import TagManager from 'react-gtm-module';

const isProd = process.env.REACT_APP_ENV === 'PRODUCTION';

export default function gtmEvent(eventName, action) {
  if (isProd) {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        appAction: action,
        // other
      },
    });
  }
}
