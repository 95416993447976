import { useSelector, shallowEqual } from 'react-redux';

export const useTaxableEntity = () => {
  const taxableEntity = useSelector(
    (state) => state.taxableEntity,
    shallowEqual,
  );

  return {
    ...taxableEntity,
  };
};
