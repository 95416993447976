import {
  FISCAL_REGIMENS_FETCH,
  FISCAL_REGIMENS_FETCH_FAILURE,
  FISCAL_REGIMENS_FETCH_SUCCESS,
} from '@constants/actionTypes';

const INIT_STATE = {
  fiscal_regimens: [],
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FISCAL_REGIMENS_FETCH:
      return {
        ...state,
        loading: true,
      };
    case FISCAL_REGIMENS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        fiscal_regimens: action.payload,
      };
    case FISCAL_REGIMENS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return { ...state };
  }
};
