import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, ImageVideoModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

function AddForeignExpence() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />

      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p className="font-weight-bold">Gastos no deducibles</p>
          <p>
            Son aquellos que no se consideran en el cálculo del beneficio neto
            antes de impuestos. Cuando un gasto no se encuentra relacionado
            directamente con la actividad de la empresa, o cuando no tiene un
            comprobante fiscal que lo justifique, no se podrá considerar en la
            determinación del resultado fiscal.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink>Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
      <Row>
        <Colxx md="12">
          <p className="font-weight-bold">Pedimento Aduanal</p>
          <p>
            Comprobante fiscal que se transmite respecto a todos los factores
            relevantes a la entrada y salida de mercancías del territorio
            nacional. Su emisión avala que todas las contribuciones requeridas
            hayan sido pagadas.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink>Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
    </>
  );
}
export default AddForeignExpence;
