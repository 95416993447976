import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import PreviewDocument from '@components/PreviewDocument';
import { ButtonConfirmModal } from '@components/Modal';
import { useCfdis, useTaxableEntity } from '@hooks';
import { updateTaxableEntity, addressUpdate } from '@redux/actions';
import { useDispatch } from 'react-redux';

function PreviewPdfViewer(props) {
  const { loading, handlePostCfdi } = useCfdis();
  const { taxable_entity } = useTaxableEntity();
  const dispatch = useDispatch();
  const stampCfdi = () => {
    const {
      receipt,
      updateLegalType,
      updateAddress,
      newLegalType,
      newPostCode,
    } = props;
    if (updateLegalType)
      dispatch(
        updateTaxableEntity(
          { taxable_entity: { legal_type: newLegalType } },
          taxable_entity.id,
          { silent: true },
        ),
      );
    if (updateAddress)
      dispatch(addressUpdate({ postcode: newPostCode }, { silent: true }));

    handlePostCfdi(receipt);
  };
  return (
    <Row>
      <Col md={9}>
        <div className="embed-responsive h-100 embed-responsive-16by9">
          <BlobProvider document={props.children}>
            {({ url, loading: loadingBlob }) => (
              <PreviewDocument
                defaultFile={url}
                loading={loadingBlob}
                maxWidth={800}
              />
            )}
          </BlobProvider>
        </div>
      </Col>
      <Col md={3}>
        <Row className="pt-2 align-items-center justify-content-between">
          <ButtonConfirmModal
            buttonClassName="mb-2"
            buttonColor="primary"
            handleSubmit={stampCfdi}
            cancelButton="Cancelar"
            confirmButton="Confirmar"
            buttonLabel="Emitir Factura"
            isButtonDisabled={loading}
            loading={loading}
          >
            <i className="large-icon iconsmind-Danger" color="danger" />
            <p>¡Estas a punto de timbrar esta factura!</p>
          </ButtonConfirmModal>
        </Row>
        <Row className="align-items-center justify-content-between">
          <PDFDownloadLink
            fileName={`vista-previa-${props.receipt.receipt.receiver_attributes.rfc}.pdf`}
            document={props.children}
          >
            {({ blob, url, loading, error }) => {
              return error ? null : (
                <Button
                  color="primary"
                  size="sm"
                  className="mr-2"
                  download="image2"
                >
                  {loading ? '...Cargando' : 'Descargar PDF'}
                </Button>
              );
            }}
          </PDFDownloadLink>
        </Row>
      </Col>
    </Row>
  );
}

PreviewPdfViewer.propTypes = {
  children: PropTypes.any,
  url: PropTypes.any,
};

export { PreviewPdfViewer };
