import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { NotificationContainer } from '@components/Notifications';
import useFeature from '@hooks/useFeature';
import { defaultStartPath } from '@constants/defaultValues';
import AppLocale from '@lang';
import AppRoutes from '@routes/app';
import Onboarding from '@routes/onboarding';
import Login from '@routes/user/Login';
import ForgotPassword from '@routes/user/ForgotPassword';
import NewPassword from '@routes/user/NewPassword';
import Register from '@routes/user/Register';
import { Error404 } from '@routes/error';
import Invoice from '@routes/public/Invoice';
import Designs from '@routes/public/Designs/Designs';
import NewClient from '@routes/public/NewClient';
import PublicRoute from '@routes/PublicRoute';
import useTracker from '@hooks/useTracker';
import useCookieToken from '@hooks/useCookieToken';
import useImpersonation from '@hooks/useImpersonation';
import { PUBLIC_INVOICE } from '@constants/featureFlags';
import 'react-datepicker/dist/react-datepicker.css';
import '@assets/css/vendor/bootstrap.min.css';
import '@silevis/reactgrid/styles.css';
import '@assets/css/vendor/reactgrid.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '@assets/css/sass/themes/gogo.light.blue.scss';
import '@konta/ui/dist/style.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import useChangelogWidgetScript from '@hooks/useChangelogWidgetScript';
import ScheduleCallProvider from '@providers/ScheduleCallProvider';
import useUTMs from '@hooks/useUTMs';
import useSSO from '@hooks/useSSO';

export default function App() {
  useTracker();
  useCookieToken();
  useChangelogWidgetScript();
  useUTMs();
  const loadingImpersonation = useImpersonation();
  const loadingSSO = useSSO();
  const [publicInvoiceEnabled] = useFeature(PUBLIC_INVOICE);
  const location = useLocation();
  const locale = useSelector(({ settings }) => settings.locale);
  if (loadingImpersonation || loadingSSO) return null;

  const currentAppLocale = AppLocale[locale];
  if (
    location.pathname === '/' ||
    location.pathname === '/app' ||
    location.pathname === '/app/'
  ) {
    return <Navigate to={defaultStartPath} />;
  }

  return (
    <ScheduleCallProvider>
      <NotificationContainer />
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Routes>
          <Route path="/app/*" element={<AppRoutes />} />
          <Route path="/onboarding/*" element={<Onboarding />} />
          <Route
            path="/new-client/:taxableEntityUuid"
            element={<NewClient />}
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/new-password"
            element={
              <PublicRoute>
                <NewPassword />
              </PublicRoute>
            }
          />
          {publicInvoiceEnabled && (
            <Route
              path="/invoice"
              element={
                <PublicRoute>
                  <Invoice />
                </PublicRoute>
              }
            />
          )}
          <Route path="/designs/*" element={<Designs />} />
          <Route path="/error" element={<Error404 />} />
          <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
      </IntlProvider>
    </ScheduleCallProvider>
  );
}
