import React, { useState } from 'react';
import { Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { declarationEntriesCreate } from '@redux/actions';
import { ModalContent, Button, Text, Flex } from '@konta/ui';
import Dropzone from '@components/Dropzone';

export default function ConstanciesModalForm({
  toggle,
  isOpen,
  workflow,
  refetchConstancies,
}) {
  const dispatch = useDispatch();
  const { id, active_declaration } = workflow;

  const [constanciesFileState, setConstanciesFileState] = useState([]);

  const handleUploadConstanciesSubmit = () => {
    if (constanciesFileState.length === 0) return;
    const body = new FormData();

    constanciesFileState.forEach((file) => {
      body.append('declaration_entry[source_attributes][file]', file);
    });
    body.append('declaration_entry[accounting_status]', 'is_deductible');
    body.append('declaration_entry[source_type]', 'Constancy');
    body.append(
      'declaration_entry[accounting_date]',
      active_declaration.start_date,
    );
    body.append('declaration_entry[declaration_id]', active_declaration.id);
    const successMessage = 'Se agregó la constancia con éxito';
    const successTitle = 'Ingreso agregado';
    dispatch(
      declarationEntriesCreate({
        id,
        body,
        successMessage,
        successTitle,
        callback: () => refetchConstancies?.(),
      }),
    );

    toggle();
    setConstanciesFileState([]);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      width="auto"
      style={{ width: 'min-width: 976px' }}
    >
      <ModalHeader toggle={toggle}>Subir constancia</ModalHeader>
      <ModalContent>
        <Flex direction="column" gap="10">
          <Text>
            ¡Agregar una constancia de retención es fácil! Sólo tienes que
            seleccionar el archivo (.xml) desde el botón de abajo o simplemente
            arrastrando el archivo.
          </Text>
          <Dropzone
            fileState={constanciesFileState}
            setFileState={setConstanciesFileState}
            maxFiles={1}
            filesTypes={['.xml']}
          />
        </Flex>
      </ModalContent>
      <ModalFooter>
        <Button onClick={handleUploadConstanciesSubmit} color="primary">
          Guardar y cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
