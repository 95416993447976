import React, { useState } from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
} from 'reactstrap';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';

import axios from 'axios';
import { NotificationManager } from '@components/Notifications';
import { useTaxableEntity } from '@hooks';

function FeedbackModal({ clickText = 'Click aquí' }) {
  const {
    taxable_entity: { id, legal_name },
  } = useTaxableEntity();
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = (event, values, errors) => {};

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleValidSubmit = (event, values, errors) => {
    const feedback = {
      comment: values.feedback,
      taxable_entity_id: id,
      legal_name,
    };
    process.env.REACT_APP_ENV !== 'PRODUCTION' &&
      axios
        .post(
          'https://hooks.zapier.com/hooks/catch/6665294/b6tdvnx/silent/',
          JSON.stringify(feedback),
        )
        .then((response) => {
          toggle();
          NotificationManager.success(
            'Retroalimentación enviada correctamente',
            'Gracias!',
            3000,
            null,
            null,
          );
        })
        .catch((e) => {
          console.log(e);
          NotificationManager.success(
            'Error al enviar la retroalimentación',
            'Ups...',
            3000,
            null,
            null,
          );
        });
  };

  return (
    <>
      <span className="clickable-text-color" onClick={toggle}>
        {clickText}
      </span>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <AvForm onSubmit={handleSubmit} onValidSubmit={handleValidSubmit}>
          <ModalHeader toggle={toggle}>Sugerencias y comentarios</ModalHeader>
          <hr className="m-0" />
          <ModalBody>
            <AvGroup>
              <Label htmlFor="feedback">
                ¿Cuál es la sugerencia? ¡Gracias por compartir! Le ponemos un
                tag a todas las sugerencias para contemplarlas en nuestro
                siguiente plan de trabajo.
              </Label>
              <AvInput
                required
                className="resize: none;"
                name="feedback"
                rows="5"
                type="textarea"
                placeholder="ej. Me gustaría que..., Considero que podrían..., Qué les parece si..., Cuando estoy en el paso #, me gustaría que..."
              />
            </AvGroup>
          </ModalBody>
          <hr className="m-0" />
          <ModalFooter className="d-flex justify-content-between">
            <p className="text-muted m-0">
              También nos puede mandar un mail a{' '}
              <a
                className="clickable-text-color"
                href="mailto:soporte@konta.com"
              >
                soporte@konta.com
              </a>
              .
            </p>
            <Button color="primary" size="sm" disabled={false} type="submit">
              Mandar mensaje
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </>
  );
}

export default FeedbackModal;
