import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
// import { updateTaxableEntity } from '@redux/actions';
import useUpdateTaxableEntityMutation from '@hooks/useUpdateTaxableEntityMutation';
import { NotificationManager } from '@components/Notifications';
import getErrorMessage from '@util/getErrorMessage';
import { updateTaxableEntitySuccess } from '@redux/taxable_entity/actions';
import useTaxableEntity from 'shared/hooks/useTaxableEntity';
import useSharedTaxableEntity from 'shared/hooks/useSharedTaxableEntity';
import { useTimeoutWhen } from 'rooks';

const validationSchema = yup.object().shape({
  rfc: yup.string().required(),
  ciec: yup.string().required(),
});

interface UploadCiecFormPayload {
  rfc: string;
  ciec: string;
  has_ciec: boolean;
}

const refetchIntervalTimeout = 10000;

export default function useFiscalVisorModalCheckout() {
  const [refetchInterval, setRefetchInterval] = useState(0);
  const [startLoading, setStartLoading] = useState(false);

  const dispatch = useDispatch();
  const { taxableEntityId } = useSharedTaxableEntity();
  const { taxableEntity, taxableEntityLoading, refetch } = useTaxableEntity(
    taxableEntityId,
    undefined,
    {
      refetchInterval,
      onSuccess: (data) => {
        dispatch(updateTaxableEntitySuccess(data));
      },
    },
  );

  const updateTaxableEntity = useUpdateTaxableEntityMutation({});

  const isCIECInvalid =
    taxableEntity?.ciec_status?.status === 'invalid' ||
    taxableEntity?.ciec_status?.status === 'error';

  const isCIECPending = taxableEntity?.ciec_status?.status === 'pending';
  const isCIECValid = taxableEntity?.ciec_status?.status === 'valid';
  const enabledForm = !isCIECPending || isCIECInvalid;

  const uploadCiec = async (payload: UploadCiecFormPayload) => {
    try {
      /* @ts-ignore  useUpdateTaxableEntityMutation is not TS */
      await updateTaxableEntity.mutateAsync(payload);
      await refetch();
      setRefetchInterval(refetchIntervalTimeout);
      setStartLoading(true);

      NotificationManager.success(
        'Se ha subido la CIEC correctamente',
        'Credenciales',
      );
    } catch (e) {
      let errorMessage = getErrorMessage(e) as string;
      if (errorMessage === 'ya ha sido tomado') {
        errorMessage = 'Ya existe un cliente con este RFC';
      }
      NotificationManager.error(errorMessage, 'Credenciales');
    }
  };

  useTimeoutWhen(
    () => {
      setStartLoading(false);
    },
    refetchIntervalTimeout,
    startLoading,
  );

  // cancel re-fetch if status is invalid
  useEffect(() => {
    if (isCIECInvalid || isCIECValid) {
      setRefetchInterval(0);
    }
  }, [isCIECInvalid, isCIECValid]);

  // enabled re-fetch if status is pending
  useEffect(() => {
    if (isCIECPending) {
      setRefetchInterval(refetchIntervalTimeout);
    }
  }, [isCIECPending]);

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      rfc: taxableEntity?.rfc || '',
      ciec: '',
    },
    validationSchema,
    onSubmit: (values) =>
      uploadCiec({ ...values, ciec: values.ciec.slice(0, 8), has_ciec: true }),
  });

  const isTaxableEntityCiecStatusValid =
    taxableEntity?.has_ciec && taxableEntity?.ciec_status?.status === 'valid';

  const isLoading =
    taxableEntityLoading ||
    updateTaxableEntity.isLoading ||
    isCIECPending ||
    startLoading;

  return {
    formik,
    isCIECInvalid,
    isCIECPending,
    enabledForm,
    isLoading,
    taxableEntity,
    isTaxableEntityCiecStatusValid,
  };
}
