import {
  CFDI_XML_FETCH,
  CFDI_XML_FETCH_SUCCESS,
  CFDI_XML_FETCH_FAILURE,
  CFDI_CANCEL_FAILURE,
  CFDI_CANCEL_SUCCESS,
  CFDI_CANCEL,
  CFDI_POST,
  CFDI_POST_FAILURE,
  CFDI_POST_SUCCESS,
  CFDIS_FETCH,
  CFDIS_FETCH_SUCCESS,
  CFDIS_FETCH_FAILURE,
  UI_SET_DATE_CFDIS,
  CFDIS_FETCH_BY_CLIENT,
  CFDIS_FETCH_BY_CLIENT_SUCCESS,
  CFDIS_FETCH_BY_CLIENT_FAILURE,
  CFDIS_FETCH_BY_PPDS,
  CFDIS_FETCH_BY_PPDS_SUCCESS,
  CFDIS_FETCH_BY_PPDS_FAILURE,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  CFDI_PPD_COMPLEMENT_POST,
  CFDI_PPD_COMPLEMENT_POST_SUCCESS,
  CFDI_PPD_COMPLEMENT_DELETE,
  CFDI_PPD_COMPLEMENT_DELETE_SUCCESS,
  CFDI_PPD_COMPLEMENT_DELETE_FAILURE,
  GET_COMPLEMENTS,
  GET_COMPLEMENTS_SUCCESS,
  GET_COMPLEMENTS_FAILURE,
} from '@constants/actionTypes';

const INIT_STATE = {
  cfdis: [],
  cfdis_by_client: [],
  cfdi_by_ppds: [],
  cfdiComplements: [],
  cfdi: null,
  loading: false,
  ui: {
    date: new Date(new Date().toISOString().split('T')[0]),
    cfdiReady: false,
  },
  error: null,
  isSendingEmail: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CFDI_XML_FETCH:
      return { ...state, cfdi: null, ui: { ...state.ui, cfdiReady: false } };
    case CFDI_CANCEL:
    case CFDI_POST:
    case CFDIS_FETCH:
    case CFDIS_FETCH_BY_CLIENT:
      return { ...state, loading: true };
    case CFDI_POST_SUCCESS:
    case CFDI_POST_FAILURE:
      return { ...state, loading: false };
    case CFDI_XML_FETCH_SUCCESS:
      return {
        ...state,
        cfdi: action.payload,
        ui: { ...state.ui, cfdiReady: true },
      };
    case CFDIS_FETCH_SUCCESS:
      return { ...state, loading: false, cfdis: action.payload };
    case CFDI_CANCEL_SUCCESS:
      return {
        ...state,
        cfdis: state.cfdis.map((cfdi) =>
          cfdi.id === action.payload.id ? action.payload : cfdi,
        ),
        loading: false,
      };
    case CFDIS_FETCH_BY_CLIENT_SUCCESS:
      return { ...state, loading: false, cfdis_by_client: action.payload };
    case CFDI_CANCEL_FAILURE:
    case CFDIS_FETCH_FAILURE:
    case CFDIS_FETCH_BY_CLIENT_FAILURE:
      return { ...state, loading: false };
    case CFDI_XML_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        ui: { ...state.ui, cfdiReady: false },
      };
    case UI_SET_DATE_CFDIS:
      return {
        ...state,
        ui: {
          ...state.ui,
          date: action.payload,
        },
      };
    case SEND_EMAIL:
      return {
        ...state,
        isSendingEmail: true,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isSendingEmail: false,
      };
    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        isSendingEmail: false,
      };
    case CFDIS_FETCH_BY_PPDS:
      return { ...state, loading: true, error: null };
    case CFDIS_FETCH_BY_PPDS_SUCCESS:
      return {
        ...state,
        loading: false,
        cfdi_by_ppds: action.payload.client_id,
      };
    case CFDIS_FETCH_BY_PPDS_FAILURE:
      return { ...state, loading: false, error: true };
    case CFDI_PPD_COMPLEMENT_DELETE:
      return { ...state, loading: true };
    case CFDI_PPD_COMPLEMENT_DELETE_SUCCESS:
      return {
        ...state,
        cfdi_by_ppds: state.cfdi_by_ppds.map((ppd) => {
          if (ppd.id === action.payload.ppd_id) {
            return {
              ...ppd,
              declaration_entry_payments: ppd.declaration_entry_payments.filter(
                (complement) =>
                  complement.id !== action.payload.declaration_entries_id,
              ),
            };
          }
          return { ...ppd };
        }),
        loading: false,
      };
    case CFDI_PPD_COMPLEMENT_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case CFDI_PPD_COMPLEMENT_POST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPLEMENTS:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPLEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        cfdiComplements: action.payload,
      };
    case GET_COMPLEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case CFDI_PPD_COMPLEMENT_POST_SUCCESS:
      if (action.payload.ppdId) {
        return {
          ...state,
          cfdiComplements: [
            ...state.cfdiComplements,
            ...action.payload.related_payments,
          ],
          loading: false,
        };
      }
      return {
        ...state,
        cfdi_by_ppds: state.cfdi_by_ppds.map((ppd) => {
          if (ppd.id === action.payload.source.payment_detail.ppd_id) {
            return {
              ...ppd,
              declaration_entry_payments: [
                ...ppd.declaration_entry_payments,
                action.payload,
              ],
            };
          }
          return {
            ...ppd,
          };
        }),
        loading: false,
      };
    default:
      return { ...state };
  }
};
