import { Button, Card, CardContent, Flex, Icon, Text } from '@konta/ui';
import {
  FilePlus02LineIcon,
  TrashCanOutlineIcon,
  File2OutlineIcon,
} from '@konta/icons';
import { ConfirmModal, NotificationManager } from '@components/index';
import Dropzone from 'shared/components/Dropzone';
import formatBytes from 'shared/util/formatBytes';
import FileCard from 'shared/components/FileCard';
import { ContainerButton } from '../styled';
import useUploadXmlModal from './useUploadXmlModal';

interface UploadXmlModalProps {
  toggleOpenUploadExpense: () => void;
}

export default function UploadXmlModal({
  toggleOpenUploadExpense,
}: UploadXmlModalProps) {
  const {
    toggleShowConfirmation,
    showConfirmation,
    xmlFiles,
    setXmlFiles,
    handleOnSubmit,
    selectRegimeView,
    setSelectRegimeView,
    regimeOptionsWithDeclarationId,
    isLoading,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
  } = useUploadXmlModal({ toggleOpenUploadExpense });

  const handleOnClose = () => {
    if (selectRegimeView) {
      setSelectRegimeView();
    } else {
      toggleShowConfirmation();
    }
  };

  return (
    <>
      <ContainerButton
        css={{
          padding: '24px',
          gap: '$10',
        }}
        onClick={toggleShowConfirmation}
      >
        <Icon
          css={{
            width: '64px',
            height: '64px',
            svg: {
              width: '64px',
              height: '64px',
              path: {
                fill: 'none',
                strokeWidth: '1px',
              },
            },
          }}
        >
          <FilePlus02LineIcon />
        </Icon>
        <Text
          l
          color="gray700"
          css={{
            fontSyle: 'normal',
            fontWeight: '400',
            lineHeight: '28px',
            textAlign: 'center',
          }}
        >
          Cargar XML de una factura
        </Text>
      </ContainerButton>

      <ConfirmModal
        open={showConfirmation}
        title={
          selectRegimeView ? 'Régimen del gasto' : 'Cargar XML de una factura'
        }
        loading={isLoading}
        acceptText={selectRegimeView ? 'Guardar' : 'Continuar'}
        cancelText={selectRegimeView ? 'Regresar' : 'Cerrar'}
        onClose={handleOnClose}
        onCancel={handleOnClose}
        onAccept={() => {
          if (selectRegimeView) {
            handleOnSubmit();
          } else {
            if (xmlFiles.length === 0) {
              NotificationManager.error(
                `Debes subir al menos un archivo válido`,
                'Error al subir archivos',
              );
              return;
            }
            setSelectRegimeView();
          }
        }}
        size="xl"
        withBlur
        centered
      >
        <Flex column gap={12}>
          {selectRegimeView ? (
            <>
              <Text
                color="gray700"
                l
                css={{
                  lineHeight: '28px',
                }}
              >
                ¿Qué régimen deseas seleccionar para los gastos?
              </Text>
              {xmlFiles.map((file, index) => (
                <FileCard
                  key={index}
                  defaultRegimeOption={defaultRegimeOptionBySatkey}
                  file={file}
                  xmlFiles={xmlFiles}
                  setXmlFiles={setXmlFiles}
                  regimeOptionsWithDeclarationId={
                    regimeOptionsWithDeclarationId
                  }
                  taxableEntityPreferences={taxableEntityPreferences}
                  view="expenses"
                />
              ))}
            </>
          ) : (
            <>
              <Text
                color="gray700"
                l
                css={{
                  lineHeight: '28px',
                }}
              >
                Haz clic o arrastra tus archivos al cuadro.{' '}
              </Text>
              <Dropzone
                fileState={xmlFiles}
                setFileState={setXmlFiles}
                maxFiles={20}
                filesTypes={['.xml']}
                fileTypesText="XML"
                name="xmlFiles"
              >
                {({ removeFile }) => (
                  <Flex column gap={8}>
                    {xmlFiles.map((file, index) => (
                      <Card
                        outlined
                        css={{
                          padding: '$16',
                          alignItems: 'flex-start',
                          gap: '$4',
                          alignSelf: 'stretch',
                        }}
                        key={index}
                      >
                        <CardContent
                          css={{
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            padding: '0',
                            gap: '$16',
                            position: 'relative',
                          }}
                          key={file.path}
                        >
                          <Icon
                            waves
                            css={{
                              width: '30px',
                              height: '30px',
                              flexShrink: 0,
                              border: 'solid var(--space) $primary50',
                              backgroundColor: '$primary100',
                              svg: {
                                width: '16px',
                                height: '16px',
                                path: {
                                  fill: '$primary600',
                                },
                              },
                            }}
                          >
                            <File2OutlineIcon />
                          </Icon>
                          <Flex
                            column
                            css={{
                              flex: '1',
                            }}
                          >
                            <Text size="s" color="gray700">
                              {file.path}
                            </Text>
                            <Text size="s" color="gray500">
                              {formatBytes(file.size)}
                            </Text>
                            {/* Show loader state down here */}
                          </Flex>
                          <Button
                            size="xs"
                            variant="minimal"
                            onClick={() => removeFile(file)}
                            icon
                          >
                            <TrashCanOutlineIcon />
                          </Button>
                        </CardContent>
                      </Card>
                    ))}
                  </Flex>
                )}
              </Dropzone>
            </>
          )}
        </Flex>
      </ConfirmModal>
    </>
  );
}
