import { FiscalRegime } from 'types/entities';
import getRegimeNameFromFiscalRegime from './getRegimeNameFromFiscalRegime';

export default function getRegimeAliasBySayKey(
  fiscalRegimes: FiscalRegime[],
  satKey: number,
  format:
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'start_case' = 'capitalize',
): string {
  const regime = fiscalRegimes.find(
    (fiscalRegime) => fiscalRegime.sat_key === satKey,
  );

  return regime ? getRegimeNameFromFiscalRegime(regime, true, format) : '-';
}
