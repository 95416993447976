import { Text, Flex } from '@konta/ui';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { ProcessType, TaxableEntity, User } from 'types/entities';
import { useTaxableEntity } from '@hooks';
import useUser from '@hooks/useUser';
import { NotificationManager } from '@components/Notifications';
import ProcessStepLayout from '@components/ProcessStepLayout';
import getErrorMessage from '@util/getErrorMessage';
import useProcess from '@hooks/useProcess';

const CALENDLY_URL = process.env.REACT_APP_CALENDLY_URL as string;
const onboardingProcess = ProcessType.OnboardingOnboardingProcess;

const getCalendlyIdentifiers = (url: string) => {
  const regex =
    /https:\/\/api\.calendly\.com\/scheduled_events\/([^/]+)\/invitees\/([^/]+)/;
  const matchArray = url.match(regex);

  if (!matchArray) {
    return null;
  }

  return {
    scheduledEventUuid: matchArray[1],
    inviteeUuid: matchArray[2],
  };
};

export default function OnboardingBookCall() {
  const { taxable_entity: taxableEntity } = useTaxableEntity() as {
    taxable_entity: TaxableEntity;
  };
  const { activeStep, next } = useProcess(onboardingProcess);
  const { user } = useUser() as { user: User };

  const sendEventData = async (
    payload: { scheduledEventUuid: string; inviteeUuid: string } | null,
  ) => {
    try {
      const activeStepId = activeStep?.id;
      if (!payload || !activeStepId) {
        NotificationManager.error(
          'Ocurrió un error al agendar la llamada, contacta a soporte por chat para poder agendar una llamada.',
          'Agendar llamada',
        );
        return;
      }
      await next({
        steps_attributes: [
          {
            id: activeStepId,
            answers: {
              scheduled_event_uuid: payload.scheduledEventUuid,
              invitee_uuid: payload.inviteeUuid,
            },
          },
        ],
      });
    } catch (e) {
      const errorMessage = getErrorMessage(e) as string;
      NotificationManager.error(errorMessage, 'Agendar llamada');
    }
  };

  useCalendlyEventListener({
    onEventScheduled: async (event) => {
      const uri = event?.data?.payload?.invitee?.uri;
      if (!uri) {
        NotificationManager.error(
          'Ocurrió un error al agendar la llamada',
          'Agendar llamada',
        );
        return;
      }
      const payload = getCalendlyIdentifiers(uri);
      await sendEventData(payload);
    },
  });

  return (
    <ProcessStepLayout
      currentProcessType={onboardingProcess}
      title="Apertura de cuenta"
      titleChip="En curso"
      content={
        <Flex direction="column" gap={12}>
          <Text x3l lineHeight="2xl" bold>
            Agenda una llamada con nosotros
          </Text>
          <Text l lineHeight="l" regular color="gray700">
            Durante la llamada realizaremos un diagnóstico fiscal y te
            proporcionaremos las mejores opciones para reducir el monto de
            impuestos a pagar.
          </Text>
          <InlineWidget
            prefill={{
              name: user?.first_name,
              email: user?.email,
            }}
            utm={{
              utmContent: taxableEntity?.id?.toString() || '',
            }}
            styles={{
              height: '600px',
            }}
            url={CALENDLY_URL}
            pageSettings={{
              backgroundColor: 'fff',
              hideEventTypeDetails: true,
              hideLandingPageDetails: true,
              primaryColor: '6941C6',
              textColor: '1D2939',
              hideGdprBanner: true,
            }}
          />
        </Flex>
      }
      rightSidebarContent
    />
  );
}
