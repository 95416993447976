import { call } from 'redux-saga/effects';
import { ErrorLayout, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  contactMethodDeleteSuccess,
  contactMethodDeleteFailure,
} from '../actions';

export default function* ({ payload }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(api.delete, `contact_methods/${payload}`, {
      headers: { Authorization: `${access_token}` },
    });
    response.data = payload;
    yield call(ErrorLayout, {
      response,
      status: response.status,
      success: contactMethodDeleteSuccess,
      failure: contactMethodDeleteFailure,
      title: 'Éxito',
      message: 'Se eliminó con éxito',
    });
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      message: 'Algo salió mal borrar el email o teléfono',
      action: contactMethodDeleteFailure,
    });
  }
}
