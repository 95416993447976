import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { TaxableEntityPublic } from 'types/entities';
import { getTPublicTaxableEntity } from '@api/taxableEntity';
import { PUBLIC_TAXABLE_ENTITY } from '@constants/reactQueries';

export default function usePublicTaxableEntity(
  taxableEntityUuid: string,
  axiosConfig?: AxiosRequestConfig<TaxableEntityPublic>,
  queryOptions?: UseQueryOptions<TaxableEntityPublic>,
) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<TaxableEntityPublic>(
    [PUBLIC_TAXABLE_ENTITY, taxableEntityUuid, axiosConfig],
    async () => {
      const publicTaxableEntity = await getTPublicTaxableEntity(
        taxableEntityUuid,
        axiosConfig,
      );
      return publicTaxableEntity;
    },
    queryOptions,
  );

  return {
    publicTaxableEntityLoading: isLoading || isFetching,
    publicTaxableEntity: data,
    ...rest,
  };
}
