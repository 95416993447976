import React from 'react';
import { Row, Jumbotron, Card } from 'reactstrap';
import { Colxx, Emoji } from '@components';

export function TaskInactiveJumbotron(props) {
  return (
    <Row>
      <Colxx xxs="12" className="mb-4">
        <Card body>
          <Jumbotron className="mb-0">
            <h1 className="display-4">¡Aviso!</h1>
            <p className="lead">
              Por adeudos acumulados <Emoji symbol="😔" label="disappointed" />{' '}
              lamentablemente se tendrá que suspender tu suscripción, para
              reactivar procede a pagar los adeudos y listo!{' '}
              <Emoji symbol="🥳" label="partying_face" />
            </p>
          </Jumbotron>
        </Card>
      </Colxx>
    </Row>
  );
}
