import React from 'react';
import { Row, NavLink } from 'reactstrap';
import { Colxx, ImageVideoModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

export default function WorkflowIntro() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />

      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p className="font-weight-bold">Cálculo de ISR</p>
          <p>
            Para poder determinar lo que vamos a pagar en ISR, debemos primero
            consultar las tablas de retenciones periódicas que el SAT publica.
            Puede descargar el documento oficial con todas las tablas de cálculo
            de ISR
            <span>
              <NavLink
                exact="true"
                className="pl-0 clickable-text-color font-italic"
                href="https://wwwmat.sat.gob.mx/cs/Satellite?blobcol=urldata&blobkey=id&blobtable=MungoBlobs&blobwhere=1461174825594&ssbinary=true"
                target="_blank"
              >
                aquí
              </NavLink>
            </span>
          </p>
        </Colxx>
        <Colxx md="12">
          <HelpCenterLink articleId="how_isr_is_calculated">
            Link al artículo
          </HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
      <Row>
        <Colxx md="12">
          <p className="font-weight-bold">Presentación de declaración</p>
          <p>
            Se presenta a más tardar el día 17 del mes siguiente al que
            corresponden los ingresos y gastos, con días adicionales dependiendo
            del sexto dígito de nuestro RFC.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
    </>
  );
}
