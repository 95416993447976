import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import type { TaxableEntityFlow } from 'types/flows';
import { getTaxableEntityFlows } from 'shared/api/taxableEntityFlows';
import { TAXABLE_ENTITY_FLOWS } from 'shared/constants/reactQueries';

export default function useTaxableEntityFlows(
  axiosConfig?: AxiosRequestConfig<TaxableEntityFlow[]>,
  queryOptions?: UseQueryOptions<TaxableEntityFlow[]>,
) {
  const {
    data = [],
    isLoading,
    isFetching,
    ...rest
  } = useQuery<TaxableEntityFlow[]>(
    [TAXABLE_ENTITY_FLOWS, axiosConfig],
    async () => {
      const taxableEntityFlows = await getTaxableEntityFlows(axiosConfig);
      return taxableEntityFlows;
    },
    queryOptions,
  );

  return {
    taxableEntityFlowsLoading: isLoading || isFetching,
    taxableEntityFlows: data,
    ...rest,
  };
}
