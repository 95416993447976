import { NotificationManager } from '@components';
import dayjs from 'dayjs';

const entryTypes = [
  'foreign_expenses',
  'national_expenses',
  'foreign_products',
  'foreign_invoices',
  'national_egress_received',
  'pastmonths_cancelled_received',
  'pastmonths_egress_cancelled_received',
];

const accountingStatus = ['is_deductible', 'fixed_asset'];

const isCreditNote = (entryType) =>
  entryType === 'national_egress_received' ||
  entryType === 'pastmonths_cancelled_received';

const getTotalAmount = ({
  accounting_date,
  source,
  declaration_entry_type,
}) => {
  try {
    if (!source) {
      throw new Error('La entrada no tiene source');
    }
    if (source.cfdi) {
      const total =
        +source.cfdi.iva +
        (+source.cfdi.iva_16_percent +
          +source.cfdi.iva_8_percent +
          +source.cfdi.iva_exempt +
          +source.cfdi.iva_0_percent) -
        (+source.cfdi.retained_isr + +source.cfdi.retained_iva);
      return source.cfdi.currency !== 'MXN'
        ? total *
            (source.cfdi.exchange_rate || source.cfdi.banxico_exchange_rate)
        : total;
    }
    if (
      declaration_entry_type === 'pastmonths_egress_cancelled_received' ||
      declaration_entry_type === 'pastmonths_cancelled_received'
    ) {
      const total =
        +source.cancelled_cfdi.cfdi.iva +
        (+source.cancelled_cfdi.cfdi.iva_16_percent +
          +source.cancelled_cfdi.cfdi.iva_8_percent +
          +source.cancelled_cfdi.cfdi.iva_exempt +
          +source.cancelled_cfdi.cfdi.iva_0_percent) -
        (+source.cancelled_cfdi.cfdi.retained_isr +
          +source.cancelled_cfdi.cfdi.retained_iva);
      return source.cancelled_cfdi.cfdi.currency !== 'MXN'
        ? total *
            (source.cancelled_cfdi.cfdi.exchange_rate ||
              source.cancelled_cfdi.cfdi.banxico_exchange_rate)
        : total;
    }
    if (declaration_entry_type === 'foreign_invoices') {
      return +source.foreign_invoice.amount + +source.foreign_invoice.iva;
    }
    if (declaration_entry_type === 'foreign_products') {
      return (
        (+source.foreign_product.taxable_amount +
          +source.foreign_product.igi +
          +source.foreign_product.prv +
          +source.foreign_product.dta) *
          1.16 +
        +source.foreign_product.non_taxable_amount
      );
    }
    // issued_date should be in range depreciation date
    if (
      source.fixed_asset &&
      accounting_date ===
        dayjs(source.fixed_asset.cfdi.date_issued)
          .startOf('month')
          .format('YYYY-MM-DD')
    ) {
      const total = +source.fixed_asset.cfdi.iva;
      return source.fixed_asset.cfdi.currency !== 'MXN'
        ? total *
            (source.fixed_asset.cfdi.exchange_rate ||
              source.fixed_asset.cfdi.banxico_exchange_rate)
        : total;
    }

    if (source.type === 'payment_detail') {
      return +source.payment_detail.total_with_iva;
    }

    return +source.fixed_asset.monthly_depreciation;
  } catch (error) {
    NotificationManager.error(
      'Una de las entradas no tiene source, por favor contacta a soporte',
      'Sin source',
    );
    return 0;
  }
};

export default function getTotalExpenses(declaration_entries) {
  return declaration_entries
    ? declaration_entries.reduce((sum, item) => {
        const {
          declaration_entry_type: entryType,
          accounting_status: entryStatus,
        } = item;
        if (
          entryTypes.includes(item.declaration_entry_type) &&
          accountingStatus.includes(entryStatus)
        ) {
          const amount = getTotalAmount(item);
          if (isCreditNote(entryType)) {
            return sum - amount;
          }
          return sum + amount;
        }
        return sum + 0;
      }, 0)
    : 0;
}
