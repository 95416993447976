import { ComponentProps, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Chip } from '@konta/ui';
import useTaxableEntities from 'backoffice/hooks/useTaxableEntities';
import { MeantimeProcessStep } from 'types/entities';
import useOnboardingsWrappers from 'backoffice/hooks/useOnboardingsWrappers';
import { randomColor } from 'backoffice/util/randomColor';
import useStore from 'backoffice/store';
import useParentPath from './useParentPath';

interface CustomerDetails {
  title: string;
  value: string | string[];
  hide?: boolean;
}

type ColorChip = ComponentProps<typeof Chip>['color'];

export default function useCustomer() {
  const navigate = useNavigate();
  const collapsed = useStore((state) => state.customerHeaderCollapsed);
  const { customerId, taxableEntityId: taxableEntityIdParam } = useParams();
  const parentPath = useParentPath();
  const setTaxableEntity = useStore((state) => state.setTaxableEntity);
  const { taxableEntities, taxableEntitiesLoading } = useTaxableEntities(
    {
      taxable_entity_id: customerId || taxableEntityIdParam || 0,
    },
    {
      onSuccess: (data) => {
        if (data?.length > 0) {
          setTaxableEntity(data[0]);
        }
      },
    },
  );

  const taxableEntity = useMemo(() => {
    if (taxableEntities.length > 0) {
      return taxableEntities[0];
    }

    return null;
  }, [taxableEntities]);
  const taxableEntityId = taxableEntity?.id || 0;

  const { onboardingWrappers } = useOnboardingsWrappers({
    params: {
      'q[type_eq]': 'OnboardingProcessWrapper',
      'q[taxable_entity_id_eq]': taxableEntityId,
    },
    queryOptions: {
      enabled: !!taxableEntityId && !collapsed,
    },
  });

  // User
  const name =
    taxableEntity?.user?.first_name ||
    taxableEntity?.user?.nickname ||
    'Sin nombre de contacto';
  const team = taxableEntity?.team
    ? `${
        taxableEntity.team.charAt(0).toUpperCase() +
        taxableEntity.team.substring(1).replace('_', ' ')
      }`
    : 'Sin equipo contable asignado';
  const loginEmail = taxableEntity?.user?.email || 'Sin email de login';
  const primaryPhone =
    taxableEntity?.user?.phone_number || 'Sin teléfono de contacto';
  const optRequiredLogin = taxableEntity?.user?.otp_required_for_login
    ? 'Activado'
    : 'Desactivado';

  // Taxable entity
  const legalName = taxableEntity?.legal_name || 'Sin razón social';
  const rfc = taxableEntity?.rfc || 'Sin RFC';
  const companyDescription = taxableEntity?.description || 'Sin descripción';
  const fiscalRegimes = useMemo(() => {
    if (!taxableEntity?.fiscal_regimes?.length) {
      return ['Sin regímenes fiscales'];
    }
    return taxableEntity.fiscal_regimes.map(
      (fiscalRegime) => fiscalRegime.description,
    );
  }, [taxableEntity?.fiscal_regimes]);

  const fiscalRegimesAlias = useMemo(() => {
    if (!taxableEntity?.fiscal_regimes?.length) {
      return ['Sin regímenes fiscales'];
    }
    return taxableEntity.fiscal_regimes.map(
      (fiscalRegime) => fiscalRegime.alias || fiscalRegime.description,
    );
  }, [taxableEntity?.fiscal_regimes]);

  const economicActivities = useMemo(
    () =>
      taxableEntity?.taxable_entity_economic_activities?.map(
        (activity) => activity.economic_activity_name,
      ) || [],
    [taxableEntity?.taxable_entity_economic_activities],
  );

  // Settings
  const referralCode = taxableEntity?.referal || 'Sin código de referido';
  const workflowTemplate =
    taxableEntity?.workflow_setting?.name || 'Sin plantilla de workflow';
  const appVersion = taxableEntity?.onboarding_detail?.is_rejected_user
    ? 'Búho contable'
    : 'Búho Tax';

  // Onboarding
  const onboardingSteps = useMemo(() => {
    if (onboardingWrappers.length > 0) {
      const onboardingProcess = onboardingWrappers[0];
      return onboardingProcess.processes.OnboardingOnboardingProcess.step;
    }

    return null;
  }, [onboardingWrappers]);

  const onboardingGroupedSteps = useMemo(() => {
    if (onboardingWrappers.length > 0) {
      const onboardingProcess = onboardingWrappers[0];

      const availableSteps = Object.values(
        onboardingProcess.processes.OnboardingOnboardingProcess.grouped_steps ??
          {},
      ).flat();
      return availableSteps;
    }

    return null;
  }, [onboardingWrappers]);

  const onboardingCallNotes = useMemo(() => {
    if (!onboardingGroupedSteps) {
      return 'Sin notas de llamada';
    }

    const meantimeStep = onboardingGroupedSteps.find(
      (processStep) => processStep.type === 'MeantimeOnboardingProcessStep',
    ) as MeantimeProcessStep | undefined;

    if (typeof meantimeStep?.answers !== 'string') {
      return meantimeStep?.answers.call_notes;
    }
    return 'Sin notas de llamada';
  }, [onboardingGroupedSteps]);

  const taxableEntityActivities = useMemo(() => {
    if (!(onboardingSteps?.income.answers.business_details || []).length) {
      return ['Sin actividades'];
    }
    return onboardingSteps?.income.answers.business_details.map(
      (activity) => activity,
    );
  }, [onboardingSteps]);

  const taxableEntityInvestments = useMemo(() => {
    if (!(onboardingSteps?.income.answers.investments || []).length) {
      return ['Sin inversiones'];
    }
    return onboardingSteps?.income.answers.investments.map(
      (investment) => investment,
    );
  }, [onboardingSteps]);

  const taxableEntityOccupations = useMemo(() => {
    if (!(onboardingSteps?.income.answers.occupation || []).length) {
      return ['Sin ocupaciones'];
    }
    return onboardingSteps?.income.answers.occupation.map(
      (occupation) => occupation,
    );
  }, [onboardingSteps]);

  const businessDescription =
    onboardingSteps?.income.answers.business_description ||
    'Sin descripción del negocio';

  const hasForeignIncomes = onboardingSteps?.income.answers.has_foreign_income
    ? 'Sí tiene ingresos en el extranjero'
    : 'No tiene ingresos en el extranjero';

  const commonExpenses =
    onboardingSteps?.expense.answers.common_expenses || 'Sin gastos comunes';
  const hasForeignProducts = onboardingSteps?.expense.answers
    .has_foreign_products
    ? 'Si'
    : 'No';
  const signedOnImporterList = onboardingSteps?.expense.answers
    .signed_on_importer_list
    ? 'Si'
    : 'No';

  // Details

  const userDetails: CustomerDetails[] = [
    {
      title: 'Apodo',
      value: name,
    },
    {
      title: 'Email de login',
      value: loginEmail,
    },
    {
      title: 'Teléfono de contacto',
      value: primaryPhone,
    },
    {
      title: 'Autor intelectual de la cuenta?',
      value: '',
      hide: true,
    },
    {
      title: 'Autor intelectual de la cuenta',
      value: '',
      hide: true,
    },
    {
      title: 'Autenticación de dos factores',
      value: optRequiredLogin,
    },
    {
      title: 'Idioma',
      value: '',
      hide: true,
    },
    {
      title: 'Edad avanzada',
      value: '',
      hide: true,
    },
    {
      title: 'Tech savy?',
      value: '',
      hide: true,
    },
    {
      title: 'Preferencia de comunicaciones',
      value: '',
      hide: true,
    },
  ];
  const taxableEntityDetails: CustomerDetails[] = [
    {
      title: 'Descripción de la empresa',
      value: companyDescription,
    },
    {
      title: 'Actividades',
      value: taxableEntityActivities || [],
    },
    {
      title: 'Ocupaciones',
      value: taxableEntityOccupations || [],
    },
    {
      title: 'Descripción del negocio',
      value: businessDescription,
    },
    {
      title: 'Inversiones',
      value: taxableEntityInvestments || [],
    },
    {
      title: 'Ingresos en el extranjero',
      value: hasForeignIncomes,
    },
    {
      title: 'Gastos más comunes del negcio',
      value: commonExpenses,
    },
    {
      title: '¿Compra producto en el extranjero y los vende en México?',
      value: hasForeignProducts,
    },
    {
      title: '¿Está dado de alta en el padrón de importadores?',
      value: signedOnImporterList,
    },
  ];

  const settingsDetails: CustomerDetails[] = [
    {
      title: 'Plantilla de workflow',
      value: workflowTemplate,
    },
    {
      title: 'Código de referido',
      value: referralCode,
    },
    {
      title: 'Versión de la aplicación',
      value: appVersion,
    },
  ];

  // badges
  const mainChipBadges = useMemo<
    { value: string | string[]; color: ColorChip }[]
  >(() => {
    const regimes = fiscalRegimesAlias.flat();
    const activities = economicActivities;

    const isOnImportersList = onboardingSteps?.expense.answers
      .signed_on_importer_list
      ? 'Padrón de importadores'
      : '';

    const labels = [...regimes, ...activities, isOnImportersList];

    return labels.map((label) => ({
      value: label,
      color: (randomColor(200, label) || 'gray200') as ColorChip,
    }));
  }, [economicActivities, fiscalRegimesAlias, onboardingSteps]);

  // Methods
  const goToCustomers = () => {
    navigate(parentPath);
  };

  return {
    taxableEntity,
    taxableEntityId,
    taxableEntitiesLoading,
    name,
    legalName,
    fiscalRegimes,
    rfc,
    goToCustomers,
    loginEmail,
    primaryPhone,
    team,
    referralCode,
    workflowTemplate,
    userDetails,
    settingsDetails,
    taxableEntityDetails,
    onboardingCallNotes,
    economicActivities,
    mainChipBadges,
    collapsed,
  };
}
