import React from 'react';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import FormikTextInput from '@components/FormikTextInput';
import { Flex } from '@konta/ui';
import useNextPhase from '@hooks/useNextPhase';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import getTotalsFromDeclarationIncome from 'shared/util/getTotalsFromDeclarationIncome';

const IVA_OPTIONS = [
  {
    label: 'IVA 16%',
    value: 0.16,
    key: 1,
    name: 'iva_rate_of_bank_transactions',
  },
  {
    label: 'IVA 8%',
    value: 0.08,
    key: 2,
    name: 'iva_rate_of_bank_transactions',
  },
];

const validationSchema = yup.object().shape({
  total_zero_bases_of_manual_input: yup.number(),
  total_exempt_bases_of_manual_input: yup.number(),
  total_with_iva16_of_manual_input: yup.number(),
  total_with_iva8_of_manual_input: yup.number(),
  select_iva_rate: yup.mixed(),
});

function useInitialValues(workflow) {
  const declarationIncomes = workflow?.active_declaration?.declaration_income;
  if (!declarationIncomes) {
    return {
      total_zero_bases_of_manual_input: 0,
      total_exempt_bases_of_manual_input: 0,
      total_with_iva16_of_manual_input: 0,
      total_with_iva8_of_manual_input: 0,
      select_iva_rate: IVA_OPTIONS[0],
    };
  }
  const ivaRate = +workflow.active_declaration.iva_rate;
  const {
    totalWithIva8OfManualInput,
    totalWithIva16OfManualInput,
    totalZeroBasesOfManualInput,
    totalExemptBasesOfManualInput,
  } = getTotalsFromDeclarationIncome(declarationIncomes);

  return {
    total_zero_bases_of_manual_input: totalZeroBasesOfManualInput,
    total_exempt_bases_of_manual_input: totalExemptBasesOfManualInput,
    total_with_iva16_of_manual_input: totalWithIva16OfManualInput,
    total_with_iva8_of_manual_input: totalWithIva8OfManualInput,
    select_iva_rate: IVA_OPTIONS.find((option) => option.value === ivaRate),
  };
}

function NewUploadIncomeTotal() {
  const { workflow } = useSelectedWorkflow();
  const nextPhase = useNextPhase();

  const onSubmit = (values) => {
    const {
      total_exempt_bases_of_manual_input,
      total_zero_bases_of_manual_input,
      total_with_iva16_of_manual_input,
      total_with_iva8_of_manual_input,
    } = values;
    const payload = {
      workflow: {
        next_phase: 'primary',
        total_exempt_bases_of_manual_input,
        total_zero_bases_of_manual_input,
        total_with_iva16_of_manual_input,
        total_with_iva8_of_manual_input,
      },
    };

    nextPhase(payload);
  };

  const initialValues = useInitialValues(workflow);

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Captura el total de sus ingresos"
          description="En esta sección podrás capturar los totales de ingresos de tu negocio en este mes."
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton onClick={formik.submitForm} />
        </>
      }
    >
      <div className="d-flex align-items-center w-100">
        <div className="w-100">
          <FormikProvider value={formik}>
            <Flex column gap={20} css={{ '@lg': { width: '50%' } }}>
              <Flex gap={20}>
                <FormikTextInput
                  data-cy="total_with_iva16_of_manual_input"
                  leftIcon="$"
                  name="total_with_iva16_of_manual_input"
                  label="Monto de ingresos con el 16% de IVA"
                  type="number"
                />
                <FormikTextInput
                  data-cy="total_with_iva8_of_manual_input"
                  leftIcon="$"
                  name="total_with_iva8_of_manual_input"
                  label="Monto de ingresos con el 8% de IVA"
                  type="number"
                />
              </Flex>
              <Flex gap={20}>
                <FormikTextInput
                  data-cy="total_zero_bases_of_manual_input"
                  leftIcon="$"
                  name="total_zero_bases_of_manual_input"
                  label="Monto de total ingresos con el 0% de IVA"
                  type="number"
                />
                <FormikTextInput
                  data-cy="total_exempt_bases_of_manual_input"
                  leftIcon="$"
                  name="total_exempt_bases_of_manual_input"
                  label="Monto de ingresos con IVA exento"
                  type="number"
                />
              </Flex>
            </Flex>
          </FormikProvider>
        </div>
      </div>
    </WorkflowLayout>
  );
}

export default NewUploadIncomeTotal;
