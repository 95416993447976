import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Img } from '@components';
import { ReactComponent as LogoSvg } from '@assets/img/logo.svg';

export function Locked({ children, blocking, description, url, btn }) {
  const navigate = useNavigate();

  function handleClick() {
    navigate(url);
  }

  return blocking ? (
    <Card>
      <CardBody>
        <div className="locked-container">
          <div className="locked-inner-container">
            <LogoSvg style={{ width: '64px' }} className="mb-4" />
            <p>{description}</p>
            <Button color="primary" onClick={handleClick}>
              {btn}
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  ) : (
    children
  );
}
