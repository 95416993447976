import { DateStr } from 'types/entities';
import _toCurrency from '@util/toCurrency';
import dayjs from 'dayjs';

const humanizeDate = (date: DateStr) => {
  if (!date) {
    return 'Sin fecha';
  }
  return dayjs(date).format('DD/MM/YYYY');
};

const humanizeDateTime = (date: DateStr) => {
  if (!date) {
    return 'Sin fecha';
  }
  return dayjs(date).format('DD/MM/YYYY h:mm A');
};

const humanizeMonthDate = (date: DateStr) => {
  if (!date) {
    return 'Sin fecha';
  }
  return dayjs(date).format('MMMM YYYY');
};

const toCurrency = (value: number | string) => {
  if (typeof +value !== 'number') {
    return 'Sin valor';
  }
  return _toCurrency(value);
};

export default {
  humanizeDate,
  toCurrency,
  humanizeDateTime,
  humanizeMonthDate,
};
