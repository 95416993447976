import { keyframes, styled } from '@konta/ui';

const spin = keyframes({
  to: {
    transform: 'rotate(360deg)',
  },
});

const draw = keyframes({
  '0%': {
    strokeDasharray: '20, 282.6',
  },
  '50%': {
    strokeDasharray: '200, 282.6',
  },
  '100%': {
    strokeDasharray: '20, 282.6',
  },
});

const LoadingIndicator = styled('div', {
  textAlign: 'center',
});

const BlockUIContainer = styled('div', {
  position: 'absolute',
  zIndex: 1010,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  height: '100%',
  width: '100%',
  minHeight: '2em',
  cursor: 'wait',
  overflow: 'hidden',
  '&:focus': {
    outline: 'none',
  },
});

const BlockUIOverlay = styled('div', {
  width: '100%',
  height: '100%',
  opacity: 0.75,
  filter: 'alpha(opacity=50)',
  backgroundColor: 'rgb(184, 184, 184)',
});

const BlockUIMessageContainer = styled('div', {
  position: 'absolute',
  top: '50%',
  left: 0,
  right: 0,
  textAlign: 'center',
  transform: 'translateY(-50%)',
  zIndex: 10001,
});

const BlockUIMessage = styled('div', {
  color: '#333',
  background: 'none',
  zIndex: 1011,
});

const Indicator = styled('svg', {
  width: '100px',
  height: '100px',
  position: 'absolute',
  top: 'calc(50% - 50px)',
  left: 'calc(50% - 50px)',
  animation: `${spin} 1s linear infinite`,
});

const Circle = styled('circle', {
  fill: 'none',
  stroke: '$primary400',
  strokeLinecap: 'round',
  strokeWidth: 4,
  animation: `${draw} 3s ease-in-out infinite`,
});

export {
  LoadingIndicator,
  BlockUIContainer,
  BlockUIOverlay,
  BlockUIMessageContainer,
  BlockUIMessage,
  Indicator,
  Circle,
};
