import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector, shallowEqual } from 'react-redux';
import setupMockInterceptor from 'shared/util/setupMockInterceptor';
import { type IRootState } from 'types/redux';

export default function useMockForNotSuscriptionUsers() {
  const [loading, setLoading] = useState(true);
  const queryClient = useQueryClient();
  const taxableEntityStatus = useSelector(
    (state: IRootState) => state.taxableEntity?.taxable_entity?.status,
    shallowEqual,
  );
  const taxableEntityLoading = useSelector(
    (state: IRootState) => state.taxableEntity?.loading,
  );

  useEffect(() => {
    setLoading(true);
    setupMockInterceptor(taxableEntityStatus === 'demo');
    setLoading(false);
    void queryClient.removeQueries();
  }, [taxableEntityStatus, queryClient]);
  return {
    loadingMock: loading || taxableEntityLoading,
  };
}
