import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { Button, Text, ModalContent } from '@konta/ui';
import ForeignProductForm from '@components/ForeignProductForm';

export default function ForeignProductModalForm({
  isOpen,
  onClose,
  onSubmit,
  foreignProduct,
  readOnly,
  setReadOnly,
  isAnnualProcess = false,
  withBlur = false,
  centered = false,
  isFromWorkflow = false,
  regimeOptions = [],
  defaultRegimeOption,
  taxableEntityPreferences,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const isEditingMode = Boolean(foreignProduct);
  const formikRef = useRef();
  const titleText = `${
    isEditingMode ? 'Editar' : 'Crear'
  } pedimento de importación`;

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      size="lg"
      height="full"
      centered={centered}
      {...(withBlur && {
        wrapClassName: 'modal-backdrop-blur',
      })}
    >
      <ModalHeader
        toggle={onClose}
        style={{ fontWeight: '500', fontSize: '18px', color: '$gray900' }}
      >
        {readOnly ? `Detalle de ${foreignProduct?.description}` : titleText}
      </ModalHeader>
      <ModalContent>
        <Text size="m">
          En esta vista puedes {isEditingMode ? 'editar' : 'crear'} un pedimento
          de importación.
        </Text>
        <Text size="m">
          El pedimento será añadido a los gastos deducibles automáticamente.
        </Text>
        <ForeignProductForm
          foreignProduct={foreignProduct}
          innerRef={formikRef}
          onSubmit={onSubmit}
          onLoading={setIsLoading}
          readOnly={readOnly}
          isAnnualProcess={isAnnualProcess}
          isFromWorkflow={isFromWorkflow}
          regimeOptions={regimeOptions}
          defaultRegimeOption={defaultRegimeOption}
          taxableEntityPreferences={taxableEntityPreferences}
        />
      </ModalContent>
      <ModalFooter>
        <Button color="white" loading={isLoading} onClick={onClose} size="m">
          {readOnly ? 'Cerrar' : 'Cancelar'}
        </Button>
        {readOnly && (
          <Button
            color="primary"
            loading={isLoading}
            onClick={() => setReadOnly(false)}
            size="m"
          >
            Habilitar edición
          </Button>
        )}
        {!readOnly && (
          <Button
            color="primary"
            loading={isLoading}
            onClick={() => formikRef.current.submitForm()}
            size="m"
          >
            {isEditingMode ? 'Actualizar pedimento' : 'Crear pedimento'}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

ForeignProductModalForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  foreignProduct: PropTypes.instanceOf(Object),
  readOnly: PropTypes.bool,
  setReadOnly: PropTypes.func,
};

ForeignProductModalForm.defaultProps = {
  foreignProduct: null,
  readOnly: false,
  setReadOnly: null,
};
