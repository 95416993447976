import { AxiosRequestConfig } from 'axios';
import api from '@api';
import type {
  DeclarationEntry,
  DeclarationEntryPayload,
  DeclarationEntryUpdatePayload,
} from 'types/entities';

export const getDeclarationEntriesByWorkflow = async (
  config?: AxiosRequestConfig<DeclarationEntry[]>,
) => {
  const { data } = await api.get<DeclarationEntry[]>(
    `declaration_entries/by_workflow`,
    config,
  );
  return data;
};

export const putDeclarationEntriesBulkUpdate = async (
  payload: DeclarationEntryPayload,
  config?: AxiosRequestConfig<DeclarationEntry[]>,
) => {
  const { data } = await api.put<DeclarationEntry[]>(
    `declaration_entries/bulk_update`,
    payload,
    config,
  );
  return data;
};

export const postDeclarationEntriesCreate = async (
  payload: FormData,
  config?: AxiosRequestConfig<DeclarationEntry>,
) => {
  const { data } = await api.post<DeclarationEntry>(
    `declaration_entries`,
    payload,
    config,
  );
  return data;
};

export const postDeclarationEntriesCreateFromSource = async (
  payload: FormData,
  config?: AxiosRequestConfig<DeclarationEntry[]>,
) => {
  const { data } = await api.post<DeclarationEntry[]>(
    `declaration_entries/create_from_source`,
    payload,
    config,
  );
  return data;
};

export const putDeclarationEntries = async (
  { payload, declarationEntryId }: DeclarationEntryUpdatePayload,
  config?: AxiosRequestConfig<DeclarationEntry>,
) => {
  const { data } = await api.put<DeclarationEntry>(
    `declaration_entries/${declarationEntryId}`,
    payload,
    config,
  );
  return data;
};
