import { QueryOptions, useQuery } from 'react-query';
import { ANNUAL_DEDUCTION_LIMITS } from '@constants/reactQueries';
import { AnnualDeduction } from 'types/entities';
import { getAnnualDeductions } from '@api/annualDeductions';

export default function useAnnualDeductionLimits(
  queryOptions?: QueryOptions<AnnualDeduction>,
) {
  const {
    data = null,
    isLoading,
    ...rest
  } = useQuery<AnnualDeduction>(
    ANNUAL_DEDUCTION_LIMITS,
    async () => {
      const annualDeduction = await getAnnualDeductions();
      return annualDeduction ?? [];
    },
    {
      ...queryOptions,
    },
  );

  return {
    annualDeductionLoading: isLoading,
    annualDeduction: data,
    ...rest,
  };
}
