import React, { useEffect } from 'react';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import FormikTextInput from '@components/FormikTextInput';
import FormikSelect from '@components/FormikSelect';
import { Flex } from '@konta/ui';
import useNextPhase from '@hooks/useNextPhase';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';

const IVA_OPTIONS = [
  {
    label: 'IVA 16%',
    value: 0.16,
    key: 1,
    name: 'iva_rate_of_bank_transactions',
  },
  {
    label: 'IVA 8%',
    value: 0.08,
    key: 2,
    name: 'iva_rate_of_bank_transactions',
  },
];

function UploadIncomeTotal() {
  const { workflow } = useSelectedWorkflow();
  const { iva_rate } = workflow.active_declaration;
  const nextPhase = useNextPhase();

  const incomes = workflow.active_declaration.declaration_income;

  const onSubmit = (values) => {
    const {
      select_iva_rate,
      total_exempt_bases_of_manual_input,
      total_with_iva_of_manual_input,
      total_zero_bases_of_manual_input,
    } = values;
    const payload = {
      workflow: {
        next_phase: 'primary',
        iva_rate: select_iva_rate.value,
        total_exempt_bases_of_manual_input:
          total_exempt_bases_of_manual_input || 0,
        total_with_iva_of_manual_input: total_with_iva_of_manual_input || 0,
        total_zero_bases_of_manual_input: total_zero_bases_of_manual_input || 0,
        iva_rate_of_bank_transactions: {
          label: select_iva_rate.label,
          value: select_iva_rate.value,
          key: select_iva_rate.key,
        },
      },
    };

    nextPhase(payload);
  };

  const formik = useFormik({
    validationSchema: yup.object().shape({
      total_with_iva_of_manual_input: yup.number(),
      total_zero_bases_of_manual_input: yup.number(),
      total_exempt_bases_of_manual_input: yup.number(),
      select_iva_rate: yup.mixed(),
    }),
    initialValues: {
      total_with_iva_of_manual_input:
        incomes.total_with_iva_of_manual_input || 0,
      total_zero_bases_of_manual_input:
        incomes.total_zero_bases_of_manual_input || 0,
      total_exempt_bases_of_manual_input:
        incomes.total_exempt_bases_of_manual_input || 0,
      select_iva_rate: IVA_OPTIONS[0],
    },
    onSubmit,
  });

  useEffect(() => {
    if (iva_rate) {
      formik.setFieldValue('select_iva_rate', {
        label: `IVA ${iva_rate * 100}%`,
        value: Number(iva_rate),
        key: 3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iva_rate]);
  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Captura el total de sus ingresos"
          description="En esta sección podrás capturar los totales de ingresos de tu negocio en este mes."
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton onClick={formik.submitForm} />
        </>
      }
    >
      <div className="d-flex align-items-center w-100">
        <div className="w-100">
          <FormikProvider value={formik}>
            <Flex column gap={20} css={{ '@lg': { width: '50%' } }}>
              <Flex gap={20}>
                <FormikTextInput
                  data-cy="total_with_iva_of_manual_input"
                  leftIcon="$"
                  name="total_with_iva_of_manual_input"
                  label="Monto total de ingresos con IVA"
                  type="number"
                />
                <FormikSelect
                  options={IVA_OPTIONS}
                  name="select_iva_rate"
                  label="IVA"
                  data-testid="select_iva_rate"
                  newDesign
                />
              </Flex>
              <Flex gap={20}>
                <FormikTextInput
                  data-cy="total_zero_bases_of_manual_input"
                  leftIcon="$"
                  name="total_zero_bases_of_manual_input"
                  label="Monto de total ingresos con el 0% de IVA"
                  type="number"
                />
                <FormikTextInput
                  data-cy="total_exempt_bases_of_manual_input"
                  leftIcon="$"
                  name="total_exempt_bases_of_manual_input"
                  label="Monto de ingresos con IVA exento"
                  type="number"
                />
              </Flex>
            </Flex>
          </FormikProvider>
        </div>
      </div>
    </WorkflowLayout>
  );
}

export default UploadIncomeTotal;
