import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import api from '@api';
import {
  fetchClientsSuppliersSuccess,
  fetchClientsSuppliersFailure,
} from '../actions';

export default function* ({ payload: { taxable_entity } }) {
  try {
    const access_token = localStorage.getItem('user_id');
    const response = yield call(
      api.get,
      `taxable_entities/${taxable_entity.id}/client_suppliers`,
      { headers: { Authorization: `${access_token}` } }
    );

    // NOTE: Uncomment for tests
    // response.data[0].fiscal_regimes = [1, 2, 3];

    yield call(StatusHandler, {
      response,
      status: response.status,
      success: fetchClientsSuppliersSuccess,
      failure: fetchClientsSuppliersFailure,
    });
  } catch (error) {
    // catch throw
    yield call(ErrorHandler, {
      error,
      action: fetchClientsSuppliersFailure,
    });
  }
}
