import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row } from 'reactstrap';
import { Colxx } from '@components/CustomBootstrap';
import { updateTaxableEntity, uploadTaxableEntityFiel } from '@redux/actions';
import { Button, Text } from '@konta/ui';

function OnboardingVideo({
  taxable_entity,
  loading,
  selectedAccordion,
  handleStepClick,
}) {
  const dispatch = useDispatch();
  const [hasSubmitting, setHasSubmitting] = useState(false);

  useEffect(() => {
    if (taxable_entity.onboarding_detail?.intro_video && hasSubmitting) {
      handleStepClick(2);
    }
  }, [hasSubmitting, taxable_entity.onboarding_detail?.intro_video]);

  const handleSubmit = () => {
    const body = {
      taxable_entity: {
        onboarding_detail_attributes: {
          intro_video: true,
        },
      },
    };
    setHasSubmitting(true);
    if (!taxable_entity.onboarding_detail?.intro_video) {
      dispatch(updateTaxableEntity(body, taxable_entity.id));
    }
  };

  return (
    <div className="p-5">
      <Row>
        <Colxx md="6" className="d-flex align-items-center">
          <div>
            <p>
              ¡Bienvenido a Konta! Realiza tu cálculo de impuestos más rapido
              que nunca con nuestra plataforma especializada para que lleves tu
              contabilidad.
            </p>
            <p>
              Puedes conocer todo sobre lo que Konta puede hacer por ti en el
              siguiente video. Recuerda que estamos a tus órdenes por WhatsApp
              en{' '}
              <Text
                as="a"
                href="https://wa.me/525570055271"
                target="_blank"
                color="primary700"
                s
              >
                (55) 7005 5271
              </Text>{' '}
              para cualquier duda sobre el servicio.
            </p>
            <Button loading={loading} onClick={handleSubmit} color="primary">
              Continuar
            </Button>
          </div>
        </Colxx>
        <Colxx md="6" className="d-flex justify-content-center">
          {selectedAccordion === 1 && (
            <iframe
              className="onboarding-video-iframe"
              width="460"
              height="230"
              src="//www.youtube.com/embed/tfsr6aRb38M?autoplay=1&cc_load_policy=1&controls=1&disablekb=0&enablejsapi=0&fs=1&iv_load_policy=1&loop=0&rel=0&showinfo=1&start=0&wmode=transparent&theme=dark&mute=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              tabIndex="-1"
              title="Onboarding video"
            />
          )}
        </Colxx>
      </Row>
    </div>
  );
}

const mapStateToProps = ({ taxableEntity }) => {
  const { taxable_entity, error, loading } = taxableEntity;
  return { taxable_entity, error, loading };
};
export default connect(mapStateToProps, {
  updateTaxableEntity,
  uploadTaxableEntityFiel,
})(OnboardingVideo);
