import { useToggle } from 'rooks';
import useWorkflow from '@components/Workflow/hooks/useWorkflow';

export default function usePreviousPeriods() {
  const [openModalTable, toggleOpenModalTable] = useToggle();
  const { regimeOptionsWithDeclarationId, workflow, updateCurrentWorkflow } =
    useWorkflow();

  return {
    regimeOptionsWithDeclarationId,
    openModalTable,
    toggleOpenModalTable,
    workflow,
    updateCurrentWorkflow,
  };
}
