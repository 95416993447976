export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 1200;
export const defaultMenuType = 'menu-sub-hidden'; // 'menu-sub-hidden', 'menu-hidden'
export const defaultStartPath = '/app/pendientes';
export const adminRoot = '/app';

export const defaultLocale = 'es';
export const localeOptions = [
  { id: 'en', name: 'English' },
  { id: 'es', name: 'Español' },
];

export const searchPath = 'https://konta.zendesk.com/hc/es-419/search?utf8=✓&';

export const conektaConfig = {
  subscription: {
    sandbox: 'key_IrXcqbjXqRaJARLKXwKyzMA',
    production: 'key_YDxFApQttP3mhFrwgnQZsiQ',
  },
  fiscal_period: {
    sandbox: 'key_Er9csqDENkqFxGrdxdd6BaA',
    production: 'key_ZkFEUVEBRqUd26QmuNk2AXg',
  },
};
