import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';

export default function CustomReactQueryProvider({ children }) {
  const client = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
        },
      },
    });
  }, []);

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
}

CustomReactQueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
