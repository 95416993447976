import _ from 'lodash';
import { getMessageFromError } from './Utils';

export default function getErrorMessage(
  error,
  defaultMessage = 'Error desconocido',
) {
  if (!error?.response) {
    // Error object
    return error?.message ?? defaultMessage;
  }

  const { data = {} } = error.response;

  if (data.debug_message) {
    return data.debug_message;
  }

  if (data.error) {
    return data.error;
  }

  // Standard schema of errors check (rfc document)
  if (data.errors instanceof Array) {
    const err = data.errors?.[0];
    if (err.message) {
      return err.message;
    }
    /* some errors do not have the message property,
      this below if is added to catch this type of errors,
      that maybe the backend did not follow the convention
      of adding the message property. */
    if (_.isPlainObject(err)) {
      return Object.entries(err)
        .map(([key, value]) => `${key} ${value}`)
        .join(', ');
    }
    return defaultMessage;
  }

  if (data.errors) {
    return getMessageFromError(data.errors);
  }

  if (_.isPlainObject(data)) {
    return getMessageFromError(data);
  }

  return defaultMessage;
}
