/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import dayjs from 'dayjs';
import { install as installResizeObserver } from 'resize-observer';
import { Chart, registerables } from 'chart.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/es-mx';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { pdfjs } from 'react-pdf';
import { rfcPattern } from './@constants/regex';

require('dayjs/locale/es');

// Polyfill for legacy browsers https://caniuse.com/resizeobserver
if (!window?.ResizeObserver) {
  installResizeObserver();
}

export const initializerChart = () => {
  Chart.register(...registerables);
};

export const initializerDayjs = () => {
  dayjs.locale('es');
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isBetween);
  dayjs.extend(utc);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.extend(LocalizedFormat);
  dayjs.extend(customParseFormat);
};

export default function initializers() {
  initializerDayjs();
  initializerChart();
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
}

yup.addMethod(yup.string, 'rfc', (message) => {
  return yup.string().test('rfc-test', message, function rfcTest(value) {
    const { path, createError } = this;
    if (!rfcPattern.test(value)) {
      return createError({ path, message: message ?? 'Formato inválido' });
    }

    return true;
  });
});

yup.addMethod(yup.string, 'ciec', (message) => {
  return yup.string().min(8, message ?? 'Mínimo 8 caracteres');
});

yup.setLocale({
  mixed: {
    default: 'No válido',
    required: 'Campo requerido',
  },
  number: {
    min: 'Debe ser mayor o igual a ${min}',
  },
  array: {
    min: 'Selecciona al menos ${min} opción(es)',
  },
  string: {
    required: 'Campo requerido',
    email: 'El correo electrónico debe ser válido',
  },
});
