import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  belvoLinksConnectionDelete,
  belvoLinksFetch,
  belvoTokenGet,
} from '@redux/actions';

import { Alert, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { Colxx, CustomModal } from '@components';
import { useToggle } from 'rooks';
import useBelvoScript from '@hooks/useBelvoScript';
import useFeature from '@hooks/useFeature';
import {
  PlusCrFr1Icon,
  RemoveCrFr1Icon,
  RoadSignNoStopping1Icon,
  BankCardExportOuLc1Icon,
} from '@konta/icons';
import {
  Text,
  Button,
  Flex,
  Card,
  CardContent,
  Spacer,
  CircularLoading,
} from '@konta/ui';
import { BELVO_ACCESS_TOKEN } from '@constants/featureFlags';
import BankStatus from './BankStatus';

function LinkStatus({ link, onClick }) {
  const [belvoAccessToken] = useFeature(BELVO_ACCESS_TOKEN);
  if (link.link_status === 'token_required' && belvoAccessToken) {
    return (
      <Button
        size="xs"
        color="gray"
        variant="contained"
        squaredPadding
        leftIcon={<BankCardExportOuLc1Icon />}
        onClick={() => onClick(link)}
      >
        Generar nuevo acceso
      </Button>
    );
  }
  if (link.link_status === 'token_required') {
    return (
      <Flex align="center" gap={8}>
        <Text color="error400">El banco solicita un token de acceso</Text>
      </Flex>
    );
  }

  if (
    link.aasm_state === 'sync_transactions' ||
    (link.aasm_state === 'pending' && link.link_status === 'valid')
  ) {
    return (
      <Flex align="center" gap={8}>
        <Text color="error400">
          En proceso de sincronización de movimientos
        </Text>
        <CircularLoading />
      </Flex>
    );
  }

  return (
    <Text color="gray700">
      {dayjs(link.last_accessed_at).format('YYYY-MM-DD')}
    </Text>
  );
}

function BankList({ isWorkflow }) {
  const dispatch = useDispatch();

  const { setOpen } = useBelvoScript();

  const [deleteModal, setDeleteModal] = useToggle();
  const [selectedBank, setSelectedBank] = useState();
  const [timeOutCounter, setTimeOutCounter] = useState(60);

  const { belvo_links, loading } = useSelector(({ belvo }) => belvo);

  const hasPendingAccounts = useMemo(
    () =>
      belvo_links?.some(
        (link) =>
          link.aasm_state === 'pending' || link.aasm_state === 'sync_accounts',
      ),
    [belvo_links],
  );
  useEffect(() => {
    let count = 60;
    if (isWorkflow && hasPendingAccounts) {
      const interval = setInterval(() => {
        if (count > 0) {
          count -= 1;
          setTimeOutCounter(count);
        }
        if (count === 30 || count === 1) {
          dispatch(belvoLinksFetch());
        }
      }, 1000);
      return () => clearTimeout(interval);
    }
  }, [isWorkflow, hasPendingAccounts]);

  useEffect(() => {
    dispatch(belvoLinksFetch());
  }, [dispatch]);

  const handleDeleteSubmit = () => {
    dispatch(belvoLinksConnectionDelete(selectedBank));
    setDeleteModal();
  };

  const handleDelete = (item) => {
    setSelectedBank(item);
    setDeleteModal();
  };

  const handleNewBank = () => {
    dispatch(belvoTokenGet());
    setOpen();
  };

  const handleNewAccessToken = ({ link }) => {
    dispatch(belvoTokenGet({ link_id: link }));
    setOpen();
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" css={{ height: '500px' }}>
        <CircularLoading />
      </Flex>
    );
  }

  return (
    <>
      {!isWorkflow && (
        <Row>
          <Colxx md={6} className="mb-2">
            <h2 className="mb-0">Conecta tus cuentas de banco</h2>
          </Colxx>
          <Colxx md={12} className="">
            <p className="text-muted">
              Puedes vincular tus bancos con Konta presionando el siguiente
              botón, de esta forma se abrirá una ventana donde debe seguir los
              pasos para que la conexión sea exitosa.
            </p>
          </Colxx>
        </Row>
      )}
      <Row>
        <Colxx md={4} className="mb-4">
          <Button
            outline
            size="xs"
            color="gray"
            variant="outlined"
            onClick={handleNewBank}
            squaredPadding
            leftIcon={<PlusCrFr1Icon className="button-icon" />}
          >
            Agregar nuevo banco
          </Button>
        </Colxx>
      </Row>
      <div id="belvo" />

      {belvo_links?.map((link) => (
        <Card outlined key={link.id} css={{ marginBottom: '12px' }}>
          <CardContent>
            <Flex direction="column" gap="4">
              <Flex gap="2" itemsCenter>
                <Text color="gray800" css={{ marginRight: '12px' }}>
                  Estado:
                </Text>
                <BankStatus linkStatus={link.link_status} />
                <Spacer />
                <Button
                  variant="text"
                  color="error"
                  leftIcon={<RemoveCrFr1Icon className="button-icon" />}
                  onClick={() => handleDelete(link)}
                >
                  Desvincular
                </Button>
              </Flex>
              <Text xl>
                {link.institution.replace(/_/g, ' ').toUpperCase()}
              </Text>
              <Flex>
                <LinkStatus link={link} onClick={handleNewAccessToken} />
              </Flex>
            </Flex>
          </CardContent>
        </Card>
      ))}
      {belvo_links?.length === 0 && (
        <Row className="mb-2">
          <Colxx md={12}>
            <Alert
              color="primary"
              className="mb-3 rounded-alert d-flex align-items-center"
            >
              <RoadSignNoStopping1Icon className="button-icon-alert-primary" />
              Aún no tienes bancos vinculados a tu cuenta.
            </Alert>
          </Colxx>
        </Row>
      )}

      <CustomModal
        title="¿Estás seguro de desvincular esta cuenta de banco?"
        toggle={setDeleteModal}
        isOpen={deleteModal}
        handleValidSubmit={handleDeleteSubmit}
        submitText="Continuar"
      >
        <div className="m-auto text-center">
          <i className="large-icon iconsmind-Danger" color="danger" />
          <p>¡Tu cuenta de banco será desvinculada de Konta!</p>
          <p className="font-weight-bold mb-0">¿Qué implica esto?</p>
          <p className="mb-0">
            Tus movimientos de banco no serán contemplados como ingresos en tu
            declaración. Asegúrate de agregar otra cuenta de banco o subir
            manualmente tus ingresos.
          </p>
        </div>
      </CustomModal>
    </>
  );
}

BankList.displayName = 'BankList';

export default BankList;
