import { CATCH_NEW_ERROR } from '@constants/actionTypes';

const initialState = {
  errors: [],
  errorStatus: null,
  errorStatusText: null,
  routeError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CATCH_NEW_ERROR:
      return {
        ...state,
        errors: action.payload.data?.errors,
        errorStatus: action.payload.status,
        errorStatusText: action.payload.statusText,
        routeError: action.payload.config.url,
      };

    default:
      return {
        ...state,
      };
  }
};
