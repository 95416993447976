import { styled } from '@konta/ui';

export const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  variants: {
    blockUi: {
      true: { position: 'relative', overflow: 'hidden' },
    },
  },
});

export const Overlay = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  bottom: '5%',
  top: '5%',
  left: '2%',
  right: '2%',
  zIndex: 10,
  '@sm': {
    inset: '20%',
    '@md': {
      inset: '30%',
    },
  },
});
