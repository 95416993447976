import { Flex, Text } from '@konta/ui';
import Loader from '@components/Loader';
import TemplatePreview from '@components/TemplatePreview';
import useSettingsBrandForm from './useSettingsBrandForm';

type InvoicePreviewProps = Pick<
  ReturnType<typeof useSettingsBrandForm>,
  'loadingPreview' | 'invoiceDraft'
>;
export default function InvoicePreview({
  loadingPreview,
  invoiceDraft,
}: InvoicePreviewProps) {
  return (
    <Flex
      css={{ flex: 1, paddingRight: 0, '@sm': { paddingRight: '40px' } }}
      gap={10}
      direction="column"
    >
      <Flex direction="column">
        <Text xl bold lineHeight="xl" css={{ color: '#2B2B34' }}>
          Vista previa
        </Text>
      </Flex>
      {loadingPreview ? (
        <Loader />
      ) : (
        <TemplatePreview
          srcdoc={invoiceDraft}
          width={816}
          style={{ borderRadius: 10 }}
          srcPreview={undefined}
        />
      )}
    </Flex>
  );
}
