import {
  ProcessType,
  ProcessStep,
  OnboardingProcessStep,
  AnualProcessStep,
  FinishOnboardingProcessStep,
} from 'types/entities';
import getOnboardingSteps from '@util/getOnboardingSteps';
import getAnualSteps from '@util/getAnualSteps';
import getFinishOnboardingSteps from '@util/getFinishOnboardingSteps';

export default function getStepsByProcessType(
  processType: ProcessType | undefined,
  processSteps: ProcessStep[],
) {
  if (processType === ProcessType.OnboardingOnboardingProcess) {
    return getOnboardingSteps(processSteps as OnboardingProcessStep[]);
  }
  if (processType === ProcessType.AnnualDeclarationProcess) {
    return getAnualSteps(processSteps as AnualProcessStep[]);
  }
  if (processType === ProcessType.FinishOnboardingProcess) {
    return getFinishOnboardingSteps(
      processSteps as FinishOnboardingProcessStep[],
    );
  }

  return getAnualSteps(processSteps as AnualProcessStep[]);
}
