import React from 'react';
import {
  Flex,
  OptionsSelect,
  OptionsSelectItem,
  styled,
  Text,
} from '@konta/ui';
import { Emoji } from '@components/Emoji';

// TODO pasar a konta kit

const Card = styled('button', {
  position: 'relative',
  cursor: 'pointer',
  background: 'White',
  border: 'solid 1px var(--border-color, $colors$gray200)',
  display: 'block',
  textAlign: 'left',
  borderRadius: '$m',
  padding: '0',
  color: '$gray500',
  outline: 'none',
  '& b': {
    color: '$gray800',
  },
  '&:hover': {
    '--border-color': '$colors$primary500',
  },
  '&:focus': {
    customBoxShadow: '$colors$primary100',
  },
  '&:disabled': {
    '--border-color': '$colors$gray200 !important',
    // boxShadow: 'none !important',
    backgroundColor: '#F9FAFB',
    cursor: 'auto',
  },
  variants: {
    selected: {
      true: {
        '--border-color': '$colors$primary500',
        // customBoxShadow: '$colors$primary100',
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    size: {
      s: {
        fontSize: '$s',
        lineHeight: '$xs',
      },
      m: {
        fontSize: '$m',
        lineHeight: '$m',
      },
    },
  },
});

const CardHeader = styled('div', {
  position: 'relative',
  borderRadius: '$m',
  display: 'flex',
  padding: '$6',
  variants: {
    selected: {
      true: {
        backgroundColor: '$primary100',
        color: '$primary500',
        '& b': {
          color: '$primary800',
        },
      },
    },
    disabled: {
      true: {
        color: 'unset',
        '& b': {
          color: 'unset',
        },
      },
    },
    flatBottom: {
      true: {
        borderRadius: '$m $m 0 0',
      },
    },
  },
  compoundVariants: [
    {
      disabled: true,
      selected: true,
      css: {
        backgroundColor: '$gray100',
      },
    },
  ],
});

const HeaderContent = styled('div', {
  paddingTop: '$2',
  flex: '1',
});

export default function FeedbackRateSelect({ rating, setRating }) {
  return (
    <OptionsSelect value={rating} onChange={setRating} noEmpty>
      <Flex
        gap="16"
        direction="row"
        css={{
          display: 'grid',
          gridAutoFlow: 'row',
          gridAutoColumns: '1fr',
          gridTemplateColumns: 'repeat(3, 1fr)',
          '@sm': {
            gridAutoFlow: 'column',
          },
        }}
      >
        <OptionsSelectItem value={1}>
          {({ onToggle, selected }) => (
            <Card onClick={onToggle} selected={selected}>
              <CardHeader selected={selected}>
                <HeaderContent>
                  <Flex direction="column" align="center">
                    <Text x2l>
                      <Emoji symbol="😣" label="persevere_face" />
                    </Text>
                    <Text>Terrible</Text>
                  </Flex>
                </HeaderContent>
              </CardHeader>
            </Card>
          )}
        </OptionsSelectItem>
        <OptionsSelectItem value={2}>
          {({ onToggle, selected }) => (
            <Card onClick={onToggle} selected={selected}>
              <CardHeader selected={selected}>
                <HeaderContent>
                  <Flex direction="column" align="center">
                    <Text x2l>
                      <Emoji symbol="😕" label="confused_face" />
                    </Text>
                    <Text>Malo</Text>
                  </Flex>
                </HeaderContent>
              </CardHeader>
            </Card>
          )}
        </OptionsSelectItem>
        <OptionsSelectItem value={3}>
          {({ onToggle, selected }) => (
            <Card onClick={onToggle} selected={selected}>
              <CardHeader selected={selected}>
                <HeaderContent>
                  <Flex direction="column" align="center">
                    <Text x2l>
                      <Emoji symbol="😐" label="neutral_face" />
                    </Text>
                    <Text>Okay</Text>
                  </Flex>
                </HeaderContent>
              </CardHeader>
            </Card>
          )}
        </OptionsSelectItem>
        <OptionsSelectItem value={4}>
          {({ onToggle, selected }) => (
            <Card onClick={onToggle} selected={selected}>
              <CardHeader selected={selected}>
                <HeaderContent>
                  <Flex direction="column" align="center">
                    <Text x2l>
                      <Emoji symbol="😌" label="relieved_face" />
                    </Text>
                    <Text>Bueno</Text>
                  </Flex>
                </HeaderContent>
              </CardHeader>
            </Card>
          )}
        </OptionsSelectItem>
        <OptionsSelectItem value={5}>
          {({ onToggle, selected }) => (
            <Card onClick={onToggle} selected={selected}>
              <CardHeader selected={selected}>
                <HeaderContent>
                  <Flex direction="column" align="center">
                    <Text x2l>
                      <Emoji symbol="😎" label="sunglasses_face" />
                    </Text>
                    <Text>Asombroso</Text>
                  </Flex>
                </HeaderContent>
              </CardHeader>
            </Card>
          )}
        </OptionsSelectItem>
      </Flex>
    </OptionsSelect>
  );
}
