import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, ImageVideoModal } from '@components';
import HelpCenterLink from '@components/HelpCenterLink';

function ComputeResult() {
  return (
    <>
      <Row>
        <Colxx md="12" className="mb-2">
          <HelpCenterLink withDefaultChildren />
        </Colxx>
      </Row>
      <hr />

      <Row>
        <ImageVideoModal videoId="K5b54p7VQmA" />
        <Colxx md="12">
          <p className="font-weight-bold">Presentación de declaración</p>
          <p>
            Se presenta a más tardar el día 17 del mes siguiente al que
            corresponden los ingresos y gastos, con días adicionales dependiendo
            del sexto dígito de nuestro RFC.
          </p>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
      <Row>
        <Colxx md="12">
          <p className="font-weight-bold">¿Qué incluye mi plan?</p>
          <li>Cálculo de sus impuestos en automático</li>
          <li>Presentación de su declaración</li>
          <li>Sincronización de sus facturas</li>
          <li>Facturación ilimitada</li>
          <li>Atención a cliente</li>
        </Colxx>
        <Colxx md="12">
          {/* //TODO ARTICLES URL IS MISSING */}
          <HelpCenterLink> Link al artículo</HelpCenterLink>
        </Colxx>
      </Row>
      <hr />
    </>
  );
}
export default ComputeResult;
