import { ReactNode } from 'react';
import { Root, IconWrapper, Title } from './styled';

interface ContainerButtonProps {
  icon: ReactNode;
  title: string | ReactNode;
  onClick: () => void;
  noFlex?: boolean;
  alignCenter?: boolean;
}
export default function ContainerButton({
  icon,
  title,
  onClick,
  noFlex,
  alignCenter,
}: ContainerButtonProps) {
  return (
    <Root onClick={onClick} alignCenter={alignCenter}>
      <IconWrapper>{icon}</IconWrapper>
      <Title noFlex={noFlex}>{title}</Title>
    </Root>
  );
}
