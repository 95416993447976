import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  WORKFLOWS_FETCH,
  SHOW_WORKFLOW_FETCH,
  WORKFLOW_UPDATE,
  WORKFLOW_PREVIOUS_PHASE,
  WORKFLOW_NEXT_PHASE,
} from '@constants/actionTypes';

import fetchSaga from './fetch';
import showSaga from './show';
import updateSaga from './update';
import previousSaga from './previous';
import nextSaga from './next_phase';

export function* watchFetch() {
  yield takeLatest(WORKFLOWS_FETCH, fetchSaga);
}

export function* watchShow() {
  yield takeLatest(SHOW_WORKFLOW_FETCH, showSaga);
}

export function* watchUpdate() {
  yield takeLatest(WORKFLOW_UPDATE, updateSaga);
}

export function* watchPrevious() {
  yield takeLatest(WORKFLOW_PREVIOUS_PHASE, previousSaga);
}

export function* watchNext() {
  yield takeLatest(WORKFLOW_NEXT_PHASE, nextSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetch),
    fork(watchShow),
    fork(watchUpdate),
    fork(watchPrevious),
    fork(watchNext),
  ]);
}
