import { styled, keyframes } from '@konta/ui';
import Seamless from './img/seamless.png';

const slideOutToLeft = keyframes({
  '0%': {
    transform: 'translate(-50%, -50%) perspective(1000px) rotateY(0deg)',
    opacity: 1,
  },
  '100%': {
    transform: 'translate(-150%, -50%) perspective(1000px) rotateY(-30deg)',
    opacity: 0,
  },
});

const slideOutToRight = keyframes({
  '0%': {
    transform: 'translate(-50%, -50%) perspective(1000px) rotateY(0deg)',
    opacity: 1,
  },
  '100%': {
    transform: 'translate(50%, -50%) perspective(1000px) rotateY(30deg)',
    opacity: 0,
  },
});

const slideInFromLeft = keyframes({
  '0%': {
    transform: 'translate(-150%, -50%) perspective(1000px) rotateY(-30deg)',
    opacity: 0,
  },
  '100%': {
    transform: 'translate(-50%, -50%) perspective(1000px) rotateY(0deg)',
    opacity: 1,
  },
});

const slideInFromRight = keyframes({
  '0%': {
    transform: 'translate(50%, -50%) perspective(1000px) rotateY(30deg)',
    opacity: 0,
  },
  '100%': {
    transform: 'translate(-50%, -50%) perspective(1000px) rotateY(0deg)',
    opacity: 1,
  },
});

export const customDialogContentCss = {
  height: '100%',
  width: '100%',
  maxHeight: '100%',
  maxWidth: '100%',
  boxSizing: 'border-box',
  '@sm': {
    height: '85vh',
    maxHeight: '85vh',
    maxWidth: '430px',
  },
  p: '24px',
  pb: '69px',
  borderRadius: 0,
  animation: 'none',
  '&[data-state="closed"]': {
    animation: 'none',
  },
  '&[data-state-animation="exiting-left"]': {
    animation: `${slideOutToLeft} 0.3s ease-out`,
    transformStyle: 'preserve-3d',
  },
  '&[data-state-animation="exiting-right"]': {
    animation: `${slideOutToRight} 0.3s ease-out`,
    transformStyle: 'preserve-3d',
  },
  '&[data-state-animation="entering-left"]': {
    animation: `${slideInFromLeft} 0.3s ease-out`,
    transformStyle: 'preserve-3d',
  },
  '&[data-state-animation="entering-right"]': {
    animation: `${slideInFromRight} 0.3s ease-out`,
    transformStyle: 'preserve-3d',
  },
  '&::before': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    pointerEvents: 'none',
    background: `url("${Seamless}") white 0% 0% / 170px 170px repeat`,
    opacity: '0.05',
  },
};

export const ArrowContainer = styled('div', {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1000,
  variants: {
    side: {
      left: { left: '-34px' },
      right: { right: '-34px' },
    },
  },
});

export const NavigationArrow = styled('button', {
  background: 'white',
  border: '1px solid $gray200',
  borderRadius: '24px',
  width: '26px',
  height: '26px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    background: '$gray100',
  },
  '&:disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  '& svg': {
    width: '14x',
    height: '14px',
    color: '$gray700',
  },
});

export const TapArea = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '50%',
  zIndex: 2,
  variants: {
    side: {
      left: { left: 0 },
      right: { right: 0 },
    },
    withCursorPointer: {
      true: { cursor: 'pointer' },
      false: { cursor: 'default' },
    },
    disabled: {
      true: { cursor: 'not-allowed', pointerEvents: 'none' },
    },
  },
});
