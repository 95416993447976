import api from '@api';

interface PostRequireCaptureLineProps {
  declarationId: number;
  workflowId: number;
}

export default async function postRequireCaptureLine({
  declarationId,
  workflowId,
}: PostRequireCaptureLineProps) {
  const { data, status } = await api.post<PostRequireCaptureLineProps>(
    `/workflows/${workflowId}/request_capture_line`,
    {
      declaration_id: declarationId,
    },
  );

  return { ...data, status };
}
