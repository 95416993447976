import type { DeclarationEntry } from 'types/entities';

export default function getExpenseEntryTypeFilterValue(
  entry: DeclarationEntry,
) {
  const { source, declaration_entry_type } = entry;
  const { type } = source;
  if (!source) {
    return null;
  }
  if (type === 'foreign_invoice') {
    return 'foreignInvoices';
  }
  if (type === 'foreign_product') {
    return 'foreignProducts';
  }
  if (type === 'cfdi' && declaration_entry_type === 'national_expenses') {
    return 'cfdi';
  }
  if (type === 'fixed_asset') {
    return 'fixedAsset';
  }
  if (
    type === 'cfdi' &&
    declaration_entry_type === 'national_egress_received'
  ) {
    return 'creditNote';
  }
  return null;
}
