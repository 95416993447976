import { conektaConfig } from '@constants/defaultValues';

const is_production = process.env.REACT_APP_ENV === 'PRODUCTION';

const conektaHelper = {
  initConekta: async (type) => {
    const conektaKey = conektaConfig[type];
    await window.Conekta.setPublicKey(
      is_production ? conektaKey.production : conektaKey.sandbox
    );
  },
  getCardBrand: (cardNumber) => {
    return window.Conekta.card.getBrand(cardNumber);
  },
  validateCardNumber: (cardNumber) => {
    return window.Conekta.card.validateNumber(cardNumber);
  },
  validateCvc: (cvc) => {
    return window.Conekta.card.validateCVC(cvc);
  },
  validateExpirationDate: (expiryMonth, expiryYear) => {
    return window.Conekta.card.validateExpirationDate(
      expiryMonth,
      `20${expiryYear}`
    );
  },
  tokenize: (
    cardNumber,
    cardHolder,
    expiryMonth,
    expiryYear,
    cvc,
    successCallback,
    errorCallback
  ) => {
    const tokenParams = {
      card: {
        number: cardNumber,
        name: cardHolder,
        exp_year: expiryYear,
        exp_month: expiryMonth,
        cvc,
      },
    };
    return new Promise(function (resolve, reject) {
      const xhr = window.Conekta.Token.create(
        tokenParams,
        successCallback,
        errorCallback
      );
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          const response = JSON.parse(xhr.response);
          if (response?.id) {
            resolve(response.id);
          } else {
            reject(response);
          }
        } else {
          let statusText;
          try {
            statusText = JSON.parse(xhr.response);
          } catch {
            statusText = xhr.response;
          }
          reject({
            status: this.status,
            statusText,
          });
        }
      };
      xhr.onerror = function () {
        let statusText;
        try {
          statusText = JSON.parse(xhr.response);
        } catch {
          statusText = xhr.response;
        }
        reject({
          status: this.status,
          statusText,
        });
      };
    });
  },
};

export default conektaHelper;
