import React from 'react';
import { Button, Flex, Text } from '@konta/ui';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import VideoButton from '@components/VideoButton';
import ProcessStepDescription from '@components/ProcessStepDescription';
import ProcessStepTitle from '@components/ProcessStepTitle';

interface OnboardingCiecHelpProps {
  setShowHelpForCiec: () => void;
}

export default function OnboardingCiecHelp({
  setShowHelpForCiec,
}: OnboardingCiecHelpProps) {
  return (
    <>
      <Flex direction="column" gap={12}>
        <ProcessStepTitle>Es importante tener tu ciec</ProcessStepTitle>
        <ProcessStepDescription>
          Es muy importante que nos proporciones tu Clave de Identificación
          Electrónica del Contribuyente (CIEC) ya que nos permitirá llevar a
          cabo ciertos trámites de manera más eficiente y rápida.
        </ProcessStepDescription>
        <ProcessStepDescription>
          Además, te aseguramos que es completamente seguro darnos esta
          información ya que utilizamos medidas de seguridad para proteger tus
          datos personales y garantizar su privacidad.
        </ProcessStepDescription>
      </Flex>
      <Flex gap={12} direction="column">
        <Text m lineHeight="m">
          Existen varias maneras de obtener tu CIEC en México:
        </Text>
        <Text m lineHeight="m">
          1.- Si necesitas sacar una nueva puedes hacerlo en línea en SAT ID,
          sigue los pasos en este video.
        </Text>
        <VideoButton
          withSpacing={false}
          withLink={false}
          videoId="myNMhcucWNo"
        />
        <Text m lineHeight="m">
          2.- Si prefieres ir al SAT presencial a obtenerla, ve este video.
        </Text>
        <VideoButton
          withSpacing={false}
          withLink={false}
          videoId="SiYW-wnOOYc"
          startTime={582}
        />
        <Text m lineHeight="m">
          3.- Si no encontraste solución a tu problema, no dudes en
          contactarnos.
        </Text>
        {/* TODO add functionality when exist */}
        {/* <Flex>
          <Button
            color="gray"
            variant="outlined"
            onClick={setShowHelpForCiec}
            rightIcon={<ArrowRightIcon />}
          >
            Agendar videollamada
          </Button>
        </Flex> */}
      </Flex>
      <Flex gap={12}>
        <Button
          color="primary600"
          onClick={setShowHelpForCiec}
          rightIcon={<ArrowRightIcon />}
        >
          Ya tengo mi CIEC
        </Button>
      </Flex>
    </>
  );
}
