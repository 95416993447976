import api from '@api';
import { useQuery } from 'react-query';
import { INVOICE_PDF_PREVIEW } from '@constants/reactQueries';

export default function useInvoiceDraftPdf(cfdiId, axiosConfig, queryOptions) {
  const {
    data = '',
    isLoading,
    isFetching,
    ...rest
  } = useQuery(
    [INVOICE_PDF_PREVIEW, cfdiId, axiosConfig],
    async () => {
      const response = await api.get(`/cfdis/${cfdiId}.pdf`, {
        responseType: 'arraybuffer',
        ...axiosConfig,
      });
      return Buffer.from(response.data, 'binary').toString('base64');
    },
    queryOptions,
  );

  return { isPdfLoading: isLoading || isFetching, pdf: data, ...rest };
}
