import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientsSuppliers } from '@redux/clients_suppliers/actions';
import type { IRootState } from 'types/redux';

// TODO: refactor this hook to use react-query
export default function useClients() {
  const dispatch = useDispatch();

  const taxableEntity = useSelector(
    (state: IRootState) => state.taxableEntity.taxable_entity,
  );

  const clients = useSelector(
    (state: IRootState) =>
      state.clientsSuppliers.clients_suppliers?.filter?.(
        (item) => item.relationship_type === 'client',
      ) ?? [],
  );

  useEffect(() => {
    dispatch(fetchClientsSuppliers(taxableEntity));
  }, [dispatch, taxableEntity]);

  return useMemo(() => ({ clients }), [clients]);
}
