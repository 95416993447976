import React, { useState } from 'react';
import { Divider, Text, Flex, Button } from '@konta/ui';
import KontaWhatsappIcon from '@components/WhatsappIcon';
import {
  Phone2OutlineIcon,
  TooltipCheckOutlineIcon,
  CheckDecagramOutlineIcon,
} from '@konta/icons';
import { ProcessType } from 'types/entities';
import NextStepButton from '@components/NextStepButton';
import ProcessStepLayout from '@components/ProcessStepLayout';
import ProcessStepDescription from '@components/ProcessStepDescription';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepHelpFeature from '@components/ProcessStepHelpFeature';
import WelcomeFeatureText from './WelcomeFeatureText';

const process = ProcessType.OnboardingOnboardingProcess;

export default function OnboardingWelcome() {
  const [payload, setPayload] = useState<boolean>();
  const messageText = `Quiero hablar con un contador`;

  return (
    <ProcessStepLayout
      withDefaultLeftSidebar={false}
      currentProcessType={process}
      title="Apertura de cuenta"
      content={
        <>
          <Flex direction="column" gap={12}>
            <ProcessStepTitle>¡Bienvenido a Konta.com!</ProcessStepTitle>
            <ProcessStepDescription>
              A partir de ahora podrás mantenerte al día con el SAT, tener
              asesoría con contadores expertos y facturar sencillo e ilimitado.
              Ya sea que seas una pequeña empresa o un profesional independiente
              estamos aquí para ayudarte.
            </ProcessStepDescription>
            <ProcessStepDescription>
              Tu primer paso es tener una llamada con un Konta experto. Durante
              esta llamada, podrás hacer cualquier pregunta que tengas sobre tus
              obligaciones fiscales. Si tienes atrasos en el cumplimiento de tus
              obligaciones fiscales, nuestro experto fiscal también te ayudará a
              ponerte al día y a evitar posibles sanciones.
            </ProcessStepDescription>
            <Flex css={{ pt: '$24' }} gap="10">
              <Button
                color="gray"
                onClick={() => {
                  window.open(
                    `https://wa.me/525570055271?text=${messageText}`,
                    'popup',
                    'height=700px,width=700px',
                  );
                }}
                variant="outlined"
                css={{
                  path: { stroke: '#393d3d' },
                }}
                rightIcon={<KontaWhatsappIcon />}
              >
                ¿Necesitas hablar con un contador en este momento?
              </Button>
              <NextStepButton
                currentProcessType={process}
                data-cy="onboardingWelcomeNextBtn"
                text="¡Empezar!"
                payload={payload}
                onClick={() => setPayload(true)}
              />
            </Flex>
          </Flex>
          <Flex direction="column" gap={24}>
            <Divider />
            <Flex
              css={{
                gap: '32px',
                flexDirection: 'column',
                '@sm': { flexDirection: 'row' },
              }}
            >
              <WelcomeFeatureText
                icon={<TooltipCheckOutlineIcon />}
                title="1. Preguntas sobre tu negocio"
                description="En estas preguntas sobre tu negocio pueden ser a que te dedicas, si tienes inversiones, tus credenciales del SAT."
              />
              <WelcomeFeatureText
                icon={<Phone2OutlineIcon />}
                title="2. Llamada con un Kontador experto"
                description="Esta es una asesoría para entender tu negocio a detalle, asegúrate de tener toda la información necesaria para tener la llamada."
              />
              <WelcomeFeatureText
                icon={<CheckDecagramOutlineIcon />}
                title="3. Ponerse al día con el SAT"
                description="Es posible que tengas que ponerte al día con el SAT y cumplir tus obligaciones fiscal, esto puede incluir registrarse en el y pagar cualquier adeudo."
              />
            </Flex>
          </Flex>
        </>
      }
      rightSidebarContent={
        <ProcessStepHelpFeature
          title="¿No sabes cómo continuar?"
          description={
            <>
              {'Ve '}
              <Text
                as="a"
                color="primary700"
                href="https://www.loom.com/share/8d357d03aa524a99ab6ed7f9f017815b"
                target="_blank"
              >
                este video
              </Text>{' '}
              {
                ' y sigue adelante con tu proceso. Te puede ayudar tener mayor claridad sobre cómo continuar abriendo tu cuenta.'
              }
            </>
          }
        />
      }
    />
  );
}
