import { select, put, take } from 'redux-saga/effects';
import { FETCH_FISCAL_PERIOD_SUCCESS } from '@constants/actionTypes';
import {
  fetchFiscalPeriod,
  selectFiscalPeriod,
  uiSetDate,
  fetchFinancialTransactions,
  fetchFinancialTransactionsSuccess,
} from '@redux/actions';
import dayjs from 'dayjs';

export default function* ({ payload: { date } }) {
  try {
    const mm = date.getMonth();
    const yy = date.getFullYear();
    yield put(uiSetDate(date));

    yield put(fetchFiscalPeriod(yy));

    yield take(FETCH_FISCAL_PERIOD_SUCCESS);
    // if changed month
    const { fiscal_period } = yield select((state) => state.fiscalPeriods);

    // Find fiscal period of that month
    const fp = yield fiscal_period.find(
      (item) => dayjs(item.start_date).month() === +mm
    ) || null;

    yield put(selectFiscalPeriod(fp));
    if (fp) {
      yield put(fetchFinancialTransactions(fp));
    } else {
      yield put(fetchFinancialTransactionsSuccess([]));
    }
    // if fiscal period exists, fetch
  } catch (error) {
    // catch throw
    console.log(error);
  }
}
