import { toCurrency } from '@util/Utils';
import useSearchParamsState from '@hooks/useSearchParamsState';
import { useMemo } from 'react';
import useWorkflow from '../hooks/useWorkflow';

export default function useExpensesByRegime() {
  const {
    workflow,
    totalDeclarationExpenses,
    activeDeclarations,
    currentPreferredRegimes,
    workflowId,
    preferredFiscalRegime,
  } = useWorkflow();
  const [action, setAction] = useSearchParamsState('action', '');
  const showEditExpense = ['edit', 'new'].includes(action);
  const toggleShowEditExpense = () => setAction(showEditExpense ? '' : 'edit');

  const total = useMemo(() => {
    if (preferredFiscalRegime) {
      return +(
        activeDeclarations.find(
          (activeDeclaration) =>
            activeDeclaration.fiscal_regime.id === preferredFiscalRegime.id,
        )?.declaration_expense?.total || 0
      );
    }
    return totalDeclarationExpenses;
  }, [activeDeclarations, preferredFiscalRegime, totalDeclarationExpenses]);

  return {
    showEditExpense,
    toggleShowEditExpense,
    workflow,
    workflowId,
    totalDeclarationExpensesFormatted: toCurrency(total),
    currentRegimes: currentPreferredRegimes,
  };
}
