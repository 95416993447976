import { useEffect, useMemo, useState } from 'react';

export default function useListPagination<T>(data: T[]) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const pages = useMemo(
    () => Math.ceil(data.length / pageSize),
    [data.length, pageSize],
  );

  const paginatedData = useMemo(
    () => data.slice(page * pageSize, page * pageSize + pageSize),
    [data, page, pageSize],
  );

  useEffect(() => {
    // validate if page is out of range
    if (page > pages - 1 && pages > 0) {
      setPage(pages - 1);
    }
  }, [page, pages]);

  const paginationProps = useMemo(
    () => ({
      value: page,
      onChange: (changePage: number) => setPage(changePage),
      pageSize,
      pages,
      setPageSize,
    }),
    [page, pageSize, pages],
  );

  return {
    paginatedData,
    paginationProps,
  };
}
