import { debounce } from 'lodash';
import { HexColorPicker } from 'react-colorful';
import { Box, Flex, HoverCard, Text } from '@konta/ui';
import Loader from '@components/Loader';
import FormikTextInput from '@components/FormikTextInput';
import useSettingsBrandForm from './useSettingsBrandForm';

type ColorSettingsBrandForm = Pick<
  ReturnType<typeof useSettingsBrandForm>,
  'formik' | 'loadingUpdateTaxableEntityPreferences'
>;
export default function ColorSettingsBrands({
  formik,
  loadingUpdateTaxableEntityPreferences,
}: ColorSettingsBrandForm) {
  return (
    <Flex
      css={{
        gap: '32px',
        justifyContent: 'space-between',
        maxWidth: '100%',
        '@md': { maxWidth: '500px' },
      }}
    >
      <Flex align="center" gap={6}>
        <Text>Color de la marca</Text>
      </Flex>
      {loadingUpdateTaxableEntityPreferences ? (
        <Loader />
      ) : (
        <Flex
          css={{
            border: '1px solid $gray300',
            borderRadius: '6px',
          }}
        >
          <HoverCard
            css={{ width: '100%', padding: 0 }}
            openDelay={0}
            triggerElement={
              <Box
                tabIndex={-1}
                role="button"
                css={{
                  cursor: 'pointer',
                  borderRadius: 6,
                  width: '24px',
                  backgroundColor: `#${formik.values.brandColor}`,
                }}
              />
            }
          >
            <Flex direction="column" align="center" gap={12}>
              <HexColorPicker
                color={`#${formik.values.brandColor}`}
                onChange={debounce(
                  (color: string) =>
                    formik.setFieldValue('brandColor', color.substring(1)),
                  800,
                )}
              />
            </Flex>
          </HoverCard>
          <Box css={{ width: '65px', textAlign: 'center' }}>
            <FormikTextInput
              leftIcon="#"
              size="xs"
              name="brandColor"
              maxLength={6}
              css={{
                paddingLeft: '$4',
                py: '$2',
                '&:first-child :first-child': {
                  border: 'none',
                  py: 0,
                  height: '$20',
                  px: '$2',
                  gap: 0,
                },
              }}
              label={undefined}
            />
          </Box>
        </Flex>
      )}
    </Flex>
  );
}
