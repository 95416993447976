import { useMemo } from 'react';
import { ChartData } from 'chart.js';

export default function useDoughnutChartData({
  data,
}: {
  data: ChartData<'doughnut'>;
}) {
  const combinedData = useMemo(
    () =>
      data?.labels?.map((label, index) => {
        const {
          data: datasetData,
          backgroundColor,
          borderColor,
          borderWidth,
          label: datasetLabel,
        } = data.datasets[0];

        return {
          label,
          datasetLabel,
          data: datasetData[index],
          backgroundColor: (backgroundColor as 'string')?.[index],
          borderColor: (borderColor as 'string')?.[index],
          borderWidth,
        };
      }),
    [data],
  );
  return {
    combinedData,
  };
}
