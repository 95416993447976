import api from '@api';
import type { AxiosRequestConfig } from 'axios';
import type {
  WorkflowHeatmapData,
  NewWorkflowHeatmapData,
} from 'types/entities';

export const getTaxDelays = async (
  config?: AxiosRequestConfig<WorkflowHeatmapData>,
) => {
  const { data } = await api.get<WorkflowHeatmapData>(
    `tax_delays/heat_map`,
    config,
  );
  return data;
};

export const getNewTaxDelays = async (
  taxableEntityId: number | string,
  config?: AxiosRequestConfig<NewWorkflowHeatmapData>,
) => {
  const { data } = await api.get<NewWorkflowHeatmapData>(
    `taxable_entities/${taxableEntityId}/declarations_status`,
    config,
  );
  return data;
};
