export const isValidMonthAndYearParams = (query) => {
  const month = query.get('month');
  const year = query.get('year');
  if (
    month &&
    isValidMonthNumber(Number(month)) &&
    year &&
    isValidYearNumber(Number(year))
  )
    return true;
  return false;
};

const isValidMonthNumber = (month) => {
  if (month > 0 && month <= 12) return true;
  return false;
};

const isValidYearNumber = (year) => {
  const regex = new RegExp('^[0-9]{4}$');
  if (year && regex.test(year)) return true;
  return false;
};
