export default function getInvoiceStatus(status) {
  if (!status || status === 'stamped') return 'Timbrada';
  if (status === 'acceptance_pending') return 'Pendiente de aceptación';
  if (status === 'not_accepted') return 'Rechazada';
  if (status === 'cancelled') return 'Cancelada';
  if (status === 'pending') return 'Pendiente';
  if (status === 'cancellation_in_progress') return 'Cancelación en proceso';

  // eslint-disable-next-line no-console
  console.error('unexpected status', status);
  return 'Error';
}
