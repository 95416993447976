import { styled } from '@konta/ui';

export const AlertContainer = styled('div', {
  display: 'flex',
  padding: '$16 $24',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '24px',
  borderRadius: '8px',
  border: '1px solid $gray200',
  background: 'linear-gradient(45deg, #53389E 0%, #7F56D9 100%)',
});

export const IconWrapper = styled('div', {
  display: 'flex',
  padding: '$4',
  alignItems: 'center',
  gap: '$8',
  borderRadius: '$4',
  variants: {
    isSpinning: {
      true: {
        svg: {
          animation: 'spin 1.7s linear infinite',
        },
      },
    },
  },

  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

export const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
});

export const Title = styled('span', {
  color: '$white',
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '28px',
  fontStyle: 'normal',
});

export const Description = styled('span', {
  color: '$white',
  fontSize: '14px',
  lineHeight: '20px',
  fontStyle: 'normal',
});

export const Link = styled('a', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$4',
  color: '$text-info-emphasis',
  fontSize: '14px',
  lineHeight: '20px',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
});
