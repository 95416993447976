import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Checkbox, Flex } from '@konta/ui';
import Cell from 'shared/components/Cell';
import { Cfdi } from 'types/entities';
import PDFModal from '@components/Utils/PDF/pdf-modal';
import Table from 'backoffice/@konta/utils/table';
import { UseMultiSelectableReturn } from 'shared/hooks/useMultiSelectable';

export default function usePreviousPeriodsColumnsTable(
  type: 'incomes' | 'expenses',
  multiSelectable: UseMultiSelectableReturn<Cfdi>,
) {
  const { checkedControl, toggleAll, toggleSelection, matchSelection } =
    multiSelectable;
  return useMemo<ColumnDef<Cfdi>[]>(
    () => [
      {
        size: 15,
        enableSorting: false,
        enableHiding: false,
        label: 'Selección',
        id: 'select',
        accessorKey: 'select',
        header: () => (
          <Checkbox
            checked={checkedControl}
            onCheckedChange={() => toggleAll()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            onCheckedChange={() => toggleSelection({ index: row.index })()}
            checked={matchSelection({ index: row.index })}
          />
        ),
      },
      {
        header: 'Razón social',
        label: 'Razón social',
        id: 'legalName',
        accessorKey: 'legalName',
        cell: ({ row }) => {
          if (type === 'incomes') {
            return <Cell capitalize>{row.original.receiver_legal_name}</Cell>;
          }
          return <Cell capitalize>{row.original.issuer_legal_name}</Cell>;
        },
      },
      {
        header: 'Rfc',
        label: 'Rfc',
        id: 'rfc',
        accessorKey: 'rfc',
        cell: ({ row }) => {
          if (type === 'incomes') {
            return <Cell capitalize>{row.original.receiver_rfc}</Cell>;
          }
          return <Cell capitalize>{row.original.issuer_rfc}</Cell>;
        },
      },
      {
        header: 'Fecha',
        label: 'Fecha',
        id: 'dateIssued',
        accessorKey: 'dateIssued',
        cell: ({ row }) => (
          <Cell capitalize>{Table.humanizeDate(row.original.date_issued)}</Cell>
        ),
      },
      {
        header: 'Total',
        label: 'Total',
        id: 'total',
        accessorKey: 'total',
        cell: ({ row }) => <Cell currency>{row.original.total}</Cell>,
      },
      {
        header: 'Acciones',
        label: 'Acciones',
        id: 'taxableEntityId',
        accessorKey: 'taxableEntityId',
        cell: ({ row: { original } }) => (
          <Flex gap={8}>
            <PDFModal
              url={original.xml_url}
              title="Vista previa de la factura"
              id={original.id}
              uuid={original.folio_fiscal}
              status={original.status}
              invoice={original}
              togglePdf={undefined}
              onToggle={undefined}
            />
            {/* <Button
              size="2xs"
              color="gray"
              variant="outlined"
              rightIcon={<ArrowRightLineIcon />}
            >
              Agregar
            </Button> */}
          </Flex>
        ),
      },
    ],
    [checkedControl, matchSelection, toggleAll, toggleSelection, type],
  );
}
