import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { FormikProvider, useFormik } from 'formik';
import { Button, Flex, ModalContent, Text } from '@konta/ui';
import { fetchClientsSuppliers } from '@redux/actions';
import regimeToOption from '@util/regimeToOption';
import isForeignRfc from '@util/isForeignRfc';
import isGenericRfc from '@util/isGenericRfc';
import * as validation from '@util/validation';
import useFiscalRegimeOptions from '@hooks/useFiscalRegimeOptions';
import { trimFormatter } from '@util/formatters';
import { PAYMENT_TYPE_OPTIONS, USE_CFDI_OPTIONS } from '@constants/invoicing';
import useVerifyRfc from '@hooks/useVerifyRfc';
import useCreateClient from '@hooks/useCreateClient';
import useUpdateClient from '@hooks/useUpdateClient';
import FormikTextInput from '@components/FormikTextInput';
import FormikSelect from 'shared/components/FormikSelect';
import FormikRegimeSelect from '@components/FormikRegimeSelect';
import { Colxx } from '@components/CustomBootstrap';
import { NotificationManager } from '@components/Notifications';
import getErrorMessage from '@util/getErrorMessage';
import useFeature from '@hooks/useFeature';
import { NEW_CLIENT_SUPPLIER_INPUTS } from '@constants/featureFlags';
import FormFieldWrapper from '@components/FormFieldWrapper';
import FormWrapper from '@components/FormWrapper';
import isGeneralPublicRfc from '@util/isGeneralPublicRfc';
import preventTypeText from '@util/preventTypeText';

const validationSchema = yup.object().shape({
  legalName: yup.string().required('Campo requerido'),
  rfc: yup
    .string()
    .required('Campo requerido')
    .test(
      'format',
      'Porfavor ingrese un RFC con formato valido. Ejemplo: XAX010101000',
      (rfc) => validation.rfc(rfc || ''),
    ),
  email: yup.string().email('El correo electrónico debe ser válido'),
  fiscalRegimes: yup.array().min(1, 'Campo requerido'),
  address: yup.object().shape({
    postcode: yup
      .string()
      .typeError('Tipo invalido, consulte con soporte')
      // .length(5, 'Deben ser 5 caracteres')
      .test('required', 'Campo requerido', (value, context) => {
        const validate = !isGenericRfc(context.from[1].value.rfc);
        if (!validate) return true;
        return !!value;
      })
      .test(
        'format',
        'Formato invalido (deben ser 5 números)',
        (value, context) => {
          const validate = !isGenericRfc(context.from[1].value.rfc);
          if (!validate) return true;
          return /^\d{5}$/.test(value);
        },
      ),
  }),
  cfdiUsage: yup.string().nullable(),
  paymentType: yup.string().nullable(),
});

// TODO: replace with "getGenericRfcClient" and create a parser "clientToValues"
function getGenericRfcValues(postcode, rfc) {
  return {
    rfc,
    email: '',
    legalName: isForeignRfc(rfc)
      ? 'Rfc generico extranjero'
      : 'PUBLICO EN GENERAL',
    address: {
      postcode: `${postcode || ''}`,
    },
    fiscalRegimes: [
      regimeToOption({
        id: 7,
        description: 'Sin obligaciones fiscales',
        sat_key: 616,
        alias: '',
      }),
    ],
    source: '',
    cfdiUsage: '',
    paymentType: '',
  };
}

function useInitialValues(client) {
  const { regimeOptions } = useFiscalRegimeOptions({
    availableRegimes: client?.fiscal_regimes.map((regime) => regime.id) ?? [],
  });
  const userPostcode = useSelector((state) => {
    return state.addresses.address?.postcode;
  });

  const initialValues = useMemo(() => {
    if (!client) {
      return {
        legalName: '',
        rfc: '',
        email: '',
        fiscalRegimes: regimeOptions,
        address: {
          postcode: '',
        },
        source: '',
        cfdiUsage: '',
        paymentType: '',
      };
    }

    const address = client.address || {};

    // This will fix "Publico en general" for existent users on production, remove it in the future
    if (isGenericRfc(client.rfc)) {
      return getGenericRfcValues(userPostcode, client.rfc);
    }

    // TODO: convert this return into a function "clientToValues"
    return {
      legalName: client.legal_name || '',
      rfc: client.rfc || '',
      email: client.email || '',
      fiscalRegimes: regimeOptions,
      address: {
        postcode: `${address.postcode || ''}`,
      },
      source: 'app',
      cfdiUsage: client.cfdi_use?.key || '',
      paymentType: client.payment_form?.key || '',
    };
  }, [client, regimeOptions, userPostcode]);

  return initialValues;
}

function valuesToClient(values) {
  const { address } = values;

  return {
    legal_name: values.legalName.trim(),
    first_name: values.firstName,
    rfc: values.rfc,
    email: values.email,
    alias: values.alias,
    fiscal_regime_ids: values.fiscalRegimes.map((item) => item.value.id),
    address_attributes: {
      postcode: address.postcode || null,
    },
  };
}

// TODO: this component must be more like ProductForm
export default function ClientModalForm({
  client,
  isOpen,
  toggle,
  onSubmit,
  relationship,
}) {
  const [newClientSupplierInputs] = useFeature(NEW_CLIENT_SUPPLIER_INPUTS);
  const dispatch = useDispatch();
  const [validating, setValidating] = useState(false);
  const [alertError, setAlertError] = useState(null);
  const initialValues = useInitialValues(client);
  const verifyRfc = useVerifyRfc({ source: 'ClientModalForm' });
  const userPostcode = useSelector(
    (state) => state.addresses.address?.postcode,
  );
  const taxableEntity = useSelector(
    (state) => state.taxableEntity.taxable_entity,
  );

  const handleSuccess = (upsertedClient) => {
    dispatch(fetchClientsSuppliers(taxableEntity));
    onSubmit(upsertedClient);
  };

  const handleError = (error) => {
    let errorMessage = getErrorMessage(error);

    if (errorMessage === 'ya ha sido tomado') {
      errorMessage = 'Ya existe un cliente con este RFC';
    }

    NotificationManager.error(errorMessage, 'Error', 6000, null, null);
  };

  const createClient = useCreateClient({
    onSuccess: (response) => {
      NotificationManager.success('Se ha creado exitosamente', 'Cliente');
      handleSuccess(response);
    },
    onError: handleError,
  });
  const updateClient = useUpdateClient({
    onSuccess: (response) => {
      NotificationManager.success('Se ha actualizado exitosamente', 'Cliente');
      handleSuccess(response);
    },
    onError: handleError,
  });

  const isEditingMode = Boolean(client);
  const isLoading =
    createClient.isLoading || updateClient.isLoading || validating;

  const handleSubmit = async (values, { setErrors }) => {
    try {
      setAlertError(null);
      const tempPostcode = values.address.postcode;

      // #region Validation
      if (isGenericRfc(values.rfc)) {
        // clear postcode for generic rfc
        // eslint-disable-next-line no-param-reassign
        values.address.postcode = taxableEntity.postcode;
      } else {
        setValidating(true);
        const { errors, fixedName } = await verifyRfc({
          rfc: values.rfc,
          zipCode: values.address.postcode,
          legalName: values.legalName,
        });
        setValidating(false);
        if (errors) {
          setErrors({
            rfc: errors.rfc,
            legalName: errors.name,
            address: { postcode: errors.cp },
          });
          if (errors.other) {
            setAlertError(errors.other);
          }
          return;
        }

        // eslint-disable-next-line no-param-reassign
        values.legalName = fixedName;
      }
      // #endregion
      const payload = {
        ...(client || {}),
        ...valuesToClient(values),
        relationship_type: relationship,
        ...(newClientSupplierInputs && {
          cfdi_use_key: values.cfdiUsage,
          payment_form_key: values.paymentType,
          source: 'app',
        }),
      };
      if (isEditingMode) {
        updateClient.mutate(payload);
      } else {
        createClient.mutate(payload);
      }

      // eslint-disable-next-line no-param-reassign
      values.address.postcode = tempPostcode;
    } catch (error) {
      setValidating(false); // for the flyes :v
      // eslint-disable-next-line
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const genericRfc = isGenericRfc(formik.values.rfc);
  const generalPublicRfc = isGeneralPublicRfc(formik.values.rfc);
  const handleRfcChange = ({ target }) => {
    const clientRfc = target.value.toUpperCase();

    if (isGenericRfc(clientRfc)) {
      formik.setValues(getGenericRfcValues(userPostcode, clientRfc));
    } else {
      formik.setValues({
        ...formik.values,
        rfc: clientRfc,
      });
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="m" height="full">
      <ModalHeader
        toggle={toggle}
        style={{ fontWeight: '500', fontSize: '18px', color: '$gray900' }}
      >
        {isEditingMode ? 'Editar cliente' : 'Nuevo Cliente'}
      </ModalHeader>
      <ModalContent>
        {`En esta vista puedes ${
          isEditingMode ? 'editar' : 'crear'
        } un cliente`}
        <FormikProvider value={formik}>
          <FormWrapper>
            <FormFieldWrapper>
              <FormikTextInput
                name="rfc"
                label="RFC"
                onChange={handleRfcChange}
                disabled={isLoading}
              />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormikTextInput
                label="Razón Social"
                name="legalName"
                formatOnBlur={trimFormatter}
                disabled={isLoading || generalPublicRfc}
                helperText="Nombre sin régimen societario (Sociedad Anónima, SA de CV, Sociedad Civil, SC, CV, etc.)"
              />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormikTextInput
                name="email"
                label="Email (opcional)"
                disabled={isLoading || genericRfc}
              />
              <FormikTextInput
                name="address.postcode"
                label="Código Postal"
                formatOnBlur={trimFormatter}
                disabled={isLoading || genericRfc}
                onKeyPress={preventTypeText}
              />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormikRegimeSelect
                css={{ width: '100%' }}
                newDesign
                isMulti
                name="fiscalRegimes"
                label="Régimen fiscal"
                disabled={isLoading || genericRfc}
              />
            </FormFieldWrapper>

            {newClientSupplierInputs && (
              <FormFieldWrapper>
                <FormikSelect
                  name="cfdiUsage"
                  label="Uso CFDI (opcional)"
                  labelHelpPopover={
                    <Flex>
                      <Text>
                        El Uso que tu cliente le va a dar al CFDI. (Usualmente
                        “Gastos en General”)
                      </Text>
                    </Flex>
                  }
                  // disabled={disableCfdiUsage}
                  options={USE_CFDI_OPTIONS}
                />
                <FormikSelect
                  // disabled={invoice.isPPD}
                  name="paymentType"
                  labelHelpPopover={
                    <Flex>
                      <Text>
                        Es la manera en que recibiste el pago de tu producto o
                        servicio.{' '}
                        <Text bold>
                          En caso de factura en PPD la Forma de Pago es “Por
                          Definir”
                        </Text>
                      </Text>
                    </Flex>
                  }
                  label="Forma de pago (opcional)"
                  options={PAYMENT_TYPE_OPTIONS}
                />
              </FormFieldWrapper>
            )}
            {!!alertError && (
              <Colxx md={12} className="pt-3">
                <Alert color="danger">{alertError}</Alert>
              </Colxx>
            )}
          </FormWrapper>
        </FormikProvider>
      </ModalContent>
      <ModalFooter>
        <Button color="white" loading={isLoading} onClick={toggle} size="m">
          Cancelar
        </Button>
        <Button
          color="primary"
          loading={isLoading}
          onClick={() => formik.submitForm()}
          size="m"
        >
          Confirmar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ClientModalForm.propTypes = {
  client: PropTypes.instanceOf(Object),
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  relationship: PropTypes.oneOf(['client', 'provider']),
};

ClientModalForm.defaultProps = {
  client: null,
  isOpen: false,
  toggle: null,
  onSubmit: null,
  relationship: 'client',
};
