import { ReactSelectItemProps } from 'types/entities';
import _ from 'lodash';

export default function useDraftSelectsParser<T>(
  draftValue: string | number | undefined | null,
  options: ReactSelectItemProps<T>[],
  defaultValue?: ReactSelectItemProps<T>,
  optionKey: keyof ReactSelectItemProps<T> = 'value',
) {
  if (!draftValue) {
    return defaultValue || null;
  }
  return (
    _.find<ReactSelectItemProps<T>>(
      options,
      (option) => option[optionKey] === draftValue,
    ) ||
    defaultValue ||
    null
  );
}
