import { useEffect, useState } from 'react';

const innerHTML = `
!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");
window.dispatchEvent(new Event('canny-changelog-loaded'));
`;

export default function useChangelogWidgetScript() {
  const [loaded, setLoaded] = useState(false);
  const isLogged = window.localStorage.getItem('user_id');

  useEffect(() => {
    if (!isLogged) return;
    if (!loaded) return;
    if (localStorage.watermark) return;
    Canny('initChangelog', {
      appID: '64b7265fc92c9c954d4e4a7f',
      position: 'bottom',
      align: 'right',
      theme: 'light', // options: light [default], dark, auto
    });
  }, [loaded, window.Canny]);

  useEffect(() => {
    if (document.querySelector('#canny-script')) return;

    window.addEventListener('canny-changelog-loaded', () => setLoaded(true));

    const script = document.createElement('script');
    script.id = 'canny-script';
    script.innerHTML = innerHTML;
    script.async = true;
    document.body.appendChild(script);
  }, [document.querySelector('#canny-script')]);
}
