import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  FINANCIAL_TRANSACTIONS_FETCH,
  UI_CHANGE_DATE,
} from '@constants/actionTypes';
import fetchSaga from './fetch';
import dateSaga from './date';

function* watchFetch() {
  yield takeLatest(FINANCIAL_TRANSACTIONS_FETCH, fetchSaga);
}
function* watchDate() {
  yield takeLatest(UI_CHANGE_DATE, dateSaga);
}

export default function* rootSaga() {
  yield all([fork(watchFetch), fork(watchDate)]);
}
