import { Row, Id } from '@silevis/reactgrid';
import _ from 'lodash';
import {
  HEADING_ROW_HEIGHT,
  MONTHS,
  ROW_HEIGHT,
  EMPTY_ROW_ID,
  HEADER_ROW_ID,
} from 'shared/constants/grids';
import { isSelectedMonth, sumGroupValues } from '../../shared/util/grids';
import {
  borderLeft,
  borderLeftRightTop,
  borderRight,
  bottomLine,
  emptyTextCell,
  generateEmptyCellForMonths,
  leftBorder,
  monthHeaderCell,
  noBorders,
  noLeftBorder,
  nonEditable,
  noRightBorder,
  numberCell,
  rightBorder,
  showZero,
  textCell,
} from './cells';

export function getHeaderRow(
  selectedMonth: string | null,
  lastItemName: string,
  months: string[] | string[][],
  height: number,
  rowId: Id,
  title = '',
): Row {
  const cellMonths = months.map((month) => {
    const monthIsArray = Array.isArray(month);
    const monthName = monthIsArray ? month[0] : month;
    const extraText = monthIsArray ? month[1] : '';
    const cell = monthHeaderCell(
      `${monthName} ${extraText}`,
      'justify-content-center',
    );
    if (isSelectedMonth(monthName, selectedMonth)) {
      return borderLeftRightTop(cell);
    }
    return cell;
  });
  return {
    rowId,
    height,
    cells: [
      noRightBorder(nonEditable(textCell(title, 'font-bold'))),
      ...cellMonths,
      noLeftBorder(
        monthHeaderCell(lastItemName, 'font-bold justify-content-end'),
      ),
    ],
  };
}

export function getEmptyRow(
  selectedMonth: string | null,
  months: string[],
  height: number,
  rowId: Id,
  nonBorders = false,
): Row {
  if (nonBorders) {
    return {
      rowId,
      height,
      cells: [
        leftBorder(noBorders(nonEditable(emptyTextCell))),
        ...generateEmptyCellForMonths(
          selectedMonth,
          'empty',
          months,
          nonBorders,
        ),
        rightBorder(noBorders(nonEditable(emptyTextCell))),
      ],
    };
  }
  return {
    rowId,
    height,
    cells: [
      noRightBorder(nonEditable(emptyTextCell)),
      ...generateEmptyCellForMonths(selectedMonth, 'empty', months),
      noLeftBorder(nonEditable(emptyTextCell)),
    ],
  };
}

export type GetRowOptions = {
  title: string;
  groups: {
    title: string;
    values: number[];
    id: string;
  }[];
  selectedMonth: string | null;
  withTitle?: boolean;
  showLastValueKeys?: string[];
};
export function getGroupRows({
  title,
  groups,
  selectedMonth,
  withTitle = true,
  showLastValueKeys = [],
}: GetRowOptions): Row[] {
  const cellGroupMonths = (values: number[], isLast: boolean) =>
    values.map((val, idx) => {
      const className = isLast ? 'font-bold disabled' : '';
      const isCurrentMonth = isSelectedMonth(MONTHS[idx], selectedMonth);
      if (isCurrentMonth) {
        return borderRight(
          borderLeft(nonEditable(numberCell(values[idx], className))),
        );
      }
      return nonEditable(numberCell(values[idx], className));
    });

  return [
    ...(withTitle
      ? [
          {
            rowId: `${title}Header`,
            height: HEADING_ROW_HEIGHT,
            cells: [
              bottomLine(
                nonEditable(
                  textCell(title, `align-items-end text-md font-bold`),
                ),
              ),
              ...generateEmptyCellForMonths(selectedMonth, 'group', MONTHS),
              bottomLine(nonEditable(emptyTextCell)),
            ],
          },
        ]
      : []),
    ...groups.flatMap<Row>(({ title: concept, values, id }, index) => {
      const isLast = index === groups.length - 1;
      const firstClass = isLast
        ? 'padding-left-lg disabled font-bold'
        : 'padding-left-lg';
      const lastClass = isLast ? 'font-bold disabled' : 'font-bold ';

      const hasLastValueKey = showLastValueKeys.includes(id);
      const lastValue = hasLastValueKey
        ? _.findLast(values, (n) => n > 0) ?? 0
        : 0;
      return [
        {
          rowId: `${concept}-${id}-${index}`,
          height: ROW_HEIGHT,
          cells: [
            nonEditable(textCell(concept, firstClass)),
            ...cellGroupMonths(values, isLast),
            nonEditable(
              showZero(
                numberCell(
                  hasLastValueKey ? lastValue : sumGroupValues(values),
                  lastClass,
                ),
              ),
            ),
          ],
        },
      ];
    }),
  ];
}

export type GenerateRowsOptions<T extends string> = {
  title: string;
  values: Record<T, number[]>;
  withTitle?: boolean;
  selectedMonth: string | null;
  cellDict: Record<string, string>;
  showLastValueKeys?: string[];
};
export function generateRows<T extends string>({
  title,
  values,
  withTitle = true,
  selectedMonth,
  cellDict,
  showLastValueKeys,
}: GenerateRowsOptions<T>) {
  const group = Object.entries(values).map(([id, cells]) => ({
    title: cellDict[id],
    values: cells as number[],
    id,
  }));

  return getGroupRows({
    title,
    groups: group,
    selectedMonth,
    withTitle,
    showLastValueKeys,
  });
}

export type GetEmptyRowOptions = {
  id: number;
  selectedMonth: string | null;
};
export function generateEmptyRow({ id, selectedMonth }: GetEmptyRowOptions) {
  return getEmptyRow(
    selectedMonth,
    MONTHS,
    HEADING_ROW_HEIGHT,
    `${EMPTY_ROW_ID}-${id}`,
  );
}

export type GenerateHeaderRowOptions = {
  lastItemName: string;
  selectedMonth: string | null;
  months?: string[] | string[][];
};
export function generateHeaderRow({
  lastItemName,
  selectedMonth,
  months = MONTHS,
}: GenerateHeaderRowOptions) {
  return getHeaderRow(
    selectedMonth,
    lastItemName,
    months,
    HEADING_ROW_HEIGHT,
    HEADER_ROW_ID,
  );
}
