import { Row } from '@silevis/reactgrid';
import dayjs from 'dayjs';
import useSimplifiedRows from 'shared/hooks/grids/useSimplifiedRows';
import { FORMAT_MONTH } from 'shared/constants/grids';
import type { SimplifiedDataGrid } from 'types/grids';
import type { Workflow } from 'types/entities';

interface UseRowsProps {
  dataSet?: SimplifiedDataGrid;
  selectedWorkflow: Workflow | null;
}

export default function useRows({
  dataSet,
  selectedWorkflow,
}: UseRowsProps): Row[] {
  const selectedMonth = dayjs(selectedWorkflow?.start_date).format(
    FORMAT_MONTH,
  );

  const rows = useSimplifiedRows({
    dataSet,
    selectedMonth,
  });

  return rows;
}
