import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
import { useToggle } from 'rooks';
import { Row, Collapse } from 'reactstrap';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import 'dayjs/locale/es';
import { Button } from '@konta/ui';
import { SettingsAdjustHrAlIcon, InfoCircleLineIcon } from '@konta/icons';
import * as chat from '@util/chat';
import useFeature from '@hooks/useFeature';
import { COMPLEMENT_GLOBAL_ATTRIBUTES_ENABLED } from '@constants/featureFlags';
import { Colxx } from '@components';
import { fiscalRegimeParser } from '@util/Utils';
import { CFDIS } from '@constants/reactQueries';
import toCurrency from '@util/toCurrency';
import generateComplementInvoice from '@util/generateComplementInvoice';
import FormikTextField from '@components/FormikTextField';
import FormikSelect from '@components/FormikSelect';
import isGenericRfc from '@util/isGenericRfc';
import FormikPeriodicityMonthField from '@components/FormikPeriodicityMonthField';
import { declarationEntriesCreate, postCFDI } from '@redux/actions';
import {
  CURRENCY_DATA,
  PAYMENT_TYPE,
  FISCAL_REGIME,
  PERIODICITY_OPTIONS,
} from '@data/newInvoiceData';
import useActiveFiscalRegime from '@hooks/useActiveFiscalRegime';
import useUserAddressQuery from '@hooks/useUserAddressQuery';
import useLastComplement from '@hooks/useLastComplement';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import { useCfdis } from '@hooks';
import getServerDate from '@util/getServerDate';
import {
  Alert,
  AlertContent,
  AlertText,
  AlertDescription,
} from '@components/Workflow/styled';
import ComplementDropzone from './ComplementDropzone';

export default function ComplementForm({
  cfdi,
  complements,
  complementsLoading,
  sumComplementPaid,
  totalInvoice,
  uploadComplementType,
  isWorkflow,
  toggleModal,
  isPfaeAndPlatform,
  taxableEntityPreferences,
}) {
  const queryClient = useQueryClient();
  const { workflow } = useSelectedWorkflow();
  const { userAddress } = useUserAddressQuery();
  const { loading: entriesLoading } = useSelector(
    (state) => state.declaration_entries,
  );
  const { loading } = useCfdis();
  const dispatch = useDispatch();
  const [prevValue, setPrevValue] = useState(0);
  const [fileState, setFileState] = useState([]);
  const [showOptions, setShowOptions] = useToggle(false);
  const activeFiscalRegime = useActiveFiscalRegime(
    isWorkflow,
    isPfaeAndPlatform,
    taxableEntityPreferences,
  );

  const { active_declaration, taxable_entity_id, start_date } = workflow;
  const generalPublicRfc = isGenericRfc(cfdi.receiver_rfc);

  const lastComplement = useLastComplement({
    isWorkflow,
    cfdi,
    complements,
    complementsLoading,
  });
  const [complementGlobalAttributesEnabled] = useFeature(
    COMPLEMENT_GLOBAL_ATTRIBUTES_ENABLED,
  );

  const handleFileSubmit = () => {
    const fileComplementBody = new FormData();
    fileComplementBody.append(
      'declaration_entry[accounting_status]',
      'is_deductible',
    );
    fileComplementBody.append(
      'declaration_entry[accounting_date]',
      active_declaration.start_date,
    );
    fileComplementBody.append(
      'declaration_entry[source_type]',
      'PaymentDetail',
    );
    fileComplementBody.append(
      'declaration_entry[declaration_id]',
      active_declaration.id,
    );
    fileComplementBody.append(
      'declaration_entry[taxable_entity_id]',
      taxable_entity_id,
    );
    fileComplementBody.append(
      'declaration_entry[source_attributes][ppd_id]',
      cfdi.id,
    );
    fileComplementBody.append(
      'declaration_entry[source_attributes][payment_date]',
      start_date,
    );
    fileComplementBody.append(
      'declaration_entry[source_attributes][cfdi_attributes][xml]',
      fileState[0],
    );
    dispatch(declarationEntriesCreate({ body: fileComplementBody }));
  };

  const handleFormSubmit = async (values, events) => {
    const serverDate = await getServerDate();

    const selectedFiscalRegime = values.fiscal_regime;
    const { receipt } = generateComplementInvoice(
      cfdi,
      values,
      selectedFiscalRegime,
      generalPublicRfc,
      complementGlobalAttributesEnabled,
      serverDate,
    );

    if (isWorkflow || isPfaeAndPlatform) {
      const entryBody = {
        accounting_status: 'is_deductible',
        accounting_date: active_declaration.start_date,
        source_type: 'PaymentDetail',
        declaration_id: active_declaration.id,
        taxable_entity_id,
        source_attributes: {
          receipt,
        },
      };
      dispatch(
        declarationEntriesCreate({
          body: entryBody,
          callback: () => {
            events.resetForm();
            toggleModal();
          },
        }),
      );
    } else {
      dispatch(
        postCFDI(
          {
            receipt,
          },
          null,
          cfdi.id,
          () => {
            queryClient.invalidateQueries([CFDIS]);
            toggleModal();
          },
        ),
      );
    }
  };

  const buttonDisabled = sumComplementPaid === totalInvoice;

  const defaultRegime = useMemo(() => {
    if (taxableEntityPreferences?.preferred_fiscal_regime) {
      return activeFiscalRegime.find(
        (regime) =>
          regime.sat_key === taxableEntityPreferences.preferred_fiscal_regime,
      );
    }
    if (activeFiscalRegime.length === 1) {
      return activeFiscalRegime[0];
    }
    return null;
  }, [activeFiscalRegime, taxableEntityPreferences?.preferred_fiscal_regime]);

  const initialValuesSchema = useMemo(() => {
    const clientSupplierFiscalRegimeParsed =
      cfdi?.client_supplier?.fiscal_regimes?.length === 1 &&
      fiscalRegimeParser(cfdi?.client_supplier.fiscal_regimes);
    return {
      amount: '',
      payment_number:
        (lastComplement && lastComplement.partiality_number + 1) || 1,
      payment_form: '',
      payment_date: '',
      receiver_zip_code:
        (cfdi && generalPublicRfc
          ? userAddress?.postcode
          : cfdi.client_supplier?.address?.postcode) || '',
      receiver_fiscal_regime: clientSupplierFiscalRegimeParsed[0] || '',
      exchange_rate: 1,
      previous_debt:
        (lastComplement?.new_debt > 0
          ? +lastComplement.new_debt
          : +cfdi.total) || 0,
      new_debt: (lastComplement && +lastComplement.new_debt) || 0,
      currency: {
        label: 'Pesos Mexicanos',
        value: 'MXN',
        key: 137,
      },
      expedition_place: userAddress?.postcode,
      periodicity:
        (cfdi &&
          generalPublicRfc && {
            value: '04',
            label: 'Mensual',
            key: 4,
          }) ||
        '',
      fiscal_regime: defaultRegime,
    };
  }, [cfdi, userAddress, lastComplement, generalPublicRfc, defaultRegime]);

  useEffect(() => {
    setPrevValue(initialValuesSchema.previous_debt);
  }, [initialValuesSchema.previous_debt, initialValuesSchema.new_debt]);

  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .required('Campo requerido')
      .when('previous_debt', {
        is: (value) => {
          return value > 0;
        },
        then: yup
          .number()
          .max(
            prevValue,
            `El monto no debe exceder al importe de saldo anterior (importe: ${prevValue})`,
          ),
      })
      .min(1, 'El monto debe ser mayor a 0'),
    previous_debt: yup
      .number()
      .max(
        totalInvoice,
        `El Importe de saldo anterior no debe exceder al monto total de la PPD: ${toCurrency(
          totalInvoice,
        )}`,
      )
      .min(1, 'El monto debe ser mayor a 0')
      .required('Campo requerido'),
    payment_number: yup.string().required('Campo requerido'),
    payment_date: yup.date().required('Campo requerido'),
    payment_form: yup.object().required('Campo requerido'),
    receiver_fiscal_regime: yup.object().required('Campo requerido'),
    exchange_rate: yup.string(),
    currency: yup.object().shape({
      value: yup.string(),
      id: yup.string(),
      key: yup.string(),
      label: yup.string(),
    }),
    receiver_zip_code: yup.string().required('Campo requerido'),
    new_debt: yup.string('Campo requerido'),
    expedition_place: yup.string().required('Campo requerido'),
    fiscal_regime: yup.object().nullable().required('Campo requerido'),
  });

  const handleAddFile = (file) => {
    setFileState((filesState) => [...filesState, file]);
  };

  const handleRemoveFile = (file) => {
    setFileState((filesState) => {
      const newFileArray = filesState.filter(
        (item) => item.name !== file.name && item.size !== file.size,
      );
      return newFileArray;
    });
  };

  const eventHandlers = {
    addedfile: handleAddFile,
    removedfile: handleRemoveFile,
  };

  const formik = useFormik({
    initialValues: initialValuesSchema,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleFormSubmit,
  });

  const hasRetainedIsr = cfdi.retained_isr > 0;

  return (
    <>
      <Colxx xxs={12}>
        <Collapse isOpen={uploadComplementType === 'manual_total'}>
          <FormikProvider value={formik}>
            <>
              <Row>
                <Colxx xxs={12} md={6} lg={4}>
                  <FormikTextField
                    label="Importe de saldo anterior *"
                    name="previous_debt"
                    placeholder="Ej. 200"
                    type="text"
                    onChange={({ target }) => {
                      setPrevValue(target.value);
                      const { value } = formik.getFieldProps('amount');
                      formik.setFieldValue(
                        'new_debt',
                        Number(target.value) - value,
                      );
                    }}
                  />
                </Colxx>
                <Colxx xxs={12} md={6} lg={4}>
                  <FormikTextField
                    label="Monto total *"
                    name="amount"
                    type="text"
                    placeholder="Ej. 200"
                    onChange={({ target }) => {
                      const { value } = formik.getFieldProps('previous_debt');
                      formik.setFieldValue(
                        'new_debt',
                        value - Number(target.value),
                      );
                    }}
                  />
                </Colxx>
                <Colxx xxs={12} md={6} lg={4}>
                  <FormikTextField
                    placeholder="Ej. 200"
                    label="Importe de saldo insoluto *"
                    name="new_debt"
                    type="text"
                    disabled
                  />
                </Colxx>

                {!formik.initialValues.payment_number && (
                  <Colxx xxs={12} md={6} lg={4}>
                    <FormikTextField
                      label="Número de parcialidad *"
                      name="payment_number"
                      placeholder="Ej. 2"
                      type="text"
                    />
                  </Colxx>
                )}
                <Colxx xxs={12} md={6} lg={4}>
                  <FormikSelect
                    label="Forma de pago *"
                    name="payment_form"
                    options={PAYMENT_TYPE}
                  />
                </Colxx>
                <Colxx xxs={12} md={6} lg={4}>
                  <FormikTextField
                    label="Fecha de pago *"
                    name="payment_date"
                    onClick={(event) => event.target.showPicker?.()}
                    onChange={({ target }) => {
                      // dayjs.locale('es');
                      const date = dayjs(target.value);
                      formik.setFieldValue('periodicityMonth', {
                        value: date.format('MM'),
                        label: date.format('MMMM'),
                        key: date.format('M'),
                      });
                      formik.setFieldValue(
                        'periodicityYear',
                        date.format('YYYY'),
                      );
                    }}
                    type="date"
                  />
                </Colxx>

                {!cfdi.client_supplier?.address?.postcode && (
                  <Colxx xxs={12} md={6} lg={4}>
                    <FormikTextField
                      label="Código postal del cliente *"
                      name="receiver_zip_code"
                      type="text"
                      placeholder="Ej. 97123"
                    />
                  </Colxx>
                )}
                {cfdi.client_supplier?.fiscal_regimes?.length !== 1 && (
                  <Colxx xxs={12} md={6} lg={4}>
                    <FormikSelect
                      label="Régimen fiscal del cliente *"
                      name="receiver_fiscal_regime"
                      options={
                        cfdi.client_supplier?.fiscal_regimes?.length > 0
                          ? fiscalRegimeParser(
                              cfdi.client_supplier.fiscal_regimes,
                            )
                          : FISCAL_REGIME
                      }
                    />
                  </Colxx>
                )}
                <Colxx xxs={12} md={6} lg={4}>
                  <FormikSelect
                    label="Régimen fiscal *"
                    name="fiscal_regime"
                    options={activeFiscalRegime}
                    disabled={
                      !!taxableEntityPreferences?.preferred_fiscal_regime ||
                      activeFiscalRegime.length === 1
                    }
                  />
                </Colxx>

                {!userAddress?.postcode && (
                  <Colxx xxs={12} md={6} lg={4}>
                    <FormikTextField
                      placeholder="Ej. 97123"
                      label="Código postal *"
                      name="expedition_place"
                      type="number"
                    />
                  </Colxx>
                )}
              </Row>

              <Row />
              <Row className="mt-3">
                <Colxx xxs={12} md={6} lg={4}>
                  <Button
                    size="s"
                    css={{ mb: '$16' }}
                    color="gray"
                    type="button"
                    variant={showOptions ? 'contained' : 'outlined'}
                    onClick={setShowOptions}
                    leftIcon={<SettingsAdjustHrAlIcon />}
                  >
                    Opciones avanzadas
                  </Button>
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs={{ size: 12, offset: 0 }} className="mb-2">
                  <Collapse isOpen={showOptions}>
                    <Row>
                      <Colxx xxs={12} md={6} lg={4}>
                        <FormikSelect
                          label="Moneda"
                          name="currency"
                          options={CURRENCY_DATA}
                        />
                      </Colxx>
                      <Colxx xxs={12} md={6} lg={4}>
                        <FormikTextField
                          label="Tipo de cambio"
                          name="exchange_rate"
                          disabled={
                            formik.getFieldProps('currency').value.value ===
                            'MXN'
                          }
                          placeholder="Ej. 16"
                          type="text"
                        />
                      </Colxx>
                      {!!formik.initialValues.payment_number && (
                        <Colxx xxs={12} md={6} lg={4}>
                          <FormikTextField
                            label="Número de parcialidad *"
                            name="payment_number"
                            placeholder="Ej. 2"
                            type="number"
                          />
                        </Colxx>
                      )}
                      {userAddress?.postcode && (
                        <Colxx xxs={12} md={6} lg={4}>
                          <FormikTextField
                            placeholder="Ej. 97123"
                            label="Código postal *"
                            name="expedition_place"
                            type="number"
                          />
                        </Colxx>
                      )}
                      {cfdi.client_supplier?.address && (
                        <Colxx xxs={12} md={6} lg={4}>
                          <FormikTextField
                            label="Código postal del cliente *"
                            name="receiver_zip_code"
                            type="text"
                            placeholder="Ej. 97123"
                          />
                        </Colxx>
                      )}
                      {generalPublicRfc && complementGlobalAttributesEnabled && (
                        <>
                          <Colxx xxs={12} md={6} lg={4}>
                            <FormikSelect
                              name="periodicity"
                              label="Periodicidad"
                              options={PERIODICITY_OPTIONS}
                            />
                          </Colxx>
                          <Colxx xxs={12} md={6} lg={4}>
                            <FormikPeriodicityMonthField name="periodicityMonth" />
                          </Colxx>

                          <Colxx xxs={12} md={6} lg={4}>
                            <FormikTextField
                              name="periodicityYear"
                              label="Año"
                              type="number"
                            />
                          </Colxx>
                        </>
                      )}
                      {!(
                        cfdi.client_supplier?.fiscal_regimes?.length !== 1
                      ) && (
                        <Colxx md={4}>
                          <FormikSelect
                            label="Régimen fiscal del cliente *"
                            name="receiver_fiscal_regime"
                            options={
                              cfdi.client_supplier?.fiscal_regimes.length > 0
                                ? fiscalRegimeParser(
                                    cfdi.client_supplier.fiscal_regimes,
                                  )
                                : FISCAL_REGIME
                            }
                          />
                        </Colxx>
                      )}
                    </Row>
                  </Collapse>
                </Colxx>
              </Row>
              <Row>
                <Colxx xxs={12}>
                  <Button
                    size="m"
                    color="primary"
                    loading={loading || entriesLoading}
                    onClick={formik.submitForm}
                    disabled={buttonDisabled || hasRetainedIsr}
                  >
                    Emitir complemento
                  </Button>
                </Colxx>
                {hasRetainedIsr && (
                  <Colxx className="mt-4">
                    <Alert color="warning">
                      <InfoCircleLineIcon />
                      <AlertContent>
                        <AlertText>
                          No es posible emitir este complemento de pago.
                        </AlertText>
                        <AlertDescription>
                          De momento no podemos timbrar complementos de pago que
                          contengan retenciones de ISR en el CFDI.
                        </AlertDescription>
                        <AlertDescription>
                          Si necesitas ayuda para poder emitirlo habla con
                          soporte.
                        </AlertDescription>
                        <Button
                          onClick={chat.open}
                          variant="outlined"
                          color="gray"
                        >
                          Hablar con soporte
                        </Button>
                      </AlertContent>
                    </Alert>
                  </Colxx>
                )}
              </Row>
            </>
          </FormikProvider>
        </Collapse>
      </Colxx>
      <Colxx xxs={12}>
        <Collapse isOpen={uploadComplementType === 'file_total'}>
          <Row>
            <Colxx xxs={8}>
              <ComplementDropzone events={eventHandlers} />
            </Colxx>
            <Colxx xxs={12}>
              <Button
                size="m"
                color="primary"
                onClick={handleFileSubmit}
                disabled={
                  fileState.length === 0 || sumComplementPaid === totalInvoice
                }
              >
                Subir
              </Button>
            </Colxx>
          </Row>
        </Collapse>
      </Colxx>
    </>
  );
}
