import { ComponentType } from 'react';
import {
  WorkflowStatus as IWorkflowStatus,
  WorkflowPhaseKeyDict,
} from './entities';

export enum WorkflowPhaseKeyRouteType {
  Intro = '/app/workflow/:workflowId/intro',
  DeclarationInfo = '/app/workflow/:workflowId/declaration-info',
  IncomesByRegime = '/app/workflow/:workflowId/incomes-by-regime',
  ExpensesByRegime = '/app/workflow/:workflowId/expenses-by-regime',
  ResumeDeclaration = '/app/workflow/:workflowId/resume',
  PendingDeclaration = '/app/declaraciones?month=:month&year=:year',
  CaptureLinePayment = '/app/workflow/:workflowId/capture-line-payment',
  TaxesPayment = '/app/workflow/:workflowId/taxes-payment',
}
export type WorkflowPhases = {
  [type in WorkflowPhaseKeyDict]: WorkflowPhaseKeyRouteType;
};

export type StatusContent = {
  IconComponent: ComponentType;
  title: string;
  description: string;
  btnText?: string;
};
export type StatusMap = {
  [key in IWorkflowStatus]: StatusContent;
};
