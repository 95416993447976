import { useState } from 'react';
import { Icon, Spacer, styled } from '@konta/ui';
import { ChevronLeftDoubleLineIcon, Menu02LineIcon } from '@konta/icons';
import { useToggle } from 'usehooks-ts';
import KontaLogo from '@assets/img/logo-sidebar.svg';
import SidebarNavMenu from './SidebarNavMenu';
import { ReactComponent as MenuIcon } from './img/menu.svg';

const SidebarBackdrop = styled('div', {
  position: 'fixed',
  inset: 0,
  background: 'rgba(96, 96, 96, 0.17)',
  zIndex: 1,
  visibility: 'visible',
  '@lg': {
    visibility: 'hidden',
  },
});
const SidebarContainer = styled('div', {
  top: '0',
  height: 'unset',
  left: '-252px',
  bottom: '0',
  zIndex: 5,
  boxShadow: 'rgba(0, 0, 0, 0.07) 0px 0px 0px',
  position: 'absolute',
  transition: 'left 0.15s ease-in 0s, box-shadow 0.15s 0s',
  background: 'rgba(255, 255, 255, 1)',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  '@lg': {
    position: 'relative',
    userSelect: 'none',
    left: '0',
    width: '57px',
    height: '100%',
    maxWidth: '330px',
    boxSizing: 'border-box',
    borderRight: '1px solid rgba(239, 241, 244, 1)',
    background: 'rgba(255, 255, 255, 1)',
    transition: 'all 0.10s ease-out',
  },
  variants: {
    isMobileMenuOpen: {
      true: {
        left: '0',
      },
    },
    hasHover: {
      true: {
        '@lg': {
          width: '260px',
        },
      },
    },
  },
});
const SidebarHeader = styled('div', {
  padding: '8px',
  flexShrink: 0,
  display: 'flex',
  flex: 'initial',
  flexDirection: 'column',
  WebkitBoxAlign: 'stretch',
  alignItems: 'stretch',
});
const SidebarHeaderLogo = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  cursor: 'pointer',
  span: {
    width: '143px',
    height: '32px',
    flex: '1',
    background: `url(${KontaLogo}) no-repeat`,
    backgroundPosition: 'right',
    backgroundSize: '143px 32px',
    backgroundPositionX: 'left',
  },
  svg: {
    path: {
      fill: 'none',
      stroke: '$gray500',
    },
  },
});
const SidebarHamburguerIcon = styled('a', {
  position: 'fixed',
  left: 'env(safe-area-inset-left,0px)',
  top: '0px',
  width: '52px',
  height: '66px',
  padding: '0px 2px',
  paddingLeft: '8px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  transitionProperty: 'border, background, color, box-shadow',
  transitionDuration: '0.15s',
  minWidth: '24px',
  '@lg': {
    display: 'none',
  },
  variants: {
    isMobileMenuOpen: {
      true: {
        display: 'none',
      },
    },
  },
});
const SidebarContent = styled('div', {
  display: 'flex',
  flex: 'initial',
  flexDirection: 'column',
  flexGrow: 1,
  overflowY: 'auto',
  padding: '8px',
  marginBottom: '2px',
});

export default function Sidebar() {
  const [isMobileMenuOpen, toggleMobileMenuOpen] = useToggle();
  const [toggleMenuOpen, setToggleMenuOpen] = useState(false);

  return (
    <>
      {isMobileMenuOpen && <SidebarBackdrop onClick={toggleMobileMenuOpen} />}
      <SidebarContainer
        isMobileMenuOpen={isMobileMenuOpen}
        onMouseLeave={() => setToggleMenuOpen(false)}
        hasHover={toggleMenuOpen}
      >
        <SidebarHeader>
          <SidebarHeaderLogo onClick={() => setToggleMenuOpen(!toggleMenuOpen)}>
            <Icon
              size={24}
              css={{
                width: '100%',
              }}
            >
              {toggleMenuOpen ? (
                <>
                  <span />
                  <ChevronLeftDoubleLineIcon />
                </>
              ) : (
                <Menu02LineIcon />
              )}
            </Icon>
          </SidebarHeaderLogo>
        </SidebarHeader>
        <SidebarContent>
          <SidebarNavMenu
            toggleMenuOpen={toggleMenuOpen}
            setToggleMenuOpen={setToggleMenuOpen}
            toggleMobileMenuOpen={toggleMobileMenuOpen}
            hasHover={toggleMenuOpen}
          />
          <Spacer />
        </SidebarContent>
        <SidebarHamburguerIcon
          href="#"
          onClick={toggleMobileMenuOpen}
          isMobileMenuOpen={isMobileMenuOpen}
        >
          <MenuIcon />
        </SidebarHamburguerIcon>
      </SidebarContainer>
    </>
  );
}
