import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addressFetch } from '@redux/address/actions';
import type { IRootState } from 'types/redux';

export default function useUserAddress() {
  const { address, loading, error } = useSelector(
    ({ addresses }: IRootState) => addresses,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!address?.id) {
      dispatch(addressFetch());
    }
  }, [address, dispatch]);

  return { address, addressLoading: loading, addressError: error };
}
